import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { VehicleMake, VehicleModel, VehicleVariant } from 'src/app/models/pos';
import { PosService } from '../pos.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { CustomerVehicleDetails, Quote } from 'src/app/classes/quote';


@Component({
  selector: 'app-pos-vehicle-details',
  templateUrl: './pos-vehicle-details.component.html',
  styleUrls: ['./pos-vehicle-details.component.less']
})
export class PosVehicleDetailsComponent implements OnInit {

  @Input() quote : Quote = new Quote();
  @Output() quoteChange = new EventEmitter<Quote>(); //this is not used at the moment... but if needs be, this can emit to update the quote upwards

  @Output() onVehicleSave = new EventEmitter<CustomerVehicleDetails>();
  @Output() onRefreshQuote = new EventEmitter<number>();

  public _isLoading: boolean;
  public isSearchingVehicles: boolean = false;
  @Input() set isLoading(value: boolean) {
    this._isLoading = value;
    if (value===false) {
      
      this.updateVehicleForm(); 
    }
  };

  @Input() set setVehicleId(value: number) {
    if (value > 0) {
      this.selectedVehicle.Vehicle_Customer_MappingId = value;
      this.updateVehicleForm(); 
    }
    
  }

  vehicleMakes : VehicleMake[] = [];
  vehicleModels:VehicleModel[]=[];
  vehicleVariants:VehicleVariant[]=[];
  filteredVehicles: CustomerVehicleDetails[] = [];

  vehicleForm: FormGroup = new FormGroup({
    vehicleReg: new FormControl("",[Validators.required]),
    vehicleYear: new FormControl(""),
    vehicleOdo: new FormControl(""),
    vehicleMakeId: new FormControl("0"),
    vehicleModelId: new FormControl("0"),
    vehicleVariantId: new FormControl("0")
  });

  isVRegSearchDropDownOpen = false;
  selectedVehicle: CustomerVehicleDetails = new CustomerVehicleDetails();
  selectedVehicleDD?: CustomerVehicleDetails;
  searchedVReg = "";
  isLoadingMakes = false;
  isLoadingModels = false;
  isLoadingVariants = false;

  constructor(private posService: PosService, private notification: NotificationService) { }

  ngOnInit(): void {
    this.isLoadingMakes = true;
    this.posService.vehicleMakes().subscribe({
      next: val => {
        this.vehicleMakes = val;
        this.isLoadingMakes = false;
        
      },
      error: err => {this.notification.handleError(err);}
    })
  }

  loadModels(makeId: number) {
    if (makeId === 0) {
      return;
    }
    this.isLoadingMakes = true;
    this.posService.vehicleModels(makeId).subscribe({
      next: val => {
        this.vehicleModels = val;
        //if the quote is completed, the values would be loaded, but now disable the form
        if (!this.quote.isCompleted) {
          this.vehicleForm.controls.vehicleModelId.enable();
        }
        this.isLoadingMakes = false;
        

        if (this.selectedVehicle.vehicleModelId) { 
          
          this.vehicleForm.controls.vehicleModelId.setValue(this.selectedVehicle.vehicleModelId);
          this.selectedVehicle.vehicleModel = this.vehicleModels.find(x=>x.vehicleModelId == this.selectedVehicle.vehicleModelId)?.vehicleModel!;
          this.loadVariants(this.selectedVehicle.vehicleModelId);
        }
      },
      error: err => {this.notification.handleError(err);}
    })
  }

  loadVariants(modelId: number) {
    if (modelId === 0) {
      return;
    }
    this.isLoadingVariants = true;
    this.posService.vehicleVariants(modelId).subscribe({
      next: val => {
        this.vehicleVariants = val;
        this.isLoadingVariants = false;
        //if the quote is completed, the values would be loaded, but now disable the form
        if (!this.quote.isCompleted) {
          this.vehicleForm.controls.vehicleVariantId.enable();
        }
        
        if (this.selectedVehicle.vehicleVariantId) {          
          this.vehicleForm.controls.vehicleVariantId.setValue(this.selectedVehicle.vehicleVariantId);
          this.selectedVehicle.vehicleVariant = this.vehicleVariants.find(x=>x.vehicleVariantId == this.selectedVehicle.vehicleVariantId)?.vehicleVariant!;
        }
      },
      error: err => {this.notification.handleError(err);}
    })
  }

  onSearchVReg(value: string) {
    this.searchedVReg = value;
    if (this.quote.hasCustomer) {
      //only search in customers vehicles, if not found, display ADD NEW
      
      this.filteredVehicles = this.quote.customerVehicles.filter(e => e.v_reg.includes(value.toUpperCase())).slice(0, 25);
    } 
    else {
      //No customer has been loaded, so we need to search vehicle links to customers and add VEH and CUST
      //check if string is more than 3 chars (4+)
      if (value.length > 3) {
        this.isSearchingVehicles = true;
        //fire search
        this.posService.searchAllVehicles(value).subscribe({
          next: val => {
            this.filteredVehicles = val;
            this.isSearchingVehicles = false;
          },
          error: err => {
            this.isSearchingVehicles = false;
          }
        })
      }
      else {
        this.filteredVehicles = [];
      }
    }
    
    
    
  }

  addNewVehicle() {
    
    this.selectedVehicle = new CustomerVehicleDetails();
    // this.updateVehicleForm();
    this.vehicleForm.controls.vehicleReg.setValue(this.searchedVReg);
    this.selectedVehicle.Vehicle_Customer_MappingId = 0;
    this.selectedVehicle.v_reg = this.searchedVReg;
    this.selectedVehicle.odoReading = 0;
    this.selectedVehicle.width = this.quote.width;
    this.selectedVehicle.profile = this.quote.profile;
    this.selectedVehicle.rim = this.quote.rim;
    
    this.onVehicleSave.emit(this.selectedVehicle);
    this.quote.customerVehicles.push(this.selectedVehicle); // Add to Customer vehicles

    this.quote.markDirty();
    this.isVRegSearchDropDownOpen = false;
    this.vehicleForm.controls.vehicleReg.enable();
    this.vehicleForm.controls.vehicleYear.enable();
    this.vehicleForm.controls.vehicleOdo.enable();
    this.vehicleForm.controls.vehicleMakeId.enable();
  }

  updateVehicle() {

    if (this.selectedVehicle && this.selectedVehicle.Vehicle_Customer_MappingId > 0) {
      this.selectedVehicle.v_reg = this.vehicleForm.value.vehicleReg;
      this.selectedVehicle.modelYear = this.vehicleForm.value.vehicleYear;
      this.selectedVehicle.odoReading = this.vehicleForm.value.vehicleOdo;
      this.selectedVehicle.vehicleMakeId = this.vehicleForm.value.vehicleMakeId;
      this.selectedVehicle.vehicleModelId = this.vehicleForm.value.vehicleModelId;
      this.selectedVehicle.vehicleVariantId = this.vehicleForm.value.vehicleVariantId;
      this.onVehicleSave.emit(this.selectedVehicle);
    }
    
  }

  updateVehicleForm() {
    
    if (this.quote.hasVehicle){
      //run a normal foor loop, for in case the vehicle linked to quote is not int he customer list...
      for (let item of this.quote.customerVehicles) {
        if (item.Vehicle_Customer_MappingId === this.quote.Vehicle_Customer_MappingId)
          this.selectedVehicle = item;
      }
      if (this.quote.hasVehicle) {
        
        this.vehicleForm.controls.vehicleReg.setValue(this.selectedVehicle.v_reg);
        this.vehicleForm.controls.vehicleYear.setValue(this.selectedVehicle.modelYear);
        this.vehicleForm.controls.vehicleOdo.setValue(this.selectedVehicle.odoReading);
        
        this.vehicleForm.controls.vehicleReg.enable();
        this.vehicleForm.controls.vehicleYear.enable();
        this.vehicleForm.controls.vehicleOdo.enable();
        this.vehicleForm.controls.vehicleMakeId.enable();
        

        if (this.selectedVehicle.vehicleMakeId) {
          this.vehicleForm.controls.vehicleMakeId.setValue(this.selectedVehicle.vehicleMakeId);
          this.selectedVehicle.vehicleMake = this.vehicleMakes.find(x=>x.vehicleMakeId == this.selectedVehicle.vehicleMakeId)?.vehicleMake!;
          this.loadModels(this.selectedVehicle.vehicleMakeId);
        }

      }    
    }
    else {
      this.vehicleForm.controls.vehicleReg.setValue("");
      this.vehicleForm.controls.vehicleYear.setValue("");
      this.vehicleForm.controls.vehicleOdo.setValue("");
      this.vehicleForm.controls.vehicleMakeId.setValue(0);
      this.vehicleForm.controls.vehicleModelId.setValue(0);
      this.vehicleForm.controls.vehicleVariantId.setValue(0);

      this.vehicleForm.controls.vehicleReg.disable();
      this.vehicleForm.controls.vehicleYear.disable();
      this.vehicleForm.controls.vehicleOdo.disable();
      this.vehicleForm.controls.vehicleMakeId.disable();

      
      this.vehicleForm.controls.vehicleModelId.disable();
      this.vehicleForm.controls.vehicleVariantId.disable();
      

      this.vehicleModels=[];
      this.vehicleVariants=[];
      
    }

    //if the quote is completed, the values would be loaded, but now disable the form
    if (this.quote.isCompleted) {
      this.vehicleForm.disable();
    }
  }

  

  onSelectVehicle(value: any) {
    if (!this.quote.hasCustomer) {
      //get customerid
      let customerID = this.filteredVehicles.find(e => e.Vehicle_Customer_MappingId === value)?.customerId;
      //now first add the customer to the quote
      if (customerID) {
        this.isLoading = true;
        this.posService.selectCustomerToQuote(this.quote.quoteId, customerID).subscribe({
          next: val => {
            //update quote
            this.quote.custId = val.customerId;
            this.quote.custName = val.name;
            this.quote.custCell = val.cell;
            this.quote.custEmail = val.email;
            this.quote.custVATNumber = val.custVATNumber;
            this.quote.custDebtorAccount = val.custDebtorAccount;
          },
          error: err => {}
        });

      }      
      
    }
  this.quote.Vehicle_Customer_MappingId = value;

  this.updateVehicleForm();
  //update ODO
  
  this.quote.vehicleOdoId = this.selectedVehicle!.vehicleOdoId;

  
  this.quote.markDirty(false);

  //this.onRefreshQuote.emit(this.quote.quoteId);
    
    
    
      
    
  }

  selectedVehicleMake: number;
  onSelectVehicleMake(value: number) {
    if (this.selectedVehicle.vehicleMakeId !== value) {
      this.selectedVehicle.vehicleMakeId = value;
      this.selectedVehicle.vehicleMake = this.vehicleMakes.find(x=>x.vehicleMakeId == value)?.vehicleMake!;
      this.selectedVehicle.vehicleModelId = 0;
      this.selectedVehicle.vehicleVariantId = 0;
      this.vehicleForm.controls.vehicleModelId.setValue(0);
      this.vehicleForm.controls.vehicleVariantId.setValue(0);
      this.vehicleForm.controls.vehicleModelId.disable();
      this.vehicleForm.controls.vehicleVariantId.disable();
      this.loadModels(value);
      this.updateVehicle();
    }
    
  }

  selectedVehicleModel: number;
  onSelectVehicleModel(value: number) {
    if (this.selectedVehicle.vehicleModelId !== value) {
      this.selectedVehicle.vehicleModelId = value;
      this.selectedVehicle.vehicleModel = this.vehicleModels.find(x=>x.vehicleModelId == this.selectedVehicle.vehicleModelId)?.vehicleModel!;
      this.selectedVehicle.vehicleVariantId = 0;
      this.vehicleForm.controls.vehicleVariantId.setValue(0);
      this.vehicleForm.controls.vehicleVariantId.disable();
      this.loadVariants(value);
      this.updateVehicle();
    }
    
  }

  selectedVehicleVariant: number;
  onSelectVehicleVariant(value: number) {
    if (this.selectedVehicle.vehicleVariantId !== value) {
    this.selectedVehicle.vehicleVariantId = value;
    this.selectedVehicle.vehicleVariant = this.vehicleVariants.find(x=>x.vehicleVariantId == this.selectedVehicle.vehicleVariantId)?.vehicleVariant!;
    this.updateVehicle();}
  }

  unlinkVehicle() {
    this.quote.Vehicle_Customer_MappingId = 0;
    this.quote.vehicleOdoId = 0;
    this.quote.markDirty();
    this.selectedVehicle = new CustomerVehicleDetails();
    this.updateVehicleForm();
  }


}
