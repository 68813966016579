<!-- <div nz-row>
    <div nz-col [nzSpan]="8">
        <button nz-button (click)="clearMessage()">
            <span nz-icon nzType="arrow-left" nzTheme="outline"></span>
        </button>
    </div>
    <div nz-col [nzSpan]="16">
        {{waService.waConversation[0].name}}
    </div>
    
</div> -->
<head>
  <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0" />
</head>
<div style="height: calc(100vh - 120px); overflow: auto; padding-right: 20px; margin-right: -20px;" #scrollMe [scrollTop]="scrollMe.scrollHeight">
<nz-list *ngFor="let item of waService.filterArchive(appComponent.archiveToggle)" nzItemLayout="vertical" [nzLoading]="waService.isLoading" [ngClass]="[item.incoming === true ? 'incomingBg' : 'outgoingBg']">


  <!-- [ngClass]="[item.incoming === true ? 'incomingBg' : 'outgoingBg', item.statusId === 0 && item.incoming === true ? 'incomingBg': '' , item.statusId === 0 && item.incoming === false ? 'outgoingBg-archive' : '']" -->
    <nz-list-item>
      <nz-list-item-meta>
        <nz-list-item-meta-description>
          <span [innerHTML]="item.message">
            <span *ngIf="item.imageBase64 && item.imageBase64.length > 0 && item.imageBase64.startsWith('data:application')" style="padding-left: 15px;">
              <button nz-button nzType="primary" nzShape="circle" nz-tooltip="Download" (click)="downloadPdf(item.imageBase64, item.message)"><span nz-icon nzType="download" nzTheme="outline"></span></button>
            </span>
            
          </span>
          
          <div *ngIf="item.imageBase64 && item.imageBase64.length > 0" (click)="openImageModal(imageModalContent, item.imageBase64)" style="cursor:pointer;">
            <img *ngIf="item.imageBase64.startsWith('data:image')" width="272" alt="picture" src="{{item.imageBase64}}" />
            <span *ngIf="item.imageBase64.startsWith('data:video')">
              
              <video width="272" controls>
                
                <source type="video/mp4" src="{{item.imageBase64}}">
              </video>
              <br />Click for large player
            </span>
            
          </div>
          <div>
            <small>
              {{ item.date + 'Z' | date:'dd MMM HH:mm' }}
              <b>
                <span *ngIf="item.incoming === true">{{item.name}}</span>
                <span *ngIf="item.incoming === false">{{item.userName}}</span>
              </b>
              
            </small>
          </div>
          <span *ngIf="item.statusId === 0" style="float: left; padding-top: 16px;" class="material-symbols-outlined" nz-tooltip [nzTooltipTitle]="'Archived message'">archive</span>
          <ul nz-list-item-actions *ngIf="item.incoming === false">
            <nz-list-item-action *ngIf="item.dateDelivered">
              <span nz-icon nzType="delivered-procedure" nzTheme="outline" nz-tooltip [nzTooltipTitle]="formatDateDelivery(item)"></span>
            </nz-list-item-action>
            <nz-list-item-action *ngIf="item.dateRead">              
              <span nz-icon nzType="read" nzTheme="outline" nz-tooltip [nzTooltipTitle]="formatDateRead(item)"></span>
            </nz-list-item-action>
            <nz-list-item-action *ngIf="item.error">
              <span nz-icon nzType="warning" nzTheme="outline" nz-tooltip [nzTooltipTitle]="item.error"></span>
            </nz-list-item-action>
          </ul>
          <!-- <ul nz-list-item-actions>
            <nz-list-item-action *ngIf="item.statusId === 0">
              <span class="material-symbols-outlined" nz-tooltip [nzTooltipTitle]="'Archived message'">archive</span>
            </nz-list-item-action>
          </ul> -->
        </nz-list-item-meta-description>
        <!-- <nz-list-item-meta-title style="background-color: red;">
          <span *ngIf="item.incoming === true" style="font-size: 0.7rem;">{{item.name}}</span>
          <span *ngIf="item.incoming === false" style="font-size: 0.7rem;">{{item.userName}}</span>
          
        </nz-list-item-meta-title> -->
       
      </nz-list-item-meta>
      <!-- {{item.message}}
      <span *ngIf="item.imageBase64 && item.imageBase64.length > 0">
        <img src="{{item.imageBase64}}">

      </span> -->
    </nz-list-item>
    
  </nz-list>
  <ng-template #prefixIconButton>
    <nz-upload [nzBeforeUpload]="sendImage.bind(this)" [nzFileType]="">
      <a ><span nz-icon nzType="sf:attachplus" nz-tooltip="Send Image/PDF"></span></a>
    </nz-upload>
    
  </ng-template>
  <!-- MEssage is open for all messages -->
  <div *ngIf="waService.loadedConversation.conversationStateId===1">
    <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" [nzAddOnBefore]="prefixIconButton">
      <input type="text" nz-input placeholder="Reply" [(ngModel)]="messageText" (keyup.enter)="sendMessage()" />
    </nz-input-group>
    <ng-template #suffixIconButton>
      <button nz-button nzType="primary" nzSearch (click)="sendMessage()" [nzLoading]="waService.isLoading"><span nz-icon nzType="send"></span></button>
      
    </ng-template>
  </div>
  <!-- Template was sent less than 24 hours ago, but no reply from customer -->
  <div *ngIf="waService.loadedConversation.conversationStateId===2">
    <nz-alert nzType="warning" nzMessage="Message was sent to customer. Please allow 24 hours for a reply before following up." nzShowIcon></nz-alert>
    
  </div>
  <!-- No reply, but tempalte send 24-72 hours ago, so we can send another template -->
  <div *ngIf="waService.loadedConversation.conversationStateId===3">
    <nz-alert nzType="success" nzMessage="No response received from customer in 24 hours. You can send any of the following pre-approved messages:" nzShowIcon></nz-alert>
    <br />
    <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select a pre approved message" [(ngModel)]="selectedTemplate" style="width:80%; margin-right: 10px;">
      <nz-option *ngFor="let item of waService.loadedConversation.templates" [nzLabel]="item.description" [nzValue]="item"></nz-option>
    </nz-select>
    <button nz-button nzType="primary" nzSearch (click)="sendTemplateMessage()" [nzLoading]="waService.isLoading"><span nz-icon nzType="send"></span></button>
  </div>
  <!-- Thread closed -->
  <div *ngIf="waService.loadedConversation.conversationStateId===4">
    <nz-alert nzType="warning" nzMessage="Please wait for customer reply before sending any more messages." nzShowIcon></nz-alert>
    
  </div>



</div>
  <!-- <nz-modal [nzVisible]="isImageOpen" [nzWidth]="'90%'" nzTitle="WhatsApp Image" (nzOnCancel)="isImageOpen = false" [nzFooter]="null"
  [nzContent]="imageModalContent" [nzComponentParams]="{value:imageBase64}">
    
  </nz-modal> -->

  <ng-template #imageModalContent let-params>
    <div style="text-align: center;">
      <img alt="picture" src="{{params.value }}" style="max-width: 100%;" *ngIf="params.value.startsWith('data:image')" />
      <video width="100%" controls *ngIf="params.value.startsWith('data:video')">
                
        <source type="video/mp4" src="{{params.value}}">
      </video>
    </div>
  </ng-template>
