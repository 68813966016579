import { Component, Input } from '@angular/core';
import { Video } from 'src/app/models/video';
import { HelpService } from '../help.service';
import { AuthService } from 'src/app/auth.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModalService } from 'src/app/widgets/modal-video/modal.service';

@Component({
  selector: 'app-video-widget',
  templateUrl: './video-widget.component.html',
  styleUrls: ['./video-widget.component.less']
})
export class VideoWidgetComponent {
  @Input() videoId : number;
  @Input() isButton : Boolean = false;
  
  showFailtoload:boolean = false;
  viewModal:boolean = false;
  watchingVideo:Video = new Video();

  constructor(private helpService: HelpService,private authService: AuthService,private notification:NotificationService,private sanitizer: DomSanitizer, private modalService: ModalService) { }

  ngOnInit(): void 
  {
     //this.fetchVideo();
  }

  fetchVideo()
  {
    this.helpService.getVideoById(this.videoId).subscribe({
      next: res => {
        this.watchingVideo = res; 
      },
      error: _ => {
        this.showFailtoload = true;
      }
    }) 
  }

  watch() {
    if (this.watchingVideo.url == null || this.watchingVideo.url == "") {
      this.helpService.getVideoById(this.videoId).subscribe({
        next: res => {
          this.watchingVideo = res; 
          this.helpService.updatelog(this.watchingVideo.videoId).subscribe(res => {
            this.modalService.openVideoModal(this.watchingVideo.url, this.watchingVideo.title);
          });
        },
        error: _ => {
          this.showFailtoload = true;
        }
      }) 
    }
    else {
      this.helpService.updatelog(this.watchingVideo.videoId).subscribe(res => {
        this.modalService.openVideoModal(this.watchingVideo.url, this.watchingVideo.title);
      });
    }
    
   
  }

  getSafeVideoUrl(): SafeResourceUrl{
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.watchingVideo.url);
  }
}

// watch()
// {
//   this.helpService.updatelog(this.watchingVideo.videoId).subscribe(res => {  
//     window.open(this.watchingVideo.url); 
//    });
// }
