<div class="container-fluid">
  <div class="row ">
    <div class="col-lg-2">
    </div>
    <div class="col-lg-8 " style="text-align: center;"  >
      <h4 class="color"><b>Searches Dashboard</b></h4>
    </div>
    <div class="col-lg-2">
    </div>
  </div>
</div>

<!-- <nz-spin [nzSpinning]="isLoading" style="position: fixed; top: 50%; width: 100%;" ></nz-spin> -->
<nz-spin [nzSpinning]="isLoading">
  <div class="container-fluid col-lg-11">
    <div *ngIf="!isLoading">
      <nz-spin [nzSpinning]="filterLoading">
       <div>
            <div style="display: block">
              <canvas id="sizeChart" baseChart
                [data]="barChartData"
                [options]="barChartOptions"
                [plugins]="barChartPlugins"
                [type]="barChartType">
              </canvas>
              <canvas id="centreChart" baseChart
              [data]="barChart2Data"
              [options]="barChart2Options"
              [plugins]="barChart2Plugins"
              [type]="barChart2Type">
            </canvas>

            </div>
        </div>
       </nz-spin>

      <div class='text-center bg-1'>
        <a nz-dropdown [nzDropdownMenu]="menu1">
          Time Frame: {{tfs}}
          <i nz-icon nzType="down"></i>
        </a>
        <nz-dropdown-menu #menu1="nzDropdownMenu">
          <ul nz-menu nzSelectable>
            <li nz-menu-item (click)="changeTimeFrame(-1)">24 hours</li>
            <li nz-menu-item (click)="changeTimeFrame(-7)">1 week</li>
            <li nz-menu-item (click)=changeTimeFrame(-30)>1 month</li>
          </ul>
        </nz-dropdown-menu>
        <br>
        <button nz-button nzType="primary" (click)="resetFilters()">Clear filters</button>
        <br>

          <nz-table #filterTable2 [nzData]="tableData" [nzFrontPagination]="false">
            <thead>
              <tr>
                <th
                  *ngFor="let column of listOfColumns"
                  [nzSortOrder]="column.sortOrder"
                  [nzSortFn]="column.sortFn"
                  [nzSortDirections]="column.sortDirections"
                  [nzFilterMultiple]="column.filterMultiple"
                  [nzFilters]="column.listOfFilter"
                  [nzFilterFn]="column.filterFn"
                  (nzFilterChange)="column.filterCallback($event, column.name)"
                >
                  {{ column.name }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of filterTable2.data">
                <td>{{ data.Client }}</td>
                <td>{{ data.Width }}</td>
                <td>{{ data.Profile }}</td>
                <td>{{ data.Rim }}</td>
                <td>{{ data.Searches }}</td>
                <td>{{ data.Sales }}</td>
                <td>{{ data.Units }}</td>
              </tr>
            </tbody>
          </nz-table>
        </div>
    </div>
  </div>
</nz-spin>

    