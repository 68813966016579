import { Pricelist , ClientEdit , ClientSettings, ClientReceipt, ClientPricing , ClientLogistics, ClientCart, SlaSlots, SlaSlot, SlaOptions } from '../../models/client';
import { NotificationService } from '../../shared/notification.service';
import { ActivatedRoute, Router} from '@angular/router';
import { ClientService } from './../client.service';
import { AuthService } from 'src/app/auth.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from 'src/app/models/user';
import { Globals } from 'src/app/shared/globals';
import { Subscription } from 'rxjs';



@Component({
  selector: 'app-clientedit',
  templateUrl: './clientedit.component.html',
  styleUrls: ['./clientedit.component.less']
})
export class ClienteditComponent implements OnInit, OnDestroy {

  //Global Var
  Logistics: ClientLogistics = new ClientLogistics();
  Settings: ClientSettings = new ClientSettings();
  CartDetails: ClientCart = new ClientCart();
  Receipt: ClientReceipt = new ClientReceipt();
  Pricing: ClientPricing = new ClientPricing();
  customer: ClientEdit = new ClientEdit();
  pricelist: Pricelist = new Pricelist ();
  totalspent:number = 0;
  totalpaid: number = 0;
  buyerId : number = 0;
  salesmen: User[] = [];

  slaTimes: SlaOptions[]= [];;
  cutOffTimes: string[] = Globals.consts.CutOffTimes;
  expectedTimes: string[] = Globals.consts.ExpectedDeliveryTimes;
  SlaSlots:SlaSlots[]= [];
  clientSlaSlots: SlaSlots[]= [];
  isLoading = false;

  paramsSub: Subscription

  isActive = false;


  constructor(public authService: AuthService,private clientService : ClientService,private activerouter:ActivatedRoute,private router: Router, private notification: NotificationService) { }
  ngOnDestroy(): void {
    this.paramsSub.unsubscribe();
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.paramsSub = this.activerouter.queryParams.subscribe(parms =>
      {
        this.buyerId = Number(parms["buyerId"]);
        this.reset();
        
        this.clientService.editClients(parms["buyerId"]).subscribe((res:any) =>
          { 
              this.totalspent = res.clients.totalSpent;
              this.totalpaid = res.clients.totalPaid;
              this.customer = res.clients.customerdata[0] as ClientEdit;
              this.pricelist = res.pricelistdata[0] as Pricelist;
              this.totalpaid = res.clients.totalPaid;
              this.totalspent = res.clients.totalSpent;
              this.clientSlaSlots = res.clients.SlaSlots as SlaSlots[];
              this.slaTimes = res.clients.SlaOptions as SlaOptions[];
              // Map Settings

              this.Settings.debtorAcc = this.customer.debtorAcc;
              this.Settings.sellingProcess  =this.customer.state;
              this.Settings.creditlimit = this.customer.creditlimit;
              this.Settings.creditorAcc =this.customer.creditorAcc;
              this.Settings.activeStatus = this.customer.active;
              this.Settings.replenishStatus = this.customer.replenish;
              this.Settings.buyerId = this.buyerId;
              this.Settings.creditCheck = this.customer.creditCheck;

        
              // Map Pricing
              this.Pricing.pricelistId= this.customer.plid;
              this.Pricing.pricelistId_Repleishment= this.customer.plid_rep;
              this.Pricing.minOrder= this.customer.minorder;
              this.Pricing.minOrderFee= this.customer.minorderfee;
              this.Pricing.bulkDiscount= this.customer.discntblk;
              this.Pricing.sbulkDiscount= this.customer.discntblksper;
              this.Pricing.repDiscount = this.customer.discntreplenish;
              this.Pricing.buyerId = this.buyerId;

             //Map Logistics
             this.Logistics.logisticsSlaId = this.customer.logislaid;
             this.Logistics.deliveryFee= this.customer.deliveryfee;
             this.Logistics.sohMax=this.customer.sohMax;
             this.Logistics.termscon= this.customer.tc;
             this.Logistics.buyerId = this.buyerId;
             this.Logistics.connectionId = this.customer.connectionId;
             this.Logistics.customSlaActive = this.customer.customSlaActive;
             this.Logistics.customSlaTimes = [];
             this.Logistics.removeSlaTimes= [];

            //Map CartDetails
            this.CartDetails.extref = this.customer.r_ext_ref;
            this.CartDetails.deliveryInfo= this.customer.r_delivery;
            this.CartDetails.salesmanName= this.customer.r_salesman;
            this.CartDetails.vehicleRegistration= this.customer.r_v_reg;
            this.CartDetails. vehicleMake=this.customer.r_v_make;
            this.CartDetails.vehicleMilage=this.customer.r_milage;
            this.CartDetails.commentBox=this.customer.r_txt;
            this.CartDetails.isDeliveryOptionShowing= this.customer.isDeliveryOptionShowing;
            this.CartDetails.buyerId=this.buyerId;
            

            //Map Sla Details
            let slot = new SlaSlots();
            slot.clientSlaSettingId = this.customer.connectionId;
            slot.cutOffTime = this.cutOffTimes;
            slot.expectedDelivery = this.expectedTimes;
            slot.selectedCuttOffslot = 0;
            slot.selectedDeliveryslot = 1;
            this.SlaSlots.push(slot);

            if(this.customer.customSlaActive)
            {
              if(this.clientSlaSlots.length > 0)
              this.SlaSlots = [];
              this.loadCustomSlots();
            }
            this.isLoading = false;
          },
          error => {
            this.notification.handleError(error);
          });
        this.clientService.getLinkedUsers(parms["buyerId"]).subscribe(res => { this.salesmen = res as [User]; })
    });
  }

  reset()
  {
    this.Logistics = new ClientLogistics();
    this.Settings=  new ClientSettings();
    this.CartDetails=  new ClientCart();
    this.Receipt=  new ClientReceipt();
    this.Pricing= new ClientPricing();
    this.customer=  new ClientEdit();
    this.pricelist= new Pricelist ();
    this.totalspent= 0;
    this.totalpaid=  0;
    //this.buyerId = 0; 
    this.salesmen = [];
    this.SlaSlots = [];
    this.clientSlaSlots = [];
    //we need to set the buyerid to the param passed
    this.Receipt.buyerId = this.buyerId;
    
  }

  
  change(type: string)
  {
    if(type == 'active'){
      this.Settings.activeStatus = !this.Settings.activeStatus;
    }

    else if(type == 'replenishment'){
      this.Settings.replenishStatus =  !this.Settings.replenishStatus;
    }

    else if (type == 'creditCheck')
    {
      this.Settings.creditCheck = !this.Settings.creditCheck;
    }
  }

  toggleCustomSla()
  {
    this.customer.customSlaActive = !this.customer.customSlaActive;
    if(this.SlaSlots.length == 0)
    {
      if(this.customer.customSlaActive && this.clientSlaSlots.length > 0)
      {
          this.loadCustomSlots();
      }
      // else
      // {
      //   this.addslot();
      // }
    }
  }

  loadCustomSlots()
  {
    for(var i = 0; i < this.clientSlaSlots.length; i++)
    {
      let slot = new SlaSlots();
      let cutOff = this.clientSlaSlots[i].cutOffTime as any as string;
      let delivery = this.clientSlaSlots[i].expectedDelivery as any as string;
      slot.clientSlaSettingId = this.clientSlaSlots[i].clientSlaSettingId;
      slot.cutOffTime = this.cutOffTimes;
      slot.expectedDelivery = this.expectedTimes;
      slot.selectedCuttOffslot = this.cutOffTimes.findIndex(s => s.toLowerCase() == cutOff.toLowerCase());
      slot.selectedDeliveryslot =  this.expectedTimes.findIndex(s => s.toLowerCase() == delivery.toLowerCase());
      this.SlaSlots.push(slot);
    }
  }

  addslot()
  {
    let slot = new SlaSlots();
    slot.clientSlaSettingId = this.customer.connectionId;
    slot.cutOffTime = this.cutOffTimes;
    slot.expectedDelivery = this.expectedTimes;
    slot.selectedCuttOffslot = 0;
    slot.selectedDeliveryslot = 1;
    this.SlaSlots.push(slot);
  }

  removeslot(slot:SlaSlots)
  {

      //Check if in client slots
      for(var i =0; i < this.clientSlaSlots.length; i ++)
      {
        if(slot.clientSlaSettingId == this.clientSlaSlots[i].clientSlaSettingId)
        {
          let RemoveSlot = new SlaSlot();
          RemoveSlot.clientSlaSettingId =  this.clientSlaSlots[i].clientSlaSettingId;
          this.Logistics.removeSlaTimes.push(RemoveSlot);
        }
      }
      this.SlaSlots.splice(this.SlaSlots.findIndex(x=> x.clientSlaSettingId ==  slot.clientSlaSettingId),1);
      if(this.SlaSlots.length == 0)
      {
        this.customer.customSlaActive = false;
        this.Logistics.removeSlaTimes = [];
      }
  }

  savesettings()
  {
    this.clientService.savesettings(this.Settings).subscribe(res =>
      {
        this.notify("Settings");
      },
      error => {
        this.notification.handleError(error);
      });
  }

  addreciept()
  {
    this.clientService.addreciept(this.Receipt).subscribe(res =>
      {
         this.notification.showSuccess("Successfully Added");
         this.ngOnInit();
      },
      error => {
        this.notification.handleError(error);
      });
  }

  savepricing()
  {
    this.clientService.savepricing(this.Pricing).subscribe(res =>
      {
        this.notify("Pricing Settings");
      },
      error => {
        this.notification.handleError(error);
      });

  }

  savelogistics()
  {
    this.Logistics.customSlaActive = this.customer.customSlaActive;
    this.Logistics.customSlaTimes = [];
    for(var i = 0; i < this.SlaSlots.length; i++)
    {
      let Slot = new SlaSlot();
      Slot.clientSlaSettingId = this.SlaSlots[i].clientSlaSettingId;
      Slot.cutOffTime = this.cutOffTimes[this.SlaSlots[i].selectedCuttOffslot];
      Slot.expectedDelivery = this.expectedTimes[this.SlaSlots[i].selectedDeliveryslot];
      this.Logistics.customSlaTimes.push(Slot);
    }
    this.clientService.savelogistics(this.Logistics).subscribe(res =>
      {
        this.notify("Logistics Settings");
      },
      error => {
        this.notification.handleError(error);
    });
  }

  saveordersettings(setting:any)
  {
    this.CartDetails[setting] = !this.CartDetails[setting];
    this.clientService.saveordersettings(this.CartDetails).subscribe(res =>
      {
        this.notify("Order Settings");
      },
      error => {
        this.notification.handleError(error);
      });
  }

  addClientLink(userId,checkStatus){

    if(checkStatus)
    {
    this.clientService.addLinkedUsers(this.buyerId,userId).subscribe(res =>
      {
         this.notification.showSuccess("Successfully Added");
         this.ngOnInit();
      },
      error => {
        this.notification.handleError(error);
      });
    }
    else
    {
      this.deleteClientLink(userId);
    }
  }

  deleteClientLink(userId){

    this.clientService.deleteLinkedUsers(this.buyerId,userId).subscribe(res =>
      {
         this.notification.showSuccess("Successfully Deleted");
         this.ngOnInit();
      },
      error => {
        this.notification.handleError(error);
      });
  }

  notify(tab:string)
  {
    this.notification.showSuccess(tab+" Successfully Updated");
    this.ngOnInit();
  }

}
