import { Component, OnDestroy, OnInit } from '@angular/core';
import { WhatsappService } from '../whatsapp.service';
import { WAArchive, WAMessageGroup } from 'src/app/models/whatsapp';
import { SearchService } from 'src/app/search/search.service';
import { CustomerService } from 'src/app/customers/customer.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { Router } from '@angular/router';
import { SearchObj } from 'src/app/models/search-obj';
import { AppComponent } from 'src/app/app.component';
import { CustomerList, CustomerWhatsApp } from 'src/app/models/customer';

@Component({
  selector: 'app-wa-inbox',
  templateUrl: './wa-inbox.component.html',
  styleUrls: ['./wa-inbox.component.less']
})
export class WaInboxComponent implements OnInit, OnDestroy {

  isCustomerListModalVisible = false;
  customerList: CustomerList [] = [];
  showOnlyUnread = true;

  constructor(public waService: WhatsappService, private searchService: SearchService, private notification : NotificationService, private router: Router, private appComponent: AppComponent, private customerService: CustomerService) { 

  }
  ngOnDestroy(): void {
    localStorage.setItem("isViewingOnlyUnreadWhatsapp", String(this.showOnlyUnread));
  }

  ngOnInit(): void {
    this.showOnlyUnread = localStorage.getItem("isViewingOnlyUnreadWhatsapp") ? localStorage.getItem("isViewingOnlyUnreadWhatsapp") === 'true' : true;
  }

  loadMessages(cell: string) {
    
    this.waService.GetConversation(cell);

  }

  markAsArchive(item: WAMessageGroup) {
    let archiveItem = new WAArchive();
    archiveItem.cell = item.cell;
    archiveItem.clientId = item.clientId;

    this.waService.setArchive(archiveItem);
  }

  getCustomerList(item: WAMessageGroup){
    this.customerService.getCustomerList(item.cell?.slice(2,11).padStart(10, '0')).subscribe(res =>
      {
        this.customerList = [];
        this.customerList = res as CustomerList [];
        
        for(var i = 0; i < this.customerList.length; i++){
          this.customerList[i].expand = false;
        }

      // Checks if there is more than one customer liked to a cellphone # and if there is only 1 connect immediately to customer page
        if(this.customerList.length === 1) 
          this.getCustomerDetails(this.customerList[0]);
        else 
          this.isCustomerListModalVisible = true;
      },
      error =>
      {
        this.notification.handleError(error);
      });
  }

  getCustomerDetails(item: CustomerList){
    let obj = new CustomerWhatsApp();

    obj.customer = item.customerId;
    obj.sendTo = 'Customer Home'
    obj.vreg = '';

    this.appComponent.isWhatsappDrawerVisible = false;
    this.isCustomerListModalVisible = false;
    
    localStorage.removeItem("customerData");
    localStorage.setItem('customerData', JSON.stringify(obj));

    if(this.router.url !== '/customer') {
      this.router.navigate(['/customer']);
    }
    else window.location.reload();
  }
}
