import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/shared/notification.service';
import { ActivatedRoute, Router} from '@angular/router';
import { SettingsService } from '../../settings.service';
import { Subscription } from 'rxjs';
import { CustomerUpdate } from 'src/app/models/settings';
import { CustomerService } from 'src/app/customers/customer.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NewPosCustomer } from 'src/app/models/pos';
import { Globals } from 'src/app/shared/globals';

@Component({
  selector: 'app-customer-edit',
  templateUrl: './customer-edit.component.html',
  styleUrls: ['./customer-edit.component.less']
})
export class CustomerEditComponent implements OnInit {

  constructor(private customerService : CustomerService,private settingsService : SettingsService,private route:ActivatedRoute,private router: Router, private notification: NotificationService) { }

  paramsSub: Subscription
  customerId: number = 0;
  isLoading = false;
  customer: CustomerUpdate = new CustomerUpdate();
  showVehicles = false;
  UpdateCustomerForm: FormGroup = new FormGroup({
    custName: new FormControl('', Validators.required),
    custCell: new FormControl('', Validators.compose([Validators.pattern('^[+0-9]+$'), Validators.minLength(10), Validators.maxLength(13)])),
    custEmail: new FormControl('', [Validators.email,/* Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) */]),
    custDebtorAcc:new FormControl(null),
    custVATNumber:new FormControl(null)
  });

  

  ngOnInit(): void {
    this.paramsSub =  this.route.params.subscribe(params => {
      this.isLoading = true;  
      this.settingsService.editCustomer(+params['customerId']).subscribe((res:any )=>
      {
        this.customerId = parseInt(params['customerId']);
        this.customer = res;
        if(res.vehicles.length > 0)
        {
          this.showVehicles = true;
        }
        this.UpdateCustomerForm.controls["custName"].setValue(this.customer.name);
        this.UpdateCustomerForm.controls["custCell"].setValue(this.customer.cell === 'None' ? '' : this.customer.cell ); 
        this.UpdateCustomerForm.controls["custEmail"].setValue(this.customer.email === 'None' ? '' : this.customer.email); 
        this.UpdateCustomerForm.controls["custDebtorAcc"].setValue(this.customer.debtorAcc); 
        this.UpdateCustomerForm.controls["custVATNumber"].setValue(this.customer.VATNumber); 
        
        this.isLoading = false;
      },
      error => 
      {
        this.notification.handleError(error);
      }); 
    });
  }

  updateCustomer()
  {
    Object.values(this.UpdateCustomerForm.controls).forEach(control => {
      if (control.invalid) {
        control.markAsDirty();
        control.updateValueAndValidity({ onlySelf: true });
      }
    });
    

    if (this.UpdateCustomerForm.valid) 
    {

      // This is COMPLETELY unneccesary. Angular already does the validation. Check the page. It is done on the fly and when clicking the button. NO NEED for this,
      //    which in any case does not work anyway.
      // if(!Globals.funcs.checkEmail(this.UpdateCustomerForm.value.custEmail)== true)
      // {
      //   this.notification.showError("Email Pattern Not Permitted Please Re Enter");
      //   return;
      // }
 

      let cust: NewPosCustomer = new NewPosCustomer()
      cust.customerId = this.customer.customerId;
      cust.name = this.UpdateCustomerForm.value.custName;
      cust.cell = this.UpdateCustomerForm.value.custCell;
      cust.email = this.UpdateCustomerForm.value.custEmail;
      cust.debtorAcc = this.UpdateCustomerForm.value.custDebtorAcc;
      cust.VATNumber = this.UpdateCustomerForm.value.custVATNumber;
      this.isLoading = true;
      this.customerService.updateCustomer(cust).subscribe(res =>
        {
          this.notification.showSuccess("Updated Successfully");
          this.isLoading = false;
        },
        error => 
        {
          this.notification.handleError(error);
        });



    }

  }

  changeVreg(obj)
  {
    for (var vehicle of this.customer.vehicles) 
    {
      if (vehicle.Id == obj.parentElement.childNodes[1].innerText) 
      {
        vehicle.vreg = obj.value;
      }
    }
  }
  
  changeSize(obj)
  {
    for (var vehicle of this.customer.vehicles) 
    {
      if (vehicle.Id == obj.parentElement.childNodes[1].innerText) 
      {
        vehicle.size = obj.value;
      }
    }
  }
  
  onVehiclesUpdate()
  {    
      this.isLoading = true;
      for(var i = 0; i < this.customer.vehicles.length ; i++)
      {
        this.customerService.updateVehicle(this.customer.vehicles[i]).subscribe(res => 
        {

        });
      }
      this.isLoading = false;
      this.notification.showSuccess("Updated Successfully");  

  }

  openCustomer()
  {
    //Set local storage Customer Then Go 
    let customer = { customer:this.customerId, sendTo: 'Customer Home', vreg : 'All' };
    localStorage.removeItem("customerData");
    localStorage.setItem('customerData', JSON.stringify(customer));
    this.router.navigate(['/customer']);
  }

}
