import { Component, HostListener, OnInit } from '@angular/core';

import { UnMappedStock, MappedStock, CustomStockToBeMapped, StockType } from '../models/client-mappings';
import { ClientStockMappingsService } from './client-stock-mappings.service';
import { NotificationService } from '../shared/notification.service';
import { AdminMappingService } from '../admin-stock-mappings/admin-mappings.service';

@Component({
  selector: 'app-client-stock-mappings',
  templateUrl: './client-stock-mappings.component.html',
  styleUrls: ['./client-stock-mappings.component.less']
})
export class ClientStockMappingsComponent {

  loadingStock = false;
  isMapping = false;

  advancedSearchInput = '';
  advancedSearchInputMapped = '';

  unmappedStock: UnMappedStock[] = [];
  unmappedStockTemp: UnMappedStock[] = [];
  unmappedStockVeryTemp: UnMappedStock[] = [];

  mappedStock: MappedStock[] = [];
  mappedStockTemp: MappedStock[] = [];
  mappedStockVeryTemp: MappedStock[] = [];

  // MSFID For certain stock items
  batteryMSFID = 27128;
  brakeMSFID = 27129;
  shockMSFID = 27130;
  serviceMSFID = 27127;

  tyreStockTypeId = 1;
  serviceStockTypeId = 2;
  batteryStockTypeId = 3;
  brakeStockTypeId = 4;
  otherStockTypeId = 7;
  shockStockTypeId = 9;
  tradeInStockTypeId = 10;

  buttonConfigs = [
    { label: 'Battery', msfId: this.batteryMSFID, stockTypeId: this.batteryStockTypeId },
    { label: 'Brake', msfId: this.brakeMSFID, stockTypeId: this.brakeStockTypeId },
    { label: 'Shock', msfId: this.shockMSFID, stockTypeId: this.shockStockTypeId },
    { label: 'Service', msfId: this.serviceMSFID, stockTypeId: this.serviceStockTypeId }
  ];

  columnList= [
    {
      title: 'Stock on Hand',
      sortOrder: 'descend',
      sortFn: (a: UnMappedStock, b: UnMappedStock) => (a.soh ?? 0) - (b.soh ?? 0),
      sortDirections: ['ascend', 'descend', null],
    }
  ]

  mappedStockRetrieved = false;
  

  constructor( 
    public clientMappingService: ClientStockMappingsService,
    private notification: NotificationService,
    public adminStockMappingService: AdminMappingService
  ) { }

  ngOnInit() {
    this.getUnmappedStock();
    this.getMappedStock();
    this.mappedStockRetrieved = true;
  }

  getUnmappedStock() {
    this.loadingStock = true;
    this.clientMappingService.getUnmappedStock().subscribe((res: UnMappedStock[]) => {
      this.unmappedStockTemp = res;
      this.unmappedStock = this.unmappedStockTemp;
      this.loadingStock = false;
    }, error => {
      this.notification.handleError(error);
      this.loadingStock = false;
    })
  }

  getMappedStock(){
    this.loadingStock = true;
    this.clientMappingService.getMappedStock().subscribe((res: MappedStock[]) => {
      this.loadingStock = false;
      this.mappedStockTemp = res.filter(stock => stock.msfStockCode !== stock.locationStockCode); //remove items that have matching msf stock code and location stock code - Requierement 5223;
      this.mappedStock = this.mappedStockTemp;
    }, error => {
      this.notification.handleError(error);
      this.loadingStock = false;
    })
  }
  openMappingModal() {
    this.clientMappingService.modalVisibility = true;
  }

  mapCustomStock(event: MouseEvent, unmappedStockItem: UnMappedStock, msfIdOfExistingStock: number, stockTypeId: number, mapType: number) {
    event.stopPropagation();
    this.isMapping = true;
    if (this.mappedStock.some(stock => stock.msfStockCode === unmappedStockItem.stockCode)) {
      this.notification.showInfo("This stock item is already mapped.");
      this.isMapping = false;
      return;
    }
    const customStockItem : CustomStockToBeMapped={
      clientId: unmappedStockItem.clientId,
      msfid: msfIdOfExistingStock,
      stockCode: unmappedStockItem.stockCode,
      description: unmappedStockItem.description,
      stockTypeId: stockTypeId,
      soh: unmappedStockItem.soh,
      cost: unmappedStockItem.cost
    }

    this.clientMappingService.mapCustomStock(customStockItem).subscribe(res => {
      this.notification.showSuccess('Stock mapped successfully');
      this.refreshUnmappedTable(unmappedStockItem);
      this.isMapping = false;
    }, error => {
      this.notification.handleError(error);
      this.isMapping = false;
    })
  }

  switchToMapped() {
    if (!this.mappedStockRetrieved) {
    this.getMappedStock();
    }
    this.mappedStockRetrieved = true;
  }
  deleteMapping(mappedItem: MappedStock) {
    this.clientMappingService.deleteMapping(mappedItem).subscribe(res => {
      this.notification.showSuccess("Mapping Deleted Successfully");
      this.refreshMappedStock(mappedItem);
    }, error => {
      this.notification.handleError(error);

    });
  }

  advancedSearch(input : string){
    this.unmappedStockVeryTemp = this.unmappedStockTemp;
    if (input.length > 2){
    this.unmappedStockVeryTemp = this.unmappedStockVeryTemp.filter(stock => 
      (stock.description.toLowerCase().includes(input.toLowerCase())) ||
      (stock.stockCode.toLowerCase().includes(input.toLowerCase()))
    );
    this.unmappedStock = this.unmappedStockVeryTemp;
  }
    if (input === "") {
        this.unmappedStock = this.unmappedStockTemp;
    }
  }

  advancedSearchMapped(input : string){
    this.mappedStockVeryTemp = this.mappedStockTemp;
    if (input.length > 2){
    this.mappedStockVeryTemp = this.mappedStockVeryTemp.filter(stock => 
      (stock.msfStockCode.toLowerCase().includes(input.toLowerCase())) ||
      (stock.msfDescription.toLowerCase().includes(input.toLowerCase())) 
    );
    this.mappedStock = this.mappedStockVeryTemp;
  }
  
    if (input === "") {
        this.mappedStock = this.mappedStockTemp;
    }
  }

  public refreshUnmappedTable ( unmappedStockItem: UnMappedStock){
    this.unmappedStockTemp = this.unmappedStockTemp.filter(item => item.stockCode !== unmappedStockItem.stockCode);
    this.unmappedStock = this.unmappedStockTemp.slice(); // refresh the filtered stock array

  }

  // filterUnmappedStock() {
  //   return this.unmappedStock.filter(stock => stock.mapped === 0 || stock.mapped === 6);
  // }

  refreshMappedStock(mappedItem: MappedStock) {
    const index = this.mappedStockTemp.findIndex(item => item.mappingId === mappedItem.mappingId);
    if (index !== -1) {
        this.mappedStockTemp.splice(index, 1);
        this.mappedStockTemp = this.mappedStockTemp.slice();
        this.mappedStock = this.mappedStockTemp;
    }
  }

  unmapStock(stockItem: UnMappedStock) {
    this.loadingStock = true;
    stockItem.mapped = 0;
    this.adminStockMappingService.reUnmapStock(stockItem).subscribe(res => {
      this.notification.showSuccess("Stock Unmapped Successfully");
      this.refreshUnmappedTableAdd(stockItem);
      this.loadingStock = false;
    }, error => {
      this.notification.handleError(error);
      this.loadingStock = false;
    });
  }

  refreshUnmappedTableAdd(stock: UnMappedStock) {
    const index = this.unmappedStockTemp.findIndex(item => item.stockCode === stock.stockCode);
    if (index !== -1) {
        this.unmappedStockTemp[index] = stock;
        this.unmappedStockTemp = [...this.unmappedStockTemp]; // refresh the unmapped stock array
        this.unmappedStockTemp = this.unmappedStockTemp;
    }
  }

  mapUnmappableStock(event: MouseEvent, unmappedStockItem: UnMappedStock, msfIdOfExistingStock: number, stockTypeId: number, mapType: number) {
    event.stopPropagation();
    this.isMapping = true;
    const customStockItem: CustomStockToBeMapped = {
      clientId: unmappedStockItem.clientId,
      description: unmappedStockItem.description,
      msfid: msfIdOfExistingStock,
      stockCode: unmappedStockItem.stockCode,
      stockTypeId: stockTypeId
    };
    this.adminStockMappingService.mapUnmappableStock(customStockItem).subscribe(res => {
      this.notification.showSuccess("Changes saved successfully");
      
      this.refreshNatMinmsfTable(unmappedStockItem, mapType);
      this.isMapping = false;
    }, error => {
      this.notification.handleError(error);
      this.isMapping = false;
    });
  }

  refreshNatMinmsfTable(unmappedStockItem: UnMappedStock, mapType: number) {
    const index = this.unmappedStockTemp.findIndex(item => item.stockCode === unmappedStockItem.stockCode);
    if (index !== -1) {
        this.unmappedStockTemp[index].mapped = mapType;
        this.unmappedStockTemp = [...this.unmappedStockTemp]; // refresh the unmapped stock array
        this.unmappedStockTemp = this.unmappedStockTemp;
    }
  }
}
