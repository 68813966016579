import { SlaSlot } from "./client"


export class TyreMeta {
    manufacturerLink: string = ""
    dryPerformance: number = 0
    handligPerformance: number = 0
    wetPerformance: number = 0
    comfortPerformance: number = 0
    treadPerformance: number = 0
    rating: number = 0
    description: string = ""
    name!: string
    image: string = ""
    imgBase64: string = ""
    runflat: boolean = false
    oe: string = "-"
    load_rating :string = "-"
    speed_rating :string = "-"
}

export class Tyres {
    Five_High: number
    Five_low: number
    Gp_Percent: number
    Gp_Percenthigh: number
    Gp_Percentlow: number
    Gp_Rands: number
    Gp_RandsHigh: number
    Gp_Randslow: number
    application: string
    brand: string
    cbm: number
    cell: string
    code: string
    stock_code: string
    commercial: number
    construction: string
    cost: number
    description: string
    email: string
    gp_percent: number
    gp_rands: number
    group_id: number
    group_name: string
    image: string
    lat: number
    load_rating: string
    locationId: number
    lon: number
    market: string
    market_price: number
    market_price_Vat: number
    mass: number
    minOrder: number
    minOrderFee: number
    node_debtorAcc: string
    node_id: number
    node_name: string
    node_sell_state: string
    node_type: string
    oe: number
    order_rank: number
    ply_rating: number
    profile: number
    retail: number
    retail_Vat:number
    retailValuePercent:number = 0
    retailValueLess:number = 0
    rim: number
    runflat: number
    sectorM: number
    sectorNT: number
    sell_price: number
    sell_price_Vat: number
    size: string
    sla: string
    sla_original: string
    sla_warning: boolean
    soh: number
    sohInt: number
    speed_rating: string
    stock_msfid: number
    msfid:number
    tc: number
    total_sales: number
    type: string
    width: null
    xl: boolean
    inCart:boolean
    inQuote:boolean
    buy_price: number
    buy_price_Vat: number
    sellerId: number
    seller_name: string
    seller_type: string
    seller_debtorAcc: string
    seller_sell_state: string
    seller_rank: number = 999   
    location_levelId: number
    market_pricing_method : string
    market_pricing_value : number
    brand_rank: number
    Slaslots: SlaSlot[]
    showSlots:boolean
    show_tagline: boolean
    tagline: string
    tooltipVisible: boolean = false
    priceShow:priceShow;
    show_meta:boolean = false;
    meta: TyreMeta = new TyreMeta();
    isTargetted:boolean = false;
    highlight:boolean = false;
    gpExtra:number = 0;
    stock_load_dt: string = '';
    stock_load_status: string = '';
}

export class TyreSelect{
    id: number;
    name: string;
    expand: boolean = false;
    seller_sell_state: string;
}

export enum priceShow{
    retail = 1,
    sellOut = 2
}

export class CustomSlaSuppliers {
    clientId: number
    Slots: SlaSlot[]
}

export class bulk{
    value: number
}

export class superbulk{
    value: number
}

export class creditlimits{
    value: number
}

export class required {
    delivery: number
    ext_ref: number
    ext_text: number
    make:number
    milage:number
    reg:number
    salesman:number
    creditcheck:boolean
    isDelivery:boolean
}

export class suppliers
{
    client:number
    name:string
    cell:string
    email:string
    tc:string
    credit:number
    dis:number
    sprdis:number
}

export class Input {
    inputValue: string;
    inputList: string [] = [];
  }
