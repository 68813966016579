<br>
<div class="text-center not-mobile" style="position: absolute; top:35%">
    <br>
    <h5 class="text-center">Please rotate your phone to view the Order</h5>
    <span nz-icon nzType="rotate-left" nzTheme="outline" class="text-center"></span>
</div>

<nz-spin [nzSpinning]="isLoading" class="mobile">
    <button nz-button nzType="primary" (click)="goBack()">
      <span nz-icon nzType="left" nzTheme="outline" style="font-size: 12px !important;"></span>
       Back
    </button>
    <p></p>
    <nz-card nzHoverable>
        <div nz-row [nzJustify]="'center'">
            <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="8" nzXl="6">
                <br>
                <h4>Branch</h4>
                <p></p>
                <p>{{ Purchase.name }}</p>
                <p>{{ Purchase.cell }}</p>
            </div>
            <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="8" nzXl="12">
                <br>
                <div class="card" style="border-color: transparent;">
                    <div nz-row [nzJustify]="'center'">
                        <div nz-col nzXs="24" nzSm="24" nzMd="24" [nzLg]="{span: 22, offset: 2}" [nzXl]="{span: 22, offset: 2}">
                            <h4>Order </h4>
                        </div>
                    </div>
                    <div nz-row [nzJustify]="'center'">
                        <div nz-col nzSpan="24" class="text-center">
                            <p class="font-weight-bold">{{datePipe.transform(this.Purchase.dt, 'mediumDate')}}</p>
                        </div>
                    </div>
                    <div nz-row>
                        <div nz-col nzSpan="24">
                            <ul id="progressbar" class="text-center">
                                <li class="active step0"></li>
                                <li *ngIf="Purchase.dt_so == null" class="step0"></li>
                                <li *ngIf="Purchase.dt_so != null" class="active step0"></li>
                                <li *ngIf="Purchase.dt_email == null" class="step0"></li>
                                <li *ngIf="Purchase.dt_email != null" class="active step0"></li>
                            </ul>
                        </div>
                    </div>
                    <div nz-row nzJustify="start">
                        <div nz-col nzXs="12" nzSm="8" nzMd="8" [nzLg]="{span: 7, offset: 1}" [nzXl]="{span: 7, offset: 1}" *ngIf="Purchase.dt" class="text-center">
                            <p>
                                <span class="font-weight-bold">Created</span> <br> {{Purchase.dt | date:'HH:mm:ss' }}
                            </p>
                        </div>
                        <div nz-col nzXs="24" nzSm="8" nzMd="8" nzLg="8" nzXl="8" class="text-center">
                            <p>
                                <span class="font-weight-bold">Inserted</span> <br> {{Purchase.dt_so | date:'HH:mm:ss' }}
                            </p>
                        </div>
                        <div nz-col nzXs="12" nzSm="8" nzMd="8" [nzLg]="{span: 7, offset: 1}" [nzXl]="{span: 7, offset: 1}" class="text-center">
                            <p>
                                <span class="font-weight-bold">Emailed</span> <br> {{Purchase.dt_email | date:'HH:mm:ss' }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="8" nzXl="6">
                <br>
                <h4>References </h4>
                <div nz-row [nzJustify]="'center'">
                    <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
                        <p>
                            StockFinder <br>
                            <span class="font-weight-bold text">  {{ Purchase.SFRef}} </span>
                        </p>
                    </div>
                    <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12" *ngIf="Purchase.SysRef != null">
                        <p>
                            {{Purchase.pos}} <br>
                            <span class="font-weight-bold text">{{ Purchase.SysRef}}</span>
                        </p>
                    </div>
                    <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12" *ngIf="Purchase.PoRef != null">
                        <p>
                            Purchase Order <br>
                            <span class="font-weight-bold text">{{ Purchase.PoRef}} </span>
                        </p>
                    </div>
                    <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12" *ngIf="Purchase.showERef != null && Purchase.showERef == true">
                        <p>
                            External <br>
                            <span class="font-weight-bold text">{{ Purchase.ExtRef }}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <hr style="background-color: var(--textcolor);">
        <div nz-row [nzJustify]="'center'">
            <div nz-col nzXs="8" nzSm="8" nzMd="8" nzLg="8" nzXl="8">
                <div *ngIf="Purchase.username != null">
                    <h4>Salesman</h4>
                    <p>
                        {{ Purchase.username }}
                        <span *ngIf="Purchase.useremail">| {{ Purchase.useremail }} </span>
                        <span *ngIf="Purchase.cell">| {{Purchase.cell}}</span></p>
                </div>
            </div>
            <div nz-col nzXs="8" nzSm="8" nzMd="8" nzLg="8" nzXl="8" class="text-center">
                <div *ngIf="Purchase.showVmake== true || Purchase.showVreg== true || Purchase.showMilage== true">
                    <h5>Vehicle Details</h5>
                    <ng-template #prefixCar>
                        <span nz-icon nzType="car" nzTheme="outline"></span>
                    </ng-template>
                    <div nz-row>
                        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
                            <div nz-row [nzJustify]="'center'">
                                <div nz-col nzXs="24" nzSm="24" nzMd="8" nzLg="8" nzXl="8" *ngIf="Purchase.showVmake != null && Purchase.showVmake== true">
                                    <i nz-icon nzType="car" nzTheme="outline" style="color: var(--textcolor);"></i> {{ Purchase.v_make }}
                                </div>
                                <div nz-col nzXs="24" nzSm="24" nzMd="8" nzLg="8" nzXl="8" *ngIf="Purchase.showVreg != null && Purchase.showVreg == true">
                                    <i nz-icon nzType="idcard" nzTheme="outline" style="color: var(--textcolor);"></i> {{ Purchase.v_reg }}
                                </div>
                                <div nz-col nzXs="24" nzSm="24" nzMd="8" nzLg="8" nzXl="8" *ngIf="Purchase.showMilage != null && Purchase.showMilage == true">
                                    {{ Purchase.milage}} <span style="color: var(--textcolor);">km</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div nz-col nzXs="8" nzSm="8" nzMd="8" nzLg="8" nzXl="8" class="text-right">
                <div *ngIf="Purchase.showDelivery == true">
                    <h5>Delivery Address</h5>
                    <p> {{Purchase.delivery}} </p>
                </div>
            </div>
        </div>
        <br>
        <nz-table #lines [nzData]="purchaselines" [nzFrontPagination]="false" style="overflow-x:auto;">
            <thead>
                <tr>
                    <th>Brand</th>
                    <th style="min-width: 180px;">Description</th>
                    <th>Code</th>
                    <th>Quantity</th>
                    <th>Price <span class="text-style">(excl)</span></th>
                    <th>Total <span class="text-style">(excl)</span></th>
                </tr>
            </thead>
            <tbody class="table-body">
                <tr *ngFor="let item of lines.data">
                    <td>
                        <img style='border-radius:10px' height='25' width='50' src='https://sftptyres.blob.core.windows.net/brands-images/brands/{{ item.brandLogo_url }}'>
                    </td>
                    <td>
                        {{item.description_Long}}
                    </td>
                    <td>
                        {{item.code}}
                    </td>
                    <td>
                        {{item.qty}}
                    </td>
                    <td>
                        {{item.price}}
                    </td>
                    <td>
                        {{item.price * item.qty }}
                    </td>
                </tr>
            </tbody>
        </nz-table>
        <div nz-row [nzJustify]="'start'">
            <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
                <div *ngIf="Purchase.comment != null && Purchase.comment != ''">
                    <h5>Comment</h5>
                    <p>{{ Purchase.comment }}</p>
                </div>
            </div>
            <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
                <div class="float-right">
                    <small class="text-info-style">(Incl VAT)</small>
                    <h4 *ngIf="Purchase.price">Total : <span class="text-info-style">
                            R </span>{{ (Purchase.price * 1.15).toFixed(2)| number }}
                    </h4>

                </div>
            </div>
        </div>
    </nz-card>
</nz-spin>