import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Message, UnreadMessages } from '../models/message';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SocketMessage } from '../models/websockets';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  apiUrl:string = 'v1/messages/';
  allMessages: Message[]=[];
  UnreadMessages: Message[]=[];
  unreadMessagesCount = 0;

  constructor(private http: HttpClient, private authService: AuthService) { }

  public getAllMessages(count:number): Observable<Message[]>
  {
      return this.http.get<Message[]>(environment.ApiUrl() + this.apiUrl + "inbox/" + count).pipe
      (  tap((res:Message[]) => {
           this.allMessages = [];
           this.allMessages = res;
      }));
  }

  public getMessageById(id: number): Observable<Message> {
    return this.http.get<Message>(environment.ApiUrl() + this.apiUrl + `inbox/id/${id}`);
  }

  public getUnreadMessages(count:number): Observable<UnreadMessages>
  {
    return this.http.get<UnreadMessages>(environment.ApiUrl() + this.apiUrl + "inbox/unread/" + count).pipe
     ( tap((res: UnreadMessages) => {
              this.UnreadMessages = res.messages;
              this.unreadMessagesCount = res.messageCount;
          }));
  }

  public addMessage(msg: SocketMessage) {
    let inboxMsg = new Message();
    inboxMsg.message = msg.message;
    inboxMsg.messageGroupId = msg.value;
    inboxMsg.dateCreated = new Date();
    inboxMsg.messageType = msg.type;
    inboxMsg.userId = this.authService.user.user_id;
    if (msg.action1Url && msg.action1Text) {
      inboxMsg.action1Url = msg.action1Url;
      inboxMsg.action1Text = msg.action1Text;
    }
    this.UnreadMessages.unshift(inboxMsg);
    this.unreadMessagesCount = this.UnreadMessages.length;
    
  }

  public markGroupIdAsRead(gid: string) {
    // For some reason a normal x.messageGroupId === gid always return false, so we need to convert to string first
    let msg = this.UnreadMessages.find(x => x.messageGroupId.toString() === gid.toString());
    if (msg)
      this.updateMessageInArrays(msg)
    
  }

  private updateMessageInArrays(msg: Message) {
    if (this.unreadMessagesCount > 10) {
      //if there are more than what we are displaying, get the next list from the server
      this.getUnreadMessages(10).subscribe(
        _ => {}
      )
    }
    else {
      //if we are displaying all the unreads, then just remove from the list
      const index = this.UnreadMessages.indexOf(msg, 0);
      if (index > -1) {
        //message was found in unread message array
        this.UnreadMessages.splice(index, 1);
        this.unreadMessagesCount -= 1;
        msg.dateRead = new Date();
      }
    }
    
    
  }

  public markMessageAsRead(msg: Message) {
    if (msg.messageGroupId) {
      return this.markGroupAsRead(msg);
    } 
    else if (msg.messageGroupId && msg.messageId > 0) {
      return this.markRead(msg);
    }
    else {
      return this.markGroupAsRead(msg);
    }
  }

  private markRead(msg: Message)
  {
      return this.http.get<Message>(environment.ApiUrl() + this.apiUrl + "inbox/markAsRead/" + msg.messageId).pipe(
        tap(
          _ => {
            this.updateMessageInArrays(msg);
          }
        )
      )
  }

  private markGroupAsRead(msg: Message)
  {
    return this.http.get<Message>(environment.ApiUrl() + this.apiUrl + "inbox/markGroupAsRead/" + msg.messageGroupId).pipe(
      tap(
        _ => {
          this.updateMessageInArrays(msg);
        }
      )
    )
  }

  public MarkAllAsRead() {
    return this.http.post(environment.ApiUrl() + this.apiUrl + "inbox/markAsRead/all", null);
  }

  public DeleteAll() {
    return this.http.delete(environment.ApiUrl() + this.apiUrl + "inbox/read");
  }

  public DeleteGroupId(groupid: string) {
    return this.http.delete(environment.ApiUrl() + this.apiUrl + `inbox/group/${groupid}`)
  }

}
