import { Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import { NotificationService } from '../shared/notification.service';
import { resultType } from '../shared/modal-content/modal-content.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SearchObj, extraOptions, aautoquote} from './../models/search-obj';
import { CustomerService } from '../customers/customer.service';
import { SearchService } from '../search/search.service';
import { Router } from '@angular/router';
import { ClientData } from '../models/client';
import { AuthService } from '../auth.service';
import { Globals } from '../shared/globals';
import { CachedSearchTerm, SearchType } from '../models/search';


@Component({ 
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html', 
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./searchbar.component.less'] })

export class SearchbarComponent implements OnInit , OnDestroy {

  //Auto Complete
  inputValue?: string;
  filteredOptions: CachedSearchTerm[] = [];
  options: CachedSearchTerm[] = [];
  extraOptions :extraOptions[];


  //Visual Effects
  isLoadingSearch = false;
  searchmore = false;
  isSerching = false;

  //Subscriptions/Observables
  searchPlaceHolderTimerId: any;

  //CustomerUpdate
  customerForm: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    cell: new FormControl('', (Validators.required,Validators.maxLength(10)) ),
    vreg: new FormControl(''),
    email: new FormControl(''),
  });
  Pattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  isCustomerModalVisible  = false;
  client: ClientData;

  //Color
  color: string = '#000000'

  constructor(private searchService: SearchService,
              private router: Router,
              private notification: NotificationService,
              private customerService: CustomerService,
              private authService: AuthService)
   {
    this.isLoadingSearch= true;
    }

  ngOnInit(): void {

        //remove any customer logs
        localStorage.removeItem("customerData");

        //Focus Search
        // document.getElementById("search")?.focus();

        //Set Search placeholder
        var searchOptions = ["2656517 gy", "265/65R17", "2454018 ao", "20 RF", "543796", "7.5r16", "31X10.50R15", "9.00-20", "ko2", ""];
        this.searchPlaceHolderTimerId = setInterval(function()
        {
          var show =  Math.floor((Math.random() * 8) + 1);
          document.getElementById('search')?.setAttribute("placeholder", searchOptions[show]);
        },2000);

        this.isLoadingSearch =false;
  }

  private searchExtraTimer; //this will be used to ensure we don't call searchExtra multiple times. Temp solution will reqork with search ahead.


  public getSearchTypeIcon(type: SearchType): string {
    switch (type) {
      case SearchType.TyreSize: {
        return 'sf:tyre2';
        break;
      }
      case SearchType.StockCode: {
        return 'sf:service';
        break;
      }
      case SearchType.Brand: {
        return 'sf:factory';
        break;
      }
      case SearchType.QuoteRef: {
        return 'sf:quote';
        break;
      }
      case SearchType.StockPackage: {
        return 'sf:stock2';
        break;
      }
      case SearchType.Customer: {
        return 'user';
        break;
      }
      case SearchType.Vehicle: {
        return 'car';
        break;
      }
      default: {
        return 'question-circle';
      }
    }

    

  }

  //On Searching
  onChange(value: string): void {
      if (value.length > 2) {

        //We can cascade the search options, first list the tyresizes only
        this.options = this.searchService.filteredCachedSearchTerms([SearchType.TyreSize]).filter(e => e.value.toLocaleLowerCase().includes(value.toLocaleLowerCase().split(" ")[0]));
        //if no tyre sizes found, cascade to next option, or DEFAULT = all
        if (this.options.length === 1) {
          const mainItem = this.options[0];
          const brand = value.toLocaleLowerCase().split(" ")[1];
          //Store extras for later use
          const extras = value.toLocaleLowerCase().split(" ")[2];
          //do tyresize matching with brands
          if (!brand || brand.length === 0) {
            //full brand name
            for (let item of this.searchService.searchbrands.filter(e => e.description.toLocaleLowerCase().includes(brand))) {
              this.options.push({key: mainItem.key + " " + item.description, value: mainItem.key + " " + item.description, searchType: mainItem.searchType, rank: mainItem.rank, order: mainItem.order});
            }
            //also add extras
            for (let item of this.searchService.searchextras.filter(e => e.description.toLocaleLowerCase().includes(brand))) {
              this.options.push({key: mainItem.key + " " + item.description, value: mainItem.key + " " + item.description, searchType: mainItem.searchType, rank: mainItem.rank, order: mainItem.order});
            }
          }
          else {
            //split brand name codes
            for (let item of this.searchService.searchbrands.filter(e => e.searchCodes.toLocaleLowerCase().includes(brand))) {
              for (let code of item.searchCodes.split(",")) {
                if ((code.startsWith(brand) && extras === undefined) || code === brand) { //if we are only searching for size and brand, display LIKE, if we are also searching extra, brand must match
                 this.options.push({key: mainItem.key + " " + code.toLocaleUpperCase(), value: mainItem.value + " " + code.toLocaleUpperCase(), searchType: mainItem.searchType, rank: mainItem.rank, order: mainItem.order});        
                }
              }
              
            }
            //also add the extras
            for (let item of this.searchService.searchextras.filter(e => e.description.toLocaleLowerCase().includes(brand))) {
              this.options.push({key: mainItem.key + " " + item.description, value: mainItem.key + " " + item.description, searchType: mainItem.searchType, rank: mainItem.rank, order: mainItem.order});
            }
          }
          //if the brands is displayed, remove the first item, which will be the main gencode
          if (this.options.length > 1) {
            this.options.splice(0,1);
          }
          //if we are searching for extras
          if (extras !== undefined && this.options.length === 1) {
            const mainBrandedItem = this.options[0];
            for (let item of this.searchService.searchextras) {
              if ((extras.toLocaleUpperCase() && item.description.startsWith(extras.toLocaleUpperCase())) || extras.length === 0)
              {
                this.options.push({key: mainBrandedItem.key + " " + item.description.toLocaleUpperCase(), value: mainBrandedItem.value + " " + item.description.toLocaleUpperCase(), searchType: mainBrandedItem.searchType, rank: mainBrandedItem.rank, order: mainBrandedItem.order});       
              }
            }
            //if extras are displayed, remove first item which will be gencode + brand
            if (this.options.length > 1) {
              this.options.splice(0,1);
            }
          }
          
        } 
        //add all other options, maybe add logic
        this.options = this.options.concat(this.searchService.filteredCachedSearchTerms([SearchType.StockPackage, SearchType.StockCode, SearchType.Brand, SearchType.QuoteRef, SearchType.Customer, SearchType.Vehicle]).filter(e => e.value.toLocaleLowerCase().includes(value.toLocaleLowerCase())));
        //because we are going to remove the BRAND item once we press SPACE, we need to force it back in
        if (this.searchService.filteredCachedSearchTerms([SearchType.Brand]).filter(e => e.value === value.toLocaleUpperCase().split(" ")[0]).length === 1 && value.toLocaleUpperCase().split(" ").length === 2) {
          this.options.splice(0,0,...this.searchService.filteredCachedSearchTerms([SearchType.Brand]).filter(e => e.value === value.toLocaleUpperCase().split(" ")[0]))
        }
        if (this.options.filter(e => e.searchType === SearchType.Brand).length === 1 && this.options[0].searchType === SearchType.Brand && value.toLocaleUpperCase().split(" ")[0] === this.options[0].value) { //we only have one brand, and it is the top one in the list
          //let ins = this.searchService.searchextras.map(i => {key: this.options[0].key, value: this.options[0].value + ' ' + i.description})
          let ins: CachedSearchTerm[] = this.searchService.searchextras.map(item => {
            return {
              key: this.options[0].key + ' ' + item.description,
              value: this.options[0].value + ' ' + item.description,
              rank: this.options[0].rank,
              searchType: this.options[0].searchType,
              order: this.options[0].order
            }
          })
          if (value.toLocaleUpperCase().split(" ")[1] && value.toLocaleUpperCase().split(" ")[1].length > 0) {
            ins = ins.filter(e => e.value.startsWith(value.toLocaleUpperCase()))
            
            
          }
          if (ins.length > 0 && value.toLocaleUpperCase().split(" ")[1]) {
            //if we pressed space, and we have extra options filtered, remove the brand only search option
            this.options.splice(0,1);
          }
          this.options = this.options.concat(ins);
        }
        
        this.filteredOptions = this.options.slice(0,50); //only display top 50 options, to ensure responsiveness

        //if we definitely have a search value, but no options, we need to display the forcing buttons
        if (this.filteredOptions.length === 0) this.searchmore = true; else this.searchmore = false;
      }

      // this.searchmore = false;
      // if(value.length > 2)
      // {
      //     this.isSerching = true;
      //     this.options = [];

      //     var term = value.toLowerCase();

      //     //first search gencode
      //     for (var i = 0; i < this.searchService.GenCodes.length; i++)
      //     {
      //       var obj = this.searchService.GenCodes[i];
      //       if(obj.name != null)
      //       {
      //         if (obj.name.toLowerCase().includes(term))
      //         {
      //           obj.name.toUpperCase()
      //           this.options.push(obj.name);
      //         }
      //       }
      //     }

      //     //Search Sizes
      //     if (this.options.length < 1)
      //     {
      //       for (var i = 0; i < this.searchService.PopularSizes.length; i++)
      //       {
      //         var obj = this.searchService.PopularSizes[i];
      //         if (obj.name.toLowerCase().includes(term))
      //         {
      //           obj.name.toUpperCase()
      //           this.options.push(obj.name);
      //         }
      //       }
      //     }

      //     //Search Client Stock Codes
      //     if (this.options.length < 1)
      //     {
      //       for (var i = 0; i < this.searchService.Clientscodes.length; i++)
      //       {
      //         var obj = this.searchService.Clientscodes[i].name.toLowerCase();
      //         if (obj.includes(term))
      //         {
      //           this.options.push(obj.toUpperCase());
      //         }
      //       }
      //     }


      //     //Search Client Custom Stock Codes
      //     if (this.options.length < 1)
      //     {
      //       for (var i = 0; i < this.searchService.Clientccodes.length; i++)
      //       {
      //         var obj = this.searchService.Clientccodes[i].name.toLowerCase();
      //         if (obj.includes(term))
      //         {
      //           this.options.push(obj.toUpperCase());
      //         }

      //       }
      //     }

      //     //Search Customers
      //     if (this.options.length < 1)
      //     {
      //       for (var i = 0; i < this.searchService.ClientCustomers.length; i++)
      //       {

      //         var obj = this.searchService.ClientCustomers[i].name.toLowerCase();
      //         if (obj.includes(term))
      //         {
      //           this.options.push(obj.toUpperCase());
      //         }
      //       }
      //     }

      //     //Search Vregs
      //     if(this.options.length < 1 && this.authService.user.isPremium) //Only premium users should search for customer/vreg etc details.
      //     {
      //       //before searching clear the list of selection items, otherwise searches will interfere
      //       this.filteredOptions = [];
      //       clearTimeout(this.searchExtraTimer);
      //       this.searchExtraTimer = setTimeout(() => {
      //         this.searchService.getSearchExtraPost(value).subscribe(res =>
      //           {
      //               //clear current extra options
      //               this.options = [];
      //               this.extraOptions =[];
      //               this.extraOptions = res as any;

      //               //remove duplicates
      //               var UniqueByAll = this.removeDuplicates(this.extraOptions, i=> i.option);

      //               //clear current extra options
      //               for(var i = 0; i <  UniqueByAll.length; i++)
      //               {
      //                 this.options.push(UniqueByAll[i].option.toUpperCase())
      //               }

      //               if(this.extraOptions.length > 0)
      //               {
      //                 //remove extra
      //                 this.isSerching = false;
      //                 this.searchmore = false;

      //                 //set filtered options
      //                 this.filteredOptions = this.options.filter(option => option.toLowerCase().indexOf(value.toLowerCase()) !== -1);
      //                 this.removeDuplicates( this.filteredOptions, i=> i);
      //               }
      //               else
      //               {
      //                 this.isSerching = false;
      //                 this.isLoadingSearch = false;
      //                 this.searchmore = true;
      //                 this.filteredOptions = []

      //               }
      //           });
      //       }, 750);
      //     }
      //     else
      //     {
      //       this.filteredOptions = this.options.slice();
      //       this.isSerching = false;
      //       this.searchmore = false;
      //     }

      // }
  }

  CustomerCheck : string = ""
  // checkIfCustomer(term)
  // {
  //   //remove duplications of customer record if any got through
  //   this.searchService.ClientCustomers = this.removeDuplicates(this.searchService.ClientCustomers, i=> i.key);
  //   this.searchService.ClientCustomers = this.removeDuplicates(this.searchService.ClientCustomers, i=> i.name);
  //   //check if entred search is a customer
  //   for (var i = 0; i < this.searchService.ClientCustomers.length; i++)
  //   {
  //     var obj = this.searchService.ClientCustomers[i].name;
  //     if (obj != null)
  //     {
  //       var name = obj.split(" | ")[0].toLowerCase();
  //       var cell = "";
  //       var vreg = "";
  //       if(obj.split(" | ").length > 1)
  //       {
  //           cell = obj.split(" | ")[1];
  //           if(obj.split(" | ").length > 2)
  //           {
  //             vreg = obj.split(" | ")[2].toLowerCase();
  //           }
  //       }

  //       if(name == term.toLowerCase() || cell == term.toLowerCase() || vreg == term.toLowerCase())
  //       {
  //         this.CustomerCheck = obj;
  //       }
  //     }
  //   }



  //   //if customer not found in current arrays trigger db serach
  //   if(this.CustomerCheck == "")
  //   {

  //     this.searchService.getSearchExtraPost(term).subscribe(res =>
  //       {

  //         var CustomersFound = res as any;
  //         if(CustomersFound.length != 0)
  //         {
  //           //if found in db search return customer data
  //           this.CustomerCheck = CustomersFound[0].option
  //         }
  //         else
  //         {
  //           //if not found return !
  //           this.CustomerCheck = "!"
  //         }
  //       });
  //   }
  // }

  // checkIfCustomerCached(term) {
  //   //remove duplications of customer record if any got through
  //   this.searchService.ClientCustomers = this.removeDuplicates(this.searchService.ClientCustomers, i=> i.key);
  //   this.searchService.ClientCustomers = this.removeDuplicates(this.searchService.ClientCustomers, i=> i.name);

  //   //check if entred search is a customer
  //   for (var i = 0; i < this.searchService.ClientCustomers.length; i++)
  //   {
  //     var obj = this.searchService.ClientCustomers[i].name;
  //     if (obj != null)
  //     {
  //       var name = obj.split(" | ")[0].toLowerCase();
  //       var cell = "";
  //       var vreg = "";
  //       if(obj.split(" | ").length > 1)
  //       {
  //           cell = obj.split(" | ")[1];
  //           if(obj.split(" | ").length > 2)
  //           {
  //             vreg = obj.split(" | ")[2].toLowerCase();
  //           }
  //       }

  //       if(name == term.toLowerCase() || cell == term.toLowerCase() || vreg == term.toLowerCase())
  //       {
  //         this.CustomerCheck = obj;
  //       }
  //     }
  //   }
  // }

  GenCodeCheck : string = ""
  // checkifGenCodeCached(term)
  // {

  //   for (var i = 0; i < this.searchService.GenCodes.length; i++)
  //   {
  //     var obj = this.searchService.GenCodes[i];
  //     if(obj.name != null)
  //     {
  //       if (obj.name.toLowerCase() == term.toLowerCase())
  //       {
  //         this.GenCodeCheck = obj;
  //       }
  //     }
  //   }

  // }

  SizeCheck: string = ""
  // checkIfSizeCached(term)
  // {
  //   for (var i = 0; i < this.searchService.PopularSizes.length; i++)
  //   {
  //     var obj = this.searchService.PopularSizes[i];
  //     if (obj.name.toLowerCase() == term.toLowerCase())
  //     {
  //       this.SizeCheck = obj;
  //     }
  //   }

  // }

  StockCodeCheck : string = ""
  // checkIfStockCodeCached(term)
  // {
  //   for (var i = 0; i < this.searchService.Clientscodes.length; i++)
  //   {
  //     var obj = this.searchService.Clientscodes[i];
  //     if (obj.name.toLowerCase() == term)
  //     {
  //       this.StockCodeCheck= obj;
  //     }
  //   }
  // }

  CustomCodeCheck : string = ""
  // checkIfCustomStockCached(term)
  // {
  //   for (var i = 0; i < this.searchService.Clientccodes.length; i++)
  //   {
  //     var obj = this.searchService.Clientccodes[i];
  //     if (obj.name.toLowerCase()  == term)
  //     {
  //       this.CustomCodeCheck = obj;
  //     }
  //   }
  // }

  private generateQuote(stockMsfids: number[], customStockIds: number[], customStockPackages: number[]) {
    this.notification.ShowAlert({type: resultType.Information, 
      title: "Generating ...", 
      htmlMessage: 'Generating new quote, please stand by' ,
      maskClosable: false});
    let quote : aautoquote = {
      sellerId :this.authService.user.client_id,
      ext_Ref : "",
      comment :"",
      name: Globals.funcs.getCustomer().name,
      email: Globals.funcs.getCustomer().email,
      cell: Globals.funcs.getCustomer().cell,
      vreg: Globals.funcs.getCustomer().vreg,
      customerId: Globals.funcs.getCustomer().customerId,
      assignedToUserId: this.authService.user.user_id,
      lastcall: parseInt(localStorage.getItem('lastcall')!),
      quote_logic : {
                      search_requirements :
                                            {
                                              raw_search: this.inputValue!,
                                              stock_msfids:stockMsfids,
                                              none_stock_msfids: [],
                                              custom_stock_ids: customStockIds,
                                              custom_stock_package_ids: customStockPackages,
                                              buyerId: this.authService.user.client_id,
                                              specialPriceSetId: this.searchService.lastSelectedSpecialPriceSetId
                                            },
                      add_lead: true,
                      qty: 1,
                      allow_partial_full:false,
                      base_level_limit: "Remote",
                      add_recommended_stock:false,
                      max_recommended_options: 0,
                      recommended_level_limit: "Remote",
                      add_default_services: false,
                      isContractPricing: false
                    }
    }
    this.searchService.Autoquote(quote).subscribe(
      {next: 
        res => {
          this.notification.removeAlert();
          var quoteId = res["quoteId"];
          if(quoteId != 0)
          {
            this.notification.showSuccess("Quote created successfully")
            localStorage.removeItem('fleetData')
            localStorage.removeItem('customerData')
            sessionStorage.clear();
            this.router.navigate(["/pos/v2",quoteId]);


          }
          else {
            this.notification.showWarning(`An error occured with your autoquote for '${this.inputValue}'. Please contact support.`)
          }
        },
        error: err => {
          this.notification.handleError(err);
          this.notification.removeAlert();
        }
      }
    );
  }

  private redirectKnownSearch(selectedOption: CachedSearchTerm) {
    switch(selectedOption.searchType) {
      case SearchType.TyreSize: {
        localStorage.removeItem("search");
        localStorage.setItem("search", selectedOption.key);
        this.router.navigate(['/stock/order']);
        return;
      }
      case SearchType.StockPackage: {
        // let customStockIds: number[] = []
        // for (let code of selectedOption.key.split(',')) {
        //   if (code) {
        //     customStockIds.push(+code)
        //   }
        // }
        // if (customStockIds) {
        //   this.generateQuote([],customStockIds);
        // }
        // else {
        //   this.notification.showWarning(`Problem creating quote for group ${selectedOption.value}. Are there items assigned to the package?`);
        // }
        this.generateQuote([],[],[+selectedOption.key])
        return;
      }
      case SearchType.StockCode: {
        if (selectedOption.key.startsWith("[M]")) {
          //this.generateQuote([+selectedOption.key.slice(3)],[]);
          localStorage.removeItem("search");
          localStorage.setItem("search", selectedOption.value);
          this.router.navigate(['/stock/order']);
        }
        else if (selectedOption.key.startsWith("[C]")) {
          this.generateQuote([],[+selectedOption.key.slice(3)],[]);
        }
        else {
          this.notification.showWarning(`Error occured with quote generation: ${selectedOption.key}`);
        }
        return;
      }
      case SearchType.Brand: {
        localStorage.removeItem("search");
        localStorage.setItem("search", selectedOption.key);
        this.router.navigate(['/stock/order']);
        return;
      }
      case SearchType.Customer: {

        localStorage.removeItem("customerData");
        localStorage.setItem('customerData', JSON.stringify(selectedOption.value));
        this.router.navigate(['/customer', selectedOption.key]);
        return;
      }
      case SearchType.QuoteRef: {
        this.router.navigate(['/pos/v2', selectedOption.key]);
        return;
      }
      case SearchType.Vehicle: {
        // if (selectedOption.key.split(',')[0] == '67546') {
        //   localStorage.removeItem("fleetData");
        //   var obj = {
        //     sendTo: 'Fleet Home',
        //     customerId: selectedOption.key.split(',')[0],
        //     vreg: this.inputValue
        //   }
        //   localStorage.setItem('fleetData', JSON.stringify(obj));
        //   this.router.navigate(['/fleet']);
        // }
        // else {
        this.router.navigate(['/customer'].concat(selectedOption.key.split(',')));
        //}       
        
        return;
      }
      default: {
        //trigger old search method
        this.onPressEnter({nzValue: selectedOption.key});
      }
    } //switch
  }

  //when an item is selected from the dropdown, via click or enter
  onSelectFromDropDown(option) {
    this.alreadySelected = true;
    const selectedOption = option.nzValue as CachedSearchTerm;
    this.redirectKnownSearch(selectedOption);
    return;
  }

  alreadySelected = false;
  
  //When enter is pressed to search in the search bar
  onPressEnter(option)
  {
    
    if (this.alreadySelected) {       
      return;
    }

    if (this.filteredOptions && this.filteredOptions.length > 0) {
      this.redirectKnownSearch(this.filteredOptions[0])
      return;
    }

    if(this.inputValue)
    {
      if (this.inputValue!.length < 3)
      {
        this.notification.showWarning("Minimum 3 characters required for searches");
        return;
      }
    }
    else
    {
      this.notification.showWarning("Minimum 3 characters required for searches");
      return;
    }
    


    this.notification.ShowAlert({type: resultType.Information, 
      title: "Searching ...", 
      htmlMessage: 'Busy searching, you will be redirected in a few seconds.' ,
      maskClosable: false});

    var obj = new SearchObj();
    if (option.nzValue != null && option.nzValue != undefined)
    {
      /////////
      // Will this flow ever execute? 'option' is a string in all cases
      ////////
      //Something was selected in the dropdown
      obj.search = option.nzValue;
      if(obj.search.split(" | ").length > 1)
      {
        //Assignobj
        obj.searchtype="customer";
        obj.cell = obj.search.split(" | ")[1];
        if (obj.search.split(" | ")[2] != undefined)
        {
          obj.vreg = obj.search.split(" | ")[2];
        }
        else
        {
          obj.vreg = "";
        }
      }
      else
      {
        if (obj.search.substring(0,2) == "SF")
        {
          obj.searchtype = "order"
        }
        else
        {
          obj.searchtype = "stock";
        }
      }
      //trigger search
      
      
      this.CheckRedirect(obj)
    }
    else
    {
      //nothing was selected, or there was no options displayed when searching

      this.searchService.searchAll(this.inputValue).subscribe({
        next: val => {
          this.notification.removeAlert();
          if (val.type === 0) {
            var obj = new SearchObj();
            obj.searchtype = "stock";
            obj.search = this.inputValue!;

            this.CheckRedirect(obj);
            return;
          }
          let returnedSearch = new CachedSearchTerm();
          returnedSearch.searchType = val.type;
          returnedSearch.key = val.value;
          returnedSearch.value = val.value;
          this.redirectKnownSearch(returnedSearch);
        },
        error: err => {
          this.notification.handleError(err);
        }
      })


      // obj.search = this.inputValue;

      // this.CustomerCheck == "";

      // //trigger to check entered value
      // // this.checkIfCustomerCached(obj.search);
      // // this.checkifGenCodeCached(obj.search);
      // // this.checkIfSizeCached(obj.search);
      // // this.checkIfCustomStockCached(obj.search);
      // // this.checkIfStockCodeCached(obj.search);


      // if(this.CustomerCheck == "" && this.GenCodeCheck == "" && this.SizeCheck == "" && this.CustomCodeCheck == "" && this.StockCodeCheck == "")
      // {
      //   this.searchService.getSearchExtraPost(obj.search).subscribe(res =>
      //     {

      //       var CustomersFound = res as any;
      //       if(CustomersFound.length != 0)
      //       {
      //         //if found in db search return customer data
      //         this.CustomerCheck = CustomersFound[0].option
      //         obj.searchtype = "customer";
      //         obj.search = this.CustomerCheck;
      //         obj.search.split(" | ");
      //         obj.cell = obj.search.split(" | ")[1];
      //         if (obj.search.split(" | ")[2] != undefined)
      //         {
      //           obj.vreg = obj.search.split(" | ")[2].toUpperCase();
      //         }
      //         else
      //         {
      //           obj.vreg = "";
      //         }
      //         //trigger search
      //         this.CheckRedirect(obj)
      //       }
      //       else
      //       {

      //         this.CustomerCheck = "!"

      //         if (obj.search.substring(0,2) == "SF")
      //         {
      //           obj.searchtype = "order"
      //         }
      //         else
      //         {
      //           obj.searchtype = "stock";
      //         }
      //         //trigger search
      //         this.CheckRedirect(obj);
      //       }
      //     });
      // }
      // else {
      //   //trigger search
      //   this.CheckRedirect(obj)
      // }
    }
  }

  CheckRedirect(obj)
  {
    this.alreadySelected = true;
    let isCustom = false;
    // for (var i = 0; i < this.searchService.Clientccodes.length; i++)
    // {
    //   if (this.searchService.Clientccodes[i].name.toLowerCase() == obj.search.toLowerCase())
    //   {
    //     isCustom = true;
    //   }
    // }
    if(isCustom)
    {
      //Get MSFID
      this.searchService.getMsfid(obj.search).subscribe(val => {
            let quote : aautoquote = {
              sellerId :this.authService.user.client_id,
              ext_Ref : "",
              comment :"",
              name: Globals.funcs.getCustomer().name,
              email: Globals.funcs.getCustomer().email,
              cell: Globals.funcs.getCustomer().cell,
              vreg: Globals.funcs.getCustomer().vreg,
              customerId: Globals.funcs.getCustomer().customerId,
              assignedToUserId: this.authService.user.user_id,
              lastcall: parseInt(localStorage.getItem('lastcall')!),
              quote_logic : {
                              search_requirements :
                                                    {
                                                      raw_search: obj.search,
                                                      stock_msfids:[],
                                                      none_stock_msfids: [ parseInt(val['msfid'])],
                                                      buyerId: this.authService.user.client_id,
                                                      specialPriceSetId: this.searchService.lastSelectedSpecialPriceSetId
                                                    },
                              add_lead: true,
                              qty: 1,
                              allow_partial_full:false,
                              base_level_limit: "Remote",
                              add_recommended_stock:false,
                              max_recommended_options: 0,
                              recommended_level_limit: "Remote",
                              add_default_services: false,
                              isContractPricing: false
                            }
            }
            if (val.customStockId) {
              quote.quote_logic.search_requirements.custom_stock_ids = [parseInt(val.customStockId)];
              quote.quote_logic.search_requirements.none_stock_msfids = [];
            }
            this.searchService.Autoquote(quote).subscribe(res => {
                this.notification.removeAlert();
                var quoteId = res["quoteId"];
                if(quoteId != 0)
                {
                  this.notification.showSuccess("Quote created successfully")
                  localStorage.removeItem('fleetData')
                  localStorage.removeItem('customerData')
                  sessionStorage.clear();
                  this.router.navigate(["/pos/v2",quoteId]);


                }
              else {
                this.notification.showWarning(`An error occured with your autoquote for '${obj.search}'. Please contact support.`)
              }
            });
      });
      return;
    }
    else
    {
      this.searchService.searchSF(obj).subscribe(res =>
        {
          this.notification.removeAlert();
          if(res["sendTo"] == "Customer Home")
          {
            localStorage.removeItem("customerData");
            localStorage.setItem('customerData', JSON.stringify(res));
            this.router.navigate(['/customer']);
          }
          else if (res["sendTo"] == "Fleet Home")
          {
            localStorage.removeItem("fleetData");
            localStorage.setItem('fleetData', JSON.stringify(res));
            this.router.navigate(['/fleet']);
          }
          else if (res["sendTo"] == "Sales Home")
          {
            this.router.navigate(["/sales/view",JSON.stringify(res["order"])])
          }
          else
          {
            localStorage.removeItem("search");
            localStorage.setItem("search", obj.search);
            this.router.navigate(['/stock/order']);
          }
        },
        error =>
        {
          this.notification.handleError(error);
        });
    }
  }

  //Navigate to Stock Order Page
  onSearch()
  {
    localStorage.removeItem("search");
    localStorage.setItem("search", JSON.stringify(this.inputValue));
    this.router.navigate(['/stock/order']);
  }

  //Duplicate filter
  removeDuplicates(arr, predicate) {
    var found = {};
    arr.forEach(d => {
      found[predicate(d)] = d;
    });
    return Object.keys(found).map(key => found[key]);
  }

  //show Customer Modal
  openCustomer()
  {
    //let isnum = Number.isInteger(toNumber(this.inputValue!)); //`Number.isInteger(toNumber(this.inputValue!)` will always be true, because `toNumber(this.inputValue!)` returns 0 if `this.inputValue` is not a number
    let isnum = !isNaN(+this.inputValue!);
    if(isnum)
    {
      this.customerForm.controls.vreg.setValue("");
      this.customerForm.controls.cell.setValue(this.inputValue);
    }
    else
    {
      this.customerForm.controls.vreg.setValue(this.inputValue?.toLocaleUpperCase());
      this.customerForm.controls.cell.setValue("");
    }
    this.isCustomerModalVisible = true;
  }

  //Addnew Customer
  AddCustomer()
  {
    if(this.customerForm.valid == false)
    {
      Object.values(this.customerForm.controls).forEach(control => {
        if (control.invalid)
        {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
    else
    {
       if(this.customerForm.value.vreg != "" )
       {
           //filter vreg
            if(this.customerForm.value.vreg.length < 2)
            {
              this.notification.ShowAlert({type: resultType.Error, 
                title: "Vehicle Registration is Not Valid", 
                htmlMessage: 'Please check that vehicle registration is Correct' ,
                maskClosable: false, 
                autoCloseInMilliseconds: 2000});

                return;
            }
       }
       if(this.customerForm.value.email != ""){
          if( !this.Pattern.test(this.customerForm.value.email) == true)
                  {
                    this.notification.ShowAlert({type: resultType.Error, 
                      title: "Incorrect Email Pattern", 
                      htmlMessage: 'Please check that the email is in the correct format, name@domain.anything' ,
                      maskClosable: false, 
                      autoCloseInMilliseconds: 5000});
                    return;
          }
       }
       if(this.customerForm.value.cell.length < 10){
        this.notification.ShowAlert({type: resultType.Error, 
          title: "Cell Number is Not Valid", 
          htmlMessage: 'Please check that number contains 10 digits' ,
          maskClosable: false, 
          autoCloseInMilliseconds: 2000});
          return;
       }
       var Customer = { "name": this.customerForm.value.name,
                        "email": this.customerForm.value.email,
                        "cell": this.customerForm.value.cell,
                        "vreg": this.customerForm.value.vreg }
        this.customerService.AddCustomer(Customer).subscribe(res =>
        {
          this.notification.removeAlert();
          this.notification.showSuccess("Added Successfully");
          localStorage.removeItem("customerData");
          localStorage.setItem('customerData', JSON.stringify(res));
          this.router.navigate(['/customer']);
        },
        error =>
        {
          this.notification.handleError(error);
        });
        this.isCustomerModalVisible = false;
    }
  }

  //check number input
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  //Clear Search Placeholder
  ngOnDestroy(): void {
    if (this.searchPlaceHolderTimerId) {
      clearInterval(this.searchPlaceHolderTimerId);
    }
  }

}
