import { Component,EventEmitter,Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomerDebtorAcc, NewPosCustomer, PosActionLogEntry, PosAuditEntry, PosAuditEntryType, PosQuote, PosSearchCustomer, VehicleReg } from 'src/app/models/pos';
import { PosService } from '../../../pos.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { PosAuditService } from '../../../posaudit.service';
import { AuthService } from 'src/app/auth.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-pos-customer',
  templateUrl: './pos-customer.component.html',
  styleUrls: ['./pos-customer.component.css']
})
export class PosCustomerComponent implements OnInit {

  //Inputs
  @Input() posQuote!: PosQuote;
  @Input() isWIP: boolean;
  @Input() isCompleted: boolean;
  @Output() loadVehicles = new EventEmitter<boolean>();

  //Objects
  customerDebtorInfo: CustomerDebtorAcc = new CustomerDebtorAcc();
  customerReg: VehicleReg[] = [];
  DebtorAccs: CustomerDebtorAcc[] = [];
  linkedReg: VehicleReg = new VehicleReg();
  customers: PosSearchCustomer[];

  //Bools
  isLoading = false;
  showRegModal = false;
  showDebtorModal = false;
  isLoadingLinkedReg = false;
  isLoadingDebtors = false;
  showAddVehicleModal = false;
  showAddDebtorModal = false;
  isAddCustomerModalVisible = false;
  isLoadingCustomers = true;
  isLoadingCustomer = false;
  statusSaved = false;
  quoteDirty = false;
  showSearch = false;
  isVATModalVisible = false;
  isAddingCustomer = true;

  
  searchedCustomerCell: string = "";
  searchedCustomerName: string = "";
  selectedCustomer:number; 
  
  constructor(
    private posService: PosService, 
    private notification: NotificationService,
    private auditService: PosAuditService,
    private authService: AuthService,
    private router: Router
    
    ) { }

  //Forms 

  addCustomerForm: FormGroup = new FormGroup({
    custName: new FormControl(""),
    custSurname: new FormControl(""),
    custCell: new FormControl("", Validators.compose([Validators.pattern('^[+0-9]+$'), Validators.minLength(10), Validators.maxLength(13) , Validators.required])),
    custEmail: new FormControl("", [Validators.email]),
    custVATNumber: new FormControl("")
  });

  addDebtorForm: FormGroup = new FormGroup({
    debtorAcc: new FormControl('', [Validators.required]),   
  });



  ngOnInit(): void {
    this.getCustomers();
    this.isLoadingCustomer = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.posQuote.firstChange == false)
    {
      if(changes.posQuote.previousValue.custId !== changes.posQuote.currentValue.custId)
      {
        this.setCustomer();
        this.isLoadingCustomer= false;
      }
    }
  }
  

  // #region " Customer "

  setCustomer()
  {
    if(this.posQuote.custId != 14041 && this.posQuote.custId != 0)
    {
      let nameSplit = this.posQuote.custName.split(' ');
      this.addCustomerForm.controls.custName.setValue(nameSplit[0]);
      this.addCustomerForm.controls.custSurname.setValue(nameSplit[1]);
      this.addCustomerForm.controls.custCell.setValue(this.posQuote.custCell);
      this.addCustomerForm.controls.custEmail.setValue(this.posQuote.custEmail);
      if(this.posQuote.custVATNumber != "None")
      {
        this.addCustomerForm.controls.custVATNumber.setValue(this.posQuote.custVATNumber); 
      }
      this.showSearch = false;
      this.addCustomerForm.controls.custName.enable();
      this.addCustomerForm.controls.custSurname.enable();
      this.addCustomerForm.controls.custCell.enable();
      this.addCustomerForm.controls.custEmail.enable();
      this.addCustomerForm.controls.custVATNumber.enable();

      this.loadCustomerConncetions();
    }
    else
    {

      //Set Empty Values
      this.selectedCustomer = 0;
      this.addCustomerForm.controls.custName.setValue('');
      this.addCustomerForm.controls.custSurname.setValue('');
      this.addCustomerForm.controls.custCell.setValue('');
      this.addCustomerForm.controls.custEmail.setValue('');
      this.addCustomerForm.controls.custVATNumber.setValue(''); 
      this.customerDebtorInfo = new CustomerDebtorAcc();


      //Disable Inputs
      this.addCustomerForm.controls.custName.disable();
      this.addCustomerForm.controls.custSurname.disable();
      this.addCustomerForm.controls.custCell.disable();
      this.addCustomerForm.controls.custEmail.disable();
      this.addCustomerForm.controls.custVATNumber.disable();
      this.showSearch = true;
    }
    
    //Emit Vehiclelog
    this.loadVehicles.emit();

  }

  getCustomers()
  {
    this.posService.getCustomers().subscribe(
      val => {
        this.customers = val;
        this.isLoadingCustomers = false;
      },
      error => {
        this.notification.handleError(error);
        this.isLoadingCustomers = false;
      }

    );
  }

  checkCustomer()
  {
      Object.values(this.addCustomerForm.controls).forEach(control => {
        if (control.invalid)
        {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      if (this.addCustomerForm.valid !== true)
      {
        return;
      }
  }

  linkCustomer(value:any) {
    if (value === undefined || value === null) return;

    // this.isLoadingCustomer = true;

    this.posService.linkCustomerToQuote(this.posQuote.quoteId, value).subscribe(
      val => {
        this.posQuote.custId = val.customerId;
        this.posQuote.custName = val.name;
        this.posQuote.custCell = val.cell;
        this.posQuote.custEmail = val.email;
        this.auditService.addAction(new PosAuditEntry(this.posQuote.quoteId, PosAuditEntryType.SalesmanSelectCustomer, val.customerId, val.name + ':' + val.cell));
        this.updateQuoteState();
        this.SaveAndLoadActionLog();
        // this.isLoadingCustomer = false;
        if (value === 67546) {
          //standard bank fleet was selected
          this.quoteDirty = false;
          this.statusSaved = true;
          this.router.navigate(['pos','fleet',this.posQuote.quoteId]);
        }
        this.showSearch = false;
        this.setCustomer();
      },
      error => {
        this.notification.handleError(error);
        this.isLoadingCustomer = false;
      }
    )


  }

  filterCustomer(filter: string) {
    if (!filter) {
      this.searchedCustomerName = "";
      this.searchedCustomerCell = "";
      return;
    }
    if (isNaN(+filter)){
      this.searchedCustomerName = filter;
      this.searchedCustomerCell = "";
    }
    else {
      this.searchedCustomerName = "";
      this.searchedCustomerCell = filter;
    }

  }

  showAddCustomer()
  {
    this.addCustomerForm.controls["custName"].setValue(this.searchedCustomerName);
    this.addCustomerForm.controls["custCell"].setValue(this.searchedCustomerCell);

    //Enable inputs
    this.addCustomerForm.controls.custName.enable();
    this.addCustomerForm.controls.custSurname.enable();
    this.addCustomerForm.controls.custCell.enable();
    this.addCustomerForm.controls.custEmail.enable();
    this.addCustomerForm.controls.custVATNumber.enable();
    this.showSearch = false;
    this.updateCustomer();
    this.checkCustomer();
  }
  
  updateCustomer()
  {
      let sendUpdate = false;
      let cust: NewPosCustomer = new NewPosCustomer();
      cust.name = this.addCustomerForm.value.custName;
      if(this.addCustomerForm.value.custSurname != undefined)
      {
        cust.name = this.addCustomerForm.value.custName + ' ' + this.addCustomerForm.value.custSurname;
      }
      cust.cell = this.addCustomerForm.value.custCell;
      cust.email = this.addCustomerForm.value.custEmail;
      if(cust.debtorAcc != undefined)
      {
        cust.debtorAcc = this.addCustomerForm.value.custDebtorAcc;
      }
      if(cust.VATNumber != undefined)
      {
        cust.VATNumber = this.addCustomerForm.value.custVATNumber;
      }

      //Check if values diffrent
      sendUpdate = this.checkIfDetailsChanged(cust);
      
      if(sendUpdate)
      {  

        this.posService.updatePosCustomer(this.posQuote.custId,cust).subscribe(
        val => {
          this.linkCustomer(val.customerId);
          this.auditService.addAction(new PosAuditEntry(this.posQuote.quoteId, PosAuditEntryType.SalesmanUpdatedCustomerDetails, val.customerId, cust.name + ':' + cust.cell + ':' + cust.email));
          // this.notification.showSuccess("Updated Customer");
        },
        error => {
          this.notification.handleError(error);
          this.isLoadingCustomer = false;
        }
      )
      }
  }

  checkIfDetailsChanged(newDetails: NewPosCustomer)
  {
    if(newDetails.name.split(' ')[0] != this.posQuote.custName.split(' ')[0])
    {
      return true;
    }
    if(newDetails.name.split(' ')[1] != this.posQuote.custName.split(' ')[1])
    {
      return true;
    }
    if(newDetails.email != this.posQuote.custEmail)
    {
      return true;
    }
    return false;
    
  }

  removeCustomer() {
     //Code to link to quote 
     this.posService.linkVcmToQuote(0 , this.posQuote).subscribe(
      val => {
        this.linkCustomer(0);
        this.auditService.addAction(new PosAuditEntry(this.posQuote.quoteId, PosAuditEntryType.SalesmanSelectCustomer, this.posQuote.custId,  this.posQuote.custName + ':' + this.posQuote.custCell));
      },
      error => {
        this.notification.handleError(error);
      });
    

  }

  
  //#endregion

  // #region " Vat Numbers "

  onVATNumberAddClick() {
    if(this.addCustomerForm.value.custVATNumber != "" && (this.addCustomerForm.value.custVATNumber != this.posQuote.custVATNumber ))
    {
      this.posService.updateCustomerVATNumber(this.posQuote.custId, this.addCustomerForm.value.custVATNumber).subscribe(
        _ => {
          this.notification.showSuccess("VAT Number updated");
          this.posQuote.custVATNumber = this.addCustomerForm.value.custVATNumber
        },
        error => {
          this.notification.handleError(error);
        }
      ) 
    }
  }

  //#endregion

  // #region " Debtor Accounts "

  onDebtorClick() {
    this.showDebtorModal = true;
    this.isLoadingDebtors = true;
    this.posService.getCustomerDebtorAcc(this.posQuote.custId).subscribe(
      val => {
        this.DebtorAccs = val;
        this.isLoadingLinkedReg = false;
      },
      error => {
        this.notification.handleError(error);
        this.showRegModal = false;
      }
    )   
    this.isLoadingDebtors = false;
  }

  onAddDebtorClick()
  {
    this.showAddDebtorModal = true;
  }

  onUnlinkDebtorClick() {
    this.linkDebtorToCustomer('remove');
  }
  
  addDebtor()
  {
    if (this.addDebtorForm.value.debtorAcc.length < 2) 
    {
      this.notification.showWarning("Invalid Debtor Account");
      return;
    } 
    this.isLoadingDebtors = true;
    this.linkDebtorToCustomer(this.addDebtorForm.value.debtorAcc);
  }

  linkDebtorToCustomer(debtorAcc: string)
  {
    this.showDebtorModal = false;
    this.showAddDebtorModal = false;
    this.isLoading = true;
    this.posService.linkDebtorToCustomer(debtorAcc,this.posQuote.custId ,this.posQuote.quoteId).subscribe(
      val => {
        this.posService.getLinkeddebtorAcc(this.posQuote.quoteId,this.posQuote.custId).subscribe(
          (val:any) => {
              this.customerDebtorInfo = val;
              this.isLoading = false;
            },
            error => {
              this.notification.handleError(error)
            }
          )
      },
      error => {
        this.notification.handleError(error);
      }
    )

  }


  private loadCustomerConncetions()
  {
    this.posService.getLinkeddebtorAcc(this.posQuote.quoteId,this.posQuote.custId).subscribe(
    (val:any) => {
        this.customerDebtorInfo = val;
        this.isLoading = false;
      },
      error => {
        this.notification.handleError(error)
      }
    )

  }

//#endregion

  // #region " Audit Log "
    isLoadingLog = false;
    actionLog: PosActionLogEntry[] = [];
    truncActionLog: PosActionLogEntry[] = [];
    datepipe: DatePipe = new DatePipe('en-US');
    isFullActionLogModalVisible = false;
  
    private handleActionLog(val: PosActionLogEntry[]) {
      this.actionLog = val;
  
      if (val.length > 3) {
        this.truncActionLog = val.slice().splice(0,3);
      }
      else {
        this.truncActionLog = val;
      }
    }
  
    private SaveAndLoadActionLog() {
      this.isLoadingLog = true;
      this.auditService.SaveActionsSub().subscribe(
        () => {
          this.posService.getActionLog(this.posQuote.quoteId).subscribe(
            val => {
              this.handleActionLog(val);
              this.isLoadingLog = false;
            },
            () => {this.isLoadingLog = false;}
          )
        },
        () => {this.isLoadingLog = false;}
      );
    }
  
    loadActionLog() {
      this.isLoadingLog = true;
  
      this.posService.getActionLog(this.posQuote.quoteId).subscribe(
        val => {
          this.handleActionLog(val);
          this.isLoadingLog = false;
        },
        error => {
          this.notification.handleError(error);
          this.isLoadingLog = false;
        }
      )
    }
  
    showFullActionLog() {
      this.isLoadingLog = true;
      this.auditService.SaveActionsSub().subscribe(
        () => {
          this.posService.getActionLog(this.posQuote.quoteId).subscribe(
            val => {
              this.handleActionLog(val);
              this.isFullActionLogModalVisible = true;
              this.isLoadingLog = false;
            },
            error => {
              this.notification.handleError(error);
              this.isLoadingLog = false;
            }
          )
        },
        error => {
          this.notification.handleError(error);
          this.isLoadingLog = false;
        }
      )
    }
   // #endregion
  
  // #region " Extra " 
    updateQuoteState() {
      this.posService.getQuoteState(this.posQuote.quoteId).subscribe(
        val => {
          this.posQuote.state = val;
          if(this.posQuote.state.stateId === 3)
          {
            this.isCompleted = true;
          }
          if ([26,27].includes(this.posQuote.state.quoteStatusId)) {
            this.isWIP = true;
            this.statusSaved = true;
          }
        },
        error => {
          this.notification.handleError(error);
        }
      )
    }
  // #endregion

}
