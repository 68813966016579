import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzImageService } from 'ng-zorro-antd/image';
import { Globals } from 'src/app/shared/globals';
import { AppComponent } from '../app.component';
import { AuthService } from '../auth.service';
import { LogAudit, LogAuditType } from '../models/logs';
import { SearchService } from '../search/search.service';
import { LogService } from '../shared/logs.services';
import { resultType } from '../shared/modal-content/modal-content.component';
import { NotificationService } from '../shared/notification.service';
import { CartErrors, CartObj, FixedTyreItem, SearchObj, aautoquote, settings, sfSearch } from './../models/search-obj';
import { CustomSlaSuppliers, Input, TyreMeta, TyreSelect, Tyres, bulk, creditlimits, required, superbulk, suppliers } from './../models/tyres';
import { Observable, Subscribable } from 'rxjs';

@Component({
  selector: 'app-stock-order',
  templateUrl: './stock-order.component.html',
  styleUrls: ['./stock-order.component.less'],
})
export class StockOrderComponent implements OnInit, OnDestroy {

  vh: number;
  vw: number;
  itemSize: number;
  pageSize: number = 50;

  Tyres: Tyres[] = [];
  cart:  CartObj[] = [];
  quote:  CartObj[] = [];
  items: CartObj[] = [];
  required: required[] = [];
  suppliers: suppliers[] = [];
  filterTyres: TyreSelect[] =[];
  inclusionList :string [] = ["Brand", "Tyre",  "Supplier", "SOH"];
  input = new Input() ;
  smallTyres: any [] = [];

  CustomSlaSuppliers: CustomSlaSuppliers[]= [];

  cartItems:number = 0;
  cartTotal: number = 0;
  quoteItems:number = 0;
  vatRate:number = 1.15;
  OrderSla : string = "";
  OrderFrom : string = "";
  gpUpDownPercent:number = 3;
  search:string = localStorage.getItem("search")!;
  color: string = '#117274';
  //stockProcess:string = "";
  stockAction :string = "";
  switchTo: string = "";
  vatShow :string = "";
  inputValue: string = "";

  isShop: boolean = true;
  isAuto: boolean = true;
  openModal:boolean= false;
  showTxt: boolean = false;
  checkout: boolean = true;
  isLoading:boolean = false;
  showMake: boolean = false;
  showVreg: boolean = false;
  showMilage: boolean = false;
  showExtref: boolean = false;
  manualQuote: boolean = true;
  minenabled : boolean = false;
  isShownCart: boolean = false;
  isShownQuote: boolean = false;
  showSalesman: boolean = false;
  showDelivery: boolean = false;
  showPickupOrDelivery: boolean = false;
  isAutoQuoting:boolean  = false;
  isManualQuoting: boolean = false;
  isBuying: boolean = true; //Is this used?
  isInSellingMode:boolean = false;
  loading:boolean = false;
  toolTipVisible:  [{id: number, bool: boolean}];
  ratings = ['terrible' , 'bad','acceptable','good', 'excellent']

  showRetail:boolean = false;
  switchPricing: string = "Switch To Retail";
  isConfirmModalVisible = false;


  bulk: bulk;
  settings : settings;
  superbulk: superbulk;
  searchobj: SearchObj;
  supplierInfo: suppliers;
  creditlimits: creditlimits;

  isTsAndCsChecked: boolean = false;

  private lastSelectedPriceSetId: number = 0; //used to store the last emitted value from the Special Price Set behavioust subject

  hideScrollbar$: Observable<any>|Subscribable<any>|Promise<any>;


  sortBrandFn = (a: Tyres, b: Tyres) => a.brand.localeCompare(b.brand);
  sortTyreFn = (a: Tyres, b: Tyres) => a.description.localeCompare(b.description);
  sortCodeFn = (a: Tyres, b: Tyres) => a.stock_code.localeCompare(b.stock_code);
  sortSlaFn = (a: Tyres, b: Tyres) => a.sla.localeCompare(b.sla);
  sortSupplierFn = (a: Tyres, b: Tyres) => a.seller_name.localeCompare(b.seller_name);
  sortCostFn = (a: Tyres, b: Tyres) =>  a.buy_price - b.buy_price;
  sortSohFn = (a: Tyres, b: Tyres) => a.soh - b.soh;

  @HostListener("window:scroll", [])
    onScroll(): void {
    if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight - (document.body.scrollHeight * 0.1)) {
            // you're at the bottom of the page
            this.pageSize += 15;
            this.input.inputValue = this.input.inputValue;
        }
    }
    resetTable(itemSize){
        this.pageSize = 50;
    }
  /* -----------------------------------------------*/

  constructor(
        private nzImageService: NzImageService,
        private searchService: SearchService,
        private router: Router, 
        private notification: NotificationService, 
        public authService: AuthService, 
        public appComponent: AppComponent, 
        private logService: LogService){
    // if (localStorage.getItem("togglePrice"))
    // {
    //   //Change price depending on whats in local storage
    //   this.showRetail = Boolean(JSON.parse(localStorage.getItem("togglePrice")!));
    // }
    // else
    // {
    //   //set it manually to sellout
    //   localStorage.setItem("togglePrice", JSON.stringify(false));
    // }
  }


  private selectedPriceSetSubscription: any;

  ngOnDestroy(): void {
    this.selectedPriceSetSubscription.unsubscribe();
  }

  ngOnInit(): void{
    this.selectedPriceSetSubscription = this.searchService.selectedPriceSet.subscribe(
      {next: val => {
        this.lastSelectedPriceSetId = val;
        this.getData(val, true);
      }});
    //this.getData();
  }

  // searchForTradeIns(){
  //   this.getData(this.lastSelectedPriceSetId, true);
  // }


  getData(selectedPriceSet: number = 0, includeTradeIns: boolean = false){
    this.input.inputValue = '';
    this.input.inputList = ["brand", "description",  "seller_name", "soh", "sla", "usedStockClassification"]
    this.isLoading =true;
    this.reset();
    this.searchobj = { search: this.search, cell : "" , searchtype: "stock" , vreg : ""}
    let search : sfSearch ={
    search_logic : {
                    search_requirements :
                                          {
                                            raw_search: this.searchobj.search,
                                            specialPriceSetId: selectedPriceSet,
                                            includeUsedStock: includeTradeIns
                                          }
                  },
    sellerId : this.authService.user.client_id    }

    this.searchService.searchStock(search).subscribe((res:any) =>{
      this.Tyres = res.tyres;
      this.CustomSlaSuppliers = res.SuppliersWithCustomSla;
      for (var i = 0; i < this.Tyres.length;i++){
        this.Tyres[i].market_price_Vat = Math.round( this.Tyres[i].market_price_Vat);
        // console.log('this.Tyres[i].buy_price: ',this.Tyres[i].buy_price)
        // this.Tyres[i].buy_price = Math.round( this.Tyres[i].buy_price);
        this.Tyres[i].buy_price = this.Tyres[i].buy_price;
        // console.log(' Math.round( this.Tyres[i].buy_price): ', Math.round( this.Tyres[i].buy_price))
       // this.Tyres[i].cost = Math.round( this.Tyres[i].cost);
        this.Tyres[i].cost = this.Tyres[i].cost;
        // this.Tyres[i].market_price = Math.round( this.Tyres[i].market_price);
        this.Tyres[i].market_price = this.Tyres[i].market_price;
        this.updateLineItemGPList( this.Tyres[i]);
        this.Tyres[i].inCart = false;
        this.Tyres[i].inQuote = false;
        this.Tyres[i].tooltipVisible = false;
        for(var j = 0; j < this.CustomSlaSuppliers.length; j++){
          if(this.Tyres[i].sellerId == this.CustomSlaSuppliers[j].clientId){
            this.Tyres[i].Slaslots = this.CustomSlaSuppliers[j].Slots;
            this.Tyres[i].showSlots = true;
          }
        }
        //Show either Retail or Sellout
        // if(this.showRetail)
        // {
        //     this.Tyres[i].priceShow = 1
        // }
        // else
        // {
        //   this.Tyres[i].priceShow = 2
        // }
        //Set Retails Less Values
        this.Tyres[i].retailValuePercent = 0;
        this.Tyres[i].retailValueLess =  this.Tyres[i].retail_Vat;
        this.Tyres[i].meta = new TyreMeta();
        // Default Ordering 
        if(this.Tyres[i].seller_rank == null){
          if(this.Tyres[i].order_rank != null){
            this.Tyres[i].seller_rank = this.Tyres[i].order_rank
          }
        }
      }
      this.creditlimits = res.creditLimits;
      this.superbulk = res.superBulk;
      this.bulk = res.bulk;
      this.required = res.required;
      this.suppliers = res.suppliers;
      
      localStorage.setItem('required', JSON.stringify(res.required));
      localStorage.setItem('lastcall', res.lastcall);
      this.Tyres = this.sortStock(this.Tyres) 

      // Sets the inCart and inQuote values to true for refresh purposes
      this.Tyres.filter(val => this.cart.find(obj => obj.msfid === val.msfid && obj.sellerId === val.sellerId) !== undefined).forEach(val => val.inCart = true); 
      this.Tyres.filter(val => this.quote.find(obj => obj.msfid === val.msfid && obj.sellerId === val.sellerId) !== undefined).forEach(val => val.inQuote = true);

      this.isLoading = false;
      
      //this.isInBuyingMode = true;
      this.isInSellingMode = localStorage.getItem("isInSellingMode") ? localStorage.getItem("isInSellingMode") === 'true' : false;          
      
      this.updateStockActionText();
      // this.loading = false;
      this.load();
    },
    error =>
    {
      this.notification.handleError(error);
    });

  }

  sortStock(array:Tyres[])
  {
    const orderByKey = 'brand_rank';
    const sortByKey = 'seller_rank';

    //Group Items in array
    const groupedData = this.Tyres.reduce((acc, obj) => {
      const group = obj[sortByKey];
      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push(obj);
      return acc;
    }, {} as { [key: string]: any[] });

    // Sort each group by 'orderByKey'

    for (const group in groupedData) {
      if (Object.prototype.hasOwnProperty.call(groupedData, group)) {
        groupedData[group].sort((a, b) => a[orderByKey] - b[orderByKey]);
      }
    }

    // Merge groups into a single array and sort by 'sortByKey'

    const result = Object.values(groupedData).reduce((a, b) => a.concat(b), []).sort((a, b) => {
      if (a[sortByKey] === b[sortByKey])
      {
        // If seller_rank is the same, sort by sellerId
        return a.sellerId - b.sellerId;
      }
      return a[sortByKey] - b[sortByKey];
    });

    return result



  }


 // Shows and hides the tooltips for the supplier in mobile view

  removeTooltip(item: Tyres) {
    let index = this.Tyres.findIndex(val => val === item);

    this.Tyres[index].tooltipVisible = true;
    setTimeout(
      () => {
        this.Tyres[index].tooltipVisible = false;
      }
      ,1200)
  }

  private updateLineItemGPList(item: Tyres) {
    // item.gp_percent = Math.round(100 * (item.market_price - item.buy_price) / item.buy_price)
    item.gp_percent = Math.round(100 - (item.buy_price/item.market_price)*100) // Will monitor fr any errors
    item.gp_rands = Math.round(item.market_price - item.buy_price);

    item.Gp_Percenthigh = item.gp_percent + this.gpUpDownPercent;
    item.Gp_Percentlow = item.gp_percent - this.gpUpDownPercent;
    item.Gp_Percent = item.gp_percent;


    item.Gp_Rands = item.gp_rands;

    item.Gp_RandsHigh = Math.round((item.buy_price / (100 - item.Gp_Percenthigh) * 100) - item.buy_price);
    item.Gp_Randslow = Math.round((item.buy_price / (100 - item.Gp_Percentlow) * 100) - item.buy_price);

    item.Five_High = +((item.buy_price + item.Gp_RandsHigh) * this.vatRate).toFixed(0);
    item.Five_low = +((item.buy_price + item.Gp_Randslow) * this.vatRate).toFixed(0);

  }

  reset()
  {
    this.Tyres = [];
    this.required = required[""];
    this.suppliers = [];
    this.creditlimits = new creditlimits();
    this.superbulk = new superbulk();
    this.bulk = new bulk();
    this.supplierInfo = {client:0,name:"",cell:"",email:"",tc:"",credit:0,dis:0,sprdis:0 };
    this.settings = {Extref: "",Milage: 0, Make: "",Vreg: "",Salesman: "",Delivery: "",Txt: "", userId: 0, isDelivery: null};
  }

  loadCart()
  {
    this.required = [];
    this.required = JSON.parse(localStorage.getItem('required')!);
    this.load();
  }

  load()
  {
    this.cartItems = 0;
    this.quoteItems = 0;
    this.cartTotal = 0;
    if(sessionStorage.getItem('Quote'))
    {
      this.isAuto = false;
    }
    else
    {
      this.isAuto = true;
    }
    if(sessionStorage.length > 0)
    {
      var Cart = JSON.parse(sessionStorage.getItem('Cart')!) as any[];
      var Quote = JSON.parse(sessionStorage.getItem('Quote')!) as any[];
      if( Cart != null && Cart.length !== 0){
          this.cart = [];
          this.OrderFrom = " - " + Cart[0].seller;
          this.OrderSla = " +/- " + Cart[0].sla;
          this.cartItems =  Cart.length;
          this.checkout = true;
          for (var i = 0; i < Cart.length; i++){
              //Retriveobject from session Storage
              var item = Cart[i];
              //
              var Tyre = this.Tyres.find(tyre => tyre.msfid == item.msfid && tyre.sellerId == item.sellerId && tyre.stockUsedId === item.usedStockId)!;

              if(Tyre != null || Tyre != undefined)
              {
                Tyre.inCart = true;
              }

              //Set SelectedIndex
              item.selectedIndex = item.qty;

              //Set Object
                var cartobj = new CartObj();
                item.soh=item.sohInt
                cartobj = item;
              //Add To Cart
                this.cart.push(cartobj);

              //ask about total
                this.cartTotal =  this.cartTotal + Math.round(item.price*item.qty);

              //check minorder display
                var findMin = Cart.find(item => item.id == "idMOF");
                if( findMin != null || findMin != undefined)
                {
                  this.minenabled = true;
                }

              //Display Extra Options
                if ( this.required["s"+item.sellerId].milage ==1)
                {
                  this.showMilage = true;
                };
                if ( this.required["s"+item.sellerId].make ==1)
                {
                  this.showMake = true;
                };
                if ( this.required["s"+item.sellerId].reg ==1)
                {
                  this.showVreg = true;
                };
                if ( this.required["s"+item.sellerId].ext_txt ==1)
                {
                  this.showTxt = true;
                };
                if ( this.required["s"+item.sellerId].ext_ref ==1)
                {
                  this.showExtref = true;
                };
                if ( this.required["s"+item.sellerId].salesman ==1)
                {
                  this.showSalesman = true;
                };
                if ( this.required["s"+item.sellerId].delivery ==1)
                {
                  this.showDelivery = true;
                };
                if (this.required["s"+item.sellerId].isDeliveryOptionShowing ==1)
                {
                  this.showPickupOrDelivery = true;
                }
          }
      }
      if(Quote != null)
      {
        this.quote = [];
        this.quoteItems = Quote.length;
        this.manualQuote = true;
        for (var i = 0; i < Quote.length; i++)
        {

          var item = Quote[i];
          var Tyre = this.Tyres.find(tyre => tyre.msfid == item.msfid && tyre.sellerId == item.sellerId && tyre.stockUsedId === item.usedStockId)!;
          if(Tyre != null || Tyre != undefined)
          {
            Tyre.inQuote = true;
          }
          var cartobj = new CartObj();
          cartobj = item;
          this.quote.push(cartobj);
        }
      }
    }
    else
    {
        this.resetCart();
    }
    this.cart.sort((a, b) => b.price - a.price);
  }

  resetCart()
  {
    this.showMilage = false;
    this.showMake = false;
    this.showVreg = false;
    this.showTxt  = false;
    this.showExtref = false;
    this.showSalesman = false;
    this.showDelivery = false;
    this.OrderFrom = "";
    this.checkout = false;
    this.settings = {Extref: "",Milage: 0, Make: "",Vreg: "",Salesman: "",Delivery: "",Txt: "", userId: 0, isDelivery: null};
  }

  openInfo(id)
  {
    this.supplierInfo = new suppliers();
    this.suppliers.forEach((supplier) => {
      if(supplier.client == id)
      {
          this.supplierInfo = supplier;
      }
    });
  }

  //---------Toggle elements----------->

  toggleShowCart(supplierId: number){
    this.openInfo(supplierId);
    sessionStorage.setItem('Cart', JSON.stringify(this.cart.filter(val => val.sellerId === supplierId)));
    sessionStorage.setItem('Quote', JSON.stringify(this.quote.filter(val => val.sellerId === supplierId)));

    this.Tyres.filter(val => (val.inCart === true || val.inQuote === true) && val.sellerId !== supplierId).forEach((val) => {val.inCart = false; val.inQuote = false});

    this.cart = [];
    this.quote = [];

    this.isShownCart = true;
    this.isShop =false;
    this.isConfirmModalVisible = false;
    this.loadCart();
  }

  toggleShowQuote() {
    this.isShownQuote = true;
    this.isShop = false;
    this.loadCart();
  }

  toggleShow(){
    this.isShop = true;
    this.isShownCart  = false;
    this.isShownQuote =false;
  }

  updateStockActionText() {
    if(!this.isInSellingMode)
    {
      //this.stockProcess = "Buying";
      this.stockAction = "Cost";
      this.switchTo = "Switch To Selling";
      this.vatShow = "(excl)"
    }
    else
    {
      //this.stockProcess = "Selling";
      this.stockAction = "Price";
      this.switchTo = "Switch To Buying";
      this.vatShow ="(Incl)";
      this.logService.addAction(new LogAudit(LogAuditType.SellingMode, this.authService.user.user_id));
      this.setTarget(this.Tyres[0])
    }
  }

  // toggleStockAction()
  // {
  //   // this.isInBuyingMode = !this.isInBuyingMode;
  //   // localStorage.setItem("isInBuyingMode", String(this.isInBuyingMode));
  //   // this.updateStockActionText();
  // }

  switchStockAction() {
    localStorage.setItem("isInSellingMode", String(this.isInSellingMode));
    this.updateStockActionText();
    if(!this.isInSellingMode)
    {
      this.Tyres.forEach(tyre => {
        tyre.highlight = false;
        tyre.isTargetted = false;
      });   
    }
    this.setTarget(this.Tyres[0])
  }


  // togglePrice()
  // {
  //     this.showRetail = !this.showRetail;
  //     if(this.showRetail)
  //     {
  //       this.switchPricing = "Switch To SellOut";
  //       //chanage prices to retail
  //       this.Tyres.forEach(tyre => {
  //         tyre.priceShow = 1
  //       });
  //     }
  //     else
  //     {
  //       this.switchPricing = "Switch To Retail";
  //       //change prices to sellout
  //       this.Tyres.forEach(tyre => {
  //         tyre.priceShow = 2
  //       });
  //     }
  //     localStorage.setItem("togglePrice", JSON.stringify(this.showRetail));

  // }

  //-----Cart Extra Functionality------>

  notSetup(sellername,sellerid)
  {
    if(sellerid == 699)
    {
      this.notification.ShowAlert({type: resultType.Warning,
        title: "Not Registered",
        htmlMessage: 'This stock must be imported <br> Please contact your preferred wholesaler to arrange the importation of this tyre' ,
        maskClosable: false,});
    }
    else
    {
      this.notification.ShowAlert({type: resultType.Warning,
        title: "Not Registered",
        htmlMessage: 'You have not been registered to buy <br> <span class="text-style">'+ sellername +'</span> stock via StockFinder <br> '+
                     'Please buy via National Remote Stock or the <br> <span class="text-style">'+ sellername +'</span> ordering portal',
        maskClosable: false,});
    }
  }

  enableCart(minType,order,minOrder,minOrderFee)
  {
    if(this.minenabled == false)
    {
      if(minType == "Rands")
      {
        this.notification.ShowAlert({type: resultType.Information,
          title: "Minimun Order Fee",
          htmlMessage: 'You have ordered <span class="text-style">R '+ order +'</span>  worth of stock <br>' +
                       'This is less than the minimum order value of <span class="text-style">R' + minOrder +'</span><br>' +
                       'A <span class="text-style">R '+ minOrderFee+'</span>  delivery fee will be added to your order <br><br> ',
          maskClosable: false,
          btnText: 'OK'}, undefined, () => {
            this.minenabled = true;
          });
      }
      else
      {
        this.notification.ShowAlert({type: resultType.Information,
            title: "Minimun Order Fee",
            htmlMessage: 'You have ordered <span class="text-style">'+ order +'</span> Kgs worth of stock <br>' +
                        'This is less than the minimum order mass of <span class="text-style">' + minOrder +'</span> Kgs <br>' +
                        'A <span class="text-style">R '+ minOrderFee+'</span>  delivery fee will be added to your order <br><br> ',
            maskClosable: false,
            btnText: 'OK'}, undefined, () => {
              this.minenabled = true;
            });
      }
    }
  }

  //Boolean check inserted to remove entry. Some functions need it and others don't. Look at 'if statement' before last return

  checkMinOrder(OrderItems,Item, removeCheck: boolean)
  {
    var index = OrderItems.findIndex(i => i.msfid === Item.msfid && i.sellerId === Item.sellerId);
    
      var creditLimit = this.creditlimits["s"+Item.sellerId.toString()];
      var mofInCart = OrderItems.find(i=> i.id == "idMOF");
      var mofToAdd =  {"id":"idMOF","brand":"MOF","image":"coin.png","description":"Minimum Order Fee","price":Item.minOrderFee ,"soh":100,
                       "qty":1,"seller": Item.seller,"sellerId":Item.sellerId,"msfid":23127,"code":"MOF","mass":0,"minOrder":Item.minOrder,"minOrderFee":Item.minOrderFee}
      var totalOrderKg = 0
      var totalOrderRands = 0
      var minOrderWeightorRand = Math.round(OrderItems[0].minOrder)
      var required = this.required["s"+Item.sellerId.toString()] ;

      for (var i = 0; i < OrderItems.length; i++)
      {
        totalOrderRands  += Math.round(OrderItems[i].price*OrderItems[i].qty);
        totalOrderKg += Math.round(OrderItems[i].mass*OrderItems[i].qty);
      }

      //Check if The Orders total Rand Value is more than the creditlimit set
      if(required.creditCheck)
      {
        if (totalOrderRands > creditLimit)
        {
          this.notification.ShowAlert({type: resultType.Warning,
            title: "Credit Limit Warning",
            htmlMessage: 'Unable to add stock item , please contact the Supplier to <br> <span class="text-style"> settle your account </span> or <span class="text-style"> increase your Credit  Limit </span> ',
            maskClosable: false,
            autoCloseInMilliseconds: 10000});
          return
        }
      };

      //check if the set order Rand or Kg is less than 400 = "weight" , more than 400 "Rand Value" is more than what we currently have in cart

      //Checking if we meet the minimum weight requirements
      if(minOrderWeightorRand > totalOrderKg  && minOrderWeightorRand < 400)
      {
        // check if the min order fee is already in cart
        if(mofInCart == undefined)
        {
          OrderItems.push(mofToAdd)
          this.enableCart("weight",totalOrderKg ,Item.minOrder,Item.minOrderFee);
        }
        this.minenabled = true;
      }
      //Checking if we meet minimum rand
      else if ( minOrderWeightorRand  > totalOrderRands)
      {
        if(mofInCart == undefined)
        {
          OrderItems.push(mofToAdd)
          this.enableCart("Rands",totalOrderRands,Item.minOrder,Item.minOrderFee);
          this.minenabled = false;
        }
      }
      else
      {
        //If already in cart 
        if(mofInCart && index !== -1)
        {
          //remove from cart
          var mofIndex = OrderItems.findIndex(i => i.id == "idMOF")
          OrderItems.splice(mofIndex,1);
          this.minenabled = false;
        }

      }
      if(removeCheck && index !== -1)
        OrderItems.splice(index,1);
      return OrderItems
  }

  // checkMinOrder2(OrderItems,Item)
  // {
  //   // var index = OrderItems.findIndex(i => i.id == "id"+Item.stock_code );

  //     var creditLimit = this.creditlimits["s"+Item.sellerId.toString()];
  //     var mofInCart = OrderItems.find(i=> i.id == "idMOF");
  //     var mofToAdd =  {"id":"idMOF","brand":"MOF","image":"coin.png","description":"Minimum Order Fee","price":Item.minOrderFee ,"soh":100,
  //                      "qty":1,"seller": Item.seller,"sellerId":Item.sellerId,"msfid":23127,"code":"MOF","mass":0,"minOrder":Item.minOrder,"minOrderFee":Item.minOrderFee}
  //     var totalOrderKg = 0
  //     var totalOrderRands = 0
  //     var minOrderWeightorRand = Math.round(OrderItems[0].minOrder)
  //     for (var i = 0; i < OrderItems.length; i++)
  //     {
  //       totalOrderRands  += Math.round(OrderItems[i].price*OrderItems[i].qty);
  //       totalOrderKg += Math.round(OrderItems[i].mass*OrderItems[i].qty);
  //     }

  //     //Check if The Orders total Rand Value is more than the creditlimit set
  //     if (totalOrderRands > creditLimit)
  //     {
  //       this.notification.ShowAlert({type: resultType.Warning,
  //         title: "Credit Limit Warning",
  //         htmlMessage: 'Unable to add stock item , please contact the Supplier to <br> <span class="text-style"> settle your account </span> or <span class="text-style"> increase your Credit  Limit </span> ',
  //         maskClosable: false,
  //         autoCloseInMilliseconds: 10000});
  //       return
  //     };

  //     //check if the set order Rand or Kg is less than 400 = "weight" , more than 400 "Rand Value" is more than what we currently have in cart

  //     //Checking if we meet the minimum weight requirements
  //     if(minOrderWeightorRand > totalOrderKg  && minOrderWeightorRand < 400)
  //     {
  //       // check if the min order fee is already in cart
  //       if(mofInCart == undefined)
  //       {
  //         OrderItems.push(mofToAdd)
  //         this.enableCart("weight",totalOrderKg ,Item.minOrder,Item.minOrderFee);
  //       }
  //       this.minenabled = true;
  //     }
  //     //Checking if we meet minimum rand
  //     else if ( minOrderWeightorRand  > totalOrderRands)
  //     {
  //       if(mofInCart == undefined)
  //       {
  //         OrderItems.push(mofToAdd)
  //         this.enableCart("Rands",totalOrderRands,Item.minOrder,Item.minOrderFee);
  //         this.minenabled = false;
  //       }
  //     }
  //     else
  //     {
  //       //If already in cart
  //       if(mofInCart)
  //       {
  //         //remove from cart
  //         var mofIndex = OrderItems.findIndex(i => i.id == "idMOF")
  //         OrderItems.splice(mofIndex,1);
  //         this.minenabled = false;
  //       }

  //     }
  //     // OrderItems.splice(index,1);
  //     return OrderItems
  // }

  //---------------Adding------------->

  addToCart(item: Tyres)
  {

    var Cart = JSON.parse(sessionStorage.getItem('Cart')!) as any[];
    var OrderItems = [] as any[];
    var addItem=1;
	  var add_MOF=0;
    var totOrderKg=item.mass;
    var totOrderR=item.buy_price;
    var nodeId = item.sellerId;
    var creditLimit= this.creditlimits;
    var required = this.required["s"+nodeId] ;

    //check if credit limit is reached
    if(required.creditCheck)
    {
      if (item.buy_price>creditLimit["s"+nodeId])
      {
       addItem=0;
      //  this.notification.ShowAlert({type: resultType.Warning, 
      //   title: "Credit Limit Warning", 
      //   htmlMessage: 'Unable to add stock item , please contact the Supplier to <br> <span class="text-style"> settle your account </span> or <span class="text-style"> increase your Credit  Limit </span> ',
      //   maskClosable: false,
      //   autoCloseInMilliseconds: 10000 });
       return
      }
    };

    //check cart
    if (Cart != null)
    {
      OrderItems = Cart;
    }

    //loop through cart and update totals
  	for (var i = 0; i < OrderItems.length; i++)
	  {
      //Retriveobject from session Storage
        var dict=OrderItems[i];
      //Check If Same Supplier
      if (dict.seller!=item.seller_name)
      {
        addItem=0;
        this.notification.ShowAlert({type: resultType.Warning,
          title: "Unable to Add Item",
          htmlMessage: 'You can only order from one supplier at a time <br> You are currently ordering from <span class="text-style">' + dict.seller + '</span>',
          maskClosable: false,
          autoCloseInMilliseconds: 7000});
        return
      };

      //Check If In Cart
      if (dict.msfid== item.msfid)
      {
        addItem=0;
        this.notification.ShowAlert({type: resultType.Warning,
          title: "Item Not Added",
          htmlMessage: 'You have already added this tyre to the Shopping Cart 🛒 ',
          maskClosable: false,
          autoCloseInMilliseconds: 7000});
        return
      };

      //Update Total Order Price
      totOrderR+=dict.price*dict.qty;

      //Update Total Order KG
      totOrderKg+=dict.mass*dict.qty;
	  }

   //Check Weight in Cart
   if (item.minOrder>totOrderKg && item.minOrder<400 && OrderItems.find(i=> i.id == "idMOF") == undefined)
  {
    //Not enough weight
    add_MOF=1;
    this.enableCart("weight",totOrderKg,item.minOrder,item.minOrderFee);
   }
   else if ( item.minOrder >totOrderR && OrderItems.find(i=> i.id == "idMOF") == undefined)
   {
    add_MOF=1;
    this.enableCart("Rands",totOrderR,item.minOrder,item.minOrderFee);
   }

  //Add To Cart
  if (addItem==1)
  {
    var order = {
      "id":'id'+item.stock_code,
      "brand":item.brand,
      "image":item.image,
      "description":item.description,
      "price":item.buy_price,
      "soh":item.soh,
      "sohInt":item.sohInt,
      "qty":1,
      "seller":item.seller_name,
      "sellerId":item.sellerId,
      "msfid":item.msfid,
      "code":item.stock_code,
      "mass":item.mass,
      "minOrder":item.minOrder,
      "minOrderFee":item.minOrderFee,
      "sla": item.sla
      };
    //Display Extra Options
    required  = ""
    if ( required.milage ==1)
    {
      this.showMilage = true;
    };
    if ( required.make ==1)
    {
      this.showMake = true;
    };
    if ( required.reg ==1)
    {
      this.showVreg = true;
    };
    if ( required .ext_txt ==1)
    {
      this.showTxt = true;
    };
    if ( required.ext_ref ==1)
    {
      this.showExtref = true;
    };
    if ( required.salesman ==1)
    {
      this.showSalesman = true;
    };
    if ( required.delivery ==1)
    {
      this.showDelivery = true;
    };

    //Show Icon
    var i = this.Tyres.findIndex(tyre => tyre.msfid == item.msfid && tyre.sellerId == item.sellerId)!;
    this.Tyres[i].inCart = true;

    //Store in Sess Storage
    this.cartTotal + Math.round(item.buy_price*1.1*1.15);

    // this.notification.showSuccessMinimal('Added ' + item.description + " to Cart");

    //Append to Cart
    OrderItems.push(order)
  }

  //Add MOF
  if (add_MOF==1)
    {
          //Add Min Order Fee
          var minOrder =
          {
          "id":"idMOF",
          "brand":"MOF",
          "image":"coin.png",
          'description':"Minimum Order Fee",
          "price":item.minOrderFee,
          "soh":100,
          "qty":1,
          "seller":item.seller_name,
          "sellerId":item.sellerId,
          "msfid":23127,
          "code":"MOF",
          'mass':0,
          'minOrder':item.minOrder,
          "minOrderFee":item.minOrderFee,
          "sla": "N/A"
          }
          //Append to Cart
          OrderItems.push(minOrder)
  };

  sessionStorage.setItem('Cart', JSON.stringify(OrderItems));
  this.load();
  }

  addToQuote(item:Tyres)
  {
     var Quote = JSON.parse(sessionStorage.getItem('Quote')!) as any[];
     var OrderItems = [] as any[];
     if (Quote  != null)
     {
       OrderItems = Quote;
     }
     var order = {
      "id":'id'+item.stock_code,
      "brand":item.brand,
      "image":item.image,
      "description":item.description,
      "price":item.buy_price,
      "soh":item.soh,
      "sohInt":item.sohInt,
      "qty":1,
      "seller":item.seller_name,
      "sellerId":item.sellerId,
      "msfid":item.msfid,
      "code":item.stock_code
      };

    //Show Icon
    var i = this.Tyres.findIndex(tyre => tyre.msfid == item.msfid && tyre.sellerId == item.sellerId)!;
    this.Tyres[i].inQuote = true;

    //Append to Quote
    OrderItems.push(order)

    // this.notification.showSuccessMinimal('Added ' + item.description + " to Quote");

    sessionStorage.setItem('Quote', JSON.stringify(OrderItems));
    this.load()
  }

  //--------------- Modifying ------------>

  counter(n: any) : Array<number> {
    return Array(n);
  }

  changeQuantity(item,event)
  {
    var Cart = JSON.parse(sessionStorage.getItem('Cart')!) as any[];
    var OrderItems = Cart;
    var Item = OrderItems.find(i => i.id == "id"+item.code);

    if(event > Item.qty )
    {
      for(var i = 0; i < Math.abs(event-Item.qty) ;i++)
      {
        this.plusQuantity(Item);
      }
    }
    else
    {
      for(var i = 0; i < Math.abs(event-Item.qty) ;i++)
      {
        this.minusQuantity(Item);
      }
    }
  }

  plusQuantity(Item)
  {
    var OrderItems = JSON.parse(sessionStorage.getItem('Cart')!) as any[];
    var Item = OrderItems.find(i => i.id == "id"+Item.code);
    if(Item.sohInt == Item.qty)
    {
      this.notification.ShowAlert({type: resultType.Warning,
        title: "Oops",
        htmlMessage: 'The Maximum Avaible Quantity for this Stock has been reached',
        maskClosable: false,
        autoCloseInMilliseconds: 10000});

      return
    }
    else
    {
      Item.qty = Item.qty + 1;
    }
     sessionStorage.setItem('Cart', JSON.stringify(this.checkMinOrder(OrderItems,Item, false)));
     this.loadCart();
  }

  minusQuantity(Item)
  {
    var OrderItems = JSON.parse(sessionStorage.getItem('Cart')!) as any[];

    var Item = OrderItems.find(i => i.id == "id"+Item.code);

    if(Item.qty == 1 )
    {
        OrderItems.splice(OrderItems.findIndex(i => i.id == "id"+Item.code),1);

        var I = this.Tyres.find(tyre => tyre.msfid == Item.msfid && tyre.sellerId == Item.sellerId)!;

        if(I != undefined || I != null)
        {
          I.inCart = false;
        }
    }
    else
    {
      Item.qty = Item.qty - 1 ;
    }

    //Check number of Items in cart

    if(OrderItems.length == 0)
    {
      this.clearCart();
      return;
    }
    else if (OrderItems.length == 1)
    {
      if(OrderItems[0].id == "idMOF")
      {
        this.clearCart();
        return
      }
    }

    sessionStorage.setItem('Cart', JSON.stringify(this.checkMinOrder(OrderItems,Item, false)));
    this.loadCart();

  }

  percentFormatter(value: number) {
    if (value === null) {
      return "";
    }
    else {
      return value.toString() + "%";
    }
  }

  private timerRetail: any;

  CalculateLessRetail(item:Tyres,value:any)
  {
    item.retailValuePercent = value;
    item.retailValueLess = Math.round(item.retail_Vat - ((value/100)*item.retail_Vat));

    clearTimeout(this.timerRetail);

    this.timerRetail = setTimeout(() => {
      this.logService.addAction(new LogAudit(LogAuditType.RetailLess, this.authService.user.user_id));
    }, 500);
  }

  setTarget(item:Tyres)
  {
    if(this.isInSellingMode)
    {     
      this.Tyres.forEach(tyre => {
        tyre.isTargetted = false;
        tyre.highlight = false;
        if(tyre.market_price_Vat < item.market_price_Vat && tyre.Gp_Rands > item.Gp_Rands)
        {
          tyre.highlight = true;  
          tyre.gpExtra = tyre.gp_rands //Math.round(tyre.gp_rands - item.gp_rands) 
        }
      });
      item.isTargetted = true;

    }
  }

  //---------------Removing--------------->
  removeFromCart(item)
  {
    var Item = this.Tyres.find(tyre => tyre.msfid == item.msfid && tyre.sellerId == item.sellerId)!;
    if (Item != undefined)
    {
      Item.inCart = false
    }

    var Cart = JSON.parse(sessionStorage.getItem('Cart')!) as any[];

    var OrderItems = Cart;

    // var index = OrderItems.findIndex(i => i.id == "id"+item.code);
    this.checkMinOrder(OrderItems,Item, true);

    // OrderItems.splice(index,1);
    if (OrderItems.length == 0)
    {
      this.clearCart();
    }
    else
    {
        if(OrderItems.length == 1)
        {
          var mof = OrderItems.find(i => i.id == "idMOF");
          if(mof != undefined)
          {
            this.minenabled = false;
            this.clearCart();
          }
          else
          {
            sessionStorage.setItem('Cart', JSON.stringify(OrderItems));
            this.loadCart();
          }
        }
        else
        {
          sessionStorage.setItem('Cart', JSON.stringify(OrderItems));
          this.loadCart();
        }
    }
    this.notification.showSuccessMinimal("Successfully Removed");

  }


  // removeFromTableCart(Item)
  // {
  //   //Show Icon
  //   this.Tyres.find(tyre => tyre.msfid == Item.msfid && tyre.sellerId == Item.sellerId)!.inCart = false;
  //   var Cart = JSON.parse(sessionStorage.getItem('Cart')!) as any[];  
  //   var OrderItems = Cart;
  //   // var index = OrderItems.findIndex(i => i.id == "id"+Item.stock_code );
  //   // OrderItems.splice(index,1);
  //   sessionStorage.setItem('Cart', JSON.stringify(this.checkMinOrder(OrderItems,Item, true)));
  //   if(OrderItems.length == 0)
  //   {
  //     sessionStorage.removeItem('Cart');
  //     this.clearCart();
  //   }
  //   else
  //   {
  //     this.load();
  //   }
  //   // this.notification.showSuccessMinimal('Removed ' + Item.description);

  // }

  // removeFromTableQuotes(Item)
  // {
  //   //Show Icon
  //   this.Tyres.find(tyre => tyre.msfid == Item.msfid && tyre.sellerId == Item.sellerId)!.inQuote = false;
  //   var Quote = JSON.parse(sessionStorage.getItem('Quote')!) as any[];
  //   var OrderItems = Quote;
  //   var index = OrderItems.findIndex(i => i.id == "id"+Item.stock_code );
  //   OrderItems.splice(index,1)
  //   sessionStorage.setItem('Quote', JSON.stringify(OrderItems));
  //   if(OrderItems.length == 0)
  //   {
  //     sessionStorage.removeItem('Quote');
  //     this.clearQuote();
  //   }
  //   else
  //   {
  //     this.load();
  //   }
  //   // this.notification.showSuccessMinimal('Removed ' + Item.description);

  // }
  removeTableItem(Item: Tyres){
    // // Cart
    // //Show Icon
    this.Tyres.find(tyre => tyre.msfid == Item.msfid && tyre.sellerId == Item.sellerId && tyre.stockUsedId === Item.stockUsedId)!.inCart = false;
    var Cart = JSON.parse(sessionStorage.getItem('Cart')!) as any[];
    var Quote = JSON.parse(sessionStorage.getItem('Quote')!) as any[];  
    var CartItems = Cart;

    // var index = OrderItems.findIndex(i => i.id == "id"+Item.stock_code );
    // OrderItems.splice(index,1);
    if(CartItems && CartItems.length > 0)
      sessionStorage.setItem('Cart', JSON.stringify(this.checkMinOrder(CartItems,Item, true)));
    if(!CartItems || CartItems.length == 0){
      sessionStorage.removeItem('Cart');
      this.clearCart();
    }
    else
    {
      this.load();
    }
    // // Quote
    // //Show Icon
    this.Tyres.find(tyre => tyre.msfid == Item.msfid && tyre.sellerId == Item.sellerId && tyre.stockUsedId === Item.stockUsedId)!.inQuote = false;
    var Quote = JSON.parse(sessionStorage.getItem('Quote')!) as any[];
    var QuoteItems = Quote;
    var index = QuoteItems.findIndex(i => i.msfid === Item.msfid && i.sellerId === Item.sellerId && i.stockUsedId === Item.stockUsedId);
    QuoteItems.splice(index,1)
    sessionStorage.setItem('Quote', JSON.stringify(QuoteItems));
    if(QuoteItems.length == 0 && (!sessionStorage.getItem('Cart')))
    {
      sessionStorage.removeItem('Quote');
      this.clearQuote();
    }
    else
    {
      this.load();
    }
  }

  //---------------Clearing--------------->

  clearCart(){
    this.OrderSla = "";
    this.OrderFrom = "";
    this.cartItems = 0;
    this.cart = [];

    sessionStorage.removeItem('Cart');
    // localStorage.removeItem('required');
    this.minenabled = false;
    this.Tyres.filter(val => val.inCart === true).forEach((val) => {val.inCart = false; val.inQuote = false});    

  }

  clearQuote(){
    this.quoteItems = 0;
    this.quote = [];
    sessionStorage.removeItem('Quote');
    this.Tyres.filter(val => val.inQuote === true).forEach((val) => {val.inCart = false; val.inQuote = false});
  }

  //-----------Final Destination----------->
  Cart_Issues: CartErrors[]
  checkOut()
  {
    //check Inpus for data
    if (this.showMilage === true) {
      if (this.settings.Milage === 0 || !Number.isInteger(this.settings.Milage)) {
        this.notification.ShowAlert({
          type: resultType.Warning,
          title: "Invalid or Missing Milage",
          htmlMessage: 'Please provide a valid integer value for the Milage to process the order.',
          maskClosable: false,
          autoCloseInMilliseconds: 10000});
        return;
      }
    };
    if (this.showMake == true)
    {
      if(this.settings.Make == "")
      {
        this.notification.ShowAlert({type: resultType.Warning,
          title: "Missing Vehicle Make",
          htmlMessage: 'Please Complete the Vehicle Make Input for the Order to Process',
          maskClosable: false,
          autoCloseInMilliseconds: 10000});
      return
      }
    };
    if (this.showVreg == true)
    {
      if(this.settings.Vreg == "")
      {
        this.notification.ShowAlert({type: resultType.Warning,
          title: "Missing Vehicle Reg",
          htmlMessage: 'Please Complete the Vehicle Registration Input for the Order to Process',
          maskClosable: false,
          autoCloseInMilliseconds: 10000});

      return
      }
    };

    if (this.showExtref == true)
    {
      if(this.settings.Extref == "")
      {
        this.notification.ShowAlert({type: resultType.Warning,
        title: "Missing Reference",
        htmlMessage: 'Please Complete the Reference Input for the Order to Process',
        maskClosable: false,
        autoCloseInMilliseconds: 10000});

      return
      }
    };
    if (this.showSalesman == true)
    {
      if(this.settings.Salesman == "")
      {
        this.notification.ShowAlert({type: resultType.Warning,
          title: "Missing Salesman Data",
          htmlMessage: 'Please Complete the Salesman Input for the Order to Process',
          maskClosable: false,
          autoCloseInMilliseconds: 10000});
      return
      }
    }
    else
    {
      this.settings.Salesman = this.authService.user.username;
    };
    if (this.showDelivery == true)
    {
      if(this.settings.Delivery == "")
      {
        this.notification.ShowAlert({type: resultType.Warning,
          title: "Missing Delivery Data",
          htmlMessage: 'Please Complete the Delivery Input for the Order to Process',
          maskClosable: false,
          autoCloseInMilliseconds: 10000});
      return
      }
    };

    if (this.showPickupOrDelivery == true)
      {
        if(this.settings.isDelivery == null)
        {
          this.notification.ShowAlert({type: resultType.Warning,
            title: "Missing Delivery Data",
            htmlMessage: 'Please select Pick Up or Delivery for the Order to Process',
            maskClosable: false,
            autoCloseInMilliseconds: 10000});
        return
        }
      };
    //Log salesman
    this.settings.userId = this.authService.user.user_id;

    var Order = {
                  "OrderDetails": this.processCart(),
                  "Settings": this.settings ,
                  "lastcall": localStorage.getItem('lastcall')}

    this.showProcessing();

    this.searchService.PlaceOrder(Order).subscribe((res:any) =>
    {
      this.notification.removeAlert();
      if(res.phase != 1)
      {
        this.notification.ShowAlert({type: resultType.Error,
          title: "Error Placing Order",
          htmlMessage: res["message"] ,
          maskClosable: false,
          autoCloseInMilliseconds: 5000});
      }
      if(res.phase == 4)
      {
        this.Cart_Issues = []
        for(let x =0; x < res.issues.length;x++){
          let issueItem = new CartErrors
          let issues_json = JSON.parse(res.issues[x]);
          issueItem.code = issues_json['code'];
          if(issues_json['code'].length == 0){
            issueItem.error_message = "Error: Material status set to not sellable …";
          }

          issueItem.error_id = issues_json['error_id'];
          issueItem.error_txt = issues_json['error_txt'];
          issueItem.req_qty = 0;
          issueItem.av_qty = 0;
          if( issueItem.error_id == '0'){
            let lineData = JSON.parse(issues_json['line_data']);
            issueItem.req_qty = lineData['orderedArticle']['requestedQuantity']['quantityValue'];
            if(lineData['orderedArticle']['scheduleDetails'].length > 0){
              issueItem.av_qty = lineData['orderedArticle']['scheduleDetails'][0]['availableQuantity']['quantityValue']
            }else{
              issueItem.av_qty = 0;
            }
          }
          issueItem.error_message = "555";
          if(issueItem.error_txt.includes("Invalid Qty")){
            issueItem.error_message = "Invalid Quantity, Only "+issueItem.av_qty+" Available"
          }

          if(issueItem.error_txt.includes("Material")){
            issueItem.error_message = issueItem.error_txt;
            issueItem.error_message = "Error: Material status set to not sellable …";
          }

          for(let n =0; n < this.cart.length;n++){
            if(this.cart[n].code == issueItem.code || issueItem.error_id.toString() == "945"){
              this.cart[n].error_message = issueItem.error_message;
              this.cart[n].soh = issueItem.av_qty;


            }
          }
          this.cart = this.cart.slice();
          this.Cart_Issues.push(issueItem);
          this.Cart_Issues = this.Cart_Issues.slice();
        }
        this.notification.ShowAlert({type: resultType.Error,
          title: "Inquiry Issues Occured For Certain Items",
          htmlMessage: res["message"] ,
          maskClosable: false,
          autoCloseInMilliseconds: 5000});
      }
      else
      {
        if (res['eta'] === undefined) {
          this.notification.ShowAlert({type: resultType.Success,
            title: "Order Placed",
            htmlMessage: 'Your Order has been Placed Successfully <br>' +
                         'Remember to Reload if order is not Visable',
            maskClosable: false,
            autoCloseInMilliseconds: 3000})
          .afterClose.subscribe(_ => {
              localStorage.removeItem('required');
              sessionStorage.clear();
              this.router.navigate(['/purchases']);
            });
        }
        else {
          this.notification.ShowAlert({type: resultType.Success,
            title: "Order Placed",
            htmlMessage: 'Your Order has been Placed Successfully <br />' +
                         'Remember to Reload if order is not Visable' +
                        '<br /><b>ESTIMATED DELIVERY DATE: ' + res['eta'] + '</b> <br /><br />' +
                        '<i>Please click close to take note of the expected delivery date.</i>',
            maskClosable: false,
            btnText: "Close"})
          .afterClose.subscribe(_ => {
              localStorage.removeItem('required');
              sessionStorage.clear();
              this.router.navigate(['/purchases']);
            });
        }
        // this.notification.ShowAlert({type: resultType.Success,
        //   title: "Order Placed",
        //   htmlMessage: 'Your Order has been Placed Successfully <br>' +
        //                'Remember to Reload if order is not Visable' +
        //                res['eta'] === undefined ? '<br />ESTIMATED DELIVERY DATE: ' + res['eta'] : 'NO DELIVERY DATE',
        //   maskClosable: false,
        //   autoCloseInMilliseconds: 3000}).afterClose.subscribe(e => {console.log("I just closed")});
        // setTimeout(() =>
        // {
        //  localStorage.removeItem('required');
        //  sessionStorage.clear();
        //  this.router.navigate(['/purchases']);
        //  },5000);
      }
    },
    error =>
    {
      this.notification.handleError(error);
    });

  }

  showProcessing()
  {
    this.notification.ShowAlert({type: resultType.Information,
      title: "Placing Order",
      htmlMessage: 'Your Order is being placed <br>'+
                   ' It may take up to 30 secs to complete <br>'+
                   '<i nz-icon style="font-size:24px"  class="text" nzType="sync" nzTheme="outline"></i>' ,
      maskClosable: false,
      autoCloseInMilliseconds: 15000});
    return
  }

  processCart()
  {
    var localData = [] as any;
    var Cart = JSON.parse(sessionStorage.getItem('Cart')!) as any[];
    if( Cart != null)
    {
      for ( var i = 0, len = Cart.length; i < len; ++i )
      {
        var item = Cart[i];
        item.soh=item.sohInt
        localData.push(item);
      }
    }
    return localData;
  }

  processQuote()
  {
    var localData = [] as any;
    var Quote = JSON.parse(sessionStorage.getItem('Quote')!) as any[];
    if( Quote != null)
    {
      for ( var i = 0, len = Quote.length; i < len; ++i )
      {
       var item = Quote[i];
       item.soh=item.sohInt
       localData.push(item)
      }
    }
    return localData;

  }


  AutoQuote()
  {
    this.isAutoQuoting = true;
    let cust = Globals.funcs.getCustomer();
    let StockMsfids = new Array();
    let RecommendedStock = true;

    let quote : aautoquote = {
      sellerId : this.authService.user.client_id,
      ext_Ref : "",
      comment :"",
      name: cust.name,
      email: cust.email,
      cell:cust.cell,
      vreg: cust.vreg,
      customerId: cust.customerId,
      assignedToUserId: this.authService.user.user_id,
      lastcall: parseInt(localStorage.getItem('lastcall')!),
      quote_logic : {
                      search_requirements :
                                            {
                                              raw_search: this.searchobj.search,
                                              stock_msfids:StockMsfids,
                                              none_stock_msfids: [],
                                              buyerId: this.authService.user.client_id,
                                              specialPriceSetId: this.lastSelectedPriceSetId
                                            },
                      add_lead: true,
                      qty: 4,
                      allow_partial_full:true,
                      base_level_limit: "Remote",
                      add_recommended_stock:RecommendedStock,
                      max_recommended_options: 5,
                      recommended_level_limit: "Remote",
                      add_default_services: true,
                      isContractPricing: false
                    }
    }
    this.QuoteSubscribeCall(quote);
  }
  ManualQuote(){
    this.isAutoQuoting = true;

    let cust = Globals.funcs.getCustomer();
    let StockMsfids: FixedTyreItem [] = [];
    let usedStockIds: number[] = [];
    let CartItems: Tyres [] = this.processQuote();
    let RecommendedStock = false;
    

    // Get all the tyres, with their locations, for the quote
    StockMsfids = CartItems.filter(e => !e.stockUsedId).map(
      (val) => ({
        msfid: val.msfid,
        sellerId: val.sellerId
      })
    );
    //Get all the used Stock ID's, to send to quote. 
    usedStockIds = CartItems.filter(e => e.stockUsedId).map(val => val.stockUsedId!);
    
    let quote : aautoquote = {
      sellerId : this.authService.user.client_id,
      ext_Ref : "",
      comment :"",
      name: cust.name,
      email: cust.email,
      cell:cust.cell,
      vreg: cust.vreg,
      customerId: cust.customerId,
      assignedToUserId: this.authService.user.user_id,
      lastcall: parseInt(localStorage.getItem('lastcall')!),
      quote_logic : {
                      search_requirements :
                                            {
                                              raw_search: this.searchobj.search,
                                              stock_msfids: [],
                                              none_stock_msfids: [],
                                              fixed_tyre_items: StockMsfids,
                                              used_stock_ids: usedStockIds,
                                              buyerId: this.authService.user.client_id,
                                              specialPriceSetId: this.lastSelectedPriceSetId
                                            },
                      add_lead: true,
                      qty: 4,
                      allow_partial_full:true,
                      base_level_limit: "Remote",
                      add_recommended_stock:RecommendedStock,
                      max_recommended_options: 5,
                      recommended_level_limit: "Remote",
                      add_default_services: true,
                      isContractPricing: false
                    }
    }
    this.QuoteSubscribeCall(quote, true);
  }

  QuoteSubscribeCall(quote: aautoquote, isManualQuote: boolean = false){
    this.searchService.Autoquote(quote, {isManualQuote : isManualQuote}).subscribe((res:any) => {
      this.isAutoQuoting = false;
        if(res.quoteId!= 0)
        {
          this.notification.showSuccess("Quote created successfully");
          localStorage.removeItem('fleetData');
          localStorage.removeItem('customerData');
          sessionStorage.clear();
          this.router.navigate(["/pos/v2",res.quoteId]);

          // Creates an action log for quoting.
          if(isManualQuote === false)
            this.logService.addAction(new LogAudit(LogAuditType.QuickQuote, this.authService.user.user_id));
          else if (isManualQuote === true)
          this.logService.addAction(new LogAudit(LogAuditType.ManualQuote, this.authService.user.user_id));
        }
      else {
        if(isManualQuote && isManualQuote === true)
          this.notification.showWarning(`An error occured with your quotation. Please contact support.`)
        else
          this.notification.showWarning(`An error occured with your autoquote for '${this.searchobj.search}'. Please contact support.`)
      }
    });
  }

  //--------------------------------------->

  formatOne(percent: number)
  {
    return ` `;
  }

  onImageHover(image:any): void {
    const images = [
      {
        src: image,
        width: '250px',
        height: '300px',
        alt: 'ng-zorro'
      },
    ];
    this.nzImageService.preview(images, { nzZoom: 1.5, nzRotate: 0 });
  }

  metaDisplay = false;
  ismetaLoading = false;
  showingMeta: Tyres = new Tyres();
  fetchMeta(tyre:Tyres)
  {
    this.showingMeta= new Tyres();
    this.showingMeta = tyre;
     if(tyre.show_meta && (tyre.meta.image == '' || tyre.meta.image == null))
     {
      this.ismetaLoading = true;
      this.searchService.fetchMeta(tyre.msfid).subscribe(res => {
        tyre.meta = res;
        this.ismetaLoading = false;
        this.logService.addAction(new LogAudit(LogAuditType.StockImage, this.authService.user.user_id));
      },
      error =>
      {
        this.notification.handleError(error);
      });
     }
     this.metaDisplay = true;
  }
  private timer: any;
  private timerActive: boolean = false;
  popupLog(){

    this.timerActive = !this.timerActive;
    clearTimeout(this.timer);

    if(this.timerActive){
      this.timer = setTimeout(() => {
        this.logService.addAction(new LogAudit(LogAuditType.PriceHover, this.authService.user.user_id));
      }, 2000);
    }

  }

  addToItem(item:Tyres){
    // var OrderItems = [] as any[];
    let CartItems = [] as any[];
    let QuoteItems = [] as any[];

  //   // CART SECTION
    let Cart = JSON.parse(sessionStorage.getItem('Cart')!) as any[];
    let addItem=1;
	  let add_MOF=0;
    let totOrderKg=item.mass;
    let totOrderR=item.buy_price;
    let nodeId = item.sellerId;
    let creditLimit= this.creditlimits;
    let required = this.required["s"+nodeId] ;

    // *ngIf="item.seller_sell_state != 'no' && item.seller_sell_state != 'soh'"
    if(item.seller_sell_state === 'no'){
      // this.notification.showWarningTimer('Cannot order from ' + item.seller_name + ' directly through StockFinder.', 3000);
    }
    else{
    //check if credit limit is reached
    if(required.creditCheck)
    {
      if (item.buy_price>creditLimit["s"+nodeId])
      {
       addItem=0;
       this.notification.ShowAlert({type: resultType.Warning, 
        title: "Credit Limit Warning", 
        htmlMessage: 'Unable to add stock item , please contact the Supplier to <br> <span class="text-style"> settle your account </span> or <span class="text-style"> increase your Credit  Limit </span> ',
        maskClosable: false,
        autoCloseInMilliseconds: 10000 });
       return
      }
    };
    //check cart
    if (Cart != null)
    {
      CartItems = Cart;

    }

    //loop through cart and update totals
  	for (var i = 0; i < CartItems.length; i++){
      //Retriveobject from session Storage
        var dict=CartItems[i];
      //Check If Same Supplier
      // if (dict.seller!=item.seller_name)
      // {
      //   addItem=0;
      //   this.notification.ShowAlert({type: resultType.Warning, 
      //     title: "Unable to Add Item", 
      //     htmlMessage: 'You can only order from one supplier at a time <br> You are currently ordering from <span class="text-style">' + dict.seller + '</span>',
      //     maskClosable: false,
      //     autoCloseInMilliseconds: 7000});
      //   return
      // };

      //Check If In Cart
      // if (dict.msfid== item.msfid)
      // {
      //   addItem=0;
      //   this.notification.ShowAlert({type: resultType.Warning, 
      //     title: "Item Not Added", 
      //     htmlMessage: 'You have already added this tyre to the Shopping Cart 🛒 ',
      //     maskClosable: false,
      //     autoCloseInMilliseconds: 7000});
      //   return
      // };

      //Update Total Order Price
      totOrderR+=dict.market_price*dict.qty;

      //Update Total Order KG
      totOrderKg+=dict.mass*dict.qty;
	  }

    //Check Weight in Cart
    if (item.minOrder>totOrderKg && item.minOrder<400 && CartItems.find(i=> i.id == "idMOF") == undefined){
      //Not enough weight
      add_MOF=1;
      this.enableCart("weight",totOrderKg,item.minOrder,item.minOrderFee);
    }
    else if ( item.minOrder >totOrderR && CartItems.find(i=> i.id == "idMOF") == undefined){
      add_MOF=1;
      this.enableCart("Rands",totOrderR,item.minOrder,item.minOrderFee);
    }

    //Add To Cart
    if (addItem==1){
      var orderCart = {
        "id":'id'+item.stock_code,
        "brand":item.brand,
        "image":item.image,
        "description":item.description,
        "price":item.buy_price,
        "soh":item.soh,
        "sohInt":item.sohInt,
        "qty":1,
        "seller":item.seller_name,
        "sellerId":item.sellerId,
        "msfid":item.msfid,
        "code":item.stock_code,
        "mass":item.mass,
        "minOrder":item.minOrder,
        "minOrderFee":item.minOrderFee,
        "sla": item.sla,
        "stockUsedId": item.stockUsedId
        };
      //Display Extra Options
      required  = ""
      if ( required.milage ==1)
      {
        this.showMilage = true;
      };
      if ( required.make ==1)
      {
        this.showMake = true;
      };
      if ( required.reg ==1)
      {
        this.showVreg = true;
      };
      if ( required .ext_txt ==1)
      {
        this.showTxt = true;
      };
      if ( required.ext_ref ==1)
      {
        this.showExtref = true;
      };
      if ( required.salesman ==1)
      {
        this.showSalesman = true;
      };
      if ( required.delivery ==1)
      {
        this.showDelivery = true;
      };

    //Show Icon
    // var i = this.Tyres.findIndex(tyre => tyre.msfid == item.msfid && tyre.sellerId == item.sellerId)!;
    // this.Tyres[i].inCart = true;
    item.inCart = true;

    //Store in Sess Storage
    this.cartTotal + Math.round(item.buy_price*1.1*1.15);

    // this.notification.showSuccessMinimal('Added ' + item.description + " to Cart");

    //Append to Cart
      CartItems.push(orderCart);
    }


    //Add MOF
    if (add_MOF==1){
      //Add Min Order Fee
      var minOrder = {
        "id":"idMOF",
        "brand":"MOF",
        "image":"coin.png",
        'description':"Minimum Order Fee",
        "price":item.minOrderFee,
        "soh":100,
        "qty":1,
        "seller":item.seller_name,
        "sellerId":item.sellerId,
        "msfid":23127,
        "code":"MOF",
        'mass':0,
        'minOrder':item.minOrder,
        "minOrderFee":item.minOrderFee,
        "sla": "N/A"
      }
      //Append to Cart
      // if (dict.seller==item.seller_name)

      CartItems.push(minOrder)
    };

    sessionStorage.setItem('Cart', JSON.stringify(CartItems));
    // this.load();

    }

    // QUOTE SECTION
    var Quote = JSON.parse(sessionStorage.getItem('Quote')!) as any[];

    if (Quote  != null){
      QuoteItems = Quote;
    }
    var orderQuote = {
      "id":'id'+item.stock_code,
      "brand":item.brand,
      "image":item.image,
      "description":item.description,
      "price":item.buy_price,
      "soh":item.soh,
      "sohInt":item.sohInt,
      "qty":1,
      "seller":item.seller_name,
      "sellerId":item.sellerId,
      "msfid":item.msfid,
      "code":item.stock_code,
      "stockUsedId": item.stockUsedId
     };

   //Show Icon
   //var i = this.Tyres.findIndex(tyre => tyre.msfid === item.msfid && tyre.sellerId === item.sellerId && tyre.stockUsedId === item.stockUsedId)!;
   //this.Tyres[i].inQuote = true;
   item.inQuote = true;
   //Append to Quote
   QuoteItems.push(orderQuote);

   sessionStorage.setItem('Quote', JSON.stringify(QuoteItems));
   this.load()
  }

  selectSupplier(){
    let suppliers = this.cart.map((val) => val.sellerId) // Creates a new array to show only the sellerId, sellerName and expand boolean for the table
    .filter((value, index, self) => { // Filters out all duplicates
      return self.map(val => val).indexOf(value) === index;
    });

    if(suppliers.length > 1){
      this.filterSupplierData();
      this.isConfirmModalVisible = true;
    }
    else {
      this.toggleShowCart(suppliers[0]);
    }
  }

  filterFullCart(supplierId: number){
    return this.cart.filter(val => val.sellerId === supplierId).sort((a, b) => a.description.localeCompare(b.description)).sort((a, b) => a.brand.localeCompare(b.brand));
  }

  filterSupplierData(){
    this.filterTyres = [];

    let uniqueArray = this.cart.map((val) => ({id: val.sellerId, name: val.seller, expand: false})) // Creates a new array to show only the sellerId, sellerName and expand boolean for the table
    .filter((value, index, self) => { // Filters out all duplicates
      return self.map(val => val.id).indexOf(value.id) === index;
    }) as TyreSelect[];

    this.filterTyres = uniqueArray;
  }
  
  // sliceArray(size: number){
  //   let Tyres = this.Tyres
  //   return Tyres.slice(0,  size);
  // }
}
