import { NotificationService } from '../../shared/notification.service';
import { PurchasesService } from '../../purchases/purchases.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Purchase, Purchaseline } from '../../models/purchase';
import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-purchaseview',
  templateUrl: './purchaseview.component.html',
  styleUrls: ['./purchaseview.component.less']})
export class PurchaseviewComponent implements OnInit {

  Purchase: Purchase;
  purchaselines : Purchaseline[]
  order: number
  purchaseRef:string
  datePipe: DatePipe = new DatePipe('en-US');

  
  //Visual Effects
  isLoading = false;

  constructor( private route: ActivatedRoute,private purchaseService: PurchasesService,private router: Router, private notification: NotificationService) { }

  ngOnInit(): void {
     this.route.params.subscribe(async params => 
      {
        this.order = params['orderid'];
        await this.getLoad(this.order);
      });
    this.isLoading = true;
    this.reset() 
  }

  reset()
  {
    this.Purchase= new Purchase();
    this.purchaselines = [];
  }
   
  getLoad(order)
  {
    this.purchaseService.getPurchase(order).subscribe( async res => 
      {
        this.Purchase = res[0] as Purchase;

        this.purchaseService.getPurchaselines(order).subscribe( async res =>
        { 
          this.purchaselines = res as Purchaseline[];
          this.isLoading = false;
        },
        error =>
        {
          this.notification.handleError(error);       
        })    
      },
      error => 
      {
        this.notification.handleError(error);       
      });
  }

  saveRef()
  {
    this.purchaseService.saveRef(this.order,this.purchaseRef).subscribe(res => {
      this.notification.showSuccess("Saved");
      this.ngOnInit();
    },
    error =>
    {
      this.notification.handleError(error);       
    })
  } 

  goBack()
  {
    window.history.back(); 
  }
}
