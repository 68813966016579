
<nz-spin [nzSpinning]="isLoading">
    <div nz-row [nzJustify]="'center'" >
        <div nz-col nzSpan="22">
            <div nz-row [nzJustify]="'start'" *ngIf="slots" class="bg-1" [nzGutter]="[25,5]">
                <div nz-col nzSpan="24">
                    <h4 style="text-align: center;color: #ccc; padding-top: 15px;">Manage Booking Time Slots</h4>
                    <hr>
                </div>
                <div nz-col nzXs="24" nzSm="8" nzMd="6" nzXXl="3" *ngFor="let item of [0,1,2,3,4,5,6]">
                    <div style="padding-top: 15px;">
                        <app-bookings-slots-day [slots]="slots[item]" [dow]="item+1" (refreshGrid)="refreshSlots()"></app-bookings-slots-day>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    
</nz-spin>


