import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.less']
})


export class SearchComponent implements OnInit {

   //Visual Effects
   isShopping = false;
   isQuote = false

  constructor(private router: Router) { }

  ngOnInit()
  {

    //Check if Already Shopping
    for (var i = 0; i < sessionStorage.length; i++)
	  {
      var Cart = JSON.parse(sessionStorage.getItem('Cart')!) as any[];
      if( Cart != null)
      {
        this.isShopping = true;
      }
    }

  }

  openCart()
  {
    this.router.navigate(['/stock/order']);
  }

}
