
<nz-skeleton [nzLoading]="waService.isLoading">
    <!-- <nz-list nzItemLayout="horizontal" [nzLoading]="waService.isLoading" *ngIf="waService.messagelist.length !== 0">
    
        <nz-list-item *ngFor="let item of waService.messagelist">
            
            <nz-list-item-meta>
              
                <nz-list-item-meta-avatar [nzSrc]="'https://logowik.com/content/uploads/images/warning6444.logowik.com.webp'" *ngIf="item.unread > 0"></nz-list-item-meta-avatar>
                <nz-list-item-meta-avatar [nzSrc]="'https://cdn.vectorstock.com/i/preview-1x/70/37/double-tick-sign-element-green-checkmark-icon-ok-vector-44877037.jpg'" *ngIf="item.unread === 0"></nz-list-item-meta-avatar>
                
                <nz-list-item-meta-title>
                  <nz-badge [nzCount]="item.unread"><a (click)="loadMessages(item.cell, item.clientId)">{{ item.cell }}  {{item.name}}</a></nz-badge>
                </nz-list-item-meta-title>
                <nz-list-item-meta-description>
                    <a (click)="loadMessages(item.cell, item.clientId)">
                        {{ item.message }} <br />
                        {{ item.date + 'Z' | date : 'dd/MM/yyyy HH:mm'}} <i>({{ calculateDateDiff(item.date) }})</i><br />
                        <b>{{ item.clientName }}</b>
                    </a>
                </nz-list-item-meta-description>
              </nz-list-item-meta>
            
        </nz-list-item>
    </nz-list> -->
    <nz-switch [(ngModel)]="switchValue" (ngModelChange)="changeSwitch($event)"></nz-switch> Only show messages with unread messages<br /><br />
    <nz-table #inboxTable [nzData]="waService.messagelist" [nzPageSize]="50" >
        <thead>
          <tr>
            <th [width]="75" [nzSortFn]="unreadSortFn">Unread</th>
            <th [width]="225">Customer</th>
            <th>Message</th>
            <th [width]="160" [nzSortFn]="dateSortFn">Date</th>
            <th [width]="125">Client</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of inboxTable.data">
            <td>{{item.unread}}</td>
            <td>{{item.cell}}  {{item.name}}</td>
            <td>{{item.message}}</td>
            <td>{{item.date + 'Z' | date : 'dd/MM/yyyy HH:mm'}}<br /><i>({{calculateDateDiff(item.date)}})</i></td>
            <td>{{item.clientName}}</td>
            <td>
                <button nz-button nzType="primary" (click)="loadMessages(item.cell, item.clientId)"><span nz-icon nzType="comment" nzTheme="outline"></span></button>
            </td>
          </tr>
        </tbody>
      </nz-table>
</nz-skeleton>