<div class="container">
    <div class="row ">
        <div class="col-lg-2">
        </div>
        <div class="col-lg-8 " style="text-align: center;"  >
           <h4><b>Pricing Rules</b></h4>
           <p>Change Default Pricelist Names</p>
        </div>
        <div class="col-lg-2">
        </div>
    </div>
</div> 

<ng-template #addOnBeforeTemplate>
    <span nz-icon nzType="tag" nzTheme="outline"></span>
</ng-template>

<nz-spin [nzSpinning]="isLoading">
    <div class="container-fluid text-center " style="padding-left: 5% !important;padding-right: 4% !important;">
        <div class="row bg-1">
            <div class="col-md-6">
                <p></p>
                <label class="float-left">Default</label>
                <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
                    <input nz-input [(ngModel)]="pricelist.deflt"  value="{{pricelist.deflt}}" placeholder="Default" nzSize="large" >
                </nz-input-group>
                <p></p>
                <label class="float-left">Retail</label>
                <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
                    <input nz-input [(ngModel)]="pricelist.plretail"  value="{{pricelist.plretail}}" placeholder="Retail"   >
                </nz-input-group>
                <p></p>
                <label class="float-left">Wholesale</label>
                <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
                    <input nz-input [(ngModel)]="pricelist.wholesale"  value="{{pricelist.wholesale}}" placeholder="Wholesale"   >
                </nz-input-group>
                <p></p>
                <label class="float-left">Internal</label>
                <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
                    <input nz-input [(ngModel)]="pricelist.plinternal"  value="{{pricelist.plinternal}}" placeholder="Internal"   >
                </nz-input-group>
                <p></p>
                <label class="float-left">Online</label>
                <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
                    <input nz-input [(ngModel)]="pricelist.plonline"  value="{{pricelist.plonline}}" placeholder="Online"   >
                </nz-input-group>                 
            </div>
            <div class="col-md-6">
                <p></p>
                <label class="float-left">Clear Out</label>
                <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
                    <input nz-input [(ngModel)]="pricelist.plclear"  value="{{pricelist.plclear}}" placeholder="Clear Out"   >
                </nz-input-group>
                <p></p>
                <label class="float-left">Custom 1</label>
                <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
                    <input nz-input [(ngModel)]="pricelist.plcustom1"  value="{{pricelist.plcustom1}}" placeholder="Custom 1"   >
                </nz-input-group>
                <p></p>
                <label class="float-left">Custom 2</label>
                <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
                    <input nz-input [(ngModel)]="pricelist.plcustom2"  value="{{pricelist.plcustom2}}" placeholder="Custom 2"   >
                </nz-input-group>
                <p></p>
                <label class="float-left">Custom 3</label>
                <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
                    <input nz-input [(ngModel)]="pricelist.plcustom3"  value="{{pricelist.plcustom3}}" placeholder="Custom 3"   >
                </nz-input-group>
                <p></p>
                <label class="float-left">Custom 4</label>
                <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
                    <input nz-input [(ngModel)]="pricelist.plcustom4"  value="{{pricelist.plcustom4}}" placeholder="Custom 4"   >
                </nz-input-group>
            </div>
            <div class="col-md-12 bg-1 text-center">
                <p></p>
                <button nz-button nzType="primary" (click)="saveSettings()">Save</button>
            </div>
        </div>
    </div>
</nz-spin>

