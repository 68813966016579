import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ModelStock, stockCost, ModelStock_def, ModelStockSizes, SixMonthSalesTrend, supplierInfo } from '../models/stock';
import {  mslSettings, mslBrand, mslBrandsCollection, pieChartModel } from 'src/app/models/brandStrat';


@Injectable({
  providedIn: 'root'
})

export class StockModelsService {
 
  //localapi.stockfinder.co.za/
  apiUrl = 'v1/stock_models/';

  constructor(private http: HttpClient) { }

  //Get Client Unmapped Stock
  public getStockModels()
  {
    return this.http.get(environment.ApiUrl() + this.apiUrl + "getModelStock");
  }

  public modelStock(daysofdata: number,sales_tier: string,dosFormula: string)
  {
    return this.http.get<ModelStock_def>(environment.ApiUrl() + this.apiUrl + "modelStock/"+daysofdata+"/"+sales_tier+"/"+dosFormula);
  }

  

  public modelStockTyreSuggestions(size:string,daysofdata:number,sales_tier:string)
  {
    return this.http.get(environment.ApiUrl() + this.apiUrl + "modelStockTyreSuggestions/"+size+"/"+daysofdata+'/'+sales_tier);
  }

  public modelStockSizeSuggestions(daysofdata:number)
  {
    return this.http.get(environment.ApiUrl() + this.apiUrl + "modelStockSizeSuggestions/"+daysofdata);
  }

  public size_suggestions()
  {
    return this.http.get(environment.ApiUrl() + this.apiUrl + "modelStockSizeSuggestions");
  }

  public getStockList()
  {
    return this.http.get(environment.ApiUrl() + this.apiUrl + "getStockList");
  }

  public getSupplierInfo()
  {
    return this.http.get<supplierInfo[]>(environment.ApiUrl() + this.apiUrl + "getSupplierInfo");
  }

  public deleteModel(modelId: number)
  {
    return this.http.get(environment.ApiUrl() + this.apiUrl + "deleteModel/"+modelId);
  }

  public deleteModel_PR(modelId: number)
  {
    return this.http.get(environment.ApiUrl() + this.apiUrl + "deleteModel_PR/"+modelId);
  }

  public updateModel(newItem: ModelStock)
  {
    return this.http.post(environment.ApiUrl() + this.apiUrl + "updateModel",newItem);
  }

  public addModel(newItem: ModelStock)
  {
    return this.http.post(environment.ApiUrl() + this.apiUrl + "addModel",newItem);
  }

  public getSupplierInvoice(items: ModelStock[])
  {
    return this.http.post(environment.ApiUrl() + this.apiUrl + "supplierQuote/",items);
  }

  public getLiveLevels(items: ModelStock[])
  {
    return this.http.post(environment.ApiUrl() + this.apiUrl + "liveStock/",items);
  }

  public summarise_stock(base: ModelStock[])
  {
    return this.http.post(environment.ApiUrl() + this.apiUrl + "summarise_orders/",base);
  }

  public trackAltStock(item: ModelStock)
  {
    return this.http.post(environment.ApiUrl() + this.apiUrl + "trackAltStock/",item);
  }

  public placeOrder(items: stockCost[])
  {
    return this.http.post(environment.ApiUrl() + this.apiUrl + "confirmOrder/",items);
  }

  // public modelStockAlerts(daysofdata: number)
  // {
  //   return this.http.get(environment.ApiUrl() + this.apiUrl + "getAlerts/"+daysofdata);
  // }

  public modelStockAlerts(items: ModelStockSizes[])
  {
    // return this.http.post(environment.ApiUrl() + this.apiUrl + "getAlerts/",items);
    return this.http.post(environment.ApiUrl() + this.apiUrl + "getGlobalAlerts/",items);
    
  }

  
  public Single_StockAlerts(items: ModelStockSizes[],type:string,daysofdata:number)
  {
    // return this.http.post(environment.ApiUrl() + this.apiUrl + "getAlerts/",items);
    return this.http.put(environment.ApiUrl() + this.apiUrl + "singleAlert/"+type+"/"+daysofdata,items);
    
  }

  public update_date(stockreplenishmentId: number)
  {
    return this.http.get(environment.ApiUrl() + this.apiUrl +"update_dateChanged/"+stockreplenishmentId);
  }

  public modelStockAlerts_Global(items: ModelStockSizes[])
  {
    return this.http.post(environment.ApiUrl() + this.apiUrl + "getGlobalAlerts/",items);
    
  }

  public salesTrendForTyre(msfid: number){
    return this.http.get<SixMonthSalesTrend>(environment.ApiUrl() + this.apiUrl + `salesTrendTyre/${msfid}`);
  }

  public salesTrendForGencode(gencode: string){
    return this.http.get<SixMonthSalesTrend>(environment.ApiUrl() + this.apiUrl + `salesTrendGencode/${gencode}`);
  }



  public getBrands()
  {
    return this.http.get<mslBrandsCollection>(environment.ApiUrl() + this.apiUrl + "getMslBrands");
  }

  public upsertBrands(brand:mslBrand)
  {
    return this.http.post<mslBrand[]>(environment.ApiUrl() + this.apiUrl + "upsertMslBrands", brand);
  }

  public deleteBrands(brand:mslBrand)
  {
    return this.http.post<mslBrand[]>(environment.ApiUrl() + this.apiUrl + "deleteMslBrands", brand);
  }

  public runAi(settings:mslSettings)
  {
    return this.http.post<mslBrandsCollection>(environment.ApiUrl() + this.apiUrl + "ai", settings);
  }

  public getPieChartData(gencode:number)
  {
    return this.http.get<pieChartModel[]>(environment.ApiUrl() + this.apiUrl + "loadPieChartData/" + gencode);
  }

}

