import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClientBookingSlot } from 'src/app/models/settings';
import { Globals } from 'src/app/shared/globals';
import { NotificationService } from 'src/app/shared/notification.service';
import { SettingsService } from '../../settings.service';

@Component({
  selector: 'app-bookings-slots-day',
  templateUrl: './bookings-slots-day.component.html',
  styleUrls: ['./bookings-slots-day.component.less']
})
export class BookingsSlotsDayComponent implements OnInit {

  @Input() slots: ClientBookingSlot[] = [];
  @Input() dow: number;
  @Output() refreshGrid = new EventEmitter();

  isAddingSlotModalVisible = false;
  isLoadingAddSlot = false;

  constructor(private settingsService: SettingsService, private notification: NotificationService) { }

  ngOnInit(): void {
  }

  getDayName() {
    
    return Globals.funcs.getDayName(this.dow);
    
  }

  deleteSlot(slot: ClientBookingSlot) {
    this.settingsService.deleteBookingSlot(slot.slotId).subscribe(
      val => {
        this.refreshGrid.next(true);
      },
      err => {
        this.notification.handleError(err);
      }
    )
    
  }

  onAddSlotClick() {
    this.isAddingSlotModalVisible = true;
  }

  selectedTime: string = "";
  onAddSlot() {
    let addItem: ClientBookingSlot = new ClientBookingSlot();
    addItem.dayOfWeek = this.dow;
    addItem.slotTime = this.selectedTime;
    addItem.status = 1;
    this.isLoadingAddSlot = true;
    this.settingsService.addBookingSlot(addItem).subscribe(
      val => {
        this.isLoadingAddSlot = false;
        this.isAddingSlotModalVisible = false;
        this.notification.showSuccess("Time Slot Added");
        this.refreshGrid.next(true);
      },
      err => {
        this.isLoadingAddSlot = false;
        this.isAddingSlotModalVisible = false;
        this.notification.handleError(err);
      }
    )
  }
}
