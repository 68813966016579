<div class="container-fluid">
    <div class="row ">
        <div class="col-lg-4">
        </div>
        <div class="col-lg-4 text-center">
            <h2><b>Price Matrix</b></h2>
        </div>
        <div class="col-lg-4">
        </div>
    </div>
</div>
<div nz-row [nzJustify]="'center'" *ngIf="isLoading" class="loading-spinner">
  <nz-spin nzSimple></nz-spin>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12 text-center" style="padding-left: 5%;padding-right: 4%;">
<div >
  <nz-tabset nzCentered nzSize="large">
    <nz-tab *ngFor="let pmlist of pricingMatrixObj.priceMatrixList; let j =index" [nzTitle]="pmlist.name" (nzSelect)="tabChanged(j)" [nzDisabled]="this.matrixDirty">
      <div class="col-lg-12 text-center" style="padding-left: 5%;padding-right: 4%;">
        <p><b>{{ pmlist.description }}</b></p>
      </div>
      <div nz-row style="padding-bottom: 5px;" [nzJustify]=" 'space-between' ">
        <div nz-col nzSpan="8">
            <nz-select [(ngModel)]="pmlist.applied"  style="width: 100%" >
                <nz-option *ngFor="let item of matrixOptions" [nzLabel]="item.text" [nzValue]="item.id"></nz-option>
            </nz-select>
        </div>
        <div >
            <button nz-button nzType="primary" type="submit" (click)="updatePMList(j)" [disabled]="!matrixDirty">Save</button>
        </div>
    </div>
      <p *ngIf="!pmlist.applied">The price matrix for this price list has been disabled</p>
      <nz-table *ngIf="pmlist.applied" #pricingMatrixTable [nzData]="pmlist.priceMatrixItems" [nzFrontPagination]="false" [nzPageSize]="100" [nzPaginationPosition]="'both'" [nzLoading]="isLoadingMatrix">
        <thead>
          <tr>
            <th nzWidth="10px"></th>
            <th nzWidth="30px"></th>
            <th 
                [nzSortOrder]="priceMatrixBrandColumn.sortOrder"
                [nzSortFn]="priceMatrixBrandColumn.sortFn"
                [nzSortDirections]="priceMatrixBrandColumn.sortDirections"
                [nzFilterMultiple]="priceMatrixBrandColumn.filterMultiple"
                [nzFilters]="priceMatrixBrandColumn.listOfFilter"
                [nzFilterFn]="priceMatrixBrandColumn.filterFn"
                nzWidth="100px">
              {{ priceMatrixBrandColumn.name }}
            </th>
            <th nzWidth="200px">Method</th>
            <th nzWidth="175px">Percentage
              <button nz-button nzType="primary" style="margin-left: 10px;" nz-popover nzPopoverTitle="Set All %" [nzPopoverContent]="setAllTemplate" nzPopoverPlacement="right" nzPopoverTrigger="click" (click)="this.popOverVisibility = !this.popOverVisibility" (nzPopoverVisible)="this.popOverVisibility">Set All Brand Defaults</button>
              <ng-template #setAllTemplate>
                <div>
                  <nz-input-group nzAddOnBeforeIcon="percentage">
                    <input nz-input [(ngModel)]="this.setAllValue" type="number" placeholder="20" style="width: 100px;">
                  </nz-input-group>
                  <div nz-row [nzJustify]="'end'">
                    <button nz-button nzType="primary" style="margin-top: 10px;" (click)="setAllPercentages(j); this.popOverVisibility = false;">Set Brand Defaults</button>
                  </div>
                </div>
              </ng-template>
              <app-video-widget [videoId]="6" style="float: inline-end;"></app-video-widget>
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let brand of pricingMatrixTable.data; let i = index;">
            <!-- Brand Row -->
            <tr [ngClass]="{'text-danger': !brand.pmId}" (click)="toggleExpand(brand)">
              <td  nz-popover nzPopoverContent="Click to modify Rim Sizes" nzPopoverTrigger="hover"><span nz-icon nzType="plus-circle" nzTheme="outline"></span></td>
              <td [(nzExpand)]="brand.expand" [nzShowExpand]="false"  nz-popover nzPopoverContent="Click to modify Rim Sizes" nzPopoverTrigger="hover"><img class="brandImage" src='https://sftptyres.blob.core.windows.net/brands-images/brands/{{brand.image}}' ></td>
              <td [nzIndentSize]="(brand?.level ?? 0) * 20" nz-popover nzPopoverContent="Click to modify Rim Sizes" nzPopoverTrigger="hover">{{ brand.brandName }}</td>
              <td>
                <button nz-button *ngIf="!brand.methodEditable" nzType="primary" (click)="$event.stopPropagation(); brand.methodEditable = true" nz-popover nzPopoverContent="Click to modify method" nzPopoverTrigger="hover" style="width: 50%">
                  {{ brand.methodId == 1 ? 'Fixed GP Percent' : brand.methodId == 2 ? 'Retail Less' : 'Dont Sell' }}
                </button>
                <nz-radio-group *ngIf="brand.methodEditable" nzSize="small" nzButtonStyle="solid" [(ngModel)]="brand.methodId" (ngModelChange)="matrixBrandChanged([brand], j, brand.value); brand.methodEditable = false" style="width: 75%"  >
                  <label nz-radio-button [nzValue]="1">Fixed GP Percent</label>
                    <label nz-radio-button [nzValue]="2">Retail Less</label>
                    <label nz-radio-button  [nzValue]="3">Dont Sell</label>
                </nz-radio-group>
                <button nz-button *ngIf="brand.methodEditable" nzType="primary" nzDanger="true" (click)="$event.stopPropagation(); brand.methodEditable = false"><span nz-icon nzType="close" nzTheme="outline"></span></button>
              </td>
              <td>
                <div class="input-group maindiv" style="width: 100%">
                  <nz-input-group nzAddOnAfterIcon="percentage" >
                    <input nz-input [(ngModel)]="brand.value" (click)="$event.stopPropagation()" (ngModelChange)="brand.methodId && matrixBrandChanged([brand], j, $event)" type="number" placeholder="100">
                  </nz-input-group>
                </div>
              </td>
            </tr>
            <!-- Rim Rows -->
            <ng-container *ngIf="brand.expand && brand.priceMatrixOverrideItems">
              <tr *ngFor="let item of brand.priceMatrixOverrideItems">
                <td></td>
                <td></td>
                <td [nzIndentSize]="15"> {{ item.rimSize }}"</td>
                <td>
                  <button nz-button *ngIf="!item.methodEditable" nzType="primary" (click)="item.methodEditable = true" nz-popover nzPopoverContent="Click to modify method" nzPopoverTrigger="hover" style="width: 50%">
                    {{ item.pmMethodId == 1 ? 'Fixed GP Percent' : item.pmMethodId == 2 ? 'Retail Less' : 'Dont Sell' }}
                  </button>
                  <nz-radio-group nzSize="small" *ngIf="item.methodEditable" nzButtonStyle="solid"  [ngModel]="item.pmMethodId" (ngModelChange)="matrixOverrideChanged(item,j,$event,item.value); item.methodEditable= false" style="width: 75%" >
                    <label nz-radio-button [nzValue]="1">Fixed GP Percent</label>
                    <label nz-radio-button [nzValue]="2">Retail Less</label>
                    <label nz-radio-button  [nzValue]="3">Dont Sell</label>
                  </nz-radio-group>
                  <button nz-button *ngIf="item.methodEditable" nzType="primary" nzDanger="true" (click)="$event.stopPropagation(); item.methodEditable = false"><span nz-icon nzType="close" nzTheme="outline"></span></button>
                </td>
                <td>
                  <div nz-row [nzJustify]="'space-between'">
                    <div nz-col nzSpan="18">
                      <nz-input-group nzAddOnAfterIcon="percentage">
                        <input nz-input [ngModel]="item.value" (ngModelChange)="matrixOverrideChanged(item, j, item.pmMethodId, $event)" type="number" placeholder="100">
                      </nz-input-group>
                    </div>
                    <div nz-col style="margin-left:10px">
                      <button nz-button nzType="primary" nzDanger="true" (click)="deleteOverrideItem(item,j)"><span nz-icon nzType="delete" nzTheme="outline"></span></button>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>Add Rim Size</td>
                <td [nzIndentSize]="((brand?.level ?? 0) + 1) * 10">
                  <nz-select style="width: 50%" [(ngModel)]="newRim[brand.brandId].size">
                    <nz-option *ngFor="let size of this.avaibleRims(brand.brandId, j)" [nzLabel]="size" [nzValue]="size"></nz-option>

                  </nz-select>
                </td>
                <td>
                    <nz-radio-group nzSize="small" nzButtonStyle="solid" [(ngModel)]="newRim[brand.brandId].methodId" style="width: 100%">
                      <label nz-radio-button [nzValue]="1">Fixed GP Percent</label>
                      <label nz-radio-button [nzValue]="2">Retail Less</label>
                      <label nz-radio-button  [nzValue]="3">Dont Sell</label>
                  </nz-radio-group>
                </td>
                <td>
                  <div nz-row [nzJustify]="'space-between'">
                    <div nz-col nzSpan="18">
                      <nz-input-group nzAddOnAfterIcon="percentage">
                        <input nz-input [(ngModel)]="newRim[brand.brandId].value" type="number" placeholder="100">
                      </nz-input-group>
                    </div>
                    <div nz-col >
                      <button nz-button nzType="primary" [disabled]="!newRim[brand.brandId].methodId || newRim[brand.brandId].value==0" (click)="brand.pmId && newRim[brand.brandId].size && addNewRim(j, brand.brandId, brand.pmId)">Add Rim</button>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </nz-table>

    </nz-tab>
    
  </nz-tabset>
  <nz-modal [(nzVisible)]="isNavModalVisible" nzTitle="Save Changes" (nzOnCancel)="isNavModalVisible = false; navPathAway='';" [nzFooter]="null" nzWidth="800px">
    <div *nzModalContent>
        <div nz-row [nzGutter]="5" [nzJustify]="'center'">
            <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12" style="text-align: center;">
                <br>
                <p>Do you want to save changes before leaving?</p>
                <div nz-row [nzJustify]="'space-between'">
                    <button nz-button nzType="primary" (click)="navigateWithSaving();">
                      <i nz-icon nzType="check-circle" nzTheme="fill"></i>
                      Save Changes
                    </button>
                    <button nz-button nzType="primary" (click)="navigateWithoutSaving()">
                        <i nz-icon nzType="left-circle" nzTheme="fill"></i>
                        Continue Without Saving
                    </button>
              </div>
            </div>
        </div>
    </div>
</nz-modal>
</div>
            
        </div>
    </div>
</div>