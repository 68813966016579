import { PurchasesService } from '../purchases/purchases.service';
import { Purchase, Purchaseline, OrderLine,SupplierSpend, BrandSpend } from './../models/purchase';
import { TableOrder} from './../models/orders';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SalesService } from '../sales/sales.service';
import { NotificationService } from '../shared/notification.service';
import { resultType } from '../shared/modal-content/modal-content.component';
import { AuthService } from '../auth.service';
import { AppComponent } from '../app.component';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';





@Component({
  selector: 'app-purchases',
  templateUrl: './purchases.component.html',
  styleUrls: ['./purchases.component.less']
})
export class PurchasesComponent implements OnInit {

  //Constants
  readonly ITEMS_PER_PAGE = 50;

  //Global Variables
  PurchaseList: Purchase[]=[];
  TableOrders: TableOrder[]=[];
  todayList: Purchase[]=[];
  weekList: Purchase[]=[];
  pastList: Purchase[]=[];
  receivingOrder: Purchase= new Purchase();
  receivinglines: Purchaseline[] = [];
  UpdateList: OrderLine[] = [];
  isLoadingMoreItems: boolean = false;
  //Visual Effects
  isLoading = false;
  isShowingOrderModal:boolean = false;
  switchTo: string = "";
  chart: any;
  user_roleIds: number[] = [];
  current_day:number = 0;
  inputValue: string = "";

  constructor(public authService: AuthService,private notification: NotificationService,private salesService: SalesService,private router: Router,private purchaseService: PurchasesService, public appComponent: AppComponent) { }

  ngOnInit(): void {    
    this.isLoading = true;
    this.getPurchases();
    this.getUserRoleIds();
    this.current_day = new Date().getDate();
    
  }

  
  getPurchases()
  {
    this.purchaseService.getPurchases().subscribe(
      res => {
        this.PurchaseList= [];
        this.PurchaseList = res as Purchase[];
        for(var i =0; i < this.PurchaseList.length; i++)
        {
          if(this.PurchaseList[i].isReceived == 1)
          {
            this.PurchaseList[i].isReceiveable = false;
          }
          else
          {
            this.PurchaseList[i].isReceiveable = true;
          }
        }
        this.filterPurchases();
        this.isLoading = false;
      },
      error => {
        this.notification.handleError(error);
      }
    );
  }

  filterPurchases()
  {
    //Set Base Date Object
    let tod = new Date(); tod.setHours(0,0,0,0);
    let Today = Date.parse(tod.toString());
    let Week = tod.setDate(tod.getDate() - 7);

    this.TableOrders = [];
    this.todayList = [];
    this.weekList = [];
    this.pastList = [];

    //Loop Through Incoming Purchase Orders
    for(var i = 0 ; i < this.PurchaseList.length; i++)
    {
      var order = this.PurchaseList[i]; var orderdate = Date.parse(order.dt);

      /* Purchases can only have status
         0 - Processing
         4 - Sent
         5 - Inserted /Placed
         6/7 - Error
         8/9/11 - Completed
      */

      //If Sell state is Email - Dont Insert to Pos and show custom states based off of Purchase order Refrences
      if(order.sell_state == 'email' && order.statusId != 5) //status id 5 means (order inserted )
        {
          //Check if the purchase order reference was populated
          if(order.PoRef == "None")
          {
              order.error = order.name + " has received your order and is currently processing it";
              order.statusId = 12;
              order.errorFix = 12;
          }
          else
          {
            //If Populated Means Refrence was updated on sales order
            order.statusId = 5;
          }
      }
      else
      {
        if(order.statusId == 0)
        {
          //Check Orders still processing day after
          if( orderdate <= Today &&  orderdate > Week)
          {
            order.statusId = 4;
            order.error = "Order Not Placed , Please Contact SF (066 453 2826)"
            order.errorFix = 2;
          }
        }

        if(order.statusId == 4 || order.statusId == 7)
        {
          var todayddmmyyyy = tod.getDay() + " " + tod.getMonth() + " " + tod.getFullYear();
          var convertedOrderDate = new Date(orderdate)
          var orderdateddmmyyyy = convertedOrderDate.getDay() + " " + convertedOrderDate.getMonth() + " " + convertedOrderDate.getFullYear();

          //If Order Date is Not the same as Today and order has a status of 4 (sent) / 7 (errord) == !insert
          if(todayddmmyyyy != orderdateddmmyyyy && order.errorFix == 1) // Errorfix 1 means SF Error
          {
            order.errorFix = 2; // Displays [ X Order Not Inserted ]
          }
          else if (todayddmmyyyy  == orderdateddmmyyyy &&  order.errorFix == 1)
          {
            //Check if Order was placed within the hour
            const hourInMilliseconds = 60 * 60 * 1000;
            const hourAgo = Date.now() - hourInMilliseconds;

            //If It was placed more than an hour ago then display order as Failed -> The Automation would've placed it by now
            if( orderdate < hourAgo)
            {
              order.errorFix = 2;
            }
          }
        }
      }

      //Filter For Diffrent Tables
      if(orderdate >= Today)
      {
        this.todayList.push(order);
      }
      if(orderdate < Today && orderdate >= Week)
      {
        this.weekList.push(order)
      }
      if(orderdate < Week)
      {
        this.pastList.push(order)
      }
    }
      let TodayTable = new TableOrder(); 
      TodayTable.title = "Today"; 
      TodayTable.period = "today"; 
      TodayTable.purchases = this.todayList.slice(0, this.ITEMS_PER_PAGE);
      this.TableOrders.push(TodayTable);
      if(TodayTable.purchases.length > 0)
      {
        //console.log("triggering reload check")
        this.reloadCheck(TodayTable.purchases);
      }
      let WeekTable  = new TableOrder(); 
      WeekTable.title = "This Week"; 
      WeekTable.period = "week"; 
      WeekTable.purchases = this.weekList.slice(0, this.ITEMS_PER_PAGE);
      this.TableOrders.push(WeekTable);
      let PastTable  = new TableOrder(); 
      PastTable.title = "Past Orders"; 
      PastTable.period = "past"; 
      PastTable.purchases =  this.pastList.slice(0, this.ITEMS_PER_PAGE);
      this.TableOrders.push(PastTable);
    //}

  }

  
 public checkDisplayLoadMore(tblIndex: number): boolean {
  switch (tblIndex) {
   case 0: {
     return this.todayList.length > this.TableOrders[tblIndex].purchases.length;
     break;
   }
   case 1: { 
     return this.weekList.length > this.TableOrders[tblIndex].purchases.length;
     break;
   }
   case 2: {
     return this.pastList.length > this.TableOrders[tblIndex].purchases.length;
     break;
   }
  }
  return true; 
}

public loadMore(tblIndex: number) {
  this.isLoadingMoreItems = true; 
  switch (tblIndex) {
    case 0: { 
      this.TableOrders[tblIndex].purchases = this.todayList.slice(0, this.TableOrders[tblIndex].purchases.length + this.ITEMS_PER_PAGE);
      break;
    }
    case 1: {
      this.TableOrders[tblIndex].purchases = this.weekList.slice(0, this.TableOrders[tblIndex].purchases.length + this.ITEMS_PER_PAGE);
      break;
    }
    case 2: {
      this.TableOrders[tblIndex].purchases = this.pastList.slice(0, this.TableOrders[tblIndex].purchases.length + this.ITEMS_PER_PAGE);
      break;
    }
  }
  this.isLoadingMoreItems = false;
}


  resubmitOrder(orderid)
  {
    this.notification.ShowAlert({type: resultType.Information, 
      title: "Resubmitting Order", 
      htmlMessage: '' ,
      maskClosable: false});
    this.salesService.resubmit_order(orderid).subscribe(
      res =>
          {
            this.notification.removeAlert();
            this.ngOnInit();
          },
      error =>
          {
            this.notification.handleError(error);
          });

  }

  viewpurchase(orderid)
  {
    this.router.navigate(["/purchases/view",orderid])
  }

  showOrderModal(orderId)
  {
    this.purchaseService.getPurchaselines(orderId).subscribe(res =>
      {
        this.receivingOrder = this.PurchaseList.find(x=> x.orderid == orderId)!;
        this.receivinglines= res as Purchaseline[];
      },
      error =>
      {
        this.notification.handleError(error);
      })
    this.isShowingOrderModal = true;
  }

  receiveStock(orderId)
  {
    this.notification.ShowAlert({type: resultType.Information, 
      title: "Adjusting Stock Levels", 
      htmlMessage: 'Your received Stock is Being loaded <br>'+
      ' It may take up to 15 secs to complete <br>'+
      '<i nz-icon nzType="sync" nzTheme="outline" style="font-size:24px"></i>' ,
      maskClosable: false,});

    this.UpdateList =[]
    for(var i =0; i < this.receivinglines.length; i++)
    {
      let obj = new  OrderLine();
      obj.description = this.receivinglines[i].description_Long;
      obj.code = this.receivinglines[i].code;
      obj.qty = this.receivinglines[i].qty;
      obj.cost = this.receivinglines[i].price;
      this.UpdateList.push(obj)
    }
    this.purchaseService.receiveStock(orderId,this.UpdateList).subscribe(res =>
      {
        this.notification.removeAlert();
        this.notification.showSuccess("Stock Successfully Received");
        this.ngOnInit();
        this.notification.ShowAlert({type: resultType.Success, 
          title: "Stock Successfully Received", 
          htmlMessage: 'Your Stock Has been Added , Remember to Check Mappings for any Unmapped stock' ,
          maskClosable: false,
          btnText: 'OK'});
      },
      error =>
      {
        this.notification.handleError(error);
      })
    this.isShowingOrderModal = false;
  }


  //Reload Section
  private reloadTimer: any;

  // Reload if any sales from the last hour have a pending status
  reloadCheck(purchases: Purchase[])
  {

    for(var i = 0; i < purchases.length; i++)
    {
      let purchaseTime = new Date(purchases[i].dt).getTime();
      let currentTime = new Date().getTime();
      let oneHourInMillis = 60 * 60 * 1000;    
      let startTimer = false

      //check only the last hour
      if(purchases[i].errorId == null && [0,1,2].includes(purchases[i].statusId) && (currentTime - purchaseTime) <= oneHourInMillis && (purchases[i].pos != null))
      {
        startTimer = true;
      }

       //start reload timer
      if(startTimer)
      {
        this.startReloadTimer();
      }

    }
  }

  startReloadTimer() {
    let reloadTimeInMinutes = 1; //Set to 1
    let reloadTimeInMillis = reloadTimeInMinutes * 60 * 1000;

    // Clear the existing timer (if any)
    this.clearReloadTimer();

    // Set the new timer
    //console.log("started new timer")
    this.reloadTimer = setTimeout(() => {
      this.ngOnInit()
     // console.log("Re laoding")
    }, reloadTimeInMillis);

  }

  // Clear the reload timer
  clearReloadTimer() {
    if (this.reloadTimer) {
     // console.log("reset the timer to 0")
      clearTimeout(this.reloadTimer);
      this.reloadTimer = null;
    }
  }

  //Lifecycle hook destroy
  ngOnDestroy() {
    this.clearReloadTimer();
  }

  // ------ Total spend 30 days only-----------------------------
 
  spending_access = false;
  getUserRoleIds(){
    this.purchaseService.getUserRoles().subscribe((res:any)=>{
      this.user_roleIds = res as number[];
      if (this.user_roleIds.includes(5)  || this.user_roleIds.includes(9)){this.spending_access = true;}
    },err =>(
      this.notification.showError(err)
    ));
  }

  color_array = [
      "red", "green", "blue", "yellow", "purple", "orange", "pink", "brown", "cyan",
      "magenta", "teal", "lime", "maroon", "navy", "olive", "indigo", "gold", "silver",
      "violet", "turquoise", "orchid", "slate", "salmon", "peru", "plum", "coral",
      "chartreuse", "lavender", "sienna", "azure", "khaki", "crimson", "aquamarine",
      "sandybrown", "thistle", "tomato", "darkgreen", "mediumblue", "lightpink", "rosybrown",
      "mediumorchid", "dodgerblue", "darkorange", "mediumseagreen", "darkviolet",
      "firebrick", "powderblue", "darkturquoise", "mediumslateblue", "cadetblue",
      "darkgoldenrod", "deepskyblue", "fuchsia", "orangered", "springgreen", "darkolivegreen",
      "steelblue", "indianred", "royalblue", "chocolate", "mediumvioletred", "palegreen",
      "saddlebrown", "midnightblue", "darkslategray", "goldenrod", "darkslateblue",
      "forestgreen", "hotpink", "greenyellow", "dimgray", "lightcoral", "palevioletred",
      "limegreen", "darkred", "blueviolet", "darkmagenta"
  ]

  brand_colors = {"PIRELLI": "Gold","ROYALBLACK": "Black","VOYAGER": "DarkGrey","COOPER": "MidnightBlue","MICHELIN": "Blue",
    "SUMITOMO": "SteelBlue","MIRAGE": "Mustard","GTRADIAL": "#White","DAYTON": "Brown","MACROYAL": "FireBrick","YOKOHAMA": "Red",
    "GENERALTIRE": "Crimson","TIGAR": "Tomato","LINGLONG": "#FF6347","MICKEYTHOMPSON": "#FF4500","COMFORSER": "#FFA500", "BLACKLION": "#000000",
    "VREDESTEIN": "#FF8C00","INVOVIC": "#FF6347","ROCKBLADE": "#8B0000","CITYTOUR": "#20B2AA","MRL": "#FFD700","BFGOODRICH": "RoyalBlue","TYREADVANCE": "#8B0000",
    "MAXXIS": "#ff6f1a","SUPERCAT": "#FF4500","UNIROYAL": "#000080","CHENGSHINTIRE": "#FF4500","HANKOOK": "#FF4500","APOLLO": "Purple","LANVIGATOR": "#FF6347","TAURUS": "#FFA500",
    "GRIPMAX": "DarkSlateGrey","ILINK": "DarkRed","FARMMASTER": "#FF6347","COMPASAL": "#FF6347","JOYALL": "#FFD700", "BARUM": "#FF6347","ACHILLES": "#FF4500","CAMSO": "#FF6347",
    "GITI": "#FF4500", "TERRAKING": "#FF6347","FIRESTONE": "Maroon","REIVILO": "#800000","DUNLOP": "GoldenRod","FALKEN": "#008000", "DAVANTI": "OrangeRed", "SAVA": "Green",
    "GOODYEAR": "Yellow", "HILO": "#FFA500","CONTINENTAL": "Orange","ATLAS": "#8B0000","EVERGOLD": "#FFD700","KLEBER": "#FF6347","BRIDGESTONE": "FireBrick", "TECHSHIELD": "#FF6347",
    "DEBICA": "#FF6347","GOLDSHIELD": "#FFA500","AUTOGRIP": "#FF6347","HIFLY": "#FF4500","GOODRIDE": "#FFA500","BFPASSENGER": "MediumBlue", "MATADOR": "#FF6347",
    "GRENLANDER": "#FFA500", "KUMHO": "#FF4500", "WESTLAKE": "#FFA500","DURUN": "#FFA500","TIANLI": "#FFA500","SAILWIN": "#FF4500"
  }
 
  totalSpendVis = false;
  spendObj: SupplierSpend[];
  viewTotalSpend(){
    this.InsightVis = false;
    this.totalSpendVis = true;
    this.isLoading = true;
    this.purchaseService.SeeSupplierSpend().subscribe((res:any)=>{
      this.spendObj = res as SupplierSpend[];
      let ratios:any = [];
      let labels: any = [];
      let overall_spend = 0;
      let colors: string[] = [];
      for(let x= 0; x < this.spendObj.length;x++){
        overall_spend += this.spendObj[x].total_spend;
      }
      for(let y = 0; y < this.spendObj.length; y++){
        var r = ((this.spendObj[y].total_spend/overall_spend)*100).toFixed(2);
        var l = this.spendObj[y].supplier_name.toString();
        colors.push(this.color_array[y]);
        labels.push(l);
        ratios.push(r);
      }
      this.totalSpend = overall_spend.toString();
      this.pieChartData.labels = labels;
      this.pieChartData.datasets = [{data: ratios, label: 'Supplier Spend', backgroundColor: colors, hoverBackgroundColor: "white", borderWidth: 0.5 } ]
      this.isLoading = false;
    }, error =>
    {
      this.notification.handleError(error);
    });
  }

  // ------- Brand Spend 30 days only------------------------------
  brandSpendVis = false;
  brand_spendObj:BrandSpend[];
  brandSpend(){
    this.InsightVis = false;
    this.brandSpendVis = true;
    this.isLoading = true;
    this.purchaseService.SeeBrandSpend().subscribe((res:any)=>{
      this.brand_spendObj = res as BrandSpend[];
      let ratios:any = [];
      let labels: any = [];
      let overall_spend = 0;
      let colors: string[] = [];
      for(let x= 0; x < this.brand_spendObj.length;x++){
        overall_spend += this.brand_spendObj[x].total_spend;
      }
      for(let y = 0; y < this.brand_spendObj.length; y++){
        var r = ((this.brand_spendObj[y].total_spend/overall_spend)*100).toFixed(2);
        var l = this.brand_spendObj[y].brand_name.toString();
        colors.push(this.brand_colors[this.brand_spendObj[y].brand_name])
        labels.push(l);
        ratios.push(r);
      }
      this.totalSpend = overall_spend.toFixed(2).toString();
      this.pieChartData.labels = labels;
      this.pieChartData.datasets = [{data: ratios, label: labels, backgroundColor: colors, hoverBackgroundColor: "white", borderWidth: 0.5} ]
      this.isLoading = false;
    }, error =>
    {
      this.notification.handleError(error);
    });
    
  }


  totalSpend = ''
  public pieChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    aspectRatio: 3,
    plugins: {
      legend: {
        display: true,
        labels: {
          textAlign: 'left', 
        },
        title: {
          display: true,
          font: { size: 25 }
        }
       
      
      },
    }
  };
  public pieChartType: ChartType = 'pie';
  public pieChartPlugins = [
  ];

  public pieChartData: ChartData<'pie'> = {
    labels: [],
    datasets: []
  }

  // events
  public chartClicked({ event, active }: { event?: ChartEvent, active?: {}[] }): void {

  }

  public chartHovered({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
    // console.log(event);
  }

  InsightVis = false;
  toggleInsights(){
    if(this.InsightVis == false){this.InsightVis = true;}
    else if(this,this.InsightVis == true){this.InsightVis = false;}
  }

  sort =0;
  sortBrandInsight(col:number){
    if(col ==0)
    {
      if(this.sort == 0){
        let sortedNames = this.brand_spendObj.sort((a, b) =>  (a.brand_name < b.brand_name) ? -1 : 1);
        this.brand_spendObj = sortedNames;
        this.brand_spendObj = this.brand_spendObj.slice();
        this.sort = 1;
      }

      else{
        let sortedNames = this.brand_spendObj.sort((a, b) => (a.brand_name > b.brand_name) ? -1 : 1);
        this.brand_spendObj = sortedNames;
        this.brand_spendObj = this.brand_spendObj.slice();
        this.sort = 0;
        
      }
    }
    

    if(col == 1)
    {
      if(this.sort == 0){
        let sortedNames = this.brand_spendObj.sort((a, b) =>  (a.units < b.units) ? -1 : 1);
        this.brand_spendObj = sortedNames;
        this.brand_spendObj = this.brand_spendObj.slice();
        this.sort = 1;
      }

      else{
        let sortedNames = this.brand_spendObj.sort((a, b) => (a.units > b.units) ? -1 : 1);
        this.brand_spendObj = sortedNames;
        this.brand_spendObj = this.brand_spendObj.slice();
        this.sort = 0;
        
      }
    }

    if(col == 2)
    {
      if(this.sort == 0){
        let sortedNames = this.brand_spendObj.sort((a, b) =>  (a.invoices < b.invoices) ? -1 : 1);
        this.brand_spendObj = sortedNames;
        this.brand_spendObj = this.brand_spendObj.slice();
        this.sort = 1;
      }

      else{
        let sortedNames = this.brand_spendObj.sort((a, b) => (a.invoices > b.invoices) ? -1 : 1);
        this.brand_spendObj = sortedNames;
        this.brand_spendObj = this.brand_spendObj.slice();
        this.sort = 0;
        
      }
    }

    if(col == 3)
    {
      if(this.sort == 0){
        let sortedNames = this.brand_spendObj.sort((a, b) =>  (a.total_spend < b.total_spend) ? -1 : 1);
        this.brand_spendObj = sortedNames;
        this.brand_spendObj = this.brand_spendObj.slice();
        this.sort = 1;
      }

      else{
        let sortedNames = this.brand_spendObj.sort((a, b) => (a.total_spend > b.total_spend) ? -1 : 1);
        this.brand_spendObj = sortedNames;
        this.brand_spendObj = this.brand_spendObj.slice();
        this.sort = 0;
        
      }
    }
  }

  sortSupplierInsight(col:number){
    if(col ==0)
    {
      if(this.sort == 0){
        let sortedNames = this.spendObj.sort((a, b) =>  (a.supplier_name < b.supplier_name) ? -1 : 1);
        this.spendObj = sortedNames;
        this.spendObj = this.spendObj.slice();
        this.sort = 1;
      }

      else{
        let sortedNames = this.spendObj.sort((a, b) => (a.supplier_name > b.supplier_name) ? -1 : 1);
        this.spendObj = sortedNames;
        this.spendObj = this.spendObj.slice();
        this.sort = 0;
        
      }
    }

    if(col ==1)
    {
      if(this.sort == 0){
        let sortedNames = this.spendObj.sort((a, b) =>  (a.units < b.units) ? -1 : 1);
        this.spendObj = sortedNames;
        this.spendObj = this.spendObj.slice();
        this.sort = 1;
      }

      else{
        let sortedNames = this.spendObj.sort((a, b) => (a.units > b.units) ? -1 : 1);
        this.spendObj = sortedNames;
        this.spendObj = this.spendObj.slice();
        this.sort = 0;
        
      }
    }

    if(col ==2)
    {
      if(this.sort == 0){
        let sortedNames = this.spendObj.sort((a, b) =>  (a.invoices < b.invoices) ? -1 : 1);
        this.spendObj = sortedNames;
        this.spendObj = this.spendObj.slice();
        this.sort = 1;
      }

      else{
        let sortedNames = this.spendObj.sort((a, b) => (a.invoices > b.invoices) ? -1 : 1);
        this.spendObj = sortedNames;
        this.spendObj = this.spendObj.slice();
        this.sort = 0;
        
      }
    }

    if(col ==3)
    {
      if(this.sort == 0){
        let sortedNames = this.spendObj.sort((a, b) =>  (a.total_spend < b.total_spend) ? -1 : 1);
        this.spendObj = sortedNames;
        this.spendObj = this.spendObj.slice();
        this.sort = 1;
      }

      else{
        let sortedNames = this.spendObj.sort((a, b) => (a.total_spend > b.total_spend) ? -1 : 1);
        this.spendObj = sortedNames;
        this.spendObj = this.spendObj.slice();
        this.sort = 0;
        
      }
    }
  }

}
