<!-- DEBUG -->
<!-- <button nz-button nzType="primary" (click)="debug()">ASSIGN OBJ</button> -->
<!-- DEBUG -->

<nz-modal [nzVisible]="this.sendReportService.isModalVisible()"
    nzTitle="{{this.sendReportService.communicationReport?.type}}: {{this.sendReportService.communicationReport?.reference}}"
    (nzOnCancel)="handleCancel()" [nzFooter]="null">
    <ng-container *nzModalContent>
        <div nz-row>
            <div nz-col [nzSpan]="3" style="text-align: left;">
                PDF:
            </div>            
            <div nz-col [nzSpan]="9" style="text-align: left;">
                <button nz-button [nzType]="'primary'" (click)="onClickPrint()" nz-tooltip="Print" [nzLoading]="sendReportService.communicationReport?.isLoading">
                    <span nz-icon nzType="printer" nzTheme="outline"></span>
                </button>
                <button nz-button [nzType]="'primary'" (click)="onClickDownload()" nz-tooltip="Download" [nzLoading]="sendReportService.communicationReport?.isLoading" style="margin-left: 5px;">
                    <span nz-icon nzType="download" nzTheme="outline"></span>
                </button>
            </div>
            <div nz-col [nzSpan]="6" style="text-align: right;">
                Open Preview:
            </div>
                     
            <div nz-col [nzSpan]="6" style="text-align: center;">
                <button nz-button [nzType]="'primary'" (click)="onClickPreview()" nz-tooltip="Open Preview" [nzLoading]="sendReportService.communicationReport?.isLoading">
                    <span nz-icon nzType="eye" nzTheme="outline"></span>
                </button>
            </div>
        </div>
        <hr />
        <div nz-row>
            <div nz-col [nzSpan]="3">
                Email:
            </div>
            <div nz-col [nzSpan]="15">
                <nz-form-control nzHasFeedback [nzValidateStatus]="this.sendReportService.communicationReport?.isValidEmail ? '' : 'error'">                    
                    <input nz-input placeholder="Please enter email" *ngIf="this.sendReportService.communicationReport" [(ngModel)]="this.sendReportService.communicationReport.email" />
                </nz-form-control>
            </div>     
            <div nz-col [nzSpan]="6" style="text-align: center;">
                <button nz-button [nzType]="'primary'" (click)="onClickEmail()" nz-tooltip="Send via Email" [nzLoading]="sendReportService.communicationReport?.isLoading"
                [disabled]="this.sendReportService.communicationReport?.email?.length === 0 || !this.sendReportService.communicationReport?.isValidEmail">
                    <span nz-icon nzType="mail" nzTheme="outline"></span>
                </button>
            </div>       
        </div>
        <hr />
        <div nz-row>
            <div nz-col [nzSpan]="3">
                Cell: 
            </div>
            <div nz-col [nzSpan]="15">
                <input nz-input placeholder="Please enter cell" *ngIf="this.sendReportService.communicationReport" [(ngModel)]="this.sendReportService.communicationReport.cell" />

                
            </div>
            <div nz-col [nzSpan]="6" style="text-align: center;">
                <!-- <button nz-button [nzType]="'primary'" (click)="onClickSMS()" disabled="true" nz-tooltip="Send via SMS" style="margin-right: 6px;">
                    <span nz-icon nzType="mobile" nzTheme="outline"></span>
                </button> -->
                <button nz-button [nzType]="'primary'" (click)="onClickWhatsApp()" nz-tooltip="Send via WhatsApp" [nzLoading]="sendReportService.communicationReport?.isLoading" [disabled]="this.sendReportService.communicationReport?.cell?.length === 0 || !this.authService.user.waEnabled || this.sendReportService.communicationReport?.disableWhatsapp">
                    <span nz-icon nzType="whats-app" nzTheme="outline"></span>
                </button>
            </div>
        </div>
        



    </ng-container>
</nz-modal>