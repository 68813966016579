import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, HostListener } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NzTableComponent } from 'ng-zorro-antd/table';
import { Observable, Observer, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PackageType, customStockItem, stockType } from 'src/app/models/pos';
import { PosService } from 'src/app/pos/pos.service';
import { CustomStock, CustomStockPackage, StockUsed } from '../../models/pricelist';
import { PricelistsService } from '../../pricelists/pricelists.service';
import { NotificationService } from '../../shared/notification.service';
import { Input } from 'src/app/models/tyres';
import { NzIconService } from 'ng-zorro-antd/icon';

@Component({
  selector: 'app-customstock',
  templateUrl: './customstock.component.html',
  styleUrls: ['./customstock.component.less']
})
export class CustomstockComponent implements OnInit {

  customStock: CustomStock[]=[];
  stockTypes: stockType[]=[];
  stockUsed: StockUsed[] = [];
  addType: stockType = new stockType();
  editcustom: CustomStock = new CustomStock();
  selectedTab = 0
  customStockPackages: CustomStockPackage[]=[];
  packageType: PackageType[]=[];
  editCustomPackage: PackageType = new PackageType();
  pageSize: number = 20;
  input = new Input();

  @HostListener("window:scroll", [])
    onScroll(): void {
    if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight - (document.body.scrollHeight * 0.1)) {
            // you're at the bottom of the page
            this.pageSize += 10;
            this.input.inputValue = this.input.inputValue;
        }
    }
    resetTable(){
        this.pageSize = 20;
    }

  customStockFilter: string = ''

  isLoading = false;
  isLoadingStock = false;
  isLoadingPackages = false;
  isModalVisible = false;
  isAddCustomStockModalVisible = false;
  isPackages = false;
  title = "";

  reactivate = false;
  successCheck = false;
  isActive = false;
  isDisabled = true;

  spanInput = 12;
  spanButton = 8;

  spanStock = 24;
  spanPackages = 0;
  offsetPackages = 0;
  isCreatePackageModal = false;
  isEditPackageModal = false;

  private timer: any;

  editId: number | null = null;
  currentPackage: PackageType = new PackageType();
  editCache: { [key: string]: { edit: boolean; data: PackageType } } = {};

  addCustomItemForm: FormGroup;

  parserNumber = (value: string): string => value.replace("", '1');

  constructor(private pricelistsService: PricelistsService, private notification: NotificationService, private posService:PosService, private fb: FormBuilder, private iconService: NzIconService) {
    this.addCustomItemForm = this.fb.group({
      description: [{value: '', disabled: true}, [Validators.required]],
      stockcode: ['', [Validators.minLength(2), Validators.required], [this.stockCodeAsyncValidator]],
      price: [{value: '', disabled: true}, [Validators.minLength(2),Validators.required]],
    });
    // const plusSVG = '<svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="plus" aria-hidden="true"><defs><style></style></defs><path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z"></path><path d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z"></path></svg>'
    // const editSVG = '<svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="edit" aria-hidden="true"><path d="M257.7 752c2 0 4-.2 6-.5L431.9 722c2-.4 3.9-1.3 5.3-2.8l423.9-423.9a9.96 9.96 0 000-14.1L694.9 114.9c-1.9-1.9-4.4-2.9-7.1-2.9s-5.2 1-7.1 2.9L256.8 538.8c-1.5 1.5-2.4 3.3-2.8 5.3l-29.5 168.2a33.5 33.5 0 009.4 29.8c6.6 6.4 14.9 9.9 23.8 9.9zm67.4-174.4L687.8 215l73.3 73.3-362.7 362.6-88.9 15.7 15.6-89zM880 836H144c-17.7 0-32 14.3-32 32v36c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-36c0-17.7-14.3-32-32-32z"></path></svg>'
    // const deleteSVG = '<svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="delete" aria-hidden="true"><path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"></path></svg>'
    // const minusSVG = '<svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="minus" aria-hidden="true"><path d="M872 474H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h720c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"></path></svg>'

    // this.iconService.addIconLiteral('sf:plus', plusSVG);
    // this.iconService.addIconLiteral('sf:edit', editSVG);
    // this.iconService.addIconLiteral('sf:delete', deleteSVG);
    // this.iconService.addIconLiteral('sf:minus', minusSVG);
   }

  ngOnInit(): void {
    this.loadCustomStock();  
  }

  // Used to exclude special characters from inputs on packages
  omit_special_char(event)
  {   
    var k;  
    k = event.charCode;
    return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
  }

  private loadCustomStock() {
    this.isLoading = true;
    this.isLoadingStock = true;

    this.pricelistsService.getCustomStock().subscribe(
      res => 
      {   
        this.customStock=[];
        this.stockTypes=[];

        this.customStock = res.customStock
        .sort(function(a, b) {
          var keyA = a.description.toLowerCase(),
              keyB = b.description.toLowerCase();
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });
        // this.sortCustomStock();

        this.stockTypes  = res.stockTypes;
        this.stockTypes.forEach(type => {
          type.showTab = true;
        });

        this.stockUsed = res.stockUsed;

        this.loadTab(this.selectedTab);

        this.isLoadingStock = false;

        this.customStockPackages=[];
        this.packageType=[];
        
        this.customStockPackages = res.customStockPackage;
        this.customStockPackages = this.customStockPackages.filter(val => val.customStockGroupItemId != null);

        this.packageType  = res.packageTypes;

        this.packageType.forEach(type => {
          type.showPackageTab = true;
        });

        this.loadPackageTab(this.selectedTab); 
        this.isLoadingPackages = false;

        this.updateEditCache(); 
        // this.loadCustomStockPackages();
        // this.isLoadingStock = false;
        // if(!this.isLoadingPackages)
          // this.isLoading = false;
      },
      err => {
        this.isLoadingStock = false;
        this.isLoadingPackages = false;
        // if(!this.isLoadingPackages)
          // this.isLoading = false;
        this.notification.handleError(err);
      });
  }

  // private loadCustomStockPackages() {
  //   this.isLoading = true;
  //   this.isLoadingPackages = true;
  //   this.pricelistsService.getCustomStockPackages().subscribe(
  //     res => 
  //     {   
  //       this.customStockPackages=[];
  //       this.packageType=[];
        
  //       this.customStockPackages = res.customStockPackage;
  //       this.customStockPackages = this.customStockPackages.filter(val => val.customStockGroupItemId != null);

  //       this.packageType  = res.packageTypes;

  //       this.packageType.forEach(type => {
  //         type.showPackageTab = true;
  //       });

  //       this.loadPackageTab(this.selectedTab); 

  //       this.updateEditCache();

  //       this.isLoadingPackages = false;
  //       if(!this.isLoadingStock)
  //         this.isLoading = false;
  //     },
  //     err => {
  //       this.isLoadingPackages = false;
  //       if(!this.isLoadingStock)
  //         this.isLoading = false;
  //       this.notification.handleError(err);
  //     });
  // }

  loadTab(tab:number)
  {
    this.resetTable();
    this.selectedTab = tab;
    let stockType = this.stockTypes[tab].stockTypeId;
    for(var i = 0; i < this.customStock.length; i++)
    {
      if(this.customStock[i].stockTypeId == stockType)
      {
        this.customStock[i].showTable = true;
      }
      else
      {
        this.customStock[i].showTable = false;
      }
    }
  }

  loadPackageTab(tab:number)
  {
    this.selectedTab = tab;
    let packageType = this.packageType[tab].packageAcronym;
    this.currentPackage = this.packageType[tab];

    for(var i = 0; i < this.customStockPackages.length; i++)
    {
      if(this.customStockPackages[i].customStockGroupAcronym === packageType)
      {
        this.customStockPackages[i].showPackageTable = true;
      }
      else
      {
        this.customStockPackages[i].showPackageTable = false;
      }
    }
  }

  updatecustom()  {
    // Update the packages with the custom stock
    this.customStockPackages.filter((val) =>  {
      if(val.customStockId === Number(this.editcustom.stockId)){
        val.price = this.editcustom.price;
        val.description = this.editcustom.description;
        val.stockCode = this.editcustom.code;
        
      }
    });
    
    this.pricelistsService.updatecustom(this.editcustom).subscribe(res => 
      { 
        this.notification.showSuccessMinimal("Updated Successfully");
        this.isModalVisible = false;
      },
      error => 
      {
        this.notification.handleError(error);       
      });
  }

  loadstockinfo(item: CustomStock)  {
    this.isModalVisible = true;
    this.editcustom = item;
  }

  openAdd(type:string)
  {
    this.resetTable();
    this.isAddCustomStockModalVisible = true;
    this.title = "Add new " + type +" item";
    this.addType = this.stockTypes.find(x=> x.stockDescription == type)!;
  }

  isAddingBuyOut: boolean;
  addCustomStockItem() 
  {
    if(!this.addCustomItemForm.valid)
    {
      Object.values(this.addCustomItemForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
    else
    {
      this.isActive = false;
  
      let custom = new customStockItem();
      custom.description = this.addCustomItemForm.value.description ;
      custom.code = this.addCustomItemForm.value.stockcode.toUpperCase();
      custom.retail_price = this.addCustomItemForm.value.price;
      custom.type = this.addType.stockDescription.toUpperCase();
      custom.stockTypeId = this.addType.stockTypeId
      custom.isBuyOut = this.isAddingBuyOut;
      
      this.isLoading = true;

      this.posService.addCustomStock(custom).subscribe(
        val => {
          if(val.customStock){
          let customStock = new CustomStock();

          customStock = val.customStock;
          customStock.showTable = true;
          customStock.isSearchable = false;

          this.isAddCustomStockModalVisible = false;
            

          this.customStock.push(customStock);
          this.sortCustomStock();

          this.customStock = [...this.customStock];

          this.isLoading = false;
          this.addCustomItemForm.reset();
          }
          
          this.notification.showSuccess(val.Message);  
        },
        error => {
          this.isAddCustomStockModalVisible = false;
          this.isLoading = false;
          this.notification.handleError(error);
        }
      )   
    }
  }

  removeItem(stockItem:CustomStock)
  {
    this.customStock = this.customStock.filter(val => val !== stockItem);
    this.customStockPackages = this.customStockPackages.filter(val => val.customStockId != stockItem.stockId);
    this.pricelistsService.removeCustomStock(stockItem).subscribe(
      val => {
        this.isLoading = false;
        this.notification.showSuccessMinimal("Item succesfully removed.");    
      },
      error => {
        this.notification.handleError(error);
      }
    );
  }

  openPackages(){
    this.resetTable();
    this.isPackages = !this.isPackages;
    if(!this.isPackages){
      this.spanStock = 24;
      this.spanPackages = 0;
      this.offsetPackages = 0;
    }
    else if(this.isPackages){
      this.spanStock = 12;
      this.spanPackages = 12;
      // this.offsetPackages = 1;
    }
  }

  addPackageItem(item: CustomStock): void {
    let itemInsert = new CustomStockPackage();
    itemInsert.clientId = item.clientId;
    itemInsert.customStockGroupId = this.currentPackage.packageTypeId;
    itemInsert.customStockGroupName = this.currentPackage.packageDescription;
    itemInsert.customStockGroupAcronym = this.currentPackage.packageAcronym;
    itemInsert.customStockId = item.stockId;
    itemInsert.description = item.description;
    itemInsert.stockCode = item.code;
    itemInsert.price = item.price;
    itemInsert.fixedQty = null;
    

    this.pricelistsService.setCustomStockPackageItem(itemInsert).subscribe(res => 
      {   
        itemInsert = res as CustomStockPackage;
        itemInsert.showPackageTable = true;
        itemInsert.fixedQty = null;
        this.customStockPackages.push(itemInsert);
        this.customStockPackages = [...this.customStockPackages];
        this.notification.showSuccessMinimal("Created Successfully");
      },
      err => {
        this.notification.handleError(err);
        this.isLoading = false;
      });
  }

  deletePackageItem(id: CustomStockPackage): void {
    this.customStockPackages = this.customStockPackages.filter(d => d !== id);

    this.pricelistsService.removeCustomStockPackageItem(id.customStockGroupItemId).subscribe(() => 
      {   
        this.notification.showSuccessMinimal("Deleted Successfully");
      },
      err => {
        this.notification.handleError(err);
        this.isLoading = false;
      });
  }

  createPackage() {
    if(this.packageType.some(val => {return (val.packageDescription === this.editCustomPackage.packageDescription && val.packageAcronym === this.editCustomPackage.packageAcronym)}))
      this.notification.showWarning("Package already exists");

    else{

      this.isLoading = true;
      this.pricelistsService.setCustomStockPackageGroup(this.editCustomPackage).subscribe(res => 
        {   
          this.editCustomPackage = res as PackageType;
          this.editCustomPackage.showPackageTab = true;

          this.packageType.push(this.editCustomPackage);
          this.packageType = [...this.packageType];

          // this.loadCustomStockPackages();
          this.updateEditCache();
          this.isCreatePackageModal = false;
          this.editCustomPackage = new PackageType();

          this.notification.showSuccessMinimal("Created Successfully");
          this.isLoading = false;
        },
        err => {
          this.notification.handleError(err);
          this.isLoading = false;
        });

      this.isCreatePackageModal = false;
      this.editCustomPackage = new PackageType();
    }
  }

  deletePackage(id: PackageType): void {
    this.packageType = this.packageType.filter(d => d !== id);

    this.pricelistsService.removeCustomStockPackageGroup(id.packageTypeId).subscribe(() => 
      {   
        this.notification.showSuccessMinimal("Deleted Successfully");
        // this.loadCustomStockPackages();
      },
      err => {
        this.notification.handleError(err);
      });

  }

  startEdit(id: number): void {
    this.editCache[id].edit = true;
  }

  cancelEdit(id: number): void {
    const index = this.packageType.findIndex(item => item.packageTypeId === id);
    this.editCache[id] = {
      data: { ...this.packageType[index] },
      edit: false
    };
  }

  saveEdit(id: number): void {
    const index = this.packageType.findIndex(item => item.packageTypeId === id);
    Object.assign(this.packageType[index], this.editCache[id].data);

    this.customStockPackages.forEach(val => {
      if(val.customStockGroupId == this.editCache[id].data.packageTypeId){
        val.customStockGroupName = this.editCache[id].data.packageDescription;
        val.customStockGroupAcronym = this.editCache[id].data.packageAcronym;
      }
    });

    this.pricelistsService.updateCustomStockPackageGroup(this.editCache[id].data).subscribe(res => 
      {   
        this.notification.showSuccessMinimal("Updated Successfully");
        this.editCache[id].edit = false;
        this.isLoading = false;
      },
      err => {
        this.notification.handleError(err);
        this.isLoading = false;
      }
    );
  }

  updateEditCache(): void {
    this.packageType.forEach(item => {
      this.editCache[item.packageTypeId] = {
        edit: false,
        data: { ...item }
      };
    });
  }

  updateTyreQty(item: CustomStock){
    this.pricelistsService.updateCustomStockTyreQty(item).subscribe(() => 
      {   

      },
      err => {
        this.notification.handleError(err);
      }
    );
  }

  updateFixedTyreQty(item: CustomStockPackage){
    // Used in case no quantity needs to be added or if the input field is cleared
    if(!item.fixedQty?.toLocaleString())
      item.fixedQty = null;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.pricelistsService.updateCustomStockPackageFixedTyreQty(item).subscribe(() => 
        {   
        },
        err => {
          this.notification.handleError(err);
        }
      );
    }, 350);
  }


  sortCustomStock(): CustomStock []{
    return this.customStock.sort((a, b) => {
      if (Number(a.stockId) < Number(b.stockId)) return -1;
      if (Number(a.stockId) > Number(b.stockId)) return 1;
      return 0;
    });
  }

  stockCodeAsyncValidator = (control: FormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
    this.reactivate = false;
    this.addCustomItemForm.get("description")?.setValue("");
    this.addCustomItemForm.get("price")?.setValue("");
    this.isDisabled = false;
    this.addCustomItemForm.controls['description'].disable();
    this.addCustomItemForm.controls['price'].disable();

    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if(control.value.length > 1){
        this.addCustomItemForm.controls['description'].enable();
        this.addCustomItemForm.controls['price'].enable();
        this.isDisabled = false;
        this.pricelistsService.getCustomStockCode(this.addCustomItemForm.value.stockcode.toUpperCase()).subscribe(
          val => {          
            if (Object.keys(val).length){
              if(val.isActive){
                observer.next({ error: true, duplicated: true });
                this.addCustomItemForm.controls['description'].disable();
                this.addCustomItemForm.controls['price'].disable();
              }
              else{
                this.addCustomItemForm.get("description")?.setValue(val.description.toUpperCase());
                this.addCustomItemForm.get("price")?.setValue(val.price);
                this.addCustomItemForm.updateValueAndValidity({ onlySelf: true });

                this.reactivate = true;
                this.successCheck = true;
                observer.next(null);
              }
            }
            else {
              observer.next(null);
              this.successCheck = false;

            }
            observer.complete();
          },
          error => {     
            this.notification.handleError(error);          
          }) 
        }     
      }, 750);
    });

  reactivateCustomStock(){
    if(!this.addCustomItemForm.valid)
    {
      Object.values(this.addCustomItemForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
    else
    {
      let custom = new customStockItem();
      custom.description = this.addCustomItemForm.value.description.toUpperCase();
      custom.code = this.addCustomItemForm.value.stockcode.toUpperCase();
      custom.retail_price = this.addCustomItemForm.value.price;
      custom.type = this.addType.stockDescription.toUpperCase();
      custom.stockTypeId = this.addType.stockTypeId
      this.isLoading = true;

      this.addCustomItemForm.controls['description'].disable();
      this.addCustomItemForm.controls['price'].disable();

      this.posService.reactivateCustomStock(custom).subscribe(
        val => {
          if(val.customStock){
          let customStock = new CustomStock();

          customStock = val.customStock;
          customStock.showTable = true;
          customStock.isSearchable = false;

          this.isAddCustomStockModalVisible = false;
            

          this.customStock.push(customStock);
          this.sortCustomStock();

          this.customStock = [...this.customStock];

          this.isLoading = false;
          this.reactivate = false;
  
          this.addCustomItemForm.reset();
          }
          
          this.notification.showSuccess(val.Message);  
        },
        error => {
          this.isAddCustomStockModalVisible = false;
          this.isLoading = false;
          this.notification.handleError(error);
        }
      )   
    }
  }

  closeModal(form: FormGroup){
    this.addCustomItemForm.controls['description'].disable();
    this.addCustomItemForm.controls['price'].disable();
    form.reset();
  }
  filterCustomStock(stockType: string): CustomStock[]{

    if (this.customStockFilter && this.customStockFilter.length > 2){
      let res = this.customStock.filter(e => e.code !== null || e.description !== null).filter(val => (val.description.toUpperCase().includes( this.customStockFilter.toUpperCase()) || val.code.toUpperCase().includes( this.customStockFilter.toUpperCase())))
      return res
    }
    else {
      let res = this.customStock.filter(val => val.type.toLocaleUpperCase() === stockType.toLocaleUpperCase())
      return res
    }
  }

  filterCustomStockPackages(id : number): CustomStockPackage[]{
    return this.customStockPackages.filter(val => val.customStockGroupId === id);
  }
}
