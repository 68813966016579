import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private currentTheme: string = 'default';
  public isDark: boolean = false;

  constructor(private authService: AuthService) {
    if (localStorage.getItem("darkTheme") && localStorage.getItem("darkTheme") === "true") {
      this.isDark = true;
    }
 
    this.loadTheme(this.currentTheme, true);
    
   }

  public switchThemeDarkLight(isDark: boolean) {

    
    localStorage.setItem("darkTheme", isDark.toString());
    this.updateTheme();
  }



  public updateTheme() {
    // This is where we will add logic to determine which theme needs to be loaded
    
    let changeThemeTo: string = "default";
    switch (this.authService.user.licenseId) {
      case 1: {
        changeThemeTo = 'tyremart'; 
        break;
      }
      case 2: {
        changeThemeTo = 'apex'; 
        break;
      }
      case 3: {
        changeThemeTo = 'bestdrive'; 
        break;
      }
      case 4: {
        changeThemeTo = 'tyreworx'; 
        break;
      }
      default: {
        changeThemeTo = 'default';
      }
    } 
    
    //check local storage for dark/light
    if (localStorage.getItem("darkTheme") && localStorage.getItem("darkTheme") === "true") {
      changeThemeTo += "_dark";
    }
    
    if (changeThemeTo !== this.currentTheme && changeThemeTo.length > 0){
      this.loadTheme(changeThemeTo);
    }
    
  }

  themeLogo(): string {
    return this.currentTheme.replace("_dark","") + '.png';
  }

  loadTheme(themeName: string, firstLoad = false): any {
    // let theme = this.currentTheme;
    
    // if (firstLoad) {
    //   document.documentElement.classList.add(themeName);
    //   console.log("Added doc");
      
    // } 
    this.loadCss(`${themeName}.css`, themeName).then(
      e => {
        // if (!firstLoad) {
        //   document.documentElement.classList.add(themeName);
        // }
        if (!firstLoad) {
          this.removeUnusedTheme(this.currentTheme);
        }
        
        this.currentTheme = themeName;


        
        
      },
      //e => reject(e)
    );
    
  }

  private loadCss(href: string, id: string): Promise<Event> {
    return new Promise((resolve, reject) => {
      const style = document.createElement('link');
      style.rel = 'stylesheet';
      style.href = href;
      style.id = id;
      style.onload = resolve;
      style.onerror = reject;
      document.head.append(style);
    });
  }

  private removeUnusedTheme(theme: string): void {
    document.documentElement.classList.remove(theme);
    const removedThemeStyle = document.getElementById(theme);
    if (removedThemeStyle) {
      document.head.removeChild(removedThemeStyle);
    }
  }

}
