import { Injectable } from '@angular/core';
import { CommunicationBase } from 'src/app/models/communication';

@Injectable({
  providedIn: 'root'
})
export class SendReportService {

  isModalVisible() : boolean {
    get : {
      return !(this.communicationReport == null);
    }
  }

  communicationReport? : CommunicationBase;

  constructor() { }

  assignReport(rpt : CommunicationBase) {
    this.communicationReport = rpt;
  }

  removeReport() {
    this.communicationReport = undefined;
  }

  validEmail() : boolean {
    var re = /\S+@\S+\.\S+/;
    if (this.communicationReport && this.communicationReport.email) {
      return re.test(this.communicationReport?.email);
    } 
    else {
      return false;
    }
    
  }
}
