import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { SystemUser, UpdateUser, UserAddModel, UserRole } from '../models/user';
import { NotificationService } from '../shared/notification.service';
import { UsersService } from './users.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.less']
})
export class UsersComponent implements OnInit {

  users: SystemUser[]
  isLoadingUsers = false;
  loadingButtons: string[] = []
  isAddUserModalVisible = false;
  isAddingUser = false;
  isDeleteUserModalVisible = false;
  isEditUserModalVisible = false;
  isEditingUser = false;


  constructor(private userService: UsersService,
    private notification: NotificationService,
    private authService: AuthService) { }

  ngOnInit(): void {
    this.loadAllUsers();
  }

  private loadAllUsers() {
    this.isLoadingUsers = true;
    this.userService.getUsers().subscribe(res => {
      this.users = res;
      this.isLoadingUsers = false;
    },
      error => {
        this.notification.handleError(error);
        this.isLoadingUsers = false;
      }
    )
  }

  onRoleBtnClick(role: UserRole, user: SystemUser) {
    let key = user.userId.toString() + "." + role.roleId.toString();
    this.loadingButtons.push(key);
    //call api
    this.userService.flipRole(user.userId, role.roleId).subscribe(
      val => {

        const roleIndex = user.roles.indexOf(role, 0);
        user.roles[roleIndex] = val;

        //remove the button from loading list
        const index = this.loadingButtons.indexOf(key, 0);
        if (index > -1)
          this.loadingButtons.splice(index, 1);
      },
      error => {
        this.notification.handleError(error);
        //remove the button from loading list
        const index = this.loadingButtons.indexOf(key, 0);
        if (index > -1)
          this.loadingButtons.splice(index, 1);
      }

    )

  }

  addUserForm: FormGroup = new FormGroup({
    usrEmail: new FormControl('', [Validators.email, Validators.required]),
    usrCell: new FormControl('', Validators.compose([Validators.pattern('^[0-9]+$'), Validators.minLength(10), Validators.maxLength(10)])),
    usrFullname: new FormControl('', Validators.required),
    usrPassword: new FormControl('', Validators.required)

  });

  showAddUser() {
    // this.addUserForm.controls.usrUserName.setValue(this.authService.user.username);
    this.isAddUserModalVisible = true;
  }

  addUser() {
    /* The following will loop through all controls and mark them as Dirty, so that visual validation can happen. This only happens on btn click */
    Object.values(this.addUserForm.controls).forEach(control => {
      if (control.invalid) {
        control.markAsDirty();
        control.updateValueAndValidity({ onlySelf: true });
      }
    });
    if (this.addUserForm.valid) {
      let usr = new UserAddModel();
      usr.userName = this.authService.user.username;
      usr.email = this.addUserForm.value.usrEmail;
      usr.cell = this.addUserForm.value.usrCell;
      usr.fullname = this.addUserForm.value.usrFullname;
      usr.password = this.addUserForm.value.usrPassword;
      this.isAddingUser = true;
      this.userService.addUser(usr).subscribe(
        val => {
          this.userService.sendDetailsEmail(val.userId).subscribe(
            val => {
              this.notification.showSuccess(val.message);
              this.isAddingUser = false;
              this.isAddUserModalVisible = false;
              this.loadAllUsers();
            },
            error => {
              this.notification.handleError(error);
              this.isAddingUser = false;
              this.isAddUserModalVisible = false;
              this.loadAllUsers();
            }
          )

        },
        error => {
          this.notification.handleError(error);
          this.isAddingUser = false;
        }
      )

    }


  }


  deletingUser: SystemUser

  showDeleteUser(user: SystemUser) {
    this.deletingUser = user;
    this.isDeleteUserModalVisible = true;
  }

  deleteUser() {
    this.isDeleteUserModalVisible = false;
    this.isLoadingUsers = true;
    this.userService.deleteUser(this.deletingUser.userId).subscribe(
      () => {
        this.loadAllUsers();
      },
      error => {
        this.notification.handleError(error);
        this.loadAllUsers();
      }

    )
  }

  sendingButtons: number[] = [];

  sendUserDetails(userId: number) {
    if (this.sendingButtons.includes(userId))
      return
    this.sendingButtons.push(userId);
    this.userService.sendDetailsEmail(userId).subscribe(
      val => {
        this.notification.showSuccess(val.message);
        const index = this.sendingButtons.indexOf(userId,0);
        if (index > -1)
          this.sendingButtons.splice(index, 1);
      },
      error => {
        this.notification.handleError(error);

      }
    );
  }

  editUserForm: FormGroup = new FormGroup({    
    editFullname: new FormControl('', Validators.required),
    editExtPosId: new FormControl('')
  });
  editingUser: SystemUser;

  onEditUserClick(user: SystemUser) {
    this.isEditUserModalVisible = true;
    this.editingUser = user;
    this.editUserForm.controls['editFullname'].setValue(user.fullname);
    this.editUserForm.controls['editExtPosId'].setValue(user.extPosId);
  }

  editUser() {
    this.isEditingUser = true;
    Object.values(this.editUserForm.controls).forEach(control => {
      if (control.invalid) {
        control.markAsDirty();
        control.updateValueAndValidity({ onlySelf: true });
      }
    });
    if (this.editUserForm.valid) {
      let user = new UpdateUser();
      user.fullName = this.editUserForm.value.editFullname;
      user.extPosId = this.editUserForm.value.editExtPosId;
      this.userService.updateUser(this.editingUser.userId, user).subscribe(
        () => {
          this.isEditUserModalVisible = false;
          this.isEditingUser = false;
          this.notification.showSuccess("User updated");
          this.loadAllUsers();
        },
        err => {
          this.isEditingUser = false;
          this.notification.handleError(err);
        }
      )
    }
  }
}
