import { Component, OnInit, TemplateRef } from '@angular/core';
import { WhatsappService } from '../whatsapp.service';
import { WAMessage } from 'src/app/models/whatsapp';
import { DatePipe } from '@angular/common';
import { AppComponent } from 'src/app/app.component';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { NotificationService } from 'src/app/shared/notification.service';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-wa-conversation',
  templateUrl: './wa-conversation.component.html',
  styleUrls: ['./wa-conversation.component.less']
})
export class WaConversationComponent implements OnInit {

  constructor(public waService: WhatsappService, public appComponent: AppComponent, private notification: NotificationService, private modal: NzModalService) { }

  isImageOpen = false;
  imageBase64: string = '';
  datePipe: DatePipe = new DatePipe(this.waService.locale);
  

  ngOnInit(): void {
    this.waService.MarkConversationAsRead();
    
  }

  clearMessage() {
    this.waService.ClearConversation();
  }

  public messageText: string = ''
  sendMessage() {
    if (this.messageText.length > 0) {
      this.waService.sendMessage(this.messageText);
      this.messageText = '';
    }
    
  }

  fileToSend: NzUploadFile;
  fileList: NzUploadFile[] = [];

  sendImage(file: NzUploadFile): boolean {
    //check file type:
    if (!(['image/jpeg','image/png','application/pdf'].includes(file.type!))) {
      this.notification.showWarning("Only image files or PDF files allowed");
      return false;
    }
    if (file.size! > 5242000) {
      //whatsapp has max file size of 5mb for images and 100mb for docs.
      //5mb = 5 242 880 bytes, so we are checking for almost 1kb under 5Mb.
      this.notification.showWarning("Maximum file size is 5Mb");
      return false;
    }
    
    this.fileList = [];
    this.fileList = this.fileList.concat(file); //if we want to upload multiple files, but for now we only want one
    //this can be used when wanting to split the SELECT FILE and UPLOAD FILE into two seperate sections.
    let formData = new FormData();
    this.fileList.forEach((file: any) => {
      formData.append('File', file);
    });
    this.waService.sendImage(formData);

    return false; //Will tell the nzUpload to not hit it's own ACTION, we did everything manually.
  }

  openImageModal(tplContent: TemplateRef<{}>, image: string) {
    //this.imageBase64 = b64;
    //this.isImageOpen = true;
    this.modal.create({
      nzTitle: "WhatsApp Image",
      nzContent: tplContent,
      nzFooter: null,
      nzWidth: "90%",
      nzData:{
        value:image
      }
    })
  }

  downloadPdf(base64: any, fileName: string) {    
    const link = document.createElement("a")
    link.href = base64
    link.download = fileName
    link.click()
    link.remove()      
  }

  formatDateDelivery(msg: WAMessage) {
    return 'DELIVERED: ' + this.datePipe.transform(msg.dateDelivered + 'Z', 'dd MMM HH:mm');//msg.dateDelivered + 'Z' | date:'dd MMM HH:mm'
  }

  formatDateRead(msg: WAMessage) {
    return 'READ:' + this.datePipe.transform(msg.dateRead + 'Z', 'dd MMM HH:mm');//msg.dateDelivered + 'Z' | date:'dd MMM HH:mm'
  }

  selectedTemplate = null;
  sendTemplateMessage() {
    if (this.selectedTemplate == null) {
      this.notification.showWarning("Please select a pre-approved message from the dropdown");
      return;
    }
    this.waService.sendTemplate(this.selectedTemplate!);
    this.selectedTemplate = null;
    
  }

}
