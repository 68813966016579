import { stockType, PackageType } from "./pos"

export class PriceNames {
    clientId: number
    plinternal: string
    deflt: string
    wholesale: string
    plretail: string
    plonline:string
    plclear: string
    plcustom1:string
    plcustom2:string
    plcustom3: string
    plcustom4:string
}

export class PriceData
{
    stockindx:number
    stock_type:string
    brandLogo_url:string
    msfid: number
    description: string
    description_long: string
    brand:string
    stockcode: string
    cost: number
    soh: number
    pricedefault: number
    priceretail: number
    pricewholesale: number
    pricecustom1: number
    pricecustom2: number
    pricecustom3: number
    pricecustom4: number
    priceclearout: number
    priceonline: number
    priceinternal: number
    retail: number
    lastCost: number
    runningTotal: number
}

export class PriceMatrixOptions {
    active:string
    external: []
    retail: []
    online: []
    wholesale: []
    internal: []
    c1:[]
    c2:[]
    c3:[]
}

export class PriceMatrixApplied {
    external:number
    retail:number
    online:number
    wholesale:number
    internal:number
    c1:number
    c2:number
    c3:0
}

export class PriceMatrixDesc {
    external:number
    retail:number
    online:number
    wholesale:number
    internal:number
    c1:number
    c2:number
    c3:0
}

export class PriceMatrixObject {
    pmtext: string
    pm_select: string
    pm_active:string
    pm_applied_id:number

}

export class PriceMatrix {
    name: string
    method: string
    value: number
    pricelistsId: string
    sf_brandId: number
    description: string
    methodcheck: number
    image : string
}

export class Plupdate{
    key: string
    price_matrix:string[] = []
    pm_id: number
    pm_applied: number
}

export class CustomStock{
    stockId: number
    description: string
    code: string
    msfid: number
    type: string
    price:number
    clientId:number
    totalsales:number
    mapped: number
    isSearchable?:boolean
    isActive?:boolean
    linkedToTyreQty?:boolean
    showTable?:boolean
    soh?:number
    stockTypeId?:number
    StockTypeImage?:string
    isBuyOut: boolean
}

export class CustomStockPackage{
    customStockGroupId: number
    customStockGroupName: string
    customStockGroupAcronym: string
    clientId: number
    customStockId: number
    description: string
    stockCode: string
    price: number
    customStockGroupItemId: number
    fixedQty: number | null
    showPackageTable?:boolean
}

export class StockReasons {
  reasonId:number
  reason:string
}

export class ReasonAdjust {
  stockAdjustId:number
  reasonId:number
  qtyFrom?:number
  qtyTo:number
  dt:Date
  userId:number
  stockIndex:number
}

export class StockReturn {
  customStock: CustomStock[]=[];
  stockTypes: stockType[]=[];
  customStockPackage: CustomStockPackage[]=[];
  packageTypes: PackageType[]=[];
}

export class StockReturnPackages {
    customStockPackage: CustomStockPackage[]=[];
    packageTypes: PackageType[]=[];
  }

export class Stock {
    description: string;
    stockCode: string;
    price: number;
    isActive: string;
}


export class StockItem {
    description: string
    stock_code: string
    msfid: number
    locationId: number
    locationName: string
    brand: string
    gen_code: string
    mapped?:number = 0
    showUnmap?:boolean = false;
    searchedGenCode?:string;
  }
  
  export class PriceName{
    plInternal: string = '';
    plDefault: string = '';
    plWholesale: string = '';
    plRetail: string = '';
    plOnline: string = '';
    plClearOut: string = '';
    plCustom1: string = '';
    plCustom2: string = '';
    plCustom3: string = '';
    plCustom4: string = '';
  }

  export class PriceListItem{
    stockIndex: number = 0;
    stock_type: string = '';
    brandLogo_url: string = '';
    brand: string = '';
    msfid: number = 0;
    stockCode: string = '';
    description_long: string = '';
    description: string = '';
    soh: number = 0;
    cost: number = 0;
    pricedefault: number
    priceretail: number
    pricewholesale: number
    pricecustom1: number
    pricecustom2: number
    pricecustom3: number
    pricecustom4: number
    priceclearout: number
    priceonline: number
    priceinternal: number
    retail: number = 0;
    lastCost: number = 0;
    runningTotal: number = 0;
    overridePricericeDefault: number = 0;
    overridePricericeRetail: number = 0;
    overridePricericeWholesale: number = 0;
    overridePricericeCustom1: number = 0;
    overridePricericeCustom2: number = 0;
    overridePricericeCustom3: number = 0;
    overridePricericeCustom4: number = 0;
    overridePricericeOnline: number = 0;
    overridePricericeInternal: number = 0;
    overridePricericeClearOut: number = 0;
    originalPriceDefault: number = 0;
    originalPriceRetail: number = 0;
    originalPriceWholesale: number = 0;
    originalPriceCustom1: number = 0;
    originalPriceCustom2: number = 0;
    originalPriceCustom3: number = 0;
    originalPriceCustom4: number = 0;
    originalPriceOnline: number = 0;
    originalPriceInternal: number = 0;
    originalPriceClearOut: number = 0;
  }
  export class PriceList{
    clientId: number
    priceNames: PriceName = new PriceName()
    items: PriceListItem[]
    reasons  : StockReasons[]
  }