import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomerVehicle, PosAuditEntry, PosAuditEntryType, PosQuote, VehicleReg,CustomerDebtorAcc } from 'src/app/models/pos';
import { NotificationService } from 'src/app/shared/notification.service';
import { PosService } from '../../pos.service';
import { PosAuditService } from '../../posaudit.service';
import { AuthService } from 'src/app/auth.service';

@Component({
  selector: 'app-pq-customer-details',
  templateUrl: './pq-customer-details.component.html',
  styleUrls: ['./pq-customer-details.component.less']
})
export class PqCustomerDetailsComponent implements OnInit {

  @Input() posQuote: PosQuote;
  @Output() removeCustomerEvent = new EventEmitter<number>();
  @Input() isWIP: boolean;
  @Input() isCompleted: boolean;
  
  linkedReg: VehicleReg = new VehicleReg();
  customerDebtorInfo: CustomerDebtorAcc = new CustomerDebtorAcc();
  customerReg: VehicleReg[] = [];
  DebtorAccs: CustomerDebtorAcc[] = [];
  isLoading = false;
  showRegModal = false;
  showDebtorModal = false;
  isLoadingLinkedReg = false;
  isLoadingDebtors = false;
  showAddVehicleModal = false;
  showAddDebtorModal = false;
  isLoadingAddVehicle = false;
  isOdoModalVisible = false;
  addVehicleForm: FormGroup = new FormGroup({
    vehReg: new FormControl('', [Validators.required, Validators.minLength(2)]),
    vehWidth: new FormControl('',),
    vehProfile: new FormControl('',),
    vehRim: new FormControl('',)
    
  });
  addOdoForm: FormGroup = new FormGroup({
    vehOdo: new FormControl('', Validators.required),
    
  });
  addDebtorForm: FormGroup = new FormGroup({
    debtorAcc: new FormControl('', [Validators.required]),   
  });

  addVATNumberForm: FormGroup = new FormGroup({
    VATNumber: new FormControl('', [Validators.required, Validators.minLength(2)]),   
  });


  constructor(
    private posService: PosService, 
    private notification: NotificationService,
    private auditService: PosAuditService,
    private authService: AuthService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.loadVehicle();
    this.loadCustomerConncetions();
  }

  private loadVehicle() {
    this.posService.getLinkedVehicleReg(this.posQuote.quoteId).subscribe(
      val => {
        this.linkedReg = val;
        this.isLoading = false;
        
      },
      error => {
        this.notification.handleError(error)
      }
    )
  }

  private loadCustomerConncetions()
  {
    this.posService.getLinkeddebtorAcc(this.posQuote.quoteId,this.posQuote.custId).subscribe(
     (val:any) => {
        this.customerDebtorInfo = val;
        this.isLoading = false;
      },
      error => {
        this.notification.handleError(error)
      }
    )

  }

  onRegClick() {
    this.showRegModal = true;
    this.isLoadingLinkedReg = true;
    this.posService.getCustomerVehicleReg(this.posQuote.custId).subscribe(
      val => {
        this.customerReg = val;
        this.isLoadingLinkedReg = false;
      },
      error => {
        this.notification.handleError(error);
        this.showRegModal = false;
      }
    )
  }

  onDebtorClick() {
    this.showDebtorModal = true;
    this.isLoadingDebtors = true;
    this.posService.getCustomerDebtorAcc(this.posQuote.custId).subscribe(
      val => {
        this.DebtorAccs = val;
        /* Ben please add clients debtor account named debtorAcc to token so i can extract it here 
        let clinetAcc = new CustomerDebtorAcc();
        clinetAcc.customerId=this.posQuote.custId;
        clinetAcc.debtorAcc = this.authService.user.debtorAcc;
        this.DebtorAccs.push(clinetAcc) */
        this.isLoadingLinkedReg = false;
      },
      error => {
        this.notification.handleError(error);
        this.showRegModal = false;
      }
    )   
    this.isLoadingDebtors = false;
  }

  onAddVehicleClick() {
    this.addVehicleForm.patchValue({
      vehWidth: this.posQuote.width,
      vehProfile: this.posQuote.profile,
      vehRim: this.posQuote.rim
    })
    
    this.showAddVehicleModal = true;
  }

  onAddDebtorClick()
  {
    // this.addDebtorForm.patchValue({
    //  // debtorAcc: this.authService.user.debtorAcc,
    // })
    this.showAddDebtorModal = true;
  }

  onUnlinkVehicleClick() {
    this.auditService.addAction(new PosAuditEntry(this.posQuote.quoteId, PosAuditEntryType.UnlinkedVehicle, 0, this.linkedReg.vReg));
    this.linkRegToQuote(0);
  }

  onUnlinkDebtorClick() {
    this.linkDebtorToCustomer('remove');
  }

  linkRegToQuote(mappingId: number) {
    this.showRegModal = false;
    this.showAddVehicleModal = false;
    this.isLoading = true;
    this.posService.linkRegToQuote(mappingId, this.posQuote.quoteId).subscribe(
      val => {
        
        this.posService.getLinkedVehicleReg(this.posQuote.quoteId).subscribe(
          val => {
            this.linkedReg = val;
            this.isLoading = false;
            if (mappingId !== 0) {
              this.auditService.addAction(new PosAuditEntry(this.posQuote.quoteId, PosAuditEntryType.LinkedVehicle, mappingId, this.linkedReg.vReg));
            }
            
            this.auditService.saveActions();
          },
          error => {
            this.notification.handleError(error)
          }
        )
      },
      error => {
        this.notification.handleError(error);
      }
    )
  }

  linkDebtorToCustomer(debtorAcc: string)
  {
    this.showDebtorModal = false;
    this.showAddDebtorModal = false;
    this.isLoading = true;
    this.posService.linkDebtorToCustomer(debtorAcc,this.posQuote.custId ,this.posQuote.quoteId).subscribe(
      val => {
        this.posService.getLinkeddebtorAcc(this.posQuote.quoteId,this.posQuote.custId).subscribe(
          (val:any) => {
             this.customerDebtorInfo = val;
             this.isLoading = false;
           },
           error => {
             this.notification.handleError(error)
           }
         )
      },
      error => {
        this.notification.handleError(error);
      }
    )

  }

  addVehicle() {
    if (this.addVehicleForm.value.vehReg.length < 2) 
    {
      this.notification.showWarning("Invalid vehicle registration");
      return;
    }  
    this.isLoadingAddVehicle = true;
    let veh: CustomerVehicle = new CustomerVehicle()
    veh.vehReg = this.addVehicleForm.value.vehReg;
    veh.vehWidth = this.addVehicleForm.value.vehWidth;
    veh.vehProfile = this.addVehicleForm.value.vehProfile;
    veh.vehRim = this.addVehicleForm.value.vehRim;
    
    this.posService.addCustomerVehicle(this.posQuote.custId, veh).subscribe(
      val => {
        this.isLoadingAddVehicle = false;
        this.auditService.addAction(new PosAuditEntry(this.posQuote.quoteId, PosAuditEntryType.AddedVehicleReg, val.mappingId, veh.vehReg));
        this.linkRegToQuote(val.mappingId);
      },
      error => {
        this.isLoadingAddVehicle = false;
        this.notification.handleError(error);
      }
    )
  }

  addDebtor()
  {
    if (this.addDebtorForm.value.debtorAcc.length < 2) 
    {
      this.notification.showWarning("Invalid Debtor Account");
      return;
    } 
    this.isLoadingDebtors = true;
    this.linkDebtorToCustomer(this.addDebtorForm.value.debtorAcc);
  }

  onOdoClick() {
    this.addOdoForm.controls["vehOdo"].setValue(this.linkedReg.odoReading ? this.linkedReg.odoReading : 0)
    
    this.isOdoModalVisible = true;
  }

  onAddOdoClick() {
    this.posService.updateQuoteOdo(this.posQuote.quoteId, this.addOdoForm.value.vehOdo).subscribe(
      val => {
        this.auditService.addAction(new PosAuditEntry(this.posQuote.quoteId, PosAuditEntryType.AddedODOReading, this.linkedReg.mappingId, this.linkedReg.vReg + " " + this.addOdoForm.value.vehOdo.toString() + "km"));
        this.auditService.saveActions();
        this.isOdoModalVisible = false;
        this.notification.showSuccess("Odo reading updated");
        this.isLoading = true;
        this.loadVehicle();
      },
      error =>{
        this.notification.handleError(error);
      }
    )
  }

  isVATModalVisible = false;

  onCustomerClick() {
    this.addVATNumberForm.patchValue({
      VATNumber: this.posQuote.custVATNumber
    })
    this.isVATModalVisible = true;
  }

  onVATNumberAddClick() {
    let vatNo = this.addVATNumberForm.value.VATNumber.length > 0 ? this.addVATNumberForm.value.VATNumber : undefined;
    this.posService.updateCustomerVATNumber(this.posQuote.custId, vatNo).subscribe(
      _ => {
        this.notification.showSuccess("VAT Number updated");
        this.posQuote.custVATNumber = vatNo,
        this.isVATModalVisible = false;
      },
      error => {
        this.notification.handleError(error);
      }
    )
  }

  removeCustomer() {
    this.removeCustomerEvent.emit(0);
  }

}
