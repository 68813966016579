import { Component, OnInit } from '@angular/core';
import { ClientStockMappingsService } from '../client-stock-mappings.service';
import { Stock, UnMappedStock, TyreStockToBeMapped, CustomStock, StockType, NewCustomStockMapping, CustomStockToBeMapped } from 'src/app/models/client-mappings';
import { NotificationService } from 'src/app/shared/notification.service';
import { ClientStockMappingsComponent } from '../client-stock-mappings.component';
import { AdminMappingService } from 'src/app/admin-stock-mappings/admin-mappings.service';
import { AuthService } from 'src/app/auth.service';
import { StockItem } from 'src/app/models/pricelist';
import { filteredStockItem } from 'src/app/models/stock';
import { UsedStockClassification, UsedStockItemToBeMapped } from 'src/app/models/admin-mappings';
@Component({
  selector: 'app-client-mappings-modal',
  templateUrl: './client-mappings-modal.component.html',
  styleUrls: ['./client-mappings-modal.component.less']
})
export class ClientMappingsModalComponent {

  isMapping : boolean = false;
  isLoadingActiveStock : boolean = false;

  isCustomMappingTab = false;

  selectedMap: Stock | null = null;

  selectedCustomMap : CustomStock | null;

  activeStock: Stock[] = [];
  activeStockTemp: Stock[] = [];

  customStockTypes: StockType[] =[]

  customStockCode: string = '';
  customStockType: StockType = { id: 0, name: 'null', msfId: 0, isVisible: false };
  customDescription: string = '';

  isNmsfAddOpen:boolean = false;

  itemToAdd:StockItem = new StockItem();

  filteredStock: filteredStockItem[] = [];
  activeCustomStock: CustomStock[] = [];
  
  usedStockClassification: UsedStockClassification[];
  selectedUsedStockClassification: UsedStockClassification;
  selectedUsedStockClassificationId: number = 1;
  constructor(
    public clientMappingService: ClientStockMappingsService,
    private notification: NotificationService,
    public clientStockMappingComponent: ClientStockMappingsComponent,
    public adminMappingService: AdminMappingService,
    public authService: AuthService
  ) { }


  ngOnInit(): void {
    this.isLoadingActiveStock = true;
    this.adminMappingService.getActiveStock().subscribe( (response : Stock[]) => {
      this.isLoadingActiveStock = false;
      this.activeStockTemp = response;
      this.activeStock = this.activeStockTemp;
    }, (error) => {
      this.isLoadingActiveStock = false;
      this.notification.ShowAlert(error);
    });
    this.adminMappingService.getCustomStockTypes().subscribe(
      (stockTypes: StockType[]) => {
        this.clientMappingService.customStockTypes = stockTypes;
      },
      (error) => {
        this.notification.showError('Error fetching custom stock types', error);
      }
    );
    this.adminMappingService.getUsedStockClassifications().subscribe(
      (usedStockClassification: UsedStockClassification[]) => {
        this.usedStockClassification = usedStockClassification;
      },
      (error) => {
        this.notification.showError('Error fetching used stock classifications', error);
      }
    );
  }

  handleClose(): void {
    this.clientMappingService.modalVisibility = false;
  }
  
  setSelectedMap(stock: Stock): void {
    this.selectedMap = stock;
  }

  mapTyreStock(unmappedStockItem: UnMappedStock, msfIdOfExistingStock: number, stockTypeId: number, mapType: number, close: boolean, next: boolean){
    this.isMapping = true;
    let tyreStockItem = new TyreStockToBeMapped();

    tyreStockItem.description = unmappedStockItem.description;
    tyreStockItem.msfid = msfIdOfExistingStock;
    tyreStockItem.stockCode = unmappedStockItem.stockCode;
    tyreStockItem.clientId = unmappedStockItem.clientId;

    this.clientMappingService.mapTyreStock(tyreStockItem).subscribe(res => {
      this.notification.showSuccess("Changes saved successfully");
      this.isMapping = false;
      this.selectedMap = null;
      if (close) {
        this.clientMappingService.modalVisibility = false;
      }
      if (next) {
        const currentIndex = this.clientStockMappingComponent.unmappedStock.findIndex(item => item === unmappedStockItem);
        if (currentIndex !== -1 && currentIndex < this.clientStockMappingComponent.unmappedStock.length - 1) {
          const nextItem = this.clientStockMappingComponent.unmappedStock[currentIndex+1];
          this.clientMappingService.unmappedStockItem = nextItem; 
 
        }
      }
      this.clientStockMappingComponent.refreshUnmappedTable(unmappedStockItem)
    }, (error) => {
      this.notification.ShowAlert(error);
      this.isMapping = false;
    });
    
  }

  setTab(tab: string) {
    if (tab === 'tyre') {
      this.isCustomMappingTab = false;
    } else if (tab === 'custom') {
      this.isCustomMappingTab = true;
    }
  }

  addCustomStockMapping(stockCode: string, description: string, stockType: StockType, unmappedStock : UnMappedStock) {
    this.isMapping = true;
    let nmsfId = stockType.msfId;
    let stockTypeId = stockType.id;
    let newCustomStockMapping = new NewCustomStockMapping();

    newCustomStockMapping.stockCode = stockCode;
    newCustomStockMapping.clientId = this.authService.user.client_id;
    newCustomStockMapping.userId = this.authService.user.user_id
    newCustomStockMapping.description = description;
    newCustomStockMapping.nmsfId = nmsfId;
    newCustomStockMapping.mappingTypeId = 5;
    newCustomStockMapping.officialStock = '';
    newCustomStockMapping.stockTypeId = stockTypeId;

    this.clientMappingService.createCustomStockMapping(newCustomStockMapping).subscribe(res => {
      this.notification.showSuccess("Changes saved successfully");
      this.mapCustomStock(unmappedStock, nmsfId, stockTypeId, 1, true, false);
      this.isMapping = false;
      //this.isAddCustomStockOpen = false;
      this.selectedCustomMap= null;
      this.clearForm();

    },
      error => {
        this.notification.handleError(error);
        this.isMapping = false;
      })
  }

  clearForm(): void {
    this.customStockCode = '';
    this.customStockType = { id: 0, name: 'null', msfId: 0, isVisible: false };
    this.customDescription = '';
  }

  mapCustomStock(unmappedStockItem: UnMappedStock, msfIdOfExistingStock: number, stockTypeId: number, mapType: number, close: boolean, next: boolean) {
    this.isMapping = true;
    const customStockItem: CustomStockToBeMapped = {
      clientId: unmappedStockItem.clientId,
      description: unmappedStockItem.description,
      msfid: msfIdOfExistingStock,
      stockCode: unmappedStockItem.stockCode,
      stockTypeId: stockTypeId
    };
    
    this.clientMappingService.mapCustomStock(customStockItem).subscribe(res => {
      this.notification.showSuccess("Changes saved successfully");
      this.isMapping = false;
      this.selectedCustomMap = null;

      if (close) {
        this.clientMappingService.modalVisibility = false;
      }
      if (next) {
        const currentIndex = this.clientStockMappingComponent.unmappedStock.findIndex(item => item === unmappedStockItem);
        if (currentIndex !== -1 && currentIndex < this.clientStockMappingComponent.unmappedStock.length - 1) {
          const nextItem = this.clientStockMappingComponent.unmappedStock[currentIndex+1];
          this.clientMappingService.unmappedStockItem = nextItem;
          
        }
      }
      this.clientStockMappingComponent.refreshUnmappedTable(unmappedStockItem);
    },
      error => {
        this.notification.handleError(error);
        this.isMapping = false;
      })
  }

  nextTyre(unmappedStockItem: UnMappedStock) {
    const currentIndex = this.clientStockMappingComponent.unmappedStock.findIndex(item => item === unmappedStockItem);
    if (currentIndex !== -1 && currentIndex < this.clientStockMappingComponent.unmappedStock.length - 1) 
      {
      const nextItem = this.clientStockMappingComponent.unmappedStock[currentIndex+1];
      this.clientMappingService.unmappedStockItem = nextItem
    } else {
      this.notification.showInfo("No more items to map");
    }
  }

  isThereNextTyre(unmappedStockItem: UnMappedStock) {
    const currentIndex = this.clientStockMappingComponent.unmappedStock.findIndex(item => item === unmappedStockItem);
    if (currentIndex !== -1 && currentIndex < this.clientStockMappingComponent.unmappedStock.length - 1) 
      {
      const nextItem = this.clientStockMappingComponent.unmappedStock[currentIndex+1];
      if (nextItem) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  searchedTerm: string = "";

  searchActiveStock(value: string) {
    if (!value) {
      this.searchedTerm = "";
      return;
    }
    this.searchedTerm = value;
    if (value && value.length > 2) {

      this.filteredStock = this.activeStock.filter(e =>
        (e.stockCode && e.stockCode.toLowerCase().includes(value.toLowerCase())) ||
        (e.description && e.description.toLowerCase().includes(value.toLowerCase())) ||
        (e.brand && e.brand.toLowerCase().includes(value.toLowerCase())) ||
        (e.genCode && e.genCode.toLowerCase().includes(value.toLowerCase())) ||
        (e.msfid && (e.msfid == Number(value) || (typeof e.msfid === 'string' && e.msfid === value))) ||
        (e.genCode + ' ' + e.brand + ' ' + e.description + ' (' + e.stockCode + ')').toLowerCase().includes(value.toLowerCase())).map(e => {
          return { key: + e.msfid, name: e.genCode + ' ' + e.brand + ' ' + e.description + ' (' + e.stockCode + ')' }
        });
    }

  }

  searchActiveCustomStock(value: string) {
    if (!value) {
      this.searchedTerm = "";
      return;
    }
    this.searchedTerm = value;
    if (value && value.length > 2) {

      this.filteredStock = this.activeCustomStock.filter(e =>
        (e.stockCode && e.stockCode.toLowerCase().includes(value.toLowerCase())) ||
        (e.description && e.description.toLowerCase().includes(value.toLowerCase())) ||
        (e.msfid && (e.msfid == Number(value) || (typeof e.msfid === 'string' && e.msfid === value))) ||
        (e.description + ' (' + e.stockCode + ')').toLowerCase().includes(value.toLowerCase())).map(e => {
          return { key: + e.msfid, name: e.description + ' (' + e.stockCode + ')' }
        });
    }
  }
  
openNMSFAdd(){
  this.isNmsfAddOpen = true;
  if (this.clientMappingService.unmappedStockItem.clientId) {
    this.itemToAdd.locationId = this.clientMappingService.unmappedStockItem.clientId;
  }
  else{
    this.itemToAdd.locationId = 0;
  }
  
  this.itemToAdd.description =this.clientMappingService.unmappedStockItem.description;
  this.itemToAdd.stock_code =this.clientMappingService.unmappedStockItem.stockCode;
  this.itemToAdd.searchedGenCode = this.searchedTerm;
}

mapUsedStockItem(unmappedStockItem: UnMappedStock, msfIdOfExistingStock: number, classifictionId:number, close: boolean, next: boolean){
  this.isMapping = true;

  const usedStockItem = new UsedStockItemToBeMapped();
  usedStockItem.clientId = unmappedStockItem.clientId;
  usedStockItem.description = unmappedStockItem.description;
  usedStockItem.msfId = msfIdOfExistingStock;
  usedStockItem.stockCode = unmappedStockItem.stockCode;
  usedStockItem.classificationId = classifictionId;
  usedStockItem.soh = unmappedStockItem.soh;
  usedStockItem.cost = unmappedStockItem.cost;

  this.clientMappingService.mapUsedStockItem(usedStockItem).subscribe(res => {
    this.notification.showSuccess("Changes saved successfully");
    this.isMapping = false;
    this.selectedMap = null;
    if (close) {
      this.adminMappingService.currentVisibility = false;
    }

    if (next) {
      const currentIndex = this.clientStockMappingComponent.unmappedStock.findIndex(item => item === unmappedStockItem);
      if (currentIndex !== -1 && currentIndex < this.clientStockMappingComponent.unmappedStock.length - 1) {
        const nextItem = this.clientStockMappingComponent.unmappedStock[currentIndex+1];
        this.adminMappingService.unmappedStockItem = nextItem     
      }
    }
    this.clientStockMappingComponent.refreshUnmappedTable(unmappedStockItem);      
    }, error => {
        this.notification.handleError(error);
        this.isMapping = false;

      })

}

setSelectedUsedStockClassification(usedStockClassification: UsedStockClassification){
  this.selectedUsedStockClassification = usedStockClassification;
  this.selectedUsedStockClassificationId = usedStockClassification.classificationId;
}
}
