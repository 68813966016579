<div id="Initial" class="container-fluid col-lg-11  bg-1">
    <nz-spin [nzSpinning]="isLoading">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12 text-center ">
                    <p></p>
                    <p></p>
                    <p class="" style="font-size: 14px;">
                        This page allows you to add additional functionally that is unique to
                        <ng-container *ngIf="customer">
                            <b><span class="text-style">{{ this.customer.name }}</span></b>
                        </ng-container>
                    </p>
                    <app-video-widget [videoId]="18" style="float: inline-end;"></app-video-widget>

                </div>
            </div>
        </div>
        <br>
        <nz-tabset nzCentered nzSize="large">
            <nz-tab nzTitle="Accounting">
                <div class="container">
                    <div nz-row class="row ring-border">
                        <div nz-col [nzSpan]="12">
                            <div nz-row>
                                <div nz-col [nzSpan]="24">
                                    <h6 class="text-style">
                                        Settings
                                    </h6>
                                </div>
                            </div>
                            <div nz-row>
                                <div nz-col [nzSpan]="24">
                                    <label>Debtor Account</label>
                                    <nz-input-group [nzPrefix]="prefixTemplateUser">
                                        <input nz-input [(ngModel)]="Settings.debtorAcc" placeholder="Debtor Account">
                                    </nz-input-group>
                                    <ng-template #prefixTemplateUser>
                                        <span nz-icon nzType="contacts" nzTheme="outline"></span>
                                    </ng-template>
                                </div>
                            </div>
                            <br>
                            <div nz-row>
                                <div nz-col [nzSpan]="24">
                                    <label>Selling Process</label>
                                    <nz-select [(ngModel)]="Settings.sellingProcess" style="width: 100%;">
                                        <ng-template [ngIf]=" Settings.sellingProcess =='email'">
                                            <nz-option nzLabel="Send Email" nzValue="email"></nz-option>
                                            <nz-option *ngIf="authService.user.isAPI" nzLabel="Insert into POS and Send Email" nzValue="pos"></nz-option>
                                            <nz-option nzLabel="Show Price but Disable Ordering" nzValue="no"></nz-option>
                                            <nz-option nzLabel="Show Stock but not Price" nzValue="soh"></nz-option>
                                        </ng-template>
                                        <ng-template [ngIf]=" Settings.sellingProcess =='pos'">
                                            <nz-option *ngIf="authService.user.isAPI" nzLabel="Insert into POS and Send Email" nzValue="pos"></nz-option>
                                            <nz-option nzLabel="Show Price but Disable Ordering" nzValue="no"></nz-option>
                                            <nz-option nzLabel="Send Email" nzValue="email"></nz-option>
                                            <nz-option nzLabel="Show Stock but not Price" nzValue="soh"></nz-option>
                                        </ng-template>
                                        <ng-template [ngIf]=" Settings.sellingProcess =='soh'">
                                            <nz-option nzLabel="Show Stock but not Price" nzValue="soh"></nz-option>
                                            <nz-option *ngIf="authService.user.isAPI" nzLabel="Insert into POS and Send Email" nzValue="pos"></nz-option>
                                            <nz-option nzLabel="Show Price but Disable Ordering" nzValue="no"></nz-option>
                                            <nz-option nzLabel="Send Email" nzValue="email"></nz-option>
                                        </ng-template>
                                        <ng-template [ngIf]=" Settings.sellingProcess !='email' && Settings.sellingProcess !='soh' && Settings.sellingProcess !='pos' ">
                                            <nz-option nzLabel="Show Price but Disable Ordering" nzValue="no"></nz-option>
                                            <nz-option nzLabel="Show Stock but not Price" nzValue="soh"></nz-option>
                                            <nz-option *ngIf="authService.user.isAPI" nzLabel="Insert into POS and Send Email" nzValue="pos"></nz-option>
                                            <nz-option nzLabel="Send Email" nzValue="email"></nz-option>
                                        </ng-template>
                                    </nz-select>
                                </div>
                            </div>
                            <br>
                            <div nz-row>
                                <div nz-col [nzSpan]="24">
                                    <label>Credit Limit<span class="text-style"> (ZAR)</span> </label>
                                    <nz-input-group [nzPrefix]="prefixTemplateUser">
                                        <input nz-input [(ngModel)]="Settings.creditlimit" type="number" min=0 step=1 placeholder="100">
                                    </nz-input-group>
                                    <ng-template #prefixTemplateUser>
                                        <span nz-icon nzType="contacts" nzTheme="outline"></span>
                                    </ng-template>
                                </div>
                            </div>
                            <br>
                            <div nz-row>
                                <div nz-col [nzSpan]="24">
                                    <label>Creditor Account</label>
                                    <nz-input-group [nzPrefix]="prefixTemplateUser">
                                        <input nz-input [(ngModel)]="Settings.creditorAcc" placeholder="Account No">
                                    </nz-input-group>
                                    <ng-template #prefixTemplateUser>
                                        <span nz-icon nzType="contacts" nzTheme="outline"></span>
                                    </ng-template>
                                </div>
                            </div>
                            <br>
                            <div nz-row>
                                <div nz-col [nzSpan]="8">
                                    <label>Active</label>
                                    <nz-switch [ngModel]="Settings.activeStatus" (ngModelChange)="change('active')" [nzCheckedChildren]="active" [nzUnCheckedChildren]="deactive"></nz-switch>
                                    <ng-template #active>
                                        <span nz-icon nzType="check"></span>
                                    </ng-template>
                                    <ng-template #deactive>
                                        <span nz-icon nzType="close"></span>
                                    </ng-template>
                                </div>
                                <div nz-col [nzSpan]="8">
                                    <label>Replenish</label>
                                    <nz-switch [ngModel]="Settings.replenishStatus" (ngModelChange)="change('replenishment')" [nzCheckedChildren]="active" [nzUnCheckedChildren]="deactive"></nz-switch>
                                    <ng-template #active>
                                        <span nz-icon nzType="check"></span>
                                    </ng-template>
                                    <ng-template #deactive>
                                        <span nz-icon nzType="close"></span>
                                    </ng-template>
                                </div>
                                <div nz-col [nzSpan]="8">
                                    <label>Credit Check</label>
                                    <nz-switch [ngModel]="Settings.creditCheck " (ngModelChange)="change('creditCheck')" [nzCheckedChildren]="active" [nzUnCheckedChildren]="deactive"></nz-switch>
                                    <ng-template #active>
                                        <span nz-icon nzType="check"></span>
                                    </ng-template>
                                    <ng-template #deactive>
                                        <span nz-icon nzType="close"></span>
                                    </ng-template>
                                </div>
                            </div>
                            <br>
                            <div nz-row>
                                <div nz-col [nzSpan]="24">
                                    <button nz-button nzType="primary" (click)="savesettings()">Save</button>
                                </div>
                            </div>
                        </div>
                        <div nz-col [nzSpan]="12" class="ant-col-spacing">
                            <div nz-row>
                                <div nz-col [nzSpan]="24">
                                    <h6 class="text-style">
                                        Add Reciept of Payment
                                    </h6>
                                </div>
                            </div>
                            <div nz-row>
                                <div nz-col [nzSpan]="24">
                                    <label class="float-left">Document Number</label>
                                    <nz-input-group [nzPrefix]="prefixTemplateUser">
                                        <input nz-input [(ngModel)]="Receipt.documentNumber" placeholder="0000">
                                    </nz-input-group>
                                    <ng-template #prefixTemplateUser>
                                        <i nz-icon nzType="file" nzTheme="outline"></i>
                                    </ng-template>
                                </div>
                            </div>
                            <br>
                            <div nz-row>
                                <div nz-col [nzSpan]="24">
                                    <label>Value <small class="text-style">(Vat Incl) </small></label>
                                    <nz-input-group [nzPrefix]="prefixTemplateUser">
                                        <input nz-input [(ngModel)]="Receipt.value" placeholder="R 100">
                                    </nz-input-group>
                                    <ng-template #prefixTemplateUser>
                                        <span nz-icon nzType="sf:moneyBillSVG" nzTheme="outline"></span>
                                    </ng-template>
                                </div>
                            </div>
                            <br>
                            <div nz-row>
                                <div nz-col [nzSpan]="24">
                                    <h6 class="">All <span class="text-style">(Vat Incl)</span> </h6>
                                </div>
                            </div>
                            <br>
                            <div nz-row>
                                <div nz-col [nzSpan]="12">
                                    <h5>Total Spent</h5>
                                    <h5>Total Paid</h5>
                                    <h5>Account balance</h5>
                                    <h5>Available credit</h5>
                                </div>
                                <div nz-col [nzSpan]="12">
                                    <h5><span class="text-style">R</span> {{ this.totalspent |number }}</h5>
                                    <h5><span class="text-style">R</span> {{ this.totalpaid |number}}</h5>
                                    <h5><span class="text-style">R</span> {{ this.totalspent - this.totalpaid|number }} </h5>
                                    <h5><span class="text-style">R</span> {{ this.customer.creditlimit +(this.totalpaid-this.totalspent) | number }} </h5>
                                </div>
                            </div>
                            <br>
                            <div nz-row>
                                <div nz-col [nzSpan]="24">
                                    <button nz-button nzType="primary" (click)="addreciept()">Add</button>
                                </div>
                            </div>
                        </div>
                        <br>
                    </div>
                </div>
            </nz-tab>
            <nz-tab nzTitle="Pricing">
                <div class="container">
                    <div nz-row class="row ring-border">
                        <div nz-col [nzSpan]="24" class="text-center">
                            <div nz-row>
                                <div nz-col [nzSpan]="12" *ngIf="this.pricelist && this.pricelist">
                                    <label class="float-left">Price List Demand</label>
                                    <nz-select style="width:100%" [(ngModel)]="Pricing.pricelistId">
                                        <nz-option [nzLabel]="'No I will not sell to them'" [nzValue]="0"></nz-option>
                                        <nz-option [nzLabel]="this.pricelist.deflt" [nzValue]="15"></nz-option>
                                        <nz-option [nzLabel]="this.pricelist.plretail" [nzValue]="16"></nz-option>
                                        <nz-option [nzLabel]="this.pricelist.wholesale" [nzValue]="17"></nz-option>
                                        <nz-option [nzLabel]="this.pricelist.plinternal" [nzValue]="14"></nz-option>
                                        <nz-option [nzLabel]="this.pricelist.plonline" [nzValue]="13"></nz-option>
                                        <nz-option [nzLabel]="this.pricelist.plcustom1" [nzValue]="10"></nz-option>
                                        <nz-option [nzLabel]="this.pricelist.plcustom2" [nzValue]="11"></nz-option>
                                        <nz-option [nzLabel]="this.pricelist.plcustom3" [nzValue]="12"></nz-option>
                                        <nz-option [nzLabel]="this.pricelist.plcustom4" [nzValue]="19"></nz-option>
                                        <nz-option [nzLabel]="this.pricelist.plclear" [nzValue]="18"></nz-option>
                                    </nz-select>
                                </div>
                                <div class="ant-col-spacing" nz-col [nzSpan]="12" *ngIf="this.pricelist && this.pricelist">
                                    <label class="float-left">Price List Replenish</label>
                                    <nz-select style="width:100%" [(ngModel)]="Pricing.pricelistId_Repleishment">
                                        <nz-option [nzLabel]="'No I will not sell to them'" [nzValue]="0"></nz-option>
                                        <nz-option [nzLabel]="this.pricelist.deflt" [nzValue]="15"></nz-option>
                                        <nz-option [nzLabel]="this.pricelist.plretail" [nzValue]="16"></nz-option>
                                        <nz-option [nzLabel]="this.pricelist.wholesale" [nzValue]="17"></nz-option>
                                        <nz-option [nzLabel]="this.pricelist.plinternal" [nzValue]="14"></nz-option>
                                        <nz-option [nzLabel]="this.pricelist.plonline" [nzValue]="13"></nz-option>
                                        <nz-option [nzLabel]="this.pricelist.plcustom1" [nzValue]="10"></nz-option>
                                        <nz-option [nzLabel]="this.pricelist.plcustom2" [nzValue]="11"></nz-option>
                                        <nz-option [nzLabel]="this.pricelist.plcustom3" [nzValue]="12"></nz-option>
                                        <nz-option [nzLabel]="this.pricelist.plcustom4" [nzValue]="19"></nz-option>
                                        <nz-option [nzLabel]="this.pricelist.plclear" [nzValue]="18"></nz-option>
                                    </nz-select>
                                </div>
                            </div>
                            <div nz-row>
                                <div nz-col [nzSpan]="12">
                                    <label class="float-left">Min Order<span class="text-style"> (Kg or R)</span> </label>
                                    <nz-input-group [nzPrefix]="prefixTemplateUser">
                                        <input nz-input [(ngModel)]="Pricing.minOrder" type="number" min=0 step=1 placeholder="100">
                                    </nz-input-group>
                                    <ng-template #prefixTemplateUser>
                                        <span nz-icon nzType="sf:moneyBillSVG" nzTheme="outline"></span>
                                    </ng-template>
                                </div>
                                <div class="ant-col-spacing" nz-col [nzSpan]="12">
                                    <label class="float-left">Min Order Fee<span class="text-style"></span> </label>
                                    <nz-input-group [nzPrefix]="prefixTemplateUser">
                                        <input nz-input [(ngModel)]="Pricing.minOrderFee" type="number" min=0 step=1 placeholder="100">
                                    </nz-input-group>
                                    <ng-template #prefixTemplateUser>
                                        <span nz-icon nzType="sf:moneyBillSVG" nzTheme="outline"></span>
                                    </ng-template>
                                </div>
                            </div>
                            <div nz-row>
                                <div nz-col [nzSpan]="12">
                                    <label class="float-left">Bulk Discount % <span class="text-style">(Orders of Value greater than R25 000)</span> </label>
                                    <nz-input-group [nzPrefix]="prefixTemplateUser">
                                        <input nz-input [(ngModel)]="Pricing.bulkDiscount" type="number" min=0 step=1 placeholder="100"> </nz-input-group>
                                    <ng-template #prefixTemplateUser>
                                        <span nz-icon nzType="sf:moneyBillSVG" nzTheme="outline"></span>
                                    </ng-template>
                                </div>
                                <div class="ant-col-spacing" nz-col [nzSpan]="12">
                                    <label class="float-left">Super Bulk Discount % <span class="text-style">(Orders of Value greater than R100 000)</span> </label>
                                    <nz-input-group [nzPrefix]="prefixTemplateUser">
                                        <input nz-input [(ngModel)]="Pricing.sbulkDiscount" type="number" min=0 step=1 placeholder="100">
                                    </nz-input-group>
                                    <ng-template #prefixTemplateUser>
                                        <span nz-icon nzType="sf:moneyBillSVG" nzTheme="outline"></span>
                                    </ng-template>
                                </div>
                            </div>
                            <br>
                            <div nz-row>
                                <div nz-col [nzSpan]="24">
                                    <button nz-button nzType="primary" (click)="savepricing()">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nz-tab>
            <nz-tab nzTitle="Logistics">
                <div class="container">
                    <div nz-row class="row ring-border">
                        <div nz-col [nzSpan]="12" nzPush="6" class="text-center">
                            <div nz-row>
                                <div nz-col [nzSpan]="12">
                                    <label class="float-left">Max SOH <span class="text-style"> (to display)</span></label>
                                    <nz-input-group [nzPrefix]="prefixTemplateUser">
                                        <input nz-input [(ngModel)]="Logistics.sohMax" type="number" min=0 step=1 placeholder=2>
                                    </nz-input-group>
                                    <ng-template #prefixTemplateUser>
                                        <span nz-icon nzType="sf:moneyBillSVG" nzTheme="outline"></span>
                                    </ng-template>
                                </div>
                                <div nz-col [nzSpan]="12" class="ant-col-spacing">
                                    <label class="float-left">Delivery Fee <span class="text-style"> (Rands per Tyre)</span> </label>
                                    <nz-input-group [nzPrefix]="prefixTemplateUser">
                                        <input nz-input [(ngModel)]="Logistics.deliveryFee" type="number" min=0 step=1 placeholder="100">
                                    </nz-input-group>
                                    <ng-template #prefixTemplateUser>
                                        <span nz-icon nzType="sf:moneyBillSVG" nzTheme="outline"></span>
                                    </ng-template>
                                </div>
                            </div>
                            <br>
                            <div nz-row>
                                <div nz-col [nzSpan]="24">
                                    <label class="float-left">T&C for Customer<span class="text-style"></span> </label>
                                    <textarea [(ngModel)]="Logistics.termscon" rows="2" class="form-control text-center form-control-custom" placeholder="Terms & Conditions">{{ Logistics.termscon }}</textarea>
                                </div>
                            </div>
                            <br>
                            <div nz-row>
                                <div nz-col [nzSpan]="12">
                                    <label class="float-left">SLA</label>
                                    <nz-select [(ngModel)]="Logistics.logisticsSlaId" style="width: 100%;">
                                        <nz-option *ngFor="let item of slaTimes;" [nzLabel]="item.description" [nzValue]="item.logistics_sla_id"></nz-option>
                                    </nz-select>
                                </div>
                                <div nz-col [nzSpan]="12" class="ant-col-spacing">
                                    <label class="float-left">Enable Custom SLA</label><br>
                                    <nz-switch [ngModel]="customer.customSlaActive" (ngModelChange)="toggleCustomSla()" [nzCheckedChildren]="active" [nzUnCheckedChildren]="deactive"></nz-switch>
                                    <ng-template #active>
                                        <span nz-icon nzType="check"></span>
                                    </ng-template>
                                    <ng-template #deactive>
                                        <span nz-icon nzType="close"></span>
                                    </ng-template>
                                </div>
                            </div>
                            <br>
                            <div nz-row *ngIf="this.customer.customSlaActive">
                                <div nz-col [nzSpan]="11"><label>Cut Off Time</label></div>
                                <div nz-col [nzSpan]="11"><label>Expected Delivery Time</label></div>
                            </div>
                            <div nz-row *ngIf="this.customer.customSlaActive" [ngStyle]="{'opacity': !this.customer.customSlaActive  ? 0.5 : 1 }">
                                <ng-container *ngFor="let slot of this.SlaSlots;let i=index">
                                    <div nz-col [nzSpan]="11">
                                        <span class="float-left" style="font-size: 0.7rem;color: var(--textcolor);font-style: italic;"> Slot {{i+1}} </span>
                                        <nz-select style="width: 100%;" [(ngModel)]="slot.selectedCuttOffslot" [disabled]="!this.customer.customSlaActive">
                                            <nz-option *ngFor="let cutOff of slot.cutOffTime; let i =index;" [nzLabel]="cutOff" [nzValue]="i"></nz-option>
                                        </nz-select>
                                    </div>
                                    <div nz-col [nzSpan]="11" class="ant-col-spacing">
                                        <span class="float-left" style="font-size: 0.7rem;color: var(--textcolor);font-style: italic;"> Slot {{i+1}} </span>
                                        <nz-select style="width: 100%;" [(ngModel)]="slot.selectedDeliveryslot" [disabled]="!this.customer.customSlaActive">
                                            <nz-option *ngFor="let expectedTime of slot.expectedDelivery; let i =index;" [nzLabel]="expectedTime" [nzValue]="i"></nz-option>
                                        </nz-select>
                                    </div>
                                    <div nz-col [nzSpan]="2">
                                        <span style="float:right; font-size: 0.7rem;color: var(--textcolor);font-style: italic;"> Delete </span>
                                        <button nz-button nzType="primary" nzShape="circle" nzDanger (click)="removeslot(slot)" style="margin-left: 15px;">
                                            <span nz-icon nzType="close" nzTheme="outline"></span>
                                        </button>
                                    </div>
                                </ng-container>
                            </div>
                            <br>
                            <div nz-row *ngIf="this.customer.customSlaActive">
                                <div nz-col [nzSpan]="12" [nzPush]="6">
                                    <button nz-button nzType="primary" (click)="addslot()">
                                        <i nz-icon nzType="plus" nzTheme="outline"></i>
                                         Add Slot
                                     </button>
                                </div>
                            </div>
                            <br>
                            <div nz-row>
                                <div nz-col [nzSpan]="24">
                                    <button nz-button nzType="primary" (click)="savelogistics()">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nz-tab>
            <nz-tab nzTitle="Information on Order">
                <div class="container">
                    <div nz-row class="row ring-border">
                        <div nz-col [nzSpan]="24" class="text-center">
                            <div nz-row>
                                <div nz-col [nzSpan]="24">
                                    <p class="" style="font-size: 14px;">
                                        Would you like Users to complete some additional information before they submit an order?
                                    </p>
                                </div>
                            </div>
                            <div nz-row>
                                <div nz-col [nzSpan]="12">
                                    <div nz-row>
                                        <div nz-col [nzSpan]="12">
                                            <label class="float-left">Order Reference</label>
                                        </div>
                                        <div nz-col [nzSpan]="12">
                                            <nz-switch [ngModel]="CartDetails.extref" (ngModelChange)="saveordersettings('extref')" [nzCheckedChildren]="active" [nzUnCheckedChildren]="deactive"></nz-switch>
                                            <ng-template #active>
                                                <span nz-icon nzType="check"></span>
                                            </ng-template>
                                            <ng-template #deactive>
                                                <span nz-icon nzType="close"></span>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                                <div nz-col [nzSpan]="12">
                                    <div nz-row>
                                        <div nz-col [nzSpan]="12">
                                            <label class="float-left">Delivery Info</label>
                                        </div>
                                        <div nz-col [nzSpan]="12">
                                            <nz-switch [ngModel]="CartDetails.deliveryInfo" (ngModelChange)="saveordersettings('deliveryInfo')" [nzCheckedChildren]="active" [nzUnCheckedChildren]="deactive"></nz-switch>
                                            <ng-template #active>
                                                <span nz-icon nzType="check"></span>
                                            </ng-template>
                                            <ng-template #deactive>
                                                <span nz-icon nzType="close"></span>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div nz-row>
                                <div nz-col [nzSpan]="12">
                                    <div nz-row>
                                        <div nz-col [nzSpan]="12">
                                            <label class="float-left">Salesman's Name</label>
                                        </div>
                                        <div nz-col [nzSpan]="12">
                                            <nz-switch [ngModel]="CartDetails.salesmanName" (ngModelChange)="saveordersettings('salesmanName')" [nzCheckedChildren]="active" [nzUnCheckedChildren]="deactive"></nz-switch>
                                            <ng-template #active>
                                                <span nz-icon nzType="check"></span>
                                            </ng-template>
                                            <ng-template #deactive>
                                                <span nz-icon nzType="close"></span>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                                <div nz-col [nzSpan]="12">
                                    <div nz-row>
                                        <div nz-col [nzSpan]="12">
                                            <label class="float-left">Vehicle Registration</label>
                                        </div>
                                        <div nz-col [nzSpan]="12">
                                            <nz-switch [ngModel]="CartDetails.vehicleRegistration" (ngModelChange)="saveordersettings('vehicleRegistration')" [nzCheckedChildren]="active" [nzUnCheckedChildren]="deactive"></nz-switch>
                                            <ng-template #active>
                                                <span nz-icon nzType="check"></span>
                                            </ng-template>
                                            <ng-template #deactive>
                                                <span nz-icon nzType="close"></span>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div nz-row>
                                <div nz-col [nzSpan]="12">
                                    <div nz-row>
                                        <div nz-col [nzSpan]="12">
                                            <label class="float-left">Vehicle Make</label>
                                        </div>
                                        <div nz-col [nzSpan]="12">
                                            <nz-switch [ngModel]="CartDetails.vehicleMake" (ngModelChange)="saveordersettings('vehicleMake')" [nzCheckedChildren]="active" [nzUnCheckedChildren]="deactive"></nz-switch>
                                            <ng-template #active>
                                                <span nz-icon nzType="check"></span>
                                            </ng-template>
                                            <ng-template #deactive>
                                                <span nz-icon nzType="close"></span>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                                <div nz-col [nzSpan]="12">
                                    <div nz-row>
                                        <div nz-col [nzSpan]="12">
                                            <label class="float-left">Vehicle Milage</label>
                                        </div>
                                        <div nz-col [nzSpan]="12">
                                            <nz-switch [ngModel]="CartDetails.vehicleMilage" (ngModelChange)="saveordersettings('vehicleMilage')" [nzCheckedChildren]="active" [nzUnCheckedChildren]="deactive"></nz-switch>
                                            <ng-template #active>
                                                <span nz-icon nzType="check"></span>
                                            </ng-template>
                                            <ng-template #deactive>
                                                <span nz-icon nzType="close"></span>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div nz-row>
                                <div nz-col [nzSpan]="12">
                                    <div nz-row>
                                        <div nz-col [nzSpan]="12">
                                            <label class="float-left">Comment Box (Optional)</label>
                                        </div>
                                        <div nz-col [nzSpan]="12">
                                            <nz-switch [ngModel]="CartDetails.commentBox" (ngModelChange)="saveordersettings('commentBox')" [nzCheckedChildren]="active" [nzUnCheckedChildren]="deactive"></nz-switch>
                                            <ng-template #active>
                                                <span nz-icon nzType="check"></span>
                                            </ng-template>
                                            <ng-template #deactive>
                                                <span nz-icon nzType="close"></span>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                                <div nz-col [nzSpan]="12">
                                    <div nz-row>
                                        <div nz-col [nzSpan]="12">
                                            <label class="float-left">Display Pick Up/Delivery Option</label>
                                        </div>
                                        <div nz-col [nzSpan]="12">
                                            <nz-switch [ngModel]="CartDetails.isDeliveryOptionShowing" (ngModelChange)="saveordersettings('isDeliveryOptionShowing')" [nzCheckedChildren]="active" [nzUnCheckedChildren]="deactive"></nz-switch>
                                            <ng-template #active>
                                                <span nz-icon nzType="check"></span>
                                            </ng-template>
                                            <ng-template #deactive>
                                                <span nz-icon nzType="close"></span>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nz-tab>
            <nz-tab nzTitle="Linked Salesmen">
                <div class="container" class="text-center">
                    <div nz-row class="row ring-border">
                        <div nz-col [nzSpan]="24" class="text-center">
                            <p class="" style="font-size: 14px;">
                                Linking a salesmen/user to a client will result in the salesmen receiving an email when the client places an order.
                            </p>
                            <div class="row">
                                <div class="col-lg-12" style="padding-left: 5%;padding-right: 4%;">
                                    <nz-table #usersTable [nzData]="salesmen" [nzFrontPagination]="false" nzSize="small">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Linked</th>
                                            </tr>
                                        </thead>
                                        <tbody class="tbody">
                                            <tr *ngFor="let user of usersTable.data">
                                                <td>{{ user.fullname }}</td>
                                                <td>{{ user.email }}</td>
                                                <td>
                                                    <nz-switch [ngModel]="user.linked" (ngModelChange)="addClientLink(user.user_id,$event)" [nzCheckedChildren]="active" [nzUnCheckedChildren]="deactive"></nz-switch>
                                                    <ng-template #active>
                                                        <span nz-icon nzType="check"></span>
                                                    </ng-template>
                                                    <ng-template #deactive>
                                                        <span nz-icon nzType="close"></span>
                                                    </ng-template>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </nz-table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nz-tab>
        </nz-tabset>
    </nz-spin>
</div>