<div class="container">
    <div class="row ">
        <div class="col-lg-4" style="text-align: center;">
        </div>
        <div class="col-lg-4" style="text-align: center;">
            <h2><b>Brand Strategy</b></h2>
        </div>
        <div class="col-lg-4" style="text-align: center;">
            <button nz-button nzType="primary" *ngIf="changes" (click)="updateClientBrandStrats()" [nz-tooltip]="'Changes Detected - Please Save'" nzTooltipPlacement="bottom">
                <i nz-icon nzType="save" nzTheme="outline"></i>
                Save Changes
            </button>
        </div>
    </div>
</div>

<br>

<div class="container-fluid col-lg-11  text-center bg-1">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <nz-spin [nzSpinning]="isLoading">
                    <nz-table #data [nzData]="RankedBrands" [nzFrontPagination]="false" [nzShowPagination]="false" [nzLoading]="isLoading">
                        <thead>
                            <tr>
                                <th style="width: 10%"></th>
                                <th>Brand</th>
                                <th></th>
                                <th class="text-center">Ranking</th>
                                <th>Meta Data</th>
                                <!-- <th>Tagline</th> -->
                                <th>
                                    Is Active
                                    <app-video-widget [videoId]="1" style="float: inline-end;"></app-video-widget>

                                </th>
                            </tr>
                        </thead>
                        <tbody class="tbody" cdkDropList (cdkDropListDropped)="alterRankings($event)">
                            <tr *ngFor="let item of RankedBrands" cdkDrag>
                                <td>
                                    <!-- <img style='border-radius:10px' height='20' width='50' src='https://sftptyres.blob.core.windows.net/brands-images/brands/{{item.url}}'> -->
                                    <img class="photos" src='https://sftptyres.blob.core.windows.net/brands-images/brands/{{item.url}}'>
                                </td>
                                <td>
                                    <span *ngIf="item.isFundingBrand" nz-icon nzType="star" style="color:var(--textcolor)" nzTheme="outline"></span> {{ item.description }}
                                </td>
                                <td>
                                    <i nz-icon nzType="save" nzTheme="outline" class="text-warning-style"></i>
                                </td>
                                <td class="text-center">
                                    {{ item.rank}}
                                </td>
                                <td>
                                    <nz-switch *ngIf="item.showMetaToggle" [ngModel]="item.metaData" (ngModelChange)="toggleMeta(item)" [nzCheckedChildren]="checkedTemplate" [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                                    <i *ngIf="!item.showMetaToggle" nz-icon type="cloud" nzTheme="outline"></i>
                                    <ng-template #checkedTemplate><span nz-icon nzType="check"></span></ng-template>
                                    <ng-template #unCheckedTemplate><span nz-icon nzType="close"></span></ng-template>
                                </td>
                                <!-- <td>
                                    <div class="input-group maindiv">
                                         <span *ngIf="item.tagline == true">
                                            <div class="btn-group">
                                                <button nz-button nzType="primary" (click)="toggleTagline(item)">
                                                    <i nz-icon nzType="check" nzTheme="outline"></i>
                                                    YesYES
                                                </button>
                                                <button nz-button nzType="primary" (click)="toggleTagline(item)">
                                                    <span nz-icon nzType="close" nzTheme="outline"></span>
                                                    No
                                                </button>
                                            </div>
                                        </span>
                                        <span *ngIf="item.tagline == false">
                                            <div class="btn-group">
                                                <button nz-button nzType="primary" (click)="toggleTagline(item)">
                                                    <i nz-icon nzType="check" nzTheme="outline"></i>
                                                    Yes
                                                </button>
                                                <button nz-button nzType="primary" (click)="toggleTagline(item)">
                                                    <span nz-icon nzType="close" nzTheme="outline"></span>
                                                    NoNO
                                                </button>
                                            </div>
                                        </span> 
                                        <nz-switch [ngModel]="item.tagline" (ngModelChange)="toggleTagline(item)" [nzCheckedChildren]="checkedTemplate" [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                                        <ng-template #checkedTemplate><span nz-icon nzType="check"></span></ng-template>
                                        <ng-template #unCheckedTemplate><span nz-icon nzType="close"></span></ng-template>

                                    </div>
                                </td> -->
                                <td>
                                    <div class="input-group maindiv">
                                        <!-- <span *ngIf="item.active == true">
                                            <div class="btn-group">
                                                <button nz-button nzType="primary" (click)="toggleActive(item)">
                                                    <i nz-icon nzType="check" nzTheme="outline"></i>
                                                    YesYES
                                                </button>
                                                <button nz-button nzType="primary" (click)="toggleActive(item)">
                                                    <span nz-icon nzType="close" nzTheme="outline"></span>
                                                    No
                                                </button>
                                            </div>
                                        </span>
                                        <span *ngIf="item.active == false">
                                            <div class="btn-group">
                                                <button nz-button nzType="primary" (click)="toggleActive(item)">
                                                    <i nz-icon nzType="check" nzTheme="outline"></i>
                                                    Yes
                                                </button>
                                                <button nz-button nzType="primary" (click)="toggleActive(item)">
                                                    <span nz-icon nzType="close" nzTheme="outline"></span>
                                                    NoNO
                                                </button>
                                            </div>
                                        </span> -->
                                        <nz-switch [ngModel]="item.active" (ngModelChange)="toggleActive(item)" [nzCheckedChildren]="checkedTemplate" [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                                        <!-- <ng-template #checkedTemplate><span nz-icon nzType="check"></span></ng-template>
                                            <ng-template #unCheckedTemplate><span nz-icon nzType="close"></span></ng-template> -->
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </nz-table>
                </nz-spin>
            </div>
        </div>
    </div>
</div>


<!--Nav Modal-->
<nz-modal [(nzVisible)]="isNavModalVisible" nzTitle="Save Rankings" (nzOnCancel)="isNavModalVisible = false; navPathAway='';" [nzFooter]="null" nzWidth="800px">
    <div *nzModalContent>
        <!-- NAV AWAY WITHOUT CHANING STATE -->
        <div class="row mb-5">
            <div class="col-md-12" *ngIf="changes">
                <h5>Some Changes were detected to the Table Please Save them before continuing</h5>
                <button nz-button nzType="primary" (click)="navigateWithoutSaving()" style="margin-left: 10px; margin-right: 10px"><i nz-icon nzType="left-circle" nzTheme="fill"
                  style="vertical-align: top; margin-right: 4px; font-size: 1.5rem;"></i>Discrad Changes, Continue
                </button>
                <button nz-button nzType="primary" (click)="navigateWithSaving()" style="margin-left: 10px; margin-right: 10px">
                    <i nz-icon nzType="save" nzTheme="fill"
                    style="vertical-align: top; margin-right: 4px; font-size: 1.5rem;"></i>Save Changes, Continue
                </button>

            </div>
        </div>
    </div>
</nz-modal>