import { Component,HostListener, OnInit } from '@angular/core';
import { ModelStock, ModelStockSizes, ModelStock_def, Model_Suggestions, stockCost, supplierInfo, supplierInvoices, supplier_summary, ModelStockTyres, SixMonthSalesTrend,rfc_summary,GenCode_Storage, ModelStockCollection, Stock_AI_Reasons } from '../models/stock';
import { StockModelsService } from './model-stock-analysis.service';
import { NotificationService } from '../shared/notification.service';
import { mslBrand, brandStrat, mslSettings, pieChartModel } from 'src/app/models/brandStrat';
import {formatDate} from '@angular/common';
import { AuthService } from '../auth.service';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NzFormTooltipIcon } from 'ng-zorro-antd/form';
import { Chart, ChartDataset } from 'chart.js';
import * as XLSX from 'xlsx';




@Component({
  selector: 'app-model-stock-analysis',
  templateUrl: './model-stock-analysis.component.html',
  styleUrls: ['./model-stock-analysis.component.less']
})
export class ModelStockAnalysisComponent implements OnInit {
  isLoading = false;
  initLoading = false;
  baseModel : ModelStock[] =[]
  
  baseBackup : ModelStock[] = []
  stockList : ModelStock[] = []
  stockListBackup : ModelStock[] = []
  suppliers : supplierInfo[] = []
  suppliersTemp: supplierInfo[] = []
  searchModel: ModelStock[] = []
  queryStock: ModelStock[] = []
  Invoices: supplierInvoices[];
  chosenAddition: ModelStock
  stockBalance: stockCost[] = []
  Model_Stock: ModelStock_def;
  Model_Stock_Base: ModelStock_def;
  Model_Size_Suggestions: Model_Suggestions[] = []
  Model_Size_Suggestions_Temp: Model_Suggestions[] = []
  stockItem: stockCost
  model_size_backup: ModelStockSizes[] = []
  Temp_Model : ModelStockTyres[] = []
  ModelTyres: ModelStockTyres[] = [];
  ModelTyres_Backup: ModelStockTyres[] = [];
  daysofdata: number = 120;
  RankedBrands: brandStrat[]=[];
  ModelTyresData: ModelStockTyres[] = [];
  ModelTyresData_Backup: ModelStockTyres[] = [];
 
  selectedLogicId:number = 0
  aiStockReasons: Stock_AI_Reasons[]= [];
  

  supplierNames: string[] = []
  currRow:number;
  addRow: number;
  ClickedRow: any;
  HighlightRow : Number;
  sort:number;
  search: string
  dos_Inval: number
  search_genCode: string
  searched: number;
  stockTotal: number = 0;
  currTable= 0;
  currP2_Table = 0;
  

 
  addModel = false;
  inputStock = false;
  filters = false;
  liveUpdate = false;
  updateTime: Date;
  altStock = false;

  tempSizes: ModelStockSizes[] = [];
  baseSizes: ModelStockSizes[] = [];
  alertBody: Array<any> = []


  summaryTitle: string =''
  page: number = 1;

  alert_on: boolean;
  suggestions: boolean = true;
  suggested_msl: boolean = true;
  isDisabled = false;
  row_marked: boolean = false;
  marked_row: string = '-1';
  topCtrls: boolean = true;

  sales_tier: string = "None";
  tierA_checked: boolean =  false;
  tierB_checked: boolean =  false;
  tierC_checked: boolean =  false;
  tierD_checked: boolean =  false;
  tierE_checked: boolean =  false;

 
  

  
  

  constructor(private StockModelsService: StockModelsService,              
              private notification: NotificationService,
              private auth:AuthService,
              ) { }
  
  

  ngOnInit(): void {
      // this.isLoading = true;
      this.liveUpdate = false;
      this.alert_on = true;
      this.searched = 0;
      
      this.getBrands();
      this.fetchPieChartData();  
      this.getLocal_Sizes();
      this.fetchSupplierInfo();
      this.fetchModelStock();
      
      // this.StockModelsService.getStockModels().subscribe((res:any) =>{
      //     this.isLoading = false;
      //     this.baseModel = res.All_Models as ModelStock[];
      //     this.baseBackup = this.baseModel.slice();
      //     let sortedSizes = this.Model_Stock.sizes.sort((a, b) =>  (a.sales_rank_all < b.sales_rank_all) ? -1 : 1);
      //     this.Model_Stock.sizes = sortedSizes;
      //     this.Model_Stock.sizes = this.Model_Stock.sizes.slice();
          
      //     this.Model_Stock_backup = this.Model_Stock;
      //     this.Model_Stock_Base = this.Model_Stock;
      // },
      // err => {
      //   this.isLoading = false;
      //   this.notification.handleError(err);
      // })
     
     
      this.sort = 0;
  }

  set_tiers(){
    let empty = true;
    this.isLoading = true;
    this.sales_tier = "(";
    if(this.tierA_checked == true){this.sales_tier += "'T01',"; empty = false;}
    if(this.tierB_checked == true){this.sales_tier += "'T02',"; empty = false;}
    if(this.tierC_checked == true){this.sales_tier += "'T03',"; empty = false;}
    if(this.tierD_checked == true){this.sales_tier += "'T04',"; empty = false;}
    // if(this.tierE_checked == true){this.sales_tier += "'E',"; empty = false;}

    if(empty == true)(this.sales_tier = 'None')
    else{
      this.sales_tier += ")";
      this.sales_tier = this.sales_tier.replace(',)',')')
    }
    this.ngOnInit();
  }

 

  sortReason($event)
  {
    this.setTabletoContent(20);
    if($event != 7){
      let sorted = [] as ModelStockTyres[];
      this.ModelTyresData_Backup.forEach(x => {
        x.rankId = 0;
        if(x.ms_ai_reasonId == $event)
        {
          x.rankId = 1;
        }
        sorted.push(x);
      });
      sorted.sort((a,b) => b.rankId - a.rankId);
      this.ModelTyresData = [];
      this.ModelTyresData = sorted;
      let sortedSizes = this.ModelTyresData.sort((a, b) =>  (a.sales> b.sales) ? -1 : 1);
      this.ModelTyresData = sortedSizes;
    }
    else{
      // let sorted = JSON.parse(JSON.stringify(this.ModelTyresData)) as ModelStockTyres []
      // sorted.sort((a,b) => {
      //   // Sort modelstock ascending
      //   if (a.modelStock < b.modelStock) return -1;
      //   if (a.modelStock > b.modelStock) return 1;
      //   // Sort suggested_modelstock descending
      //   if (a.suggested_modelStock < b.suggested_modelStock) return 1;
      //   if (a.suggested_modelStock > b.suggested_modelStock) return -1;
      //   // Both idential, return 0
      //   return 0
      //   });
      // this.ModelTyresData = [];
      // this.ModelTyresData = sorted;

      this.ModelTyresData = []
      for(var j = 0; j < this.ModelTyresData_Backup.length;j++){
        if(this.ModelTyresData_Backup[j].suggested_modelStock >0 && this.ModelTyresData_Backup[j].modelStock == 0){
          this.ModelTyresData.push(this.ModelTyresData_Backup[j])
        }
      }
      let sortedSizes = this.ModelTyresData.sort((a, b) =>  (a.sales> b.sales) ? -1 : 1);
      this.ModelTyresData = sortedSizes;
     
    }
  }


  totalModelStock= 0;
  totalSOH= 0;
  totalSales = 0;
  totalDaysOfStock = 0;
  
  fetchModelStock(){
    this.isLoading = true;
    this.StockModelsService.modelStock(this.daysofdata,this.sales_tier,this.dos_var1).subscribe(res =>{
      this.isLoading = false;
      let tempMS = res;
      // this.Model_Stock = res;
      this.tempSizes = tempMS.sizes;
      this.model_size_backup = tempMS.sizes.slice();
      //this.aiStockReasons = [];
      this.aiStockReasons = tempMS.Reasons;
      // this.ModelTyresData = [];
      this.ModelTyresData = tempMS.TyreLines;
      for(var j = 0; j < tempMS.TyreLines.length;j++)
      { 
        tempMS.TyreLines[j].days_of_stock = tempMS.TyreLines[j].soh/(tempMS.TyreLines[j].sales/this.daysofdata*1.0);     
        if(tempMS.TyreLines[j].ms_ai_reasonId == 0)
        {
          tempMS.TyreLines[j].ms_ai_reason = '-';
        }
        else
        {
          tempMS.TyreLines[j].ms_ai_reason = this.aiStockReasons.find(x=> x.ms_ai_reasonId == tempMS.TyreLines[j].ms_ai_reasonId)!.description;
         
        }
        tempMS.TyreLines[j].rankId = 0   
      }
    
      // this.switch_var1()
      if(this.dos_var1 == "SOH"){this.var1 = tempMS.soh;}
      else if(this.dos_var1 == "MODEL STOCK"){this.var1 = tempMS.modelStock;}
      else if(this.dos_var1 == "SUGGESTED MODEL STOCK"){this.var1 = tempMS.suggested_modelStock;}
      this.var2 = tempMS.sales;
      this.dosCalc = Math.floor(this.var1/(this.var2/this.daysofdata));
      this.Model_Stock = tempMS;
      this.ModelTyresData_Backup = this.ModelTyresData.slice();
      this.aiStockReasons[7].description = "No MSL, AI suggestion"
     
    },
    err => {
      this.notification.handleError(err);
    });
   
   
  }

  fetchAlerts(){
    this.isLoading = true;
    this.initLoading = true;
    this.StockModelsService.modelStockAlerts(this.Model_Stock.sizes).subscribe(res =>{
      this.Model_Stock.sizes = res as ModelStockSizes[];
      this.tempSizes = this.Model_Stock.sizes;
      this.isLoading  = false;
      this.initLoading = false;
      },err=>{
        this.notification.handleError(err)
      });
  }

  fetchGlobalAlerts(){
   
    this.StockModelsService.modelStockAlerts_Global(this.Model_Stock.sizes).subscribe(res =>{
      this.Model_Stock.sizes = res as ModelStockSizes[];
      this.tempSizes = this.Model_Stock.sizes;
      
      },err=>{
          this.notification.handleError(err);
      });

  }

  
  fetchSupplierInfo(){
    this.StockModelsService.getSupplierInfo().subscribe(res =>{
      this.suppliers = res as supplierInfo[];
      this.suppliersTemp = this.suppliers.slice();
     },
    err => {
      this.notification.handleError(err);
      this.isLoading = false;
    })
  }

  dos_var1: string = "SOH";
  dos_var2: string = "SALES";
  var1:number = 0;
  var2:number = 0;
  dosCalc = 0;
  switch_var1(){
    if(this.dos_var1 == "SOH"){this.dos_var1 = "MODEL STOCK"; this.var1 = this.Model_Stock.modelStock;}
    else if(this.dos_var1 == "MODEL STOCK"){this.dos_var1="SUGGESTED MODEL STOCK";this.var1 = this.Model_Stock.suggested_modelStock;}
    else if(this.dos_var1 == "SUGGESTED MODEL STOCK"){this.dos_var1="SOH";this.var1 = this.Model_Stock.soh;}
    this.dosCalc = Math.floor(this.var1/(this.var2/this.daysofdata));
  }
  killTime = false;
  funnyArray = ["Nothing ruins a Friday more than realizing it’s Tuesday",
  "Time flies like an arrow; fruit flies like a banana. – Anthony G",
  "The last four letters in 'queue' are not silent,\nThey're just waiting their turn",
  "My wife told me to stop impersonating a flamingo. I had to put my foot down.",
  "Most people are shocked when they find out how bad I am as an electrician.",
  "Never trust atoms; they make up everything.",
  "I feel bad for lions at zoos. How would you feel if a bunch of pizzas came to your house, took your picture, and couldn’t even eat them? ",
  "Despite the high cost of living, it remains popular.",
  "A blind man walked into a bar… and a table… and a chair…",
  "I went to a seafood disco last week, but ended up pulling a mussel.",
  "Who built King Arthur’s round table? Sir Cumference",
  "I threw a boomerang a couple years ago; I now live in constant fear.",
  "The man who survived both mustard gas and pepper spray is a seasoned veteran now.",
  "Before you marry a person, you should first make them use a computer with a slow Internet connection to see who they really are.",
  "Light travels faster than sound. This is why some people appear bright until they open their mouths.",
  "One day YouTube, Twitter, and Facebook will join together and be called: YouTwitFace",
  "The past, present, and future walked into a bar. Things got a little tense",
  "I once survived the fallout from moving an image 1 cm to the right in Word"]
waiting_caption: string = ''
lastJoke: number = 0;
jokesmentioned:number[] = []
nextJoke(){
  
  let captionIndex = Math.floor(Math.random() * (this.funnyArray.length));
  if (this.jokesmentioned.includes(captionIndex)){
    captionIndex = Math.floor(Math.random() * (this.funnyArray.length)); 
    
  }
  this.jokesmentioned.push(captionIndex);
  this.waiting_caption = this.funnyArray[captionIndex];
  
  
  
}
  
  fetchRecommendedSizes(){
    this.jokesmentioned = [];
    let captionIndex = Math.floor(Math.random() * (this.funnyArray.length - 1 + 1)) + 0; 
    this.waiting_caption = this.funnyArray[captionIndex];
    this.isLoading = true;
    if (this.Model_Size_Suggestions.length == 0){
      this.StockModelsService.modelStockSizeSuggestions(this.daysofdata).subscribe(res =>{
        this.Model_Size_Suggestions = res as Model_Suggestions[];
        this.Model_Size_Suggestions_Temp = this.Model_Size_Suggestions;

        for(let n =0;n < this.Model_Size_Suggestions.length;n++){
          if(this.Model_Size_Suggestions[n].rank == 1 || this.Model_Size_Suggestions[n].rank == 2 || this.Model_Size_Suggestions[n].rank == 3){
            for(let m = 0; m < this.Model_Stock.sizes.length; m++){
              if(this.Model_Size_Suggestions[n].gen_code == this.Model_Stock.sizes[m].size && this.Model_Stock.sizes[m].modelStock == 0){
                this.Model_Stock.sizes[m].alerts.push("Number"+this.Model_Size_Suggestions[n].rank+" rank in recommended sizes is not in your model stock");
              }
            }
          }
          
        }
        let sortedSizes = this.Model_Stock.sizes.sort((a, b) =>  (a.alerts.length > b.alerts.length) ? -1 : 1);
        this.Model_Stock.sizes = sortedSizes;
        this.Model_Stock.sizes = this.Model_Stock.sizes.slice();
        this.tempSizes = this.Model_Stock.sizes;

        sortedSizes = this.Model_Stock.sizes.sort((a, b) =>  (a.sales_rank_all < b.sales_rank_all) ? -1 : 1);
        this.Model_Stock.sizes = sortedSizes;
        this.Model_Stock.sizes = this.Model_Stock.sizes.slice();
        this.isLoading = false;
      },err=>{
        this.notification.handleError(err);
        this.isLoading = false;
      });
      this.isLoading =  false;
    }else{
      this.isLoading = false;
    }
  }

  
  Alerts(){
    if(this.alert_on == true){
      this.alert_on = false; 
      
    }
    else if(this.alert_on == false){
      this.alert_on = true; 
      
    }
  }

  dodChange()
  { 
    this.isLoading= true;
    let dod = (document.getElementById("daysOfData") as HTMLInputElement).value;
    var dodInt: number = +dod;
    if(dodInt > 120)
    {
      this.notification.ShowBlockingPopup("days of data has to be below 120 days")
      this.isLoading = false;
    }else{
      
      this.daysofdata = dodInt;
      this.fetchModelStock();
      let sortedSizes = this.Model_Stock.sizes.sort((a, b) =>  (a.sales_rank_all < b.sales_rank_all) ? -1 : 1);
      this.Model_Stock.sizes = sortedSizes;
      this.Model_Stock.sizes = this.Model_Stock.sizes.slice();
      
      let sortedSuggestions = this.Model_Size_Suggestions.sort((a,b)=> (a.searches < b.searches)? -1:1);
      this.Model_Size_Suggestions = sortedSuggestions;
      this.Model_Size_Suggestions = this.Model_Size_Suggestions.slice();
      
      
    }
    
  }

  len: number = 0;
  genSearch: ModelStockSizes[] = []
  genSearch_2: Model_Suggestions[] =[]
  modelSearch: ModelStockTyres[] = []
  missingGenCode = false;
  gen_codeInputChange(e:string){
    this.genSearch = [];
    this.genSearch_2 = [];
    this.modelSearch = [];
    this.len = e.length;
    if(this.currP2_Table == 0)
    {
      for(let x = 0; x < this.tempSizes.length; x++)
      {
        if(this.tempSizes[x].size.includes(e))
        {
          this.genSearch.push(this.tempSizes[x]);
        }
      }
      this.Model_Stock.sizes = this.genSearch;

     
    
    }



    if(this.currP2_Table == 1)
    {
      
      for(let x = 0; x < this.ModelTyresData_Backup.length; x++)
        {
          if(this.ModelTyresData_Backup[x].stock_code.includes(e.toUpperCase()))
          {
            this.modelSearch.push(this.ModelTyresData_Backup[x]);
          }
        }
        this.ModelTyresData = this.modelSearch;
    }

    if(this.currP2_Table == 2)
      {
        for(let x = 0; x < this.Model_Size_Suggestions_Temp.length; x++)
          {
           
            if(this.Model_Size_Suggestions_Temp[x].gen_code.includes(e))
            {
              this.genSearch_2.push(this.Model_Size_Suggestions_Temp[x]);
            }
          }
          this.Model_Size_Suggestions = this.genSearch_2;
      }

    

    if(this.viewModel_Lines == true)
    { 
      for(let x = 0; x < this.ModelTyres_Backup.length; x++)
      {
        if(this.ModelTyres_Backup[x].stock_code.includes(e.toUpperCase()))
        {
          this.modelSearch.push(this.ModelTyres_Backup[x]);
        }
      }
      this.ModelTyres = this.modelSearch;
    }
    
    


  }


  editMode = false;
  editId:number = 0;
  saveChanges_P2(item): void{

    let msl = (document.getElementById("msl_suggest") as HTMLInputElement).value;
    msl = msl.replace('+','');
    var mslInt: number = +msl;
    item.msl = mslInt;
    if(item.supplier_soh.toString().includes('+') == true){item.supplier_soh = item.supplier_soh.replace('+','');}
    if(item.brand_rank == 'N/A'){item.brand_rank = 0;}
    if(item.best_cost == 'N/A'){item.best_cost = 0;}
    if(item.supplier_soh == 'N/A'){item.supplier_soh = 0;}
    item.modelStock = mslInt;
    let item_to_modify = new ModelStockTyres
    for(let x = 0;x < this.baseBackup.length;x++)
    {
      if(this.baseBackup[x].modelId == item.stockReplenishmentId)
      {
        item_to_modify.stockReplenishmentId = this.baseBackup[x].modelId;
        item_to_modify.modelStock = mslInt;
        item_to_modify.best_supplier = this.baseBackup[x].supplier_name;
        break;
      }
    }
    
    
    
    this.StockModelsService.updateModel(item).subscribe(
      res =>
      {
         this.notification.showSuccess("successfully updated "+item.description);
         let mRow = this.ModelTyres.indexOf(item);
         this.ModelTyres[mRow].modelStock = mslInt;
         this.ModelTyres = this.ModelTyres.slice()
        //  this.ngOnInit();
      },
      error => {
        this.notification.handleError(error);
      })
    
    this.editMode = false;
    this.editId = 0;
    this.page = 2;
  
  }

  
  sortSize = (a: ModelStockSizes, b: ModelStockSizes) => a.size.localeCompare(b.size);
  sortQuotes = (a: ModelStockSizes, b: ModelStockSizes) => a.leads_generated - b.leads_generated;
  sortConv = (a: ModelStockSizes, b: ModelStockSizes) => this.leadConversion(a.leads_converted,a.leads_generated) - this.leadConversion(b.leads_converted,b.leads_generated);
  sortNR = (a: ModelStockSizes, b: ModelStockSizes) => a.sales_rank_all - b.sales_rank_all;
  sortSales = (a: ModelStockSizes, b: ModelStockSizes) => a.sales - b.sales;
  sortDOS = (a: ModelStockSizes, b: ModelStockSizes) => a.dos - b.dos;
  sortWDemand = (a: ModelStockSizes, b: ModelStockSizes) => a.four_week_demand - b.four_week_demand;
  sortAIMS = (a: ModelStockSizes, b: ModelStockSizes) => a.suggested_modelStock - b.suggested_modelStock;
  sortMS = (a: ModelStockSizes, b: ModelStockSizes) => a.modelStock - b.modelStock;
  sortSOH = (a: ModelStockSizes, b: ModelStockSizes) => a.soh - b.soh;


  sortBrandInv = (a: ModelStockTyres, b: ModelStockTyres) => a.brandLogo.localeCompare(b.brandLogo);
  sortDescInv = (a: ModelStockTyres, b: ModelStockTyres) => a.description.localeCompare(b.description);
  sortCodeInv = (a: ModelStockTyres, b: ModelStockTyres) => a.stock_code.localeCompare(b.stock_code);
  sortQuoteInv = (a: ModelStockTyres, b: ModelStockTyres) => a.leads_generated-b.leads_generated;
  sortSoldInv = (a: ModelStockTyres, b: ModelStockTyres) => a.sales-b.sales;
  sortDOSInv = (a: ModelStockTyres, b: ModelStockTyres) => a.days_of_stock-b.days_of_stock;
  sortMSInv = (a: ModelStockTyres, b: ModelStockTyres) => a.modelStock-b.modelStock;
  sortAIMSInv = (a: ModelStockTyres, b: ModelStockTyres) => a.suggested_modelStock-b.suggested_modelStock;
  sortNRInv = (a: ModelStockTyres, b: ModelStockTyres) => a.national_rank-b.national_rank;
  sortCostInv = (a: ModelStockTyres, b: ModelStockTyres) => a.best_cost-b.best_cost;
  sortSuppInv = (a: ModelStockTyres, b: ModelStockTyres) => a.best_supplier.localeCompare(b.best_supplier);
  sortS_SOHInv = (a: ModelStockTyres, b: ModelStockTyres) => a.supplier_soh-b.supplier_soh;
  sortSOHInv = (a: ModelStockTyres, b: ModelStockTyres) => a.soh-b.soh; 

  sortSizeR = (a: Model_Suggestions, b: Model_Suggestions) => a.gen_code.localeCompare(b.gen_code);
  sortNR_SearchesR = (a: Model_Suggestions, b: Model_Suggestions) => a.searches-b.searches;
  sortSalesR = (a: Model_Suggestions, b: Model_Suggestions) => a.sales-b.sales;
  sortRankR = (a: Model_Suggestions, b: Model_Suggestions) => a.rank-b.rank;

  sortedKeys: number[] = []
  sortedData: any[]= []
  dict: { [key: string]: ModelStockSizes[]};
  
  leadConversion(leadsC:number,leadsG:number){
    let conversion_rate: number  = 0;
   
    if (leadsC == 0){
      conversion_rate = 0;
    }else{
      conversion_rate = leadsC/leadsG*100;
    }
    let conversion_result: string =  conversion_rate.toFixed(2)
    let convValue:number =  +conversion_result;
    return convValue
  }

  monthDemand(sales:number){
    let monthlyDemand: string = (sales/this.daysofdata*30).toFixed(1);
    let mDemand_Value: number = +monthlyDemand;
    return mDemand_Value; 
  }

 
  suggestedAddition: ModelStock
  addItem_P2(item)
  {
    this.suggestedAddition = new ModelStock;
    this.suggestedAddition.code = item.stock_code;
    this.suggestedAddition.description = item.description;
    this.suggestedAddition.msfId = item.msfid;
    this.inputStock = true;
  }

  strResp: string
  submitModelStock(item: ModelStock)
  {
    let msl = (document.getElementById("new_msl") as HTMLInputElement).value;
    var mslInt: number = +msl;
    item.msl = mslInt;
    this.alertBody = [];
    let supplier = (document.getElementById("new_supplier") as HTMLInputElement).value;
    item.supplier_name = supplier;
    if(this.chosenAddition)
      {item.supplierId = this.chosenAddition.supplierId;}
    else(item.supplierId = this.suggestedAddition.supplierId)

    this.StockModelsService.addModel(item).subscribe(
      res =>
      {
        // this.isLoading = true;
        this.strResp = res as string;
        this.notification.showSuccess(res as string);
        var repId = this.strResp.split('|')[0];
        var idInt: number = +repId;
        item.modelId = idInt;
        this.baseBackup.push(item);
        this.baseModel = this.baseBackup;
        this.baseBackup = this.baseBackup.slice();
        this.baseModel = this.baseModel.slice();
        
        for (let n =0;n < this.ModelTyres.length;n++)
        {
          if(this.ModelTyres[n].msfid == item.msfId)
          {
         
            this.ModelTyres[n].modelStock = mslInt;
            this.ModelTyres[n].stockReplenishmentId = idInt;
            this.ModelTyres_Backup = this.ModelTyres.slice()
           
            
          }
          if(this.ModelTyres[n].national_rank == 1 && this.ModelTyres[n].modelStock == 0 )
          {
            this.alertBody.push('#1 National Rank not in model Stock');
          }
          if(this.ModelTyres[n].brand_rank == 1 && this.ModelTyres[n].modelStock == 0 )
          {
            this.alertBody.push('#1 Brand Rank not in model Stock');
          }
          
        }
        let sortedSuggestions = this.ModelTyres_Backup.sort((a, b) => (a.best_cost < b.best_cost) ? -1 : 1);
        if(sortedSuggestions[0].modelStock == 0){
          this.alertBody.push("Cheapest Item not in model stock")
        }
        sortedSuggestions = this.ModelTyres_Backup.sort((a, b) => (a.modelStock > b.modelStock) ? -1 : 1);
        this.ModelTyres = sortedSuggestions;
      
        this.ModelTyres = this.ModelTyres.slice();
        this.show_critical_items();
        this.highlightCode = '';
        this.highlighted = 0;
        // this.isLoading = false;
        
      },
      error => {
        this.notification.handleError(error);
        this.isLoading = false;
      })
      
      this.addRow = this.stockList.indexOf(item);
      this.stockList.splice(this.addRow,1);
      this.stockList = this.stockList.slice();
      this.inputStock = false;
      this.currTable = 1;
      // this.isLoading = false;

      
  }

 
  indexMSFID = 0;
 

  editID = 0
  editLine_P2(line)
  {
    this.editMode = true;
    this.editID = line.msfid;
  }

deleteModel(item: ModelStockTyres): void{
    
    this.StockModelsService.deleteModel(item.stockReplenishmentId).subscribe(
    res =>
    {
      this.isLoading = true;
      this.notification.showSuccess("successfully Removed "+item.description);
      // this.ngOnInit();

      let r = this.ModelTyres_Backup.indexOf(item);
      this.ModelTyres[r].stockReplenishmentId = 0;
      this.ModelTyres[r].modelStock = 0;
      this.ModelTyres = this.ModelTyres.slice();
      this.ModelTyres_Backup = this.ModelTyres;
      this.ModelTyres_Backup = this.ModelTyres_Backup.slice();
      this.show_critical_items();
      this.isLoading = false;
      this.editMode = false;
    },
    error => {
      this.notification.handleError(error);
    })
    
    
    
}

deleteModel_Permenant(item: ModelStockTyres): void{
    
  this.StockModelsService.deleteModel_PR(item.stockReplenishmentId).subscribe(
  res =>
  {
    this.isLoading = true;
    this.notification.showSuccess("successfully Removed "+item.description);
    // this.ngOnInit();
    let r = this.ModelTyres_Backup.indexOf(item);
    for(let x = 0; x < this.ModelTyres.length;x++){
      if (this.ModelTyres[x].stockReplenishmentId == item.stockReplenishmentId){
        this.ModelTyres[x].stockReplenishmentId = 0;
        this.ModelTyres[x].modelStock = 0;
       
      }
    }
    this.ModelTyres = this.ModelTyres.slice();
    this.ModelTyres_Backup = this.ModelTyres;
    this.ModelTyres_Backup = this.ModelTyres_Backup.slice();
    this.show_critical_items();
    this.isLoading = false;
    this.editMode = false;
  },
  error => {
    this.notification.handleError(error);
    this.isLoading = false;
  })
  
  
  
}

  
  chooseSupplier(supplier: supplierInfo, page:number): void{
    if(page ==0)
    {
      (document.getElementById("supllierInput") as HTMLInputElement).value = supplier.supplier_name;
    }

    else if(page ==1)
    {
      (document.getElementById("new_supplier") as HTMLInputElement).value = supplier.supplier_name;
      this.chosenAddition.supplierId = supplier.supplierId;
    }
    else{
      (document.getElementById("new_supplier") as HTMLInputElement).value = supplier.supplier_name;
      this.suggestedAddition.supplierId = supplier.supplierId;
    }

  }
  
  

  suppIds: number[] = [];
  suppSummary: supplier_summary[] = []
  suppNew: supplier_summary

Model_Suggestions: Model_Suggestions[] = []
  size_suggestions()
  {
      this.StockModelsService.size_suggestions().subscribe(res =>
      {
        this.Model_Suggestions =  res as Model_Suggestions[];
      });
  }

  model_lines: Array<any>[] = []
  viewModel_Lines = false;
  
  highlightCode: string = ''
  highlightCode2: string = ''
  highlightCode3: string = ''
  highlighted: number = 0
  AltModelTyres: ModelStockTyres[] = []
  AllModelTyres: ModelStockTyres[] = []

  supplier_filter: string[] = []
  altItems:number = 0
  tier_inspected = "";
  isDark = false;
  baseTxt = 'white';
  genCode_quoteQty: number = 0;
  genCode_onView: string = '';
  viewLines(item:any)
  {
    this.genCode_onView = item;
    this.altItems = 0;
    this.highlightCode = '';
    this.highlighted = 0;
    this.alertBody = [];
    this.isLoading = true;
    let noSales = true;
    if (this.sales_tier != 'None'){ this.tier_inspected = item.split(': ')[1];}

    for(let m =0; m < this.Model_Stock.sizes.length;m++){
      if(this.Model_Stock.sizes[m].size == item){
        this.genCode_quoteQty = this.Model_Stock.sizes[m].leads_generated;
      }
    }
     
    this.StockModelsService.modelStockTyreSuggestions(item,this.daysofdata,this.sales_tier).subscribe((res:any) =>{
      this.isDark =  JSON.parse(localStorage.getItem('darkTheme')!) as boolean
      if(this.isDark == false){this.baseTxt = 'black';}
      this.ModelTyres = res.tyres as ModelStockTyres[];
   
      this.AltModelTyres = res.nonDemand as ModelStockTyres[];
      this.AllModelTyres = res.All_Suppliers as ModelStockTyres[];
      this.altItems = this.AltModelTyres.length;
      
      for(let x = 0; x < this.AltModelTyres.length;x++){
        this.AllModelTyres.push(this.AltModelTyres[x]);
      }
     
      if(this.ModelTyres.length == 0 || this.AllModelTyres.length == 0){
        this.notification.ShowBlockingPopup("None of your active suppliers have stock in this size")
        
        this.viewModel_Lines = true;
        this.isLoading = false;
      }else{
        this.ModelTyres_Backup = this.ModelTyres.slice();
        this.ModelTyres = this.ModelTyres.slice();
        this.show_critical_items();
       
        
        if (noSales === true){
          let sortedSales = this.ModelTyres.sort((a, b) => (a.national_rank < b.national_rank) ? -1 : 1);
          this.ModelTyres = sortedSales.slice();
        } else if(noSales === false){
          let sortedSales = this.ModelTyres.sort((a, b) => (a.sales > b.sales) ? -1 : 1);
          this.ModelTyres = sortedSales.slice();
        }
       
        this.displayByCriteria();
       
        this.viewModel_Lines = true;
        if (this.suppliersTemp.length == 1 ){
          this.groupBy(this.suppliersTemp[0])
        }
        this.isLoading = false;
    
      }
     
      // this.groupBy(this.suppliers[0]);
     
    },
    err => {
      this.notification.handleError(err);
    }
    )
    
    this.configure_localStorage(item)
  }

sizes_viewedStorage: GenCode_Storage[] = []

gencodes_view: string[] = []
todayStr: string = formatDate(new Date(), 'yyyy/MM/dd', 'en');
configure_localStorage(size: string)
{
  this.gencodes_view = []
  let today = formatDate(new Date(), 'yyyy/MM/dd', 'en');
  let viewedGenCode = new GenCode_Storage()
  viewedGenCode.size = size
  viewedGenCode.dt = today
  viewedGenCode.userId = this.auth.user.client_id
  
  if(localStorage.getItem('sizes_viewed')==null)
  {
    this.sizes_viewedStorage.push(viewedGenCode)
    localStorage.setItem('sizes_viewed',JSON.stringify(this.sizes_viewedStorage))
  }else{
    this.sizes_viewedStorage =  JSON.parse(localStorage.getItem('sizes_viewed')!) as GenCode_Storage[]
    let itemsToSave: GenCode_Storage[] = [];
    let found = false
    for(let x =0;x < this.sizes_viewedStorage.length;x++)
    {
      if(this.getDaysPastItem(this.sizes_viewedStorage[x]) <= 7){
        // this.sizes_viewedStorage.splice(x,1);
        // this.sizes_viewedStorage = this.sizes_viewedStorage.slice()
        itemsToSave.push(this.sizes_viewedStorage[x]);
      }
      // if(size == this.sizes_viewedStorage[x].size && this.sizes_viewedStorage[x].userId ==  this.auth.user.client_id && today != this.sizes_viewedStorage[x].dt){
      //     this.sizes_viewedStorage[x].dt = today; found = true;
      // }
      // if(size == this.sizes_viewedStorage[x].size && this.sizes_viewedStorage[x].userId ==  this.auth.user.client_id && today == this.sizes_viewedStorage[x].dt){
      //   found = true;
      // }
      
    }
    for (const item of itemsToSave) {
      if (item.size == viewedGenCode.size && item.userId == viewedGenCode.userId) {
        found = true;
        item.dt = viewedGenCode.dt;
      }
    }
    if (!found){
      itemsToSave.push(viewedGenCode)
    }
    localStorage.removeItem('sizes_viewed')
    localStorage.setItem('sizes_viewed',JSON.stringify(itemsToSave));
    this.sizes_viewedStorage = itemsToSave;
  }
  for(let n =0; n < this.sizes_viewedStorage.length;n++){
    this.gencodes_view.push(this.sizes_viewedStorage[n].size)
  }
}

getLocal_Sizes()
{
  this.gencodes_view = []
  if(localStorage.getItem('sizes_viewed')!=null)
  {
    this.sizes_viewedStorage =  JSON.parse(localStorage.getItem('sizes_viewed')!) as GenCode_Storage[]
    }

  for(let n =0; n < this.sizes_viewedStorage.length;n++){
    
    //---Uncomment if items viewed more than 7 days ago must be removed
    let itemsToShow: string[] = [];
    if(this.getDaysPastItem(this.sizes_viewedStorage[n]) <= 7 && this.sizes_viewedStorage[n].userId ==  this.auth.user.client_id){
      //this.sizes_viewedStorage.splice(n,1);
      //this.sizes_viewedStorage = this.sizes_viewedStorage.slice()
      itemsToShow.push(this.sizes_viewedStorage[n].size)
    }
    //--------------------------------------------------
    this.gencodes_view = itemsToShow;
  }
  
}

findSize(size:string):  boolean
{
  
  let isFound =  this.sizes_viewedStorage.some(element=>{
    if(element.size === size && element.userId == this.auth.user.client_id){return true;}
    return false;
  }) 
  return isFound
}

findSize_Date(size:string): string
{
  let res = ''
  let temp = new GenCode_Storage()
  temp.size = size
  temp.dt = this.todayStr.toString()
  for(let x =0; x < this.sizes_viewedStorage.length;x++){
    if(this.sizes_viewedStorage[x].size==size && this.sizes_viewedStorage[x].userId ==  this.auth.user.client_id  && this.sizes_viewedStorage[x].dt == this.todayStr){
      res = 'today'
    }
    if(this.sizes_viewedStorage[x].size==size && this.sizes_viewedStorage[x].userId ==  this.auth.user.client_id && this.sizes_viewedStorage[x].dt != this.todayStr){
      res = 'not_today'
    }
  }
  return res
}

getDaysPast(size:string): number{
  let days = 0
  for(let x=0;x < this.sizes_viewedStorage.length;x++){
    if(this.sizes_viewedStorage[x].size == size && this.sizes_viewedStorage[x].userId ==  this.auth.user.client_id){
      // let tL: number =+ this.sizes_viewedStorage[x].dt.split('/')[2]
      // let tD: number =+ this.todayStr.split('/')[2]
      let tL = this.sizes_viewedStorage[x].dt.split('/')
      let tD = this.todayStr.split('/')
      let today = new Date(tD[1]+"/"+tD[2]+"/"+tD[0])
      let past_date =  new Date(tL[1]+"/"+tL[2]+"/"+tL[0])

      

      var diff = today.getTime() - past_date.getTime();
      days = diff / (1000 * 3600 * 24); 


      // days = tD - tL
    }
  }
  return days
}

getDaysPastItem(item:GenCode_Storage): number{
  let days = 0
  
      // let tL: number =+ this.sizes_viewedStorage[x].dt.split('/')[2]
      // let tD: number =+ this.todayStr.split('/')[2]
    let tL = item.dt.split('/')
    let tD = this.todayStr.split('/')
    let today = new Date(tD[1]+"/"+tD[2]+"/"+tD[0])
    let past_date =  new Date(tL[1]+"/"+tL[2]+"/"+tL[0])

    

    var diff = today.getTime() - past_date.getTime();
    days = diff / (1000 * 3600 * 24); 


      // days = tD - tL
    
  return days
}


items_shown: boolean = false
nonDemandTyres(){
  let noSales = true;
  if(this.items_shown == false){
    for(let n =0; n < this.AltModelTyres.length;n++){
      let idx = this.ModelTyres.indexOf(this.AltModelTyres[n])
      if(this.AllModelTyres[n] && this.AltModelTyres[n].sales > 0){noSales = false;}
      if(idx == -1){
        this.ModelTyres.push(this.AltModelTyres[n]);
        this.ModelTyres = this.ModelTyres.slice();
        
      }
    }
    this.items_shown = true;
    if(noSales == false){
      let sortedSales = this.ModelTyres.sort((a, b) => (a.sales > b.sales) ? -1 : 1);
      this.ModelTyres = sortedSales.slice();
    }else if (noSales == true){
      let sortedSales = this.ModelTyres.sort((a, b) => (a.national_rank < b.national_rank) ? -1 : 1);
      this.ModelTyres = sortedSales.slice();

    }
    
   
  }
  
}

tempModel: ModelStockTyres[] = []
groupMethod: string = "default"
currentSupplierOnView: supplierInfo;
groupBy(supplier:supplierInfo){
  let noSales = true;
  this.currentSupplierOnView = supplier;
  this.altItems = this.AltModelTyres.length;
  this.items_shown = false;
  this.groupMethod = "other";
  this.tempModel = [];
  let codesAdded:string[] = [];
  for(let n =0; n < this.AllModelTyres.length;n++){
    if(this.AllModelTyres[n].best_supplier == supplier.supplier_name){
      if(codesAdded.includes(this.AllModelTyres[n].stock_code)){
        continue
      }
      else{
        this.tempModel.push(this.AllModelTyres[n]);
        codesAdded.push(this.AllModelTyres[n].stock_code);
      }
      if(this.AllModelTyres[n] && this.AllModelTyres[n].sales > 0){noSales = false;}
    }
  }

  this.ModelTyres = this.tempModel;
  this.ModelTyres = this.ModelTyres.slice()
  
  if(noSales == false){
    let sortedSales = this.ModelTyres.sort((a, b) => (a.sales > b.sales) ? -1 : 1);
    this.ModelTyres = sortedSales.slice();
  }else if (noSales == true){
    let sortedSales = this.ModelTyres.sort((a, b) => (a.national_rank < b.national_rank) ? -1 : 1);
    this.ModelTyres = sortedSales.slice();

  }
}


displayByCriteria(){
  this.currentSupplierOnView = new supplierInfo;
  this.currentSupplierOnView.supplier_name = 'ALL';
  let noSales = true;
  this.altItems = this.AltModelTyres.length;
  this.items_shown = false;
  this.groupMethod = "other";
  this.tempModel = [];
  let codesAdded:string[] = [];
 
  for(let n =0; n < this.AllModelTyres.length;n++){
    
    if(this.AllModelTyres[n].sales > 0 || this.AllModelTyres[n].soh >0 || this.AllModelTyres[n].supplier_soh >0 || this.AllModelTyres[n].suggested_modelStock > 0 || this.AllModelTyres[n].modelStock >0){
     
       let match = false;
       //  check tempModel to see if code already exists. if it does only keep the cheapest one 
       for(let y = 0; y < codesAdded.length; y++){
        if(codesAdded[y] == this.AllModelTyres[n].stock_code){match = true;}
       }
       if(match == false){
        var cheapestCost = this.AllModelTyres[n].best_cost;
        var cheapestIdx = n;
        for(let j = 0; j < this.AllModelTyres.length;j++){
          if(this.AllModelTyres[j].stock_code == this.AllModelTyres[n].stock_code && this.AllModelTyres[j].best_cost < cheapestCost && this.AllModelTyres[j].best_supplier != 'CostFinder: buy_all_in Cost'){cheapestCost =this.AllModelTyres[j].best_cost; cheapestIdx = j;}
        }
        this.tempModel.push(this.AllModelTyres[cheapestIdx]);
        codesAdded.push(this.AllModelTyres[cheapestIdx].stock_code);
        codesAdded.slice();
       }
      //  -----------------------------------------------------------------------------------
       
      
       
       if(this.AllModelTyres[n] && this.AllModelTyres[n].sales > 0){noSales = false;}
    }
  }
 
  this.ModelTyres = this.tempModel.slice();
  // this.ModelTyres = this.ModelTyres.slice();
  
  if(noSales == false){
    let sortedSales = this.ModelTyres.sort((a, b) => (a.sales > b.sales) ? -1 : 1);
    this.ModelTyres = sortedSales.slice();
  }else if (noSales == true){
    let sortedSales = this.ModelTyres.sort((a, b) => (a.national_rank < b.national_rank) ? -1 : 1);
    this.ModelTyres = sortedSales.slice();
  }
  
}

showNonDemandOnly(){
  let noSales = true;
  this.currentSupplierOnView = new supplierInfo();
  this.currentSupplierOnView.supplier_name = 'Items Suppliers Are Not Carrying';
  this.ModelTyres = [];
  if(this.items_shown == false){
    for(let n =0; n < this.AltModelTyres.length;n++){
      let idx = this.ModelTyres.indexOf(this.AltModelTyres[n])
      if(this.AllModelTyres[n] && this.AltModelTyres[n].sales > 0){noSales = false;}
      if(idx == -1){
        this.ModelTyres.push(this.AltModelTyres[n]);
        this.ModelTyres = this.ModelTyres.slice();
        
      }
    }
    this.items_shown = true;
    if(noSales == false){
      let sortedSales = this.ModelTyres.sort((a, b) => (a.sales > b.sales) ? -1 : 1);
      this.ModelTyres = sortedSales.slice();
    }else if (noSales == true){
      let sortedSales = this.ModelTyres.sort((a, b) => (a.national_rank < b.national_rank) ? -1 : 1);
      this.ModelTyres = sortedSales.slice();

    }
    
    
  }
}

reset_to_bestcost(){
  this.groupMethod = "default"
  this.altItems = this.AltModelTyres.length;
  this.items_shown = false;
  this.ModelTyres = []
  this.ModelTyres = this.ModelTyres_Backup;
  this.ModelTyres = this.ModelTyres.slice()
  this.items_shown = false;
}

cheapest_code: string = ''
national_codes:string[] = []
brand_codes:string[] = []
show_critical_items()
{
 
  for(let n = 0; n < this.ModelTyres.length; n++)
      {
        for(let m  = 0; m < this.suppliers.length; m++)
        {
          if(this.ModelTyres[n].best_supplier == this.suppliers[m].supplier_name)
          {
            this.ModelTyres[n].replenish_active = this.suppliers[m].repActive;
          }
        }
      }
  let sortedSuggestions = this.ModelTyres.filter(item => item.best_cost !== 0).sort((a, b) => (a.best_cost < b.best_cost) ? -1 : 1);
  if(sortedSuggestions.length >0){
    this.ModelTyres = sortedSuggestions;
  }
  this.ModelTyres = this.ModelTyres.slice();
  this.cheapest_code = this.ModelTyres[0].stock_code;

  for(let x =0; x < this.ModelTyres.length; x++){
    if(this.ModelTyres[x].national_rank == 1){this.national_codes.push(this.ModelTyres[x].stock_code);}
    if(this.ModelTyres[x].brand_rank == 1){this.brand_codes.push(this.ModelTyres[x].stock_code);}
  }
}
// show_critical_items()
// {

//   this.cheapest_code =  '';
//   this.national_code =  []
//   this.brand_code = [];
//   this.alertBody = [];
//   for(let n = 0; n < this.ModelTyres.length; n++)
//       {
//         for(let m  = 0; m < this.suppliers.length; m++)
//         {
//           if(this.ModelTyres[n].best_supplier == this.suppliers[m].supplier_name)
//           {
//             this.ModelTyres[n].replenish_active = this.suppliers[m].repActive;
//           }
//         }
//         if(this.ModelTyres[n].national_rank == 1 && this.ModelTyres[n].modelStock == 0 && this.alertBody.includes('#1 National Rank not in model Stock') == false )
//         {
//           this.alertBody.push('#1 National Rank not in model Stock');
//           // this.highlightCode = this.ModelTyres[n].stock_code;
          
//         }
//         // if(this.alertBody.includes('#1 Brand Rank for this size not in model Stock') == false){
//         //   if(this.ModelTyres[n].brand_rank == 1 && this.ModelTyres[n].modelStock == 0 )
//         //   {
//         //     this.alertBody.push('#1 Brand Rank for this size not in model Stock');
//         //     this.highlightCode2 = this.ModelTyres[n].stock_code;
//         //   }
//         // }
//         for(let x = 0; x <  this.Model_Stock.sizes.length ;x++)
//         {
//           if(this.Model_Stock.sizes[x].size ==this.ModelTyres[n].size){
//             if(this.Model_Stock.sizes[x].alerts.includes("Brand Switch")){
//               this.alertBody.push(this.Model_Stock.sizes[x].alerts)
//             }
//           }
//         }
        
//       }
//       let sortedSuggestions = this.ModelTyres_Backup.sort((a, b) => (a.best_cost < b.best_cost) ? -1 : 1);
//       if(sortedSuggestions[0].modelStock == 0){
//         this.alertBody.push("Cheapest Item not in model stock");
//         // this.highlightCode3 = sortedSuggestions[0].stock_code;
//       }
      
//   for(let n = 0 ; n < this.alertBody.length;n++){
//     if(this.alertBody[n].includes('Cheapest')){
//       let sortedSuggestions = this.ModelTyres.sort((a, b) => (a.best_cost < b.best_cost) ? -1 : 1);
//       this.ModelTyres = sortedSuggestions;
//       this.ModelTyres = this.ModelTyres.slice();
//       this.cheapest_code = this.ModelTyres[0].stock_code;
//       }

//     if(this.alertBody[n].toString().includes('National')){
//       let sortedSuggestions = this.ModelTyres.sort((a, b) => (a.national_rank < b.national_rank) ? -1 : 1);
//       this.ModelTyres = sortedSuggestions;
//       this.ModelTyres = this.ModelTyres.slice();
//       this.national_code = this.ModelTyres[0].stock_code;
      
//     }
//     if(this.alertBody[n].toString().includes('Brand')){
//       let sortedSuggestions = this.ModelTyres.sort((a, b) => (a.brand_rank < b.brand_rank) ? -1 : 1);
//       this.ModelTyres = sortedSuggestions;
//       this.ModelTyres = this.ModelTyres.slice();
//       this.brand_code = this.ModelTyres[0].stock_code;
      
//     }
//   }
//   sortedSuggestions = this.ModelTyres_Backup.sort((a, b) => (a.modelStock > b.modelStock) ? -1 : 1);
//   this.ModelTyres = sortedSuggestions;
//   this.ModelTyres = this.ModelTyres.slice();

  
// }


warningHighlight(item: any)
{
  
  if(item.toString().includes('Cheapest')){
    let sortedSuggestions = this.ModelTyres.sort((a, b) => (a.best_cost < b.best_cost) ? -1 : 1);
    this.ModelTyres = sortedSuggestions;
    this.ModelTyres = this.ModelTyres.slice();
    if(this.highlightCode == this.ModelTyres[0].stock_code){
      this.highlightCode = '';
    }else{
      this.highlightCode = this.ModelTyres[0].stock_code;
    }
  }

  if(item.toString().includes('National')){
    let sortedSuggestions = this.ModelTyres.sort((a, b) => (a.national_rank < b.national_rank) ? -1 : 1);
    this.ModelTyres = sortedSuggestions;
    this.ModelTyres = this.ModelTyres.slice();
    if(this.highlightCode == this.ModelTyres[0].stock_code){
      this.highlightCode = '';
    }else{
      this.highlightCode = this.ModelTyres[0].stock_code;
    }
  }

  if(item.toString().includes('Brand')){
    let sortedSuggestions = this.ModelTyres.sort((a, b) => (a.brand_rank < b.brand_rank) ? -1 : 1);
    this.ModelTyres = sortedSuggestions;
    this.ModelTyres = this.ModelTyres.slice();
    if(this.highlightCode == this.ModelTyres[0].stock_code){
      this.highlightCode = '';
    }else{
      this.highlightCode = this.ModelTyres[0].stock_code;
    }
  }
  
  
  
}

getAlert(type: string)
{
  this.isLoading = true;
  this.initLoading = true;
  this.StockModelsService.Single_StockAlerts(this.Model_Stock.sizes,type,this.daysofdata).subscribe(res =>{
    this.Model_Stock.sizes = res as ModelStockSizes[];
    this.tempSizes = this.Model_Stock.sizes;
    this.isLoading  = false;
    this.initLoading = false;
    let sortedSizes = this.Model_Stock.sizes.sort((a, b) =>  (a.sales_rank_all < b.sales_rank_all) ? -1 : 1);
    this.Model_Stock.sizes = sortedSizes;
    this.Model_Stock.sizes = this.Model_Stock.sizes.slice();
  },err=>{
    this.notification.handleError(err);
  });
}

Model_Stock_backup: ModelStock_def;
filter_applied = false;
double_relational = false;
filter_comparisonA = "gt";
filter_comparisonA_LR = "gl";

filter_comparisonB = "gt";
filter_comparisonB_LR = "gl";

filter_comparisonC = "gt";
filter_comparisonC_LR = "gl";

filter(type: string){
  // this.clear_filters()
  this.isLoading = true;
  this.Model_Stock_backup = this.Model_Stock
  let model_filtered = new ModelStock_def;
  model_filtered.soh = 0;
  model_filtered.sales = 0;
  model_filtered.daysOfStock = 0;
  model_filtered.modelStock = 0;
  model_filtered.suggested_modelStock = 0;
  model_filtered.sizes = [];
  let val = 0;
  let lVal = 0;
  let rVal = 0;
  if (type == 'DOS'){ 
    if(this.double_relational == false){
      // (document.getElementById('filterB') as HTMLInputElement).value= '0';
      // (document.getElementById('filterC') as HTMLInputElement).value= '0';
      let dod = (document.getElementById("filterA") as HTMLInputElement).value;
      var dodInt: number = +dod;
      val = dodInt
      for(let x =0; x < this.Model_Stock.sizes.length;x++){
        if(this.filter_comparisonA =='gt' && this.Model_Stock.sizes[x].dos > val){
          let filtered_size = new ModelStockSizes
          filtered_size.size = this.Model_Stock.sizes[x].size;
          filtered_size.sales = this.Model_Stock.sizes[x].sales;
          filtered_size.sales_rank_all = this.Model_Stock.sizes[x].sales_rank_all;
          filtered_size.sales_rank_client = this.Model_Stock.sizes[x].sales_rank_client
          filtered_size.sales_share = this.Model_Stock.sizes[x].sales_share
          filtered_size.search_rank_all = this.Model_Stock.sizes[x].sales_rank_all;
          filtered_size.soh = this.Model_Stock.sizes[x].soh;
          filtered_size.dos = this.Model_Stock.sizes[x].dos;
          filtered_size.modelStock = this.Model_Stock.sizes[x].modelStock;
          filtered_size.suggested_modelStock = this.Model_Stock.sizes[x].suggested_modelStock;
          filtered_size.four_week_demand = this.Model_Stock.sizes[x].four_week_demand;
          filtered_size.lines = this.Model_Stock.sizes[x].lines;
          filtered_size.alerts = this.Model_Stock.sizes[x].alerts;
          model_filtered.modelStock += filtered_size.modelStock;
          model_filtered.suggested_modelStock += filtered_size.suggested_modelStock;
          model_filtered.soh += filtered_size.soh;
          model_filtered.sales += filtered_size.sales;
          if (filtered_size.sales != 0 && filtered_size.soh != 0){
            model_filtered.daysOfStock += (filtered_size.soh/(filtered_size.sales/this.daysofdata*1.0));
          }
          model_filtered.sizes.push(filtered_size);
        }
        else if(this.filter_comparisonA =='lt' && this.Model_Stock.sizes[x].dos < val){
          let filtered_size = new ModelStockSizes
          filtered_size.size = this.Model_Stock.sizes[x].size;
          filtered_size.sales = this.Model_Stock.sizes[x].sales;
          filtered_size.sales_rank_all = this.Model_Stock.sizes[x].sales_rank_all;
          filtered_size.sales_rank_client = this.Model_Stock.sizes[x].sales_rank_client
          filtered_size.sales_share = this.Model_Stock.sizes[x].sales_share
          filtered_size.search_rank_all = this.Model_Stock.sizes[x].sales_rank_all;
          filtered_size.soh = this.Model_Stock.sizes[x].soh;
          filtered_size.dos = this.Model_Stock.sizes[x].dos;
          filtered_size.modelStock = this.Model_Stock.sizes[x].modelStock;
          filtered_size.suggested_modelStock = this.Model_Stock.sizes[x].suggested_modelStock;
          filtered_size.four_week_demand = this.Model_Stock.sizes[x].four_week_demand;
          filtered_size.lines = this.Model_Stock.sizes[x].lines;
          filtered_size.alerts = this.Model_Stock.sizes[x].alerts;
          model_filtered.modelStock += filtered_size.modelStock;
          model_filtered.suggested_modelStock += filtered_size.suggested_modelStock;
          model_filtered.soh += filtered_size.soh;
          model_filtered.sales += filtered_size.sales;
          if (filtered_size.sales != 0 && filtered_size.soh != 0){
            model_filtered.daysOfStock += (filtered_size.soh/(filtered_size.sales/this.daysofdata*1.0));
          }
          // model_filtered.daysOfStock += filtered_size.dos;
          model_filtered.sizes.push(filtered_size);
        }
      }
    }
    else if(this.double_relational == true){
      // (document.getElementById('filterL_B') as HTMLInputElement).value= '0';
      // (document.getElementById('filterR_B') as HTMLInputElement).value= '0';
      // (document.getElementById('filterL_C') as HTMLInputElement).value= '0';
      // (document.getElementById('filterR_C') as HTMLInputElement).value= '0';
      let invalA = (document.getElementById("filterL_A") as HTMLInputElement).value;
      var dodInt: number = +invalA;
      lVal = dodInt
      let invalB = (document.getElementById("filterR_A") as HTMLInputElement).value;
      var dodInt: number = +invalB;
      rVal = dodInt
      for(let x =0; x < this.Model_Stock.sizes.length;x++){
        if(this.filter_comparisonA_LR =='gl' && (lVal < this.Model_Stock.sizes[x].dos  && this.Model_Stock.sizes[x].dos < rVal )){
          let filtered_size = new ModelStockSizes
          filtered_size.size = this.Model_Stock.sizes[x].size;
          filtered_size.sales = this.Model_Stock.sizes[x].sales;
          filtered_size.sales_rank_all = this.Model_Stock.sizes[x].sales_rank_all;
          filtered_size.sales_rank_client = this.Model_Stock.sizes[x].sales_rank_client
          filtered_size.sales_share = this.Model_Stock.sizes[x].sales_share
          filtered_size.search_rank_all = this.Model_Stock.sizes[x].sales_rank_all;
          filtered_size.soh = this.Model_Stock.sizes[x].soh;
          filtered_size.dos = this.Model_Stock.sizes[x].dos;
          filtered_size.modelStock = this.Model_Stock.sizes[x].modelStock;
          filtered_size.suggested_modelStock = this.Model_Stock.sizes[x].suggested_modelStock;
          filtered_size.four_week_demand = this.Model_Stock.sizes[x].four_week_demand;
          filtered_size.lines = this.Model_Stock.sizes[x].lines;
          filtered_size.alerts = this.Model_Stock.sizes[x].alerts;
          model_filtered.modelStock += filtered_size.modelStock;
          model_filtered.suggested_modelStock += filtered_size.suggested_modelStock;
          model_filtered.soh += filtered_size.soh;
          model_filtered.sales += filtered_size.sales;
          if (filtered_size.sales != 0 && filtered_size.soh != 0){
            model_filtered.daysOfStock += (filtered_size.soh/(filtered_size.sales/this.daysofdata*1.0));
          }
          // model_filtered.daysOfStock += filtered_size.dos;
          model_filtered.sizes.push(filtered_size);
        }
        
      }
    }
    model_filtered.daysOfStock = Math.round(model_filtered.soh/(model_filtered.sales/this.daysofdata*1.0));
    this.Model_Stock = model_filtered;
    this.filter_applied = true;
  }
  if (type == 'SOH'){
    if(this.double_relational == false){
      // (document.getElementById('filterA') as HTMLInputElement).value= '0';
      // (document.getElementById('filterC') as HTMLInputElement).value= '0'; 
      let dod = (document.getElementById("filterB") as HTMLInputElement).value;
      var dodInt: number = +dod;
      val = dodInt
      for(let x =0; x < this.Model_Stock.sizes.length;x++){
        if(this.filter_comparisonB == 'gt' && this.Model_Stock.sizes[x].soh > val){
          let filtered_size = new ModelStockSizes
          filtered_size.size = this.Model_Stock.sizes[x].size;
          filtered_size.sales = this.Model_Stock.sizes[x].sales;
          filtered_size.sales_rank_all = this.Model_Stock.sizes[x].sales_rank_all;
          filtered_size.sales_rank_client = this.Model_Stock.sizes[x].sales_rank_client
          filtered_size.sales_share = this.Model_Stock.sizes[x].sales_share
          filtered_size.search_rank_all = this.Model_Stock.sizes[x].sales_rank_all;
          filtered_size.soh = this.Model_Stock.sizes[x].soh;
          filtered_size.dos = this.Model_Stock.sizes[x].dos;
          filtered_size.modelStock = this.Model_Stock.sizes[x].modelStock;
          filtered_size.suggested_modelStock = this.Model_Stock.sizes[x].suggested_modelStock;
          filtered_size.four_week_demand = this.Model_Stock.sizes[x].four_week_demand;
          filtered_size.lines = this.Model_Stock.sizes[x].lines;
          filtered_size.alerts = this.Model_Stock.sizes[x].alerts;
          model_filtered.modelStock += filtered_size.modelStock;
          model_filtered.suggested_modelStock += filtered_size.suggested_modelStock;
          model_filtered.soh += filtered_size.soh;
          model_filtered.sales += filtered_size.sales;
          if (filtered_size.sales != 0 && filtered_size.soh != 0){
            model_filtered.daysOfStock += (filtered_size.soh/(filtered_size.sales/this.daysofdata*1.0));
          }
          // int(size.soh/(size.sales/daysOfData*1.0))
          model_filtered.sizes.push(filtered_size);
        }
        else if(this.filter_comparisonB == 'lt' && this.Model_Stock.sizes[x].soh < val){
          let filtered_size = new ModelStockSizes
          filtered_size.size = this.Model_Stock.sizes[x].size;
          filtered_size.sales = this.Model_Stock.sizes[x].sales;
          filtered_size.sales_rank_all = this.Model_Stock.sizes[x].sales_rank_all;
          filtered_size.sales_rank_client = this.Model_Stock.sizes[x].sales_rank_client
          filtered_size.sales_share = this.Model_Stock.sizes[x].sales_share
          filtered_size.search_rank_all = this.Model_Stock.sizes[x].sales_rank_all;
          filtered_size.soh = this.Model_Stock.sizes[x].soh;
          filtered_size.dos = this.Model_Stock.sizes[x].dos;
          filtered_size.modelStock = this.Model_Stock.sizes[x].modelStock;
          filtered_size.suggested_modelStock = this.Model_Stock.sizes[x].suggested_modelStock;
          filtered_size.four_week_demand = this.Model_Stock.sizes[x].four_week_demand;
          filtered_size.lines = this.Model_Stock.sizes[x].lines;
          filtered_size.alerts = this.Model_Stock.sizes[x].alerts;
          model_filtered.modelStock += filtered_size.modelStock;
          model_filtered.suggested_modelStock += filtered_size.suggested_modelStock;
          model_filtered.soh += filtered_size.soh;
          model_filtered.sales += filtered_size.sales;
          if (filtered_size.sales != 0 && filtered_size.soh != 0){
            model_filtered.daysOfStock += (filtered_size.soh/(filtered_size.sales/this.daysofdata*1.0));
          }
          // int(size.soh/(size.sales/daysOfData*1.0))
          model_filtered.sizes.push(filtered_size);
        }
      }
    }
    if(this.double_relational == true){
      // (document.getElementById('filterL_A') as HTMLInputElement).value= '0';
      // (document.getElementById('filterR_A') as HTMLInputElement).value= '0';
      // (document.getElementById('filterL_C') as HTMLInputElement).value= '0';
      // (document.getElementById('filterR_C') as HTMLInputElement).value= '0';  
      let invalA = (document.getElementById("filterL_B") as HTMLInputElement).value;
      var dodInt: number = +invalA;
      lVal = dodInt
      let invalB = (document.getElementById("filterR_B") as HTMLInputElement).value;
      var dodInt: number = +invalB;
      rVal = dodInt
      for(let x =0; x < this.Model_Stock.sizes.length;x++){
        if(this.filter_comparisonB_LR == 'gl' && (lVal < this.Model_Stock.sizes[x].soh && this.Model_Stock.sizes[x].soh < rVal)){
          let filtered_size = new ModelStockSizes
          filtered_size.size = this.Model_Stock.sizes[x].size;
          filtered_size.sales = this.Model_Stock.sizes[x].sales;
          filtered_size.sales_rank_all = this.Model_Stock.sizes[x].sales_rank_all;
          filtered_size.sales_rank_client = this.Model_Stock.sizes[x].sales_rank_client
          filtered_size.sales_share = this.Model_Stock.sizes[x].sales_share
          filtered_size.search_rank_all = this.Model_Stock.sizes[x].sales_rank_all;
          filtered_size.soh = this.Model_Stock.sizes[x].soh;
          filtered_size.dos = this.Model_Stock.sizes[x].dos;
          filtered_size.modelStock = this.Model_Stock.sizes[x].modelStock;
          filtered_size.suggested_modelStock = this.Model_Stock.sizes[x].suggested_modelStock;
          filtered_size.four_week_demand = this.Model_Stock.sizes[x].four_week_demand;
          filtered_size.lines = this.Model_Stock.sizes[x].lines;
          filtered_size.alerts = this.Model_Stock.sizes[x].alerts;
          model_filtered.modelStock += filtered_size.modelStock;
          model_filtered.suggested_modelStock += filtered_size.suggested_modelStock;
          model_filtered.soh += filtered_size.soh;
          model_filtered.sales += filtered_size.sales;
          if (filtered_size.sales != 0 && filtered_size.soh != 0){
            model_filtered.daysOfStock += (filtered_size.soh/(filtered_size.sales/this.daysofdata*1.0));
          }
          // int(size.soh/(size.sales/daysOfData*1.0))
          model_filtered.sizes.push(filtered_size);
        }
        
      }
    }

    model_filtered.daysOfStock = Math.round(model_filtered.soh/(model_filtered.sales/this.daysofdata*1.0));
    this.Model_Stock = model_filtered;
    this.filter_applied = true;
  }

  if (type == 'SALES'){
    if(this.double_relational == false){
      // (document.getElementById('filterA') as HTMLInputElement).value= '0';
      // (document.getElementById('filterB') as HTMLInputElement).value= '0';
      let dod = (document.getElementById("filterC") as HTMLInputElement).value;
      var dodInt: number = +dod;
      val = dodInt
      for(let x =0; x < this.Model_Stock.sizes.length;x++){
        if(this.filter_comparisonC =='gt' && this.Model_Stock.sizes[x].sales > val){
          let filtered_size = new ModelStockSizes
          filtered_size.size = this.Model_Stock.sizes[x].size;
          filtered_size.sales = this.Model_Stock.sizes[x].sales;
          filtered_size.sales_rank_all = this.Model_Stock.sizes[x].sales_rank_all;
          filtered_size.sales_rank_client = this.Model_Stock.sizes[x].sales_rank_client
          filtered_size.sales_share = this.Model_Stock.sizes[x].sales_share
          filtered_size.search_rank_all = this.Model_Stock.sizes[x].sales_rank_all;
          filtered_size.soh = this.Model_Stock.sizes[x].soh;
          filtered_size.dos = this.Model_Stock.sizes[x].dos;
          filtered_size.modelStock = this.Model_Stock.sizes[x].modelStock;
          filtered_size.suggested_modelStock = this.Model_Stock.sizes[x].suggested_modelStock;
          filtered_size.four_week_demand = this.Model_Stock.sizes[x].four_week_demand;
          filtered_size.lines = this.Model_Stock.sizes[x].lines;
          filtered_size.alerts = this.Model_Stock.sizes[x].alerts;
          model_filtered.modelStock += filtered_size.modelStock;
          model_filtered.suggested_modelStock += filtered_size.suggested_modelStock;
          model_filtered.soh += filtered_size.soh;
          model_filtered.sales += filtered_size.sales;
          // model_filtered.daysOfStock += filtered_size.dos;
          if (filtered_size.sales != 0 && filtered_size.soh != 0){
            model_filtered.daysOfStock += (filtered_size.soh/(filtered_size.sales/this.daysofdata*1.0));
          }
          model_filtered.sizes.push(filtered_size);
        }
        else if(this.filter_comparisonC =='lt' && this.Model_Stock.sizes[x].sales < val){
          let filtered_size = new ModelStockSizes
          filtered_size.size = this.Model_Stock.sizes[x].size;
          filtered_size.sales = this.Model_Stock.sizes[x].sales;
          filtered_size.sales_rank_all = this.Model_Stock.sizes[x].sales_rank_all;
          filtered_size.sales_rank_client = this.Model_Stock.sizes[x].sales_rank_client
          filtered_size.sales_share = this.Model_Stock.sizes[x].sales_share
          filtered_size.search_rank_all = this.Model_Stock.sizes[x].sales_rank_all;
          filtered_size.soh = this.Model_Stock.sizes[x].soh;
          filtered_size.dos = this.Model_Stock.sizes[x].dos;
          filtered_size.modelStock = this.Model_Stock.sizes[x].modelStock;
          filtered_size.suggested_modelStock = this.Model_Stock.sizes[x].suggested_modelStock;
          filtered_size.four_week_demand = this.Model_Stock.sizes[x].four_week_demand;
          filtered_size.lines = this.Model_Stock.sizes[x].lines;
          filtered_size.alerts = this.Model_Stock.sizes[x].alerts;
          model_filtered.modelStock += filtered_size.modelStock;
          model_filtered.suggested_modelStock += filtered_size.suggested_modelStock;
          model_filtered.soh += filtered_size.soh;
          model_filtered.sales += filtered_size.sales;
          // model_filtered.daysOfStock += filtered_size.dos;
          if (filtered_size.sales != 0 && filtered_size.soh != 0){
            model_filtered.daysOfStock += (filtered_size.soh/(filtered_size.sales/this.daysofdata*1.0));
          }
          model_filtered.sizes.push(filtered_size);
        }
      }
    }
    else if(this.double_relational == true){
      // (document.getElementById('filterL_A') as HTMLInputElement).value= '0';
      // (document.getElementById('filterR_A') as HTMLInputElement).value= '0';
      // (document.getElementById('filterL_B') as HTMLInputElement).value= '0';
      // (document.getElementById('filterR_B') as HTMLInputElement).value= '0';
      let invalA = (document.getElementById("filterL_C") as HTMLInputElement).value;
      var dodInt: number = +invalA;
      lVal = dodInt
      let invalB = (document.getElementById("filterR_C") as HTMLInputElement).value;
      var dodInt: number = +invalB;
      rVal = dodInt
      for(let x =0; x < this.Model_Stock.sizes.length;x++){
        if(this.filter_comparisonC_LR =='gl' && (lVal <this.Model_Stock.sizes[x].sales && this.Model_Stock.sizes[x].sales < rVal)){
          let filtered_size = new ModelStockSizes
          filtered_size.size = this.Model_Stock.sizes[x].size;
          filtered_size.sales = this.Model_Stock.sizes[x].sales;
          filtered_size.sales_rank_all = this.Model_Stock.sizes[x].sales_rank_all;
          filtered_size.sales_rank_client = this.Model_Stock.sizes[x].sales_rank_client
          filtered_size.sales_share = this.Model_Stock.sizes[x].sales_share
          filtered_size.search_rank_all = this.Model_Stock.sizes[x].sales_rank_all;
          filtered_size.soh = this.Model_Stock.sizes[x].soh;
          filtered_size.dos = this.Model_Stock.sizes[x].dos;
          filtered_size.modelStock = this.Model_Stock.sizes[x].modelStock;
          filtered_size.suggested_modelStock = this.Model_Stock.sizes[x].suggested_modelStock;
          filtered_size.four_week_demand = this.Model_Stock.sizes[x].four_week_demand;
          filtered_size.lines = this.Model_Stock.sizes[x].lines;
          filtered_size.alerts = this.Model_Stock.sizes[x].alerts;
          model_filtered.modelStock += filtered_size.modelStock;
          model_filtered.suggested_modelStock += filtered_size.suggested_modelStock;
          model_filtered.soh += filtered_size.soh;
          model_filtered.sales += filtered_size.sales;
          // model_filtered.daysOfStock += filtered_size.dos;
          if (filtered_size.sales != 0 && filtered_size.soh != 0){
            model_filtered.daysOfStock += (filtered_size.soh/(filtered_size.sales/this.daysofdata*1.0));
          }
          model_filtered.sizes.push(filtered_size);
        }
      }
    }

    
    model_filtered.daysOfStock = Math.round(model_filtered.soh/(model_filtered.sales/this.daysofdata*1.0));
    this.Model_Stock = model_filtered;
    this.filter_applied = true;
  }
  this.isLoading = false;
}

clear_filters(){
  this.isLoading = true;
  (document.getElementById('filterA') as HTMLInputElement).value= '0';
  (document.getElementById('filterB') as HTMLInputElement).value= '0';
  (document.getElementById('filterC') as HTMLInputElement).value= '0';
  this.Model_Stock = this.Model_Stock_Base;
  this.filter_applied = false;
  this.isLoading = false;
}


download_TS(){
  this.isLoading = true;
  this.killTime = true;
  this.nextJoke();
  setTimeout(()=> {
    this.setTabletoContent(this.ModelTyresData.length);
  },1000);
  // this.setTabletoContent(this.ModelTyresData.length);
  // for(let cnt = 0; cnt < this.ModelTyresData.length;cnt++){
  //   document.documentElement.scrollTop = document.documentElement.scrollHeight;
  // }
  setTimeout(()=> {
   
    let element = document.getElementById('AllItems_Set');
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, {raw:true});
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    let filename = this.auth.user.client_name;
    filename = filename+' Tyre Statistics.xlsx';
    XLSX.writeFile(wb, filename, {bookType:'xlsx',type:'buffer'});
    this.isLoading = false;
    this.killTime = false;
  },1000)
}
pageSize = 50;
@HostListener("window:scroll", [])
  onScroll(): void {
  if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight - (document.body.scrollHeight * 0.1)) {
          // you're at the bottom of the page
          this.pageSize += 10;
          // this.input.inputValue = this.input.inputValue;
      }
  }
  resetTable(itemSize){
      this.pageSize = 50;
  }
  setTabletoContent(arrSize){
    this.pageSize = arrSize;
  }



// update_dt(stockreplenishmentId: number){
//   this.StockModelsService.update_date(stockreplenishmentId).subscribe(res => {
//     let dt = res as string
//   },err=>{
//     this.notification.showError(err);
//   })

// }



//#region 6 MONTH SALES DATA

//variables
public isLoadingSalesPerMsfid = false;
public isMsfidSalesModalVisible = false;
public tyreSalesTrend: SixMonthSalesTrend;

public isLoadingSalesPerGencode = false;
public isGencodeSalesModalVisible = false;
public gencodeSalesTrend: SixMonthSalesTrend;
//Functions

viewSalesTrendTyre(msfid: number) {
  this.isMsfidSalesModalVisible = true;
  this.isLoadingSalesPerMsfid = true;
  this.StockModelsService.salesTrendForTyre(msfid).subscribe(
    val => {
      this.tyreSalesTrend = val;
      this.isLoadingSalesPerMsfid = false;
    },
    err => {
      this.notification.handleError(err);
      this.isLoadingSalesPerMsfid = false;
      this.isMsfidSalesModalVisible = false;
    }
  )
}

viewSalesTrendGencode(gencode: string) {
  this.isGencodeSalesModalVisible = true;
  this.isLoadingSalesPerGencode = true;
  this.StockModelsService.salesTrendForGencode(gencode).subscribe(
    val => {
      this.gencodeSalesTrend = val;
      this.isLoadingSalesPerGencode = false;
    },
    err => {
      this.notification.handleError(err);
      this.isLoadingSalesPerGencode = false;
      this.isGencodeSalesModalVisible = false;
    }
  )
  
}

getMonthName(m: number) {
  const date = new Date();
  date.setMonth(m - 1);

  return date.toLocaleString('en-US', { month: 'short' });
}

private getTrendPercentage(a: number, b: number) : string {
  if (a === 0  && b === 0) return "0 units (0%)"
  if (a === 0) return "+" + b.toString() + ' units (+100%)';
  if (b === 0) return "-" + a.toString() + ' units (-100%)';
  let sign: string = b-a > 0 ? "+" : "";
  const percent = Math.round(((b * 100 / a) - 100) * 10) / 10;
  return sign + (b-a).toString() + " units (" + sign + (percent).toString() + "%)";
}

getMonthTrend(item: SixMonthSalesTrend): string {
  return this.getTrendPercentage(item.sales[2].qty, item.sales[1].qty)
  
}

getQuarterTrend(item: SixMonthSalesTrend): string {
  return this.getTrendPercentage(
    item.sales[4].qty + item.sales[5].qty + item.sales[6].qty, 
    item.sales[1].qty + item.sales[2].qty + item.sales[3].qty)
}

getMonthRange(item: SixMonthSalesTrend) : string {
  return this.getMonthName(item.sales[1].month) + " vs " + this.getMonthName(item.sales[2].month)
}

getQuarterRange(item: SixMonthSalesTrend) : string {
  return this.getMonthName(item.sales[3].month) + " - " + this.getMonthName(item.sales[1].month) + " vs " + this.getMonthName(item.sales[6].month) + " - " + this.getMonthName(item.sales[4].month)
}

//#endregion

//#region AI 
 
isModalLoading:boolean = false;
isLoadingBrandsTable:boolean = false;
isBrandModalVisable:boolean = false;
isloadingAISuggestion = false;
isAiModalClosable= false;
sfBrands:mslBrand[] = [];
clientBrands:mslBrand[] = [];
autoCompleteBrands:mslBrand[] = [];
searchedBrand:string = "";
suggestedMSL:number = 0
suggestedSKU: number = 0


aiForm: FormGroup = new FormGroup({
  unitsSold: new FormControl(null, [Validators.required,Validators.minLength(1),Validators.pattern('^[0-9]+$')]),
  capital: new FormControl(null, Validators.required),
  conversion: new FormControl(null, [Validators.required,Validators.minLength(1),Validators.pattern('^[0-9]+$')]),
  daysofStock:new FormControl(null, [Validators.required,Validators.minLength(1),Validators.maxLength(300),Validators.pattern('^[0-9]+$')]),
  replenishmentCycle:new FormControl(null, [Validators.required,Validators.minLength(1),Validators.maxLength(100),Validators.pattern('^[0-9]+$')]),
  pushMarketSales:new FormControl(null, [Validators.required,Validators.minLength(1),Validators.maxLength(300),Validators.pattern('^[0-9]+$')]),
  pushProfit: new FormControl(null, Validators.required),
  pushCheap: new FormControl(null, Validators.required),
  supplierSOH:new FormControl(null, Validators.required)    
});


getBrands()
{
  this.isLoadingBrandsTable = true;
  this.StockModelsService.getBrands().subscribe(res => {
    this.sfBrands = [];
    this.clientBrands = [];
    this.sfBrands = res.sfBrands;
    this.clientBrands = res.clientBrands;
    this.clientBrands.forEach(brand => {
      brand.sliderValue = this.popularityToSlider(brand.popularityAdvantage);
    });

    this.setAiFormDefaults(res.aiSettings);

    this.isLoadingBrandsTable = false;
  },
  err => {
    this.notification.handleError(err);
    this.isLoadingBrandsTable = false;
  });
}

setAiFormDefaults(res:mslSettings)
{
    // Set Form Defaults
    if(res.units_sold == null)
      res.units_sold = 1;
    if(res.monthly_units_stocking_cut_off == null)
      res.monthly_units_stocking_cut_off = 1;
    if(res.conversion == null)
      res.conversion = 10;
    if(res.days_of_stock == null)
      res.days_of_stock = 30;
    if(res.replenishment_cycle == null)
      res.replenishment_cycle = 5;
    if(res.push_market_sales == null)
      res.replenishment_cycle = 100;
    if(res.push_profit == null)
      res.push_profit = 0;
    if(res.push_cheap == null)
      res.push_cheap = 1;
    if(res.supplier_soh == null)
      res.supplier_soh = 0;


    this.aiForm.controls.unitsSold.setValue(res.units_sold); 
    this.aiForm.controls.capital.setValue(this.recalculateMonthlyUnitsSold(res.monthly_units_stocking_cut_off)); 
    this.aiForm.controls.conversion.setValue(res.conversion); 
    this.aiForm.controls.daysofStock.setValue(res.days_of_stock); 
    this.aiForm.controls.replenishmentCycle.setValue(res.replenishment_cycle); 
    this.aiForm.controls.pushMarketSales.setValue(res.push_market_sales);
    this.aiForm.controls.pushProfit.setValue(res.push_profit); 
    this.aiForm.controls.pushCheap.setValue(res.push_cheap); 
    this.aiForm.controls.supplierSOH.setValue(res.supplier_soh); 
}

sliderToPopularity(sliderValue: number): number {
  let minSlider = -100;
  let maxSlider = 100;
  let minPopularity = -200;
  let maxPopularity = 200;
  let normalized = (sliderValue - minSlider) / (maxSlider - minSlider);
  let popularityValue = normalized * (maxPopularity - minPopularity) + minPopularity;
  return Math.round(popularityValue);
}

popularityToSlider(popularityValue: number): number {
  let minSlider = -100;
  let maxSlider = 100;
  let minPopularity = -200;
  let maxPopularity = 200;
  let normalized = (popularityValue - minPopularity) / (maxPopularity - minPopularity);
  let sliderValue = normalized * (maxSlider - minSlider) + minSlider;
  return Math.round(sliderValue);
}

sliderToCostAdvantage(sliderValue: number): number {
  let minSlider = -100;
  let maxSlider = 100;
  let minCostAdvantage = -30;
  let maxCostAdvantage = 30;
  let normalized = (sliderValue - minSlider) / (maxSlider - minSlider);
  let costAdvantageValue = normalized * (maxCostAdvantage - minCostAdvantage) + minCostAdvantage;
  return Math.round(costAdvantageValue); 
}

calculateMonthlyUnitsSold(value:number): number
{
      if(value == 1)
      {
        return 3.00 
      }
      if(value == 2)
      {
        return 2 
      }
      if(value == 3)
      {
        return 1.00 
      }
      if(value == 4)
      {
        return 0.80 
      }
      if(value == 5)
      {
        return 0.50 
      }
      return 1.00
}

recalculateMonthlyUnitsSold(value:number): number
{
      if(value == 3.00)
      {
        return 1 
      }
      if(value == 2)
      {
        return 2 
      }
      if(value == 1.00)
      {
        return  3
      }
      if(value == 0.80)
      {
        return  4
      }
      if(value == 0.50)
      {
        return  5
      }
      return 3
}

onBrandModalClick()
{
  this.isModalLoading = true;
  this.isBrandModalVisable = true;
  this.isModalLoading = false;
}

searchBrands($event:any)
{
  let OmmitBrands = [] as number[];
  this.clientBrands.forEach(b => {
    OmmitBrands.push(b.brandId);
  })

  if($event != null && $event != undefined && $event != '')
  {
    this.autoCompleteBrands = this.sfBrands.filter(x => (x.name.toLowerCase().includes($event.target.value.toLowerCase())) && !OmmitBrands.includes(x.brandId));
  }
}

selectBrand($event:any)
{
  
  if($event != null && $event != undefined && $event != '')
  {
    let sfBrand = this.sfBrands.find( x=> x.name.toLowerCase() == this.sfBrands.find(x => x.name == $event)?.name!.toLowerCase())
    let newBrand = new mslBrand();
    newBrand.brandId = sfBrand?.brandId!;
    newBrand.popularityAdvantage = 0;
    newBrand.sliderValue = 0;
    newBrand.costAdvantage = 0
    newBrand.clientId = this.auth.user.client_id;   
    if(newBrand.brandId != undefined){
      this.isLoadingBrandsTable = true;
      this.StockModelsService.upsertBrands(newBrand).subscribe(res => {
          this.clientBrands = [];
          this.clientBrands = res;
          this.clientBrands.forEach(brand => {
            brand.sliderValue = this.popularityToSlider(brand.popularityAdvantage);
          })
          this.notification.showSuccess("Added Successfully");
          this.isLoadingBrandsTable = false;
          this.autoCompleteBrands = [];
          this.searchedBrand = "";
      });
    }
  }
}

updateBrand(brand:mslBrand)
{
  brand.popularityAdvantage = this.sliderToPopularity(brand.sliderValue);
  brand.costAdvantage = this.sliderToCostAdvantage(brand.sliderValue);
  this.StockModelsService.upsertBrands(brand).subscribe(res => {
  this.clientBrands = [];
  this.clientBrands = res;
  this.clientBrands.forEach(brand => {
    brand.sliderValue = this.popularityToSlider(brand.popularityAdvantage);
  })
  this.isLoadingBrandsTable = false;
  });
}

deleteBrand(brand:mslBrand)
{
  this.isLoadingBrandsTable = true;
  this.StockModelsService.deleteBrands(brand).subscribe(res => {
    this.clientBrands = [];
    this.clientBrands = res;
    this.isLoadingBrandsTable = false;
    this.notification.showSuccess("Removed Successfully");
  });
}

TooltipIcon: NzFormTooltipIcon = {
  type: 'info-circle',
  theme: 'outline'
};

runAI()
{
  if (this.aiForm.invalid)
  {
    Object.values(this.aiForm.controls).forEach(control => {
      if (control.invalid)
      {
        control.markAsDirty();
        control.updateValueAndValidity({ onlySelf: true });
      }
    });
    return;
  }
  else
  {
    // Format Model

    let settings = new mslSettings();
    settings.clientId = this.auth.user.client_id;
    settings.units_sold = this.aiForm.controls.unitsSold.value;
    settings.days_of_stock = this.aiForm.controls.daysofStock.value;
    settings.push_cheap = this.aiForm.controls.pushCheap.value;
    settings.monthly_units_stocking_cut_off = this.calculateMonthlyUnitsSold(this.aiForm.controls.capital.value);
    settings.conversion = this.aiForm.controls.conversion.value;
    settings.replenishment_cycle = this.aiForm.controls.replenishmentCycle.value;
    settings.push_market_sales = this.aiForm.controls.pushMarketSales.value;
    settings.push_profit = this.aiForm.controls.pushProfit.value;
    settings.supplier_soh = this.aiForm.controls.supplierSOH.value;   
            
    settings.datasetId = 0
    settings.dataset_days = 28
    settings.fullset_advantage = 1;
    settings.active = 1;
    settings.supplierId = 211; 

    // Toggle Display
    this.isBrandModalVisable = false;
    this.isloadingAISuggestion = true;
    this.isAiModalClosable = false;

    // Response
    this.StockModelsService.runAi(settings).subscribe((res:any) => {
      this.suggestedMSL = res.msl_suggested;
      this.suggestedSKU = res.skus;
      this.isAiModalClosable = true; 
    },
    error => {
      this.notification.handleError(error);
      this.isAiModalClosable = true; 
    });
  }
}

//#endregion

//#region Pie Chart
  nzTabs = [{index:0 ,title:"Ai Model Stock"},{index:1 ,title:"Model Stock"},{index:2 ,title:"Stock On Hand"}]
  selectedTab:number = 0;
  selectedGenCode:number = 0;
  isPieChartLoading:boolean = false;
  isPieChartModal:boolean = false;
  dataUnits: pieChartModel[] = [];

  pieChart: Chart;
  pieChartLabels: string[] = [];
  pieChartData: ChartDataset[] = [
    {
      label:"Market Share",
      data: [],
      backgroundColor: [], 
      hoverBackgroundColor:[],
      borderWidth: 0, 
      hoverOffset: 4
    }
  ];
  pieChartType: string = 'doughnut';
  pieChartOptions: any = {
    responsive: true,
    legend: {
      position: 'top',
    },
    animation: {
      animateScale: true,
      animateRotate: true
    }
  };


  fetchPieChartData()
  {
    this.isPieChartLoading = true;
    this.StockModelsService.getPieChartData(this.selectedGenCode).subscribe(res => {
      this.dataUnits = res;

      // Clear Arrays
      let colors = [] as string[];
      let data = [] as number[];
      this.pieChartLabels = [];
      this.pieChartData[0].data = [];
      this.pieChartData[0].backgroundColor = [];
      this.pieChartData[0].hoverBackgroundColor = [];

      // Load Data
      this.dataUnits.forEach((unit,index) => {
         this.pieChartLabels.push(unit.brand);
         colors.push(unit.color_code)
         if(this.selectedTab == 0)
         {
          data.push(unit.ai_model_stock)
         }
         else if (this.selectedTab == 1)
         {
          data.push(unit.model_stock)
         }
         else
         {
          data.push(unit.soh)
         }
      });
      
      // Initialize Chart
      this.pieChartData[0].data = data;
      this.pieChartData[0].backgroundColor = colors;
      this.pieChartData[0].hoverBackgroundColor = colors;

      if (this.pieChart) {
        this.pieChart.update();
      }

      this.isPieChartLoading = false;
    },
    err => {
      this.notification.handleError(err);
      this.isPieChartLoading = true;
    });
  }


  displayChart(gencode:number,selectedIndex:number)
  {
    this.selectedTab = selectedIndex;
    this.selectedGenCode = gencode;
    this.fetchPieChartData();
    this.isPieChartModal = true;
  }

  reloadChart(index:number)
  {
    this.selectedTab = index;
    this.fetchPieChartData(); 
  }




 



   
//#endregion

}


