import { Component, Input, OnInit, inject } from '@angular/core';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modal-content',
  templateUrl: './modal-content.component.html',
  styleUrls: ['./modal-content.component.less']
})
export class ModalContentComponent implements OnInit {

  constructor() { }

  @Input() notiType: resultType;

  readonly nzModalData = inject(NZ_MODAL_DATA);

  public localResultType = resultType;

  ngOnInit(): void {
    this.notiType = this.nzModalData.notiType;
  }

}

export enum resultType {
  Success,
  Information,
  Warning,
  Error
}
