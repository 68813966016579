import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Replenishments_lines } from '../models/replenish';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ReplenishmentService {
  apiUrl = 'v1/replenishments/';
  

  constructor(private http: HttpClient) { }

  //Get Client Sales
  public replenishCentres(id)
  { 

    return this.http.get( environment.ApiUrl() + this.apiUrl + "getreplenishments/"+id);
  }




  
  public getBranches(client)
  {
    return this.http.get(environment.ApiUrl() + this.apiUrl + "getBranches/"+client );
  }

  public placeRepOrder(orderList: Replenishments_lines[])
  {

    return this.http.post(environment.ApiUrl() + this.apiUrl + "completeRepOrder/", orderList);
  }

  public getUser()
  {
    
    return this.http.get<any>(environment.ApiUrl() + this.apiUrl + "getUserDetails");
  }

  
  



}
