import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Client_Model } from '../models/procurement';


@Injectable({
  providedIn: 'root'
})
export class ProcurementService {
  apiUrl = 'v1/procurement_maintenance/';

  constructor(private http: HttpClient) { }

  public get_all_replenishment_clients(){
   
    return this.http.get<Client_Model[]>(environment.ApiUrl() + this.apiUrl + "get_all_replenishment_clients");
  }
}
