import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomerVehicleDetails, Quote } from 'src/app/classes/quote';
import { PosService } from '../pos.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { PosAuditService } from '../posaudit.service';
import { wbcQuote } from 'src/app/models/pos';
import { resultType } from 'src/app/shared/modal-content/modal-content.component';

@Component({
  selector: 'app-pos-wbc',
  templateUrl: './pos-wbc.component.html',
  styleUrls: ['./pos-wbc.component.less']
})
export class PosWbcComponent implements OnInit {

  @Input() quote : Quote = new Quote();
  @Output() quoteChange = new EventEmitter<Quote>();
  @Input() isLoading: boolean;
  constructor(private posService: PosService, private notification: NotificationService, private auditService:PosAuditService) { }
  

  wbcForm: FormGroup = new FormGroup({
    name: new FormControl(null,Validators.required),
    surname: new FormControl(null,Validators.required),
    email: new FormControl(null,[Validators.required,Validators.email]),
    cell: new FormControl(null,Validators.required),
    vehicleMilage: new FormControl(null,[Validators.required, Validators.min(1)]),
    vehicleCondition: new FormControl(null,Validators.required),
    vehicleReg:new FormControl(null,Validators.required),
    vehicleYear: new FormControl(null,Validators.required),
    vehicleMake: new FormControl(null,Validators.required),
    vehicleModel: new FormControl(null,Validators.required),
    vehicleVariant: new FormControl(null,Validators.required)
  });
  
  isVRegSearchDropDownOpen = false;
  selectedVehicle: CustomerVehicleDetails = new CustomerVehicleDetails();
  searchedVReg = "";
  isWbcModal = false;
  vehicleCategory:string[] =  ['other','vehicle','motorbike','caravan','trailer','boat'];
  vehicleCondition:string[] = ['Excellent','Good','Average','Below Average','Very Poor'];
  vehicleConditionselect:string = '';


  ngOnInit(): void {
  }

  showWbcModal()
  {
    this.selectedVehicle = this.quote.customerVehicles.find(x=> x.Vehicle_Customer_MappingId == this.quote.Vehicle_Customer_MappingId)!;
    this.wbcForm.controls["name"].setValue(this.quote.custName.split(" ")[0]);
    this.wbcForm.controls["surname"].setValue(this.quote.custName.split(" ")[1]);
    this.wbcForm.controls["email"].setValue(this.quote.custEmail);
    this.wbcForm.controls["cell"].setValue(this.quote.custCell);
    this.wbcForm.controls["vehicleReg"].setValue(this.selectedVehicle.v_reg);
    this.wbcForm.controls["vehicleYear"].setValue(this.selectedVehicle.modelYear);
    this.wbcForm.controls["vehicleMilage"].setValue(this.selectedVehicle.odoReading);
    this.wbcForm.controls["vehicleMake"].setValue(this.selectedVehicle.vehicleMake);
    this.wbcForm.controls["vehicleModel"].setValue(this.selectedVehicle.vehicleModel);
    this.wbcForm.controls["vehicleVariant"].setValue(this.selectedVehicle.vehicleVariant);
    this.vehicleConditionselect = 'Good';
    this.isWbcModal = true;
  }

  sendWBCquote()
  {
    if (!this.wbcForm.valid) 
    {
      Object.values(this.wbcForm.controls).forEach(control => {
        if (control.invalid) 
        {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
        return;
      });
    }
    else
    {
      let wbcQuoteDetails: wbcQuote = new wbcQuote();
      wbcQuoteDetails.vehicleCategory = "vehicle";
      wbcQuoteDetails.reference = "StockFinder-" + this.quote.quoteId;   
      wbcQuoteDetails.name = this.wbcForm.value.name;
      wbcQuoteDetails.surname = this.wbcForm.value.surname;
      wbcQuoteDetails.email = this.wbcForm.value.email;
      wbcQuoteDetails.mobilePhone = this.wbcForm.value.cell;
      wbcQuoteDetails.vehicleReg = this.wbcForm.value.vehicleReg;
      wbcQuoteDetails.vehicleMilage = this.wbcForm.value.vehicleMilage;
      wbcQuoteDetails.vehicleCondition = this.vehicleConditionselect;
      wbcQuoteDetails.vehicleYear = this.wbcForm.value.vehicleYear;
      wbcQuoteDetails.customerId = this.quote.custId;
      wbcQuoteDetails.vehicleMake = this.wbcForm.value.vehicleMake;
      wbcQuoteDetails.vehicleModel = this.wbcForm.value.vehicleModel; 
      wbcQuoteDetails.vehicleVariant = this.wbcForm.value.vehicleVariant; 
      wbcQuoteDetails.vehicleVariantId = this.selectedVehicle.vehicleVariantId;

      this.posService.WbcQuote(wbcQuoteDetails).subscribe(res => {
        this.isWbcModal = false;
        this.wbcForm.reset();
        this.notification.ShowAlert({type: resultType.Success, 
          title: "WBC Quote Requested Successfully", 
          htmlMessage: 'Please notify Customer to monitor their emails and SMS for contact from WBC' ,
          maskClosable: false,
          btnText: 'OK'});
        this.isWbcModal = false;
      },
      error => {
        this.notification.handleError(error);
      });    
    }
  }

}
