import { Component, HostListener, OnInit } from '@angular/core';
import { AdminMappingService } from './admin-mappings.service';
import { NotificationService } from '../shared/notification.service';
import { AuthService } from '../auth.service';
import { UnMappedStock, ClientInfoForAdminStockMappings, MappedStock, CustomStockToBeMapped, StockType } from '../models/admin-mappings';
import { Input } from '../models/tyres';
import { ModalMappingComponent } from '../widgets/modal-mapping/modal-mapping.component';

@Component({
  selector: 'app-admin-stock-mappings',
  templateUrl: './admin-stock-mappings.component.html',
  styleUrls: ['./admin-stock-mappings.component.less']
})
export class AdminStockMappingsComponent implements OnInit {

  // Containing objects
  clientList: ClientInfoForAdminStockMappings[] = [];
  clientListTemp: ClientInfoForAdminStockMappings[] = [];
  unMappedStock: UnMappedStock[] = [];
  unMappedStockTemp: UnMappedStock[] = [];
  unMappedStockVeryTemp : UnMappedStock[] = [];
  filterTerm : string = '';
  currentClientName: string = '';
  currentClientId: number = 0;
  // Client row selection
  selectedClient: ClientInfoForAdminStockMappings;

  // Loading flags
  isAllClients = false;
  isMapping = false;
  isLoadingStock = false;
  isLoadingClients = false;
  isLoadingMappedStock = false;

  mappedStockRetrieved = false;



  customStockTypes: StockType[] =[]

  input: Input = new Input();
  pageSize: number = 20;
  
  mappedStock: MappedStock[] = [];
  mappedStockTemp: MappedStock[] = [];
  mappedStockVeryTemp: MappedStock[] = [];

  tabIndex: number = 0;
  
columnList= [
    {
      title: 'Stock on Hand',
      sortOrder: 'descend',
      sortFn: (a: UnMappedStock, b: UnMappedStock) => (a.soh ?? 0) - (b.soh ?? 0),
      sortDirections: ['ascend', 'descend', null],
    }

  ]
  constructor(
    public stockMappingService: AdminMappingService,
    private notifcation: NotificationService, 
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.clientList = [];
    this.getClients();
    this.mappedStock = [];
    this.input.inputValue = '';
    this.input.inputList = ["locationStockCode", "msfid", "msfDescription", "msfStockCode", "stock_code"];
  }


  resetTable() {
    this.pageSize = 20;
  }

  getClients() {
    this.isLoadingClients = true;
    this.stockMappingService.getClients().subscribe((res: ClientInfoForAdminStockMappings[]) => {
      this.clientList = res;
      this.clientList.sort((a, b) => b.unmappedStock - a.unmappedStock);
      this.isLoadingClients = false;
    }, error => {
      this.notifcation.handleError(error);
      this.isLoadingClients = false;
    });   
  }

  onClientChange(client: ClientInfoForAdminStockMappings) {
    this.mappedStockRetrieved = false;
    this.tabIndex = 0;
    this.mappedStock = [];
    this.stockMappingService.currentClientId = client.clientId;
    this.stockMappingService.currentClientName = client.name;
    this.getUnmappedStock(client);
    this.getMappedStock(client.clientId);
  }

  getUnmappedStock(client: ClientInfoForAdminStockMappings) {
    if (client.clientId == 0) {
      this.isAllClients = true;
    } else {
      this.isAllClients = false;
    }
    this.isLoadingStock = true;
    this.stockMappingService.getUnmappedStock(client).subscribe((res : UnMappedStock[]) => {
      
      this.unMappedStockTemp = res;
      this.unMappedStock = this.unMappedStockTemp;
      this.currentClientId = this.selectedClient.clientId;
      this.currentClientName = this.selectedClient.name;
      
      this.isLoadingStock = false;
    }, error => {
      this.notifcation.handleError(error);
      this.isLoadingStock = false;
    })

  }

  getMappedStock(clientId: number) {
    if (clientId == 0 && this.isMapping) {
      this.isAllClients = true;
      this.notifcation.showInfo("Please select a client to view mapped stock");
    } else {
      this.isLoadingMappedStock = true;
      this.stockMappingService.getMappedStock(clientId).subscribe((res: MappedStock[]) => {
        this.mappedStockTemp = res.filter(stock => stock.msfStockCode !== stock.locationStockCode); //remove items that have matching msf stock code and location stock code - Requierement 5223
        this.mappedStock = this.mappedStockTemp
        this.isLoadingMappedStock = false;
        this.mappedStockRetrieved = true;
      } , error => {
        this.isLoadingMappedStock = false;
        this.notifcation.handleError(error);
      });
    }
  }

  mapCustomStock(event: MouseEvent, unmappedStockItem: UnMappedStock, msfIdOfExistingStock: number, stockTypeId: number, mapType: number) {
    event.stopPropagation();
    this.isMapping = true;
    if (this.mappedStock.some(stock => stock.msfStockCode === unmappedStockItem.stockCode)) {
      this.notifcation.showInfo("This stock item is already mapped.");
      this.isMapping = false;
      return;
    }
    const customStockItem: CustomStockToBeMapped = {
      clientId: unmappedStockItem.clientId,
      description: unmappedStockItem.description,
      msfid: msfIdOfExistingStock,
      stockCode: unmappedStockItem.stockCode,
      stockTypeId: stockTypeId,
      cost: unmappedStockItem.cost,
      soh: unmappedStockItem.soh
    };

    this.stockMappingService.mapCustomStock(customStockItem).subscribe(res => {
      this.notifcation.showSuccess("Changes saved successfully");
      this.refreshUnMappedStockTable(unmappedStockItem, unmappedStockItem.clientId);
      this.isMapping = false;
    }, error => {
      this.notifcation.handleError(error);
      this.isMapping = false;
    });
  }

  mapUnmappableStock(event: MouseEvent, unmappedStockItem: UnMappedStock, msfIdOfExistingStock: number, stockTypeId: number, mapType: number) {
    event.stopPropagation();
    this.isMapping = true;
    const customStockItem: CustomStockToBeMapped = {
      clientId: unmappedStockItem.clientId,
      description: unmappedStockItem.description,
      msfid: msfIdOfExistingStock,
      stockCode: unmappedStockItem.stockCode,
      stockTypeId: stockTypeId
    };
    this.stockMappingService.mapUnmappableStock(customStockItem).subscribe(res => {
      this.notifcation.showSuccess("Changes saved successfully");
      this.refreshUnMappedStockTable(unmappedStockItem, unmappedStockItem.clientId);
      // this.refreshNatMinmsfTable(unmappedStockItem, mapType);
      this.isMapping = false;
    }, error => {
      this.notifcation.handleError(error);
      this.isMapping = false;
    });
  }

  openMappingModal(stockItem: UnMappedStock) {
    this.stockMappingService.openModal(stockItem);
    
  }

  setAdvancedFilterInput(input: string) {
    this.input.inputValue = input;
    this.filterStockAdvancedly(input);
  }

  setAdvancedFilterInputMapped(input: string) {
    this.input.inputValue = input;
    this.filterStockAdvancedlyMapped(input);  
  }

  switchToMapped() {
    if (!this.selectedClient || this.selectedClient.clientId == 0) {
      this.notifcation.showInfo("Please select a client to view mapped stock");
    } else if (this.selectedClient.clientId != 0 && !this.mappedStockRetrieved) {
      this.getMappedStock(this.selectedClient.clientId);
      this.mappedStockRetrieved = true;
    }
  }

  deleteMapping(item: MappedStock) {
    this.stockMappingService.deleteMapping(item).subscribe(res => {
      this.notifcation.showSuccess("Mapping Deleted Successfully");
      this.refreshMappedStock(item);
    }, error => {
      this.notifcation.handleError(error);

    });
  }

  // reUnmapStock(item: UnMappedStock) {
  //   this.isLoadingStock = true;
  //   item.mapped = 0;
  //   this.stockMappingService.reUnmapStock(item).subscribe(res => {
  //     this.notifcation.showSuccess("Stock Unmapped Successfully");
  //     this.refreshUnmappedStockTable(item);
  //     this.isLoadingStock = false;
  //   }, error => {
  //     this.notifcation.handleError(error);
  //     this.isLoadingStock = false;
  //   });
  // }



  filterMappedStock() {  
      return this.mappedStock;
  }


  dropdownClicked(event: MouseEvent) {
    event.stopPropagation();
  }

  filterStockAdvancedly(searchTerm: string) {
    this.filterTerm = searchTerm;
    this.unMappedStockVeryTemp = this.unMappedStockTemp;
    this.unMappedStockVeryTemp = this.unMappedStockVeryTemp.filter(stock => stock.description.toLowerCase().includes(searchTerm.toLowerCase()));
    this.unMappedStock = this.unMappedStockVeryTemp;

    if (searchTerm === "") {
        this.unMappedStock = this.unMappedStockTemp;
    }
}

  filterStockAdvancedlyMapped(searchTerm: string) {
    this.filterTerm = searchTerm;
    this.mappedStockVeryTemp = this.mappedStockTemp;
    this.mappedStockVeryTemp = this.mappedStockVeryTemp.filter(stock => stock.msfDescription.toLowerCase().includes(searchTerm.toLowerCase()));
    this.mappedStock = this.mappedStockVeryTemp;

    if (searchTerm === "") {
        this.mappedStock = this.mappedStockTemp;
    }
  }

  public refreshUnMappedStockTable(unmappedStockItem: UnMappedStock, clientId: number) {
    this.unMappedStockTemp = this.unMappedStockTemp.filter(item => item.stockCode !== unmappedStockItem.stockCode);
    this.unMappedStock = this.unMappedStockTemp.slice(); // refresh the filtered stock array

    const currentClientIndex = this.clientList.findIndex(client => client.clientId === clientId);
    if (currentClientIndex) {
      this.clientList[currentClientIndex].unmappedStock = this.unMappedStock.length;
    }
    this.setAdvancedFilterInput(this.input.inputValue)
  }

  // refreshNatMinmsfTable(unmappedStockItem: UnMappedStock, mapType: number) {
  //   const index = this.unMappedStockTemp.findIndex(item => item.stockCode === unmappedStockItem.stockCode);
  //   if (index !== -1) {
  //       this.unMappedStockTemp[index].mapped = mapType;
  //       this.unMappedStockTemp = [...this.unMappedStockTemp]; // refresh the unmapped stock array
  //       this.unMappedStockTemp = this.unMappedStockTemp;
  //   }
  // }

  refreshMappedStock(mappedItem: MappedStock) {
    const index = this.mappedStockTemp.findIndex(item => item.mappingId === mappedItem.mappingId);
    if (index !== -1) {
        this.mappedStockTemp.splice(index, 1);
        this.mappedStockTemp = this.mappedStockTemp.slice();
        this.mappedStock = this.mappedStockTemp;
    }
  }

  // refreshUnmappedStockTable(stock: UnMappedStock) {
  //   const index = this.unMappedStockTemp.findIndex(item => item.stockCode === stock.stockCode);
  //   if (index !== -1) {
  //       this.unMappedStockTemp[index] = stock;
  //       this.unMappedStockTemp = [...this.unMappedStockTemp]; // refresh the unmapped stock array
  //       this.unMappedStockTemp = this.unMappedStockTemp;
  //   }
  // }

}
