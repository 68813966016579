import { Component, OnInit } from '@angular/core';
import { Leads } from '../../models/leads';
import { LeadsService } from '.././leads.service';
import { Router } from '@angular/router';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-upcoming',
  templateUrl: './upcoming.component.html',
  styleUrls: ['./upcoming.component.less']
})
export class UpcomingComponent implements OnInit {

 //Global Var
 upcomingleads: Leads[];

 custSortFn = (a: Leads[], b: Leads[]): number => a['customername'].toLowerCase() < b['customername'].toLowerCase() ? -1 : 1;
 stateSortFn = (a: Leads[], b: Leads[]): number => a['state'] < b['state'] ? -1 : 1;
 clientSortFn = (a: Leads[], b: Leads[]): number => a['clientname'].toLowerCase() < b['clientname'].toLowerCase() ? -1 : 1;
 dtSortFn = (a: Leads[], b: Leads[]): number => a['dt'] < b['dt'] ? -1 : 1;

 constructor(private leadsService : LeadsService,private router: Router, private notification: NotificationService) { }

 ngOnInit(): void {
  this.reset();
  this.leadsService.getLeads().subscribe(res => 
    {
      this.upcomingleads = res['upcomingleads'] as Leads[];  
    },
    error => {
      this.notification.handleError(error);       
    }); 
}

reset()
{
  this.upcomingleads=[];
}

viewquote(quoteId)
{
  this.router.navigate(["/pos/v2",quoteId])
}


}
