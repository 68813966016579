import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Leads, LeadsChart } from './../models/leads';
import { LeadsService } from '../leads/leads.service';
import { Router } from '@angular/router';
import { NotificationService } from '../shared/notification.service';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { Subscription } from 'rxjs';
import { ReportsService } from '../shared/reports.service';
import { AuthService } from '../auth.service';
import { SystemUser } from '../models/user';
import { UsersService } from '../users/users.service';
import { LeadsReportCommunication } from 'src/app/models/communication';
import { SendReportService } from '../widgets/send-report/send-report.service';
@Component({
  selector: 'app-leads',
  templateUrl: './leads.component.html',
  styleUrls: ['./leads.component.less'],
})
export class LeadsComponent implements OnInit, OnDestroy {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  //Global Var
  currentleads: Leads[];
  chartData: LeadsChart[] = [];
  tfs = 'past week';
  tf = -7;
  ch = 'All';

  users: SystemUser[]
  isLoadingUsers = false;

  private loadingLeadsSub: Subscription;

  constructor(public leadsService: LeadsService, private router: Router, private notification: NotificationService, private reportsService: ReportsService, public authService: AuthService, private userService: UsersService, private sendReportService: SendReportService) { }
  

  ngOnDestroy(): void {
    this.loadingLeadsSub.unsubscribe();
  }

  

  ngOnInit(): void {
    // this.reset();
    // this.leadsService.getLeads().subscribe(res => {
    //   this.currentleads = res['currentleads'] as Leads[];
    // },
    //   error => {
    //     this.notification.handleError(error);
    //   }
    // );
    this.isLoadingUsers = true;
    //Subscribe to the BehaviousSubject in the leads service to determine if we can update the leads.
    this.loadingLeadsSub = this.leadsService.updatingLeads.subscribe({
      next: val => {
        
        this.leadsService.isLoadingLeads = true;
        if (val === false) {
          // Get all the leads, this is open and future
          
          this.leadsService.getLeadsList().subscribe({
            next: res => {
              this.leadsService.leads = res;
              this.leadsService.isLoadingLeads = false;
            },
            error: error => {
              this.notification.handleError(error);
            }
          })

          //Get all the quotes, this will be ALL, top 100.
          this.leadsService.isLoadingQuotes = true;
          this.leadsService.getLeadQuoteList().subscribe({
            next: res => {
              this.leadsService.leadQuotes = res;
              this.leadsService.isLoadingQuotes = false;
            },
            error: error => {
              this.notification.handleError(error);
            }
          })
        }
      }
    })
    
    // Get the filters from storage
    let settingsString = localStorage.getItem("LeadsFilter")
    if (settingsString) {
      let setObj = JSON.parse(settingsString);
      this.leadsService.filterAssignedToMe = setObj.assignedToMe;
      this.leadsService.filterAssignedToOther = setObj.assignedToOther;
      this.leadsService.filterUnassigned = setObj.unassigned;
      this.leadsService.filterOnlyTentativelyAccepted = setObj.tentativelyAccepted;
      this.leadsService.filterOnlyBookings = setObj.bookings;
    } else {
      this.leadsService.filterAssignedToMe = true;
      this.leadsService.filterAssignedToOther = true;
      this.leadsService.filterUnassigned = true;
      this.leadsService.filterOnlyTentativelyAccepted = false;
      this.leadsService.filterOnlyBookings = false;
    }
    

    this.leadsService.getLeadschart(this.tf, this.ch).subscribe(res => {
      this.chartData = res['chartData'] as LeadsChart[];
      this.barChartData.labels = this.chartData['labels'];
      this.barChartData.datasets = [
        { data: this.chartData['centre'], label: 'Your Centre', backgroundColor: "rgba(231, 176, 25, 0.75)", hoverBackgroundColor: "rgba(231, 176, 25, 1)" },
        { data: this.chartData['national'], label: 'National', backgroundColor: "rgba(21, 142, 144, 0.75)", hoverBackgroundColor: "rgba(21, 142, 144, 1)" },
      ]
      this.chart?.update();
    },
      error => {
        this.notification.handleError(error);
      }
    );

    this.userService.getUsers().subscribe(res => {
      this.users = res;
      this.isLoadingUsers = false;
    },
      error => {
        this.notification.handleError(error);
        this.isLoadingUsers = false;
      }
    )
  }

  updateFilterSettings() {
    let setObj = {"assignedToMe": this.leadsService.filterAssignedToMe,
      "assignedToOther": this.leadsService.filterAssignedToOther,
      "unassigned": this.leadsService.filterUnassigned,
      "tentativelyAccepted": this.leadsService.filterOnlyTentativelyAccepted,
      "bookings": this.leadsService.filterOnlyBookings}
    localStorage.setItem("LeadsFilter",JSON.stringify(setObj));
    
  }

  reset() {
    this.currentleads = [];
  }


  viewquote(quoteId) {
    this.router.navigate(["/pos/v2", quoteId])

  }

  // Chart
  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    aspectRatio: 4,
    scales: {
      x: {
        ticks: {
          maxRotation: 100,
          font: { size: 10 }
        }
      },
      y: {
        grid: { color: 'rgba(100,100,100,0.5)' },
        min: 0
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = '';
            if (context.parsed.y !== null) {
              label = new Intl.NumberFormat().format(context.parsed.y) + '%';
            }
            return label;
          }
        }
      },
      legend: {
        display: true,
        title: {
          display: true,
          text: 'Closed quote reasons (%)',
          font: { size: 25 }
        }
      },
    }
  };
  public barChartType: ChartType = 'bar';
  public barChartPlugins = [
  ];

  public barChartData: ChartData<'bar'> = {
    labels: [],
    datasets: []
  }

  // events
  public chartClicked({ event, active }: { event?: ChartEvent, active?: {}[] }): void {

  }

  public chartHovered({ event, active }: { event?: ChartEvent, active?: {}[] }): void {

  }


  changeTimeFrame(timeFrameDropDown): void {
    if (timeFrameDropDown == -7) {
      this.tfs = 'past week'
    }
    else if (timeFrameDropDown == -30) {
      this.tfs = 'past month'
    }
    else if (timeFrameDropDown == -90) {
      this.tfs = 'past 3 months'
    }
    this.tf = timeFrameDropDown;
    this.leadsService.getLeadschart(this.tf, this.ch).subscribe(res => {
      this.chartData = res['chartData'] as LeadsChart[];
      this.barChartData.labels = this.chartData['labels'];
      this.barChartData.datasets = [
        { data: this.chartData['centre'], label: 'Your Centre', backgroundColor: "rgba(231, 176, 25, 0.75)", hoverBackgroundColor: "rgba(231, 176, 25, 1)" },
        { data: this.chartData['national'], label: 'National', backgroundColor: "rgba(21, 142, 144, 0.75)", hoverBackgroundColor: "rgba(21, 142, 144, 1)" },
      ]
      this.chart?.update();
    },
      error => {
        this.notification.handleError(error);
      }
    );

  }

  changeChannel(c): void {
    this.ch = c
    this.leadsService.getLeadschart(this.tf, this.ch).subscribe(res => {
      this.chartData = res['chartData'] as LeadsChart[];
      this.barChartData.labels = this.chartData['labels'];
      this.barChartData.datasets = [
        { data: this.chartData['centre'], label: 'Your Centre', backgroundColor: "rgba(231, 176, 25, 0.75)", hoverBackgroundColor: "rgba(231, 176, 25, 1)" },
        { data: this.chartData['national'], label: 'National', backgroundColor: "rgba(21, 142, 144, 0.75)", hoverBackgroundColor: "rgba(21, 142, 144, 1)" },
      ]
      this.chart?.update();
    },
      error => {
        this.notification.handleError(error);
      }
    );

  }

  fetchLeadReport(userId: number, name: string) {
    let rpt = new LeadsReportCommunication(`Leads for ${name} - ${new Date().toLocaleDateString()}`, userId);
        this.sendReportService.assignReport(rpt);
  }
}
