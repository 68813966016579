import { PosQuote, PosQuoteLine, StockSearchResult } from 'src/app/models/pos';
import { NotificationService } from 'src/app/shared/notification.service';
import { resultType } from 'src/app/shared/modal-content/modal-content.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { PosService } from '../pos.service';
import { Subscription } from 'rxjs';
import { FleetService } from 'src/app/fleet/fleet.service';

@Component({ selector: 'app-posfleet', templateUrl: './posfleet.component.html', styleUrls: ['./posfleet.component.less'] })

export class PosfleetComponent implements OnInit , OnDestroy {

  paramsSub: Subscription
  posQuote: PosQuote = new PosQuote()
  stockSearchResult: StockSearchResult[]
  isSearchStockModalVisible = false;
  isSubmitModalVisible = false;
  isLoadingStockSearch = true;
  isSavingQuote = false;
  isLoading = false;
  searchString = "";
  isCompleted = false;


  authForm: FormGroup = new FormGroup({
    odometer: new FormControl('', Validators.required),
    drivername: new FormControl('', Validators.required),
    drivercell: new FormControl('', Validators.required),
  });

  constructor(private route: ActivatedRoute, private posService: PosService, private notification: NotificationService, private router: Router, private modalService: NzModalService
    ,private fleetService: FleetService) { }


  ngOnInit(): void {
    this.paramsSub = this.route.params.subscribe(params => {
      this.isLoading = true;
      // Load quote deets
      this.loadQuote(+params['quoteid'])
    });
  };

  private loadQuote(quoteId: number) {
    this.posService.getQuoteDetails(quoteId).subscribe(
      val => {
        this.posQuote = val; 
        this.posQuote.isContractPricing = true;
        for (let item of this.posQuote.quoteLines) 
        {
          this.updateLineItemGPList(item);
        }      
        this.isLoading = false;
        this.posService.getQuoteState(this.posQuote.quoteId).subscribe(
          val => {
            this.posQuote.state = val;  
            if(this.posQuote.state.quoteStatusId > 7)
            {
              this.isCompleted = true;
            }
          },
          error => {
            this.notification.handleError(error);
          }
        )
      },
      error => {
        this.notification.handleError(error);
      });
  }

 //formatters
  randFormatter(value: number) {
  if (value === null) {
    return "";
  }
  else {
    return "R " + value.toString();
  }

  }

  percentFormatter(value: number) {
  if (value === null) {
    return "";
  }
  else {
    return value.toString() + "%";
  }

  }

  getStatePercentage(): string {
  if (!this.posQuote || !this.posQuote.state) return "N/A";
  switch (this.posQuote.state.stateId){
    case 1: { return "40%"}
    case 2: { return "70%"}
    case 3: { return "100%"}
    case 4: { return "N/A"}
    default:{ return "N/A" }
    
  }
  
  }

  //search stock click 
  searchStock(data: PosQuoteLine) {
    this.isLoadingStockSearch = true;
    this.stockSearchResult = [];
    this.isSearchStockModalVisible = true;
    this.posService.searchStock(data.stockCode).subscribe(
      val => {
        this.stockSearchResult = val;
        this.isLoadingStockSearch = false;
        this.isSearchStockModalVisible = true;
      },
      error => {
        this.notification.handleError(error);
      }
    )
  }

  //search stock show
  filterSearchStock(pos: number): StockSearchResult[] {    
    return this.stockSearchResult.filter(x => x.locationId == pos);
  }

  //on click item hide modal
  selectSearchItem(item: StockSearchResult) {
    this.isSearchStockModalVisible = false;
  }

  //search input
  searchStockInput() {
    this.posService.searchStock(this.searchString).subscribe(
      val => {
        val = val.sort((a, b) => a.locationId - b.locationId) //sort
        if (val.length === 0) {
          this.notification.showWarning("No results found!");
        }
        else {
          let newItem: PosQuoteLine = {
            difference: 0,
            gpPercent: 0,
            gpPercentLow: 0,
            gpPercentHigh: 0, 
            gpRands: 0,
            gpRandsLow: 0,
            gpRandsHigh: 0,
            fiveLow: 0,
            fiveHigh: 0,
            totalPriceIncl: Math.round(val[0].price * this.posQuote.vatRate),
            cost: val[0].cost,
            description: val[0].description,
            locationId: val[0].locationId,
            msfid: val[0].msfid,
            price: Math.round(val[0].price),
            priceIncl: Math.round(val[0].price * this.posQuote.vatRate),
            quoteLineId: 0,
            qty: 1,
            soh: val[0].soh,
            stockCode: val[0].stockCode,
            stockType: 'TYRE',
            brand: val[0].brand,
            brandLogoUrl: val[0].brandLogoUrl,
            clientName: val[0].supplier,
            clientId: val[0].supplierId,
            recommended: false,
            customStockId: 0 //TODO: FIX THIS
          };
          this.updateLineItemGPList(newItem);
          this.posQuote.quoteLines.push(newItem);
          this.posQuote.quoteLines = this.posQuote.quoteLines.slice()
          this.searchString = ""
          
        }
      })  
  }

  //select tyre
  selectLineItem(index: number) 
  {
    let newArr: PosQuoteLine[] = [];
    for (var i in this.posQuote.quoteLines) {
      if (this.posQuote.quoteLines[i].stockType.toUpperCase() !== 'TYRE' || +i === index) {
        newArr.push(this.posQuote.quoteLines[i]);
      }
    }
    this.posQuote.quoteLines = newArr;
  }

  //remove tyre
  removeLineItem(index: number) 
  {
    this.posQuote.quoteLines.splice(index, 1);
    this.posQuote.quoteLines = this.posQuote.quoteLines.slice(); //This will copy the array and assign it again. To circumvent angular change detection
  }


  //Get Total Price
  totalPriceInc(): string {
    if (!this.posQuote.quoteLines)
      return "";

    let total = 0;
    for (let line of this.posQuote.quoteLines) {
      total += line.totalPriceIncl;
    }
    return total.toFixed(2).toString();
  }


  //save quote
  saveQuote() {    
    this.isSavingQuote = true;
    this.posService.updateQuote(this.posQuote).subscribe(
      val => {
        this.isSavingQuote = false;
        this.notification.showSuccess("Quote saved!");
        
      },
      error => {
        this.notification.handleError(error);
        this.isSavingQuote = false;
      }
    )
  }

  //clear quote
  clearQuote() {
    this.posQuote.quoteLines = [];
    this.posQuote.quoteLines = this.posQuote.quoteLines.slice();
  }


  //addItem to Quote
  addItemToQuote(item: PosQuoteLine) {
    this.updateLineItemGPList(item);
    item.totalPriceIncl = item.priceIncl * item.qty;   
    this.posQuote.quoteLines.push(item);
    this.posQuote.quoteLines = this.posQuote.quoteLines.slice();
  }

  //Update Line Item Data

  updateLineItem(index: number) {
    let item = this.posQuote.quoteLines[index];
    item.totalPriceIncl = item.priceIncl * item.qty;
    item.price = item.priceIncl / this.posQuote.vatRate;
    this.updateLineItemGPList(item);
  } 

  updateLineItemGP(item: PosQuoteLine, addPrice: number) {
    item.price = Math.round(item.cost + addPrice);

    item.priceIncl = Math.round(item.price * this.posQuote.vatRate);
    this.updateLineItemGPList(item);
    item.totalPriceIncl = item.priceIncl * item.qty;
  }

  private updateLineItemGPList(item: PosQuoteLine) 
  {
    item.gpPercent = Math.round(100 * (item.price - item.cost) / item.price); //Yes
    item.gpRands = Math.round(item.price - item.cost); //Yes    
    item.gpPercentHigh = item.gpPercent + 5;
    item.gpPercentLow = item.gpPercent - 5;
    item.gpRandsHigh = Math.round((item.cost / (100 - item.gpPercentHigh) * 100) - item.cost);
    item.gpRandsLow = Math.round((item.cost / (100 - item.gpPercentLow) * 100) - item.cost);
    item.fiveHigh = +((item.cost + item.gpRandsHigh) * this.posQuote.vatRate).toFixed(0);
    item.fiveLow = +((item.cost + item.gpRandsLow) * this.posQuote.vatRate).toFixed(0);
  }

  savecomment()
  {
    this.isSavingQuote = true;
    this.posService.updateComment(this.posQuote).subscribe(
      val => {
        this.isSavingQuote = false;
        this.notification.showSuccess("Saved!");
        
      },
      error => {
        this.notification.handleError(error);
        this.isSavingQuote = false;
      });
   
  }

   onModalOk() 
   {
     if(this.authForm.value.odometer == ""  || this.authForm.value.drivercell == "" || this.authForm.value.drivername == "" )
     {
      this.notification.ShowAlert({type: resultType.Warning, 
        title: "Incomplete Submission", 
        htmlMessage: 'Please check that all fields are completed before submitting' ,
        maskClosable: false, 
        autoCloseInMilliseconds: 10000});
        return
     }
     this.posQuote.odo = this.authForm.value.odometer;
     this.posQuote.driver_cell = this.authForm.value.drivercell;
     this.posQuote.driver_name = this.authForm.value.drivername;
     this.isSavingQuote = true;
     this.posService.updateQuote(this.posQuote).subscribe(
       val => {
         this.posService.addSale(this.posQuote).subscribe(
           res => {
             this.isSavingQuote = false;
             //The sale was inserted, ensure we cannot add it again:
             this.isCompleted = true;
             this.isSubmitModalVisible = false;

             if(res['status_code'] == 202)
             {
              this.modalService.create({
                nzTitle: 'Success',
                nzContent: 'Auth Was Successfully Submitted. In a few seconds you will get the result.'
              });
              this.router.navigate["/sales"];
            }
            else
            {
              this.modalService.create({
                nzTitle: 'Error' + res['status_code'],
                nzContent: res['status_reason']  +' . please process this request via the call centre.'
              });            
            }
            sessionStorage.clear();
            localStorage.removeItem('required');
            localStorage.removeItem('fleetData');
            localStorage.removeItem('customerData');
           },
           error => {       
             this.notification.handleError(error);
             this.isSavingQuote = false;
             this.isSubmitModalVisible = false;
             //reload the quote to check if we can/should resubmit auth
             this.loadQuote(this.posQuote.quoteId);
           })  
       },
       error => {
         this.notification.handleError(error);
         this.isSavingQuote = false;
       })
  }


  //check number input 
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  //On Destroy
  ngOnDestroy(): void {
    this.paramsSub.unsubscribe();
  }

}
