<div class="container-fluid col-lg-11">

    <nz-card *ngIf="this.topCtrls == true;" style="border-radius: 8px;">
        <nz-card *ngIf="this.rfcLoading == true" style="width: 70%; position: absolute; left: 2%;">
            <div style="display: flex;">
                <div style="width:60%;">
                    <div style="display: flex;">
                        <div>
                            <h6><i>"{{this.waiting_caption}}"</i></h6>
                        </div>
                        <div style="position: absolute; right: 5%; bottom: 2%;"><a (click)="nextJoke()"><i nz-icon nzType="right" aria-hidden="true"></i></a></div>
                    </div>
                </div>
                <div style="position: absolute; right: 8%">
                    <img style="display: inline-block;" src="/assets/Images/{{this.logo_load}}" class="brandImage" />
                </div>
            </div>
            <hr style="border-top: 2px solid #117274;">
        </nz-card>

        <div *ngIf="this.rfcLoading == false;" style="float: left; width: 30%; padding: 1%;">
            <nz-spin [nzSpinning]="isLoading">
                <input nz-input placeholder="Search Stock or Gen Code" [ngModel]="search" (ngModelChange)="codeInputChange($event)" id="stockSearch" class="submit trinput" autocomplete="off" />
            </nz-spin>
            <hr style="border-top: 2px solid #117274;">
            <div style=" display: flex; float: left; padding-left: 1%; width: 100%;">
                <div><b>Apply work in progress to diff</b>
                    <nz-switch style="margin-left: 10px;" (click)="work_in_progress()"></nz-switch>
                </div>
                <div style="margin-top: 3px; margin-left: 5px;" *ngIf="this.wip_visible == false;"><a (click)="this.wip_visible = true" style="color:#117274; margin-left: 3%; margin-bottom: 3%;"><i nz-icon nzType="eye" aria-hidden="true"></i></a></div>
                <div style="margin-top: 3px; margin-left: 5px;" *ngIf="this.wip_visible == true;"><a (click)="this.wip_visible = false" style="color: red; margin-left: 3%; margin-bottom: 3%;"><i nz-icon nzType="eye-invisible" aria-hidden="true"></i></a></div>


            </div>



        </div>




        <div class="repTR_DIV" style="float: right">
            <div class="TR_LEFT" style="float: left;">
                <button *ngIf="this.currTable !=0;" nz-button nzType="primary" class="btn_border" nz-tooltip="Download Model Stock as Excel Document" (click)="download_doc('MAIN')">
                    <a style="color: white;"><i nz-icon nzType="download" aria-hidden="true"></i></a>
                </button>

                <!-- <span style="color: #117274;"> | <button nz-button nzType="primary" class="btn_border" (click)="liveStockCheck(-1)">Get Live Stock Levels</button></span> -->

                <br>
                <span *ngIf="this.liveUpdate == true;" style="color: #117274;"> Live Stock Scanned</span>
                <div class="repBR_DIV" style="padding-top: 80px;">
                    <nz-spin [nzSpinning]="rfcLoading">
                        <div class="reportLabel">
                            <a style="display: flex;" nz-dropdown [nzDropdownMenu]="rfc_menu">
                                <div><a style="color: #117274;">| <i nz-icon nzType="down" aria-hidden="true"></i></a></div>
                                <div style="margin-left: 5px;"><b>Show slow moving stock</b></div>
                                <app-video-widget style="margin-left:5px" [videoId]="5"></app-video-widget>

                            </a>
                            <nz-dropdown-menu #rfc_menu="nzDropdownMenu">
                                <ul nz-menu nzSelectable class="hover_dropdown">
                                    <li nz-menu-item (click)="RFC_Check(3)" class="hover_list"><b>Show RFC Items</b></li>
                                    <li nz-menu-item (click)="Surplus_Check()" class="hover_list"><b>Show Surplus Items</b></li>
                                </ul>
                            </nz-dropdown-menu>
                        </div>
                    </nz-spin>
                </div>
            </div>

        </div>
    </nz-card>
    <div style="display: flex;">

        <div style="width: 98%;">
            <hr class="hrTheme">
        </div>

        <div style="margin-left: 1%; margin-top: 0.6%;">
            <a style="color: var(--textcolor);" *ngIf="this.topCtrls == true;" (click)="topCtrls=false;"><i nz-icon nzType="up" aria-hidden="true"></i></a>
            <a style="color: var(--textcolor);" *ngIf="this.topCtrls == false;" (click)="topCtrls=true;"><i nz-icon nzType="down" aria-hidden="true"></i></a>
        </div>
    </div>
</div>



<div class="container-fluid col-lg-11">
    <!-- ---------------------------RFC VIEW ------------------------------------------->
    <nz-modal [(nzVisible)]="rfc_visible" (nzOnCancel)="rfc_visible = false" [nzFooter]="null" nzTheme="fill" [nzWidth]="2000">

        <ng-container *nzModalContent>

            <div class="flex_boxRFC">
                <div>
                    <h6 class="modal_header">RFC_ITEMS </h6>
                </div>
                <div class="rfcDownload_btn">
                    <button nz-button nzType="primary" class="btn_border" nz-tooltip="Download RFC Items as Excel Document" (click)="download_doc('RFC')">
                            <a><i nz-icon nzType="download" aria-hidden="true"></i></a>
                        </button>
                </div>

                <div class="Item_count" style="float: right;">
                    <span>[{{RFC_SUMMARY.length}} Items]</span>
                </div>
            </div>
            <hr class="blueLine">
            <div style="margin-left:18%;" (click)="slowStock_ModeChange()"><a><i nz-icon nzType="up" aria-hidden="true"></i></a></div>
            <div class="flex_boxRFC">
                <div>
                    <span class="modal_header">The items below have not had a <a style="color:var(--textcolor);">{{slowStock_mode}}</a> in the last <input nz-input value="{{this.monthR}}" placeholder="{{this.monthR}}"  type="number" min="1" max="6" id="month_entry" class="submit trinput" style="width: 10%;"  autocomplete="off"/> months <button nz-button nzType="primary" class="btn_border" (click)="this.RFC_RangeChange()"><i nz-icon nzType="search"></i></button></span>
                    <br>
                </div>
                <div class="sales_header">
                    <span *ngIf="slowStock_mode=='sale';">Quantity  <a style="color:var(--textcolor);">sold</a> in the past 6 months -></span>
                    <span *ngIf="slowStock_mode=='purchase';">Quantity  <a style="color:var(--textcolor);">purchased</a> in the past 6 months -></span>
                </div>
            </div>
            <div style="margin-left:18%;" (click)="slowStock_ModeChange()"><a><i nz-icon nzType="down" aria-hidden="true"></i></a></div>
            <br>
            <nz-spin [nzSpinning]="rfcLoading">
                <!-- RFC Sales -->
                <nz-table *ngIf="slowStock_mode=='sale';" [nzData]="RFC_SUMMARY" #RFC_ITEMS id="RFC_ITEMS" [nzFrontPagination]="false" [nzScroll]="{ y:'900px'}">
                    <thead style="position: sticky;">
                        <tr>
                            <th [nzSortFn]="sortBrandRFC" [nzWidth]="'5%'" class="headerReplenish">Brand</th>
                            <th [nzSortFn]="sortDescRFC" [nzWidth]="'15%'" class="headerReplenish">Description</th>
                            <th [nzSortFn]="sortCodeRFC" [nzWidth]="'5%'" class="headerReplenish">Code</th>
                            <th [nzSortFn]="sortMSLRFC" [nzWidth]="'5%'" class="headerReplenish">MSL</th>
                            <th [nzSortFn]="sortSOHRFC" [nzWidth]="'5%'" class="headerReplenish">SOH</th>
                            <th [nzSortFn]="sortSupplierRFC" [nzWidth]="'15%'" class="headerReplenish">Supplier</th>
                            <th [nzSortFn]="sortPriceRFC" [nzWidth]="'5%'" class="headerReplenish">Cost</th>
                            <th [nzSortFn]="sortlastsoldRFC" [nzWidth]="'6%'" class="headerReplenish">last sold</th>
                            <th [nzSortFn]="sortlastpurchaseRFC" [nzWidth]="'6%'" class="headerReplenish">last purchased</th>
                            <th [nzSortFn]="sortM1RFC" [nzWidth]="'5%'" class="headerReplenish">{{getMonthName(monthList[0])}}_{{yearList[0]}}</th>
                            <th [nzSortFn]="sortM2RFC" [nzWidth]="'5%'" class="headerReplenish">{{getMonthName(monthList[1])}}_{{yearList[1]}}</th>
                            <th [nzSortFn]="sortM3RFC" [nzWidth]="'5%'" class="headerReplenish">{{getMonthName(monthList[2])}}_{{yearList[2]}}</th>
                            <th [nzSortFn]="sortM4RFC" [nzWidth]="'5%'" class="headerReplenish">{{getMonthName(monthList[3])}}_{{yearList[3]}}</th>
                            <th [nzSortFn]="sortM5RFC" [nzWidth]="'5%'" class="headerReplenish">{{getMonthName(monthList[4])}}_{{yearList[4]}}</th>
                            <th [nzSortFn]="sortM6RFC" [nzWidth]="'5%'" class="headerReplenish">{{getMonthName(monthList[5])}}_{{yearList[5]}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let line of RFC_ITEMS.data">
                            <td class="doubleCol text-center"><img style="display: inline-block; width: 95%;" src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{line.logo}}" class="brandImage" />
                                <div style="display: none;">{{line.brand.replace('.png','').replace('.jpg','').replace('logo','').replace('.webp','').replace('.gif','')}}</div>
                            </td>
                            <td>{{line.description}}</td>
                            <td class="rowReplenish">{{line.code}}</td>
                            <td class="rowReplenish">{{line.msl}}</td>
                            <td class="rowReplenish">{{line.soh}}</td>
                            <td class="rowReplenish">{{line.supplier_name}}</td>
                            <td class="rowReplenish">R{{line.price}}</td>
                            <td class="rowReplenish">{{line.lastSold}}</td>

                            <td class="singleCol" *ngIf="line.lastPurchase_status == None">{{line.lastPurchased}}</td>
                            <td class="singleCol" nz-tooltip="No Purchases in the last {{line.lastPurchase}} Months" style="color:orange" *ngIf="line.lastPurchase_status == 'red'">{{line.lastPurchased}}</td>

                            <td class="rowReplenish">{{line.sales[0].qty}}</td>
                            <td class="rowReplenish">{{line.sales[1].qty}}</td>
                            <td class="rowReplenish">{{line.sales[2].qty}}</td>
                            <td class="rowReplenish">{{line.sales[3].qty}}</td>
                            <td class="rowReplenish">{{line.sales[4].qty}}</td>
                            <td class="rowReplenish">{{line.sales[5].qty}}</td>
                        </tr>
                    </tbody>
                </nz-table>
                <!-- RFC Purchases -->
                <nz-table *ngIf="slowStock_mode=='purchase';" [nzData]="RFC_SUMMARY" #RFC_ITEMS id="RFC_ITEMS" [nzFrontPagination]="false" [nzScroll]="{ y:'900px'}">
                    <thead style="position: sticky;">
                        <tr>
                            <th [nzSortFn]="sortBrandRFC" [nzWidth]="'5%'" class="headerReplenish">Brand</th>
                            <th [nzSortFn]="sortDescRFC" [nzWidth]="'15%'" class="headerReplenish">Description</th>
                            <th [nzSortFn]="sortCodeRFC" [nzWidth]="'5%'" class="headerReplenish">Code</th>
                            <th [nzSortFn]="sortMSLRFC" [nzWidth]="'5%'" class="headerReplenish">MSL</th>
                            <th [nzSortFn]="sortSOHRFC" [nzWidth]="'5%'" class="headerReplenish">SOH</th>
                            <th [nzSortFn]="sortSupplierRFC" [nzWidth]="'15%'" class="headerReplenish">Supplier</th>
                            <th [nzSortFn]="sortPriceRFC" [nzWidth]="'5%'" class="headerReplenish">Cost</th>
                            <th [nzSortFn]="sortlastsoldRFC" [nzWidth]="'6%'" class="headerReplenish">last sold</th>
                            <th [nzSortFn]="sortlastpurchaseRFC" [nzWidth]="'6%'" class="headerReplenish">last purchased</th>
                            <th [nzSortFn]="sortM1RFC" [nzWidth]="'5%'" class="headerReplenish">{{getMonthName(monthList[0])}}_{{yearList[0]}}</th>
                            <th [nzSortFn]="sortM2RFC" [nzWidth]="'5%'" class="headerReplenish">{{getMonthName(monthList[1])}}_{{yearList[1]}}</th>
                            <th [nzSortFn]="sortM3RFC" [nzWidth]="'5%'" class="headerReplenish">{{getMonthName(monthList[2])}}_{{yearList[2]}}</th>
                            <th [nzSortFn]="sortM4RFC" [nzWidth]="'5%'" class="headerReplenish">{{getMonthName(monthList[3])}}_{{yearList[3]}}</th>
                            <th [nzSortFn]="sortM5RFC" [nzWidth]="'5%'" class="headerReplenish">{{getMonthName(monthList[4])}}_{{yearList[4]}}</th>
                            <th [nzSortFn]="sortM6RFC" [nzWidth]="'5%'" class="headerReplenish">{{getMonthName(monthList[5])}}_{{yearList[5]}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let line of RFC_ITEMS.data">
                            <td class="doubleCol text-center"><img style="display: inline-block; width: 95%;" src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{line.logo}}" class="brandImage" />
                                <div style="display: none;">{{line.brand.replace('.png','').replace('.jpg','').replace('logo','').replace('.webp','').replace('.gif','')}}</div>
                            </td>
                            <td>{{line.description}}</td>
                            <td class="rowReplenish">{{line.code}}</td>
                            <td class="rowReplenish">{{line.msl}}</td>
                            <td class="rowReplenish">{{line.soh}}</td>
                            <td class="rowReplenish">{{line.supplier_name}}</td>
                            <td class="rowReplenish">R{{line.price}}</td>
                            <td class="rowReplenish" *ngIf="line.lastSale_status == None">{{line.lastSold}}</td>
                            <td class="rowReplenish" nz-tooltip="No Sales in the last {{line.lastSale}} Months" style="color:orange" *ngIf="line.lastSale_status == 'red'">{{line.lastSold}}</td>

                            <td class="singleCol">{{line.lastPurchased}}</td>
                            <td class="rowReplenish">{{line.purchases[0].qty}}</td>
                            <td class="rowReplenish">{{line.purchases[1].qty}}</td>
                            <td class="rowReplenish">{{line.purchases[2].qty}}</td>
                            <td class="rowReplenish">{{line.purchases[3].qty}}</td>
                            <td class="rowReplenish">{{line.purchases[4].qty}}</td>
                            <td class="rowReplenish">{{line.purchases[5].qty}}</td>
                        </tr>
                    </tbody>
                </nz-table>
            </nz-spin>
        </ng-container>
    </nz-modal>
    <!-- ---------------------------SURPLUS VIEW ------------------------------------------->
    <nz-modal [(nzVisible)]="surplus_visible" (nzOnCancel)="surplus_visible = false" [nzFooter]="null" nzTheme="fill" [nzWidth]="2500">
        <ng-container *nzModalContent>
            <div class="flex_boxRFC">
                <div>
                    <h6 class="modal_header">Over Stocked Items </h6>
                </div>
                <div class="rfcDownload_btn">
                    <button nz-button nzType="primary" *ngIf="this.captionSet == 0;" class="btn_border" nz-tooltip="Download Surplus Items as Excel Document" (click)="download_doc('surplus')">
                            <a><i nz-icon nzType="download" aria-hidden="true"></i></a>
                        </button>
                    <button nz-button nzType="primary" *ngIf="this.captionSet == 1;" class="btn_border" nz-tooltip="Download Non_MSL Items as Excel Document" (click)="download_doc('nonMSL')">
                            <a><i nz-icon nzType="download" aria-hidden="true"></i></a>
                        </button>
                </div>
                <div class="Item_count">
                    <span *ngIf="this.captionSet == 0;">[{{OVER_MSL.length}} Items]</span>
                    <span *ngIf="this.captionSet == 1;">[{{NON_MSL.length}} Items]</span>
                </div>
            </div>
            <hr class="blueLine">

            <div class="flex_boxRFC">
                <div>
                    <span *ngIf="this.captionSet ==0;" class="modal_header">You are carrying more stock than your msl for the items listed below </span>
                    <span *ngIf="this.captionSet ==1;" class="modal_header">The items below are in your inventory, but not on your model stock </span>

                    <br>
                </div>
                <div class="sales_header">
                    <span>Quantity sold in the past 6 months -></span>
                    <!-- <span *ngIf="slowStock_mode == 'sale'">Quantity sold in the past 6 months -></span>
                        <span *ngIf="slowStock_mode == 'purchase'">Quantity purchased in the past 6 months -></span> -->
                </div>
            </div>
            <br>
            <nz-spin [nzSpinning]="rfcLoading">
                <nz-tabset nzCentered nzSize="large" class="bg-1" [(nzSelectedIndex)]="captionSet">
                    <nz-tab nzTitle="Surplus Stock">
                        <nz-table #lines [nzData]="OVER_MSL" [nzFrontPagination]="false" [nzScroll]="{ y:'900px'}" id="Surplus_Items">
                            <thead>
                                <tr>
                                    <th [nzSortFn]="sortBrandRFC" [nzWidth]="'8%'" class="headerReplenish">Brand</th>
                                    <th [nzSortFn]="sortDescSP" [nzWidth]="'15%'" class="headerReplenish">Description</th>
                                    <th [nzSortFn]="sortCodeSP" [nzWidth]="'8%'" class="headerReplenish">Code</th>
                                    <th [nzSortFn]="sortMSLSP" [nzWidth]="'8%'" class="headerReplenish">MSL</th>
                                    <th [nzSortFn]="sortSOHSP" [nzWidth]="'8%'" class="headerReplenish">SOH</th>
                                    <th [nzSortFn]="sortCostSP" [nzWidth]="'8%'" class="headerReplenish">Cost</th>
                                    <th [nzSortFn]="sortlastsoldSP" [nzWidth]="'8%'" class="headerReplenish">last sold</th>
                                    <th [nzSortFn]="sortlastpurchaseSP" [nzWidth]="'8%'" class="headerReplenish">last purchased</th>
                                    <th [nzSortFn]="sortM1SP" [nzWidth]="'8%'" class="headerReplenish">{{getMonthName(monthList_MS[0])}}_{{yearList_MS[0]}}</th>
                                    <th [nzSortFn]="sortM2SP" [nzWidth]="'8%'" class="headerReplenish">{{getMonthName(monthList_MS[1])}}_{{yearList_MS[1]}}</th>
                                    <th [nzSortFn]="sortM3SP" [nzWidth]="'8%'" class="headerReplenish">{{getMonthName(monthList_MS[2])}}_{{yearList_MS[2]}}</th>
                                    <th [nzSortFn]="sortM4SP" [nzWidth]="'8%'" class="headerReplenish">{{getMonthName(monthList_MS[3])}}_{{yearList_MS[3]}}</th>
                                    <th [nzSortFn]="sortM5SP" [nzWidth]="'8%'" class="headerReplenish">{{getMonthName(monthList_MS[4])}}_{{yearList_MS[4]}}</th>
                                    <th [nzSortFn]="sortM6SP" [nzWidth]="'8%'" class="headerReplenish">{{getMonthName(monthList_MS[5])}}_{{yearList_MS[5]}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let line of lines.data">
                                    <td class="doubleCol"><img style="margin: 0 auto; width: 95%;" src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{line.logo}}" class="brandImage" />
                                        <div style="display: none;">{{line.brand.replace('.png','').replace('.jpg','').replace('logo','').replace('.webp','').replace('.gif','')}}</div>
                                    </td>
                                    <td>{{line.description}}</td>
                                    <td class="rowReplenish">{{line.code}}</td>
                                    <td class="rowReplenish">{{line.msl}}</td>
                                    <td class="rowReplenish">{{line.soh}}</td>
                                    <td class="rowReplenish">R{{line.price}}</td>
                                    <td class="rowReplenish">{{line.lastSold}}</td>
                                    <td class="singleCol">{{line.lastPurchased}}</td>
                                    <td class="rowReplenish">{{line.sales[0].qty}}</td>
                                    <td class="rowReplenish">{{line.sales[1].qty}}</td>
                                    <td class="rowReplenish">{{line.sales[2].qty}}</td>
                                    <td class="rowReplenish">{{line.sales[3].qty}}</td>
                                    <td class="rowReplenish">{{line.sales[4].qty}}</td>
                                    <td class="rowReplenish">{{line.sales[5].qty}}</td>
                                </tr>
                            </tbody>
                        </nz-table>
                    </nz-tab>
                    <nz-tab nzTitle="Items not in Model Stock">
                        <nz-table #lines2 [nzData]="NON_MSL" [nzFrontPagination]="false" [nzScroll]="{ y:'900px'}" id="NonMSL_Items">
                            <thead>
                                <tr>
                                    <th [nzSortFn]="sortBrandSP" [nzWidth]="'8%'" class="headerReplenish">Brand</th>
                                    <th [nzSortFn]="sortDescSP" [nzWidth]="'15%'" class="headerReplenish">Description</th>
                                    <th [nzSortFn]="sortCodeSP" [nzWidth]="'8%'" class="headerReplenish">Code</th>
                                    <th [nzSortFn]="sortMSLSP" [nzWidth]="'8%'" class="headerReplenish">Model Stock</th>
                                    <th [nzSortFn]="sortSOHSP" [nzWidth]="'8%'" class="headerReplenish">SOH</th>
                                    <th [nzSortFn]="sortCostSP" [nzWidth]="'8%'" class="headerReplenish">Cost</th>
                                    <th [nzSortFn]="sortlastsoldSP" [nzWidth]="'8%'" class="headerReplenish">last sold</th>
                                    <th [nzSortFn]="sortlastpurchaseSP" [nzWidth]="'8%'" class="headerReplenish">last purchased</th>
                                    <th [nzSortFn]="sortM1SP" [nzWidth]="'8%'" class="headerReplenish">{{getMonthName(monthList_NonMS[0])}}_{{yearList_NonMS[0]}}</th>
                                    <th [nzSortFn]="sortM2SP" [nzWidth]="'8%'" class="headerReplenish">{{getMonthName(monthList_NonMS[1])}}_{{yearList_NonMS[1]}}</th>
                                    <th [nzSortFn]="sortM3SP" [nzWidth]="'8%'" class="headerReplenish">{{getMonthName(monthList_NonMS[2])}}_{{yearList_NonMS[2]}}</th>
                                    <th [nzSortFn]="sortM4SP" [nzWidth]="'8%'" class="headerReplenish">{{getMonthName(monthList_NonMS[3])}}_{{yearList_NonMS[3]}}</th>
                                    <th [nzSortFn]="sortM5SP" [nzWidth]="'8%'" class="headerReplenish">{{getMonthName(monthList_NonMS[4])}}_{{yearList_NonMS[4]}}</th>
                                    <th [nzSortFn]="sortM6SP" [nzWidth]="'8%'" class="headerReplenish">{{getMonthName(monthList_NonMS[5])}}_{{yearList_NonMS[5]}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let line of lines2.data">
                                    <td class="doubleCol"><img style="margin: 0 auto;" src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{line.logo}}" class="brandImage" style="display: inline-block; width: 80%;" />
                                        <div style="display: none;">{{line.brand.replace('.png','').replace('.jpg','').replace('logo','').replace('.webp','').replace('.gif','')}}</div>
                                    </td>
                                    <td>{{line.description}}</td>
                                    <td class="rowReplenish">{{line.code}}</td>
                                    <td class="rowReplenish">{{line.msl}}</td>
                                    <td class="rowReplenish">{{line.soh}}</td>
                                    <td class="rowReplenish">R{{line.price}}</td>
                                    <td class="rowReplenish">{{line.lastSold}}</td>
                                    <td class="singleCol">{{line.lastPurchased}}</td>
                                    <td class="rowReplenish">{{line.sales[0].qty}}</td>
                                    <td class="rowReplenish">{{line.sales[1].qty}}</td>
                                    <td class="rowReplenish">{{line.sales[2].qty}}</td>
                                    <td class="rowReplenish">{{line.sales[3].qty}}</td>
                                    <td class="rowReplenish">{{line.sales[4].qty}}</td>
                                    <td class="rowReplenish">{{line.sales[5].qty}}</td>
                                </tr>
                            </tbody>
                        </nz-table>
                    </nz-tab>
                </nz-tabset>
            </nz-spin>
        </ng-container>
    </nz-modal>

    <!-- Action Modal ------------------------------------------------------------- -->

    <nz-modal [(nzVisible)]="actionView" (nzOnCancel)="actionView = false" [nzFooter]="null" nzTheme="fill" [nzWidth]="900">
        <ng-container *nzModalContent>
            <nz-tabset nzCentered nzSize="large" class="bg-1">
                <nz-tab *ngIf="editType == 'A'" nzTitle="Add item to model stock">
                    <div style="padding-left: 30px;">
                        <form nz-form nzTitle="Add new stock model">
                            <nz-form-item>
                                <p style="margin-left: 5px; margin-right: 10px;">Description: </p>
                                <input nz-input placeholder="{{item_to_change.description}}" readonly id="new_description" style="width: 70%;" autocomplete="off" />
                            </nz-form-item>
                            <nz-form-item>
                                <p style=" margin-left: 5px; margin-right: 10px;">Stock Code: </p>
                                <input nz-input placeholder="{{item_to_change.stock_code}}" readonly id="new_code" style="width: 70%;" autocomplete="off" />
                            </nz-form-item>
                            <nz-form-item>
                                <p style="margin-left: 5px; margin-right: 10px;">Model Stock: </p>
                                <input nz-input placeholder="Enter Model Stock" type="number" min="0" id="new_msl" style="width: 70%;" autocomplete="off" />
                            </nz-form-item>
                            <nz-form-item>
                                <p style="margin-left: 5px; margin-right: 100px;">Supplier: </p>
                                <input nz-dropdown [nzDropdownMenu]="menu" placeholder="{{item_to_change.best_supplier}}" value="{{item_to_change.best_supplier}}" id="new_supplier" style="width: 50%; text-align: center; color: var(--textcolor);" autocomplete="off" />
                                <nz-dropdown-menu #menu="nzDropdownMenu">
                                    <ul nz-menu nzSelectable *ngFor="let supplier of suppliersTemp">
                                        <li nz-menu-item *ngIf="supplier.repActive == true && ['WAREHOUSE','WHOLESALE','REMOTE'].includes(supplier.type)" (click)="chooseSupplier(supplier,3)">{{supplier.supplier_name}} </li>
                                    </ul>
                                </nz-dropdown-menu>
                            </nz-form-item>
                            <div style="padding: 5px;">
                                <button nz-button nztype="default" style="float: right;" (click)="addNewStockModel(item_to_change)">Add Item To Model Stock</button>
                            </div>
                        </form>
                    </div>
                </nz-tab>
                <nz-tab *ngIf="editType == 'E' || editType == 'H'" nzTitle="Edit item">
                    <div style="display: flex; padding-left: 100px;">
                        <div><u>Edit Item</u> : <u>{{item_to_change.description}}</u>
                            <div style="float: right; margin-left: 300px;"> <u>[{{item_to_change.stock_code}}]</u></div>
                        </div>
                    </div>
                    <div style="display: flex; padding-left: 100px;" *ngIf="item_to_change.modelStock == 0">
                        <p style="color: var(--warningcolor);">*</p> Increase Model Stock to re-enable item</div>
                    <hr style="border-top: 2px solid var(--textcolor); width: 80%;">
                    <div style="padding-left: 250px;">
                        <div style="display: flex;">
                            Model Stock:
                            <div style="margin-left: 15px; margin-bottom: 15px;"><input nz-input placeholder="{{item_to_change.modelStock}}" value="{{item_to_change.modelStock}}" type="number" min="0" id="edit_msl" style="width: 70%;" autocomplete="off" /></div>
                        </div>
                        <div style="display: flex;">
                            Supplier:
                            <div style="margin-left: 35px; margin-bottom: 15px;">
                                <input nz-dropdown [nzDropdownMenu]="menu" placeholder="{{item_to_change.best_supplier}}" value="{{item_to_change.best_supplier}}" id="edit_supplier" style="width: 80%; text-align: center; color: var(--textcolor);" autocomplete="off" />
                                <nz-dropdown-menu #menu="nzDropdownMenu">
                                    <ul nz-menu nzSelectable *ngFor="let supplier of suppliersTemp">
                                        <li nz-menu-item *ngIf="supplier.repActive == true && ['WAREHOUSE','WHOLESALE','REMOTE'].includes(supplier.type)" (click)="chooseSupplier(supplier,2)">{{supplier.supplier_name}} </li>
                                    </ul>
                                </nz-dropdown-menu>
                            </div>
                        </div>
                    </div>
                    <button nz-button nztype="default" style="float: right; margin-left: 100px;" (click)="saveChanges(item_to_change)">Save Changes</button>
                </nz-tab>
                <nz-tab *ngIf="(editType == 'E' || editType == 'H') && item_to_change.modelStock != 0" nzTitle="Disable Item Replenishment">
                    <div style="display: flex; padding-left: 100px;">
                        <div><u>Hold Stock Item</u> : <u>{{item_to_change.description}}</u>
                            <div style="float: right; margin-left: 200px;"> <u>[{{item_to_change.stock_code}}]</u></div>
                        </div>
                    </div>
                    <hr style="border-top: 2px solid var(--textcolor); width: 80%;">
                    <div style="padding-left: 250px;">
                        <div style="display: flex;">
                            Model Stock:
                            <div style="margin-left: 15px; margin-bottom: 15px; position:absolute; right: 15%;">
                                <div>{{item_to_change.modelStock}}</div>
                            </div>
                        </div>
                        <div style="display: flex;">
                            SOH:
                            <div style="margin-left: 15px; margin-bottom: 15px; position:absolute; right: 15%;">
                                <div>{{item_to_change.soh}}</div>
                            </div>
                        </div>
                        <div style="display: flex;">
                            Supplier Stock:
                            <div style="margin-left: 15px; margin-bottom: 15px; position:absolute; right: 15%;">
                                <div>{{item_to_change.supplier_soh}}</div>
                            </div>
                        </div>
                        <div style="display: flex;">
                            Price:
                            <div style="margin-left: 35px; margin-bottom: 15px; position:absolute; right: 15%;">
                                <div>R{{item_to_change.best_cost}}</div>
                            </div>
                        </div>
                        <div style="display: flex;">
                            Supplier:
                            <div style="margin-left: 35px; margin-bottom: 15px; position:absolute; right: 15%;">
                                <div>{{item_to_change.best_supplier}}</div>
                            </div>
                        </div>

                        <div *ngIf="item_to_change.best_cost == 0"><span style="color: var(--errorcolor);">*</span> Pricing can not be assigned if supplier has no stock</div>
                    </div>
                    <button nz-button nztype="default" style="float: right; margin-left: 100px;" (click)="deleteModel(item_to_change.stockReplenishmentId)">Disable Item </button>
                </nz-tab>
                <nz-tab *ngIf="(editType == 'H' && item_to_change.holding_status == 0) || (item_to_change.holding_status == 0 && item_to_change.supplier_soh == 0)" nzTitle="Hold item until stock is available">
                    <div style="display: flex; padding-left: 100px;">
                        <div><u>Hold Stock Item</u> : <u>{{item_to_change.description}}</u>
                            <div style="float: right; margin-left: 200px;"> <u>[{{item_to_change.stock_code}}]</u></div>
                        </div>
                    </div>
                    <hr style="border-top: 2px solid var(--textcolor); width: 80%;">
                    <div style="padding-left: 250px;">
                        <div style="display: flex;">
                            Model Stock:
                            <div style="margin-left: 15px; margin-bottom: 15px;">
                                <div>{{item_to_change.modelStock}}</div>
                            </div>
                        </div>
                        <div style="display: flex;">
                            SOH:
                            <div style="margin-left: 15px; margin-bottom: 15px;">
                                <div>{{item_to_change.soh}}</div>
                            </div>
                        </div>
                        <div style="display: flex;">
                            Supplier Stock:
                            <div style="margin-left: 15px; margin-bottom: 15px;">
                                <div>{{item_to_change.supplier_soh}}</div>
                            </div>
                        </div>
                        <div style="display: flex;">
                            Supplier:
                            <div style="margin-left: 35px; margin-bottom: 15px;">
                                <div>{{item_to_change.best_supplier}}</div>
                            </div>
                        </div>
                        <div style="display: flex;">
                            Price:
                            <div style="margin-left: 35px; margin-bottom: 15px;">
                                <div>R{{item_to_change.best_cost}}</div>
                            </div>
                        </div>
                        <div *ngIf="item_to_change.best_cost == 0"><span style="color: var(--errorcolor);">*</span> Pricing can not be assigned if supplier has no stock</div>
                    </div>
                    <button nz-button nztype="default" style="float: right; margin-left: 100px;" (click)="holdItem(item_to_change.stockReplenishmentId)">Hold Stock</button>
                </nz-tab>

                <nz-tab *ngIf="item_to_change.holding_status == 1" nzTitle="Stop Holding This Item">
                    <div style="display: flex; padding-left: 100px;">
                        <div><u>Holding Stock Item</u> : <u>{{item_to_change.description}}</u>
                            <div style="float: right; margin-left: 200px;"> <u>[{{item_to_change.stock_code}}]</u></div>
                        </div>
                    </div>
                    <hr style="border-top: 2px solid var(--textcolor); width: 80%;">
                    <div style="padding-left: 250px;">
                        <div style="display: flex;">
                            Model Stock:
                            <div style="margin-left: 15px; margin-bottom: 15px;">
                                <div>{{item_to_change.modelStock}}</div>
                            </div>
                        </div>
                        <div style="display: flex;">
                            SOH:
                            <div style="margin-left: 15px; margin-bottom: 15px;">
                                <div>{{item_to_change.soh}}</div>
                            </div>
                        </div>
                        <div style="display: flex;">
                            Supplier Stock:
                            <div style="margin-left: 15px; margin-bottom: 15px;">
                                <div>{{item_to_change.supplier_soh}}</div>
                            </div>
                        </div>
                        <div style="display: flex;">
                            Supplier:
                            <div style="margin-left: 35px; margin-bottom: 15px;">
                                <div>{{item_to_change.best_supplier}}</div>
                            </div>
                        </div>
                        <div style="display: flex;">
                            Price:
                            <div style="margin-left: 35px; margin-bottom: 15px;">
                                <div>R{{item_to_change.best_cost}}</div>
                            </div>
                        </div>
                        <div *ngIf="item_to_change.best_cost == 0"><span style="color: var(--errorcolor);">*</span> Pricing can not be assigned if supplier has no stock</div>
                    </div>
                    <button nz-button nztype="default" style="float: right; margin-left: 100px;" (click)="holdItem(item_to_change.stockReplenishmentId)">Stop Holding</button>
                </nz-tab>
            </nz-tabset>
        </ng-container>
    </nz-modal>
    <!-- Sales Trend View --------------------------------------------------------- -->
    <nz-modal [(nzVisible)]="isMsfidSalesModalVisible" (nzOnCancel)="isMsfidSalesModalVisible = false" [nzFooter]="null" nzTheme="fill" [nzWidth]="700">
        <ng-container *nzModalContent>
            <nz-spin [nzSpinning]="isLoadingSalesPerMsfid">
                <div *ngIf="this.tyreSalesTrend">
                    <h4>6 MONTH SALES TREND</h4>
                    <img style="margin: 0 auto;" src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{tyreSalesTrend.logo}}" class="brandImage" style="display: inline-block" />
                    <h5 style="display: inline-block; margin-left: 20px">
                        <div class="description_clickable"><a>{{tyreSalesTrend.description}}</a></div>
                    </h5>
                    <h5 style="padding-top: 10px;">Classification: {{tyreSalesTrend.classification}}</h5>
                    <nz-row class="border-top" style="padding-top: 10px; padding-bottom: 5px;">
                        <div nz-col [nzSpan]="3">

                        </div>
                        <div nz-col [nzSpan]="3" *ngFor="let item of tyreSalesTrend.sales; let i = index" style="text-align: center;">
                            <i *ngIf="i === 0; else elseMonth">{{getMonthName(item.month)}} {{item.year}}</i>
                            <ng-template #elseMonth>{{getMonthName(item.month)}} {{item.year}}</ng-template>
                        </div>
                    </nz-row>
                    <nz-row style="padding-top: 5px;padding-bottom: 5px;">
                        <div nz-col [nzSpan]="3" style="padding-left: 5px;">
                            Qty:
                        </div>
                        <div nz-col [nzSpan]="3" *ngFor="let item of tyreSalesTrend.sales; let i = index" style="text-align: center;">
                            <i *ngIf="i === 0; else elseQty">{{item.qty}}</i>
                            <ng-template #elseQty>{{item.qty}}</ng-template>
                        </div>
                    </nz-row>
                    <nz-row style="padding-top: 5px;padding-bottom: 5px;">
                        <div nz-col [nzSpan]="3" style="padding-left: 5px;">
                            Invoices:
                        </div>
                        <div nz-col [nzSpan]="3" *ngFor="let item of tyreSalesTrend.sales; let i = index" style="text-align: center;">
                            <i *ngIf="i === 0; else elseCount">{{item.count}}</i>
                            <ng-template #elseCount>{{item.count}}</ng-template>
                        </div>
                    </nz-row>
                    <div class="border-top" style="font-size: 1.2rem; padding-top: 8px;">
                        Month Sales Trend ({{getMonthRange(tyreSalesTrend)}}): <span [ngStyle]="{'color': getMonthTrend(tyreSalesTrend).charAt(0) === '-' ? 'var(--errorcolor)' : 'var(--textcolor)'}">{{getMonthTrend(tyreSalesTrend)}}</span>
                    </div>
                    <div style="font-size: 1.2rem; padding-top: 8px;">
                        3 Month Sales Trend ({{getQuarterRange(tyreSalesTrend)}}): <span [ngStyle]="{'color': getQuarterTrend(tyreSalesTrend).charAt(0) === '-' ? 'var(--errorcolor)' : 'var(--textcolor)'}">{{getQuarterTrend(tyreSalesTrend)}}</span>
                    </div>
                </div>
            </nz-spin>
        </ng-container>
    </nz-modal>
    <!-- Alt Stock View ----------------------------------------------------------- -->

    <nz-modal [(nzVisible)]="this.altStock" nzTitle="Alternative stock for item: {{this.altItem}}" (nzOnCancel)="this.altStock = false" [nzFooter]="null" nzTheme="fill" nzWidth=99.56%>
        <nz-spin [nzSpinning]="isLoading_ALT">
            <ng-container *nzModalContent>
                <div style="display:flex">
                    <button  nz-button nztype="default" (click)="holdItem(this.altItemId)">Hold Item</button>
                    <button  nz-button nztype="default" (click)="deleteModel(this.altItemId)" style="margin-left:1%;">Disable Item</button>
                    <!-- <button  nz-button nztype="default" (click)="deleteModel(this.altItemId)" style="margin-left:1%;">Enable Item</button> -->
                </div>
                <hr>
                <div style="width: 100%; display: flex;">
                    <div style="float: right; width: 20%; margin-left: 72%; padding-bottom: 5px;">
                        <nz-dropdown-menu #supplier_menu="nzDropdownMenu">
                            <ul nz-menu nzSelectable style="border: solid 1px var(--textcolor); border-radius: 8px;">
                                <ul nz-menu nzSelectable *ngFor="let supplier of suppliersTemp">
                                    <li nz-menu-item *ngIf="supplier.repActive == true && ['WAREHOUSE','WHOLESALE','REMOTE','FITMENT'].includes(supplier.type)" (click)="groupBy(supplier)"><b>{{supplier.supplier_name}}</b></li>

                                </ul>
                                <li nz-menu-item (click)="reset_to_bestcost()" style="color: var(--warningcolor);"><b>Best Cost</b></li>
                            </ul>
                            <hr>
                        </nz-dropdown-menu>
                    </div>

                </div>
                <nz-table #lines [nzData]="size_inquiry" style="width: auto;" [nzFrontPagination]="false" [nzScroll]="{'y':'450px'}" nzSize="small">
                    <thead>
                        <tr>
                            <th [nzSortFn]="sortBrandAlt" [nzWidth]="'6%'">Brand</th>
                            <th [nzWidth]="'6%'"></th>
                            <th [nzSortFn]="sortDescriptionAlt" [nzWidth]="'15%'">Description</th>
                            <th [nzSortFn]="sortCodeAlt" [nzWidth]="'6%'">Code</th>
                            <th [nzSortFn]="sortSoldAlt" [nzWidth]="'6%'" nz-tooltip nzTooltipTitle="Units sold for the past 120 days">Sold</th>
                            <th [nzSortFn]="sortSOHAlt" [nzWidth]="'6%'">SOH</th>
                            <th [nzSortFn]="sortMSLAlt" [nzWidth]="'6%'" nz-tooltip nzTooltipTitle="Model stock level currently allocated">MS</th>
                            <th [nzSortFn]="sortAIMSLAlt" [nzWidth]="'6%'" nz-tooltip nzTooltipTitle="Model stock level suggested by AI">AI MS</th>
                            <!-- <th>Brand_Rank <a (click)="sortTable(7,'F')" id="sortIcon"><i class="fa fa-sort" aria-hidden="true"></i></a></th> -->
                            <th [nzSortFn]="sortNRAlt" [nzWidth]="'6%'" nz-tooltip nzTooltipTitle="National Rank">NR</th>
                            <th [nzSortFn]="sortCostAlt" [nzWidth]="'6%'">Cost</th>
                            <th [nzSortFn]="sortSuppAlt" [nzWidth]="'10%'">Supplier</th>
                            <th [nzSortFn]="sortS_SOHAlt" [nzWidth]="'6%'" nz-tooltip nzTooltipTitle="Supplier SOH">S_SOH</th>
                           

                            <th [nzWidth]="'6%'">Action</th>
                            <th *ngIf="this.editMode==true;">Delete Stock Model</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of lines.data">
                            <td class="doubleCol"><img style="margin: 0 auto;" src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{item.brandLogo}}" class="brandImage" /></td>
                            <!--"https://sftptyres.blob.core.windows.net/brands-images/brands/{{item.brandLogoUrl}}"-->
                            <!-- <td  [ngStyle]="{'color':item.stock_code == cheapest_code  ? 'brown' : 'white' }">{{item.description}}</td> -->
                            <td><span nz-icon nzType="stock" nzTheme="outline" nz-tooltip="Sales Trend" style="color: var(--textcolor);font-size: 2rem !important;cursor: pointer" (click)="viewSalesTrendTyre(item.msfid)"></span></td>
                            <!-- <td [ngStyle]="{'color':item.description == this.altItem ? 'var(--warningcolor)' : 'var(--textcolor)' }">{{item.description}}</td> -->
                            <td *ngIf="item.description == this.altItem" class="inquiryItem">{{item.description}}</td>
                            <td *ngIf="item.description != this.altItem">{{item.description}}</td>
                            <td>{{item.stock_code}}</td>
                            <td style="text-align: center;" *ngIf="item.sales != -1">{{item.sales}}</td>
                            <td style="text-align: center;" *ngIf="item.sales == -1">-</td>
                            <td style="text-align: center;">{{item.soh}}<span *ngIf="item.back_order_qty >0" style="color: cadetblue; cursor:ponter;" nz-tooltip nzTooltipTitle="SOH | Back order Quantity"> | {{item.back_order_qty}}</span></td>
                            <td style="text-align: center;" *ngIf="this.editMode == true && this.editID != item.msfid">{{item.modelStock}}</td>
                            <td style="text-align: center;" *ngIf="this.editMode == false;">{{item.modelStock}}</td>
                            <td style="text-align: center;" *ngIf="this.editMode == true && this.editID == item.msfid">
                                <input nz-input placeholder="{{item.modelStock}}" type="number" min="0" id="msl_suggest" style="width: 50%;" autocomplete="off" />
                            </td>
                            <td style="text-align: center;">{{item.suggested_modelStock}}</td>
                            <!-- <td style="text-align: center;">{{item.brand_rank}}</td> -->
                            <td style="text-align: center;" *ngIf="item.sales != -1">{{item.national_rank}}</td>
                            <td style="text-align: center;" *ngIf="item.sales == -1">{{item.national_rank}}</td>
                            <td style="text-align: center;">{{item.best_cost}}</td>
                            <td style="text-align: center;">{{item.best_supplier}} </td>
                            <td style="text-align: center;" *ngIf="item.supplier_soh <= item.supplier_sohMax">{{item.supplier_soh}}</td>
                            <td style="text-align: center;" *ngIf="item.supplier_soh > item.supplier_sohMax">{{item.supplier_sohMax}}+</td>
                            

                            <td *ngIf="item.stockReplenishmentId != 0 && (item.best_supplier == '' || (item.listed_supplier != item.best_supplier))"><a><button nz-button nztype="default" style="border: solid 3px var(--textcolor);" (click)="editItem(item,'A')"><i nz-icon nzType="plus" nz-tooltip nzTooltipTitle="Add Item to Model Stock"></i></button></a></td>
                            <td *ngIf="item.stockReplenishmentId != 0 && (item.best_supplier != '' && (item.listed_supplier == item.best_supplier));"><a><button nz-button nztype="default" style="border: solid 3px var(--textcolor);" (click)="editItem(item,'E')"><i nz-icon nzType="edit" nz-tooltip nzTooltipTitle="Edit Item"></i></button></a></td>
                            <td *ngIf="item.stockReplenishmentId == 0;"><a><button nz-button nztype="default" style="border: solid 3px var(--textcolor);" (click)="editItem(item,'A')"><i nz-icon nzType="plus" nz-tooltip nzTooltipTitle="Add Item to Model Stock"></i></button></a></td>
                            <!-- <td *ngIf="item.stockReplenishmentId == 0 && item.modelStock ==0" style="text-align: center;"><button nz-button nztype="default" (click)="editItem(item,'A')" style="position: absolute; right: 20px;"><i class="fa fa-plus" nz-tooltip nzTooltipTitle="Add Item to Model Stock"></i></button></td> -->

                            <td *ngIf="item.replenish_active == false;" style="text-align: center;"><a style="color: var(--warningcolor);" id="inactive_supplier"><i  nz-icon nzType="info-circle" aria-hidden="true" nz-tooltip nzTooltipTitle="None of your active suppliers are carrying this stock"></i></a></td>
                            <!-- <td *ngIf="item.msfid == this.editID && this.editMode == true;"><button nz-button nztype="default" (click)="saveChanges_P2(item)"><i nz-icon nzType="save"  nz-tooltip nzTooltipTitle="SAVE MSL"></i></button></td> -->
                            <td *ngIf="item.msfid == this.editID && this.editMode == true;">
                                <!-- <button nz-button nztype="default" (click)="deleteModel(item)">
                            <i class="fa fa-trash-alt" style="font-size: 1.5rem; vertical-align: top; " nz-tooltip nzTooltipTitle="Delete Item"></i>
                            </button> -->
                            </td>


                        </tr>
                    </tbody>
                </nz-table>

            </ng-container>
        </nz-spin>
    </nz-modal>

    <nz-tabset nzCentered nzSize="large" class="bg-1" [(nzSelectedIndex)]="currTable">
        <nz-tab nzTitle="Bulk Order">
            <nz-modal [(nzVisible)]="this.viewCost" (nzOnCancel)="this.viewCost = false" [nzFooter]="null" nzTheme="fill" nzWidth=90%>
                <ng-container *nzModalContent>
                    <nz-tabset nzCentered nzSize="large" class="bg-1">
                        <nz-tab nzTitle="Replenishable Items">
                            <div class="flex-container">
                                <!-- <div style="padding-bottom: 0.01%;"><h6 style="color: white;">stock Cost: R{{this.no_discount_total}}</h6></div>
                                <div style="padding-bottom: 0.01%;">
                                    <h6  [ngStyle]="{'color':this.no_discount_total < this.minOrder ? 'red' : 'green' }">Minimum Order Fee: R{{this.minOrder}}</h6>
                                    <h6  [ngStyle]="{'color':this.no_discount_total < this.minOrder ? 'red' : 'green' }">Difference: R{{this.no_discount_total-this.minOrder}}</h6> -->
                                <!-- <h6 *ngIf="this.no_discount_total < this.minOrder" style="color: red;" [ngStyle]="{'color':this.no_discount_total < this.minOrder ? 'red' : 'green' }">Difference: R{{this.no_discount_total-this.minOrder}}</h6> -->
                                <!-- </div> -->
                                <br>
                                <!-- <div style="padding-bottom: 1%;"><h6 style="color: white;">Supplier Discount: {{this.repDiscount}}% | Savings: R{{(this.no_discount_total - this.stockTotal).toFixed(2) }} </h6></div> -->
                                <div style="padding-bottom: 1%;">
                                    <h6 style="margin-left: 5px;">Total cost: <u>R{{this.stockTotal | number : '1.2-2'}}</u></h6>
                                </div>
                                <div style="margin-left: 5px;" *ngIf="this.stockBalance_partials.length >0;"><a style="color: var(--warningcolor);"><i  nz-icon nzType="info-circle" aria-hidden="true"></i> {{this.stockBalance_partials.length}} item(s) available for a partial fill.</a></div>
                                <div style="margin-left: 5px;" *ngIf="this.similarity == true;"><a style="color: var(--warningcolor);"><i nz-icon nzType="flag" aria-hidden="true"></i> {{this.similarCount}} item(s) / {{this.stockBalance.length}} have appeared on orders in the past 3 days.</a></div>

                                <div style="float: right; padding-bottom: 1%; padding-right: 5%;">
                                    <nz-spin [nzSpinning]="isLoadingOrder">
                                        <button nz-button nzType="default" *ngIf="this.no_discount_total >= this.minOrder && this.order_possible == true;" (click)="confirmOrder(stockBalance)" style="padding: 2px"> Place Order</button><br>
                                    </nz-spin>
                                    <p *ngIf="this.order_possible == false;">Supplier has not been set up for email or POS ordering,<br> Please order from supplier portal.
                                        <button nz-button nztype="default" nz-tooltip="Download Order as Excel Document , Pricing may differ" (click)="download_doc('ORDER')">
                                        <a><i nz-icon nzType="download" aria-hidden="true"></i></a>
                                    </button>
                                    </p>
                                </div>

                            </div>
                            <nz-table #lines [nzData]="stockBalance" [nzFrontPagination]="false" id="Main_Order" nzSize="small" [nzScroll]="{'y':'700px'}">
                                <thead>
                                    <tr>
                                        <th [nzSortFn]="sortDescSC" [nzWidth]="'300px'">Description</th>
                                        <th [nzSortFn]="sortBrandSC">Brand</th>
                                        <th [nzSortFn]="sortCodeSC">Code</th>
                                        <th [nzSortFn]="sortMSLSC">MSL</th>
                                        <th [nzSortFn]="sortSOHSC">SOH</th>
                                        <th [nzSortFn]="sortQTYSC">Qty</th>
                                        <th [nzSortFn]="sortS_SOHSC">S_SOH</th>
                                        <th [nzSortFn]="sortS_PriceSC">Cost <span class="text-style">(Ex VAT)</span></th>
                                        <th [nzSortFn]="sortTotalSC">Total Cost <span class="text-style">(Ex VAT)</span></th>
                                        <th>Edit </th>
                                        <th>Delete </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of lines.data">
                                        <td style="text-align: left;">
                                            <div style="display: flex; width: 60%;">{{item.description}}
                                                <div *ngIf="item.similarity == true">
                                                    <div nz-dropdown [nzDropdownMenu]="similarity_menu" style="display: flex;"><a style="color: var(--warningcolor); position: absolute; right: 5px;"><i nz-icon nzType="flag" aria-hidden="true"></i></a></div>
                                                    <div style="color: var(--textcolor); position: absolute; right: 25px;">[{{item.previousOrders.length}} | {{item.previousSales.length}}]</div>
                                                </div>

                                                <nz-dropdown-menu #similarity_menu="nzDropdownMenu">
                                                    <nz-card width="200">
                                                        <span style="display: flex;">Orders placed for <p style="color: var(--warningcolor);"> {{item.description}} </p> in the past 3 days</span><br>
                                                        <nz-tabset>
                                                            <nz-tab nzTitle="Purchases">
                                                                <nz-table #lines [nzData]="item.previousOrders" [nzFrontPagination]="false" [nzFooter]="null">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>order_ref</th>
                                                                            <th>seller_name</th>
                                                                            <th>qty</th>
                                                                            <th>date</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr *ngFor="let order of item.previousOrders">
                                                                            <td>{{order.orderRef}}</td>
                                                                            <td>{{order.sellerName}}</td>
                                                                            <td>{{order.qty}}</td>
                                                                            <td>{{order.dt}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </nz-table>
                                                            </nz-tab>
                                                            <nz-tab nzTitle="Sales">
                                                                <nz-table #lines [nzData]="item.previousSales" [nzFrontPagination]="false" [nzFooter]="null">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>order_ref</th>
                                                                            <th>buyer_name</th>
                                                                            <th>qty</th>
                                                                            <th>date</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr *ngFor="let order of item.previousSales">
                                                                            <td>{{order.orderRef}}</td>
                                                                            <td>{{order.buyerName}}</td>
                                                                            <td>{{order.qty}}</td>
                                                                            <td>{{order.dt}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </nz-table>
                                                            </nz-tab>
                                                        </nz-tabset>
                                                    </nz-card>
                                                </nz-dropdown-menu>

                                            </div>


                                        </td>
                                        <td style="text-align: center; border-right: solid 0.01px var(--textcolor); border-left: solid 0.01px var(--textcolor);"><img style="margin: 0 auto;" src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{item.brandLogo}}" class="brandImage" nz-tooltip="{{item.brand}}" /></td>
                                        <td style="text-align: center;">{{item.code}}</td>
                                        <td style="text-align: center;">{{item.msl}}</td>
                                        <td style="text-align: center;">{{item.soh}}<span *ngIf="item.back_order_qty >0" style="color: cadetblue; cursor:ponter;" nz-tooltip nzTooltipTitle="SOH | Back order Quantity"> | {{item.back_order_qty}}</span></td>
                                        <td *ngIf="this.indexMSFID != item.msfid" style="text-align: center;">{{item.qty}}</td>
                                        <td *ngIf="this.indexMSFID == item.msfid" style="text-align: center;">
                                            <input nz-tooltip nzTooltipTitle="Supplier has {{item.sohSupplier}} unit(s) available" nz-input placeholder="{{item.qty}}" value="{{item.qty}}" type="number" min="0" max="{{item.sohSupplier}}" id="editQty" style="width: 90%;" autocomplete="off" />
                                        </td>
                                        <td style="text-align: center;">{{item.sohSupplier}}</td>
                                        <td style="text-align: center;">{{item.price}}</td>
                                        <td style="text-align: center;">{{item.price * item.qty | number : '1.2-2'}}</td>

                                        <td *ngIf="this.indexMSFID != item.msfid" style="text-align: center;">
                                            <button nz-button nzType="default" (click)="editLine(item)">
                                        <i nz-icon nzType="edit" nz-tooltip nzTooltipTitle="Edit Item"></i>
                                    </button>
                                        </td>
                                        <td *ngIf="this.indexMSFID == item.msfid" style="text-align: center;">
                                            <button nz-button nzType="default" (click)="saveLine(item)">
                                        <i nz-icon nzType="save" nz-tooltip nzTooltipTitle="Save"></i>
                                    </button>
                                        </td>

                                        <td style="text-align: center;">
                                            <button nz-button nzType="default" (click)="delFromOrder(item)" nz-tooltip nzTooltipTitle="Delete Item">
                                                <a style="color:red;"><i nz-icon nzType="delete"  aria-hidden="true"  ></i></a>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </nz-table>
                        </nz-tab>
                        <nz-tab nzTitle="Partial Fills">
                            <nz-table #stockBalance_partialsSet [nzData]="stockBalance_partials" [nzFrontPagination]="false" nzSize="small" id="Additional_Order" [nzScroll]="{'y':'700px'}">
                                <thead>
                                    <tr>
                                        <th [nzSortFn]="sortDescSC">Description</th>
                                        <th [nzSortFn]="sortBrandSC">Brand</th>
                                        <th [nzSortFn]="sortCodeSC">Code</th>
                                        <th [nzSortFn]="sortMSLSC">MSL</th>
                                        <th [nzSortFn]="sortSOHSC">SOH</th>
                                        <th [nzSortFn]="sortQTYSC">Qty</th>
                                        <th [nzSortFn]="sortS_SOHSC">S_SOH</th>
                                        <th [nzSortFn]="sortS_PriceSC">Cost <span class="text-style">(Ex VAT)</span></th>
                                        <th [nzSortFn]="sortTotalSC">Total COst <span class="text-style">(Ex VAT)</span></th>
                                        <th>Add to order</th>


                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of stockBalance_partialsSet.data">
                                        <td>
                                            <div style="display: flex; width: 100%;">{{item.description}}
                                                <div style="position: absolute; right: 5px;"><a style="color: var(--textcolor);" *ngIf="item.holding_status == 1"><i nz-icon nzType="book" aria-hidden="true" nz-tooltip nzTooltipTitle="Holding item"></i></a></div>
                                            </div>
                                        </td>
                                        <td style="text-align: center; border-right: solid 0.01px var(--textcolor); border-left: solid 0.01px var(--textcolor);"><img style="margin:  0 auto;" src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{item.brandLogo}}" class="brandImage" nz-tooltip="{{item.brand}}" /></td>
                                        <td style="text-align: center;">{{item.code}}</td>
                                        <td style="text-align: center;">{{item.msl}}</td>
                                        <td style="text-align: center;">{{item.soh}}<span *ngIf="item.back_order_qty >0" style="color: cadetblue; cursor:ponter;" nz-tooltip nzTooltipTitle="SOH | Back order Quantity"> | {{item.back_order_qty}}</span></td>
                                        <td *ngIf="this.indexMSFID != item.msfid" style="text-align: center;">{{item.qty}}</td>
                                        <td *ngIf="this.indexMSFID == item.msfid" style="text-align: center;">
                                            <input nz-input placeholder="{{item.qty*-1}}" type="number" min="0" id="editQty" style="width: 50%;" autocomplete="off" />
                                        </td>
                                        <td style="text-align: center;">{{item.sohSupplier}}</td>
                                        <td style="text-align: center;">{{item.price}}</td>
                                        <td style="text-align: center;">{{item.price * item.qty | number : '1.2-2'}}</td>

                                        <td *ngIf="item.price > 0 && item.qty > item.sohSupplier " style="border-top: solid var(--warningcolor); border-bottom: solid var(--warningcolor); border-width:1px; text-align: center;">
                                            <a style="color: var(--warningcolor);" (click)="partial_fill(item)"><i nz-icon nzType="info-circle" aria-hidden="true" nz-tooltip nzTooltipTitle="Supplier only has {{item.sohSupplier}} units, click alert to do a partial fill"></i></a>
                                        </td>

                                        <td *ngIf="item.price > 0 && item.qty <= item.sohSupplier" style="text-align: center;">
                                            <a><button nz-button nzType="default" (click) = addToOrder(item)><i nz-icon nzType="plus" aria-hidden="true"></i></button></a>
                                        </td>
                                        <!-- <td *ngIf="item.price == 0 or item.price==''" style="border-top: solid red; border-bottom: solid red; border-width:1px; text-align: center;">
                                    <a  style="color: red;"><i nz-icon nzType="info-circle" aria-hidden="true" nz-tooltip nzTooltipTitle="No Pricing Available For This Item"></i></a>
                                </td> -->
                                        <td *ngIf="item.price == 0 or item.price==''" style="text-align: center;">
                                            <a style="color: var(--textcolor);" nz-tooltip nzTooltipTitle="The supplier does not have this item in stock"><b>-</b></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </nz-table>
                        </nz-tab>
                        <nz-tab nzTitle="Additional Items">
                            <nz-table #stockBalance_addSet [nzData]="stockBalance_add" [nzFrontPagination]="false" nzSize="small" id="Additional_Order" [nzScroll]="{'y':'700px'}">
                                <thead>
                                    <tr>
                                        <th [nzSortFn]="sortDescSC">Description</th>
                                        <th [nzSortFn]="sortBrandSC">Brand</th>
                                        <th [nzSortFn]="sortCodeSC">Code</th>
                                        <th [nzSortFn]="sortMSLSC">MSL</th>
                                        <th [nzSortFn]="sortSOHSC">SOH</th>
                                        <th [nzSortFn]="sortQTYSC">Qty</th>
                                        <th [nzSortFn]="sortS_SOHSC">S_SOH</th>
                                        <th [nzSortFn]="sortS_PriceSC">Cost <span class="text-style">(Ex VAT)</span></th>
                                        <th [nzSortFn]="sortTotalSC">Total COst <span class="text-style">(Ex VAT)</span></th>
                                        <th>Add to order</th>


                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of stockBalance_addSet.data">
                                        <td>
                                            <div style="display: flex; width: 100%;">{{item.description}}
                                                <div style="position: absolute; right: 5px;"><a style="color: var(--textcolor);" *ngIf="item.holding_status == 1"><i nz-icon nzType="book" aria-hidden="true" nz-tooltip nzTooltipTitle="Holding item"></i></a></div>
                                            </div>
                                        </td>
                                        <td style="text-align: center; border-right: solid 0.01px var(--textcolor); border-left: solid 0.01px var(--textcolor);"><img style="margin:  0 auto;" src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{item.brandLogo}}" class="brandImage" nz-tooltip="{{item.brand}}" /></td>
                                        <td style="text-align: center;">{{item.code}}</td>
                                        <td style="text-align: center;">{{item.msl}}</td>
                                        <td style="text-align: center;">{{item.soh}}<span *ngIf="item.back_order_qty >0" style="color: cadetblue; cursor:ponter;" nz-tooltip nzTooltipTitle="SOH | Back order Quantity"> | {{item.back_order_qty}}</span></td>
                                        <td *ngIf="this.indexMSFID != item.msfid" style="text-align: center;">{{item.qty}}</td>
                                        <td *ngIf="this.indexMSFID == item.msfid" style="text-align: center;">
                                            <input nz-input placeholder="{{item.qty*-1}}" type="number" min="0" id="editQty" style="width: 50%;" autocomplete="off" />
                                        </td>
                                        <td style="text-align: center;">{{item.sohSupplier}}</td>
                                        <td style="text-align: center;">{{item.price}}</td>
                                        <td style="text-align: center;">{{item.price * item.qty | number : '1.2-2'}}</td>

                                        <td *ngIf="item.price > 0 && item.qty > item.sohSupplier " style="border-top: solid var(--warningcolor); border-bottom: solid var(--warningcolor); border-width:1px; text-align: center;">
                                            <a style="color: var(--warningcolor);" (click)="partial_fill(item)"><i nz-icon nzType="info-circle" aria-hidden="true" nz-tooltip nzTooltipTitle="Supplier only has {{item.sohSupplier}} units, click alert to do a partial fill"></i></a>
                                        </td>

                                        <td *ngIf="item.price > 0 && item.qty <= item.sohSupplier" style="text-align: center;">
                                            <a><button nz-button nzType="default" (click) = addToOrder(item)><i nz-icon nzType="plus" aria-hidden="true"></i></button></a>
                                        </td>
                                        <!-- <td *ngIf="item.price == 0 or item.price==''" style="border-top: solid red; border-bottom: solid red; border-width:1px; text-align: center;">
                                    <a  style="color: red;"><i nz-icon nzType="info-circle" aria-hidden="true" nz-tooltip nzTooltipTitle="No Pricing Available For This Item"></i></a>
                                </td> -->
                                        <td *ngIf="item.price == 0 or item.price==''" style="text-align: center;">
                                            <a style="color: var(--textcolor);" nz-tooltip nzTooltipTitle="The supplier does not have this item in stock"><b>-</b></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </nz-table>
                        </nz-tab>

                        <nz-tab nzTitle="Holding Items" *ngIf="this.stockBalance_holding.length >0">
                            <nz-table #lines [nzData]="stockBalance_holding" [nzFrontPagination]="false" nzSize="small" id="Holding_Order" [nzScroll]="{'y':'700px'}">
                                <thead>
                                    <tr>
                                        <th [nzSortFn]="sortDescSC">Description</th>
                                        <th [nzSortFn]="sortBrandSC">Brand</th>
                                        <th [nzSortFn]="sortCodeSC">Code</th>
                                        <th [nzSortFn]="sortMSLSC">MSL</th>
                                        <th [nzSortFn]="sortSOHSC">SOH</th>
                                        <th [nzSortFn]="sortQTYSC">Quantity</th>
                                        <th [nzSortFn]="sortS_SOHSC">SOH Supplier</th>
                                        <th [nzSortFn]="sortS_PriceSC">Price <span class="text-style">(Ex VAT)</span></th>
                                        <th [nzSortFn]="sortTotalSC">Total Price <span class="text-style">(Ex VAT)</span></th>
                                        <!-- <th>Edit </th>
                                        <th>Delete </th> -->

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of lines.data">
                                        <td>
                                            <div style="display: flex; width: 100%;">{{item.description}}
                                                <div style="position: absolute; right: 5px;"><a style="color: var(--textcolor);" *ngIf="item.holding_status == 1"><i nz-icon nzType="book" aria-hidden="true" nz-tooltip nzTooltipTitle="Holding item"></i></a></div>
                                            </div>
                                        </td>
                                        <td style="text-align: center; border-right: solid 0.01px var(--textcolor); border-left: solid 0.01px var(--textcolor);"><img style="margin:  0 auto;" src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{item.brandLogo}}" class="brandImage" nz-tooltip="{{item.brand}}" /></td>
                                        <td style="text-align: center;">{{item.code}}</td>
                                        <td style="text-align: center;">{{item.msl}}</td>
                                        <td style="text-align: center;">{{item.soh}}<span *ngIf="item.back_order_qty >0" style="color: cadetblue; cursor:ponter;" nz-tooltip nzTooltipTitle="SOH | Back order Quantity"> | {{item.back_order_qty}}</span></td>
                                        <td *ngIf="this.indexMSFID != item.msfid" style="text-align: center;">{{item.qty*-1}}</td>
                                        <td *ngIf="this.indexMSFID == item.msfid" style="text-align: center;">
                                            <input nz-input placeholder="{{item.qty*-1}}" type="number" min="0" id="editQty" style="width: 50%;" autocomplete="off" />
                                        </td>
                                        <td style="text-align: center;">{{item.sohSupplier}}</td>
                                        <td style="text-align: center;">{{item.price}}</td>
                                        <td style="text-align: center;">{{item.price * item.qty*-1}}</td>

                                        <!-- <td *ngIf="item.price > 0 && item.qty*-1 > item.sohSupplier " style="border-top: solid var(--warningcolor); border-bottom: solid var(--warningcolor); border-width:1px; text-align: center;">
                                            <a style="color: var(--warningcolor);" (click)="partial_fill(item)"><i nz-icon nzType="info-circle" aria-hidden="true" nz-tooltip nzTooltipTitle="Supplier only has {{item.sohSupplier}} units, click alert to do a partial fill"></i></a>
                                        </td>

                                        <td *ngIf="item.price > 0 && item.qty*-1 <= item.sohSupplier" style="text-align: center;">
                                            <a><button nz-button nztype="default" (click) = addToOrder(item)><i nz-icon nzType="plus" aria-hidden="true"></i></button></a>
                                        </td> -->
                                        <!-- <td *ngIf="item.price == 0 or item.price==''" style="border-top: solid red; border-bottom: solid red; border-width:1px; text-align: center;">
                                    <a  style="color: red;"><i nz-icon nzType="info-circle" aria-hidden="true" nz-tooltip nzTooltipTitle="No Pricing Available For This Item"></i></a>
                                </td> -->
                                        <td *ngIf="item.price == 0 or item.price==''" style="text-align: center;">
                                            <a style="color: var(--textcolor);" nz-tooltip nzTooltipTitle="The supplier does not have this item in stock"><b>-</b></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </nz-table>
                        </nz-tab>
                        <nz-tab *ngIf="this.stockBalance_disabled.length >0" nzTitle="Disabled">
                            <nz-table #lines [nzData]="stockBalance_disabled" [nzFrontPagination]="false" nzSize="small" id="Disabled_Order" [nzScroll]="{'y':'700px'}">
                                <thead>
                                    <tr>
                                        <th [nzSortFn]="sortDescSC">Description</th>
                                        <th [nzSortFn]="sortBrandSC">Brand</th>
                                        <th [nzSortFn]="sortCodeSC">Code</th>
                                        <th [nzSortFn]="sortMSLSC">MSL</th>
                                        <th [nzSortFn]="sortSOHSC">SOH</th>
                                        <th [nzSortFn]="sortQTYSC">Quantity</th>
                                        <th [nzSortFn]="sortS_SOHSC">SOH Supplier</th>
                                        <th [nzSortFn]="sortS_PriceSC">Price <span class="text-style">(Ex VAT)</span></th>
                                        <th [nzSortFn]="sortTotalSC">Total Price <span class="text-style">(Ex VAT)</span></th>
                                        <th>Add to order </th>


                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of lines.data">
                                        <td>{{item.description}}</td>
                                        <td style="text-align: center; border-right: solid 0.01px var(--textcolor); border-left: solid 0.01px var(--textcolor);"><img style="margin:  0 auto;" src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{item.brandLogo}}" class="brandImage" nz-tooltip="{{item.brand}}" /></td>
                                        <td style="text-align: center;">{{item.code}}</td>
                                        <td style="text-align: center;">{{item.msl}}</td>
                                        <td style="text-align: center;">{{item.soh}}<span *ngIf="item.back_order_qty >0" style="color: cadetblue; cursor:ponter;" nz-tooltip nzTooltipTitle="SOH | Back order Quantity"> | {{item.back_order_qty}}</span></td>
                                        <td *ngIf="this.indexMSFID != item.msfid" style="text-align: center;">{{item.qty*-1}}</td>
                                        <td *ngIf="this.indexMSFID == item.msfid" style="text-align: center;">
                                            <input nz-input placeholder="{{item.qty*-1}}" type="number" min="0" id="editQty" style="width: 50%;" autocomplete="off" />
                                        </td>
                                        <td style="text-align: center;">{{item.sohSupplier}}</td>
                                        <td style="text-align: center;">{{item.price}}</td>
                                        <td style="text-align: center;">{{item.price * item.qty*-1}}</td>

                                        <!-- <td *ngIf="item.price > 0 && item.qty*-1 > item.sohSupplier "style="border-top: solid var(--warningcolor); border-bottom: solid var(--warningcolor); border-width:1px; text-align: center;">
                                    <a style="color: var(--warningcolor);" (click)="partial_fill(item)"><i nz-icon nzType="info-circle" aria-hidden="true" nz-tooltip nzTooltipTitle="Supplier only has {{item.sohSupplier}} units, click alert to do a partial fill"></i></a>
                                </td> -->

                                        <!-- <td *ngIf="item.price > 0 && item.qty*-1 <= item.sohSupplier" style="text-align: center;">
                                    <a ><button nz-button nztype="default" class = 'btn' (click) = addToOrder(item)><i class="fa fa-plus" aria-hidden="true"></i></button></a>
                                </td> -->
                                        <!-- <td *ngIf="item.price == 0 or item.price==''" style="border-top: solid red; border-bottom: solid red; border-width:1px; text-align: center;">
                                    <a  style="color: red;"><i nz-icon nzType="info-circle" aria-hidden="true" nz-tooltip nzTooltipTitle="No Pricing Available For This Item"></i></a>
                                </td> -->
                                        <td style="text-align: center;">
                                            <a style="color: var(--textcolor);" nz-tooltip nzTooltipTitle="The supplier does not have this item in stock"><b>-</b></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </nz-table>
                        </nz-tab>
                    </nz-tabset>
                </ng-container>
            </nz-modal>

            <nz-spin [nzSpinning]="isLoading">
                <nz-table [nzData]="this.BulkOrders" [nzFrontPagination]="false" nzSize="small">
                    <thead>
                        <tr>
                            <th style="text-align: center;">Supplier_Name</th>
                            <th style="text-align: center;">Line Items</th>
                            <th style="text-align: center;">Total Items</th>
                            <th style="text-align: center;">Cost</th>
                            <th style="text-align: center;">View/Edit Summary</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let supp of this.BulkOrders" style="padding: 1px;">

                            <td style="text-align: center;">{{supp.supplier_name}}_{{supp.order_type}} <a *ngIf="supp.replenish_active != true"><i nz-icon nzType="info-circle" aria-hidden="true" nz-tooltip nzTooltipTitle="Supplier deactivated replenishment"></i></a></td>
                            <td style="text-align: center;">{{supp.line_items}}</td>
                            <td style="text-align: center;">{{supp.line_qtys}}</td>
                            <td style="text-align: center;">R{{supp.total | number : '1.2-2'}}</td>
                            <td style="text-align: center;">
                                <nz-spin [nzSpinning]="isLoading">
                                    <div nz-row style="display:flex;">
                                        <div nz-col nzSpan="6" nzOffset="6">
                                            <button nz-button nzType="default" (click)="generateOrderGroups(supp);" *ngIf="supp.replenish_active == true">
                                                VIEW
                                            </button>
                                        </div>
                                        <div nz-col nzSpan="6" nzOffset="1">
                                            <button *ngIf="liveChecksDone.includes(supp.supplierId) == false" nz-button nzType="primary" class="btn_border" nz-tooltip="Get Live Stock for {{supp.supplier_name}} "  (click)="liveStockCheck(supp.supplierId)">
                                                <a style="color: white;"><i nz-icon nzType="vertical-align-bottom" aria-hidden="true"></i></a>
                                                <!-- <span nz-icon nzType="vertical-align-bottom" nzTheme="outline"></span> -->
                                            </button>
                                            <button *ngIf="liveChecksDone.includes(supp.supplierId) == true" nz-button nzType="primary"  class="btn_border" style="background-color: green; border-color: green;" nz-tooltip="Live Stock for {{supp.supplier_name}} has been retrieved"  (click)="liveStockCheck(supp.supplierId)">
                                                <a style="color: white;"><i nz-icon nzType="check" aria-hidden="true"></i></a>
                                                <!-- <span nz-icon nzType="vertical-align-bottom" nzTheme="outline"></span> -->
                                            </button>
                                        </div>
                                    </div>
                                </nz-spin>
                            </td>
                        </tr>
                    </tbody>
                </nz-table>
            </nz-spin>
        </nz-tab>

        <nz-tab nzTitle="Replenishable" (nzClick)="setReplenishables()">
            <nz-spin [nzSpinning]="isLoading">
                <nz-row>
                    <nz-col [nzSpan]="'4'" nzOffset="20">
                         <div style="display:flex"><label><button (click)="clearBrandFilter()" *ngIf="this.filteredBrand_Rep != 'Brands'" nz-button nzType="primary" class="btn_border" nz-tooltip="clear filter">X</button><span style="margin-left:2px;">Filter : {{this.filteredBrand_Rep}} </span></label></div>
                         <nz-select [(ngModel)]="replenishableItems" id="filterStyle" (ngModelChange)="filterReason($event)">
                             <nz-option *ngFor="let item of brandsGathered" [nzValue]="item" [nzLabel]="item"></nz-option>
                         </nz-select><br>
                     </nz-col>
                 </nz-row>
                <nz-table #Replenishable_Set [nzData]="replenishableItems" id="Replenishable_Set" [nzFrontPagination]="false" nzSize="small"  [nzScroll]="{'y':'700px'}">
                    <thead style="position: sticky;">
                        <tr>
                            <th [nzSortFn]="sortSupplier" [nzWidth]="'15%'">Supplier</th>
                            <th [nzSortFn]="sortBrand" [nzWidth]="'8%'">Brand</th>
                            <th [nzSortFn]="sortCode" [nzWidth]="'8%'">Code</th>
                            <th [nzSortFn]="sortDescription" [nzWidth]="'15%'">Description</th>
                            <th [nzSortFn]="sortMSL" [nzWidth]="'8%'">MSL</th>
                            <th [nzSortFn]="sortSOH" [nzWidth]="'8%'">SOH</th>
                            <th [nzSortFn]="sortWIP" *ngIf="this.wip_visible==true" style="color:var(--textcolor)">WIP</th>
                            <th [nzSortFn]="sortDiff" [nzWidth]="'8%'">diff</th>
                            <th [nzSortFn]="sortS_SOH" [nzWidth]="'8%'" nz-tooltip nzTooltipTitle="Suppliers stock on hand">S_SOH </th>
                            <th [nzSortFn]="sortS_Price" [nzWidth]="'8%'">Cost</th>
                            <th [nzSortFn]="sortS_Sales" *ngIf="this.editMode == false" [nzWidth]="'8%'">Sales Trend</th>
                            <th *ngIf="this.editMode == true" [nzWidth]="'8%'">Save</th>
                            <th *ngIf="this.editMode == true" [nzWidth]="'8%'">Cancel</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of Replenishable_Set.data" style="padding: 1px;">
                            <td *ngIf="this.editMode == false" style="text-align: center;">{{item.supplier_name}} <a *ngIf="item.replenish_active != true" (click)="trackAltStock(item,'A'); this.altStock=true;"><i nz-icon nzType="info-circle" aria-hidden="true" nz-tooltip nzTooltipTitle="Supplier deactivated replenishment"></i></a>                                </td>
                            <td *ngIf="this.editMode == true && this.editId != item.modelId">{{item.supplier_name}} <a *ngIf="item.replenish_active != true" (click)="trackAltStock(item,'A'); this.altStock=true;"><i nz-icon nzType="info-circle" aria-hidden="true" nz-tooltip nzTooltipTitle="Supplier deactivated replenishment"></i></a></td>
                            <td *ngIf="this.editMode == true && this.editId == item.modelId" style="text-align: center;">
                                <input nz-dropdown [nzDropdownMenu]="menu5" placeholder="{{item.supplier_name}}" id="supllierInput" style="width: 50%; text-align: center;" autocomplete="off" />
                                <nz-dropdown-menu #menu5="nzDropdownMenu">
                                    <ul nz-menu nzSelectable *ngFor="let supplier of suppliersTemp">
                                        <li nz-menu-item (click)="chooseSupplier(supplier,0)">{{supplier.supplier_name}}</li>
                                    </ul>
                                </nz-dropdown-menu>
                            </td>
                            <td style="text-align: center; border-right: solid 0.01px var(--textcolor); border-left: solid 0.01px var(--textcolor);"><img style="margin: 0 auto;" src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{item.brandLogo}}" class="brandImage" nz-tooltip="{{item.brand}}" />
                                <div style="display: none;">{{item.brand}}</div>
                            </td>
                            <td style="text-align: center;"><div>{{item.code}}</div><div id="manCode_disp" nz-tooltip nzTooltipTitle="manufacturers stock code"  *ngIf="item.code != item.manufacturers_code && item.manufacturers_code != null">{{item.manufacturers_code}}</div></td>
                            <td>
                                <div>
                                    <div style="display:flex;">
                                        <div class="description_clickable" (click)="inquire_on_size(item)" nz-dropdown [nzDropdownMenu]="menu_basic" nz-tooltip nzTooltipTitle="(click to investigate further)" style="padding-right: 2px; color:cadetblue; cursor:pointer;">{{item.description}}</div>
                                        <!-- <div *ngIf="item.diff*-1>item.sohSupplier && item.sohSupplier == 0 && item.holding_status == 0" style="position: absolute; right:1%; color:red; cursor:pointer;" nz-tooltip nzTooltipTitle="Escalate to Head Office" (click)="addToEscelations(item)"><i nz-icon nzType="mail" aria-hidden="true"></i></div> -->
                                    </div>
                                    <nz-dropdown-menu #menu_basic="nzDropdownMenu">
                                        <ul nz-menu nzSelectable style="border-radius: 8px;">
                                            <li nz-menu-item (click)="deleteModel(item.modelId)" *ngIf="item.holding_status !=2" class="hover_list"><b>Disable Item</b></li>
                                            <li nz-menu-item (click)="holdItem(item.modelId)" *ngIf="item.holding_status !=2" class="hover_list"><b>Hold Item</b></li>
                                            <li nz-menu-item (click)="deleteModel(item.modelId)" *ngIf="item.holding_status ==2" class="hover_list"><b>Re-enable Item</b></li>
                                            <!-- <li nz-menu-item (click)="modify_msl(item.modelId)" class="hover_list"><b>Change MSL</b></li> -->
                                        </ul>
                                    </nz-dropdown-menu>
                                    <a (click)="inquire_on_size(item);" nz-dropdown [nzDropdownMenu]="menu6" *ngIf="item.holding_status == 1 && item.msl >0 " style="color: var(--textcolor); position: absolute; right: 8px; bottom: 20px;"><i nz-icon nzType="book" aria-hidden="true" nz-tooltip nzTooltipTitle="Holding item until stock is available at {{item.supplier_name}}. (click to edit)"></i></a>
                                    <nz-dropdown-menu #menu6="nzDropdownMenu">
                                        <ul nz-menu nzSelectable style="border-radius: 8px;">
                                            <!-- <li nz-menu-item (click)="holdItem(item.modelId)" class="hover_list"><b>Stop Holding Item</b></li> -->
                                            <li nz-menu-item (click)="inquire_on_size(item)" class="hover_list"><b>Investigate Further</b></li>
                                            <!-- <li nz-menu-item (click)="deleteModel(item.modelId)" class="hover_list"><b>Disable Item</b></li> -->
                                        </ul>
                                    </nz-dropdown-menu>
                                    <div style="width: 100%; display: flex;">
                                        <a (click)="inquire_on_size(item);" nz-dropdown [nzDropdownMenu]="menu_disabled" *ngIf="item.holding_status == 2" style="color: var(--warningcolor); position: absolute; right: 15px;">
                                            <div style="position: absolute; right: 5px; bottom: 6px;"><i nz-icon nzType="stop" aria-hidden="true" nz-tooltip nzTooltipTitle="Item Disabled for automated replenishment. (click to edit)"></i></div>
                                        </a>
                                    </div>
                                    <nz-dropdown-menu #menu_disabled="nzDropdownMenu">
                                        <ul nz-menu nzSelectable style="border-radius: 8px; ">
                                            <li nz-menu-item (click)="deleteModel(item.modelId)" class="hover_list"><b>Re-enable Item</b></li>
                                        </ul>
                                    </nz-dropdown-menu>
                                </div>
                            </td>
                            <td *ngIf="this.editMode == false" style="text-align: center;">{{item.msl}}</td>
                            <td *ngIf="this.editMode == true && this.editId != item.modelId" style="text-align: center;">{{item.msl}}</td>
                            <td *ngIf="this.editMode == true && this.editId == item.modelId" style="text-align: center;">
                                <input nz-input placeholder="{{item.msl}}" type="number" placeholder="{{item.msl}}" value="{{item.msl}}" min="0" id="msl" style="width: 50%;" id="editPOI" autocomplete="off" />
                            </td>
                            <td style="text-align: center;" [ngStyle]="{'color':item.live == true ? 'var(--textcolor)': '' }"><b>{{item.soh}}<span *ngIf="item.back_order_qty >0" style="color: cadetblue; cursor:ponter;" nz-tooltip nzTooltipTitle="SOH | Back order Quantity"> | {{item.back_order_qty}}</span></b></td>
                            <td style="text-align: center;" *ngIf="this.wip_visible == true;"><b>- {{item.work_in_progress}}</b></td>
                            <td style="text-align: center;" [ngStyle]="{'color':item.diff < 0 ? 'var(--errorcolor)' : 'var(--textcolor)' }">{{item.diff}}</td>
                            <td style="text-align: center;" [ngStyle]="{'color':item.supplier_live == true ? 'var(--textcolor)' : '' }" style="text-align: center;">{{item.sohSupplier}}
                                <a nz-dropdown [nzDropdownMenu]="menu8" (click)="inquire_on_size(item);" *ngIf="item.diff*-1>item.sohSupplier && item.sohSupplier > 0 && item.holding_status == 0" style="color: var(--warningcolor);"><i  nz-icon nzType="info-circle" aria-hidden="true" nz-tooltip nzTooltipTitle=" Supplier only has {{item.sohSupplier}} unit(s) Partial fill will  be available in order summary"></i></a>
                                <a nz-dropdown [nzDropdownMenu]="menu8" (click)="inquire_on_size(item);" *ngIf="item.diff*-1>item.sohSupplier && item.sohSupplier == 0 && item.holding_status == 0" style="color: var(--warningcolor);"><i  nz-icon nzType="info-circle" aria-hidden="true" nz-tooltip nzTooltipTitle="Supplier does not have enough stock for your MSL"></i></a>
                                <nz-dropdown-menu #menu8="nzDropdownMenu">
                                    <ul nz-menu nzSelectable style="border-radius: 8px;">
                                        <!-- <li nz-menu-item (click)="holdItem(item.modelId)" class="hover_list" *ngIf="item.sohSupplier == 0;"><b>Hold Item</b></li> -->
                                        <li nz-menu-item (click)="inquire_on_size(item)" class="hover_list"><b>Investigate Further</b></li>
                                        <!-- <li nz-menu-item (click)="deleteModel(item.modelId)" class="hover_list"><b>Disable Item</b></li> -->
                                    </ul>
                                </nz-dropdown-menu>
                            </td>

                            <td style="text-align: center;" *ngIf="item.supplierPrice > 0">R {{item.supplierPrice}}</td>
                            <td *ngIf="item.supplierPrice == 0" style="text-align: center;">
                                <a style="color: var(--errorcolor);" nz-tooltip nzTooltipTitle="Pricing can not be applied if supplier has no stock"><b>-</b></a>
                            </td>
                            <td style="text-align: center;">
                                <div style="display: flex">
                                    <span style="color: cadetblue; cursor:ponter; position: absolute; bottom: 5%; right: 30%;" nz-tooltip nzTooltipTitle="sales (120 days)"> | {{item.sales}}</span>
                                    <span *ngIf="this.editMode == false;" nz-icon nzType="stock" nzTheme="outline" nz-tooltip="Sales Trend" style="color: var(--textcolor);font-size: 2rem !important;cursor: pointer" (click)="viewSalesTrendTyre(item.msfId)"></span>
                                </div>
                                <button nz-button nztype="default" *ngIf="this.editMode == true && this.editId == item.modelId" (click)="saveMSLChange(item)">
                                    <i  nz-icon nzType="save" style="font-size: 1.5rem; vertical-align: top; " nz-tooltip nzTooltipTitle="Save Changes"></i>
                                </button>
                            </td>
                            <td style="text-align: center;" *ngIf="this.editMode == true && this.editId == item.modelId">
                                <button nz-button nzType="default" (click)="cancel()">
                                <i  nz-icon nzType="close-square" nz-tooltip nzTooltipTitle="Cancel"></i>
                                </button>
                            </td>

                        </tr>
                    </tbody>

                </nz-table>
            </nz-spin>
        </nz-tab>

        <nz-tab nzTitle="Out Of Stock at Supplier" (nzClick)="setIOS()">
            <nz-spin [nzSpinning]="isLoading">
                <nz-row>
                    <nz-col [nzSpan]="'4'" nzOffset="20">
                         <div style="display:flex"><label><button (click)="clearBrandFilter()" *ngIf="this.filteredBrand_IOS != 'Brands'" nz-button nzType="primary" class="btn_border" nz-tooltip="clear filter">X</button><span style="margin-left:2px;">Filter : {{this.filteredBrand_IOS}} </span></label></div>
                         <nz-select [(ngModel)]="itemsOutOfStockAtSupplier" id="filterStyle" (ngModelChange)="filterReason($event)">
                             <nz-option *ngFor="let item of brandsGathered" [nzValue]="item" [nzLabel]="item"></nz-option>
                         </nz-select><br>
                     </nz-col>
                 </nz-row>
                <nz-table #IOS_Supplier [nzData]="itemsOutOfStockAtSupplier" id="IOS_Supplier" [nzFrontPagination]="false" nzSize="small"  [nzScroll]="{'y':'700px'}">
                    <thead style="position: sticky;">
                        <tr>
                            <th [nzSortFn]="sortSupplier" [nzWidth]="'15%'">Supplier</th>
                            <th [nzSortFn]="sortBrand" [nzWidth]="'8%'">Brand</th>
                            <th [nzSortFn]="sortCode" [nzWidth]="'8%'">Code</th>
                            <th [nzSortFn]="sortDescription" [nzWidth]="'15%'">Description</th>
                            <th [nzSortFn]="sortMSL" [nzWidth]="'8%'">MSL</th>
                            <th [nzSortFn]="sortSOH" [nzWidth]="'8%'">SOH</th>
                            <th [nzSortFn]="sortWIP" *ngIf="this.wip_visible==true" style="color:var(--textcolor)">WIP</th>
                            <th [nzSortFn]="sortDiff" [nzWidth]="'8%'">diff</th>
                            <th [nzSortFn]="sortS_SOH" [nzWidth]="'8%'" nz-tooltip nzTooltipTitle="Suppliers stock on hand">S_SOH</th>
                            <th [nzSortFn]="sortS_Price" [nzWidth]="'8%'">Cost</th>
                            <th [nzSortFn]="sortS_Sales" *ngIf="this.editMode == false" [nzWidth]="'8%'">Sales Trend</th>
                            <th *ngIf="this.editMode == true" [nzWidth]="'8%'">Save</th>
                            <th *ngIf="this.editMode == true" [nzWidth]="'8%'">Cancel</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of IOS_Supplier.data" style="padding: 1px;">
                            <td *ngIf="this.editMode == false" style="text-align: center;">{{item.supplier_name}} <a *ngIf="item.replenish_active != true" (click)="trackAltStock(item,'A'); this.altStock=true;"><i nz-icon nzType="info-circle" aria-hidden="true" nz-tooltip nzTooltipTitle="Supplier deactivated replenishment"></i></a>                                </td>
                            <td *ngIf="this.editMode == true && this.editId != item.modelId">{{item.supplier_name}} <a *ngIf="item.replenish_active != true" (click)="trackAltStock(item,'A'); this.altStock=true;"><i nz-icon nzType="info-circle" aria-hidden="true" nz-tooltip nzTooltipTitle="Supplier deactivated replenishment"></i></a></td>
                            <td *ngIf="this.editMode == true && this.editId == item.modelId" style="text-align: center;">
                                <input nz-dropdown [nzDropdownMenu]="menu5" placeholder="{{item.supplier_name}}" id="supllierInput" style="width: 50%; text-align: center;" autocomplete="off" />
                                <nz-dropdown-menu #menu5="nzDropdownMenu">
                                    <ul nz-menu nzSelectable *ngFor="let supplier of suppliersTemp">
                                        <li nz-menu-item (click)="chooseSupplier(supplier,0)">{{supplier.supplier_name}}</li>
                                    </ul>
                                </nz-dropdown-menu>
                            </td>
                            <td style="text-align: center; border-right: solid 0.01px var(--textcolor); border-left: solid 0.01px var(--textcolor);"><img style="margin: 0 auto;" src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{item.brandLogo}}" class="brandImage" nz-tooltip="{{item.brand}}" />
                                <div style="display: none;">{{item.brand}}</div>
                            </td>
                            <td style="text-align: center;"><div>{{item.code}}</div><div id="manCode_disp" nz-tooltip nzTooltipTitle="manufacturers stock code"  *ngIf="item.code != item.manufacturers_code && item.manufacturers_code != null">{{item.manufacturers_code}}</div></td>
                            <td>
                                <div>
                                    <div class="description_clickable" style="padding-right: 2px; display:flex;">
                                        <div nz-dropdown [nzDropdownMenu]="menu_basic" nz-tooltip nzTooltipTitle="(click to investigate further)" (click)="inquire_on_size(item)" style="color:cadetblue; cursor:pointer;">{{item.description}}</div>
                                        <!-- <div *ngIf="item.lastCarry_dt == null || item.lastCarry_dt == '' " style="position: absolute; right:1%; color:red; cursor:pointer;" nz-tooltip nzTooltipTitle="Escalate to Head Office" (click)="addToEscelations(item)"><i nz-icon nzType="mail" aria-hidden="true"></i></div> -->

                                    </div>

                                    <nz-dropdown-menu #menu_basic="nzDropdownMenu">
                                        <ul nz-menu nzSelectable style="border-radius: 8px;">
                                            <li nz-menu-item (click)="deleteModel(item.modelId)" *ngIf="item.holding_status !=2" class="hover_list"><b>Disable Item</b></li>
                                            <li nz-menu-item (click)="holdItem(item.modelId)" *ngIf="item.holding_status !=2" class="hover_list"><b>Hold Item</b></li>
                                            <li nz-menu-item (click)="deleteModel(item.modelId)" *ngIf="item.holding_status ==2" class="hover_list"><b>Re-enable Item</b></li>
                                            <!-- <li nz-menu-item (click)="modify_msl(item.modelId)" class="hover_list"><b>Change MSL</b></li> -->
                                        </ul>
                                    </nz-dropdown-menu>
                                    <a (click)="inquire_on_size(item);" nz-dropdown [nzDropdownMenu]="menu6" *ngIf="item.holding_status == 1 && item.msl >0 " style="color: var(--textcolor); position: absolute; right: 8px; bottom: 20px;"><i nz-icon nzType="book" aria-hidden="true" nz-tooltip nzTooltipTitle="Holding item until stock is available at {{item.supplier_name}}. (click to edit)"></i></a>
                                    <nz-dropdown-menu #menu6="nzDropdownMenu">
                                        <ul nz-menu nzSelectable style="border-radius: 8px;">
                                            <!-- <li nz-menu-item (click)="holdItem(item.modelId)" class="hover_list"><b>Stop Holding Item</b></li> -->
                                            <li nz-menu-item (click)="inquire_on_size(item)" class="hover_list"><b>Investigate Further</b></li>
                                            <!-- <li nz-menu-item (click)="deleteModel(item.modelId)" class="hover_list"><b>Disable Item</b></li> -->
                                        </ul>
                                    </nz-dropdown-menu>
                                    <div style="width: 100%; display: flex;">
                                        <a (click)="inquire_on_size(item);" nz-dropdown [nzDropdownMenu]="menu_disabled" *ngIf="item.holding_status == 2" style="color: var(--warningcolor); position: absolute; right: 15px;">
                                            <div style="position: absolute; right: 5px; bottom: 6px;"><i nz-icon nzType="stop" aria-hidden="true" nz-tooltip nzTooltipTitle="Item Disabled for automated replenishment. (click to edit)"></i></div>
                                        </a>
                                    </div>
                                    <nz-dropdown-menu #menu_disabled="nzDropdownMenu">
                                        <ul nz-menu nzSelectable style="border-radius: 8px; ">
                                            <li nz-menu-item (click)="deleteModel(item.modelId)" class="hover_list"><b>Re-enable Item</b></li>
                                        </ul>
                                    </nz-dropdown-menu>
                                </div>
                            </td>
                            <td *ngIf="this.editMode == false" style="text-align: center;">{{item.msl}}</td>
                            <td *ngIf="this.editMode == true && this.editId != item.modelId" style="text-align: center;">{{item.msl}}</td>
                            <td *ngIf="this.editMode == true && this.editId == item.modelId" style="text-align: center;">
                                <input nz-input placeholder="{{item.msl}}" type="number" placeholder="{{item.msl}}" value="{{item.msl}}" min="0" id="msl" style="width: 50%;" id="editPOI" autocomplete="off" />
                            </td>
                            <td style="text-align: center;" [ngStyle]="{'color':item.live == true ? 'var(--textcolor)': '' }"><b>{{item.soh}}<span *ngIf="item.back_order_qty >0" style="color: cadetblue; cursor:ponter;" nz-tooltip nzTooltipTitle="SOH | Back order Quantity"> | {{item.back_order_qty}}</span></b></td>
                            <td style="text-align: center;" *ngIf="this.wip_visible == true;"><b>- {{item.work_in_progress}}</b></td>
                            <td style="text-align: center;" [ngStyle]="{'color':item.diff < 0 ? 'var(--errorcolor)' : 'var(--textcolor)' }">{{item.diff}}</td>
                            <td style="text-align: center;" [ngStyle]="{'color':item.supplier_live == true ? 'var(--textcolor)' : '' }" style="text-align: center;">{{item.sohSupplier}}
                                <a nz-dropdown [nzDropdownMenu]="menu8" (click)="inquire_on_size(item);" *ngIf="item.diff*-1>item.sohSupplier && item.sohSupplier > 0 && item.holding_status == 0" style="color: var(--warningcolor);"><i nz-icon nzType="info-circle" aria-hidden="true" nz-tooltip nzTooltipTitle=" Supplier only has {{item.sohSupplier}} unit(s) Partial fill will  be available in order summary"></i></a>
                                <a nz-dropdown [nzDropdownMenu]="menu8" (click)="inquire_on_size(item);" *ngIf="item.diff*-1>item.sohSupplier && item.sohSupplier == 0 && item.holding_status == 0" style="color: var(--warningcolor);"><i nz-icon nzType="info-circle" aria-hidden="true" nz-tooltip nzTooltipTitle="Supplier does not have enough stock for your MSL"></i></a>
                                <nz-dropdown-menu #menu8="nzDropdownMenu">
                                    <ul nz-menu nzSelectable style="border-radius: 8px;">
                                        <!-- <li nz-menu-item (click)="holdItem(item.modelId)" class="hover_list" *ngIf="item.sohSupplier == 0;"><b>Hold Item</b></li> -->
                                        <li nz-menu-item (click)="inquire_on_size(item)" class="hover_list"><b>Investigate Further</b></li>
                                        <!-- <li nz-menu-item (click)="deleteModel(item.modelId)" class="hover_list"><b>Disable Item</b></li> -->
                                    </ul>
                                </nz-dropdown-menu>
                            </td>

                            <td style="text-align: center;" *ngIf="item.supplierPrice > 0">R {{item.supplierPrice}}</td>
                            <td *ngIf="item.supplierPrice == 0" style="text-align: center;">
                                <a style="color: var(--errorcolor);" nz-tooltip nzTooltipTitle="Pricing can not be applied if supplier has no stock"><b>-</b></a>
                            </td>
                            <td style="text-align: center;">

                                <div style="display:flex">
                                    <span style="color: cadetblue; cursor:ponter; position: absolute; bottom: 5%; right: 30%;" nz-tooltip nzTooltipTitle="sales (120 days)"> | {{item.sales}}</span>
                                    <span *ngIf="this.editMode == false;" nz-icon nzType="stock" nzTheme="outline" nz-tooltip="Sales Trend" style="color: var(--textcolor);font-size: 2rem !important;cursor: pointer" (click)="viewSalesTrendTyre(item.msfId)"></span>
                                </div>
                                <button nz-button nztype="default" *ngIf="this.editMode == true && this.editId == item.modelId" (click)="saveMSLChange(item)">
                                <i nz-icon nzType="save" style="font-size: 1.5rem; vertical-align: top; " nz-tooltip nzTooltipTitle="Save Changes"></i>
                                </button>
                            </td>
                            <td style="text-align: center;" *ngIf="this.editMode == true && this.editId == item.modelId">
                                <button nz-button nzType="default" (click)="cancel()">
                                <i nz-icon nzType="info-circle" nz-tooltip nzTooltipTitle="Cancel"></i>
                                </button>
                            </td>

                        </tr>
                    </tbody>

                </nz-table>
            </nz-spin>
        </nz-tab>

        <nz-tab nzTitle="Holding" (nzClick)="setHolding()">
            <nz-spin [nzSpinning]="isLoading">
                <nz-row>
                    <nz-col [nzSpan]="'4'" nzOffset="20">
                         <div style="display:flex"><label><button (click)="clearBrandFilter()" *ngIf="this.filteredBrand_H != 'Brands'" nz-button nzType="primary" class="btn_border" nz-tooltip="clear filter">X</button><span style="margin-left:2px;">Filter : {{this.filteredBrand_H}} </span></label></div>
                         <nz-select [(ngModel)]="itemsHolding" id="filterStyle" (ngModelChange)="filterReason($event)">
                             <nz-option *ngFor="let item of brandsGathered" [nzValue]="item" [nzLabel]="item"></nz-option>
                         </nz-select><br>
                     </nz-col>
                 </nz-row>
                <nz-table #itemsHolding1 id="holdingItems" [nzData]="itemsHolding" [nzFrontPagination]="false" nzSize="small"  [nzScroll]="{'y':'700px'}">
                    <thead style="position: sticky;">
                        <tr>
                            <th [nzSortFn]="sortSupplier" [nzWidth]="'15%'">Supplier</th>
                            <th [nzSortFn]="sortBrand" [nzWidth]="'8%'">Brand</th>
                            <th [nzSortFn]="sortCode" [nzWidth]="'8%'">Code</th>
                            <th [nzSortFn]="sortDescription" [nzWidth]="'15%'">Description</th>
                            <th [nzSortFn]="sortMSL" [nzWidth]="'8%'">MSL</th>
                            <th [nzSortFn]="sortSOH" [nzWidth]="'8%'">SOH</th>
                            <th [nzSortFn]="sortWIP" *ngIf="this.wip_visible==true" style="color:var(--textcolor)">WIP</th>
                            <th [nzSortFn]="sortDiff" [nzWidth]="'8%'">diff</th>
                            <th [nzSortFn]="sortS_SOH" [nzWidth]="'8%'" nz-tooltip nzTooltipTitle="Suppliers stock on hand">S_SOH</th>
                            <th [nzSortFn]="sortS_Price" [nzWidth]="'8%'">Cost</th>
                            <th [nzSortFn]="sortS_Sales" *ngIf="this.editMode == false" [nzWidth]="'8%'">Sales Trend</th>
                            <th *ngIf="this.editMode == true" [nzWidth]="'8%'">Save</th>
                            <th *ngIf="this.editMode == true" [nzWidth]="'8%'">Cancel</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of itemsHolding1.data" style="padding: 1px;">
                            <td *ngIf="this.editMode == false" style="text-align: center;">{{item.supplier_name}} <a *ngIf="item.replenish_active != true" (click)="trackAltStock(item,'A'); this.altStock=true;"><i nz-icon nzType="info-circle" aria-hidden="true" nz-tooltip nzTooltipTitle="Supplier deactivated replenishment"></i></a>                                </td>
                            <td *ngIf="this.editMode == true && this.editId != item.modelId">{{item.supplier_name}} <a *ngIf="item.replenish_active != true" (click)="trackAltStock(item,'A'); this.altStock=true;"><i nz-icon nzType="info-circle" aria-hidden="true" nz-tooltip nzTooltipTitle="Supplier deactivated replenishment"></i></a></td>
                            <td *ngIf="this.editMode == true && this.editId == item.modelId" style="text-align: center;">
                                <input nz-dropdown [nzDropdownMenu]="menu5" placeholder="{{item.supplier_name}}" id="supllierInput" style="width: 50%; text-align: center;" autocomplete="off" />
                                <nz-dropdown-menu #menu5="nzDropdownMenu">
                                    <ul nz-menu nzSelectable *ngFor="let supplier of suppliersTemp">
                                        <li nz-menu-item (click)="chooseSupplier(supplier,0)">{{supplier.supplier_name}}</li>
                                    </ul>
                                </nz-dropdown-menu>
                            </td>
                            <td style="text-align: center; border-right: solid 0.01px var(--textcolor); border-left: solid 0.01px var(--textcolor);"><img style="margin: 0 auto;" src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{item.brandLogo}}" class="brandImage" nz-tooltip="{{item.brand}}" />
                                <div style="display: none;">{{item.brand}}</div>
                            </td>
                            <td style="text-align: center;"><div>{{item.code}}</div><div id="manCode_disp" nz-tooltip nzTooltipTitle="manufacturers stock code"  *ngIf="item.code != item.manufacturers_code && item.manufacturers_code != null">{{item.manufacturers_code}}</div></td>
                            <td>
                                <div>
                                    <a (click)="inquire_on_size(item)" nz-dropdown [nzDropdownMenu]="menu_basic" nz-tooltip nzTooltipTitle="(click to edit)">
                                        <div class="description_clickable" style="padding-right: 2px;">{{item.description}}</div>
                                    </a>
                                    <nz-dropdown-menu #menu_basic="nzDropdownMenu">
                                        <ul nz-menu nzSelectable style="border-radius: 8px;">
                                            <li nz-menu-item (click)="deleteModel(item.modelId)" *ngIf="item.holding_status !=2" class="hover_list"><b>Disable Item</b></li>
                                            <li nz-menu-item (click)="deleteModel(item.modelId)" *ngIf="item.holding_status ==2" class="hover_list"><b>Re-enable Item</b></li>
                                            <!-- <li nz-menu-item (click)="modify_msl(item.modelId)" class="hover_list"><b>Change MSL</b></li> -->
                                        </ul>
                                    </nz-dropdown-menu>
                                    <a (click)="inquire_on_size(item);" nz-dropdown [nzDropdownMenu]="menu6" *ngIf="item.holding_status == 1 && item.msl >0 " style="color: var(--textcolor); position: absolute; right: 8px; bottom: 20px;"><i nz-icon nzType="book" aria-hidden="true" nz-tooltip nzTooltipTitle="Holding item until stock is available at {{item.supplier_name}}. (click to edit)"></i></a>
                                    <nz-dropdown-menu #menu6="nzDropdownMenu">
                                        <ul nz-menu nzSelectable style="border-radius: 8px;">
                                            <!-- <li nz-menu-item (click)="holdItem(item.modelId)" class="hover_list"><b>Stop Holding Item</b></li> -->
                                            <li nz-menu-item (click)="inquire_on_size(item)" class="hover_list"><b>Investigate Further</b></li>
                                            <!-- <li nz-menu-item (click)="deleteModel(item.modelId)" class="hover_list"><b>Disable Item</b></li> -->
                                        </ul>
                                    </nz-dropdown-menu>
                                    <div style="width: 100%; display: flex;">
                                        <a (click)="inquire_on_size(item);" nz-dropdown [nzDropdownMenu]="menu_disabled" *ngIf="item.holding_status == 2" style="color: var(--warningcolor); position: absolute; right: 15px;">
                                            <div style="position: absolute; right: 5px; bottom: 6px;"><i nz-icon nzType="stop" aria-hidden="true" nz-tooltip nzTooltipTitle="Item Disabled for automated replenishment. (click to edit)"></i></div>
                                        </a>
                                    </div>
                                    <nz-dropdown-menu #menu_disabled="nzDropdownMenu">
                                        <ul nz-menu nzSelectable style="border-radius: 8px; ">
                                            <li nz-menu-item (click)="deleteModel(item.modelId)" class="hover_list"><b>Re-enable Item</b></li>
                                        </ul>
                                    </nz-dropdown-menu>
                                </div>
                            </td>
                            <td *ngIf="this.editMode == false" style="text-align: center;">{{item.msl}}</td>
                            <td *ngIf="this.editMode == true && this.editId != item.modelId" style="text-align: center;">{{item.msl}}</td>
                            <td *ngIf="this.editMode == true && this.editId == item.modelId" style="text-align: center;">
                                <input nz-input placeholder="{{item.msl}}" type="number" placeholder="{{item.msl}}" value="{{item.msl}}" min="0" id="msl" style="width: 50%;" id="editPOI" autocomplete="off" />
                            </td>
                            <td style="text-align: center;" [ngStyle]="{'color':item.live == true ? 'var(--textcolor)': '' }"><b>{{item.soh}}<span *ngIf="item.back_order_qty >0" style="color: cadetblue; cursor:ponter;" nz-tooltip nzTooltipTitle="SOH | Back order Quantity"> | {{item.back_order_qty}}</span></b></td>
                            <td style="text-align: center;" *ngIf="this.wip_visible == true;"><b>- {{item.work_in_progress}}</b></td>
                            <td style="text-align: center;" [ngStyle]="{'color':item.diff < 0 ? 'var(--errorcolor)' : 'var(--textcolor)' }">{{item.diff}}</td>
                            <td style="text-align: center;" [ngStyle]="{'color':item.supplier_live == true ? 'var(--textcolor)' : '' }" style="text-align: center;">{{item.sohSupplier}}
                                <a nz-dropdown [nzDropdownMenu]="menu8" (click)="inquire_on_size(item);" *ngIf="item.diff*-1>item.sohSupplier && item.sohSupplier > 0 && item.holding_status == 0" style="color: var(--warningcolor);"><i nz-icon nzType="info-circle" aria-hidden="true" nz-tooltip nzTooltipTitle=" Supplier only has {{item.sohSupplier}} unit(s) Partial fill will  be available in order summary"></i></a>
                                <a nz-dropdown [nzDropdownMenu]="menu8" (click)="inquire_on_size(item);" *ngIf="item.diff*-1>item.sohSupplier && item.sohSupplier == 0 && item.holding_status == 0" style="color: var(--warningcolor);"><i nz-icon nzType="info-circle" aria-hidden="true" nz-tooltip nzTooltipTitle="Supplier does not have enough stock for your MSL"></i></a>
                                <nz-dropdown-menu #menu8="nzDropdownMenu">
                                    <ul nz-menu nzSelectable style="border-radius: 8px;">
                                        <!-- <li nz-menu-item (click)="holdItem(item.modelId)" class="hover_list" *ngIf="item.sohSupplier == 0;"><b>Hold Item</b></li> -->
                                        <li nz-menu-item (click)="inquire_on_size(item)" class="hover_list"><b>Investigate Further</b></li>
                                        <!-- <li nz-menu-item (click)="deleteModel(item.modelId)" class="hover_list"><b>Disable Item</b></li> -->
                                    </ul>
                                </nz-dropdown-menu>
                            </td>

                            <td style="text-align: center;" *ngIf="item.supplierPrice > 0">R {{item.supplierPrice}}</td>
                            <td *ngIf="item.supplierPrice == 0" style="text-align: center;">
                                <a style="color: var(--errorcolor);" nz-tooltip nzTooltipTitle="Pricing can not be applied if supplier has no stock"><b>-</b></a>
                            </td>
                            <td style="text-align: center;">
                                <div style="display:flex">
                                    <span style="color: cadetblue; cursor:ponter; position: absolute; bottom: 5%; right: 30%;" nz-tooltip nzTooltipTitle="sales (120 days)"> | {{item.sales}}</span>
                                    <span *ngIf="this.editMode == false;" nz-icon nzType="stock" nzTheme="outline" nz-tooltip="Sales Trend" style="color: var(--textcolor);font-size: 2rem !important;cursor: pointer" (click)="viewSalesTrendTyre(item.msfId)"></span>
                                </div>
                                <button nz-button nztype="default" *ngIf="this.editMode == true && this.editId == item.modelId" (click)="saveMSLChange(item)">
                                <i nz-icon nzType="save" style="font-size: 1.5rem; vertical-align: top; " nz-tooltip nzTooltipTitle="Save Changes"></i>
                                </button>
                            </td>
                            <td style="text-align: center;" *ngIf="this.editMode == true && this.editId == item.modelId">
                                <button nz-button nzType="default" (click)="cancel()">
                                <i nz-icon nzType="close-square" nz-tooltip nzTooltipTitle="Cancel"></i>
                                </button>
                            </td>

                        </tr>
                    </tbody>

                </nz-table>
            </nz-spin>
        </nz-tab>

        <nz-tab nzTitle="Disabled" (nzClick)="setDisabled()">
            <nz-spin [nzSpinning]="isLoading">
                <nz-row>
                    <nz-col [nzSpan]="'4'" nzOffset="20">
                         <div style="display:flex"><label><button (click)="clearBrandFilter()" *ngIf="this.filteredBrand_D != 'Brands'" nz-button nzType="primary" class="btn_border" nz-tooltip="clear filter">X</button><span style="margin-left:2px;">Filter : {{this.filteredBrand_D}} </span></label></div>
                         <nz-select [(ngModel)]="itemsDisabled" id="filterStyle" (ngModelChange)="filterReason($event)">
                             <nz-option *ngFor="let item of brandsGathered" [nzValue]="item" [nzLabel]="item"></nz-option>
                         </nz-select><br>
                     </nz-col>
                 </nz-row>
                <nz-table #DisabledItems [nzData]="itemsDisabled" id="DisabledItems" [nzFrontPagination]="false" nzSize="small"  [nzScroll]="{'y':'700px'}">
                    <thead style="position: sticky;">
                        <tr>
                            <th [nzSortFn]="sortSupplier" [nzWidth]="'15%'">Supplier</th>
                            <th [nzSortFn]="sortBrand" [nzWidth]="'8%'">Brand</th>
                            <th [nzSortFn]="sortCode" [nzWidth]="'8%'">Code</th>
                            <th [nzSortFn]="sortDescription" [nzWidth]="'15%'">Description</th>
                            <th [nzSortFn]="sortMSL" [nzWidth]="'8%'">MSL</th>
                            <th [nzSortFn]="sortSOH" [nzWidth]="'8%'">SOH</th>
                            <th [nzSortFn]="sortWIP" *ngIf="this.wip_visible==true" style="color:var(--textcolor)">WIP</th>
                            <th [nzSortFn]="sortDiff" [nzWidth]="'8%'">diff</th>
                            <th [nzSortFn]="sortS_SOH" [nzWidth]="'8%'" nz-tooltip nzTooltipTitle="Suppliers stock on hand">S_SOH</th>
                            <th [nzSortFn]="sortS_Price" [nzWidth]="'8%'">Cost</th>
                            <th [nzSortFn]="sortS_Sales" *ngIf="this.editMode == false" [nzWidth]="'8%'">Sales Trend</th>
                            <th *ngIf="this.editMode == true" [nzWidth]="'8%'">Save</th>
                            <th *ngIf="this.editMode == true" [nzWidth]="'8%'">Cancel</th>


                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of DisabledItems.data" style="padding: 1px;">
                            <td *ngIf="this.editMode == false" style="text-align: center;">{{item.supplier_name}} <a *ngIf="item.replenish_active != true" (click)="trackAltStock(item,'A'); this.altStock=true;"><i nz-icon nzType="info-circle" aria-hidden="true" nz-tooltip nzTooltipTitle="Supplier deactivated replenishment"></i></a>                                </td>
                            <td *ngIf="this.editMode == true && this.editId != item.modelId">{{item.supplier_name}} <a *ngIf="item.replenish_active != true" (click)="trackAltStock(item,'A'); this.altStock=true;"><i nz-icon nzType="info-circle" aria-hidden="true" nz-tooltip nzTooltipTitle="Supplier deactivated replenishment"></i></a></td>
                            <td *ngIf="this.editMode == true && this.editId == item.modelId" style="text-align: center;">
                                <input nz-dropdown [nzDropdownMenu]="menu5" placeholder="{{item.supplier_name}}" id="supllierInput" style="width: 50%; text-align: center;" autocomplete="off" />
                                <nz-dropdown-menu #menu5="nzDropdownMenu">
                                    <ul nz-menu nzSelectable *ngFor="let supplier of suppliersTemp">
                                        <li nz-menu-item (click)="chooseSupplier(supplier,0)">{{supplier.supplier_name}}</li>
                                    </ul>
                                </nz-dropdown-menu>
                            </td>
                            <td style="text-align: center; border-right: solid 0.01px var(--textcolor); border-left: solid 0.01px var(--textcolor);"><img style="margin: 0 auto;" src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{item.brandLogo}}" class="brandImage" nz-tooltip="{{item.brand}}" />
                                <div style="display: none;">{{item.brand}}</div>
                            </td>
                            <td style="text-align: center;"><div>{{item.code}}</div><div id="manCode_disp" nz-tooltip nzTooltipTitle="manufacturers stock code"  *ngIf="item.code != item.manufacturers_code && item.manufacturers_code != null">{{item.manufacturers_code}}</div></td>
                            <td>
                                <div>
                                    <a (click)="inquire_on_size(item)" nz-dropdown [nzDropdownMenu]="menu_basic" nz-tooltip nzTooltipTitle="(click to edit)">
                                        <div class="description_clickable" style="padding-right: 2px;">{{item.description}}</div>
                                    </a>
                                    <nz-dropdown-menu #menu_basic="nzDropdownMenu">
                                        <ul nz-menu nzSelectable style="border-radius: 8px;">
                                            <li nz-menu-item (click)="deleteModel(item.modelId)" *ngIf="item.holding_status !=2" class="hover_list"><b>Disable Item</b></li>
                                            <li nz-menu-item (click)="deleteModel(item.modelId)" *ngIf="item.holding_status ==2" class="hover_list"><b>Re-enable Item</b></li>
                                            <!-- <li nz-menu-item (click)="modify_msl(item.modelId)" class="hover_list"><b>Change MSL</b></li> -->
                                        </ul>
                                    </nz-dropdown-menu>
                                    <a (click)="inquire_on_size(item);" nz-dropdown [nzDropdownMenu]="menu6" *ngIf="item.holding_status == 1 && item.msl >0 " style="color: var(--textcolor); position: absolute; right: 8px; bottom: 20px;"><i nz-icon nzType="book" aria-hidden="true" nz-tooltip nzTooltipTitle="Holding item until stock is available at {{item.supplier_name}}. (click to edit)"></i></a>
                                    <nz-dropdown-menu #menu6="nzDropdownMenu">
                                        <ul nz-menu nzSelectable style="border-radius: 8px;">
                                            <!-- <li nz-menu-item (click)="holdItem(item.modelId)" class="hover_list"><b>Stop Holding Item</b></li> -->
                                            <li nz-menu-item (click)="inquire_on_size(item)" class="hover_list"><b>Investigate Further</b></li>
                                            <!-- <li nz-menu-item (click)="deleteModel(item.modelId)" class="hover_list"><b>Disable Item</b></li> -->
                                        </ul>
                                    </nz-dropdown-menu>
                                    <div style="width: 100%; display: flex;">
                                        <a (click)="inquire_on_size(item);" nz-dropdown [nzDropdownMenu]="menu_disabled" *ngIf="item.holding_status == 2" style="color: var(--warningcolor); position: absolute; right: 15px;">
                                            <div style="position: absolute; right: 5px; bottom: 6px;"><i nz-icon nzType="stop" aria-hidden="true" nz-tooltip nzTooltipTitle="Item Disabled for automated replenishment. (click to edit)"></i></div>
                                        </a>
                                    </div>
                                    <nz-dropdown-menu #menu_disabled="nzDropdownMenu">
                                        <ul nz-menu nzSelectable style="border-radius: 8px; ">
                                            <li nz-menu-item (click)="deleteModel(item.modelId)" class="hover_list"><b>Re-enable Item</b></li>
                                        </ul>
                                    </nz-dropdown-menu>
                                </div>
                            </td>
                            <td *ngIf="this.editMode == false" style="text-align: center;">{{item.msl}}</td>
                            <td *ngIf="this.editMode == true && this.editId != item.modelId" style="text-align: center;">{{item.msl}}</td>
                            <td *ngIf="this.editMode == true && this.editId == item.modelId" style="text-align: center;">
                                <input nz-input placeholder="{{item.msl}}" type="number" placeholder="{{item.msl}}" value="{{item.msl}}" min="0" id="msl" style="width: 50%;" id="editPOI" autocomplete="off" />
                            </td>
                            <td style="text-align: center;" [ngStyle]="{'color':item.live == true ? 'var(--textcolor)': '' }"><b>{{item.soh}}<span *ngIf="item.back_order_qty >0" style="color: cadetblue; cursor:ponter;" nz-tooltip nzTooltipTitle="SOH | Back order Quantity"> | {{item.back_order_qty}}</span></b></td>
                            <td style="text-align: center;" *ngIf="this.wip_visible == true;"><b>- {{item.work_in_progress}}</b></td>
                            <td style="text-align: center;" [ngStyle]="{'color':item.diff < 0 ? 'var(--errorcolor)' : 'var(--textcolor)' }">{{item.diff}}</td>
                            <td style="text-align: center;" [ngStyle]="{'color':item.supplier_live == true ? 'var(--textcolor)' : '' }" style="text-align: center;">{{item.sohSupplier}}
                                <a nz-dropdown [nzDropdownMenu]="menu8" (click)="inquire_on_size(item);" *ngIf="item.diff*-1>item.sohSupplier && item.sohSupplier > 0 && item.holding_status == 0" style="color: var(--warningcolor);"><i nz-icon nzType="info-circle" aria-hidden="true" nz-tooltip nzTooltipTitle=" Supplier only has {{item.sohSupplier}} unit(s) Partial fill will  be available in order summary"></i></a>
                                <a nz-dropdown [nzDropdownMenu]="menu8" (click)="inquire_on_size(item);" *ngIf="item.diff*-1>item.sohSupplier && item.sohSupplier == 0 && item.holding_status == 0" style="color: var(--warningcolor);"><i nz-icon nzType="info-circle" aria-hidden="true" nz-tooltip nzTooltipTitle="Supplier does not have enough stock for your MSL"></i></a>
                                <nz-dropdown-menu #menu8="nzDropdownMenu">
                                    <ul nz-menu nzSelectable style="border-radius: 8px;">
                                        <!-- <li nz-menu-item (click)="holdItem(item.modelId)" class="hover_list" *ngIf="item.sohSupplier == 0;"><b>Hold Item</b></li> -->
                                        <li nz-menu-item (click)="inquire_on_size(item)" class="hover_list"><b>Investigate Further</b></li>
                                        <!-- <li nz-menu-item (click)="deleteModel(item.modelId)" class="hover_list"><b>Disable Item</b></li> -->
                                    </ul>
                                </nz-dropdown-menu>
                            </td>

                            <td style="text-align: center;" *ngIf="item.supplierPrice > 0">R {{item.supplierPrice}}</td>
                            <td *ngIf="item.supplierPrice == 0" style="text-align: center;">
                                <a style="color: var(--errorcolor);" nz-tooltip nzTooltipTitle="Pricing can not be applied if supplier has no stock"><b>-</b></a>
                            </td>
                            <td style="text-align: center;">
                                <div style="display:flex">
                                    <span style="color: cadetblue; cursor:ponter; position: absolute; bottom: 5%; right: 30%;" nz-tooltip nzTooltipTitle="sales (120 days)"> | {{item.sales}}</span>
                                    <span *ngIf="this.editMode == false;" nz-icon nzType="stock" nzTheme="outline" nz-tooltip="Sales Trend" style="color: var(--textcolor);font-size: 2rem !important;cursor: pointer" (click)="viewSalesTrendTyre(item.msfId)"></span>
                                </div>
                                <button nz-button nztype="default" *ngIf="this.editMode == true && this.editId == item.modelId" (click)="saveMSLChange(item)">
                                <i nz-icon nzType="save" style="font-size: 1.5rem; vertical-align: top; " nz-tooltip nzTooltipTitle="Save Changes"></i>
                                </button>
                            </td>
                            <td style="text-align: center;" *ngIf="this.editMode == true && this.editId == item.modelId">
                                <button nz-button nzType="default" (click)="cancel()">
                                <i nz-icon nzType="close-square" nz-tooltip nzTooltipTitle="Cancel"></i>
                                </button>
                            </td>

                        </tr>
                    </tbody>

                </nz-table>
            </nz-spin>
        </nz-tab>

        <!-- <nz-tab nzTitle="Escalation" (nzClick)="setEscalation()">
            <div>
                The items listed below have been out of stock at the relevant suppliers, while branches are still experiencing high demand.
                <br>this will be brought to the attention of head office upon completion
            </div>
            <nz-spin [nzSpinning]="isLoading">
                <nz-table [nzData]="attentionHeadOffice" id="Escalation_Items" [nzFrontPagination]="false" nzSize="small"  style="margin-top:2%;" [nzScroll]="{'y':'700px'}">
                    <thead>
                        <tr>
                            <th [nzWidth]="'10%'">Brand</th>
                            <th [nzWidth]="'15%'">Description</th>
                            <th [nzWidth]="'10%'">Code</th>
                            <th [nzWidth]="'10%'">msl</th>
                            <th [nzWidth]="'10%'">SOH</th>
                            <th [nzWidth]="'10%'">S_SOH</th>
                            <th [nzWidth]="'15%'">supplier</th>
                            <th [nzWidth]="'10%'">Last_Carry dt</th>
                            <th [nzWidth]="'10%'">Remove</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of attentionHeadOffice">
                            <td style="text-align: center; border-right: solid 0.01px var(--textcolor); border-left: solid 0.01px var(--textcolor);">
                                <img style="margin: 0 auto;" src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{item.brandLogo}}" class="brandImage" nz-tooltip="{{item.brand}}" />
                                <div style="display: none;">{{item.brand}}</div>
                            </td>
                            <td>
                                <div style="display:flex;">
                                    <div class="description_clickable" (click)="inquire_on_size(item)" nz-dropdown [nzDropdownMenu]="menu_basic" nz-tooltip nzTooltipTitle="(click to Investigate)" style="padding-right: 2px; color:cadetblue; cursor:pointer;">{{item.description}}</div>
                                </div>
                            </td>
                            <td><div>{{item.code}}</div><div id="manCode_disp" nz-tooltip nzTooltipTitle="manufacturers stock code"  *ngIf="item.code != item.manufacturers_code && item.manufacturers_code != null">{{item.manufacturers_code}}</div></td>
                            <td>{{item.msl}}</td>
                            <td>{{item.soh}}<span *ngIf="item.back_order_qty >0" style="color: cadetblue; cursor:ponter;" nz-tooltip nzTooltipTitle="SOH | Back order Quantity"> | {{item.back_order_qty}}</span></td>
                            <td>{{item.sohSupplier}}</td>
                            <td>{{item.supplier_name}}</td>
                            <td>{{item.lastCarry_dt}}</td>
                            <td style="text-align:center;"><button nz-button nzType="default" (click)="removeFromEscelation(item)"><a style="color:red;"><i nz-icon nzType="delete"  aria-hidden="true"></i></a></button></td>
                        </tr>

                    </tbody>
                </nz-table>
            </nz-spin>
        </nz-tab> -->
        <nz-tab nzTitle="All" (nzClick)="setAll()">
            <nz-spin [nzSpinning]="isLoading">
                <nz-row>
                   <nz-col [nzSpan]="'4'" nzOffset="20">
                        <div style="display:flex"><label><button (click)="clearBrandFilter()" *ngIf="this.filteredBrand_all != 'Brands'" nz-button nzType="primary" class="btn_border" nz-tooltip="clear filter">X</button><span style="margin-left:2px;">Filter : {{this.filteredBrand_all}} </span></label></div>
                        <nz-select [(ngModel)]="all_models" id="filterStyle" (ngModelChange)="filterReason($event)">
                            <nz-option *ngFor="let item of brandsGathered" [nzValue]="item" [nzLabel]="item"></nz-option>
                        </nz-select><br>
                    </nz-col>
                </nz-row>
                <nz-table #AllItems_Set [nzData]="all_models" id="AllItems_Set" [nzFrontPagination]="false" nzSize="small"  > 
                    <thead>
                        <tr>
                            <th [nzSortFn]="sortSupplier" [nzWidth]="'15%'">Supplier</th>
                            <th [nzSortFn]="sortBrand" [nzWidth]="'8%'">Brand</th>
                            <th [nzSortFn]="sortCode" [nzWidth]="'8%'">Code</th>
                            <th [nzSortFn]="sortDescription" [nzWidth]="'15%'">Description</th>
                            <th [nzSortFn]="sortMSL" [nzWidth]="'8%'">MSL</th>
                            <th [nzSortFn]="sortSOH" [nzWidth]="'8%'">SOH</th>
                            <th [nzSortFn]="sortWIP" *ngIf="this.wip_visible==true" style="color:var(--textcolor)">WIP</th>
                            <th [nzSortFn]="sortDiff" [nzWidth]="'8%'">diff</th>
                            <th [nzSortFn]="sortS_SOH" [nzWidth]="'8%'" nz-tooltip nzTooltipTitle="Suppliers stock on hand">S_SOH</th>
                            <th [nzSortFn]="sortS_Price" [nzWidth]="'8%'">Cost</th>
                            <th [nzSortFn]="sortS_Sales" *ngIf="this.editMode == false" [nzWidth]="'8%'">Sales Trend</th>
                            <th *ngIf="this.editMode == true" [nzWidth]="'8%'">Save</th>
                            <th *ngIf="this.editMode == true" [nzWidth]="'8%'">Cancel</th>
                        </tr>
                        
                    </thead>
                    
                    <tbody>
                        <tr *ngFor="let item of AllItems_Set.data | filter: '': []:0:pageSize" style="padding: 1px;">
                            <td *ngIf="this.editMode == false" style="text-align: center;">{{item.supplier_name}} <a *ngIf="item.replenish_active != true" (click)="trackAltStock(item,'A'); this.altStock=true;"><i nz-icon nzType="info-circle" aria-hidden="true" nz-tooltip nzTooltipTitle="Supplier deactivated replenishment"></i></a>                                </td>
                            <td *ngIf="this.editMode == true && this.editId != item.modelId">{{item.supplier_name}} <a *ngIf="item.replenish_active != true" (click)="trackAltStock(item,'A'); this.altStock=true;"><i nz-icon nzType="info-circle" aria-hidden="true" nz-tooltip nzTooltipTitle="Supplier deactivated replenishment"></i></a></td>
                            <td *ngIf="this.editMode == true && this.editId == item.modelId" style="text-align: center;">
                                <input nz-dropdown [nzDropdownMenu]="menu5" placeholder="{{item.supplier_name}}" id="supllierInput" style="width: 50%; text-align: center;" autocomplete="off" />
                                <nz-dropdown-menu #menu5="nzDropdownMenu">
                                    <ul nz-menu nzSelectable *ngFor="let supplier of suppliersTemp">
                                        <li nz-menu-item (click)="chooseSupplier(supplier,0)">{{supplier.supplier_name}}</li>
                                    </ul>
                                </nz-dropdown-menu>
                            </td>
                            <td style="text-align: center; border-right: solid 0.01px var(--textcolor); border-left: solid 0.01px var(--textcolor);"><img style="margin: 0 auto;" src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{item.brandLogo}}" class="brandImage" nz-tooltip="{{item.brand}}" />
                                <div style="display: none;">{{item.brand}}</div>
                            </td>
                            <td style="text-align: center;"><div>{{item.code}}</div><div id="manCode_disp" nz-tooltip nzTooltipTitle="manufacturers stock code"  *ngIf="item.code != item.manufacturers_code && item.manufacturers_code != null">{{item.manufacturers_code}}</div></td>
                            <td>
                                <div>
                                    <a (click)="inquire_on_size(item)" nz-dropdown [nzDropdownMenu]="menu_basic" nz-tooltip nzTooltipTitle="(click to edit)">
                                        <div class="description_clickable" style="padding-right: 2px;">{{item.description}}</div>
                                    </a>
                                    <nz-dropdown-menu #menu_basic="nzDropdownMenu">
                                        <ul nz-menu nzSelectable style="border-radius: 8px;">
                                            <li nz-menu-item (click)="deleteModel(item.modelId)" *ngIf="item.holding_status !=2" class="hover_list"><b>Disable Item</b></li>
                                            <li nz-menu-item (click)="deleteModel(item.modelId)" *ngIf="item.holding_status ==2" class="hover_list"><b>Re-enable Item</b></li>
                                            <!-- <li nz-menu-item (click)="modify_msl(item.modelId)" class="hover_list"><b>Change MSL</b></li> -->
                                        </ul>
                                    </nz-dropdown-menu>
                                    <a (click)="inquire_on_size(item);" nz-dropdown [nzDropdownMenu]="menu6" *ngIf="item.holding_status == 1 && item.msl >0 " style="color: var(--textcolor); position: absolute; right: 5px; bottom: 20px;"><i nz-icon nzType="book" aria-hidden="true" nz-tooltip nzTooltipTitle="Holding item until stock is available at {{item.supplier_name}}. (click to edit)"></i></a>
                                    <nz-dropdown-menu #menu6="nzDropdownMenu">
                                        <ul nz-menu nzSelectable style="border-radius: 8px;">
                                            <!-- <li nz-menu-item (click)="holdItem(item.modelId)" class="hover_list"><b>Stop Holding Item</b></li> -->
                                            <li nz-menu-item (click)="inquire_on_size(item)" class="hover_list"><b>Investigate Further</b></li>
                                            <!-- <li nz-menu-item (click)="deleteModel(item.modelId)" class="hover_list"><b>Disable Item</b></li> -->
                                        </ul>
                                    </nz-dropdown-menu>
                                    <div style="width: 100%; display: flex;">
                                        <a (click)="inquire_on_size(item);" nz-dropdown [nzDropdownMenu]="menu_disabled" *ngIf="item.holding_status == 2" style="color: var(--warningcolor); position: absolute; right: 15px;">
                                            <div style="position: absolute; right: 5px; bottom: 6px;"><i nz-icon nzType="stop" aria-hidden="true" nz-tooltip nzTooltipTitle="Item Disabled for automated replenishment. (click to edit)"></i></div>
                                        </a>
                                    </div>
                                    <nz-dropdown-menu #menu_disabled="nzDropdownMenu">
                                        <ul nz-menu nzSelectable style="border-radius: 8px; ">
                                            <li nz-menu-item (click)="deleteModel(item.modelId)" class="hover_list"><b>Re-enable Item</b></li>
                                        </ul>
                                    </nz-dropdown-menu>
                                </div>
                            </td>
                            <td *ngIf="this.editMode == false" style="text-align: center;">{{item.msl}}</td>
                            <td *ngIf="this.editMode == true && this.editId != item.modelId" style="text-align: center;">{{item.msl}}</td>
                            <td *ngIf="this.editMode == true && this.editId == item.modelId" style="text-align: center;">
                                <input nz-input placeholder="{{item.msl}}" type="number" placeholder="{{item.msl}}" value="{{item.msl}}" min="0" id="msl" style="width: 50%;" id="editPOI" autocomplete="off" />
                            </td>
                            <td style="text-align: center;" [ngStyle]="{'color':item.live == true ? 'var(--textcolor)': '' }"><b>{{item.soh}} <span *ngIf="item.back_order_qty >0" style="color: cadetblue; cursor:ponter;" nz-tooltip nzTooltipTitle="SOH | Back order Quantity"> | {{item.back_order_qty}}</span></b></td>
                            <td style="text-align: center;" *ngIf="this.wip_visible == true;"><b>- {{item.work_in_progress}}</b></td>
                            <td style="text-align: center;" [ngStyle]="{'color':item.diff < 0 ? 'var(--errorcolor)' : 'var(--textcolor)' }">{{item.diff}}</td>
                            <td style="text-align: center;" [ngStyle]="{'color':item.supplier_live == true ? 'var(--textcolor)' : '' }" style="text-align: center;">{{item.sohSupplier}}
                                <a nz-dropdown [nzDropdownMenu]="menu8" (click)="inquire_on_size(item);" *ngIf="item.diff*-1>item.sohSupplier && item.sohSupplier > 0 && item.holding_status == 0" style="color: var(--warningcolor);"><i nz-icon nzType="info-circle" aria-hidden="true" nz-tooltip nzTooltipTitle=" Supplier only has {{item.sohSupplier}} unit(s) Partial fill will  be available in order summary"></i></a>
                                <a nz-dropdown [nzDropdownMenu]="menu8" (click)="inquire_on_size(item);" *ngIf="item.diff*-1>item.sohSupplier && item.sohSupplier == 0 && item.holding_status == 0" style="color: var(--warningcolor);"><i nz-icon nzType="info-circle" aria-hidden="true" nz-tooltip nzTooltipTitle="Supplier does not have enough stock for your MSL"></i></a>
                                <nz-dropdown-menu #menu8="nzDropdownMenu">
                                    <ul nz-menu nzSelectable style="border-radius: 8px;">
                                        <!-- <li nz-menu-item (click)="holdItem(item.modelId)" class="hover_list" *ngIf="item.sohSupplier == 0;"><b>Hold Item</b></li> -->
                                        <li nz-menu-item (click)="inquire_on_size(item)" class="hover_list"><b>Investigate Further</b></li>
                                        <!-- <li nz-menu-item (click)="deleteModel(item.modelId)" class="hover_list"><b>Disable Item</b></li> -->
                                    </ul>
                                </nz-dropdown-menu>
                            </td>

                            <td style="text-align: center;" *ngIf="item.supplierPrice > 0">R {{item.supplierPrice}}</td>
                            <td *ngIf="item.supplierPrice == 0" style="text-align: center;">
                                <a style="color: var(--errorcolor);" nz-tooltip nzTooltipTitle="Pricing can not be applied if supplier has no stock"><b>-</b></a>
                            </td>
                            <td style="text-align: center;">
                                <div style="display:flex">
                                    <span style="color: cadetblue; cursor:ponter; position: absolute; bottom: 5%; right: 30%;" nz-tooltip nzTooltipTitle="sales (120 days)"> | {{item.sales}}</span>
                                    <span *ngIf="this.editMode == false;" nz-icon nzType="stock" nzTheme="outline" nz-tooltip="Sales Trend" style="color: var(--textcolor);font-size: 2rem !important;cursor: pointer" (click)="viewSalesTrendTyre(item.msfId)"></span>
                                </div>    
                                <button nz-button nztype="default" *ngIf="this.editMode == true && this.editId == item.modelId" (click)="saveMSLChange(item)">
                                <i nz-icon nzType="save" style="font-size: 1.5rem; vertical-align: top; " nz-tooltip nzTooltipTitle="Save Changes"></i>
                                </button>
                            </td>
                            <td style="text-align: center;" *ngIf="this.editMode == true && this.editId == item.modelId">
                                <button nz-button nzType="default" (click)="cancel()">
                                <i nz-icon nzType="close-square" nz-tooltip nzTooltipTitle="Cancel"></i>
                                </button>
                            </td>

                        </tr>
                    </tbody>

                </nz-table>
            </nz-spin>
        </nz-tab>

    </nz-tabset>

</div>
<!-- </nz-spin> -->