import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { AuthService } from '../auth.service';
import { environment } from './../../environments/environment';
import { WAMessageGroup, WAMessage, WAArchive, WAConversation, WAMessageTemplate } from '../models/whatsapp';
import { NotificationService } from '../shared/notification.service';

@Injectable({
  providedIn: 'root'
})
export class WhatsappService {

  private apiUrl:string = 'v1/messages/';
  public isLoading: boolean = false;
  public messagelist: WAMessageGroup[] = [];
  public waConversation: WAMessage[] = [];
  public loadedConversation: WAConversation;
  public searchString: string = '';

  constructor(private http: HttpClient, private authService: AuthService, private notification: NotificationService, @Inject(LOCALE_ID) public locale: string) { }

  totalUnread(){
    get:{
      return this.messagelist.reduce((acc, item) => {return acc + item.unread}, 0)
    }
  }

  public GetAllMessages() {
    if (this.authService.user.waEnabled === false) return; //early exit
    this.isLoading = true;
    this.http.get<WAMessageGroup[]>(environment.ApiUrl() + this.apiUrl + "whatsapp").subscribe(
      {
        next: val => {
          this.messagelist = val;
          this.isLoading = false;
        },
        error: err => {
          this.messagelist = [];
          this.notification.handleErrorSilent(err);
          this.isLoading = false;
        }
      }
    )
  }

  public GetConversation(cellNo: string) {
    if (this.authService.user.waEnabled === false) return; //early exit
    this.isLoading = true;
    this.http.get<WAConversation>(environment.ApiUrl() + this.apiUrl + `whatsapp/${cellNo}`).subscribe(
      {
        next: val => {
          this.waConversation = val.messages;
          this.loadedConversation = val;
          
          this.isLoading = false;
          
        },
        error: err => {
          this.waConversation = [];
          this.notification.handleErrorSilent(err);
          this.isLoading = false;
        }
      }
    )
  }

  public GetAllMessagesAdmin() {
    // if (this.authService.user.waEnabled === false) return; //early exit
    this.isLoading = true;
    this.http.get<WAMessageGroup[]>(environment.ApiUrl() + this.apiUrl + "admin/whatsapp").subscribe(
      {
        next: val => {
          this.messagelist = val;
          this.isLoading = false;
        },
        error: err => {
          this.messagelist = [];
          this.notification.handleErrorSilent(err);
          this.isLoading = false;
        }
      }
    )
  } 

  public GetConversationAdmin(cellNo: string, clientId: number) {
    // if (this.authService.user.waEnabled === false) return; //early exit
    this.isLoading = true;
    this.http.get<WAMessage[]>(environment.ApiUrl() + this.apiUrl + `admin/whatsapp/${clientId}/${cellNo}`).subscribe(
      {
        next: val => {
          this.waConversation = val;          
          this.isLoading = false;
          
        },
        error: err => {
          this.waConversation = [];
          this.notification.handleErrorSilent(err);
          this.isLoading = false;
        }
      }
    )
  }

  public RefreshAll() {
    if (this.authService.user.waEnabled === false) return; //early exit
    this.isLoading = true;
    this.http.get<WAMessageGroup[]>(environment.ApiUrl() + this.apiUrl + "whatsapp").subscribe(
      {
        next: val => {
          this.messagelist = val;
          if (this.waConversation.length > 0) {
            this.GetConversation(this.waConversation[0].cell);
            
          }
          else {
            this.isLoading = false;
            
          }
          
          
        },
        error: err => {
          this.messagelist = [];
          this.notification.handleErrorSilent(err);
          this.isLoading = false;
        }
      }
    )
    


  }

  public ClearConversation() {
    if (this.authService.user.waEnabled === false) return; //early exit
    this.waConversation = [];
    this.GetAllMessages();
  }

  public MarkConversationAsRead() {
    if (this.authService.user.waEnabled === false) return; //early exit
    setTimeout(() => {
      if (this.waConversation.length > 0) {
        
        this.http.post(environment.ApiUrl() + this.apiUrl + `whatsapp/${this.waConversation[0].cell}/markread`, null).subscribe({
          next: () => {}
          ,error: err => {
            this.notification.handleErrorSilent(err);
          }
        })
      }

    },1500)
    
  }

  public sendTemplate(msg: WAMessageTemplate) {
    if (this.authService.user.waEnabled === false) return; //early exit
    this.isLoading = true;
    let message: {name: string, cell: string, message: string} = {
      name: this.waConversation[0].name, 
      cell: this.waConversation[0].cell, 
      message: msg.messageText}
    this.http.post(environment.ApiUrl() + this.apiUrl + `whatsapp/${this.waConversation[0].cell}/sendTemplate/${msg.templateId}`, message).subscribe({
      next: () => {
        this.notification.showSuccess("Message Sent");
        this.GetConversation(this.waConversation[0].cell);
        this.isLoading = false;
      }
      ,error: err => {
        this.notification.handleError(err);
        this.GetConversation(this.waConversation[0].cell);
        this.isLoading = false;
      }
    })
    
  }

  public sendMessage(messageStr: string) {
    if (this.authService.user.waEnabled === false) return; //early exit
    this.isLoading = true;
    let message: {name: string, cell: string, message: string} = {
      name: this.waConversation[0].name, 
      cell: this.waConversation[0].cell, 
      message: messageStr}
    this.http.post(environment.ApiUrl() + this.apiUrl + `whatsapp/${this.waConversation[0].cell}/sendmessage`, message).subscribe({
      next: () => {
        this.notification.showSuccess("Message Sent");
        this.GetConversation(this.waConversation[0].cell);
        this.isLoading = false;
      }
      ,error: err => {
        this.notification.handleError(err);
        this.GetConversation(this.waConversation[0].cell);
        this.isLoading = false;
      }
    })
  }

  public sendMessageAdmin(messageStr: string) {
    // if (this.authService.user.waEnabled === false) return; //early exit
    this.isLoading = true;
    let message: {name: string, cell: string, message: string} = {
      name: this.waConversation[0].name, 
      cell: this.waConversation[0].cell, 
      message: messageStr}
      
    this.http.post(environment.ApiUrl() + this.apiUrl + `admin/whatsapp/${this.waConversation[0].clientId}/${this.waConversation[0].cell}/sendmessage`, message).subscribe({
      next: () => {
        this.notification.showSuccess("Message Sent");
        this.GetConversationAdmin(this.waConversation[0].cell, this.waConversation[0].clientId);
        this.isLoading = false;
      }
      ,error: err => {
        this.notification.handleError(err);
        this.GetConversation(this.waConversation[0].cell);
        this.isLoading = false;
      }
    })
  }

  public sendImage(formData: FormData) {
    if (this.authService.user.waEnabled === false) return; //early exit
    this.isLoading = true;
    this.http.post(environment.ApiUrl() + this.apiUrl + `whatsapp/${this.waConversation[0].cell}/sendimage`, formData).subscribe({
      next: _ => {
        this.notification.showSuccess("Image Sent");
        this.GetConversation(this.waConversation[0].cell);
        this.isLoading = false;
      },
      error: err => {
        this.notification.handleError(err);
        this.GetConversation(this.waConversation[0].cell);
        this.isLoading = false;
      }
    })
  }

  public ClearConversationAdmin() {
    
    this.waConversation = [];
    //this.GetAllMessagesAdmin();
  }

  public isLoadingMarkAllAsReadAdmin: boolean = false;
  public MarkConversationAsReadAdmin() { 
    this.isLoadingMarkAllAsReadAdmin = true;
    this.http.post(environment.ApiUrl() + this.apiUrl + `admin/whatsapp/${this.waConversation[0].clientId}/${this.waConversation[0].cell}/markAsRead`,null).subscribe({
      next: () => {
        this.isLoadingMarkAllAsReadAdmin = false;
        this.ClearConversationAdmin();
      }
      ,error: err => {
        this.notification.handleError(err);
        this.isLoadingMarkAllAsReadAdmin = false;
      }
    })
  }

  public setArchive(item: WAArchive){
    this.messagelist = this.messagelist.filter(val => val.cell !== item.cell)

    this.http.put(environment.ApiUrl() + this.apiUrl + `whatsapp/${item.cell}/markarchive`, null).subscribe({
      next: () => {}
      ,error: err => {
        this.notification.handleErrorSilent(err);
      }
    })
  }

  public filterArchive(toggle: boolean){
    if(!toggle)
      return this.waConversation.filter(val => val.statusId !== 0);
    else return this.waConversation
  }


  publicMessageListSize = 25; //this will store the amount of messages to display in the inbox
  public filterMessages(onlyUnread: boolean) {
    let retList = this.messagelist.slice();
    
    if (this.searchString && this.searchString.length > 0) {
      retList = retList.filter(val => (val.cell?.includes(this.searchString)) || (val.cell?.slice(2,11).padStart(10, '0').includes(this.searchString)) || (val.name?.toLocaleLowerCase().includes(this.searchString.toLocaleLowerCase())))
    }
     
    if (onlyUnread) {
      retList = retList.filter(val => val.unread > 0);
    }
    
    return retList.slice(0,this.publicMessageListSize);
  }

  public hasMoreMessages() : boolean {
    return this.messagelist.length > this.publicMessageListSize;
  }

  public loadMoreMessages() : void {
    this.publicMessageListSize += 25;
  }
  
  
}
