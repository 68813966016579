<!--Lines View Modal-->
<nz-table #closed [nzData]="quotes" [nzFrontPagination]="false">
    <thead>
        <tr>
            <th [nzSortFn]="custSortFn">Name</th>
            <th [nzSortFn]="emailSortFn">Email</th>
            <th [nzSortFn]="cellSortFn">Cell</th>
            <th [nzSortFn]="refSortFn">Ref#</th>
            <th [nzSortFn]="dtSortFn">Quote Date </th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of closed.data">
            <td>{{item.customername}} </td>
            <td>{{item.customeremail}}</td>
            <td>{{item.customercell}}</td>
            <td>{{item.quoteRef}}</td>
            <td>{{item.dt}}</td>
            <td>
                <button nz-button nzType="primary" (click)="viewquote(item.quoteId)">
            View <span nz-icon nzType="sf:externalSVG" nzTheme="outline" ></span>
          </button>
            </td>
        </tr>
    </tbody>
</nz-table>