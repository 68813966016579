<div nz-row>
    <div nz-col nzSpan="24" style="text-align: center;">
        <h2>USERS</h2>
    </div>
</div>
<div nz-row [nzJustify]="'center'">
    <div nz-col nzSpan="23">
        
        
        <input nzSize="large"
            placeholder='Search for Clients'
            nz-input
            [(ngModel)]="selectedClient"
            (ngModelChange)="onTypeFilter($event)"
            [nzAutocomplete]="auto" />
        <nz-autocomplete #auto>
            <nz-auto-option *ngFor="let option of filteredClients" [nzValue]="option">
                {{option.name}}
            </nz-auto-option>
        </nz-autocomplete>
        
        <nz-divider></nz-divider>

        <nz-table #usersTable [nzData]="users" [nzFrontPagination]="false" nzSize="small" [nzLoading]="isLoadingUsers" *ngIf="this.selectedClientObj">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Contact</th>
                    
                    <th>Roles</th>
                    <th style="width: 100px;">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let user of usersTable.data">
                    <td>{{ user.fullname }}<small *ngIf="user.superUser" class="color-primary"><br />{{user.superUser}}</small></td>
                    <td>{{ user.email }}<br />{{ user.cell }}</td>
                    
                    <td>
                        <!-- <button nz-button nzType="default" *ngIf="!claim.claimId">{{ claim.name }}</button>
                    <button nz-button nzType="default" *ngIf="claim.claimId">{{ claim.name }}</button> -->
                        <button nz-button nzType="primary" [nzDanger]="!role.isSelected"  *ngFor="let role of user.roles"
                            style="margin-right: 10px; margin-bottom: 10px;"
                            (click)="onRoleBtnClick(role, user)"
                            [nzLoading]="loadingButtons.includes(user.userId.toString() + '.' + role.roleId.toString())">
                            <i nz-icon nzType="check-circle" nzTheme="outline"
                                style="font-size: 18px;"
                                *ngIf="role.isSelected"></i>
                            <i nz-icon nzType="minus-circle" nzTheme="outline"
                                style="font-size: 18px;"
                                *ngIf="!role.isSelected"></i>
                            {{ role.roleName }}
                        </button>
                    </td>
                    <td style="font-size: 1.5rem">
                        <a (click)="onEditUserClick(user)" nz-tooltip="Edit user details">
                            <span nz-icon nzType="edit" nzTheme="outline" style="font-size: 1.5rem !important; margin-right: 10px;"></span>
                        </a>
                        <a (click)="sendUserDetails(user.userId)" nz-tooltip="Send user details email">
                            <span nz-icon [nzType]="sendingButtons.includes(user.userId) ? 'loading' : 'mail'" nzTheme="outline" style="font-size: 1.5rem  !important"></span>
                        </a>
                        <!-- <a style="margin-right: 10px;" (click)="onEditUserClick(user)">
                            <i nz-icon nzType="edit" nzTheme="outline" style="font-size: 1.5rem; color: #136413;" nz-tooltip
                                nzTooltipTitle="Edit User"></i>
                        </a>
                        
                        <a (click)="sendUserDetails(user.userId)" style="margin-right: 10px;">                                    
                            <i nz-icon [nzType]="sendingButtons.includes(user.userId) ? 'loading' : 'mail'" nzTheme="outline" 
                                style="font-size: 1.5rem; color: #136413;" nz-tooltip
                                nzTooltipTitle="Email User Details"></i>                                   
                        </a> -->
                        
                        
                        
                    </td>
                    
                </tr>
            </tbody>
        </nz-table>
    </div>
</div>
<nz-modal [(nzVisible)]="isEditUserModalVisible" nzTitle="Edit User" (nzOnCancel)="isEditUserModalVisible = false">
    <div *nzModalContent>
        <nz-spin [nzSpinning]="isSavingUser">

        
            <form nz-form [formGroup]="editUserForm">
                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Username</nz-form-label>
                    <nz-form-control>
                        <nz-input-group nzPrefixIcon="user" >
                            <input nz-input placeholder="Username" disabled="true" [value]="editingUser.userName">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="6" nzFor="fullname">Full Name</nz-form-label>
                    <nz-form-control [nzErrorTip]="'Name Required'">
                        <nz-input-group nzPrefixIcon="idcard" >
                            <input nz-input name="fullname" formControlName="fullname" placeholder="Full Name">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="6" nzFor="extPosId">Ext Pos ID</nz-form-label>
                    <nz-form-control>
                        <nz-input-group nzPrefixIcon="laptop">
                            <input nz-input name="extPosId" formControlName="extPosId" placeholder="External POS ID">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="6" nzFor="email">Email</nz-form-label>
                    <nz-form-control>
                        <nz-input-group nzPrefixIcon="mail">
                            <input nz-input name="email" formControlName="email" placeholder="Email">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="6" nzFor="cell">Cell</nz-form-label>
                    <nz-form-control>
                        <nz-input-group nzPrefixIcon="phone">
                            <input nz-input name="cell" formControlName="cell" placeholder="Cell">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="6" nzFor="password">Password</nz-form-label>
                    <nz-form-control>
                        <nz-input-group nzPrefixIcon="lock">
                            <input nz-input name="password" formControlName="password" placeholder="Password">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-spin>
        
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="default" (click)="isEditUserModalVisible = false">
            <i nz-icon nzType="close" nzTheme="outline" style="vertical-align: middle; margin-top: -5px;"></i>Cancel
        </button>
        <button nz-button nzType="default" (click)="editUser()">
            <i nz-icon nzType="check" nzTheme="outline" style="vertical-align: middle; margin-top: -5px;"></i>Update
        </button>
    </div>
</nz-modal>