import { Component, OnInit } from '@angular/core';
import { WhatsappService } from './whatsapp.service';

@Component({
  selector: 'app-whatsapp',
  templateUrl: './whatsapp.component.html',
  styleUrls: ['./whatsapp.component.less']
})
export class WhatsappComponent implements OnInit {

  constructor(public waService: WhatsappService) { }

  ngOnInit(): void {
  }

  
}
