import { Component, OnInit } from '@angular/core';
import { PricelistsService } from '../../pricelists/pricelists.service';
import { FormBuilder, Validators } from '@angular/forms';
import { NotificationService } from '../../shared/notification.service';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx'; 
import * as _ from 'lodash';

@Component({
  selector: 'app-directupload',
  templateUrl: './directupload.component.html',
  styleUrls: ['./directupload.component.less']
})
export class DirectuploadComponent implements OnInit {

  fileUploadForm =this.formBuilder.group({
    formFile: [null, Validators.required]
  });

  // Global variables
  p: string = 'None selected'

  // Excel Things
  private file: File;
  fileName= 'ExcelSheet.xlsx'; 
  uploadFileInput: string = ""
  fileInputLabel: string;


  constructor(private pricelistsService: PricelistsService, private router: Router, private notification: NotificationService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
  }

  
  onFileSelect(event) {
    let af = ['.csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      if (!_.includes(af, file.type))
      {
        this.notification.showWarning("Only Excel files allowed")
      } 
      this.fileInputLabel = file.name;
      this.file = event.target.files[0];
    }
  }


  onFormSubmit() {
    if (this.fileUploadForm.value.formFile == null) 
    {     
      this.notification.showWarningTimer('Please Select a File', 1500);
      return;
    } 
    else if (this.p=='None selected')
    {
      this.notification.showWarningTimer('Please specify which price to update', 1500);
      return;
    }
    else
    {
      this.pricelistsService.directUpload(this.file, this.p).subscribe(response => 
        {
          if (response == "success") 
          {
            // Reset the file input
            this.notification.showSuccess("Price list updated");
            this.ngOnInit();
            this.uploadFileInput= "";
            this.fileInputLabel = "";
          }
        }, 
        error => {
          this.notification.showError(error)
        });
    }
  };
  

  priceToUpdate(s) {
    this.p = s 
  };

}
