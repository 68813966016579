import { Component, OnInit } from '@angular/core';
import { InsightsService } from './insights.service';

@Component({
  selector: 'app-insights',
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.less']
})
export class InsightsComponent implements OnInit {
  
  private insightsService: InsightsService;
  Week = '';
  Alerts = [];
  isLoading = false;
  
  constructor(_insightsService: InsightsService) {
    this.insightsService = _insightsService;
   }

  ngOnInit(): void {
    this.isLoading = true;
    this.insightsService.callInsightsMain().subscribe(
      result => {
        this.Week = result.week;
        this.Alerts = result.alerts_data;
        this.isLoading = false;
      }
      );
    }}
