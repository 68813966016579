export class Client {
    name: string
    password: string
    lat: string
    lon: string
    type: string
    email: string
    cell: string
    refClientId: number
    buyerId: number
    debtorAcc: string
    creditorAcc: string
    minorder: number
    creditlimit: number
    minorderfee: number
    active: number
    pricelid: number
    tc: string
    logistics: number
    Description: string
    deliveryfee: number
    apicallcnt: number
    sohcallcnt: number
    sohMax: number
    isSuperUser: boolean
}

export class ClientData {
    client_id: number
    client_name: string
    client_logo: string
    client_franchise: string
}

export class Pricelist {
    clientId: number
    plinternal: string
    deflt: string
    wholesale: string
    plretail: string
    plonline:string
    plclear: string
    plcustom1:string
    plcustom2:string
    plcustom3: string
    plcustom4:string
}

export class ClientAdd {
    name: string
    type: string
    email: string
    cell: string
    clientId: number
    buyerId: number
    supplier: string
    plId: number
}

export class ClientNew {
    name: string
    email: string
    cell: string
    password: string
    plId: number
    clientId: number
    type: string
    franchiseId: number
    regionId: number
}

export class ClientEdit{
    name: string
    clientId: number
    debtorAcc: string
    creditorAcc: string
    creditlimit: number
    active: boolean
    replenish: boolean
    minorder: number
    minorderfee: number
    plid: number
    plid_rep: number
    discntblk: number
    discntblksper: number
    discntreplenish: number
    tc: string
    logislaid: number
    Description: string
    deliveryfee: number
    r_ext_ref: boolean
    r_delivery: boolean
    r_salesman: boolean
    r_v_reg: boolean
    r_v_make: boolean
    r_milage:boolean
    r_txt: boolean
    isDeliveryOptionShowing: boolean
    state: string
    connectionId: number
    customSlaActive: boolean
    sohMax: number
    creditCheck:boolean = false;
}

export class SlaSlot
{
  clientSlaSettingId: number;
  cutOffTime: string;
  expectedDelivery: string;
}


export class SlaOptions
{
  logistics_sla_id: number;
  description: string;
}

export class SlaSlots
{
  clientSlaSettingId: number;
  cutOffTime: string[];
  expectedDelivery: string[];
  selectedCuttOffslot: number;
  selectedDeliveryslot:number;
}

export class ClientSettings{
    debtorAcc: string
    sellingProcess: string
    creditlimit: number
    creditorAcc: string
    activeStatus: boolean
    replenishStatus: boolean
    buyerId: number
    creditCheck: boolean
}

export class ClientReceipt{
    documentNumber: string
    value: number
    buyerId: number
}


export class ClientPricing{
    pricelistId: number
    pricelistId_Repleishment: number
    minOrder: number
    minOrderFee: number
    bulkDiscount: number
    sbulkDiscount: number
    repDiscount: number
    buyerId: number
}

export class ClientLogistics{
    logisticsSlaId: number
    deliveryFee: number
    termscon: string
    buyerId: number
    connectionId:number;
    customSlaActive:boolean
    customSlaTimes: SlaSlot[]
    removeSlaTimes: SlaSlot[]
    sohMax: number
}

export class ClientCart{
    extref: boolean
    deliveryInfo: boolean
    salesmanName: boolean
    vehicleRegistration: boolean
    vehicleMake: boolean
    vehicleMilage: boolean
    commentBox: boolean
    isDeliveryOptionShowing: boolean
    buyerId: number
}

export class LinkedAccountLogin {
    clientId: number
    password: string
}

export class ClientAddReturn{
    clientId : number
    userId : number
}

export class ClientBase {
    clientId: number;
    type: string;
    name: string;
}

