import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { clientStock,sentOrders,unsentOrders,api_clients } from '../models/diagnostic';
import { DiagnosticService } from './diagnostic.service';
import { NotificationService } from '../shared/notification.service';
@Component({
  selector: 'app-diagnostic',
  templateUrl: './diagnostic.component.html',
  styleUrls: ['./diagnostic.component.less']
})
export class DiagnosticComponent implements OnInit {
  stockLoad: clientStock[] = [];
  ss_api: api_clients[] = [];
  unsent: unsentOrders[] = [];
  sent: sentOrders[] = [];
  singlePing: api_clients;
  isLoading = false;

  constructor(private diagService: DiagnosticService,private router:Router,private notification: NotificationService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.stockLoad = [];
    this.diagService.getStockLoad().subscribe(res=>
      {
        this.stockLoad = res as clientStock[];
        this.isLoading=false;
      })

    
  }
  scan_clients(): void{
    this.isLoading = true;
    this.diagService.getAPI_Clients().subscribe(res =>
      {
        this.ss_api = res as api_clients[];
        this.isLoading = false;
      } )
  }
  showAPI(): void{
    // this.isLoading = true;
    let apiTable:HTMLElement = document.getElementById("apiTable") as HTMLElement;
    apiTable.style.display = "block";
    let stockTable:HTMLElement = document.getElementById("stockTable") as HTMLElement;
    stockTable.style.display = "None";
    

  }

  showStock(): void{
    // this.isLoading = true;
    let apiTable:HTMLElement = document.getElementById("apiTable") as HTMLElement;
    apiTable.style.display = "None";
    let stockTable:HTMLElement = document.getElementById("stockTable") as HTMLElement;
    stockTable.style.display = "block";

  }

  scan_one(id): void{
    
    
    this.isLoading = true;
    for(let x = 0; x < this.ss_api.length ; x++ )
    {
      
      if(this.ss_api[x]['clientId'] == id )
      {
        this.diagService.scan_client(id).subscribe(res =>
          {
            this.ss_api[x] = res as api_clients;
            this.isLoading = false;
            
          } )
      }
    }
  }

}
