<div class="container-fluid text-center">
    <div class="row">
        <div class="col-lg-12" style="padding-left: 5%;padding-right: 4%;">
            <h4><b>Suppliers</b></h4>
            <p>StockFinder connects you to multiple suppliers. You can see what stock they have on hand by activating them below. Please note that you will only be able to see Stock on Hand and not be able to see the pricing or order from them. Pricing and
                Ordering need to be set up by the Supplier. </p>
        </div>
    </div>
</div>

<div class="container-fluid" class="text-center">
    <div class="row">
        <div class="col-lg-12" style="padding-left: 5%;padding-right: 4%;">
            <nz-spin [nzSpinning]="isLoading">
                <nz-table #supplierTable [nzData]="suppliers" [nzFrontPagination]="false">
                    <thead>
                        <tr>
                            <th>Supplier</th>
                            <th class="text-center">Rank</th>
                            <th>Selling Process</th>
                            <th *ngIf="isPAM">Buy In Pricing</th>
                            <th *ngIf="isPAM">Sell Out Pricing</th>
                            <th>Activated</th>
                        </tr>
                    </thead>
                    <tbody class="tbody" cdkDropList (cdkDropListDropped)="alterRankings($event)">
                        <tr *ngFor="let supplier of supplierTable.data" cdkDrag>
                            <td>{{ supplier.name }} ({{ supplier.cell }})</td>
                            <td class="text-center">
                                <ng-container *ngIf="supplier.rank == 9999 then dash ; else rank"></ng-container>
                                <ng-template #dash>-</ng-template>
                                <ng-template #rank>{{ supplier.rank }}</ng-template>
                            </td>
                            <td *ngIf="supplier.sell_state == 'pos' || supplier.sell_state == 'email'">Order via StockFinder</td>
                            <td *ngIf="supplier.sell_state == 'soh'">Display SOH, No Ordering</td>
                            <td *ngIf="supplier.sell_state == 'no'">Display Price, No Ordering</td>
                            <td *ngIf="!supplier.sell_state"></td>
                            <td *ngIf="isPAM">
                                <div>
                                    <nz-select [(ngModel)]="supplier.buy_in_optionId" [compareWith]="compareFn" (ngModelChange)="changeBuyInPricing(supplier.connectionId,$event)" style="width: 100%;">
                                        <nz-option nzLabel="Set by Supplier" nzValue=1></nz-option>
                                        <nz-option nzLabel="On Invoice" nzValue=2></nz-option>
                                        <nz-option nzLabel="All In" nzValue=3></nz-option>
                                        <nz-option nzLabel="Branch Cost" nzValue=4></nz-option>
                                        <!--<nz-option nzLabel="Other One" nzValue=5></nz-option>
                                <nz-option nzLabel="Other Two" nzValue=6></nz-option>
                                <nz-option nzLabel="Other Three" nzValue=7></nz-option>-->
                                    </nz-select>
                                </div>
                            </td>
                            <td *ngIf="isPAM">
                                <div>


                                    <nz-select [(ngModel)]="supplier.sell_out_optionId" [compareWith]="compareFn" (ngModelChange)="changeSellOutPricing(supplier.connectionId,$event)" style="width: 100%">
                                        <nz-option nzLabel="Default" nzValue=11></nz-option>
                                        <nz-option nzLabel="Low" nzValue=8></nz-option>
                                        <nz-option nzLabel="Medium" nzValue=9></nz-option>
                                        <nz-option nzLabel="High" nzValue=10></nz-option>
                                    </nz-select>

                                </div>
                            </td>
                            <td>
                                <!-- <span *ngIf="supplier.linked">
                                <div class="btn-group">
                                    <button nz-button nzType="default">
                                        <i nz-icon nzType="check" nzTheme="outline"></i>
                                        Yes
                                    </button>
                                    <button nz-button nzType="default" (click)="deleteSupplierLink(supplier.supplier_id)">
                                        <span nz-icon nzType="close" nzTheme="outline"></span>
                                        No
                                    </button>
                                </div>
                            </span>

                            <span *ngIf="supplier.linked === false">
                                <div class="btn-group">
                                    <button nz-button nzType="default" (click)="addSupplierLink(supplier.supplier_id)">
                                        <i nz-icon nzType="check" nzTheme="outline"></i>
                                        Yes
                                    </button>
                                    <button nz-button nzType="default">
                                        <span nz-icon nzType="close" nzTheme="outline"></span>
                                        No
                                    </button>
                                </div>
                            </span> -->
                                <nz-switch [ngModel]="supplier.linked" (ngModelChange)="toggleSupplierLink(supplier.supplier_id, $event)" [nzCheckedChildren]="checkedTemplate" [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                                <ng-template #checkedTemplate><span nz-icon nzType="check"></span></ng-template>
                                <ng-template #unCheckedTemplate><span nz-icon nzType="close"></span></ng-template>
                            </td>
                        </tr>
                    </tbody>
                </nz-table>
            </nz-spin>
        </div>
    </div>

</div>