import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ReportBase64 } from '../models/reports';
import { NotificationService } from './notification.service';
import { AuthService } from '../auth.service';
import { CommunicationBase } from '../models/communication';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  apiUrl = 'v1/reports/';
  public invoiceData = '';
  public isGeneratingReport = false;

  constructor(private http: HttpClient, private notification: NotificationService, 
              private router: Router,private authService:AuthService) { }

  private downloadFile(base64:any,fileName:any){
    // const src = `data:application/pdf;base64, ${base64}`;
    // const link = document.createElement("a")
    // link.href = src
    // link.download = fileName
    // link.click()
    // link.remove()

    // Fix For all browsers including Mobile
    var element = document.createElement('a');
    var clearUrl = base64.replace(`data:application/pdf;base64,${base64}`);
    element.setAttribute('href', 'data:application/octet-stream;base64,' + encodeURIComponent(clearUrl));
    element.setAttribute('download', fileName);
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
 
    }


  public jobCard(jobCardId: number, withRev: boolean = false) {
    return this.http.get<ReportBase64>( environment.ApiUrl() + this.apiUrl + `jobcard/${jobCardId.toString()}/${withRev}`);
  }

  public openJobCard(jobCardId: number, title: string = "JOBCARD", withRev: boolean = false) {
    this.jobCard(jobCardId, withRev).subscribe(
      val => {
        let pdfWindow = window.open("", "jobcard");
        // pdfWindow!.document.title = 'testing';
        if (val.title !== undefined) title = val.title;
        pdfWindow?.focus();
        pdfWindow?.document.open();
        pdfWindow!.document.write("<title>" + title + "</title><iframe width='100%' height='100%' src='data:application/pdf;base64, " + val.content + "'></iframe>");
        //window.open("data:application/pdf;base64, " + val.content);
      }
    )
  }

  public invoice(orderId: number) {
    return this.http.get<ReportBase64>( environment.ApiUrl() + this.apiUrl + "invoice/" + orderId.toString());
  }



  public openInvoice(orderId: number, title: string = "INVOICE") {
    this.invoice(orderId).subscribe(
      val => {
        let pdfWindow = window.open("",'invoice');
        if (val.title !== undefined) title = val.title;
        pdfWindow?.focus();
        pdfWindow?.document.open();
        pdfWindow!.document.write("<title>" + title + "</title><iframe width='100%' height='100%' src='data:application/pdf;base64, " + val.content + "'></iframe>");
        //window.open("data:application/pdf;base64, " + val.content);
        // this.invoiceData = val.content;
        // this.router.navigate(['sales/invoice'])
      }
    )
  }

  public downloadInvoice(orderId: number) {
    this.invoice(orderId).subscribe(
      val => {
        this.downloadFile(val.content, val.filename);
       
      }
    )
  }

  apiUrlv2 = 'v2/reports/'
  public quote(quoteId: number) {
    return this.http.get<ReportBase64>( environment.ApiUrl() + this.apiUrlv2 + "quote/" + quoteId.toString());
  }

  public openQuote(quoteId: number, reportBase : CommunicationBase, title: string = "QUOTE") {
    reportBase.isLoading = true;
    this.quote(quoteId).subscribe(
      val => {
        let pdfWindow = window.open("",'invoice');
        if (val.title !== undefined) title = val.title;
        pdfWindow?.focus();
        pdfWindow?.document.open();
        pdfWindow!.document.write("<title>" + title + "</title><iframe width='100%' height='100%' src='data:application/pdf;base64, " + val.content + "'></iframe>");
        //window.open("data:application/pdf;base64, " + val.content);
        // this.invoiceData = val.content;
        // this.router.navigate(['sales/invoice'])
        reportBase.isLoading = false;
      }
    )
  }

  public downloadQuote(quoteId: number, reportBase : CommunicationBase) {
    reportBase.isLoading = true;
    this.quote(quoteId).subscribe(
      val => {
        this.downloadFile(val.content, val.filename);
        reportBase.isLoading = false;
      }
    )
  }
  
}


