<div nz-row nzJustify="center">
    <div nz-col class="text-center" nzSpan="12">
        <nz-input-group [nzSuffix]="suffixIconSearch" style="width: clamp(200px, 75%, 400px);">
            <input type="text" nz-input [(ngModel)]="inputValue" (ngModelChange)="resetTable()" placeholder="Advanced Filter" style="text-transform:uppercase;" />
        </nz-input-group>
        <ng-template #suffixIconSearch>
            <span nz-icon nzType="search"></span>
        </ng-template>
    </div>
</div>
<br>
<nz-table #open [nzData]="getLeads()" [nzFrontPagination]="false" [nzLoading]="leadsService.isLoadingLeads">
    <thead>
        <tr>
            <th></th>
            <th [nzSortFn]="custSortFn">Customer</th>
            <th [nzSortFn]="saleSortFn">Salesman</th>
            <th [nzSortFn]="clientSortFn">Channel</th>
            <th [nzSortFn]="dtSortFn">Date Created</th>
            <th>Comments</th>
            <th [nzSortFn]="statusSortFn">Status</th>
            <th>
                <app-video-widget [videoId]="13" style="float: inline-end;"></app-video-widget>
            </th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of open.data | filter: inputValue:[]:0:pageSize">
            <td>
                <div>
                    <i nz-icon nzType="clock-circle" nzTheme="outline" class="iconNeutral" *ngIf="item.stateId < 3" nz-tooltip nzTooltipTitle="In Progress"></i>
                    <i nz-icon nzType="check-circle" nzTheme="outline" class="iconGreen" *ngIf="item.stateId === 3 && item.stateType === 1" nz-tooltip nzTooltipTitle="Completed Successfully"></i>
                    <i nz-icon nzType="close-circle" nzTheme="outline" class="iconRed" *ngIf="item.stateId === 3 && item.stateType === 0" nz-tooltip nzTooltipTitle="Not Converted to Sale"></i>
                </div>
            </td>
            <td>{{item.customerName}}<br />{{item.customerCell}} </td>

            <td>{{item.assignedTo}}</td>
            <td>
                <span *ngIf="item.buyerId === item.sellerId">{{item.leadChannel}}</span>
                <span *ngIf="item.buyerId !== item.sellerId">{{ item.buyerName }}</span>
            </td>
            <td> {{ item.dtCreated + "Z" | date:'dd-MMM HH:mm' }}</td>
            <td *ngIf="item.comment.length === 1">
                <span>{{item.comment}}</span>
            </td>
            <td *ngIf="item.comment.length > 1">
                <span *ngFor="let data of item.comment; let i = index">
                    {{data}}
                    <br>
                </span>
            </td>
            <td *ngIf="item.comment.length === 0"></td>
            <td> {{ item.statusDescription }}</td>
            <td>
                <button nz-button nzType="primary" id="btnview" (click)="viewquote(item)">
            View <span nz-icon nzType="sf:externalSVG" nzTheme="outline" ></span>
          </button>
            </td>
        </tr>
    </tbody>
</nz-table>