<div class="container-fluid">
    <div class="row ">
        <div class="col-lg-2">
        </div>
        <div class="col-lg-8 " style="text-align: center;">
            <h4 class="color"><b>Sales Insights</b></h4>
        </div>
        <div class="col-lg-2">
        </div>
    </div>
</div>

<div class="container-fluid col-lg-11">
    <nz-spin [nzSpinning]="isLoading">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12" style="padding-left: 5%;padding-right: 4%;">
                    <div style="display: block">
                        <canvas baseChart [data]="barChartData" [options]="barChartOptions" [plugins]="barChartPlugins" [type]="barChartType" (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)">
            </canvas>
                    </div>
                    <h6>
                        (Key: Increase = Green - <i nz-icon nzType="caret-up" nzTheme="outline" style="color: rgb(4, 154, 57);"></i>, Decrease = Red - <i nz-icon nzType="caret-down" nzTheme="outline" style="color: rgb(139, 0, 0);"></i>
                    </h6>
                    <nz-table #searchTable [nzData]="table" [nzFrontPagination]="false">
                        <thead>
                            <tr>
                                <th [nzSortFn]="custSortFn" [nzSortOrder]="null">Customer</th>
                                <th [nzSortFn]="thisSortFn">This week</th>
                                <th [nzSortFn]="lastSortFn">Last week</th>
                                <th [nzSortFn]="difSortFn">Difference</th>
                                <th [nzSortFn]="percSortFn">Percentage difference</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let i of searchTable.data" onMouseOver="this.style.opacity='0.75'" onMouseOut="this.style.opacity='1'">
                                <td style='background-color: {{i[5]}}; border-top: 1px solid grey;'>{{i[0]}}</td>
                                <td style='background-color: {{i[5]}}; border-top: 1px solid grey;'>{{i[1]}}</td>
                                <td style='background-color: {{i[5]}}; border-top: 1px solid grey;'>{{i[2]}}</td>
                                <td style='background-color: {{i[5]}}; border-top: 1px solid grey;'>{{i[3]}}</td>
                                <td *ngIf="i[4]!=99999" style='background-color: {{i[5]}}; border-top: 1px solid grey;'>{{i[4]}}%</td>
                                <td *ngIf="i[4]==99999" style='background-color: {{i[5]}}; border-top: 1px solid grey;'>infinite</td>
                            </tr>
                            <tbody>
                    </nz-table>
                </div>
            </div>
        </div>
    </nz-spin>
</div>