import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { InsightsService } from '../insights.service';
import { NzTableFilterFn, NzTableFilterList, NzTableSortFn, NzTableSortOrder } from 'ng-zorro-antd/table';
import { ViewChildren, QueryList } from '@angular/core';

interface DataItem {
  Client: string;
  Width: number;
  Profile: number;
  Rim: number;
  Searches: number; 
  Sales: number;
  Units: number;
}

interface ColumnItem {
  name: string;
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn<DataItem> | null;
  listOfFilter: NzTableFilterList;
  filterFn: NzTableFilterFn<DataItem> | null;
  filterMultiple: boolean;
  sortDirections: NzTableSortOrder[];
  filterCallback: any;
}

@Component({
  selector: 'app-searches-dashboard-insights',
  templateUrl: './searches-dashboard-insights.component.html',
  styleUrls: ['./searches-dashboard-insights.component.less']
})
export class SearchesDashboardInsightsComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  @ViewChildren(BaseChartDirective) components:QueryList<BaseChartDirective>;

  // Global variables
  tableData: DataItem[];
  filteredData: DataItem[];
  filters = {};
  bar = {};
  // Fxn variables
  Filters = {
    Client: [],
    Width: [],
    Profile: [],
    Rim: [],
    TimeFrame: -7
  };
  tfs = 'past week'

  ngOnInit(): void {
    this.InsightsService.callInsightsSearchesDashboard(this.Filters).subscribe(
      result => {
        this.tableData = result.tableData;
        // this.filteredData = result.tableData.slice();
        
        this.filters = result.filters;
        this.listOfColumns[0].listOfFilter = result.filters['Client'];
        this.listOfColumns[1].listOfFilter = result.filters['Width'];
        this.listOfColumns[2].listOfFilter = result.filters['Profile'];
        this.listOfColumns[3].listOfFilter = result.filters['Rim'];
        this.listOfColumns[4].listOfFilter = result.filters['Searches'];
        this.listOfColumns[5].listOfFilter = result.filters['Sales'];
        this.listOfColumns[6].listOfFilter = result.filters['Units'];
        
        this.bar = result.bar;
        this.barChartData.labels = result.bar['size'];
        this.barChartData.datasets = [
          { data: result.bar['sizeSearches'], label: '', backgroundColor:  "rgba(21, 142, 144, 0.5)", hoverBackgroundColor: "rgba(21, 142, 144, 1)"} 
            ]
        this.barChart2Data.labels = result.bar['centre'];
        this.barChart2Data.datasets = [
          { data: result.bar['centreSearches'], label: '', backgroundColor:  "rgba(231, 176, 25, 0.5)", hoverBackgroundColor: "rgba(231, 176, 25, 1)"} 
              ]
        
        this.isLoading = false;
       })  
  }
  
  listOfColumns: ColumnItem[] = [
    {
      name: 'Client',
      sortOrder: null,
      sortFn: (a: DataItem, b: DataItem) => a.Client.localeCompare(b.Client),
      sortDirections: ['ascend', 'descend', null],
      filterMultiple: true,
      listOfFilter: [],
      filterFn: (list: string[], item: DataItem) => list.some(name => name == item.Client),
      filterCallback: this.onFilterChange.bind(this)
    },
    {
      name: 'Width',
      sortOrder: null,
      sortFn: (a: DataItem, b: DataItem) => a.Width - b.Width,
      sortDirections: ['ascend', 'descend', null],
      listOfFilter: [],
      filterFn: (list: number[], item: DataItem) => list.some(name => name == item.Width),
      filterMultiple: true,
      filterCallback: this.onFilterChange.bind(this)
    },
    {
      name: 'Profile',
      sortOrder: null,
      sortFn: (a: DataItem, b: DataItem) => a.Profile - b.Profile,
      sortDirections: ['ascend', 'descend', null],
      listOfFilter: [],
      filterFn: (list: number[], item: DataItem) => list.some(name => name == item.Profile),
      filterMultiple: true,
      filterCallback: this.onFilterChange.bind(this)
    },
    {
      name: 'Rim',
      sortOrder: null,
      sortFn: (a: DataItem, b: DataItem) => a.Rim - b.Rim,
      sortDirections: ['ascend', 'descend', null],
      listOfFilter: [],
      filterFn: (list: number[], item: DataItem) => list.some(name => name == item.Rim),
      filterMultiple: true,
      filterCallback: this.onFilterChange.bind(this)
    },
    {
      name: 'Searches',
      sortOrder: 'descend',
      sortFn: (a: DataItem, b: DataItem) => a.Searches - b.Searches,
      sortDirections: ['ascend', 'descend', null],
      listOfFilter: [],
      filterFn: (list: number[], item: DataItem) => list.some(name => name == item.Searches),
      filterMultiple: true,
      filterCallback: this.onFilterChange.bind(this)
    },
    {
      name: 'Sales',
      sortOrder: null,
      sortFn: (a: DataItem, b: DataItem) => a.Sales - b.Sales,
      sortDirections: ['ascend', 'descend', null],
      listOfFilter: [],
      filterFn: (list: number[], item: DataItem) => list.some(name => name == item.Sales),
      filterMultiple: true,
      filterCallback: this.onFilterChange.bind(this)
    },
    {
      name: 'Units',
      sortOrder: null,
      sortFn: (a: DataItem, b: DataItem) => a.Units - b.Units,
      sortDirections: ['ascend', 'descend', null],
      listOfFilter: [],
      filterFn: (list: number[], item: DataItem) => list.some(name => name == item.Units),
      filterMultiple: true,
      filterCallback: this.onFilterChange.bind(this)
    }
  ];

  private InsightsService: InsightsService;
  isLoading = true;
  filterLoading = false;

  // dateSortFn = (a: string[], b: string[]): number => a[0] < b[0] ? -1 : 1;
  // searchSortFn = (a: number[], b: number[]): number => a[1] < b[1] ? -1 : 1;
  // saleSortFn = (a: number[], b: number[]): number => a[2] < b[2] ? -1 : 1;
  // unitSortFn = (a: number[], b: number[]): number => a[3] < b[3] ? -1 : 1;

  constructor(_InsightsService: InsightsService) {
    this.InsightsService = _InsightsService;
   }

  // Size chart 
  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    aspectRatio: 6,
    scales: {
      x: {grid: {display: false},
          ticks:{maxRotation: 100,
                 font: {size:9}}},
      y: {min: 0}},
    plugins: {
          tooltip: {
              callbacks: {
                  label: function(context) {
                      let label = '';
                      if (context.parsed.y !== null) {
                          label = new Intl.NumberFormat().format(context.parsed.y);
                      }
                      return label;}}},        
      legend: {
        display: true,
        title: {
         display: true,
         text: 'Searches by size',
         font: {size:25}
      }
    },
  }
 };
  public barChartType: ChartType = 'bar';
  public barChartPlugins = [
  ];

  public barChartData: ChartData<'bar'> = {
    labels: [],
    datasets: []}
  
  // Centre chart   
  public barChart2Options: ChartConfiguration['options'] = {
      responsive: true,
      aspectRatio: 5,
      scales: {
        x: {grid: {display: false},
            ticks:{maxRotation: 100,
                   font: {size:9}}},
        y: {min: 0}},
      plugins: {
            tooltip: {
                callbacks: {
                    label: function(context) {
                        let label = '';
                        if (context.parsed.y !== null) {
                            label = new Intl.NumberFormat().format(context.parsed.y);
                        }
                        return label;}}},        
        legend: {
          display: true,
          title: {
           display: true,
           text: 'Searches by centre',
           font: {size:25}
        }
      },
    }
   };
    public barChart2Type: ChartType = 'bar';
    public barChart2Plugins = [
    ];
  
    public barChart2Data: ChartData<'bar'> = {
      labels: [],
      datasets: []}
  

  // events
  public chartClicked({ event, active }: { event?: ChartEvent, active?: {}[] }): void {

  }


  onFilterChange(val: [], column: string) {
    this.filterLoading = true;
    if (val.length>0) {
      this.Filters[column] = val
    }
    else {
      this.Filters[column] = []
    }
    this.InsightsService.callInsightsSearchesDashboard(this.Filters).subscribe(
      result => {
        this.filterLoading = false;
        this.bar = result.bar;
        this.barChartData.labels = result.bar['size'];
        this.barChartData.datasets[0].data =  result.bar['sizeSearches']
        this.barChart2Data.labels = result.bar['centre'];
        this.barChart2Data.datasets[0].data =  result.bar['centreSearches']
        this.components.toArray()[0].update();
        this.components.toArray()[1].update();
      })        
  }


  changeTimeFrame(timeFrameDropDown): void {
    this.filterLoading = true;
    if (timeFrameDropDown==-1) {
      this.tfs = 'past 24 hours'
      this.Filters.TimeFrame = timeFrameDropDown
    } 
    else if (timeFrameDropDown==-7) {
      this.tfs = 'past week'
      this.Filters.TimeFrame = timeFrameDropDown
    } 
    else if (timeFrameDropDown==-30) {
      this.tfs = 'past month'
      this.Filters.TimeFrame = timeFrameDropDown
    }

    this.InsightsService.callInsightsSearchesDashboard(this.Filters).subscribe(
      result => {
        this.filterLoading = false;
        this.tableData = result.tableData;
        this.filters = result.filters;
        this.listOfColumns[0].listOfFilter = result.filters['Client'];
        this.listOfColumns[1].listOfFilter = result.filters['Width'];
        this.listOfColumns[2].listOfFilter = result.filters['Profile'];
        this.listOfColumns[3].listOfFilter = result.filters['Rim'];
        this.listOfColumns[4].listOfFilter = result.filters['Searches'];
        this.listOfColumns[5].listOfFilter = result.filters['Sales'];
        this.listOfColumns[6].listOfFilter = result.filters['Units'];

        this.bar = result.bar;
        this.barChartData.labels = result.bar['size'];
        this.barChartData.datasets[0].data =  result.bar['sizeSearches']
        this.barChart2Data.labels = result.bar['centre'];
        this.barChart2Data.datasets[0].data =  result.bar['centreSearches']
        this.components.toArray()[0].update();
        this.components.toArray()[1].update();
      })        
  }


  resetFilters(): void {  
    this.filterLoading = true;
    this.Filters = {
      Client: [],
      Width: [],
      Profile: [],
      Rim: [],
      TimeFrame: this.Filters.TimeFrame
    };
      
      this.InsightsService.callInsightsSearchesDashboard(this.Filters).subscribe(
      result => {
        this.filterLoading = false;
        this.tableData = result.tableData;
        
        this.filters = result.filters;
        this.listOfColumns[0].listOfFilter = result.filters['Client'];
        this.listOfColumns[1].listOfFilter = result.filters['Width'];
        this.listOfColumns[2].listOfFilter = result.filters['Profile'];
        this.listOfColumns[3].listOfFilter = result.filters['Rim'];
        this.listOfColumns[4].listOfFilter = result.filters['Searches'];
        this.listOfColumns[5].listOfFilter = result.filters['Sales'];
        this.listOfColumns[6].listOfFilter = result.filters['Units'];
        
        this.bar = result.bar;
        this.barChartData.labels = result.bar['size'];
        this.barChartData.datasets[0].data =  result.bar['sizeSearches']
        this.barChart2Data.labels = result.bar['centre'];
        this.barChart2Data.datasets[0].data =  result.bar['centreSearches']
        this.components.toArray()[0].update();
        this.components.toArray()[1].update();  
      })  
  }
 
}
  
