import { Component, HostListener, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { MappedStock, Stock ,TabObject, filteredStockItem} from '../models/stock';
import { NotificationService } from '../shared/notification.service';
import { StockService } from './stock.service';
import { Input } from '../models/tyres';
import { StockItem } from '../models/pricelist';

@Component({
  selector: 'app-stock-mapping',
  templateUrl: './stock-mapping.component.html',
  styleUrls: ['./stock-mapping.component.less']
})
export class StockMappingComponent implements OnInit {



  /* Containing objects */

  stock:Stock[] = [];
  unmappedStock: Stock[] = [];
  mappedStock: MappedStock[] = [];
  
  currentWorkItem: Stock = new Stock();
  activeStock: Stock[] = [];
  countDisplay:string = "";
  totalMappedToday:number = 0;


  /* Visual loading indicators */

  isMapping = false;
  isLoading = false;
  isLoadingMapped = false;
  isLoadingUnmapped = true;
  activestockAvailable = false;

  /* Row selection code */

  HighlightRow : Number = 0;
  currRow:number;
  clickedRow:any;
  selectedMap:any
  selectedIndex:number

  searchedTerm: string = "";
  selectedStockItem:Stock;
  isStockDropDownOpen: boolean = false;
  filteredStock:filteredStockItem[] = [];
  isNmsfAddOpen:boolean = false;

  input = new Input() ;
  pageSize: number = 20;


  @HostListener("window:scroll", [])
  onScroll(): void {
  if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight - (document.body.scrollHeight * 0.1)) {
          // you're at the bottom of the page
          this.pageSize += 15;
          // this.input.inputValue = this.input.inputValue;
      }
  }
  
  resetTable(){
    this.pageSize = 20;
  }



  /* Data Objects*/
  defaultActiveObjects:Stock[] = [
    {
      description: "Skip", msfid: 0, stock_code: "skip", locationId: 0, brand: "", locationName: "", gen_code: "",
      mapped: 0,
      showUnmap: false,
      searchedGenCode: ''
    },
    {
      description: "Skip", msfid: -1, stock_code: "skip", locationId: 0, brand: "", locationName: "", gen_code: "",
      mapped: 0,
      showUnmap: false,
      searchedGenCode: ''
    },
    {
      description: "Not a Tyre", msfid: -3, stock_code: "nat", locationId: 0, brand: "", locationName: "", gen_code: "",
      mapped: 0,
      showUnmap: false,
      searchedGenCode: ''
    },
    {
      description: "Missing in NMSF", msfid: -2, stock_code: "minmsf", locationId: 0, brand: "", locationName: "", gen_code: "",
      mapped: 0,
      showUnmap: false,
      searchedGenCode: ''
    },
    {
      description: "Not a Brand we Record", msfid: -4, stock_code: "nabwr", locationId: 0, brand: "", locationName: "", gen_code: "",
      mapped: 0,
      showUnmap: false,
      searchedGenCode: ''
    },
    {
      description: "Not Mappable", msfid: -5, stock_code: "nomap", locationId: 0, brand: "", locationName: "", gen_code: "",
      mapped: 0,
      showUnmap: false,
      searchedGenCode: ''
    },
  ];

  tabs:TabObject[] = [ { id:3 , name: "NAT" , description: "Not a Tyre"},{ id:2 , name: "MINMSF",description: "Missing in NMSF"}]
    
  
  /* Sorting  */
  FCStockCodeSortFn = (a: MappedStock, b: MappedStock): number => a.locationStockCode!.toLowerCase() < b.locationStockCode!.toLowerCase() ? -1 : 1;
  SFStockCodeSortFn = (a: MappedStock, b: MappedStock): number => a.msfStockCode!.toLowerCase() < b.msfStockCode!.toLowerCase() ? -1 : 1;

  constructor(private stockMappingService: StockService,private notification: NotificationService,public authService: AuthService) {
      this.clickedRow = function(index) { this.HighlightRow = index; }
  }

  ngOnInit(): void {
    this.input.inputValue = '';
    this.input.inputList = ["locationStockCode", "msfid",  "msfDescription", "msfStockCode", "stock_code"]
    this.getActiveStock();
    this.getUnmappedStock();    
  }

  getUnmappedStock()
  {
    this.isLoadingUnmapped= true;
    this.stockMappingService.getUnmapedStock().subscribe(res => {
     
      // Fetch Unmapped Stock
      this.stock = res;
      for(var i = 0; i < this.stock.length ; i++)
      {     
        // Unmapped 
        if(this.stock[i].mapped == 6 ||  this.stock[i].mapped === 0)
        {
          this.unmappedStock.push(this.stock[i]);   
        } 
      } 
    
      this.tabSelect(0);

      // Stop Loading
      this.isLoadingUnmapped = false;

      // Set Current Selected Line 
      if(this.unmappedStock.length > 0)
      {
        this.select_line(this.unmappedStock[0]);
      }

      this.unmappedStock = this.unmappedStock.slice(0);

      //Get mapped Stock
      this.getMappedStock();

    });

  }

  getActiveStock()
  {
    // Fetch Active Stock
    this.activestockAvailable = false;
    this.stockMappingService.getActiveStock().subscribe(res_active => {
    this.activeStock = res_active;
    this.defaultActiveObjects.forEach((obj) => {
    this.activeStock.push(obj);
    })
    this.activestockAvailable = true;
    }, error => {
      this.notification.handleError(error);
    });
  }

  getMappedStock()
  {
    this.isLoadingMapped = true;
    this.stockMappingService.getMapedStock().subscribe(res => {
      this.mappedStock = [];
      this.mappedStock = res.stock; 
      this.totalMappedToday = res.totalMappedToday;
      if(this.mappedStock.length > 0) {
        this.mappedStock = this.mappedStock.slice(0);
      }
      this.isLoadingMapped = false;
    });


  }

  select_line(item: Stock)
  {
     if(this.currentWorkItem != null)
    {
      this.currentWorkItem = item;
    }
  }

  tabSelect($event)
  {
    if($event == 0)
    {
     this.countDisplay = "Total Unmapped : " + this.unmappedStock.length; 
    }
    else if($event == 1)
    {
      this.countDisplay = "Total Mapped : " + this.mappedStock.length; 
    }
    this.resetTable();
  }

  log($event)
  {
    if($event == 0)
    {
     this.countDisplay = "Total UnMapped : " + this.unmappedStock.length; 
    }
    else if($event == 1)
    {
      this.countDisplay = "Total NAT : " + this.stock.filter(val => val.mapped === 3).length; 
    }
    else if ($event == 2)
    {
      this.countDisplay = "Total MINMSF : " + this.stock.filter(val => val.mapped === 2).length; 
    }
  }

  filterUnmappedStock(value: number): Stock[]{
    if (value === 6) {
      return this.stock.filter(val => val.mapped === value || val.mapped === 0).slice(0,50);
    }
    else
    { 
      return this.stock.filter(val => val.mapped === value).slice(0,50);
    }
  }

  itemToAdd:StockItem = new StockItem();
  searchActiveStock(value: string)
  {
    if (!value) {
      this.searchedTerm = "";
      return;
    }
    this.searchedTerm = value;
    if (value && value.length > 2) 
    {

      this.filteredStock = this.activeStock.filter(e => 
        (e.stock_code && e.stock_code.toLowerCase().includes(value.toLowerCase())) ||
        (e.description && e.description.toLowerCase().includes(value.toLowerCase())) ||
        (e.brand && e.brand.toLowerCase().includes(value.toLowerCase())) ||
        (e.gen_code && e.gen_code.toLowerCase().includes(value.toLowerCase())) ||
        (e.msfid && (e.msfid == Number(value) || (typeof e.msfid === 'string' && e.msfid === value))) ||
        (e.gen_code + ' ' + e.brand + ' ' + e.description + ' (' + e.stock_code + ')').toLowerCase().includes(value.toLowerCase())).map(e => 
          {
            return {  key: + e.msfid, name: e.gen_code+' '+e.brand+' '+e.description+' ('+e.stock_code+')' }
          });
    } 
    else {
      //clear the filter array
      this.filteredStock = [];
    }
    this.itemToAdd.locationId =this.currentWorkItem.locationId;
    this.itemToAdd.description =this.currentWorkItem.description;
    this.itemToAdd.stock_code =this.currentWorkItem.stock_code;
    this.itemToAdd.searchedGenCode = this.searchedTerm;
  }

 
  /* Mapping Logic */

  mapStock(mappFrom:string,mappTo: number,mapType:number) 
  {
    let mappedStock = this.unmappedStock.find(x => x.stock_code == mappFrom)!;
    if(mappedStock != null)
    {
      // Set Items Mapped Status
      mappedStock.mapped = mappTo;
      this.refreshUnmapped();
      let mappedStockModel = new MappedStock();
      mappedStockModel.locationStockCode = mappFrom;
      mappedStockModel.msfid = mappTo;
      mappedStockModel.locationStockDescription = mappedStock.description;
      mappedStockModel.mappingTypeId = mapType;
      mappedStockModel.locationId = mappedStock.locationId;
      mappedStockModel.isTradeIn = mappedStock.isTradeIn;
      this.isMapping = true;
      this.stockMappingService.postMapStock(mappedStockModel).subscribe(res => {

      // Notify
      this.notification.showSuccess("Changes Saved");

      // load mappeda
      this.getMappedStock();

      // Unload
      this.isMapping = false;

      // Clear Selected Map
      this.selectedMap = null;
      this.isStockDropDownOpen = false;
 
      },
      error => {
        this.notification.handleError(error);
      });
    }
    else
    {
      this.notification.showWarning("Item not Found in Unmapped Stock , Please Refresh the page");
    }  
  }

  deleteMapping(mappingId: number) {
    this.isLoadingMapped = true;
    this.stockMappingService.postDeleteMapping({mappingId: mappingId}).subscribe(res => {
          this.mappedStock.splice(this.mappedStock.findIndex(x => x.mappingId == mappingId), 1);
          this.mappedStock = [... this.mappedStock];
          this.isLoadingMapped = false;
          this.notification.showSuccess("Mapping Removed");
      },
      error => {
        this.notification.handleError(error);
      }
    )

  }

  reUnmapStock(item: Stock)
  {
    item.mapped = 0;
    this.refreshUnmapped();
    this.isLoadingUnmapped= true;
    this.stockMappingService.reUnmapStock(item).subscribe(
      res => {
         this.getMappedStock()
         this.isLoadingUnmapped= false;
       },
      error => {
        this.notification.handleError(error);
    });
  }

  refreshUnmapped()
  {
    this.unmappedStock = [];
    this.stock.forEach(item => {
      if(item.mapped == 6 ||  item.mapped === 0)
      {
        this.unmappedStock.push(item);   
      }
    });

    if(this.unmappedStock.length > 0)
    {
      this.select_line(this.unmappedStock[0]);
    }
    else
    {
      this.currentWorkItem = new Stock();
    }
    this.selectedMap = undefined;
  }

  removeItem(event: string){
    this.unmappedStock = this.unmappedStock.filter(val => val.stock_code !== event);
    if(this.unmappedStock.length > 0){
      this.select_line(this.unmappedStock[0]);
    }
    this.getActiveStock();
  }
}
