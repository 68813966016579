import { Component, OnInit } from '@angular/core';
import { WAMessageGroup } from 'src/app/models/whatsapp';
import { WhatsappService } from 'src/app/whatsapp/whatsapp.service';

@Component({
  selector: 'app-admin-wa-inbox',
  templateUrl: './admin-wa-inbox.component.html',
  styleUrls: ['./admin-wa-inbox.component.less']
})
export class AdminWaInboxComponent implements OnInit {

  constructor(public waService: WhatsappService) { }

  switchValue = false;

  unreadSortFn = (a: WAMessageGroup, b: WAMessageGroup): number => a.unread - b.unread;
  dateSortFn = (a: WAMessageGroup, b: WAMessageGroup): number => a.date > b.date ? 1 : -1

  fullMessagelist: WAMessageGroup[];

  ngOnInit(): void { 
    this.waService.GetAllMessagesAdmin(); 
  }

  changeSwitch(value: boolean) {
    if (value) {
      this.fullMessagelist = this.waService.messagelist;
      this.waService.messagelist = this.waService.messagelist.filter(e => e.unread > 0);
    }
    else {
      this.waService.messagelist = this.fullMessagelist;
    }
  }

  calculateDateDiff(sentdate): string {
    let date = new Date(sentdate + 'Z');
    let currentDate = new Date();
    let mins = Math.floor((currentDate.getTime() - date.getTime()) / 1000 / 60);
    return mins.toString() + ' mins ago'
  }

  loadMessages(cell: string, clientId: number) {
    if (clientId == null) {
      clientId = 0;
    }
    this.waService.GetConversationAdmin(cell, clientId);
  }

}
