<div class="container">
    <div class="row ">
        <div class="col-lg-2">
            <button nz-button nzType="primary" (click)="showAddUser()">
                <i nz-icon nzType="user-add"></i>
                Add User
            </button>
        </div>
        <div class="col-lg-8 " style="text-align: center;">
            <h2><b>System Users</b></h2>
        </div>
        <div class="col-lg-2">
            <app-video-widget [videoId]="17" style="float: inline-end;"></app-video-widget>

        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12" style="padding-left: 5%;padding-right: 4%;">
            <nz-table #usersTable [nzData]="users" [nzFrontPagination]="false" nzSize="small" [nzLoading]="isLoadingUsers">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Ext Pos ID</th>
                        <th>Roles</th>
                        <th style="width: 100px;"></th>
                    </tr>
                </thead>
                <tbody class="tbody">
                    <tr *ngFor="let user of usersTable.data">
                        <td>{{ user.fullname }}</td>
                        <td>{{ user.email }}</td>
                        <td>{{ user.extPosId }}</td>
                        <td>
                            <!-- <button nz-button nzType="default" *ngIf="!claim.claimId">{{ claim.name }}</button>
                        <button nz-button nzType="default" *ngIf="claim.claimId">{{ claim.name }}</button> -->
                            <button nz-button nzType="primary" [nzDanger]="!role.isSelected" *ngFor="let role of user.roles" style="margin-right: 5px;" (click)="onRoleBtnClick(role, user)" [nzLoading]="loadingButtons.includes(user.userId.toString() + '.' + role.roleId.toString())">
                                <i nz-icon nzType="check-circle" nzTheme="outline"
                                    style="font-size: 18px;"
                                    *ngIf="role.isSelected"></i>
                                <i nz-icon nzType="minus-circle" nzTheme="outline"
                                    style="font-size: 18px;"
                                    *ngIf="!role.isSelected"></i>
                                {{ role.roleName }}
                            </button>
                        </td>
                        <td>
                            <a style="margin-right: 10px;" (click)="onEditUserClick(user)">
                                <i nz-icon nzType="edit" nzTheme="outline" style="font-size: 1.5rem; color: #136413;" nz-tooltip nzTooltipTitle="Edit User"></i>
                            </a>

                            <a (click)="sendUserDetails(user.userId)" style="margin-right: 10px;">
                                <i nz-icon [nzType]="sendingButtons.includes(user.userId) ? 'loading' : 'mail'" nzTheme="outline" style="font-size: 1.5rem; color: #136413;" nz-tooltip nzTooltipTitle="Email User Details"></i>
                            </a>

                            <!-- <button nz-button nzType="default"  [nzLoading]="true" style="background-color: #00000000;">
                                <i nz-icon nzType="mail" nzTheme="outline"
                                    style="font-size: 1.5rem; color: #136413;" nz-tooltip
                                    nzTooltipTitle="Email User Details"></i>
                            </button> -->
                            <a (click)="showDeleteUser(user)">
                                <i nz-icon nzType="close-circle" nzTheme="outline" style="font-size: 1.5rem; color: #eb2f2f;" nz-tooltip nzTooltipTitle="Remove User"></i>
                            </a>
                        </td>

                    </tr>
                </tbody>
            </nz-table>
        </div>
    </div>
</div>

<nz-modal [(nzVisible)]="isAddUserModalVisible" nzTitle="Add New User" (nzOnCancel)="isAddUserModalVisible = false">
    <div *nzModalContent>
        <nz-spin [nzSpinning]="isAddingUser">
            <form nz-form [formGroup]="addUserForm">
                <nz-form-item>
                    <nz-form-control [nzErrorTip]="'Email Required'">
                        <nz-input-group nzPrefixIcon="mail">
                            <input nz-input name="usrEmail" formControlName="usrEmail" placeholder="Email">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-control>
                        <nz-input-group nzPrefixIcon="phone">
                            <input nz-input name="usrCell" formControlName="usrCell" placeholder="Cell Number">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-control [nzErrorTip]="'Name Required'">
                        <nz-input-group nzPrefixIcon="idcard">
                            <input nz-input name="usrFullname" formControlName="usrFullname" placeholder="Full Name">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-control [nzErrorTip]="' Password Required'">
                        <nz-input-group nzPrefixIcon="lock">
                            <input nz-input name="usrPassword" formControlName="usrPassword" placeholder="Password">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-spin>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="default" (click)="isAddUserModalVisible = false">
            <i nz-icon nzType="close" nzTheme="outline" style="vertical-align: middle; margin-top: -5px;"></i>Cancel
        </button>
        <button nz-button nzType="default" (click)="addUser()" [nzLoading]="isAddingUser">
            <i nz-icon nzType="check" nzTheme="outline" style="vertical-align: middle; margin-top: -5px;"></i>Add
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="isDeleteUserModalVisible" nzTitle="Delete User" (nzOnCancel)="isDeleteUserModalVisible = false">
    <div *nzModalContent>
        Are you sure you want to delete:<br /> Fullname: {{deletingUser.fullname}} <br /> Username: {{deletingUser.userName}} <br />

    </div>
    <div *nzModalFooter>
        <button nz-button nzType="default" (click)="isDeleteUserModalVisible = false">
            <i nz-icon nzType="close" nzTheme="outline" style="vertical-align: middle; margin-top: -5px;"></i>Cancel
        </button>
        <button nz-button nzType="default" (click)="deleteUser()">
            <i nz-icon nzType="check" nzTheme="outline" style="vertical-align: middle; margin-top: -5px;"></i>Delete
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="isEditUserModalVisible" nzTitle="Edit User" (nzOnCancel)="isEditUserModalVisible = false">
    <div *nzModalContent>
        <nz-spin [nzSpinning]="isEditingUser">


            <form nz-form [formGroup]="editUserForm">

                <nz-form-item>
                    <nz-form-control [nzErrorTip]="'Name Required'">
                        <nz-input-group nzPrefixIcon="idcard">
                            <input nz-input name="editFullname" formControlName="editFullname" placeholder="Full Name">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-control>
                        <nz-input-group nzPrefixIcon="laptop">
                            <input nz-input name="editExtPosId" formControlName="editExtPosId" placeholder="External POS ID">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-spin>

    </div>
    <div *nzModalFooter>
        <button nz-button nzType="default" (click)="isEditUserModalVisible = false">
            <i nz-icon nzType="close" nzTheme="outline" style="vertical-align: middle; margin-top: -5px;"></i>Cancel
        </button>
        <button nz-button nzType="default" (click)="editUser()">
            <i nz-icon nzType="check" nzTheme="outline" style="vertical-align: middle; margin-top: -5px;"></i>Update
        </button>
    </div>
</nz-modal>