import { NzTableFilterList, NzTableSortOrder, NzTableSortFn, NzTableFilterFn } from "ng-zorro-antd/table";

export interface ClientDataItem {
    name: string;
    unmappedStock: number;
}

export interface ClientColumnItem{
    name: string;
    sortOrder: NzTableSortOrder | null;
    sortFn: NzTableSortFn<UnMappedStock> | null;
    listOfFilter: NzTableFilterList | null;
    filterFn: NzTableFilterFn<UnMappedStock> | null;
    filterMultiple: boolean;
    sortDirections: NzTableSortOrder[];
}

export class ClientInfoForAdminStockMappings{
    
    clientId: number;
    name: string;
    unmappedStock: number;
}

export class UnMappedStock{
    stockCode: string;
    description: string;
    clientId: number;
    clientName: string;
    msfId?: number;
    locationId?:number;
    locationName?:  string;;
    brand?: string;
    genCode?: string;
    mapped?:number = 0;
    showUnmap?:boolean = false;
    searchedGenCode?:string;
    soh:number | null;
}

export class MappedStock {
  mappingId?: number;
  msfId: number;
  description: string;
  officialStock?: boolean;
  locationStockCode: string;
  locationStockDescription?: string;
  msfStockCode?: string;
  msfDescription: string;
  mappingTypeId?: number;
  locationId?: number;
  dt?: string;
  searchedGenCode?: string;
  date?: Date;
  locationName?: string;
}


  export class CustomStockToBeMapped{
    msfid: number;
    stockCode: string;
    description: string;
    clientId: number;
    stockTypeId: number;
  }

  export class TyreStockToBeMapped{
    msfid: number;
    stockCode: string;
    description: string;
    clientId: number;
  }

  export class Stock {
    description: string
    stockCode: string
    msfid: number
    locationId: number
    locationName: string
    brand: string
    genCode: string
    mapped?:number = 0
    showUnmap?:boolean = false;
    searchedGenCode?:string;
  }

  export class CustomStock {
    customStockId : number;
    msfid : number;
    stockCode : string;
    description : string;
    stockType : string;
    clientId
  }

  export class NewCustomStockMapping{
    nmsfId : number; 
    stockCode : string;
    clientId : number; 
    mappingTypeId : number;
    description : string ;
    userId : number ;
    officialStock : string;
    stockTypeId : number;
  }

  export class StockType{
    id: number;
    name: string;
    msfId: number;
  }