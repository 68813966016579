<div class="container">
    <div class="row ">
        <div class="col-lg-4">
            <button nz-button nzType="primary" (click)="onAddClientClick()">
            <i nz-icon nzType="plus" nzTheme="outline"></i><span>Create New Client</span>
      </button>
        </div>
        <div class="col-lg-4 text-center ">
            <h2><b>Add Clients</b></h2>
        </div>
        <div class="col-lg-4">
            <app-video-widget [videoId]="16" style="float: inline-end;"></app-video-widget>

        </div>
    </div>
</div>

<br>
<div class="container-fluid col-lg-11 ">
    <div class="container">
        <br>
        <div class="row justify-content-md-center">
            <div class="col-6">

                <nz-input-group nzSuffixIcon="search">
                    <input type="text" nz-input placeholder="Filter Clients" [(ngModel)]="clientFilter" (keyup)="filterClients()" class="text-center" />
                </nz-input-group>

            </div>
        </div>
    </div>
    <br>
    <!-- table class/ didnt want to remove in case we can still use -->
    <!-- class="table table-striped table-borderless table-hover table-responsive-sm" -->
    <nz-table #filter [nzData]="filteredClients" id="example" [nzFrontPagination]="true" [nzPageSize]="50" [nzLoading]="isLoadingClients">
        <thead>
            <tr>
                <th>Client</th>
                <th>Email</th>
                <th>Cell</th>
                <th>Main Supplier</th>
                <th>Add</th>
            </tr>
        </thead>
        <tbody class="tbody">
            <tr *ngFor="let item of filter.data">
                <td>{{ item.name }}</td>
                <td> {{ item.email }}</td>
                <td>{{ item.cell }}</td>
                <td>{{ item.supplier }}</td>
                <!-- class="btn text-left add" -->
                <td><button nz-button nzType="default" (click)="addexisting(item)"><i nz-icon nzType="plus" nzTheme="outline"></i></button></td>
            </tr>
        </tbody>
    </nz-table>
</div>

<!--Add New Modal-->

<nz-modal [(nzVisible)]="isAddingModalVisible" nzTitle="Adding New Client" (nzOnCancel)="isAddingModalVisible = false" [nzFooter]="null" nzWidth="900px">
    <div *nzModalContent>
        <!-- <div class="col-lg-8 offset-2">
            <label class="float-left">Trading As</label>
            <div class="input-group maindiv">
                <span class="input-group-prepend"><div class="input-group-text bg-transparent">
                    <i nz-icon nzType="file" nzTheme="outline"></i>></div>
                </span>
                <input [(ngModel)]="this.newclient.name" class="form-control text-center form-control-custom"  placeholder="Enter Name"   >
            </div>
            <p></p>
            <label class="float-left">Access Key <small class="text-style">*Password</small></label>
            <div class="input-group maindiv">
                <span class="input-group-prepend"><div class="input-group-text bg-transparent">
                    <i nz-icon nzType="file" nzTheme="outline"></i></div>
                </span>
                <input [(ngModel)]="this.newclient.password" class="form-control text-center form-control-custom"  placeholder="Pa$$w0rd"   >
            </div>
            <p></p>
            <label class="float-left">Email</label>
            <div class="input-group maindiv">
                <span class="input-group-prepend"><div class="input-group-text bg-transparent">
                    <i nz-icon nzType="file" nzTheme="outline"></i></div>
                </span>
                <input [(ngModel)]="this.newclient.email" class="form-control text-center form-control-custom"  placeholder="email@test.com"   >
            </div>
            <p></p>
            <label class="float-left">Cell</label>
            <div class="input-group maindiv">
                <span class="input-group-prepend"><div class="input-group-text bg-transparent">
                    <i nz-icon nzType="file" nzTheme="outline"></i></div>
                </span>
                <input [(ngModel)]="this.newclient.cell" [maxlength]="10" class="form-control text-center form-control-custom"  placeholder="000 000 0000"   >
            </div>
            <p></p>
            <label class="float-left">Price list</label>
            <div class="input-group maindiv">
                <span class="input-group-prepend"><div class="input-group-text bg-transparent">
                    <i nz-icon nzType="book" nzTheme="outline"></i></div>
                </span>
                <select [(ngModel)]="this.newclient.plId" class="form-control text-center form-control-custom" >
                    <option value="0">No I will not sell to them</option>
                    <option value="15">{{ this.pricelist.deflt }}</option>
                    <option value="16">{{ this.pricelist.plretail }}</option>
                    <option value="17">{{ this.pricelist.wholesale }}</option>
                    <option value="14">{{ this.pricelist.plinternal }}</option>
                    <option value="13">{{ this.pricelist.plonline }}</option>
                    <option value="10">{{ this.pricelist.plcustom1 }}</option>
                    <option value="11">{{ this.pricelist.plcustom2 }}</option>
                    <option value="12">{{ this.pricelist.plcustom3}}</option>
                    <option value="19">{{ this.pricelist.plcustom4 }}</option>
                    <option value="18">{{ this.pricelist.plclear }}</option>
                </select>
            </div>
            <p></p>
            <label class="float-left">Client Business Type</label>
            <div class="input-group maindiv">
                <span class="input-group-prepend"><div class="input-group-text bg-transparent">
                    <i nz-icon nzType="book" nzTheme="outline"></i></div>
                </span>
                <select [(ngModel)]="this.newclient.type" class="form-control text-center form-control-custom" >
                    
                    <option value="FITMENT">Fitment Centre</option>
                    <option value="DEALER">Dealer</option>
                    <option value="OTHER">Other</option>
                </select>
            </div>
            <p></p>
            <label class="float-left">Franchise</label>
            <div class="input-group maindiv">
                <span class="input-group-prepend"><div class="input-group-text bg-transparent">
                    <i nz-icon nzType="book" nzTheme="outline"></i></div>
                </span>
                <select [(ngModel)]="this.newclient.franchise" class="form-control text-center form-control-custom" >
                    <option value="OTHER">Other</option>
                    <option value="TYREMART">Tyremart</option>
                    <option value="HI-Q">Hi-Q</option>
                    <option value="SUPA QUICK">Supa Quick</option>
                    <option value="TYRES & MORE">Tyres & More</option>
                    <option value="BEST DRIVE">Best Drive</option>
                    <option value="POINT S">Point S</option>
                    <option value="DUNLOP ZONE">Dunlop Zone</option>
                    <option value="TIGER WHEEL & TYRE">Tiger Wheel & Tyre</option>
                    <option value="SPEEDY">Speedy</option>
                    <option value="SUPER TYRES">Super Tyres</option>
                    <option value="REVOLUTION">Revolution</option>
                </select>
            </div>
            <br>
            <button type="submit" class="service1 btn btn-md" (click)="addnew()" >Add</button>
            <br>
            <br>
        </div> -->
        <nz-skeleton [nzLoading]="isLoadingAdd">

            <!-- Span for all nz-form-label -->
            <!-- style="color: var(--textcolor)" -->
            <form nz-form [formGroup]="clientAddForm" style="padding-bottom: 15px;">
                <div nz-row>
                    <div nz-col [nzSm]="12" [nzXs]="24">
                        <nz-form-item>
                            <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="name"><span>Name</span></nz-form-label>
                            <nz-form-control [nzSm]="16" [nzXs]="24" nzErrorTip="Enter Name">

                                <input nz-input formControlName="name" id="name" placeholder="Enter Name" />
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSm]="12" [nzXs]="24">
                        <nz-form-item>
                            <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="password"><span>Password</span></nz-form-label>
                            <nz-form-control [nzSm]="16" [nzXs]="24" nzErrorTip="Enter password">
                                <input nz-input formControlName="password" id="password" placeholder="Enter password" />
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <div nz-row>
                    <div nz-col [nzSm]="12" [nzXs]="24">
                        <nz-form-item>
                            <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="email"><span>Email</span></nz-form-label>
                            <nz-form-control [nzSm]="16" [nzXs]="24" nzErrorTip="Enter Email">
                                <input nz-input formControlName="email" id="email" placeholder="email@domain.com" />
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSm]="12" [nzXs]="24">
                        <nz-form-item>
                            <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="cell"><span>Tel No</span></nz-form-label>
                            <nz-form-control [nzSm]="16" [nzXs]="24" nzErrorTip="Enter Tel No">
                                <input nz-input formControlName="cell" id="cell" placeholder="000 000 0000" />
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <div nz-row>
                    <div nz-col [nzSm]="12" [nzXs]="24">
                        <nz-form-item>
                            <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="regionId"><span>Region</span></nz-form-label>
                            <nz-form-control [nzSm]="16" [nzXs]="24" nzErrorTip="Enter Region">
                                <nz-select formControlName="regionId" id="regionId">
                                    <nz-option *ngFor="let item of regions" nzValue="{{item.regionId}}" nzLabel="{{item.name}}"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSm]="12" [nzXs]="24">
                        <nz-form-item>
                            <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="franchiseId"><span>Franchise</span></nz-form-label>
                            <nz-form-control [nzSm]="16" [nzXs]="24" nzErrorTip="Enter Email">
                                <nz-select formControlName="franchiseId" id="franchiseId">
                                    <nz-option *ngFor="let item of franchises" nzValue="{{item.franchiseId}}" nzLabel="{{item.name}}"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                </div>
                <div nz-row>
                    <div nz-col [nzSm]="12" [nzXs]="24">
                        <nz-form-item>
                            <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="type"><span>Business Type</span></nz-form-label>
                            <nz-form-control [nzSm]="16" [nzXs]="24" nzErrorTip="Enter type">
                                <nz-select formControlName="type" id="type">
                                    <nz-option nzValue="FITMENT" nzLabel="Fitment Centre"></nz-option>
                                    <nz-option nzValue="DEALER" nzLabel="Dealer"></nz-option>
                                    <nz-option nzValue="OTHER" nzLabel="Other"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSm]="12" [nzXs]="24">
                        <nz-form-item>
                            <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="plId"><span>Price List</span></nz-form-label>
                            <nz-form-control [nzSm]="16" [nzXs]="24" nzErrorTip="Enter Email">
                                <nz-select formControlName="plId" id="plId">
                                    <nz-option nzValue="0" nzLabel="No I will not sell to them"></nz-option>
                                    <nz-option nzValue="15" nzLabel="{{ this.pricelist.deflt }}"></nz-option>
                                    <nz-option nzValue="16" nzLabel="{{ this.pricelist.plretail }}"></nz-option>
                                    <nz-option nzValue="17" nzLabel="{{ this.pricelist.wholesale }}"></nz-option>
                                    <nz-option nzValue="14" nzLabel="{{ this.pricelist.plinternal }}"></nz-option>
                                    <nz-option nzValue="13" nzLabel="{{ this.pricelist.plonline }}"></nz-option>
                                    <nz-option nzValue="10" nzLabel="{{ this.pricelist.plcustom1 }}"></nz-option>
                                    <nz-option nzValue="11" nzLabel="{{ this.pricelist.plcustom2 }}"></nz-option>
                                    <nz-option nzValue="12" nzLabel="{{ this.pricelist.plcustom3}}"></nz-option>
                                    <nz-option nzValue="19" nzLabel="{{ this.pricelist.plcustom4 }}"></nz-option>
                                    <nz-option nzValue="18" nzLabel="{{ this.pricelist.plclear }}"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <div style="text-align: center;">
                    <button nz-button nzType="primary" [nzLoading]="isSavingAdd" (click)="addnew()">Add</button>
                </div>

            </form>
        </nz-skeleton>
    </div>
</nz-modal>


<!--Add Existing Modal-->

<nz-modal [(nzVisible)]="isExistingVisible" nzTitle="Adding Existing Client" (nzOnCancel)="isExistingVisible = false" [nzFooter]="null" nzWidth="900px">
    <div *nzModalContent>
        <div>
            <div class="modal-body text-center" style="font-size:20px">
                <h3><span class="text-style">{{ this.existing.name }}</span></h3>
                <div class="col-lg-8 offset-2">
                    <label class="float-left">Price list</label>
                    <div class="input-group maindiv">
                        <nz-select [(ngModel)]="this.existing.plId" style="width:50%">
                            <nz-option nzLabel="No I will not sell to them" nzValue="0"></nz-option>
                            <nz-option nzLabel="{{ this.pricelist.deflt }}" nzValue="15"></nz-option>
                            <nz-option nzLabel="{{ this.pricelist.plretail }}" nzValue="16"></nz-option>
                            <nz-option nzLabel="{{ this.pricelist.wholesale }}" nzValue="17"></nz-option>
                            <nz-option nzLabel="{{ this.pricelist.plinternal }}" nzValue="14"></nz-option>
                            <nz-option nzLabel="{{ this.pricelist.plonline }}" nzValue="13"></nz-option>
                            <nz-option nzLabel="{{ this.pricelist.plcustom1 }}" nzValue="10"></nz-option>
                            <nz-option nzLabel="{{ this.pricelist.plcustom2 }}" nzValue="11"></nz-option>
                            <nz-option nzLabel="{{ this.pricelist.plcustom3}}" nzValue="12"></nz-option>
                            <nz-option nzLabel="{{ this.pricelist.plcustom4 }}" nzValue="19"></nz-option>
                            <nz-option nzLabel="{{ this.pricelist.plclear }}" nzValue="18"></nz-option>
                        </nz-select>
                    </div>
                    <br>
                    <button nz-button nzType="primary" type="submit" (click)="savexisting()">Add</button>
                    <br>
                    <br>
                </div>
            </div>
        </div>
    </div>
</nz-modal>