import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/shared/notification.service';
import { ActivatedRoute, Router} from '@angular/router';
import { SettingsService } from '../../settings.service';
import { Subscription } from 'rxjs';
import { CustomerUpdate } from 'src/app/models/settings';
import { CustomerService } from 'src/app/customers/customer.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NewPosCustomer, PosCustomer } from 'src/app/models/pos';
import { Globals } from 'src/app/shared/globals';
import { SearchService } from 'src/app/search/search.service';
import { CachedSearchTerm } from 'src/app/models/search';
import { AddCustomerResponse, AddSubCustomer, Communities, CustomerDetails, NewCustomer, SubCustomer } from 'src/app/models/customer';
import { resultType } from 'src/app/shared/modal-content/modal-content.component';

@Component({
  selector: 'app-customer-edit',
  templateUrl: './customer-edit.component.html',
  styleUrls: ['./customer-edit.component.less']
})
export class CustomerEditComponent implements OnInit {

  constructor(private customerService : CustomerService,
    private settingsService : SettingsService,
    private route:ActivatedRoute,
    private router: Router, 
    private notification: NotificationService,
    private searchService: SearchService) { }

  paramsSub: Subscription
  customerId: number = 0;
  isLoading = false;
  customer: CustomerUpdate = new CustomerUpdate();
  showVehicles = false;
  UpdateCustomerForm: FormGroup = new FormGroup({
    custName: new FormControl('', Validators.required),
    custCell: new FormControl('', Validators.compose([Validators.pattern('^[+0-9]+$'), Validators.minLength(10), Validators.maxLength(13)])),
    custEmail: new FormControl('', [Validators.email,/* Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) */]),
    custDebtorAcc:new FormControl(null),
    custVATNumber:new FormControl(null)
  });

  selectedCustomerString: string = '' ;
  filteredCustomers : CachedSearchTerm[] = [];
  customers : CachedSearchTerm[] = [];
  selectedCustomer: CachedSearchTerm | null = null;
  isCustomerSelected: boolean = false;
  subCustomers: SubCustomer[] = [];
  communities: Communities[] = [];
  
  isLoadingSubCustomers = false;
  isLoadingCommunities = false;

  isModalOpen = false;
  customerForm: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    cell: new FormControl('', (Validators.required,Validators.maxLength(10)) ),
    vreg: new FormControl(''),
    email: new FormControl('', [Validators.email]),
  });
  Pattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  
  ngOnInit(): void {
    this.paramsSub =  this.route.params.subscribe(params => {
      this.isLoading = true;  
      this.settingsService.editCustomer(+params['customerId']).subscribe((res:any )=>
      {
        this.customerId = parseInt(params['customerId']);

        this.customer = res;
        this.isLoadingSubCustomers = true;
        // Fetch all sub customers
        this.fetchSubCustomer(this.customerId);

        if(res.vehicles.length > 0)
        {
          this.showVehicles = true;
        }
        this.UpdateCustomerForm.controls["custName"].setValue(this.customer.name);
        this.UpdateCustomerForm.controls["custCell"].setValue(this.customer.cell === 'None' ? '' : this.customer.cell ); 
        this.UpdateCustomerForm.controls["custEmail"].setValue(this.customer.email === 'None' ? '' : this.customer.email); 
        this.UpdateCustomerForm.controls["custDebtorAcc"].setValue(this.customer.debtorAcc); 
        this.UpdateCustomerForm.controls["custVATNumber"].setValue(this.customer.VATNumber); 
        
        this.isLoading = false;
      },
      error => 
      {
        this.notification.handleError(error);
      }); 
    });
    
  }

  fetchSubCustomer(customerId: number){
    this.customerService.getSubCustomers(customerId).subscribe((res: SubCustomer[]) => {
      this.subCustomers = res;
      this.isLoadingSubCustomers = false;
    }, error => {
      this.notification.handleError(error);
      this.isLoadingSubCustomers = false;
    });
  }

  updateCustomer()
  {
    Object.values(this.UpdateCustomerForm.controls).forEach(control => {
      if (control.invalid) {
        control.markAsDirty();
        control.updateValueAndValidity({ onlySelf: true });
      }
    });
    

    if (this.UpdateCustomerForm.valid) 
    {

      // This is COMPLETELY unneccesary. Angular already does the validation. Check the page. It is done on the fly and when clicking the button. NO NEED for this,
      //    which in any case does not work anyway.
      // if(!Globals.funcs.checkEmail(this.UpdateCustomerForm.value.custEmail)== true)
      // {
      //   this.notification.showError("Email Pattern Not Permitted Please Re Enter");
      //   return;
      // }
 

      let cust: NewPosCustomer = new NewPosCustomer()
      cust.customerId = this.customer.customerId;
      cust.name = this.UpdateCustomerForm.value.custName;
      cust.cell = this.UpdateCustomerForm.value.custCell;
      cust.email = this.UpdateCustomerForm.value.custEmail;
      cust.debtorAcc = this.UpdateCustomerForm.value.custDebtorAcc;
      cust.VATNumber = this.UpdateCustomerForm.value.custVATNumber;
      this.isLoading = true;
      this.customerService.updateCustomer(cust).subscribe(res =>
        {
          this.notification.showSuccess("Updated Successfully");
          this.isLoading = false;
        },
        error => 
        {
          this.notification.handleError(error);
        });



    }

  }

  changeVreg(obj)
  {
    for (var vehicle of this.customer.vehicles) 
    {
      if (vehicle.Id == obj.parentElement.childNodes[1].innerText) 
      {
        vehicle.vreg = obj.value;
      }
    }
  }
  
  changeSize(obj)
  {
    for (var vehicle of this.customer.vehicles) 
    {
      if (vehicle.Id == obj.parentElement.childNodes[1].innerText) 
      {
        vehicle.size = obj.value;
      }
    }
  }
  
  onVehiclesUpdate()
  {    
      this.isLoading = true;
      for(var i = 0; i < this.customer.vehicles.length ; i++)
      {
        this.customerService.updateVehicle(this.customer.vehicles[i]).subscribe(res => 
        {

        });
      }
      this.isLoading = false;
      this.notification.showSuccess("Updated Successfully");  

  }

  openCustomer()
  {
    //Set local storage Customer Then Go 
    let customer = { customer:this.customerId, sendTo: 'Customer Home', vreg : 'All' };
    localStorage.removeItem("customerData");
    localStorage.setItem('customerData', JSON.stringify(customer));
    this.router.navigate(['/customer']);
  }

  searchCustomer(value: string): void {
    this.selectedCustomerString = value;
    if (value.length > 2) {
      this.customers = this.searchService.filteredCachedSearchTerms([6]).filter(e => e.value.toLocaleLowerCase().includes(value.toLocaleLowerCase()));
    }

    this.filteredCustomers = this.customers.slice(0,20);

  }


  onOptionSelected(selectedValue: CachedSearchTerm) {
    this.addSubCustomer(selectedValue);
  }


  addSubCustomer(customer: CachedSearchTerm) {
    this.isLoadingSubCustomers = true;
  
    // Check if customer is already a sub-customer
    if (this.subCustomers.some(subCustomer => subCustomer.customerId === Number(customer.key))) {
      this.isLoadingSubCustomers = false;
      this.notification.showError("Customer is already a sub-customer");
      return;
    }
  
    const addSubCustomerData = new AddSubCustomer();
    addSubCustomerData.parentCustomerId = this.customerId;
    addSubCustomerData.subCustomerId = Number(customer.key);
  
    this.customerService.addSubCustomer(addSubCustomerData).subscribe(
      (res: SubCustomer[]) => {
        this.isLoadingSubCustomers = false;
        this.subCustomers = res; // Re-fetch and update sub-customers list
        this.notification.showSuccess("Sub-Customer Added Successfully");
        this.filteredCustomers = [];
      },
      error => {
        this.notification.handleError(error);
        this.isLoadingSubCustomers = false;
      }
    );
  }
  
  
    removeSubCustomer(customer: SubCustomer) {
      this.isLoadingSubCustomers = true;
      this.customerService.removeSubCustomer(customer.customerId).subscribe(res => {
        this.isLoadingSubCustomers = false;
        this.notification.showSuccess("Sub-Customer Removed Successfully");
        this.subCustomers = this.subCustomers.filter(subCustomer => subCustomer.customerId !== customer.customerId);
      }, error => {
        this.isLoadingSubCustomers = false;
        this.notification.handleError(error); 
    }
    );
  }
  openNewCustomerModal(event: MouseEvent) {
    event.stopPropagation();
    this.isModalOpen = true;
  }

  addNewCustomer() {
    // Check if the form is invalid, mark controls as dirty to show validation errors if needed
    if (!this.customerForm.valid) {
      this.markFormControlsAsDirty();
      return;
    }
  
    // Validate vehicle registration if provided
    if (this.customerForm.value.vreg && !this.isValidVehicleRegistration(this.customerForm.value.vreg)) {
      this.showAlert("Vehicle Registration is Not Valid", "Please check that vehicle registration is correct", 2000);
      return;
    }
  
    // Validate email format if provided
    if (this.customerForm.value.email && !this.Pattern.test(this.customerForm.value.email)) {
      this.showAlert("Incorrect Email Pattern", "Please check that the email is in the correct format, name@domain.anything", 5000);
      return;
    }
  
    // Validate cell phone length
    if (this.customerForm.value.cell.length < 10) {
      this.showAlert("Cell Number is Not Valid", "Please check that the number contains 10 digits", 2000);
      return;
    }
  
    let  newCustomer = new NewCustomer();
    newCustomer.customerId = 0;
    newCustomer.name = this.customerForm.value.name;
    newCustomer.cell = this.customerForm.value.cell;
    newCustomer.email = this.customerForm.value.email;
    newCustomer.parentCustomerId = this.customerId;
    newCustomer.debtorAcc = '';
    newCustomer.VATNumber = '';


    this.isLoadingSubCustomers = true;
    
    this.customerService.createCustomerAndAddSubCustomer(newCustomer).subscribe((res: SubCustomer[]) => {
      this.isLoadingSubCustomers = false;
      this.subCustomers = res;
      this.handleSuccessResponse(res);

      // Reset form inputs
      this.customerForm.reset();
    }, error => {
      this.notification.handleError(error);
      this.isLoadingSubCustomers = false;
    }); 
    // Close the modal
    this.isModalOpen = false;
  }
  
  
  private markFormControlsAsDirty() {
    Object.values(this.customerForm.controls).forEach((control) => {
      if (control.invalid) {
        control.markAsDirty();
        control.updateValueAndValidity({ onlySelf: true });
      }
    });
  }
  
  private isValidVehicleRegistration(vreg: string): boolean {
    return vreg.length >= 2;
  }
  
  private showAlert(title: string, message: string, duration: number) {
    this.notification.ShowAlert({
      type: resultType.Error,
      title,
      htmlMessage: message,
      maskClosable: false,
      autoCloseInMilliseconds: duration,
    });
  }
  
  private handleSuccessResponse(res: any) {
    this.notification.removeAlert();
    this.notification.showSuccess("Added Successfully");

  }

  changeCustomerCommunity(customerId:number, customerGroupId: number, active: boolean) {
    this.isLoadingCommunities = true;
    let customerDetails = new CustomerDetails();
    
    customerDetails.customerId = customerId; 
    customerDetails.groupId = customerGroupId;
    customerDetails.isActive = active;
    this.customerService.changeCommunity(customerDetails).subscribe(res => {
      this.customer.customerGroups.filter(val => val.customerGroupId === customerGroupId).map(val => val.hasCustomerLink = !val.hasCustomerLink);
      this.notification.showSuccess("Changed Successfully");
      this.isLoadingCommunities = false;
    }, error => {
      this.isLoadingCommunities = false;
      this.notification.handleError(error); 
  });
  }  
}
