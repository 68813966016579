import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth.service';
import { ClientBankingDetails, ClientSettings } from 'src/app/models/settings';
import { NotificationService } from 'src/app/shared/notification.service';
import { SettingsService } from '../settings.service';
import { Router } from '@angular/router';
import { resultType } from 'src/app/shared/modal-content/modal-content.component';

@Component({
  selector: 'app-settings-main',
  templateUrl: './settings-main.component.html',
  styleUrls: ['./settings-main.component.less']
})
export class SettingsMainComponent implements OnInit {

  settingsForm : FormGroup = new FormGroup({
    vatNr: new FormControl(''),
    regNr: new FormControl(''),
    bankingDetails: new FormControl(''),
    liftCount: new FormControl(0),
    address: new FormControl(''),
    zipCode: new FormControl(''),
    city: new FormControl(''),
    bookingBlockedHours: new FormControl(''),
    displayCostOnJC: new FormControl('0'),
    featureLevel: new FormControl("1"),
    manageStock: new FormControl('0'),
    isInvoiceManagedSF: new FormControl('0'),
  });

  bankDetailsForm : FormGroup = new FormGroup({
    beneficiaryName: new FormControl('',  Validators.required),
    branchCode: new FormControl('',  Validators.required),
    bankName: new FormControl('',  Validators.required),
    accountType: new FormControl('',  Validators.required),
    accountNumber: new FormControl('',  Validators.required),
    showBankingDetails: new FormControl('0'),
  })


  isLoading = false;
  isSaving = false;
  showBankingDetails = false;
  bankingDetailsId:number = 0;

  constructor(public authService: AuthService, private settingsService: SettingsService, 
              private notification: NotificationService, private router:Router) { }

  ngOnInit(): void {
    this.router.navigateByUrl('/settings/main-v2');
    this.isLoading= true;
    this.settingsService.getMainSettings().subscribe(
      val => {
        this.settingsForm.controls["vatNr"].setValue(val.VATNumber);
        this.settingsForm.controls["regNr"].setValue(val.RegNumber);
        // this.settingsForm.controls["bankingDetails"].setValue(val.bankingDetails);
        this.settingsForm.controls["liftCount"].setValue(val.liftCountOnline);
        this.settingsForm.controls["address"].setValue(val.address);
        this.settingsForm.controls["zipCode"].setValue(val.zipCode);
        this.settingsForm.controls["city"].setValue(val.city);
        this.settingsForm.controls["bookingBlockedHours"].setValue(val.bookingsBlockedHours)
        this.settingsForm.controls["displayCostOnJC"].setValue(val.displayCostOnJC)
        this.settingsForm.controls["manageStock"].setValue(this.authService.user.isManagedSF)
        this.settingsForm.controls["isInvoiceManagedSF"].setValue(val.isInvoiceManagedSF)
        this.settingsForm.controls["featureLevel"].setValue(val.featureLevel.toString())
        this.bankingDetailsId = val.bankingDetails.bankingDetailsId
        if(this.bankingDetailsId > 0)
        {
          this.bankDetailsForm.controls["beneficiaryName"].setValue(val.bankingDetails.beneficiaryName);
          this.bankDetailsForm.controls["bankName"].setValue(val.bankingDetails.bankName);
          this.bankDetailsForm.controls["accountType"].setValue(val.bankingDetails.accountType);
          this.bankDetailsForm.controls["accountNumber"].setValue(val.bankingDetails.accountNumber);
          this.bankDetailsForm.controls["branchCode"].setValue(val.bankingDetails.branchCode);
          this.bankDetailsForm.controls["showBankingDetails"].setValue(val.showBankingDetails);
        }
        else{
          this.bankingDetailsId = 0
        }
        this.isLoading= false;
      },
      error => {
        this.isLoading= false;
        this.notification.handleError(error);
      }
    )
  }

  showNotice()
  {
    this.submitSettingsForm();
    this.notification.ShowAlert({type: resultType.Information,
      title: "Setting Change",
      htmlMessage: 'This change will only occur on next login,Click below to log out',
      maskClosable: false,
      autoCloseInMilliseconds: 10000,
      btnText: 'Logout'}, undefined, () => {
        // console.log('Working Fine');
        localStorage.removeItem("clientData");
        localStorage.removeItem("access_token");
        sessionStorage.clear();
        location.reload();
      });
  }

  triggerBank()
  {
    if(this.bankDetailsForm.value.showBankingDetails == true && this.bankDetailsForm.invalid)
    {
      Object.values(this.bankDetailsForm.controls).forEach(control => {
        if (control.invalid)
        {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  submitSettingsForm() {
    this.isSaving = true;
    let model = new ClientSettings()
    model.VATNumber = this.settingsForm.value.vatNr;
    model.RegNumber = this.settingsForm.value.regNr;
    // model.bankingDetails = this.settingsForm.value.bankingDetails;
    model.liftCountOnline = this.settingsForm.value.liftCount;
    model.address = this.settingsForm.value.address;
    model.zipCode = this.settingsForm.value.zipCode;
    model.city = this.settingsForm.value.city;
    model.bookingsBlockedHours = this.settingsForm.value.bookingBlockedHours;
    model.displayCostOnJC = this.settingsForm.value.displayCostOnJC;
    model.manageStock = !this.settingsForm.value.manageStock;
    model.isInvoiceManagedSF = this.settingsForm.value.isInvoiceManagedSF;
    model.featureLevel = this.settingsForm.value.featureLevel;
    model.showBankingDetails = this.bankDetailsForm.value.showBankingDetails;  
    if(this.bankDetailsForm.valid)
    {
      model.bankingDetails = new ClientBankingDetails();
      model.bankingDetails.bankingDetailsId = this.bankingDetailsId;
      model.bankingDetails.beneficiaryName = this.bankDetailsForm.value.beneficiaryName;
      model.bankingDetails.bankName = this.bankDetailsForm.value.bankName;
      model.bankingDetails.branchCode = Number(this.bankDetailsForm.value.branchCode);
      model.bankingDetails.accountType = this.bankDetailsForm.value.accountType;
      model.bankingDetails.accountNumber = this.bankDetailsForm.value.accountNumber;
      model.bankingDetails.clientId = this.authService.user.client_id;
    }

    this.settingsService.updateMainSettings(model).subscribe(
      () => {
        this.notification.showSuccess("Settings updated");
        this.isSaving = false;
        this.ngOnInit();
      },
      error => {
        this.isSaving = false;
        this.notification.handleError(error);
      }
    )
  }

}
