import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { ModalService } from './modal.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-modal-video',
  templateUrl: './modal-video.component.html',
  styleUrls: ['./modal-video.component.less']
})


export class ModalVideoComponent implements OnInit, OnDestroy {
  videoUrl: string;
  title: string;
  safeUrl: SafeResourceUrl;
  isVisible = false;
  private subscription: Subscription = new Subscription();

  constructor(private sanitizer: DomSanitizer, public modalService: ModalService) 
  {}
  
  ngOnInit(): void {
    this.subscription.add(this.modalService.currentVideoUrl.subscribe(url => {
      this.videoUrl = url;
      this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }));
    this.subscription.add(this.modalService.currentTitle.subscribe(title => this.title = title));
    this.subscription.add(this.modalService.currentVisibility.subscribe(visible => this.isVisible = visible));
  }

  handleClose(): void {
    this.isVisible = false;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
