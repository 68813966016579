<!-- <div style="text-align: center;"  > -->
<div class="text-center">
    <div nz-row nzJustify="center">
        <nz-card nzHoverable style="width: 90%;">
            <div nz-row nzJustify="center" nzAlign="top">
                <div nz-col [nzSm]="24" [nzXs]="24" [nzMd]="22" [nzLg]="18" [nzXl]="16" class="input-form ">
                    <h2 style="margin-left:-1px; margin-right: -1px"><b>Settings</b></h2>
                    <nz-spin [nzSpinning]="isLoading">
                        <form nz-form [formGroup]="settingsForm" style="padding-bottom: 15px;">
                            <nz-form-item>
                                <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="vatNr">VAT Nr</nz-form-label>
                                <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Enter a valid VAT Number">
                                    <input nz-input formControlName="vatNr" id="vatNr" />
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item>
                                <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="regNr">Reg Nr</nz-form-label>
                                <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Enter a valid Registration Number">
                                    <input nz-input formControlName="regNr" id="regNr" />
                                </nz-form-control>
                            </nz-form-item>
                            <div nz-row>
                                <div nz-col [nzSpan]="12">
                                    <nz-form-item *ngIf="authService.user.bookingsEnabled">
                                        <nz-form-label [nzSm]="12" [nzXs]="24" nzFor="liftCount"><small>Booking Fitment Bays</small></nz-form-label>
                                        <nz-form-control [nzSm]="8" [nzXs]="24" nzErrorTip="Enter the amount of lifts for online bookings">
                                            <nz-input-number nz-input formControlName="liftCount" id="liftCount" style="width: 100%;">
                                            </nz-input-number>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                                <div nz-col [nzSpan]="12">
                                    <nz-form-item *ngIf="authService.user.bookingsEnabled">
                                        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="bookingBlockedHours"><small>Bookings Locked<br /><i>(hours)</i></small></nz-form-label>
                                        <nz-form-control [nzSm]="8" [nzXs]="24" nzErrorTip="Enter the number of hours to lock bookings">
                                            <nz-input-number nz-input formControlName="bookingBlockedHours" id="bookingBlockedHours" style="width: 100%;">
                                            </nz-input-number>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                            <div nz-row>
                                <div nz-col [nzSpan]="8" *ngIf="authService.hasClaims(['STA'])" [nzPush]="4">
                                    <!--Add For Stock MANAGEMENT dont show for non premium -->
                                    <nz-form-item>
                                        <nz-form-label nzFor="manageStock">Allow SF to manage Stock</nz-form-label>
                                        <nz-form-control [nzSm]="8" [nzXs]="24" style="text-align: left;">
                                            <nz-switch (click)="showNotice()" formControlName="manageStock" id="manageStock"></nz-switch>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                            <div nz-row>
                                <div nz-col [nzSpan]="12">
                                    <nz-form-item>
                                        <nz-form-label [nzSm]="12" [nzXs]="24" nzFor="isInvoiceManagedSF">Feature Level</nz-form-label>
                                        <nz-form-control [nzSm]="12" [nzXs]="24" style="text-align: left">
                                            <nz-select formControlName="featureLevel" id="featureLevel">
                                                <nz-option nzValue="1" nzLabel="Quoting Only"></nz-option>
                                                <nz-option nzValue="2" nzLabel="Work In Progress"></nz-option>
                                                <nz-option nzValue="3" nzLabel="Invoicing"></nz-option>
                                            </nz-select>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                            <div nz-row>
                                <div nz-col [nzSpan]="8" *ngIf="this.settingsForm.value.featureLevel >= 2" [nzPush]="4">
                                    <nz-form-item>
                                        <nz-form-label nzFor="displayCostOnJC">Display Price on Jobcard</nz-form-label>
                                        <nz-form-control [nzSm]="8" [nzXs]="24" style="text-align: left;">
                                            <!-- <input nz-input formControlName="displayCostOnJC" id="displayCostOnJC" /> -->
                                            <nz-switch formControlName="displayCostOnJC" id="displayCostOnJC"></nz-switch>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                                <div nz-col [nzSpan]="12" *ngIf="this.settingsForm.value.featureLevel == 3" [nzPush]="4">
                                    <nz-form-item>
                                        <nz-form-label [nzSm]="12" [nzXs]="24" nzFor="isInvoiceManagedSF">Invoice Managed by SF</nz-form-label>
                                        <nz-form-control [nzSm]="8" [nzXs]="24" style="text-align: left;">
                                            <nz-switch formControlName="isInvoiceManagedSF" id="isInvoiceManagedSF"></nz-switch>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                            <div nz-row *ngIf="this.settingsForm.controls.featureLevel.dirty" nzJustify="center">
                                <div nz-col [nzSpan]="22">
                                    <nz-alert nzType="warning" nzMessage="PLEASE NOTE: All users needs to log out and back in for changes to take effect" nzShowIcon></nz-alert>
                                </div>
                            </div>
                            <div>
                            </div>
                            <ng-container>
                                <form nz-form [formGroup]="bankDetailsForm" style="padding-bottom: 15px;">
                                    <h3>Banking Details</h3>
                                    <nz-form-item>
                                        <nz-form-label [nzSm]="3" [nzXs]="12" nzFor="beneficiery">Beneficiary</nz-form-label>
                                        <nz-form-control [nzSm]="7" [nzXs]="12" nzErrorTip="Enter Beneficiary Name">
                                            <input nz-input formControlName="beneficiaryName" id="beneficiaryName" />
                                        </nz-form-control>
                                        <nz-form-label [nzSm]="3" [nzXs]="12" nzFor="bankName">Bank</nz-form-label>
                                        <nz-form-control [nzSm]="7" [nzXs]="12" nzErrorTip="Enter Bank Name">
                                            <input nz-input formControlName="bankName" id="bankName" />
                                        </nz-form-control>
                                    </nz-form-item>
                                    <nz-form-item>
                                        <nz-form-label [nzSm]="3" [nzXs]="12" nzFor="branchCode">Branch Code</nz-form-label>
                                        <nz-form-control [nzSm]="7" [nzXs]="12" nzErrorTip="Enter branch Code">
                                            <input nz-input formControlName="branchCode" id="branchCode" />
                                        </nz-form-control>
                                        <nz-form-label [nzSm]="3" [nzXs]="12" nzFor="accountType">Acc Type</nz-form-label>
                                        <nz-form-control [nzSm]="7" [nzXs]="12" nzErrorTip="Enter AccountType">
                                            <input nz-input formControlName="accountType" id="accountType" />
                                        </nz-form-control>
                                    </nz-form-item>
                                    <nz-form-item>
                                        <nz-form-label [nzSm]="3" [nzXs]="12" nzFor="accountNumber">Acc Number</nz-form-label>
                                        <nz-form-control [nzSm]="7" [nzXs]="12" nzErrorTip="Enter Account Number">
                                            <input nz-input formControlName="accountNumber" id="accountNumber" />
                                        </nz-form-control>
                                        <nz-form-label [nzSm]="4" [nzXs]="14"><small>Show Details (Quote)</small></nz-form-label>
                                        <nz-form-control [nzSm]="6" [nzXs]="10" nzErrorTip="Enter Account Number">
                                            <nz-switch formControlName="showBankingDetails" [ngModel]="showBankingDetails" [nzCheckedChildren]="active" [nzUnCheckedChildren]="deactive" (ngModelChange)="triggerBank()"></nz-switch>
                                        </nz-form-control>
                                        <ng-template #active>
                                            <span nz-icon nzType="check"></span>
                                        </ng-template>
                                        <ng-template #deactive>
                                            <span nz-icon nzType="close"></span>
                                        </ng-template>
                                    </nz-form-item>
                                </form>
                            </ng-container>
                            <h3>Address</h3>
                            <nz-form-item>
                                <nz-form-label [nzSm]="3" [nzXs]="24" nzFor="address">Address</nz-form-label>
                                <nz-form-control [nzSm]="17" [nzXs]="24" nzErrorTip="Enter valid address">
                                    <textarea nz-input nz-input rows="2" formControlName="address" id="address"></textarea>
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item>
                                <nz-form-label [nzSm]="3" [nzXs]="12" nzFor="city">City</nz-form-label>
                                <nz-form-control [nzSm]="7" [nzXs]="12" nzErrorTip="Enter a valid City">
                                    <input nz-input formControlName="city" id="city" />
                                </nz-form-control>
                                <nz-form-label [nzSm]="3" [nzXs]="12" nzFor="zipCode">Zip Code</nz-form-label>
                                <nz-form-control [nzSm]="7" [nzXs]="12" nzErrorTip="Enter a valid Zip Code">
                                    <input nz-input formControlName="zipCode" id="zipCode" />
                                </nz-form-control>
                            </nz-form-item>
                            <div>
                                <button [disabled]="bankDetailsForm.value.showBankingDetails == true && bankDetailsForm.invalid" nz-button nzType="primary" [nzLoading]="isSaving" (click)="submitSettingsForm()">Save</button>
                            </div>
                        </form>
                    </nz-spin>
                </div>
            </div>
        </nz-card>
    </div>
</div>