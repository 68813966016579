<div class="container-fluid">
    <div class="row ">
        <div class="col-lg-2">
        </div>
        <div class="col-lg-8 " style="text-align: center;">
            <h4><b>Custom Services & Stock </b></h4>
            <app-video-widget [videoId]="14" style="float: inline-end;"></app-video-widget>

        </div>
        <div class="col-lg-2">
        </div>
    </div>
</div>

<div class="text-center">
    <p style="margin-bottom: 15px;">
        <b>Below You can Update the Stock Code and Price of Custom Stock.You must do this for us to insert orders into your POS. All items marked in red will cause the order to fail.</b>
    </p>
    <br>
    <!-- <nz-spin [nzSpinning]="isLoading"> -->
    <ng-template #packageTemplate>
    </ng-template>
    <div nz-row nzGutter="24">
        <div nz-col [nzLg]="{ span: spanStock, offset: 0 }">
            <nz-spin [nzSpinning]="isLoadingStock">
                <nz-tabset nzCentered nzSize="large" (nzSelectedIndexChange)="loadTab($event)" [nzTabBarExtraContent]="packageTemplate" class="sticky">
                    <ng-container *ngFor="let type of stockTypes">
                        <nz-tab *ngIf="type.showTab" [nzTitle]="type.stockDescription">
                            <ng-template nz-tab>
                                <div nz-row nzJustify="space-around">
                                    <div nz-col nzSpan="6">
                                        <button nz-button nzType="default" (click)="openAdd(type.stockDescription)">
                                                <i nz-icon nzType="plus" nzTheme="outline"></i>
                                                &nbsp;Add New {{ type.stockDescription| titlecase}}
                                            </button>
                                    </div>
                                    <div nz-col nzSpan="10">
                                        <nz-input-group [nzSuffix]="suffixIconSearch">
                                            <input type="text" nz-input placeholder="Search Custom Stock" [nzSize]="'large'" [(ngModel)]="input.inputValue" (ngModelChange)="resetTable()" style="text-transform:uppercase;" />
                                        </nz-input-group>
                                        <ng-template #suffixIconSearch>
                                            <span nz-icon nzType="search"></span>
                                        </ng-template>
                                    </div>
                                    <div nz-col nzSpan="6">
                                        <button nz-button nzType="primary" nzSize="large" (click)="openPackages()">
                                                <span nz-icon nzType="database" nzTheme="outline"></span><span *ngIf="!isPackages">Open</span><span *ngIf="isPackages">Close</span>&nbsp;Packages</button>
                                    </div>
                                </div>
                                <br>
                                <nz-table #customStockTable [nzData]="filterCustomStock(type.stockDescription)" [nzFrontPagination]="false" [nzSize]="'small'">
                                    <thead>
                                        <tr>
                                            <th nzWidth="350px">Description</th>
                                            <th>Stock Code</th>
                                            <th *ngIf="!isPackages">Stock Type</th>
                                            <th>Price <small class="text-style">*(Vat Incl)</small></th>
                                            <th>Cost</th>
                                            <th>SOH</th>
                                            <th nz-tooltip nzTooltipTitle="Check the box to allow the same quantity as tyres. This overrules the 'Fixed Quantity' in the Packages table" nzTooltipColor="var(--textcolor)" style="max-width: 7rem;">Linked to Tyre Qty</th>
                                            <!-- <th *ngIf="!isPackages">Delete</th> -->
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                            <tr *ngFor="let data of customStockTable.data | filter: input.inputValue: input.inputList: 2: pageSize;">
                                                <td [ngClass]="{'color-error' :  data.mapped != 1}">{{ data.description.toUpperCase()}}</td>
                                                <td>
                                                    {{ data.code.toUpperCase() }}
                                                    <span nz-icon nzType="search" nzTheme="outline" *ngIf="data.isSearchable" nz-tooltip nzTooltipTitle="Stock Code Searchable " nzTooltipPlacement="right"></span>
                                                </td>
                                                <td *ngIf="!isPackages">
                                                    {{data.type}}
                                                </td>
                                                <td><button nz-button nzType="default"><span class="text-info-style">R </span>&nbsp;{{ data.price| number }}</button></td>
                                                <td>
                                                    <span class="text-info-style" *ngIf="data.cost">R </span>&nbsp;{{ data.cost| number }}
                                                </td>
                                                <td>{{data.soh}}</td>
                                                <td class="text-center">
                                                    <label nz-checkbox [(ngModel)]="data.linkedToTyreQty" (ngModelChange)="updateTyreQty(data)"></label>
                                                </td>
                                                <td *ngIf="!isPackages" class="text-center">
                                                    <nz-button-group>
                                                        <button nz-button nzType="primary" data-toggle="modal" data-target="#updatestock" (click)="loadstockinfo(data)">
                                                            <span nz-icon nzType="edit" nzTheme="outline"></span>
                                                        </button>
                                                        <button nz-button nzType="default" nzDanger (click)="removeItem(data)">
                                                            <span nz-icon nzType="delete" nzTheme="outline"></span>
                                                        </button>
                                                    </nz-button-group>
                                                </td>
                                                <td *ngIf="isPackages" class="text-center">
                                                    <button nz-button nzType="primary" nzShape="circle" style="border: none" (click)="addPackageItem(data)">
                                                            <span nz-icon nzType="plus" nzTheme="outline"></span>
                                                    </button>
                                                </td>
                                            </tr>
                                        <!-- </ng-template> -->
                                        <tr>
                                            <td colspan="6"></td>
                                        </tr>
                                    </tbody>
                                </nz-table>
                            </ng-template>
                        </nz-tab>
                    </ng-container>
                    <nz-tab nzTitle="TRADE-INS">
                        <ng-template nz-tab>
                            <div nz-row nzJustify="space-around">
                                <div nz-col nzSpan="6">
                                    <!-- <button nz-button nzType="default" (click)="openAdd(type.stockDescription)">
                                            <i nz-icon nzType="plus" nzTheme="outline"></i>
                                            &nbsp;Add New {{ type.stockDescription| titlecase}}
                                        </button> -->
                                </div>
                                <div nz-col nzSpan="10">
                                    <nz-input-group [nzSuffix]="suffixIconSearch">
                                        <input type="text" nz-input placeholder="Search Trade Ins" [nzSize]="'large'" [(ngModel)]="input.inputValue" (ngModelChange)="resetTable()" style="text-transform:uppercase;" />
                                    </nz-input-group>
                                    <ng-template #suffixIconSearch>
                                        <span nz-icon nzType="search"></span>
                                    </ng-template>
                                </div>
                                <div nz-col nzSpan="6">
                                    <!-- <button nz-button nzType="primary" nzSize="large" (click)="openPackages()">
                                            <span nz-icon nzType="database" nzTheme="outline"></span><span *ngIf="!isPackages">Open</span><span *ngIf="isPackages">Close</span>&nbsp;Packages</button> -->
                                </div>
                            </div>
                            <br>
                            <nz-table #usedStockTable [nzData]="stockUsed" [nzFrontPagination]="false" [nzSize]="'small'">
                                <thead>
                                    <tr>
                                        <th nzWidth="350px">Description</th>
                                        <th>Stock Code</th>
                                        <th>Brand</th>
                                        <th>Price <small class="text-style">*(Vat Incl)</small></th>
                                        <th>Cost</th>
                                        <th>SOH</th>
                                        
                                        <!-- <th *ngIf="!isPackages">Delete</th> -->
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                        <tr *ngFor="let data of usedStockTable.data | filter: input.inputValue: input.inputList: 2: pageSize;">
                                            <td>{{ data.description.toUpperCase()}}</td>
                                            <td>
                                                {{ data.stock_code.toUpperCase() }}
                                                
                                            </td>
                                            <td >
                                                {{data.brand}}
                                            </td>
                                            <td>
                                                <button nz-button nzType="default" nz-tooltip [nzTooltipTitle]="'Excl: R' + data.price"><span class="text-info-style">R </span>&nbsp;{{ data.priceIncl | number : '1.2-2' }}</button>
                                            </td>
                                            <td>
                                                <span class="text-info-style" *ngIf="data.cost">R </span>&nbsp;{{ data.cost | number : '1.2-2' }}
                                            </td>
                                            <td>{{data.soh}}</td>
                                            
                                            <td *ngIf="!isPackages" class="text-center">
                                                <!-- <nz-button-group>
                                                    <button nz-button nzType="primary" data-toggle="modal" data-target="#updatestock" (click)="loadstockinfo(data)">
                                                        <span nz-icon nzType="edit" nzTheme="outline"></span>
                                                    </button>
                                                    <button nz-button nzType="default" nzDanger (click)="removeItem(data)">
                                                        <span nz-icon nzType="delete" nzTheme="outline"></span>
                                                    </button>
                                                </nz-button-group> -->
                                            </td>
                                            
                                        </tr>
                                    <!-- </ng-template> -->
                                    <tr>
                                        <td colspan="6"></td>
                                    </tr>
                                </tbody>
                            </nz-table>
                        </ng-template>
                    </nz-tab>
                </nz-tabset>
            </nz-spin>
        </div>
        <div nz-col [nzLg]="{ span: spanPackages, offset: 0 }">
            <nz-spin [nzSpinning]="isLoadingPackages">
                <nz-tabset nzCentered nzSize="large" (nzSelectedIndexChange)="loadPackageTab($event)" class="sticky">
                    <ng-container *ngFor="let type of packageType">
                        <nz-tab [nzTitle]="type.packageAcronym">
                            <ng-template nz-tab>
                                <div nz-row>
                                    <div nz-col nzSpan="18">
                                        <strong *ngIf="type.packageTypeId === 1" class="color-primary">The AQ(Auto Quote) tab is used for the current Quick Quote button</strong>
                                    </div>
                                    <div nz-col nzSpan="6">
                                        <button nz-button nzType="default" nzSize="large" (click)="this.isEditPackageModal = true;"><span nz-icon nzType="file-sync" nzTheme="outline"></span> Edit Packages</button>
                                    </div>
                                </div>
                                <br>
                                <nz-table #packagesTable [nzData]="filterCustomStockPackages(type.packageTypeId)" [nzFrontPagination]="false" [nzSize]="'small'">
                                    <thead>
                                        <tr>
                                            <th>Description</th>
                                            <th>Stock Code</th>
                                            <th>Price <small class="text-style">*(Vat Incl)</small></th>
                                            <th nz-tooltip nzTooltipTitle="This allows you to customize your set quantities for the specific stock inside the packages. This does not overrule the 'Linked To Tyre Qty' check." nzTooltipColor="var(--textcolor)">Fixed Quantity</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody class="tbody">
                                        <tr *ngFor="let item of packagesTable.data">
                                            <td>{{item.description}}</td>
                                            <td>{{item.stockCode}}</td>
                                            <td>
                                                <button nz-button nzType="default">
                                                        <span class="text-info-style">R </span>&nbsp;{{ item.price| number }}
                                                    </button>
                                            </td>
                                            <td>
                                                <nz-input-number [(ngModel)]="item.fixedQty" (ngModelChange)="updateFixedTyreQty(item)" [nzMin]="1" [nzMax]="20" [nzStep]="1">{{item.fixedQty}}</nz-input-number>
                                            </td>
                                            <td style="text-align: center;">
                                                <button nz-button nzType="primary" nzShape="circle" style="border: none" (click)="deletePackageItem(item)">
                                                        <i nz-icon nzType="minus" nzTheme="outline"></i>
                                                    </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </nz-table>
                            </ng-template>
                        </nz-tab>
                    </ng-container>
                </nz-tabset>
            </nz-spin>
        </div>
    </div>
    <!-- </nz-spin> -->
</div>


<!--Modal for updating -->

<nz-modal [(nzVisible)]="isModalVisible" (nzOnCancel)="this.isModalVisible = false" nzWidth="800px">
    <div *nzModalContent>
        <div class="modal-body text-center" style="font-size:20px">
            <h3>{{ this.editcustom.description }}</h3>
            <div class="container">
                <div class="row">
                    <div class="col-lg-3">
                        <label class="float-left">Stock Code</label>
                        <nz-input-group [nzAddOnBefore]="prefixBar">
                            <input nz-input [(ngModel)]="editcustom.code" value="{{ this.editcustom.code}}">
                        </nz-input-group>
                        <ng-template #prefixBar>
                            <span nz-icon nzType="barcode" nzTheme="outline"></span>
                        </ng-template>

                    </div>
                    <div class="col-lg-4">
                        <label class="float-left">Description</label>
                        <nz-input-group [nzAddOnBefore]="prefixAli">
                            <input nz-input [(ngModel)]="editcustom.description" value="{{ this.editcustom.description}}">
                        </nz-input-group>
                        <ng-template #prefixAli>
                            <span nz-icon nzType="align-left" nzTheme="outline"></span>
                        </ng-template>
                    </div>
                    <div class="col-lg-3">
                        <label class="float-left">Price <small class="text-style">*(Vat incl)</small></label>
                        <nz-input-group [nzAddOnBefore]="prefixDol">
                            <input nz-input [(ngModel)]="editcustom.price" value="{{ this.editcustom.price}}">
                        </nz-input-group>
                        <ng-template #prefixDol>
                            <span nz-icon nzType="dollar" nzTheme="outline"></span>
                        </ng-template>
                    </div>
                    <div class="col-lg-2">
                        <label class="float-left">Buy Out</label>
                        <nz-switch [(ngModel)]="editcustom.isBuyOut" [nzCheckedChildren]="checkedTemplate" [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                        <ng-template #checkedTemplate><small style="vertical-align: top;">YES</small></ng-template>
                        <ng-template #unCheckedTemplate><small style="vertical-align: top;">NO</small></ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="primary" (click)="updatecustom()">Save</button>
    </div>
</nz-modal>


<!--Modal For Adding-->

<nz-modal [(nzVisible)]="isAddCustomStockModalVisible" nzTitle="{{title | titlecase}}" (nzOnCancel)="closeModal(this.addCustomItemForm); isAddCustomStockModalVisible = false" nzWidth="700px">
    <div *nzModalContent>
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-6 offset-3">
                    <form nz-form [formGroup]="addCustomItemForm">
                        <nz-form-item>
                            <nz-form-label [nzSpan]="24" nzRequired>Stock Code</nz-form-label>
                            <nz-form-control [nzSpan]="24" nzHasFeedback nzValidatingTip="Validating Stock Code..." [nzErrorTip]="userErrorTpl" [nzSuccessTip]="userSuccessTpl">
                                <nz-input-group nzPrefixIcon="barcode">
                                    <input formControlName="stockcode" name="stockcode" nz-input placeholder="Stock Code" nzBorderless/>
                                </nz-input-group>
                                <ng-template #userErrorTpl let-control>
                                    <ng-container *ngIf="control.hasError('required')">Stock code must be at least 2 characters.</ng-container>
                                    <ng-container *ngIf="control.hasError('duplicated')">Stock code is already active and is displayed in your list.</ng-container>
                                </ng-template>
                                <ng-template #userSuccessTpl let-control>
                                    <ng-container *ngIf="!successCheck">Stock code exists and can be used.</ng-container>
                                    <ng-container *ngIf="successCheck">Stock code is currently disabled and can be REACTIVED.</ng-container>
                                </ng-template>
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <label>Description</label>
                            <nz-input-group nzPrefixIcon="file-text">
                                <input formControlName="description" name="description" nz-input placeholder="Description" />
                            </nz-input-group>
                        </nz-form-item>
                        <nz-form-item>
                            <label>Price (incl VAT)</label>
                            <nz-input-group nzPrefixIcon="money-collect">
                                <input formControlName="price" name="price" nz-input placeholder="Price" />
                            </nz-input-group>
                        </nz-form-item>

                    </form>
                    <nz-form-item>
                        <label>Buy Out</label>
                        <nz-input-group>
                            <nz-switch [nzCheckedChildren]="checkedTemplate" [nzUnCheckedChildren]="unCheckedTemplate" [(ngModel)]="isAddingBuyOut"></nz-switch>
                            <ng-template #checkedTemplate><small style="vertical-align: top;">YES</small></ng-template>
                            <ng-template #unCheckedTemplate><small style="vertical-align: top;">NO</small></ng-template>
                        </nz-input-group>
                    </nz-form-item>
                </div>
            </div>
        </div>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="primary" (click)="reactivateCustomStock()" *ngIf="reactivate">
            <span nz-icon nzType="retweet" nzTheme="outline"></span>Reactivate
        </button>
        <button nz-button nzType="primary" (click)="addCustomStockItem()" [disabled]="!addCustomItemForm.valid" *ngIf="!reactivate">
            <i nz-icon nzType="save" nzTheme="fill" style="vertical-align: top; margin-right: 4px; font-size: 1.5rem;"></i>Save
        </button>
    </div>
</nz-modal>

<!--Modal for creating new package -->

<nz-modal [(nzVisible)]="isCreatePackageModal" (nzOnCancel)="this.isCreatePackageModal = false" nzWidth="800px">
    <div *nzModalContent>
        <div class="modal-body text-center" style="font-size:20px">
            <h3>Create New Package</h3>
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <label class="float-left">Package Fullname</label>
                        <nz-input-group [nzAddOnBefore]="prefixBar">
                            <input nz-input [(ngModel)]="editCustomPackage.packageDescription" value="{{ this.editCustomPackage.packageDescription}}" (keypress)="omit_special_char($event)">
                        </nz-input-group>
                        <ng-template #prefixBar>
                            <span nz-icon nzType="barcode" nzTheme="outline"></span>
                        </ng-template>

                    </div>
                    <div class="col-lg-4">
                        <label class="float-left">Package Acronym</label>
                        <nz-input-group [nzAddOnBefore]="prefixAli">
                            <input nz-input [(ngModel)]="editCustomPackage.packageAcronym" value="{{ this.editCustomPackage.packageAcronym}}" (keypress)="omit_special_char($event)">
                        </nz-input-group>
                        <ng-template #prefixAli>
                            <span nz-icon nzType="align-left" nzTheme="outline"></span>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="primary" (click)="createPackage()">Save</button>
    </div>
</nz-modal>

<!--Modal for creating new package -->

<nz-modal [(nzVisible)]="isEditPackageModal" nzWidth="800px" (nzOnCancel)="this.isEditPackageModal = false">
    <div *nzModalContent>
        <div class="modal-body text-center" style="font-size:20px">
            <h3>Edit Packages</h3>
            <div nz-col nzSpan="6" nzOffset="1">
                <button nz-button nzType="primary" (click)="this.isCreatePackageModal = true;"><span nz-icon nzType="file-add" nzTheme="outline"></span> Add Package</button>
            </div>
            <br />
            <nz-table #packageTypeTable [nzData]="packageType" [nzFrontPagination]="false">
                <thead>
                    <tr>
                        <th>Description</th>
                        <th nzWidth="180px" class="text-center">Acronym</th>
                        <th nzWidth="150px"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of packageTypeTable.data" class="editable-row">
                        <ng-container *ngIf="!editCache[item.packageTypeId].edit; else editTemplate">
                            <td>{{item.packageDescription}}</td>
                            <td class="text-center">{{item.packageAcronym}}</td>
                            <td class="text-center">
                                <button nz-button nzShape="circle" nzSize="large" nzType="primary" *ngIf="item.clientId != 0" (click)="startEdit(item.packageTypeId)" style="margin-right: 10px">
                                    <span nz-icon nzType="edit" nzTheme="outline"></span>
                                </button>
                                <button nz-button nzShape="circle" nzSize="large" nzDanger *ngIf="item.clientId != 0" nz-popconfirm nzPopconfirmTitle="Sure to delete?" (nzOnConfirm)="deletePackage(item)" [nzIcon]="iconTpl" style="margin-left: 10px">
                                    <span nz-icon nzType="delete" nzTheme="outline" class="text-danger"></span>
                                </button>
                                <ng-template #iconTpl>
                                    <span nz-icon nzType="delete" style="color: var(--errorcolor)"></span>
                                </ng-template>
                            </td>
                        </ng-container>
                        <ng-template #editTemplate>
                            <td><input type="text" nz-input [(ngModel)]="editCache[item.packageTypeId].data.packageDescription" (keypress)="omit_special_char($event)" /></td>
                            <td><input type="text" nz-input [(ngModel)]="editCache[item.packageTypeId].data.packageAcronym" (keypress)="omit_special_char($event)" /></td>
                            <td class="text-center">
                                <button nz-button nzShape="circle" nzSize="large" nzType="text" (click)="saveEdit(item.packageTypeId)" class="save" style="margin-right: 10px"><span nz-icon nzType="save" nzTheme="outline"></span></button>
                                <button nz-button nzShape="circle" nzSize="large" nzType="text" (click)="cancelEdit(item.packageTypeId)" style="margin-left: 10px"><span nz-icon nzType="close-circle" nzTheme="outline"></span></button>
                            </td>
                        </ng-template>
                    </tr>
                </tbody>
            </nz-table>
        </div>
    </div>
    <div *nzModalFooter>
        <!-- <button nz-button nzType="default" (click)="this.isEditPackageModal = false">Cancel</button> -->
        <button nz-button nzType="primary" (click)="this.isEditPackageModal = false">OK</button>
    </div>
</nz-modal>