import { Component, OnInit } from '@angular/core';
import { ClientBookingSlot } from 'src/app/models/settings';
import { NotificationService } from 'src/app/shared/notification.service';
import { SettingsService } from '../settings.service';

@Component({
  selector: 'app-bookings-slots',
  templateUrl: './bookings-slots.component.html',
  styleUrls: ['./bookings-slots.component.less']
})
export class BookingsSlotsComponent implements OnInit {

  constructor(private settingsService: SettingsService, private notification: NotificationService) { }

  slots: ClientBookingSlot[][] = [];
  isLoading = true;

  ngOnInit(): void {
    this.loadSlots();
  }

  private loadSlots() {
    this.isLoading = true;
    this.settingsService.getBookingSlots().subscribe(
      val => {
        for (let index = 0; index < 7; index++) {          
          this.slots[index] = val
            .filter(e => e.dayOfWeek === index + 1)
            .sort((n1, n2) => (+n1.slotTime.substring(0,2)*60 + +n1.slotTime.substring(3,5)) - (+n2.slotTime.substring(0,2)*60 + +n2.slotTime.substring(3,5)));
          this.isLoading = false;
        }
      },
      error => {
        this.notification.handleError(error);
        this.isLoading = false;
      }
    )
  }

  public refreshSlots() {
    this.loadSlots();
  }

}
