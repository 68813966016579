import { NotificationService } from '../shared/notification.service';
import { StockService } from './../stock-mapping/stock.service';
import { StockData, StockInfo  } from '../models/stock';
import { Component, OnInit } from '@angular/core';
import { PriceNames  } from '../models/pricelist';
import * as XLSX from 'xlsx';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { resultType } from '../shared/modal-content/modal-content.component'; 
import { AuthService } from '../auth.service';



@Component({
  selector: 'app-stock-override',
  templateUrl: './stock-override.component.html',
  styleUrls: ['./stock-override.component.less']
})
export class StockOverrideComponent implements OnInit {

  pricelist: PriceNames;
  stockInfo: StockInfo[]=[];
  editCache: { [key: string]: { edit: boolean; data: StockData } } = {};
  overrideData: StockData[]=[];
  options: StockInfo[]=[];

  msfid: number = 0;
  inputValue: string = "";
  color: string = '#117274';
  fileName:string = 'ExcelSheet.xlsx';
  info:string = 'To search for multiple classifications, seperate search with a space eg. 2656517 MICH 242767.'


  showSearch:boolean = false;
  brandClicked : boolean = false;
  genCodeClicked : boolean = false;
  isAddModalVisible:boolean = false;
  stockCodeClicked : boolean = false;
  isLoading: boolean = true;
  showNonActiveStock: boolean = false;
  


  //Rule Data
   ruleForm: FormGroup = new FormGroup({
    priceonline: new FormControl(0),
    priceretail: new FormControl(0),

    pricedefault: new FormControl(0),
    pricewholesale: new FormControl(0),

    priceinternal: new FormControl(0),
    priceclearout: new FormControl(0),

    pricecustom1: new FormControl(0),
    pricecustom2: new FormControl(0),

    pricecustom3: new FormControl(0),
    pricecustom4: new FormControl(0),

    msfid: new FormControl(0),
  });


  constructor(private stockService: StockService, private notification: NotificationService, public authService: AuthService) { }

  ngOnInit(){
    this.isLoading = true;
    this.reloadData();
    this.getData();

    this.notification.ShowAlert({type: resultType.Information, 
                                 title: "Stock Override", 
                                 htmlMessage: 'Add custom pricing that will overrule all other pricing <br>'+
                                              'If you need to move aging stock or protect scarce stock, this is where you can do it. <br>'+
                                              '<span class="text-style">To prevent stock being displayed on a price list set its price to zero <span >(0)</span></span> <br>', 
                                 btnText: "OK", 
                                 maskClosable: false, 
                                 autoCloseInMilliseconds: 12000})
  }

  reloadData(){
    this.pricelist= new PriceNames;
    this.overrideData = [];
    this.stockInfo= [];
    this.options = [];
    this.msfid = 0;

  }

  getData(){
    this.stockService.getStock().subscribe((res:any) => {
        this.pricelist = res.pricedata as PriceNames;
        this.overrideData = res.override_Data as StockData[];
        this.stockInfo = res.stock_info as StockInfo[];
        this.isLoading = false;
        this.updateEditCache();
      },
      error => {
        this.notification.handleError(error);
      }
    )
  }

  onClickAddNewRule() {
    this.isAddModalVisible = true;
    this.inputValue = "";
    this.msfid = 0;
  }

  onChange(option: any): void{
    this.msfid = option;
  }

  removeRule(item:StockData){
    this.stockService.removeItem(item).subscribe(res =>{
        this.notification.showSuccess("successfully Removed " + item.description);
        this.overrideData = this.overrideData.filter(val => val.overindex !== item.overindex);
        this.overrideData = [...this.overrideData];
      },
      error => {
        this.notification.handleError(error);
      })
  }

  addRule(){
    this.isLoading = true;
    if(this.inputValue == null || this.inputValue == "" ){
      this.notification.ShowAlert({type: resultType.Warning, 
        title: "Incomplete Submission", 
        htmlMessage: 'Please link a Stock Item' ,
        maskClosable: false, 
        autoCloseInMilliseconds: 10000});

        return
    }
    this.ruleForm.controls.msfid.setValue(this.msfid);

    this.stockService.addNewRule(this.ruleForm.value).subscribe(res =>{
      this.overrideData.push(res[0]);
      this.overrideData = [...this.overrideData];

      this.isAddModalVisible = false;
      this.isLoading = false;
      this.updateEditCache();

      this.inputValue = "";

      this.notification.showSuccess("Rule Added Successfully");
    },
    error =>{
      this.notification.handleError(error);
      this.isLoading = false;
    })
  }

  exportexcel(){
    let element = document.getElementById('tbl');
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw:true});
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, this.fileName);
  }

  startEdit(id: number): void {
    this.editCache[id].edit = true;
  }

  saveEdit(id: number): void {
    const index = this.overrideData.findIndex(item => item.overindex === id);

    this.stockService.editItem(this.editCache[id].data).subscribe({next : (res) =>{
      Object.assign(this.overrideData[index], this.editCache[id].data);
      this.editCache[id].edit = false;
    },
    error: (error) => {
      this.notification.handleError(error);
    },
    complete: () => {
      this.notification.showSuccess("Rule Updated Successfully");
    }
  });
  }

  cancelEdit(id: number): void {
    const index = this.overrideData.findIndex(item => item.overindex === id);
    this.editCache[id] = {
      data: { ...this.overrideData[index] },
      edit: false
    };
  }

  updateEditCache(): void {
    this.overrideData.forEach(item => {
      this.editCache[item.overindex] = {
        edit: false,
        data: { ...item }
      };
    });
  }

  filterStock(){
    let allStock : StockInfo [] = [];

    if(this.showNonActiveStock == true){
      allStock = this.stockInfo;
    }
    else{
      allStock = this.stockInfo.filter(val => val.activeId !== 0);
    }
    return allStock
  }

// Disables edit button if there are no changes to the current row
  duplicationCheck(item: StockData){
    if(JSON.stringify(item) === JSON.stringify(this.editCache[item.overindex].data))
      return true
    return false
  }
}
