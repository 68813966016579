<nz-card>
    <div nz-row [nzJustify]="'end'" *ngIf="authService.user.client_id == 954 ">
        <div nz-col nzSpan="12">
            <h6 style="float: inline-start;"> Mapped Today : {{totalMappedToday}}</h6>
        </div>
        <div nz-col nzSpan="12">
            <h6 style="float: inline-end;"> {{countDisplay}}</h6>
            <app-video-widget [videoId]="8" style="float: inline-end;"></app-video-widget>

        </div>
    </div>
    <div nz-row>
        <div nz-col nzSpan="24">
            <nz-tabset nzCentered nzSize="large" (nzSelectChange)="tabSelect($event.index)">
                <nz-tab nzTitle="Unmapped">
                    <div nz-row [nzJustify]="'center'">
                        <div nz-col nzSpan="8">
                            <nz-input-group [nzSuffix]="suffixIconSearch" class="mobile-view">
                                <input type="text" nz-input placeholder="Advanced Filter" [(ngModel)]="input.inputValue" (ngModelChange)="resetTable()" style="text-transform:uppercase;" />
                            </nz-input-group>
                            <ng-template #suffixIconSearch>
                                <span nz-icon nzType="search"></span>
                            </ng-template>
                        </div>
                    </div>
                    <br>
                    <nz-tabset [nzTabPosition]="'left'" (nzSelectChange)="log($event.index)">
                        <nz-tab [nzTitle]="notMap">
                            <nz-spin [nzSpinning]="isLoadingUnmapped">
                                <ng-template #notMap>
                                    <span nz-tooltip nzTooltipTitle="Not Mapped" nzTooltipPlacement="right">Not Mapped</span>
                                </ng-template>
                                <!--Mapping row-->
                                <div nz-row [nzJustify]="'end'" *ngIf="authService.user.client_id == 954 ">
                                    <div nz-col nzSpan="4" class="text-center">
                                        <button nz-button nzType="default" (click)="getUnmappedStock()">
                                                load next 50
                                            </button>
                                    </div>
                                </div>
                                <div nz-row>
                                    <div nz-col nzSpan="11" class="text-center">
                                        <h5>Selected</h5>
                                        <input nz-input type="text" nzSize="large" readonly class="text-center" placeholder="Selected Stock" value=" {{currentWorkItem.description}} - {{currentWorkItem.stock_code}} " style="width: 80%; color: var(--textcolor)" />
                                    </div>
                                    <div nz-col nzSpan="2" class="text-center">
                                        <br><br>
                                        <span *ngIf="!isMapping" nz-icon nzType="swap" nzTheme="outline"></span>
                                        <i *ngIf="isMapping" nz-icon [nzType]="'loading'"></i>
                                    </div>
                                    <div nz-col nzSpan="11" class="text-center">
                                        <h5>Mapping</h5>
                                        <nz-spin nzSimple [nzIndicator]="indicatorTemplate" *ngIf="!activestockAvailable"></nz-spin>
                                        <!-- <nz-select 
                                        nzSize="large" 
                                        *ngIf="activestockAvailable" 
                                        [(ngModel)]="selectedMap" 
                                        (nzOnSearch)="searchActiveStock($event)"
                                        [nzFilterOption]="selectedStockItem"
                                        (ngModelChange)="mapStock(this.currentWorkItem.stock_code,$event,5)" 
                                        nzShowSearch 
                                        nzAnyowClear 
                                        nzPlaceHolder="Select Stock Item" 
                                        style="width: 80%"
                                      
                                        >
                                            <nz-option *ngFor="let c of activeStock" [nzValue]="c.msfid" [nzLabel]="c.gen_code+' '+c.brand+' '+c.description+' ('+c.stock_code+')'"></nz-option>
                                        </nz-select> -->

                                        <nz-select style="width: 80%;" [nzShowArrow]="false" nzPlaceHolder="Search Stock Item" [nzShowSearch]="true" [nzNotFoundContent]="noStockResults" (nzOnSearch)="searchActiveStock($event)" (ngModelChange)="mapStock(this.currentWorkItem.stock_code,$event,5)"
                                            [(ngModel)]="selectedMap" [ngModelOptions]="{standalone: true}" [(nzOpen)]="isStockDropDownOpen">
                                            <nz-option *ngFor="let c of filteredStock" [nzValue]="c.key" [nzLabel]="c.name"></nz-option>
                                        </nz-select>
                                        <!--Template for loading Active Stock-->
                                        <ng-template #indicatorTemplate>
                                            <span nz-icon nzType="loading"></span>
                                            <span>loading Active Stock </span>
                                        </ng-template>
                                        <!-- Template for no stock found result in select -->
                                        <ng-template #noStockResults style="text-align: center;">
                                            <div nz-row>
                                                <div nz-col nzSpan="24" class="text-center">
                                                    <div style="text-align: center;" *ngIf="filteredStock.length === 0 && searchedTerm.length > 2; else continueTyping">
                                                        <span> No results found </span><br /><br />
                                                        <button nz-button [nzType]="'primary'" class="btn" (click)="isNmsfAddOpen = true;" *ngIf="this.currentWorkItem.stock_code != '' && authService.hasClaims(['NMSFI'])">
                                                            <i nz-icon nzType="plus" nzTheme="outline" ></i>
                                                            Add To NMSF
                                                        </button>
                                                    </div>
                                                    <ng-template #continueTyping>
                                                        <div style="text-align: center;">
                                                            <span> Continue typing to search... </span><br /><br />
                                                        </div>
                                                    </ng-template>

                                                </div>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                                <!--Mapping Table-->
                                <br>
                                <nz-table #stockTableUnmapped [nzData]="this.unmappedStock" [nzFrontPagination]="true" [nzPageSize]="25">
                                    <thead>
                                        <tr>
                                            <th> Client Name</th>
                                            <th>Stock Description</th>
                                            <th>Stock Code</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody class="tbody">
                                        <tr *ngFor="let item of stockTableUnmapped.data| filter: input.inputValue: input.inputList: 0: pageSize; let i = index" (click)="select_line(item);clickedRow(i)" [class.active]="i == HighlightRow">
                                            <td>
                                                {{item.locationName}}
                                            </td>
                                            <td>
                                                <i nz-icon nzType="exclamation" nzTheme="outline" class="text-warning-style" *ngIf="item.mapped == 6" nz-tooltip nzTooltipPlacement='left' nzTooltipTitle=' SF Admin unable to Map this Item Automatically'></i>                                                {{item.description}}
                                            </td>
                                            <td>
                                                {{item.stock_code}}
                                            </td>
                                            <td>
                                                <nz-button-group nzSize="large">
                                                    <button nz-button nzType="default" class="button" (click)="mapStock(item.stock_code,27128,5)">Battery</button>
                                                    <button nz-button nzType="default" class="button" (click)="mapStock(item.stock_code,27129,5)">Brake</button>
                                                    <button nz-button nzType="default" class="button" (click)="mapStock(item.stock_code,27130,5)">Shock</button>
                                                    <button nz-button nzType="default" class="button" (click)="mapStock(item.stock_code,3,3)">Not a tyre (NAT)</button>
                                                    <button nz-button nzType="default" class="button" (click)="mapStock(item.stock_code,2,2)">Missing in nmsf (MINMSF)</button>
                                                    <button nz-button nzType="default" class="button" *ngIf="authService.user.client_id == 954" (click)="mapStock(item.stock_code,6,6)">Not Mappable</button>
                                                    <button nz-button nzType="default" class="button" *ngIf="authService.user.client_id != 954" nzDanger (click)="mapStock(item.stock_code,6,0)">Delete</button>
                                                </nz-button-group>
                                            </td>
                                        </tr>
                                    </tbody>
                                </nz-table>
                            </nz-spin>
                        </nz-tab>
                        <nz-tab *ngFor="let tab of tabs" [nzTitle]="title">
                            <nz-spin [nzSpinning]="isLoadingUnmapped">
                                <ng-template #title>
                                    <span nz-tooltip nzTooltipTitle="{{tab.description}}" nzTooltipPlacement="right">{{tab.name}}</span>
                                </ng-template>
                                <nz-table #stockTableUnmapped [nzData]="filterUnmappedStock(tab.id)" [nzFrontPagination]="false">
                                    <thead>
                                        <tr>
                                            <th>Client Name</th>
                                            <th>Stock Description</th>
                                            <th>Stock Code</th>
                                            <th>Unmap stock code</th>
                                        </tr>
                                    </thead>
                                    <tbody class="tbody">
                                        <tr *ngFor="let item of stockTableUnmapped.data| filter: input.inputValue: input.inputList: 0: pageSize; let i = index">
                                            <td>{{item.locationName}}</td>
                                            <td>
                                                <i nz-icon nzType="exclamation" nzTheme="outline" class="text-warning-style" *ngIf="item.mapped == 6" nz-tooltip nzTooltipPlacement='left' nzTooltipTitle=' SF Admin unable to Map this Item Automatically'></i>                                                {{item.description}}
                                            </td>
                                            <td>{{item.stock_code}}</td>
                                            <td>
                                                <button nz-button nzType="default" nzDanger nzTooltipTitle="Unmap Stock Code" nzTooltipPlacement="top" nz-tooltip (click)="reUnmapStock(item)">
                                                    <!-- <span nz-icon nzType="delete"  nzTheme="fill"></span>  -->
                                                    Unmap</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </nz-table>
                            </nz-spin>
                        </nz-tab>
                    </nz-tabset>
                </nz-tab>
                <nz-tab nzTitle="Mapped">
                    <div nz-row [nzJustify]="'center'">
                        <div nz-col nzSpan="8">
                            <nz-input-group [nzSuffix]="suffixIconSearch" class="mobile-view">
                                <input type="text" nz-input placeholder="Advanced Filter" [(ngModel)]="input.inputValue" (ngModelChange)="resetTable()" style="text-transform:uppercase;" />
                            </nz-input-group>
                            <ng-template #suffixIconSearch>
                                <span nz-icon nzType="search"></span>
                            </ng-template>
                        </div>
                    </div>
                    <br>
                    <nz-spin [nzSpinning]="isLoadingMapped">
                        <nz-table #stockTable2 [nzData]="this.mappedStock" [nzFrontPagination]="false">
                            <thead>
                                <tr>
                                    <th colspan="3" style="border-right: solid 0.01px var(--textcolor);">
                                        <h5>Fitment Center</h5>
                                    </th>
                                    <th colspan="4">
                                        <h5>StockFinder</h5>
                                    </th>
                                </tr>
                                <tr>
                                    <th>Date mapped</th>
                                    <th>Maped Stock Description</th>
                                    <th [nzSortFn]="FCStockCodeSortFn" style="border-right: solid 0.01px var(--textcolor);">Stock Code</th>

                                    <th>msfId</th>
                                    <th>MSF Description</th>
                                    <th [nzSortFn]="SFStockCodeSortFn">Stock Code</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody class="tbody">
                                <tr *ngFor="let item of stockTable2.data| filter: input.inputValue: input.inputList: 0: pageSize;">
                                    <td>{{ item.dt }}</td>
                                    <td>{{ item.locationStockDescription }}</td>
                                    <td style="border-right: solid 0.01px var(--textcolor);">{{ item.locationStockCode }}</td>

                                    <td>{{item.msfid}}</td>
                                    <td>{{ item.msfDescription }}</td>
                                    <td>{{ item.msfStockCode }}</td>
                                    <td><button nz-button nzType="default" nzDanger (click)="deleteMapping(item.mappingId!)">
                                        <!-- <span nz-icon nzType="delete" nzTheme="fill"></span> -->
                                        Delete
                                    </button>
                                    </td>
                                </tr>
                            </tbody>
                        </nz-table>
                    </nz-spin>
                </nz-tab>
            </nz-tabset>
        </div>
    </div>
</nz-card>


<nz-modal [nzVisible]="isNmsfAddOpen" [nzTitle]="mappingHeader" [nzFooter]="null" (nzOnCancel)="isNmsfAddOpen = false;" nzWidth="800px">
    <div *nzModalContent>
        <app-stock-add-nmsf (dismissModal)="isNmsfAddOpen = false" (stockCode)="removeItem($event)" [stockItem]="itemToAdd"></app-stock-add-nmsf>
    </div>
    <ng-template #mappingHeader>
        <span>Mapping Item: {{itemToAdd.description}} - Code: </span>
        <span class="color-primary">{{itemToAdd.stock_code}}</span>
    </ng-template>
</nz-modal>