import { Component, OnInit } from '@angular/core';
import { ProcurementService } from './procurement.service';
import { StockModelsService} from '../model-stock-analysis/model-stock-analysis.service';
import { StockModelsService_Replenishment } from '../stock-models/stock-models.service';
import { Client_Model,Replenishment_Line,Client_Connection } from '../models/procurement';
import { NotificationService } from '../shared/notification.service';
import { Client, ClientData } from '../models/client';
@Component({
  selector: 'app-procurement-maintenance',
  templateUrl: './procurement-maintenance.component.html',
  styleUrls: ['./procurement-maintenance.component.less']
})
export class ProcurementMaintenanceComponent implements OnInit{


  constructor(private procServ: ProcurementService,private repService:StockModelsService_Replenishment,private mslService:StockModelsService,private alerts:NotificationService){}

  innerHeight = 0;
  scrollHeight = '';
  Client_Data: Client_Model[] =[]
  isLoading = false;
  view = "client_list";
  ngOnInit(): void {
    this.isLoading = true;
    this.innerHeight =  window.innerHeight;
    this.scrollHeight = (this.innerHeight*0.80)+"px";
    this.procServ.get_all_replenishment_clients().subscribe((res:Client_Model[]) =>{
      this.Client_Data = res;
      this.isLoading = false;
    },error =>{
      this.alerts.showWarning(error)
      this.isLoading = false;
    })
  }

  Client_Replenishment_Lines: Replenishment_Line[] =[]
  Client_Viewed: Client_Model
  view_client_modelStock(client:Client_Model){
    this.view = client.account.buyer_name;
    this.Client_Viewed = client;
  }

  codeSearch_Results: Replenishment_Line[] = []
  codeSearched = ''
  scanforcode(){
    let code = (document.getElementById("stockSearch") as HTMLInputElement).value;
    this.codeSearched = code;
    this.codeSearch_Results = []
    this.Client_Data.forEach(client => {
      for(let n = 0; n< client.replenishment_lines.length;n++){
        if(client.replenishment_lines[n].stock_code == code){this.codeSearch_Results.push(client.replenishment_lines[n])}
      }
    });

    if(this.codeSearch_Results.length == 0){
      this.alerts.showInfo("No Results Found For Stock Code: "+code);
    }



  }
}
