import { SocketMessageType } from "./websockets";


export class Message {
      messageId: number;
      userId:number;
      dateCreated: Date;
      dateRead: Date;
      message:string;
      messageLong: string;
      value:number;
      messageType: SocketMessageType
      action1Url: string;
      action1Text: string;
      messageOpened:boolean;
      messageGroupId: string;
}

export class UnreadMessages {
      userId: number
      messages: Message[] = []
      messageCount: number
}
