<div class="container-fluid" *ngIf="this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Web || this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Tablet">
    <div class="row ">
        <div class="col-lg-2">
        </div>
        <div class="col-lg-8 " style="text-align: center;">
            <h4><b>Purchase Orders</b></h4>
        </div>
        <div class="col-lg-2">
        </div>
    </div>
</div>


<br>
<div class="text-center not-mobile" style="position: absolute; top:35%">
    <br>
    <h5 class="text-center">Please rotate your phone to view the Purchases</h5>
    <span nz-icon nzType="rotate-left" nzTheme="outline" class="text-center"></span>
</div>
<!-- -------------------------------Total/Brand Spend tables---------------------------------------------------------------------------- -->

<nz-modal [(nzVisible)]="brandSpendVis" (nzOnCancel)="brandSpendVis = false" [nzFooter]="null" nzTheme="fill" [nzWidth]="2000">
    <nz-spin [nzSpinning]="isLoading">
        <ng-container *nzModalContent>
            <div>
                View Insights:
                <nz-switch [(ngModel)]="InsightVis" (Click)="toggleInsights()"></nz-switch>
            </div>
            <div style="color:white; width: 50%; margin-left:25%; text-align:center;">
                <h4>Brand Spend: <span style="color:cadetblue">R{{this.totalSpend}}</span></h4><br>
                <hr>
            </div>
            <canvas *ngIf="this.InsightVis == true" baseChart [data]="pieChartData" [options]="pieChartOptions" [plugins]="pieChartPlugins" [type]="pieChartType" (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
            <br>
            <nz-table #period [nzData]="brand_spendObj" [nzFrontPagination]="false" [nzScroll]="{ y:'500px'}">
                <thead>
                    <tr>
                        <th style="text-align: center;">
                            <div id="sortableHead" (click)="sortBrandInsight(0)">Brand<a id="sortIcon"><span nz-icon nzType="sf:sortSVG" nzTheme="outline" ></span></a></div>
                        </th>
                        <th style="text-align: center;"></th>
                        <th style="text-align: center; border-left:0px ;">
                            <div id="sortableHead" (click)="sortBrandInsight(1)">Units<a id="sortIcon"><span nz-icon nzType="sf:sortSVG" nzTheme="outline" ></span></a></div>
                        </th>
                        <th style="text-align: center;">
                            <div id="sortableHead" (click)="sortBrandInsight(2)">Invoices<a id="sortIcon"><span nz-icon nzType="sf:sortSVG" nzTheme="outline" ></span></a></div>
                        </th>
                        <th style="text-align: center;">
                            <div id="sortableHead" (click)="sortBrandInsight(3)">Total Spend<a id="sortIcon"><span nz-icon nzType="sf:sortSVG" nzTheme="outline" ></span></a></div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of this.brand_spendObj">
                        <td>{{item.brand_name}}</td>
                        <td><img style="margin: 0 auto; width: 80%;" src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{item.brand_logo}}" class="brandImage" /></td>
                        <td>{{item.units}}</td>
                        <td>{{item.invoices}}</td>
                        <td>R{{item.total_spend}}</td>
                    </tr>
                </tbody>

            </nz-table>


            <!-- <nz-tab  nzTitle="Graphs">
                    <canvas baseChart [data]="pieChartData"  [options]="pieChartOptions" [plugins]="pieChartPlugins" [type]="pieChartType" (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas> 
                </nz-tab> -->

        </ng-container>
    </nz-spin>
</nz-modal>



<nz-modal [(nzVisible)]="totalSpendVis" (nzOnCancel)="totalSpendVis = false" [nzFooter]="null" nzTheme="fill" [nzWidth]="2000">
    <nz-spin [nzSpinning]="isLoading">
        <ng-container *nzModalContent>
            <div>

                View Insights:
                <nz-switch [(ngModel)]="InsightVis" (Click)="toggleInsights()"></nz-switch>
            </div>
            <div style="color:white; width: 50%; margin-left:25%; text-align:center;">
                <h4>Supplier Spend: <span style="color:cadetblue">R{{this.totalSpend}}</span></h4><br>
                <hr>
            </div>
            <canvas *ngIf="this.InsightVis == true" baseChart [data]="pieChartData" [options]="pieChartOptions" [plugins]="pieChartPlugins" [type]="pieChartType" (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
            <br>
            <nz-table #period [nzData]="spendObj" [nzFrontPagination]="false" [nzScroll]="{ y:'500px'}">
                <thead>
                    <tr>
                        <th style="text-align: center;">
                            <div id="sortableHead" (click)="sortSupplierInsight(0)">Supplier<a id="sortIcon"><span nz-icon nzType="sf:sortSVG" nzTheme="outline" ></span></a></div>
                        </th>
                        <th style="text-align: center;">
                            <div id="sortableHead" (click)="sortSupplierInsight(1)">units<a id="sortIcon"><span nz-icon nzType="sf:sortSVG" nzTheme="outline" ></span></a></div>
                        </th>
                        <th style="text-align: center;">
                            <div id="sortableHead" (click)="sortSupplierInsight(2)">Invoices<a id="sortIcon"><span nz-icon nzType="sf:sortSVG" nzTheme="outline" ></span></a></div>
                        </th>
                        <th style="text-align: center;">
                            <div id="sortableHead" (click)="sortSupplierInsight(3)">Total Spend<a id="sortIcon"><span nz-icon nzType="sf:sortSVG" nzTheme="outline" ></span></a></div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of this.spendObj">
                        <td>{{item.supplier_name}}</td>
                        <td>{{item.units}}</td>
                        <td>{{item.invoices}}</td>
                        <td>R{{item.total_spend}}</td>
                    </tr>
                </tbody>

            </nz-table>

        </ng-container>
    </nz-spin>
</nz-modal>
<!-- ------------------------------- End Total/Brand Spend tables---------------------------------------------------------------------------- -->

<nz-spin [nzSpinning]="isLoading" class="mobile">
    <!-- <div class="container-fluid col-lg-11 text-center  bg-1 "> -->
    <ng-template #extraTemplate>
        <button nz-button nzType="primary" style="margin-left: -15%;" nz-tooltip nzTooltipTitle='This feature is only available to users with Analyst or Supplier Management Privelages' *ngIf="this.appComponent.currentBreakpoint != this.appComponent.Breakpoints.HandsetPortrait && spending_access==false">
            Total Spend <span nz-icon  nzTheme="outline"></span>
        </button>
        <button nz-button nzType="primary" style="margin-left: -15%;" nz-dropdown [nzDropdownMenu]="menu" *ngIf="this.appComponent.currentBreakpoint != this.appComponent.Breakpoints.HandsetPortrait && spending_access==true">
            Total Spend <span nz-icon  nzTheme="outline"></span>
        </button>
        <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu nzSelectable class="hover_dropdown">
                <li nz-menu-item style="justify-content: center;" (click)="brandSpend()"> Brand Spend ({{this.current_day}} days)</li>
                <li nz-menu-item style="justify-content: center;" (click)="viewTotalSpend()"> Supplier Spend ({{this.current_day}} days)</li>
            </ul>
        </nz-dropdown-menu>
        <button nz-button nzType="primary" (click)="ngOnInit()" style="margin-left: 1%;" *ngIf="this.appComponent.currentBreakpoint != this.appComponent.Breakpoints.HandsetPortrait">
        Reload <span nz-icon nzType="redo" nzTheme="outline"></span>
        </button>
    </ng-template>
    <nz-tabset nzCentered nzSize="large" [nzTabBarExtraContent]="extraTemplate">
        <nz-tab *ngFor="let table of this.TableOrders, let tblIndex = index" nzTitle="{{ table.title}}">
            <div nz-row nzJustify="center">
                <div nz-col class="text-center" nzSpan="12">
                    <nz-input-group [nzSuffix]="suffixIconSearch" style="width: clamp(200px, 75%, 400px);">
                        <input type="text" nz-input [(ngModel)]="inputValue" placeholder="Advanced Filter" style="text-transform:uppercase;" />
                    </nz-input-group>
                    <ng-template #suffixIconSearch>
                        <span nz-icon nzType="search"></span>
                    </ng-template>
                </div>
            </div>
            <br>
            <nz-table #period [nzData]="table.purchases" [nzFrontPagination]="false" nzSize="small">
                <thead>
                    <tr>
                        <th style="min-width: 200px;">Supplier</th>
                        <th *ngIf="this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Web || this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Tablet">Ref#</th>
                        <th style="width: 6%;" *ngIf="this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Web || this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Tablet">Value</th>
                        <th>Units</th>
                        <th *ngIf="this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Web || this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Tablet">Comment</th>
                        <th style="min-width: 120px;">Date </th>
                        <th *ngIf="this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Web || this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Tablet">Emailed</th>
                        <th style="width: 4%;" *ngIf="this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Web || this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Tablet"></th>
                        <th style="width: 16%;" class="text-center">Status</th>
                        <th *ngIf="authService.hasClaims(['STA']) && this.authService.user.isManagedSF"></th>
                        <th style="min-width: 120px;"></th>
                    </tr>
                </thead>
                <tbody class="table-body">
                    <tr *ngFor="let item of period.data | filter: inputValue">
                        <td>{{item.name }}</td>
                        <td *ngIf="this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Web || this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Tablet">{{item.orderRef}}</td>
                        <td *ngIf="this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Web || this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Tablet"><span class="text-info-style">R </span>{{item.price|number }}</td>
                        <td>{{item.qty}}</td>
                        <td *ngIf="this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Web || this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Tablet">{{item.comment}}</td>
                        <td>{{ item.dateformatted}}</td>
                        <td class="text-center" *ngIf="this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Web || this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Tablet"><span *ngIf="item.dt_email != 'None'">Email Sent <span nz-icon nzType="check" nzTheme="outline" class="color-primary"></span></span>
                        </td>
                        <!--Order Feedback Icon status---->
                        <td class="text-center" *ngIf="this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Web || this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Tablet">
                            <!--State - Processing -->
                            <div *ngIf="item.statusId== 0" class=" btn btn-dark" style="border:none" nz-tooltip nzTooltipPlacement='left' nzTooltipTitle='Order Is still Processing - Click To Refresh' (click)="ngOnInit()">
                                <span nz-icon [nzType]="'loading'" class="color-warning"></span>
                            </div>
                            <!--State - Errors-->
                            <div *ngIf="item.statusId == 4 || item.statusId==7 || item.statusId == 12">
                                <!--SF / Client Fix Warning -->
                                <button nz-button nzType="default" *ngIf="item.errorFix == 0 || item.errorFix == 1" style="border:none;" nz-tooltip nzTooltipPlacement='left' nzTooltipTitle='{{item.error}}'>
                              <span nz-icon nzType="warning" nzTheme="fill" class="color-warning"></span>
                    </button>
                                <!--SF Fix Failed -->
                                <button nz-button nzType="default" *ngIf="item.errorFix == 2" nzDanger style="border:none;" nz-tooltip nzTooltipPlacement='left' nzTooltipTitle='{{item.error}}'>
                            <span nz-icon nzType="close" nzTheme="outline"></span>
                    </button>
                                <!--Wholesale Still Processing -->
                                <button nz-button nzType="default" *ngIf="item.errorFix == 12" style="border:none;" nz-tooltip nzTooltipPlacement='left' nzTooltipTitle='{{item.error}}'>
                        <span nz-icon [nzType]="'loading'" class="color-warning"></span>
                    </button>
                            </div>
                            <!--State Completed-->
                            <div *ngIf="(item.statusId == 5 || item.statusId == 8 ||item.statusId == 9 || item.statusId == 11) && item.errorId != 21" style="border:none;" nz-tooltip nzTooltipPlacement='top' nzTooltipTitle='Order Completed Successfully'>
                                <span nz-icon nzType="check" nzTheme="outline"></span>
                            </div>
                            <div *ngIf="item.statusId == 5 && item.errorId == 21" style="border:none;" nz-tooltip nzTooltipPlacement='top' nzTooltipTitle='{{item.error}}'>
                                <span nz-icon nzType="warning" nzTheme="fill" class="color-warning"></span>

                            </div>
                        </td>
                        <!--Order Feedback Text status-->
                        <td class="text-center">
                            <span *ngIf="item.statusId==0"> Order Processing</span>
                            <div *ngIf="item.statusId == 4 || item.statusId == 7 || item.statusId == 12">
                                <!--State - Client Fix by Resubmitting -->
                                <button nz-button nzType="default" *ngIf="item.errorFix == 0" (click)="resubmitOrder(item.orderid)" nz-tooltip nzTooltipPlacement='top' nzTooltipTitle='Please Fix the Issue mentioned on the Error Alert and Then Place The Order Again'>
                          Place Again <span nz-icon nzType="export" nzTheme="outline"></span>
                        </button>
                                <!--State - SF Fix by Resubmitting -->
                                <span *ngIf="item.errorFix == 1">Auto Resubmitting <span nz-icon [nzType]="'loading'"></span></span>
                                <!--State - Order Not Placed -->
                                <span *ngIf="item.errorFix == 2">Order not Placed</span>
                                <!--State - Wholesale Completed -->
                                <span *ngIf="item.errorFix == 12">Order Submitted</span>

                            </div>
                            <div *ngIf="item.statusId == 6">
                                <span> Order not Inserted </span>
                            </div>
                            <!--Completed-->
                            <span *ngIf="(item.statusId == 5 || item.statusId == 8 ||item.statusId == 9 || item.statusId == 11) && item.errorId !=21"> Order Placed</span>
                            <span *ngIf="item.statusId == 5 && item.errorId == 21"> Order Placed: mapping issue ,PO not inserted</span>
                        </td>
                        <td *ngIf=" authService.hasClaims(['STA']) && item.isReceiveable && this.authService.user.isManagedSF">
                            <button nz-button nzType="default" id="btnreceive" (click)="showOrderModal(item.orderid)">
                    Receive <i nz-icon nzType="code-sandbox" nzTheme="outline"></i>
                  </button>
                        </td>
                        <td>
                            <button nz-button nzType="primary" id="btnview" (click)="viewpurchase(item.orderid)">
                    View<span nz-icon nzType="select" nzTheme="outline"></span>
                  </button>
                        </td>
                    </tr>
                </tbody>
            </nz-table>
            <div *ngIf="checkDisplayLoadMore(tblIndex)" style="text-align: center;">                    
                <button nz-button nzType="primary" (click)="loadMore(tblIndex)" [nzLoading]="isLoadingMoreItems"><span nz-icon nzType="caret-down" nzTheme="outline"></span> LOAD MORE</button>
                
            </div>
        </nz-tab>
    </nz-tabset>
    <!-- </div> -->
</nz-spin>


<nz-modal [(nzVisible)]="isShowingOrderModal" nzTitle="Receive Order" (nzOnCancel)="isShowingOrderModal  = false" [nzFooter]="null" [nzWidth]="'900px'">
    <div *nzModalContent>
        <nz-table #orderdata [nzData]="receivinglines" [nzFrontPagination]="false" [nzShowPagination]="false" [nzBordered]="false" [nzOuterBordered]="false" nzSize="small">
            <thead>
                <tr>
                    <th></th>
                    <th>Description</th>
                    <th>Quantity</th>
                    <th>Price <small class="text-info-style">(ex VAT)</small></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of orderdata.data">
                    <td><img src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{item.brandLogo_url}}" class="brandImage" /></td>
                    <td>{{item.description_Long}}</td>
                    <td>{{item.qty}}</td>
                    <td>
                        <span class="text-info-style">R </span> {{item.price}}
                    </td>
                </tr>
            </tbody>
        </nz-table>
        <br>
        <div nz-row nzJustify="center">
            <div nz-col nzSpan="12" [nzPush]="5">
                <button nz-button nzType="primary" (click)=" receiveStock(receivingOrder.orderid)">Confirm</button>
            </div>
        </div>
    </div>
</nz-modal>