<nz-modal [(nzVisible)]="isVisible" [nzTitle]="title" (nzOnOk)="handleClose()"
  [nzFooter]="footerTemplate" [nzWidth]="800" nzCentered="true" nzCloseIcon="" nzClosable="true">
  <ng-container *nzModalContent>
    <iframe width="100%" height="450" [src]="safeUrl" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </ng-container>
  <ng-template #footerTemplate>
    <button nz-button nzType="primary" (click)="handleClose()">Close</button>
  </ng-template>
</nz-modal>


