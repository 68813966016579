export enum SearchType{
    All = 0,
    TyreSize = 1,
    StockPackage = 2,
    StockCode = 3,
    Brand = 4,
    QuoteRef = 5,    
    Customer = 6,
    Vehicle = 7,
    SaleOrder = 8
}

export class CachedSearchTerm {
    key: string;
    value: string;
    searchType: SearchType;
    rank: number;
    order: number;
}

export class CachedBrand {
    description: string;
    searchCodes: string;
}

export class CachedSearchExtra {
    description: string;
    extraType: number;
}

export class SearchCache {
    searchTerms: CachedSearchTerm[] = [];
    searchBrands: CachedBrand[] = [];
    searchExtras: CachedSearchExtra[] = [];
}

export class SearchAllResult {
    type: number;
    value: string;
}

export class SpecialPriceSet {
    specialPriceSetId: number;
    name: string;
    clientCount?: number;
    authType: number = 0;
    isDoc: boolean = false;
}