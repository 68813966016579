export class UserClaim {
  claimId: number
  code: string
  name: string
}

export class User {
  client_id: number
  email: string
  user_id: number
  username: string
  fullname: string
  sub_rel: number[]
  exp: number
  iat: number
  iss: string
  nbf: number
  claims: string[]
  groupId: number
  client_name: string
  linked: boolean
  bookingsEnabled: boolean
  isSuperUser: boolean
  suId: number
  isPremium: boolean
  isAPI: boolean
  featureLevel: number
  isManagedSF:boolean
  franchiseId:number
  licenseId: number
  waEnabled: boolean
}

export class AuthResult {
  access_token: string;
  token_type: string
}

export class SystemUser {
  userId: number;
  userName: string;
  email: string;
  fullname: string;

  extPosId: string
  roles: UserRole[] = [];
}

export class AdminSystemUser extends SystemUser {
  superUser: string;
  cell: string;
  password: string;
}

export class UpdateUser {
  fullName: string;
  extPosId: string;
}

export class UserRole {  
  roleId: number;
  roleName: string;
  roleDescription: string;
  isSelected: boolean;
}

export class UserAddModel {
  userName: string
  email: string
  cell: string
  password: string
  fullname: string
}

export class LinkedAccount {
  userId: number
  clientId: number
  clientName: string
  password: string
  isSuperUser: boolean
  searchCount: number
  salesCount: number
}

export class  Salesman{
  userId:number
  fullname: string
  email:string
  cell:string
  rank:number
}
