import { mslBrand } from "./brandStrat"


export class Stock {
  description: string
  stock_code: string
  msfid: number
  locationId: number
  locationName: string
  brand: string
  gen_code: string
  mapped?:number = 0
  showUnmap?:boolean = false;
  searchedGenCode?:string;
  isTradeIn?:boolean = false;
}

export class MappedStock {
  mappingId?: number
  msfid?: number
  officialStock?: boolean
  locationStockCode?: string
  locationStockDescription?: string
  msfStockCode?: string
  msfDescription?: string
  mappingTypeId?: number
  locationId?: number
  dt?: string
  isTradeIn?:boolean  = false;
}

export class TabObject{
  id: number = 0;
  name: string = "";
  description:string = "";
}

export class MappedItems {
    stock: MappedStock[] = [];
    totalMappedToday:number = 0;
}

export class StockData  {
  overindex: number
  clientId: number
  description: string
  plinternal: string
  deflt: string
  wholesale: string
  plretail: string
  plonline:string
  plclear: string
  plcustom1:string
  plcustom2:string
  plcustom3: string
  plcustom4:string 
  stock_code:string
}

export class StockInfo {
  msfid: number
  stockcode: string
  description: string
  gencode: string
  brand:string
  activeId: number
  stockType: string
}

export class ModelStock{
    modelId : number
    supplierId : number
    supplier_name : string
    replenish_active: boolean
    description: string
    code : string
    manufacturers_code: string
    msfId : number
    brand: string
    brandLogo: string 
    msl : number
    soh : number
    back_order_qty: number
    sohSupplier: number
    supplierPrice: number
    diff : number
    work_in_progress: number
    live: boolean
    supplier_live: boolean
    gen_code: string
    searches: number
    sales: number
    stock_type: string
    holding_status: number
    sales_tier: string
    lastCarry_dt: string 
    suggested_msl:number
    ms_ai_reasonId:number
}


export class Stock_AI_Reasons {
  ms_ai_reasonId:number
  description:string
}

export class supplierInfo{
  supplier_name : string
  supplierId : number
  debtorAcc : string
  minOrderFee: number
  sohMax: number
  repDiscount: number
  repActive: boolean
  type: string
  sell_state: string
}

export class Order_summary{
  total: number
  line_items: number
  line_qtys: number
  supplierId: number
  supplier_name: string
  order_type: string
  replenish_active: boolean

  orderMain: stockCost[]
  orderPartialFills: stockCost[]
  orderDisabledItems: stockCost[]
  orderHoldingItems: stockCost[]
  orderAdditionalItems: stockCost[]
}

export class ModelStockCollection{
  all_items: ModelStock[]
  itemsOutOfStockAtSupplier: ModelStock[]
  holdingItems: ModelStock[]
  disabledItems: ModelStock[]
  replenishableItems: ModelStock[]
  escalationItems: ModelStock[]
  attentionHeadOffice: ModelStock[]
  stocklist: ModelStock[]
  suppliers: supplierInfo[]
  BulkOrders: Order_summary[]
  
}

export class supplier_summary{
  supplierId: number
  supplier_name: string
  order_cost: number
  total_items: number
  replenish_active: boolean
  MOF: number
  balance: stockCost[]

}

export class supplierInvoices{
  msfid: number
  price: number

}

export class PrevOrders{
    orderRef: string
    sellerName: string
    qty: number
    dt: string
}

export class PrevSales{
  orderRef: string
  buyerName: string
  qty: number
  dt: string
}

export class stockCost{
  description: string
  code: string
  brand: string
  brandLogo: string
  msl: number
  soh: number
  back_order_qty: number
  qty: number
  msfid: number
  price: number
  sellerId: number
  sohSupplier: number
  stock_type: string
  holding_status: number
  similarity: boolean
  lastOrder: string
  lastRef: string
  previousOrders: PrevOrders[]
  previousSales: PrevSales[]
  
}

export class ModelStockSizes{
  stockReplenishmentId: number
  size: string
  sales: number
  sales_rank_all: number
  sales_rank_client: number
  sales_share: number
  search_rank_all: number
  soh: number
  dos: number
  modelStock: number
  suggested_modelStock: number
  four_week_demand: number
  sales_tier: string
  lines: Array<any> = []
  alerts: Array<any> = []
  ms_ai_reasonId:number
  description: string
  code : string
  msfid : number
  brand: string
  brandLogo: string 
  msl : number
  gen_code:number
}

export class ModelStock_def{
    modelStock: number
    suggested_modelStock: number
    soh: number
    sales: number
    daysOfStock: number
    daysOfData: number
    sizes: ModelStockSizes[] 
    Reasons:Stock_AI_Reasons[] = [];
    TyreLines:ModelStockTyres[] = [];
}

export class ModelStockTyres {
    stockReplenishmentId: number
    msfid: number
    size: string
    description: string
    brand: string
    brandLogo: string 
    brand_rank: number
    stock_code: string
    modelStock: number
    suggested_modelStock: number
    sales: number
    national_rank: number
    best_cost: number
    buy_all_in: number 
    best_supplier: string
    listed_supplier: string
    supplier_soh: number
    supplier_sohMax: number
    soh: number
    back_order_qty: number
    replenish_active: boolean
    holding_status: number
    days_of_stock: number
    sales_tier: string
    ms_ai_reasonId:number
    ms_ai_reason:string
    rankId:number

    
}



export class Model_Suggestions{
    gen_code: string
    searches: number
    sales: number
    rank: number
}

export class salesAutoMAP {
  msfid:number
  stockcode:string
  clientId:number
  userId:number
  mappingTypeId:number
  orderId:number
}

export class wipLine {
  msfid:number
  quoteId:number
  qty:number
}

export class MonthSalesTrendValue {
  month: number
  year: number
  qty: number
  count: number
}

export class MonthPurchasesTrendValue {
  month: number
  year: number
  qty: number
  count: number
}

export class SixMonthSalesTrend {
    description: string
    classification: string
    logo: string
    sales: MonthSalesTrendValue[] = []
}

export class rfc_summary{
    description: string
    code: string
    msfid: number
    soh: number
    price: number
    supllierId: number
    supplier_name: string
    lastSale: number
    lastPurchase: number
    msl: number
    classification: string
    logo: string
    lastSold: string
    lastSale_status: string
    lastPurchased: string
    lastPurchase_status: string
    sales: MonthSalesTrendValue[] = [] 
    purchases: MonthPurchasesTrendValue[] = [] 
}

export class item_purchaseData{
    orderId: number
    orderRef: string
    dt: string 
    msfid: number
    sellerId: number 
    buyerId: number
    customerId: number 
    seller_name: number
}

export class item_saleData{
    orderId: number
    orderRef: string
    dt: string 
    msfid: number
    sellerId: number
    buyerId: number
    customerId: number 
    customer_name: string 
    buyerName: string 
}

export class surplusStock{
    stockReplenishmentId: number
    msfid: number
    code: string
    description: string
    soh: number
    price: number
    supplierId: number
    supplier_name: string
    lastSale: string
    msl:number
    logo: string
    lastSold: string
    lastPurchased: string
    sales: MonthSalesTrendValue[] = []
    purchases: MonthSalesTrendValue[] = []  
}

export class GenCode_Storage{
  size: string
  dt: string
  userId: number
}


export class  filteredStockItem
{
  key: number
  name: string
}

export class gen_code {
    gen_code:string = ""
    width:string = ""
    profile:string = ""
    rim:string = ""
}

export class NmsfMetaHolder {
  widths:string[] =  [];
  profiles:string[] =  [];
  rims:string[] =  [];
  speedRatings:string[] =  [];
  loadRatings:string[] =  [];
  brands:mslBrand[] =  [];
  markets:string[] =  [];
  runFlat:string[] =  [];
  extraLoad:string[] =  [];
  genCodes:gen_code[] = [];
}

export class NmsfItem {
  msfid:number;
  name:string;
  descriptionLong: string;
  width:number;
  profile:number;
  rim:number;
  speedRating:string;
  loadRating:string;
  brandId:number;
  brand:string;
  brandLogo_url:string;
  runflat:boolean | null;
  xl:boolean | null;
  market:string;
  pattern:string;
  stockCode:string;
  clientId:number;
  stockCodeToMap:string;
  locationIdToMap:number;
  descriptionToMap:string;
}

export class NMSF_Add_Response {
  status:number;
  message:string;
  data:NmsfItem[] = [];
}
