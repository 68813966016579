import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Quote } from 'src/app/classes/quote';
import { PosService } from '../pos.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { PosAuditEntry, PosAuditEntryType, PosSearchCustomer, StoredCustomer } from 'src/app/models/pos';
import { SearchService } from 'src/app/search/search.service';
import { PosAuditService } from '../posaudit.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SearchType } from 'src/app/models/search';

@Component({
  selector: 'app-pos-customer-details',
  templateUrl: './pos-customer-details.component.html',
  styleUrls: ['./pos-customer-details.component.less']
})
export class PosCustomerDetailsComponent implements OnInit {

  @Input() quote : Quote = new Quote();
  @Output() quoteChange = new EventEmitter<Quote>(); //this is not used at the moment... but if needs be, this can emit to update the quote upwards

  public _isLoading: boolean;
  @Input() set isLoading(value: boolean) {
    this._isLoading = value;
    if (value===false) {
      this.updateCustomerForm();
    }
  };

  isCustomerSearchDropDownOpen = false;

  isAddingCustomer = false; //used to indicate if we are adding a blank customer, for the ADD CUSTOMER button
  
  filteredCustomers: StoredCustomer[] = [];

  searchedCustomerCell: string = "";
  searchedCustomerName: string = "";

  addCustomerForm: FormGroup = new FormGroup({
    custName: new FormControl({value: "", disabled: !this.quote.hasCustomer} ),
    custSurname: new FormControl({value: "", disabled: !this.quote.hasCustomer}),
    custCell: new FormControl({value: "", disabled: !this.quote.hasCustomer}, Validators.compose([Validators.pattern('^[+0-9]+$'), Validators.minLength(10), Validators.maxLength(13) , Validators.required])),
    custEmail: new FormControl({value: "", disabled: !this.quote.hasCustomer}, [Validators.email]),
    custVATNumber: new FormControl({value: "", disabled: !this.quote.hasCustomer}),
    custDebtorAccount: new FormControl({value: "", disabled: !this.quote.hasCustomer})
  });


  constructor(private posService: PosService, private notification: NotificationService, private searchService: SearchService, private auditService: PosAuditService) { }

  ngOnInit(): void {

  }

  onSearchCustomer(value: string) {
    //set the filters, ready for addition of we click add customer
    if (!value) {
      this.searchedCustomerName = "";
      this.searchedCustomerCell = "";
      return;
    }
    if (isNaN(+value)){
      this.searchedCustomerName = value;
      this.searchedCustomerCell = "";
    }
    else {
      this.searchedCustomerName = "";
      this.searchedCustomerCell = value;
    }

    if (value && value.length > 3) {
      //filter the array
      //this.filteredCustomers = this.customers.filter(e => e.customer.includes(value.toUpperCase()));
      //this.filteredCustomers = this.searchService.ClientCustomers.filter(e => e["name"].includes(value.toUpperCase()));
      this.filteredCustomers = this.searchService.filteredCachedSearchTerms([SearchType.Customer]).filter(e => e.value.includes(value.toUpperCase())).map(e => {return {name: e.value, key: +e.key}});
    } 
    else {
      //clear the filter array
      this.filteredCustomers = [];
    }
    
  }

  updateCustomerVehicles() {
    //if customer is zero (unlinked), clear the vehicles
    if (this.quote.custId === 0) {
      this.quote.customerVehicles = [];
      this.quote.Vehicle_Customer_MappingId = 0;
      this.isLoading = true;
      this.isLoading = false;
    }
    else {
      this.posService.getCustomerVehicles(this.quote.custId).subscribe({
        next: val => {
          this.quote.customerVehicles = val;
        },
        error: err => {
          this.notification.handleError(err);
        }
      })
    }
  }

  selectedCustomer?: PosSearchCustomer;
  onSelectCustomer(value : any) {
    this._isLoading = true;
    this.posService.selectCustomerToQuote(this.quote.quoteId, value).subscribe({
      next: val => {
        //update quote
        this.quote.custId = val.customerId;
        this.quote.custName = val.name;
        this.quote.custCell = val.cell;
        this.quote.custEmail = val.email;
        this.quote.custVATNumber = val.custVATNumber;
        this.quote.custDebtorAccount = val.custDebtorAccount;
        this.auditService.addAction(new PosAuditEntry(this.quote.quoteId, PosAuditEntryType.SalesmanSelectCustomer, val.customerId, val.name + ':' + val.cell));
        this.quote.markActionLog();
        this.updateCustomerForm();
        this.selectedCustomer = undefined;
        this._isLoading = false;
        this.updateCustomerVehicles();
        this.quote.markDirty();
      },
      error: err => {
        this.notification.handleError(err);
        this._isLoading = false;
      }
    })
    
  }

  updateCustomerForm() {
    if (this.quote.hasCustomer) {
      //enable form
      this.addCustomerForm.controls.custName.enable();
      this.addCustomerForm.controls.custCell.enable();
      this.addCustomerForm.controls.custEmail.enable();
      this.addCustomerForm.controls.custSurname.enable();
      this.addCustomerForm.controls.custVATNumber.enable();
      this.addCustomerForm.controls.custDebtorAccount.enable();
      //set values
      this.addCustomerForm.controls.custCell.setValue(this.quote.custCell);
      this.addCustomerForm.controls.custEmail.setValue(this.quote.custEmail);
      if (this.quote.custName.indexOf(' ') > 0) {
        this.addCustomerForm.controls.custName.setValue(this.quote.custName.substring(0, this.quote.custName.indexOf(' ')));
        this.addCustomerForm.controls.custSurname.setValue(this.quote.custName.substring(this.quote.custName.indexOf(' ') + 1));
      }
      else {
        this.addCustomerForm.controls.custName.setValue(this.quote.custName);
        this.addCustomerForm.controls.custSurname.setValue("");
      }
      this.addCustomerForm.controls.custVATNumber.setValue(this.quote.custVATNumber);
      this.addCustomerForm.controls.custDebtorAccount.setValue(this.quote.custDebtorAccount);
      this.isCustomerSearchDropDownOpen = false;
    }
    else {
      
      //disable form
      this.addCustomerForm.controls.custName.disable();
      this.addCustomerForm.controls.custCell.disable();
      this.addCustomerForm.controls.custEmail.disable();
      this.addCustomerForm.controls.custSurname.disable();
      this.addCustomerForm.controls.custVATNumber.disable();
      this.addCustomerForm.controls.custDebtorAccount.disable();
      //set values
      this.addCustomerForm.controls.custCell.setValue("");
      this.addCustomerForm.controls.custEmail.setValue("");
      
      this.addCustomerForm.controls.custName.setValue("");
      this.addCustomerForm.controls.custSurname.setValue("");
      this.addCustomerForm.controls.custVATNumber.setValue("");
      this.addCustomerForm.controls.custDebtorAccount.setValue("");
      
    }
    
    //if the quote is completed, the values would be loaded, but now disable the form
    if (this.quote.isCompleted) {
      this.addCustomerForm.disable();
    }

  }

  updateCustomer() {
    Object.values(this.addCustomerForm.controls).forEach(control => {
      if (control.invalid)
      {
        control.markAsDirty();
        control.updateValueAndValidity({ onlySelf: true });
      }
    });
    if (this.addCustomerForm.valid !== true)
    {
      return;
    }
    this.quote.custCell = this.addCustomerForm.value.custCell;
    let name = "";
    if (this.addCustomerForm.value.custSurname && this.addCustomerForm.value.custSurname.length > 0) {
      name = this.addCustomerForm.value.custName + ' ' + this.addCustomerForm.value.custSurname;
    }
    else {
      name = this.addCustomerForm.value.custName;
    }
    this.quote.custName = name;
    this.quote.custCell = this.addCustomerForm.value.custCell;
    this.quote.custEmail = this.addCustomerForm.value.custEmail;
    this.quote.custVATNumber = this.addCustomerForm.value.custVATNumber;
    this.quote.custDebtorAccount = this.addCustomerForm.value.custDebtorAccount;

    this.quote.markDirty();


  }

  
  addNewCustomer() {
    this.isAddingCustomer = true;
    this.posService.addNewCustomerForQuote(this.quote.quoteId, {cell: this.searchedCustomerCell, name: this.searchedCustomerName}).subscribe({
      next: val => {
        //update quote
        this.quote.custId = val.customerId;
        this.quote.custName = val.name;
        this.quote.custCell = val.cell;
        this.quote.custEmail = val.email;
        this.quote.custVATNumber = val.custVATNumber;
        this.quote.custDebtorAccount = val.custDebtorAccount;
        this.auditService.addAction(new PosAuditEntry(this.quote.quoteId, PosAuditEntryType.SalesmanAddCustomer, val.customerId, val.name + ':' + val.cell));
        this.quote.markActionLog();
        this.updateCustomerForm();
        this.selectedCustomer = undefined;
        this.isAddingCustomer = false;
        this.updateCustomerVehicles();
      },
      error: err => {
        this.notification.handleError(err);
        this.isAddingCustomer = false;
      }
    })
  }
  

}
