<br>
<div class="text-center not-mobile" style="position: absolute; top:35%">
    <br>
    <h5 class="text-center">Please rotate your phone to view the Order</h5>
    <span nz-icon nzType="rotate-left" nzTheme="outline" class="text-center"></span>
</div>

<nz-spin [nzSpinning]="isLoading" class="mobile">
    <button nz-button nzType="primary" (click)="goBack()">
        <span nz-icon nzType="left" nzTheme="outline" style="font-size: 12px !important;"></span>
            Back
    </button>
    <p></p>
    <nz-card nzHoverable>
        <div nz-row [nzJustify]="'center'">
            <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="8" nzXl="8" style="text-align: center;">
                <h4>Customer</h4>
                <p></p>
                <p>{{ Sale.customername }}</p>
                <p>{{ Sale.customercell}}</p>
            </div>
            <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="8" nzXl="8" style="text-align: center;">
                <h4>Order </h4>
                <p class="font-weight-bold">{{datePipe.transform(this.Sale.dt, 'mediumDate')}}</p>
                <br><br>
                <nz-steps>
                    <nz-step nzTitle="Created" nzStatus="finish" [nzIcon]="'check-circle'" nzDescription="{{Sale.dt | date:'HH:mm:ss'}}"></nz-step>
                    <nz-step nzTitle="Inserted" [nzStatus]="Sale.dt_so != null ? 'finish' : 'process'" [nzIcon]="Sale.dt_so ? 'check-circle' : 'close-circle'" nzDescription="{{Sale.dt_so | date:'HH:mm:ss'}}"></nz-step>
                    <nz-step nzTitle="Emailed" [nzStatus]="Sale.dt_email != null ? 'finish' : 'process'" [nzIcon]="Sale.dt_email ? 'check-circle' : 'close-circle'" nzDescription="{{Sale.dt_email | date:'HH:mm:ss' }}"></nz-step>
                </nz-steps>
            </div>
            <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="8" nzXl="8" style="text-align: center;">
                <h4>References </h4>
                <div nz-row [nzJustify]="'center'">
                    <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
                        <p>
                            StockFinder <br>
                            <span class="font-weight-bold text">  {{ Sale.SFRef}} </span>
                        </p>
                    </div>
                    <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12" *ngIf="Sale.SysRef != null">
                        <p>
                            {{Sale.pos}} <br>
                            <span class="font-weight-bold text">{{ Sale.SysRef}}</span>
                        </p>
                    </div>
                    <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12" *ngIf="Sale.PoRef!= null">
                        <p>
                            Purchase Order <br>
                            <span class="font-weight-bold text">{{ Sale.PoRef}} </span>
                        </p>
                    </div>
                    <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12" *ngIf="Sale.showERef != null && Sale.showERef == true">
                        <p>
                            External <br>
                            <span class="font-weight-bold text">{{ Sale.extRef }}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <hr style="background-color: var(--textcolor);">
        <div nz-row [nzJustify]="'center'">
            <div nz-col nzXs="8" nzSm="8" nzMd="8" nzLg="8" nzXl="8">
                <div *ngIf="Sale.username != null">
                    <h4>Salesman</h4>
                    <p>
                        {{ Sale.username }}
                        <span *ngIf="Sale.useremail">| {{ Sale.useremail }} </span>
                        <span *ngIf="Sale.cell">| {{Sale.cell}}</span></p>
                </div>
            </div>
            <div nz-col nzXs="8" nzSm="8" nzMd="8" nzLg="8" nzXl="8" class="text-center">
                <div *ngIf="Sale.showVmake== true || Sale.showVreg== true || Sale.showMilage== true">
                    <h5>Vehicle Details</h5>
                    <ng-template #prefixCar>
                        <span nz-icon nzType="car" nzTheme="outline"></span>
                    </ng-template>
                    <div nz-row>
                        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
                            <div nz-row [nzJustify]="'center'">
                                <div nz-col nzXs="24" nzSm="24" nzMd="8" nzLg="8" nzXl="8" *ngIf="Sale.showVmake != null && Sale.showVmake== true">
                                    <i nz-icon nzType="car" nzTheme="outline" style="color: var(--textcolor);"></i> {{ Sale.v_make }}
                                </div>
                                <div nz-col nzXs="24" nzSm="24" nzMd="8" nzLg="8" nzXl="8" *ngIf="Sale.showVreg != null && Sale.showVreg == true">
                                    <i nz-icon nzType="idcard" nzTheme="outline" style="color: var(--textcolor);"></i> {{ Sale.v_reg }}
                                </div>
                                <div nz-col nzXs="24" nzSm="24" nzMd="8" nzLg="8" nzXl="8" *ngIf="Sale.showMilage != null && Sale.showMilage == true">
                                    {{ Sale.milage}} <span style="color: var(--textcolor);">km</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div nz-col nzXs="8" nzSm="8" nzMd="8" nzLg="8" nzXl="8" class="text-center">
                <div *ngIf="this.isEmail">
                    <h5>Order Reference</h5>
                    <form nz-form [formGroup]="this.addReferanceForm">
                        <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: 100%;">
                            <nz-form-item>
                                <nz-form-control [nzErrorTip]="'Order Reference Required'">
                                    <div class="input-group maindiv">
                                        <input nz-input name="purchaseRef" formControlName="purchaseRef" placeholder="Reference">
                                    </div>
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item>
                                <div class="input-group-append">
                                    <button nz-button nzType="primary" (click)="saveRef()">
                                        Save Reference
                                        </button>
                                </div>
                            </nz-form-item>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <br>
        <nz-table #lines [nzData]="Salelines" [nzFrontPagination]="false" style="overflow-x:auto;">
            <thead>
                <tr>
                    <th>Brand</th>
                    <th>Description</th>
                    <th>Code</th>
                    <th>Quantity</th>
                    <th>Price <span class="text-style">(excl)</span></th>
                    <th>Total <span class="text-style">(excl)</span></th>
                </tr>
            </thead>
            <tbody class="tbody">
                <tr *ngFor="let item of lines.data">
                    <td>
                        <img style='border-radius:10px' height='25' width='50' src='https://sftptyres.blob.core.windows.net/brands-images/brands/{{ item.brandLogo_url }}'>
                    </td>
                    <td>
                        {{item.description_Long}}
                    </td>
                    <td>
                        {{item.code}}
                    </td>
                    <td>
                        {{item.qty}}
                    </td>
                    <td>
                        {{item.price}}
                    </td>
                    <td>
                        {{item.price * item.qty }}
                    </td>
                </tr>
            </tbody>
        </nz-table>
        <div nz-row [nzJustify]="'start'">
            <div nz-col nzXs="24" nzSm="24" nzMd="8" nzLg="8" nzXl="8" class="text-center">
                <button *ngIf="this.hasquote" nz-button nzType="primary" (click)="viewquote()">
                    <i nz-icon nzType="export" nzTheme="outline"></i>
                    View Quote 
                </button>
                <button *ngIf="this.authService.user.featureLevel >= 3" nz-button nzType="primary" style="border:none; margin-left: 15px;" nz-tooltip nzTooltipPlacement='top' nzTooltipTitle='Print Invoice' (click)="communicateInvoice()">
                    <span nz-icon nzType="credit-card" nzTheme="outline" style="margin-right: 5px;"></span> Invoice
                </button>
                <button *ngIf="this.hasquote" nz-button nzType="primary" style="border:none; margin-left: 15px;" (click)="quoteagain()" [disabled]="isLoading">
                    <span nz-icon nzType="redo" nzTheme="outline"></span>
                    Quote Again            
                </button>
                <button *ngIf="this.hasquote" nz-button nzType="primary" style="border:none; margin-left: 15px;" (click)="AutoQuote()" [disabled]="isLoading">
                    <span nz-icon nzType="import" nzTheme="outline"></span>
                    Quick Quote
                </button>
            </div>
            <div nz-col nzXs="24" nzSm="24" nzMd="8" nzLg="8" nzXl="8" class="text-center">
                <div *ngIf="Sale.comment != null && Sale.comment != ''">
                    <br>
                    <h5>Comment</h5>
                    <p>{{ Sale.comment }}</p>
                </div>
            </div>
            <div nz-col nzXs="24" nzSm="24" nzMd="8" nzLg="8" nzXl="8" class="text-center">

                <div *ngIf="Sale.showDelivery == true">
                    <h5>Delivery Address</h5>
                    <p> {{Sale.delivery}} </p>
                </div>


                <h4>Total : <span class="text-info-style">R </span>{{ Sale.price | number }}</h4>
            </div>
        </div>
    </nz-card>
</nz-spin>