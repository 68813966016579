<div nz-row nzJustify="center">
    <div nz-col nzSpan="22" class="text-center bg-1 ">
        <div nz-row nzJustify="center">
            <div nz-col nzSpan="22" class="text-center bg-1 ">
                <h4>Help Videos</h4>
            </div>
        </div>

        <div nz-row [nzJustify]="'center'" class="row" style="padding-left: 5%;padding-right: 4%;" [nzGutter]="15">
            <!--All Cards-->
            <div nz-col [nzSm]="24" [nzXs]="24" [nzMd]="24" [nzLg]="6" [nzXl]="6" [nzXXl]="6" *ngFor="let video of this.Videos">
                <nz-card nzHoverable style="margin-bottom: 10px;" [nzLoading]="isloading">
                    <h6>{{video.title}}</h6>
                    <hr>
                    <br>
                    <span nz-icon nzType="youtube" nzTheme="outline" style="font-size: 30px !important;"></span>
                    <!-- <iframe width="250" height="250" style="border-radius: 10px;" [src]="getSafeVideoUrl(video.url)" [frameBorder]="0" allow="accelerometer; autoplay;  encrypted-media; gyroscope; picture-in-picture;" allowfullscreen></iframe> -->
                    <br><br>
                    <div nz-row nzJustify="center" nzGutter="15">
                        <div nz-col nzSpan="12" *ngIf="video.docname">
                            <button nz-button nzType="primary">
                                PDF
                                <span nz-icon nzType="download" (click)="downloadPdf(video)"></span>
                            </button>
                        </div>
                        <div nz-col nzSpan="12">
                            <button nz-button nzType="primary" (click)="watch(video)">
                                Watch
                                <span  nz-icon nzType="eye"></span>
                            </button>
                        </div>
                    </div>
                </nz-card>
            </div>
        </div>
        <p style="text-align: center;">
            <br> For further assistance please contact our Customer Support Centre (<span class="text-style">066 453 2826</span>)
        </p>

    </div>
</div>