<div *ngIf="found==0" class="container-fluid col-md-10 col-offset-md-1 bg-1" id="errorDiv">
    <h1>We could not find any tyres that matched you search. <a href="#" onclick="show('help')">Click here</a> for more information on how to structure your search. </h1>
</div>

<!-- <div class="container-fluid bg-1" id ="excelDiv" *ngIf="toggles[excelDiv]">
  <h1>Upload From Excel</h1>
    <p>You can edit existing stock or upload new stock by uploading the excel template. Download the template from the below table.</p>
  <form class="form-horizontal" action="/nmsf2" method="POST" enctype="multipart/form-data" id="excelForm">

    <div class="form-group">
        <input nz-input type="file" accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" name="file" >
    </div>
    <input nz-input type="hidden" name="key" value="excel">
    <button nz-button nzType="primary" type="submit" class="btn btn-success btn-block" id="excelBtn" onclick="excelForm()">Update</button>
  </form>
</div> -->
<div class="container-fluid bg-1" id="excelDiv" *ngIf="toggles.excelDiv">
    <form [formGroup]="fileUploadForm" (ngSubmit)="onFormSubmit()" enctype="multipart/form-data">
        <div class="container py-5">
            <!-- For demo purpose -->
            <header class="text-white text-center">
                <h4 style="color: white;">Upload from excel</h4>
                <img src="assets/Images/excel.png" alt="" width="150" class="mb-4">
            </header>
            <div class="row py-4">
                <div class="col-lg-6 mx-auto text-center">
                    <!-- Upload image input-->
                    <div class="input-group mb-3 px-2 py-2 rounded-pill shadow-sm">
                        <input nz-input #UploadFileInput name="formFile" formControlName="formFile" id="customFile" type="file" (change)="onFileSelect($event)">
                        <label id="customFile-label" for="customFile" class="font-weight-light">{{fileInputLabel}}</label>
                        <div class="input-group-append">
                            <label for="customFile" class="btn btn-light m-0 rounded-pill px-4"> 
                          <i nz-icon nzType="upload" nzTheme="outline" class=" mr-2"></i>
                          <small class="text-uppercase font-weight-bold">
                              Choose file
                          </small>
                      </label>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col-lg-12 text-center">
                            <br>
                            <button nz-button nzType="primary" type="submit">Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>


<div class="container-fluid bg-1" id="ful" *ngIf="toggles.ful">
    <nz-table #Failed [nzData]="Failed_Data" [nzFrontPagination]="false">
        <thead>
            <tr>
                <th>CODE</th>
                <th>DESCRIPTION</th>
                <th>ERROR LOCATION(S)</th>
                <th>DT</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let i of Failed.data">
                <td>{{ i[1] }}</td>
                <td>{{ i[0] }}</td>
                <td>{{ i[2] }}</td>
                <td>{{ i[3] }}</td>
            </tr>


        </tbody>
    </nz-table>
</div>

<div class="container-fluid bg-1 col-md-10 col-offset-md-1" id="help" *ngIf="toggles.help">
    <h1 style="color: white;">How to use the search bar</h1>
    <p>You can search based on any of the bellow characteristics;
        <ul>
            <li>Size (2656517 or 265/60R18 or 7.5r16 or 80/100-12</li>
            <li>Code (543796)</li>
            <li>Width (195)</li>
            <li>Rim (15)</li>
            <li>Spead Rating (H)</li>
            <li>Load Index (91)</li>
            <li>XL (XL)</li>
            <li>RF (RF, runflat)</li>
            <li>Brand (GoodYear,gy,sava</li>
            <li>OE (moe,ao)</li>
            <li>Market (passenger,commercial,industrial,agriccultural)</li>
            <li>Construction (radial,bias)</li>
            <li>Application (steer,drive,trailer ect)</li>
        </ul>
        To search for more than one characteristic just add it into the search and separate it with a space. For example, 1956515 TO. This will return tyres of size 1956515 that are Toyota OE.
        <!-- </p> -->
</div>


<div class="container-fluid bg-1">
    <br>
    <div class="row">
        <div class="col-md-6">
            <h3>
                <button nz-button nzType="primary" (click)="show('excelDiv')">Upload New Stock</button>
                <button nz-button nzType="primary" (click)="show('ful')">Failed Upload Log</button>
                <!-- <button nz-button nzType="primary">Full NMSF</button>  -->
            </h3>
        </div>

        <div class="col-md-6">
            <div class="input-group mb-3">
                <input nz-input (keyup.enter)="onSelect($event)" id="search" placeholder="Search">
                <div class="input-group-append">
                    <button nz-button nzType="primary" nz-button (click)="show('help')">help</button>
                </div>
            </div>
        </div>
    </div>

    <!--table for download -->
    <nz-spin [nzSpinning]="searching">
        <nz-table #MSF [nzData]="MSF_Data" [nzFrontPagination]="false" id="tbl">
            <thead>
                <tr>
                    <th>DESL</th>
                    <th>CODE</th>
                    <th>W</th>
                    <th>P</th>
                    <th>R</th>
                    <th>B</th>
                    <th>SR</th>
                    <th>LR</th>
                    <th>OE</th>
                    <th>RF</th>
                    <th>RETAIL</th>
                    <th>PR</th>
                    <th>XL</th>
                    <th>CONST</th>
                    <th>MASS</th>
                    <th>CBM</th>
                    <th>MARKET</th>
                    <th>SECTOR(NT)</th>
                    <th>SECTOR(M)</th>
                    <th>APPLICATION</th>
                    <th>COM</th>
                    <th>SPECIAL</th>
                    <th>HOMOID</th>

                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let i of MSF.data">
                    <td> {{ i['description'] }}</td>
                    <td> {{ i['code'] }}</td>
                    <td> {{ i['width'] }}</td>
                    <td> {{ i['profile'] }}</td>
                    <td> {{ i['rim'] }}</td>
                    <td> {{ i['brand'] }}</td>
                    <td> {{ i['speed_rating'] }}</td>
                    <td> {{ i['load_rating'] }}</td>
                    <td> {{ i['oe'] }}</td>
                    <td> {{ i['runflat'] }}</td>
                    <td> {{ i['retail'] }}</td>
                    <td> {{ i['ply_rating'] }}</td>
                    <td> {{ i['xl'] }}</td>
                    <td> {{ i['construction'] }}</td>
                    <td> {{ i['mass'] }}</td>
                    <td> {{ i['cbm'] }}</td>
                    <td> {{ i['market'] }}</td>
                    <td> {{ i['sectorNT'] }}</td>
                    <td> {{ i['sectorM'] }}</td>
                    <td> {{ i['application'] }}</td>
                    <td> {{ i['commercial'] }}</td>
                    <td></td>
                    <td></td>
                </tr>

            </tbody>
        </nz-table>
    </nz-spin>

</div>

<!-- <script>
  $('#download').dataTable( {
    paging: false, 
    info: false,
    searching:false,
    ordering:false,
    dom: 'Bfrtip',
        buttons: [
            'excel', 'pdf', 'print'
        ]
  } );
  
  $('#downloadF').dataTable( {
    paging: false, 
    info: false,
    searching:false,
    ordering:false,
    dom: 'Bfrtip',
        buttons: [
            'excel', 'pdf'
        ]
  } );
  
  function show(id) {
  $('#'+id).toggle('slow');
  }

  function excelForm() {
    $('#excelBtn').attr("disabled", true);
    $('#excelForm').submit();
  }

</script> -->