import { CustomerVehicle, NewPosCustomer, PosCustomer, PosQuote, QuoteState, StockSearchResult, VehicleReg , QuoteStatus, PosActionLogEntry, PosQuoteLine, BookingSlot, CustomerDebtorAcc, PosSearchCustomer,customStockItem, stockType, wbcQuote, QuoteVehicles, VehicleMake, VehicleModel, VehicleVariant } from '../models/pos';
import { environment } from 'src/environments/environment';
import { AddSale, AddSaleLine } from '../models/sale';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { AuthService } from '../auth.service';
import { formatDate } from '@angular/common';
import { map, tap } from 'rxjs/operators';
import { SearchService } from '../search/search.service';
import { Vehicle } from '../models/vehicles';
import { Quote, QuoteLine, CustomerVehicleDetails, QuoteLineAdded } from '../classes/quote';
import { Observable } from 'rxjs';
import { CustomStock } from '../models/pricelist';
import { Salesman } from '../models/user';


@Injectable({
  providedIn: 'root'
})

export class PosService {

  apiUrl: string = "v1/pos/";

  constructor(
    private http: HttpClient,
    private authService:AuthService,
    private searchService: SearchService,
    @Inject(LOCALE_ID) public locale: string) { }

  getQuoteDetails(id: number) {
    return this.http.get<PosQuote>(environment.ApiUrl() + this.apiUrl + "quote/" + id.toString())
  }

  getCustomers() {
    return this.http.get<PosSearchCustomer[]>(environment.ApiUrl() + this.apiUrl + "customer")
  }

  searchStock(stockCode: string) {
    return this.http.get<StockSearchResult[]>(environment.ApiUrl() + this.apiUrl + "searchstock/" + stockCode)
  }

  getLinkedVehicleReg(quoteId: number) {
    return this.http.get<VehicleReg>(environment.ApiUrl() + this.apiUrl + "quote/" + quoteId + "/vehicleReg")
  }

  getLinkeddebtorAcc(quoteId:number,customerId: number) {
    return this.http.get<CustomerDebtorAcc>(environment.ApiUrl() + this.apiUrl + "quote/" + quoteId + "/debtorAcc/" + customerId)
  }

  getCustomerVehicleReg(customerId: number) {
    return this.http.get<VehicleReg[]>(environment.ApiUrl() + this.apiUrl + "vehicleReg/" + customerId)
  }
  
  getCustomerDebtorAcc(customerId: number) {
    return this.http.get<CustomerDebtorAcc[]>(environment.ApiUrl() + this.apiUrl + "debtorAcc/" + customerId)
  }

  linkCustomerToQuote(quoteId: number, customerId: number) {
    return this.http.post<{customerId: number, name: string, email: string, cell: string}>(environment.ApiUrl() + this.apiUrl + "quote/" + quoteId + "/linkCustomer/" + customerId, null)
  }

  addCustomer(cust: NewPosCustomer) {
    return this.http.post<{customerId: number}>(environment.ApiUrl() + this.apiUrl + "customer", cust)
    .pipe(
      tap(() => {this.searchService.getSearchData().subscribe(_ => {})}) //tap when complete to refresh the local search data with new customer details
    );;
  }

  // addVehicle(vehicle: QuoteVehicles) {
  //   return this.http.post(environment.ApiUrl() + this.apiUrl + "vehicle", vehicle);
  // }

  linkVcmToQuote(vMapId:number,quote: PosQuote)
  {
    return this.http.get(environment.ApiUrl() + this.apiUrl + "quote/" + quote.quoteId + "/vehicleLink/" + vMapId)
  }

  linkRegToQuote(mappingId: number, quoteId: number) {
    return this.http.post(environment.ApiUrl() + this.apiUrl + "quote/" + quoteId + "/vehicleReg/" + mappingId, null)
  }

  linkDebtorToCustomer(debtorAcc: string, customerId:number, quoteId: number)
  {
    return this.http.post(environment.ApiUrl() + this.apiUrl + "quote/" + quoteId + "/debtorAcc/" + customerId  + "/link/" + debtorAcc , null)
  }

  addCustomerVehicle(customerId: number, veh: CustomerVehicle) {
    return this.http.post<{mappingId: number}>(environment.ApiUrl() + this.apiUrl + "vehicleReg/" + customerId, veh)
  }

  vehicleUpsert(veh: CustomerVehicle) {
    return this.http.post<{mappingId: number}>(environment.ApiUrl() + this.apiUrl + "vehicleUpsert", veh)
  }

  updateQuoteOdo(quoteId: number, odoReading: number) {
    return this.http.post<{mappingId: number}>(environment.ApiUrl() + this.apiUrl + `quote/${quoteId}/odo/${odoReading}`, null)
  }

  updateCustomerVATNumber(customerId: number, VATNumber: string) {
    return this.http.post(environment.ApiUrl() + this.apiUrl + `customer/${customerId}/vatno/${VATNumber}`, null)
  }

  updatePosCustomer(customerId: number, customer: NewPosCustomer) {
    return this.http.patch<{customerId: number}>(environment.ApiUrl() + this.apiUrl + `customer/${customerId}`, customer )
  }


  updateQuote(quote: PosQuote) {
    //calculate the running total first
    let total: number = 0;
    for (let line of quote.quoteLines) {
      total += line.totalPriceIncl / 1.15;
    }
    quote.running_total = total;
    return this.http.post(environment.ApiUrl() + this.apiUrl + "updatequote/" + quote.quoteId.toString(), quote)
  }

  updateComment(quote: PosQuote) {
    return this.http.post(environment.ApiUrl() + this.apiUrl + "quote/" + quote.quoteId.toString()+ "/updateComment", quote.comment.toString())
  }


  getQuoteState(quoteId: number) {
    return this.http.get<QuoteState>(environment.ApiUrl() + this.apiUrl + "quote/" + quoteId.toString() + "/status");
  }

  updateQuoteState(quoteId: number, statusId: number, actionDateRec?: Date) {
    const body = {actionDate: actionDateRec ? actionDateRec : null};
    return this.http.put<QuoteState>(environment.ApiUrl() + this.apiUrl + "quote/" + quoteId.toString() + "/status/" + statusId.toString(), body);
  }

  addSale(quote: PosQuote) {
    let addLines: AddSaleLine[] = []
    for (const row of quote.quoteLines)
    {
      let addLine = new AddSaleLine();
      addLine.cost = row.cost ? row.cost : 0;
      addLine.levelId = row.locationId;
      addLine.msfid = row.msfid;
      addLine.price = row.brand.toUpperCase() === "SERVICE" ? row.price / 1.15 : row.price; //TODO: Remove this when we save services as exvat pricing.
      addLine.qty = row.qty;
      addLine.sellerId = row.clientId;
      addLine.customStockId = row.customStockId;
      addLines.push(addLine);
    }
    let add = new AddSale()

    /////////Fleet Data///////////////
    add.customerCode = quote.ext_num
    add.make = quote.make
    add.model = quote.model
    add.reg = quote.reg
    add.odo = quote.odo
    add.driver_name = quote.driver_name
    add.driver_cell =quote.driver_cell
    //////////////////////////////////
    add.buyerId = quote.buyerId;
    add.delay_send = 0;
    if(quote.isContractPricing == true)
    {
      add.delay_send = 1;
    }
    add.manualInsert = 0;
    if(quote.manualInsert == true)
    {
      add.manualInsert = 1;
    }
    add.ext_Ref = "";
    add.comment = quote.comment ? quote.comment : ''; //If comment is null, pass it as empty string
    add.customerId = quote.custId;
    add.email = quote.custEmail;
    add.cell = quote.custCell;
    add.orderId = quote.quoteId;
    add.sellerId = quote.clientId;
    add.sf_ref = quote.quoteRef;
    add.line_items = addLines.slice();
    add.reg = quote.reg;
    add.userId = this.authService.user.user_id;
    return this.http.post(environment.ApiUrl() + "v1/sales/sale", add);
  }

  searchOtherStock(type: string) {
    return this.http.get<StockSearchResult[]>(environment.ApiUrl() + this.apiUrl + "searchOther/" + type)
  }

  searchOtherStockWithSpecialPrice(typeId: number, specialPriceSetId: number) {
    return this.http.get<StockSearchResult[]>(environment.ApiUrl() + this.v2ApiUrl + "search_customstock/" + typeId + '/' + specialPriceSetId)
  }

  getStatusses() {
    return this.http.get<QuoteStatus[]>(environment.ApiUrl() + this.apiUrl + "quotestatus");
  }

  emailQuote(id: number, emailAddress: string = "") {
    return this.http.post(environment.ApiUrl() + this.apiUrl + `quote/${id.toString()}/sendEmail${emailAddress ? "?overrideEmail=" + emailAddress : ""}`, null)

  }

  whatsappQuote(id: number, cell: string = "") {
    return this.http.post(environment.ApiUrl() + this.apiUrl + `quote/${id.toString()}/sendWhatsapp${cell ? "?overrideCell=" + cell : ""}`, null)

  }

  getActionLog(id: number) {
    return this.http.get<PosActionLogEntry[]>(environment.ApiUrl() + this.apiUrl + `quote/${id.toString()}/actionLog`);
  }

  getItemByMsfid(msfid: number) {
    return this.http.get<PosQuoteLine>(environment.ApiUrl() + this.apiUrl + `itemByMsfid/${msfid.toString()}`);
  }

  getItemByCustomStockCode(msfid: number) {
    return this.http.get<PosQuoteLine>(environment.ApiUrl() + this.apiUrl + `itemByCustomStockCode/${msfid.toString()}`);
  }

  getBookingSlotsPerDay(date: Date) {
    return this.http.get<BookingSlot[]>(environment.ApiUrl() + this.apiUrl + `bookingSlots?bookingDate=${formatDate(date,"yyyy-MM-dd", this.locale)}`);
  }

  confirmBooking(quoteRef: string, quoteId: number, date: Date, time: string) {
    const APIEndpoint = environment.ApiUrl() + `v1/bookings/${quoteRef}/confirmBooking`;
    return this.http.post(APIEndpoint,{quoteId: quoteId, date: formatDate(date,"yyyy-MM-dd", this.locale), time: time })
  }

  addCustomStock(custom:customStockItem){
    return this.http.post<{Message: string, customStockId: number, msfId: number, customStock: CustomStock}>(environment.ApiUrl() + this.apiUrl + "customStock_addition",custom)
  }

  reactivateCustomStock(custom:customStockItem){
    return this.http.put<{Message: string, customStockId: number, msfId: number, customStock: CustomStock}>(environment.ApiUrl() + this.apiUrl + "customStock_reactivate",custom)
  }

  getStockTypes()
  {
    return this.http.get<stockType[]>(environment.ApiUrl() + this.apiUrl + `stockTypes`);
  }

  assignWorkInProgress(id: number, reserveStock: boolean) {
    return this.http.post(environment.ApiUrl() + this.apiUrl + `quote/${id.toString()}/wip/${reserveStock}`, null)
  }

  getVehicles()
  {
      return this.http.get<Vehicle[]>(environment.ApiUrl() + this.apiUrl + "getVehicles");
  }

  getVehicleMakes()
  {
    return this.http.get<VehicleMake[]>(environment.ApiUrl() + this.apiUrl + "getVehicleMakes");
  }

  getVehicleModels(make:number)
  {
    return this.http.get<VehicleModel[]>(environment.ApiUrl() + this.apiUrl + "getVehicleModels/" + make);
  }

  getVehicleVariants(make:number,model:number)
  {
    return this.http.get<VehicleVariant[]>(environment.ApiUrl() + this.apiUrl + "getVehicleVariants/"+ make +"/"+ model);
  }


  generateWbcQuote(quote:wbcQuote)
  {
    return this.http.post<wbcQuote>(environment.ApiUrl() + this.apiUrl + "requestWbcQuote", quote);
  }


  //V2 POS Calls

  v2ApiUrl: string = "v2/pos/";

  getQuote(id: number): Observable<Quote> {
    return this.http.get<Quote>(environment.ApiUrl() + this.v2ApiUrl + "quote/" + id.toString())
  }

  searchByStockCode(code: string) {
    return this.http.get<QuoteLine[]>(environment.ApiUrl() + this.v2ApiUrl + "searchCode/" + code)
  }

  searchByStockCodeWithSpecialPriceSet(code: string, specialPriceSetId: number) {
    return this.http.get<QuoteLine[]>(environment.ApiUrl() + this.v2ApiUrl + "searchCode/" + code + "/" + specialPriceSetId)  
  }

  saveQuote(quote: Quote) {
    //calculate the running total first
    let total: number = 0;
    for (let line of quote.lines) {
      total += line.totalPriceIncl / 1.15;
    }
    delete quote['quoteToBeSaved$'];
    delete quote['actionLogToBeSaved$'];
    
    
    quote.running_total = total;

    //create a copy of quote and empty the customerVehices array
    let quoteCopy = Object.assign(new Quote(), quote);
    quoteCopy.customerVehicles = [];

    return this.http.post<QuoteLineAdded[]>(environment.ApiUrl() + this.v2ApiUrl + "updatequote/" + quote.quoteId.toString(), quoteCopy)
    .pipe(
      tap(e => {        
        for (let item of quote.lines.filter(x => x.quoteLineId === 0)) {          
          let found = e.find(x => x.msfid === item.msfid && (x.customStockId === item.customStockId || !item.customStockId) && !quote.lines.find(ss => ss.quoteLineId === x.quoteLineId))
          if (found) {
            item.quoteLineId = found.quoteLineId; //set the quoteLineId with the newly created one.
          }
        }        
      })
    )
  }

  selectCustomerToQuote(quoteId: number, customerId: number) {
    return this.http.post<{customerId: number, name: string, email: string, cell: string, custVATNumber:string, custDebtorAccount:string, customerGroupIds: number[], parentCustId: number, parentCustName: string}>(environment.ApiUrl() + this.v2ApiUrl + "quote/" + quoteId + "/linkCustomer/" + customerId, null)
  }

  addNewCustomerForQuote(quoteId: number, {cell, name}) {
    return this.http.post<{customerId: number, name: string, email: string, cell: string, custVATNumber:string, custDebtorAccount:string}>(environment.ApiUrl() + this.v2ApiUrl + "quote/" + quoteId + "/addCustomer", {cell: cell, name: name})
  }

  vehicleMakes()
  {
    return this.http.get<VehicleMake[]>(environment.ApiUrl() + this.v2ApiUrl + "getVehicleMakes");
  }

  vehicleModels(makeId:number)
  {
    return this.http.get<VehicleModel[]>(environment.ApiUrl() + this.v2ApiUrl + "getVehicleModels/" + makeId);
  }

  vehicleVariants(modelId:number)
  {
    return this.http.get<VehicleVariant[]>(environment.ApiUrl() + this.v2ApiUrl + "getVehicleVariants/" + modelId);
  }

  updateVehicleDetails(quoteId: number, vehicle: CustomerVehicleDetails) {
    if (vehicle.odoReading && vehicle.odoReading.toString().length === 0) vehicle.odoReading = 0;
    return this.http.post<{Vehicle_Customer_MappingId: number, vehicleOdoId: number}>(environment.ApiUrl() + this.v2ApiUrl + `quote/${quoteId}/updateVehicleDetails`, vehicle)
  }

  serviceGroups() {
    return this.http.get<{packageTypeId: number, packageDescription: string, packageAcronym: string, clientId: number}[]>(environment.ApiUrl() + this.v2ApiUrl + "serviceGroups");
  }

  customStockGroups() {
    return this.http.get<{stockTypeId: number, stockDescription: string, stockTypeImage: string}[]>(environment.ApiUrl() + this.v2ApiUrl + "customStockGroups");
  }

  servicesByServiceGroup(groupId: number) {
    return this.http.get<QuoteLine[]>(environment.ApiUrl() + this.v2ApiUrl + "serviceGroups/" + groupId.toString());
  }

  servicesByServiceGroupSpecialPrice(groupId: number, specialPriceSetId: number) {
    return this.http.get<QuoteLine[]>(environment.ApiUrl() + this.v2ApiUrl + "serviceGroups/" + groupId.toString() + "/" + specialPriceSetId.toString());
  }

  getCustomerVehicles(customerId: number) {
    return this.http.get<CustomerVehicleDetails[]>(environment.ApiUrl() + this.v2ApiUrl + `customer/${customerId.toString()}/vehicles`);
  }

  searchAllVehicles(vreg: string) {
    return this.http.get<CustomerVehicleDetails[]>(environment.ApiUrl() + this.v2ApiUrl + `vehicles/${vreg.toString()}`);
  }

  completeSale(quote: Quote) {
    let addLines: AddSaleLine[] = []
    for (const row of quote.lines)
    {
      let addLine = new AddSaleLine();
      addLine.cost = row.cost ? row.cost : 0;
      addLine.levelId = row.locationId;
      addLine.msfid = row.msfid;
      addLine.price = row.brand.toUpperCase() === "SERVICE" ? row.price / 1.15 : row.price; //TODO: Remove this when we save services as exvat pricing.
      addLine.qty = row.qty;
      addLine.sellerId = row.clientId;
      addLine.customStockId = row.customStockId;
      addLine.altStockCode = row.altStockCode;
      addLine.altStockDescription = row.altStockDescription;
      addLine.stockUsedId = row.stockUsedId;
      addLines.push(addLine);
    }
    let add = new AddSale()
    /////////Fleet Data///////////////
    add.customerCode = 0;
    if (quote.authType == 1) {
      let selectedVehicle = quote.customerVehicles.find(x =>  x.Vehicle_Customer_MappingId === quote.Vehicle_Customer_MappingId);
      if(selectedVehicle && quote.sbFleetAuthData)
      {
        add.make = selectedVehicle.vehicleMake;
        add.model = selectedVehicle.vehicleModel;
        add.reg = selectedVehicle.v_reg;
        add.odo = quote.sbFleetAuthData.odo;
        add.driver_cell = quote.sbFleetAuthData.driver_cell;
        add.driver_name = quote.sbFleetAuthData.driver_name;
        add.fleetAuthType = quote.authType ? quote.authType : 0;
      }
    }
    
    
    
    
    //////////////////////////////////
    add.buyerId = quote.channelId;
    add.delay_send = 0;
    add.specialPriceSetId = quote.specialPriceSetId;
    add.customerOrderReference = quote.customerOrderReference;
    add.invoiceDeliveryMethod = quote.invoiceDeliveryMethod;
    add.payments = quote.payments.map(x => {
      return {paymentTypeId: x.paymentTypeId, amount: x.amount}
    }
    )
    add.manualInsert = 0;
    if(quote.manualInsert == true)
    {
      add.manualInsert = 1;
    }
    add.ext_Ref = "";
    add.comment = quote.comment ? quote.comment : ''; //If comment is null, pass it as empty string
    add.customerId = quote.custId;
    add.email = quote.custEmail;
    add.cell = quote.custCell;
    add.orderId = quote.quoteId;
    add.sellerId = quote.clientId;
    add.sf_ref = quote.quoteRef;
    add.line_items = addLines.slice();
    
    add.userId = quote.userId; //this send the linked userId 
    return this.http.post(environment.ApiUrl() + "v1/sales/sale", add);
  }

  getSalesman(quoteId:number,clientId:number)
  {
    return this.http.get<Salesman[]>(environment.ApiUrl() + this.v2ApiUrl + `quote/${quoteId}/getSalesman/${clientId}` )
  }

  // updateSalesman(quoteId:number,userId:number)
  // {
  //   return this.http.get<Salesman[]>(environment.ApiUrl() + this.v2ApiUrl + `quote/${quoteId}/updateSalesman/${userId}`)
  // }

  WbcQuote(quote:wbcQuote)
  {
    return this.http.post<wbcQuote>(environment.ApiUrl() + this.v2ApiUrl + "requestWbcQuote", quote);
  }


}
