

export class ChangePasswordRequest {
    oldPassword: string
    newPassword: string
}

export class ClientBookingSlot {
    slotId: number
    dayOfWeek: number
    slotTime: string
    status: number
}

export class ClientBankingDetails {
    bankingDetailsId:number = 0
    beneficiaryName:string = ""
    bankName:string = ""
    branchCode:number = 0
    accountType:string = ""
    accountNumber: string = ""
    clientId: number = 0
}

export class ClientQuoteReport {
    reportDataId: number = 0
    clientId: number = 0
    name: string = ""
    quoteLogo: string = ""
    quoteQrText: string = ""
    quoteTerms: string = ""
    isCustom: boolean = false
    customClientId: number = 0
    clientColor: string = ""
    isFranchise: boolean = false
}

export class ClientCustomStockPackages {
    customStockGroupId: number| null = 0
    customStockGroupName: string = ""
}


export class ClientSettings {
    liftCountOnline: number = 0
    VATNumber: string = ""
    RegNumber: string = ""
    address: string = ""
    zipCode: string = ""
    city: string = ""
    regionId:number = 0
    bookingsBlockedHours: number = 0
    displayCostOnJC: boolean = false
    manageStock:boolean = false
    isInvoiceManagedSF: boolean = false
    featureLevel: number = 0
    bankingDetails: ClientBankingDetails = new ClientBankingDetails()
    showBankingDetails:boolean = false
    showAddressDetails:boolean = false
    quoteSettings: ClientQuoteReport = new ClientQuoteReport()
    quoteThemeOptions: ClientQuoteReport[] = []
    quoteSoftPrint:boolean = false
    showQuoteImage: boolean = false
    showPosNavAway:boolean = false
    leadChannel:boolean = false
    leadType:boolean = false
    leadExpiryDays: number = 4
    customStockPackages: ClientCustomStockPackages [] = []
    defaultCustomStockPackage: number = 1;
    gpProtectionPercent: number | null = null;
    hasGpProtection: boolean = false;
    lon: number;
    lat: number;
}

export class quoteSettings{
    clientId : number =0
    clientName : string = ""
    quoteSoftPrint: boolean = false
    showAddressDetails: boolean = false
    showBankingDetails: boolean = false
    showQuoteImage:boolean = false
    isFranchise: boolean = false
    quoteTheme: number = 0
    customTheme: boolean = false
    quoteTerms:  string = ""
    quoteColor:string = ""
    quoteQrText: string = ""
    showPosNavAway:boolean = false
    leadChannel:boolean = false
    leadType:boolean = false
    leadExpiryDays: number = 4
    defaultCustomStockPackageId: number = 1
    gpProtectionPercent: number | null = null
    
}

export class Customer {
    customerId: number
    email: string
    name: string
    cell: string
    vreg: string
    debtorAcc: string
}

export class Vehicle{
    Id:number
    vreg:string
    size: string
}

export class CustomerUpdate {
    customerId: number
    email: string
    name: string
    cell: string
    VATNumber: string
    debtorAcc: string
    vehicles: [Vehicle]
}

export class ApiEndpoint {
    url: string
    weight: number
    isActive: boolean
}
