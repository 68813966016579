<nz-skeleton [nzLoading]="isLoadingCustomer" [nzActive]="true">
    <i (click)="removeCustomer()" nz-icon nzType="close-circle" nzTheme="outline" style="float: left; font-size: 1.1rem !important; color:rgb(192, 1, 1)" class="removeCust" *ngIf="!this.isWIP && !this.isCompleted && this.posQuote.custId != 14041 && this.posQuote.custId != 0"
        nz-tooltip="Remove Customer" nzTooltipColor='rgb(192, 1, 1)' nzTooltipPlacement='top'></i>
    <!--         Search       -->
    <ng-container *ngIf="showSearch">
        <div class="row">
            <div class="col-12">
                <nz-select style="width: 100%;font-size: 15px;" nzShowSearch (ngModelChange)="linkCustomer($event)" [(ngModel)]="selectedCustomer" [nzLoading]="isLoadingCustomers" class="text-center" nzPlaceHolder="Cell Number" [nzShowArrow]="false" nzLayout="inline"
                    nzAllowClear [nzNotFoundContent]="noCustomerResults" (nzOnSearch)="filterCustomer($event)">
                    <nz-option *ngFor="let c of customers" [nzValue]="c.customerId" [nzLabel]="c.customer"></nz-option>
                </nz-select>
            </div>
        </div>
        <br>
    </ng-container>
    <ng-template #noCustomerResults>
        <span> No results found </span><br>
        <button class="btn" (click)="showAddCustomer()">
                <i nz-icon nzType="user-add" nzTheme="outline" style="vertical-align: middle; margin-top: -5px;"></i>
                Add new
            </button>
    </ng-template>
    <!-------------------------->
    <form nz-form [formGroup]="addCustomerForm">
        <div class=" row row-height-customer" *ngIf="!showSearch">
            <div class="col-12">
                <nz-form-item>
                    <nz-form-control [nzErrorTip]="'Valid Customer Cell Required'">
                        <div class="input-group maindiv">
                            <span class="input-group-prepend">
                                            <div class="input-group-text bg-transparent">
                                                <i nz-icon nzType="phone" nzTheme="outline"></i>
                                            </div>
                                        </span>
                            <input (focusout)="updateCustomer()" nz-input name="custCell" formControlName="custCell" placeholder="Cell Number" class="form-control text-center form-control-custom">
                        </div>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div class="row row-height-customer">
            <div class="col-6">
                <nz-form-item>
                    <nz-form-control [nzErrorTip]="'Customer Name Required'">
                        <div class="input-group maindiv">
                            <span class="input-group-prepend">
                                <div class="input-group-text bg-transparent">
                                    <i nz-icon nzType="user" nzTheme="outline"></i>
                                    </div>
                                </span>
                            <input [nz-tooltip]="showSearch ? 'Search Cell No. First!' : ''" (focusout)="updateCustomer()" nz-input name="custName" formControlName="custName" placeholder="Name" class="form-control text-center form-control-custom">
                        </div>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="col-6">
                <nz-form-item>
                    <nz-form-control [nzErrorTip]="'Customer Surname Required'">
                        <div class="input-group maindiv">
                            <span class="input-group-prepend">
                                    <div class="input-group-text bg-transparent">
                                        <i nz-icon nzType="user" nzTheme="outline"></i>
                                    </div>
                                </span>
                            <input [nz-tooltip]="showSearch ? 'Search Cell No. First!' : ''" (focusout)="updateCustomer()" nz-input name="custSurname" formControlName="custSurname" placeholder="Surname" class="form-control text-center form-control-custom">
                        </div>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div class="row row-height-customer">
            <div class="col-12">
                <nz-form-item>
                    <nz-form-control nzErrorTip="Valid or empty email required">
                        <div class="input-group maindiv">
                            <span class="input-group-prepend">
                                    <div class="input-group-text bg-transparent">
                                        <i nz-icon nzType="mail" nzTheme="outline"></i>
                                    </div>
                                </span>
                            <input [nz-tooltip]="showSearch ? 'Search Cell No. First!' : ''" (focusout)="updateCustomer()" nz-input name="custEmail" formControlName="custEmail" placeholder="Email Address" class="form-control text-center form-control-custom">
                        </div>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div class="row row-height-customer">
            <div class="col-6">
                <nz-form-item>
                    <nz-form-control>
                        <div class="input-group maindiv">
                            <span class="input-group-prepend">
                                    <div class="input-group-text bg-transparent">
                                        <i nz-icon nzType="bank" nzTheme="outline"></i>
                                    </div>
                                </span>
                            <input [nz-tooltip]="showSearch ? 'Search Cell No. First!' : ''" name="custVATNumber" formControlName="custVATNumber" (focusout)="onVATNumberAddClick()" nz-input placeholder="VAT Number" class="form-control text-center form-control-custom">
                        </div>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="col-6">
                <nz-form-item>
                    <nz-form-control>
                        <div class="input-group maindiv">
                            <span class="input-group-prepend">
                                    <div class="input-group-text bg-transparent">
                                        <i nz-icon nzType="bank" nzTheme="outline"></i>
                                    </div>
                                </span>
                            <input readonly [nz-tooltip]="showSearch ? 'Search Cell No. First!' : ''" [disabled]="posQuote.custId === 14041 || posQuote.custId === 0" (click)="onDebtorClick()" value="{{customerDebtorInfo.debtorAcc}}" nz-input placeholder="Debtor Account ?" class="form-control text-center form-control-custom">
                        </div>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
    </form>
</nz-skeleton>


<!-- Modal Section -->
<nz-modal [(nzVisible)]="showDebtorModal" nzTitle="Customer Debtor Account" (nzOnCancel)="showDebtorModal = false" [nzFooter]="null">
    <div *nzModalContent>
        <nz-spin [nzSpinning]="isLoadingDebtors">
            <div *ngFor="let item of DebtorAccs" class="text-div text-div-interactive" style="display: block;" (click)="linkDebtorToCustomer(item.debtorAcc)">
                <i nz-icon nzType="bank" nzTheme="outline"></i> {{item.debtorAcc}}
            </div>

            <button nz-button class="btn " style="margin-top: 10px;border:none" (click)="onAddDebtorClick()">
                <i nz-icon nzType="plus" nzTheme="outline" style="vertical-align: middle; margin-top: -6px;"></i>
                 Link New Account
            </button>
            <button nz-button class="btn" style="margin-top: 10px; margin-left: 10px;;border:none" (click)="onUnlinkDebtorClick()" *ngIf="customerDebtorInfo.debtorAcc !== null">
                <i nz-icon nzType="close" nzTheme="outline" style="vertical-align: middle; margin-top: -6px;"></i> 
                Unlink Account {{this.customerDebtorInfo.debtorAcc}}
            </button>
        </nz-spin>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="showAddDebtorModal" nzTitle="Add New Debtor Account" (nzOnCancel)="showAddDebtorModal = false">
    <div *nzModalContent>
        <nz-spin [nzSpinning]="isLoadingDebtors">
            <form nz-form [formGroup]="addDebtorForm">
                <nz-form-item>
                    <nz-form-control [nzErrorTip]="'Debtor Account required'">
                        <div class="input-group maindiv">
                            <span class="input-group-prepend">
                                    <div class="input-group-text bg-transparent">
                                        <i nz-icon nzType="bank" nzTheme="outline"></i>
                                    </div>
                                </span>
                            <input nz-input name="debtorAcc" formControlName="debtorAcc" placeholder="Debtor Account" class="form-control text-center form-control-custom">
                        </div>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-spin>
    </div>
    <div *nzModalFooter>
        <button nz-button class="btnred" style="border:none" (click)="showAddDebtorModal = false">
            <i nz-icon nzType="close" nzTheme="outline" style="vertical-align: middle; margin-top: -5px;"></i>Cancel
        </button>
        <button nz-button class="btn" style="border:none" (click)="addDebtor()">
            <i nz-icon nzType="check" nzTheme="outline" style="vertical-align: middle; margin-top: -5px;"></i>Add
        </button>
    </div>
</nz-modal>