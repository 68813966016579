<div nz-col nzSpan="24" style="padding-left: 5%;padding-right: 5%;">

<div nz-row nzJustify="center">
    <h2><b>Price Matrix</b></h2>
</div>
<div nz-row [nzJustify]="'center'" *ngIf="isLoading" class="loading-spinner">
    <nz-spin nzSimple></nz-spin>
</div>

<nz-tabset nzCentered nzSize="large">
    <nz-tab *ngFor="let pmList of pricingMatrixObj.priceMatrixList; let j=index " [nzTitle]="pmList.name">
        <div nz-row nzJustify="center">
            <p><b>{{pmList.description}}</b></p>
        </div>
        <div nz-row nzJustify="space-between" style="padding-bottom: 20px;">
            <div nz-col nzSpan="8">
                <nz-select [(ngModel)]="pmList.applied" style="width: 100%">
                    <nz-option *ngFor="let item of appliedOptions" [nzLabel]="item.text" [nzValue]="item.value"></nz-option>
                </nz-select>
            </div>
            <div>
                <button nz-button nzType="primary" type="submit" (click)="updatePMList(j)" [disabled]="!matrixDirty">Save</button>
            </div>
        </div>

        <div nz-col nzSpan="24">
            <nz-table [nzData]="pmList.priceMatrixItems" [nzFrontPagination]="false" [nzLoading]="isLoading" style="width: 100%;">
                <thead>
                    <tr>
                        <th nzWidth="15px"></th>
                        <th nzWidth="50px"></th>
                        <th 
                            [nzSortOrder]="priceMatrixBrandColumn.sortOrder"
                            [nzSortFn]="priceMatrixBrandColumn.sortFn"
                            [nzSortDirections]="priceMatrixBrandColumn.sortDirections"
                            [nzFilterMultiple]="priceMatrixBrandColumn.filterMultiple"
                            [nzFilters]="priceMatrixBrandColumn.listOfFilter"
                            [nzFilterFn]="priceMatrixBrandColumn.filterFn"
                            nzWidth="150px">
                            {{ priceMatrixBrandColumn.name }}
                        </th>
                        <th nzWidth="200px">Method</th>
                        <th nzWidth="125px">Percentage
                            <button nz-button nzType="primary" style="margin-left: 10px;" nz-popover nzPopoverTitle="Set All %" [nzPopoverContent]="setAllTemplate" nzPopoverPlacement="right" nzPopoverTrigger="click" (nzPopoverVisible)="isPopoverVisible">Set All</button>
                            <ng-template #setAllTemplate>
                                <div>
                                    <nz-input-group nzAddOnBeforeIcon="percentage">
                                        <input nz-input [(ngModel)]="setAllValue" type="number" placeholder="20" style="width: 100px;">
                                    </nz-input-group>
                                    <div nz-row [nzJustify]="'end'">
                                        <button nz-button nzType="primary" style="margin-top: 10px;" (click)="setAllPercentages(j); isPopoverVisible = false;">Set</button>
                                    </div>
                                </div>
                            </ng-template>
                            <app-video-widget [videoId]="6" style="float: inline-end;"></app-video-widget>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let brand of pmList.priceMatrixItems; let i = index">
                        <tr (click)="toggleExpand(brand)">
                            <td></td>
                            <td><img class="brandImage" src='https://sftptyres.blob.core.windows.net/brands-images/brands/{{brand.image}}'></td>
                            <td>{{brand.brandName}}</td>
                            <td>
                                <nz-radio-group [(ngModel)]="brand.methodId" nzButtonStyle="solid" nzSize="small" (ngModelChange)="methodChanged()">
                                    <label nz-radio-button [nzValue]="1">Fixed GP Percentage</label>
                                    <label nz-radio-button [nzValue]="2">Retail Less</label>
                                    <label nz-radio-button [nzValue]="3">Do Not Sell</label>
                                </nz-radio-group>
                            </td>
                            <td> <div class="input-group maindiv" style="width: 80%">
                              <nz-input-group nzAddOnAfterIcon="percentage" >
                                <input nz-input [(ngModel)]="brand.value" (ngModelChange)="brand.methodId && matrixBrandChanged()" type="number" placeholder="100">
                              </nz-input-group>
                            </div>
                          </td>
                        </tr>
                        <ng-container *ngIf="brand.expand">
                            <tr *ngFor="let item of brand.priceMatrixOverrideItems">
                                <td></td>
                                <td></td>
                                <td [nzIndentSize]="((brand?.level ?? 0) + 1) * 15"> {{ item.rimSize }}"</td>
                                <td><nz-radio-group nzSize="small" [ngModel]="item.pmMethodId" (ngModelChange)="matrixOverrideChanged()"  >
                                  <label nz-radio-button [nzValue]="1">Fixed GP Percent</label>
                                  <label nz-radio-button [nzValue]="2">Retail Less</label>
                                  <label nz-radio-button  [nzValue]="3">Dont Sell</label>
                                </nz-radio-group>
                                </td>
                                <td><div nz-row [nzJustify]="'space-between'">
                                  <div nz-col nzSpan="18">
                                    <nz-input-group nzAddOnAfterIcon="percentage">
                                      <input nz-input [ngModel]="item.value" (ngModelChange)="matrixOverrideChanged()" type="number" placeholder="100">
                                    </nz-input-group>
                                  </div>
                                  <div nz-col style="margin-left:10px">
                                    <button nz-button nzType="primary" nzDanger="true" ><span nz-icon nzType="delete" nzTheme="outline"></span></button>
                                  </div>
                                </div></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Add Rim Size</td>
                                <td [nzIndentSize]="((brand?.level ?? 0) + 1) * 10">
                                    <nz-select style="width: 50%">
                                        <nz-option *ngFor="let size of this.avaibleRims(brand.brandId, j)" [nzLabel]="size" [nzValue]="size"></nz-option>
                                    </nz-select>
                                </td>
                                <td>
                                  <nz-radio-group nzSize="small"  style="width: 75%">
                                    <label nz-radio-button [nzValue]="1">Fixed GP Percent</label>
                                    <label nz-radio-button [nzValue]="2">Retail Less</label>
                                    <label nz-radio-button  [nzValue]="3">Dont Sell</label>
                                </nz-radio-group>
                                </td>
                                <td>
                                  <div nz-row [nzJustify]="'space-between'">
                                    <div nz-col style="width: 50%">
                                      <nz-input-group nzAddOnAfterIcon="percentage">
                                        <input nz-input  type="number" placeholder="100">
                                      </nz-input-group>
                                    </div>
                                    <div nz-col style="margin-left:10px">
                                      <button nz-button nzType="primary" >Add Rim</button>
                                    </div>
                                  </div>
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container>
                </tbody>
            </nz-table>
        </div>
    </nz-tab>
</nz-tabset>

<!-- Nav Away Modal -->


</div>
