import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/shared/notification.service';
import { StockService } from '../stock.service';
import { NMSF_Add_Response, NmsfItem, NmsfMetaHolder } from 'src/app/models/stock';
import { resultType } from 'src/app/shared/modal-content/modal-content.component';
import { StockItem } from 'src/app/models/pricelist';

@Component({
  selector: 'app-stock-add-nmsf',
  templateUrl: './stock-add-nmsf.component.html',
  styleUrls: ['./stock-add-nmsf.component.less']
})
export class StockAddNmsfComponent {

  @Output() dismissModal = new EventEmitter<boolean>();
  @Output() stockCode = new EventEmitter<string>();
  @Input() stockItem : StockItem = new StockItem();
  public _isLoading: boolean;

  metaHolder: NmsfMetaHolder = new NmsfMetaHolder();
  showForm:boolean = true;
  showCodeExists:boolean = false;
  similarTyreExist:boolean  = false;
  responseModel:NMSF_Add_Response;
  itemToAdd:NmsfItem = new NmsfItem();


  addStockItemForm: FormGroup = new FormGroup({
    width: new FormControl("",[Validators.required]),
    profile: new FormControl("",[Validators.required]),
    rim: new FormControl("", [Validators.required]),
    speedRating: new FormControl(null, [Validators.required]),
    loadRating: new FormControl(null, [Validators.required]),
    market: new FormControl("",),
    brand: new FormControl("", [Validators.required]),
    pattern: new FormControl("", [Validators.required]),
    stockCode:new FormControl("", [Validators.required]),
    runFlat: new FormControl("", [Validators.required]),
    xl: new FormControl("", [Validators.required]),
  });


  constructor(private notification: NotificationService, private stockService:StockService ) { }

  ngOnInit(): void {
    this.addStockItemForm.get('stockCode')?.setValue(this.stockItem.stock_code);
    this.fetchMetaData();
  }

  clearMetaData()
  {
    this.metaHolder.widths =  [];
    this.metaHolder.profiles =  [];
    this.metaHolder.rims =  [];
    this.metaHolder.speedRatings=  [];
    this.metaHolder.loadRatings =  [];
    this.metaHolder.brands =  [];
    this.metaHolder.markets =  [];
  }

  fetchMetaData()
  {
    this._isLoading = true;
    this.clearMetaData();
    this.stockService.getStockMetaData().subscribe(
      res => { 
          this.metaHolder = res;
          this.metaHolder.runFlat = ["Yes","No"];
          this.metaHolder.extraLoad = ["Yes","No"];
          this._isLoading = false;
          this.setGenCodes();
      }, 
      error => { 
        this.notification.handleError(error);
        this._isLoading = false;
    });
  }

  setGenCodes()
  {
    for(var i = 0; i < this.metaHolder.genCodes.length; i++)
    {
      if(this.metaHolder.genCodes[i].gen_code == this.stockItem.stock_code)
        {
          this.addStockItemForm.controls.width.setValue(this.metaHolder.genCodes[i].width);
          this.addStockItemForm.controls.profile.setValue(this.metaHolder.genCodes[i].profile);
          this.addStockItemForm.controls.rim.setValue(this.metaHolder.genCodes[i].rim);
        }
    }
  }

  addStockItem()
  {
    this._isLoading = true;
    if (!this.addStockItemForm.valid) 
      {
        Object.values(this.addStockItemForm.controls).forEach(control => {
          if (control.invalid) 
          {
            control.markAsDirty();
            control.updateValueAndValidity({ onlySelf: true });
          }
          this._isLoading = false;
          return;
        });
      }
      else
      {
        let nmsf: NmsfItem = new NmsfItem();
        nmsf.width =Number(this.addStockItemForm.value.width);
        nmsf.profile = Number(this.addStockItemForm.value.profile);
        nmsf.rim = Number(this.addStockItemForm.value.rim);
        nmsf.speedRating = this.addStockItemForm.value.speedRating;
        nmsf.loadRating = this.addStockItemForm.value.loadRating;
        nmsf.market = this.addStockItemForm.value.market;
        nmsf.brandId = this.addStockItemForm.value.brand;
        nmsf.brand = this.metaHolder.brands.find(x=> x.brandId == nmsf.brandId)?.name!;
        nmsf.brandLogo_url = this.metaHolder.brands.find(x=> x.brandId == nmsf.brandId)?.brandLogo_url!;
        nmsf.pattern = this.addStockItemForm.value.pattern;
        nmsf.stockCode = this.addStockItemForm.value.stockCode;
        nmsf.runflat = this.addStockItemForm.value.runFlat == "Yes" ? true :null;
        nmsf.xl = this.addStockItemForm.value.xl == "Yes" ? true : null;
        nmsf.stockCodeToMap = this.stockItem.stock_code;
        nmsf.locationIdToMap = this.stockItem.locationId;
        nmsf.descriptionToMap = this.stockItem.description;
        this.itemToAdd = nmsf;
        this.stockService.addStockItemNMSF(nmsf).subscribe(res => {
          this.responseModel = res;
          if(res.status == 1) { // 1 Means - Stock Code Found
            this.showForm = false;
            this.showCodeExists = true;
          }
          else if (res.status == 2) { // 2 Means - Matches were found for stock Item
            this.showForm = false;
            this.similarTyreExist = true;
          }
          else if (res.status == 3) { // 3 Means - Success
             // emit modal close
             this._isLoading = false;
             this.notification.showSuccess("Successfully Added Tyre to NMSF");
             this.dismissModal.emit(true);
             this.stockCode.emit(this.stockItem.stock_code);
          }
          else
          {
            this._isLoading = false;
            this.notification.showError("Unexpected Error has Occoured whilst Attempting to Add the Item , Please Contact SF to Resolve");
          }
        },
        error => {
          this._isLoading = false;
          this.notification.handleError(error);
        });    
      }
  }

  addStockItemDirect()
  {
    this.stockService.addStockItemNMSFDirect(this.itemToAdd).subscribe(res => {
      if (res.status == 3) { // 3 Means - Success
         // emit modal close
         this.notification.showSuccess("Successfully Added Tyre to NMSF");
         this.dismissModal.emit(true);
         this.stockCode.emit(this.stockItem.stock_code);
      }
      else
      {
        this.notification.showError("Unexpected Error has Occoured whilst Attempting to Add the Item , Please Contact SF to Resolve");
      }
    },
    error => {
      this.notification.handleError(error);
    });    
  }

  notifyAlreadyExists()
  {
    this.notification.showSuccess("Stock Code Already Exists in NMSF");
    this.dismissModal.emit(true);
  }

  displayAlert()
  {
    this.notification.ShowAlert({type: resultType.Information, 
      title: "Stock Code Exists", 
      htmlMessage: 'Please Insert the Stock Code Directly your POS system , It will then me updated Automatically in the next Stock Load' ,
      maskClosable: false,
      btnText: 'OK'},
      undefined, () => {
        this.dismissModal.emit(true);
      });
  }

  addNewStockMapping(item:NmsfItem)
  {   
    this.itemToAdd.msfid = item.msfid;
    this.itemToAdd.stockCode = this.stockItem.stock_code;
    this.itemToAdd.pattern = this.stockItem.description;  
    this.stockService.addNmsfItemMapping(this.itemToAdd).subscribe(res => {
        this.notification.showSuccess("Successfully Mapped Item");
        this.dismissModal.emit(true);
    },
    error => {
      this.notification.handleError(error);
    });
  }

  marketCheck(event: any){
    const marketList = ['NTRA','NOTR','NOTH','NIND'];
    if(marketList.includes(event)){
      this.addStockItemForm.controls.loadRating.removeValidators([Validators.required]);
      this.addStockItemForm.controls.speedRating.removeValidators([Validators.required]);
    }
    else{
      this.addStockItemForm.controls.loadRating.addValidators([Validators.required]);
      this.addStockItemForm.controls.speedRating.addValidators([Validators.required]);
    }
    // Apply changes
    this.addStockItemForm.controls.loadRating.updateValueAndValidity()
    this.addStockItemForm.controls.speedRating.updateValueAndValidity()
  }
}








