import { Plupdate,PriceMatrix,PriceMatrixOptions,PriceMatrixApplied,PriceMatrixDesc ,PriceMatrixObject, PriceMatrixObj, PriceList, PriceMatrixTreeNode, PriceMatrixOverrideItem, PriceMatrixColumnItem, PmBrand, PriceMatrixPriceList, updatePMResponse } from './../../models/pricelist';
import { PricelistsService } from '../../pricelists/pricelists.service';
import { NotificationService } from '../../shared/notification.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { Globals } from 'src/app/shared/globals';
import { LogService } from 'src/app/shared/logs.services';
import { LogAudit, LogAuditType } from 'src/app/models/logs';
import { Router } from '@angular/router';


@Component({
  selector: 'app-pricingmatrix',
  templateUrl: './pricingmatrix.component.html',
  styleUrls: ['./pricingmatrix.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PricingmatrixComponent implements OnInit, OnDestroy {

  priceMatrixOptions: PriceMatrixOptions;
  priceDescriptions: PriceMatrixDesc;
  priceApplied: PriceMatrixApplied;
  priceMatrix : PriceMatrix[];
  priceObj:PriceMatrixObject = new PriceMatrixObject;
  matrixObject: any[] = Globals.consts.matrixObject;
  matrixOptions: any[] = Globals.consts.matrixOptions;
  selectedValue: string[] = [];

  setAllValue : number;
  popOverVisibility: boolean = false;
  isLoading: boolean = false;
  isLoadingMatrix: boolean = false;

  priceMatrixLists : PriceMatrixTreeNode[]

  rimSizes: number[] = [
    3.00, 4.00, 4.33, 5.00, 5.50, 5.51, 6.00, 6.50, 7.00, 8.00, 9.00, 9.75, 10.00, 
    10.50, 11.00, 11.25, 12.00, 12.13, 13.00, 14.00, 14.50, 15.00, 15.30, 15.50, 
    16.00, 16.10, 16.50, 17.00, 17.50, 18.00, 19.00, 19.50, 20.00, 20.50, 21.00, 
    22.00, 22.01, 22.50, 23.00, 24.00, 24.50, 25.00, 25.20, 26.00, 26.50, 28.00, 
    28.50, 29.00, 30.00, 30.50, 32.00, 33.00, 34.00, 35.00, 36.00, 38.00, 42.00, 
    44.00, 45.00, 46.00, 48.00, 49.00, 50.00, 51.00, 52.00, 54.00, 430.90
  ];
  brandNames: {text: string, value: number}[] = [];
  priceMatrixBrandColumn: PriceMatrixColumnItem=
    {
      name: 'Brand',
      sortOrder: null,
      sortFn: (a: PriceMatrixTreeNode, b: PriceMatrixTreeNode) => a.brandName.localeCompare(b.brandName),
      sortDirections: ['ascend', 'descend', null],
      listOfFilter: this.brandNames,
      filterFn: (list: number[], item: PriceMatrixTreeNode) => list.some(value => item.brandId === value),
      filterMultiple: true
      
    }

  pricingMatrixObj: PriceMatrixObj = new PriceMatrixObj;
  priceMatrixDisplayDataObj: PriceMatrixObj = new PriceMatrixObj;
  selectedTab: number = 2;

  newRuleBrand: PmBrand | null = null;
  newRuleMethod: string  = `FGPP`;
  newRuleValue: number = 0;

  newRim: {[brandId: number]: { size: number, methodId: number, value: number } } = {};

  matrixDirty: boolean = false;
  updatedPmItems: PriceMatrixTreeNode[] = [];
  updatedPmOverrides: PriceMatrixOverrideItem[] = [];
  brandAvailableRims: { [brandId: string]: any[] } = {};

  isNavModalVisible = false;
  navPathAway: string = "";

  isApplyModalVisible = false;
  constructor( private pricelistsService: PricelistsService,
    private notification: NotificationService,
    public authService: AuthService, 
    private logService: LogService,
     private cdr: ChangeDetectorRef,
    private router: Router) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.isLoadingMatrix = true;
    this.pricelistsService.getPricingMatrix().subscribe(
      (res:any) =>
      {
        
       this.pricingMatrixObj = res;      
        this.priceMatrixBrandColumn.listOfFilter = this.pricingMatrixObj.pmBrandList;
        
        // Add missing brands with placeholder values

        this.pricingMatrixObj.priceMatrixList.forEach((pmlist, index) => {
          pmlist.priceMatrixItems.forEach((brand) => {
            this.newRim[brand.brandId] = { size: 0, methodId: 1, value: 0 };
          });
        });
        this.isLoadingMatrix = false;
        this.isLoading = false;
        this.cdr.markForCheck();
      }
    )
   

  }

  ngOnDestroy(): void {
    // Add any necessary cleanup logic here
  }

  tabChanged(pmIndex: number) {
    if (this.matrixDirty) {
      this.notification.showWarning("Please save your changes before switching tabs");
    } 

  }

  transformData(data: PriceMatrixObj): PriceMatrixObj {
    const result: PriceMatrixObj = new PriceMatrixObj;
    
    return result;
  }

  // showmatrix(selectedmatrix)
  // { 

  //   this.priceObj.pmtext = this.priceDescriptions[selectedmatrix]
  //   this.priceObj.pm_applied_id = this.priceApplied[selectedmatrix]
  //   this.priceObj.pm_active = selectedmatrix;
  //   this.priceObj.pm_select = selectedmatrix;
  //   this.priceMatrix = this.priceMatrixOptions[selectedmatrix];
  //   this.priceMatrixOptions.active = selectedmatrix;

  //   this.priceMatrix.forEach((obj, index) => {
  //     this.selectedValue[index] = obj.method
  //   });

  //   //Limit to External FOR Ws
  //   if(this.authService.hasClaims(['WSP'],false) && !this.authService.hasClaims(['PRL'],false))
  //   {
  //     this.matrixObject = [];
  //     this.matrixOptions = [];
  //     this.matrixObject.push(Globals.consts.matrixObject[0]);
  //     this.matrixOptions.push(Globals.consts.matrixOptions[1]);
  //   }
  // }

  // changemx()
  // {
  //   this.showmatrix(this.priceObj.pm_select)
  // }

  // updateobj(obj:any ,value: any,type : number)
  // {
  //   var active_pm = this.priceMatrixOptions.active

  //   //change setting
  //   this.priceObj.pm_applied_id = 1

  //   //set settings from pm object 
  //   let index = this.priceMatrixOptions[active_pm].findIndex(x => x.name == obj.name);
  //   this.priceMatrixOptions[active_pm][index].name = obj.name;
  //   if(type == 1) // If method changed = 1
  //   {
  //   this.priceMatrixOptions[active_pm][index].method = value;
  //   this.priceMatrixOptions[active_pm][index].value= Number(obj.value);
  //   }
  //   else if(type == 2) // If percentage value changed = 2
  //   {
  //     this.priceMatrixOptions[active_pm][index].method = obj.method;
  //     this.priceMatrixOptions[active_pm][index].value = Number(value.target.value);
  //   }
  // }

  // updatematrix()
  // {
  //   var rows = new Array();
  //   var active_pm=this.priceMatrixOptions.active;
  //   var pm_applied=this.priceObj.pm_applied_id;
  //   var pm_id =0;
  //   for (let i of this.priceMatrixOptions[active_pm])
  //     {
  //        var obj = {"brand": i.name,"brandId": i.sf_brand_id, "method": i.method, "value": i.value};
  //   	   rows.push(obj);
  //        pm_id=i.price_list_id;
  //     };  
  //   // console.log(this.priceMatrixOptions[active_pm])
  //   var SendData = new Plupdate();
  //   SendData.key = "ext_price",
  //   SendData.price_matrix = rows,
  //   SendData.pm_id = pm_id,
  //   SendData.pm_applied= pm_applied 
  //   this.pricelistsService.updatepricelist(SendData).subscribe(res => 
  //     {
  //         this.notification.showSuccess("Matrix Updated Successfully");
  //         this.ngOnInit();   
  //         this.logService.addAction(new LogAudit(LogAuditType.PricingMatrix, this.authService.user.user_id));
  //     },
  //     error => 
  //     {
  //       this.notification.handleError(error);       
  //     });
  // }

  setAllPercentages(pmIndex: number): void {
   this.isLoadingMatrix = true;
    const brands: PriceMatrixTreeNode[]= []
    this.pricingMatrixObj.priceMatrixList[pmIndex].priceMatrixItems.forEach((obj, index) => {
      obj.value = this.setAllValue;
      
      if (obj.methodId ) {
        brands.push(obj);
        }

    });
    this.popOverVisibility = false; // Ensure the popover is closed
    this.matrixBrandChanged(brands, pmIndex, this.setAllValue);
    this.isLoadingMatrix = false; 
  };

  toggleExpand(item: PriceMatrixTreeNode): void {
    if (item.priceMatrixOverrideItems) {
      item.expand = !item.expand;
    }
  }


  addNewRim(priceListIndex: number, brandId: number, pmId: number): void {
    this.isLoadingMatrix = true;
    const newRimDetails = this.newRim[brandId];

    const parentItem = this.pricingMatrixObj.priceMatrixList[priceListIndex].priceMatrixItems.find((brand) => brand.brandId === brandId);

    if (parentItem) {
      const newRim: PriceMatrixOverrideItem = {
        brandId: brandId,
        clientId: this.authService.user.client_id,
        pmMethodId: newRimDetails.methodId, 
        pmOverrideId: null, //will be set in the db
        rimSize: newRimDetails.size,
        value: newRimDetails.value,
        parentId: pmId,
        expand: false,
        priceListId: parentItem.priceListId,
        methodEditable: false
      };
      const brandIndex = this.pricingMatrixObj.priceMatrixList[priceListIndex].priceMatrixItems.findIndex((brand) => brand.brandId === brandId);
          try {
            this.pricingMatrixObj.priceMatrixList[priceListIndex].priceMatrixItems[brandIndex].priceMatrixOverrideItems = [
              ...(this.pricingMatrixObj.priceMatrixList[priceListIndex].priceMatrixItems[brandIndex].priceMatrixOverrideItems || []),
              newRim
            ];
          } catch (error) {
              this.notification.handleError(error);
          }
      
      this.pricelistsService.updatePmNewOverride(newRim).subscribe(
        (res) => {
          newRim.pmOverrideId = res;
          
          this.notification.showSuccess("New Rim Added Successfully");
          this.logService.addAction(new LogAudit(LogAuditType.PricingMatrix, this.authService.user.user_id));
          this.isLoadingMatrix = false;
        },
        (error) => {
          this.notification.handleError(error);
          this.pricingMatrixObj.priceMatrixList[priceListIndex].priceMatrixItems[brandIndex].priceMatrixOverrideItems.pop();
          this.isLoadingMatrix = false;
        }
      );
    }
    this.newRim[brandId] = { size: 0, methodId: 1, value: 0 };
    this.isLoadingMatrix=false;
  }

  calculateAvailableRims(brandId: number, index: number) {
    this.brandAvailableRims[brandId] = this.avaibleRims(brandId, index);
  }


  avaibleRims(brandId: number, priceListIndex: number): number[] {
    const usedRims = this.pricingMatrixObj.priceMatrixList[priceListIndex].priceMatrixItems.find((brand) => brand.brandId === brandId)?.priceMatrixOverrideItems.map((rim) => rim.rimSize) || [];
    return this.rimSizes.filter((rim) => !usedRims.includes(rim));
  }

  matrixBrandChanged(brands: PriceMatrixTreeNode[],pmListIndex: number, value: number): void{
    this.matrixDirty = true;

    brands.forEach(brand => {
        
        if (brand.pmId === null) {
          const newRule: PriceMatrixTreeNode = {
            brandName: brand.brandName,
            image: brand.image,
            methodId: brand.methodId,
            pmId: null,
            brandId: brand.brandId,
            priceListId: this.pricingMatrixObj.priceMatrixList[pmListIndex].priceListId,
            value: brand.value,
            priceMatrixOverrideItems: [],
            expand: false,
            level: 0,
            parentId: undefined,
            methodEditable: false
          };
          
            const existingItemIndex = this.updatedPmItems.findIndex((item) => item.brandId === brand.brandId && item.priceListId === this.pricingMatrixObj.priceMatrixList[pmListIndex].priceListId);
            if (existingItemIndex === -1) {
              this.updatedPmItems.push(newRule);
            } else {
              this.updatedPmItems[existingItemIndex].methodId = brand.methodId;
              this.updatedPmItems[existingItemIndex].value = value;
            }
          } else {
        const index = this.pricingMatrixObj.priceMatrixList[pmListIndex].priceMatrixItems.findIndex((brandItem) => brandItem.pmId === brand.pmId);

        if (index !== -1) {
          this.pricingMatrixObj.priceMatrixList[pmListIndex].priceMatrixItems[index].methodId = brand.methodId;
          this.pricingMatrixObj.priceMatrixList[pmListIndex].priceMatrixItems[index].value = value;

          const existingItemIndex = this.updatedPmItems.findIndex((item) => item.pmId === brand.pmId);
          if (existingItemIndex === -1) {
            this.updatedPmItems.push(this.pricingMatrixObj.priceMatrixList[pmListIndex].priceMatrixItems[index]);
          } else {
            this.updatedPmItems[existingItemIndex].methodId = brand.methodId;
            this.updatedPmItems[existingItemIndex].value = value;
          }
        }
      } 
      })

  }

  matrixOverrideChanged(override: PriceMatrixOverrideItem, pmListIndex: number, methodId: number, value: number): void {
    
    this.matrixDirty= true;
    const brandIndex = this.pricingMatrixObj.priceMatrixList[pmListIndex].priceMatrixItems.findIndex((brand) => brand.priceListId === override.priceListId);
    const rimIndex = this.pricingMatrixObj.priceMatrixList[pmListIndex].priceMatrixItems[brandIndex].priceMatrixOverrideItems?.findIndex((rim) => rim.pmOverrideId === override.pmOverrideId) ?? -1;

    if (rimIndex !== -1) {
      this.pricingMatrixObj.priceMatrixList[pmListIndex].priceMatrixItems[brandIndex].priceMatrixOverrideItems[rimIndex].pmMethodId = methodId;
      this.pricingMatrixObj.priceMatrixList[pmListIndex].priceMatrixItems[brandIndex].priceMatrixOverrideItems[rimIndex].value = value;

      const existingItemIndex = this.updatedPmOverrides.findIndex((item) => item.pmOverrideId === override.pmOverrideId);
      if (existingItemIndex === -1) {
        this.updatedPmOverrides.push(this.pricingMatrixObj.priceMatrixList[pmListIndex].priceMatrixItems[brandIndex].priceMatrixOverrideItems[rimIndex]);
      } else {
        this.updatedPmOverrides[existingItemIndex].pmMethodId = methodId;
        this.updatedPmOverrides[existingItemIndex].value = value;
      }

    }
    this.brandAvailableRims[override.brandId] = this.avaibleRims(override.brandId, pmListIndex);
  }

  updatePMList(pmListIndex: number): void {
    this.matrixDirty = false;
    this.isLoadingMatrix = true;
    this.pricelistsService.updatePM(this.updatedPmItems, this.updatedPmOverrides).subscribe(
      (res: any) => {
        this.isLoadingMatrix = false;
        const response = res as updatePMResponse[];
        this.updatedPmItems.forEach(item => {
          const brandItem = this.pricingMatrixObj.priceMatrixList[pmListIndex].priceMatrixItems.find((brand) => brand.brandId === item.brandId);
          const responseItem = response.find((res) => res.brandId === item.brandId);
          if (brandItem && responseItem) {
            brandItem.pmId = responseItem.pmId;
          }
          
        });
        this.cdr.detectChanges(); // Trigger change detection
        this.notification.showSuccess("Matrix Updated Successfully");
        this.isLoadingMatrix = false;
        this.logService.addAction(new LogAudit(LogAuditType.PricingMatrix, this.authService.user.user_id));
        this.updatedPmItems = [];
        this.updatedPmOverrides = [];
      },
      (error) => {
        this.notification.handleError(error);
        this.isLoadingMatrix = false
      }
    );
  }

  deleteOverrideItem(override: PriceMatrixOverrideItem, pmListIndex: number): void {
    this.isLoadingMatrix = true;
    const brandIndex = this.pricingMatrixObj.priceMatrixList[pmListIndex].priceMatrixItems.findIndex((brand) => brand.brandId === override.brandId);
      const rimIndex = this.pricingMatrixObj.priceMatrixList[pmListIndex].priceMatrixItems[brandIndex].priceMatrixOverrideItems.findIndex((rim) => rim.pmOverrideId === override.pmOverrideId);
      if (rimIndex !== -1) {
        this.pricingMatrixObj.priceMatrixList[pmListIndex].priceMatrixItems[brandIndex].priceMatrixOverrideItems.splice(rimIndex, 1);
    }

    if (override.pmOverrideId) {
    this.pricelistsService.deletePmOverride(override.pmOverrideId).subscribe(
      (res) => {
        this.isLoadingMatrix = false;
        this.notification.showSuccess("Rim Deleted Successfully");
        this.logService.addAction(new LogAudit(LogAuditType.PricingMatrix, this.authService.user.user_id));
        
      },
      (error) => {
        this.notification.handleError(error);
        this.isLoadingMatrix = false
      }
    );
  }
  else {
    this.notification.showSuccess("Rim Deleted Successfully");
    this.isLoadingMatrix = false;
  }
  this.isLoadingMatrix = false;
  }

  checkNavGuard(navPathAway: string): boolean {
    if (!this.matrixDirty) {
      return true;
    }
    if (!this.navPathAway) {
      this.navPathAway = navPathAway;
      this.showNavModal();
      return false;
    } else if (this.navPathAway === navPathAway) {
      return true;
    } else {
      return true;
    }
  }

  showNavModal() {
    this.isNavModalVisible = true;
    this.cdr.detectChanges();
  }

  navigateWithoutSaving() {
    this.matrixDirty = false;
    this.updatedPmItems = [];
    this.updatedPmOverrides = [];
    this.isNavModalVisible = false;
    if (this.navPathAway) {
      this.router.navigate([this.navPathAway]);
    }
  }

  navigateWithSaving(){
    this.updatePMList(this.selectedTab);
    if (this.navPathAway) {
      this.router.navigate([this.navPathAway]);
    }
  }

  pmIndexForPMDelete = 0;
  openApplyModal(pmIndex: number): void {
    if (this.pricingMatrixObj.priceMatrixList[pmIndex].applied == 0) {
      this.isApplyModalVisible = true;
      this.pmIndexForPMDelete = pmIndex;
    }
  }

  deletePriceMatrixRules(): void {
    this.isLoadingMatrix = true;

    const pmId = this.pricingMatrixObj.priceMatrixList[this.pmIndexForPMDelete].priceListId;
    const clientId = this.pricingMatrixObj.clientId;
    this.pricelistsService.deletePMList(pmId, clientId).subscribe(
      (res) => {
        this.notification.showSuccess("Matrix Deleted Successfully");
        this.logService.addAction(new LogAudit(LogAuditType.PricingMatrix, this.authService.user.user_id));
        this.isLoadingMatrix = false;
        this.pricingMatrixObj.priceMatrixList[this.pmIndexForPMDelete].priceMatrixItems.forEach(item => {
          item.pmId = null;
          item.value = 0;
          item.methodId = 1;
        });
        this.cdr.detectChanges(); // Trigger change detection
        this.pricingMatrixObj.priceMatrixList[this.pmIndexForPMDelete].applied = 0
        
      },
      (error) => {
        this.notification.handleError(error);
        this.isLoadingMatrix = false;
      }
    );
    this.isApplyModalVisible = false;
  }

  cancelApply(): void {
    this.isApplyModalVisible = false;
    this.pricingMatrixObj.priceMatrixList[this.pmIndexForPMDelete].applied = 1;
    this.cdr.detectChanges(); 
  }
}

