import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { PosQuote, VehicleReg, QuoteVehicles, PosAuditEntry, PosAuditEntryType, CustomerVehicle, VehicleMake, VehicleModel, VehicleVariant } from 'src/app/models/pos';
import { PosService } from '../../../pos.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { PosAuditService } from '../../../posaudit.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';




@Component({
  selector: 'app-pos-vehicles',
  templateUrl: './pos-vehicles.component.html',
  styleUrls: ['./pos-vehicles.component.css']
})
export class PosVehiclesComponent implements OnInit {

   //Inputs
   @Input() posQuote!: PosQuote;
   @Output() loadVehicles = new EventEmitter<boolean>();

  //SelectedVehicleIndex
  selectIndex: number = 0;
  pageTotal: number = 0;

  
  constructor(    
    private posService: PosService, 
    private notification: NotificationService,
    private auditService: PosAuditService
    ) { }


  //All Customer Vehicles
  CustomerVehicles:QuoteVehicles[] = []

  //Used For Drop Downs
  VehicleMakes : VehicleMake[] = [];
  VehicleModels:VehicleModel[]=[];
  VehicleVariants:VehicleVariant[]=[];

  //Used for Selected Values from Drop Down

  vehicleYear: string = "";
  vehicleMake: number = 0;
  vehicleModel: number = 0;
  vehicleVariant: number = 0;

  ViewVehicle: QuoteVehicles = new QuoteVehicles()


  //Forms
  addVehicle: FormGroup = new FormGroup({
    v_reg: new FormControl("",Validators.required),
    vehicleYear: new FormControl(""),
    vehicleMilage: new FormControl(""),
  });


  isLoading = false;

  ngOnInit(): void {
    this.getVehicleMakes();
  }

  ngOnChanges(changes: SimpleChanges) {
      if(changes.posQuote.firstChange == false)
      {
        if(changes.posQuote.currentValue.custVehicles.length > 0)
        {
          if(changes.posQuote.previousValue.custVehicles != changes.posQuote.currentValue.custVehicles)
          {
            this.CustomerVehicles = changes.posQuote.currentValue.custVehicles;  
            if(this.CustomerVehicles.length > 0)
            {
              if(this.posQuote.Vehicle_Customer_MappingId != null)
              {           
                this.CustomerVehicles.forEach(vehicle => {
                  if(vehicle.Vehicle_Customer_MappingId == this.posQuote.Vehicle_Customer_MappingId)
                  {
                    vehicle.isVisable = true;
                    this.selectIndex = this.CustomerVehicles.indexOf(vehicle);
                  }
                });
              }
              else
              {
                this.CustomerVehicles[0].isVisable=true;
                this.selectIndex = 0;
              }

              this.pageTotal = this.CustomerVehicles.length*10;

              //set addform defaults
              this.setVehicleForm();             
            }
            else
            {
              this.addVehicle.controls.v_reg.setValue("");
              this.addVehicle.controls.vehicleYear.setValue("");
              this.addVehicle.controls.vehicleMilage.setValue("");
            }
          }
        }
        else
        {
          this.CustomerVehicles = [];
          this.addVehicle.controls.v_reg.setValue("");
          this.addVehicle.controls.vehicleYear.setValue("");
          this.addVehicle.controls.vehicleMilage.setValue("");
          this.vehicleMake= 0;
          this.vehicleModel = 0;
          this.vehicleVariant = 0;
          this.vehicleYear = "";
        }
      }
   }
  
  setVehicleForm()
  {
    
    this.ViewVehicle = new QuoteVehicles();
    this.ViewVehicle = this.CustomerVehicles.find(x=> x.isVisable)!;
    if(this.ViewVehicle != undefined)
    {
      this.addVehicle.controls.v_reg.setValue(this.ViewVehicle.v_reg);  
      if(this.ViewVehicle.modelYear == null || this.ViewVehicle.modelYear == 0)
      {
        this.ViewVehicle.modelYear = 0;    
        this.addVehicle.controls.vehicleYear.setValue("");
      }
      else
      {
        this.addVehicle.controls.vehicleYear.setValue(this.ViewVehicle.modelYear);
      }

      if(this.ViewVehicle.vehicleMilage == null)
      {
        this.addVehicle.controls.vehicleMilage.setValue("");
        this.ViewVehicle.vehicleMilage = "";
      }
      else
      {
        this.addVehicle.controls.vehicleMilage.setValue(this.ViewVehicle.vehicleMilage);
      }

      this.vehicleMake = this.ViewVehicle.vehicleMakeId;
      this.vehicleModel = this.ViewVehicle.vehicleModelId;
      this.vehicleVariant = this.ViewVehicle.vehicleVariantId;
    
      // Trigger Dropdowns
      this.onSearchVmake(this.ViewVehicle.vehicleMakeId);
      this.onSearchVmodels(this.ViewVehicle.vehicleModelId);
    }

  }

  getVehicleMakes()
  {
    this.posService.getVehicleMakes().subscribe(res=> {
      this.VehicleMakes = res as VehicleMake[];
    });   
  }

  onSearchVmake(makeId:any)
  {
    if (makeId === undefined || makeId === null) return;
    this.vehicleMake = makeId;
    this.posService.getVehicleModels(this.VehicleMakes.find(x=> x.vehicleMakeId == makeId)?.vehicleMakeId!).subscribe(res=> {
      this.VehicleModels = res as VehicleModel[];
    }); 
  }

  onSearchVmodels(modelId:any)
  {
    if (modelId === undefined || modelId === null) return;
    this.vehicleModel = modelId;
    this.posService.getVehicleVariants(this.vehicleMake,modelId).subscribe(res=> {
      this.VehicleVariants = res as VehicleVariant[];
    });
  }

  onSearchVvariant(variantId:any)
  {
    if (variantId === undefined || variantId === null) return;
    this.vehicleVariant = variantId;
  }


  onPageIndexChange(index)
  {

    let vehicleToShow = this.CustomerVehicles[parseInt(index)-1];
    this.selectIndex = index-1;
    this.CustomerVehicles.forEach(vehicle => {
      vehicle.isVisable = false;
    });
    vehicleToShow.isVisable=true;

    //Call Set
    this.setVehicleForm();
    
    //Code to link to quote 
    this.posService.linkVcmToQuote(vehicleToShow.Vehicle_Customer_MappingId , this.posQuote).subscribe(
      val => {
        this.auditService.addAction(new PosAuditEntry(this.posQuote.quoteId, PosAuditEntryType.AddedVehicleReg, this.posQuote.custId, vehicleToShow.v_reg));
      },
      error => {
        this.notification.handleError(error);
      });
  }

  /* Vehicle Upsert */
  addVehicleCheck() {
    if (this.addVehicle.value.v_reg.length < 2) 
    {
      this.notification.showWarning("Invalid vehicle registration");
      return;
    }
    else
    {
      let veh: CustomerVehicle = new CustomerVehicle()
      veh.vehReg = this.addVehicle.value.v_reg;
      veh.tyresize =JSON.stringify(this.posQuote.width) + JSON.stringify(this.posQuote.profile) + JSON.stringify(this.posQuote.rim);
      veh.vehMilage = this.addVehicle.value.vehicleMilage;
      veh.vehMakeId = this.vehicleMake;
      veh.vehModelId = this.vehicleModel;
      veh.vehVariantId = this.vehicleVariant;
      veh.modelYear = parseInt(this.addVehicle.value.vehicleYear);    
      veh.vehWidth = this.posQuote.width;
      veh.vehProfile = this.posQuote.profile;
      veh.vehRim = this.posQuote.rim;
      veh.customerId = this.posQuote.custId;
      veh.quoteId = this.posQuote.quoteId;
      veh.vehicleMapId = this.ViewVehicle.Vehicle_Customer_MappingId;

   

      this.posService.vehicleUpsert(veh).subscribe(
      val => {
         //Emit Vehiclelog
        this.loadVehicles.emit();
        this.auditService.addAction(new PosAuditEntry(this.posQuote.quoteId, PosAuditEntryType.AddedVehicleReg, veh.customerId , veh.vehReg));
      },
      error => {
        this.notification.handleError(error);
      }
    )

      
  }


    
    
  }

  updatingCheck()
  {
    let sendUpdate = false;
    
    if(this.ViewVehicle.v_reg != this.addVehicle.value.v_reg || 
       this.ViewVehicle.modelYear != this.addVehicle.value.vehicleYear ||
       this.ViewVehicle.vehicleMilage != this.addVehicle.value.vehicleMilage ||
       this.ViewVehicle.vehicleMakeId != this.vehicleMake || 
       this.ViewVehicle.vehicleModelId != this.vehicleModel || 
       this.ViewVehicle.vehicleVariantId != this.vehicleVariant)
       {
        sendUpdate = true;
       }
  
    if(sendUpdate)
    this.addVehicleCheck();
  }


}
