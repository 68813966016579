import { Component, OnInit } from '@angular/core';
import { PricelistsService } from '../../pricelists/pricelists.service';

interface DataItem {
  brand:string;
  description:string;
  stock_code:string;
  buy_on_invoice:number;
  buy_all_in:number;
  buy_branch_cost:number;
  buy_other_one:number;
  buy_other_two:number;
  sell_low:number;
  sell_medium:number;
  sell_high:number;
}

@Component({
  selector: 'app-pamprices',
  templateUrl: './pamprices.component.html',
  styleUrls: ['./pamprices.component.less']
})
export class PampricesComponent implements OnInit {
  
  isLoading = true;
  pamdata: DataItem[];
  listOfDisplayData: DataItem[];
  brandSearchValue = '';
  descSearchValue = '';
  stockSearchValue = '';
  brandvisible = false;
  descvisible = false;
  stockvisible = false;
  
  brandSortFn = (a: DataItem, b: DataItem) => a.brand.localeCompare(b.brand);
  descSortFn = (a: DataItem, b: DataItem) => a.description.localeCompare(b.description);
  stockSortFn = (a: DataItem, b: DataItem) => a.stock_code.localeCompare(b.stock_code);

  constructor(private pricelistsService: PricelistsService) { }

  ngOnInit(): void {
    this.pricelistsService.pamprices().subscribe(
      res => 
      {     
        this.pamdata= res['pamdata'];
        this.listOfDisplayData = [...this.pamdata];
        this.isLoading = false;
      }
    )

  }


  reset(data: string): void {
    if (data=='brand') {
      this.brandSearchValue = '';
    } 
    else if (data=='description') {
      this.descSearchValue = '';
    }
    else {
      this.stockSearchValue = '';
    }
    this.search(data);
  }


  search(data: string): void {
    if (data=='brand') {
      this.brandvisible = false;
      this.stockSearchValue = '';
      this.listOfDisplayData = this.pamdata.filter((item: DataItem) => item.brand.indexOf(this.brandSearchValue.toUpperCase()) !== -1);
      this.listOfDisplayData = this.listOfDisplayData.filter((item: DataItem) => item.description.indexOf(this.descSearchValue.toUpperCase()) !== -1);
    }
    else if (data=='description') {
      this.descvisible = false;
      this.stockSearchValue = '';
      this.listOfDisplayData = this.pamdata.filter((item: DataItem) => item.brand.indexOf(this.brandSearchValue.toUpperCase()) !== -1);
      this.listOfDisplayData = this.listOfDisplayData.filter((item: DataItem) => item.description.indexOf(this.descSearchValue.toUpperCase()) !== -1);
    }
    else { // stock code search is treated differently - it is not combined with description and brand, because there will only be one line per code 
      this.stockvisible = false;
      this.descSearchValue = '';
      this.brandSearchValue = '';
      this.listOfDisplayData = this.pamdata.filter((item: DataItem) => item.stock_code.indexOf(this.stockSearchValue.toUpperCase()) !== -1);
    }

  }


}


