<div class="container-fluid col-lg-11">
    <nz-card>
        <div nz-row>
            <div nz-col>
                <!-- <button nz-button nzType="primary" (click)="refresh()"  id="infobtn" class="btn btn-md info service1" style="float: right;"> 
              <i nz-icon nzType="sync" nzTheme="outline"></i>
            </button> -->
            </div>
            <div nz-col style="text-align: center;">
                <h2><b>Replenish Your Fitment Centers</b>
                    <!--:  <button nz-button nzType="primary" class='service1 btn text-left' data-toggle="modal" data-target="#status"
        (click)="pingMyCentres()" [nzLoading]="isLoading">PING CENTRES</button>--></h2>
            </div>
            <div nz-col>
                <button nz-button nzType="primary" id="infobtn" class="btn btn-md info service1">
        <i nz-icon nzType="info-circle" nzTheme="outline"></i>
      </button>
            </div>
        </div>
    </nz-card>
</div>

<br>

<!--Main Table -->
<div class="container-fluid col-lg-11">
    <nz-spin [nzSpinning]="isLoading">
        <nz-table #branch [nzData]="BranchList" id="example">
            <thead>
                <tr>
                    <th>Branch</th>
                    <th>Email</th>
                    <th>Tel</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of branch.data">
                    <td>{{item.name}}</td>
                    <td>{{item.email}}</td>
                    <td>{{item.tel}}</td>
                    <td><button nz-button nzType="primary" class='service1 btn text-left' data-toggle="modal" data-target="#status" (click)="repCentre(item.id)" [nzLoading]="isLoading">REPLENISH</button></td>

                    <!-- <button nz-button nzType="primary" class='service1 btn text-left' data-toggle="modal" data-target="#status" (click)="configlvls(item.id)">CONFIGURE LEVELS</button></td> -->
                </tr>

            </tbody>
        </nz-table>
    </nz-spin>


</div>

<!-- <h3 id = "Loader" style="display: none;">Loading...</h3> -->
<!-- Replenish Table -->

<div class="container-fluid col-lg-11" id="repResults" style="display: None;">
    <!-- <nz-spin [nzSpinning]="isLoading"></nz-spin> -->
    <div class="flex-container" style="width: 27%; margin: 0 auto;">
        <nz-spin [nzSpinning]="isLoading"><button nz-button nzType="primary" class='service1 btn text-left' data-toggle="modal" data-target="#status" (click)="placeReplenishments()">PLACE_REPLENISHMENT</button></nz-spin>
        <p><b> : All stock items highlighted green will be replenished</b></p>
    </div>

    <hr>

    <p><span style="background-color: orange; border-radius: 10%;"><b>Yellow</b></span> = stock is not in our master stock file table [no pricing]</p>
    <p><span style="background-color: #b32400; border-radius: 10%;"><b>Red</b></span> = Warehouse does not have any stock [no pricing when WH_lvl = 0]</p>
    <p><span style="background-color: #ff6666; border-radius: 10%;"><b>Pink</b></span> = Partial replenishment available</p>
    <p><span style="background-color: Green; border-radius: 10%;"><b>Green</b></span> = Item will be replenished</p>

    <div class="container-fluid col-lg-11" id="repInvoice" style="display: None;">
        <h5>Your Relenishment has been ordered</h5>
        <nz-table #response [nzData]="orderResponse" id="example">
            <thead>
                <tr *ngFor="let item of response.data" style="text-align: center;">
                    <th>Order Reference: {{item.orderRef}}</th>
                </tr>
            </thead>

        </nz-table>
    </div>



    <nz-table #order [nzData]="branchOrder" id="example" style="border-radius: 15%;" [nzFrontPagination]="false">
        <thead style="position: sticky; inset-block-start: 0;">
            <tr>
                <th>msfId</th>
                <th>stock_code</th>
                <th>description</th>
                <th>soh</th>
                <th>base_cost</th>
                <th>min_lvl</th>
                <th>difference</th>
                <th>price_internal</th>
                <th> WH_levels</th>
                <th>Stock_Type</th>
                <th>Qty_To_Order</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of order.data">
                <td [style]="item.status">{{item.msfId}}</td>
                <td>{{item.stock_code}}</td>
                <td><b>{{item.description}}</b></td>
                <td>{{item.soh}}</td>
                <td>{{item.cost}}</td>
                <td>{{item.Min_Lvl}}</td>
                <td [style]="item.status">{{item.difference}}</td>

                <td>{{item.price_internal}}</td>
                <td [style]="item.status">{{item.wh_lvl}}</td>
                <td>{{item.sType}}</td>
                <td>
                    <div style="display: flex;">
                        <nz-spin [nzSpinning]="isLoading">
                            <span style="float: left;" *ngIf='item.status == "background-color:Green;"'>REPLENISH: </span>
                            <span style="float: left;" *ngIf='item.status == "background-color:Red;"  || item.status == "background-color:Orange;" '>-</span>
                            <span style="float: left;" *ngIf='item.status == "background-color:#FFC0CB;"'>REPLENISH: </span>
                            <div style="float: right;" *ngIf="item.price_internal>0 && item.wh_lvl>0"><button nz-button nzType="primary" (click)="removeItem(item)" style="background-color: Transparent; border: none;  border-radius: 10%;">
                <i nz-icon nzType="minus-circle" nzTheme="outline"></i></button>
                            </div>
                        </nz-spin>
                        <div style="margin: 0  auto;" *ngIf="item.price_internal>0 && item.wh_lvl>0">{{item.ordQty}}</div>
                        <nz-spin [nzSpinning]="isLoading">
                            <div style="float: right;" *ngIf="item.price_internal>0 && item.wh_lvl>0"><button nz-button nzType="primary" (click)="addItem(item)" style="background-color: Transparent; border: none; border-radius: 10%;">
                <i  nz-icon nzType="plus-circle" nzTheme="outline"></i></button>
                            </div>
                        </nz-spin>
                    </div>
                </td>
            </tr>

        </tbody>
    </nz-table>
</div>