import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ModelStock, ModelStockTyres, stockCost, wipLine,ModelStockCollection } from '../models/stock';



@Injectable({
  providedIn: 'root'
})

export class StockModelsService_Replenishment {
  //localapi.stockfinder.co.za/
  apiUrl = 'v1/stock_models/';

  constructor(private http: HttpClient) { }

  public getlastStockLoad(){
    return this.http.get(environment.ApiUrl() + this.apiUrl + "getLastStockLoad");
  }
  public getStockModels()
  {
    
    return this.http.get(environment.ApiUrl() + this.apiUrl + "getModelStock");
  }

  public getModelStockCollection()
  {
    
    return this.http.get<ModelStockCollection>(environment.ApiUrl() + this.apiUrl + "getModelStockCollection");
  }

  public surplusStock()
  {
    return this.http.get(environment.ApiUrl() + this.apiUrl + "surplusStock");
  }

  public modelStock(daysofdata: number,sales_tier:string,dosFormula: string)
  {
    return this.http.get(environment.ApiUrl() + this.apiUrl + "modelStock/"+daysofdata+"/"+sales_tier+"/"+dosFormula);
  }

  public getOutstandingStats(item: ModelStockTyres,msfId: number,daysofdata:number)
  {
    return this.http.put(environment.ApiUrl() + this.apiUrl + "model_stats/"+msfId+"/"+daysofdata,item);
  }
  

  public modelStockTyreSuggestions(size:string)
  {
    return this.http.get(environment.ApiUrl() + this.apiUrl + "modelStockTyreSuggestions/"+size);
  }

  public modelStockSizeSuggestions()
  {
    return this.http.get(environment.ApiUrl() + this.apiUrl + "modelStockSizeSuggestions");
  }

  public size_suggestions()
  {
    return this.http.get(environment.ApiUrl() + this.apiUrl + "modelStockSizeSuggestions");
  }

  public getStockList()
  {
    return this.http.get(environment.ApiUrl() + this.apiUrl + "getStockList");
  }

  public getSupplierInfo()
  {
    return this.http.get(environment.ApiUrl() + this.apiUrl + "getSupplierInfo");
  }

  public deleteModel(modelId: number)
  {
    return this.http.get(environment.ApiUrl() + this.apiUrl + "deleteModel/"+modelId);
  }

  public updateModel(newItem: ModelStockTyres)
  {
    return this.http.post(environment.ApiUrl() + this.apiUrl + "updateModel",newItem);
  }

  public addModel(newItem: ModelStock)
  {
    return this.http.post(environment.ApiUrl() + this.apiUrl + "addModel",newItem);
  }

  public getSupplierInvoice(items: ModelStock[])
  {
    return this.http.post(environment.ApiUrl() + this.apiUrl + "supplierQuote/",items);
  }

    
  // public getOutstandingStats(item: ModelStockTyres,msfId: number,daysofdata:number)
  // {
  //   return this.http.put(environment.ApiUrl() + this.apiUrl + "model_stats/"+msfId+"/"+daysofdata,item);
  // }
  public getLiveLevelsV2(collection: ModelStockCollection,supplierId_limit: number)
  {
    return this.http.post(environment.ApiUrl() + this.apiUrl + "liveStockV2/"+supplierId_limit,collection);
  }

  public getLiveLevels(items: ModelStock[])
  {
    return this.http.post(environment.ApiUrl() + this.apiUrl + "liveStock",items);
  }

  public summarise_stock(base: ModelStock[])
  {
    return this.http.post(environment.ApiUrl() + this.apiUrl + "summarise_orders/",base);
  }

  public trackAltStock(item: ModelStock)
  {
    return this.http.post(environment.ApiUrl() + this.apiUrl + "trackAltStock/",item);
  }

  public placeOrder(items: stockCost[])
  {
    return this.http.post(environment.ApiUrl() + this.apiUrl + "confirmOrder",items);
  }

  public similarityCheck(items: stockCost[])
  {
    return this.http.post(environment.ApiUrl() + this.apiUrl + "similarityCheck",items);
  }
  

  public download_as_excell(items: ModelStock[])
  {
    return this.http.post(environment.ApiUrl() + this.apiUrl + "downloadexcelldoc/",items);
    
  }

  public apply_work_in_progress(quoteIds: number[])
  {
    return this.http.post<wipLine[]>(environment.ApiUrl() + this.apiUrl + "applyWIP/",quoteIds);
  }

  public holdItem(modelId:number)
  {
    return this.http.get(environment.ApiUrl() + this.apiUrl + "hold_item/"+modelId);
  }

  public RFC_Check(items: ModelStock[],monthRange:number)
  {
    // return this.http.post(environment.ApiUrl() + this.apiUrl + "getAlerts/",items);
    return this.http.put(environment.ApiUrl() + this.apiUrl + "RFC_Check/"+monthRange,items);
    
  }

  public getLastCarryDate(item: ModelStock)
  {
    return this.http.post(environment.ApiUrl() + this.apiUrl + "getLastCarryDate",item);
  }

  public getLastCarryDateGroup(items: ModelStock[])
  {
    return this.http.post(environment.ApiUrl() + this.apiUrl + "getLastCarryDateGroup",items);
  }

  public setEscalationStatus(item: ModelStock)
  {
    return this.http.post(environment.ApiUrl() + this.apiUrl + "setEscalationStatus",item);
  }

  public revokeEscalationStatus(item: ModelStock)
  {
    return this.http.post(environment.ApiUrl() + this.apiUrl + "revokeEscalationStatus",item);
  }

  
  
  



  

}