<!--Heading-->
<div nz-row [nzJustify]="'center'">
    <div nz-col>
        <h4 class="color"><b>Leads</b></h4>
    </div>
</div>

<br>

<!--Toggles-->
<div nz-row [nzJustify]="'center'">
    <div nz-col nzSpan="22">
        <!--Graphs-->
        <nz-collapse>
            <nz-collapse-panel [nzHeader]="'Insights'" [nzActive]="false">



                <div nz-row [nzJustify]="'center'">
                    <div nz-col nzSpan="22" class="text-center bg-1">
                        <br>
                        <div>
                            <div style="display: block">
                                <canvas baseChart [data]="barChartData" [options]="barChartOptions" [plugins]="barChartPlugins" [type]="barChartType" (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
                            </div>
                            <a nz-dropdown [nzDropdownMenu]="menu1">Time Frame: {{tfs}}<i nz-icon nzType="down"></i></a>
                            <nz-dropdown-menu #menu1="nzDropdownMenu">
                                <ul nz-menu nzSelectable>
                                    <li nz-menu-item (click)="changeTimeFrame(-7)">1 week</li>
                                    <li nz-menu-item (click)="changeTimeFrame(-30)">1 month</li>
                                    <li nz-menu-item (click)=changeTimeFrame(-90)>3 months</li>
                                </ul>
                            </nz-dropdown-menu>
                            <a nz-dropdown [nzDropdownMenu]="menu2">Selected channel: {{ch}}<i nz-icon nzType="down"></i></a>
                            <nz-dropdown-menu #menu2="nzDropdownMenu">
                                <ul *ngFor="let item of chartData['channels']" nz-menu nzSelectable>
                                    <li nz-menu-item (click)="changeChannel(item)">{{item}}</li>
                                </ul>
                            </nz-dropdown-menu>
                        </div>

                    </div>
                </div>
            </nz-collapse-panel>
        </nz-collapse>

        <div class="row mt-4" style="border-top: 1px solid var(--backcolor); border-top-left-radius: 20px; border-top-right-radius: 20px; background-color: var(--backcolor);">
            <div class="col-12 text-left pt-1 pb-1">
                Filter by:
            </div>
        </div>
        <div class="row pt-3 border-box">
            <div class="col-12 pl-5 text-center" style="padding-bottom: 15px;">
                Assigned to me:
                <nz-switch [(ngModel)]="leadsService.filterAssignedToMe" (ngModelChange)="updateFilterSettings()"></nz-switch>
                &nbsp;&nbsp;&nbsp;&nbsp; Assigned to other:
                <nz-switch [(ngModel)]="leadsService.filterAssignedToOther" (ngModelChange)="updateFilterSettings()"></nz-switch>
                &nbsp;&nbsp;&nbsp;&nbsp; Unassigned:
                <nz-switch [(ngModel)]="leadsService.filterUnassigned" (ngModelChange)="updateFilterSettings()"></nz-switch>
                Only show Tentatively Accepted:
                <nz-switch [(ngModel)]="leadsService.filterOnlyTentativelyAccepted" (ngModelChange)="updateFilterSettings()"></nz-switch>
                &nbsp;&nbsp;&nbsp;&nbsp; Only show with bookings:
                <nz-switch [(ngModel)]="leadsService.filterOnlyBookings" (ngModelChange)="updateFilterSettings()"></nz-switch>
            </div>
        </div>
    </div>
</div>



<!--Tables-->
<div nz-row nz-row [nzJustify]="'center'" class="bg-1">
    <div nz-col nzSpan="22">
        <nz-tabset nzCentered nzSize="large">
            <nz-tab nzTitle="Current">
                <!----------------current leads----------------------->
                <div id='currentleads'>
                    <app-leads-current></app-leads-current>
                </div>
            </nz-tab>
            <nz-tab nzTitle="Future">
                <!---------------upcoming leads----------------------->
                <!-- <app-upcoming></app-upcoming> -->
                <app-leads-future></app-leads-future>
            </nz-tab>
            <nz-tab nzTitle="ALL">
                <!---------------Completed ----------------------->
                <!-- <app-quotes></app-quotes> -->
                <app-leads-quotes></app-leads-quotes>
            </nz-tab>
        </nz-tabset>
    </div>
</div>