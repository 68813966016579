<div class="container">
    <div class="row ">
        <div class="col-lg-12 text-center" style="text-align: center;"  >
           <h2><b>Customers</b></h2>
        </div>
    </div>
</div>
<br>
<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12" style="padding-left: 5%;padding-right: 4%;">
      <div class="container">
        <br>
        <div class="row justify-content-md-center">
            <div class="col-6">
            <nz-spin [nzSpinning]="isLoading">
                <nz-input-group nzSuffixIcon="search">
                    <input nz-input type="text" nz-input placeholder="Filter Customers" [(ngModel)]="clientFilter" (keyup)="filterCustomers()" />
                </nz-input-group>
            </nz-spin>
            </div>
        </div>
    </div>
    <br>
    <nz-spin [nzSpinning]="isLoading">
      <nz-table #cldata [nzData]="this.filteredCustomers" [nzFrontPagination]="true" [nzPageSize]="35" >
        <thead>
          <tr>
            <th>Customer</th>
            <th>DebtorAcc </th>
            <th>Email </th>
            <th>Cell</th>
            <th></th>
          </tr>
        </thead>      
        <tbody class="tbody">
        <tr *ngFor="let customer of cldata.data">
        <td>
            <div *ngIf="customer.name == '' || customer.name == null" class='text-left'> Missing Name <span nz-icon nzType="warning" nzTheme="fill" class="color-warning"></span></div>
            <button nz-button nzType="default" *ngIf="customer.name != '' && customer.name != null" (click)="editCustomer(customer)" >{{ customer.name }}</button>
        </td>
        <td>
            {{ customer.debtorAcc }}
        </td>
        <td>
            <span *ngIf="customer.email == '' || customer.email== null" > Missing Email <span nz-icon nzType="warning" nzTheme="fill" class="color-warning"></span></span>
            <span *ngIf="customer.email != '' && customer.email != null" >{{ customer.email }} </span>
        </td>
        <td>
            <span *ngIf="customer.cell == '' || customer.cell == null" > Missing Cell <span nz-icon nzType="warning" nzTheme="fill" class="color-warning"></span></span>
            <span *ngIf="customer.cell != '' && customer.cell != null" >{{ customer.cell}}</span>
        </td>
        
        <td style="text-align: center;">
          <button nz-button nzType="primary" id="btnview" (click)="viewCustomer(customer)">
            <span nz-icon nzType="eye" nzTheme="outline"></span>
          </button>
        </td>
        </tr>
        </tbody>
      </nz-table>
    </nz-spin>
    </div>
  </div>
</div>
