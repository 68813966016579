import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { callInsightsMainResult, callInsightsSearchResult, callInsightsSaleResult, callInsightsConversionResult, callInsightsSearchesDashboardResult, chartFilters} from '../models/insights';

@Injectable({
  providedIn: 'root'
})
export class InsightsService {

  apiUrl = 'v1/insights/';

  constructor(private http: HttpClient) { }

  callInsightsMain() {
    return this.http.get<callInsightsMainResult>(environment.ApiUrl() + this.apiUrl + "MainInsights");
  };
  
  callInsightsSearch() {
    return this.http.get<callInsightsSearchResult>(environment.ApiUrl() + this.apiUrl + "SearchInsights");
  };

  callInsightsSale() {
    return this.http.get<callInsightsSaleResult>(environment.ApiUrl() + this.apiUrl + "SaleInsights");
  };

  callInsightsConversion() {
    return this.http.get<callInsightsConversionResult>(environment.ApiUrl() + this.apiUrl + "ConversionInsights");
  };

  callInsightsSearchesDashboard(chartFilters: chartFilters) {
    return this.http.post<callInsightsSearchesDashboardResult>(environment.ApiUrl() + this.apiUrl + "SearchesDashboardInsights", chartFilters);
  }

  }

  



