 <div class="container-fluid">
    <div class="row ">
      <div class="col-lg-2">
      </div>
      <div class="col-lg-8 " style="text-align: center;"  >
        <h4 class="color"><b>PAM Pricing</b></h4>
      </div>
      <div class="col-lg-2">
      </div>
    </div>
  </div>


<nz-spin [nzSpinning]="isLoading"> 
  <div class="container-fluid col-lg-11">
    <nz-table #nzTable [nzData]="listOfDisplayData" [nzPageSize]="100">
        <thead>
        <tr>
          <th [nzSortFn]="brandSortFn" nzCustomFilter>
            Brand
            <nz-filter-trigger [(nzVisible)]="brandvisible" [nzActive]="descSearchValue.length > 0" [nzDropdownMenu]="brand">
            <i nz-icon nzType="search"></i>
            </nz-filter-trigger>
        <th [nzSortFn]="descSortFn" nzCustomFilter>
                Description
                <nz-filter-trigger [(nzVisible)]="descvisible" [nzActive]="descSearchValue.length > 0" [nzDropdownMenu]="description">
                <i nz-icon nzType="search"></i>
                </nz-filter-trigger>
            </th>
            <th [nzSortFn]="stockSortFn" nzCustomFilter>
              Stock Code
              <nz-filter-trigger [(nzVisible)]="stockvisible" [nzActive]="stockSearchValue.length > 0" [nzDropdownMenu]="stock_code">
              <i nz-icon nzType="search"></i>
              </nz-filter-trigger>
          </th>
          <th>Buy on invoice</th>
            <th>Buy all in cost</th>
            <th>Buy branch cost</th>
            <th>Sell price low</th>
            <th>Sell price medium</th>
            <th>Sell price high</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let data of nzTable.data">
            <td>{{ data.brand }}</td>
            <td>{{ data.description }}</td>
            <td>{{ data.stock_code }}</td>
            <td>{{ data.buy_on_invoice }}</td>
            <td>{{ data.buy_all_in }}</td>
            <td>{{ data.buy_branch_cost }}</td>
            <td>{{ data.sell_low }}</td>
            <td>{{ data.sell_medium }}</td>
            <td>{{ data.sell_high }}</td>
        </tr>
        </tbody>
    </nz-table>

    <nz-dropdown-menu #brand="nzDropdownMenu">
      <div class="ant-table-filter-dropdown">
      <div class="search-box">
          <input type="text" nz-input placeholder="Search brand" [(ngModel)]="brandSearchValue" />
          <button nz-button nzSize="small" nzType="primary" (click)="search('brand')">Search</button>
          <button nz-button nzSize="small" (click)="reset('brand')">Reset</button>
      </div>
      </div>
  </nz-dropdown-menu>
    <nz-dropdown-menu #description="nzDropdownMenu">
      <div class="ant-table-filter-dropdown">
      <div class="search-box">
          <input type="text" nz-input placeholder="Search description" [(ngModel)]="descSearchValue" />
          <button nz-button nzSize="small" nzType="primary" (click)="search('description')">Search</button>
          <button nz-button nzSize="small" (click)="reset('description')">Reset</button>
      </div>
      </div>
  </nz-dropdown-menu>
  <nz-dropdown-menu #stock_code="nzDropdownMenu">
    <div class="ant-table-filter-dropdown">
    <div class="search-box">
        <input type="text" nz-input placeholder="Search code" [(ngModel)]="stockSearchValue" />
        <button nz-button nzSize="small" nzType="primary" (click)="search('stock_code')">Search</button>
        <button nz-button nzSize="small" (click)="reset('stock_code')">Reset</button>
    </div>
    </div>
</nz-dropdown-menu>
</div>
</nz-spin>