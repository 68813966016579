import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../shared/notification.service';
import { SuppliersService } from './suppliers.service';
import { SupplierLinks } from '../models/suppliers';
import { ClientService } from '../client/client.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { LogService } from '../shared/logs.services';
import { LogAudit, LogAuditType } from '../models/logs';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.less']
})
export class SuppliersComponent implements OnInit {

  constructor(
    private notification: NotificationService,
    private supplierService: SuppliersService,
    private clientService: ClientService,
    private logService : LogService,
    private authService: AuthService
  ) { }

  suppliers: SupplierLinks[]
  isPAM: boolean = true
  testNUm = "2";
  isLoading = false;

  compareFn = (o1: any, o2: any): boolean => {    
    return (o1 && o2 ? o1.toString() === o2.toString() : o1 === o2)
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.loadSuppliers();
        
  }

  loadSuppliers()
  {
    this.supplierService.getSupplierLinkData().subscribe(res =>
      {
        this.suppliers = (res as SupplierLinks[]).sort((x,y) => x.rank - y.rank);
        this.isLoading = false;      
      },
      error => {
        this.notification.handleError(error);
      });

      this.clientService.getIsPAM().subscribe(res =>
        {
          this.isPAM = res["isPAM"];
        },
        error => {
          this.notification.handleError(error);
        });
  }


  alterRankings(event: CdkDragDrop<string[]>)
  {
    this.isLoading = true;
    this.updatelist(this.suppliers,event.previousIndex,event.currentIndex);
  }

  updatelist(array,previousIndex,currentIndex)
  {
    moveItemInArray(array, previousIndex,currentIndex);
    for(var i =0; i < this.suppliers.length; i++)
    {
      this.suppliers[i].rank = i+1;
    }
    this.updateRankings();
  }


  updateRankings()
  {
    this.supplierService.updateSupplierRankings(this.suppliers).subscribe(res =>
      {
         this.notification.showSuccess("Changes Saved");
         this.ngOnInit();
         this.logService.addAction(new LogAudit(LogAuditType.SupplierRankings, this.authService.user.user_id));
         this.isLoading = false;
      },
      error => {
        this.notification.handleError(error);
      });
  }


  addSupplierLink(sellerId){

    this.supplierService.addSupplierLink(sellerId).subscribe(res =>
      {
         this.notification.showSuccess("Successfully Activated");
         this.ngOnInit();
      },
      error => {
        this.notification.handleError(error);
      });
  }

  deleteSupplierLink(sellerId){

    this.supplierService.deleteSupplierLink(sellerId).subscribe(res =>
      {
         this.notification.showSuccess("Successfully Deleted");
         this.ngOnInit();
      },
      error => {
        this.notification.handleError(error);
      });
  }

  toggleSupplierLink(sellerId, toggle: boolean) {
    if (toggle) {
      this.addSupplierLink(sellerId);
    }
    else {
      this.deleteSupplierLink(sellerId)
    }
    
  }

  changeBuyInPricing(connectionId,buy_in_optionId){
    this.supplierService.changeBuyInPricing(connectionId,buy_in_optionId).subscribe(res =>
      {
         this.notification.showSuccess("Buy In Pricing Updated");
         this.ngOnInit();
      },
      error => {
        this.notification.handleError(error);
      });
  }

  changeSellOutPricing(connectionId,sell_out_optionId){
    this.supplierService.changeSellOutPricing(connectionId,sell_out_optionId).subscribe(res =>
      {
         this.notification.showSuccess("Sell Out Pricing Updated");
         this.ngOnInit();
      },
      error => {
        this.notification.handleError(error);
      });
  }

  
}
