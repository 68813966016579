<ng-container *ngIf="this.view == 'client_list'">
    <!-- <div nz-row>
        <div nz-col nzSpan="24"><h1>Procurement Maintenance</h1></div>
    </div>
    <hr style="border-bottom: solid 2px rgb(95, 158, 160)">
    <div nz-row>
        <div nz-col nzSpan="24" style="display:flex; justify-content: center;"><h4>Client List</h4></div>
    </div> -->
    <div nz-row>
        <div nz-col nzSpan="6">Search Which centres contain the following code: 
            <div style="display: flex;">
                <input nz-input placeholder="Search Stock Code" id="stockSearch" style="width: 70%;" class="submit trinput" autocomplete="off" />
                <button nz-button (click)="scanforcode()" nz-dropdown nzTrigger="click" [nzDropdownMenu]="codeResults"><span nz-icon nzType="search" nzTheme="outline"></span></button>
            </div>
            <nz-dropdown-menu #codeResults="nzDropdownMenu" >
                <div  nz-col nzSpan="24" style="border: solid 1px var(--backcolor); border-radius: 10px; background-color: var(--backcolor);">
                    <span style="display: flex; justify-content: center; padding-top: 2%">Code: {{this.codeSearched}} appears in the following centres<button nz-button style="position: absolute; right: 5%;">ESC</button></span>
                    <br>
                    <nz-table  #code_results [nzData]="codeSearch_Results" [nzFrontPagination]="false" nzSize="small" nzTableLayout="auto"  [nzScroll]="{'y':this.scrollHeight}"  > <!--[nzScroll]="{'y':this.scrollHeight}"-->
                        <thead>
                            <tr class="sticky-header">
                                <th style="text-align: center;">Client</th>
                                <th style="text-align: center;">Code</th>
                                <th style="text-align: center;">Description</th>
                                <th style="text-align: center;">MSL</th>
                                <th style="text-align: center;">AI_MISL</th>
                                <th style="text-align: center;">SOH</th>
                                <th style="text-align: center;">S_SOH</th>
                            
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let r of code_results.data" >
                                <td>{{r.client_name}}</td>
                                <td>{{r.stock_code}}</td>
                                <td>{{r.description}}</td>
                                <td>{{r.msl}}</td>
                                <td>{{r.suggested_msl}}</td>
                                <td>{{r.client_soh}}</td>
                                <td>{{r.supplier_soh}}</td>
                            </tr>
                        </tbody>
                    </nz-table>
                </div>
            </nz-dropdown-menu>
        </div>
        <!-- <div nz-col nzSpan="6" style="border: solid green;"><input nz-input placeholder="Search Stock Code" id="stockSearch" class="submit trinput" autocomplete="off" /></div> -->
    </div>

    <nz-spin [nzSpinning]="isLoading" style="margin-top: 1%;" *ngIf="view == 'client_list'">
    <div nz-row *ngIf="this.Client_Data.length >0">
        <div nz-col nzSpan="24">
            <nz-table  #client_lines [nzData]="Client_Data"  [nzFrontPagination]="false" [nzShowPagination]="false" nzSize="small" nzTableLayout="auto"> <!--[nzScroll]="{'y':this.scrollHeight}"-->
                <thead>
                    <tr class="sticky-header">
                        <th style="text-align: center;">Client</th>
                        <th style="text-align: center;">Account</th>
                        <th style="text-align: center;">Sell_state</th>
                        <th style="text-align: center;">Price List</th>
                        <th style="text-align: center;">SKUs</th>
                     
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let c of client_lines.data" (click)="view_client_modelStock(c)" class="clientRow">
                        <td>{{c.account.buyer_name}}</td>
                        <td>{{c.account.debtorAcc}}</td>
                        <td>{{c.account.sell_state}}</td>
                        <td>{{c.account.pricelist_name}}</td>
                        <td>{{c.replenishment_lines.length}}</td>
                   

                    </tr>
                </tbody>
            </nz-table>
        </div>
    </div>
    </nz-spin>
</ng-container>

<ng-container *ngIf="this.view != 'client_list'">
    <app-procurement-client-model-stock></app-procurement-client-model-stock>
    <!-- <div nz-row  style="border: solid green;">
        <div nz-col nzSpan="24">
            <app-procurement-client-model-stock></app-procurement-client-model-stock>
            
        </div>
    </div> -->
</ng-container>