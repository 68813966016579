<div class="text-center">
    <h5 class="text-style">{{getDayName()}}</h5>
    <div *ngFor="let item of slots" class="timeSlot">
        Time: {{item.slotTime.substring(0,5)}}
        <i nz-icon nzType="close-circle" nzTheme="fill" style="float: right; font-size: 1.5rem !important;" (click)="deleteSlot(item)"></i>
    </div>
    <div class="timeSlotAdd" (click)="onAddSlotClick()">
        <i nz-icon nzType="plus-circle" nzTheme="fill" style="font-size:1.5rem !important;  margin-right: 6px;"></i>
        Add New
        
    </div>
</div>

<nz-modal [(nzVisible)]="isAddingSlotModalVisible" nzTitle="Add Time Slot"
    (nzOnCancel)="isAddingSlotModalVisible = false" [nzFooter]="null" nzWidth="700px">

    <div *nzModalContent>
        <div class="row justify-content-md-center">
            <div class="col-10">
                <div class="input-group text-center">
                    <input nz-input [(ngModel)]="selectedTime"
                        placeholder="Time ex: 10:00" />
                    <div class="input-group-append ">
                        <button nz-button nzType="primary" (click)="onAddSlot()" [disabled]="isLoadingAddSlot || !(selectedTime.length === 5)">Add</button>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</nz-modal>
