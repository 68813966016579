import { trigger,style,animate,transition,state,group} from '@angular/animations';

export const fadeIn = 
[
  trigger( 'fade',
          [ transition('void => *',
                        [ style({ opacity: 0 }), animate(2000, style({opacity: 1})) ]
                      )
          ])];


export const posAnimation = 
[
  trigger( 'inOutAnimation',
          [ transition(':enter',
                       [ style({ height: 0, opacity: 0 }),
                         animate('0.3s ease-out',style({ height: '!', opacity: 1 }))
                       ]
                      ),
            transition(':leave',
                       [ style({ height: '!', opacity: 1 }),
                         animate('0.3s ease-out',
                         style({ height: 0, opacity: 0 }))
                       ]
                      )
          ])]
