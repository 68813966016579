import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Globals } from './shared/globals';
import { User } from './models/user';

/*
This interceptor will check if a valid JWT is active, and if so, add it to all outgoing requests via the angular httpmodule. 
If we are connecting to more than just StockFinder's API, the code will have to be updated to only send the JWT to stockfinder.co.za
*/
@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

    constructor(private authService: AuthService, private router: Router) {}
    private emptyObject: User

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.authService.isLoggedIn()){
            req = req.clone({headers: req.headers.set("Authorization", "Bearer " + this.authService.token).set("x-b2b-version",Globals.consts.VERSION_NUMBER)
                    .set("x-browser",this.getBrowserName())});
            // console.log(this.getBrowserName());
            // console.log(window.navigator.userAgent);
                     
        }
         
        return next.handle(req).pipe( tap((httpEvent: HttpEvent<any>) => {
            if(httpEvent.type === 0) {
                return;
            }   
            if (httpEvent instanceof HttpResponse) {
                if (httpEvent.headers.has("x-b2b-minversion")) {
                    if (Number(httpEvent.headers.get('x-b2b-minversion')) > Globals.consts.VERSION_NUMBER_INT) {
                        location.reload();                        
                    }
                }
            }
        },
            (err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    this.authService.clearLogin();
                    this.router.navigate(['login']);
                }
                
            }
        }));
               
    }

    private getBrowserName() {
        const agent = window.navigator.userAgent.toLowerCase()
        switch (true) {
          case agent.indexOf('edge') > -1 || agent.indexOf('edg') > -1:
            return 'edge';
          case agent.indexOf('opr') > -1 && !!(<any>window).opr:
            return 'opera';
          case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
            return 'chrome';
          case agent.indexOf('trident') > -1:
            return 'ie';
          case agent.indexOf('firefox') > -1:
            return 'firefox';
          case agent.indexOf('safari') > -1:
            return 'safari';
          default:
            return 'other';
        }
    }
}