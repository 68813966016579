import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { HelpService } from '../help/help.service';
import { Video } from '../models/video';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.less']
})
export class HelpComponent implements OnInit {

  Videos: Video[]
  selectedVideo:Video;
  isloading:boolean = false;


  constructor(private helpService: HelpService,private authService: AuthService,public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.getVideos();
  }

  getVideos()
  {
    this.isloading = true;
    this.helpService.getVideos().subscribe(res => { 
      this.Videos = res
      this.isloading = false; 
    });
    this.selectedVideo = new Video()
  }

  watch(video:Video)
  {
    this.selectedVideo = video;
    this.helpService.updatelog(video.videoId).subscribe(res => {  
      window.open(this.selectedVideo.url); 
     });
  }

  getSafeVideoUrl(url:string): SafeResourceUrl{
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  downloadPdf(video:Video) {
    FileSaver.saveAs(video.document, video.docname);
  }

}
