<div class="container">
  <div class="row ">
      <div class="col-lg-2">
      </div>
      <div class="col-lg-8 " style="text-align: center;"  >
         <h4><b>PAM</b></h4>
      </div>
      <div class="col-lg-2">
      </div>
  </div>
</div>

<div class="container-fluid">
  <p style="font-style: italic;">{{message}}</p>
    
    <a nz-dropdown [nzDropdownMenu]="menu1" [nzDisabled]="isLoading">
      Generate PAM
      <i nz-icon nzType="down"></i>
    </a>
    <nz-dropdown-menu #menu1="nzDropdownMenu">
      <ul nz-menu nzSelectable>
        <li nz-menu-item (click)="Pam('AW')">AW</li>
        <li nz-menu-item (click)="Pam('Jones')">Jones</li>
        <li nz-menu-item (click)="Pam('Prima')">Prima</li>
      </ul>
    </nz-dropdown-menu>

    <a nz-dropdown [nzDropdownMenu]="menu2" [nzDisabled]="isLoading">
      Generate Pre-PAM
      <i nz-icon nzType="down"></i>
    </a>
    <nz-dropdown-menu #menu2="nzDropdownMenu">
      <ul nz-menu nzSelectable>
        <li nz-menu-item (click)="PrePam('AW')">AW</li>
        <li nz-menu-item (click)="PrePam('Jones')">Jones</li>
        <li nz-menu-item (click)="PrePam('Prima')">Prima</li>
      </ul>
    </nz-dropdown-menu>
 
    <a nz-dropdown [nzDropdownMenu]="menu3" [nzDisabled]="isLoading">
      Update Agilis
      <i nz-icon nzType="down"></i>
    </a>
    <nz-dropdown-menu #menu3="nzDropdownMenu">
      <ul nz-menu nzSelectable>
        <li nz-menu-item (click)="Agilis('AW')">AW</li>
        <li nz-menu-item (click)="Agilis('Jones')">Jones</li>
        <li nz-menu-item (click)="Agilis('Prima')">Prima</li>
      </ul>
    </nz-dropdown-menu>
     
    <a nz-dropdown [nzDropdownMenu]="menu4" [nzDisabled]="isLoading">
      Update Standard GPs
      <i nz-icon nzType="down"></i>
    </a>
    <nz-dropdown-menu #menu4="nzDropdownMenu">
      <ul nz-menu nzSelectable>
        <li nz-menu-item (click)="StdGP('AW')">AW</li>
        <li nz-menu-item (click)="StdGP('Jones')">Jones</li>
        <li nz-menu-item (click)="StdGP('Prima')">Prima</li>
      </ul>
    </nz-dropdown-menu>

   <div *ngIf="isLoading">{{load}}</div>

  <br>
  <i nz-icon nzType="google-circle" nzTheme="fill"></i>
  <a nz-button nzType="link" href="https://drive.google.com/drive/folders/1meSyNZ5tCB-6TW6nZzs4X915ZkzYQQ-L?usp=sharing" target="_blank">Go to Google Drive folder ➚</a>

  <div class="container">
    <div class="row ">
        <div class="col-lg-2">
        </div>
        <div class="col-lg-8 " style="text-align: center;"  >
           <h6><b>Database</b></h6>
        </div>
        <div class="col-lg-2">
        </div>
    </div>
  </div>

  <button nz-button nzType="primary" (click)="storedProc('Update upcoming retail')">Update upcoming retail</button>
  <button nz-button nzType="primary" (click)="storedProc('Update retail')">Update retail</button>
  <button nz-button nzType="primary" (click)="storedProc('Update replacement cost')">Update replacement cost</button>

  <br>
  <button nz-button nzType="primary" style="position: absolute; right: 30px;" (click)="helpModal=true">Help</button>
  
  <nz-modal [(nzVisible)]="helpModal" nzTitle="Help"
   (nzOnCancel)="helpModal=false;" [nzFooter]="null" nzWidth="800px">
   <div *nzModalContent>
    <pre style="color: white;">
    - Update upcoming retail with retail - use button
    - Uploaded catalogues which change upcoming retail
    - Generate Pre-PAM
      - Upcoming retail gets used to make Pre-PAM
      - Pre-PAM gets used to update landing_replacement_cost table (removes old and maps msfid)
      - landing_replacement_cost is used to add indexing to Pre-PAM
    - Last day of month: update retail using upcoming retail - use button
    - Generate PAM (uses retail)
      - landing_replacement_cost is again updated with PAM, and is used to assign indexing to PAM
    - Update replacement_cost table using landing_replacement_cost - use button</pre>
   </div>
  </nz-modal>
</div>
