
export class Customer {
    customerId: number
    email: string
    name: string
    cell: string
    vreg: string
    totalSales: number
    debtorAcc: string
}

export class CustomerSubList {
   customerId: number
   vreg: string
}
export class CustomerList {
   customerId: number;
   email: string;
   name: string;
   cell: string;
   data: CustomerSubList[] = [];
   expand: boolean = false;
}

export class customerline{
    Brand: string
    Description: string
    Id: number
    Price: string
    Size: string
    Total: string
    qty: number
    stocktype: string
    msfid: number
 }

 export class customerObj{
    Id: number
    LineItems: customerline[]
    Price: number
    Ref: string
    buyerId:  number
    dtcreated: string
    salequote: string
    sellerId:  number
    status: string
    stateId: number
    stateType: number
    stategroup : number
    vreg: string
 }

 export class customerobj{
    customerId:number
    vreg: string
 }

 export class vehicle{
    vreg: string
    size: string
    Id: string
 }

 export class CustomerWhatsApp{
   customer: number;
   sendTo: string;
   vreg: string;
 }

