import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { Lead } from 'src/app/models/leads';
import { LeadsService } from '../leads.service';

@Component({
  selector: 'app-leads-quotes',
  templateUrl: './leads-quotes.component.html',
  styleUrls: ['./leads-quotes.component.less']
})
export class LeadsQuotesComponent implements OnInit {

  inputValue: string = "";

  custSortFn = (a: Lead[], b: Lead[]): number => String(a['customerName']).toLowerCase() < String(b['customerName']).toLowerCase() ? -1 : 1;
  saleSortFn = (a: Lead[], b: Lead[]): number => String(a['assignedTo']).toLowerCase() < String(b['assignedTo']).toLowerCase() ? -1 : 1;
  clientSortFn = (a: Lead[], b: Lead[]): number => String(a['buyerName']).toLowerCase() < String(b['buyerName']).toLowerCase() ? -1 : 1;
  dtSortFn = (a: Lead[], b: Lead[]): number => a['dtCreated'] < b['dtCreated'] ? -1 : 1;
  statusSortFn = (a: Lead[], b: Lead[]): number => String(a['statusDescription']) < String(b['statusDescription']) ? -1 : 1;

  pageSize: number = 50;

  @HostListener("window:scroll", [])
  onScroll(): void {
  if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight - (document.body.scrollHeight * 0.1)) {
          // you're at the bottom of the page
          this.pageSize += 10;
      }
  }
  resetTable(){
      this.pageSize = 50;
  }

  constructor(public leadsService: LeadsService, private router: Router, public auth: AuthService) { }

  ngOnInit(): void {
  }

  getLeads() {
    return this.leadsService.filterQuotes(this.auth.user.user_id);
  }

  viewquote(quote: Lead) {
    //check if the buyer is SB Fleet, meaning redirect to fleet page and not pos page
    // if (quote.buyerId == 674) {
    //   this.router.navigate(["/pos/fleet", quote.quoteId]);
    // } 
    // else {
    this.router.navigate(["/pos/v2", quote.quoteId]);
    // }
    
  }

}
