import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { ClientSettings, ClientReceipt, ClientPricing , ClientLogistics, ClientCart , ClientNew, ClientAdd, LinkedAccountLogin, ClientAddReturn } from './../models/client';


@Injectable({
  providedIn: 'root'
})
export class ClientService {
  apiUrl = 'v1/clients/';

  constructor(private http: HttpClient) { }

  // public getClientData()
  // {
  //   return this.http.get( environment.ApiUrl() + this.apiUrl + "getclientData");
  // }


  public getClients()
    {
      return this.http.get( environment.ApiUrl() + this.apiUrl + "getclients");
    }
  
  public getallClients()
    {
      return this.http.get( environment.ApiUrl() + this.apiUrl + "getallclients");
    }

  public editClients(buyerId)
    {
      return this.http.get( environment.ApiUrl() + this.apiUrl + "editclients/" + buyerId );
    }

  public savesettings(settings : ClientSettings)
    {
      return this.http.post(environment.ApiUrl() + this.apiUrl + "updatesettings",settings);

    }
  
  public addreciept(Receipt: ClientReceipt)
    {
      return this.http.post(environment.ApiUrl() + this.apiUrl + "addreceipts",Receipt);

    }
  
  public savepricing(Pricing: ClientPricing)
    {
      return this.http.post(environment.ApiUrl() + this.apiUrl + "updatepricing",Pricing);

    }
  
  public savelogistics(Logistics: ClientLogistics)
    {
      return this.http.post(environment.ApiUrl() + this.apiUrl + "updatelogistics",Logistics);

    }
  
  public saveordersettings(CartData: ClientCart)
    {
      return this.http.post(environment.ApiUrl() + this.apiUrl + "updatecartsettings",CartData);

    }

  public addclient(Client: ClientNew)
    {
      return this.http.post<ClientAddReturn>(environment.ApiUrl() + this.apiUrl + "addclient",Client);

    }

  public addexisting(Client: ClientAdd)
    {
      return this.http.post(environment.ApiUrl() + this.apiUrl + "addexisting",Client);

    }

  public getLinkedUsers(buyerId )
    {
      return this.http.get( environment.ApiUrl() + this.apiUrl +  "getLinkedUsers/"+ buyerId);
    }

  public addLinkedUsers(buyerId: number,userId: number)
    {
      const payload={"buyerId":buyerId,"userId":userId};
      return this.http.post(environment.ApiUrl() + this.apiUrl +  "addUserLink",payload);
    }

    public deleteLinkedUsers(buyerId: number,userId: number)
    {
      const payload={"buyerId":buyerId,"userId":userId};
      return this.http.post(environment.ApiUrl() + this.apiUrl +  "deleteUserLink",payload);
    }

    public addLinkedAccountLogin(user: LinkedAccountLogin) {
      return this.http.post(environment.ApiUrl() + "v1/users/linkedAccount",user);
    }

    public getRegions(countryId: number) {
      return this.http.get<{regionId: number, name: string}[]>(environment.ApiUrl() + `v1/public/regions/${countryId}`);
    }

    public getFranchises(countryId: number) {
      return this.http.get<{franchiseId: number, name: string}[]>(environment.ApiUrl() + `v1/public/franchises/${countryId}`);
    }

    public getIsPAM()
    {
      return this.http.get( environment.ApiUrl() + this.apiUrl + "isPAM");
    }
}
