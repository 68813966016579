import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SupplierLinks } from '../models/suppliers';


@Injectable({
  providedIn: 'root'
})
export class SuppliersService {
  apiUrl = 'v1/suppliers/';

  constructor(private http: HttpClient) { }

  public getSupplierLinkData()
  {
    return this.http.get( environment.ApiUrl() + this.apiUrl + "getSupplierLinks");
  }


  public addSupplierLink(sellerId)
    {
      const payload={"sellerId":sellerId}
      return this.http.post(environment.ApiUrl() + this.apiUrl + "addSupplierLink",payload);

    }

  public deleteSupplierLink(sellerId)
    {
      const payload={"sellerId":sellerId}
      return this.http.post(environment.ApiUrl() + this.apiUrl + "deleteSupplierLink",payload);

    }

  public changeSellOutPricing(connectionId,sell_out_optionId)
    {
      const payload={"connectionId":connectionId,"sell_out_optionId":sell_out_optionId}
      return this.http.post(environment.ApiUrl() + this.apiUrl + "changeSellOutPricing",payload);

    }

    public changeBuyInPricing(connectionId,sell_out_optionId)
    {
      const payload={"connectionId":connectionId,"buy_in_optionId":sell_out_optionId}
      return this.http.post(environment.ApiUrl() + this.apiUrl + "changeBuyInPricing",payload);

    }

    public updateSupplierRankings(suppliers: SupplierLinks[])
    {
      return this.http.post(environment.ApiUrl() + this.apiUrl + "updateSupplierRankings", suppliers)
    }
}
