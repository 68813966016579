import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { fleetobj,sb_auth_letter,tyre } from './../models/fleet'

@Injectable({
  providedIn: 'root'
})

export class FleetService {
  apiUrl = 'v1/fleet/';

  constructor(private http: HttpClient) { }

  public getfleetData(obj: fleetobj)
  {
    return this.http.post( environment.ApiUrl() + this.apiUrl + "getfleetData",  obj);
  }  

  public updatevehiclesize(obj: tyre)
  {
    return this.http.post( environment.ApiUrl() + this.apiUrl + "updatevehiclesize",  obj);
  }

  //Fleeet 
  
  public resubmit_sb_auth(order: number)
  {
    return this.http.get( environment.ApiUrl() + this.apiUrl + "SB_Auth_Resubmit/" + order);
  }

  public check_sb_auth(extref:string)
  {
    return this.http.get( environment.ApiUrl() + this.apiUrl + "SB_Auth_Check/" + extref);
  }

  public getletter_sb_auth(extref:string)
  {
    return this.http.get<sb_auth_letter[]>( environment.ApiUrl() + this.apiUrl + "SB_Auth_Letter/" +  extref );
  }

}




