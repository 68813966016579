import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientBase } from '../models/client';
import { environment } from 'src/environments/environment';
import { AdminSystemUser } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private http: HttpClient) { }

  public getClients() {
    return this.http.get<ClientBase[]>(environment.ApiUrl() + "v1/clients/admin/clients");
  }

  public getUsers(clientid: number) {
    return this.http.get<AdminSystemUser[]>(environment.ApiUrl() + `v1/users/admin/${clientid}/users`);
  }

  public updateUser(user: AdminSystemUser) {
    return this.http.put<AdminSystemUser>(environment.ApiUrl() + `v1/users/admin/user/${user.userId}`, user);
  }


}
