import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { WAMessage } from 'src/app/models/whatsapp';
import { WhatsappService } from 'src/app/whatsapp/whatsapp.service';

@Component({
  selector: 'app-admin-wa-conversation',
  templateUrl: './admin-wa-conversation.component.html',
  styleUrls: ['./admin-wa-conversation.component.less']
})
export class AdminWaConversationComponent implements OnInit {

  constructor(public waService: WhatsappService, private modal: NzModalService) { }

  isImageOpen = false;
  imageBase64: string = '';
  datePipe: DatePipe = new DatePipe(this.waService.locale);
  

  ngOnInit(): void {
  }

  clearMessage() {
    this.waService.ClearConversationAdmin();
  }

  markAsRead() {
    this.waService.MarkConversationAsReadAdmin();
  }

  public messageText: string = ''
  sendMessage() {
    if (this.messageText.length > 0) {
      this.waService.sendMessageAdmin(this.messageText);
      this.messageText = '';
    }
    
  }

  openImageModal(tplContent: TemplateRef<{}>, image: string) {
    //this.imageBase64 = b64;
    //this.isImageOpen = true;
    this.modal.create({
      nzTitle: "WhatsApp Image",
      nzContent: tplContent,
      nzFooter: null,
      nzWidth: "90%",
      nzData:{
        value:image
      }
    })
  }

  formatDateDelivery(msg: WAMessage) {
    return 'DELIVERED: ' + this.datePipe.transform(msg.dateDelivered + 'Z', 'dd MMM HH:mm');//msg.dateDelivered + 'Z' | date:'dd MMM HH:mm'
  }

  formatDateRead(msg: WAMessage) {
    return 'READ:' + this.datePipe.transform(msg.dateRead + 'Z', 'dd MMM HH:mm');//msg.dateDelivered + 'Z' | date:'dd MMM HH:mm'
  }

}
