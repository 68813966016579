import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
// import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth.service';
import { ClientBankingDetails, ClientSettings , ClientQuoteReport,quoteSettings, ClientCustomStockPackages  } from 'src/app/models/settings';
import { NotificationService } from 'src/app/shared/notification.service';
import { SettingsService } from '../settings.service';
import { ClientService } from 'src/app/client/client.service';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { resultType } from 'src/app/shared/modal-content/modal-content.component';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { Observable, Observer } from 'rxjs'

@Component({
  selector: 'app-settings-main-v2',
  templateUrl: './settings-main-v2.component.html',
  styleUrls: ['./settings-main-v2.component.less'],
  styles: [
    `
        :host ::ng-deep .upload-list-inline .ant-upload-list-item {
        float: left;
        width: 200px;
        margin-right: 8px;
      }
      :host ::ng-deep .upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
        float: right;
      }
      :host ::ng-deep .upload-list-inline .ant-upload-animate-enter {
        animation-name: uploadAnimateInlineIn;
      }
      :host ::ng-deep .upload-list-inline .ant-upload-animate-leave {
        animation-name: uploadAnimateInlineOut;
      }
    `
  ]
})
export class SettingsMainV2Component implements OnInit {
  passwordValidator: AsyncValidatorFn = (control: AbstractControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          const value = control.value
          this.settingsService.getGpProtectionPassword(value).subscribe({
            next: (res) => {
              if (!res) {
                return observer.next({ error: true, duplicated: true });
              }
              return observer.next(null);
            },
            error: (error) => {
              console.error(error);
              this.isLoading = false;
              return true;
            },
            complete: () => {
                observer.complete();
            }
          })
        }, 750);
    });

  confirmValidator: ValidatorFn = (control: AbstractControl) => {
    if(this.gpHasPassword){
      return {}
    }
    if (!control.value) {
      return { error: true, required: true };
    } else if (control.value !== this.validateRegisterPassword.controls.password.value) {
      return { confirm: true, error: true };
    }
    return {};
  };

  confirmResetValidator: ValidatorFn = (control: AbstractControl) => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (control.value !== this.validateChangePassword.controls.newPassword.value) {
      return { confirm: true, error: true };
    }
    return {};
  };

  @ViewChild('download', { static: false }) download!: ElementRef;

  settingsForm : FormGroup = new FormGroup({
    vatNr: new FormControl('', [Validators.pattern('^4[0-9]{9}$')]),
    regNr: new FormControl('',[Validators.pattern('^[0-9]{4}/[0-9]{6}/[0-9]{2}$')]),
    bankingDetails: new FormControl(''),
    liftCount: new FormControl(0),
    address: new FormControl('',Validators.required),
    zipCode: new FormControl(0, [ Validators.pattern('^[0-9]{4}$')]),
    city: new FormControl('',Validators.required),
    regionId: new FormControl('1'),
    latitude: new FormControl('', [Validators.pattern('^-?[0-9]*(\.[0-9]+)?$')]),
    longitude: new FormControl('', [Validators.pattern('^-?[0-9]*(\.[0-9]+)?$')]),
    bookingBlockedHours: new FormControl(''),
    displayCostOnJC: new FormControl('0'),
    featureLevel: new FormControl("1"),
    manageStock: new FormControl('0'),
    isInvoiceManagedSF: new FormControl('0'),
  });

  validatePassword : FormGroup = new FormGroup({
    password: new FormControl('', [Validators.required], [this.passwordValidator]),
  });

  validateRegisterPassword : FormGroup = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.pattern(/^.{4,}$/)]),
    confirm: new FormControl('', [this.confirmValidator])
  });

  validateChangePassword : FormGroup = new FormGroup({
    oldPassword: new FormControl('', [Validators.required], [this.passwordValidator]),
    newPassword: new FormControl('', [Validators.required, Validators.pattern(/^.{4,}$/)]),
    confirm: new FormControl('', [this.confirmResetValidator])
  });

  bankDetailsForm : FormGroup = new FormGroup({
    beneficiaryName: new FormControl('',  Validators.required),
    branchCode: new FormControl('',  Validators.required),
    bankName: new FormControl('',  Validators.required),
    accountType: new FormControl('',  Validators.required),
    accountNumber: new FormControl('',  [Validators.required, Validators.pattern('^[0-9]{7,11}$')]),
  })

  quoteSettingsForm : FormGroup = new FormGroup({
    quoteSoftPrint:new FormControl('0'),
    showAddressDetails:new FormControl('0'),
    showBankingDetails: new FormControl('0'),
    showQuoteImage:new FormControl('0'),
    quoteTheme:new FormControl('0'),
    customTheme: new FormControl('0'),
    quoteTerms: new FormControl(''),
    quoteImage: new FormControl(''),
    quoteColor: new FormControl('#27898b'),
    quoteQrText: new FormControl('Modify Quote Here'),
    showPosNavAway: new FormControl('0'),
    leadChannel: new FormControl('0'),
    leadType: new FormControl('0'),
    leadExpiryDays: new FormControl(null),
    defaultCustomStockPackage: new FormControl('1'),
    gpProtectionPassword: new FormControl(''),
    gpProtectionPercent: new FormControl(null)

  })

  formatterPercent = (value: number): string => value === null ? '%' : `${value}%`;
  parserPercent = (value: string): string => value.replace('%', '');
  
  timer: any;
  gpHasPassword = false;
  gpPasswordRegisterModalVisible = false;
  gpPasswordModalVisible = false;
  gpPasswordCheck = true;
  changePasswordModal = false;

  isLoading = false;
  isSaving = false;
  showBankingDetails = false;
  showAddressDetails = false;
  customtheme = false;
  displayCustomToggle = true;

  clientQuoteSettings : ClientQuoteReport = new ClientQuoteReport();
  quoteThemeOptions: ClientQuoteReport[] = []
  customStockPackages: ClientCustomStockPackages[] = []
  file : NzUploadFile
  selectedReport : string = '0'
  fileList :any[] = [];
  bankingDetailsId:number = 0;
  region : string = '';
  regions: {regionId: number, name: string}[] = [];
  selectedValue = null;
  latitude: number;
  longitude: number;


  constructor(public authService: AuthService, 
              private settingsService: SettingsService, 
              private notification: NotificationService,
              private clientService:ClientService,
              private http: HttpClient){ }

  ngOnInit(): void {
    this.isLoading= true;
    this.settingsService.getMainSettings().subscribe(
      val => {
        this.settingsForm.controls["vatNr"].setValue(val.VATNumber);
        this.settingsForm.controls["regNr"].setValue(val.RegNumber);
        this.settingsForm.controls["liftCount"].setValue(val.liftCountOnline);
        this.settingsForm.controls["address"].setValue(val.address);
        this.settingsForm.controls["zipCode"].setValue(val.zipCode);
        this.settingsForm.controls["city"].setValue(val.city);
        if (val.regionId) {
          this.settingsForm.controls["regionId"].setValue(val.regionId.toString());
        } else {
          this.settingsForm.controls["regionId"].setValue(val.regionId);
        }        
        this.settingsForm.controls["bookingBlockedHours"].setValue(val.bookingsBlockedHours)
        this.settingsForm.controls["displayCostOnJC"].setValue(val.displayCostOnJC)
        this.settingsForm.controls["manageStock"].setValue(this.authService.user.isManagedSF)
        this.settingsForm.controls["isInvoiceManagedSF"].setValue(val.isInvoiceManagedSF)
        this.settingsForm.controls["featureLevel"].setValue(val.featureLevel.toString())
        this.bankingDetailsId = val.bankingDetails.bankingDetailsId    
        
        this.settingsForm.controls["longitude"].setValue(val.lon);
        this.settingsForm.controls["latitude"].setValue(val.lat);
      
        //Get banking Details
        if(this.bankingDetailsId > 0)
        {
          this.bankDetailsForm.controls["beneficiaryName"].setValue(val.bankingDetails.beneficiaryName);
          this.bankDetailsForm.controls["bankName"].setValue(val.bankingDetails.bankName);
          this.bankDetailsForm.controls["accountType"].setValue(val.bankingDetails.accountType);
          this.bankDetailsForm.controls["accountNumber"].setValue(val.bankingDetails.accountNumber);
          this.bankDetailsForm.controls["branchCode"].setValue(val.bankingDetails.branchCode);    
        }
        else
        {
          this.bankingDetailsId = 0
        }

       //quote settings Form
       this.quoteSettingsForm.controls["showAddressDetails"].setValue(val.showAddressDetails);
       this.quoteSettingsForm.controls["quoteImage"].setValue(this.clientQuoteSettings.quoteLogo);
       this.quoteSettingsForm.controls["showBankingDetails"].setValue(val.showBankingDetails);
       this.quoteSettingsForm.controls["showQuoteImage"].setValue(val.showQuoteImage);
       this.quoteSettingsForm.controls["quoteSoftPrint"].setValue(val.quoteSoftPrint);
       this.quoteSettingsForm.controls["quoteTheme"].setValue(val.quoteSettings.reportDataId);
       this.quoteSettingsForm.controls["customTheme"].setValue(val.quoteSettings.isCustom);
       this.quoteSettingsForm.controls["showPosNavAway"].setValue(val.showPosNavAway);
       this.quoteSettingsForm.controls["leadChannel"].setValue(val.leadChannel)
       this.quoteSettingsForm.controls["leadType"].setValue(val.leadType)
       this.quoteSettingsForm.controls["leadExpiryDays"].setValue(val.leadExpiryDays);
       this.quoteSettingsForm.controls["defaultCustomStockPackage"].setValue(val.defaultCustomStockPackage.toLocaleString());
       this.gpHasPassword = val.hasGpProtection;
       this.quoteSettingsForm.controls["gpProtectionPercent"].setValue(val.gpProtectionPercent);
       this.customStockPackages = val.customStockPackages
       this.quoteThemeOptions = val.quoteThemeOptions
       this.clientQuoteSettings = val.quoteSettings

      //  Add a null value for selection for custom stock packages without altering select statement from sql.

       this.customStockPackages.unshift({"customStockGroupId": 0, customStockGroupName: 'No package (None)'})

       if(this.authService.hasClaims(['BGP']) && this.gpHasPassword)
        this.gpPasswordCheck = false;

      //  Adds string of 'Default' to Auto Quote as that is the standard default.

       this.customStockPackages.map(obj => {
        if (obj.customStockGroupId == 1) {
         obj.customStockGroupName= obj.customStockGroupName + ' (Default)';
        }
        return obj;
       })
       
       this.customtheme = this.clientQuoteSettings.isCustom;

       //push to theme Options if Custom Theme
       if(this.customtheme == true)
       {
         this.quoteSettingsForm.controls["quoteTerms"].setValue(this.clientQuoteSettings.quoteTerms);
         this.quoteSettingsForm.controls["quoteColor"].setValue(this.clientQuoteSettings.clientColor);
         this.quoteSettingsForm.controls["quoteImage"].setValue(this.clientQuoteSettings.quoteLogo);
       }
       let addCustom = true;
       for(var i = 0; i < this.quoteThemeOptions.length; i++)
       {
        
        if(this.quoteThemeOptions[i].customClientId == this.authService.user.client_id)
        {
          addCustom = false;
        }
       }
       if (addCustom)
       {
         let customThemeOption = new ClientQuoteReport()
         customThemeOption.reportDataId = 0;
         customThemeOption.customClientId = this.authService.user.client_id;
         customThemeOption.name = this.authService.user.client_name;
         customThemeOption.isFranchise = false;
         this.quoteThemeOptions.push(customThemeOption);
       }

      //Get Regions
      this.clientService.getRegions(1).subscribe(
        val_2 => {
          this.regions = val_2;
        },
        error => {
          this.notification.handleError(error);
        });

      //Disable Spinner
       this.isLoading = false;
      },
      error => {
        this.isLoading= false;
        this.notification.handleError(error);
      }
    )
  }

  parseClientName(): string {
    return this.authService.user.client_name.replace(/ /g,'+',);
  }

  showSuccess(msg: string) {
    this.notification.showSuccess(msg + ' copied to clipboard!')
  }

  getClipboardLink() : string {
    return 'https://shop.stockfinder.co.za/l/' + this.authService.user.client_id.toString();
  }

  downloadQrCode(): void {
    
    const canvas = document.getElementById('download')?.querySelector<HTMLCanvasElement>('canvas');
    if (canvas) {
      this.download.nativeElement.href = canvas.toDataURL('image/png');
      this.download.nativeElement.download = 'Whatsapp Qr Code';
      const event = new MouseEvent('click');
      this.download.nativeElement.dispatchEvent(event);
    }
  }

  showNotice()
  {
    this.submitSettingsForm();
      this.notification.ShowAlert({type: resultType.Information,
        title: "Setting Change",
        htmlMessage: 'This change will only occur on next login,Click below to log out',
        maskClosable: false,
        autoCloseInMilliseconds: 10000,
        btnText: 'Logout'}, undefined, () => {
          localStorage.removeItem("clientData");
          localStorage.removeItem("access_token");
          sessionStorage.clear();
          location.reload();
        });
  }

  triggerBank()
  {
    if(this.bankDetailsForm.value.showBankingDetails == true && this.bankDetailsForm.invalid)
    {
      Object.values(this.bankDetailsForm.controls).forEach(control => {
        if (control.invalid)
        {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  triggerAddress()
  {
    if(this.settingsForm.value.showAddressDetails == true)
    {
      if(this.settingsForm.value.address == '' || (this.settingsForm.value.zipCode == '' || this.settingsForm.value.zipCode == null ) || this.settingsForm.value.city == '')
        Object.values(this.settingsForm.controls).forEach(control => {
          if (control.invalid)
          {
            control.markAsDirty();
            control.updateValueAndValidity({ onlySelf: true });
          }
        });
        return
    }
  }

  submitSettingsForm() {
    this.isSaving = true;
    let model = new ClientSettings()

    if (!this.settingsForm.controls.vatNr.valid) {
      this.notification.showWarning("Please make sure VAT number is correct and try again. VAT numbers should start with 4 and be 10 digits long.");
      this.isSaving = false;
      return;
    }
    if (!this.settingsForm.controls.regNr.valid) {
      this.notification.showWarning("Please make sure registration number is correct and try again. Registration numbers should be in the format 1234/123456/12.");
      this.isSaving = false;
      return;
    }

    if (!this.settingsForm.controls.latitude.valid || !this.settingsForm.controls.longitude.valid){
      this.notification.showWarning("Please make sure latitude and longitude values are correct and try again");
      this.isSaving = false;
      return;
    }

    if (!this.settingsForm.controls.zipCode.valid) {
      this.notification.showWarning("Please make sure zip code is correct and try again. ZIP codes should be 4 digits long.");
      this.isSaving = false;
      return;
    }

    if (!this.bankDetailsForm.controls.accountNumber.valid) {
      this.notification.showWarning("Please make sure account number is correct and try again");
      this.isSaving = false;
      return;
    }
    
    model.VATNumber = this.settingsForm.value.vatNr;
    model.RegNumber = this.settingsForm.value.regNr;
    model.liftCountOnline = this.settingsForm.value.liftCount;
    model.address = this.settingsForm.value.address;
    model.zipCode = this.settingsForm.value.zipCode;
    model.city = this.settingsForm.value.city;
    model.regionId = Number(this.settingsForm.value.regionId);
    model.bookingsBlockedHours = this.settingsForm.value.bookingBlockedHours;
    model.displayCostOnJC = this.settingsForm.value.displayCostOnJC;
    model.manageStock = !this.settingsForm.value.manageStock;
    model.isInvoiceManagedSF = this.settingsForm.value.isInvoiceManagedSF;
    model.featureLevel = this.settingsForm.value.featureLevel;
    model.showBankingDetails = this.bankDetailsForm.value.showBankingDetails;  
    model.showAddressDetails = this.settingsForm.value.showAddressDetails;
    model.lon = this.settingsForm.value.longitude;
    model.lat = this.settingsForm.value.latitude;

    
    
    if(this.bankDetailsForm.valid)
    {
      model.bankingDetails = new ClientBankingDetails();
      model.bankingDetails.bankingDetailsId = this.bankingDetailsId;
      model.bankingDetails.beneficiaryName = this.bankDetailsForm.value.beneficiaryName;
      model.bankingDetails.bankName = this.bankDetailsForm.value.bankName;
      model.bankingDetails.branchCode = Number(this.bankDetailsForm.value.branchCode);
      model.bankingDetails.accountType = this.bankDetailsForm.value.accountType;
      model.bankingDetails.accountNumber = this.bankDetailsForm.value.accountNumber;
      model.bankingDetails.clientId = this.authService.user.client_id;
    }
    this.settingsService.updateMainSettings(model).subscribe(
      () => {
        this.notification.showSuccess("Settings updated");
        this.isSaving = false;
        this.ngOnInit();
      },
      error => {
        this.isSaving = false;
        this.notification.handleError(error);
      }
    )
  }

  beforeUpload = (file: NzUploadFile,$event:any): boolean => {
    this.fileList = this.fileList.concat(file);
    this.file = this.fileList[0]; //$event.target.files[0]
    return false;
  };

  changeTheme($event)
  {
      let selectedTheme = this.quoteThemeOptions.find(x=> x.reportDataId == $event);
      this.quote.isFranchise = selectedTheme?.isFranchise!;
      if(selectedTheme?.customClientId == this.authService.user.client_id)
      {
        this.customtheme = true;
        this.quoteSettingsForm.controls["quoteTerms"].setValue(selectedTheme.quoteTerms);
        this.quoteSettingsForm.controls["quoteColor"].setValue(selectedTheme.clientColor);
        this.quoteSettingsForm.controls["quoteImage"].setValue(selectedTheme.quoteLogo);
      }
      else
      {
        this.customtheme = false;
      }
  }

  quote = new quoteSettings()
  updateQuote()
  {
    if(this.quoteSettingsForm.valid)
    {
      this.quote.clientId  = this.authService.user.client_id
      this.quote.clientName = this.authService.user.client_name
      this.quote.quoteColor = this.quoteSettingsForm.value.quoteColor
      this.quote.quoteTerms = this.quoteSettingsForm.value.quoteTerms
      this.quote.quoteTheme = Number(this.selectedReport);
      this.quote.quoteSoftPrint = this.quoteSettingsForm.value.quoteSoftPrint
      this.quote.showAddressDetails = this.quoteSettingsForm.value.showAddressDetails
      this.quote.showBankingDetails = this.quoteSettingsForm.value.showBankingDetails
      this.quote.showQuoteImage = this.quoteSettingsForm.value.showQuoteImage
      this.quote.quoteQrText  = this.quoteSettingsForm.value.quoteQrText
      this.quote.showPosNavAway = this.quoteSettingsForm.value.showPosNavAway
      this.quote.leadChannel= this.quoteSettingsForm.value.leadChannel
      this.quote.leadType= this.quoteSettingsForm.value.leadType
      this.quote.leadExpiryDays =  Number(this.quoteSettingsForm.value.leadExpiryDays)
      this.quote.defaultCustomStockPackageId = Number(this.quoteSettingsForm.value.defaultCustomStockPackage)
      this.quote.gpProtectionPercent = typeof(this.quoteSettingsForm.value.gpProtectionPercent) === 'number' ? Number(this.quoteSettingsForm.value.gpProtectionPercent) : null;
      if(this.quoteThemeOptions.find(x=> x.reportDataId ==  Number(this.selectedReport))?.customClientId == this.authService.user.client_id)
      {
        this.quote.customTheme = true;
      }
      if(this.quote.isFranchise == undefined)
      {
        this.quote.isFranchise = false;
      }

      const formData = new FormData();
      if(this.fileList.length>0)
      {
        formData.append('file',  this.fileList[0],  this.fileList[0].name);
      }
      formData.append('quote',JSON.stringify(this.quote));
      this.settingsService.updateQuote(formData).subscribe(res => {
          this.notification.showSuccess("Settings Updated Successfully");
          this.fileList = [];
          this.ngOnInit();
      });

    }
    else
    {
      this.notification.showWarning("Please Make Sure All Settings have been set Correctly");
    }
  }
  
   getLocation(){
      this.isLoading= true;
      this.settingsService.getPosition().subscribe({
        next: (pos) => {
          this.latitude = pos.coords.latitude;
          this.longitude = pos.coords.longitude;
          this.settingsForm.controls["longitude"].setValue(this.longitude);
          this.settingsForm.controls["latitude"].setValue(this.latitude);
          this.isLoading= false;
          },
        error: (error) => {
          console.error(error);
          this.isLoading = false;
        }
      });  
   }

   registerPassword(): void {
      this.settingsService.updateGpProtectionPassword(this.validateRegisterPassword.value.password).subscribe(
        res => {
          this.notification.showSuccess('GP protection password has been updated!');
          this.gpPasswordCheck = false;
          this.gpPasswordRegisterModalVisible = false;
          this.gpHasPassword = true;
        }, 
        error => {
          this.notification.handleError(error);
        }
      ); 
    this.validateRegisterPassword.reset();
  }

  validateConfirmPassword(form: FormGroup<any>): void {
    setTimeout(() => form.controls.confirm.updateValueAndValidity());
  }

  changePassword(){
    this.settingsService.changeGpPercentPassword(this.validateChangePassword.value.oldPassword, this.validateChangePassword.value.newPassword).subscribe({
      next: (res) => {
        this.isLoading = false;
        this.changePasswordModal = false;
        this.gpPasswordCheck = false;
        this.validateChangePassword.reset();
      },
      error: (error) => {
        console.error(error);
        this.isLoading = false;
        return true;
      }
    })
  }

  activateGPProtection(){
    if(this.gpHasPassword){
      this.gpPasswordModalVisible = true;
    }
    else if (!this.gpHasPassword)
      this.gpPasswordRegisterModalVisible = true;
  }

  enableGPProtection(){
    this.gpPasswordModalVisible = false;
    this.gpPasswordCheck = false;
    this.validatePassword.reset();
  }
}


