import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MappedItems, MappedStock, NMSF_Add_Response, NmsfItem, NmsfMetaHolder, Stock, StockData } from '../models/stock';


@Injectable({
  providedIn: 'root'
})

export class StockService {
  //localapi.stockfinder.co.za/
  apiUrl = 'v1/stock/';

  constructor(private http: HttpClient) { }

  //Get Client Unmapped Stock
  public getUnmapedStock()
  {
    return this.http.get<Stock[]>(environment.ApiUrl() + this.apiUrl + "unmappedStock");
  }
  
  //Get mapped Stock
  public getMapedStock()
  {
    return this.http.get<MappedItems>(environment.ApiUrl() + this.apiUrl + "mappedStock");
  }
  //Get active stock from NMSF
  public getActiveStock()
  {
    return this.http.get<Stock[]>(environment.ApiUrl() + this.apiUrl + "activeStock");
  }

  //Get Override Rules
  public getStock()
  {
    return this.http.get(environment.ApiUrl() + this.apiUrl + "getStock");
  }

  public addNewRule(rules)
  {
    return this.http.post<StockData>(environment.ApiUrl() + this.apiUrl + "addRule", rules);
  }

  public removeItem(item){
    return this.http.post(environment.ApiUrl() + this.apiUrl + "removeItem", item);
  }
  public editItem(item: StockData){
    return this.http.post(environment.ApiUrl() + this.apiUrl + "editItem", item);
  }


  //Client to Map Stock to NMSF
  public postMapStock(mappedStock: MappedStock)
  {
    return this.http.post(environment.ApiUrl() + this.apiUrl + "mapStockCodes", mappedStock);
  }
  // Re-Unamp Stock 
  public reUnmapStock(Stock: Stock)
  {
    return this.http.post(environment.ApiUrl() + this.apiUrl + "reUnmapStockCode", Stock);
  }

  //Delete mapping
  public postDeleteMapping(mappedStock: MappedStock)
  {
    return this.http.post(environment.ApiUrl() + this.apiUrl + "deleteMapping", mappedStock);
  }


  // Fetch Stock Meta Data
  public getStockMetaData()
  {
    return this.http.get<NmsfMetaHolder>(environment.ApiUrl() + this.apiUrl + "getNmsfMetaData");
  }

  public addStockItemNMSF(nmsfItem:NmsfItem)
  {
    return this.http.post<NMSF_Add_Response>(environment.ApiUrl() + this.apiUrl + "addNmsfItem",nmsfItem);
  }

  public addStockItemNMSFDirect(nmsfItem:NmsfItem)
  {
    return this.http.post<NMSF_Add_Response>(environment.ApiUrl() + this.apiUrl + "addNmsfItemDirect",nmsfItem);
  }

  public addNmsfItemMapping(nmsfItem:NmsfItem)
  {
    return this.http.post(environment.ApiUrl() + this.apiUrl + "addNmsfItemMapping",nmsfItem);
  }

  


}
