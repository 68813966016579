import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LinkedAccount } from '../models/user';
import { environment } from 'src/environments/environment';
import { AdminSystemUser } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class AllUserService {

  constructor(private http: HttpClient) { }

  public getClients() {
    return this.http.get<LinkedAccount[]>(environment.ApiUrl() + "v1/users/linkedAccounts");
  }

  public getUsers(clientid: number) {
    return this.http.get<AdminSystemUser[]>(environment.ApiUrl() + `v1/users/${clientid}/allUsers`);
  }

  public updateUser(user: AdminSystemUser) {
    return this.http.put<AdminSystemUser>(environment.ApiUrl() + `v1/users/admin/user/${user.userId}`, user);
  }
}
