<nz-spin [nzSpinning]="isLoading">
    <div class="container-fluid bg-1">
        <div class="row justify-content-center">
            <div class="col-lg-3">
                <div *ngIf="this.isMapped" class="text-center float-left">
                    <p class="btn info ">
                        <i nz-icon nzType="car" nzTheme="outline" class="back"></i> {{ this.vehicle.tyre_size }}<br>
                    </p>
                </div>
                <button nz-button nzType="primary" (click)="openModal()"> 
                    <span nz-icon nzType="edit" nzTheme="outline"></span>
                    {{this.btntext}}
                </button>
                <p></p>
            </div>
            <div class="col-lg-6 text-center">
                <div class="maindiv  btn info custom ">
                    <span><i nz-icon nzType="user" nzTheme="outline" class="back"></i> {{ this.fleet.name }}  /   {{ this.customer.cell }} </span><br>
                    <span><i nz-icon nzType="user" nzTheme="outline" class="back"></i> {{ this.customer.name }} </span><br>
                    <span><i nz-icon nzType="mail" nzTheme="outline" class="back"></i> {{ this.customer.email }} </span>
                </div>
            </div>
            <div class="col-lg-3 text-center">
                <button nz-button nzType="primary" *ngIf="this.isMapped" id="infobtn" (click)="autoquote()"> 
                    <span nz-icon nzType="export" nzTheme="outline"></span>
                    Auto Quote
                </button>
                <div class="btn info custom float-right ">
                    Reg - <span class="back"> {{ this.vehicle.reg }} </span>
                </div>
            </div>
        </div>
    </div>
</nz-spin>
<p></p>
<div class="container-fluid  text-center">
    <div class="row">
        <div class="col-lg-12" style="padding-left: 5%;padding-right: 4%;">
            <nz-table #lines [nzData]="fleetObjects" [nzFrontPagination]="false">
                <thead>
                    <tr>
                        <th>Lead Type</th>
                        <th>Date</th>
                        <th>Tyre Size</th>
                        <th>Tyre Brands</th>
                        <th>Total <small class="text-style">(Vat Excl)</small></th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody class="tbody">
                    <tr *ngFor="let item of lines.data ">
                        <td>
                            <span *ngIf="item.salequote == 'quote'">
                                <span nz-icon nzType="file" nzTheme="outline" class="back"></span>Quote
                            </span>
                            <span *ngIf="item.salequote == 'sale'">
                                <span nz-icon nzType="sf:invoceDollarSVG" nzTheme="outline" class="text-success" ></span> Sale
                            </span>
                        </td>
                        <td>
                            {{ item.dtcreated }}
                        </td>
                        <td>
                            <div *ngFor="let item of item.LineItems; let i=index">
                                <span *ngIf="i<1">
                                    <span *ngIf="item.stocktype == 'TYRE'">
                                        {{ item.Size }}
                                    </span>
                                <span *ngIf="item.stocktype == 'NONE STOCK'">
                                        Service
                                    </span>
                                </span>
                            </div>
                        </td>
                        <td>
                            <span *ngFor="let line of item.LineItems">
                                <img *ngIf="line.stocktype == 'TYRE'" style='margin-left: 1%;border-radius: 10px;' height='25' width='50' src='assets/Images/brands/{{line.Brand}}'>   
                                <img *ngIf="line.stocktype == 'NONE STOCK'" style='margin-left: 1%;border-radius: 10px;' height='25' width='50' src='assets/Images/brands/service.jpg'>                            
                        </span>
                        </td>
                        <td>
                            <span *ngIf="item.sellerId != authService.user.client_id">
                                <button nz-button nzType="primary">External</button> 
                            </span>
                            <span *ngIf="item.sellerId == authService.user.client_id">
                                <button nz-button nzType="primary">
                                    <span class="text-info-style">R </span> {{ item.Price | number }}
                            </button>
                            </span>
                        </td>
                        <td>
                            <span *ngIf="item.sellerId != authService.user.client_id">
                                <button nz-button nzType="primary">External</button> 
                            </span>
                            <span *ngIf="item.sellerId == authService.user.client_id">
                                <span *ngIf="item.salequote == 'quote'">
                                    <span *ngIf="item.stateId == 1 || item.stateId == 2">
                                        Work In Progress
                                        <i nz-icon nzType="hourglass" nzTheme="outline" class="text"></i>
                                    </span>
                            <span *ngIf="item.stateId == 3">
                                        <span *ngIf="item.stateType == 1">
                                            {{ item.status }} 
                                            <i nz-icon nzType="check" nzTheme="outline" class="text"></i>
                                        </span>
                            <span *ngIf="item.stateType == 0">
                                            {{ item.status }}
                                            <i nz-icon nzType="check" nzTheme="outline" class="text-danger-style"></i>
                                        </span>
                            </span>
                            </span>
                            <span *ngIf="item.salequote == 'sale'">
                                    <span *ngIf="item.stategroup == 1">        
                                        {{ item.status }}
                                        <i nz-icon [nzType]="'loading'" class="text-warning-style"></i>
                                    </span>
                            <span *ngIf="item.stategroup == 2">
                                        {{ item.status }}
                                        <i nz-icon nzType="check" nzTheme="outline" class="text"></i>
                                    </span>
                            <span *ngIf="item.stategroup == 3">
                                        {{ item.status }}
                                        <i nz-icon nzType="close" nzTheme="outline" class="text-danger-style"></i>
                                    </span>
                            <span *ngIf="item.stategroup == 4">
                                        <i nz-icon nzType="check" nzTheme="outline" class="text"></i>
                                        {{ item.status }}
                                    </span>
                            </span>
                            </span>
                        </td>
                        <td>
                            <span *ngIf="item.sellerId != authService.user.client_id">                         
                            </span>
                            <span *ngIf="item.sellerId == authService.user.client_id">
                                <span *ngIf="item.salequote == 'quote'">
                                    <button nz-button nzType="primary" (click)="route(item.Id)">
                                        <span nz-icon nzType="sf:externalSVG" nzTheme="outline" ></span>
                            </button>
                            </span>
                            <span *ngIf="item.salequote == 'sale'">
                                    <button nz-button nzType="primary" (click)="routesale(item.Id)">
                                        <span nz-icon nzType="sf:externalSVG" nzTheme="outline" ></span>

                            </button>
                            </span>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </nz-table>
        </div>
    </div>
</div>

<nz-modal [(nzVisible)]="isSizeModalVisible" nzClosable="true" nzTitle="Update Tyre Details" (nzOnCancel)="isSizeModalVisible = false;" (nzOnOk)="onSizeUpdate()" [nzFooter]="null">
    <div *nzModalContent>
        <form [formGroup]="sizeForm" class="text-center">
            <nz-form-item>
                <nz-form-control>
                    <label class="text-style">Width</label>
                    <input nz-input name="width" formControlName="width" placeholder="Tyre Width">
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control>
                    <label class="text-style">Profile</label>
                    <input pattern="^\d{1,2}$" maxlength="2" nz-input name="profile" formControlName="profile" placeholder="Tyre Profile">
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control>
                    <label class="text-style">Rim</label>
                    <input pattern="^\d{1,4}$" maxlength="4" nz-input name="rim" formControlName="rim" placeholder="Tyre Rim">
                </nz-form-control>
            </nz-form-item>
        </form>
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-2 offset-5">
                    <button nz-button nzType="primary" (click)="onSizeUpdate()">Save</button>
                </div>
            </div>
        </div>
    </div>
</nz-modal>