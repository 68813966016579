import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { clientStock,unsentOrders,sentOrders, api_clients } from '../models/diagnostic';


@Injectable({
  providedIn: 'root'
})

export class DiagnosticService {
  apiUrl = 'v1/diagnostic/';

  constructor(private http: HttpClient) { }

  public getStockLoad()
  {
    return this.http.get(environment.ApiUrl() + this.apiUrl + "currentStockLoad");
  }

  public getAPI_Clients()
  {
    return this.http.get(environment.ApiUrl() + this.apiUrl + "apiTest");
  }

  public scan_client(id)
  {
    return this.http.get(environment.ApiUrl() + this.apiUrl + "scanOne/"+id);
  }



  

}




