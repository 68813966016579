
export class Customer {
    customerId: number
    email: string
    name: string
    cell: string
    vreg: string
    totalSales: number
    debtorAcc: string
}

export class CustomerSubList {
   customerId: number
   vreg: string
}
export class CustomerList {
   customerId: number;
   email: string;
   name: string;
   cell: string;
   data: CustomerSubList[] = [];
   expand: boolean = false;
}

export class customerline{
    Brand: string
    Description: string
    Id: number
    Price: string
    Size: string
    Total: string
    qty: number
    stocktype: string
    msfid: number
 }

 export class customerObj{
    Id: number
    LineItems: customerline[]
    Price: number
    Ref: string
    buyerId:  number
    dtcreated: string
    salequote: string
    sellerId:  number
    status: string
    stateId: number
    stateType: number
    stategroup : number
    vreg: string
 }

 export class customerobj{
    customerId:number
    vreg: string
 }

 export class vehicle{
    vreg: string
    size: string
    Id: string
 }

 export class CustomerWhatsApp{
   customer: number;
   sendTo: string;
   vreg: string;
 }

export class SubCustomer{
   customerId: number
   email: string
   name: string
   cell: string
}

export class AddSubCustomer{
   parentCustomerId: number
   subCustomerId: number
}

export class AddCustomerResponse{
   customerId: number
   vreg: string
}

export class LinkedAndSubCustomers{
   linked: Customer[]
   sub: SubCustomer[]
}

export class NewCustomer{
   customerId: number
   name: string
   email: string
   cell: string
   debtorAcc: string
   VATNumber: string
   parentCustomerId: number
}

export class CustomerSubCustomer{
   customerId: number;
   name: string;
   cell: string;
   email: string;
   subcustomers?: CustomerSubCustomer[];  // Nested customers
}

export class CustomerNode {
   name: string;
   customerId: number;
   cell: string;
   email: string;
   disabled?: boolean;
   subcustomers?: CustomerNode[];
 }

 export class FlatCustomerNode {
   expandable: boolean;
   name: string;
   customerId: number;
   cell: string;
   email: string;
   level: number;
   disabled: boolean;
 }

 export class ParentCustomer{
   customerId: number;
   name: string;
   cell: string;
   email: string;
 }

 export class Communities {
   customerGroupId: number;
   groupName: string;
   groupDescription: string;
   canAddMembers: boolean;
   canRemoveMembers: boolean;
   hasCustomerLink: boolean = false;
 }

 export class FlatCommunityNode {
   expandable: boolean;
   name: string;
   groupId: number;
   level: number;
   disabled: boolean;
 }

 export class CommunityNode {
   name: string;
   groupId: number;
   disabled?: boolean;
   community?: CommunityNode[];
 }

 export class CustomerDetails{
   customerId: number;
   groupId: number;
   isActive: boolean;
 }