<nz-card>
  <div nz-col nzSpan="24">
    <nz-select
      nzShowSearch 
      nzAllowClear 
      nzPlaceHolder="Select a client" 
      [(ngModel)]="selectedClient"
      [nzShowArrow]="false"
      (ngModelChange)="onClientChange($event)"
      [nzLoading]="isLoadingClients">
    
      <nz-option *ngIf="isLoadingClients" nzCustomContent>
        <span nz-icon nzType="loading" class="loading-icon"></span>
        Loading Data...
      </nz-option>
    
      <ng-container *ngIf="!isLoadingClients">
        <nz-option *ngFor="let client of clientList" [nzLabel]="client.name + ' (' + client.unmappedStock + ')'" [nzValue]="client">
        </nz-option>
      </ng-container>
    </nz-select>
  

      <nz-tabset nzCentered nzSize="large" [(nzSelectedIndex)]="tabIndex">
        <nz-tab nzTitle="Unmapped">
          <div nz-row [nzJustify]="'center'">
            
            <div nz-col nzSpan="12" class="search-bar-container">       
              <nz-input-group  [nzSuffix]="suffixIconSearch" class="mobile-view">
                <input type="text" nz-input placeholder="Advanced Filter" [(ngModel)]="input.inputValue" (ngModelChange)="setAdvancedFilterInput(input.inputValue)" style="text-transform:uppercase;" />
              </nz-input-group>
              <ng-template #suffixIconSearch>
                <span nz-icon nzType="search"></span>
              </ng-template>
            </div>
          </div>
          <nz-tabset [nzTabPosition]="'left'">
            <nz-tab [nzTitle]="'Not Mapped'">
              <div class="table-container">
                <nz-table #unmappedStockTable [nzData]="filterUnmappedStock(0)" [nzFrontPagination]="true" [nzPageSize]="25" [nzLoading]="isLoadingStock || isMapping" [nzLoadingIndicator]="Loading">
                
                  <thead>
                    <tr>
                      <th *ngIf="isAllClients">Client</th>
                      <th>Stock Code</th>
                      <th>Stock on Hand</th>
                      <th>Description</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    
                    <tr *ngFor="let item of unmappedStockTable.data" (click)="openMappingModal(item)">
                      <td *ngIf="isAllClients">{{ item.clientName }}</td>
                      <td>
                        <i nz-icon nzType="exclamation" nzTheme="outline" class="text-warning-style" *ngIf="item.mapped == 6" nz-tooltip nzTooltipPlacement='left' nzTooltipTitle=' SF Admin unable to Map this Item Automatically'></i>
                        {{ item.stockCode }}
                      </td>
                      <td>{{ item.soh ? item.soh : 'Not Available' }}</td>
                      <td>{{ item.description }}</td>
                      <td>
                        <!-- Button group for larger screens -->
                        <div class="button-group" *ngIf="!isSmallScreen">
                          <nz-button-group nzSize="large">
                            <button nz-button nzType="default" class="button" (click)="mapCustomStock($event, item, batteryMSFID, batteryStockTypeId, 5)">Battery</button>
                            <button nz-button nzType="default" class="button" (click)="mapCustomStock($event, item, brakeMSFID, brakeStockTypeId, 5)">Brake</button>
                            <button nz-button nzType="default" class="button" (click)="mapCustomStock($event, item, shockMSFID, shockStockTypeId, 5)">Shock</button>
                            <button nz-button nzType="default" class="button" (click)="mapCustomStock($event, item, serviceMSFID, serviceStockTypeId, 5)">Service</button>
                            <button nz-button nzType="default" class="button" (click)="mapUnmappableStock($event, item, 3, 0, 3)">Not a tyre (NAT)</button>
                            <button nz-button nzType="default" class="button" (click)="mapUnmappableStock($event, item, 2, 0, 2)">Missing in nmsf (MINMSF)</button>
                            <button nz-button nzType="default" class="button" (click)="mapUnmappableStock($event, item, 6, 0, 6)">Not Mappable</button>
                          </nz-button-group>
                        </div>
                        <!-- Dropdown for smaller screens -->
                        <div class="dropdown-group" *ngIf="isSmallScreen">
                          <button nz-button nzType="default" class="button" nz-dropdown [nzDropdownMenu]="menu" (click)="dropdownClicked($event)"><span nz-icon nzType="menu" nzTheme="outline"></span> Actions <span nz-icon nzType="down"></span></button>
                          <nz-dropdown-menu #menu="nzDropdownMenu">         
                            <ul nz-menu>
                              <li nz-menu-item (click)="mapCustomStock($event, item, batteryMSFID, batteryStockTypeId, 5)">Battery</li>
                              <li nz-menu-item (click)="mapCustomStock($event, item, brakeMSFID, brakeStockTypeId, 5)">Brake</li>
                              <li nz-menu-item (click)="mapCustomStock($event, item, shockMSFID, shockStockTypeId, 5)">Shock</li>
                              <li nz-menu-item (click)="mapCustomStock($event, item, shockMSFID, serviceStockTypeId, 5)">Service</li>
                              <li nz-menu-item (click)="mapUnmappableStock($event, item, 3, 3, 0)">Not a tyre (NAT)</li>
                              <li nz-menu-item (click)="mapUnmappableStock($event, item, 2, 0, 2)">Missing in nmsf (MINMSF)</li>
                              <li nz-menu-item (click)="mapUnmappableStock($event, item, 6, 0, 6)">Not Mappable</li>
                            </ul>
                          </nz-dropdown-menu>
                        </div>
                      </td>
                    </tr>
                  
                  <ng-template #noData>
                    <tr>
                      <td colspan="5" class="text-center">No Unmapped Items</td>
                    </tr>
                  </ng-template>
                  </tbody>
              
                </nz-table>
              </div>

              <ng-template #Loading>
                <nz-spin></nz-spin>
              </ng-template>
            </nz-tab>

            <nz-tab [nzTitle]="'Not A Tyre'">
              <nz-table #stockTableUnmappedNAT [nzData]="filterUnmappedStock(3)" [nzFrontPagination]="false" [nzLoading]="isLoadingStock || isMapping" [nzLoadingIndicator]="Loading" >
               
                  <thead>
                    <tr>
                      <th *ngIf="isAllClients">Client</th>
                      <th>Stock Description</th>
                      <th>Stock Code</th>
                      <th>Unmap stock code</th>
                    </tr>
                  </thead>
                <tbody class="tbody">
                    <tr *ngFor="let item of stockTableUnmappedNAT.data">
                      <td *ngIf="isAllClients">{{ item.clientName }}</td>
                      <td>
                        <i nz-icon nzType="exclamation" nzTheme="outline" class="text-warning-style" *ngIf="item.mapped == 6" nz-tooltip nzTooltipPlacement='left' nzTooltipTitle=' SF Admin unable to Map this Item Automatically'></i> {{item.description}}
                      </td>
                      <td>{{item.stockCode}}</td>
                      <td>
                        <button nz-button nzType="default" nzDanger nzTooltipTitle="Unmap Stock Code" nzTooltipPlacement="top" nz-tooltip (click)="reUnmapStock(item)">
                          Unmap
                        </button>
                      </td>
                    </tr>
                </tbody>
              
              </nz-table>
            </nz-tab>

            <nz-tab [nzTitle]="'MINMSF'">
              <nz-table #stockTableUnmappedMINNMSF [nzData]="filterUnmappedStock(2)" [nzFrontPagination]="false" [nzLoading]="isLoadingStock || isMapping" [nzLoadingIndicator]="Loading">
                
                <thead>
                  <tr>
                    <th *ngIf="isAllClients">Client</th>
                    <th>Stock Description</th>
                    <th>Stock Code</th>
                    <th>Unmap stock code</th>
                  </tr>
                </thead>
                <tbody class="tbody">
                  <tr *ngFor="let item of stockTableUnmappedMINNMSF.data">
                    <td *ngIf="isAllClients">{{ item.clientName }}</td>
                    <td>
                      <i nz-icon nzType="exclamation" nzTheme="outline" class="text-warning-style" *ngIf="item.mapped == 6" nz-tooltip nzTooltipPlacement='left' nzTooltipTitle=' SF Admin unable to Map this Item Automatically'></i> {{item.description}}
                    </td>
                    <td>{{item.stockCode}}</td>
                    <td>
                      <button nz-button nzType="default" nzDanger nzTooltipTitle="Unmap Stock Code" nzTooltipPlacement="top" nz-tooltip (click)="reUnmapStock(item)">
                        Unmap
                      </button>
                    </td>
                  </tr>
                </tbody>
              </nz-table>
            </nz-tab>
          </nz-tabset>
        </nz-tab>

        <nz-tab nzTitle="Mapped" (nzSelect)="switchToMapped()">
          <p *ngIf="!selectedClient || selectedClient.clientId==0">*Please select a client</p>
          <div nz-row [nzJustify]="'center'">
            <div nz-col nzSpan="12" class="search-bar-container">
              <nz-input-group  [nzSuffix]="suffixIconSearch" class="mobile-view">
                <input type="text" nz-input placeholder="Advanced Filter" [(ngModel)]="input.inputValue" (ngModelChange)="setAdvancedFilterInputMapped(input.inputValue)" style="text-transform:uppercase;" />
              </nz-input-group>
            </div>
          </div>
          
          <nz-table #mappedStockTable [nzData]="mappedStock" [nzFrontPagination]="true" [nzPageSize]="25" [nzLoading]="isLoadingMappedStock" [nzLoadingIndicator]="Loading">
            <thead >
              <tr>
                <th colspan="3" style="border-right: solid 0.01px var(--textcolor);">
                  <h5 *ngIf="selectedClient">{{selectedClient.name}}</h5>
                </th>
                <th colspan="4">
                  <h5>StockFinder</h5>
                </th>
              </tr>
              <tr>
                <th>Date mapped</th>
                <th>Mapped Stock Description</th>
                <th style="border-right: solid 0.01px var(--textcolor);">Stock Code</th>
                <th>msfId</th>
                <th>MSF Description</th>
                <th>Stock Code</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let item of mappedStockTable.data">
                <td>{{ item.date | date: 'dd/MM/yyyy' }}</td>
                <td>{{ item.locationStockDescription }}</td>
                <td>{{ item.locationStockCode }}</td>
                <td>{{ item.msfId }}</td>
                <td>{{ item.msfDescription }}</td>
                <td>{{ item.msfStockCode }}</td>
                <td>
                  <button nz-button nzType="default" nzDanger (click)="deleteMapping(item)">Delete</button>
                  <nz-popconfirm nzTitle="Are you sure to delete this mapping?" (nzOnConfirm)="deleteMapping(item)" nzOkText="Yes" nzCancelText="No">
                    <a nz-popconfirm>Yes</a>
                  </nz-popconfirm>
                </td>
              </tr>
            </tbody>
          </nz-table>
          <ng-template #Loading>
            <nz-spin></nz-spin>
          </ng-template>
        </nz-tab>
      </nz-tabset>
  </div>
</nz-card>

<app-modal-mapping></app-modal-mapping>