<div nz-row nzJustify="center">
    <div nz-col class="text-center" nzSpan="12">
        <nz-input-group [nzSuffix]="suffixIconSearch" style="width: clamp(200px, 75%, 400px);">
            <input type="text" nz-input [(ngModel)]="inputValue" (ngModelChange)="resetTable()" placeholder="Advanced Filter" style="text-transform:uppercase;" />
        </nz-input-group>
        <ng-template #suffixIconSearch>
            <span nz-icon nzType="search"></span>
        </ng-template>
    </div>
</div>
<br>
<nz-table #open [nzData]="getLeads()" [nzFrontPagination]="false" [nzLoading]="leadsService.isLoadingLeads">
    <thead>
        <tr>
            <th></th>
            <th [nzSortFn]="custSortFn">Customer</th>
            <!-- <th>Quote State</th> -->
            <th [nzSortFn]="saleSortFn">Salesman</th>
            <th [nzSortFn]="clientSortFn">Channel</th>
            <th>Comment</th>
            <th [nzSortFn]="dtSortFn">Date</th>
            <th [nzSortFn]="statusSortFn">Status</th>
            <th>
                <app-video-widget [videoId]="13" style="float: inline-end;"></app-video-widget>
            </th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of open.data | filter: inputValue:[]:0:pageSize">
            <td>
                <div>
                    <!-- SalesMan Icon -->
                    <i nz-icon nzType="user" nzTheme="outline" [ngClass]="{'iconGreen': item.userId === auth.user.user_id, 
                    'iconNeutral': item.userId && item.userId !== auth.user.user_id,
                    'iconRed': !item.userId || item.userId === 0}" nz-tooltip [nzTooltipTitle]="item.userId === auth.user.user_id ? 'Assigned To You' : item.userId && item.userId !== auth.user.user_id ? 'Assigned To:' + item.assignedTo : 'UNASSIGNED'"></i>
                    <!-- Booking Icon -->
                    <i nz-icon nzType="calendar" nzTheme="outline" class="iconGreen" nz-tooltip nzTooltipTitle="BOOKING: {{item.bookingDate}}" *ngIf="item.bookingDate"></i>
                    <!-- Tentatively Accepted Icon -->
                    <i nz-icon nzType="check-square" nzTheme="outline" class="iconGreen" nz-tooltip nzTooltipTitle="Tentatively Accepted" *ngIf="item.statusId===18"></i>
                </div>
            </td>
            <td>{{item.customerName}}<br />{{item.customerCell}} </td>
            <!-- <td *ngIf="item.state == '40%'" >
            <div class='progresss' style='color:black'>
              <div class='progresss-bar1 text-center' style='width: 40%;'>
                <span class='text-center' style="padding: 5px;">40%</span>
              </div>
            </div>
            {{ item.description }}
        </td> -->

            <!-- <td *ngIf="item.state == '70%'" >
            <div class='progresss' style='color:black'>
              <div class='progresss-bar2 text-center' style='width: 70%;padding-top:1.5px'>
              <span class='text-center' >70%</span>
              </div>
            </div>
            {{ item.description }}
        </td>  -->
            <td>{{item.assignedTo}}</td>
            <td>
                <span *ngIf="item.buyerId === item.sellerId">{{item.leadChannel}}</span>
                <span *ngIf="item.buyerId !== item.sellerId">{{ item.buyerName }}</span>
            </td>
            <td *ngIf="item.comment.length === 1">
                <span>{{item.comment}}</span>
            </td>
            <td *ngIf="item.comment.length > 1">
                <span *ngFor="let data of item.comment; let i = index">
                    {{data}}
                    <br>
                </span>
            </td>
            <td *ngIf="item.comment.length === 0"></td>
            <!-- <td> {{ item.dtAction | date:'dd-MMM HH:mm' }}</td> -->
            <td> {{ item.dtAction + "Z" | date:'dd-MMM HH:mm' }} <br /><span>In {{generateTimeString(item.actionInMinutes)}}</span></td>
            <td> {{ item.statusDescription }}</td>
            <td>
                <button nz-button nzType="primary" id="btnview" (click)="viewquote(item)">
            View <span nz-icon nzType="sf:externalSVG" nzTheme="outline" ></span>
          </button>
            </td>
        </tr>
    </tbody>
</nz-table>