import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/shared/notification.service';
import { SettingsService } from '../settings.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.less']
})
export class ChangePasswordComponent implements OnInit {

  isLoading = false;
  passwordVisible = false;
  errorMessage = "";
  pwdForm: FormGroup = new FormGroup({
    currentPassword: new FormControl('', Validators.required),
    newPassword: new FormControl('', Validators.required),
    confirmPassword: new FormControl('', Validators.required)
  });

  constructor(private settingsService: SettingsService, private notification: NotificationService) { }

  ngOnInit(): void {
  }

  changePassword() {
    
    this.errorMessage = "";
    /* The following will loop through all controls and mark them as Dirty, so that visual validation can happen. This only happens on btn click */
    Object.values(this.pwdForm.controls).forEach(control => {
      if (control.invalid) {
        control.markAsDirty();
        control.updateValueAndValidity({ onlySelf: true });
      }
    });

    /* The next part of the code will probably never be called, because the SUBMIT cannot happen if the FORM is invalid */
    if (this.pwdForm.controls['currentPassword'].invalid) {
      this.errorMessage += "Current Password is required"
    }
    if (this.pwdForm.controls['newPassword'].invalid) {
      this.errorMessage += "New Password is required"
    }    
    
    /* To check if the two passwords are the same */
    if (this.pwdForm.value.newPassword != this.pwdForm.value.confirmPassword) {
      // Alternatively: (this.pwdForm.controls['newPassword'].value != this.pwdForm.controls['confirmPassword'].value)
      this.pwdForm.controls["confirmPassword"].setErrors({'error': true});
      this.errorMessage += "Passwords do not match"
    }
    
    if (this.pwdForm.valid) {
      this.isLoading = true;
      this.settingsService.changePassword(this.pwdForm.value.currentPassword, this.pwdForm.value.newPassword).subscribe(
        msg => {
          this.notification.showSuccess(msg.detail);
          this.pwdForm.reset();
          this.isLoading = false        
        },
        error => {
          this.notification.handleError(error);
          this.isLoading = false;
        }
      )      
    }    
  }
}
