import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationService } from '../shared/notification.service';
import { environment } from './../../environments/environment';
import { SearchObj , aautoquote } from './../models/search-obj';
import { CachedBrand, CachedSearchExtra, CachedSearchTerm, SearchAllResult, SearchCache, SearchType, SpecialPriceSet } from '../models/search';
import { TyreMeta } from '../models/tyres';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SearchService {

  apiUrl = 'v1/search/';
  searchterms: CachedSearchTerm[] = [];
  searchbrands: CachedBrand[] = [];
  searchextras: CachedSearchExtra[] = [];
  //searchextras: string[] = ["pass","c","com","ind","agri","suv","4x4","lt","truck","tractor","otr","fr","mgt","b","l","k1","k2","k3","ar","vol","dt","n0","n1","n2","n3","n4","n5","n6","no","vw","*","*rsc","* rsc","a* rsc","ao","aoe","af","f1","fi","j","lr","jlr","mo","mo1","moe","n-","por","po1","ro1","r01","ro2","to","vo"];


  constructor(private http: HttpClient, private notification: NotificationService) {
    // if(this.PopularSizes == undefined || this.PopularSizes == null) 
    // {
    //   this.GenCodes = JSON.parse(localStorage.getItem('GenCodes')!);
    //   this.PopularSizes =  JSON.parse(localStorage.getItem('PopularSizes')!);
    //   this.ClientCustomers = JSON.parse(localStorage.getItem('ClientCustomers')!);
    //   this.Clientscodes  =  JSON.parse(localStorage.getItem('Clientscodes')!);
    //   this.Clientccodes  =  JSON.parse(localStorage.getItem('Clientccodes')!);
    // }
    
   }
    // GenCodes: any[];
    // PopularSizes: any[];
    // ClientCustomers: any[];
    // Clientscodes: any[];
    // Clientccodes: any[];

    public refreshSearchData() {
      this.refreshCachedSearchTerms(SearchType.All);
      return;
      // this.getSearchData().subscribe(
      //   val => {

      //     // this.GenCodes = this.removeDuplicates(val["gencodes"], i=> i.name);
      //     // this.PopularSizes =  this.removeDuplicates(val["sizes"],i => i.name).sort( function (a, b) { return b.key - a.key });
      //     // this.ClientCustomers = this.removeDuplicates(val["customers"], i=> i.key);
      //     // this.ClientCustomers = this.removeDuplicates(this.ClientCustomers, i=> i.name);
      //     // this.Clientscodes  =  this.removeDuplicates(val["scodes"], i=> i.name);
      //     // this.Clientccodes  =  this.removeDuplicates(val["ccodes"], i=> i.name);

      //     // localStorage.setItem('GenCodes', JSON.stringify(this.GenCodes));
      //     // localStorage.setItem('PopularSizes', JSON.stringify(this.PopularSizes));
      //     // localStorage.setItem('ClientCustomers', JSON.stringify(this.ClientCustomers));
      //     // localStorage.setItem('Clientscodes', JSON.stringify(this.Clientscodes));
      //     // localStorage.setItem('Clientccodes', JSON.stringify(this.Clientccodes));
         
      //     this.refreshCachedSearchTerms(SearchType.All);
      //   }, 
      //   err => {
      //     this.notification.handleErrorSilent(err);
      //   }
      // )
    }

    public refreshCachedSearchTerms(type: SearchType) {
      
      //load from localstorage into mem
      this.searchterms = JSON.parse(localStorage.getItem('CachedSearchTerms') ? localStorage.getItem('CachedSearchTerms')! : '[]');
      this.searchbrands = JSON.parse(localStorage.getItem('CachedBrands') ? localStorage.getItem('CachedBrands')! : '[]');
      this.searchextras = JSON.parse(localStorage.getItem('CachedExtras') ? localStorage.getItem('CachedExtras')! : '[]');
      
      this.getCachedSearchTerms(type).subscribe({
        next: val => {
          //the returned stock items needs to be orderd by ORDER first, then RANK, to ensure RANK 1 is at the top, ordered by ORDER thereafter.
          if (type == SearchType.All) {
            this.searchterms = val.searchTerms.sort(function(a,b) {return a.order - b.order}).sort(function(a,b) {return a.rank - b.rank});
          }
          else {
            this.searchterms = this.searchterms.filter(e => e.searchType !== type).concat(val.searchTerms).sort(function(a,b) {return a.order - b.order}).sort(function(a,b) {return a.rank - b.rank});
          }

          this.searchbrands = val.searchBrands;
          this.searchextras = val.searchExtras;
          //first clear unneeded items
          localStorage.removeItem('GenCodes');
          localStorage.removeItem('ClientCustomers');
          localStorage.removeItem('Clientscodes');
          localStorage.removeItem('Clientccodes');
          localStorage.removeItem('PopularSizes');
          
          //then set items
          localStorage.setItem('CachedSearchTerms', JSON.stringify(this.searchterms.filter(e => e.searchType !== SearchType.Customer && e.searchType !== SearchType.Vehicle)));
          localStorage.setItem('CachedBrands', JSON.stringify(val.searchBrands));
          localStorage.setItem('CachedExtras', JSON.stringify(val.searchExtras));
          
        },
        error: err => {this.notification.handleErrorSilent(err);}
      })
    }

    public filteredCachedSearchTerms(types: SearchType[]): CachedSearchTerm[] {
      //check if inmem list is empty, load from disk
      //this is being API called from F5 (app.component), method called: this.refreshCachedSearchTerms, so is this still necessary?
      if (this.searchterms.length === 0) this.searchterms = JSON.parse(localStorage.getItem('CachedSearchTerms') ? localStorage.getItem('CachedSearchTerms')! : '[]');
      if (this.searchbrands.length === 0) this.searchbrands = JSON.parse(localStorage.getItem('CachedBrands') ? localStorage.getItem('CachedBrands')! : '[]')
      if (this.searchextras.length === 0) this.searchextras = JSON.parse(localStorage.getItem('CachedExtras') ? localStorage.getItem('CachedExtras')! : '[]')

      if (types.includes(SearchType.All)) {
        //early exit for most-used search type
        return this.searchterms;
      }
      else {
        //filtered based on requested data
        return this.searchterms.filter(e => types.includes(e.searchType));
      }
    }

    private getCachedSearchTerms(type: number) {
      return this.http.get<SearchCache>(environment.ApiUrl() + this.apiUrl + `cachedsearchterms/${type}`);
    }

    public  getSearchData()
    {
      return this.http.get( environment.ApiUrl() + this.apiUrl + "getsearchdata");
    }

    public getSearchExtra(term:string)
    {
      return this.http.get( environment.ApiUrl() + this.apiUrl + "getsearchextra/" + term);
    }

    public searchCustomerByCell(cell: string) {
      return this.http.get<{found: boolean, customerId: number}>(environment.ApiUrl() + this.apiUrl + `customer/cell/${cell}`);
    }

    public getSearchExtraPost(term)
    {
      var search = {"term":term}
      return this.http.post( environment.ApiUrl() + this.apiUrl + "getsearchextra",search);
    }

    public searchAll(searchTerm: string) {
      const searchObject = {"term": searchTerm};
      return this.http.post<SearchAllResult>(environment.ApiUrl() + this.apiUrl + "search_all", searchObject);
    }

    public getMsfid(code:string)
    {
      return this.http.get<{msfid: string, customStockId: string}>( environment.ApiUrl() + this.apiUrl + "getmsfid/" + code);
    }

    public searchSF(searchobj: SearchObj)
    {
      return this.http.post(environment.ApiUrl() + this.apiUrl + "searchSF", searchobj);
    }

    public searchStock(searchobj)
    {
      return this.http.post(environment.ApiUrl() + this.apiUrl + "searchStock", searchobj);

    }

    public PlaceOrder(order)
    {
      return this.http.post(environment.ApiUrl() + this.apiUrl + "placeorder", order);
    }

    public Autoquote(aqobj: aautoquote, opts : {customerId?: number, vehicleId?: number, isManualQuote?: boolean} = {})
    {
      let url = environment.ApiUrl() + "v1/leads/auto_quote"
      let queryParam = new HttpParams();
      // Creates a list of query parameters to pass to the API. THese parameters will not be displayed in the website url.
      if (opts){
        if (opts.isManualQuote && opts.isManualQuote === true){
          queryParam = queryParam.append('isManualQuote', opts.isManualQuote)
        }
        if (opts.customerId && opts.customerId > 0) {
          queryParam = queryParam.append('customerId', opts.customerId)
        }
        if (opts.vehicleId && opts.vehicleId > 0) {
          queryParam = queryParam.append('vehicleId', opts.vehicleId)
        }
      }
      return this.http.post(url, aqobj, {params: queryParam});
    }

    public fetchMeta(msfid:number)
    {
      return this.http.get<TyreMeta>(environment.ApiUrl() + "v1/stock/fetchmeta/" + msfid);
    }

    specialPriceSets : SpecialPriceSet[] = [];
    private lastSelectedSpecialPriceSet: SpecialPriceSet = new SpecialPriceSet();

    //readonly property to get the lastSelectedPriceSet id
    public get lastSelectedSpecialPriceSetId() {
      return this.lastSelectedSpecialPriceSet.specialPriceSetId;
    }

    public get lastPriceSet(){
      return this.lastSelectedSpecialPriceSet;
    }

    //writeonly property to set the lastSelectedPriceSet
    public storeLastSelectedSpecialPriceSet(id: number) {
      this.lastSelectedSpecialPriceSet = this.specialPriceSets.find(e => e.specialPriceSetId === id)!;
    }

    public selectedPriceSet: BehaviorSubject<number> = new BehaviorSubject<number>(0); //This is used to determine which price set is selected - default 0 - none
    public getSpecialPriceSets() {
      return this.http.get<SpecialPriceSet[]>(environment.ApiUrl() + this.apiUrl + 'SpecialPriceSets');
    }

    
    private removeDuplicates(arr, predicate) {
      var found = {};
      arr.forEach(d => {
        found[predicate(d)] = d;
      });
      return Object.keys(found).map(key => found[key]);
    }

}
