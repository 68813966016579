import { Component, OnInit } from '@angular/core';
import { NzContextMenuService, NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown';
import { en_US, NzI18nService, zh_CN } from 'ng-zorro-antd/i18n';
import { resultType } from 'src/app/shared/modal-content/modal-content.component';
import { NotificationService } from 'src/app/shared/notification.service';

interface Person {
  key: string;
  name: string;
  age: number;
  address: string;
}

@Component({
  selector: 'app-component-legend',
  templateUrl: './component-legend.component.html',
  styleUrls: ['./component-legend.component.less']
})
export class ComponentLegendComponent implements OnInit {

  checked = true;
  switchValue = true;
  date = null;

  constructor(private nzContextMenuService: NzContextMenuService, private i18n: NzI18nService, private notification: NotificationService) { }

  ngOnInit(): void {
  }

  // dropdowns
  contextMenu($event: MouseEvent, menu: NzDropdownMenuComponent): void {
    this.nzContextMenuService.create($event, menu);
  }

  closeMenu(): void {
    this.nzContextMenuService.close();
  }

  // dates
  onDatePickerChange(result: Date): void {
    console.log('onChange: ', result);
  }

  onCalendarValueChange(value: Date): void {
    console.log(`Current value: ${value}`);
  }

  onCalendarPanelChange(change: { date: Date; mode: string }): void {
    console.log(`Current value: ${change.date}`);
    console.log(`Current mode: ${change.mode}`);
  }

  //select
  selectedName = null;

  // table
  listOfData: Person[] = [
    {
      key: '1',
      name: 'John Brown',
      age: 32,
      address: 'New York No. 1 Lake Park'
    },
    {
      key: '2',
      name: 'Jim Green',
      age: 42,
      address: 'London No. 1 Lake Park'
    },
    {
      key: '3',
      name: 'Joe Black',
      age: 32,
      address: 'Sidney No. 1 Lake Park'
    }
  ];

  listOfEmptyData: Person[] = []

  //notis
  showSuccess() {
    this.notification.showSuccess("This is a success message");
  }

  showWarning() {
    this.notification.showWarning("This is a Warning message");
  }

  showInfo() {
    this.notification.showInfo("This is a info message");
  }

  showNonBlock() {
    this.notification.ShowNonBlockingMessage("This is a non blocking ws message");
  }

  showSuccessMin() {
    this.notification.showSuccessMinimal("This is a minimal success message");
  }

  showError() {
    this.notification.showError("This is a Error message");
  }
  
  showSuccessAlert() {
    this.notification.ShowAlert({type: resultType.Success, htmlMessage: "<b>THIS</b> is html, can be <i>anything</i>, there is no title", btnText:"OK"})
  }

  showWarningAlert() {
    this.notification.ShowAlert({type: resultType.Warning, title: "Warning Title", message: "This is just a normal text message", btnText:"OK"})
  }

  showInfoAlert() {
    this.notification.ShowAlert({type: resultType.Information, title: "Info", message: "This modal has no buttons"})
  }

  showErrorAlert() {
    this.notification.ShowAlert({type: resultType.Error, title: "Error", message: "This alert can only be closed if the button is clicked", btnText: "OK", closable: false, maskClosable: false})
  }

  showSuccessAlertDelay() {
    this.notification.ShowAlert({type: resultType.Success, htmlMessage: "<b>THIS</b> Alert will auto close in 4 seconds", closable: false, maskClosable: false, autoCloseInMilliseconds: 4000})
  }
}
