export class WAMessageGroup {
    messageId: number;
    date: Date;
    name: string;
    cell: string;
    incoming: boolean;
    message: string;
    unread: number;
    clientId: number;
    clientName: string;
    error: string;
}

export class WAMessage {
    messageId: number;
    date: Date;
    name: string;
    cell: string;
    incoming: boolean;
    message: string;
    dateRead: Date;
    dateDelivered: Date;
    imageBase64: string;
    userName: string;
    error: string;
    clientId: number;
    statusId: number;
}

export class WAMessageTemplate {
    templateId: number;
    templateName: string;
    payload: string;
    messageText: string;
    description: string;
}

export class WAConversation {
    messages: WAMessage[];
    conversationStateId: number;
    templates: WAMessageTemplate[];

}
export class WAArchive {
    cell: string
    clientId: number
}