export class Client_Connection{
        sellerId: number = 0 
        seller_name: string= '' 
        buyerId: number = 0  
        buyer_name: string= '' 
        debtorAcc: string= '' 
        sell_state: string= '' 
        pricelistId_Replenish: number = 0 
        pricelist_name: string= '' 
}


export class Replenishment_Line{
        stockReplenishmentId: number = 0 
        clientId: number = 0 
        client_name: string= '' 
        supplierId: number = 0 
        supplier_name: string= '' 
        msfid: number = 0 
        msl: number = 0 
        stock_code: string= ''
        client_soh: number = 0
        description: string = '' 
        suggested_msl: number = 0 
        holding_status: number = 0 
        dt_changed: Date 
        ms_ai_reasonId: number = 0 
        ms_ai_reason: string= '' 
        supplier_cost: number = 0
        supplier_soh: number = 0 
        supplier_replenishment_price: number = 0  
        stock_type: string = ''
        stock_market: string = ''
        brand: string = ''
        brand_logo: string = '' 
}

export class Client_Model{
        account : Client_Connection 
        replenishment_lines: Replenishment_Line[] = []

}