import { environment } from 'src/environments/environment';
import { ChangePasswordRequest, ClientBookingSlot, ClientSettings, Customer, CustomerUpdate } from '../models/settings';
import { PriceNames  } from './../models/pricelist';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { brandStrat } from '../models/brandStrat';
import { AuthService } from '../auth.service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private http: HttpClient, private authService:AuthService) { }

  public changePassword(currentPassword: string, newPassword: string) {
    const body: ChangePasswordRequest = { oldPassword: currentPassword, newPassword: newPassword}
    return this.http.post<{detail: string}>(environment.ApiUrl() + 'v1/users/password', body)
  }

  public getPricingSettings()
  {
    return this.http.get( environment.ApiUrl() + "v1/pricelists/getsettings");
  }

  public savesettings(settings: PriceNames)
  {
    return this.http.post(environment.ApiUrl() + "v1/pricelists/updatesettings", settings);
  }

  public getBookingSlots() {
    return this.http.get<ClientBookingSlot[]>(environment.ApiUrl() + "v1/settings/bookings/slot")
  }

  public getBookingSlot(id: number) {
    return this.http.get<ClientBookingSlot>(environment.ApiUrl() + `v1/settings/bookings/slot/${id.toString()}`)
  }

  public deleteBookingSlot(id: number) {
    return this.http.delete(environment.ApiUrl() + `v1/settings/bookings/slot/${id.toString()}`)
  }

  public addBookingSlot(slot: ClientBookingSlot) {
    return this.http.post<ClientBookingSlot>(environment.ApiUrl() + "v1/settings/bookings/slot", slot)
  }

  /*-----------------------------------------------------------*/
  versionUrl = "v2/settings/main"
  public getMainSettings() {
    return this.http.get<ClientSettings>(environment.ApiUrl() + this.versionUrl);
  }

  public updateMainSettings(settings: ClientSettings) {
    return this.http.put(environment.ApiUrl() + this.versionUrl, settings);
  }

  public updateQuote(file:FormData) {
    return this.http.post(environment.ApiUrl() + "v2/settings/updateQuoteSettings", file );
  }


  /*-----------------------------------------------------------*/

  public getCustomerConnections() {
    return this.http.get<Customer[]>(environment.ApiUrl() + "v1/settings/Customers")
  }

  public editCustomer(customerId:number)
  {
    return this.http.get<CustomerUpdate>(environment.ApiUrl() + "v1/settings/Customers/" + customerId)
  }

  public getClientBrandStrats() {
    return this.http.get<brandStrat[]>(environment.ApiUrl() + "v1/clients/ClientBrandStrat")
  }

  public updateClientBrandStrats(brands: brandStrat[])
  {
    return this.http.post(environment.ApiUrl() + "v1/clients/ClientBrandStrat/Update", brands)
  }

  /*----------------GET LOCATION SERVICE-----------------------------*/

  public getPosition(): Observable<any> {
    return new Observable(observer => {
      window.navigator.geolocation.getCurrentPosition(
        position => {
          observer.next(position);
          observer.complete();
        },
        error => {
          observer.error(error);
        }
      );
    });
  }
  public hasGpProtectionPassword()
  {
    return this.http.get( environment.ApiUrl() + "v1/settings/hasGpProtectionPassword");
  }

  public getGpProtectionPassword(password: string)
  {
    return this.http.get( environment.ApiUrl() + "v1/settings/getGpProtectionPassword/" + password);
  }
  public updateGpProtectionPassword(password: string)
  {
    const body = {password: password ? password : null};
    console.log(body)
    return this.http.put(environment.ApiUrl() + "v1/settings/setGpProtectionPassword/" + password, body);
  }

  public changeGpPercentPassword(currentPassword: string, newPassword: string) {
    const body: ChangePasswordRequest = { oldPassword: currentPassword, newPassword: newPassword}
    return this.http.post<{detail: string}>(environment.ApiUrl() + 'v1/settings/password', body)
  }
}
