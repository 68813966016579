<div class="container-fluid">
    <div nz-row class="justify-content-md-center">
        <div nz-col nzSpan="24">
            <nz-collapse style="margin-bottom: 30px;">
                <nz-collapse-panel [nzHeader]="'Item Summary'">
                    <div nz-row [nzJustify]="'center'"> 
                        <div nz-col nzSpan="24">
                            <nz-table #summary [nzData]="wipSummaryItems" [nzFrontPagination]="false" [nzLoading]="isLoading" style="overflow-x: auto;">
                                <thead>
                                    <tr>
                                        <th>Brand</th>
                                        <th>Item</th>
                                        <th>Reserved</th>
                                        <th>Not Reserved</th>
                                        <th>Total</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of summary.data">
                                        <td><img src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{item.brandImage}}" class="brandImage" alt="{{item.brand}}" nz-tooltip nzTooltipTitle="{{item.brand}}" nzTooltipPlacement="right" /></td>
                                        <td>{{item.description}}</td>
                                        <td>{{item.qtyReserverd}}</td>
                                        <td>{{item.qtyNotReserved}}</td>
                                        <td>{{item.qtyReserverd + item.qtyNotReserved}}</td>
                                        <td><button nz-button (click)="showItemLeads(item)"><span nz-icon nzType="eye" nzTheme="outline"></span></button></td>
                                    </tr>
                                </tbody>
                            </nz-table>                            
                        </div>
                    </div>
                </nz-collapse-panel>
            </nz-collapse>
            <div style="margin-bottom: 20px; text-align: center;">
                <strong>Filter By:</strong> Assigned to Me: 
                <nz-switch [(ngModel)]="showAssingedToMe" (ngModelChange)="updateFilterSettings()"></nz-switch>
                Assigned to Other:
                <nz-switch [(ngModel)]="showAssingedToOther" (ngModelChange)="updateFilterSettings()"></nz-switch>
            </div>
            <nz-table #open [nzData]="filteredLeads" [nzFrontPagination]="false" [nzLoading]="isLoading" style="overflow-x: auto;">
                <thead>
                    <tr>
                        <th style="width: 5%"></th>
                        <th  [nzSortFn]="customerSortFn">Customer</th>
                        <!-- <th>Quote State</th> -->
                        <th [nzSortFn]="salesManSortFn" [nzFilterFn]="salesManFilterFn" [nzFilters]="salesManFilterValues" nzShowFilter>Salesman</th>
                        <th>Channel</th>
                        <th style="text-align:center;">Vehicle</th>
                        <th style="text-align: center;">
                            Stock
                        </th>
                        <th>
                            Total
                        </th>
                        <th [nzSortFn]="createdSortFn" nzShowFilter [nzFilterFn]="actionTimeFilterFn" [nzFilters]="actionTimeFilterValues">
                            Created
                        </th>
                        <th style="align-content: center;"></th>
                    </tr>
                </thead>
                <tbody class="table-body">
                    <tr *ngFor="let item of open.data">
                        <td style="text-align: center;">
                            <span nz-icon nzType="warning" nzTheme="fill" *ngIf="getRowWarning(item.actionInMinutes) > 0" [ngClass]="{'iconRed' : getRowWarning(item.actionInMinutes) === 2, 'iconYellow' : getRowWarning(item.actionInMinutes) === 1}"></span>
                        </td>
                        <td>{{item.customerName}}<br />{{item.customerCell}} </td>

                        <td>{{item.assignedTo}}</td>
                        <td> {{ item.buyerName }}</td>
                        <td style="text-align:center;">
                            {{item.vehicle_Reg}}<br> {{item.vehicle_Make}} <br> {{item.vehicle_Model}}
                        </td>
                        <td style="text-align: center;">
                            <i nz-icon nzType="minus-circle" nzTheme="fill" class="iconNeutral" [nz-tooltip]="'Stock Not Reserved'" *ngIf="item.statusId === 26"></i>
                            <i nz-icon nzType="check-circle" nzTheme="fill" class="iconGreen" [nz-tooltip]="'Stock Reserved'" *ngIf="item.statusId === 27"></i>
                        </td>
                        <td>
                            R{{item.total | number : '1.2-2'}} <small class="text-info-style">Ex</small><br />
                            R{{item.totalIncl | number : '1.2-2'}} <small class="text-info-style">Incl</small>
                        </td>
                        <td>
                            {{ item.dtAction + "Z" | date:'dd-MMM HH:mm' }}<br />
                            <small [ngClass]="{'spanRed' : getRowWarning(item.actionInMinutes) === 2, 'spanYellow' : getRowWarning(item.actionInMinutes) === 1}"><i>{{generateTimeString(item.actionInMinutes)}}</i></small>
                        </td>
                        <td style="text-align: center;">
                            <button nz-button nzType="primary" id="btnview" (click)="viewquote(item)" nz-tooltip nzTooltipColor='var(--textcolor)' nzTooltipPlacement='top' nzTooltipTitle='View/Edit Job Card'>
                            Open<span nz-icon nzType="import" nzTheme="outline"></span>
                            </button>
                            <!-- <button style="border:none;" nz-tooltip nzTooltipColor='var(--textcolor)' nzTooltipPlacement='top' nzTooltipTitle='Print Job Card' (click)="printJobCard(item.quoteId)">
                                <span nz-icon nzType="credit-card" nzTheme="outline" style="color:var(--textcolor); margin-right: 5px;"></span> Job Card
                            </button> -->
                        </td>
                    </tr>
                </tbody>
            </nz-table>
        </div>
    </div>
</div>
<!-- Modal showing leads per MSFID item -->
<div *ngIf="selectedWipSummaryItem">


    <nz-modal [(nzVisible)]="isItemLeadsModalVisible" nzTitle="{{selectedWipSummaryItem.description}}" (nzOnCancel)="isItemLeadsModalVisible = false" (nzOnOk)="isItemLeadsModalVisible = true"
    [nzMaskClosable]="true" [nzFooter]="null">
        <ng-container *nzModalContent>
            
                <nz-table #summary [nzData]="selectedWipSummaryItem.leads" [nzFrontPagination]="false" [nzLoading]="isLoading" style="overflow-x: auto;">
                    <thead>
                        <tr>
                            <th>Customer</th>
                            <th>WIP Ref</th>
                            <th>Qty</th>
                            <th>Reserved</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of summary.data">
                            <td>{{item.customerName}}</td>
                            <td>{{item.quoteRef}}</td>
                            <td>{{item.qty}}</td>
                            <td>
                                <i nz-icon nzType="minus-circle" nzTheme="fill" class="iconNeutral" [nz-tooltip]="'Stock Not Reserved'" *ngIf="item.statusId === 26"></i>
                                <i nz-icon nzType="check-circle" nzTheme="fill" class="iconGreen" [nz-tooltip]="'Stock Reserved'" *ngIf="item.statusId === 27"></i>
                            </td>
                            <td><button nz-button (click)="navigateToSelectedWip(item)"><span nz-icon nzType="eye" nzTheme="outline"></span></button></td>
                        </tr>
                    </tbody>
                </nz-table>       
                <button nz-button nzType="primary" (click)="isItemLeadsModalVisible = false"><span nz-icon nzType="close-circle" nzTheme="outline"></span>Close</button>
        </ng-container>
        
    </nz-modal>
</div>
