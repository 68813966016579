<div class="container-fluid" *ngIf="this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Web || this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Tablet">
    <div class="row ">
        <div class="col-lg-2">
        </div>
        <div class="col-lg-8 " style="text-align: center;">
            <h4><b>Sale Orders</b></h4>
        </div>
        <div class="col-lg-2">
        </div>
    </div>
</div>


<div class="text-center not-mobile" style="position: absolute; top:35%">
    <br>
    <h5 class="text-center">Please rotate your phone to view the Sales</h5>
    <span nz-icon nzType="rotate-left" nzTheme="outline" class="text-center"></span>
</div>


<div class="container-fluid col-12  text-center bg-1 mobile">

    <nz-spin [nzSpinning]="isLoading">
        <ng-template #extraTemplate>

            <button nz-button nzType="primary" (click)="markAllSalesAsSeen()" *ngIf="this.appComponent.currentBreakpoint != this.appComponent.Breakpoints.HandsetPortrait" style="margin-right: 10px; width: 170px; position: absolute; left:5px; top:10px"> Mark all as seen <span nz-icon nzType="eye" nzTheme="outline"></span>
              </button>
            <button nz-button nzType="primary" (click)="ngOnInit()" *ngIf="this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Web" style="position: absolute; right: 5px; top:10px">Reload <span nz-icon nzType="redo" nzTheme="outline"></span></button>
        </ng-template>
        <nz-tabset nzCentered nzSize="large" [nzTabBarExtraContent]="extraTemplate">
            <nz-tab *ngFor="let table of this.TableOrders, let tblIndex=index" nzTitle="{{ table.title}}">
                <nz-input-group [nzSuffix]="suffixIconSearch" style="width: clamp(200px, 75%, 400px);">
                    <input type="text" nz-input [(ngModel)]="inputValue" placeholder="Advanced Filter" style="text-transform:uppercase;" />
                  </nz-input-group>
                  <ng-template #suffixIconSearch>
                    <span nz-icon nzType="search"></span>
                  </ng-template>
                <nz-table #period [nzData]="table.sales" [nzFrontPagination]="false" style="overflow-x: auto;">
                    <thead>
                        <tr class="text-center">
                            <th style="min-width: 200px;">Customer</th>
                            <th *ngIf="this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Web || this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Tablet">Ref#</th>
                            <th style="width: 7%;" *ngIf="this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Web || this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Tablet">Value</th>
                            <th>Units</th>
                            <th *ngIf="this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Web || this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Tablet">Comment</th>
                            <th style="min-width: 120px;">Date</th>
                            <th *ngIf="this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Web || this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Tablet">Emailed</th>
                            <th *ngIf="this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Web || this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Tablet">ExtRef</th>
                            <th style="width: 5%;" *ngIf="this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Web || this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Tablet"></th>
                            <th class="text-center" style="min-width: 120px;">Order Status</th>
                            <th>
                                <app-video-widget [videoId]="9" style="float: inline-end;"></app-video-widget>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="table-body">
                        <tr *ngFor="let item of period.data | filter: inputValue">
                            <td>
                                <span class="ribbon" *ngIf="!item.seen">NEW</span>
                                <div style="margin-left: 22px;">
                                    {{item.name }}
                                    <div *ngIf="item.customerName" style="font-size: 0.7rem;color: var(--textcolor);font-style: italic;">{{item.customerName}}</div>
                                </div>
                            </td>
                            <td *ngIf="this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Web || this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Tablet">
                                {{item.orderRef}}
                            </td>
                            <td *ngIf="this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Web || this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Tablet"><span class="text-info-style">R </span>{{item.price|number }}</td>
                            <td>{{item.qty}}</td>
                            <td *ngIf="this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Web || this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Tablet">{{item.comment}}</td>
                            <td class="timee">{{ item.dateformatted}}</td>
                            <td class="text-center" *ngIf="this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Web || this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Tablet"><span *ngIf="item.dt_email != 'None'">Email Sent 
                                <i nz-icon nzType="check" nzTheme="outline" class="text-info-style"></i>        
                            </span></td>

                            <!--Order Feedback SB Auth Client---->
                            <td class="text-center" *ngIf="this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Web || this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Tablet">
                                <button nz-button nzType="default" *ngIf="item.statusId == 3 || item.statusId == 5 && item.buyerId == 674" (click)="download_sb_auth(item.extRef)" nz-tooltip nzTooltipPlacement='top' nzTooltipTitle='Click To Download Authorization PDF' style="border:none;">
                                  Download 
                                  <i nz-icon nzType="download" nzTheme="outline" class="text"></i>
                                </button>
                                <div *ngIf="item.buyerId === 674 && item.statusId !== 3 && item.statusId !== 5">
                                     {{item.extRef}}   
                                </div>
                                <div *ngIf="item.buyerId!=674">{{ item.SysRef }}</div>
                                <div *ngIf="item.SysRef == null">-</div>
                            </td>
                            <!--Order Feedback Icon status---->
                            <td class="text-center" *ngIf="this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Web || this.appComponent.currentBreakpoint == this.appComponent.Breakpoints.Tablet">
                                <!--State - Processing -->
                                <button nz-button nzType="default" [nzLoading]="isCheckingSBAuth" *ngIf="item.statusId==0" nz-tooltip nzTooltipPlacement='left' nzTooltipTitle='Order Is still Processing - Click To Refresh' (click)="ngOnInit()">
                                    Check Status <span nz-icon nzType="redo" nzTheme="outline"></span>
                                </button>
                                <!--State - Auth Pending -->
                                <button nz-button nzType="default" [nzLoading]="isCheckingSBAuth" *ngIf="item.statusId==1" nz-tooltip nzTooltipPlacement='left' nzTooltipTitle='Awaiting Authorization - Click to Check Again' (click)="check_sb_auth(item.extRef)">
                                    Check Status <span nz-icon nzType="redo" nzTheme="outline"></span>
                                </button>
                                <!--State - Auth Failed -->
                                <button nz-button nzType="default" *ngIf="item.statusId==2" style="border:none;" nz-tooltip nzTooltipPlacement='left' nzTooltipTitle='Authorization Rejected' (click)="resubmitAuth(item.orderid)">
                                    <i nz-icon nzType="close" nzTheme="outline" class="text-danger-style"></i>
                                </button>
                                <!--State - Auth Failed -->
                                <button nz-button nzType="default" *ngIf="item.statusId==10" style="border:none;" nz-tooltip nzTooltipPlacement='left' nzTooltipTitle='Authorization Failed Please Call Center on 0860222668'>
                                    <i nz-icon nzType="close" nzTheme="outline" class="text-danger-style"></i>
                                </button>
                                <!--State - Errors-->
                                <div *ngIf="item.statusId == 4 || item.statusId==7 || item.statusId == 12">
                                    <!--SF / Client Fix Warning -->
                                    <button nz-button nzType="default" *ngIf="item.errorFix == 1 || item.errorFix == 3 " style="border:none;" nz-tooltip nzTooltipPlacement='left' nzTooltipTitle='{{item.error}}'>
                                       <i nz-icon [nzType]="'warning'" class="text-warning-style"></i>
                                    </button>
                                    <button nz-button nzType="default" (click)="isShowError(item)" *ngIf="item.errorFix == 0 " style="border:none;" nz-tooltip nzTooltipPlacement='left' nzTooltipTitle='Click Here to Fix This Issue'>
                                          <i nz-icon [nzType]="'warning'" class="text-warning-style"></i>
                                   </button>

                                    <!--SF Fix Failed -->
                                    <button nz-button nzType="default" *ngIf="item.errorFix == 2 && item.sellerPos != 'None'" style="border:none;" nz-tooltip nzTooltipPlacement='left' nzTooltipTitle='{{item.error}}'>
                                        <i nz-icon nzType="close" nzTheme="outline" class="text-danger-style"></i>
                                    </button>
                                    <button nz-button nzType="default" *ngIf="item.errorFix == 2 && item.sellerPos == 'None' && item.PoRef == 'None'" style="border:none;" nz-tooltip nzTooltipPlacement='left' nzTooltipTitle='Order Received-update external reference to complete'>
                                        <i nz-icon nzType="info-circle" nzTheme="outline" class="text-warning-style"></i>
                                    </button>
                                    <button nz-button nzType="default" *ngIf="item.errorFix == 2 && item.sellerPos == 'None' && item.PoRef != 'None'" style="border:none;" nz-tooltip nzTooltipPlacement='top' nzTooltipTitle='Order Completed Successfully'>
                                          <i nz-icon nzType="check" nzTheme="outline" class="text"></i>
                                    </button>

                                    <!--Wholesale Still Processing -->
                                    <button nz-button nzType="default" *ngIf="item.errorFix == 12" style="border:none;" nz-tooltip nzTooltipPlacement='left' nzTooltipTitle='{{item.error}}'>
                                        <i nz-icon [nzType]="'loading'" class="text-warning-style"></i>
                                    </button>
                                </div>
                                <!--State Completed-->
                                <div *ngIf="item.statusId == 3 || item.statusId == 5 || item.statusId == 8 ||item.statusId == 9 || item.statusId == 11" style="border:none;" nz-tooltip nzTooltipPlacement='top' nzTooltipTitle='Order Completed Successfully'>
                                    <i nz-icon nzType="check" nzTheme="outline" class="text"></i>
                                </div>
                            </td>
                            <!--Order Feedback Text status-->
                            <td class="text-center">
                                <span *ngIf="item.statusId==0">Order Processing</span>
                                <!--SB FLEET Auth---->
                                <span *ngIf="item.statusId==1">Authorization Pending <img src="./../../assets/Images/fleet/Standardbank.png" height="30" width="30"></span>
                                <span *ngIf="item.statusId==2"> Authorization Rejected <img src="./../../assets/Images/fleet/Standardbank.png" height="30" width="30"></span>
                                <span *ngIf="item.statusId==3">Authorization  Approved<img src="./../../assets/Images/fleet/Standardbank.png" height="30" width="30"></span>
                                <span *ngIf="item.statusId==10">Authorization Failed<img src="./../../assets/Images/fleet/Standardbank.png" height="30" width="30"></span>
                                <!--SB FLEET Auth---->
                                <!--State Error -->
                                <div *ngIf="item.statusId == 4 || item.statusId == 7  || item.statusId == 12">
                                    <!--State - Client Fix by Resubmitting -->
                                    <button nz-button nzType="primary" *ngIf="item.errorFix == 0" (click)="resubmitOrder(item.orderid)" style="border:none;" nz-tooltip nzTooltipPlacement='top' nzTooltipTitle='Please Fix the Issue mentioned on the Error Alert and Then Place The Order Again'>
                                        Place Again 
                                        <i nz-icon nzType="export" nzTheme="outline" class=" text"></i>

                                    </button>

                                    <button nz-button nzType="primary" *ngIf="item.errorFix == 3" (click)="resubmitOrder(item.orderid)" style="border:none;" nz-tooltip nzTooltipPlacement='top' nzTooltipTitle='Please Fix the Issue mentioned on the Error Alert and Then Place The Order Again'>
                                        Place Again 
                                        <i nz-icon nzType="export" nzTheme="outline" class=" text"></i>
                                    </button>
                                    <!--State - SF Fix by Resubmitting -->
                                    <span *ngIf="item.errorFix == 1"> Auto Resubmitting 
                                        <i nz-icon [nzType]="'loading'"></i>                      
                                    </span>
                                    <span *ngIf="item.errorFix == 2 && item.sellerPos != 'None'">  Order not Inserted </span>
                                    <span *ngIf="item.errorFix == 2 && item.sellerPos == 'None' && item.PoRef == 'None'">  Click 
                                        <span nz-icon nzType="sf:externalSVG" nzTheme="outline" ></span> to add Ref#</span>
                                    <span *ngIf="item.errorFix == 2 && item.sellerPos == 'None' && item.PoRef != 'None'"> Order Complete </span>
                                    <span *ngIf="item.errorFix == 12"> Click 
                                        <span nz-icon nzType="sf:externalSVG" nzTheme="outline" ></span> to add Ref#</span>
                                </div>

                                <div *ngIf="item.statusId == 6">
                                    <span> Order not Inserted </span>
                                </div>

                                <!--Completed-->
                                <span *ngIf="item.statusId == 5 || item.statusId == 8 ||item.statusId == 9 || item.statusId == 11">Order Inserted</span>
                            </td>
                            <td>
                                <button nz-button nzType="primary" id="btnview" (click)="viewsale(item.orderid)">
                                    <span nz-icon nzType="sf:externalSVG" nzTheme="outline" ></span>
                                    
                                    Open
                                </button>
                                <button nz-button nzType="primary" *ngIf="this.authService.user.featureLevel >= 3" style="border:none;" nz-tooltip nzTooltipPlacement='top' nzTooltipTitle='Print Invoice' (click)="communicateInvoice(item)">
                                     <span nz-icon nzType="credit-card" nzTheme="outline" style="margin-right: 5px;"></span> Invoice
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </nz-table>
                <div *ngIf="checkDisplayLoadMore(tblIndex)">

                    <button nz-button nzType="primary" (click)="loadMore(tblIndex)" [nzLoading]="isLoadingMoreItems"><span nz-icon nzType="caret-down" nzTheme="outline"></span> LOAD MORE</button>
                </div>

            </nz-tab>
        </nz-tabset>
    </nz-spin>
</div>

<nz-modal [(nzVisible)]="this.isshowError" nzTitle="Error Processing Order" (nzOnCancel)="this.isshowError = false" [nzFooter]="null" nzWidth="700px">
    <div *nzModalContent>
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h6>
                        <i nz-icon nzType="warning" nzTheme="outline" class="text-warning-style"></i> {{isshowItem.error}}
                    </h6>
                </div>
                <div class="col-lg-12">
                    <hr style="background-color: white;">
                    <h4 style="color: var(--textcolor);">Solution</h4>
                </div>
                <div class="col-lg-12">
                    <p *ngFor='let sol of solutions;let i = index;'>{{sol}}</p>
                </div>
                <div class="col-lg-12" *ngIf="this.showMapOptions">
                    <div class="container">
                        <div class="row">
                            <div class="col-4">
                                Unmapped Code Found
                            </div>
                            <div class="col-4">
                                Enter Your Code To Match
                            </div>
                            <div class="col-4">
                                Confirm Mapping
                            </div>
                        </div>
                        <div class="row rowline" *ngFor="let code of this.showMapCodes">
                            <div class="col-4">
                                <input nz-input value="{{code.unmappedCode}}" readonly />
                            </div>
                            <div class="col-4">
                                <input nz-input [(ngModel)]="code.AutoMapCode" value="{{code.AutoMapCode}}" placeholder="Add Code here " autofocus />
                            </div>
                            <div class="col-4">
                                <button nz-button nzType="default" *ngIf="!code.ismapped" (click)="autoMap(code)">
                                    Confrim 
                                 </button>
                                <span *ngIf="code.ismapped">Successfully Mapped 
                                    <i nz-icon nzType="check" nzTheme="outline" class="text-success"></i>
                                </span>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <br>
                    <p> * Once Completed, Close This Window and Resubmit the order </p>
                </div>
            </div>
        </div>
    </div>
</nz-modal>