import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { callStdGpResult, callAgilisUpdateResult, callGeneratePrePamResult, callStoredProcResult, callGeneratePamResult } from '../models/grv';

@Injectable({
  providedIn: 'root'
})
export class GrvService {
  apiUrl = 'v1/grv';

  constructor(private http: HttpClient) { }

  callGeneratePam(group: string) {
    return this.http.get<callGeneratePamResult>(environment.ApiUrl() + this.apiUrl + "/GeneratePam/" + group);
  }


  callGeneratePrePam(group: string) {
    return this.http.get<callGeneratePrePamResult>(environment.ApiUrl() + this.apiUrl + "/GeneratePrePam/" + group);
  }

  callStdGp(group: string) {
    return this.http.get<callStdGpResult>(environment.ApiUrl() + this.apiUrl + "/StdGPPam/" + group);
  }

  callAgilisUpdate(group: string) {
    return this.http.get<callAgilisUpdateResult>(environment.ApiUrl() + this.apiUrl + "/AgilisPam/" + group);
  }

  callStoredProc(storedProc: string) {
    return this.http.get<callStoredProcResult>(environment.ApiUrl() + this.apiUrl + "/PamStoredProcs/" + storedProc);
  }


}
