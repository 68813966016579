<div class="container-fluid">
    <div class="row ">
        <div class="col-lg-4">
        </div>
        <div class="col-lg-4 text-center">
            <h2><b>Stock Override</b></h2>
        </div>
        <div class="col-lg-4">
        </div>
    </div>
</div>

<div class="container-fluid">
    <div nz-row nzJustify="center">
        <div nz-col nzSpan="24" style="padding-bottom: 20px;">
            <div id="btnfunctions" class="text-center">
                <button nz-button nzType="primary" (click)="onClickAddNewRule()" style="margin-left: 10px; margin-right: 10px;">
                    <span nz-icon nzType="file-add" nzTheme="outline"></span>
                Add New Rule
                </button>
                <button nz-button nzType="primary" (click)="exportexcel()" style="margin-left: 10px; margin-right: 10px;">
                    <span nz-icon nzType="file-excel" nzTheme="outline"></span>
                Download To Excel
                </button>
            </div>
        </div>
        <div nz-col nzSpan="24">
            <p></p>
            <nz-table [nzData]="this.overrideData" #stockTable [nzFrontPagination]="false" id="tbl" [nzLoading]="isLoading">
                <thead>
                    <tr>
                        <th>Stock Code</th>
                        <th>Description</th>
                        <th>{{ this.pricelist.plonline }}</th>
                        <th>{{ this.pricelist.plretail }}</th>
                        <th>{{ this.pricelist.deflt }}</th>
                        <th>{{ this.pricelist.wholesale }}</th>
                        <th>{{ this.pricelist.plinternal }}</th>
                        <th>{{ this.pricelist.plclear}}</th>
                        <th>{{ this.pricelist.plcustom1 }}</th>
                        <th>{{ this.pricelist.plcustom2 }}</th>
                        <th>{{ this.pricelist.plcustom3 }}</th>
                        <th>{{ this.pricelist.plcustom4 }}</th>
                        <th>Action </th>
                        <th><app-video-widget [videoId]="20" style="float: inline-end;"></app-video-widget></th>
                    </tr>
                </thead>
                <tbody class="tbody">
                    <tr *ngFor="let item of stockTable.data">
                        <ng-container *ngIf="!editCache[item.overindex].edit; else editTemplate">
                            <td><span style="margin-left: 5px;">{{item.stock_code}}</span></td>
                            <td  class="description"><span>{{ item.description }}</span> </td>
                            <td><span *ngIf="item.plonline == null"> - </span><span *ngIf="item.plonline != null">{{ item.plonline }}</span></td>
                            <td><span *ngIf="item.plretail  == null"> - </span><span *ngIf="item.plretail != null">{{ item.plretail }}</span></td>
                            <td><span *ngIf="item.deflt  == null"> - </span><span *ngIf="item.deflt != null">{{ item.deflt }}</span></td>
                            <td><span *ngIf="item.wholesale == null"> - </span><span *ngIf="item.wholesale != null">{{ item.wholesale }}</span></td>
                            <td><span *ngIf="item.plinternal == null"> - </span><span *ngIf="item.plinternal != null">{{ item.plinternal }}</span></td>
                            <td><span *ngIf="item.plclear == null"> - </span><span *ngIf="item.plclear != null"> {{ item.plclear }}</span></td>
                            <td><span *ngIf="item.plcustom1  == null"> - </span><span *ngIf="item.plcustom1 != null">{{ item.plcustom1 }}</span></td>
                            <td><span *ngIf="item.plcustom2 == null"> - </span><span *ngIf="item.plcustom2  != null">{{ item.plcustom2 }}</span></td>
                            <td><span *ngIf="item.plcustom3 == null"> - </span><span *ngIf="item.plcustom3 != null">{{ item.plcustom3 }}</span></td>
                            <td><span *ngIf="item.plcustom4 == null"> - </span><span *ngIf="item.plcustom4 != null"> {{ item.plcustom4 }}</span></td>
                            <td [colSpan]="2">
                                <nz-button-group nzSize="large">
                                    <button nz-button nzType="primary" nzSize="large" (click)="startEdit(item.overindex)" nz-tooltip nzTooltipTitle="Edit">
                                        <span nz-icon nzType="edit" nzTheme="fill"></span>
                                    </button>
                                    <button nz-button nzType="default" nzSize="large" nzDanger nzBorderless (click)="removeRule(item)" nz-tooltip nzTooltipTitle="Delete">
                                        <span nz-icon nzType="delete" nzTheme="fill"></span>
                                    </button>
                                </nz-button-group>
                            </td>
                        </ng-container>
                        <ng-template #editTemplate>
                            <td><span style="margin-left: 5px;">{{item.stock_code}}</span></td>
                            <td class="description"><span>{{ item.description }}</span></td>
                            <td><input type="number" nz-input [(ngModel)]="editCache[item.overindex].data.plonline"></td>
                            <td><input type="number" nz-input [(ngModel)]="editCache[item.overindex].data.plretail"></td>
                            <td><input type="number" nz-input [(ngModel)]="editCache[item.overindex].data.deflt"></td>
                            <td><input type="number" nz-input [(ngModel)]="editCache[item.overindex].data.wholesale"></td>
                            <td><input type="number" nz-input [(ngModel)]="editCache[item.overindex].data.plinternal"></td>
                            <td><input type="number" nz-input [(ngModel)]="editCache[item.overindex].data.plclear"></td>
                            <td><input type="number" nz-input [(ngModel)]="editCache[item.overindex].data.plcustom1"></td>
                            <td><input type="number" nz-input [(ngModel)]="editCache[item.overindex].data.plcustom2"></td>
                            <td><input type="number" nz-input [(ngModel)]="editCache[item.overindex].data.plcustom3"></td>
                            <td><input type="number" nz-input [(ngModel)]="editCache[item.overindex].data.plcustom4"></td>
                            <td>
                                <nz-button-group nzSize="large">
                                    <button nz-button nzType="primary" nzSize="large" (click)="saveEdit(item.overindex)" nz-tooltip nzTooltipTitle="Save" [disabled]="duplicationCheck(item)">
                                        <span nz-icon nzType="check" nzTheme="outline"></span>
                                    </button>
                                    <button nz-button nzType="default" nzSize="large" nzBorderless (click)="cancelEdit(item.overindex)" nz-tooltip nzTooltipTitle="Cancel">
                                        <span nz-icon nzType="close" nzTheme="outline"></span>
                                    </button>
                                </nz-button-group>
                            </td>
                        </ng-template>
                    </tr>
                </tbody>
            </nz-table>
        </div>
    </div>
</div>

<nz-modal [(nzVisible)]="isAddModalVisible" nzClosable="true" nzTitle="Add New Rule" (nzOnCancel)="isAddModalVisible = false;" (nzOnOk)="addRule()" [nzFooter]="null" nzSize="large" nzWidth="600px">
    <div *nzModalContent class="text-center">
        <div class="container-fluid">
            <div nz-row>
                <div nz-col nzSpan="24">
                    <div  style="float: right">
                        <span>Show non active stock: </span>
                        <nz-switch [(ngModel)]="showNonActiveStock"></nz-switch>
                    </div>
                </div>
            </div>
            <br>
            <div nz-row>
                <div nz-col nzSpan="24">
                    <div class="input-group maindiv " style="width: 100%;margin: 0 auto;">
                        <nz-input-group [nzPrefix]="prefixQuestion" [nzSuffix]="suffixIconSearch">
                            <input placeholder="Search Here" nz-input [(ngModel)]="inputValue" [nzAutocomplete]="auto" (ngModelChange)="onChange($event)" style="text-transform:uppercase;"/>
                        </nz-input-group>
                        <ng-template #prefixQuestion>
                            <span class="input-group-prepend" nz-tooltip nzTooltipPlacement="bottom" [nzTooltipTitle]="info">
                                <span nz-icon nzType="question-circle" nzTheme="outline"></span>
                            </span>
                        </ng-template>
                        <ng-template #suffixIconSearch>
                            <span nz-icon nzType="search"></span>
                          </ng-template>
                        <nz-autocomplete #auto>
                            <div *ngIf="inputValue.length > 0">
                                <nz-auto-option *ngFor="let option of filterStock() | filter: inputValue;" [nzLabel]="option.description" [nzValue]="option.msfid">{{ option.description }} {{ option.brand }} {{ option.stockcode }} <span *ngIf="option.activeId === 0" style="color: var(--textcolor)">NON ACTIVE STOCK</span>
                                </nz-auto-option>
                            </div>
                        </nz-autocomplete>
                    </div>
                </div>
            </div>
            <br>
        </div>
        <br>
        <hr>
        <div>
            <form [formGroup]="ruleForm" class="text-center">
                <div nz-row [nzGutter]="16">
                    <div nz-col nzSpan="12">
                        <nz-form-item>
                            <nz-form-control>
                                <label>Price Online</label>
                                <input nz-input name="priceonline" formControlName="priceonline" placeholder="0">
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-control>
                                <label>Price Default</label>
                                <input nz-input name="pricedefault" formControlName="pricedefault" placeholder="0">
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-control>
                                <label>Price Internal </label>
                                <input nz-input name="priceinternal" formControlName="priceinternal" placeholder="0">
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-control>
                                <label>Price Custom 1</label>
                                <input nz-input name="pricecustom1" formControlName="pricecustom1" placeholder="0">
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-control>
                                <label>Price Custom 3</label>
                                <input nz-input name="pricecustom3" formControlName="pricecustom3" placeholder="0">
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col nzSpan="12">
                        <nz-form-item>
                            <nz-form-control>
                                <label>Price Retail</label>
                                <input nz-input name="priceretail" formControlName="priceretail" placeholder="0">
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-control>
                                <label>Price wholesale</label>
                                <input nz-input name="pricewholesale" formControlName="pricewholesale" placeholder="0">
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-control>
                                <label>Price Clear Out</label>
                                <input nz-input name="priceclearout" formControlName="priceclearout" placeholder="0">
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-control>
                                <label>Price Custom 2</label>
                                <input nz-input name="pricecustom2" formControlName="pricecustom2" placeholder="0">
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-control>
                                <label>Price Custom 4</label>
                                <input nz-input name="pricecustom4" formControlName="pricecustom4" placeholder="0">
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            </form>
            <div class="container-fluid">
                <div nz-row>
                    <div nz-col nzSpan="12" class="text-center offset-3">
                        <button nz-button nzType="primary" (click)="addRule()">
                            <span nz-icon nzType="plus" nzTheme="outline"></span>
                        Add Rule
                    </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</nz-modal>