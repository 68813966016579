import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { PriceMatrixColumnItem, PriceMatrixObj, PriceMatrixTreeNode } from 'src/app/models/pricelist';
import { PricelistsService } from '../pricelists.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-price-matrix',
  templateUrl: './price-matrix.component.html',
  styleUrls: ['./price-matrix.component.less']
})
export class PriceMatrixComponent implements OnInit, OnDestroy {

  pricingMatrixObj: PriceMatrixObj = new PriceMatrixObj();
  isLoading: boolean = false;
  matrixDirty: boolean = false;
  isNavModalVisible = false;
  navPathAway: string = "";

  appliedOptions = [
    { text: 'Prices Set with Custom Logic negotiated with StockFinder', value: 0 },
    { text: 'Use the below Price Matrix', value: 1 },
  ];

  priceMatrixBrandColumn: PriceMatrixColumnItem=
      {
        name: 'Brand',
        sortOrder: null,
        sortFn: (a: PriceMatrixTreeNode, b: PriceMatrixTreeNode) => a.brandName.localeCompare(b.brandName),
        sortDirections: ['ascend', 'descend', null],
        listOfFilter: [],
        filterFn: (list: number[], item: PriceMatrixTreeNode) => list.some(value => item.brandId === value),
        filterMultiple: true
        
      }

  setAllValue : number = 0;
  isPopoverVisible: boolean = false;

  rimSizes: number[] = [
    3.00, 4.00, 4.33, 5.00, 5.50, 5.51, 6.00, 6.50, 7.00, 8.00, 9.00, 9.75, 10.00, 
    10.50, 11.00, 11.25, 12.00, 12.13, 13.00, 14.00, 14.50, 15.00, 15.30, 15.50, 
    16.00, 16.10, 16.50, 17.00, 17.50, 18.00, 19.00, 19.50, 20.00, 20.50, 21.00, 
    22.00, 22.01, 22.50, 23.00, 24.00, 24.50, 25.00, 25.20, 26.00, 26.50, 28.00, 
    28.50, 29.00, 30.00, 30.50, 32.00, 33.00, 34.00, 35.00, 36.00, 38.00, 42.00, 
    44.00, 45.00, 46.00, 48.00, 49.00, 50.00, 51.00, 52.00, 54.00, 430.90
  ];
  constructor(
    private priceListService: PricelistsService,
    private modal: NzModalService,
    private router: Router
  ) { }

  ngOnInit() {
    this.isLoading = true;

    this.priceListService.getPricingMatrix().subscribe(
      (res: PriceMatrixObj) => {
        console.log(res);
        this.pricingMatrixObj = res;
        this.isLoading = false;
      },
      error => {
        this.isLoading = false;
      }
    );
  }

  ngOnDestroy(): void {
    // Add any necessary cleanup logic here
  }

  updatePMList(index: number) {
    console.log("Updating PM List");
    this.matrixDirty = false;
  }

  setAllPercentages(j) {
    console.log("Setting all percentages");
    this.matrixDirty = true;
  }

  toggleExpand(item: PriceMatrixTreeNode): void {
    if (item.priceMatrixOverrideItems) {
      item.expand = !item.expand;
    }
  }

  avaibleRims(brandId: number, priceListIndex: number): number[] {
    const usedRims = this.pricingMatrixObj.priceMatrixList[priceListIndex].priceMatrixItems.find((brand) => brand.brandId === brandId)?.priceMatrixOverrideItems.map((rim) => rim.rimSize) || [];
    return this.rimSizes.filter((rim) => !usedRims.includes(rim));
  }

  checkNavGuard(navPathAway: string): boolean {
    if (!this.matrixDirty) {
      return true;
    }
    if (!this.navPathAway) {
      this.showNavModal();
      this.navPathAway = navPathAway;
      return false;
    } else if (this.navPathAway === navPathAway) {
      return true;
    } else {
      return true;
    }
  }

  showNavModal() {
    this.isNavModalVisible = true;
  }

  navigateWithoutSaving() {
    this.router.navigate([this.navPathAway]);
  }

  methodChanged(){
    this.matrixDirty = true;
  }

  matrixBrandChanged(){
    this.matrixDirty = true;
  }

  matrixOverrideChanged(){
    this.matrixDirty = true;
  }
  
}