<div class="text-div custDeets">
    <i nz-icon nzType="close-circle" nzTheme="outline" style="float: right; font-size: 1.5rem !important; color:rgb(192, 1, 1)" (click)="removeCustomer()" class="removeCust" *ngIf="!this.isWIP && !this.isCompleted" nz-tooltip="Remove Customer" nzTooltipColor='rgb(192, 1, 1)'
        nzTooltipPlacement='top'></i>
    <i nz-icon nzType="user" nzTheme="outline"></i>{{posQuote.custName}}<br />
    <a style="font-size: 0.7rem; padding-left: 33px;" (click)="onCustomerClick()">VAT:<span>{{posQuote.custVATNumber}}</span><span *ngIf="!posQuote.custVATNumber"> CLICK TO ADD</span></a><br />
    <!-- </div>
<div class="text-div"> -->
    <i nz-icon nzType="mail" nzTheme="outline"></i>{{posQuote.custEmail}}<br />
    <!-- </div>
<div class="text-div"> -->
    <i nz-icon nzType="phone" nzTheme="outline"></i>{{posQuote.custCell}}

<nz-spin [nzSpinning]="isLoading">
    <div class="text-div" *ngIf="linkedReg.mappingId !== 0">
        <a (click)="onRegClick()"><i nz-icon nzType="car" nzTheme="outline" style="margin-right: 7px;"></i> {{linkedReg.vReg}}</a>
        <a style="float: right; margin-right: 10px;" (click)="onOdoClick()" [ngStyle]="{'color': linkedReg.readingAge >=7 ? 'darkgoldenrod' : 'var(--textcolor)'}" *ngIf="linkedReg.vehicleOdoId" nz-tooltip [nzTooltipTitle]="linkedReg.readingAge + ' days old'">
                    <i nz-icon nzType="warning" nzTheme="outline" *ngIf="linkedReg.readingAge >=7"></i> {{linkedReg.odoReading}} km
                </a>
        <a style="float: right; margin-right: 10px; color: darkgoldenrod;" (click)="onOdoClick()" *ngIf="!linkedReg.vehicleOdoId">
            <i nz-icon nzType="warning" nzTheme="outline"></i> NO ODO
        </a>
    </div>
    <a class="btn" *ngIf="linkedReg.mappingId === 0" style="display: block; width: 100%; text-align: left;" (click)="onRegClick()">
        <span style="color: darkgoldenrod;">
                    <i nz-icon nzType="warning" nzTheme="outline" ></i>
                    Reg not linked
                </span>
    </a>
</nz-spin>
<nz-spin [nzSpinning]="isLoading">
    <div style="margin-top: 2%;"></div>
    <div class="text-div" *ngIf="customerDebtorInfo.debtorAcc !== null" (click)="onDebtorClick()" style="margin-top: 2%;">
        <a><i nz-icon nzType="bank" nzTheme="outline" style="margin-right: 7px;"></i> {{customerDebtorInfo.debtorAcc}}</a>
    </div>
    <a class="btn" *ngIf="customerDebtorInfo.debtorAcc == null || customerDebtorInfo.debtorAcc == ''" style="display: block; width: 100%; text-align: left;color: rgb(17, 103, 156)" (click)="onDebtorClick()">
        <span>
           <i nz-icon nzType="warning" nzTheme="outline" ></i> 
            Debtor Account not linked
         </span>
    </a>
</nz-spin>
</div>

<nz-modal [(nzVisible)]="showRegModal" nzTitle="Vehicle Registration" (nzOnCancel)="showRegModal = false" [nzFooter]="null">
    <div *nzModalContent>
        <nz-spin [nzSpinning]="isLoadingLinkedReg">
            <div *ngFor="let item of customerReg" class="text-div text-div-interactive" style="display: block;" (click)="linkRegToQuote(item.mappingId)">
                <i nz-icon nzType="car" nzTheme="outline"></i> {{item.vReg}} <span *ngIf="item.odoReading"> | 
                    {{item.odoReading}}km <i nz-icon nzType="warning" nzTheme="outline" *ngIf="item.readingAge >=7" style="vertical-align: top;margin-top: 5px;color: darkgoldenrod;"></i></span>
            </div>
            <div style="text-align: center;color: darkgoldenrod;" *ngIf="customerReg.length === 0">
                <i nz-icon nzType="warning" nzTheme="outline" style="font-size: 3rem;"></i><br /> No linked registrations
            </div>
            <button nz-button style="margin-top: 10px;" (click)="onAddVehicleClick()">
                <i nz-icon nzType="plus" nzTheme="outline" style="vertical-align: middle; margin-top: -6px;"></i> Add
                new registration
            </button>
            <button nz-button style="margin-top: 10px; margin-left: 10px;" (click)="onUnlinkVehicleClick()" *ngIf="linkedReg.mappingId !== 0">
                <i nz-icon nzType="close" nzTheme="outline" style="vertical-align: middle; margin-top: -6px;"></i> 
                Unlink {{this.linkedReg.vReg}}
            </button>
        </nz-spin>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="showDebtorModal" nzTitle="Customer Debtor Account" (nzOnCancel)="showDebtorModal = false" [nzFooter]="null">
    <div *nzModalContent>
        <nz-spin [nzSpinning]="isLoadingDebtors">
            <div *ngFor="let item of DebtorAccs" class="text-div text-div-interactive" style="display: block;" (click)="linkDebtorToCustomer(item.debtorAcc)">
                <i nz-icon nzType="bank" nzTheme="outline"></i> {{item.debtorAcc}}
            </div>

            <button nz-button style="margin-top: 10px;border:none" (click)="onAddDebtorClick()">
                <i nz-icon nzType="plus" nzTheme="outline" style="vertical-align: middle; margin-top: -6px;"></i>
                 Link New Account
            </button>
            <button nz-button style="margin-top: 10px; margin-left: 10px;;border:none" (click)="onUnlinkDebtorClick()" *ngIf="customerDebtorInfo.debtorAcc !== null">
                <i nz-icon nzType="close" nzTheme="outline" style="vertical-align: middle; margin-top: -6px;"></i> 
                Unlink Account {{this.customerDebtorInfo.debtorAcc}}
            </button>
        </nz-spin>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="showAddDebtorModal" nzTitle="Add New Debtor Account" (nzOnCancel)="showAddDebtorModal = false">
    <div *nzModalContent>
        <nz-spin [nzSpinning]="isLoadingDebtors">
            <form nz-form [formGroup]="addDebtorForm">
                <nz-form-item>
                    <nz-form-control [nzErrorTip]="'Debtor Account required'">
                        <nz-input-group nzPrefixIcon="bank">
                            <input nz-input name="debtorAcc" formControlName="debtorAcc" placeholder="Debtor Account">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-spin>
    </div>
    <div *nzModalFooter>
        <button nz-button style="border:none" (click)="showAddDebtorModal = false">
            <i nz-icon nzType="close" nzTheme="outline" style="vertical-align: middle; margin-top: -5px;"></i>Cancel
        </button>
        <button nz-button style="border:none" (click)="addDebtor()">
            <i nz-icon nzType="check" nzTheme="outline" style="vertical-align: middle; margin-top: -5px;"></i>Add
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="showAddVehicleModal" nzTitle="Add New Vehicle" (nzOnCancel)="showAddVehicleModal = false">
    <div *nzModalContent>
        <nz-spin [nzSpinning]="isLoadingAddVehicle">
            <form nz-form [formGroup]="addVehicleForm">
                <nz-form-item>
                    <nz-form-control [nzErrorTip]="'Vehicle Registration required'">
                        <nz-input-group nzPrefixIcon="car">
                            <input nz-input name="vehReg" formControlName="vehReg" placeholder="Vehicle Registration">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-control>
                        <nz-input-group nzPrefixIcon="car">
                            <input nz-input name="vehWidth" formControlName="vehWidth" placeholder="Width">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-control>
                        <nz-input-group nzPrefixIcon="car">
                            <input nz-input name="vehProfile" formControlName="vehProfile" placeholder="Profile">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-control>
                        <nz-input-group nzPrefixIcon="car">
                            <input nz-input name="vehRim" formControlName="vehRim" placeholder="Rim">

                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

            </form>
        </nz-spin>
    </div>
    <div *nzModalFooter>
        <button nz-button (click)="showAddVehicleModal = false">
            <i nz-icon nzType="close" nzTheme="outline" style="vertical-align: middle; margin-top: -5px;"></i>Cancel
        </button>
        <button nz-button (click)="addVehicle()">
            <i nz-icon nzType="check" nzTheme="outline" style="vertical-align: middle; margin-top: -5px;"></i>Add
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="isOdoModalVisible" nzTitle="Vehicle ODO Reading" (nzOnCancel)="isOdoModalVisible = false">
    <div *nzModalContent>
        <form nz-form [formGroup]="addOdoForm">
            <nz-form-item>
                <nz-form-control>
                    <nz-input-group nzPrefixIcon="car">
                        <input nz-input name="vehOdo" formControlName="vehOdo" placeholder="Odo">
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button (click)="isOdoModalVisible = false">
            <i nz-icon nzType="close" nzTheme="outline" style="vertical-align: middle; margin-top: -5px;"></i>Cancel
        </button>
        <button nz-button (click)="onAddOdoClick()">
            <i nz-icon nzType="check" nzTheme="outline" style="vertical-align: middle; margin-top: -5px;"></i>Add
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="isVATModalVisible" nzTitle="Customer VAT Number" (nzOnCancel)="isVATModalVisible = false">
    <div *nzModalContent>
        <form nz-form [formGroup]="addVATNumberForm">
            <nz-form-item>
                <nz-form-control>
                    <nz-input-group nzPrefixIcon="container">
                        <input nz-input name="VATNumber" formControlName="VATNumber" placeholder="VAT No">
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button (click)="isVATModalVisible = false">
            <i nz-icon nzType="close" nzTheme="outline" style="vertical-align: middle; margin-top: -5px;"></i>Cancel
        </button>
        <button nz-button (click)="onVATNumberAddClick()">
            <i nz-icon nzType="check" nzTheme="outline" style="vertical-align: middle; margin-top: -5px;"></i>Update
        </button>
    </div>
</nz-modal>