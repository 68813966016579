<div style="width: 50%; margin: 0 auto; display: flex;"><h1>STOCKFINDER DIAGNOSTIC</h1></div>
<div style="width: 50%; margin: 0 auto; display: flex;">
  <div style="padding: 3px;"><button nz-button nzType="primary" data-toggle="modal" data-target="#status"
  (click)="showAPI()">SF_API Clients</button></div>
  <div style="padding: 3px;"><button nz-button nzType="primary" data-toggle="modal" data-target="#status"
  (click)="showStock()">Stock Load Table</button></div>
</div>

<hr>
<!-- <div  style="width: 50%; margin: 0 auto; padding: 3px;">
  <button nz-button nzType="primary">Stock load</button> |
  <button nz-button nzType="primary">Unsent Orders</button> |
</div> -->

<div class="container-fluid col-lg-11" id ="stockTable">
    <nz-spin [nzSpinning]="isLoading">
      <nz-table #stock [nzData]="stockLoad" id="example" [nzFrontPagination]="false">
        <thead>
          <tr>
            <th>name</th>
            <th>dt</th>
            <th>lines</th>
            <th>soh</th>
            
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of stock.data" [style]="item.status">
            <td>{{item.name}}</td>
            <td>{{item.dt}}</td>
            <td>{{item.lines}}</td>
            <td>{{item.soh}}</td>
            
  
            <!-- <button nz-button nzType="primary" data-toggle="modal" data-target="#status" (click)="configlvls(item.id)">CONFIGURE LEVELS</button></td> -->
          </tr>
  
        </tbody>
      </nz-table>
    </nz-spin>
  
  
</div>

  <div class="container-fluid col-lg-11" style="display: None;" id="apiTable">
    <div style="width: 50%; margin: 0 auto; display: flex;">
      <div style="padding: 3px;"><button nz-button nzType="primary" data-toggle="modal" data-target="#status"
      (click)="scan_clients()">Scan Clients</button></div>
    </div>
    <nz-spin [nzSpinning]="isLoading">
      <nz-table #api [nzData]="ss_api" id="example" [nzFrontPagination]="false">
        <thead>
          <tr>
            <th>ClientId</th>
            <th>Name</th>
            <th>Contact</th>
            <th>last_scan</th>
            <th>API</th>
            <th>ping client</th>
            
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of api.data">
            <td>{{item.clientId}}</td>
            <td>{{item.name}}</td>
            <td>{{item.contact}}</td>
            <td>{{item.time}}</td>
            <td [style]="item.status">{{item.apiStatus}}</td>
            <td><button nz-button nzType="primary" data-toggle="modal" data-target="#status"
              (click)="scan_one(item.clientId)">ping</button></td>
          </tr>
  
        </tbody>
      </nz-table>
    </nz-spin>
  
  
  </div>