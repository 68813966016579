import { Pricelist , ClientAdd, ClientNew } from '../../models/client';
import { NotificationService } from '../../shared/notification.service';
import { ClientService } from './../client.service';
import { Globals } from 'src/app/shared/globals';
import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { resultType } from 'src/app/shared/modal-content/modal-content.component';
// import { resultType } from '../shared/modal-content/modal-content.component'; 



@Component({
  selector: 'app-clientadd',
  templateUrl: './clientadd.component.html',
  styleUrls: ['./clientadd.component.less']
})
export class ClientaddComponent implements OnInit {

   //Global Var
   clients: ClientAdd[];
   filteredClients: ClientAdd[];
   pricelist: Pricelist;
   existing: ClientAdd;
   clientFilter: string = "";
   isAddingModalVisible: boolean = false;
   isExistingVisible: boolean = false;
   isLoadingAdd: boolean = false;
   isSavingAdd: boolean = false;
   Pattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
   regions: {regionId: number, name: string}[] = [];
   franchises: {franchiseId: number, name: string}[] = [];
   isLoadingClients = false;

   clientAddForm : FormGroup = new FormGroup({
    name: new FormControl(''),
    email: new FormControl(''),
    cell: new FormControl(''),
    password: new FormControl(''),
    plId: new FormControl("0"),
    clientId: new FormControl(0),
    type: new FormControl('FITMENT'),
    franchiseId: new FormControl('9'),
    regionId: new FormControl('1'),
   })

 

  constructor(private clientService : ClientService,private router: Router, private notification: NotificationService) { }

  ngOnInit(): void {
    this.isLoadingClients = true;
    this.reset();
    this.clientService.getallClients().subscribe(res => 
      {     
        this.isLoadingClients = false;
        this.clients = res['clients'] as ClientAdd[];
        this.filteredClients = this.clients; //reference type, point to same array. when filtering a new array will be created, so this just saves a bit of RAM
        this.pricelist = res['pricelistdata'] as Pricelist;  
        this.notification.ShowAlert({type: resultType.Information, 
          title: "Adding New Client", 
          htmlMessage: 'Before you add a new client, please check to see if they are already registered in the table below.<br>'+
          'If both you and the existing <span class="text-style">“Main Supplier”</span> are happy to both service the client <br>'+
          'Use the <span class="text-style">“+ button” </span> to do a quick add<br>'+
          '<span class="text-style"> Each Client can only have one login!</span>' ,
          btnText: "OK", 
          maskClosable: false})
      },
      error => {
        this.isLoadingClients = false;
        this.notification.handleError(error);       
      });
  }

  reset()
  {
    this.pricelist = new Pricelist();
    this.clients=[];
    // this.newclient = {
    //   name: "",
    //   email: "",
    //   cell: "",
    //   password: "",
    //   plId: 0,
    //   clientId: 0,
    //   type: 'FITMENT',
    //   franchise: "OTHER"
    // }
    this.existing = {
      name: "",
      type: "",
      email: "",
      cell: "",
      supplier:"",
      buyerId: 0,
      plId: 0,
      clientId: 0
    };
  }

  addexisting(client: ClientAdd)
  {
    this.existing = client
    this.existing.plId = 0;
    this.isExistingVisible = true;
  }

  savexisting()
  {
    this.clientService.addexisting(this.existing).subscribe(res =>
      {        
         this.isExistingVisible = false;
         this.router.navigate(['/clients']);
         this.notification.showSuccess("Successfully Added " + this.existing.name); 
      },
      error => {
        this.notification.handleError(error);       
      }); 
  }

  onAddClientClick() 
  {
    this.isAddingModalVisible = true;
    this.clientAddForm.controls["password"].setValue(Globals.funcs.generatePassword());
    this.isLoadingAdd = true;
    //load data async, but with synced isLoading flag
    let loadingFranchises = true;
    let loadingRegions = true;
    //load franchises and regions
    this.clientService.getFranchises(1).subscribe(
      val => {
        this.franchises = val;
        loadingFranchises = false;
        if (loadingRegions === false) {
          this.isLoadingAdd = false;
        }
      },
      error => {
        this.notification.handleError(error);
      }
    )
    this.clientService.getRegions(1).subscribe(
      val => {
        this.regions = val;
        loadingRegions = false;
        if (loadingFranchises === false) {
          this.isLoadingAdd = false;
        }
      },
      error => {
        this.notification.handleError(error);
      }
    )
  }

  addnew()
  {
    let newClient = new ClientNew();
    newClient.name = this.clientAddForm.value.name;
    newClient.password = this.clientAddForm.value.password;
    newClient.email = this.clientAddForm.value.email;
    newClient.cell = this.clientAddForm.value.cell;
    newClient.type = this.clientAddForm.value.type;
    newClient.franchiseId = this.clientAddForm.value.franchiseId;
    newClient.plId = this.clientAddForm.value.plId;
    newClient.regionId = this.clientAddForm.value.regionId;
    
    
    //check for inputs
    if(newClient.cell == ""  || newClient.email== "" || newClient.password== "" || newClient.name == "" )
    {
      this.notification.showWarning("Please ensure that All Fields Are filled in");
      return
    }
    else
    {
      if (newClient.password.length < 3) 
      {
        this.notification.showWarning("Password needs to be at least 3 chars");
        return;
      }
      if(!this.Pattern.test(newClient.email) == true)
      {
                this.notification.showWarning("Please check that the email is in the correct format, name@domain.anything ");
                return
      }  
      if(newClient.cell.length < 10)
      {    
        this.notification.showWarning("Invalid Contact Number ");
        return
      }
    }
    this.isSavingAdd = true;
    this.clientService.addclient(newClient).subscribe(res =>
      { 
         this.isAddingModalVisible = false;
         this.isSavingAdd = false;
         if(res.clientId === 0 && res.userId === 0)
         {
          this.notification.showWarning("Client Already Exists");
          this.clientAddForm.reset();
         }
         else
         {
          this.router.navigate(['/clients']);
          this.notification.showSuccess("Successfully Added " + newClient.name); 
         }   
      },
      error => {
        this.isSavingAdd = false;
        this.notification.handleError(error);       
      }); 
  }

  filterClients() 
  {
    this.filteredClients = this.clients.filter(e => e.name !== null).filter(e => e.name.toUpperCase().includes(this.clientFilter.toUpperCase()));
  }

}
