export class UnMappedStock{
    stockCode: string;
    description: string;
    clientId: number;
    clientName: string;
    msfId?: number;
    locationId?:number;
    locationName?:  string;;
    brand: string;
    genCode: string;
    mapped?:number = 0;
    showUnmap?:boolean = false;
    searchedGenCode?:string;
    soh?:number;
    cost?:number;
}

export class MappedStock {
    mappingId?: number;
    msfId: number;
    description: string;
    officialStock?: boolean;
    locationStockCode: string;
    locationStockDescription?: string;
    msfStockCode: string;
    msfDescription: string;
    mappingTypeId?: number;
    locationId?: number;
    dt: string;
    searchedGenCode?: string;
    date?: Date;
    locationName?: string;
  }

  export class CustomStockToBeMapped{
    msfid: number;
    stockCode: string;
    description: string;
    stockTypeId: number;
    clientId : number;
    cost?: number;
    soh?: number;
  }

  export class Stock {
    description: string
    stockCode: string
    msfid: number
    locationId: number
    locationName: string
    brand: string
    genCode: string
    mapped?:number = 0
    showUnmap?:boolean = false;
    searchedGenCode?:string;
  }

  export class TyreStockToBeMapped{
    msfid: number;
    stockCode: string;
    description: string;
    clientId: number;
  }

  export class CustomStock {
    customStockId : number;
    msfid : number;
    stockCode : string;
    description : string;
    stockType : string;
    clientId
  }

  export class StockType{
    id: number;
    name: string;
    msfId: number;
    isVisible: boolean;
  }

  export class NewCustomStockMapping{
    nmsfId : number; 
    stockCode : string;
    clientId : number; 
    mappingTypeId : number;
    description : string ;
    userId : number ;
    officialStock : string;
    stockTypeId : number;
  }