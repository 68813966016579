import { AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { PosAuditEntry, PosAuditEntryType, StockSearchResult } from 'src/app/models/pos';
import { PosService } from '../pos.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { PosAuditService } from '../posaudit.service';
import { Quote, QuoteLine } from 'src/app/classes/quote';
import { sfSearch } from 'src/app/models/search-obj';
import { AuthService } from 'src/app/auth.service';
import { SearchService } from 'src/app/search/search.service';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Observable, Observer } from 'rxjs';
import { SettingsService } from 'src/app/settings/settings.service';

@Component({
  selector: 'app-quote-lines',
  templateUrl: './quote-lines.component.html',
  styleUrls: ['./quote-lines.component.less']
})
export class QuoteLinesComponent implements OnInit {
  passwordValidator: AsyncValidatorFn = (control: AbstractControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          const value = control.value
          this.settingsService.getGpProtectionPassword(value).subscribe({
            next: (res) => {
              if (!res) {
                return observer.next({ error: true, duplicated: true });
              }
              return observer.next(null);
            },
            error: (error) => {
              console.error(error);
              this.isLoading = false;
              return true;
            },
            complete: () => {
                observer.complete();
            }
          })
        }, 750);
    });

  validatePassword : FormGroup = new FormGroup({
    password: new FormControl('', [Validators.required], [this.passwordValidator]),
  });

  @Input() quote : Quote = new Quote();
  @Output() quoteChange = new EventEmitter<Quote>(); //this is not used at the moment... but if needs be, this can emit to update the quote upwards

  @Output() addItems = new EventEmitter<QuoteLine[]>();

  @Input() isLoading: boolean;
  //change
  //posQuote: PosQuote = new PosQuote()
  showCost: boolean = true;
  isQtyLocked = true;
  
  gpUpDownPercent = 3;

  //completely recheck
  // isLoading = false;
  isLoadingStockSearch = false;
  stockSearchResult: StockSearchResult[];
  isSearchStockModalVisible = false;
  quoteDirty = false;
  searchString = "";
  passwordModalVisible = false;
  passwordConfirm = false;
  timer: any;

  constructor(private posService: PosService, 
              private notification: NotificationService, 
              private auditService:PosAuditService, 
              private authService: AuthService,
              private searchService: SearchService,
              private settingsService: SettingsService,
            ) { }

  ngOnInit(): void {
  }


  searchStock(data: QuoteLine) {
    this.isLoadingStockSearch = true;
    this.stockSearchResult = [];
    this.isSearchStockModalVisible = true;
    this.posService.searchStock(data.stockCode).subscribe(
      val => {
        this.stockSearchResult = val;
        this.isLoadingStockSearch = false;
        this.isSearchStockModalVisible = true;
      },
      error => {
        this.notification.handleError(error);
      }
    )
  }

  updateLineItem(data: QuoteLine, field: string, event: any) {
    //action audit
    let index = this.quote.lines.findIndex(val => val === data);

    if (field === 'QTY') {
      this.auditService.addAction(new PosAuditEntry(this.quote.quoteId, PosAuditEntryType.ChangeQty, this.quote.lines[index].msfid,
        this.quote.lines[index].description,this.quote.lines[index].qty.toString(), event.toString()));
      this.quote.lines[index].qty = event;
      if (this.isQtyLocked && this.quote.lines[index].isTyre)
        this.quote.updateLockedQuantities(event);
      else{
        for (const item of this.quote.lines)
          item.totalPriceIncl = item.priceIncl * item.qty;
      }
    }
    else {
      this.auditService.addAction(new PosAuditEntry(this.quote.quoteId, PosAuditEntryType.ChangePrice, this.quote.lines[index].msfid,
        this.quote.lines[index].description, this.quote.lines[index].priceIncl.toString(), event.toString()));
      this.quote.lines[index].priceIncl = event;
    }
    
    let item = this.quote.lines[index];
    item.totalPriceIncl = item.priceIncl * item.qty;
    item.price = !item.isTyre ? item.priceIncl : item.priceIncl / this.quote.vatRate;
    // if(data.gpPercent < this.quote.gpProtectionPercent!)
    //   this.quote.isDirty = true;
    item.updateLineItemGPList(this.quote.vatRate);
    this.updateServicesPrice();
    this.quote.markDirty();
    // this.posQuote.quoteLines[index].totalPriceIncl = this.posQuote.quoteLines[index].priceIncl * this.posQuote.quoteLines[index].qty;
  }


  updateLineItemGP(item: QuoteLine, addPrice: number, type: string) {
    const prvPrice = item.priceIncl;

    item.price = Math.round(item.cost + addPrice);
    item.priceIncl = Math.round(item.price * this.quote.vatRate);

    this.auditService.addAction(new PosAuditEntry(this.quote.quoteId, PosAuditEntryType.ChangePrice, item.msfid,
      `${item.description} (GPPercent ${type})(+R${addPrice.toString()})`, prvPrice.toString(), item.priceIncl.toString()));
    this.quoteDirty = true;

    item.updateLineItemGPList(this.quote.vatRate);
    item.totalPriceIncl = item.priceIncl * item.qty;
    this.quote.markDirty();

  }

  

  cashbackBrandsGYPD = ["GOODYEAR","PIRELLI","DUNLOP"];
  cashbackBrandsMIBF = ["MICHELIN","BFGOODRICH","BFPASSENGER"];
  servicesPriceIncl: number = 0;
  cashbackPriceInclMIBF: number = 0;
  cashbackPriceInclGYPD: number = 0;

  updateServicesPrice() {
    
    this.servicesPriceIncl = 0;
    this.cashbackPriceInclMIBF = 0;
    this.cashbackPriceInclGYPD = 0;
    
    for (let line of this.quote.lines) {
      if (!line.isTyre) {
        if ([29714,29715,29716,29717,29718,29719,29720,29721].includes(line.msfid)) {
          //cashback code
          this.cashbackPriceInclMIBF += line.priceIncl * line.qty;
        }
        else if ([27747,27746,27745,27744,27743,27742,27741,27740].includes(line.msfid)) {
          this.cashbackPriceInclGYPD += line.priceIncl * line.qty;
        }
        else {
          //normal service item
          this.servicesPriceIncl += line.priceIncl * line.qty;
        }
        
      }
    }
  }

  updateGpPercent(item: QuoteLine, event: any) {
    //action audit
    this.auditService.addAction(new PosAuditEntry(this.quote.quoteId, PosAuditEntryType.ChangeGpPercent, item.msfid, item.description, item.gpPercent.toString(), event.toString()));
   
    item.changeGPPercent(event, this.quote.vatRate);
    this.quote.markDirty();
 }

  calcOptionPriceIncl(data: QuoteLine): number {
    if (this.servicesPriceIncl === 0 && this.cashbackPriceInclGYPD === 0 && this.cashbackPriceInclMIBF === 0 ) {
      //calculate the diffent service prices first
      this.updateServicesPrice();
    }
    if (this.cashbackBrandsMIBF.includes(data.brand)) {
      //part of cashback campaign
      return (data.totalPriceIncl + this.servicesPriceIncl + this.cashbackPriceInclMIBF);
    }
    else if (this.cashbackBrandsGYPD.includes(data.brand) ) {
      return (data.totalPriceIncl + this.servicesPriceIncl + this.cashbackPriceInclGYPD);
    }
    else {
      //normal item
      return (data.totalPriceIncl + this.servicesPriceIncl);
    }
  }

  calcOptionGPExcl(data: QuoteLine): number {

    if (this.cashbackBrandsMIBF.includes(data.brand)) {
      //part of cashback campaign
      return (((data.gpRands * data.qty) + ((this.servicesPriceIncl + this.cashbackPriceInclMIBF) / this.quote.vatRate)));
    }
    else if (this.cashbackBrandsGYPD.includes(data.brand)) {
      //part of cashback campaign
      return (((data.gpRands * data.qty) + ((this.servicesPriceIncl + this.cashbackPriceInclGYPD) / this.quote.vatRate)));
    }
    else {
      //normal item
      return ((data.gpRands * data.qty) + (this.servicesPriceIncl / this.quote.vatRate));
    }
  }

  removeLineItem(data: QuoteLine, tabIndex: number) {
    let index = this.quote.lines.findIndex(val => val === data);

    // action audit
    const comment = this.quote.lines[index].qty.toString() + "x " + this.quote.lines[index].description
    this.auditService.addAction(new PosAuditEntry(this.quote.quoteId, PosAuditEntryType.DeleteItem, this.quote.lines[index].msfid, comment));
    this.quoteDirty = true;
    // The following is due to the way angular does change detection:
    this.quote.lines.splice(index, 1);
    // Check if there are only services with a certain optionId and removes them.
    if(data.optionId !== null && !this.quote.lines.some(val => { return val.stockType.toLocaleUpperCase() === 'TYRE' && val.optionId === data.optionId}))
      this.quote.lines = this.quote.lines.filter(val => val.optionId !== data.optionId); // Filter out the services without a connecting tyre
    // Change the selected option if an option is removed from inside the option tab.
    if(tabIndex !== 0 && data.isTyre){
      try{
        this.quote.selectedOption = this.quote.lines[index].optionId!;
      }
      catch{  //Use the catch for when the the last option is removed.
        this.quote.selectedOption = this.quote.lines[index - 1].optionId!;
      }
    }
  
    this.quote.lines = this.quote.lines.slice(); //This will copy the array and assign it again. To force angular change detection
    this.updateServicesPrice();
    this.quote.markDirty();
  }

  selectLineItem(data: QuoteLine) {
    let index = this.quote.lines.findIndex(val => val === data);
    //action audit
    const comment = this.quote.lines[index].qty.toString() + "x " + this.quote.lines[index].description;
    this.auditService.addAction(new PosAuditEntry(this.quote.quoteId, PosAuditEntryType.SelectItem, this.quote.lines[index].msfid, comment));
    this.quoteDirty = true;
    // Filter array based on optionId to remove the rest.
    this.quote.lines = this.quote.lines.filter(val => val.optionId === data.optionId || val.optionId === null);

    this.updateServicesPrice();
    this.quote.markDirty();

  }

  public isSearchingByStockCode = false;
  searchStockInput() {
    this.isSearchingByStockCode = true;
    this.posService.searchByStockCodeWithSpecialPriceSet(this.searchString, this.quote.specialPriceSetId ? this.quote.specialPriceSetId : 0).subscribe(
      {next: val => {
        this.isSearchingByStockCode = false;
        if (val.length === 0) 
        {
          this.notification.showWarning("No results found!");
          
        }
        else {
          let newLines: QuoteLine[] = [];
          val.forEach(e => newLines.push(Object.assign(new QuoteLine() , e)));
          
          this.addItems.emit(newLines);

          if(this.quote.lines.some(val => val.isTyre === true))
            this.quote.updateLockedQuantities(this.quote.lines[0].qty);
          else{
            for (const item of this.quote.lines)
              item.totalPriceIncl = item.priceIncl * item.qty;
          }
     
          this.searchString = "";
          this.quote.lines = this.quote.lines.slice();
          this.updateServicesPrice();
          
        }
      }, 
      error: err => {
        this.isSearchingByStockCode = false;
        this.notification.handleError(err)
      }

      },

    )

  }

  totalPriceInc(optionId: number|null): string {
    // this.servicesPriceIncl = 0;
    if (!this.quote.lines)
      return "";

    
    //First filter on the option, then sum the incl price, then give it 2 decimal places and convert to string
    return this.quote.lines.filter(e => e.optionId === null || e.optionId === optionId || optionId === null)
      .reduce((a,b) => {return a + b.totalPriceIncl;},0)
      .toFixed(2).toString();
    //OLD IMPLEMENTATION:
    //let total = 0;
    // if(id === 0){
    //   for (let line of this.quote.lines) {
    //     total += line.totalPriceIncl;
    //     // if (line.stockType === "SERVICE") {
    //     //   this.servicesPriceIncl += line.priceIncl * line.qty;
    //     // }
    //   }
    // }
    // else{
    //   for (let line of this.quote.lines.filter(val => {
    //     return (val.optionId === id || val.optionId === null)
    //   })) {
    //     total += line.totalPriceIncl;
    //     // if (line.stockType === "SERVICE") {
    //     //   this.servicesPriceIncl += line.priceIncl * line.qty;
    //     // }
    //   }
    // }
    //return total.toFixed(2).toString();
  }


  percentFormatter(value: number) {
    if (value === null) {
      return "";
    }
    else {
      return value.toString() + "%";
    }

  }

  CalculateLessRetail(item:QuoteLine,value:any)
  {
    
    item.retailValuePercent = value;
    item.retailValueLess = Math.round(item.retail! - ((value/100)*item.retail!))
    item.price = item.retailValueLess;
    item.priceIncl = Math.round(item.retailValueLess * this.quote.vatRate);
    item.totalPriceIncl = item.priceIncl * item.qty;
    
    item.updateLineItemGPList(this.quote.vatRate);
    this.updateServicesPrice();
    this.quote.markDirty();
    
  }

  filteredArrayOfOptions(): (number|null)[] {
    //this will return a list of all the option numbers in the array, dunamically calculated, with NULL at the first position
    return [...new Set(this.quote.lines.map(item => item.optionId)).add(null)].sort((a,b) => {
      if (a === null) return -1;
      if (b === null) return 1;
      return a-b;
    });
  }

  tabName(optionId: number, tabIndex: number): string {
    if (optionId === null) return "ALL";
    return "Option " + optionId.toString();
  }

  filteredItemsForOption(optionId: number): QuoteLine[] {
    return this.quote.lines.filter(e => e.optionId === null || e.optionId === optionId || optionId === null);
  }

  selectedOptionTabChanged(optionId: number) {
    this.quote.selectedOption = optionId;
  }

  private removeAllAutoDiscounts() {
    for (var discount of this.quote.discountRules.filter(e => e.discounts)) {
      if (this.quote.lines.find(e => e.msfid === discount.discounts![0].msfid)) {
        //remove item from array
        this.quote.lines = this.quote.lines.filter(e => !(e.msfid === discount.discounts![0].msfid));
      }
    }
  }

  onClickShoudlApplyDiscountsAutomatically() {
    this.quote.shouldApplyDiscounts = !this.quote.shouldApplyDiscounts;
    if (!this.quote.shouldApplyDiscounts) {
      this.removeAllAutoDiscounts();
    }
    this.quote.markDirty();
  }

  public lineHasError(item: QuoteLine): boolean {
    return (this.lineHasQtyCostError(item) || this.lineHasGpProtectionError(item));

  }

  lineHasQtyCostError(item: QuoteLine): boolean{
    return(item.isTyre && (!item.qty || item.qty === 0 || !item.cost || item.cost === 0));
  }

  lineHasGpProtectionError(item: QuoteLine): boolean{
    return(this.quote.gpProtectionPercent !== null && item.isTyre && (item.gpPercent < this.quote.gpProtectionPercent!));
  }

 
  isOtherLineOptionsModalVisible = false;
  isLoadingOtherLineOptions = false;
  otherLineOptions: StockSearchResult[] = [];
  selectedLineItemWithError: QuoteLine;

  public onClickLineWithError(item: QuoteLine) {
    this.isOtherLineOptionsModalVisible = true;
    this.isLoadingOtherLineOptions = true;
    this.selectedLineItemWithError = item;
    let itemLineOptions: StockSearchResult[] = [];

    let search: sfSearch = {
      search_logic: {
        search_requirements: {
          raw_search: item.width.toString().concat(item.profile.toString(), item.rim.toString()),
          stock_msfids: [item.msfid],
          stock_msfids_only: true,
          specialPriceSetId: this.quote.specialPriceSetId
        }
      },
      sellerId : this.authService.user.client_id
    };

    this.searchService.searchStock(search).subscribe({
      next: val => {
        for (let item of val["tyres"].sort((a,b) => a.cost - b.cost)) {
          let res = new StockSearchResult();
          item.gp_rands = Math.round(item.market_price - item.cost);
          res.brand = item.brand;
          res.brandLogoUrl = item.image;
          res.cost = item.cost;
          res.description = item.description;
          res.locationId = item.location_levelId;
          res.msfid = item.msfid;
          res.price = Math.round(item.cost + item.gp_rands);
          res.sla = item.sla;
          res.soh = item.sohInt;
          res.stockCode = item.stock_code;
          res.supplier = item.seller_name;
          res.supplierId = item.sellerId;
          res.width = item.width;
          res.profile = item.profile;
          res.rim = item.rim;
          itemLineOptions.push(res);
        }
        this.otherLineOptions = itemLineOptions;
        this.isLoadingOtherLineOptions = false;
      },
      error: err => {
        this.notification.handleError(err);
        this.isLoadingOtherLineOptions = false;
      }
    });



  }

  onSelectOtherOption(item: StockSearchResult) {
    this.isOtherLineOptionsModalVisible = false;
    let itemToUpdate = this.quote.lines.find(e => e.msfid === item.msfid);
    if (itemToUpdate) {
      itemToUpdate.cost = item.cost;
      itemToUpdate.soh = item.soh;
      itemToUpdate.clientId = item.supplierId;
      itemToUpdate.clientName = item.supplier;
      itemToUpdate.updateLineItemGPList(this.quote.vatRate);
      this.quote.markDirty();
    }
  }

  submitPassword(){
    this.passwordModalVisible = false; 
    this.auditService.addAction(new PosAuditEntry(this.quote.quoteId, PosAuditEntryType.DisableGPProtectionPassword, 0, 'Overriding GP Percent Protection of ' + `${this.quote.gpProtectionPercent}` + '%'));
    this.quote.gpProtectionPercent = null; 
    this.quote.markDirty();
  }
}
