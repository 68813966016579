import { Component } from '@angular/core';
import { AdminService } from '../admin.service';
import { ClientBase } from 'src/app/models/client';
import { AdminSystemUser, UserRole } from 'src/app/models/user';
import { UsersService } from 'src/app/users/users.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.less']
})
export class AdminUsersComponent {

  public clients: ClientBase[] = [];
  public users: AdminSystemUser[] = [];
  public filteredClients: ClientBase[] = [];

  public selectedClient: string;
  public selectedClientObj: ClientBase;

  public isLoadingClients: boolean = false;
  public isLoadingUsers: boolean = false;
  public isEditUserModalVisible = false;
  public isSavingUser = false;

  constructor(private adminService: AdminService, private userService: UsersService, private notification: NotificationService) { }

  ngOnInit(): void {
    this.isLoadingClients = true;
    this.adminService.getClients().subscribe(clients => {
      this.clients = clients;
      this.filteredClients = clients;
      this.isLoadingClients = false;
    });
  }

  onSelectClient(client: ClientBase) {
    this.isLoadingUsers = true;
    this.adminService.getUsers(client.clientId).subscribe(users => {
      this.users = users;
      this.isLoadingUsers = false;
      this.selectedClientObj = client;
    });
  }

  onTypeFilter(item: any) {
    if (item.clientId) {
      this.onSelectClient(item);
      this.selectedClient = item.name;
      this.filteredClients = [];
      return;
    }

    this.filteredClients = this.clients.filter(x => x.name.toLowerCase().includes(item.toLowerCase()));
  }


  loadingButtons: string[] = []
  sendingButtons: number[] = [];

  onRoleBtnClick(role: UserRole, user: AdminSystemUser) {
    let key = user.userId.toString() + "." + role.roleId.toString();
    this.loadingButtons.push(key);
    //call api
    this.userService.flipRole(user.userId, role.roleId).subscribe(
      val => {

        const roleIndex = user.roles.indexOf(role, 0);
        user.roles[roleIndex] = val;

        //remove the button from loading list
        const index = this.loadingButtons.indexOf(key, 0);
        if (index > -1)
          this.loadingButtons.splice(index, 1);
      },
      error => {
        this.notification.handleError(error);
        //remove the button from loading list
        const index = this.loadingButtons.indexOf(key, 0);
        if (index > -1)
          this.loadingButtons.splice(index, 1);
      }

    )

  }

  showPassword(user: AdminSystemUser) {
    this.notification.showInfo("Password" + user.password);
  }

  sendUserDetails(userId: number) {
    if (this.sendingButtons.includes(userId))
      return
    this.sendingButtons.push(userId);
    this.userService.sendDetailsEmail(userId).subscribe(
      val => {
        this.notification.showSuccess(val.message);
        const index = this.sendingButtons.indexOf(userId,0);
        if (index > -1)
          this.sendingButtons.splice(index, 1);
      },
      error => {
        this.notification.handleError(error);

      }
    );
  }

  editUserForm: FormGroup = new FormGroup({    
    fullname: new FormControl('', Validators.required),
    extPosId: new FormControl(''),
    email: new FormControl('', [Validators.email, Validators.required]),
    cell: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  });
  editingUser: AdminSystemUser;
  onEditUserClick(user: AdminSystemUser) {
    this.isEditUserModalVisible = true;
    this.editingUser = user;
    this.editUserForm.controls['fullname'].setValue(user.fullname);
    this.editUserForm.controls['extPosId'].setValue(user.extPosId);
    this.editUserForm.controls['email'].setValue(user.email);
    this.editUserForm.controls['cell'].setValue(user.cell);
    this.editUserForm.controls['password'].setValue(user.password);
    
  }

  editUser() {
    this.isSavingUser = true;
    Object.values(this.editUserForm.controls).forEach(control => {
      if (control.invalid) {
        control.markAsDirty();
        control.updateValueAndValidity({ onlySelf: true });
      }
    });
    if (this.editUserForm.valid) {
      let user = new AdminSystemUser();
      user.userId = this.editingUser.userId;
      user.userName = this.editingUser.userName;
      user.fullname = this.editUserForm.value.fullname;
      user.extPosId = this.editUserForm.value.extPosId;
      user.email = this.editUserForm.value.email;
      user.cell = this.editUserForm.value.cell;
      user.password = this.editUserForm.value.password;
      this.adminService.updateUser(user).subscribe({
          next : () => {
            this.isEditUserModalVisible = false;
            this.isSavingUser = false;
            this.notification.showSuccess("User updated");
            this.onSelectClient(this.selectedClientObj);
          },
          error: err => {
            this.isSavingUser = false;
            this.notification.handleError(err);
          }
        }
      )
    }
  }

}
