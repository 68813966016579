<div nz-row style="background-color: var(--bodybackgroundcolor);" id="sticky-component" >
    <div nz-col nzSpan="24">
        <div nz-row>
            <div nz-col nzSpan="12"><h5>Procurement Maintenance [{{this.proc_Data.Client_Viewed.account.buyer_name}}]</h5></div><br>
            <div nz-col [nzSpan]="4" [nzPush]="8"><button (click)="download_msl()" nz-button nzType="primary" nz-tooltip nzTooltipTitle="Download {{this.proc_Data.Client_Viewed.account.buyer_name}}s' Model Stock"><span nz-icon nzType="download" nzTheme="outline"></span></button></div>
        </div>

        <div nz-row >
            <div nz-col nzSpan="24">
                <span>Only model stock with {{this.proc_Data.Client_Viewed.account.seller_name}} set as a supplier for {{this.proc_Data.Client_Viewed.account.buyer_name}} is displayed below</span>
            </div>
        </div>
        <hr style="border-bottom: solid 2px rgb(95, 158, 160)">
        <div nz-row >
            <div nz-col nzSpan="12">
                <input nz-input placeholder="Search Stock or Gen Code" [ngModel]="code_search" (ngModelChange)="codeInputChange($event)" id="stockSearch" style="width: 70%;" class="submit trinput" autocomplete="off" />
            </div>
            <div  nz-col [nzSpan]="4" [nzPush]="8">
                <button nz-button nzType="primary" (click)="this.proc_Data.view = 'client_list'">Return To Client List <span nz-icon nzType="rollback" nzTheme="outline"></span></button>
            </div>

        </div>
    </div>
</div>
<br>


 
<nz-spin [nzSpinning]="isLoading">
<div nz-row *ngIf="this.proc_Data.Client_Viewed" >
    <div nz-col nzSpan="24">
        
        <nz-table  #rep_lines [nzData]="this.tmpCLient_Data.replenishment_lines" id="rep_lines"   [nzFrontPagination]="false" [nzShowPagination]="false" nzSize="small" > <!--[nzScroll]="{'y':this.scrollHeight}"-->
            <thead >
                <tr id="sticky-header"> <!-- class="sticky-header"-->
                    <th [nzSortFn]="sortRepBrands" style="text-align: center;">Brand</th>
                    <th [nzSortFn]="sortRepCodes" style="text-align: center;">Code</th>
                    <th [nzSortFn]="sortRepdescriptions" style="text-align: center;">Description</th>
                    <th [nzSortFn]="sortRepMSL" style="text-align: center;">MSL</th>
                    <th [nzSortFn]="sortRepAIMSL" style="text-align: center;">AI_MSL</th>
                    <th [nzSortFn]="sortRepCost" style="text-align: center;">{{this.proc_Data.Client_Viewed.account.seller_name}}_COST</th>
                    <th [nzSortFn]="sortRepPrice" style="text-align: center;">{{this.proc_Data.Client_Viewed.account.pricelist_name}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let r of rep_lines.data | filter: '': []:0:pageSize">
                    <td><img nz-tooltip nzTooltipTitle="{{r.brand}}" style=" height:  15px; ;margin: 0 auto;" src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{r.brand_logo}}" class="brandImage" /> <div style="display: none;">{{r.brand}}</div></td>
                    <td style="font-size: {{fsBulkEdit}};">{{r.stock_code}}</td>
                    <td style="font-size: {{fsBulkEdit}};">{{r.description}}</td>
                    <td style="font-size: {{fsBulkEdit}};">{{r.msl}}</td>
                    <td style="font-size: {{fsBulkEdit}};">{{r.suggested_msl}}</td>
                    <td style="font-size: {{fsBulkEdit}};">{{r.supplier_cost}}</td>
                    <td style="font-size: {{fsBulkEdit}};">{{r.supplier_replenishment_price}}</td>
               

                </tr>
            </tbody>
        </nz-table>
        
    </div>
</div>
</nz-spin>
