import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { NotificationService } from './shared/notification.service';


@Injectable({providedIn: 'root'})
export class AuthGuard  {
    ////////////////////////////////
    // This class is deprecated in favour of the functional approach, please see below class, AuthGuardCheck
    ////////////////////////////////
    constructor(private authService: AuthService, private router: Router, private notification: NotificationService) { }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authService.isLoggedIn())
            return this.checkClaims(route);
        else
            if (this.authService.getStoredLogin())
                return this.checkClaims(route);
            else
                var retVal = this.router.createUrlTree(['/login']);
                retVal.queryParams = {"returnUrl":state.url}
                return retVal;
    }

    checkClaims(route: ActivatedRouteSnapshot): boolean | Observable<boolean> {        
        if (route.data.claimCodes) {  
            return this.authService.hasClaims(route.data.claimCodes as Array<string>, true);
        } else {
            return true;
        }
        
    }
}

export const AuthGuardCheck: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> => { 
    const authService = inject(AuthService);
    const claims = route.data.claimCodes as Array<string>;
    if (authService.isLoggedIn())
        return authService.hasClaims(claims, true);
    else
        if (authService.getStoredLogin())
            return authService.hasClaims(claims, true);
        else
            var retVal = inject(Router).createUrlTree(['/login']);
            retVal.queryParams = {"returnUrl":state.url}
            return retVal;

};