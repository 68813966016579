<!---------------upcomingleads----------------------->
<nz-table #future [nzData]="upcomingleads" [nzFrontPagination]="false">
    <thead>
        <tr>
            <th [nzSortFn]="custSortFn">Customer</th>
            <th [nzSortFn]="stateSortFn">Quote State</th>
            <th [nzSortFn]="clientSortFn">Channel </th>
            <th [nzSortFn]="dtSortFn">Date</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of future.data">
            <td>{{item.customername}} {{item.customercell}} </td>
            <td *ngIf="item.state == '40%'">
                <div class='progresss' style='color:black'>
                    <div class='progresss-bar1 text-center' style='width: 40%;'>
                        <span class='text-center' style="padding: 5px;">40%</span>
                    </div>
                </div>
                {{ item.description }}
            </td>
            <td *ngIf="item.state == '70%'">
                <div class='progresss' style='color:black'>
                    <div class='progresss-bar2 text-center' style='width: 70%;padding-top:1.5px'>
                        <span class='text-center'>70%</span>
                    </div>
                </div>
                {{ item.description }}
            </td>
            <td> {{ item.clientname }}</td>
            <td> {{ item.dt }}</td>
            <td>
                <button nz-button nzType="primary" id="btnview" (click)="viewquote(item.quoteId)">
          View <span nz-icon nzType="sf:externalSVG" nzTheme="outline" ></span>
        </button>
            </td>
        </tr>
    </tbody>
</nz-table>