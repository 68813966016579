export class brandStrat {
    brandId: number
    description: string
    rank: number
    active: boolean
    url: string
    ischanging:boolean
    metaData:boolean
    showMetaToggle:boolean = false
    tagline: boolean
    isFundingBrand:boolean
    defaultrank:number = 0;
}


export class mslBrand {
    brandId: number
    name: string
    brandLogo_url!:string
    clientId:number
    costAdvantage:number
    popularityAdvantage:number
    sliderValue:number = 0 
}


export class mslBrandsCollection {
     sfBrands:mslBrand[] = [];
     clientBrands:mslBrand[] = [];
     aiSettings:mslSettings = new mslSettings();
}

export class mslSettings{
    ms_settingsId:number
    clientId:number
    units_sold:number 
    datasetId:number
    days_of_stock:number
    dataset_days:number
    push_cheap:number
    fullset_advantage:number
    active:number
    supplierId:number
    monthly_units_stocking_cut_off:number
    conversion:number
    replenishment_cycle:number
    push_market_sales:number
    push_popular:number
    push_profit:number
    supplier_soh:number
}

export class pieChartModel {
    brand:string = "";
    color_code:string = "";
    ai_model_stock:number;
    model_stock:number;
    soh:number;
}



