<div nz-row nzJustify="center">
    <div nz-col class="text-center" nzSpan="12">
        <nz-input-group [nzSuffix]="suffixIconSearch" style="width: clamp(200px, 75%, 400px);">
            <input type="text" nz-input [(ngModel)]="inputValue" (ngModelChange)="resetTable()" placeholder="Advanced Filter" style="text-transform:uppercase;" />
        </nz-input-group>
        <ng-template #suffixIconSearch>
            <span nz-icon nzType="search"></span>
        </ng-template>
    </div>
</div>
<br>
<nz-table #open [nzData]="getLeads()" [nzFrontPagination]="false" [nzLoading]="leadsService.isLoadingLeads">
    <thead>
        <tr>
            <th nzWidth="50px"></th>
            <th [nzSortFn]="custSortFn">Customer</th>
            <!-- <th>Quote State</th> -->
            <th [nzSortFn]="saleSortFn">Salesman</th>
            <th [nzSortFn]="clientSortFn">Channel</th>
            <th>Comment</th>
            <th [nzSortFn]="dtSortFn">Date</th>
            <!-- <th [nzSortFn]="expSortFn">Expires in</th> -->
            <th nzWidth="10px">
                <app-video-widget [videoId]="13" style="float: inline-end;"></app-video-widget>
            </th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of open.data | filter: inputValue:[]:0:pageSize">
            <td>
                <div>
                    <!-- SalesMan Icon -->
                    <i nz-icon nzType="user" nzTheme="outline" [ngClass]="{'color-success': item.userId === auth.user.user_id,
                    'color-primary': item.userId && item.userId !== auth.user.user_id,
                    'color-error': !item.userId || item.userId === 0}" nz-tooltip [nzTooltipTitle]="item.userId === auth.user.user_id ? 'Assigned To You' : item.userId && item.userId !== auth.user.user_id ? 'Assigned To:' + item.assignedTo : 'UNASSIGNED'"></i>
                    <!-- Booking Icon -->
                    <i nz-icon nzType="calendar" nzTheme="outline" class="color-primary" nz-tooltip nzTooltipTitle="BOOKING: {{item.bookingDate}}" *ngIf="item.bookingDate"></i>
                    <!-- Tentatively Accepted Icon -->
                    <i nz-icon nzType="check-square" nzTheme="outline" class="color-primary" nz-tooltip nzTooltipTitle="Tentatively Accepted" *ngIf="item.statusId===18"></i>
                    <!--Callback requested-->
                    <i nz-icon nzType="phone" nzTheme="outline" class="color-primary" nz-tooltip nzTooltipTitle="Customer Requested Call Back" *ngIf="item.statusId===25"></i>
                </div>
            </td>
            <td>{{item.customerName}}<br />{{item.customerCell}} </td>
            <!-- <td *ngIf="item.state == '40%'" >
            <div class='progresss' style='color:black'>
              <div class='progresss-bar1 text-center' style='width: 40%;'>
                <span class='text-center' style="padding: 5px;">40%</span>
              </div>
            </div>
            {{ item.description }}
        </td> -->

            <!-- <td *ngIf="item.state == '70%'" >
            <div class='progresss' style='color:black'>
              <div class='progresss-bar2 text-center' style='width: 70%;padding-top:1.5px'>
              <span class='text-center' >70%</span>
              </div>
            </div>
            {{ item.description }}
        </td>  -->
            <td>{{item.assignedTo}}</td>
            <td>
                <span *ngIf="item.buyerId === item.sellerId">{{item.leadChannel}}</span>
                <!-- <div *ngIf="item.buyerId === item.sellerId">
                    <span *ngIf="item.leadChannel === 0">NOT CLASSIFIED</span>
                    <span *ngIf="item.leadChannel === 1">WALK-IN</span>
                    <span *ngIf="item.leadChannel === 2">TELEPHONIC</span>
                </div> -->
                <span *ngIf="item.buyerId !== item.sellerId">{{ item.buyerName }}</span>
            </td>
            <!-- <td> {{ item.dtAction | date:'dd-MMM HH:mm' }}</td> -->
            <td *ngIf="item.comment.length === 1">
                <span>{{item.comment}}</span>
            </td>
            <td *ngIf="item.comment.length > 1">
                <span *ngFor="let data of item.comment; let i = index">
                    {{data}}
                    <br>
                </span>
            </td>
            <td *ngIf="item.comment.length === 0"></td>
            <td> {{ item.dtAction + "Z" | date:'dd-MMM HH:mm' }} <br /><span>{{generateTimeString(item.actionInMinutes)}} ago</span></td>
            <!-- <td>
                <i *ngIf="item.expiryInHours<24" nz-icon nzType="warning" nzTheme="fill" class="text-danger-style" style="vertical-align: top; margin-right: 4px; margin-top: 3px;"></i>
                <span *ngIf="item.expiryInHours >= 0">{{ item.expiryInHours }} <span>hours </span></span>
                <span *ngIf="item.expiryInHours < 0">EXPIRED</span> 
            </td> -->
            <td>
                <button nz-button nzType="primary" id="btnview" (click)="viewquote(item)">
            View <span nz-icon nzType="sf:externalSVG" nzTheme="outline" ></span>
          </button>
            </td>
        </tr>
    </tbody>
</nz-table>

<nz-modal [(nzVisible)]="isModalVisible" nzTitle="Comments" (nzOnCancel)="isModalVisible = false" (nzOnOk)="isModalVisible = false">
    <ng-container *nzModalContent >
        <!-- <nz-timeline *ngFor="let data of commentList">
            {{data.date}}<nz-timeline-item>{{data.comment}}</nz-timeline-item>
          </nz-timeline> -->
          <nz-list nzBordered nzSize="small" *ngFor="let data of commentList">
              <nz-list-item>
                <span nz-typography>{{ data.date }}</span>
                {{ data.comment }}
            </nz-list-item>
            <!-- } -->
          </nz-list>
    </ng-container>
  </nz-modal>