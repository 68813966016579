import { BookingSlot, CustomerVehicle, customStockItem, NewPosCustomer, PosActionLogEntry, PosAuditEntry, PosAuditEntryType,wbcQuote ,PosQuote, PosQuoteLine, PosSearchCustomer, StockSearchResult, stockType } from 'src/app/models/pos';
import { NotificationService } from 'src/app/shared/notification.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { posAnimation } from '../../shared/animations';
import { NzModalService } from 'ng-zorro-antd/modal';
import { PosService } from '../pos.service';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';

import { differenceInCalendarDays } from 'date-fns';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';
import { environment } from 'src/environments/environment';
import { PosAuditService } from '../posaudit.service';
import { AuthService } from 'src/app/auth.service';
import { ReportsService } from 'src/app/shared/reports.service';
import { QuoteCommunication } from 'src/app/models/communication';
import { SendReportService } from 'src/app/widgets/send-report/send-report.service';
import { Vehicle } from 'src/app/models/vehicles';

@Component({
  selector: 'app-posquote',
  templateUrl: './posquote.component.html',
  styleUrls: ['./posquote.component.less'],
  animations: [posAnimation]
})
export class PosquoteComponent implements OnInit, OnDestroy {

  paramsSub: Subscription;
  posQuote: PosQuote = new PosQuote()
  customers: PosSearchCustomer[];
  stockSearchResult: StockSearchResult[];
  stockTypes: stockType[] = [];
  vehicles: Vehicle[]= [];
  isLoading = false;
  isLoadingCustomers = true;
  isLoadingStockSearch = true;
  isLoadingCustomer = false;
  isSavingQuote = false;
  isAddCustomerModalVisible = false;
  isSearchStockModalVisible = false;
  isBookingModalVisible = false;
  isLoadingBookingSlots = false;
  showCost = true;
  searchString = "";
  qtyT = "1";
  selectedType = "";
  desc:string = "";
  code:string = "";
  price:number = 0;
  gpUpDownPercent = 3;
  isCompleted = false;
  isWIP = false;
  isWbcModal=false

  //Locked Qty
  lockedQtyItemList = [23339, 23340, 23341, 23342, 23343, 25466];
  isQtyLocked = true;

  //Inputs
  VminputValue?: string = "";
  VvinputValue?:string = "";
  VehicleMakeOptions:string[]=[];
  VehicleOptions:Vehicle[]=[];
  VehicleVariants:Vehicle[]=[];

  constructor(
    private route: ActivatedRoute,
    private posService: PosService,
    private notification: NotificationService,
    private router: Router,
    private modalService: NzModalService,
    private auditService: PosAuditService,
    public authService: AuthService,
    public reportsService: ReportsService,
    private sendReportService: SendReportService
    
    ) {}

  ngOnInit(): void {
   

    this.paramsSub = this.route.params.subscribe(params => {
      // Redirect
      // if([1,2,3,4].includes(this.authService.user.client_id))
      // {
      //   this.quoteDirty = false;
      //   this.statusSaved = true;
      //   this.navPathAway = "/pos/quotev2" + +params['quoteid'];
      //   this.router.navigate(["/pos/quotev2",+params['quoteid']])
        
      //   return; //add return so that we don't run all the following api calls
      // }  
      // if([1,2,3,4,8,9,10,11,12,13,14,15,16,17,18,19,23,295].includes(this.authService.user.client_id))
      // {
        //full exit and redirect.
      this.quoteDirty = false;
      this.statusSaved = true;
      this.navPathAway = "/pos/v2" + +params['quoteid'];
      this.router.navigate(["/pos/v2",+params['quoteid']])
      
      return; //add return so that we don't run all the following api calls
      // }
  
      this.isLoading = true;
      // Load quote deets
      this.posService.getQuoteDetails(+params['quoteid']).subscribe(
        val => {    
  
          //action audit
          this.auditService.addAction(new PosAuditEntry(val.quoteId, PosAuditEntryType.OpenQuote, 0))
          this.posQuote = val;
          this.posQuote.quoteLines = this.posQuote.quoteLines.sort(this.SortQuoteLinesArray)
          this.posQuote.isContractPricing = false;
          this.loadActionLog();
          this.isLoading = false;
          this.updateQuoteState();
          this.updateServicesPrice();
          for (let item of this.posQuote.quoteLines)
          {
            this.updateLineItemGPList(item);
          }

          //Get Vehicles
          this.getVehicles();
          
        },
        error => {
          this.notification.handleError(error);
          this.isLoading = false;
        }
      );

    });

    this.posService.getStockTypes().subscribe(res => {
      this.stockTypes = res;
    },
    err => {
      this.notification.handleError(err);
    })

    this.posService.getCustomers().subscribe(
      val => {
        this.customers = val;
        this.isLoadingCustomers = false;
      },
      error => {
        this.notification.handleError(error);
        this.isLoadingCustomers = false;
      }

    );

    if(this.posQuote.custId == 67546  || this.posQuote.buyerId == 674 )
    {
      this.router.navigate(["/pos/fleet",this.posQuote.quoteId]);
    }


  }

  private SortQuoteLinesArray(item1: PosQuoteLine, item2: PosQuoteLine): number { 
    // if (item1.stockType.toUpperCase() === "TYRE" && item2.stockType.toUpperCase() !== "TYRE") return -1;
    // if (item2.stockType.toUpperCase() === "TYRE" && item1.stockType.toUpperCase() !== "TYRE") return 1;
    // //Both are the same, tyre or service
    // if (item1.recommended === true && item2.recommended === false || null) return -1;
    // if (item2.recommended === true && item1.recommended === false || null) return 1;
    //both are recommended, or not recommended
    //order by most soh
    return item1.rank! - item2.rank!;
  }

  removeDuplicates(arr, predicate) {
    var found = {};
    arr.forEach(d => {
      found[predicate(d)] = d;
    });
    return Object.keys(found).map(key => found[key]);
  }

  getVehicles()
  {
    this.posService.getVehicles().subscribe(res=> {
      this.vehicles = res as Vehicle[];
      let ArrayMakes = [] as string[];
      for(var i = 0; i < this.vehicles.length; i++)
      {
        ArrayMakes.push(this.vehicles[i].Make);    
      }   
      let uniq = [...new Set(ArrayMakes)];
      this.VehicleMakeOptions = uniq;
    });


  }

  // #region " QUOTE "

  randFormatter(value: number) {
    if (value === null) {
      return "";
    }
    else {
      return "R " + value.toString();
    }

  }

  percentFormatter(value: number) {
    if (value === null) {
      return "";
    }
    else {
      return value.toString() + "%";
    }

  }

  removeLineItem(index: number) {
    //action audit
    const comment = this.posQuote.quoteLines[index].qty.toString() + "x " + this.posQuote.quoteLines[index].description
    this.auditService.addAction(new PosAuditEntry(this.posQuote.quoteId, PosAuditEntryType.DeleteItem, this.posQuote.quoteLines[index].msfid, comment));
    this.quoteDirty = true;
    // The following is due to the way angular does change detection:
    this.posQuote.quoteLines.splice(index, 1);
    this.posQuote.quoteLines = this.posQuote.quoteLines.slice(); //This will copy the array and assign it again. To force angular change detection

  }

  selectLineItem(index: number) {
    //action audit
    const comment = this.posQuote.quoteLines[index].qty.toString() + "x " + this.posQuote.quoteLines[index].description
    this.auditService.addAction(new PosAuditEntry(this.posQuote.quoteId, PosAuditEntryType.SelectItem, this.posQuote.quoteLines[index].msfid, comment))
    this.quoteDirty = true;

    let newArr: PosQuoteLine[] = [];
    for (var i in this.posQuote.quoteLines) {
      if (this.posQuote.quoteLines[i].stockType.toUpperCase() !== 'TYRE' || +i === index) {
        newArr.push(this.posQuote.quoteLines[i]);
      }
    }
    this.posQuote.quoteLines = newArr;
  }

  cashbackBrandsGYPD = ["GOODYEAR","PIRELLI","DUNLOP"];
  cashbackBrandsMIBF = ["MICHELIN","BFGOODRICH","BFPASSENGER"];

  updateServicesPrice() {
    this.servicesPriceIncl = 0;
    this.cashbackPriceInclMIBF = 0;
    this.cashbackPriceInclGYPD = 0;

    for (let line of this.posQuote.quoteLines) {
      if (line.stockType === "SERVICE") {
        if ([29714,29715,29716,29717,29718,29719,29720,29721].includes(line.msfid)) {
          //cashback code
          this.cashbackPriceInclMIBF += line.priceIncl * line.qty;
        }
        else if ([27747,27746,27745,27744,27743,27742,27741,27740].includes(line.msfid)) {
          this.cashbackPriceInclGYPD += line.priceIncl * line.qty;
        }
        else {
          //normal service item
          this.servicesPriceIncl += line.priceIncl * line.qty;
        }
        
      }
    }
  }

  calcOptionPriceIncl(data: PosQuoteLine): number {
    
    
    if (this.cashbackBrandsMIBF.includes(data.brand)) {
      //part of cashback campaign
      return (data.totalPriceIncl + this.servicesPriceIncl + this.cashbackPriceInclMIBF);
    }
    else if (this.cashbackBrandsGYPD.includes(data.brand) ) {
      return (data.totalPriceIncl + this.servicesPriceIncl + this.cashbackPriceInclGYPD);
    }
    else {
      //normal item
      return (data.totalPriceIncl + this.servicesPriceIncl);
    }
  }

  calcOptionGPExcl(data: PosQuoteLine): number {
    //((data.gpRands * data.qty) + (servicesPriceIncl / 1.15)).toFixed(2)\
    if (this.cashbackBrandsMIBF.includes(data.brand)) {
      //part of cashback campaign
      return (((data.gpRands * data.qty) + ((this.servicesPriceIncl + this.cashbackPriceInclMIBF) / 1.15)));
    }
    else if (this.cashbackBrandsGYPD.includes(data.brand)) {
      //part of cashback campaign
      return (((data.gpRands * data.qty) + ((this.servicesPriceIncl + this.cashbackPriceInclGYPD) / 1.15)));
    }
    else {
      //normal item
      return ((data.gpRands * data.qty) + (this.servicesPriceIncl / 1.15));
    }
  }


  updateLineItem(index: number, field: string, event: any) {
    //action audit
    if (field === 'QTY') {
      this.auditService.addAction(new PosAuditEntry(this.posQuote.quoteId, PosAuditEntryType.ChangeQty, this.posQuote.quoteLines[index].msfid,
        this.posQuote.quoteLines[index].description,this.posQuote.quoteLines[index].qty.toString(), event.toString()));
      this.posQuote.quoteLines[index].qty = event;
      if (this.isQtyLocked)
        this.updateLockedQuantities(event);
    }
    else {
      this.auditService.addAction(new PosAuditEntry(this.posQuote.quoteId, PosAuditEntryType.ChangePrice, this.posQuote.quoteLines[index].msfid,
        this.posQuote.quoteLines[index].description, this.posQuote.quoteLines[index].priceIncl.toString(), event.toString()));
      this.posQuote.quoteLines[index].priceIncl = event;
    }
    this.quoteDirty = true;
    let item = this.posQuote.quoteLines[index];
    item.totalPriceIncl = item.priceIncl * item.qty;
    item.price = item.brand.toUpperCase() === 'SERVICE' ? item.priceIncl : item.priceIncl / this.posQuote.vatRate;
    this.updateLineItemGPList(item);
    this.updateServicesPrice();
    // this.posQuote.quoteLines[index].totalPriceIncl = this.posQuote.quoteLines[index].priceIncl * this.posQuote.quoteLines[index].qty;
  }

  private updateLockedQuantities(qty: number) {
    if (this.isQtyLocked){
      for (const item of this.posQuote.quoteLines) {
        if (item.stockType === "TYRE") {
          item.qty = qty;
          item.totalPriceIncl = item.priceIncl * item.qty;
        }
        else if (item.stockType === "SERVICE" && this.lockedQtyItemList.some(e => e === item.msfid)){
          item.qty = qty;
          item.totalPriceIncl = item.priceIncl * item.qty;
        }
      }
    }
  }

  updateGpPercent(item: PosQuoteLine, event: any) {
     //action audit
    this.auditService.addAction(new PosAuditEntry(this.posQuote.quoteId, PosAuditEntryType.ChangeGpPercent, item.msfid,
      item.description, item.gpPercent.toString(), event.toString()));
    item.gpPercent = event;
    this.quoteDirty = true;

    item.price = Math.round((item.cost / (100 - item.gpPercent) * 100));
    item.priceIncl = Math.round(item.price * this.posQuote.vatRate);
    item.totalPriceIncl = item.priceIncl * item.qty;
    this.updateLineItemGPList(item);
  }

  updateLineItemGP(item: PosQuoteLine, addPrice: number, type: string) {
    const prvPrice = item.priceIncl;


    item.price = Math.round(item.cost + addPrice);
    item.priceIncl = Math.round(item.price * this.posQuote.vatRate);

    this.auditService.addAction(new PosAuditEntry(this.posQuote.quoteId, PosAuditEntryType.ChangePrice, item.msfid,
      `${item.description} (GPPercent ${type})(+R${addPrice.toString()})`, prvPrice.toString(), item.priceIncl.toString()));
    this.quoteDirty = true;

    this.updateLineItemGPList(item);
    item.totalPriceIncl = item.priceIncl * item.qty;
  }


  servicesPriceIncl: number = 0;
  cashbackPriceInclMIBF: number = 0;
  cashbackPriceInclGYPD: number = 0;

  totalPriceInc(): string {

    // this.servicesPriceIncl = 0;
    if (!this.posQuote.quoteLines)
      return "";

    let total = 0;
    for (let line of this.posQuote.quoteLines) {
      total += line.totalPriceIncl;
      // if (line.stockType === "SERVICE") {
      //   this.servicesPriceIncl += line.priceIncl * line.qty;
      // }
    }
    return total.toFixed(2).toString();
  }


  private updateLineItemGPList(item: PosQuoteLine) {

    item.gpPercent = Math.round(100 * (item.price - item.cost) / item.price); //Yes
    item.gpRands = Math.round(item.price - item.cost); //Yes
    item.gpPercentHigh = item.gpPercent + this.gpUpDownPercent;
    item.gpPercentLow = item.gpPercent - this.gpUpDownPercent;

    item.gpRandsHigh = Math.round((item.cost / (100 - item.gpPercentHigh) * 100) - item.cost);
    item.gpRandsLow = Math.round((item.cost / (100 - item.gpPercentLow) * 100) - item.cost);

    item.fiveHigh = +((item.cost + item.gpRandsHigh) * this.posQuote.vatRate).toFixed(0);
    item.fiveLow = +((item.cost + item.gpRandsLow) * this.posQuote.vatRate).toFixed(0);
  }

  addItemToQuote(item: PosQuoteLine) {
    //action audit
    
    if (this.posQuote.quoteLines.find(e => (e.msfid === item.msfid && item.stockType === 'TYRE') || (e.customStockId && item.customStockId && e.customStockId == item.customStockId))) {
      //this will ensure that we don't add one item multiple times.
      this.notification.showWarning(`${item.description} already on quote`);
      return;
    }

    const comment = item.qty.toString() + "x " + item.description
    this.auditService.addAction(new PosAuditEntry(this.posQuote.quoteId, PosAuditEntryType.AddItem, item.msfid, comment))
    this.quoteDirty = true;

    this.updateLineItemGPList(item);
    item.totalPriceIncl = item.priceIncl * item.qty;
    this.posQuote.quoteLines.push(item);
    this.posQuote.quoteLines = this.posQuote.quoteLines.sort(this.SortQuoteLinesArray);
    this.posQuote.quoteLines = this.posQuote.quoteLines.slice();
  }

  clearQuote() {
    //action audit
    this.auditService.addAction(new PosAuditEntry(this.posQuote.quoteId, PosAuditEntryType.ClearQuote, 0))
    this.quoteDirty = true;

    this.posQuote.quoteLines = [];
    this.posQuote.quoteLines = this.posQuote.quoteLines.slice(); //This will copy the array and assign it again. To circumvent angular change detection
  }

  updateQuoteState() {
    this.posService.getQuoteState(this.posQuote.quoteId).subscribe(
      val => {
        this.posQuote.state = val;
        if(this.posQuote.state.stateId === 3)
        {
          this.isCompleted = true;
        }
        if ([26,27].includes(this.posQuote.state.quoteStatusId)) {
          this.isWIP = true;
          this.statusSaved = true;
        }
      },
      error => {
        this.notification.handleError(error);
      }
    )
  }

  public getQuoteLineWarningTextSeller(item: PosQuoteLine): string {
    if (item.brand === 'SERVICE') {
      return "";
    }
    return item.clientId !== item.origClientId ? `SELLER: ${item.origClientName} -> ${item.clientName}` : "";
  }

  public getQuoteLineWarningTextCost(item: PosQuoteLine): string {
    if (item.brand === 'SERVICE') {
      return "";
    }
    return item.cost !== item.origCost ? `COST: R${item.origCost} -> R${item.cost}` : "";
  }


 // #region Status Updates

  // getStatePercentage(): string {
  //   if (!this.posQuote || !this.posQuote.state) return "N/A";
  //   switch (this.posQuote.state.stateId){
  //     case 1: { return "40%"}
  //     case 2: { return "70%"}
  //     case 3: { return "100%"}
  //     case 4: { return "N/A"}
  //     default:{ return "N/A" }

  //   }

  // }

  // quoteStatusses: QuoteStatus[] = [];
  // isUpdateStatusModalVisible = false;
  // isLoadingUpdateStatusses = false;
  // showQuoteStatusModal() {
  //   //this will no longer be used, but kept for reference purpose
  //   this.isUpdateStatusModalVisible = true;

  //   if (this.quoteStatusses.length === 0) {
  //     this.isLoadingUpdateStatusses= true;
  //     this.posService.getStatusses().subscribe(
  //       val => {
  //         this.quoteStatusses = val; //Filter out follow up later
  //         this.isLoadingUpdateStatusses = false;
  //       },
  //       error => {
  //         this.notification.handleError(error);
  //         this.isLoadingUpdateStatusses = false;
  //       }

  //     )
  //   }
  // }

  // updateQuoteStatus(statusId: number, actionDate?: Date) {
  //   //this will no longer be used, but kept for reference purpose
  //   this.isLoadingUpdateStatusses= true;

  //   this.posService.updateQuoteState(this.posQuote.quoteId, statusId, actionDate).subscribe(
  //     val => {
  //       this.posQuote.state = val;
  //       this.isLoadingUpdateStatusses= false;
  //       this.isUpdateStatusModalVisible = false;
  //     },
  //     error => {
  //       this.notification.handleError(error);
  //       this.isLoadingUpdateStatusses= false;
  //       this.isUpdateStatusModalVisible = false;
  //     }
  //   )
  // }

  private updateQuoteStatus(statusId: number, actionDate?: Date) {

    this.posService.updateQuoteState(this.posQuote.quoteId, statusId, actionDate).subscribe(
      val => {
        this.posQuote.state = val;

      },
      error => {
        this.notification.handleError(error);

      }
    )
  }

  isNavAwayVisible = false;
  showStatusModal(isNavAwayVisible: boolean){
    this.isNavModalVisible = true;
    this.isNavAwayVisible = isNavAwayVisible;
    this.followUpDate = new Date();
  }

  followUpAddMinutes(num: number) {
    this.followUpDate = new Date(); //reset the date and time, to be 100% sure
    this.followUpDate.setMinutes(this.followUpDate.getMinutes() + num);
    this.followUpDatePicker.writeValue(this.followUpDate);
    this.updateQuoteStateWithAction(PosAuditEntryType.FollowUpLater);
  }

  followUpAddHours(num: number) {
    this.followUpDate = new Date(); //reset the date and time, to be 100% sure
    this.followUpDate.setHours(this.followUpDate.getHours() + num);
    this.followUpDatePicker.writeValue(this.followUpDate);
    this.updateQuoteStateWithAction(PosAuditEntryType.FollowUpLater);
  }

  // followUpTomorrow() {
  //   // this.followUpDate.set(this.followUpDate.getHours() + num)

  //   this.followUpDate.setDate(this.followUpDate.getDate() + 1);
  //   this.followUpDate.setHours(0);
  //   this.followUpDate.setMinutes(0);
  //   this.followUpDate.setSeconds(0);
  //   this.followUpDatePicker.writeValue(this.followUpDate);
  // }

  followUpAddDays(num: number) {
    // this.followUpDate.set(this.followUpDate.getHours() + num)
    this.followUpDate = new Date(); //reset the date and time, to be 100% sure
    this.followUpDate.setDate(this.followUpDate.getDate() + num);
    this.followUpDate.setHours(0);
    this.followUpDate.setMinutes(0);
    this.followUpDate.setSeconds(0);
    this.followUpDatePicker.writeValue(this.followUpDate);
    this.updateQuoteStateWithAction(PosAuditEntryType.FollowUpLater);
  }

  followUpLunch() {
    this.followUpDate = new Date(); //reset the date and time, to be 100% sure
    this.followUpDate.setHours(12);
    this.followUpDate.setMinutes(0);
    this.followUpDate.setSeconds(0);
    this.followUpDatePicker.writeValue(this.followUpDate);
    this.updateQuoteStateWithAction(PosAuditEntryType.FollowUpLater);
  }

  checkLunchDisabled() : boolean {
    if (new Date().getHours() < 12) {
      return false;
    }
    return true;
  }

  // followUpNow() {
  //   this.followUpDate = new Date();
  //   this.followUpDatePicker.writeValue(this.followUpDate);
  // }

  PosAuditEntryType = PosAuditEntryType;
  // selectedEntryType: PosAuditEntryType;
  // isFollowingUp: boolean = false;
  statusComment: string = "";
  // updateQuoteStateWithAction(followUp: boolean) {
  //   if (this.selectedEntryType != 0)
  //     this.auditService.addAction(new PosAuditEntry(this.posQuote.quoteId, this.selectedEntryType, 0, (followUp===true ? this.followUpDate.toLocaleString() : "") + (this.statusComment.length > 0 ? " " + this.statusComment : "")));
  //   this.saveQuote(); //will also save the action log
  //   this.statusSaved = true;
  //   let statusId: number = 0;
  //   switch (this.selectedEntryType) {
  //     case PosAuditEntryType.FollowUpLater: {statusId = 6; break;}
  //     case PosAuditEntryType.LookingSpecificTyre: {statusId = 14; break;}
  //     case PosAuditEntryType.Looking2ndHand: {statusId = 16; break;}
  //     case PosAuditEntryType.LookingCloserFC: {statusId = 13; break;}
  //     case PosAuditEntryType.CustomerUnreachable: {statusId = 10; break;}
  //     case PosAuditEntryType.PriceChecking: {statusId = 14; break;}
  //     case PosAuditEntryType.TentativelyAccepted: {statusId = 18; break;}
  //     default:
  //       statusId = 6;
  //   }

  //   if (!followUp) {
  //     this.updateQuoteStatus(statusId, undefined);
  //     if (!this.navPathAway) {
  //       this.navPathAway = "/search";
  //     }
  //   }
  //   else {
  //     //Make statusId = 6 (Follow Up Later) to ensure quote is not closed,
  //     //  UNLESS it is tentatively accepted (18)
  //     if (statusId != 18) statusId = 6;
  //     this.updateQuoteStatus(statusId, this.followUpDate);
  //     this.statusComment = "";
  //     this.selectedEntryType = PosAuditEntryType.None;
  //     this.isFollowingUp = false;

  //   }

  //   if (this.navPathAway) {
  //     this.router.navigate([this.navPathAway])
  //   }
  //   this.isNavModalVisible = false;

  // }

  // selectQuoteStateWithAction(action: PosAuditEntryType) {
  //   this.selectedEntryType = action;
  // }

  updateQuoteStateWithAction(action: PosAuditEntryType, followUp: boolean = true){
    this.auditService.addAction(new PosAuditEntry(this.posQuote.quoteId, action, 0, (followUp===true ? this.followUpDate.toLocaleString().substring(0,17).replace(", 00:00","") : "") + (this.statusComment.length > 0 ? " " + this.statusComment : "")));
    this.saveQuote(); //will also save the action log
    this.statusSaved = true;
    let statusId: number = 0; //to store the quote status id, based on the action we just took
    switch (action) {
      case PosAuditEntryType.FollowUpLater:{statusId = 6; break;}
      case PosAuditEntryType.NotReadyToBuy:{statusId = 21; break;}
      case PosAuditEntryType.IncorrectCustomerDetails:{statusId = 22; break;}
      case PosAuditEntryType.BoughtFromCompetitor:{statusId = 23; break;}
      case PosAuditEntryType.ClosedOther:{statusId = 24; break;}
      case PosAuditEntryType.TentativelyAccepted:{statusId = 18; break;}

      default:
        statusId = 6;
        break;
    }

    if (!followUp) {
      this.updateQuoteStatus(statusId, undefined);
      if (!this.navPathAway) {
        this.navPathAway = "/search";
      }
    }
    else {
      this.updateQuoteStatus(statusId, this.followUpDate);
      this.statusComment = "";

    }

    if (this.navPathAway) {
      this.router.navigate([this.navPathAway])
    }
    this.isNavModalVisible = false;

  }

  navigateWithoutSaving () {
    this.router.navigate([this.navPathAway]);
  }

  navigateWithSaving () {
    this.saveQuote();
    this.router.navigate([this.navPathAway]);
  }

  followUpDate: Date = new Date();
  @ViewChild('followUpDatePicker') followUpDatePicker!: NzDatePickerComponent;
  today = new Date(); //to reuse a date object to check all dates, in stead of creating a new one for evey day to check
  // onClickAssignFollowUp(val: Date) {


  // }

  disabledDate = (current: Date): boolean =>
    // Can not select days before today, but not today
    differenceInCalendarDays(current, this.today) < 0;

  // getDateDiff(): string {
  //   let mins = Math.round((this.followUpDate.getTime() - new Date().getTime()) / (60 * 1000));
  //   let hours = Math.floor(mins / 60);
  //   mins = mins % 60;
  //   let days = Math.floor(hours / 24);
  //   hours = hours % 24;
  //   let statusString = "In ";
  //   if (days > 2) return "In " + days.toString() + " days"
  //   if (days === 1 || days === 2) {
  //     return "In " + days.toString() + " days, " + hours.toString() + " hours"
  //   }
  //   if (hours >= 6) return `In ${hours.toString()} hours`
  //   if (hours === 0) return `In ${mins.toString()} minutes`
  //   return `In ${hours.toString()} hours, ${mins.toString()} minutes`

  // }

  // #endregion

  isEmailingQuote = false;
  isEmailQuoteModalVisible = false;
  emailAddressTo = "";
  emailQuote() {
    //action audit
    const comment = this.emailAddressTo ? this.emailAddressTo : this.posQuote.custEmail;
    this.auditService.addAction(new PosAuditEntry(this.posQuote.quoteId, PosAuditEntryType.EmailedQuote, this.posQuote.custId, comment));
    this.SaveAndLoadActionLog();
    this.isSavingQuote = true;
    this.isEmailingQuote = true;
    this.posService.updateQuote(this.posQuote).subscribe(
      () => {
        this.isSavingQuote = false;
        this.posService.emailQuote(this.posQuote.quoteId, this.emailAddressTo).subscribe(
          val => {
            this.isSavingQuote = false;
            this.isEmailingQuote = false;
            this.notification.showSuccess("Quote emailed");
            this.isEmailQuoteModalVisible = false;
          },
          error => {
            this.notification.handleError(error);
            this.isSavingQuote = false;
            this.isEmailingQuote = false;
            this.isEmailQuoteModalVisible = false;
          })

      },
      error => {
        this.notification.handleError(error);
        this.isSavingQuote = false;
        this.isEmailingQuote = false;
        this.isEmailQuoteModalVisible = false;
      });
  }

  clickEmailQuote() {
    this.isEmailQuoteModalVisible = true;
    this.emailAddressTo = this.posQuote.custEmail;
  }

  clickSendQuote() {
    if (!this.isAllQuoteValuesValid()) {
      return;
    }
    //action audit
    //this.auditService.addAction(new PosAuditEntry(this.posQuote.quoteId, PosAuditEntryType.SalesmanViewedQuote, 0));

    this.saveQuote()

    
    let rpt = new QuoteCommunication(this.posQuote.quoteRef, 
      this.posQuote.quoteId, 
      this.authService.user.user_id, 
      this.posQuote.custCell, 
      this.posQuote.custEmail, 
      this.posQuote.custId);
    
    this.sendReportService.assignReport(rpt);
  }
  // #endregion

  // #region " Audit Log ""
  isLoadingLog = false;
  actionLog: PosActionLogEntry[] = [];
  truncActionLog: PosActionLogEntry[] = [];
  datepipe: DatePipe = new DatePipe('en-US');
  isFullActionLogModalVisible = false;

  private handleActionLog(val: PosActionLogEntry[]) {
    this.actionLog = val;

    if (val.length > 3) {
      this.truncActionLog = val.slice().splice(0,3);
    }
    else {
      this.truncActionLog = val;
    }
  }

  private SaveAndLoadActionLog() {
    this.isLoadingLog = true;
    this.auditService.SaveActionsSub().subscribe(
      () => {
        this.posService.getActionLog(this.posQuote.quoteId).subscribe(
          val => {
            this.handleActionLog(val);
            this.isLoadingLog = false;
          },
          () => {this.isLoadingLog = false;}
        )
      },
      () => {this.isLoadingLog = false;}
    );
  }

  loadActionLog() {
    this.isLoadingLog = true;

    this.posService.getActionLog(this.posQuote.quoteId).subscribe(
      val => {
        this.handleActionLog(val);
        this.isLoadingLog = false;
      },
      error => {
        this.notification.handleError(error);
        this.isLoadingLog = false;
      }
    )
  }

  showFullActionLog() {
    this.isLoadingLog = true;
    this.auditService.SaveActionsSub().subscribe(
      () => {
        this.posService.getActionLog(this.posQuote.quoteId).subscribe(
          val => {
            this.handleActionLog(val);
            this.isFullActionLogModalVisible = true;
            this.isLoadingLog = false;
          },
          error => {
            this.notification.handleError(error);
            this.isLoadingLog = false;
          }
        )
      },
      error => {
        this.notification.handleError(error);
        this.isLoadingLog = false;
      }
    )
  }
  // #endregion

  // #region " CUSTOMER "


  wbcForm: FormGroup = new FormGroup({
    name: new FormControl('',Validators.required),
    surname: new FormControl('',Validators.required),
    email: new FormControl('',[Validators.required,Validators.email]),
    cell: new FormControl('',Validators.required),
    vehicleMilage: new FormControl(0,[Validators.required, Validators.min(1)]),
    vehicleCondition: new FormControl('',Validators.required),
    vehicleMake: new FormControl('',Validators.required ),
    vehicleYear: new FormControl('',Validators.required),
    vehicleModel: new FormControl('',Validators.required),
    vehicleVariant: new FormControl('',Validators.required)
    // vehicleReg: new FormControl('',Validators.required) #Future Dev
  });

  vehicleCategory:string[] =  ['other','vehicle','motorbike','caravan','trailer','boat']
  vehicleCondition:string[] = ['Excellent','Good','Average','Below Average','Very Poor']
  vehicleConditionselect:string = '';
  vehicleMake:string = '';
  vehicleModel: string = '';
  vehicleVariant:string = '';

  onConditionSelect(value)
  {
   this.vehicleConditionselect = value;
  }

  onMakeSelect(make)
  {
    this.vehicleMake = make;
  }
  
  onSearchVmake(value)
  {
    this.VehicleOptions = [];
    for(var i = 0; i < this.vehicles.length; i++)
    {        
      let option = this.vehicles[i];
      if ( option.Make == this.vehicleMake && option.Model.toLowerCase().includes(value.toLowerCase()))
      {
        this.VehicleOptions.push(option);
      }  
    }
  }

  onSearchVvariant(value)
  {
    this.VehicleVariants = [];
    for(var i = 0; i < this.vehicles.length; i++)
    {        
      let option = this.vehicles[i];
      if (option.Variant != null && option.Variant.toLowerCase().includes(value.toLowerCase()) && option.Make == this.vehicleMake)
      {
        this.VehicleVariants.push(option);
      }  
    }

  }

  showAddVehiclModal()
  {
    this.wbcForm.controls["name"].setValue(this.posQuote.custName.split(" ")[0]);
    this.wbcForm.controls["surname"].setValue(this.posQuote.custName.split(" ")[1]);
    this.wbcForm.controls["email"].setValue(this.posQuote.custEmail);
    this.wbcForm.controls["cell"].setValue(this.posQuote.custCell);
    this.isWbcModal = true;
  }

  sendWBCquote()
  {

    Object.values(this.wbcForm.controls).forEach(control => {
      if (control.invalid) 
      {
        control.markAsDirty();
        control.updateValueAndValidity({ onlySelf: true });
      }
      return;
    });

    if (this.wbcForm.valid) 
    {
      let wbcQuoteDetails: wbcQuote = new wbcQuote();
      wbcQuoteDetails.vehicleCategory = "vehicle";
      wbcQuoteDetails.reference = "StockFinder-" + this.posQuote.quoteId;
      wbcQuoteDetails.vehicleMilage = this.wbcForm.value.vehicleMilage;
      wbcQuoteDetails.vehicleCondition = this.wbcForm.value.vehicleCondition;
      wbcQuoteDetails.vehicleMake = this.wbcForm.value.vehicleMake;
      wbcQuoteDetails.vehicleYear = this.wbcForm.value.vehicleYear;
      wbcQuoteDetails.vehicleModel = this.wbcForm.value.vehicleModel;
      wbcQuoteDetails.vehicleVariant = this.wbcForm.value.vehicleVariant;
      wbcQuoteDetails.name = this.wbcForm.value.name;
      wbcQuoteDetails.surname = this.wbcForm.value.surname;
      wbcQuoteDetails.email = this.wbcForm.value.email;
      wbcQuoteDetails.mobilePhone = this.wbcForm.value.cell;
      wbcQuoteDetails.customerId = this.posQuote.custId;
      wbcQuoteDetails.vehicleVariant =  this.wbcForm.value.vehicleVariant; 

      this.posService.generateWbcQuote(wbcQuoteDetails).subscribe(res => {
        this.isWbcModal = false;
        this.wbcForm.reset();
        this.notification.showSuccess("WBC Quote Requested Successfully!");
      },
      error => {
        this.notification.handleError(error);
      });
  }
 
  }

  addCustomerForm: FormGroup = new FormGroup({
    custVReg: new FormControl(''),
    custName: new FormControl('',),
    custCell: new FormControl('', Validators.compose([Validators.pattern('^[+0-9]+$'), Validators.minLength(10), Validators.maxLength(13)])),
    custEmail: new FormControl('', Validators.email),
    custDebtorAcc:new FormControl(null)
  });


  showAddCustomerModal()
  {
    this.addCustomerForm.controls["custName"].setValue(this.searchedCustomerName);
    this.addCustomerForm.controls["custCell"].setValue(this.searchedCustomerCell);
    this.isAddCustomerModalVisible = true;
  }

  public isAddingCustomer = false;
  addCustomer() {
    this.addCustomerForm.controls['custVReg'].setErrors(null);
    if (this.addCustomerForm.value.custCell === "") this.addCustomerForm.controls['custCell'].setErrors(null);
    /* The following will loop through all controls and mark them as Dirty, so that visual validation can happen. This only happens on btn click */
    Object.values(this.addCustomerForm.controls).forEach(control => {
      if (control.invalid) {
        control.markAsDirty();
        control.updateValueAndValidity({ onlySelf: true });
      }
    });

    if (this.addCustomerForm.value.custVReg === "" && this.addCustomerForm.value.custCell === "")
    {

      this.addCustomerForm.controls['custVReg'].markAsDirty();
      this.addCustomerForm.controls['custCell'].markAsDirty();
      this.addCustomerForm.controls['custVReg'].setErrors({'error': true});
      this.addCustomerForm.controls['custCell'].setErrors({'error': true});
      this.addCustomerForm.controls['custDebtorAcc'].setErrors({'errors':true});
    }
    if (this.addCustomerForm.valid) {
      this.isAddingCustomer = true;
      let cust: NewPosCustomer = new NewPosCustomer()
      cust.name = this.addCustomerForm.value.custName;
      cust.cell = this.addCustomerForm.value.custCell;
      cust.email = this.addCustomerForm.value.custEmail;
      cust.debtorAcc = this.addCustomerForm.value.custDebtorAcc;
      this.isLoadingCustomer = true;
      this.isAddCustomerModalVisible = false;
      this.posService.addCustomer(cust).subscribe(
        val => {
          this.auditService.addAction(new PosAuditEntry(this.posQuote.quoteId, PosAuditEntryType.SalesmanAddCustomer, val.customerId, cust.name + ':' + cust.cell + ':' + cust.email));
          this.linkCustomer(val.customerId);
          //Add VREG and Link
          if (this.addCustomerForm.value.custVReg.length > 2) {
            let veh: CustomerVehicle = new CustomerVehicle()
            veh.vehReg = this.addCustomerForm.value.custVReg;
            veh.vehWidth = this.posQuote.width;
            veh.vehProfile = this.posQuote.profile;
            veh.vehRim = this.posQuote.rim;
            this.posService.addCustomerVehicle(val.customerId, veh).subscribe(
              vali => {

                this.auditService.addAction(new PosAuditEntry(this.posQuote.quoteId, PosAuditEntryType.AddedVehicleReg, vali.mappingId, veh.vehReg));

                this.posService.linkRegToQuote(vali.mappingId, this.posQuote.quoteId).subscribe(
                  val2 => {
                    this.isAddingCustomer = false;
                    this.auditService.addAction(new PosAuditEntry(this.posQuote.quoteId, PosAuditEntryType.LinkedVehicle, vali.mappingId, veh.vehReg));

                    this.SaveAndLoadActionLog();

                  },
                  error => {
                    this.notification.handleError(error);
                    this.isAddingCustomer = false;
                  }
                )
              },
              error => {
                this.isAddingCustomer = false;
                this.notification.handleError(error);
              }
            )
          }
          else {
            this.isAddingCustomer = false;
          }
        },
        error => {
          this.notification.handleError(error);
          this.isLoadingCustomer = false;
        }
      )
    }


  }

  selectedCustomer: number; //useless piece of bag because otherwise we don't get the change model event firing

  linkCustomer(value: number) {
    if (value === undefined || value === null) return;

    this.isLoadingCustomer = true;

    this.posService.linkCustomerToQuote(this.posQuote.quoteId, value).subscribe(
      val => {
        this.posQuote.custId = val.customerId;
        this.posQuote.custName = val.name;
        this.posQuote.custCell = val.cell;
        this.posQuote.custEmail = val.email;
        this.auditService.addAction(new PosAuditEntry(this.posQuote.quoteId, PosAuditEntryType.SalesmanSelectCustomer, val.customerId, val.name + ':' + val.cell));
        this.updateQuoteState();
        this.SaveAndLoadActionLog();
        this.isLoadingCustomer = false;
        if (value === 67546) {
          //standard bank fleet was selected
          this.quoteDirty = false;
          this.statusSaved = true;
          this.router.navigate(['pos','fleet',this.posQuote.quoteId]);
        }
      },
      error => {
        this.notification.handleError(error);
        this.isLoadingCustomer = false;
      }
    )


  }

  savecomment()
  {
    this.isSavingQuote = true;
    //action audit
    this.auditService.addAction(new PosAuditEntry(this.posQuote.quoteId, PosAuditEntryType.SalesmanAddComment, 0, this.posQuote.comment.toString()));
    this.SaveAndLoadActionLog();
    this.posService.updateQuote(this.posQuote).subscribe(
      () => {
        this.posService.updateComment(this.posQuote).subscribe(
          () => {
            this.isSavingQuote = false;
            this.notification.showSuccess("Quote and comment saved!");

          },
          error => {
            this.notification.handleError(error);
            this.isSavingQuote = false;
          });
      },
      error => {
        this.notification.handleError(error);
        this.isSavingQuote = false;
      }
    );

  }

  searchedCustomerCell: string = "";
  searchedCustomerName: string = "";
  filterCustomer(filter: string) {
    if (!filter) {
      this.searchedCustomerName = "";
      this.searchedCustomerCell = "";
      return;
    }
    if (isNaN(+filter)){
      this.searchedCustomerName = filter;
      this.searchedCustomerCell = "";
    }
    else {
      this.searchedCustomerName = "";
      this.searchedCustomerCell = filter;
    }

  }

  //#endregion

  searchStock(data: PosQuoteLine) {
    this.isLoadingStockSearch = true;
    this.stockSearchResult = [];
    this.isSearchStockModalVisible = true;
    this.posService.searchStock(data.stockCode).subscribe(
      val => {
        this.stockSearchResult = val;
        this.isLoadingStockSearch = false;
        this.isSearchStockModalVisible = true;
      },
      error => {
        this.notification.handleError(error);
      }
    )
  }

  filterSearchStock(pos: number): StockSearchResult[] {
    return this.stockSearchResult.filter(x => x.locationId == pos);
  }

  selectSearchItem(item: StockSearchResult) {
    this.isSearchStockModalVisible = false;
  }

  isAddCustomStockModalVisible = false;
  searchStockInput() {
    this.posService.searchStock(this.searchString).subscribe(
      val => {
        val = val.sort((a, b) => a.locationId - b.locationId) //sort
        if (val.length === 0) 
        {
          this.notification.showWarning("No results found!");
          //pull stock code through
          this.code = this.searchString;
          this.isAddCustomStockModalVisible = true;
        }
        else {
          let newItem: PosQuoteLine = {
            difference: 0,
            gpPercent: 0,
            gpPercentLow: 0,
            gpPercentHigh: 0,
            gpRands: 0,
            gpRandsLow: 0,
            gpRandsHigh: 0,
            fiveLow: 0,
            fiveHigh: 0,
            //totalPriceIncl: Math.round(val[0].price * this.posQuote.vatRate),
            totalPriceIncl: val[0].stockType === 'TYRE' ? Math.round(val[0].price * this.posQuote.vatRate) : Math.round(val[0].price),
            cost: val[0].cost,
            description: val[0].description,
            locationId: val[0].locationId,
            msfid: val[0].msfid,
            price: Math.round(val[0].price),
            priceIncl: val[0].stockType === 'TYRE' ? Math.round(val[0].price * this.posQuote.vatRate) : Math.round(val[0].price),
            quoteLineId: 0,
            qty: 1,
            soh: val[0].soh,
            stockCode: val[0].stockCode,
            stockType: val[0].stockType,
            brand: val[0].brand,
            brandLogoUrl: val[0].brandLogoUrl,
            clientName: val[0].supplier,
            clientId: val[0].supplierId,
            recommended: false,
            origClientId: val[0].supplierId,
            origClientName: val[0].supplier,
            origCost: val[0].cost,
            customStockId: val[0].stockId
          };
          this.addItemToQuote(newItem);
          
          this.searchString = "";

        }
      }
    )

  }

  private isAllQuoteValuesValid(): boolean {
    let hasError = false;
    for (let item of this.posQuote.quoteLines) {
     if (item.price.toString().length === 0 || item.priceIncl.toString().length === 0) {
       this.notification.showWarning("<b>Invalid Price: </b>" + item.description);
       hasError = true;
     }
     if (item.qty.toString().length === 0 || item.qty <= 0) {
       this.notification.showWarning("<b>Invalid Quantity</b> " + item.description);
       hasError = true;
     }
   }

   return !hasError;
  }

  saveQuote() {
    //do validation checking of valid values
    if (!this.isAllQuoteValuesValid()) {
      return;
    }
    
    this.isSavingQuote = true;
    this.SaveAndLoadActionLog();
    this.quoteDirty = false;
    this.posService.updateQuote(this.posQuote).subscribe(
      val => {
        this.isSavingQuote = false;
        this.notification.showSuccess("Quote saved!");

      },
      error => {
        this.notification.handleError(error);
        this.isSavingQuote = false;
      }
    )
    return true;
  }

  completeSale() {
    //do validation checking of valid values
    if (!this.isAllQuoteValuesValid()) {
      return;
    }
    this.isSavingQuote = true;
    //action audit
    this.auditService.addAction(new PosAuditEntry(this.posQuote.quoteId, PosAuditEntryType.SaleCompleted, 0));
    this.auditService.saveActions();

    //added for fleet will use later
    this.posQuote.model = "";
    this.posQuote.reg = "";
    this.posQuote.make = "";
    this.posQuote.driver_cell = "";
    this.posQuote.driver_name = "";
    this.posQuote.odo = 0;
    ////////////////////////////////
    this.posService.updateQuote(this.posQuote).subscribe(
      val => {
        this.posService.addSale(this.posQuote).subscribe(
          val => {
            if (this.authService.user.featureLevel >= 3) {
              this.reportsService.openInvoice(val["saleId"]);
            }
            this.isSavingQuote = false;
            // this.modalService.create({
            //   nzTitle: 'Success',
            //   nzContent: 'Sale was successfully created. Please allow a few minutes for the sale to be inserted in your POS.',
            //   nzFooter: null
            // });
            //this.notification.ShowBlockingPopup("Sale was successfully created. Please allow a few minutes for the sale to be inserted in your POS.")
            if (this.authService.user.isPremium) {
              this.notification.showSuccessPinned("Sale was successfully created. Please allow a few minutes for the sale to be inserted in your POS.");
            }
            else {
              this.notification.showSuccessPinned("Sale was successfully created.");
            }
            this.quoteDirty = false;
            this.statusSaved = true;
            this.router.navigate(["sales"]);
            sessionStorage.clear();
            localStorage.removeItem('required');
            // this.notification.showSuccess("Sale successfully created");

          },
          error => {

            this.notification.handleError(error);
            this.isSavingQuote = false;
          }
        )


      },
      error => {
        this.notification.handleError(error);
        this.isSavingQuote = false;
      }
    )

  }

  tentativelyAccept(now: boolean = true) {
    this.navPathAway = "/leads"; //to navigate away after accepting
    if (now) {this.followUpDate = new Date();} //To make the follow up now
    this.updateQuoteStateWithAction(PosAuditEntryType.TentativelyAccepted, true);
  }

  viewQuote() {
    //do validation checking of valid values
    if (!this.isAllQuoteValuesValid()) {
      return;
    }
    //action audit
    this.auditService.addAction(new PosAuditEntry(this.posQuote.quoteId, PosAuditEntryType.SalesmanViewedQuote, 0));

    this.saveQuote()
    window.open(environment.b2c_url + `/quote/${this.posQuote.quoteRef}/${this.authService.user.user_id}`,"_blank")
  }

  ngOnDestroy(): void {
    this.paramsSub.unsubscribe();
  }


  navigateCustomers()
  {
    localStorage.removeItem("customerData");
    var Customer = { "customerId" : this.posQuote.custId }
    localStorage.setItem('customerData', JSON.stringify(Customer));
    this.router.navigate(['/customer']);
  }

  quoteDirty = false;
  statusSaved = false;
  isNavModalVisible = false;
  navPathAway: string = "";
  checkNavGuard(navPathAway: string): boolean {
    if (!this.quoteDirty && this.statusSaved) {
      return true;
    }
    if (!this.navPathAway) {
      this.showStatusModal(true)
      this.navPathAway = navPathAway;
      return false;
    } else if (this.navPathAway === navPathAway) {
      return true;
    }
    else {
      //What to do now? Navigating to a different path?
      return true;
    }

  }



  bookingSlots: BookingSlot[] = [];
  bookingSelectedDate: string = "";
  datePipe: DatePipe = new DatePipe(this.posService.locale);

  onBookingClick() {
    this.isBookingModalVisible = true;
    this.loadSlots(new Date());
    this.bookingSelectedDate = this.datePipe.transform(new Date(),'dd MMM')!;
  }

  private loadSlots(date: Date) {
    this.isLoadingBookingSlots = true;
    this.posService.getBookingSlotsPerDay(date).subscribe(
      val => {
        this.bookingSlots = val;
        this.isLoadingBookingSlots = false;
      },
      error => {
        this.notification.handleError(error);
      }
    )
  }

  onBookingDateSelected(val: Date) {
    

    this.bookingSelectedDate = this.datePipe.transform(val,'dd MMM')!;
    this.loadSlots(val);
  }

  onSlotSelected(i: BookingSlot){

    this.followUpDate = new Date(i.date + "T00:00");
    this.auditService.addAction(new PosAuditEntry(this.posQuote.quoteId, PosAuditEntryType.SalesmanBookedSlot, 0, i.date + " " + i.slotTime));

    this.posService.confirmBooking(this.posQuote.quoteRef, this.posQuote.quoteId, i.date, i.slotTime).subscribe(
      val => {
        this.tentativelyAccept(false);
      },
      error => {
        this.notification.handleError(error);
      }
    );
  }

  customStock_Addition = "";
  submitCustomStock()
  {
    if(this.desc == ""){this.notification.showWarning("Please Enter Product Description");}
    if(this.code == ""){this.notification.showWarning("Please Enter Stock Code");}
    if(this.price == 0){this.notification.showWarning("Please Enter Retail Price");}
    if(this.selectedType== ""){this.notification.showWarning("Please Select a stock type");}
    if(this.desc != "" && this.code !="" && this.price != 0 && this.selectedType != "")
    {
      let custom = new customStockItem()
      custom.description = this.desc.toUpperCase();
      custom.code = this.code.toUpperCase();
      custom.retail_price = this.price;
      custom.type = this.selectedType.toUpperCase();
      custom.stockTypeId = this.stockTypes.find(x=> x.stockDescription == this.selectedType)?.stockTypeId;
      this.isLoading = true;
      this.posService.addCustomStock(custom).subscribe(
        val => {
          this.isLoading = false;
          this.notification.showInfo(val.Message);
          this.isAddCustomStockModalVisible = false;
          //ADD TO QUOTE
          let res = new PosQuoteLine();
          res.brand = "Service";
          res.brandLogoUrl = "none.jpg";
          res.clientId = this.authService.user.client_id;
          res.clientName = this.authService.user.client_name;
          res.cost = 0;
          res.description = custom.description;
          res.difference = 0;
          res.locationId = 1;
          res.msfid = val.msfId;
          res.price = custom.retail_price;
          res.priceIncl = res.brand == "Service" ? custom.retail_price : Math.round(custom.retail_price * this.posQuote.vatRate); //TODO: Refactor when custom stock saves price ex vat
          res.qty = 1;
          res.quoteLineId = 0;
          res.soh = 20;
          res.stockCode = custom.code;
          res.stockType = "NONE STOCK";
          res.recommended = false;
          res.origClientId = this.authService.user.client_id;
          res.origClientName = this.authService.user.client_name;
          res.origCost = 0;
          res.customStockId = Number(val.customStockId);
          this.addItemToQuote(res);
        },
        error => {
          this.notification.handleError(error);
        }
      )
    }
  }
  assignType(type:string)
  {
    this.selectedType = type;
  }

  //WORK IN PROGRESS AND STOCK RESERVE
  assignWIP(reserveStock: boolean) {
    if (!this.isAllQuoteValuesValid()) {
      return;
    }

    this.isSavingQuote = true;
    //action audit
    this.auditService.addAction(new PosAuditEntry(this.posQuote.quoteId, PosAuditEntryType.WorkInProgress, 0));
    if (reserveStock) {
      this.auditService.addAction(new PosAuditEntry(this.posQuote.quoteId, PosAuditEntryType.StockReserved, 0));
    }
    this.auditService.saveActions();

    this.posService.updateQuote(this.posQuote).subscribe(
      val => {
        this.posService.assignWorkInProgress(this.posQuote.quoteId, reserveStock).subscribe(
          _ => {
            this.quoteDirty = false;
            this.statusSaved = true;
            this.reportsService.openJobCard(this.posQuote.quoteId); // Open Jobcard in new window
            this.router.navigate(['wip']);
          },
          error => {
            this.notification.handleError(error);
          }
        )

      },
      error => {
        this.notification.handleError(error);
        this.isSavingQuote = false;
      }
    )


    
  }

  printJobCard() {
    if (!this.isAllQuoteValuesValid()) {
      return;
    }
    this.posService.updateQuote(this.posQuote).subscribe(
      val => {
        this.reportsService.openJobCard(this.posQuote.quoteId);
      },
      err => {
        this.notification.handleError(err);
      }
      )
    
  }

  cancelJobCard() {
    this.showStatusModal(false);
  }


}
