import { Quote, QuoteLine } from "../classes/quote"

export class PosQuote {
  quoteId: number = 0
  quoteRef: string
  clientId: number = 0
  sellerName: string
  dateQuoted: Date
  custId: number = 0
  custName: string
  custCell: string
  custEmail: string
  custSurname?:string
  custVATNumber: string
  running_total: number = 0
  buyerId: number = 0
  vatRate: number = 0
  quoteLines: PosQuoteLine[]
  comment: string
  state: QuoteState = new QuoteState()
  ext_num: number = 0
  make: string
  model: string
  reg: string
  odo: number = 0
  driver_name:string
  driver_cell:string
  rim: number = 0
  width: number = 0
  profile: number = 0
  isContractPricing: boolean
  booking: PosQuoteBooking
  vehicleOdoId: number = 0
  odoReading: number = 0
  readingDate: Date
  readingAge: number = 0
  user:QuoteUser = new QuoteUser()
  manualInsert :boolean
  wbcActive: boolean
  custVehicles:  QuoteVehicles[]
  Vehicle_Customer_MappingId:number = 0
  custDebtorAcc= new CustomerDebtorAcc()
}

export class QuoteVehicles {
  Vehicle_Customer_MappingId:number = 0
  customerId:number = 0
  v_reg: string = ""
  vehicleMilage: string = ""
  vehicleMakeId: number = 0
  vehicleModelId: number = 0
  vehicleVariantId: number = 0 
  modelYear: number = 0
  quoteId: number = 0
  isVisable:boolean = false
}

export class QuoteUser{
  userCell: string
  userName: string
}

export class PosQuoteBooking {
dateBooked: Date
timeBooked: string
bookingId: number = 0
}

export class PosQuoteLine {
  difference: number = 0
  gpPercent: number = 0
  gpPercentLow: number = 0
  gpPercentHigh: number = 0
  gpRands: number = 0
  gpRandsLow: number = 0
  gpRandsHigh: number = 0
  fiveLow: number = 0
  fiveHigh: number = 0
  totalPriceIncl: number = 0
  cost: number = 0
  description: string
  locationId: number = 0
  msfid: number = 0
  price: number = 0
  priceIncl: number = 0
  quoteLineId: number = 0
  qty: number = 0
  soh: number = 0
  stockCode: string
  stockType: string
  brand: string
  brandLogoUrl: string
  clientName: string
  clientId: number = 0
  recommended: boolean
  origClientName?: string
  origClientId?: number = 0
  origCost?: number = 0
  customStockId: number = 0
  rank?: number = 0
}

export class PosCustomer {
  customerId: number = 0
  name: string
  email: string
  cell: string
  customer: string
  // vreg: string # temporarily remove for performance reasons 29 June
  // vmapid: number # temporarily remove for performance reasons 29 June
}

export class PosSearchCustomer {
  customerId: number = 0
  customer: string
}

export class StoredCustomer {
  key: number
  name: string
}

export class StockSearchResult {
  stockId: number
  stockCode: string
  code:string;
  brand: string
  brandLogoUrl: string
  description: string
  soh: number = 0
  sla: string
  supplier: string
  supplierId: number = 0
  price: number = 0
  locationId: number = 0
  clientId: number = 0
  msfid: number = 0
  cost: number = 0
  stockType: string
  type:string
  mapped: number = 0
  totalsales: number = 0
  active: boolean
  selected: boolean = false;
  isBuyOut: boolean = false;
  linkedToTyreQty: boolean = false;
  width: number;
  profile: number;
  rim: number;
  market: string;
  stockUsedId: number;
  usedStockClassification: string;

  //the following seems to not be used any more. If so, please remove
  public stockSearchToQuoteLine(item: StockSearchResult, quote: Quote): QuoteLine{
    let res = new QuoteLine();
    res.brand = item.brand;
    res.brandLogoUrl = item.brandLogoUrl;
    res.clientId = item.supplierId;
    res.clientName = item.supplier;
    res.cost = item.cost;
    res.description = item.description;
    res.difference = 0;
    res.locationId = item.locationId;
    res.msfid = item.msfid;
    res.price = item.price;
    res.isTyre = res.brand === "Service" ? false : true;
    res.priceIncl = res.isTyre ? Math.round(item.price * quote.vatRate) : item.price;    
    res.qty = res.isTyre ? 4 : 1;
    res.totalPriceIncl = res.priceIncl * res.qty;
    res.quoteLineId = 0;
    res.soh = item.soh;
    res.stockCode = item.stockCode;
    res.stockType = res.isTyre ? "TYRE" : "SERVICE";
    
    res.recommended = false;
    res.origClientId = item.supplierId;
    res.origClientName = item.supplier;
    res.origCost = item.cost;
    res.rank = res.isTyre ? 95 : 99;
    res.customStockId = Number(item.stockId);
    res.width = item.width;
    res.profile = item.profile;
    res.rim = item.rim;

    return res
  }
}

export class VehicleReg {
mappingId: number = 0
vReg: string
vehicleOdoId: number = 0
odoReading: number = 0
readingDate: Date
readingAge: number = 0
}

export class CustomerDebtorAcc {
customerId: number = 0
debtorAcc: string
}

export class CustomerVehicle {
  vehReg: string
  vehWidth: number = 0
  vehProfile: number = 0
  vehRim: number = 0
  tyresize:string = ""
  vehMilage: string = ""
  vehMakeId: number = 0
  vehModelId: number = 0
  vehVariantId: number = 0
  modelYear: number = 0
  customerId: number = 0
  quoteId: number = 0
  vehicleMapId: number = 0
}

export class VehicleMake {  
  vehicleMakeId: number = 0
  vehicleMake: string = ""
}

export class VehicleModel {  
  vehicleModelId: number = 0
  vehicleMakeId: number = 0
  vehicleModel: string = ""
}

export class VehicleVariant {  
  vehicleVariantId: number = 0
  vehicleVariant: string = ""
}

export class NewPosCustomer {
customerId:number = 0
name: string
email: string
cell: string
debtorAcc: string
VATNumber: string
}

export class QuoteState {
stateId: number = 0
description: string
dt_act: string
quoteStatusId: number = 0
dt_processed: string
}

export class QuoteStatus {
quoteStatusId: number = 0
description: string
}

export class fleetSubmission {
sf_ref: string
sellerId: number = 0
buyerId: number = 0
cell: string
email: string
comment: string
customerId: number = 0
customerName: string
customerCode: number = 0
delay_send: number = 0
state: string
line_items: PosQuoteLine[]
odo: number = 0
make: string
model:string
reg:string
driver_name:string
driver_cell:string
ext_ref:string
}

export enum PosAuditEntryType {
None = 0,
CreatedWalkIn = 1,
CreatedPhoneCall = 2,
CreatedEmail = 3,
CreatedExternalChannel = 4,
OpenQuote = 5,
EmailedQuote,
SMSQuote,
PrintQuote,
ViewedCustomer,
ChangePrice,
ChangeQty,
ChangeGpPercent,
AddItem,
DeleteItem,
SelectItem,
ClearQuote,
SalesmanViewedQuote,
LookingCloserFC,
PriceChecking,
CustomerUnreachable,
Looking2ndHand,
LookingSpecificTyre,
SalesmanAddComment = 23,
SalesmanClassifyCustomer,
CustomerRatedProcess,
SaleCompleted,
FollowUpTimeExpired,
FollowUpLater,
TentativelyAccepted,
NotReadyToBuy,
IncorrectCustomerDetails,
BoughtFromCompetitor,
ClosedOther,
SalesmanBookedSlot = 44,
SalesmanSelectCustomer = 46,
SalesmanAddCustomer = 47,
AddedVehicleReg = 48,
LinkedVehicle,
UnlinkedVehicle,
AddedODOReading,
WorkInProgress = 53,
StockReserved = 54,
SalesmanUpdatedCustomerDetails = 55,
SendWhatsApp = 56,
AdminChangeQuoteSaleman = 57, 
CommitedToBuy,
ReactivateQuote,
DisableGPProtectionPassword
}

export class PosAuditEntry {
quoteId: number = 0;
actionId: PosAuditEntryType;
foreignKeyId: number = 0;
valuePrev: string;
valueNew: string;
comment: string;
dateActioned: Date;
isWriting: boolean;
writingTime: Date | null;

constructor(quoteId: number = 0, action: PosAuditEntryType, foreignId: number = 0, comment: string = "", previousValue: string = "", newValue: string = "") {
  this.quoteId = quoteId;
  this.actionId = action;
  this.valuePrev = previousValue;
  this.valueNew = newValue;
  this.comment = comment;
  this.foreignKeyId = foreignId;
  this.dateActioned = new Date();
  this.isWriting = false;
  this.writingTime = null;
}
}

export class PosActionLogEntry {
name: string;
dateActioned: Date;
description: string;
valuePrev: string;
valueNew: string;
comment: string;
}

export class BookingSlot {

constructor(public date: Date,
            public dow: number = 0,
            public slotTime: string,
            public openSlots: number = 0,
            public bookings: number = 0,
            public totalSlots: number = 0) {

}


}

export class customStockItem{
code: string
description: string
retail_price: number = 0
type: string
stockTypeId?:number = 0
isBuyOut: boolean = false
}

export class stockType {
stockTypeId: number = 0;
stockDescription: string;
showTab!:boolean;
}

export class PackageType {
  packageTypeId: number = 0;
  packageDescription: string;
  packageAcronym: string;
  clientId: number;
  showPackageTab!:boolean;
}


export class wbcQuote {
vehicleCategory: string = ''
vehicleMilage:number = 0
vehicleCondition: string= ''
vehicleMake:string = ''
vehicleYear:number = 0
vehicleModel:string = ''
vehicleVariant: string = ''
vehicleVariantId:number = 0
vehicleReg: string = ''
reference:string=''
name:string = ''
surname:string = ''
email: string = ''
mobilePhone: string = ''  
altPhone: string = ''
suburb: string = ''
town: string = ''
province : string = ''
customerId: number = 0
}




