<div class="row mt-4 mb-4">
    <div class="col-12 text-center">
        <nz-spin [nzSpinning]="isLoadingStockSearch">
            <button nz-button nz-button-images nzType="default" (click)="showExtra_Tyre()"><img src="/assets/Images/pos/tyres.png"><br />Tyres</button>
            <button nz-button nz-button-images nzType="default" (click)="showExtra_Other('SERVICE')"><img src="/assets/Images/pos/service.png"><br />Service</button>
            <button nz-button nz-button-images nzType="default" (click)="showExtra_Other('BATTERY')"><img src="/assets/Images/pos/battery.png"><br />Battery</button>
            <button nz-button nz-button-images nzType="default" (click)="showExtra_Other('BRAKE')"><img src="/assets/Images/pos/brakes.png"><br />Brakes</button>
            <button nz-button nz-button-images nzType="default" (click)="showExtra_Other('SHOCK')"><img src="/assets/Images/pos/shocks.png"><br />Shocks</button>
            <button nz-button nz-button-images nzType="default" (click)="showExtra_Other('OTHER')"><img src="/assets/Images/pos/other.png"><br />Other</button>
        </nz-spin>
    </div>
    <div class="col-12 text-center mt-3">
        <nz-button-group>
            <button nz-button nz-button-services nzType="default" (click)="addByMsfids([23338,23340,23342,25466])">4x4/SUV Services</button>
            <button nz-button nz-button-services nzType="default" (click)="addByMsfids([23337,23340,23342,25466])">Premium Services</button>
            <button nz-button nz-button-services nzType="default" (click)="addByMsfids([23337,23340,23342,25466])">Value Services</button>
            <button nz-button nz-button-services nzType="default" (click)="addByMsfids([23336,23340,23342,25466])">Budget Services</button>
        </nz-button-group>
    </div>
</div>

<nz-modal [(nzVisible)]="isSearchStockModalVisible" nzTitle="Stock Availability" (nzOnCancel)="isSearchStockModalVisible = false" [nzFooter]="null" [nzWidth]="'900px'">
    <div *nzModalContent>
        <div class="form">
            <input nz-input type="text" nz-input placeholder="Search for tyre..." (keyup.enter)="showExtra_Tyre()" [(ngModel)]="searchString">
            <span class="left-pan">
                <i nz-icon nzType="search" nzTheme="outline"></i>
            </span>
        </div>
        <ng-template #emptyTemplate></ng-template>
        <nz-tabset nzCentered>
            <nz-tab nzTitle="Local">
                <nz-table #searchTable1 [nzData]="filterSearchStock(1)" [nzLoading]="isLoadingStockSearch" [nzShowPagination]="false" [nzBordered]="false" [nzOuterBordered]="false" nzSize="small" [nzNoResult]="stockSearchNoResults" [nzPageSize]="100">
                    <thead>
                        <tr>
                            <th>Brand</th>
                            <th>Description</th>
                            <th>SOH</th>
                            <th>SLA</th>
                            <th>Supplier</th>
                            <th>(Ex) Price</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of searchTable1.data">
                            <td><img src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{item.brandLogoUrl}}" class="brandImage" /></td>
                            <td>{{item.description}}</td>
                            <td>{{item.soh}}</td>
                            <td>{{item.sla}}</td>
                            <td>{{item.supplier}}</td>
                            <td>{{item.price}}</td>
                            <td>
                                <button nz-button nzType="default" (click)="selectSearchItem(item)">
                                    <i nz-icon nzType="plus" nzTheme="outline" nz-tooltip nzTooltipTitle="Select Item"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </nz-table>
            </nz-tab>
            <nz-tab nzTitle="Group">
                <nz-table #searchTable2 [nzData]="filterSearchStock(2)" [nzLoading]="isLoadingStockSearch" [nzShowPagination]="false" [nzBordered]="false" [nzOuterBordered]="false" nzSize="small" [nzNoResult]="stockSearchNoResults" [nzPageSize]="100">
                    <thead>
                        <tr>
                            <th>Brand</th>
                            <th>Description</th>
                            <th>SOH</th>
                            <th>SLA</th>
                            <th>Supplier</th>
                            <th>(Ex) Price</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of searchTable2.data">
                            <td><img src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{item.brandLogoUrl}}" class="brandImage" /></td>
                            <td>{{item.description}}</td>
                            <td>{{item.soh}}</td>
                            <td>{{item.sla}}</td>
                            <td>{{item.supplier}}</td>
                            <td>{{item.price}}</td>
                            <td>
                                <button nz-button nzType="default" (click)="selectSearchItem(item)">
                                    <i nz-icon nzType="plus" nzTheme="outline" nz-tooltip nzTooltipTitle="Select Item"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </nz-table>
            </nz-tab>
            <nz-tab nzTitle="Supplier">
                <nz-table #searchTable3 [nzData]="filterSearchStock(3)" [nzLoading]="isLoadingStockSearch" [nzShowPagination]="false" [nzBordered]="false" [nzOuterBordered]="false" nzSize="small" [nzNoResult]="stockSearchNoResults" [nzPageSize]="100">
                    <thead>
                        <tr>
                            <th>Brand</th>
                            <th>Description</th>
                            <th>SOH</th>
                            <th>SLA</th>
                            <th>Supplier</th>
                            <th>(Ex) Price</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of searchTable3.data">
                            <td><img src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{item.brandLogoUrl}}" class="brandImage" /></td>
                            <td>{{item.description}}</td>
                            <td>{{item.soh}}</td>
                            <td>{{item.sla}}</td>
                            <td>{{item.supplier}}</td>
                            <td>{{item.price}}</td>
                            <td>
                                <button nz-button nzType="default" (click)="selectSearchItem(item)">
                                    <i nz-icon nzType="plus" nzTheme="outline" nz-tooltip nzTooltipTitle="Select Item"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </nz-table>
            </nz-tab>
            <nz-tab nzTitle="NRS">
                <nz-table #searchTable4 [nzData]="filterSearchStock(4)" [nzLoading]="isLoadingStockSearch" [nzShowPagination]="false" [nzBordered]="false" [nzOuterBordered]="false" nzSize="small" [nzNoResult]="stockSearchNoResults" [nzPageSize]="100">
                    <thead>
                        <tr>
                            <th>Brand</th>
                            <th>Description</th>
                            <th>SOH</th>
                            <th>SLA</th>
                            <th>Supplier</th>
                            <th>(Ex) Price</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of searchTable4.data">
                            <td><img src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{item.brandLogoUrl}}" class="brandImage" /></td>
                            <td>{{item.description}}</td>
                            <td>{{item.soh}}</td>
                            <td>{{item.sla}}</td>
                            <td>{{item.supplier}}</td>
                            <td>{{item.price}}</td>
                            <td>
                                <button nz-button nzType="default" (click)="selectSearchItem(item)">
                                    <i nz-icon nzType="plus" nzTheme="outline" nz-tooltip nzTooltipTitle="Select Item"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </nz-table>
            </nz-tab>
        </nz-tabset>
        <ng-template #stockSearchNoResults>


            <div style="text-align: center;">
                <i nz-icon nzType="warning" nzTheme="outline" class="text-warning-style " style="font-size: 4rem;"></i>
                <br />
                <b>NO STOCK FOUND</b>
            </div>


        </ng-template>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="isEditStockModalVisible" nzTitle="{{this.editcustom.description}}" (nzOnCancel)="isEditStockModalVisible = false" [nzFooter]="null" [nzWidth]="'900px'">
    <div *nzModalContent>
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <label class="float-left">Stock Code</label>
                    <div class="input-group maindiv">
                        <span class="input-group-prepend"><div class="input-group-text bg-transparent">
                    <i nz-icon nzType="barcode" nzTheme="outline"></i>
                
                </div>
                </span>
                        <input nz-input [(ngModel)]="editcustom.code" value="{{ this.editcustom.code}}">
                    </div>
                </div>
                <div class="col-lg-6">
                    <label class="float-left">Price <small class="text-style">*(Vat incl)</small></label>
                    <div class="input-group maindiv">
                        <span class="input-group-prepend"><div class="input-group-text bg-transparent">
                            <span nz-icon nzType="sf:moneyBillSVG" nzTheme="outline" ></span>

                    </div>
                    </span>
                    <input nz-input [(ngModel)]="editcustom.price" value="{{ this.editcustom.price}}">
                </div>
            </div>
        </div>
        <p></p>
        <div class="row text-center ">
            <div class="col-lg-4 offset-4">
                <button nz-button nzType="default" (click)="updatecustom()" data-dismiss="modal">Save</button>
            </div>
        </div>
    </div>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="isSearchOtherModalVisible" nzTitle="Stock Availability" (nzOnCancel)="isSearchOtherModalVisible = false" [nzFooter]="null" [nzWidth]="'900px'">
    <div *nzModalContent>
        <button nz-button nzType="default" (click)="openAdd()"> Add New {{addType | titlecase}}</button>
        <br>
        <br>
        <nz-table #searchTable1 [nzData]="stockSearchResult" [nzLoading]="isLoadingStockSearch" [nzShowPagination]="false" [nzBordered]="false" [nzOuterBordered]="false" nzSize="small" [nzNoResult]="stockSearchNoResults" [nzPageSize]="100">
            <thead>
                <tr>
                    <th>Brand</th>
                    <th>Description</th>
                    <th>SOH</th>
                    <!-- <th>SLA</th>
                    <th>Supplier</th> -->
                    <th>(Ex) Price</th>
                    <th>Modify</th>
                    <th>Add Item</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of searchTable1.data">
                    <td><img src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{item.brandLogoUrl}}" class="brandImage" /></td>
                    <td [ngStyle]="{'color':item.mapped == 1 ? 'white' : 'red' }">{{item.description}}</td>
                    <td [ngStyle]="{'color':item.mapped == 1 ? 'white' : 'red' }">{{item.soh}}</td>
                    <!-- <td [ngStyle]="{'color':item.mapped == 1 ? 'white' : 'red' }">{{item.sla}}</td>
                    <td [ngStyle]="{'color':item.mapped == 1 ? 'white' : 'red' }">{{item.supplier}}</td> -->
                    <td [ngStyle]="{'color':item.mapped == 1 ? 'white' : 'red' }">{{item.price}}</td>
                    <td>
                        <button nz-button nzType="default" (click)="loadstockinfo(item)">
                        <i nz-icon nzType="plus" nzTheme="outline"  nz-tooltip nzTooltipTitle="Modify price or stock code"></i>
                        </button>
                    </td>
                    <td>
                        <button nz-button nzType="default" *ngIf="item.active" (click)="selectSearchItem(item)">
                           <i nz-icon nzType="plus" nzTheme="outline"  nz-tooltip nzTooltipTitle="Select Item"></i>
                        </button>

                        <button nz-button nzType="default" *ngIf="!item.active" (click)="loadstockinfo(item)">
                           <i nz-icon nzType="plus" nzTheme="outline"  nz-tooltip nzTooltipTitle="Modify price to activate custom stock item"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </nz-table>

        <ng-template #stockSearchNoResults>
            <div style="text-align: center;">
                <i nz-icon nzType="warning" nzTheme="outline" class="text-warning-style " style="font-size: 4rem;"></i>
                <br />
                <b>NO STOCK FOUND</b>
            </div>
        </ng-template>
    </div>

</nz-modal>

<nz-modal [(nzVisible)]="this.isAddCustomStockModalVisible" nzTitle="{{title | titlecase}}" (nzOnCancel)="this.isAddCustomStockModalVisible = false" [nzFooter]="null" nzWidth="700px">

    <div *nzModalContent>
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-6 offset-3">
                    <form nz-form>
                        <nz-form-item>
                            <label>Description</label>
                            <nz-input-group nzPrefixIcon="file-text">
                                <input [(ngModel)]="desc" name="desc" value="{{desc}}" nz-input placeholder="Description" id="custom_description" style="width: 50%;" autocomplete="off" />
                            </nz-input-group>
                        </nz-form-item>
                        <nz-form-item>
                            <label>Stock Code</label>
                            <nz-input-group nzPrefixIcon="barcode">
                                <input [(ngModel)]="code" name="code" value="{{code}}" nz-input placeholder="Stock Code" id="custom_code" style="width: 50%;" autocomplete="off" />
                            </nz-input-group>
                        </nz-form-item>
                        <nz-form-item>
                            <label>Price (incl VAT)</label>
                            <nz-input-group nzPrefixIcon="money-collect">
                                <input [(ngModel)]="price" name="value" value="{{price}}" nz-input placeholder="Price" type="number" min="0" id="custom_price" style="width: 50%;" autocomplete="off" />
                            </nz-input-group>
                        </nz-form-item>
                    </form>
                    <button nz-button nzType="default" (click)="submitCustomStock()"><i nz-icon nzType="save" nzTheme="fill" 
                    style="vertical-align: top; margin-right: 4px; font-size: 1.5rem;"></i>Save</button>
                </div>
            </div>
        </div>
    </div>
</nz-modal>