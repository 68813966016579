
<nz-tabset nzCentered *ngIf="quote && this.quote.lines" [nzTabBarExtraContent]="extraTemplate">
    <nz-tab *ngFor="let optionId of filteredArrayOfOptions(); let optionTabIndex = index" [nzTitle]="tabName(optionId!, optionTabIndex)" (nzSelect)="selectedOptionTabChanged(optionId!)">
        <nz-table #quoteLinesTable [nzData]="filteredItemsForOption(optionId!)" [nzLoading]="isLoading" [nzBordered]="false" [nzOuterBordered]="false" nzSize="small" [nzFrontPagination]="false" style="overflow-x: auto;">
            <thead>
                <tr>
                    <th style="min-width: 200px;">Description</th>
                    <th class="mobile">Brand</th>
                    <th>Code</th>
                    <th>SOH</th>
                    <th (click)="this.showCost = !this.showCost" style="cursor: pointer;" nz-tooltip="Hide/Show Cost"><i nz-icon nzType="eye" nzTheme="outline" style="vertical-align: baseline;"></i></th>
                    <th (click)="isQtyLocked = !isQtyLocked" style="cursor: pointer;">
                        <span [ngClass]="isQtyLocked ? 'color-primary' : ''">
                            <i nz-icon nzType="lock" nzTheme="fill" *ngIf="isQtyLocked" style="vertical-align: middle;"></i>
                            <i nz-icon nzType="unlock" nzTheme="fill" *ngIf="!isQtyLocked" style="vertical-align: middle;"></i> Qty
                        </span>
                    </th>
                    <th style="min-width: 120px;">Price (incl VAT)</th>
                    <th style="min-width: 120px;">Total Price</th>
                    <th>
                        <div style="text-align: right; padding-right:15px; min-width: 80px;">
                            <span nz-icon nzType="warning" nzTheme="outline" class="color-warning" *ngIf="quote.isDirty && !quote.isSaving" nz-tooltip="Needs to be saved"></span>
                            <span nz-icon [nzType]="'loading'" class="color-primary" *ngIf="quote.isDirty && quote.isSaving" nz-tooltip="Saving..."></span>
                            <span nz-icon nzType="check-circle" nzTheme="outline" class="color-success" *ngIf="!quote.isDirty" nz-tooltip="Quote saved"></span>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody class="table-body">
                <tr *ngFor="let data of quoteLinesTable.data" [ngClass]="{'errorRowBackground': lineHasError(data)}">
                    <td>
                        <span nz-icon [nzType]="data.isUnfolded === true ? 'sf:unfoldless' : 'sf:unfoldmore'" style="cursor: pointer; margin: 2px;" (click)="data.isUnfolded = !data.isUnfolded" *ngIf="data.isBuyOut === true"></span>

                        <nz-badge [nzCount]="data.recommended ? recommendedTemplate : 0">
                            <!-- <button nz-button nzType="default" (click)="searchStock(data)" class="btn"> -->
                            <!-- <i nz-icon nzType="warning" nzTheme="fill" style="color: orange; font-size: 1rem; vertical-align: middle; margin-bottom: 3px;" *ngIf="getQuoteLineWarningTextSeller(data) || getQuoteLineWarningTextCost(data)" nz-tooltip [nzTooltipTitle]="costPriceWarningTemplate"></i> -->
                            {{ data.description }}
                            <!-- </button> -->
                        </nz-badge>
                        <ng-template #recommendedTemplate>
                            <i nz-icon nzType="check-circle" class=" color-primary" style="margin-left:10px" nz-tooltip="Recommended Option"></i>
                        </ng-template>
                        <!-- <ng-template #costPriceWarningTemplate>
                            {{getQuoteLineWarningTextSeller(data)}} <br *ngIf="getQuoteLineWarningTextSeller(data)" /> {{getQuoteLineWarningTextCost(data)}}
                        </ng-template> -->
                        <small class="color-primary float-right" *ngIf="optionTabIndex === 0 && data.optionId !== null">Option {{data.optionId}}</small>
                        <div *ngIf="data.isUnfolded && data.isBuyOut" style="padding-top: 5px; padding-left: 25px;">
                            <input nz-input placeholder="Description" [(ngModel)]="data.altStockDescription" (ngModelChange)="quote.markDirty()" />

                        </div>
                    </td>
                    <td class="mobile">
                        <span *ngIf="data.isTyre">
                            <div *ngIf="!data.brandLogoUrl; else brandLogoImage">{{ data.brand }}</div>
                            <ng-template #brandLogoImage>
                                <img src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{data.brandLogoUrl}}" class="brandImage" alt="{{data.brand}}" nz-tooltip nzTooltipTitle="{{data.brand}}" nzTooltipPlacement="right" />
                            </ng-template>
                        </span>
                    </td>
                    <td>
                        {{ data.stockCode }}
                        <!-- Buyout Stock -->
                        <div *ngIf="data.isUnfolded && data.isBuyOut" style="padding-top: 5px;">
                            <input nz-input placeholder="Stock Code" [(ngModel)]="data.altStockCode" style="max-width: 100px;" (ngModelChange)="quote.markDirty()" />
                        </div>

                    </td>
                    <td>
                        {{ data.soh }}
                        <a (click)="onClickLineWithError(data)"><span nz-icon nzType="warning" nzTheme="outline" *ngIf="lineHasQtyCostError(data)" style="float:right" class="color-warning"></span></a>
                        <!-- Buyout Stock -->
                        <div *ngIf="data.isUnfolded && data.isBuyOut" style="height: 30px;"></div>
                    </td>
                    <td>
                        <div *ngIf="showCost && data.cost !== 0 ">
                            <i nz-icon nzType="warning" nzTheme="fill" style="font-size: 1rem; vertical-align: middle; margin-bottom: 3px;" 
                            *ngIf="data.cost !== data.origCost" class="text-warning-style"
                            nz-tooltip [nzTooltipTitle]="'Cost changed from R' + data.origCost?.toString() + ' to R' + data.cost.toString() "></i> 
                            {{ data.cost }}
                        </div>
                        <!-- Buyout Stock -->
                        <div *ngIf="data.isUnfolded && data.isBuyOut" style="height: 30px;"></div>
                    </td>
                    <td>

                        <nz-input-number [ngModel]="data.qty" [nzMin]="1" [nzMax]="500" [nzStep]="1" style="width: 55px;" (ngModelChange)="updateLineItem(data,'QTY',$event)" [disabled]="this.quote.isCompleted"
                            [nzStatus]="data.qty.toString() === '' ? 'error' : ''">
                        </nz-input-number>
                        <!-- Buyout Stock -->

                        
                    
                        <div *ngIf="data.isUnfolded && data.isBuyOut" style="height: 30px;"></div>
                    </td>
                    <td>
                        <ng-template #toolTipTemplate>
                            <table>
                                <tr>
                                    <td>
                                        <small>RETAIL<br /></small> R {{data.retail }}
                                        <small style="color: var(--textcolor);">less</small>
                                    </td>
                                    <td>
                                        <nz-input-number [disabled]="this.quote.isCompleted" style="width: 75px !important;" [nzMin]="1" [nzMax]="99" [(ngModel)]="data.retailValuePercent" (ngModelChange)="CalculateLessRetail(data,$event)" [nzFormatter]="percentFormatter">
                                        </nz-input-number>
                                    </td>
                                    <td>
                                        <button nz-button nzType="default" [disabled]="this.quote.isCompleted">
                                        <span> R {{ (data.retailValueLess! * quote.vatRate) | number: '1.0-0' }}</span>
                                        </button>
                                    </td>
                                </tr>
                                <tr style="border-top: lightgray 1px solid;">
                                    <td>
                                        R {{data.gpRandsHigh}}
                                    </td>
                                    <td>
                                        <div style="padding-left: 10px;">{{data.gpPercentHigh}}%</div>
                                    </td>
                                    <td>
                                        <button [disabled]="this.quote.isCompleted" nz-button nzType="primary" (click)="updateLineItemGP(data, data.gpRandsHigh,'+3')" class="btn">R {{
                                            data.fiveHigh | number: '1.0-0'}}</button>
                                    </td>
                                </tr>
                                <tr style="border-top: lightgray 1px solid;">
                                    <td>
                                        R {{data.gpRands}}
                                    </td>
                                    <td>
                                        <nz-input-number [disabled]="this.quote.isCompleted" [ngModel]="data.gpPercent" [nzMin]="1" [nzStep]="1" style="width: 75px;" (ngModelChange)="updateGpPercent(data, $event)" [nzFormatter]="percentFormatter"></nz-input-number>
                                    </td>
                                    <td>
                                        <button [disabled]="this.quote.isCompleted" nz-button nzType="primary" disabled class="btn">R {{ data.priceIncl | number: '1.0-0' }}</button>
                                    </td>
                                </tr>
                                <tr style="border-top: lightgray 1px solid;">
                                    <td>
                                        R {{data.gpRandsLow}}
                                    </td>
                                    <td>
                                        <div style="padding-left: 10px;">{{data.gpPercentLow}}%</div>
                                    </td>
                                    <td>
                                        <button [disabled]="this.quote.isCompleted" nz-button nzType="primary" (click)="updateLineItemGP(data, data.gpRandsLow,'-3')" class="btn">R {{
                                            data.fiveLow  | number: '1.0-0'}}</button>
                                    </td>
                                </tr>
                            </table>

                        </ng-template>
                        <div nz-popover nzPopoverPlacement="leftTop" [nzPopoverContent]="toolTipTemplate" *ngIf="(data.stockType && data.isTyre); else nonTyrePrice">
                            <nz-input-number-group [nzPrefix]="'R'" [nzAddOnAfter]="inclExclPrice" [nzStatus]="data.priceIncl.toString() === '' ? 'error' : ''">
                                <nz-input-number [ngModel]="data.priceIncl" [nzStep]="1" class="text-style" (ngModelChange)="updateLineItem(data,'PRICE',$event)" [disabled]="this.quote.isCompleted">
                                </nz-input-number>
                            </nz-input-number-group>
                            <ng-template #inclExclPrice>
                                <small nz-tooltip="EX VAT: R{{data.price}}">INCL</small>
                            </ng-template>
                        </div>
                        <ng-template #nonTyrePrice>
                            <nz-input-number-group [nzPrefix]="'R'" [nzAddOnAfter]="inclExclPrice" [nzStatus]="data.priceIncl.toString() === '' ? 'error' : ''">
                                <nz-input-number [ngModel]="data.priceIncl" [nzStep]="1" (ngModelChange)="updateLineItem(data,'PRICE',$event)" [disabled]="this.quote.isCompleted">
                                </nz-input-number>
                            </nz-input-number-group>
                            <ng-template #inclExclPrice>
                                <small>INCL</small>
                            </ng-template>
                        </ng-template>
                        <div *ngIf="data.isUnfolded && data.isBuyOut" style="height: 30px;"></div>
                    </td>
                    <ng-template #profitTooltip>
                        <span class="color-primary"><b>OPTION TOTAL</b></span><br /> <b>R {{calcOptionPriceIncl(data).toFixed(2)}}</b><br /> GP: R{{calcOptionGPExcl(data).toFixed(2)}} <small class="color-primary">ex vat</small>

                    </ng-template>
                    <td>
                        <div nz-popover nzPopoverPlacement="rightTop" [nzPopoverContent]="profitTooltip" *ngIf="(data.isTyre); else nonStockTotal">
                            R <b class="text-style">{{ data.totalPriceIncl }} </b>

                            <sup [ngClass]="lineHasGpProtectionError(data) ? 'color-error' : 'color-primary'">
                                {{ data.gpPercent }}%
                                <span nz-icon nzType="warning" nzTheme="outline" *ngIf="lineHasGpProtectionError(data)" style="float:right" class="color-warning"  nz-tooltip [nzTooltipTitle]="GPWarning"></span>
                                <ng-template #GPWarning>
                                    <span>Current GP is less than minimum allowed GP of {{quote.gpProtectionPercent}}%</span>
                                </ng-template>
                            </sup><br />
                            <sup>{{ data.clientName }}</sup>

                        </div>
                        <ng-template #nonStockTotal>
                            R <b class="text-style">{{ data.totalPriceIncl }} </b>

                            <!-- <sup class="color-primary">{{ data.gpPercent }}%</sup><br />
                                <sup>{{ data.clientName }}</sup> -->

                        </ng-template>
                        <!-- Buyout Stock -->
                        <div *ngIf="data.isUnfolded && data.isBuyOut" style="height: 30px;"></div>
                    </td>
                    <td>
                        <span *ngIf="this.quote.isCompleted === false">
                            <button nz-button nzType="default" nzDanger (click)="removeLineItem(data, optionTabIndex)" nzShape="circle" nz-tooltip nzTooltipTitle="Remove Item">
                                <span nz-icon nzType="minus" nzTheme="outline"></span>
                            </button>
                            <span *ngIf="data.isTyre">                                   
                                <button nz-button nzType="primary" (click)="selectLineItem(data)" nzShape="circle" nz-tooltip nzTooltipTitle="Select Item" style="margin-left: 15px;" *ngIf="quote.gpProtectionPercent === null || data.gpPercent >= quote.gpProtectionPercent!">
                                    <span nz-icon nzType="check" nzTheme="outline"></span>
                                </button>
                                <button nz-button nzType="primary" nzShape="circle" style="margin-left: 15px;" nz-tooltip nzTooltipTitle="Input password to deactivate GP protection" *ngIf="quote.gpProtectionPercent !== null && data.gpPercent < quote.gpProtectionPercent!" (click)="passwordModalVisible = true;">
                                    <span nz-icon nzType="lock" nzTheme="outline"></span>
                                </button> 
                            </span>
                        </span>
                    </td>
                    <td ></td>
                </tr>
                <tr>
                    <td>
                        <nz-form-control [nzSpan]="24" [nzValidateStatus]="'validating'" [nzHasFeedback]="isSearchingByStockCode" style="max-width: 12rem;">
                            <input nz-input placeholder="Stock Code" [(ngModel)]="searchString" (keyup.enter)="searchStockInput()" style="text-transform: uppercase;" [disabled]="isSearchingByStockCode" [disabled]="this.quote.isCompleted" />
                        </nz-form-control>
                    </td>
                    <td colspan="7">
                        <div style="text-align: right; padding-top: 5px;">
                            <h4>TOTAL R <span class="text-info-style">{{totalPriceInc(optionId)}}</span></h4>

                        </div>
                    </td>
                    <td class="mobile" colspan="1">
                        

                    </td>
                </tr>
            </tbody>
        </nz-table>
    </nz-tab>
    <ng-template #extraTemplate>
        <div style="padding-right: 25px; padding-top: 10px;">
            <button nz-button nzSize="small" nzType="primary" [nzDanger]="!this.quote.shouldApplyDiscounts" style="margin-right: 10px;" *ngIf="this.quote.discountRules && this.quote.discountRules.length > 0" (click)="onClickShoudlApplyDiscountsAutomatically()">
                <small *ngIf="this.quote.shouldApplyDiscounts">Auto Discount Active</small>
                <small *ngIf="!this.quote.shouldApplyDiscounts">Auto Discount Disabled</small>
            </button>
            <app-video-widget [videoId]="2"></app-video-widget>
        </div>
        
    </ng-template>
    
</nz-tabset>

<nz-modal [(nzVisible)]="isOtherLineOptionsModalVisible" nzTitle="Other options" (nzOnCancel)="isOtherLineOptionsModalVisible = false" [nzFooter]="null"
[nzWidth]="800">
    <ng-container *nzModalContent>
        <nz-table #otherOptionsTable [nzData]="otherLineOptions" [nzLoading]="isLoadingOtherLineOptions">
            <thead>
              <tr>
                <th>Supplier</th>
                <th>Cost</th>
                <th>SOH</th>
                <th>GP</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of otherOptionsTable.data">
                <td>{{data.supplier}}</td>
                <td>R {{data.cost}}</td>
                <td>{{data.soh}}</td>
                <td>R {{selectedLineItemWithError.price - data.cost}} ({{((selectedLineItemWithError.price - data.cost) * 100 / selectedLineItemWithError.price) | number : '1.2-2' }} %)
                </td>
                <td>
                    <button nz-button nzType="primary" (click)="onSelectOtherOption(data)" nzShape="circle" nz-tooltip nzTooltipTitle="Select Item" style="margin-left: 15px;">
                        <span nz-icon nzType="check" nzTheme="outline"></span>
                    </button>
                </td>
              </tr>
            </tbody>
          </nz-table>
    </ng-container>
  </nz-modal>

  <nz-modal
[(nzVisible)]="passwordModalVisible"
[nzTitle]="'Please input GP Protection Password!'"
(nzOnCancel)="passwordModalVisible = false"
(nzOnOk)="passwordModalVisible = false"
>
    <ng-container *nzModalContent>
        <form nz-form [formGroup]="validatePassword">
            <nz-form-item>
                <nz-form-label [nzSpan]="7" nzRequired>Password</nz-form-label>
                <nz-form-control [nzSpan]="12" nzHasFeedback [nzErrorTip]="passwordErrorTpl" nzValidatingTip="Validating...">
                    <input nz-input placeholder="Please enter your password" type="password" formControlName="password" />
                    <ng-template #passwordErrorTpl let-control>
                        <span *ngIf="control.errors?.['required']">Please confirm your password!</span>
                        <span *ngIf="control.errors?.['duplicated']">Password is incorrect!</span>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
        </form>
    </ng-container>
    <div *nzModalFooter>
        <button nz-button nzType="default" (click)="passwordModalVisible = false">Cancel</button>
        <button nz-button nzType="primary" (click)="submitPassword()" [disabled]="!validatePassword.valid">Submit</button>
    </div>
</nz-modal>