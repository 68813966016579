import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { NotificationService } from '../shared/notification.service';

import { UnMappedStock, MappedStock, CustomStockToBeMapped, Stock, TyreStockToBeMapped, CustomStock, NewCustomStockMapping, StockType } from '../models/client-mappings';
import { AdminMappingService } from '../admin-stock-mappings/admin-mappings.service';
import { AuthService } from '../auth.service';
import { UsedStockItemToBeMapped } from '../models/admin-mappings';


@Injectable({
  providedIn: 'root'
})

export class ClientStockMappingsService {
    apiUrl = 'v2/client_stockmappings/';

    modalVisibility: boolean = false;

    unmappedStockItem: UnMappedStock = {
        stockCode: '',
        description: '',
        clientId: 0,
        clientName: '',
        soh: 0,
        brand: '',
        genCode: '',
    };

    currentStockItem: UnMappedStock = this.unmappedStockItem;

    isLoadingActiveCustomStock = false;

    activeCustomStock: CustomStock[] = [];

    customStockTypes : StockType[] = []


    constructor(private http: HttpClient, private notification: NotificationService, public adminMappingService: AdminMappingService, public authService: AuthService) { }

    public getUnmappedStock() {
        return this.http.get<UnMappedStock[]>(environment.ApiUrl() + this.apiUrl + "unmappedStock");
    }

    public getMappedStock() {
        return this.http.get<MappedStock[]>(environment.ApiUrl() + this.apiUrl + "mappedStock");
    }

    public mapCustomStock( customStock : CustomStockToBeMapped){
        return this.http.post(environment.ApiUrl() + this.apiUrl + "mapCustomStock", customStock);
    }
    
    public openModal(item: UnMappedStock){
        this.unmappedStockItem = item;
        this.modalVisibility = true;
        this.adminMappingService.getActiveCustomStock(this.authService.user.client_id);
    }

    public mapTyreStock(unmappedStockItem: TyreStockToBeMapped){
        return this.http.post(environment.ApiUrl() + this.apiUrl + "mapTyreStock", unmappedStockItem);
    }

    public deleteMapping(item: MappedStock) {
        return this.http.post(environment.ApiUrl() + this.apiUrl + "deleteMapping", item);
    }

    public createCustomStockMapping(customStockMapping: NewCustomStockMapping) {
        return this.http.post(environment.ApiUrl() + this.apiUrl + "createCustomStockMapping", customStockMapping);
    }

    public mapUsedStockItem(item: UsedStockItemToBeMapped) {
        return this.http.post(environment.ApiUrl() + this.apiUrl + "mapUsedStockItem", item);
    }
}

