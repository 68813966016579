<head>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0" />
</head>
<div nz-row nzJustify="center" style="padding-bottom: 20px;">
    <div nz-col nzSpan="20">
        <nz-input-group [nzSuffix]="suffixIconSearch">
            <input type="search" nz-input placeholder="Search Inbox" [(ngModel)]="waService.searchString"/>
        </nz-input-group>
        <ng-template #suffixIconSearch>
            <span nz-icon nzType="search"></span>
        </ng-template>
    </div>
</div>
<div nz-row nzJustify="center" style="padding-bottom: 20px;">
    <div nz-col nzSpan="16">
        Show only unread messages
    </div>
    <div nz-col nzSpan="4">
        <nz-switch [(ngModel)]="showOnlyUnread" [nzCheckedChildren]="checkedTemplate" [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
        <ng-template #checkedTemplate><span nz-icon nzType="check"></span></ng-template>
        <ng-template #unCheckedTemplate><span nz-icon nzType="close"></span></ng-template>
    </div>
</div>
<nz-divider></nz-divider>
<nz-skeleton [nzLoading]="waService.isLoading" *ngIf="waService.messagelist.length !== 0">
    <!-- <nz-list nzItemLayout="horizontal" [nzLoading]="waService.isLoading" *ngFor="let item of waService.filterMessages()">
        <nz-list-item>
            <nz-list-item-meta>
                <nz-list-item-meta-avatar [nzSrc]="'https://logowik.com/content/uploads/images/warning6444.logowik.com.webp'" *ngIf="item.unread > 0"></nz-list-item-meta-avatar>
                <nz-list-item-meta-avatar [nzSrc]="'https://cdn.vectorstock.com/i/preview-1x/70/37/double-tick-sign-element-green-checkmark-icon-ok-vector-44877037.jpg'" *ngIf="item.unread === 0"></nz-list-item-meta-avatar>

                <nz-list-item-meta-title>
                    <nz-badge [nzCount]="item.unread">
                        <a (click)="loadMessages(item.cell)" nz-tooltip [nzTooltipTitle]="item.cell">{{item.name}}</a>
                    </nz-badge>
                </nz-list-item-meta-title>
                
                <nz-list-item-meta-description>
                    <a (click)="loadMessages(item.cell)">{{ item.message }}</a>
                    <div nz-row>
                        <div nz-col nzSpan="4" nzOffset="15">
                            <button nz-button nzType="primary" nzShape="round" (click)="getCustomerList(item)" nz-tooltip nzTooltipTitle="Customer details.">
                                <span nz-icon nzType="idcard" nzTheme="outline"></span>
                            </button>
                        </div>
                        <div nz-col nzSpan="4" nzOffset="1">
                            <button nz-button nzType="primary" nzShape="round" (click)="markAsArchive(item)" nz-tooltip nzTooltipTitle="Archive conversation.">
                                <a class="material-symbols-outlined">archive</a>
                            </button>
                        </div>
                    </div>
                </nz-list-item-meta-description>
            </nz-list-item-meta>
        </nz-list-item>
    </nz-list> -->
    <div *ngFor="let item of waService.filterMessages(showOnlyUnread)">
        <div style="padding-bottom: 15px; cursor: pointer;" (click)="loadMessages(item.cell)">
            <div style="display: inline-block; width: 40px; vertical-align: top;">
                <span nz-icon nzType="warning" nzTheme="outline" *ngIf="item.unread > 0" style="font-size: 2rem !important; " class="color-warning"></span>
                <span nz-icon nzType="check-circle" nzTheme="outline" *ngIf="item.unread === 0" style="font-size: 2rem !important; " class="color-success"></span>
            </div>
            <div style="display: inline-block; width: 400px">
                <span class="color-primary"><b>{{item.name}}</b></span><br />
                {{ item.message }}
            </div>
            
        </div>
        <div nz-row>
            <div nz-col nzSpan="2" class="color-error">
                <span nz-icon nzType="issues-close" nzTheme="outline" [nz-tooltip]="item.error" *ngIf="item.error" nzTooltipPlacement="left"></span>
            </div>
            <div nz-col nzSpan="8" style="color:var(--textcolor)">
                <small><i>{{item.date | date: 'dd/MM/yyyy HH:mm'}}</i></small>
            </div>
            <div nz-col nzSpan="4" nzOffset="5">
                <button nz-button nzType="primary" nzShape="round" (click)="getCustomerList(item)" nz-tooltip nzTooltipTitle="Customer details.">
                    <span nz-icon nzType="idcard" nzTheme="outline"></span>
                </button>
            </div>
            <div nz-col nzSpan="4" nzOffset="1">
                <button nz-button nzType="primary" nzShape="round" (click)="markAsArchive(item)" nz-tooltip nzTooltipTitle="Archive conversation.">
                    <a class="material-symbols-outlined">archive</a>
                </button>
            </div>
        </div>
        <nz-divider></nz-divider>
    </div>
    <div *ngIf="waService.hasMoreMessages() === true">
        <button nz-button nzType="primary" nzShape="round" (click)="waService.loadMoreMessages()" nz-tooltip nzTooltipTitle="Archive conversation.">
            Load More...
        </button>
    </div>
</nz-skeleton>

<nz-modal [(nzVisible)]="isCustomerListModalVisible" nzTitle="List of linked customers." nzCentered (nzOnCancel)="isCustomerListModalVisible = false" (nzOnOk)="isCustomerListModalVisible = false" [nzWidth]="800">
    <ng-container *nzModalContent>
        <nz-table #customerDetails [nzData]="customerList"
        [nzBordered]="false"
        [nzFrontPagination]="false"
        [nzShowPagination]="false">
          <thead>
            <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Cell</th>
                <th>Select</th>
            </tr>
            </thead>
          <tbody class="table-body">
            <ng-template ngFor let-data [ngForOf]="customerDetails.data">
                <tr>
                    <td [(nzExpand)]="data.expand">{{data.name}}</td>
                    <td>{{data.email}}</td>
                    <td>{{data.cell}}</td>
                    <td>
                        <button nz-button nzType="primary" (click)="getCustomerDetails(data)" nz-tooltip nzTooltipTitle="Open Customer Page">Open
                            <span nz-icon nzType="user" nzTheme="outline"></span>
                        </button>
                    </td>
                </tr>
                <tr [nzExpand]="data.expand">
                    <nz-table #innerTable [nzData]="data.data" nzSize="middle" [nzShowPagination]="false">
                      <thead>
                        <tr>
                            <th>Vehicle Registration</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let data of innerTable.data" >
                            <td>{{data.vreg}}</td>
                        </tr>
                    </tbody>
                </nz-table>
              </tr>
            </ng-template>
            </tbody>
        </nz-table>
    </ng-container>
  </nz-modal>
