<div class="text-center">
    <div nz-row nzJustify="center">
        <nz-card style="width: 95%;">
            <div nz-row nzJustify="center" nzAlign="top">
                <div nz-col [nzSm]="24" [nzXs]="24" [nzMd]="22" [nzLg]="22" [nzXl]="20" class="input-form ">
                    <h2 style="margin-left:-1px; margin-right: -1px"><b>Settings</b></h2>
                    <nz-spin [nzSpinning]="isLoading">
                        <nz-tabset nzCentered nzSize="large">
                            <nz-tab nzTitle="Main">
                                <app-video-widget [videoId]="3" style="float: inline-end;"></app-video-widget>
                                <form nz-form [formGroup]="settingsForm" style="padding-bottom: 15px;">
                                    <nz-form-item>
                                        <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="vatNr">VAT Nr</nz-form-label>
                                        <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Enter a valid VAT Number">
                                            <input nz-input formControlName="vatNr" id="vatNr" placeholder="Company Vat Number" />
                                        </nz-form-control>
                                    </nz-form-item>
                                    <nz-form-item>
                                        <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="regNr">Reg Nr</nz-form-label>
                                        <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Enter a valid Registration Number">
                                            <input nz-input formControlName="regNr" id="regNr" placeholder="Company Registration Number" />
                                        </nz-form-control>
                                    </nz-form-item>
                                    <div nz-row>
                                        <div nz-col [nzSpan]="12">
                                            <nz-form-item *ngIf="authService.user.bookingsEnabled">
                                                <nz-form-label [nzSm]="12" [nzXs]="24" nzFor="liftCount"><small>Booking Fitment Bays</small></nz-form-label>
                                                <nz-form-control [nzSm]="8" [nzXs]="24" nzErrorTip="Enter the amount of lifts for online bookings">
                                                    <nz-input-number nz-input formControlName="liftCount" id="liftCount" style="width: 100%;">
                                                    </nz-input-number>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                                        <div nz-col [nzSpan]="12">
                                            <nz-form-item *ngIf="authService.user.bookingsEnabled">
                                                <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="bookingBlockedHours"><small>Bookings Locked<br /><i>(hours)</i></small></nz-form-label>
                                                <nz-form-control [nzSm]="8" [nzXs]="24" nzErrorTip="Enter the number of hours to lock bookings">
                                                    <nz-input-number nz-input formControlName="bookingBlockedHours" id="bookingBlockedHours" style="width: 100%;">
                                                    </nz-input-number>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                                    </div>
                                    <div nz-row>
                                        <div nz-col [nzSpan]="8" *ngIf="authService.hasClaims(['STA'])" [nzPush]="4">
                                            <!--Add For Stock MANAGEMENT dont show for non premium -->
                                            <nz-form-item>
                                                <nz-form-label nzFor="manageStock">Allow SF to manage Stock</nz-form-label>
                                                <nz-form-control [nzSm]="8" [nzXs]="24" style="text-align: left;">
                                                    <nz-switch (click)="showNotice()" formControlName="manageStock" id="manageStock"></nz-switch>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                                    </div>
                                    <div nz-row>
                                        <div nz-col [nzSpan]="12">
                                            <nz-form-item>
                                                <nz-form-label [nzSm]="12" [nzXs]="24" nzFor="isInvoiceManagedSF">Feature Level</nz-form-label>
                                                <nz-form-control [nzSm]="12" [nzXs]="24" style="text-align: left">
                                                    <nz-select formControlName="featureLevel" id="featureLevel">
                                                        <nz-option nzValue="1" nzLabel="Quoting Only"></nz-option>
                                                        <nz-option nzValue="2" nzLabel="Work In Progress"></nz-option>
                                                        <nz-option nzValue="3" nzLabel="Invoicing"></nz-option>
                                                    </nz-select>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                                    </div>
                                    <div nz-row>
                                        <div nz-col [nzSpan]="8" *ngIf="this.settingsForm.value.featureLevel >= 2" [nzPush]="4">
                                            <nz-form-item>
                                                <nz-form-label nzFor="displayCostOnJC">Display Price on Jobcard</nz-form-label>
                                                <nz-form-control [nzSm]="8" [nzXs]="24" style="text-align: left;">
                                                    <!-- <input nz-input formControlName="displayCostOnJC" id="displayCostOnJC" /> -->
                                                    <nz-switch formControlName="displayCostOnJC" id="displayCostOnJC"></nz-switch>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                                        <div nz-col [nzSpan]="12" *ngIf="this.settingsForm.value.featureLevel == 3" [nzPush]="4">
                                            <nz-form-item>
                                                <nz-form-label [nzSm]="12" [nzXs]="24" nzFor="isInvoiceManagedSF">Invoice Managed by SF</nz-form-label>
                                                <nz-form-control [nzSm]="8" [nzXs]="24" style="text-align: left;">
                                                    <nz-switch formControlName="isInvoiceManagedSF" id="isInvoiceManagedSF"></nz-switch>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                                    </div>
                                    <div nz-row *ngIf="this.settingsForm.controls.featureLevel.dirty" nzJustify="center">
                                        <div nz-col [nzSpan]="22">
                                            <nz-alert nzType="warning" nzMessage="PLEASE NOTE: All users needs to log out and back in for changes to take effect" nzShowIcon></nz-alert>
                                        </div>
                                    </div>
                                    <div>
                                    </div>
                                    <ng-container>
                                        <form nz-form [formGroup]="bankDetailsForm" style="padding-bottom: 15px;">
                                            <h3>Banking Details</h3>
                                            <nz-form-item>
                                                <nz-form-label [nzSm]="3" [nzXs]="12" nzFor="beneficiery">Beneficiary</nz-form-label>
                                                <nz-form-control [nzSm]="7" [nzXs]="12" nzErrorTip="Enter Beneficiary Name">
                                                    <input nz-input formControlName="beneficiaryName" id="beneficiaryName" />
                                                </nz-form-control>
                                                <nz-form-label [nzSm]="3" [nzXs]="12" nzFor="bankName">Bank</nz-form-label>
                                                <nz-form-control [nzSm]="7" [nzXs]="12" nzErrorTip="Enter Bank Name">
                                                    <input nz-input formControlName="bankName" id="bankName" />
                                                </nz-form-control>
                                            </nz-form-item>
                                            <nz-form-item>
                                                <nz-form-label [nzSm]="3" [nzXs]="12" nzFor="branchCode">Branch Code</nz-form-label>
                                                <nz-form-control [nzSm]="7" [nzXs]="12" nzErrorTip="Enter branch Code">
                                                    <input nz-input formControlName="branchCode" id="branchCode" />
                                                </nz-form-control>
                                                <nz-form-label [nzSm]="3" [nzXs]="12" nzFor="accountType">Acc Type</nz-form-label>
                                                <nz-form-control [nzSm]="7" [nzXs]="12" nzErrorTip="Enter AccountType">
                                                    <input nz-input formControlName="accountType" id="accountType" />
                                                </nz-form-control>
                                            </nz-form-item>
                                            <nz-form-item>
                                                <nz-form-label [nzSm]="3" [nzXs]="12" nzFor="accountNumber">Acc Number</nz-form-label>
                                                <nz-form-control [nzSm]="7" [nzXs]="12" nzErrorTip="Enter Account Number">
                                                    <input nz-input formControlName="accountNumber" id="accountNumber" />
                                                </nz-form-control>
                                            </nz-form-item>
                                        </form>
                                    </ng-container>
                                    <h3>Address</h3>
                                    <nz-form-item>
                                        <nz-form-label [nzSm]="3" [nzXs]="24" nzFor="address">Address</nz-form-label>
                                        <nz-form-control [nzSm]="17" [nzXs]="24" nzErrorTip="Enter valid address">
                                            <textarea nz-input nz-input rows="2" formControlName="address" placeholder="Adress"></textarea>
                                        </nz-form-control>
                                    </nz-form-item>

                                    <nz-form-item>
                                        <nz-form-label [nzSm]="3" [nzXs]="12" nzFor="city">City</nz-form-label>
                                        <nz-form-control [nzSm]="7" [nzXs]="12" nzErrorTip="Enter a valid City">
                                            <input nz-input formControlName="city" id="city" placeholder="City Name" />
                                        </nz-form-control>
                                        <nz-form-label [nzSm]="3" [nzXs]="12" nzFor="zipCode">Zip Code</nz-form-label>
                                        <nz-form-control [nzSm]="7" [nzXs]="12" nzErrorTip="Enter a valid Zip Code">
                                            <input nz-input formControlName="zipCode" id="zipCode" placeholder="Zip Code" />
                                        </nz-form-control>
                                    </nz-form-item>

                                    <nz-form-item>
                                        <nz-form-label [nzSm]="3" [nzXs]="12" nzFor="regionId"><span>Region</span></nz-form-label>
                                        <nz-form-control [nzSm]="7" [nzXs]="12" nzErrorTip="Enter Region">
                                            <nz-select formControlName="regionId" [(ngModel)]="this.settingsForm.value.regionId">
                                                <nz-option *ngFor="let item of regions" nzValue="{{item.regionId}}" nzLabel="{{item.name}}"></nz-option>
                                            </nz-select>
                                        
                                            
                                        </nz-form-control>
                                        
                                    </nz-form-item>
                                    <nz-form-item>                                                                           
                                        <!-- Display latitude -->
                                        <nz-form-label [nzSm]="3" [nzXs]="12" nzFor="latitude" n>Latitude</nz-form-label>
                                        <nz-form-control [nzSm]="3" [nzXs]="12" nzErrorTip="Enter a valid Latitude value">
                                            <input nz-input formControlName="latitude" id="latitude" placeholder=" " />
                                        </nz-form-control>

                                          <!-- Display longitude -->
                                        <nz-form-label [nzSm]="3" [nzXs]="10" nzFor="longitude">Longitude</nz-form-label>
                                        <nz-form-control [nzSm]="3" [nzXs]="10" nzErrorTip="Enter a valid longitude value">
                                            <input nz-input formControlName="longitude" id="longitude" placeholder=" " />
                                        </nz-form-control>
                                    
                                        <!-- Button to get current location -->
                                        <nz-form-control nzOffset="2" [nzSm]="7" [nzXs]="12">    
                                            <button nz-button nzType="primary" [nzLoading]="isLoading" (click)="getLocation()">Use My Current Location</button>
                                        </nz-form-control>
                                      
                                        
                                    </nz-form-item>
                                    
                                    


                                    <div>
                                        <button nz-button nzType="primary" [nzLoading]="isSaving" (click)="submitSettingsForm()">Save</button>
                                    </div>
                                </form>
                            </nz-tab>
                            <nz-tab nzTitle="Quote">
                                <form nz-form [formGroup]="quoteSettingsForm" style="padding-bottom: 15px;">
                                    <div nz-row>
                                        <div nz-col [nzSpan]="24">
                                            <h4>POS/Leads</h4>
                                            <app-video-widget [videoId]="15" style="float: inline-end;"></app-video-widget>

                                        </div>
                                    </div>
                                    <br>
                                    <div nz-row>
                                        <div nz-col [nzSpan]="24">
                                            <div nz-row [nzJustify]="'center'">
                                                <div nz-col [nzSm]="24" [nzXs]="24" [nzMd]="24" [nzLg]="12">
                                                    <nz-form-item style="text-align: center;">
                                                        <nz-form-label [nzSpan]="12">Lead Expiry <small style="color:var(--textcolor)">(Days)</small></nz-form-label>
                                                        <nz-form-control [nzSpan]="8">
                                                            <nz-input-number
                                                            formControlName="leadExpiryDays"
                                                            [nzMin]="0"
                                                            [nzStep]="1"
                                                          ></nz-input-number>
                                                            <!-- <input nz-input formControlName="leadExpiryDays" placeholder="4" style="text-align:center;" />  -->
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                    <nz-form-item>
                                                        <nz-form-label [nzSpan]="12">Force Follow Up</nz-form-label>
                                                        <nz-form-control [nzSpan]="5">
                                                            <nz-switch formControlName="showPosNavAway" [nzCheckedChildren]="displayNav" [nzUnCheckedChildren]="hideNav"></nz-switch>
                                                            <ng-template #displayNav>
                                                                <span nz-icon nzType="check"></span>
                                                            </ng-template>
                                                            <ng-template #hideNav>
                                                                <span nz-icon nzType="close"></span>
                                                            </ng-template>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                    <nz-form-item>
                                                        <nz-form-label [nzSpan]="12">GP Protection Percent</nz-form-label>
                                                        <nz-form-control [nzSpan]="8" nz-tooltip="Press the 'Save' button at the bottom of the page to save progress" nzTooltipPlacement="top">
                                                            <nz-input-number
                                                            formControlName="gpProtectionPercent"
                                                            [nzMin]="0"
                                                            [nzMax]="100"
                                                            [nzStep]="1"
                                                            [nzFormatter]="formatterPercent"
                                                            [nzParser]="parserPercent"
                                                            [nzDisabled]="gpPasswordCheck"
                                                          ></nz-input-number>
                                                          </nz-form-control>
                                                          <a (click)="changePasswordModal = true; gpPasswordModalVisible = false" class="color-primary" *ngIf="gpHasPassword">Reset Password</a>
                                                          <button nz-button nzType="primary" (click)="activateGPProtection()" *ngIf="gpPasswordCheck">
                                                            <span *ngIf="!gpHasPassword">Activate</span>
                                                            <span *ngIf="gpHasPassword">Change</span>
                                                        </button>
                                                    </nz-form-item>
                                                </div>
                                                <div nz-col [nzSm]="24" [nzXs]="24" [nzMd]="24" [nzLg]="12">
                                                    <nz-form-item>
                                                        <nz-form-label [nzSpan]="12">Lead Channel Classification</nz-form-label>
                                                        <nz-form-control [nzSpan]="5">
                                                            <nz-switch formControlName="leadChannel" [nzCheckedChildren]="displayNav1" [nzUnCheckedChildren]="hideNav1"></nz-switch>
                                                            <ng-template #displayNav1>
                                                                <span nz-icon nzType="check"></span>
                                                            </ng-template>
                                                            <ng-template #hideNav1>
                                                                <span nz-icon nzType="close"></span>
                                                            </ng-template>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                    <nz-form-item>
                                                        <nz-form-label [nzSpan]="12">Lead Type Classification</nz-form-label>
                                                        <nz-form-control [nzSpan]="5">
                                                            <nz-switch formControlName="leadType" [nzCheckedChildren]="displayNav2" [nzUnCheckedChildren]="hideNav2"></nz-switch>
                                                            <ng-template #displayNav2>
                                                                <span nz-icon nzType="check"></span>
                                                            </ng-template>
                                                            <ng-template #hideNav2>
                                                                <span nz-icon nzType="close"></span>
                                                            </ng-template>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>
                                                <div nz-col [nzSm]="24" [nzXs]="24" [nzMd]="24" [nzLg]="24">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <nz-divider></nz-divider>
                                    <div nz-row>
                                        <div nz-col [nzSpan]="24">
                                            <h4>Custom Stock Package</h4>
                                        </div>
                                    </div>
                                    <div nz-row>
                                        <div nz-col [nzSpan]="24">
                                            <nz-form-item>
                                                <nz-form-label [nzSpan]="8" nz-tooltip="Custom Stock Package" nzTooltipPlacement="topRight">Default CSP for manual quotes</nz-form-label>
                                                <nz-form-control [nzSpan]="10">
                                                    <nz-select nzPlaceHolder="Select default Custom Stock Package for manual quotes" formControlName="defaultCustomStockPackage">
                                                        <nz-option *ngFor="let item of customStockPackages" nzCustomContent nzValue="{{item.customStockGroupId}}" nzLabel="{{item.customStockGroupName.toUpperCase()}}">
                                                            <span nz-icon nzType="sf:stock2" nzTheme="outline" [ngClass]="{'color-primary' : item.customStockGroupId == this.quoteSettingsForm.value.defaultCustomStockPackage}"></span>
                                                            <span [ngClass]="{'color-primary' : item.customStockGroupId == this.quoteSettingsForm.value.defaultCustomStockPackage}">{{item.customStockGroupName.toUpperCase()}}</span>
                                                        </nz-option>
                                                    </nz-select>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                                    </div>
                                    <nz-divider></nz-divider>
                                    <div nz-row>
                                        <div nz-col [nzSpan]="24">
                                            <h4>PDF</h4>
                                        </div>
                                    </div>
                                    <br>
                                    <div nz-row>
                                        <div nz-col [nzSpan]="24">
                                            <div nz-row [nzJustify]="'center'">
                                                <div nz-col [nzSm]="24" [nzXs]="24" [nzMd]="24" [nzLg]="6">
                                                    <nz-form-item style="text-align: center;">
                                                        <nz-form-label [nzSpan]="8">Address</nz-form-label>
                                                        <nz-form-control [nzSpan]="5">
                                                            <nz-switch formControlName="showAddressDetails" [nzCheckedChildren]="showAdd" [nzUnCheckedChildren]="hideAdd"></nz-switch>
                                                            <ng-template #showAdd>
                                                                <span nz-icon nzType="check"></span>
                                                            </ng-template>
                                                            <ng-template #hideAdd>
                                                                <span nz-icon nzType="close"></span>
                                                            </ng-template>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>
                                                <div nz-col [nzSm]="24" [nzXs]="24" [nzMd]="24" [nzLg]="6">
                                                    <nz-form-item>
                                                        <nz-form-label [nzSpan]="12">Display Logo</nz-form-label>
                                                        <nz-form-control [nzSpan]="5">
                                                            <nz-switch formControlName="showQuoteImage" [nzCheckedChildren]="showImage" [nzUnCheckedChildren]="hideImage"></nz-switch>
                                                            <ng-template #showImage>
                                                                <span nz-icon nzType="check"></span>
                                                            </ng-template>
                                                            <ng-template #hideImage>
                                                                <span nz-icon nzType="close"></span>
                                                            </ng-template>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>
                                                <div nz-col [nzSm]="24" [nzXs]="24" [nzMd]="24" [nzLg]="6">
                                                    <nz-form-item>
                                                        <nz-form-label [nzSpan]="12">Bank Details</nz-form-label>
                                                        <nz-form-control [nzSpan]="5">
                                                            <nz-switch formControlName="showBankingDetails" [nzCheckedChildren]="showBank" [nzUnCheckedChildren]="hideBank"></nz-switch>
                                                            <ng-template #showBank>
                                                                <span nz-icon nzType="check"></span>
                                                            </ng-template>
                                                            <ng-template #hideBank>
                                                                <span nz-icon nzType="close"></span>
                                                            </ng-template>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>
                                                <div nz-col [nzSm]="24" [nzXs]="24" [nzMd]="24" [nzLg]="6">
                                                    <nz-form-item>
                                                        <nz-form-label [nzSpan]="12">Ink Saving</nz-form-label>
                                                        <nz-form-control [nzSpan]="5">
                                                            <nz-switch formControlName="quoteSoftPrint" [nzCheckedChildren]="showInk" [nzUnCheckedChildren]="hideInk"></nz-switch>
                                                            <ng-template #showInk>
                                                                <span nz-icon nzType="check"></span>
                                                            </ng-template>
                                                            <ng-template #hideInk>
                                                                <span nz-icon nzType="close"></span>
                                                            </ng-template>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>
                                            </div>
                                            <div nz-row [nzJustify]="'center'">
                                                <div nz-col [nzSm]="24" [nzXs]="24" [nzMd]="24" [nzLg]="12">
                                                    <nz-form-item>
                                                        <nz-form-label [nzSpan]="7">Quote Theme</nz-form-label>
                                                        <nz-form-control [nzSpan]="16" style="text-align: center">
                                                            <nz-select (ngModelChange)="changeTheme($event)" formControlName="quoteTheme" nzPlaceHolder="Select Theme" [(ngModel)]="selectedReport">
                                                                <nz-option *ngFor="let theme of quoteThemeOptions" [nzValue]="theme.reportDataId" [nzLabel]="theme.name"></nz-option>
                                                            </nz-select>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>
                                            </div>
                                            <div nz-row *ngIf="customtheme == true" [nzJustify]="'center'">
                                                <div nz-col [nzSpan]="16">
                                                    <h3>Custom Theme Settings</h3>
                                                    <nz-form-label>Qr Text</nz-form-label><br>
                                                    <nz-form-item>
                                                        <nz-form-control>
                                                            <input nz-input formControlName="quoteQrText" placeholder="Enter Text Here" style="text-align:center;width: 60%;" />
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                    <nz-form-item>
                                                        <nz-form-control nzErrorTip="Enter Beneficiary Name">
                                                            <div *ngIf="quoteSettingsForm.value.quoteImage != '' && quoteSettingsForm.value.quoteImage != null" [ngStyle]="{'border-color': quoteSettingsForm.value.quoteImage != null ?  quoteSettingsForm.value.quoteColor :  '#27898b' }" style="border-radius: 20px; border: 1px solid;text-align:center;padding: 10px;">
                                                                <img height="100" width="400" alt="Logo" src="{{quoteSettingsForm.value.quoteImage}}" />
                                                            </div>
                                                            <br>
                                                            <div class="clearfix">
                                                                <nz-upload [nzBeforeUpload]="beforeUpload" nzListType="picture" [(nzFileList)]="fileList">
                                                                    <button nz-button>
                                                                        <span nz-icon nzType="upload"></span>
                                                                            Select Logo
                                                                    </button>
                                                                </nz-upload>
                                                            </div>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                    <nz-form-label>Custom Color</nz-form-label><br>
                                                    <nz-form-item>
                                                        <nz-form-control nzErrorTip="Enter Bank Name">
                                                            <input type="color" placeholder="Select Custom Color" nz-input formControlName="quoteColor" style="width: 10%;border-radius:50px;" />
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                    <nz-form-label>Terms & Conditions</nz-form-label><br>
                                                    <nz-form-item>
                                                        <nz-form-control nzErrorTip="Enter valid terms">
                                                            <textarea nz-input rows="2" formControlName="quoteTerms" placeholder="Enter Ts & Cs"></textarea>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>
                                                
                                            </div>
                                            <div>
                                                <button nz-button nzType="primary" [disabled]="!this.quoteSettingsForm.valid" [nzLoading]="isSaving" (click)="updateQuote()">Save</button>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </form>
                            </nz-tab>
                            <nz-tab nzTitle="Whatsapp" *ngIf="authService.user.waEnabled">
                                <div nz-row nzJustify="center">
                                    <div nz-col [nzSpan]="16" style="text-align: left;">
                                        Your customers can request quotes from ({{authService.user.client_name}}) via WhatsApp. There are two ways to do this.<br />
                                        <ol>
                                            <li>You can download the QR-Code and add it to any marketing material you like. Here are a few examples:</li>
                                            <ul>
                                                <li>Use the QR-Code in a poster at your local school or sports club.</li>
                                                <li>Create a flyer to hand out at events or leave at strategic locations.</li>
                                                <li>Put the QR code on a sticker or your vehicle.</li>
                                                <li>Add it to your business card.</li>
                                            </ul>
                                            <li>You can share the link via Email, WhatsApp or add it to your website and social media pages. <br />
                                            <h4><a href="https://shop.stockfinder.co.za/l/{{authService.user.client_id}}">https://shop.stockfinder.co.za/l/{{authService.user.client_id}}</a>&nbsp;&nbsp;&nbsp;
                                                <button nz-button nzType="primary" [copy-clipboard]="getClipboardLink()" (copied)="showSuccess($event)"><span nz-icon nzType="copy" nzTheme="outline"></span> Copy</button></h4> 
                                            </li>
                                        </ol>
                                    </div>
                                    <div nz-col [nzSpan]="8">
                                        <div id="download">
                                            <nz-qrcode nzValue="https://wa.me/27664532826/?text=Please+send+me+a+Quote+from+{{this.parseClientName()}}"
                                                [nzPadding]="2" [nzSize]="320"></nz-qrcode><br />
                                            <a #download></a>
                                            <button nz-button nzType="primary" (click)="downloadQrCode()"><span nz-icon nzType="download" nzTheme="outline"></span> Download</button>
                                        </div>
                                    </div>
                                </div>
                                
                            </nz-tab>
                        </nz-tabset>
                    </nz-spin>
                </div>
            </div>
        </nz-card>
    </div>
</div>

<!-- Password Activate Modal -->

<nz-modal
[(nzVisible)]="gpPasswordModalVisible"
[nzTitle]="'Please input GP Protection Password!'"
(nzOnCancel)="gpPasswordModalVisible = false"
(nzOnOk)="enableGPProtection()"
>
    <ng-container *nzModalContent>
        <form nz-form [formGroup]="validatePassword">
            <nz-form-item>
                <nz-form-label [nzSpan]="7" nzRequired>Password</nz-form-label>
                <nz-form-control [nzSpan]="12" nzHasFeedback [nzErrorTip]="passwordErrorTpl" nzValidatingTip="Validating...">
                    <input nz-input placeholder="Please enter your password" type="password" formControlName="password" />
                    <ng-template #passwordErrorTpl let-control>
                        <span *ngIf="control.errors?.['required']">Please confirm your password!</span>
                        <span *ngIf="control.errors?.['duplicated']">Password is incorrect!</span>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
        </form>
    </ng-container>
    <ng-template #modalTitle>
        <span *ngIf="this.gpHasPassword">Please input GP Protection Password!</span>
        <span *ngIf="!this.gpHasPassword">Please register GP Protection Password!</span>
    </ng-template>
    <div *nzModalFooter>
        <!-- <button nz-button nzType="default" (click)="changePasswordModal = true; this.gpPasswordRegisterModalVisible = false;">Reset Password</button> -->
        <a (click)="changePasswordModal = true; gpPasswordModalVisible = false" class="color-primary" style="margin-right: 10px">Reset Password</a>
        <button nz-button nzType="default" (click)="gpPasswordModalVisible = false; validatePassword.reset()">Cancel</button>
        <button nz-button nzType="primary" (click)="enableGPProtection()" [disabled]="!validatePassword.valid">Submit</button>
      </div>
</nz-modal>

<!-- Password Register Modal -->

<nz-modal
[(nzVisible)]="gpPasswordRegisterModalVisible"
[nzTitle]="modalTitle"
(nzOnCancel)="gpPasswordRegisterModalVisible = false;"
(nzOnOk)="registerPassword()"
>
    <ng-container *nzModalContent>
        <form nz-form [formGroup]="validateRegisterPassword">
            <nz-form-item>
                <nz-form-label [nzSpan]="7" nzRequired>Password</nz-form-label>
                <nz-form-control [nzSpan]="12" nzHasFeedback [nzErrorTip]="passwordErrorTpl" nzValidatingTip="Validating...">
                <input nz-input placeholder="Please enter your password" type="password" formControlName="password" (ngModelChange)="validateConfirmPassword(validateRegisterPassword)" />
                <ng-template #passwordErrorTpl let-control>
                    <span *ngIf="control.errors?.['required']">Please confirm your password!</span>
                    <span *ngIf="control.errors?.['duplicated']">Password is incorrect!</span>
                </ng-template>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="!this.gpHasPassword">
                <nz-form-label [nzSpan]="7" nzFor="confirm" nzRequired>Confirm Password</nz-form-label>
                <nz-form-control [nzSpan]="12" nzHasFeedback [nzErrorTip]="passwordErrorTpl">
                <input nz-input type="password" formControlName="confirm" placeholder="confirm your password" />
                    <ng-template #passwordErrorTpl let-control>
                        <span *ngIf="control.errors?.['required']">Please confirm your password!</span>
                        <span *ngIf="control.errors?.['confirm']">Password is inconsistent!</span>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
        </form>
    </ng-container>
    <ng-template #modalTitle>
        <span *ngIf="this.gpHasPassword">Please input GP Protection Password!</span>
        <span *ngIf="!this.gpHasPassword">Please register GP Protection Password!</span>
    </ng-template>
    <div *nzModalFooter>
        <button nz-button nzType="default" (click)="gpPasswordRegisterModalVisible = false;">Cancel</button>
        <button nz-button nzType="primary" (click)="registerPassword()" [disabled]="!validateRegisterPassword.valid">Submit</button>
      </div>
</nz-modal>

<!-- Password Change Modal -->

<nz-modal
[(nzVisible)]="changePasswordModal"
[nzTitle]="modalTitle"
(nzOnCancel)="changePasswordModal = false"
(nzOnOk)="changePassword()"
>
    <ng-container *nzModalContent>
        <form nz-form [formGroup]="validateChangePassword">
            <nz-form-item>
                <nz-form-label [nzSpan]="9" nzRequired>Current Password</nz-form-label>
                <nz-form-control [nzSpan]="15" nzHasFeedback [nzErrorTip]="passwordErrorTpl" nzValidatingTip="Validating...">
                    <input nz-input placeholder="Please enter your current password" type="password" formControlName="oldPassword" />
                    <ng-template #passwordErrorTpl let-control>
                        <span *ngIf="control.errors?.['required']">Please confirm your password!</span>
                        <span *ngIf="control.errors?.['duplicated']">Password is inconsistent!</span>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="9" nzRequired>New Password</nz-form-label>
                <nz-form-control [nzSpan]="15" nzHasFeedback>
                    <input nz-input placeholder="Please enter your new password" type="password" formControlName="newPassword" (ngModelChange)="validateConfirmPassword(validateChangePassword)"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="9" nzFor="confirm" nzRequired>Confirm New Password</nz-form-label>
                <nz-form-control [nzSpan]="15" nzHasFeedback [nzErrorTip]="passwordChangeErrorTpl">
                <input nz-input type="password" formControlName="confirm" placeholder="confirm your new password" />
                    <ng-template #passwordChangeErrorTpl let-control>
                        <span *ngIf="control.errors?.['required']">Please confirm your new password!</span>
                        <span *ngIf="control.errors?.['confirm']">Password is inconsistent!</span>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
        </form>
    </ng-container>
    <ng-template #modalTitle>
        <span *ngIf="this.gpHasPassword">Please input GP Protection Password!</span>
        <span *ngIf="!this.gpHasPassword">Please register GP Protection Password!</span>
    </ng-template>
    <div *nzModalFooter>
        <button nz-button nzType="default" (click)="changePasswordModal = false">Cancel</button>
        <button nz-button nzType="primary" (click)="changePassword()" [disabled]="!validateChangePassword.valid">Submit</button>
      </div>
</nz-modal>