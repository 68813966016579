import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private videoUrlSource = new BehaviorSubject<string>('');
  private titleSource = new BehaviorSubject<string>('');
  private isVisibleSource = new BehaviorSubject<boolean>(false);

  currentVideoUrl = this.videoUrlSource.asObservable();
  currentTitle = this.titleSource.asObservable();
  currentVisibility = this.isVisibleSource.asObservable();

constructor() { }
 

openVideoModal(videoUrl: string, title: string): void {
  this.videoUrlSource.next(videoUrl);
  this.titleSource.next(title);
  this.isVisibleSource.next(true);  // Make the modal visible
}

closeModal(): void {
  this.isVisibleSource.next(false); // Hide the modal
}

}
