import { Purchase } from "./purchase"
import { Sale } from "./sale"

export class TableOrder {
    title:string
    period:string
    purchases: Purchase[]=[]
    sales: Sale[]=[]
}

