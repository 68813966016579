export class SocketMessage {
    type: SocketMessageType
    message: string
    value: string
    action1Url: string | null
    action1Text: string | null
}

export enum SocketMessageType {
    BlockingPopup = 1,
    PopUp = 2,
    SilentMessage = 3,
    SilentNotification = 4,
    SilentRefreshInbox = 5,
    ReadInboxMessage = 6,
    ForceLogout = 7,
    LeadsCount = 8,
    SalesCount = 9,
    SilentRefreshWhatsApp = 10,
    LoadPopUpMessage = 11,
    UpdateLoadBalancing = 12
}