import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { AddCustomerResponse, AddSubCustomer, customerobj, SubCustomer, NewCustomer, Communities, CustomerDetails } from '../models/customer';
import { SearchService } from '../search/search.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  apiUrl = 'v1/customers/';

  v2ApiUrl = 'v2/customers/';

  constructor(private http: HttpClient, private searchService: SearchService) { }


  public getCustomerData(obj: customerobj)
  {
    return this.http.post( environment.ApiUrl() + this.apiUrl + "getCustomerData",  obj);
  }

  public AddCustomer(customer)
  {
    return this.http.post<AddCustomerResponse>(environment.ApiUrl() + this.apiUrl + "addCustomerData", customer)
    .pipe(
      tap(() => {this.searchService.refreshSearchData()}) //tap when complete to refresh the local search data with new customer details
    );
  }

  public updateCustomer(customer)
  {
    return this.http.post(environment.ApiUrl() + this.apiUrl + "updateCustomerData", customer)
    .pipe(
      tap(() => {this.searchService.refreshSearchData()}) //tap when complete to refresh the local search data with new customer details
    );
  }

  public updateVehicle(vehicle)
  {
    return this.http.post(environment.ApiUrl() + this.apiUrl + "updateVehicle", vehicle);
  }

  public getCustomerList(cell: string)
  {
    return this.http.get( environment.ApiUrl() + this.apiUrl + `getCustomerDetailsWhatsApp/${cell}`);
  }

  public getSubCustomers(customerId: number)
  {
    return this.http.get<SubCustomer[]>( environment.ApiUrl() + this.v2ApiUrl + 'subcustomers/'+customerId);
  }

  public createCustomerAndAddSubCustomer(newCustomer: NewCustomer) {
    return this.http.post<SubCustomer[]>(environment.ApiUrl() + this.v2ApiUrl + 'createCustomerAndSub', newCustomer);
  }


  public addSubCustomer(data: AddSubCustomer)
  {
    return this.http.post<SubCustomer[]>(environment.ApiUrl() + this.v2ApiUrl + 'addSubCustomer', data);
  }

  public removeSubCustomer(customerId: Number)
  {
    return this.http.post(environment.ApiUrl() + this.v2ApiUrl + 'removeSubCustomer', {customerId})
  }

  public getCommunities()
  {
    return this.http.get<Communities[]>( environment.ApiUrl() + this.apiUrl + 'communities');
  }

  public changeCommunity(data: CustomerDetails)
  {
    return this.http.post<number>(environment.ApiUrl() + this.apiUrl + 'changeCustomerCommunity', data);
  }
}
