<nz-modal [(nzVisible)]="clientMappingService.modalVisibility" nzCentered="true" nzClosable="true" (nzMask)="true" 
nzTitle="Mapping" (nzOnCancel)="handleClose()" [nzFooter]="footerTemplate" [nzWidth]="1400" >
<ng-container *nzModalContent >   
  <nz-spin *ngIf="isMapping" nzTip="Mapping..." style="z-index: 1;"></nz-spin>
   <div nz-row nzJustify="space-around">

        <div nz-col nzSpan="10" nz-flex nzJustify="start" nzAlign="center">
            <h3 class="text-center">Selected</h3>
            <div nz-col nzJustify="space-between"> <p>Description:</p> <h5>{{clientMappingService.unmappedStockItem.description}}</h5></div>
            <div nz-col nzJustify="space-between"> <p>Stock Code:</p> <h5>{{clientMappingService.unmappedStockItem.stockCode}}</h5></div>
            <div nz-col nzJustify="space-between"> <p>Stock on Hand: </p> <h5>{{clientMappingService.unmappedStockItem.soh}}</h5></div>

        </div>
        <div nz-col nzSpan="12">
            <h3 class="text-center">Map</h3>

            <nz-tabset>
              <nz-tab nzTitle="Tyre" (nzSelect)="setTab('Tyre')">
            <nz-select 
                style="width: 100%;" 
                [(ngModel)]="selectedMap" 
                (ngModelChange)="setSelectedMap($event)" 
                nzPlaceHolder="Search for a stock item" 
                [nzShowArrow]="false"        
                nzShowSearch
                nzAllowClear="true"
                [nzNotFoundContent]="noStockResults"   
                [nzLoading]="isLoadingActiveStock"
                (nzOnSearch)="searchActiveStock($event)"
              > 
                <nz-option *ngIf="isLoadingActiveStock" nzCustomContent>
                  <span nz-icon nzType="loading" class="loading-icon"></span>
                  Loading Data...
                </nz-option>
                <ng-container *ngIf="!isLoadingActiveStock">
                  <nz-option *ngFor="let item of activeStock"
                    [nzValue]="item" 
                    [nzLabel]="item.genCode+' '+item.brand+' '+item.description+' ('+item.stockCode+')'">
                  </nz-option>
                </ng-container>
              </nz-select>

              <ng-template #noStockResults style="text-align: center;">
                <div nz-row>
                  <div nz-col nzSpan="24" class="text-center">
                    <div style="text-align: center;" *ngIf="filteredStock.length === 0 && searchedTerm.length > 2; else continueTyping">
                      <span> No results found </span><br /><br />
                      <button nz-button [nzType]="'primary'" class="btn" *ngIf="authService.hasClaims(['NMSFI'])" (click)="openNMSFAdd()">
                        <i nz-icon nzType="plus" nzTheme="outline"  ></i>
                        Add To NMSF
                    </button>
                    </div>
                    <ng-template #continueTyping>
                      <div style="text-align: center;">
                        <span> Continue typing to search... </span><br /><br />
                      </div>
                    </ng-template>
                  </div>
                </div>
              </ng-template>

              <nz-divider></nz-divider>
                <div nz-row nzJustify="space-around">
                  <button nz-button nzType="primary" disabled="{{!selectedMap || isMapping}}" (click)="selectedMap && mapTyreStock(clientMappingService.unmappedStockItem, selectedMap.msfid, 1, 5, false, true)">Map & Next</button>
                  <button nz-button nzType="primary" disabled="{{!selectedMap || isMapping}}" (click)="selectedMap && mapTyreStock(clientMappingService.unmappedStockItem, selectedMap.msfid, 1, 5, true, false)">Map & Close</button>
                  <button nz-button nzType="primary" [disabled]="!isThereNextTyre(clientMappingService.unmappedStockItem)" (click)="nextTyre(clientMappingService.unmappedStockItem)">Skip</button>
                </div> 
              </nz-tab>

              <nz-tab nzTitle="Custom Stock" (nzSelect)="setTab('custom')">
                <p>Custom Mappings for {{authService.user.client_name}}</p>
                <nz-select 
                  #customStockSelect
                  style="width: 100%;" 
                  [nzShowArrow]="false" 
                  nzPlaceHolder="Search for a custom stock item" 
                  nzShowSearch
                  [(ngModel)]="selectedCustomMap" 
                  (ngModelChange)="setSelectedMap($event)"
                  [nzNotFoundContent]="noCustomStockResults"
                > 
                  <nz-option *ngIf="adminMappingService.isLoadingActiveCustomStock" nzCustomContent>
                    <span nz-icon nzType="loading" class="loading-icon"></span>
                    Loading Data...
                  </nz-option>
                  <ng-container *ngIf="!adminMappingService.isLoadingActiveCustomStock">
                  <nz-option *ngFor="let e of adminMappingService.activeCustomStock"
                    [nzValue]="e" 
                    [nzLabel]="e.stockType+' ' +e.description+' ('+e.stockCode+')'">
                  </nz-option>
                </ng-container>
                </nz-select>

                <ng-template #noCustomStockResults style="text-align: center;">
                  <div nz-row>
                    <div nz-col nzSpan="24" class="text-center">
                      <div style="text-align: center;" *ngIf="activeStock.length === 0; else continueTyping">
                        <span> No results found </span><br /><br />
                      </div>
                      <div style="margin: 10px; padding: 10px;">
                        <!-- Divider Above the Form -->
                        <nz-divider [nzText]="'Custom Stock Mapping'"></nz-divider>
                          <!-- Stock Code Field -->
                            <div nz-row [nzGutter]="24" nzJustify="start" nzAlign="middle" >
                              
                                  <nz-input-group nzAddOnBefore="Stock Code" style="margin-bottom: 10px;" >
                                    <input nz-input [(ngModel)]="customStockCode"  style="width: 100%; height: 40px;" [ngModel]="clientMappingService.unmappedStockItem.stockCode" />
                                  </nz-input-group>

                          <!-- Stock Type Field -->
                              
                                  <nz-input-group nzAddOnBefore="Stock Type" style="margin-bottom: 10px;">
                                    <nz-select style="width: 100%;" nzPlaceHolder="Type" nzShowSearch [(ngModel)]="customStockType">
                                      <nz-option *ngFor="let type of clientMappingService.customStockTypes" [nzValue]="type" [nzLabel]="type.name"></nz-option>
                                    </nz-select>
                                  </nz-input-group>
                                
                          <!-- Description Field -->
                                  <nz-input-group nzAddOnBefore="Description" style="margin-bottom: 10px;">
                                    <input nz-input [(ngModel)]="customDescription" style="width: 100%; height: 40px;" [ngModel]="clientMappingService.unmappedStockItem.description"  />
                                  </nz-input-group>

                          <!-- Add Item Button -->
                                <button nz-button nzType="primary"  style="width: 100%; height: 40px;" (click)="addCustomStockMapping(customStockCode, customDescription, customStockType, clientMappingService.unmappedStockItem)">
                                  Add New Custom Stock Item
                                </button>
                              
                          </div>
                        
                      </div>

                      <ng-template #continueTyping>
                        <div style="text-align: center;">
                          <span> Continue typing to search... </span><br /><br />
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </ng-template>

                <nz-divider></nz-divider>
                <div nz-row nzJustify="space-around">
                  <button nz-button nzType="primary" disabled="{{!selectedCustomMap || isMapping}}" (click)="selectedCustomMap && mapCustomStock(clientMappingService.unmappedStockItem, selectedCustomMap.msfid, 1, 5, false, true)">Map & Next</button>
                  <button nz-button nzType="primary" disabled="{{!selectedCustomMap || isMapping}}" (click)="selectedCustomMap && mapCustomStock(clientMappingService.unmappedStockItem, selectedCustomMap.msfid, 1, 5, true, false)">Map & Close</button>
                  <button nz-button nzType="primary" [disabled]="!isThereNextTyre(clientMappingService.unmappedStockItem)" (click)="nextTyre(clientMappingService.unmappedStockItem)">Skip</button>
                </div> 
              </nz-tab>

              <nz-tab nzTitle="Trade In" >
                <div nz-row style="padding-bottom: 16px;"> 
              <nz-select 
              style="width: 100%;" 
              [(ngModel)]="selectedMap" 
              (ngModelChange)="setSelectedMap($event)" 
              nzPlaceHolder="Search for main stock item" 
              [nzShowArrow]="false"        
              nzShowSearch
              nzAllowClear="true"
              [nzNotFoundContent]="noStockResults"   
              [nzLoading]="isLoadingActiveStock"
              (nzOnSearch)="searchActiveStock($event)"
            > 
              <nz-option *ngIf="isLoadingActiveStock" nzCustomContent>
                <span nz-icon nzType="loading" class="loading-icon"></span>
                Loading Data...
              </nz-option>
              <ng-container *ngIf="!isLoadingActiveStock">
                <nz-option *ngFor="let item of activeStock"
                  [nzValue]="item" 
                  [nzLabel]="item.genCode+' '+item.brand+' '+item.description+' ('+item.stockCode+')'">
                </nz-option>
              </ng-container>
            </nz-select>
      
            <ng-template #noStockResults style="text-align: center;">
              <div nz-row>
                <div nz-col nzSpan="24" class="text-center">
                  <div style="text-align: center;" *ngIf="filteredStock.length === 0 && searchedTerm.length > 2; else continueTyping">
                    <span> No results found </span><br /><br />
                    <button nz-button [nzType]="'primary'" class="btn" *ngIf="authService.hasClaims(['NMSFI'])" (click)="openNMSFAdd()">
                      <i nz-icon nzType="plus" nzTheme="outline"  ></i>
                      Add To NMSF
                  </button>
                  </div>
                  <ng-template #continueTyping>
                    <div style="text-align: center;">
                      <span> Continue typing to search... </span><br /><br />
                    </div>
                  </ng-template>
                </div>
              </div>
            </ng-template>
          </div>
          <div nz-row>
            <nz-select
            style="width: 100%;" 
            nzPlaceHolder="Select used stock classification"
            [(ngModel)]="selectedUsedStockClassificationId"
            (ngModelChange)="setSelectedUsedStockClassification($event)">
              <nz-option *ngIf="isLoadingActiveStock" nzCustomContent>
                <span nz-icon nzType="loading" class="loading-icon"></span>
                Loading Data...
              </nz-option>
              <ng-container *ngIf="!isLoadingActiveStock">
                <nz-option *ngFor="let item of usedStockClassification"
                  [nzValue]="item.classificationId" 
                  [nzLabel]="item.classification">
                </nz-option>
              </ng-container>
            </nz-select>
          </div>
              <nz-divider></nz-divider>
              <div nz-row nzJustify="space-around">
                <button nz-button nzType="primary" disabled="{{!selectedMap || isMapping || !selectedUsedStockClassificationId || !isThereNextTyre(clientMappingService.unmappedStockItem)}}" (click)="selectedMap && selectedUsedStockClassificationId && mapUsedStockItem(clientMappingService.unmappedStockItem, selectedMap.msfid, selectedUsedStockClassificationId , false, true)">Map & Next</button>
                <button nz-button nzType="primary" disabled="{{!selectedMap || isMapping || !selectedUsedStockClassificationId }}" (click)="selectedMap && selectedUsedStockClassificationId && mapUsedStockItem(clientMappingService.unmappedStockItem, selectedMap.msfid, selectedUsedStockClassificationId, false, true)">Map & Close</button>
                  <button nz-button nzType="primary" [disabled]="!isThereNextTyre(clientMappingService.unmappedStockItem)" (click)="nextTyre(clientMappingService.unmappedStockItem)">Skip</button>
                
              </div>
            </nz-tab>
            </nz-tabset>

        </div>

    </div>
</ng-container>
    <ng-template #footerTemplate>
        <button nz-button nzType="primary" (click)="handleClose()">Close</button>
      </ng-template>

      <nz-modal [nzVisible]="isNmsfAddOpen" [nzTitle]="mappingHeader" [nzFooter]="null" (nzOnCancel)="isNmsfAddOpen = false;" nzWidth="800px">
        <div *nzModalContent>
            <app-stock-add-nmsf (dismissModal)="isNmsfAddOpen = false; clientMappingService.modalVisibility=false " (stockCode)="this.clientStockMappingComponent.refreshUnmappedTable(clientMappingService.unmappedStockItem)" [stockItem]="itemToAdd" ></app-stock-add-nmsf>
        </div>
        <ng-template #mappingHeader>
            <span>Mapping Item: {{itemToAdd.description}}  - Code: </span>
            <span class="color-primary">{{itemToAdd.stock_code}}</span>
        </ng-template>
      </nz-modal>
</nz-modal>
      