<span style="float:left" *ngIf="backToCustomerId">
    <button nz-button (click)="navigateBackToCustomer()"><span nz-icon nzType="left" nzTheme="outline"></span>BACK</button>
</span>
<div nz-row *ngIf="quote.specialPriceSetName">
    <div nz-col nzXs="24" nzSm="20" nzMd="16" nzLg="12" nzXl="8" nzXXl="6" style="margin-bottom: 10px;">
        <nz-alert nzType="warning" nzMessage="Fleet Pricing Active: {{quote.specialPriceSetName}}" nzShowIcon></nz-alert>
    </div>
</div>

<div nz-row class="text-div">
    
    <div nz-col nzXs="24" nzSm="8" nzMd="8" nzLg="8" nzXl="8" nzXXl="8" class="text-center">
        <span class="text-style">Quote :</span> {{quote.quoteRef}}
    </div>
    <div nz-col nzXs="24" nzSm="8" nzMd="8" nzLg="8" nzXl="8" nzXXl="8" class="text-center">
        <span class="text-style">Date :</span> {{quote.dt_processed | date}}
    </div>
    <div nz-col nzXs="24" nzSm="8" nzMd="8" nzLg="8" nzXl="8" nzXXl="8" class="text-center">
        <span class="text-style">Salesman : </span>
        <span *ngIf="!authService.hasClaims(['SDRP'])">{{quote.userFullname}} {{ quote.userCell }}</span>
        <nz-select [disabled]="quote.isCompleted" *ngIf="authService.hasClaims(['SDRP'])" (ngModelChange)="changeSalesman($event)" style="width: 50%;" nzPlaceHolder="Select Salesman" [(ngModel)]="assignedSalesman">
            <nz-option *ngFor="let user of salesmen" [nzValue]="user.userId" [nzLabel]="user.fullname + ' - ' + user.cell"></nz-option>
        </nz-select>
    </div>
</div>

<div nz-row>
    <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="9" nzXXl="9">
        <nz-divider nzText="Customer"></nz-divider>
        <div nz-row nzJustify="center">
            <div nz-col nzXs="22" nzSm="22" nzMd="22" nzLg="22" nzXl="22" nzXXl="22">
                <app-pos-customer-details [(quote)]="quote" [isLoading]="isLoading"></app-pos-customer-details>
            </div>
        </div>
    </div>
    <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="9" nzXXl="9">
        <nz-divider nzText="Vehicle"></nz-divider>
        <div nz-row nzJustify="center">
            <div nz-col nzXs="22" nzSm="22" nzMd="22" nzLg="22" nzXl="22" nzXXl="22">
                <app-pos-vehicle-details [(quote)]="quote" [isLoading]="isLoading" (onVehicleSave)="saveVehicle($event)" [setVehicleId]="externalVehicleId"></app-pos-vehicle-details>
            </div>
        </div>
    </div>
    <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="6" nzXXl="6">
        <nz-divider style="margin-top: 29px;margin-bottom: 27px;"></nz-divider>
        <app-pos-actions [(quote)]="quote" [isLoading]="isLoading" (statusChange)="showStatusModal($event)" (addItems)="addItemsToQuote($event)"></app-pos-actions>
    </div>

</div>
<!-- <nz-tabset nzCentered>
    <nz-tab *ngFor="let tab of this.tableOrder" nzTitle="{{tab.name}}"> -->
<app-quote-lines [quote]="quote" [isLoading]="isLoading" (addItems)="addItemsToQuote($event)"></app-quote-lines>
<!-- </nz-tab>
</nz-tabset> -->

<!-- Bottom row of strip buttons -->
<div nz-row nzJustify="center" style="padding-top: 30px;" *ngIf="this.quote.isCompleted == false && this.quote.authType === 0">
    <div nz-col nzXs="24" nzSm="24" nzMd="5" nzLg="5" nzXl="4" nzXXl="4">
        <div *ngIf="this.quote.lines && this.quote.lines.length > 0 && this.quote.isWIP === false">
            <button nz-button nzType="default" class="btn submit float-left" style="height: 40px; font-size: 1rem; padding-bottom: 1rem;" (click)="tentativelyAccept()" [disabled]="!this.quote.hasCustomer" [nz-tooltip]="!this.quote.hasCustomer ? 'Select Customer Details First!' : ''"
                [nzLoading]="quote.isDirty" *ngIf="!authService.hasClaims(['WIP'])">
                <i nz-icon nzType="clock-circle" nzTheme="fill"
                    style="font-size: 1.5rem; vertical-align: middle; margin-top: -6px;"></i> Tentatively Accept
            </button>
            <button nz-button nzType="default" *ngIf="this.authService.user.bookingsEnabled === true" class="btn submit float-left" style="height: 40px; font-size: 1rem; margin-left: 1rem; margin-bottom: 1rem;" (click)="onBookingClick()" [disabled]="!checkCanClickComplete()"
                [nz-tooltip]="getCompleteButtonTooltip()" [nzLoading]="quote.isDirty" [ngClass]="{'text-info-style' : quote.booking}">
                <i nz-icon nzType="calendar" nzTheme="fill"
                    style="font-size: 1.5rem; vertical-align: middle; margin-top: -6px; "
                    ></i> {{quote.booking ? 'BOOKED: ' + quote.booking.dateBooked + ' ' + quote.booking.timeBooked.toString().padStart(2,"0").substring(0,5) : 'Booking'}}
            </button>
        </div>
        <div *ngIf="this.quote.isWIP">
            <button nz-button nzType="default" class="btn submit float-left" style="height: 40px; font-size: 1rem;  margin-bottom: 1rem;" (click)="cancelJobCard()" [disabled]="!this.quote.hasCustomer || !authService.hasClaims(['CJC'])" [nz-tooltip]="!this.quote.hasCustomer ? 'Select Customer Details First!' : ''"
                [nzLoading]="quote.isDirty" *ngIf="this.quote.isWIP">
                <i nz-icon nzType="close-circle" nzTheme="fill"
                    style="font-size: 1.5rem; vertical-align: middle; margin-top: -6px;"></i> Cancel Jobcard
            </button>
        </div>
        <div *ngIf="this.quote.wbcActive">
            <app-pos-wbc [(quote)]="quote" [isLoading]="isLoading" ></app-pos-wbc>
        </div>
    </div>
    <div nz-col nzXs="24" nzSm="24" nzMd="19" nzLg="19" nzXl="16" nzXXl="16" *ngIf="authService.hasClaims(['WIP']) && authService.user.featureLevel >= 2">
        <div *ngIf="this.quote.lines && this.quote.lines.length > 0 && this.quote.isWIP === false" class="text-center">
            <span class="mobile" style="font-size: 1rem;">Work In Progress:</span>
            <nz-button-group style="padding-inline: 1rem;">
                <button nz-button nzType="default" nzSize="large" class="btn submit text-center" (click)="assignWIP(true)" [disabled]="!checkCanClickComplete()" [nz-tooltip]="getCompleteButtonTooltip()"
                    [nzLoading]="quote.isDirty" [ngClass]="{'text-info-style' : quote.booking}">
                    <i nz-icon nzType="plus-circle" nzTheme="fill"
                        style="font-size: 1.5rem; vertical-align: middle; margin-top: -6px;"
                        ></i> 
                        <span>Reserve</span>&nbsp;<span class="mobile"> Stock</span>
                </button>
                <button nz-button nzType="default" nzSize="large" class="btn submit text-center" (click)="assignWIP(false)" [disabled]="!checkCanClickComplete()" [nz-tooltip]="getCompleteButtonTooltip()"
                    [nzLoading]="quote.isDirty" [ngClass]="{'text-info-style' : quote.booking}">
                    <i nz-icon nzType="minus-circle" nzTheme="fill"
                        style="font-size: 1.5rem; vertical-align: middle; margin-top: -6px;"
                        ></i> 
                        <span>Dont Reserve</span>&nbsp;<span class="mobile"> Stock</span>
                </button>
            </nz-button-group>
        </div>
    </div>
    <div nz-col nzXs="0" nzSm="0" nzMd="16" nzLg="16" nzXl="16" nzXXl="16" *ngIf="!authService.hasClaims(['WIP']) || authService.user.featureLevel < 2">
    </div>
    <div nz-col nzXs="24" nzSm="24" nzMd="4" nzLg="4" nzXl="4" nzXXl="4" *ngIf="(authService.hasClaims(['WIP']) && this.quote.isWIP === true) || (!authService.hasClaims(['WIP']) || (authService.user.featureLevel < 2)); else noSaveTemplate">
        <button nz-button nzType="default" *ngIf="this.quote.lines && this.quote.lines.length > 0" class="btn submit float-right" style="height: 40px; font-size: 1rem;" (click)="completeSale()" [disabled]="!checkCanClickComplete()" [nz-tooltip]="getCompleteButtonTooltip()"
            [nzLoading]="quote.isDirty">
            <i nz-icon nzType="check-circle" nzTheme="fill" 
                style="font-size: 1.5rem; vertical-align: middle; margin-top: -6px;"></i><span *ngIf="!this.quote.isWIP || authService.user.featureLevel < 3">Complete Sale</span><span *ngIf="this.quote.isWIP && authService.user.featureLevel === 3">Invoice Customer</span>
        </button>
    </div>
    <ng-template #noSaveTemplate>
        <div nz-col nzXs="0" nzSm="0" nzMd="4" nzLg="4" nzXl="4" nzXXl="4"></div>
    </ng-template>
</div>
<!-- Row of buttons if quote is completed -->
<div nz-row nzJustify="center" *ngIf="this.quote.isCompleted">
    <div nz-col nzXs="24" nzSm="24" nzMd="5" nzLg="5" nzXl="4" nzXXl="4">
        <nz-button-group>
            <button nz-button nzType="default" class="btn submit" style="height: 40px; font-size: 1rem;" (click)="onReactivateClick()" [nz-tooltip]="'Reactivate this quote'"
                [nzLoading]="isLoading">
                <i nz-icon nzType="check-circle" nzTheme="fill" 
                    style="font-size: 1.5rem; vertical-align: middle; margin-top: -6px;"></i>Reactivate
            </button>
            <button nz-button nzType="default" class="btn submit" style="height: 40px; font-size: 1rem;" (click)="onRequoteClick()" [nz-tooltip]="'Requote this quote'"
                [nzLoading]="isLoading">
                <i nz-icon nzType="check-circle" nzTheme="fill" 
                    style="font-size: 1.5rem; vertical-align: middle; margin-top: -6px;"></i>Requote
            </button>
        </nz-button-group>
    </div>
</div>
<!-- Fleet Buttons -->
<div nz-row nzJustify="center" *ngIf="!this.quote.isCompleted && this.quote.authType === 1">
    <div nz-col nzXs="24" nzSm="24" nzMd="5" nzLg="5" nzXl="4" nzXXl="4">
        <nz-button-group>
            <button nz-button nzType="default" class="btn submit" style="height: 40px; font-size: 1rem;" (click)="showSBAuth()" [nz-tooltip]="'Send for Authorisation'"
                [nzLoading]="isLoading">
                <i nz-icon nzType="check-circle" nzTheme="fill" 
                    style="font-size: 1.5rem; vertical-align: middle; margin-top: -6px;"></i>Send for Authorisation
            </button>
        </nz-button-group>
    </div>
</div>

<!-- MODALS -->


<!-- Nav Away Modal -->

<nz-modal [(nzVisible)]="isNavModalVisible" nzTitle="Update Status" (nzOnCancel)="isNavModalVisible = false; navPathAway='';" [nzFooter]="null" nzWidth="800px">
    <div *nzModalContent>

        <!--Classification-->
        <ng-container *ngIf="this.quote.leadChannel || this.quote.leadType then classification"></ng-container>
        <div nz-row [nzGutter]="5" [nzJustify]="'center'">
            <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12" style="text-align: center;">
                <br>
                <ng-container *ngIf="this.quote.leadChannel || this.quote.leadType then check else allow"></ng-container>
                <ng-template #check>
                    <button  nz-button 
                             nzType="primary" 
                             (click)="navigateWithoutSaving()" 
                             [disabled]="(this.quote.leadChannel && this.quote.selectedChannel == null) || (this.quote.leadType && this.quote.selectedType == null)">
                        <i nz-icon nzType="left-circle" nzTheme="fill"></i>
                        Just Checking,Continue
                    </button>
                </ng-template>
                <ng-template #allow>
                    <button nz-button nzType="primary" (click)="navigateWithoutSaving()">
                        <i nz-icon nzType="left-circle" nzTheme="fill"></i>
                        Just Checking,Continue
                    </button>
                </ng-template>
            </div>
        </div>

        <!--Divider-->
        <nz-divider *ngIf="this.quote.leadChannel || this.quote.leadType"></nz-divider>

        <!--Follow up & Closing-->
        <div nz-row [nzJustify]="'center'">
            <div nz-col nzSpan="24" style="text-align: center;">
                <div nz-row [nzGutter]="15" [nzJustify]="'center'">
                    <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12" style="text-align: center;" *ngIf="!this.quote.isWIP">
                        <!-- FOLLOW UP -->
                        <br>
                        <nz-card  nzTitle="FOLLOW UP LATER" class="nav-card" >
                            <div nz-row>
                                <nz-input-group [nzAddOnAfter]="addOnAfterTemplate">
                                    <textarea [(ngModel)]="statusComment" nz-input placeholder="Add Comment" [maxLength]="450" rows="2"
                                    [disabled]="(this.quote.leadChannel && this.quote.selectedChannel == null) || (this.quote.leadType && this.quote.selectedType == null)"></textarea>
                                </nz-input-group>
                                <ng-template #addOnAfterTemplate>
                                    <span style="color: grey;"><small>{{statusComment.length}}/450</small></span>
                                </ng-template>
                            </div>  
                            <br>
                            <div nz-row>
                                <div nz-col nzSpan="24">
                                    <nz-select [ngModel]="followUpSelect" nzPlaceHolder="Select Follow Up Time" style="width: 60%;" (ngModelChange)="followUpChange($event)"
                                    [disabled]="(this.quote.leadChannel && this.quote.selectedChannel == null) || (this.quote.leadType && this.quote.selectedType == null)">                          
                                        <nz-option [nzValue]="1" nzLabel="In 15 mins"></nz-option>
                                        <nz-option [nzValue]="2" nzLabel="In 1 hour"></nz-option>
                                        <nz-option [nzValue]="3" nzLabel="In 2 hours"></nz-option>
                                        <nz-option [nzValue]="4" nzLabel="Lunch Time"  [nzDisabled]="checkLunchDisabled()"></nz-option>
                                        <nz-option [nzValue]="5" nzLabel="Tomorrow" ></nz-option>
                                        <nz-option [nzValue]="6" nzLabel="In 2 days"></nz-option>
                                        <nz-option [nzValue]="7" nzLabel="Calendar"></nz-option>
                                    </nz-select>
                                </div>                 
                            </div>
                            <br>
                            <div nz-row>
                                <div nz-col nzSpan="24" *ngIf="followUpSelect === 7">
                                    Follow Up On: <br>
                                    <nz-date-picker [nzDisabledDate]="disabledDate" nzFormat="yyyy-MM-dd HH:mm" nzAllowClear="false" [(ngModel)]="followUpDate" nzPlaceHolder="Select Date" #followUpDatePicker (ngModelChange)="updateQuoteStateWithAction(PosAuditEntryType.FollowUpLater)"
                                        class="txtareaDatePicker" [disabled]="(this.quote.leadChannel && this.quote.selectedChannel == null) || (this.quote.leadType && this.quote.selectedType == null)">
                                    </nz-date-picker>
                                </div>
                            </div>
                        </nz-card>
                    </div>
                    <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12" style="text-align: center;">
                        <!-- CUSTOMER ACTIONS -->
                        <br>
                        <nz-card  nzTitle="CLOSE LEAD" class="nav-card">
                            <div nz-row>
                                <div nz-col nzSpan="24">
                                    <nz-select [ngModel]="leadSelect" nzPlaceHolder="Select Reason " style="width: 60%;" (ngModelChange)="closeLead($event)"
                                    [disabled]="(this.quote.leadChannel && this.quote.selectedChannel == null) || (this.quote.leadType && this.quote.selectedType == null)"> 
                                        <nz-option [nzValue]="1" nzLabel="Bought From Competitor"></nz-option>      
                                        <nz-option [nzValue]="2" nzLabel="Customer Not Reachable"></nz-option>
                                        <nz-option [nzValue]="3" nzLabel="Price Checking"></nz-option>
                                        <nz-option [nzValue]="4" nzLabel="Commited to Buy"></nz-option>
                                        <nz-option [nzValue]="5" nzLabel="Other"></nz-option>
                                    </nz-select>
                                </div>                 
                            </div>
                            <br>
                            <div nz-row [nzJustify]="'center'" *ngIf="leadSelect === 5">
                                <div nz-col nzSpan="24">
                                    OTHER:<br>
                                    <nz-input-group [nzAddOnAfter]="addOnAfterTemplate">
                                        <textarea [(ngModel)]="statusComment" nz-input placeholder="Add Comment" [maxLength]="450" rows="2"
                                        [disabled]="(this.quote.leadChannel && this.quote.selectedChannel == null) || (this.quote.leadType && this.quote.selectedType == null)"></textarea>
                                    </nz-input-group>
                                    <ng-template #addOnAfterTemplate>
                                        <span style="color: grey;"><small>{{statusComment.length}}/450</small></span>
                                    </ng-template>
                                </div>
                            </div>
                            <br>
                            <div nz-row [nzJustify]="'center'">
                                <div nz-col nzSpan="24">
                                    <button nz-button nzType="primary" (click)="updateQuoteStateWithAction(PosAuditEntryType.ClosedOther)"
                                    [disabled]="(this.quote.leadChannel && this.quote.selectedChannel == null) || (this.quote.leadType && this.quote.selectedType == null)">
                                        <i nz-icon nzType="save" nzTheme="fill"
                                            ></i>Save
                                    </button>
                                </div>
                            </div>
                        </nz-card>
                    </div>
                </div>
            </div>
        </div>

    </div>
</nz-modal>

<!-- LEad Classification Modal -->

<nz-modal [nzVisible]="isLeadClassificationModalVisible" nzTitle="Classify Lead" nzOkText="Continue" (nzOnOk)="executeMethodToRunWhenLeadClassified()" (nzOnCancel)="isLeadClassificationModalVisible = false; navPathAway='';"  nzWidth="800px"
[nzOkLoading]="quote.isDirty">
    <div *nzModalContent>
        <ng-container *ngIf="this.quote.leadChannel || this.quote.leadType then classification"></ng-container>
    </div>
</nz-modal>

<ng-template #classification>
     <!--Classification Section-->
     <div nz-row [nzGutter]="5" [nzJustify]="'center'">
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12" style="text-align: center;" *ngIf="this.quote.leadChannel">
            <label><b>Lead Channel</b></label><br>
            <nz-select [nzDisabled]="quote.selectedChannel === 3" nzread (ngModelChange)="classifyLead($event,1)" [(ngModel)]="this.quote.selectedChannel" nzPlaceHolder="Select Lead Channel" style="width: 60%;" [nzStatus]="this.quote.selectedChannel == null ? 'error' : ''">
                <nz-option [nzValue]="1" nzLabel="Walk-in"></nz-option>
                <nz-option [nzValue]="2" nzLabel="Telephonic"></nz-option>
                <nz-option [nzValue]="3" nzLabel="Online" *ngIf="this.quote.selectedChannel == 3"></nz-option>
            </nz-select>
            <p class="text-danger" *ngIf="this.quote.selectedChannel == null "><i>Lead channel required</i></p>
        </div>
        <div  nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12" style="text-align: center;" *ngIf="this.quote.leadType">
            <label><b>Lead Type</b></label><br>
            <nz-select (ngModelChange)="classifyLead($event,2)" [(ngModel)]="this.quote.selectedType" nzPlaceHolder="Select Lead Type" style="width: 60%;" [nzStatus]="this.quote.selectedType == null ? 'error' : ''">
                <nz-option [nzValue]="1" nzLabel="Budget "></nz-option>
                <nz-option [nzValue]="2" nzLabel="Value"></nz-option>
                <nz-option [nzValue]="3" nzLabel="Premium"></nz-option>
            </nz-select>
            <p class="text-danger" *ngIf="this.quote.selectedType == null "><i>Lead Type required</i></p>
        </div>
    </div>

</ng-template>


<!-- Booking Modal -->
<nz-modal [(nzVisible)]="isBookingModalVisible" nzTitle="Book Fitment Slot" (nzOnCancel)="isBookingModalVisible = false" [nzFooter]="null" nzWidth="700px">

    <div *nzModalContent>
        <div nz-row nzGutter="10" [nzJustify]="'center'">
            <div nz-col nzXs="24" nzSm="24" nzMd="12">
                <div [ngStyle]="{ width: '300px' }">
                    <nz-calendar [nzFullscreen]="false" (nzSelectChange)="onBookingDateSelected($event)"></nz-calendar>
                </div>
            </div>
            <div nz-col nzXs="24" nzSm="24" nzMd="12">
                <nz-spin [nzSpinning]="isLoadingBookingSlots">
                    <div style="margin-bottom: 10px;">Date: {{bookingSelectedDate}}</div>

                    <button nz-button nzType="primary" (click)="onSlotSelected(i)" style="width: 100%; text-align: left; margin-bottom: 5px;" *ngFor="let i of bookingSlots"><span style="text-align: left;">Time: {{i.slotTime.toString().padStart(2,"0").substring(0,5)}}</span><small class="float-right"><i>{{i.bookings}} bookings</i></small></button>

                    <div *ngIf="bookingSlots.length === 0" style="padding-top: 20px; text-align: center;">
                        <i nz-icon nzType="minus-circle" nzTheme="fill" style="color: darkred; font-size: 3rem; padding-bottom: 20px;"></i>

                        <div style="text-align: center;">No Time Slots</div>
                    </div>
                </nz-spin>


            </div>
        </div>
    </div>
</nz-modal>

<!-- FLEET MODALS -->
 <!-- SB Fleet -->
 <nz-modal [(nzVisible)]="isSbAuthModalVisible" nzClosable="true" nzTitle="Please Provide Fleet Details" (nzOnCancel)="isSbAuthModalVisible=false;" (nzOnOk)="onSbAuthSubmit()" [nzFooter]="null">
    <div *nzModalContent>
        <form [formGroup]="sbauthForm" class="text-center">
            <nz-form-item>                
                <nz-form-label nzFor="odometer" [nzSpan]="6">Milage</nz-form-label>
                <nz-form-control nzErrorTip="Enter valid mileage">
                    <input class="inputdiv" maxlength="6" nz-input name="odometer" formControlName="odometer" placeholder="Vehicle Milage" type="number">
                </nz-form-control>                
            </nz-form-item>
            <nz-form-item>
                <nz-form-label nzFor="drivername" [nzSpan]="6">Driver Name</nz-form-label>
                <nz-form-control nzErrorTip="Enter valid Driver Name">
                    <input class="inputdiv" nz-input name="drivername" formControlName="drivername" placeholder="Driver Name">
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label nzFor="drivercell" [nzSpan]="6">Driver Contact No</nz-form-label>
                <nz-form-control nzErrorTip="Enter a valid cell no">
                    <input class="inputdiv" maxlength="10" nz-input name="drivercell" formControlName="drivercell" placeholder="Driver Cell" type="number">
                </nz-form-control>
            </nz-form-item>
        </form>
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-2 offset-5">
                    <button nz-button nzType="default" [nzLoading]="isLoading" (click)="onSbAuthSubmit()"><span nz-icon nzType="cloud-upload" nzTheme="outline"></span> Submit</button>
                </div>
            </div>
        </div>
    </div>
</nz-modal>