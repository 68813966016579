import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { AuthService } from '../auth.service';
import { Video } from '../models/video';

@Injectable({
  providedIn: 'root'
})
export class HelpService {

  apiUrl = 'v1/videos/';

  constructor(private http: HttpClient, private authService: AuthService) { }

  //Get Videos
  public getVideos()
  {
    return this.http.get<Video[]>( environment.ApiUrl() + this.apiUrl + "getvideos");
  }

  public getVideoById(videoId:number)
  {
    return this.http.get<Video>(environment.ApiUrl() + this.apiUrl + "getvideo/" + videoId );
  }

  public updatelog(videoId)
  {
    return this.http.get(environment.ApiUrl() + this.apiUrl + "updatelog/" + videoId);
  }

}
