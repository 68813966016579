<nz-card>
    <div nz-col nzSpan="24">
        <nz-tabset nzCentered="true" nzSize="large">
            <nz-tab nzTitle="Unmapped">
                <div nz-col nzJustify="center" >
                    <div nz-row  nzJustify="center">
                        <div nz-col nzSpan="12" class="search-bar-container" style="margin-bottom: 20px;" >
                            <nz-input-group [nzSuffix]="suffixIconSearch" class="mobile-view" > 
                                <input type="text" nz-input placeholder="Advanced Filter" [(ngModel)]="this.advancedSearchInput" (ngModelChange)="advancedSearch(advancedSearchInput)" style="text-transform:uppercase;" />
                            </nz-input-group>
                            <ng-template #suffixIconSearch>
                                <span nz-icon nzType="search"></span>
                              </ng-template>
                        </div>
                    </div>
                    <div class="table-container">
                      <nz-table #unmappedStockTable [nzData]="this.unmappedStock" [nzFrontPagination]="true" [nzPageSize]="25" [nzLoading]="this.loadingStock">
                          <thead>
                              <tr>
                                <th [nzWidth]="'10%'">Stock Code</th>
                                <th *ngFor="let column of columnList"
                                [nzSortOrder]="column.sortOrder"
                                [nzSortFn]="column.sortFn"
                                [nzSortDirections]="column.sortDirections"
                                [nzWidth]="'10%'">{{column.title}}</th>
                                <th>Description</th>
                                <th [nzWidth]="'50%'"></th>
                              </tr>
                            </thead>
                          <tbody>
                                <tr *ngFor="let item of unmappedStockTable.data" (click)="clientMappingService.openModal(item)" style="cursor: pointer;">
                                  <td>
                                    <i nz-icon nzType="exclamation" nzTheme="outline" class="text-warning-style" *ngIf="item.mapped == 6" nz-tooltip nzTooltipPlacement='left' nzTooltipTitle=' SF Admin unable to Map this Item Automatically'></i>{{item.stockCode}}</td>
                                  <td>{{ item.soh ? item.soh : 'Not Available' }}</td>
                                  <td>{{item.description}}</td>
                                  <td><div class="button-group">
                                      <nz-button-group nzSize="large">
                                          <button
                                          *ngFor="let button of clientMappingService.customStockTypes"
                                          nz-button
                                          nzType="default"
                                          class="button"
                                          (click)="mapCustomStock($event, item, button.msfId, button.id, 5)"
                                        >
                                          {{ button.name }}
                                        </button>
                                      </nz-button-group>
                                    </div></td>
                              </tr>
                          </tbody>
                      </nz-table>
                  </div>
                  <ng-template #Loading>
                    <nz-spin></nz-spin>
                  </ng-template>
                   
                </div>
            </nz-tab>
            <nz-tab nzTitle="Mapped" (nzSelect)="switchToMapped()">
                <div nz-col nzJustify="center" >
                    <div nz-row nzJustify="center">
                        <div nz-col nzSpan="12" class="search-bar-container" style="margin-bottom: 20px;">
                            <nz-input-group [nzSuffix]="suffixIconSearch" class="mobile-view" > 
                                <input type="text" nz-input placeholder="Advanced Filter" [(ngModel)]="this.advancedSearchInputMapped" (ngModelChange)="advancedSearchMapped(this.advancedSearchInputMapped)" style="text-transform:uppercase;" />
                            </nz-input-group>
                            <ng-template #suffixIconSearch>
                                <span nz-icon nzType="search"></span>
                              </ng-template>
                        </div>
                    </div>
                    <div class="table-container">
                        <nz-table #mappedStockTable [nzData]="this.mappedStock" [nzFrontPagination]="true" [nzPageSize]="25" [nzLoading]="this.loadingStock">
                            <thead >
                                <tr>
                                  <th colspan="3" style="border-right: solid 0.01px var(--textcolor);">
                                    <h5>Fitment Center</h5>
                                  </th>
                                  <th colspan="4">
                                    <h5>StockFinder</h5>
                                  </th>
                                </tr>
                                <tr>
                                  <th>Date mapped</th>
                                  <th>Mapped Stock Description</th>
                                  <th style="border-right: solid 0.01px var(--textcolor);">Stock Code</th>
                                  <th>msfId</th>
                                  <th>MSF Description</th>
                                  <th>Stock Code</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let item of mappedStockTable.data">
                                  <td>{{ item.date | date: 'dd/MM/yyyy' }}</td>
                                  <td>{{ item.locationStockDescription }}</td>
                                  <td>{{ item.locationStockCode }}</td>
                                  <td>{{ item.msfId }}</td>
                                  <td>{{ item.msfDescription }}</td>
                                  <td>{{ item.msfStockCode }}</td>
                                  <td>
                                    <button nz-button nzType="default" nzDanger (click)="deleteMapping(item)" nz-tooltip nzTooltipTitle="Remove Mapping"><span nz-icon nzType="delete" ></span></button>
                                    <nz-popconfirm nzTitle="Are you sure to delete this mapping?" (nzOnConfirm)="deleteMapping(item)" nzOkText="Yes" nzCancelText="No">
                                      <a nz-popconfirm>Yes</a>
                                    </nz-popconfirm>
                                  </td>
                                </tr>
                              </tbody>
                        </nz-table>
                    </div>
                </div>
            </nz-tab>    
        </nz-tabset>
    </div>
</nz-card>
<app-client-mappings-modal></app-client-mappings-modal>