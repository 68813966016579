import { Component, OnInit } from '@angular/core';
import { InvoiceCommunication } from 'src/app/models/communication';
import { SendReportService } from './send-report.service';
import { AuthService } from 'src/app/auth.service';
import { AppComponent } from 'src/app/app.component';
import { LogService } from 'src/app/shared/logs.services';
import { LogAudit, LogAuditType } from 'src/app/models/logs';
import { ReportsService } from 'src/app/shared/reports.service';

@Component({
  selector: 'app-send-report',
  templateUrl: './send-report.component.html',
  styleUrls: ['./send-report.component.less']
})
export class SendReportComponent implements OnInit {

  constructor(  public sendReportService : SendReportService, 
                public authService: AuthService, 
                private reportService: ReportsService, 
                private logService: LogService) { }

  ngOnInit(): void {

  }

  handleCancel() {
    this.sendReportService.removeReport();
  }

  onClickPrint() {
    this.sendReportService.communicationReport?.print();
    this.logService.addAction(new LogAudit(LogAuditType.PrintQuote, this.authService.user.user_id));
  }

  onClickDownload() {
    this.sendReportService.communicationReport?.download();
  }

  onClickPreview() {
    this.sendReportService.communicationReport?.openPreview();
  }

  onClickEmail() {
    // if (this.sendReportService.communicationReport) {
    //   this.sendReportService.communicationReport.email = '';
    // }
    this.sendReportService.communicationReport?.sendEmail();
    this.logService.addAction(new LogAudit(LogAuditType.EmailQuote, this.authService.user.user_id));
  }

  onClickSMS() {

  }

  onClickWhatsApp() {
    this.sendReportService.communicationReport?.sendWhatsApp();
  }

  // debug() {
  //   let rpt = new InvoiceCommunication('SF1234',123);
  //   this.sendReportService.assignReport(rpt);
  // }

}
