import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { InsightsService } from '../insights.service';

@Component({
  selector: 'app-sales-insights',
  templateUrl: './sales-insights.component.html',
  styleUrls: ['./sales-insights.component.less']
})
export class SalesInsightsComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  private salesInsightsService: InsightsService;
  isLoading = true;
  
  // Global variables
  table = [];

  custSortFn = (a: string[], b: string[]): number => a[0] < b[0] ? -1 : 1;
  thisSortFn = (a: number[], b: number[]): number => a[1] < b[1] ? -1 : 1;
  lastSortFn = (a: number[], b: number[]): number => a[2] < b[2] ? -1 : 1;
  difSortFn = (a: number[], b: number[]): number => a[3] < b[3] ? -1 : 1;
  percSortFn = (a: number[], b: number[]): number => a[4] < b[4] ? -1 : 1;

  constructor(_salesInsightsService: InsightsService) {
    this.salesInsightsService = _salesInsightsService;
   }
  
  ngOnInit(): void {
    this.salesInsightsService.callInsightsSale().subscribe(
      result => {
        this.table = result.table_data;
        
        this.barChartData.labels = result.labels;
        this.barChartData.datasets = [
              { data: result.green, label: '20 and above', backgroundColor:  "rgba(4, 154, 57, 0.5)", hoverBackgroundColor: "rgba(4, 154, 57, 1)", barPercentage: 4}, 
              { data: result.blue, label: '10-20', backgroundColor:  "rgba(21, 142, 144, 0.5)", hoverBackgroundColor: "rgba(21, 142, 144, 1)", barPercentage: 4},
              { data: result.orange, label: '5-10', backgroundColor:  "rgba(231, 176, 25, 0.5)", hoverBackgroundColor: "rgba(231, 176, 25, 1)", barPercentage: 4},
              { data: result.red, label: 'below 5', backgroundColor:  "rgba(139, 0, 0, 0.5)", hoverBackgroundColor: "rgba(139, 0, 0, 1)", barPercentage: 4}
            ]
        this.chart?.update();
        this.isLoading = false;
       })  
  }

    public barChartOptions: ChartConfiguration['options'] = {
      responsive: true,
      aspectRatio: 3,
      scales: {
        x: {grid: {display: false},
            ticks:{maxRotation: 100,
                   font: {size:6}}},
        y: {min: 0}},
      plugins: {
            tooltip: {
                callbacks: {
                    label: function(context) {
                        let label = '';
                        if (context.parsed.y !== null) {
                            label = new Intl.NumberFormat().format(context.parsed.y);
                        }
                        return label;}}},        
        legend: {
          display: true,
          title: {
           display: true,
           text: 'Sales per customer in the last 7 days',
           font: {size:25}
        }
      },
    }
   };
    public barChartType: ChartType = 'bar';
    public barChartPlugins = [
    ];
  
    public barChartData: ChartData<'bar'> = {
      labels: [],
      datasets: []}
    
      // events
    public chartClicked({ event, active }: { event?: ChartEvent, active?: {}[] }): void {

    }
  
    public chartHovered({ event, active }: { event?: ChartEvent, active?: {}[] }): void {

    }
  
}
    
