import { Customer,customerObj,customerobj,vehicle,customerline } from '../models/customer';
import { NotificationService } from '../shared/notification.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomerService } from '../customers/customer.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { resultType } from '../shared/modal-content/modal-content.component';
import { Subscription } from 'rxjs';
import { NzTableFilterList } from 'ng-zorro-antd/table';

@Component({ selector: 'app-customers', templateUrl: './customers.component.html', styleUrls: ['./customers.component.less'] })
export class CustomersComponent implements OnInit {
  gridStyle = {
    width: '1/3',
    textAlign: 'center'
  };

  //Var
  customer: Customer;

  CustomerObjects: customerObj[] = [];
  ShowLines : customerObj[]= [];
  vehicles: vehicle[]=[];
  localsales: number = 0;
  Totalsales: number = 0;
  localquotes: number = 0;
  Totalquotes: number = 0;
  SelectedVreg = "";
  selectedVregId: number = 0;
  // hasService: boolean = false;

  //Visual Effects
  isLoading = false;
  edit = true;
  isInfoModalVisible = false;
  hasVehicles = false;
  isVehicleModalVisible = false;


  //Client
  //Client = JSON.parse(localStorage.getItem('clientData')!);
  quoteItem: customerObj;
  showData: customerline[]


  //CustomerUpdate
  customerForm: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    cell: new FormControl('', Validators.required),
  });
  isCustomerModalVisible  = false;

  //Vreg 
  cusData = JSON.parse(localStorage.getItem('customerData')!);

  //Subs/Observables
  paramsSub: Subscription;

      // Filter Functions
  
      leadFilterFn = (lead: string[], item: customerObj): boolean => lead.some(e => e === item.salequote);
      leadFilterValues: NzTableFilterList = [];
    
      // Filter list population
    
      private populateLeadFilterValues() {
        for (let item of this.ShowLines) {
          if (!this.leadFilterValues.some(e => e.text === item.salequote)) {
              this.leadFilterValues.push({ text: item.salequote, value: item.salequote })
          }
    
        }
        this.leadFilterValues = this.leadFilterValues.slice(); 
      }

  constructor(private customerService: CustomerService,private router: Router, private notification: NotificationService, public authService: AuthService, private activatedRoute: ActivatedRoute) 
  { 
    this.isLoading = true; 
  }

  ngOnInit(): void 
  {
      this.paramsSub = this.activatedRoute.params.subscribe(params => {     
        var obj = new customerobj();   
        if (params['customerId']) {
          obj.customerId = params['customerId'];
          //Because the whole component is built on the vreg string, we need to switch to it from the id in the param string
          obj.vreg = 'All'
          if (params["vehicleId"]) {
            this.selectedVregId = +params["vehicleId"];
          }
          
        }
        else {
          if(this.cusData.sendTo == undefined)
          {
            obj.customerId = this.cusData.customerId;
          }
          else
          {
            obj.customerId = this.cusData.customer;
          }
          if(this.cusData.vreg != "")
          {
            obj.vreg = this.cusData.vreg;
            this.SelectedVreg = this.cusData.vreg;
          }
          else
          {
            obj.vreg = "All"
          }
          
        }
        
        this.getCustomerData(obj);  
        
      });
      // //load data
      // if (this.cusData) {
      //   var obj = new customerobj();
      //   if(this.cusData.sendTo == undefined)
      //   {
      //     obj.customerId = this.cusData.customerId;
      //   }
      //   else
      //   {
      //     obj.customerId = this.cusData.customer;
      //   }
      //   if(this.cusData.vreg != "")
      //   {
      //     obj.vreg = this.cusData.vreg;
      //     this.SelectedVreg = this.cusData.vreg;
      //   }
      //   else
      //   {
      //     obj.vreg = "All"
      //   }
      //   this.getCustomerData(obj);   
      // }
         
  }


  reset()
  {
    this.customer = new Customer();
    this.quoteItem = new customerObj();
    this.CustomerObjects =[];
    this.vehicles = [];
    this.ShowLines = [];
    this.SelectedVreg = "All";
  }

  getCustomerData(obj)
  {
    this.reset();
    this.customerService.getCustomerData(obj).subscribe(res => 
      {           
        // Map  
        this.vehicles = res["vehicles"];
        this.localsales =  res["localSales"];
        this.Totalsales=  res["TotalSales"];
        this.localquotes=  res["localQuotes"];
        this.Totalquotes=  res["TotalQuotes"];
        this.customer = res["customer"];
        this.CustomerObjects = res["Items"];
        //this is the hack implementation of the selected vreg ID, please redo the entire VREG implementation
        if (this.selectedVregId) {
          for (let item of this.vehicles) {
            if (+item.Id === this.selectedVregId) {
              this.SelectedVreg = item.vreg;
            }
          }
        } 
        else {
          this.SelectedVreg = res["vreg"];
        }
        
        // Filter out duplicates        
        var filtered = new Set(this.CustomerObjects);
        this.CustomerObjects = [...filtered];

        //log
        this.isLoading = false;

        //reset object
        localStorage.setItem('customerData', JSON.stringify(res["customer"]));
        if(this.vehicles.length > 1)
        {
          for(var vreg in this.vehicles)
          {
            vreg.toUpperCase();
          }
        }  
      
        //filter
        this.filtertable();
        
        //check data
        if(res["customer"].name == "" || res["customer"].name == null || res["customer"].cell== "" || res["customer"].cell == null)
        {
          this.notification.ShowAlert({type: resultType.Warning, 
            title: "Missing Customer Data", 
            htmlMessage: 'Please Remember to Update Customers Details' ,
            maskClosable: false, 
            autoCloseInMilliseconds: 3000})
        }
      },
      error => {
        this.notification.handleError(error);       
    });
  }

  total()
  {
    let total = 0;
    for(var i=0; i < this.CustomerObjects.length;i++)
    {
      if(this.CustomerObjects[i].salequote == "sale")
      total = total + this.CustomerObjects[i].Price;  
    }
    return (total).toFixed(2)
  }

  filtertable()
  {
    if(this.SelectedVreg == null)
    {
      this.SelectedVreg = "All"
    }

    if(this.SelectedVreg && this.SelectedVreg != "All" )
    {
      this.ShowLines=[]
      for(var i = 0; i < this.CustomerObjects.length; i++)
      {
          if(this.CustomerObjects[i].vreg.toUpperCase() == this.SelectedVreg.toUpperCase())
          {
            this.ShowLines.push(this.CustomerObjects[i])
          }
      }
      this.populateLeadFilterValues();
    } 
    else
    {
      this.ShowLines = this.CustomerObjects;
      this.populateLeadFilterValues();
    } 
    if(this.vehicles.length > 0)
    {
      this.hasVehicles = true;
    }
    this.total();
  }

  route(quoteid)
  {
    this.router.navigate(["/pos/v2",quoteid],{queryParams: {cid: this.customer.customerId}});
  }

  routesale(orderid)
  {
    this.router.navigate(["/sales/view",orderid])
  }

  openCustomer()
  {
    this.router.navigate(['/settings/customer-edit',this.customer.customerId]);
  }
  hasService(rows: customerline []){
    if(rows.some(val => val.stocktype === 'NONE STOCK'))
      return true
    else return false

  }

}
