<div class="container-fluid col-lg-11  bg-1">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-3">
                <p></p>
                <p></p>
                <button nz-button nzType="default" (click)="openCustomer()">
                    <i nz-icon nzType="user" nzTheme="outline"></i>
                    {{ this.customer.name | titlecase }}'s History
                </button>
            </div>
            <div class="col-lg-9 text-left ">
                <p></p>
                <p></p>
                <p class="offset-2" style="font-size: 14px;">
                    This page allows you Update Details For
                    <ng-container *ngIf="customer">
                        <b><span class="text-style">{{ this.customer.name }}</span></b>
                    </ng-container>
                </p>
            </div>
        </div>
    </div>
    <br>
    <nz-spin [nzSpinning]="isLoading">
        <nz-tabset nzCentered nzSize="large">
            <nz-tab nzTitle="Details">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-4 text-center offset-4">
                            <form nz-form [formGroup]="UpdateCustomerForm">
                                <nz-form-item>
                                    <nz-form-control [nzErrorTip]="'Customer Name Required'">
                                        <label class="float-left">Name</label>
                                        <div class="input-group maindiv">

                                            <input nz-input name="custName" formControlName="custName" placeholder="Customer Name">
                                        </div>
                                    </nz-form-control>
                                </nz-form-item>
                                <nz-form-item>
                                    <nz-form-control nzErrorTip="Valid or empty email required">
                                        <label class="float-left">Email</label>
                                        <div class="input-group maindiv">

                                            <input nz-input name="custEmail" formControlName="custEmail" placeholder="Email Address">
                                        </div>
                                    </nz-form-control>
                                </nz-form-item>
                                <nz-form-item>
                                    <nz-form-control [nzErrorTip]="'Valid Customer Cell Required'">
                                        <label class="float-left">Cell</label>
                                        <div class="input-group maindiv">

                                            <input nz-input name="custCell" formControlName="custCell" placeholder="Cell Number">
                                        </div>
                                    </nz-form-control>
                                </nz-form-item>
                                <nz-form-item>
                                    <nz-form-control>
                                        <label class="float-left">Vat No</label>
                                        <div class="input-group maindiv">

                                            <input nz-input name="custVATNumber" formControlName="custVATNumber" placeholder="VAT No">
                                        </div>
                                    </nz-form-control>
                                </nz-form-item>
                                <nz-form-item>
                                    <nz-form-control>
                                        <label class="float-left">Debtor Acc</label>
                                        <div class="input-group maindiv">

                                            <input nz-input name="custDebtorAcc" formControlName="custDebtorAcc" placeholder="Debtor Account">
                                        </div>
                                    </nz-form-control>
                                </nz-form-item>
                                <button nz-button nzType="primary" (click)="updateCustomer()">Update</button>
                                <br>
                            </form>
                        </div>
                    </div>
                </div>
            </nz-tab>
            <nz-tab nzTitle="Vehicles" *ngIf="this.showVehicles">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-3">
                            <button nz-button nzType="default" (click)="onVehiclesUpdate()">Save Vehicles</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div *ngFor="let item of this.customer.vehicles">
                                <nz-form-item>
                                    <nz-form-control>
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-6">
                                                    <label class="text-style">Registration</label>
                                                    <p hidden> {{item.Id}}</p>
                                                    <input style="text-transform: uppercase;" (change)="changeVreg($event.target)" value="{{item.vreg}}" nz-input name="name" placeholder="Vreg">
                                                </div>
                                                <div class="col-6">
                                                    <label class="text-style">Tyre Size</label>
                                                    <p hidden> {{item.Id}}</p>
                                                    <input style="text-transform: uppercase;" (change)="changeSize($event.target)" value="{{item.size}}" nz-input name="name" placeholder="Size">
                                                </div>
                                            </div>
                                        </div>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                    </div>
                </div>
            </nz-tab>
            <nz-tab nzTitle="Sub-Customers">
                <div class="container-fluid">
                    <nz-input-group [nzSuffix]="searchIcon">
                        <input
                          nz-input
                          [disabled]="isLoadingSubCustomers"
                          placeholder="Search Customers"
                          [(ngModel)]="this.selectedCustomer"
                          [nzAutocomplete]="auto"
                          (ngModelChange)="searchCustomer($event)"
                          (keyup.escape)="filteredCustomers = []"
                          style="text-transform:uppercase;"
                          (selectionchange)="this.selectedCustomer= selectedCustomer"
                        />
                      
                        <nz-autocomplete #auto [nzDefaultActiveFirstOption]="true">
                          <nz-auto-option
                            *ngFor="let customer of filteredCustomers"
                            [nzValue]="customer.value" 
                            [nzLabel]="customer.value"
                            (click)="addSubCustomer(customer); this.selectedCustomer = null; " 
                          >
                            <div nz-row nzJustify="space-between" style="display: flex; align-items: center;">
                              <div style="display: flex; align-items: center;">
                                <span nz-icon nzType="user" nzTheme="outline" class="color-primary"></span>
                                <span style="margin-left: 8px; text-transform:uppercase;">
                                  {{ customer.value }}
                                </span>
                              </div>
                            </div>
                          </nz-auto-option>
                      
                          <nz-auto-option *ngIf="filteredCustomers.length === 0 && this.selectedCustomerString.length > 2" [nzValue]="null">
                            <div nz-row nzJustify="space-between" style="align-items: center; width: 100%;">
                              <span style="margin-left: 8px;">No customer found</span>
                              <button
                                nz-button
                                nzType="primary"
                                (click)="openNewCustomerModal($event)"
                                style="margin-left: auto;"
                              >
                                Create New Customer
                              </button>
                            </div>
                          </nz-auto-option>
                        </nz-autocomplete>
                      </nz-input-group>
                      
                    <ng-template #searchIcon>
                        <i nz-icon nzType="search"></i>
                    </ng-template>
                    <ng-template #plusIcon>
                        <i nz-icon nzType="plus"></i>
                    </ng-template>
                </div>
                <!-- <div nz-row nzSpan="12" *ngIf="isCustomerSelected"  
                style=" display: flex;
                        align-items: center;
                        padding: 8px 12px;
                        border: 1px solid #404040;
                        border-radius: 4px;
                        margin-top: 30px;
                        text-transform:uppercase;">
                    <span nz-icon nzType="user" nzTheme="outline" class="color-primary"></span>
                    <span style="margin-left: 8px;">{{ selectedCustomerString }}</span>
                    <button
                        nz-button
                        nzType="primary"
                        (click)="addSubCustomer(this.selectedCustomer, $event)"
                        nz-tooltip="Click to add to sub-customers"
                        style="margin-left: auto; padding: 5px 5px 5px 5px">
                            <span nz-icon nzType="plus" nzTheme="outline"></span>
                            Add sub-customer
                    </button>
                </div> -->

                <nz-table
                    [nzFrontPagination]="false"
                    [nzPageSize]="10"
                    [nzShowPagination]="true"
                    [nzLoading]="isLoadingSubCustomers"
                    [nzData]="subCustomers"
                    style="margin-top: 16px;">
                    <thead>
                        <tr>
                            <th style="width: 30%; border-right: none;">Name</th>
                            <th style="width: 30%; border-right: none;">Email</th>
                            <th style="width: 25%; border-right: none;">Cell</th>
                            <th style="width: 15%; border-right: none;"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let customer of subCustomers">
                            <td style="border-right: none; text-transform:uppercase;">
                                {{ customer.name }}
                            </td> 
                            <td style="border-right: none;">
                                {{ customer.email }}

                            </td>
                            <td style="border-right: none;">
                                {{ customer.cell }}
                            </td>
                            <td style="border-right: none;">
                            <button
                            nz-button
                            nzType="default"
                            nzSize="small"
                            (click)="removeSubCustomer(customer)"
                            style="background-color: #ff4d4f; border-color: #ff4d4f; color: white;"
                            nz-tooltip="Click to remove sub-customer">
                            <span nz-icon nzType="delete" nzTheme="outline" ></span> Remove
                            </button>
                        </td>
                        </tr>
                    </tbody>
                </nz-table>
            </nz-tab>
            <nz-tab nzTitle="Communities">
                <div class="container-fluid">
                    <nz-table
                    #communities
                    [nzFrontPagination]="false"
                    [nzPageSize]="10"
                    [nzShowPagination]="true"
                    [nzLoading]="isLoadingSubCustomers"
                    [nzData]="customer.customerGroups"
                    style="margin-top: 16px;">
                    <thead>
                        <tr>
                            <th style="width: 30%; border-right: none;">Name</th>
                            <th style="width: 30%; border-right: none;">Description</th>
                            <th style="width: 15%; border-right: none;"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of communities.data">
                            <td style="border-right: none;">
                                <span nz-icon nzType="check-circle" nzTheme="outline" class="color-success" *ngIf="data.hasCustomerLink" nz-tooltip="Community is linked for this customer"></span>
                                {{ data.groupName.toUpperCase() }}
                            </td> 
                            <td style="border-right: none;">{{ data.groupDescription }}</td>
                            <td style="border-right: none;">
                                <button
                                *ngIf="!data.hasCustomerLink"
                                [disabled]="!data.canAddMembers"
                                nz-button
                                nzType="primary"
                                nzSize="small"
                                (click)="changeCustomerCommunity(this.customerId, data.customerGroupId, true)"
                                [nz-tooltip]="addComTemplate">
                                    <span nz-icon nzType="usergroup-add" nzTheme="outline"></span> Add
                                </button>
                                <ng-template #addComTemplate>
                                    <span *ngIf="data.canAddMembers">Click to link customer for this community</span>
                                    <span *ngIf="!data.canAddMembers">The customer cannot be added to this community</span>
                                  </ng-template>
                                <button
                                *ngIf="data.hasCustomerLink"
                                [disabled]="!data.canRemoveMembers"
                                nz-button
                                nzType="primary"
                                nzDanger
                                nzSize="small"
                                (click)="changeCustomerCommunity(this.customerId, data.customerGroupId, false)"
                                [nz-tooltip]="removeComTemplate">
                                    <span nz-icon nzType="usergroup-delete" nzTheme="outline"></span> Remove
                                </button>
                                <ng-template #removeComTemplate>
                                    <span *ngIf="data.canRemoveMembers">Click to unlink customer from this community</span>
                                    <span *ngIf="!data.canRemoveMembers">The customer cannot be removed from this community</span>
                                  </ng-template>
                            </td>
                        </tr>
                    </tbody>
                </nz-table>
                </div>
            </nz-tab>
        </nz-tabset>
    </nz-spin>
</div>

<nz-modal [(nzVisible)]="isModalOpen" nzClosable="true" nzTitle="New Customer" (nzOnCancel)="isModalOpen = false;" (nzOnOk)="addNewCustomer()" [nzFooter]="null">
    <div *nzModalContent>
        <form nz-form [formGroup]="customerForm" class="text-center">
            <nz-form-item>
                <nz-form-control [nzErrorTip]="'Name required'">
                    <label class="text-style">Name</label>
                    <input nz-input name="name" formControlName="name" placeholder="Customer Name">
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control [nzErrorTip]="'10 Digit Cell Number required'">
                    <label class="text-style">Cell</label>
                    <input maxlength="10" nz-input name="cell" formControlName="cell" placeholder="Customer Cell">
                </nz-form-control>
            </nz-form-item>
            <hr style="border-top:2px solid var(--backcolor);">
            <nz-form-item>
                <nz-form-control>
                    <small class="text-style">*Optional*</small>
                    <input nz-input name="vreg" formControlName="vreg" placeholder="Vehicle Registration">
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control>
                    <small class="text-style">*Optional*</small>
                    <input nz-input name="email" formControlName="email" placeholder="Customer Email">
                </nz-form-control>
            </nz-form-item>
        </form>
        <div class="container-fluid">
            <div nz-row nzJustify="center" >
                    <button nz-button nzType="primary" (click)="addNewCustomer()">Save and Add sub-customer</button>
            </div>
        </div>
    </div>
</nz-modal>

