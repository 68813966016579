<button nz-button nzType="default" class="btn btn-wbc float-left" style="height: 40px; font-size: 1rem;  margin-bottom: 1rem;margin-left: 2%;" (click)="showWbcModal()" [nzTooltipPlacement]="'right'"
[disabled]="!this.quote.Vehicle_Customer_MappingId" [nz-tooltip]="!this.quote.Vehicle_Customer_MappingId ? 'Select Customer Details First!' : 'New WBC Quote'">                                              
    <img src="./../../../assets/Images/dealers/wbc.png" width="19" height="15" style="margin-right: 5px;">
    WBC Quote
</button>

<nz-modal [(nzVisible)]="isWbcModal" nzTitle="Request We Buy Cars Quote" (nzOnCancel)="isWbcModal = false">
    <div *nzModalContent>
        <div class="container-fluid">
            <div class="row">
                <form nz-form [formGroup]="wbcForm" (keydown.enter)="$event.preventDefault()">
                    <div nz-row nzGutter="10">
                        <div nz-col [nzSpan]="12">
                            <nz-form-item>
                                <nz-form-control [nzErrorTip]="'Customer Name Required'">
                                    <nz-input-group nzPrefixIcon="user">
                                        <input style="text-transform: uppercase;" nz-input formControlName="name" placeholder="Customer Name">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="12">
                            <nz-form-item>
                                <nz-form-control [nzErrorTip]="'Customer Surname Required'">
                                    <nz-input-group nzPrefixIcon="user">
                                        <input style="text-transform: uppercase;" nz-input formControlName="surname" placeholder="Customer Surname">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>
                    <div nz-row>
                        <div nz-col [nzSpan]="24">
                            <nz-form-item>
                                <nz-form-control [nzErrorTip]="'Customer Email Required'">
                                    <nz-input-group nzPrefixIcon="mail">
                                        <input style="text-transform: uppercase;" nz-input formControlName="email" placeholder="Customer Email">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>
                    <div nz-row [nzJustify]="'center'">
                        <div nz-col [nzSpan]="16">
                            <nz-form-item>
                                <nz-form-control [nzErrorTip]="'Customer Cell Required'">
                                    <nz-input-group nzPrefixIcon="phone">
                                        <input style="text-transform: uppercase;" nz-input formControlName="cell" placeholder="Customer Cell">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>
                    <div nz-row [nzGutter]="24">
                        <div nz-col [nzSpan]="12">
                            <nz-form-item>
                                <nz-form-control [nzErrorTip]="'Vehicle Make Required'">
                                    <nz-form-label>Make</nz-form-label>
                                    <nz-input-group nzPrefixIcon="car">
                                        <input nz-input name="vehicleMake" formControlName="vehicleMake" placeholder="Enter Make">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="12">
                            <nz-form-item>
                                <nz-form-control [nzErrorTip]="'Vehicle Model Required'">
                                    <nz-form-label>Model</nz-form-label>
                                    <nz-input-group nzPrefixIcon="car">
                                        <input nz-input name="vehicleModel" formControlName="vehicleModel" placeholder="Enter Model">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>
                    <div nz-row>
                        <div nz-col [nzSpan]="24">
                            <nz-form-item>
                                <nz-form-control [nzErrorTip]="'Vehicle Variant Required'">
                                    <nz-form-label>Variant</nz-form-label>
                                    <nz-input-group nzPrefixIcon="car">
                                        <input nz-input name="vehicleMake" formControlName="vehicleVariant" placeholder="Enter Variant">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>
                    <div nz-row [nzGutter]="24">
                        <div nz-col [nzSpan]="12">
                            <nz-form-item>
                                <nz-form-control [nzErrorTip]="'Vehicle Registration Required'">
                                    <nz-form-label>Registration</nz-form-label>
                                    <nz-input-group nzPrefixIcon="car">
                                        <input style="text-transform: uppercase;" nz-input formControlName="vehicleReg" placeholder="Vehicle Registration" class="text-center">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="12">
                            <nz-form-item>
                                <nz-form-control [nzErrorTip]="'Vehicle Year Required'">
                                    <nz-form-label>Year</nz-form-label>
                                    <nz-input-group nzPrefixIcon="schedule">
                                        <input style="text-transform: uppercase;" nz-input formControlName="vehicleYear" placeholder="Vehicle Year" class="text-center">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>
                    <div nz-row [nzGutter]="24">
                        <div nz-col [nzSpan]="12">
                            <nz-form-item>
                                <nz-form-label>Milage</nz-form-label><br>
                                <nz-form-control [nzErrorTip]="'Vehicle Milage Required'">
                                    <nz-input-group nzPrefixIcon="dashboard">
                                        <input style="text-transform: uppercase;" nz-input formControlName="vehicleMilage" placeholder="Vehicle Milage" class="text-center">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="12">
                            <nz-form-item>
                                <nz-form-label>Condition</nz-form-label><br>
                                <nz-form-control [nzErrorTip]="'Vehicle Model Required'">
                                    <nz-input-group>
                                        <nz-select formControlName="vehicleCondition" [(ngModel)]="vehicleConditionselect" style="width: 100%;" [nzPlaceHolder]="'Vehicle Condition'" [nzShowSearch]="true">
                                            <nz-option *ngFor="let condition of vehicleCondition" [nzValue]="condition" [nzLabel]="condition"></nz-option>
                                        </nz-select>
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div *nzModalFooter>
        <button [disabled]="!wbcForm.valid" nz-button nzType="default" class="btn btn-wbc" (click)="sendWBCquote()">
                Request Quote
        </button>
    </div>
</nz-modal>