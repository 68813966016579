import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Quote, QuoteLine, CustomerVehicleDetails, sbFleetAuthData } from '../classes/quote';
import { PosService } from './pos.service';
import { NotificationService } from '../shared/notification.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { PosAuditService } from './posaudit.service';
import { BookingSlot, PosAuditEntry, PosAuditEntryType } from '../models/pos';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';
import { differenceInCalendarDays } from 'date-fns';
import { AuthService } from '../auth.service';
import { ReportsService } from '../shared/reports.service';
import { DatePipe } from '@angular/common';
import { Salesman } from '../models/user';
import { aautoquote } from '../models/search-obj';
import { SearchService } from '../search/search.service';
import { LeadsService } from '../leads/leads.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { resultType } from '../shared/modal-content/modal-content.component';

@Component({
  selector: 'app-pos',
  templateUrl: './pos.component.html',
  styleUrls: ['./pos.component.less']
})
export class PosComponent implements OnInit, OnDestroy {

  paramsSub: Subscription;
  qparamsSub: Subscription;
  

  quote: Quote = new Quote();
  isLoading = false;
  salesmen: Salesman[] = [];
  assignedSalesman: number = 0;
  backToCustomerId: number = 0;
  leadSelect: number = 0;
  followUpSelect: number = 0;
  reActivate: boolean = false;


  navPathAway: string = ""; //used to indicate that the user clicked to navigate away from the page, this will store the intended route.
  isNavModalVisible = false;
  isNavAwayVisible = false;

  private quoteSub: Subscription;

  constructor(
    private posService: PosService, 
    private notification: NotificationService, 
    private route: ActivatedRoute, 
    private auditService: PosAuditService,
    private router: Router,
    public authService: AuthService,
    private reportsService: ReportsService,
    private searchService: SearchService,
    private leadsService: LeadsService) { }

  ngOnDestroy(): void {
    this.quote.quoteToBeSaved$?.unsubscribe();
    this.quote.actionLogToBeSaved$?.unsubscribe();
    this.paramsSub.unsubscribe();
    this.qparamsSub.unsubscribe();
  }

  loadQuote(quoteId: number) {
    this.isLoading = true;
      this.posService.getQuote(quoteId).subscribe(
        {next: val => {
          //Because Javascript is not staticly typed, we need to specifically cast the return value to the class so we have access to the class methods
          this.quote = Object.assign(new Quote(), val);
          //this.quote.fleetType = this.searchService.lastSelectedSpecialPriceSet ? this.searchService.lastSelectedSpecialPriceSet.authType : 0; //Assign the auth type to the fleet type of the quote.
          
          let newLines: QuoteLine[] = [];
          this.quote.lines.forEach(e => newLines.push(Object.assign(new QuoteLine() , e)));
          newLines.forEach(e => e.updateLineItemGPList(this.quote.vatRate));
          newLines.forEach(e => e.retailValueLess = e.retail); //update retailvalueless for each line item to retail less 0%
          this.quote.lines = newLines;
          this.quote.sortLines();
          // if (this.quote.canSave === false) {
          // if(this.quote.lines.some(line => line.gpPercent < this.quote.gpProtectionPercent!)){
          //   this.quote.markDirty();
          // }
            
          //all classes mapped successfully
          //subscribe to the event if quote needs to be saved
          this.quote.quoteToBeSaved$?.subscribe(
            (val) => {
              this.saveQuote(val);
              
              
            }
          );  
          //sub to event for action log
          this.quote.actionLogToBeSaved$?.subscribe(
            _ => {
              this.saveActionLog();
            }
          );
          this.isLoading = false;

          //Check if Admin and display the dropdown for slameman
          this.assignedSalesman = this.quote.userId;
          if(this.authService.hasClaims(['SDRP']))
          {
            this.posService.getSalesman(this.authService.user.client_id,this.authService.user.user_id).subscribe({next: res => { this.salesmen = res;},error: error => this.notification.handleError(error)});
          }   

          //Check Navaway
          if(this.quote.showNavAway == false)
          {
            this.skipNavAway = true;
          }

          // Check Online Lead
          // While we only have one online classification, we are removing the unnecessary variable. 
          // This will be updated in the future when we have more than one classification that is readonly - and will be moved to a function.
          // if(this.quote.selectedChannel == 3)
          // {
          //     this.readonlyChannel = true;
          // }

        },
        error: err => this.notification.handleError(err)}
      )
  }

  ngOnInit(): void {
    this.paramsSub = this.route.params.subscribe(params => {
      this.loadQuote(+params['quoteid'])
    });
    this.qparamsSub = this.route.queryParams.subscribe( qp => {
      this.backToCustomerId = qp.cid; 
    })
  }

  navigateBackToCustomer() {
    this.router.navigate(['/customer', this.backToCustomerId]);
  }

  addItemsToQuote(items: QuoteLine[]) {
    for (var item of items) {
      if (this.quote.lines.find(e => (e.msfid === item.msfid && item.isTyre) || (e.customStockId && item.customStockId && e.customStockId == item.customStockId && (this.quote.selectedOption == null || e.optionId == this.quote.selectedOption || e.optionId == null)))) {
        //this will ensure that we don't add one item multiple times.
        this.notification.showWarning(`${item.description} already on quote`);
        continue;
      }

      //add audot action first
      const comment = item.qty.toString() + "x " + item.description
      this.auditService.addAction(new PosAuditEntry(this.quote.quoteId, PosAuditEntryType.AddItem, item.msfid, comment))
      //update the gp list
      item.updateLineItemGPList(this.quote.vatRate);

      //check option id
      if (item.isTyre) {
        if (this.quote.selectedOption) {
          item.optionId = this.quote.selectedOption;
        }
        else {
          item.optionId = this.quote.lines.reduce((acc, nxt) => {return nxt.optionId! > acc ? nxt.optionId! : acc},0) + 1;
        }
      } 
      else {
        if (this.quote.selectedOption) {
          item.optionId = this.quote.selectedOption;
        }
      }
      
      this.quote.lines.push(item);

   }
   this.quote.lines = this.quote.lines.slice(); //Force angular change detection
   this.quote.sortLines();
   this.quote.markDirty();
    
  }

  // private checkLineItemForDiscountsDEPRECATED(item: QuoteLine) {    
  //   // for (var discount of this.quote.discountRules) {
  //   //   if ((!discount.BrandDescriptions || discount.BrandDescriptions.split(",").includes(item.brand!)) 
  //   //     && (!discount.MsfidRequirements || discount.MsfidRequirements.split(",").includes(item.msfid.toString()))
        
  //   //     ) {          
  //   //       if (discount.discounts) {
  //   //         for (var discountItem of discount.discounts) {
  //   //           discountItem.optionId = item.optionId;
  //   //           this.quote.lines.push(discountItem);
  //   //         }
  //   //       }
  //   //   } 
  //   // }
  //   console.log("Skipping item line check");
    
  // }

  private checkAllOptionsForDiscounts() {
    for (var item of this.quote.lines.filter(e => e.isTyre)) {
      if (!this.quote.shouldApplyDiscounts) {
        return;
      }
      for (var discount of this.quote.discountRules.filter(e => e.discounts)) {
        if ((!discount.BrandDescriptions || discount.BrandDescriptions.split(",").includes(item.brand!)) 
         && (!discount.MsfidRequirements || discount.MsfidRequirements.split(",").includes(item.msfid.toString()))
         && (!discount.tyreQty || discount.tyreQty.split(",").includes(item.qty.toString()))
         && (!discount.rimSize || discount.rimSize.split(",").includes(item.rim.toString()))
         && (!discount.markets || discount.markets.split(",").includes(item.market.toString()))
         && this.quote.shouldApplyDiscounts ) {
          //needs the discount to be applied
          
          if (this.quote.lines.find(e => e.msfid === discount.discounts![0].msfid && e.optionId === item.optionId)) {
            //already has the discount
            //Do nothing??

          }
          else { 
            //check for customer limitations
            let shouldLoadDiscount = true;
            if (discount.hasCustomerLimits) {
              
              shouldLoadDiscount = false;
              
              if (this.quote.hasCustomer && this.quote.custCustomerGroupIds.some(i => discount.customerGroupIds.includes(i))) {
                shouldLoadDiscount = true;
              }
              
            }
            if (shouldLoadDiscount) {
              for (var discountItem of discount.discounts!) {
                
                let itemToBeAdded = Object.assign(new QuoteLine(), discountItem);
                itemToBeAdded.optionId = item.optionId;
                this.quote.lines.push(itemToBeAdded);
              }
            }
            
          }
        }
        else {
          //needs the discount to be removed.
          if (this.quote.lines.find(e => e.msfid === discount.discounts![0].msfid && e.optionId === item.optionId && discount.rimSize!.split(",").includes(item.rim.toString()) )) {
            //remove item from array
            this.quote.lines = this.quote.lines.filter(e => !(e.msfid === discount.discounts![0].msfid && e.optionId === item.optionId));
          }
        }
      }
    }

  }

  private timer: any;
  saveQuote(skipReload: boolean = true) {
    this.checkAllOptionsForDiscounts();
    this.leadsService.updatingLeads.next(true); //Set the leads BehaviourSubject to state that we are updateing a lead.
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (this.quote.canSave === false || this.isTyreWithZeroPrice()) {
        return;
      }
      this.quote.isSaving = true;
      this.posService.saveQuote(Object.assign({}, this.quote)).subscribe({
        next: _ => {          
          this.quote.isSaving = false;
          this.quote.isDirty = false;
          this.leadsService.updatingLeads.next(false); //Set the leads BehaviourSubject to state that we are done updating the lead
          this.auditService.saveActions();
          //reload the quote
          if (!skipReload) {
            this.quote.quoteToBeSaved$?.unsubscribe();
            this.quote.actionLogToBeSaved$?.unsubscribe();
            this.loadQuote(this.quote.quoteId);
          }
          
        },
        error: error => {
          this.quote.isSaving = false;          
          this.leadsService.updatingLeads.next(false); //Set to done udpating
          this.notification.handleError(error);          
        }})      
    }, 750);
    
    
  }

  private alTimer: any;
  saveActionLog() {    
    clearTimeout(this.alTimer);
    this.alTimer = setTimeout(() => {
      this.auditService.saveActions();      
    }, 1500);
    
    
  }

  externalVehicleId = 0;
  saveVehicle(vehicle: CustomerVehicleDetails) {
    
    vehicle.odoReading = +vehicle.odoReading; //to make sure an empty string is converted to 0 :)
    
    this.posService.updateVehicleDetails(this.quote.quoteId, vehicle).subscribe({
      next: val => {
        
        
        this.quote.Vehicle_Customer_MappingId = val.Vehicle_Customer_MappingId;
        this.quote.vehicleOdoId = val.vehicleOdoId;
        this.externalVehicleId = val.Vehicle_Customer_MappingId;
      },
      error: err => {
        this.notification.handleError(err);
      }
    })
  }

  changeSalesman(user:number)
  {
    this.quote.userId = this.salesmen.find(x=> x.userId == user)!.userId; 
    this.quote.markDirty(false);


    // this.assignedSalesman = this.salesmen.find(x=> x.userId == user)!.userId; 
    // this.quote.markDirty();
    // this.auditService.addAction(new PosAuditEntry(this.quote.quoteId, PosAuditEntryType.AdminChangeQuoteSaleman, 0, "Admin assigned " + this.salesmen.find(x=> x.userId == user)?.fullname + " with userId " + this.salesmen.find(x=> x.userId == user)?.userId + " to quote: " + this.quote.quoteId));
    // this.posService.updateSalesman(this.quote.quoteId,user).subscribe(
    //   {
    //     next: res => { 
    //       this.assignedSalesman = this.salesmen.find(x=> x.userId == user)!.userId; 
    //       this.notification.showSuccess("Salesman Updated!");
    //       this.saveActionLog();
    //     },
    //     error: error => this.notification.handleError(error)
    //   });
  }


  //#region NAV AWAY GUARD AND MODAL ACTIONS
  skipNavAway = false;
  followUpDate: Date = new Date();
  @ViewChild('followUpDatePicker') followUpDatePicker!: NzDatePickerComponent;
  today = new Date(); //to reuse a date object to check all dates, in stead of creating a new one for evey day to check
  // Can not select days before today, but not today
  disabledDate = (current: Date): boolean => differenceInCalendarDays(current, this.today) < 0;
  statusComment = "";
  PosAuditEntryType = PosAuditEntryType;

  checkNavGuard(navPathAway: string): boolean {
    
    // if (!this.quoteDirty && this.statusSaved) {
    //   return true;
    // }
    if (this.skipNavAway || this.quote.custId == 14041 || this.quote.custId == 0) { 
      return true;
    }
    if (!this.navPathAway && !this.quote.isWIP) {
      this.showStatusModal(true)
      this.navPathAway = navPathAway;
      return false;
    } else if (this.navPathAway === navPathAway) {
      return true;
    }
    else {
      //What to do now? Navigating to a different path?
      return true;
    }

  }

  showStatusModal(isNavAwayVisible: boolean){
    this.isNavModalVisible = true;
    this.isNavAwayVisible = isNavAwayVisible;
    //this.followUpDate = new Date();
  }

  navigateWithoutSaving () {
    this.router.navigate([this.navPathAway]);
  }

  checkLunchDisabled() : boolean {
    if (new Date().getHours() < 12) {
      return false;
    }
    return true;
  }

  followUpAddMinutes(num: number) {
    this.followUpDate = new Date(); //reset the date and time, to be 100% sure
    this.followUpDate.setMinutes(this.followUpDate.getMinutes() + num);
    // this.followUpDatePicker.writeValue(this.followUpDate);
    this.updateQuoteStateWithAction(PosAuditEntryType.FollowUpLater);
  }

  followUpAddHours(num: number) {
    this.followUpDate = new Date(); //reset the date and time, to be 100% sure
    this.followUpDate.setHours(this.followUpDate.getHours() + num);
    // this.followUpDatePicker.writeValue(this.followUpDate);
    this.updateQuoteStateWithAction(PosAuditEntryType.FollowUpLater);
  }


  followUpAddDays(num: number) {
    this.followUpDate = new Date(); //reset the date and time, to be 100% sure
    this.followUpDate.setDate(this.followUpDate.getDate() + num);
    this.followUpDate.setHours(0);
    this.followUpDate.setMinutes(0);
    this.followUpDate.setSeconds(0);
    // this.followUpDatePicker.writeValue(this.followUpDate);
    this.updateQuoteStateWithAction(PosAuditEntryType.FollowUpLater);
  }

  followUpLunch() {
    this.followUpDate = new Date(); //reset the date and time, to be 100% sure
    this.followUpDate.setHours(12);
    this.followUpDate.setMinutes(0);
    this.followUpDate.setSeconds(0);
    // this.followUpDatePicker.writeValue(this.followUpDate);
    this.updateQuoteStateWithAction(PosAuditEntryType.FollowUpLater);
  }

  updateQuoteStateWithAction(action: PosAuditEntryType, followUp: boolean = true){
    this.auditService.addAction(new PosAuditEntry(this.quote.quoteId, action, 0, (followUp===true ? this.followUpDate.toLocaleString('en-GB').substring(0,17).replace(", 00:00","") + " :: " : "") + (this.statusComment.length > 0 ? this.statusComment : "")));
    
    let statusId: number = 0; //to store the quote status id, based on the action we just took
    switch (action) {
      case PosAuditEntryType.FollowUpLater:{statusId = 6; break;}
      case PosAuditEntryType.PriceChecking:{statusId = 14; break;}
      case PosAuditEntryType.IncorrectCustomerDetails:{statusId = 22; break;}
      case PosAuditEntryType.CustomerUnreachable:{statusId = 10; break;}
      case PosAuditEntryType.BoughtFromCompetitor:{statusId = 23; break;}
      case PosAuditEntryType.ClosedOther:{statusId = 24; break;}
      case PosAuditEntryType.CommitedToBuy:{statusId = 28; break;}
      case PosAuditEntryType.TentativelyAccepted:{statusId = 18; break;}
      case PosAuditEntryType.ReactivateQuote:{statusId = 29; break;} // reactivating a closed quote

      default:
        statusId = 6;
        break;
    }

    if (!followUp) 
    {
      this.updateQuoteStatus(statusId, undefined); 
      
      if((this.quote.leadChannel && this.quote.selectedChannel == null) ||  (this.quote.leadType && this.quote.selectedType == null) || (this.reActivate === true))
      {
        this.reActivate = false;
        return; // dont allow navigation if classifications not met
      }
      if (!this.navPathAway) {
        this.navPathAway = "/search";
      }
    }
    else {
      this.updateQuoteStatus(statusId, this.followUpDate);    
      if((this.quote.leadChannel && this.quote.selectedChannel == null) ||  (this.quote.leadType && this.quote.selectedType == null) || (this.reActivate === true))
      {
        this.reActivate = false;
        return; // dont allow navigation if classifications not met
      }
      this.statusComment = "";
      this.navPathAway = "/search";
    }

    if (this.navPathAway) {     
      if((this.quote.leadChannel && this.quote.selectedChannel == null) ||  (this.quote.leadType && this.quote.selectedType == null))
      {
        return; // dont allow navigation if classifications not met
      }
      this.router.navigate([this.navPathAway])
    }
    this.isNavModalVisible = false;
  }

  private updateQuoteStatus(statusId: number, actionDate?: Date) {

    if (actionDate){
      this.quote.dt_act = actionDate;
    }
    this.quote.quoteStatusId = statusId;
    this.quote.markDirty()
  }

  followUpChange($event:any)
  {
    this.followUpSelect = $event;
    if($event == 1) {
      this.followUpAddMinutes(15);
    }
    else if ($event == 2)
    {
      this.followUpAddHours(1);
    }
    else if ($event == 3)
    {
       this.followUpAddHours(2);
    }
    else if ($event == 4)
    {
     this.followUpLunch();
    }
    else if ($event == 5)
    {
      this.followUpAddDays(1);
    }
    else if ($event == 6)
    {
      this.followUpAddDays(2);
    }
  }

  closeLead($event:any){
    this.leadSelect = $event;
    if($event == 1){
      this.updateQuoteStateWithAction(PosAuditEntryType.BoughtFromCompetitor, false)
    }
    else if ($event == 2){
      this.updateQuoteStateWithAction(PosAuditEntryType.CustomerUnreachable, false)
    }
    else if ($event == 3){
       this.updateQuoteStateWithAction(PosAuditEntryType.PriceChecking, false)
    }
    else if ($event == 4){
       this.updateQuoteStateWithAction(PosAuditEntryType.CommitedToBuy, false)
    }

  }

  classifyLead($event:any,classification:number)
  {
    if(classification == 1)
    {
      this.quote.selectedChannel = $event;
    }
    if(classification == 2)
    {
      this.quote.selectedType = $event
    }
    this.quote.markDirty();
  }
  
  public methodToRunWhenLeadClassified: (() => void);
  private changeStatusToWhenLeadClassified: number = 0;
  public isLeadClassificationModalVisible = false;

  shouldStopForClassifyModal(methodToRunWhenSelected: () => void): boolean {
    if((this.quote.leadChannel && this.quote.selectedChannel == null) ||  (this.quote.leadType && this.quote.selectedType == null)) {
      this.methodToRunWhenLeadClassified = methodToRunWhenSelected;
      this.isLeadClassificationModalVisible = true;      
    }
    else {
      this.isLeadClassificationModalVisible = false;        
    }
    return this.isLeadClassificationModalVisible;
  }

  executeMethodToRunWhenLeadClassified() {     
    //before changing the quote, run the status change
    if (this.changeStatusToWhenLeadClassified)  {      
      this.quote.quoteStatusId = this.changeStatusToWhenLeadClassified;
      this.changeStatusToWhenLeadClassified = 0;
    }
    //now call the method, which might mark the quote as dirty, updating the status to the above.
    if (this.methodToRunWhenLeadClassified) {
      this.methodToRunWhenLeadClassified();
    }
  }



  //#endregion



//#region Save/WIP/Complete Quote
assignWIP(reserveStock: boolean) {
  // if (!this.isAllQuoteValuesValid()) {
  //   return;
  // }

  // this.isSavingQuote = true;
  //action audit
  if (this.shouldStopForClassifyModal(() => this.assignWIP(reserveStock))) {    
    this.changeStatusToWhenLeadClassified = reserveStock ? 27 : 26;    
    return;
  }

  this.auditService.addAction(new PosAuditEntry(this.quote.quoteId, PosAuditEntryType.WorkInProgress, 0));
  if (reserveStock) {
    this.auditService.addAction(new PosAuditEntry(this.quote.quoteId, PosAuditEntryType.StockReserved, 0));
  }
  this.quote.markActionLog();

  
  this.posService.assignWorkInProgress(this.quote.quoteId, reserveStock).subscribe({
    next : _ => {
      this.reportsService.openJobCard(this.quote.quoteId); // Open Jobcard in new window
      this.skipNavAway = true;
      this.router.navigate(['wip']);
    },
    error : error => {
      this.notification.handleError(error);
    }
  });
}

cancelJobCard() {
  this.showStatusModal(false);
}

tentativelyAccept(now: boolean = true) {
  //assign the tentativelyAccept function to methodToRunWhenLeadClassified

  if (this.shouldStopForClassifyModal(() => this.tentativelyAccept(now))) {
    return;
  }
  this.skipNavAway = true;
  this.navPathAway = "/leads"; //to navigate away after accepting
  if (now) {this.followUpDate = new Date();} //To make the follow up now
  this.updateQuoteStateWithAction(PosAuditEntryType.TentativelyAccepted, true);
}

completeSale() {
  //do validation checking of valid values
  // if (!this.isAllQuoteValuesValid()) {
  //   return;
  // }
  // this.isSavingQuote = true;
  //action audit
  if (this.shouldStopForClassifyModal( () => this.completeSale() )) {
    return;
  }


  this.auditService.addAction(new PosAuditEntry(this.quote.quoteId, PosAuditEntryType.SaleCompleted, 0));
  this.quote.markActionLog();

  this.quote.isDirty = true;
      this.posService.completeSale(this.quote).subscribe(
        val => {
          if (this.authService.user.featureLevel >= 3) {
            this.reportsService.openInvoice(val["saleId"]);
          }
          this.quote.isDirty = false;
          // this.modalService.create({
          //   nzTitle: 'Success',
          //   nzContent: 'Sale was successfully created. Please allow a few minutes for the sale to be inserted in your POS.',
          //   nzFooter: null
          // });
          //this.notification.ShowBlockingPopup("Sale was successfully created. Please allow a few minutes for the sale to be inserted in your POS.")
          if (this.authService.user.isPremium) {
            this.notification.showSuccessPinned("Sale was successfully created. Please allow a few minutes for the sale to be inserted in your POS.");
          }
          else {
            this.notification.showSuccessPinned("Sale was successfully created.");
          }
          this.quote.isDirty = false;

          this.skipNavAway = true;
          this.router.navigate(["sales"]);
          sessionStorage.clear();
          localStorage.removeItem('required');
          // this.notification.showSuccess("Sale successfully created");

        },
        error => {

          this.notification.handleError(error);
          this.quote.isDirty = false;
        }
      )
   

}

checkCanClickComplete() : boolean {
  return this.quote.hasCustomer && !this.isTyreWithZeroPrice();
} 

getCompleteButtonTooltip() : string {
  return !this.quote.hasCustomer ? 'Select Customer Details First!' : this.quote.booking ? 'BOOKED: ' + this.quote.booking.dateBooked + ' ' + this.quote.booking.timeBooked : this.isTyreWithZeroPrice() ? 'Cannot have retail of R0' : '';
}

private isTyreWithZeroPrice() : boolean {
  return this.quote.lines.some(e => e.isTyre && (e.priceIncl === 0 || e.price === 0));
}

//#endregion


onRequoteClick()
  {

    this.skipNavAway = true;
    this.isLoading = true;
    let search: string = 'NOSEARCH'
    if(this.quote.width && this.quote.profile && this.quote.rim){
    search = this.quote.width.toString() + this.quote.profile.toString() + this.quote.rim.toString();
    }
    
    let msfidsTyres = this.quote.lines.filter(e => e.isTyre === true).map(e => e.msfid);
    let customStockIds = this.quote.lines.filter(e => e.isTyre === false && e.customStockId).map(e => e.customStockId);
    let vreg = ""; //add vreg?
    
    let quote : aautoquote = {
      sellerId : this.authService.user.client_id,
      ext_Ref : "",
      comment :"",
      name: this.quote.custName,
      cell: this.quote.custCell,
      email: this.quote.custEmail,
      vreg: vreg,
      assignedToUserId: this.authService.user.user_id,
      lastcall: parseInt(localStorage.getItem('lastcall')!),
      quote_logic : {
                      search_requirements :
                                            {
                                              raw_search: search,
                                              stock_msfids: msfidsTyres,
                                              none_stock_msfids: [],
                                              custom_stock_ids: customStockIds,
                                              buyerId: this.authService.user.client_id,
                                              specialPriceSetId: this.quote.specialPriceSetId
                                            },
                      add_lead: true,
                      qty: 4,
                      allow_partial_full:true,
                      base_level_limit: "Remote",
                      add_recommended_stock: true,
                      max_recommended_options: 3,
                      recommended_level_limit: "Remote",
                      add_default_services: false,
                      isContractPricing: false
                    }
    };
  

    this.searchService.Autoquote(quote, {customerId : this.quote.custId, vehicleId : this.quote.Vehicle_Customer_MappingId}).subscribe({
      next: res => {
         this.isLoading = false;
         var quoteId = res["quoteId"];
         if(quoteId != 0)
         {
            this.isLoading = false;
            this.notification.showSuccess('Quote Created');
            this.router.navigate(["/pos/v2",quoteId])
         }
      },
      error: error => {
        this.isLoading = false;
        this.notification.handleError(error);
      }
    });
  }

onReactivateClick(){
  this.reActivate = true;
  this.quote.stateId = 1;
  this.quote.quoteStatusId = 29; //reactivate status id
  this.updateQuoteStateWithAction(PosAuditEntryType.ReactivateQuote);
}


//#region BOOKINGS
      
  bookingSlots: BookingSlot[] = [];
  bookingSelectedDate: string = "";
  datePipe: DatePipe = new DatePipe(this.posService.locale);
  isBookingModalVisible = false;
  isLoadingBookingSlots = false;

  onBookingClick() {
    if (this.shouldStopForClassifyModal(() => this.onBookingClick())) {
      return;
    }

    this.isBookingModalVisible = true;
    this.loadSlots(new Date());
    this.bookingSelectedDate = this.datePipe.transform(new Date(),'dd MMM')!;
  }

  private loadSlots(date: Date) {
    this.isLoadingBookingSlots = true;
    this.posService.getBookingSlotsPerDay(date).subscribe(
      val => {
        this.bookingSlots = val;
        this.isLoadingBookingSlots = false;
      },
      error => {
        this.notification.handleError(error);
      }
    )
  }

  onBookingDateSelected(val: Date) {
    

    this.bookingSelectedDate = this.datePipe.transform(val,'dd MMM')!;
    this.loadSlots(val);
  }

  onSlotSelected(i: BookingSlot){

    this.followUpDate = new Date(i.date + "T00:00");
    this.auditService.addAction(new PosAuditEntry(this.quote.quoteId, PosAuditEntryType.SalesmanBookedSlot, 0, i.date + " " + i.slotTime));

    this.posService.confirmBooking(this.quote.quoteRef, this.quote.quoteId, i.date, i.slotTime).subscribe(
      val => {
        this.tentativelyAccept(false);
      },
      error => {
        this.notification.handleError(error);
      }
    );
  }
//#endregion


//#region FLEET AUTH

sbauthForm: FormGroup = new FormGroup({
  odometer: new FormControl('', Validators.required),
  drivername: new FormControl('', Validators.required),
  drivercell: new FormControl('', Validators.required),
});

isSbAuthModalVisible = false;
public showSBAuth() {
  const selectedVehicle = this.quote.customerVehicles.find(x =>  x.Vehicle_Customer_MappingId === this.quote.Vehicle_Customer_MappingId);
  if (!selectedVehicle || !selectedVehicle.vehicleMakeId || !selectedVehicle.vehicleModelId || !selectedVehicle.v_reg) {
    this.notification.showError("Please enter vehicle details, make and model and registration, for Standard Bank Fleet Auth");
    return;
  }
  this.isSbAuthModalVisible = true;
}

public onSbAuthSubmit() {
  //valication: TODO: Move to proper form validation
  if(this.sbauthForm.value.odometer == ""  || this.sbauthForm.value.drivercell == "" || this.sbauthForm.value.drivername == "" ) {
     this.notification.ShowAlert({type: resultType.Warning, 
       title: "Incomplete Submission", 
       htmlMessage: 'Please check that all fields are completed before submitting' ,
       maskClosable: false, 
       autoCloseInMilliseconds: 10000});
       return
  }
  const selectedVehicle = this.quote.customerVehicles.find(x =>  x.Vehicle_Customer_MappingId === this.quote.Vehicle_Customer_MappingId);
  if(selectedVehicle === undefined) {
    this.notification.ShowAlert({type: resultType.Warning, 
      title: "Incomplete Submission", 
      htmlMessage: 'Vehicle was not found' ,
      maskClosable: false, 
      autoCloseInMilliseconds: 5000});
      return
  }
  this.isLoading = true;
  this.auditService.addAction(new PosAuditEntry(this.quote.quoteId, PosAuditEntryType.SaleCompleted, 0));
  this.quote.markActionLog();

  //valid form, prep for submission
  this.quote.sbFleetAuthData = new sbFleetAuthData();
  this.quote.sbFleetAuthData.odo = this.sbauthForm.value.odometer;
  this.quote.sbFleetAuthData.driver_name = this.sbauthForm.value.drivername; 
  this.quote.sbFleetAuthData.driver_cell = this.sbauthForm.value.drivercell;
  this.quote.authType = 1; //SB Fleet Auth
  this.quote.isSaving = true;
  this.quote.isDirty = true;
  this.posService.saveQuote(Object.assign({}, this.quote)).subscribe({
    next: _ => {  
      this.posService.completeSale(this.quote).subscribe({next: () => {
        this.quote.isSaving = false;
        this.quote.isDirty = false;
        this.quote.stateId = 3; //lock user from changing any values - 3 = Completed
        this.isSbAuthModalVisible = false;
        this.isLoading = false;
        this.notification.ShowAlert(({type: resultType.Success, title: "Success", htmlMessage: 'Auth Was Successfully Submitted. In a few seconds you will get the result.', maskClosable: false, autoCloseInMilliseconds: 10000}));
        this.skipNavAway = true;
        this.router.navigate(["sales"]);
      },
      error: error => {
        this.quote.isSaving = false;
        this.isLoading = false;
        this.quote.isDirty = false;   
        this.notification.handleError(error);
        this.skipNavAway = true;
        this.router.navigate(["sales"]);
      }})        
        
    },
    error: error => {
      this.quote.isSaving = false;  
      this.isLoading = false;
      this.quote.isDirty = false;        
      this.notification.handleError(error);          
    }})      
      
    

}


//#endregion
}
