<nz-spin [nzSpinning]="isLoadingSearch">
    <!-- <div class="form" > -->
    <nz-input-group [nzSuffix]="suffixIconButton">
        <input SFAutoFocus [(ngModel)]="inputValue" (ngModelChange)="onChange($event)" [nzAutocomplete]="auto" nz-input (keyup.enter)="onPressEnter($event)" (keyup.escape)="filteredOptions = []" id="search" placeholder="Search Here" style="text-transform:uppercase;">
        <nz-autocomplete [nzDefaultActiveFirstOption]="true" #auto style="text-transform:uppercase;" (selectionChange)="onSelectFromDropDown($event)">
            <nz-auto-option *ngFor="let option of filteredOptions" [nzValue]="option">
                <span nz-icon nzType="{{getSearchTypeIcon(option.searchType)}}" nzTheme="outline" class="color-primary"></span> {{option.value}}

            </nz-auto-option>
        </nz-autocomplete>
        <!-- <span class="left-pan">
      <div class="f-box"
           (click)="onSelect($event)"
           nz-tooltip
           [nzTooltipTitleContext]="'Searching With StockFinder'"
           [nzTooltipTitle]="titleTemplate"
           nzTooltipPlacement="right"
           [nzTooltipMouseEnterDelay]="1"
           [nzTooltipColor]="color"
         >
        <i nz-icon nzType="search" nzTheme="outline"></i>
      </div>
    </span> -->
    </nz-input-group>
    <ng-template #suffixIconButton>
        <button nz-button nzType="primary" nzSearch [nzSize]="'small'" (click)="onPressEnter($event)" nz-tooltip [nzTooltipTitleContext]="'Searching With StockFinder'" [nzTooltipTitle]="titleTemplate" nzTooltipPlacement="right" [nzTooltipMouseEnterDelay]="1">
        <span nz-icon nzType="search"></span>
      </button>
    </ng-template>
    <!-- </div> -->

    <ng-template #titleTemplate>
        <span>
       Search with gencodes - simply search the width, profile and rim as one search term, e.g. 2656517<br>
      <a routerLink="/help"
         style="color:var( --textcolor)">
         <i nz-icon nzType="video-camera" nzTheme="outline"></i>
         watch tutorial
      </a>
    </span>
    </ng-template>
</nz-spin>
<div class="form">
    <!--link logic here--->

    <div *ngIf="searchmore" style="padding-top: 10px;">

        <nz-button-group>
            <button nz-button nzType="primary" (click)="onSearch()"> Search Stock</button>
            <button nz-button nzType="primary" (click)="openCustomer()"> New Customer</button>
        </nz-button-group>
    </div>

    <!--Search Logic-->
    <div class="text-center" *ngIf="isSerching">
        <ng-template #indicatorTemplate style="font-size: 20px;">
            <h6>Searching <i nz-icon nzType="loading"></i></h6>
            <br />
        </ng-template>
        <nz-spin [nzIndicator]="indicatorTemplate" nzSpinning="isSerching">
            &nbsp;<br /><br />
        </nz-spin>


    </div>
</div>
<nz-modal [(nzVisible)]="isCustomerModalVisible" nzClosable="true" nzTitle="New Customer" (nzOnCancel)="isCustomerModalVisible = false;" (nzOnOk)="AddCustomer()" [nzFooter]="null">
    <div *nzModalContent>
        <form nz-form [formGroup]="customerForm" class="text-center">
            <nz-form-item>
                <nz-form-control [nzErrorTip]="'Name required'">
                    <label class="text-style">Name</label>
                    <input nz-input name="name" formControlName="name" placeholder="Customer Name">
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control [nzErrorTip]="'10 Digit Cell Number required'">
                    <label class="text-style">Cell</label>
                    <input (keypress)="numberOnly($event)" maxlength="10" nz-input name="cell" formControlName="cell" placeholder="Customer Cell">
                </nz-form-control>
            </nz-form-item>
            <hr style="border-top:2px solid var(--backcolor);">
            <nz-form-item>
                <nz-form-control>
                    <small class="text-style">*Optional*</small>
                    <input nz-input name="vreg" formControlName="vreg" placeholder="Vehicle Registration">
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control>
                    <small class="text-style">*Optional*</small>
                    <input nz-input name="email" formControlName="email" placeholder="Customer Email">
                </nz-form-control>
            </nz-form-item>
        </form>
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-2 offset-5">
                    <button nz-button nzType="primary" (click)="AddCustomer()">Save</button>
                </div>
            </div>
        </div>
    </div>
</nz-modal>