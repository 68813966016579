<div class="container-fluid">
    <div class="row justify-content-md-center">
        <div class="col-11 bg-1">
            <div class="container-fluid" style="padding-top: 20px;padding-bottom: 30px;">
                <div nz-row nzJustify="center">
                    <div nz-col nzSpan="22">
                        <nz-card nzHoverable>
                            <div nz-row>
                                <div nz-col nzSpan="12" class="text-center">
                                    <span class="text-style">Quote </span> - {{posQuote.quoteRef}}
                                </div>
                                <div nz-col nzSpan="12" class="text-center">
                                    <span class="text-style">Salesman </span> - {{posQuote.user.userName}} {{ posQuote.user.userCell }}
                                </div>
                            </div>
                        </nz-card>
                    </div>
                </div>
                <br>
                <br>
                <nz-skeleton [nzLoading]="isLoading || isLoadingCustomer" [nzActive]="true">
                    <div *ngIf="posQuote.custId === 14041 || posQuote.custId === 0; else customerDetails">

                        <div nz-row>
                            <div nz-col [nzSpan]="12" [nzOffset]="6" style="text-align: center;">
                                <nz-select (ngModelChange)="linkCustomer($event)" [(ngModel)]="selectedCustomer" [nzLoading]="isLoadingCustomers" style="width: 400px;font-size: 20px;" class="text-center" nzPlaceHolder="...Search Customer..." nzShowSearch nzLayout="inline" nzAllowClear
                                    [nzNotFoundContent]="noCustomerResults" (nzOnSearch)="filterCustomer($event)">
                                    <nz-option *ngFor="let c of customers" [nzValue]="c.customerId" [nzLabel]="c.customer">
                                    </nz-option>
                                </nz-select>
                            </div>
                            <div nz-col [nzSpan]="6">
                                <button nz-button nzType="primary" class="float-right" style="border:none; width: 200px; font-size: 1rem;height: 40px;" (click)="clickSendQuote()" [nzLoading]="isEmailingQuote">
                                    <i nz-icon nzType="share-alt" nzTheme="outline"
                                        style="vertical-align: middle !important; margin-top: -5px; font-size: 1.5rem !important;"></i>
                                    Share Quote
                                </button>
                            </div>
                        </div>

                    </div>
                    <br>
                    <ng-template #customerDetails>
                        <nz-spin [nzSpinning]="isLoadingCustomer">
                            <div class="row">
                                <div class="col-md-3 float-left">
                                    <app-pq-customer-details [posQuote]="posQuote" [isWIP]="this.isWIP" [isCompleted]="this.isCompleted" *ngIf="posQuote.quoteId != null && !isAddingCustomer" (removeCustomerEvent)="linkCustomer($event)">
                                    </app-pq-customer-details>
                                </div>
                                <div class="col-md-6" style="text-align: center">
                                    <div class="justify-content-md-center">
                                        <h5>{{posQuote.state ? posQuote.state.description : ""}} {{posQuote.state ? posQuote.state.dt_processed ? " - " + posQuote.state.dt_processed : "" : ""}}
                                        </h5>

                                    </div>
                                    <!-- <div id="circle" style="display: inline-block;">
                                        <div class="loader">
                                            <div class="loader">
                                                <div class="loader">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="percent text-center">
                                            {{getStatePercentage()}}
                                        </div>
                                    </div> -->
                                    <nz-spin [nzSpinning]="isLoadingLog">
                                        <div style="text-align: left;">
                                            <span style="text-align: left; background-color: #222222; border-top-left-radius: 20px; border-top-right-radius: 20px; padding: 8px;color: #cccccc">
                                                <b>ACTION LOG</b>
                                            </span>
                                        </div>
                                        <div style="background-color: #222222; border-bottom-left-radius: 20px;border-bottom-right-radius: 20px; border-top-right-radius: 20px;
                                        padding: 8px;">
                                            <div *ngFor="let item of truncActionLog" style="text-align: left; color: #cccccc">
                                                <small>{{ datepipe.transform(item.dateActioned, 'dd MMM YYYY HH:mm') }}
                                                    -
                                                    {{item.description}}{{item.comment.length > 0 ? " (" + item.comment
                                                    + ")" : ""}}</small>
                                            </div>
                                            <div style="text-align: left; padding-top: 10px;">
                                                <button nz-button nzType="primary" (click)="showFullActionLog()">
                                                    View Full Log
                                                </button>
                                            </div>
                                        </div>

                                    </nz-spin>

                                </div>
                                <div class="col-md-3">
                                    <div class="row">
                                        <div class="col-12">
                                            <button nz-button nzType="primary" class="btn float-right" style="border:none; width: 200px; font-size: 1rem;height: 40px;" (click)="clickSendQuote()" [nzLoading]="isEmailingQuote">
                                                <i nz-icon nzType="share-alt" nzTheme="outline"
                                                    style="vertical-align: middle !important; margin-top: -5px; font-size: 1.5rem !important;"></i>
                                                Share Quote
                                            </button>
                                        </div>
                                        <!-- <div class="col-12 pt-1">
                                            <button class="btn float-right" style="border:none; width: 200px; font-size: 1rem;height: 40px;" (click)="clickEmailQuote()" nz-button nzType="default" [nzLoading]="isEmailingQuote">
                                                <i nz-icon nzType="mail" nzTheme="fill"
                                                    style="vertical-align: middle !important; margin-top: -5px; font-size: 1.5rem !important;"></i>
                                                Email Quote
                                            </button>
                                        </div>
                                        <div class="col-12 pt-1">
                                            <button class="btn float-right" style="border:none; width: 200px; font-size: 1rem;height: 40px;" (click)="viewQuote()" nz-button nzType="default" [nzLoading]="isEmailingQuote">
                                                <i nz-icon nzType="eye" nzTheme="fill"
                                                    style="vertical-align: middle !important; margin-top: -5px; font-size: 1.5rem !important;"></i>
                                                View Quote
                                            </button>
                                        </div> -->
                                        <div class="col-12 pt-1">
                                            <button nz-button nzType="primary" class="btn float-right" style="width: 200px;" (click)="showStatusModal(false)" *ngIf="this.isCompleted == false && this.isWIP === false">
                                                <!-- <i nz-icon nzType="mail" nzTheme="fill"
                                            style="vertical-align: middle; margin-top: -2px; font-size: 1.5rem;"></i> -->
                                                <span nz-icon nzType="edit" nzTheme="outline"></span>
                                                Update Status
                                            </button>
                                            <button nz-button nzType="primary" class="btn float-right" style="width: 200px;" (click)="printJobCard()" *ngIf="this.isCompleted == false && this.isWIP === true">
                                                <!-- <i nz-icon nzType="mail" nzTheme="fill"
                                            style="vertical-align: middle; margin-top: -2px; font-size: 1.5rem;"></i> -->
                                                <span nz-icon nzType="credit-card" nzTheme="outline" style="margin-right: 5px;"></span>
                                                Print Jobcard
                                            </button>
                                        </div>
                                        <div class="col-12 pt-1">
                                            <button nz-button nzType="primary" class="btn float-right" style="width: 200px;" (click)="navigateCustomers()">
                                                <i nz-icon nzType="user" nzTheme="outline"></i>
                                                Customer Info
                                            </button>
                                        </div>
                                        <div class="col-12 pt-1" *ngIf="this.posQuote.wbcActive">
                                            <button nz-button nzType="primary" class="btn float-right btn-orange" style="width: 200px;" (click)="showAddVehiclModal()">                                              
                                                <img src="./../../../assets/Images/dealers/wbc.png" width="25" height="25" >
                                                Send WBC Quote
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </nz-spin>
                    </ng-template>
                </nz-skeleton>

                <ng-template #noCustomerResults>
                    No results found.
                    <button nz-button nzType="primary" class="btn" (click)="showAddCustomerModal()">
                        <i nz-icon nzType="user-add" nzTheme="outline"
                            style="vertical-align: middle; margin-top: -5px;"></i>
                        Add new
                    </button>
                </ng-template>

                <nz-modal [(nzVisible)]="isAddCustomerModalVisible" nzTitle="Add New Customer" (nzOnCancel)="isAddCustomerModalVisible = false">
                    <div *nzModalContent>
                        <form nz-form [formGroup]="addCustomerForm">
                            <nz-form-item>
                                <nz-form-control [nzErrorTip]="'Vehicle Registration or Cell Number required'">
                                    <nz-input-group nzPrefixIcon="car">
                                        <input nz-input name="custVReg" formControlName="custVReg" placeholder="Vehicle Registration">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item>
                                <nz-form-control>
                                    <nz-input-group nzPrefixIcon="user">
                                        <input style="text-transform: capitalize;" nz-input name="custName" formControlName="custName" placeholder="Customer Name">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item>
                                <nz-form-control>
                                    <nz-input-group nzPrefixIcon="phone">
                                        <input nz-input name="custCell" formControlName="custCell" placeholder="Cell Number">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item>
                                <nz-form-control>
                                    <nz-input-group nzPrefixIcon="mail">
                                        <input nz-input name="custEmail" formControlName="custEmail" placeholder="Email Address">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item>
                                <nz-form-control>
                                    <nz-input-group nzPrefixIcon="bank">
                                        <input nz-input name="custDebtorAcc" formControlName="custDebtorAcc" placeholder="Debtor Account">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </form>
                    </div>
                    <div *nzModalFooter>
                        <button nz-button nzType="default" class="btnred" (click)="isAddCustomerModalVisible = false">
                            <i nz-icon nzType="close" nzTheme="outline"
                                style="vertical-align: middle; margin-top: -5px;"></i>Cancel
                        </button>
                        <button nz-button nzType="default" class="btn" (click)="addCustomer()">
                            <i nz-icon nzType="check" nzTheme="outline"
                                style="vertical-align: middle; margin-top: -5px;"></i>Add
                        </button>
                    </div>
                </nz-modal>

                <!-- BUTTONS TO ADD MORE ITEMS -->
                <div *ngIf="this.isCompleted == false" class="row justify-content-md-center">
                    <div class="col-11">
                        <app-pq-add-lines [posQuote]="posQuote" (itemAddedEvent)="addItemToQuote($event)">
                        </app-pq-add-lines>
                    </div>
                </div>

                <!-- RIGHT FLOATING SAVE AND CLEAR BUTTONS -->
                <div class="row justify-content-md-center">
                    <div class="col-11 text-right">
                        <button nz-button nzType="primary" *ngIf="this.isCompleted == false" (click)="saveQuote()" class="float-left" style="margin: 10px;">
                            <i nz-icon nzType="save"  style="font-size: 1.5rem; vertical-align: top; "  nzTheme="outline" nz-tooltip nzTooltipTitle="Save Quote"></i>
                        </button>
                        <button nz-button nzType="primary" *ngIf="this.isCompleted == false" (click)="clearQuote()" style="margin: 10px;">
                            <i nz-icon nzType="delete" nzTheme="outline" style="font-size: 1.5rem; vertical-align: top; " nz-tooltip nzTooltipTitle="Clear Quote"></i>

                        </button>
                    </div>
                </div>

                <!-- MAIN TABLE WITH ITEMS -->
                <div nz-row nzJustify="center" nzAlign="middle">
                    <div nz-col nzXXl="22">
                        <nz-table #quoteLinesTable [nzData]="posQuote.quoteLines" [nzLoading]="isLoading" [nzBordered]="false" [nzOuterBordered]="false" nzSize="small" [nzFrontPagination]="false" [nzScroll]="{ x: '1100px' }">
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Brand</th>
                                    <th>Code</th>
                                    <th>SOH</th>
                                    <th (click)="this.showCost = !this.showCost" style="cursor: pointer;"><i nz-icon nzType="eye" nzTheme="outline" style="vertical-align: baseline;"></i></th>
                                    <th (click)="isQtyLocked = !isQtyLocked" [style.color]="isQtyLocked ? 'var(--textcolor)' : ''" style="cursor: pointer;">
                                        <i nz-icon nzType="lock" nzTheme="fill" *ngIf="isQtyLocked" style="vertical-align: middle;"></i>
                                        <i nz-icon nzType="unlock" nzTheme="fill" *ngIf="!isQtyLocked" style="vertical-align: middle;"></i> Qty
                                    </th>
                                    <th>Price (incl VAT)</th>
                                    <th>Total Price</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of quoteLinesTable.data; let i = index">
                                    <td>
                                        <nz-badge [nzCount]="data.recommended ? recommendedTemplate : 0">

                                            <i nz-icon nzType="warning" nzTheme="fill" style="color: orange; font-size: 1rem; vertical-align: middle; margin-bottom: 3px;" *ngIf="getQuoteLineWarningTextSeller(data) || getQuoteLineWarningTextCost(data)" nz-tooltip [nzTooltipTitle]="costPriceWarningTemplate"></i>
                                            <span style=" padding-right: 30px">{{ data.description }}</span>

                                        </nz-badge>
                                        <ng-template #recommendedTemplate>
                                            <i nz-icon nzType="check-circle" class="ant-scroll-number-custom-component" style="color: var(--textcolor); margin-top: 4px; margin-left: 5px;" nz-tooltip="Recommended Option"></i>
                                        </ng-template>
                                        <ng-template #costPriceWarningTemplate>
                                            {{getQuoteLineWarningTextSeller(data)}} <br *ngIf="getQuoteLineWarningTextSeller(data)" /> {{getQuoteLineWarningTextCost(data)}}
                                        </ng-template>
                                    </td>
                                    <td>
                                        <div *ngIf="!data.brandLogoUrl; else brandLogoImage">{{ data.brand }}</div>
                                        <ng-template #brandLogoImage>
                                            <img src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{data.brandLogoUrl}}" class="brandImage" alt="{{data.brand}}" nz-tooltip nzTooltipTitle="{{data.brand}}" nzTooltipPlacement="right" />
                                        </ng-template>
                                    </td>
                                    <td>{{ data.stockCode }}</td>
                                    <td>{{ data.soh }}</td>
                                    <td>
                                        <div *ngIf="showCost && data.cost !== 0 ">{{ data.cost }}</div>
                                    </td>
                                    <td>
                                        <nz-input-number [ngModel]="data.qty" [nzMin]="1" [nzMax]="500" [nzStep]="1" style="width: 55px;" (ngModelChange)="updateLineItem(i,'QTY',$event)">
                                        </nz-input-number>
                                    </td>
                                    <td>
                                        <ng-template #toolTipTemplate>
                                            <table>
                                                <tr>
                                                    <td>
                                                        R {{data.gpRandsHigh}}
                                                    </td>
                                                    <td>
                                                        <div style="padding-left: 10px;">{{data.gpPercentHigh}}%</div>
                                                    </td>
                                                    <td>
                                                        <button nz-button nzType="primary" (click)="updateLineItemGP(data, data.gpRandsHigh,'+3')" class="btn">R {{
                                                            data.fiveHigh}}</button>
                                                    </td>
                                                </tr>
                                                <tr style="border-top: lightgray 1px solid;">
                                                    <td>
                                                        R {{data.gpRands}}
                                                    </td>
                                                    <td>
                                                        <nz-input-number [ngModel]="data.gpPercent" [nzMin]="1" [nzStep]="1" style="width: 75px;" (ngModelChange)="updateGpPercent(data, $event)" [nzFormatter]="percentFormatter"></nz-input-number>
                                                    </td>
                                                    <td>
                                                        <button nz-button nzType="primary" disabled class="btn">R {{ data.priceIncl }}</button>
                                                    </td>
                                                </tr>
                                                <tr style="border-top: lightgray 1px solid;">
                                                    <td>
                                                        R {{data.gpRandsLow}}
                                                    </td>
                                                    <td>
                                                        <div style="padding-left: 10px;">{{data.gpPercentLow}}%</div>
                                                    </td>
                                                    <td>
                                                        <button nz-button nzType="primary" (click)="updateLineItemGP(data, data.gpRandsLow,'-3')" class="btn">R {{
                                                            data.fiveLow}}</button>
                                                    </td>
                                                </tr>
                                            </table>

                                        </ng-template>
                                        <div nz-popover nzPopoverPlacement="leftTop" [nzPopoverContent]="toolTipTemplate" *ngIf="(data.stockType && data.stockType.toUpperCase() == 'TYRE'); else nonTyrePrice">
                                            <nz-input-group [nzPrefix]="'R'">
                                                <nz-input-number [ngModel]="data.priceIncl" [nzStep]="1" (ngModelChange)="updateLineItem(i,'PRICE',$event)" style="width: 100%;  border: none;">
                                                </nz-input-number>
                                            </nz-input-group>
                                        </div>
                                        <ng-template #nonTyrePrice>
                                            <nz-input-group [nzPrefix]="'R'">
                                                <nz-input-number [ngModel]="data.priceIncl" [nzStep]="1" style="width: 100px;" (ngModelChange)="updateLineItem(i,'PRICE',$event)" style="width: 100%;  border: none;">
                                                </nz-input-number>
                                            </nz-input-group>
                                        </ng-template>
                                    </td>
                                    <ng-template #profitTooltip>
                                        <span style="color:var(--textcolor)"><b>OPTION TOTAL</b></span><br /> R {{calcOptionPriceIncl(data).toFixed(2)}}<br /> GP: R{{calcOptionGPExcl(data).toFixed(2)}} <small style="color: var(--textcolor)">ex vat</small>

                                    </ng-template>
                                    <td>
                                        <div nz-popover nzPopoverPlacement="rightTop" [nzPopoverContent]="profitTooltip" *ngIf="(data.stockType && data.stockType.toUpperCase() == 'TYRE'); else nonStockTotal">
                                            R <b class="text-style">{{ data.totalPriceIncl }} </b>
                                            <div class="smallText">
                                                <sup style="color: seagreen">{{ data.gpPercent }}</sup>
                                                <sub>{{ data.clientName }}</sub>
                                            </div>
                                        </div>
                                        <ng-template #nonStockTotal>
                                            R <b class="text-style">{{ data.totalPriceIncl }} </b>
                                            <div class="smallText">
                                                <sup style="color: seagreen">{{ data.gpPercent }}</sup>
                                                <sub>{{ data.clientName }}</sub>
                                            </div>
                                        </ng-template>
                                    </td>
                                    <td>
                                        <span *ngIf="this.isCompleted == false">
                                            <button nz-button nzType="primary" class="btn btnremove" (click)="removeLineItem(i)">
                                                <i nz-icon nzType="minus" nzTheme="outline" nz-tooltip nzTooltipTitle="Remove Item"></i>
                                            </button>
                                            <span *ngIf="data.stockType && data.stockType.toUpperCase() == 'TYRE'">
                                                <nz-divider nzType="vertical"></nz-divider>
                                                <button nz-button nzType="primary" class="btn btnaccept" (click)="selectLineItem(i)">
                                                    <i nz-icon nzType="check" nzTheme="outline" nz-tooltip nzTooltipTitle="Select Item"></i>
                                                </button>
                                            </span>
                                        </span>
                                    </td>
                                </tr>
                                <tr *ngIf="this.isCompleted == false">
                                    <td>
                                        <input nz-input placeholder="Stock Code" [(ngModel)]="searchString" (keyup.enter)="searchStockInput()" class="submit trinput" style="width: 50%;text-transform: uppercase;" />
                                    </td>
                                    <td colspan="8">

                                    </td>
                                </tr>
                            </tbody>
                        </nz-table>
                        <br>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="float-right">
                                    <h4>Total : <span class="text-info-style">R
                                        </span>{{totalPriceInc()}}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-md-center">
                    <div class="col-10">
                        <span class="text-left" style="font-size: 1rem;">Comment:</span>
                        <div class="input-group text-center">
                            <input nz-input [(ngModel)]="posQuote.comment" class=" form-control txtarea" placeholder="Comment to Customer..." (keypress)="$event.keyCode == 13 ? savecomment() : null" [maxlength]="200" />
                            <div class="input-group-append ">
                                <button nz-button nzType="primary" class="btn btn-sm " (click)="savecomment()">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="row justify-content-md-center"> -->
                <div nz-row nzJustify="space-between" style="padding-top: 30px;" *ngIf="this.isCompleted == false">
                    <div nz-col nzSpan="7">
                        <div *ngIf="this.posQuote.quoteLines && this.posQuote.quoteLines.length > 0 && this.isWIP === false">
                            <button nz-button nzType="default" class="btn submit float-left" style="height: 40px; font-size: 1rem;" (click)="tentativelyAccept()" [disabled]="posQuote.custId === 14041 || posQuote.custId === 0" [nz-tooltip]="posQuote.custId === 14041 || posQuote.custId === 0 ? 'Select Customer Details First!' : ''"
                                [nzLoading]="isSavingQuote" *ngIf="!authService.hasClaims(['WIP'])">
                                <i nz-icon nzType="clock-circle" nzTheme="fill"
                                    style="font-size: 1.5rem; vertical-align: middle; margin-top: -6px;"></i> Tentatively Accept
                            </button>
                            <button nz-button nzType="default" *ngIf="this.authService.user.bookingsEnabled === true" class="btn submit float-left" style="height: 40px; font-size: 1rem; margin-left: 10px;" (click)="onBookingClick()" [disabled]="posQuote.custId === 14041 || posQuote.custId === 0"
                                [nz-tooltip]="posQuote.custId === 14041 || posQuote.custId === 0 ? 'Select Customer Details First!' : posQuote.booking ? 'BOOKED: ' + posQuote.booking.dateBooked + ' ' + posQuote.booking.timeBooked : ''" [nzLoading]="isSavingQuote"
                                [ngClass]="{'text-info-style' : posQuote.booking}">
                                <i nz-icon nzType="calendar" nzTheme="fill"
                                    style="font-size: 1.5rem; vertical-align: middle; margin-top: -6px;"
                                    ></i> {{posQuote.booking ? 'BOOKED: ' + posQuote.booking.dateBooked + ' ' + posQuote.booking.timeBooked.toString().padStart(2,"0").substring(0,5) : 'Booking'}}
                            
                            </button>
                        </div>
                        <div *ngIf="this.isWIP">
                            <button nz-button nzType="default" class="btn submit float-left" style="height: 40px; font-size: 1rem;" (click)="cancelJobCard()" [disabled]="posQuote.custId === 14041 || posQuote.custId === 0" [nz-tooltip]="posQuote.custId === 14041 || posQuote.custId === 0 ? 'Select Customer Details First!' : ''"
                                [nzLoading]="isSavingQuote" *ngIf="this.isWIP">
                                <i nz-icon nzType="close-circle" nzTheme="fill"
                                    style="font-size: 1.5rem; vertical-align: middle; margin-top: -6px;"></i> Cancel Jobcard
                            </button>
                        </div>


                    </div>

                    <div nz-col nzSpan="13" *ngIf="authService.hasClaims(['WIP']) && authService.user.featureLevel >= 2">
                        <div *ngIf="this.posQuote.quoteLines && this.posQuote.quoteLines.length > 0 && this.isWIP === false">
                            <span style="font-size: 1rem;">Work In Progress:</span>
                            <button nz-button nzType="default" class="btn submit text-center" style="height: 40px; font-size: 1rem; margin-left: 10px; border-bottom-right-radius: 0px; border-top-right-radius: 0px;" (click)="assignWIP(true)" [disabled]="posQuote.custId === 14041 || posQuote.custId === 0"
                                [nz-tooltip]="posQuote.custId === 14041 || posQuote.custId === 0 ? 'Select Customer Details First!' : posQuote.booking ? 'BOOKED: ' + posQuote.booking.dateBooked + ' ' + posQuote.booking.timeBooked : ''" [nzLoading]="isSavingQuote"
                                [ngClass]="{'text-info-style' : posQuote.booking}">
                                <i nz-icon nzType="plus-circle" nzTheme="fill"
                                    style="font-size: 1.5rem; vertical-align: middle; margin-top: -6px;"
                                    ></i> 
                                    Reserve Stock
                            </button>
                            <button nz-button nzType="default" class="btn submit text-center" style="height: 40px; font-size: 1rem;border-bottom-left-radius: 0px; border-top-left-radius: 0px;" (click)="assignWIP(false)" [disabled]="posQuote.custId === 14041 || posQuote.custId === 0"
                                [nz-tooltip]="posQuote.custId === 14041 || posQuote.custId === 0 ? 'Select Customer Details First!' : posQuote.booking ? 'BOOKED: ' + posQuote.booking.dateBooked + ' ' + posQuote.booking.timeBooked : ''" [nzLoading]="isSavingQuote"
                                [ngClass]="{'text-info-style' : posQuote.booking}">
                                <i nz-icon nzType="minus-circle" nzTheme="fill"
                                    style="font-size: 1.5rem; vertical-align: middle; margin-top: -6px;"
                                    ></i> 
                                    Dont Reserve Stock
                            </button>
                        </div>

                    </div>
                    <div nz-col nzSpan="13" *ngIf="!authService.hasClaims(['WIP']) || authService.user.featureLevel < 2">

                    </div>
                    <div nz-col nzSpan="4" *ngIf="(authService.hasClaims(['WIP']) && this.isWIP === true) || (!authService.hasClaims(['WIP']) || (authService.user.featureLevel < 2)); else noSaveTemplate">
                        <button nz-button nzType="default" *ngIf="this.posQuote.quoteLines && this.posQuote.quoteLines.length > 0" class="btn submit float-right" style="height: 40px; font-size: 1rem;" (click)="completeSale()" [disabled]="posQuote.custId === 14041 || posQuote.custId === 0"
                            [nz-tooltip]="posQuote.custId === 14041 || posQuote.custId === 0 ? 'Select Customer Details First!' : ''" [nzLoading]="isSavingQuote">
                            <i nz-icon nzType="check-circle" nzTheme="fill" 
                                style="font-size: 1.5rem; vertical-align: middle; margin-top: -6px;"></i><span *ngIf="!this.isWIP || authService.user.featureLevel < 3">Complete Sale</span><span *ngIf="this.isWIP && authService.user.featureLevel === 3">Invoice Customer</span>
                        </button>

                    </div>
                    <ng-template #noSaveTemplate>
                        <div nz-col nzSpan="4">

                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>

<nz-modal [(nzVisible)]="isSearchStockModalVisible" nzTitle="Stock Availability" (nzOnCancel)="isSearchStockModalVisible = false" [nzFooter]="null" [nzWidth]="'900px'">
    <div *nzModalContent>
        <nz-tabset>
            <nz-tab nzTitle="Local">
                <nz-table #searchTable1 [nzData]="filterSearchStock(1)" [nzLoading]="isLoadingStockSearch" [nzFrontPagination]="false" [nzBordered]="false" [nzOuterBordered]="false" nzSize="small" [nzNoResult]="stockSearchNoResults">
                    <thead>
                        <tr>
                            <th>Brand</th>
                            <th>Description</th>
                            <th>SOH</th>
                            <th>SLA</th>
                            <th>Supplier</th>
                            <th>Price</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of searchTable1.data">
                            <td><img src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{item.brandLogoUrl}}" class="brandImage" /></td>
                            <td>{{item.description}}</td>
                            <td>{{item.soh}}</td>
                            <td>{{item.sla}}</td>
                            <td>{{item.supplier}}</td>
                            <td>{{item.price}}</td>
                            <td>
                                <a (click)="selectSearchItem(item)">
                                    <i nz-icon nzType="check-circle" nzTheme="outline" style="font-size: 1.5rem; color: #136413;" nz-tooltip nzTooltipTitle="Select Item"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </nz-table>
            </nz-tab>
            <nz-tab nzTitle="Group">
                <nz-table #searchTable2 [nzData]="filterSearchStock(2)" [nzLoading]="isLoadingStockSearch" [nzFrontPagination]="false" [nzBordered]="false" [nzOuterBordered]="false" nzSize="small" [nzNoResult]="stockSearchNoResults">
                    <thead>
                        <tr>
                            <th>Brand</th>
                            <th>Description</th>
                            <th>SOH</th>
                            <th>SLA</th>
                            <th>Supplier</th>
                            <th>Price</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of searchTable2.data">
                            <td><img src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{item.brandLogoUrl}}" class="brandImage" /></td>
                            <td>{{item.description}}</td>
                            <td>{{item.soh}}</td>
                            <td>{{item.sla}}</td>
                            <td>{{item.supplier}}</td>
                            <td>{{item.price}}</td>
                            <td>
                                <a (click)="selectSearchItem(item)">
                                    <i nz-icon nzType="check-circle" nzTheme="outline" style="font-size: 1.5rem; color: #136413;" nz-tooltip nzTooltipTitle="Select Item"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </nz-table>
            </nz-tab>
            <nz-tab nzTitle="Supplier">
                <nz-table #searchTable3 [nzData]="filterSearchStock(3)" [nzLoading]="isLoadingStockSearch" [nzFrontPagination]="false" [nzBordered]="false" [nzOuterBordered]="false" nzSize="small" [nzNoResult]="stockSearchNoResults">
                    <thead>
                        <tr>
                            <th>Brand</th>
                            <th>Description</th>
                            <th>SOH</th>
                            <th>SLA</th>
                            <th>Supplier</th>
                            <th>Price</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of searchTable3.data">
                            <td><img src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{item.brandLogoUrl}}" class="brandImage" /></td>
                            <td>{{item.description}}</td>
                            <td>{{item.soh}}</td>
                            <td>{{item.sla}}</td>
                            <td>{{item.supplier}}</td>
                            <td>{{item.price}}</td>
                            <td>
                                <a (click)="selectSearchItem(item)">
                                    <i nz-icon nzType="check-circle" nzTheme="outline" style="font-size: 1.5rem; color: #136413;" nz-tooltip nzTooltipTitle="Select Item"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </nz-table>
            </nz-tab>
            <nz-tab nzTitle="NRS">
                <nz-table #searchTable4 [nzData]="filterSearchStock(4)" [nzLoading]="isLoadingStockSearch" [nzFrontPagination]="false" [nzBordered]="false" [nzOuterBordered]="false" nzSize="small" [nzNoResult]="stockSearchNoResults">
                    <thead>
                        <tr>
                            <th>Brand</th>
                            <th>Description</th>
                            <th>SOH</th>
                            <th>SLA</th>
                            <th>Supplier</th>
                            <th>Price</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of searchTable4.data">
                            <td><img src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{item.brandLogoUrl}}" class="brandImage" /></td>
                            <td>{{item.description}}</td>
                            <td>{{item.soh}}</td>
                            <td>{{item.sla}}</td>
                            <td>{{item.supplier}}</td>
                            <td>{{item.price}}</td>
                            <td>
                                <a (click)="selectSearchItem(item)">
                                    <i nz-icon nzType="check-circle" nzTheme="outline" style="font-size: 1.5rem; color: #136413;" nz-tooltip nzTooltipTitle="Select Item"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </nz-table>
            </nz-tab>
        </nz-tabset>
        <ng-template #stockSearchNoResults>
            <div style="text-align: center;">
                <i nz-icon nzType="warning" nzTheme="outline" class="text-warning-style " style="font-size: 4rem;"></i>

                <br />
                <b>NO STOCK FOUND</b>
            </div>
        </ng-template>
    </div>
</nz-modal>

<!-- //this will no longer be used, but kept for reference purpose -->
<!-- <nz-modal [(nzVisible)]="isUpdateStatusModalVisible" nzTitle="Update Quote Status"
    (nzOnCancel)="isUpdateStatusModalVisible = false" [nzFooter]="null" [nzWidth]="'600px'">
    <div *nzModalContent>
        <nz-spin [nzSpinning]="isLoadingUpdateStatusses">
            <div class="row">
                <div class="col-sm-4 mb-2" *ngFor="let item of quoteStatusses">
                    <button nz-button nzType="primary" (click)="followUpDatePicker.open()" class="btn" *ngIf="item.quoteStatusId === 6">Follow Up Later
                        <nz-date-picker [(ngModel)]="followUpDate" (ngModelChange)="updateQuoteStatus(item.quoteStatusId, $event)" 
                        [nzDisabledDate]="disabledDate" [nzShowToday]="false" nzPlaceHolder="" style="border:none; width: 0px; height: 0px; background-color: #00000000; margin-left: -15px;" 
                        #followUpDatePicker></nz-date-picker>
                    </button>
                    <button nz-button nzType="primary" class="btn" (click)="updateQuoteStatus(item.quoteStatusId)" *ngIf="item.quoteStatusId !== 6">{{ item.description }}</button>
                    
                </div>
            </div>
        </nz-spin>
    </div>
</nz-modal> -->

<nz-modal [(nzVisible)]="isFullActionLogModalVisible" nzTitle="Action Log" (nzOnCancel)="isFullActionLogModalVisible = false" [nzFooter]="null" nzWidth="900px">
    <div *nzModalContent>
        <nz-table #fullLogTable [nzData]="actionLog" [nzLoading]="isLoadingLog" [nzBordered]="false" [nzOuterBordered]="false" nzSize="small" [nzFrontPagination]="false">
            <thead>
                <tr>
                    <th>User</th>
                    <th>Date</th>
                    <th>Description</th>
                    <th>Comment</th>
                    <th>From</th>
                    <th>To</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of fullLogTable.data; let i = index">
                    <td><small>{{data.name}}</small></td>
                    <td><small>{{datepipe.transform(data.dateActioned, 'dd MMM YYYY HH:mm')}}</small></td>
                    <td>
                        <small>{{data.description}}</small>
                    </td>
                    <td><small>{{data.comment}}</small></td>
                    <td><small>{{data.valuePrev}}</small></td>
                    <td><small>{{data.valueNew}}</small></td>
                </tr>
            </tbody>
        </nz-table>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="isNavModalVisible" nzTitle="Update Status" (nzOnCancel)="isNavModalVisible = false; navPathAway='';" [nzFooter]="null" nzWidth="800px">
    <div *nzModalContent>
        <!-- NAV AWAY WITHOUT CHANING STATE -->
        <div *ngIf="isNavAwayVisible">
            <div class="row mb-5">
                <div class="col-md-6">
                    <button nz-button nzType="primary" class="btn" (click)="navigateWithoutSaving()"><i nz-icon nzType="left-circle" nzTheme="fill"
                            style="vertical-align: top; margin-right: 4px; font-size: 1.5rem;"></i>Just Checking,
                        Continue</button>
                </div>
                <div class="col-md-6" *ngIf="quoteDirty">
                    <button nz-button nzType="primary" class="btn" (click)="navigateWithSaving()"><i nz-icon nzType="save" nzTheme="fill"
                            style="vertical-align: top; margin-right: 4px; font-size: 1.5rem;"></i>Save Quote and
                        Continue</button>
                </div>
            </div>
            <!-- <div class="row">
                <div class="col-12">
                    <span style="background-color: black; border-top-left-radius: 20px; border-top-right-radius: 20px; 
                    padding-top: 15px; padding-left: 15px; padding-right: 15px; padding-bottom: 10px;">DON'T CHANGE STATE</span>
                </div>
            </div>
            <div class= "mb-4" style="border: 2px solid black; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; border-top-right-radius: 20px; padding: 15px; margin-top: 8px;">
            
                <div class="row mb-2" style="padding-left: 20px;">
                    <div class="col-md-6">
                        <button nz-button nzType="primary" class="btn" (click)="navigateWithoutSaving()"><i nz-icon nzType="right-circle" nzTheme="fill" style="vertical-align: top; margin-right: 4px; font-size: 1.5rem;"></i>Just Checking, Continue</button>
                    </div>
                    <div class="col-md-6" *ngIf="quoteDirty">
                        <button nz-button nzType="primary" class="btn" (click)="navigateWithSaving()"><i nz-icon nzType="save" nzTheme="fill" style="vertical-align: top; margin-right: 4px; font-size: 1.5rem;"></i>Save Quote and Continue</button>
                    </div>
                </div>

                
            </div> -->
        </div>

        <!-- FOLLOW UP -->
        <div class="row" *ngIf="!this.isWIP">
            <div class="col-12">
                <span style="background-color: black; border-top-left-radius: 20px; border-top-right-radius: 20px; 
                padding-top: 15px; padding-left: 15px; padding-right: 15px; padding-bottom: 10px;">FOLLOW UP LATER</span>
            </div>
        </div>
        <div *ngIf="!this.isWIP" style="border: 2px solid black; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; border-top-right-radius: 20px; padding: 15px; margin-top: 8px;">
            <div class="row mb-2">
                <div class="col-12">
                    <div class="input-group text-center">
                        <input nz-input [(ngModel)]="statusComment" placeholder="Add Comment" style="width: 620px;" [maxLength]="450" />
                        <div class="input-group-append ">
                            <span class="btn btn-sm" style="color: grey;"><small>{{statusComment.length}}/450</small></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-12 mb-2">
                    Follow Up On:

                    <nz-date-picker [nzDisabledDate]="disabledDate" nzShowTime nzFormat="yyyy-MM-dd HH:mm" nzAllowClear="false" [(ngModel)]="followUpDate" nzPlaceHolder="Select Date" #followUpDatePicker (nzOnOk)="updateQuoteStateWithAction(PosAuditEntryType.FollowUpLater)"
                        class="txtareaDatePicker">
                    </nz-date-picker>

                </div>

            </div>

            <div class="row">
                <div class="col-sm-2 mb-2">
                    <button nz-button nzType="default" class="btn" (click)="followUpAddMinutes(15)" style="border-color: var(--textcolor);" [autofocus]="true">
                        In 15 mins
                    </button>
                </div>
                <div class="col-sm-2 mb-2">
                    <button nz-button nzType="default" class="btn" (click)="followUpAddHours(1)" style="border-color: var(--textcolor);">
                        In 1 hour
                    </button>
                </div>
                <div class="col-sm-2 mb-2">
                    <button nz-button nzType="default" class="btn" (click)="followUpAddHours(2)" style="border-color: var(--textcolor);">
                        In 2 hours
                    </button>
                </div>
                <div class="col-sm-2 mb-2">
                    <button nz-button nzType="default" class="btn" (click)="followUpLunch()" style="border-color: var(--textcolor);" [disabled]="checkLunchDisabled()">
                        Lunch Time
                    </button>
                </div>
                <div class="col-sm-2 mb-2">
                    <button nz-button nzType="default" class="btn" (click)="followUpAddDays(1)" style="border-color: var(--textcolor);">
                        Tomorrow
                    </button>
                </div>
                <div class="col-sm-2 mb-2">
                    <button nz-button nzType="default" class="btn" (click)="followUpAddDays(2)" style="border-color: var(--textcolor);">
                        In 2 days
                    </button>
                </div>

            </div>
        </div>
        <!-- CUSTOMER ACTIONS -->
        <div class="row mt-4">
            <div class="col-12">
                <span style="background-color: black; border-top-left-radius: 20px; border-top-right-radius: 20px; 
                padding-top: 15px; padding-left: 15px; padding-right: 15px; padding-bottom: 10px;">CLOSE LEAD</span>
            </div>
        </div>
        <div style="border: 2px solid black; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; border-top-right-radius: 20px; padding: 15px; margin-top: 8px;">
            <div class="row mb-2">
                <div class="col-sm-4 mb-2">
                    <button nz-button nzType="primary" class="btn" (click)="updateQuoteStateWithAction(PosAuditEntryType.NotReadyToBuy, false)">
                        Not Ready To Buy
                    </button>
                </div>
                <div class="col-sm-4 mb-2">
                    <button nz-button nzType="primary" class="btn" (click)="updateQuoteStateWithAction(PosAuditEntryType.IncorrectCustomerDetails, false)">
                        Incorrect Contact Details
                    </button>
                </div>
                <div class="col-sm-4 mb-2">
                    <button nz-button nzType="primary" class="btn" (click)="updateQuoteStateWithAction(PosAuditEntryType.BoughtFromCompetitor, false)">
                        Bought From Competitor
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <nz-form-item>
                        <nz-form-label>Other</nz-form-label>
                        <nz-form-control>
                            <nz-input-group>
                                <!-- <button nz-button nzType="primary">
                                    OTHER:
                                </button> -->
                                <input nz-input [(ngModel)]="statusComment" style="width:600px" placeholder="Add Comment" [maxLength]="450" />
                                <button nz-button nzType="primary" (click)="updateQuoteStateWithAction(PosAuditEntryType.ClosedOther)" style="border-top-left-radius: 0px !important; border-bottom-left-radius: 0px !important;">
                                    <i nz-icon nzType="save" nzTheme="fill"
                                        style="vertical-align: top; margin-right: 4px; font-size: 1.5rem;"></i>Save
                                </button>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                    <!-- <button nz-button nzType="primary" class="btn" style="border-top-right-radius: 0px; border-bottom-right-radius: 0px; margin-top: -6px;">
                        OTHER:
                    </button>

                    <input nz-input [(ngModel)]="statusComment" class="txtareaMiddle" placeholder="Add Comment" [maxLength]="450" />

                    <button nz-button nzType="primary" class="btn" style="border-top-left-radius: 0px; border-bottom-left-radius: 0px; margin-top: -6px;" (click)="updateQuoteStateWithAction(PosAuditEntryType.ClosedOther)">
                        <i nz-icon nzType="save" nzTheme="fill"
                            style="vertical-align: top; margin-right: 4px; font-size: 1.5rem;"></i>Save
                    </button> -->

                </div>
            </div>
        </div>
        <!-- <div
            style="border: 2px solid black; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; border-top-right-radius: 20px; padding: 15px; margin-top: 8px;">
            <div class="row mb-2">
                <div class="col-12">
                    <div class="input-group text-center">
                        <input nz-input[(ngModel)]="statusComment" class=" form-control txtarea" placeholder="Add Comment"
                            [maxLength]="450"
                            (keyup)="statusComment.length < 3 && selectedEntryType===PosAuditEntryType.FollowUpLater && selectedEntryType=PosAuditEntryType.None " />
                        Read the (keyup) very carefully - it is `Condition && Condition && expression`. Expression will execute if first two conditions are met
                        <div class="input-group-append ">
                            <span class="btn btn-sm"
                                style="color: grey;"><small>{{statusComment.length}}/450</small></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4 mb-2">
                    <button nz-button nzType="primary" class="btn" (click)="selectQuoteStateWithAction(PosAuditEntryType.TentativelyAccepted)"
                        [class.btn_focus]="selectedEntryType===PosAuditEntryType.TentativelyAccepted">
                        Tentatively Accepted
                    </button>
                </div>
                <div class="col-sm-4 mb-2">
                    <button nz-button nzType="primary" class="btn" (click)="selectQuoteStateWithAction(PosAuditEntryType.CustomerUnreachable)"
                        [class.btn_focus]="selectedEntryType===PosAuditEntryType.CustomerUnreachable">
                        Customer Unreachable
                    </button>
                </div>
                <div class="col-sm-4 mb-2">
                    <button nz-button nzType="primary" class="btn" (click)="selectQuoteStateWithAction(PosAuditEntryType.LookingCloserFC)"
                        [class.btn_focus]="selectedEntryType===PosAuditEntryType.LookingCloserFC">
                        Looking For Closer FC
                    </button>
                </div>
                <div class="col-sm-4 mb-2">
                    <button nz-button nzType="primary" class="btn" (click)="selectQuoteStateWithAction(PosAuditEntryType.PriceChecking)"
                        [class.btn_focus]="selectedEntryType===PosAuditEntryType.PriceChecking">
                        Price Checking
                    </button>
                </div>
                <div class="col-sm-4 mb-2">
                    <button nz-button nzType="primary" class="btn" (click)="selectQuoteStateWithAction(PosAuditEntryType.Looking2ndHand)"
                        [class.btn_focus]="selectedEntryType===PosAuditEntryType.Looking2ndHand">
                        Looking For Second-Hand
                    </button>
                </div>
                <div class="col-sm-4 mb-2">
                    <button nz-button nzType="primary" class="btn" (click)="selectQuoteStateWithAction(PosAuditEntryType.FollowUpLater)"
                        [class.btn_focus]="selectedEntryType===PosAuditEntryType.FollowUpLater"
                        [disabled]="statusComment.length < 3"
                        [nz-tooltip]="statusComment.length < 3 ? 'Add comment first' : ''" nzTooltipPlacement="right">
                        Other
                    </button>
                </div>
            </div>
        </div> -->

        <!-- TYPE OF ACTION -->
        <!-- <div class="row mt-4"
            *ngIf="!(selectedEntryType === undefined) && (selectedEntryType != PosAuditEntryType.None)"
            [@inOutAnimation]>
            <div class="col-12" style="text-align: center;">
                <button nz-button nzType="primary" class="btn" style="border-top-right-radius: 0px; border-bottom-right-radius: 0px;"
                    [class.btn_focus]="isFollowingUp" (click)="isFollowingUp = !isFollowingUp">
                    <i nz-icon nzType="clock-circle" nzTheme="fill"
                        style="vertical-align: top; margin-right: 4px; font-size: 1.5rem;"></i>Follow Up Later
                </button>
                <button nz-button nzType="primary" class="btn" style="border-top-left-radius: 0px; border-bottom-left-radius: 0px;"
                    (click)="updateQuoteStateWithAction(false)"
                    [disabled]="selectedEntryType===PosAuditEntryType.TentativelyAccepted">
                    <i nz-icon nzType="close-circle" nzTheme="fill"
                        style="vertical-align: top; margin-right: 4px; font-size: 1.5rem;"></i>Close Quote
                </button>
            </div>
        </div> -->


        <!-- FOLLOW UP OLD -->
        <!-- <div *ngIf="isFollowingUp" [@inOutAnimation]>


            <div class="row mt-4">
                <div class="col-12">
                    <span style="background-color: black; border-top-left-radius: 20px; border-top-right-radius: 20px; 
                    padding-top: 15px; padding-left: 15px; padding-right: 15px; padding-bottom: 10px;">FOLLOW UP
                        LATER</span>
                </div>
            </div>
            <div
                style="border: 2px solid black; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; border-top-right-radius: 20px; padding: 15px; margin-top: 8px;">

                <div class="row mb-2">
                    <div class="col-12 mb-2">
                        Follow Up On:

                        <nz-date-picker [nzDisabledDate]="disabledDate" nzShowTime nzFormat="yyyy-MM-dd HH:mm"
                            nzAllowClear="false" [(ngModel)]="followUpDate" nzPlaceHolder="Select Date"
                            #followUpDatePicker (nzOnOk)="updateQuoteStateWithAction(true)">
                        </nz-date-picker>
                        
                    </div>

                </div>

                <div class="row">
                    <div class="col-sm-2 mb-2">
                        <button nz-button nzType="default" class="btn" (click)="followUpAddMinutes(15)"
                            style="border-color: var(--textcolor);" [autofocus]="true">
                            In 15 mins
                        </button>
                    </div>
                    <div class="col-sm-2 mb-2">
                        <button nz-button nzType="default" class="btn" (click)="followUpAddHours(1)"
                            style="border-color: var(--textcolor);">
                            In 1 hour
                        </button>
                    </div>
                    <div class="col-sm-2 mb-2">
                        <button nz-button nzType="default" class="btn" (click)="followUpAddHours(1)"
                            style="border-color: var(--textcolor);">
                            In 2 hours
                        </button>
                    </div>
                    <div class="col-sm-2 mb-2">
                        <button nz-button nzType="default" class="btn" (click)="followUpLunch()" style="border-color: var(--textcolor);"
                            [disabled]="checkLunchDisabled()">
                            Lunch Time
                        </button>
                    </div>
                    <div class="col-sm-2 mb-2">
                        <button nz-button nzType="default" class="btn" (click)="followUpAddDays(1)"
                            style="border-color: var(--textcolor);">
                            Tomorrow
                        </button>
                    </div>
                    <div class="col-sm-2 mb-2">
                        <button nz-button nzType="default" class="btn" (click)="followUpAddDays(2)"
                            style="border-color: var(--textcolor);">
                            In 2 days
                        </button>
                    </div>

                </div>
                

            </div>
        </div> -->

    </div>
</nz-modal>

<nz-modal [(nzVisible)]="isEmailQuoteModalVisible" nzTitle="Email Quote To" (nzOnCancel)="isEmailQuoteModalVisible = false" [nzFooter]="null" nzWidth="700px">
    <div *nzModalContent>

        <nz-input-group nzPrefixIcon="mail">
            <input nz-input [(ngModel)]="emailAddressTo" placeholder="Email Address">
        </nz-input-group>
        <button class="btn" style="border:none; width: 200px; font-size: 1rem;height: 40px; margin-top: 30px;" (click)="emailQuote()" nz-button nzType="default" [nzLoading]="isEmailingQuote">
            <i nz-icon nzType="mail" nzTheme="fill"
                style="vertical-align: middle; margin-top: -5px; font-size: 1.5rem;"></i>
            Email Quote
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="isBookingModalVisible" nzTitle="Book Fitment Slot" (nzOnCancel)="isBookingModalVisible = false" [nzFooter]="null" nzWidth="700px">

    <div *nzModalContent>
        <div nz-row [nzJustify]="'center'">
            <div nz-col nzXs="24" nzSm="24" nzMd="12">
                <div [ngStyle]="{ width: '300px', border: '1px solid #d9d9d9', borderRadius: '4px' }">
                    <nz-calendar [nzFullscreen]="false" (nzSelectChange)="onBookingDateSelected($event)" class="customCalendar"></nz-calendar>
                </div>
            </div>
            <div nz-col nzXs="24" nzSm="24" nzMd="12">
                <nz-spin [nzSpinning]="isLoadingBookingSlots">
                    <div class="text-info-style" style="margin-bottom: 10px;">Date: {{bookingSelectedDate}}</div>
                    <a (click)="onSlotSelected(i)" style="text-decoration: none;" *ngFor="let i of bookingSlots">
                        <div class="timeSlot">Time: {{i.slotTime.toString().padStart(2,"0").substring(0,5)}}<small class="float-right"><i>{{i.bookings}} bookings</i></small></div>
                    </a>
                    <div *ngIf="bookingSlots.length === 0" style="padding-top: 20px; text-align: center;">
                        <i nz-icon nzType="minus-circle" nzTheme="fill" style="color: darkred; font-size: 3rem; padding-bottom: 20px;"></i>

                        <div class="text-info-style" style="text-align: center;">No Time Slots</div>
                    </div>
                </nz-spin>


            </div>
        </div>

    </div>
</nz-modal>

<nz-modal [(nzVisible)]="this.isAddCustomStockModalVisible" nzTitle="Add New Custom Stock Item" (nzOnCancel)="this.isAddCustomStockModalVisible = false" [nzFooter]="null" nzWidth="700px">
    <div *nzModalContent>
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-6 offset-3">
                    <form nz-form nzTitle="Create new custom stock item">
                        <nz-form-item>
                            <label>Description</label>
                            <nz-input-group nzPrefixIcon="file-text">
                                <input [(ngModel)]="desc" name="desc" value="{{desc}}" nz-input placeholder="Description" style="width: 50%;" autocomplete="off" />
                            </nz-input-group>
                        </nz-form-item>
                        <nz-form-item>
                            <label>Stock Code</label>
                            <nz-input-group nzPrefixIcon="barcode">
                                <input [(ngModel)]="code" name="code" value="{{code | uppercase}}" nz-input placeholder="Stock Code" style="width: 50%;text-transform: uppercase;" autocomplete="off" />
                            </nz-input-group>
                        </nz-form-item>
                        <nz-form-item>
                            <label>Price (Incl VAT)</label>
                            <nz-input-group nzPrefixIcon="money-collect">
                                <input [(ngModel)]="price" name="value" value="{{price}}" nz-input placeholder="Price" type="number" min="0" style="width: 50%;" autocomplete="off" />
                            </nz-input-group>
                        </nz-form-item>
                        <nz-form-item>
                            <span><label  style="display: block;">Stock Type</label>  </span>
                        </nz-form-item>
                        <nz-form-item>
                            <input [(ngModel)]="selectedType" name="selectedType" value="{{selectedType}}" nz-dropdown [nzDropdownMenu]="menu" placeholder="Type" style="width: 50%; text-align: center;padding:10px;border-radius:10px" autocomplete="off" />
                            <nz-dropdown-menu #menu="nzDropdownMenu">
                                <ul nz-menu nzSelectable>
                                    <li *ngFor="let item of stockTypes" nz-menu-item value="{{item.stockTypeId}}" (click)="assignType(item.stockDescription)">{{item.stockDescription}}</li>
                                </ul>
                            </nz-dropdown-menu>
                        </nz-form-item>
                    </form>
                    <button nz-button nzType="primary" class="btn" (click)="submitCustomStock()"><i nz-icon nzType="save" nzTheme="fill" 
                    style="vertical-align: top; margin-right: 4px; font-size: 1.5rem;"></i>Save</button>
                </div>
            </div>
        </div>
    </div>
</nz-modal>

<!--WBC-->
<nz-modal [(nzVisible)]="this.isWbcModal" nzTitle="Send WBC Vehicle Details" (nzOnCancel)="this.isWbcModal = false" [nzFooter]="null" nzWidth="700px">
    <div *nzModalContent>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <form nz-form [formGroup]="wbcForm" nzTitle="Vehicle Details" class="text-center">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-6">
                                    <label>Name</label><br>
                                    <nz-form-item>
                                        <nz-form-control [nzErrorTip]="'Customer Name is required'">
                                            <nz-input-group nzPrefixIcon="user">
                                                <input name="name" formControlName="name" nz-input placeholder="Mike" autocomplete="off" />
                                            </nz-input-group>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                                <div class="col-6">
                                    <label>Surname</label><br>
                                    <nz-form-item>
                                        <nz-form-control [nzErrorTip]="'Customer Surname is required'">
                                            <nz-input-group nzPrefixIcon="user">
                                                <input name="surname" formControlName="surname" nz-input placeholder="Coxsmall" autocomplete="off" />
                                            </nz-input-group>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <label>Cell</label><br>
                                    <nz-form-item>
                                        <nz-form-control [nzErrorTip]="'Customer Cell is required'">
                                            <nz-input-group nzPrefixIcon="phone">
                                                <input name="cell" formControlName="cell" nz-input placeholder="082474..." autocomplete="off" />
                                            </nz-input-group>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                                <div class="col-6">
                                    <label>Email</label><br>
                                    <nz-form-item>
                                        <nz-form-control [nzErrorTip]="'Customer Email is required'">
                                            <nz-input-group nzPrefixIcon="mail">
                                                <input name="email" formControlName="email" nz-input placeholder="jones@gmail.com" autocomplete="off" />
                                            </nz-input-group>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6 text-center offset-3">
                                    <label>Milage</label><br>
                                    <nz-form-item>
                                        <nz-form-control [nzErrorTip]="'Vehicle milage is required'">
                                            <nz-input-group nzPrefixIcon="dashboard">
                                                <input name="vehicleMilage" formControlName="vehicleMilage" nz-input placeholder="100000" autocomplete="off" />
                                            </nz-input-group>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <label>Condition </label><br>
                                    <nz-form-item>
                                        <nz-form-control [nzErrorTip]="'Vehicle Condition is required'">
                                            <input name="vehicleCondition" formControlName="vehicleCondition" [(ngModel)]="vehicleConditionselect" nz-dropdown [nzDropdownMenu]="condition" placeholder="Excellent" style="width: 150px;text-align: center;padding:10px;border-radius:10px" autocomplete="off"
                                            />
                                            <nz-dropdown-menu #condition="nzDropdownMenu">
                                                <ul nz-menu nzSelectable>
                                                    <li *ngFor="let condition of vehicleCondition" nz-menu-item value="{{condition}}" (click)="onConditionSelect(condition)">{{condition}}</li>
                                                </ul>
                                            </nz-dropdown-menu>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>

                                <div class="col-4">
                                    <label>Make</label><br>
                                    <nz-form-item>
                                        <nz-form-control [nzErrorTip]="'Vehicle Make is required'">
                                            <input name="vehicleMake" formControlName="vehicleMake" placeholder="Audi" style="text-align: center;padding:10px;border-radius:10px" nz-input [(ngModel)]="vehicleModel" (ngModelChange)="onSearchVmake($event)" [nzAutocomplete]="auto" />
                                            <nz-autocomplete #auto>
                                                <nz-auto-option *ngFor="let option of VehicleMakeOptions" [nzValue]="option" (click)="onMakeSelect(option)">{{ option }}</nz-auto-option>
                                            </nz-autocomplete>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>

                                <div class="col-4">
                                    <label>Year</label><br>
                                    <nz-form-item>
                                        <nz-form-control [nzErrorTip]="'Vehicle Year is required'">
                                            <input nz-inputname="vehicleYear" formControlName="vehicleYear" placeholder="2022" style="width: 150px;text-align: center;padding:10px;border-radius:10px" autocomplete="off" />
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>


                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <label>Model</label><br>
                                    <nz-form-item>
                                        <nz-form-control [nzErrorTip]="'Vehicle Model is required'">
                                            <input name="vehicleModel" formControlName="vehicleModel" placeholder="A4 " style="text-align: center;padding:10px;border-radius:10px" nz-input [(ngModel)]="VminputValue" (ngModelChange)="onSearchVmake($event)" [nzAutocomplete]="autoModel" />
                                            <nz-autocomplete #autoModel>
                                                <nz-auto-option *ngFor="let option of VehicleOptions" [nzValue]="option.Model">{{ option.Model}} {{ option.Year}} </nz-auto-option>
                                            </nz-autocomplete>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                                <div class="col-6">
                                    <label>Variant</label><br>
                                    <nz-form-item>
                                        <nz-form-control [nzErrorTip]="'Vehicle Variant is required'">
                                            <input name="vehicleVariant" formControlName="vehicleVariant" placeholder="2.0 MT " style="text-align: center;padding:10px;border-radius:10px" nz-input [(ngModel)]="VvinputValue" (ngModelChange)="onSearchVvariant($event)" [nzAutocomplete]="autoModelVariant"
                                            />
                                            <nz-autocomplete #autoModelVariant>
                                                <nz-auto-option *ngFor="let option of VehicleVariants" [nzValue]="option.Variant">{{ option.Variant}} {{ option.Year}} </nz-auto-option>
                                            </nz-autocomplete>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                        </div>
                        <button nz-button nzType="primary" class="btn btn-orange" (click)="sendWBCquote()">
                            Send To WBC
                            <i nz-icon nzType="field-time" nzTheme="outline" style="vertical-align: top; margin-right: 4px; font-size: 1.5rem;"></i>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</nz-modal>