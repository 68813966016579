<nz-spin [nzSpinning]="_isLoading">

    <!-- Template for no customer found result in select -->
    <ng-template #noCustomerResults>
        <div style="text-align: center;" *ngIf="filteredCustomers.length === 0 && (searchedCustomerName.length > 3 || searchedCustomerCell.length > 3); else continueTyping">
            <span> No results found </span><br /><br />
            <button nz-button [nzType]="'primary'" class="btn" (click)="addNewCustomer()" [nzLoading]="isAddingCustomer">
                    <i nz-icon nzType="user-add" nzTheme="outline" ></i>
                    Add new
                </button>
        </div>
        <ng-template #continueTyping>
            <div style="text-align: center;">
                <span> Continue typing to search... </span><br /><br />

            </div>
        </ng-template>

    </ng-template>

    <form nz-form [formGroup]="addCustomerForm" (keydown.enter)="$event.preventDefault()">
        <!-- No Customer Linked -->
        <div nz-row [hidden]="quote.hasCustomer">
            <div nz-col [nzSpan]="21">
                <nz-form-item>

                    <nz-select style="width: 100%;" [nzShowArrow]="false" nzPlaceHolder="Search Cell Number" [nzShowSearch]="true" [nzNotFoundContent]="noCustomerResults" (nzOnSearch)="onSearchCustomer($event)" (ngModelChange)="onSelectCustomer($event)" [(ngModel)]="selectedCustomer"
                        [ngModelOptions]="{standalone: true}" [(nzOpen)]="isCustomerSearchDropDownOpen">
                        <nz-option *ngFor="let c of filteredCustomers" [nzValue]="c.key" [nzLabel]="c.name"></nz-option>
                    </nz-select>

                </nz-form-item>
            </div>
        </div>
        <!-- Customer Linked -->
        <div nz-row [hidden]="!quote.hasCustomer">
            <div nz-col [nzSpan]="21">
                <nz-form-item>
                    <nz-form-control [nzErrorTip]="'Valid Customer Cell Required'">
                        <nz-input-group nzPrefixIcon="phone">
                            <input (focusout)="updateCustomer()" (keydown.enter)="updateCustomer()" nz-input name="custCell" formControlName="custCell" placeholder="Cell Number">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col [nzSpan]="3">
                <button [disabled]="this.quote.isCompleted || this.quote.isWIP" nz-button nzDanger style="float: right; margin-right: 0px;" (click)="onSelectCustomer(0)" nz-tooltip="Unlink Customer">
                    <span nz-icon nzType="api" nzTheme="outline" ></span>
                </button>
            </div>
        </div>
        <div nz-row [nzGutter]="24">
            <div nz-col [nzSpan]="12">
                <nz-form-item>
                    <nz-form-control [nzErrorTip]="'Customer Name Required'">
                        <nz-input-group nzPrefixIcon="user">
                            <input [nz-tooltip]="!quote.hasCustomer ? 'Search Cell Number' : ''" (focusout)="updateCustomer()" (keydown.enter)="updateCustomer()" nz-input name="custName" formControlName="custName" placeholder="Name">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col [nzSpan]="12">
                <nz-form-item>
                    <nz-form-control [nzErrorTip]="'Customer Surname Required'">
                        <nz-input-group nzPrefixIcon="user">
                            <input [nz-tooltip]="!quote.hasCustomer ? 'Search Cell Number' : ''" (focusout)="updateCustomer()" (keydown.enter)="updateCustomer()" nz-input name="custSurname" formControlName="custSurname" placeholder="Surname">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div nz-row>
            <div nz-col [nzSpan]="24">
                <nz-form-item>
                    <nz-form-control nzErrorTip="Valid or empty email required">
                        <nz-input-group nzPrefixIcon="mail">
                            <input [nz-tooltip]="!quote.hasCustomer ? 'Search Cell Number' : ''" (focusout)="updateCustomer()" (keydown.enter)="updateCustomer()" nz-input name="custEmail" formControlName="custEmail" placeholder="Email Address">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div nz-row [nzGutter]="24">
            <div nz-col [nzSpan]="12">
                <nz-form-item>
                    <nz-form-control>
                        <nz-input-group nzPrefixIcon="fund">
                            <input [nz-tooltip]="!quote.hasCustomer ? 'Search Cell Number' : ''" (focusout)="updateCustomer()" (keydown.enter)="updateCustomer()" nz-input name="custVATNumber" formControlName="custVATNumber" placeholder="VAT Number">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col [nzSpan]="12">
                <nz-form-item>
                    <nz-form-control>
                        <nz-input-group nzPrefixIcon="bank">
                            <input [nz-tooltip]="!quote.hasCustomer ? 'Search Cell Number' : ''" (focusout)="updateCustomer()" (keydown.enter)="updateCustomer()" nz-input name="custDebtorAccount" formControlName="custDebtorAccount" placeholder="Debtor Account">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <!-- <nz-form-item>
                    <nz-form-control>
                        <div class="input-group maindiv">
                            <span class="input-group-prepend">
                                    <div class="input-group-text bg-transparent">
                                        <i nz-icon nzType="bank" nzTheme="outline"></i>
                                    </div>
                                </span>
                            <input [nz-tooltip]="showSearch ? 'Search Cell No. First!' : ''" name="custVATNumber" formControlName="custVATNumber" (focusout)="onVATNumberAddClick()" nz-input placeholder="VAT Number" class="form-control text-center form-control-custom">
                        </div>
                    </nz-form-control>
                </nz-form-item>
            
                <nz-form-item>
                    <nz-form-control>
                        <div class="input-group maindiv">
                            <span class="input-group-prepend">
                                    <div class="input-group-text bg-transparent">
                                        <i nz-icon nzType="bank" nzTheme="outline"></i>
                                    </div>
                                </span>
                            <input readonly [nz-tooltip]="showSearch ? 'Search Cell No. First!' : ''" [disabled]="posQuote.custId === 14041 || posQuote.custId === 0" (click)="onDebtorClick()" value="{{customerDebtorInfo.debtorAcc}}" nz-input placeholder="Debtor Account ?" class="form-control text-center form-control-custom">
                        </div>
                    </nz-form-control>
                </nz-form-item> -->

    </form>
</nz-spin>