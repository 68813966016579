import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { callNmsfResult, callNmsfSearchResult, updateNmsfExcelResult } from '../models/nmsf';

@Injectable({
  providedIn: 'root'
})

export class NmsfService {

  apiUrl = 'v1/nmsf';

  constructor(private http: HttpClient) { }

  callNmsfResult() {
    return this.http.get<callNmsfResult>(environment.ApiUrl() + this.apiUrl);
  };

  callNmsfSearchResult(search) {
    return this.http.post<callNmsfSearchResult>(environment.ApiUrl() + this.apiUrl + '/nmsf/search',search);
  };
  
  updateNmsfExcel(file: File) {
    const formData = new FormData();
    formData.append('File',file, file.name);
    return this.http.post<updateNmsfExcelResult>(environment.ApiUrl() + this.apiUrl + "/excel", formData);
  }


}