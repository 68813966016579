<nz-result *ngIf="notiType == localResultType.Success"
      nzStatus="success"
      [nzTitle]=nzModalData.title
      [nzSubTitle]=nzModalData.message>
      <div nz-result-content *ngIf="nzModalData.htmlMessage" [innerHTML]="nzModalData.htmlMessage"></div>
</nz-result>
<nz-result *ngIf="notiType == localResultType.Information"
      nzStatus="info"
      [nzTitle]=nzModalData.title
      [nzSubTitle]=nzModalData.message>
      <div nz-result-content *ngIf="nzModalData.htmlMessage" [innerHTML]="nzModalData.htmlMessage"></div>
</nz-result>
<nz-result *ngIf="notiType == localResultType.Warning"
      nzStatus="warning"
      [nzTitle]=nzModalData.title
      [nzSubTitle]=nzModalData.message>
      <div nz-result-content *ngIf="nzModalData.htmlMessage" [innerHTML]="nzModalData.htmlMessage"></div>
</nz-result>
<nz-result *ngIf="notiType == localResultType.Error"
      nzStatus="error"
      [nzTitle]=nzModalData.title
      [nzSubTitle]=nzModalData.message>
      <div nz-result-content *ngIf="nzModalData.htmlMessage" [innerHTML]="nzModalData.htmlMessage"></div>
</nz-result>