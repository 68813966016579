import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, AuthGuardCheck } from './auth.guard';
import { LoginComponent } from './login/login.component';
import { SearchComponent } from './search/search.component';
import { SalesComponent } from './sales/sales.component';
import { LeadsComponent } from './leads/leads.component';
import { QuotesComponent } from './leads/quotes/quotes.component';
import { UpcomingComponent } from './leads/upcoming/upcoming.component';
import { PurchasesComponent } from './purchases/purchases.component';
import { ChangePasswordComponent } from './settings/change-password/change-password.component';
import { InsightsComponent } from './insights/insights.component';
import { SearchesInsightsComponent } from './insights/searches-insights/searches-insights.component';
import { SalesInsightsComponent } from './insights/sales-insights/sales-insights.component';
import { ConversionsInsightsComponent } from './insights/conversions-insights/conversions-insights.component';
import { SearchesDashboardInsightsComponent } from './insights/searches-dashboard-insights/searches-dashboard-insights.component';
import { FleetComponent } from './fleet/fleet.component';
import { ClientComponent } from './client/client.component';
import { ClientaddComponent } from './client/clientadd/clientadd.component';
import { ClienteditComponent } from './client/clientedit/clientedit.component';
import { ReplenishmentsComponent } from './replenishments/replenishments.component';
import { DiagnosticComponent } from './diagnostic/diagnostic.component';
import { StockModelsComponent } from './stock-models/stock-models.component';
import { ModelStockAnalysisComponent } from './model-stock-analysis/model-stock-analysis.component';



import { PosquoteComponent } from './pos/posquote/posquote.component';
import { PricelistsComponent } from './pricelists/pricelists.component';
import { StockMappingComponent } from './stock-mapping/stock-mapping.component';
import { StockOrderComponent } from './stock-order/stock-order.component';
import { PosfleetComponent } from './pos/posfleet/posfleet.component';
import { UsersComponent } from './users/users.component';
import { CustomersComponent } from './customers/customers.component';
import { PricingComponent } from './settings/pricing/pricing.component';
import { StockOverrideComponent } from './stock-override/stock-override.component';
import { PricingmatrixComponent } from './pricelists/pricingmatrix/pricingmatrix.component';
import { CustomstockComponent } from './pricelists/customstock/customstock.component';
import { DirectuploadComponent } from './pricelists/directupload/directupload.component';
import { PampricesComponent } from './pricelists/pamprices/pamprices.component';
import { GrvComponent } from './grv/grv.component';
import { NmsfComponent } from './nmsf/nmsf.component';
import { SaleviewComponent } from './sales/saleview/saleview.component';
import { PurchaseviewComponent } from './purchases/purchaseview/purchaseview.component';
import { PosNavAwayGuard } from './pos/posnavaway.guard';
import { HelpComponent } from './help/help.component';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { BookingsSlotsComponent } from './settings/bookings-slots/bookings-slots.component';
import { SettingsMainComponent } from './settings/settings-main/settings-main.component';
import { CustomerEditComponent } from './settings/customer/customer-edit/customer-edit.component';
import { CustomerComponent } from './settings/customer/customer.component';
import { BrandStrategyComponent } from './settings/brand-strategy/brand-strategy.component';
import { BrandstratnavawayGuard } from './settings/brand-strategy/brandstratnavaway.guard';
import { MessagesComponent } from './messages/messages.component';
import { WipComponent } from './wip/wip.component';
import { PosquoteV2Component } from './pos/posquoteV2/posquote-v2/posquote-v2.component';
import { ComponentLegendComponent } from './debug/component-legend/component-legend.component';
import { PosComponent } from './pos/pos.component';
import { SettingsMainV2Component } from './settings/settings-main-v2/settings-main-v2.component';
import { AdminWhatsappComponent } from './admin/admin-whatsapp/admin-whatsapp.component';
import { PosGuardNavAway } from './pos/posguard.guard';
import { BrandStratGuardNavAway } from './settings/brand-strategy/brandstratguard.guard';
import { AdminUsersComponent } from './admin/admin-users/admin-users.component';
import { ProcurementMaintenanceComponent } from './procurement-maintenance/procurement-maintenance.component';
import { AdminStockMappingsComponent } from './admin-stock-mappings/admin-stock-mappings.component';
import { AllUsersComponent } from './all-users/all-users.component';
import { ClientStockMappingsComponent } from './client-stock-mappings/client-stock-mappings.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/login' },
  { path: 'login', component:LoginComponent },
  { path: 'search', component:SearchComponent, canActivate:[AuthGuardCheck]},
  { path: 'settings/change-password', component:ChangePasswordComponent, canActivate:[AuthGuardCheck] },
  { path: 'sales', component:SalesComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['SLE']} },
  { path: 'sales/view/:orderid', component:SaleviewComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['SLE']} },
  { path: 'leads', component:LeadsComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['LDC']} },
  { path: 'upcoming', component:UpcomingComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['LDU']} },
  { path: 'quotes', component:QuotesComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['QOT']} },
  { path: 'purchases', component:PurchasesComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['PUR']} },
  { path: 'purchases/view/:orderid', component:PurchaseviewComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['PUR']} },
  { path: 'insights', component:InsightsComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['INS']} },
  { path: 'insights/searches', component:SearchesInsightsComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['INS']} },
  { path: 'insights/sales', component:SalesInsightsComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['INS']} },
  { path: 'insights/conversions', component:ConversionsInsightsComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['INS']} },
  { path: 'insights/searches-dashboard-insights', component:SearchesDashboardInsightsComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['INS']} },
  { path: 'fleet', component:FleetComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['POS']} },
  { path: 'clients', component:ClientComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['CLI']} },
  { path: 'clients/add', component:ClientaddComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['CLI']} },
  { path: 'clients/edit', component:ClienteditComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['CLI']} },
  { path: 'pos/quote/:quoteid', component:PosComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['POS']}, canDeactivate:[PosGuardNavAway] }, // This is the old POS route. It is deprecated. Please use the new one below.
  { path: 'pos/quotev2/:quoteid', component:PosComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['POS']}, canDeactivate:[PosGuardNavAway] }, // This is the old POS route. It is deprecated. Please use the new one below.
  { path: 'pos/v2/:quoteid', component:PosComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['POS']}, canDeactivate:[PosGuardNavAway] },  
  { path: 'pos/fleet/:quoteid', component:PosfleetComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['POS']}  },
  { path: 'pricelists', component:PricelistsComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['PRL']} },
  { path: 'matrix', component:PricingmatrixComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['PRL','WSP']} },
  { path: 'custom-stock', component:CustomstockComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['PRL']} },
  { path: 'pricelists/directupload', component:DirectuploadComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['PRL']} },
  { path: 'pricelists/pamprices', component:PampricesComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['PRL']} },
  { path: 'stock/mapping', component:ClientStockMappingsComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['STM']} },
  { path: 'stock/order', component:StockOrderComponent, canActivate:[AuthGuardCheck] },
  { path: 'stock/override', component:StockOverrideComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['OVR']} },
  { path: 'replenishments', component:ReplenishmentsComponent , canActivate:[AuthGuardCheck], data:{claimCodes: ['RPL']} },
  { path: 'users', component:UsersComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['USR']} },
  { path: 'all-users', component:AllUsersComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['URA']} },
  { path: 'customer', component:CustomersComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['POS']} },
  { path: 'customer/:customerId', component:CustomersComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['POS']} },
  { path: 'customer/:customerId/:vehicleId', component:CustomersComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['POS']} },
  { path: 'pricing', component:PricingComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['PRL']} },
  { path: 'grv', component:GrvComponent, canActivate:[AuthGuardCheck] },
  { path: 'help', component:HelpComponent, canActivate:[AuthGuardCheck]},
  { path: 'suppliers', component:SuppliersComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['SUP']}},
  { path: 'settings/bookings', component:BookingsSlotsComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['BSM']}},
  { path: 'settings/main', component:SettingsMainComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['SET']}},
  { path: 'settings/main-v2', component:SettingsMainV2Component, canActivate:[AuthGuardCheck], data:{claimCodes: ['SET']}},
  { path: 'settings/customer', component:CustomerComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['POS']}},
  { path: 'settings/customer-edit/:customerId', component:CustomerEditComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['POS']}},
  { path: 'settings/brand-strategy', component:BrandStrategyComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['BSC']}, canDeactivate:[BrandStratGuardNavAway]},
  /*Automated replenishments */
  { path: 'stock-models', component:StockModelsComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['ARPL']}},
  { path: 'model-stock-analysis', component:ModelStockAnalysisComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['MSV']}}, 
  { path: 'procurement-maintenance', component:ProcurementMaintenanceComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['MSV']}},  

  { path: 'messages', component:MessagesComponent, canActivate:[AuthGuardCheck]},
  { path: 'messages/:messageId', component:MessagesComponent, canActivate:[AuthGuardCheck]},
  { path: 'wip', component: WipComponent, canActivate:[AuthGuardCheck], data: {claimCodes: ['WIP']}},
  /* STOCKFINDER ADMIN */
  { path: 'diagnostic', component:DiagnosticComponent},
  { path: 'component-legend', component: ComponentLegendComponent, canActivate:[AuthGuardCheck]},
  { path: 'admin/whatsapp', component: AdminWhatsappComponent, canActivate:[AuthGuardCheck], data: {claimCodes: ['SFA']}},
  { path: 'nmsf', component:NmsfComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['SFA']} },
  { path: 'admin/users', component:AdminUsersComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['SFA']} },
  { path: 'stock/admin-mapping', component: AdminStockMappingsComponent, canActivate:[AuthGuardCheck], data:{claimCodes: ['SFA']} },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }


