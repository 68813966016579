import { NotificationService } from 'src/app/shared/notification.service';
import { SettingsService } from '../settings.service';
import { PriceNames  } from '../../models/pricelist';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.less']
})
export class PricingComponent implements OnInit {

  isLoading = false;
  pricelist: PriceNames;

  constructor(private settingsService: SettingsService, private notification: NotificationService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.reloadData();
    this.getPricingSettings();
  }

  reloadData()
  {
    this.pricelist = new PriceNames();
  }

  getPricingSettings()
  {
    this.settingsService.getPricingSettings().subscribe(
      res => {
        this.pricelist = res as PriceNames;
        this.isLoading = false        
      },
      error => {
        this.notification.handleError(error);
        this.isLoading = false;
      }
    )  
  }

  saveSettings()
  {
    this.settingsService.savesettings(this.pricelist).subscribe(res =>
      { 
         this.notification.showSuccess("Successfully Updated"); 
         this.ngOnInit();
      },
      error => {
        this.notification.handleError(error);       
      }); 

  }


}
