import { Component, OnInit } from '@angular/core';
import { NmsfService } from './nmsf.service';
import { FormBuilder, Validators } from '@angular/forms';
import { NotificationService } from '../shared/notification.service';
import { resultType } from '../shared/modal-content/modal-content.component';
import { sfSearch } from './../models/search-obj';
import { ClientData } from '../models/client';
import * as _ from 'lodash';
import { AuthService } from '../auth.service';


@Component({
  selector: 'app-nmsf',
  templateUrl: './nmsf.component.html',
  styleUrls: ['./nmsf.component.less']
})
export class NmsfComponent implements OnInit {
  
  // Variables
  searching = false;
  MSF_Data = [];
  Failed_Data = [];
  found = 1;
  toggles = {
    help: false,
    excelDiv: false,
    ful: false
  };
  // client: ClientData;

  //form controls add
  fileUploadForm =this.formBuilder.group({
      formFile: [null, Validators.required]
    });


  // Excel Things
  private file: File;
  fileName= 'ExcelSheet.xlsx'; 
  uploadFileInput: string = ""
  fileInputLabel: string;


  
  constructor(private nmsfService: NmsfService, private notification: NotificationService, private formBuilder: FormBuilder, private authService: AuthService) {

  }

  ngOnInit(): void {
    this.nmsfService.callNmsfResult().subscribe(
      result => {
        this.Failed_Data = result.Failed_Data;
      }
    );
    //this.client = JSON.parse(localStorage.getItem('clientData')!);
  }


  onFileSelect(event) {
    let af = ['.csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      if (!_.includes(af, file.type))
      {
        this.notification.showWarning("Only Excel files allowed")
      } 
      this.fileInputLabel = file.name;
      this.file = event.target.files[0];
    }
  }


  onFormSubmit() {
    if (this.fileUploadForm.value.formFile == null) 
    {  
      // this.notification.ShowAlert({type: resultType.Warning, 
      //   title: "Please Select a File", 
      //   htmlMessage: '' ,
      //   maskClosable: false, 
      //   autoCloseInMilliseconds: 1500})
        this.notification.showWarning('Please Select a File')
      return;
    } 
    else
    {
      this.nmsfService.updateNmsfExcel(this.file).subscribe(response => 
        {
          if (response.nmsfUpdated == true) 
          {
            this.notification.showSuccess("File successfully uploaded");
            this.ngOnInit();
            this.uploadFileInput= "";
            this.fileInputLabel = "";
            this.show('excelDiv');
          }
        }, 
        error => {
          this.notification.showError(error)
        });
    }
  }


  onSelect(e): void {
    this.searching = true;
    let search : sfSearch =
    {
     search_logic : {
                     search_requirements :
                                           {
                                             raw_search: e.srcElement.value
                                           }
                   },
     sellerId : this.authService.user.client_id
   }

    this.nmsfService.callNmsfSearchResult(search).subscribe(res => {
      this.searching = false;
      this.MSF_Data = res.MSF_Data;
      this.found = res.found;
      })
  }

  show(id): void {
    this.toggles[id] = !this.toggles[id] 
  }


}




