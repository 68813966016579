<div nz-row>
    <div nz-col [nzSpan]="8" style="padding-bottom: 10px;">
      <nz-button-group [nzSize]="'large'">
        <button nz-button (click)="clearMessage()">
            <span nz-icon nzType="arrow-left" nzTheme="outline"></span> Back
        </button>
        <button nz-button (click)="markAsRead()" [nzLoading]="this.waService.isLoadingMarkAllAsReadAdmin">
          <span nz-icon nzType="eye" nzTheme="outline"></span> Mark all unread as read
      </button>
      </nz-button-group>
    </div>
    
</div>
<div style="height: calc(100vh - 120px); overflow: auto; padding-right: 20px; margin-right: -20px;" #scrollMe [scrollTop]="scrollMe.scrollHeight">
    <nz-list nzItemLayout="vertical" [nzLoading]="waService.isLoading" >
        <nz-list-item *ngFor="let item of waService.waConversation" [ngClass]="item.incoming === true ? 'incomingBg' : 'outgoingBg'">
          <nz-list-item-meta>
            
            <nz-list-item-meta-description>
              <span [innerHTML]="item.message"></span>
              <div *ngIf="item.imageBase64 && item.imageBase64.length > 0" (click)="openImageModal(imageModalContent, item.imageBase64)" style="cursor:pointer">
                <img *ngIf="item.imageBase64.startsWith('data:image')" width="272" alt="picture" src="{{item.imageBase64}}" />
                <span *ngIf="item.imageBase64.startsWith('data:video')">
                  
                  <video width="272" controls>
                    
                    <source type="video/mp4" src="{{item.imageBase64}}">
                  </video>
                  <br />Click for large player
                </span>
              </div>
              <div>
                <small>
                  {{ item.date + 'Z' | date:'dd MMM HH:mm' }}
                  <b>
                    <span *ngIf="item.incoming === true">{{item.name}}</span>
                    <span *ngIf="item.incoming === false">{{item.userName}}</span>
                  </b>
                </small>
              </div>
              <ul nz-list-item-actions *ngIf="item.incoming === false">
                <nz-list-item-action *ngIf="item.dateDelivered">
                  <span nz-icon nzType="delivered-procedure" nzTheme="outline" nz-tooltip [nzTooltipTitle]="formatDateDelivery(item)"></span>
                </nz-list-item-action>
                <nz-list-item-action *ngIf="item.dateRead">              
                  <span nz-icon nzType="read" nzTheme="outline" nz-tooltip [nzTooltipTitle]="formatDateRead(item)"></span>
                </nz-list-item-action>
                <nz-list-item-action *ngIf="item.error">
                  <span nz-icon nzType="warning" nzTheme="outline" nz-tooltip [nzTooltipTitle]="item.error"></span>
                </nz-list-item-action>
              </ul>
            </nz-list-item-meta-description>
            <!-- <nz-list-item-meta-title style="background-color: red;">
              <span *ngIf="item.incoming === true" style="font-size: 0.7rem;">{{item.name}}</span>
              <span *ngIf="item.incoming === false" style="font-size: 0.7rem;">{{item.userName}}</span>
              
            </nz-list-item-meta-title> -->
           
          </nz-list-item-meta>
          <!-- {{item.message}}
          <span *ngIf="item.imageBase64 && item.imageBase64.length > 0">
            <img src="{{item.imageBase64}}">
    
          </span> -->
        </nz-list-item>
        
      </nz-list>
    
      <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
        <input type="text" nz-input placeholder="Reply" [(ngModel)]="messageText" (keyup.enter)="sendMessage()" />
      </nz-input-group>
      <ng-template #suffixIconButton>
        <button nz-button nzType="primary" nzSearch (click)="sendMessage()"><span nz-icon nzType="send"></span></button>
      </ng-template>
    </div>
      <!-- <nz-modal [nzVisible]="isImageOpen" [nzWidth]="'90%'" nzTitle="WhatsApp Image" (nzOnCancel)="isImageOpen = false" [nzFooter]="null"
      [nzContent]="imageModalContent">
        
      </nz-modal> -->
    
      <ng-template #imageModalContent let-params>
        <div style="text-align: center;">
          <img alt="picture" src="{{params.value }}" style="max-width: 100%;" *ngIf="params.value.startsWith('data:image')" />
          <video width="100%" controls *ngIf="params.value.startsWith('data:video')">
                    
            <source type="video/mp4" src="{{params.value}}">
          </video>
        </div>
      </ng-template>
    