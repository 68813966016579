import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SystemUser, UpdateUser, UserAddModel, UserRole } from '../models/user';


@Injectable({
  providedIn: 'root'
})

export class UsersService {
  //localapi.stockfinder.co.za/
  apiUrl = 'v1/users/';

  constructor(private http: HttpClient) { }

  //Get Users
  public getUsers()
  {
    return this.http.get<SystemUser[]>(environment.ApiUrl() + this.apiUrl + "users");
  }

  public updateUser(id: number, user: UpdateUser) {
    return this.http.patch(environment.ApiUrl() + this.apiUrl + `user/${id}`,user);
  }

  public flipRole(userId: number, roleId: number) {
    return this.http.post<UserRole>(environment.ApiUrl() + this.apiUrl + `user/fliprole/${userId}/${roleId}`, null);
  }

  public addUser(user: UserAddModel) {
    return this.http.post<{userId: number}>(environment.ApiUrl() + this.apiUrl + "user", user)
  }

  public deleteUser(userId: number) {
    return this.http.delete(environment.ApiUrl() + this.apiUrl + `user/${userId}`);
  }

  public sendDetailsEmail(userId: number) {
    return this.http.post<{message: string}>(environment.ApiUrl() + this.apiUrl + `user/${userId}/sendPassword`, null)
  }

}
