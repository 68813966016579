<!-- Buttons -->
<button nz-button nzType="primary">Primary Button</button>
<button nz-button nzType="default">Default Button</button>
<button nz-button nzType="dashed">Dashed Button</button>
<button nz-button nzType="default">Text Button</button>
<a nz-button nzType="link">Link Button</a>
<button nz-button nzType="primary" nzShape="circle"><span nz-icon nzType="search"></span></button>
<button nz-button nzType="primary" nzShape="circle">A</button>
<button nz-button nzType="primary">
      <span nz-icon nzType="search"></span>
      Search
    </button>
<button nz-button nzType="primary" nzLoading>
        
        Loading
      </button>
<br />
<!-- notis -->
<button nz-button nzType="primary" (click)="showSuccess()">Success Noti</button>
<button nz-button nzType="primary" (click)="showWarning()">Warning Noti</button>
<button nz-button nzType="primary" (click)="showInfo()">Info Noti</button>
<button nz-button nzType="primary" (click)="showNonBlock()">Nonblock Noti</button>
<button nz-button nzType="primary" (click)="showSuccessMin()">Success Min Noti</button>
<button nz-button nzType="primary" nzDanger (click)="showError()">Error Noti</button>
<br />
<button nz-button nzType="default" (click)="showSuccessAlert()">Success Alert</button>
<button nz-button nzType="default" (click)="showWarningAlert()">Warning Alert</button>
<button nz-button nzType="default" (click)="showInfoAlert()">Info Alert</button>
<button nz-button nzType="default" (click)="showErrorAlert()">Error Alert</button>
<button nz-button nzType="default" (click)="showSuccessAlertDelay()">Delay Close Alert</button>
<br />
<!-- dropdowns -->
<a nz-dropdown [nzDropdownMenu]="menu">
    Hover me for dropdown menu
    <span nz-icon nzType="down"></span>
  </a>
<div class="context-area" (contextmenu)="contextMenu($event, menu)">Right Click on here</div>

<nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu nzSelectable>
        <li nz-menu-item>Event can alos be click, default is hover</li>
        <li nz-menu-item>1st menu item</li>
        <li nz-menu-item>2nd menu item</li>
        <li nz-menu-item>3rd menu item</li>
        <li nz-menu-item nzDanger>4th danger item</li>
        <li nz-menu-divider></li>
        <li nz-menu-item nzDisabled>5th menu item（disabled）</li>
        <li nz-submenu nzTitle="sub menu">
            <ul>
                <li nz-menu-item>1st child menu item</li>
                <li nz-menu-item>2nd child menu item</li>
            </ul>
        </li>
    </ul>
</nz-dropdown-menu>

<br />
<!-- checkbox -->
<label nz-checkbox [(ngModel)]="checked">Checkbox</label>
<label nz-radio ngModel>Radio</label>
<nz-switch [(ngModel)]="switchValue"></nz-switch>
<nz-switch [ngModel]="true" [nzCheckedChildren]="checkedTemplate" [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
<ng-template #checkedTemplate><span nz-icon nzType="check"></span></ng-template>
<ng-template #unCheckedTemplate><span nz-icon nzType="close"></span></ng-template>
<br /><br />
<!-- dates -->
<nz-date-picker [(ngModel)]="date" (ngModelChange)="onDatePickerChange($event)"></nz-date-picker>
<br /><br />

<div [ngStyle]="{ width: '300px', border: '1px solid #d9d9d9', borderRadius: '4px' }">
    <nz-calendar [nzFullscreen]="false" (nzSelectChange)="onCalendarValueChange($event)" (nzPanelChange)="onCalendarPanelChange($event)"></nz-calendar>
</div>
<!-- select -->
<br /><br />
<nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select a person" [(ngModel)]="selectedName">
    <nz-option nzLabel="Allan" nzValue="allan"></nz-option>
    <nz-option nzLabel="Leroux" nzValue="leroux"></nz-option>
    <nz-option nzLabel="Emile" nzValue="emile"></nz-option>
    <nz-option nzLabel="Cody" nzValue="cody"></nz-option>
    <nz-option nzLabel="Calvin" nzValue="calvin"></nz-option>

</nz-select>
<br /><br />
<!-- popover/tooltip -->
<button nz-button nz-popover nzType="primary" nzPopoverTitle="Title" nzPopoverContent="Content: this can be a render template">Hover me for popover</button>
<button nzTooltipTitle="Tooltip is only text" nzTooltipPlacement="top" nz-button nz-tooltip nzType="primary">Hover me for tooltip</button>

<!-- table -->
<nz-table #basicTable [nzData]="listOfData">
    <thead>
        <tr>
            <th>Name</th>
            <th>Age</th>
            <th>Address</th>
            <th>Action</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let data of basicTable.data">
            <td>{{ data.name }}</td>
            <td>{{ data.age }}</td>
            <td>{{ data.address }}</td>
            <td>
                <a>Action 一 {{ data.name }}</a>
                <nz-divider nzType="vertical"></nz-divider>
                <a>Delete</a>
            </td>
        </tr>
    </tbody>
</nz-table>

<nz-table #emptyTable [nzData]="listOfEmptyData">
    <thead>
        <tr>
            <th>Name</th>
            <th>Age</th>
            <th>Address</th>
            <th>Action</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let data of emptyTable.data">
            <td>{{ data.name }}</td>
            <td>{{ data.age }}</td>
            <td>{{ data.address }}</td>
            <td>
                <a>Action 一 {{ data.name }}</a>
                <nz-divider nzType="vertical"></nz-divider>
                <a>Delete</a>
            </td>
        </tr>
    </tbody>
</nz-table>
<br /><br />
<!-- form -->
<form nz-form>
    <nz-form-item>
        <nz-form-label [nzSpan]="5">Fail</nz-form-label>
        <nz-form-control nzValidateStatus="error" [nzSpan]="12" nzErrorTip="Should be combination of numbers & letter">
            <input nz-input [ngModel]="'unavailable choice'" name="errorValid" />
        </nz-form-control>
    </nz-form-item>
    <nz-form-item>
        <nz-form-label [nzSpan]="5">Warning</nz-form-label>
        <nz-form-control nzValidateStatus="warning" [nzSpan]="12">
            <input nz-input [ngModel]="'Warning'" name="warningValid" />
        </nz-form-control>
    </nz-form-item>
    <nz-form-item>
        <nz-form-label [nzSpan]="5">Validating</nz-form-label>
        <nz-form-control [nzSpan]="12" nzValidateStatus="validating" nzHasFeedback nzValidatingTip="I'm validating the content">
            <input nz-input [ngModel]="'The content is being validated'" name="validating" />
        </nz-form-control>
    </nz-form-item>
    <nz-form-item>
        <nz-form-label [nzSpan]="5">Success</nz-form-label>
        <nz-form-control [nzSpan]="12" nzValidateStatus="success" nzHasFeedback>
            <input nz-input [ngModel]="'The content'" name="successValid" />
        </nz-form-control>
    </nz-form-item>
    <nz-form-item>
        <nz-form-label [nzSpan]="5">Warning</nz-form-label>
        <nz-form-control [nzSpan]="12" nzValidateStatus="warning" nzHasFeedback nzWarningTip="Should be combination of numbers & letters - warning, not failing">
            <input nz-input [ngModel]="'Warning'" name="warningHighValid" />
        </nz-form-control>
    </nz-form-item>



    <nz-form-item>
        <nz-form-label [nzSpan]="5">Select Error</nz-form-label>
        <nz-form-control [nzSpan]="12" nzValidateStatus="error" nzHasFeedback>
            <nz-select name="select-error" [ngModel]="'Option 1'">
                <nz-option nzValue="Option 1" nzLabel="Option 1"></nz-option>
                <nz-option nzValue="Option 2" nzLabel="Option 2"></nz-option>
            </nz-select>
        </nz-form-control>
    </nz-form-item>
    <nz-form-item>
        <nz-form-label [nzSpan]="5">Select Validating</nz-form-label>
        <nz-form-control [nzSpan]="12" nzValidateStatus="validating" nzHasFeedback>
            <nz-select name="select-validate" [ngModel]="'Option 2'">
                <nz-option nzValue="Option 1" nzLabel="Option 1"></nz-option>
                <nz-option nzValue="Option 2" nzLabel="Option 2"></nz-option>
            </nz-select>
        </nz-form-control>
    </nz-form-item>
    <nz-form-item>
        <nz-form-label [nzSpan]="5">Number input Success</nz-form-label>
        <nz-form-control [nzSpan]="12" nzValidateStatus="success" nzHasFeedback>
            <nz-input-number name="inputnumber-success" style="width:100%"></nz-input-number>
        </nz-form-control>
    </nz-form-item>
</form>

<!-- result -->
<nz-result nzStatus="success" nzTitle="Successfully Purchased Cloud Server ECS!" nzSubTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait.">
</nz-result>

<h1>Extra Icons, statically loaded</h1>
<div style="font-size: 1.5rem;">
    sf:stock<span nz-icon nzType="sf:stock" nzTheme="outline" style="font-size: 3rem !important;"></span><br /> sf:stock2
    <span nz-icon nzType="sf:stock2" nzTheme="outline" style="font-size: 3rem !important;"></span><br /> sf:tyre
    <span nz-icon nzType="sf:tyre" nzTheme="outline" style="font-size: 3rem !important;"></span><br /> sf:service
    <span nz-icon nzType="sf:service" nzTheme="outline" style="font-size: 3rem !important;"></span><br /> sf:quote
    <span nz-icon nzType="sf:quote" nzTheme="outline" style="font-size: 3rem !important;"></span><br /> sf:fj
    <span nz-icon nzType="sf:fj" nzTheme="outline" style="font-size: 3rem !important;"></span><br /> sf:tyre2
    <span nz-icon nzType="sf:tyre2" nzTheme="outline" style="font-size: 3rem !important;"></span><br /> sf:tyrepile
    <span nz-icon nzType="sf:tyrepile" nzTheme="outline" style="font-size: 3rem !important;"></span><br /> sf:factory
    <span nz-icon nzType="sf:factory" nzTheme="outline" style="font-size: 3rem !important;"></span><br /> sf:attachplus
    <span nz-icon nzType="sf:attachplus" nzTheme="outline" style="font-size: 3rem !important;"></span><br /> sf:unfoldmore
    <span nz-icon nzType="sf:unfoldmore" nzTheme="outline" style="font-size: 3rem !important;"></span><br /> sf:unfoldless
    <span nz-icon nzType="sf:unfoldless" nzTheme="outline" style="font-size: 3rem !important;"></span><br /> sf:shadowMinus
    <span nz-icon nzType="sf:shadowMinus" nzTheme="outline" style="font-size: 3rem !important;"></span><br />
</div>