
export class Searches {
    name: string
    key: number
}

export class options {
    search: string
}

export class extraOptions {
    option: string
}

export class SearchObj {
    searchtype:string
    search: string
    cell: string
    vreg: string
}

export class CartObj {
    id:string
    sla:string
    brand:string
    image:string
    description:string
    price:number
    soh:number
    qty:number
    seller:string
    sellerId:number
    msfid:number
    code:number
    stock_code:number
    mass:number
    minOrder:number
    minOrderFee:number
    selectedIndex:number
    error_message: string
}

export class CartErrors {
    code:number
    error_id: string
    error_txt: string
    req_qty: number
    av_qty: number
    error_message: string

}

export class QuoteObj {
    brand:string
    image:string
    description:string
    price:number
    soh:number
    qty:number
    seller:string
    sellerId:number
    msfid:number
    code:number
}


export class settings{
    Extref: string
    Milage: number
    Make: string
    Vreg: string
    Salesman:string
    userId:number
    Delivery: string
    Txt:string
    isDelivery: boolean | null

}

export class FixedTyreItem{
    msfid: number;
    sellerId: number;
}

interface searchReq {
    raw_search: string
    stock_msfids: number[]
    none_stock_msfids: number[]
    custom_stock_ids?: number[]
    custom_stock_package_ids?: number[]
    fixed_tyre_items?: FixedTyreItem[]
    buyerId: number
    specialPriceSetId?: number;
}

interface quoteLogic {
    search_requirements: searchReq
    add_lead: boolean
    qty: number
    allow_partial_full: boolean
    base_level_limit: string
    add_recommended_stock: boolean
    max_recommended_options: number
    recommended_level_limit: string
    add_default_services: boolean
    isContractPricing : boolean
}

export class aautoquote {
    sellerId: number
    ext_Ref: string
    comment: string
    name: string
    email?: string
    cell: string
    vreg: string
    assignedToUserId: number
    customerId?: number
    quote_logic: quoteLogic
    lastcall?:number
}

interface sfsearchReq {
  raw_search: string;
  stock_msfids?: number[];
  stock_msfids_only?: boolean;
  specialPriceSetId?: number;
}

interface searchLogic {
  search_requirements: sfsearchReq
}

export class sfSearch{
  search_logic: searchLogic
  sellerId:number
}

export class customer {
    cusid: number
    name: string
    email:string
    cell: string
    vreg: string
}
