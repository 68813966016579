<nz-spin [nzSpinning]="_isLoading">

    <!-- Template for no customer found result in select -->
    <ng-template #noCustomerResults>
        <div style="text-align: center;" *ngIf="filteredCustomers.length === 0 && (searchedCustomerName.length > 3 || searchedCustomerCell.length > 3); else continueTyping">
            <span> No results found </span><br /><br />
            <button nz-button [nzType]="'primary'" class="btn" (click)="addNewCustomer()" [nzLoading]="isAddingCustomer">
                    <i nz-icon nzType="user-add" nzTheme="outline" ></i>
                    Add new
                </button>
        </div>
        <ng-template #continueTyping>
            <div style="text-align: center;">
                <span> Continue typing to search... </span><br /><br />

            </div>
        </ng-template>

    </ng-template>

    <!-- <div *ngIf="isAddMainDebtorModalVisible">
        <div nz-row>
            <div nz-col [nzSpan]="21">
                <nz-form-item>

                    <nz-select style="width: 100%;" [nzShowArrow]="false" nzPlaceHolder="Search Name / Cell Number" [nzShowSearch]="true" [nzNotFoundContent]="noCustomerResults" (nzOnSearch)="onSearchMainCustomer($event)" (ngModelChange)="onSelectMainCustomer($event)" [(ngModel)]="selectedMainCustomer"
                    [ngModelOptions]="{standalone: false}" [(nzOpen)]="isCustomerSearchDropDownOpen">
                    <nz-option *ngFor="let c of filteredCustomers" [nzValue]="c.key" [nzLabel]="c.name"></nz-option>
                </nz-select>

                </nz-form-item>
            </div>
        </div>
    </div> -->

    <form nz-form [formGroup]="addCustomerForm" (keydown.enter)="$event.preventDefault()">
        <!-- No Customer Linked -->
        <div nz-row [hidden]="quote.hasCustomer">
            <div nz-col [nzSpan]="21">
                <nz-form-item>

                    <nz-select style="width: 100%;" [nzShowArrow]="false" nzPlaceHolder="Search Name / Cell Number" [nzShowSearch]="true" [nzNotFoundContent]="noCustomerResults" (nzOnSearch)="onSearchCustomer($event)" (ngModelChange)="onSelectCustomer($event)" [(ngModel)]="selectedCustomer"
                        [ngModelOptions]="{standalone: true}" [(nzOpen)]="isCustomerSearchDropDownOpen">
                        <nz-option *ngFor="let c of filteredCustomers" [nzValue]="c.key" [nzLabel]="c.name"></nz-option>
                    </nz-select>

                </nz-form-item>
            </div>
        </div>
        <!-- Customer Linked -->
        <div nz-row [hidden]="!quote.hasCustomer">
            <div nz-col [nzSpan]="18">
                
                <nz-form-item>
                    <nz-form-control [nzErrorTip]="'Valid Customer Cell Required'">
                        <nz-input-group nzPrefixIcon="phone">
                            <input (focusout)="updateCustomer()" (keydown.enter)="updateCustomer()" nz-input name="custCell" formControlName="custCell" placeholder="Cell Number">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col [nzSpan]="6">
                
                <button [disabled]="this.quote.isCompleted || this.quote.isWIP" nz-button nzDanger style="float: right; margin-right: 0px;" (click)="onSelectCustomer(0)" nz-tooltip="Unlink Customer">
                    <span nz-icon nzType="api" nzTheme="outline" ></span>
                </button>
                <button nz-button style="float: right; margin-right: 5px;" (click)="isCustomerGroupModalVisible = true" nz-tooltip="Customer Communities">
                    <span nz-icon nzType="bank" nzTheme="outline"></span>
                </button>
                <!-- Parent Customer -->
                <button nz-button style="float: right; margin-right: 5px;" [nz-tooltip]="'MAIN DEBTOR: ' + quote.parentCustName" *ngIf="quote.parentCustId">
                    <span nz-icon nzType="team" nzTheme="outline"></span>
                </button>
                <button nz-button style="float: right; margin-right: 5px;" [nz-tooltip]="'Add Main Debtor...'" *ngIf="!quote.parentCustId" (click)="isAddMainDebtorModalVisible = true">
                    <span nz-icon nzType="user-add" nzTheme="outline"></span>
                </button>
            </div>
        </div>
        <div nz-row [nzGutter]="24">
            <div nz-col [nzSpan]="12">
                <nz-form-item>
                    <nz-form-control [nzErrorTip]="'Customer Name Required'">
                        <nz-input-group nzPrefixIcon="user">
                            <input [nz-tooltip]="!quote.hasCustomer ? 'Search Cell Number' : ''" (focusout)="updateCustomer()" (keydown.enter)="updateCustomer()" nz-input name="custName" formControlName="custName" placeholder="Name">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col [nzSpan]="12">
                <nz-form-item>
                    <nz-form-control [nzErrorTip]="'Customer Surname Required'">
                        <nz-input-group nzPrefixIcon="user">
                            <input [nz-tooltip]="!quote.hasCustomer ? 'Search Cell Number' : ''" (focusout)="updateCustomer()" (keydown.enter)="updateCustomer()" nz-input name="custSurname" formControlName="custSurname" placeholder="Surname">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div nz-row>
            <div nz-col [nzSpan]="24">
                <nz-form-item>
                    <nz-form-control nzErrorTip="Valid or empty email required">
                        <nz-input-group nzPrefixIcon="mail">
                            <input [nz-tooltip]="!quote.hasCustomer ? 'Search Cell Number' : ''" (focusout)="updateCustomer()" (keydown.enter)="updateCustomer()" nz-input name="custEmail" formControlName="custEmail" placeholder="Email Address">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div nz-row [nzGutter]="24">
            <div nz-col [nzSpan]="12">
                <nz-form-item>
                    <nz-form-control>
                        <nz-input-group nzPrefixIcon="fund">
                            <input [nz-tooltip]="!quote.hasCustomer ? 'Search Cell Number' : ''" (focusout)="updateCustomer()" (keydown.enter)="updateCustomer()" nz-input name="custVATNumber" formControlName="custVATNumber" placeholder="VAT Number">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col [nzSpan]="12">
                <nz-form-item>
                    <nz-form-control>
                        <nz-input-group nzPrefixIcon="bank">
                            <input [nz-tooltip]="!quote.hasCustomer ? 'Search Cell Number' : ''" (focusout)="updateCustomer()" (keydown.enter)="updateCustomer()" nz-input name="custDebtorAccount" formControlName="custDebtorAccount" placeholder="Debtor Account">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <!-- <nz-form-item>
                    <nz-form-control>
                        <div class="input-group maindiv">
                            <span class="input-group-prepend">
                                    <div class="input-group-text bg-transparent">
                                        <i nz-icon nzType="bank" nzTheme="outline"></i>
                                    </div>
                                </span>
                            <input [nz-tooltip]="showSearch ? 'Search Cell No. First!' : ''" name="custVATNumber" formControlName="custVATNumber" (focusout)="onVATNumberAddClick()" nz-input placeholder="VAT Number" class="form-control text-center form-control-custom">
                        </div>
                    </nz-form-control>
                </nz-form-item>
            
                <nz-form-item>
                    <nz-form-control>
                        <div class="input-group maindiv">
                            <span class="input-group-prepend">
                                    <div class="input-group-text bg-transparent">
                                        <i nz-icon nzType="bank" nzTheme="outline"></i>
                                    </div>
                                </span>
                            <input readonly [nz-tooltip]="showSearch ? 'Search Cell No. First!' : ''" [disabled]="posQuote.custId === 14041 || posQuote.custId === 0" (click)="onDebtorClick()" value="{{customerDebtorInfo.debtorAcc}}" nz-input placeholder="Debtor Account ?" class="form-control text-center form-control-custom">
                        </div>
                    </nz-form-control>
                </nz-form-item> -->

    </form>
</nz-spin>


<!-- Communities Modal -->
<nz-modal [(nzVisible)]="isCustomerGroupModalVisible" nzTitle="Customer Communities" (nzOnCancel)="isCustomerGroupModalVisible=false" (nzOnOk)="isCustomerGroupModalVisible=false" [nzFooter]="null">
    <ng-container *nzModalContent>
        <nz-table #customerGroupTable [nzData]="quote.customerGroups">
            <thead>
              <tr>
                <th>Community Name</th>
                <th>Linked</th>
                <th>Link/Unlink</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of customerGroupTable.data">
                <td>{{data.customerGroupName}}</td>
                <td>
                    <span nz-icon nzType="check-circle" nzTheme="outline" class="color-success" style="margin-right: 5px;" *ngIf="isCustomerInGroup(data.customerGroupId)"></span>
                    <span nz-icon nzType="close-circle" nzTheme="outline" class="color-error" style="margin-right: 5px;" *ngIf="!isCustomerInGroup(data.customerGroupId)"></span>
                </td>
                <td>
                    <button nz-button nzType="primary" nzShape="circle" [disabled]="!data.canAddMembers" *ngIf="!isCustomerInGroup(data.customerGroupId)" (click)="addCustomerToGroup(data.customerGroupId)">
                        <span nz-icon nzType="plus" nzTheme="outline"></span>
                    </button>
                    <button nz-button nzType="primary" nzDanger nzShape="circle" [disabled]="!data.canRemoveMembers" *ngIf="isCustomerInGroup(data.customerGroupId)" (click)="removeCustomerFromGroup(data.customerGroupId)">
                        <span nz-icon nzType="minus" nzTheme="outline"></span>
                    </button>
                </td>
                
              </tr>
            </tbody>
          </nz-table>
    </ng-container>
  </nz-modal>

  <nz-modal [(nzVisible)]="isAddMainDebtorModalVisible" nzTitle="Add Main Debtor" (nzOnCancel)="isAddMainDebtorModalVisible=false" (nzOnOk)="isAddMainDebtorModalVisible=false" [nzFooter]="null">
    <ng-container *nzModalContent>
        
            <div nz-row>
                <div nz-col [nzSpan]="21">
                    <!-- <nz-form-item>

                        <nz-select style="width: 100%;" [nzShowArrow]="false" nzPlaceHolder="Search Name / Cell Number" [nzShowSearch]="true" [nzNotFoundContent]="noCustomerResults" (nzOnSearch)="onSearchMainCustomer($event)" (ngModelChange)="onSelectMainCustomer($event)" [(ngModel)]="selectedMainCustomer"
                        [ngModelOptions]="{standalone: true}" [(nzOpen)]="isCustomerSearchDropDownOpen">
                        <nz-option *ngFor="let c of filteredCustomers" [nzValue]="c.key" [nzLabel]="c.name"></nz-option>
                    </nz-select>

                    </nz-form-item> -->
                    <input [(ngModel)]="selectedCustomer" (ngModelChange)="onSearchCustomer($event)" [nzAutocomplete]="auto" nz-input  (keyup.escape)="filteredCustomers = []" id="search" placeholder="Search for Name / Tel" style="text-transform:uppercase;">
                    <nz-autocomplete [nzDefaultActiveFirstOption]="true" #auto style="text-transform:uppercase;" (selectionChange)="onSelectMainCustomer($event)">
                        <nz-auto-option *ngFor="let option of filteredCustomers" [nzValue]="option.key + '[*]' + option.name">
                            {{option.name}}

                        </nz-auto-option>
                    </nz-autocomplete>
                </div>
            </div>
        
    </ng-container>
  </nz-modal>