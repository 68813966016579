import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { Lead } from 'src/app/models/leads';
import { LeadsService } from '../leads.service';

@Component({
  selector: 'app-leads-future',
  templateUrl: './leads-future.component.html',
  styleUrls: ['./leads-future.component.less']
})
export class LeadsFutureComponent implements OnInit {

  inputValue: string = "";
  
  pageSize: number = 50;

  @HostListener("window:scroll", [])
  onScroll(): void {
  if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight - (document.body.scrollHeight * 0.1)) {
          // you're at the bottom of the page
          this.pageSize += 10;
      }
  }
  resetTable(){
      this.pageSize = 50;
  }

  custSortFn = (a: Lead[], b: Lead[]): number => String(a['customerName']).toLowerCase() < String(b['customerName']).toLowerCase() ? -1 : 1;
  saleSortFn = (a: Lead[], b: Lead[]): number => String(a['assignedTo']).toLowerCase() < String(b['assignedTo']).toLowerCase() ? -1 : 1;
  clientSortFn = (a: Lead[], b: Lead[]): number => String(a['buyerName']).toLowerCase() < String(b['buyerName']).toLowerCase() ? -1 : 1;
  dtSortFn = (a: Lead[], b: Lead[]): number => a['dtAction'] < b['dtAction'] ? -1 : 1;
  statusSortFn = (a: Lead[], b: Lead[]): number => String(a['statusDescription']) < String(b['statusDescription']) ? -1 : 1;


  constructor(public leadsService: LeadsService, private router: Router, public auth: AuthService) { }

  ngOnInit(): void {
  }

  viewquote(quote: Lead) {
    //check if the buyer is SB Fleet, meaning redirect to fleet page and not pos page
    // if (quote.buyerId == 674) {
    //   this.router.navigate(["/pos/fleet", quote.quoteId]);
    // } 
    // else {
    this.router.navigate(["/pos/v2", quote.quoteId]);
    // }
    
  }

  getLeads(): Lead[] {
    return this.leadsService.filterLeads(this.auth.user.user_id).filter(x => x.actionInMinutes > 0)
  }

  generateTimeString(minutes: number): string {
    if (minutes < 0) minutes = minutes * -1;
    if (minutes < 60) return minutes + " minutes";
    if (minutes < 60 * 24) return Math.trunc(minutes / 60).toString() + " hours"
    return Math.trunc(minutes / 60 / 24) + " days";
  }

}
