import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { brandStrat } from 'src/app/models/brandStrat';
import { NotificationService } from 'src/app/shared/notification.service';
import { SettingsService } from '../settings.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Globals } from 'src/app/shared/globals';
import { LogService } from 'src/app/shared/logs.services';
import { LogAudit, LogAuditType } from 'src/app/models/logs';
import { AuthService } from 'src/app/auth.service';
@Component({
  selector: 'app-brand-strategy',
  templateUrl: './brand-strategy.component.html',
  styleUrls: ['./brand-strategy.component.less']
})
export class BrandStrategyComponent implements OnInit {

  //Global Var
  RankedBrands: brandStrat[]=[];
  isFundingBrand: brandStrat[]=[];
  isNonFundingBrand: brandStrat[]=[];

  //Visual Effects
  isLoading = false;
  changes = false;

  constructor(private settingsService : SettingsService,private router: Router, private notification: NotificationService, private logService : LogService, private authService: AuthService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.load();
  }

  load()
  {
    this.settingsService.getClientBrandStrats().subscribe((res: any) =>
      {
        this.RankedBrands = res;       
        this.isLoading = false;
      },
      error => {
        this.notification.handleError(error);
      });
  }

  alterRankings(event: CdkDragDrop<string[]>)
  {
    if(this.RankedBrands[event.previousIndex].isFundingBrand)
    {
      if(this.RankedBrands[event.currentIndex].isFundingBrand)
      {
        this.updatelist(this.RankedBrands,event.previousIndex,event.currentIndex);
      }
      else
      {
        this.notification.showInfo("Action prohibited !! Cannot move funding brand to non funding Section");
      }
    }
    else
    {
      if(!this.RankedBrands[event.currentIndex].isFundingBrand)
      {
        this.updatelist(this.RankedBrands,event.previousIndex,event.currentIndex);
      }
      else
      {
          this.notification.showInfo("Action prohibited !! Cannot move non funding brand to funding brand Section");
      }
    }

  }

  updatelist(array,previousIndex,currentIndex)
  {
    //Move Item using ng zorro
    moveItemInArray(array, previousIndex,currentIndex);

    //update item
    this.RankedBrands[currentIndex]!.ischanging = true;

    //Note Change
    this.changes = true;

    //Update Rankings
    for(var i =0; i < this.RankedBrands.length; i++)
    {
      this.RankedBrands[i].rank = i+1;
    }
  }

  toggleMeta(item:brandStrat)
  {
      //Find Item in List and toggle Active State
      let brand =  this.RankedBrands.find(i => i.rank == item.rank)!;
      brand.metaData = !brand.metaData
      //Note Change
      this.changes = true;
  }

  

  toggleTagline(item:brandStrat)
  {
    //Find Item in List and toggle Active State
    let brand =  this.RankedBrands.find(i => i.rank == item.rank)!;
    brand.tagline = !brand.tagline;
    //Note Change
    this.changes = true;

  }

  toggleActive(item:brandStrat)
  {
    //Find Item in List and toggle Active State
    let brand =  this.RankedBrands.find(i => i.rank == item.rank)!;
    brand.active = !brand.active;
    //Note Change
    this.changes = true;

  }

  updateClientBrandStrats()
  {
    this.settingsService.updateClientBrandStrats(this.RankedBrands).subscribe(res =>
      {
         this.notification.showSuccess("Changes Saved");
         this.changes= false;
         this.isNavModalVisible = false;
         this.load();
         this.logService.addAction(new LogAudit(LogAuditType.BrandStrat, this.authService.user.user_id));
      },
      error => {
        this.notification.handleError(error);
      });
  }

  //Nav Away Guard
  isNavModalVisible = false;
  navPathAway: string = "";
  checkNavGuard(navPathAway: string): boolean {
    if (this.changes == false)
    {
      return true;
    }
    if (!this.navPathAway)
    {
      this.isNavModalVisible = true;
      this.navPathAway = navPathAway;
      return false;
    }
    else if (this.navPathAway === navPathAway)
    {
      return true;
    }
    else
    {
      return true;
    }
  }

  navigateWithoutSaving () {
    this.router.navigate([this.navPathAway]);
  }

  navigateWithSaving () {
    this.updateClientBrandStrats()
    this.router.navigate([this.navPathAway]);
  }


}
