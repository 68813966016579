import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzTableFilterList } from 'ng-zorro-antd/table';
import { AuthService } from '../auth.service';
import { LeadsService } from '../leads/leads.service';
import { Lead, WIPLead, WIPLeadItem, WIPSummaryItem, WIPSummaryItemLead } from '../models/leads';
import { NotificationService } from '../shared/notification.service';
import { ReportsService } from '../shared/reports.service';

@Component({
  selector: 'app-wip',
  templateUrl: './wip.component.html',
  styleUrls: ['./wip.component.less']
})
export class WipComponent implements OnInit {

  constructor(
    private leadsService: LeadsService,
    public auth: AuthService,
    private notification: NotificationService,
    private router: Router,
    private reportsService: ReportsService) { }

  public jobs: WIPLead[] = [];
  public isLoading = false;
  public isItemLeadsModalVisible = false;
  public wipSummaryItems: WIPSummaryItem[] = [];
  public selectedWipSummaryItem: WIPSummaryItem;

  //sort functions
  salesManSortFn = (a: Lead, b: Lead): number => a.assignedTo.toLowerCase() < b.assignedTo.toLowerCase() ? -1 : 1;
  customerSortFn = (a: Lead, b: Lead): number => a.customerName.toLowerCase() < b.customerName.toLowerCase() ? -1 : 1;
  createdSortFn = (a: Lead, b: Lead): number => a.dtAction < b.dtAction ? -1 : 1;

  //filter functions
  salesManFilterFn = (salesman: string[], item: Lead): boolean => salesman.some(e => e === item.assignedTo);
  salesManFilterValues: NzTableFilterList = [];
  actionTimeFilterFn = (times: number[], item: Lead): boolean => times.some(e => e === this.getRowWarning(item.actionInMinutes));
  actionTimeFilterValues: NzTableFilterList = [
    {text: "Current", value: 0},
    {text: "1 - 7 Days", value: 1},
    {text: "Over 7 Days", value: 2}
    
    
  ]

  private populateSalesManFilterValues() {
    for (let item of this.jobs) {
      if (!this.salesManFilterValues.some(e => e.text === item.assignedTo)) {
        this.salesManFilterValues.push({ text: item.assignedTo, value: item.assignedTo })
      }

    }
    this.salesManFilterValues = this.salesManFilterValues.slice(); //to force angular change detection
  }


  //Function to take all WIP Leads and summarize according to items on quotes
  private getLeadSummary(): WIPSummaryItem[] {
    const summaryItemsMap: { [key: string]: WIPSummaryItem } = {};

    this.jobs.forEach(lead => {
        lead.items.forEach(item => {
            if (!summaryItemsMap[item.msfid]) {
                summaryItemsMap[item.msfid] = {
                    msfid: item.msfid,
                    stockCode: item.stockCode,
                    description: item.description,
                    brandImage: item.brandImage,
                    brand: item.brand,
                    qtyReserverd: 0,
                    qtyNotReserved: 0,
                    leads: []
                };
            }

            summaryItemsMap[item.msfid].qtyReserverd += lead.statusId === 27 ? item.qty : 0; // If lead status is 27 - Lead In Progress - Stock Reserved, add to reserved qty
            summaryItemsMap[item.msfid].qtyNotReserved += lead.statusId === 26 ? item.qty : 0; // If lead status is 26 - Lead In Progress, add to not reserved qty
            summaryItemsMap[item.msfid].leads.push({
                quoteId: lead.quoteId,
                quoteRef: lead.quoteRef,
                customerName: lead.customerName,
                msfid: item.msfid,
                qty: item.qty,
                statusId: lead.statusId
            });
        });
    });
    // this.wipSummaryItems = Object.values(summaryItemsMap);
    
    
    return Object.values(summaryItemsMap);
  }

  public showItemLeads(item) {
    this.selectedWipSummaryItem = item;
    this.isItemLeadsModalVisible = true;
  }

  public navigateToSelectedWip(item: WIPSummaryItemLead) {
    this.router.navigate(["/pos/v2", item.quoteId]);
    this.isItemLeadsModalVisible = false;
  }


  ngOnInit(): void {
    this.isLoading = true;
    this.leadsService.getWorkInProgress().subscribe({
      next: val => {
        this.jobs = val;
        this.wipSummaryItems = this.getLeadSummary();
        this.isLoading = false;
        this.populateSalesManFilterValues();
        //get the stored filter settings
        let storedSettings = localStorage.getItem("WIPFilter");
        if (storedSettings) {
          let settings = JSON.parse(storedSettings);
          this.showAssingedToMe = settings.assignedToMe;
          this.showAssingedToOther = settings.assignedToOther;
          this.updateFilterSettings();
        }
        else {
          this.updateFilterSettings();
        }
        
      },
      error: err => {
        this.notification.handleError(err);
      }
    })
  }

  generateTimeString(minutes: number): string {
    if (minutes < 0) minutes = minutes * -1;
    if (minutes < 60) return minutes + " minutes";
    if (minutes < 60 * 24) return Math.trunc(minutes / 60).toString() + " hours"
    return Math.trunc(minutes / 60 / 24) + " days";
  }

  getRowWarning(minutes: number): number {
    if (minutes < 0) minutes = minutes * -1;
    if (minutes > 1440 && minutes < 10080) {
      return 1;
    }
    else if (minutes >= 10080) {
      return 2;
    }
    else {
      return 0;
    }
  }

  viewquote(quote: WIPLead) {
    //check if the buyer is SB Fleet, meaning redirect to fleet page and not pos page
    // if (quote.buyerId == 674) {
    //   this.router.navigate(["/pos/fleet", quote.quoteId]);
    // }
    // else {
    this.router.navigate(["/pos/v2", quote.quoteId]);
    // }

  }

  printJobCard(quoteId: number) {
    this.reportsService.openJobCard(quoteId);
  }

  //Filtering
  public showAssingedToMe = true;
  public showAssingedToOther = true;
  public filteredLeads: WIPLead[] = [];

  public updateFilterSettings() {
    //store persistent settings
    let setObj = {"assignedToMe": this.showAssingedToMe,
      "assignedToOther": this.showAssingedToOther}
    localStorage.setItem("WIPFilter",JSON.stringify(setObj));
    //filter the leads. User temparray to ensure change detection only fires once
    let tempArray: WIPLead[] = [];
    tempArray = this.showAssingedToMe ? tempArray.concat(this.jobs.filter(e => e.userId === this.auth.user.user_id)) : tempArray;
    tempArray = this.showAssingedToOther ? tempArray.concat(this.jobs.filter(e => e.userId !== this.auth.user.user_id)) : tempArray;
    if (tempArray.length === 0) {
      tempArray = this.jobs;
    }
    this.filteredLeads = tempArray; //change detection fires
  }


}
