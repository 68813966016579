import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '../shared/notification.service';
import { Client, LinkedAccountLogin, Pricelist } from '../models/client';
import { ClientService } from './client.service';
import { AuthService } from '../auth.service';
import { LinkedAccount } from '../models/user';
import { FormControl, FormGroup, Validators } from '@angular/forms';



@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.less']
})
export class ClientComponent implements OnInit {

   //Global Var
   clients: Client[];
   filteredClients: Client[];
   pricelist: Pricelist;
   clientFilter: string = "";
   isLoading = false;
   isLoadingLinkedAccounts = false;
   linkedAccounts: LinkedAccount[] = [];
   isCreateAccountModalVisible = false;

    //sort functions
    clientSortFn = (a: Client, b: Client): number => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
    searchesSortFn = (a: Client, b: Client): number => a.apicallcnt < b.apicallcnt ? 1 : -1;
    salesSortFn = (a: Client, b: Client): number => a.sohcallcnt < b.sohcallcnt ? 1 : -1;

  constructor(
    private clientService : ClientService,
    private router: Router, 
    private notification: NotificationService,
    public authService: AuthService) { }

  ngOnInit(): void {
    this.refresh();
    
  }

  refresh()
  {
    this.clients=[];
    this.isLoading = true;
    this.clientService.getClients().subscribe(res =>
      {
        this.clients = res['clients'] as Client[];
        this.clients.sort(this.clientSortFn);
        this.filteredClients = this.clients;
        this.pricelist = res['pricelistdata'] as Pricelist;
        this.isLoading = false;
        if (this.authService.user.isSuperUser) {
          this.isLoadingLinkedAccounts = true;
          this.authService.getLinkedAccounts().subscribe(
            val => {
              this.linkedAccounts = val;
              this.isLoadingLinkedAccounts = false;
            },
            error => {
              this.isLoadingLinkedAccounts = false;
              this.notification.handleError(error);
            }
          )
        }
      },
      error => {
        this.isLoading = false;
        this.notification.handleError(error);
      });
  }

  openadd()
  {
    this.router.navigate(['/clients/add']);
  }

  editclient(buyerId)
  {
    this.router.navigate(['/clients/edit'], { queryParams: { buyerId: buyerId } });

  }

  filterClients()
  {
    this.filteredClients = this.clients.filter(e => e.name !== null).filter(e => e.name.toUpperCase().includes(this.clientFilter.toUpperCase()));
  }

  linkedAccountExists(clientId: number) {
    return this.linkedAccounts.some(e => e.clientId === clientId);
  }

  // getLinkedAccount() {

  // }

  loginToAccount(clientId: number) {
    this.isLoading = true;
    let account = this.linkedAccounts.find(e => e.clientId === clientId);
    this.authService.login(this.authService.user.username, account!.password).subscribe(
      val => {
        //the pipe/tap will take care of all the storing of JWT details
        this.isLoading = false;
        //ensure the user has correct access by going to default page:
        this.router.navigate(['/search']);
      },
      error => {
        this.isLoading = false;
        this.notification.handleError(error);
      }
    )
  }

  addLoginForm: FormGroup = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.minLength(4)]),
  });
  linkedAccountClientId: number;
  isAddingLogin = false;

  createAccountLogin(clientId: number) {
    this.linkedAccountClientId = clientId;
    this.isCreateAccountModalVisible = true;
  }

  onCreateLinkedAccount() {
    if (this.addLoginForm.invalid) {
      this.notification.showWarning("Please enter a valid password");
      return;
    }
    this.isAddingLogin = true;
    let user: LinkedAccountLogin = {
      clientId: this.linkedAccountClientId,
      password: this.addLoginForm.value.password
    }
    this.clientService.addLinkedAccountLogin(user).subscribe(
      val => {
        this.isAddingLogin = false;
        this.isCreateAccountModalVisible = false;
        this.refresh()
      }, 
      error => {
        this.isAddingLogin = false;
        this.notification.handleError(error);
      }
    )

  }

}
