import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Plupdate,CustomStock, ReasonAdjust, StockReturn, StockReturnPackages, CustomStockPackage, Stock, PriceList, PriceMatrixObj, PriceMatrixTreeNode, PriceMatrixOverrideItem, updatePMResponse } from './../models/pricelist';
import { OrderLine } from '../models/purchase';
import { customStockItem, PackageType } from '../models/pos';


@Injectable({
  providedIn: 'root'
})
export class PricelistsService {
  apiUrl = 'v1/pricelists/';

  constructor(private http: HttpClient) { }

    public getPricelists()
    {
      return this.http.get( environment.ApiUrl() + this.apiUrl + "getpricelists");
    }

    public getClientPriceList()
    {
      return this.http.get<PriceList>( environment.ApiUrl() + this.apiUrl + "clientPriceList");
    }

    public updatepricelist(listdata: Plupdate)
    {
      return this.http.post(environment.ApiUrl() + this.apiUrl + "updatepricelists", listdata);
    }

    public getCustomStock() {
      return this.http.get<StockReturn>(environment.ApiUrl() + this.apiUrl + "customStock");
    }

    public getCustomStockPackages() {
      return this.http.get<StockReturnPackages>(environment.ApiUrl() + this.apiUrl + "customStockPackages");
    }

    public setCustomStockPackageGroup(customStockPackage: PackageType) {
      return this.http.post(environment.ApiUrl() + this.apiUrl + "customStockPackages", customStockPackage);
    }

    public updateCustomStockPackageGroup(customStockPackage: PackageType) {
      return this.http.put(environment.ApiUrl() + this.apiUrl + "customStockPackages", customStockPackage);
    }

    public removeCustomStockPackageGroup(id: number) {
      return this.http.delete(environment.ApiUrl() + this.apiUrl + `customStockPackages/${id}`)
    }

    public setCustomStockPackageItem(customStockPackage: CustomStockPackage) {
      return this.http.post(environment.ApiUrl() + this.apiUrl + "customStockPackageItem", customStockPackage);
    }

    public removeCustomStockPackageItem(id: number) {
      return this.http.delete(environment.ApiUrl() + this.apiUrl + `customStockPackageItem/${id}`)
    }

    public updateCustomStockTyreQty(item: CustomStock){
      return this.http.put(environment.ApiUrl() + this.apiUrl + "customStockTyreQty", item);
    }
    
    public updateCustomStockPackageFixedTyreQty(item: CustomStockPackage){
      return this.http.put(environment.ApiUrl() + this.apiUrl + "customStockPackageFixedTyreQty", item);
    }

    public updatecustom(stockData: CustomStock)
    {
      return this.http.post(environment.ApiUrl() + this.apiUrl + "updateCustom", stockData);
    }

    public removeCustomStock(stockItem: CustomStock)
    {
      return this.http.delete(environment.ApiUrl() + this.apiUrl + "removeCustom/" + stockItem.stockId);
    }


    public uploadexcel(file: File)
    {
      const formData = new FormData();
      formData.append('File',file, file.name);
      return this.http.post(environment.ApiUrl() + this.apiUrl + "uploadexcel", formData);
    }

    public uploadSohExcel(file:File)
    {
      const formData = new FormData();
      formData.append('File',file, file.name);
      return this.http.post(environment.ApiUrl() + this.apiUrl + "uploadexcelSoh", formData);
    }


    public directUpload(file: File, p: string)
    {
      const formData = new FormData();
      formData.append('File',file, file.name);
      return this.http.post(environment.ApiUrl() + this.apiUrl + "directUpload/"+ p, formData);
    }

    public pamprices()
    {
      return this.http.get(environment.ApiUrl() + this.apiUrl + "pamprices");
    }

    public updateSoh(body: ReasonAdjust)
    {
      return this.http.post(environment.ApiUrl() + this.apiUrl + "updatesoh",  body);
    }

    public receiveStockline(item:OrderLine) {
      return this.http.post(environment.ApiUrl() + this.apiUrl + "receiveStockLine/", item);
    }

    public getCustomStockCode(code: string){
      return this.http.get<Stock>( environment.ApiUrl() + this.apiUrl + `getCustomStockCode/${code}`);
    }

    public getPricingMatrix()
    {
      return this.http.get<PriceMatrixObj>(environment.ApiUrl() + this.apiUrl + "clientPriceMatrix");
    }

    public updatePmNewRule(rule: PriceMatrixTreeNode)
    {
      return this.http.post<number>(environment.ApiUrl() + this.apiUrl + "newPMRule", rule);
    }

    public updatePmNewOverride(override: PriceMatrixOverrideItem)
    {
      return this.http.post<number>(environment.ApiUrl() + this.apiUrl + "newPMOverride", override);
    }

    public deletePmOverride(id: number)
    {
      return this.http.delete(environment.ApiUrl() + this.apiUrl + `deletePMOverride/${id}`);
    }

    public updatePM(items: PriceMatrixTreeNode[], overrides: PriceMatrixOverrideItem[])
    {
      return this.http.post<updatePMResponse[]>(environment.ApiUrl() + this.apiUrl + "updatePM", {items, overrides});
    }

    public updatePMListApplied(id: number)
    {
      return this.http.put<updatePMResponse[]>(environment.ApiUrl() + this.apiUrl + `updatePMListApplied/${id}`, {});
    }

    public deletePMList(pmId: number, clientId: number)
    {

      return this.http.delete(environment.ApiUrl() + this.apiUrl + `deletePMList/${pmId}/${clientId}`);
    }
}
