

<nz-spin [nzSpinning]="isLoading">
    <div class="container">
        <div class="row ">
            <div class="col-lg-2">
            </div>
            <div class="col-lg-8 " style="text-align: center;"  >
            <h4><b>Main Insights</b></h4>
            <h5><b>{{Week}}</b></h5>
            </div>
            <div class="col-lg-2">
            </div>
        </div>
    </div>

    <div class="container-fluid  text-center">
        <div class="row">
            <div class="col-lg-12" style="padding-left: 5%;padding-right: 4%;">    
                <nz-table #ins [nzData]="Alerts" [nzFrontPagination]="false" id="tbl">
                    <thead>
                        <tr>
                            <th>Alert Description:</th>
                            <th>Branches concerned:</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let i of ins.data">
                            <td>
                                <h3>{{i[0][0]}}</h3> 
                                {{i[0][1]}} 
                            </td>
                            <td>
                                <pre>{{i[1]}}</pre>
                            </td>
                        </tr>
                    </tbody> 
                </nz-table> 
            </div>
        </div>
    </div>
</nz-spin>

   

