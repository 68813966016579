<ng-template #noVehicleResults>
    <div *ngIf="isSearchingVehicles">
        Searching...
    </div>
    <div *ngIf="!isSearchingVehicles">
        
            <button nz-button [nzType]="'primary'" class="btn" (click)="addNewVehicle()">
                <i nz-icon nzType="user-add" nzTheme="outline" ></i>
                Add new
            </button>
        
    </div>
        
   
</ng-template>

<nz-spin [nzSpinning]="_isLoading">
    <!-- nzValidateStatus="validating" nzHasFeedback -->
    <form nz-form [formGroup]="vehicleForm" (keydown.enter)="$event.preventDefault()">
        <div nz-row *ngIf="!quote.hasVehicle">
            <div nz-col [nzSpan]="21">
                <nz-form-item>
                    <nz-select style="width: 100%;" [nzShowArrow]="false" nzPlaceHolder="Search Registration" [nzShowSearch]="true" (nzOnSearch)="onSearchVReg($event)" [nzNotFoundContent]="noVehicleResults"
                    (ngModelChange)="onSelectVehicle($event)" [(ngModel)]="selectedVehicleDD" [ngModelOptions]="{standalone: true}" [(nzOpen)]="isVRegSearchDropDownOpen">
                    <nz-option *ngFor="let c of filteredVehicles" [nzValue]="c.Vehicle_Customer_MappingId" [nzLabel]="c.v_reg"></nz-option>
                    
                </nz-select>
            </nz-form-item>
            </div>

        </div>
        <div nz-row *ngIf="quote.hasVehicle">
            <div nz-col [nzSpan]="21">
                <nz-form-item>
                    <nz-form-control nzErrorTip="Valid Registration Required">
                        <nz-input-group nzPrefixIcon="car">
                            <input (focusout)="updateVehicle()" (keydown.enter)="updateVehicle()" nz-input name="vehicleReg" formControlName="vehicleReg" placeholder="Vehicle Reg">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col [nzSpan]="3">
                <button [disabled]="this.quote.isCompleted" nz-button nzDanger style="float: right; margin-right: 0px;" (click)="unlinkVehicle()" nz-tooltip="Unlink Vehicle">
                    <span nz-icon nzType="api" nzTheme="outline" ></span>
                </button>
            </div>
        </div>
        <div nz-row [nzGutter]="24">
            <div nz-col [nzSpan]="12">
                <nz-form-item>
                    <nz-form-control>
                        <nz-input-group nzPrefixIcon="calendar">
                            <input (focusout)="updateVehicle()" (keydown.enter)="updateVehicle()" nz-input name="vehicleYear" formControlName="vehicleYear" placeholder="Year">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col [nzSpan]="12">
                <nz-form-item>
                    <nz-form-control>
                        <nz-input-number-group nzPrefixIcon="car" [nzAddOnAfter]="'km'">
                            <nz-input-number (focusout)="updateVehicle()" (keydown.enter)="updateVehicle()" nz-input name="vehicleOdo" formControlName="vehicleOdo" placeholder="Mileage"
                            [nzStep]="1000"></nz-input-number>
                        </nz-input-number-group>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div nz-row [nzGutter]="24">
            <div nz-col [nzSpan]="12">
                <nz-form-item>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-select name="vehicleMakeId" formControlName="vehicleMakeId" [nzLoading]="isLoadingMakes" style="width: 100%;" [nzPlaceHolder]="'Vehicle Make'"
                            [nzShowSearch]="true" (ngModelChange)="onSelectVehicleMake($event)" [(ngModel)]="selectedVehicleMake">
                                <nz-option *ngFor="let item of vehicleMakes" [nzValue]="item.vehicleMakeId" [nzLabel]="item.vehicleMake"></nz-option>
                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col [nzSpan]="12">
                <nz-form-item>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-select name="vehicleModelId" formControlName="vehicleModelId" [nzLoading]="isLoadingMakes" style="width: 100%;" [nzPlaceHolder]="'Vehicle Model'"
                            [nzShowSearch]="true" (ngModelChange)="onSelectVehicleModel($event)" [(ngModel)]="selectedVehicleModel">
                                <nz-option *ngFor="let item of vehicleModels" [nzValue]="item.vehicleModelId" [nzLabel]="item.vehicleModel"></nz-option>
                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div nz-row [nzGutter]="24">
            <div nz-col [nzSpan]="24">
                <nz-form-item>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-select name="vehicleVariantId" formControlName="vehicleVariantId" [nzLoading]="isLoadingVariants" style="width: 100%;" [nzPlaceHolder]="'Vehicle Variant'"
                            [nzShowSearch]="true" (ngModelChange)="onSelectVehicleVariant($event)" [(ngModel)]="selectedVehicleVariant">
                                <nz-option *ngFor="let item of vehicleVariants" [nzValue]="item.vehicleVariantId" [nzLabel]="item.vehicleVariant"></nz-option>
                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </div>
            
        </div>
    </form>
</nz-spin>
