<p style="color: black;">
    <b>Excel must have only two columns, stock code and new price (no headings).
    <br>You must also specify which price is being updated in the dropdown.
    <br>Note, use the names used in the excel template.</b>
</p>

<a nz-dropdown [nzDropdownMenu]="menu1">
    Price to update: {{p}}
    <i nz-icon nzType="down"></i>
  </a>
<nz-dropdown-menu #menu1="nzDropdownMenu">
    <ul nz-menu nzSelectable>
        <li nz-menu-item (click)="priceToUpdate('pricedefault')">Default</li>
        <li nz-menu-item (click)="priceToUpdate('priceretail')">Retail</li>
        <li nz-menu-item (click)="priceToUpdate('pricewholesale')">Wholesale</li>
        <li nz-menu-item (click)="priceToUpdate('priceinternal')">Internal</li>
        <li nz-menu-item (click)="priceToUpdate('priceonline')">Online</li>
        <li nz-menu-item (click)="priceToUpdate('pricecustom1')">Custom 1</li>
        <li nz-menu-item (click)="priceToUpdate('pricecustom2')">Custom 2</li>
        <li nz-menu-item (click)="priceToUpdate('pricecustom3')">Custom 3</li>
        <li nz-menu-item (click)="priceToUpdate('priceclearout')">Clear out</li>
    </ul>

</nz-dropdown-menu>

<form [formGroup]="fileUploadForm" (ngSubmit)="onFormSubmit()" enctype="multipart/form-data">
    <div class="container py-5">
        <!-- For demo purpose -->
        <header class="text-white text-center">
            <h3 class="display-4">Upload Pricelist</h3>
            <img src="assets/Images/excel.png" alt="" width="150" class="mb-4">
        </header>
        <div class="row py-4">
            <div class="col-lg-6 mx-auto text-center">
                <!-- Upload image input-->
                <div class="input-group mb-3 px-2 py-2 rounded-pill shadow-sm">
                    <input nz-input #UploadFileInput name="formFile" formControlName="formFile" id="customFile" type="file" (change)="onFileSelect($event)">
                    <label id="customFile-label" for="customFile" class="font-weight-light">{{fileInputLabel}}</label>
                    <div class="input-group-append">
                        <label for="customFile" class="btn btn-light m-0 rounded-pill px-4"> 
                            <i nz-icon nzType="upload" nzTheme="outline" class="mr-2"></i>
                            <small class="text-uppercase font-weight-bold">
                                Choose file
                            </small>
                        </label>
                    </div>
                </div>
                <div class="row text-center">
                    <div class="col-lg-12 text-center">
                        <br>
                        <button nz-button nzType="primary" type="submit">Upload</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>