import { PriceNames, PriceData, StockReasons, ReasonAdjust, PriceList, PriceListItem } from './../models/pricelist';
import { Component, HostListener, OnInit } from '@angular/core';
import { PricelistsService } from '../pricelists/pricelists.service';
import { NotificationService } from '../shared/notification.service';
import { resultType } from '../shared/modal-content/modal-content.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import * as _ from 'lodash';
import { AuthService } from '../auth.service';
import { PosService } from '../pos/pos.service';
import { stockType } from '../models/pos';
import { OrderLine } from './../models/purchase';
import { Globals } from '../shared/globals';

@Component({
  selector: 'app-pricelists',
  templateUrl: './pricelists.component.html',
  styleUrls: ['./pricelists.component.less']
})
export class PricelistsComponent implements OnInit {

  priceNames: PriceNames = new PriceNames();
  modifystock: PriceListItem = new PriceListItem();

  priceList: PriceList = new PriceList();
  
  priceData: PriceData[] = [];
  filteredData: PriceData[] = [];
  reasons: StockReasons[] = [];
  stockTypes: stockType[] = [];

  selectedReason: number = 1;
  newsoh: number = 0;
  removeTypes: number[] = [2,3,4,7,9]
  selectedStockType:number = 0

  listFilter: string = "";
  listFilterArray : string[] = ["description_long", "brand", "stockcode"];
  uploadFileInput: string = ""
  fileInputLabel: string="Select File";
  uploadlist: string = "";
  selectedType:string = "TYRE";
  fileName: string= "StockFinder-Pricelist.xlsx";

  showR: boolean = true;
  showForSOH: boolean = true;
  showupload: boolean = false;
  showuploadSoh: boolean =  false;
  showmain : boolean = true;
  showRt: boolean = false;
  showLc: boolean = false;
  showaddStock:boolean = false;
  showButtons:boolean = true;
  btnfunctions: boolean = true;
  printSection: boolean = true;
  isLoading:boolean = false;
  isQtyModalVisible:boolean=false;
  showDemoSohExport:boolean = false;
  showHash:boolean = false;

  private file: File;
  numberOnly :any = Globals.funcs.numberOnly;
  StringFormant:string ="@";

  //form file upload
  fileUploadForm = this.formBuilder.group({
      formFile: [null, Validators.required]
    });

  //Form Add stock
  StockAddForm: FormGroup = new FormGroup({
    stock_code: new FormControl("", Validators.required),
    description: new FormControl("", Validators.required),
    soh: new FormControl(0, Validators.required),
    cost: new FormControl(0, Validators.required),
    type: new FormControl("0", Validators.required)
  });

  isLoadingOne:boolean = false;
  pageSize: number = 100;

  @HostListener("window:scroll", [])
    onScroll(): void {
    if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight - (document.body.scrollHeight * 0.1)) {
            // you're at the bottom of the page
            this.pageSize += 30;
        }
    }
    resetTable(){
        this.pageSize = 100;
    }

  /* -----------------------------------------------*/

  constructor(public authService: AuthService,
              private pricelistsService: PricelistsService,
              private router: Router, 
              private notification: NotificationService, 
              private formBuilder: FormBuilder,
              private posService: PosService
              ) { }

  ngOnInit(): void {
    this.reset();
    this.isLoading = true;
    this.pricelistsService.getClientPriceList().subscribe(
      (res: PriceList ) =>
      {
        this.priceList = res;
        this.reasons = this.priceList.reasons
        this.notification.showSuccess("Price List Loaded Successfully");
        this.isLoading = false;
      },
      error => {
        this.notification.showError(error);
        this.isLoading = false;
      }
    );

    
    
    // this.pricelistsService.getPricelists().subscribe(
    //   (res:any) =>
    //   {
    //     this.priceNames = res.pricelistdata;
    //     this.priceData = res.price_Data;
    //     this.reasons = res.reasons;
    //     this.filteredData = this.priceData;
    //     this.isLoading = false;
    //     this.posService.getStockTypes().subscribe(res => {
    //       this.stockTypes = res;
    //       for(var i =0; i < this.removeTypes.length; i++)
    //       {
    //         this.stockTypes.splice(this.stockTypes.findIndex(x=> x.stockTypeId == this.removeTypes[i]),1);
    //       }
    //     })
    //     this.reasons.splice(this.reasons.findIndex(x=> x.reasonId == 4),1);
    //   });
  }

  reset()
  {
    this.priceNames = new PriceNames();
    this.priceData = [];
    this.fileUploadForm = this.formBuilder.group({formFile: [null, Validators.required]});
  }

  exportToExcel(fileName: string){
    if(this.priceList.items.length == 0){
      this.showDemoSohExport = true;
      setTimeout(()=> {
        let element = document.getElementById('pldataTemp');
        const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, {raw:false});
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb,`StockFinder-${fileName}.xlsx`);
        this.showDemoSohExport = false;
      },1000)
    }
    else{
        let partialPriceData = this.priceList.items.map(({description, brand, stockCode, cost, soh, pricedefault, priceretail, pricewholesale, priceinternal, priceonline, pricecustom1, pricecustom2, pricecustom3, pricecustom4, retail, lastCost, runningTotal, ...rest}) => ({description, brand, stockCode, cost, soh, pricedefault, priceretail, pricewholesale, priceinternal, priceonline, pricecustom1, pricecustom2, pricecustom3, pricecustom4, retail}));
        
      setTimeout(()=> {
        // Gets all the column names from table. This helps to only update the column names and everything else will be dynamic on top of that
        let element = document.getElementById('pldata');
        const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, {raw: true});
        ws['!fullref'] = 'A1:N1';
        ws['!ref'] = 'A1:N1';

        XLSX.utils.sheet_add_json(ws, partialPriceData, { origin: 'A2', skipHeader: true });
        ws['!cols'] = this.fitToColumn(partialPriceData);
        const cellRef = XLSX.utils.encode_cell({c: 2, r: 0});
        const cell = ws[cellRef];
        if (fileName === 'Pricelist'){
          if (cell) {
              cell.v = "Stock Code - 'add #'";
          } else {
              XLSX.utils.sheet_add_aoa(ws, [['YourValue']], {origin: cellRef});
          }
        }
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb,`StockFinder-${fileName}.xlsx`);
      },1000)
    }
  }

  // exportexcel()
  // {
  //   let partialPriceData = this.priceData.map(({description_long, brand, stockcode, cost, soh, pricedefault, priceretail, pricewholesale, priceinternal, priceonline, pricecustom1, pricecustom2, pricecustom3, retail, lastCost, runningTotal, ...rest}) => ({description_long, brand, stockcode, cost, soh, pricedefault, priceretail, pricewholesale, priceinternal, priceonline, pricecustom1, pricecustom2, pricecustom3, retail}));
  //   this.showR = false;
  //   this.pageSize = this.priceData.length;
  //   setTimeout(()=> {
  //     let element = document.getElementById('pldata');
  //     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, {raw:true});
  //     ws['!cols'] = this.fitToColumn(partialPriceData);
  //     const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  //     XLSX.writeFile(wb, this.fileName);
  //     this.showR = true;
  //     this.resetTable();
  //   },1000)
  // }

  // exportSoh()
  // {
  //   this.isLoading = true;
  //   this.showR = false;
  //   this.showForSOH = false;
  //   this.showRt = true;
  //   this.showLc = true;
  //   this.pageSize = this.priceData.length;
  //   if(this.priceData.length == 0)
  //   {
  //     this.showDemoSohExport = true;
  //     setTimeout(()=> {
  //       let element = document.getElementById('pldataTemp');
  //       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, {raw:false});
  //       const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  //       XLSX.writeFile(wb,'StockFinder-Stocklist.xlsx');
  //       this.showR = true;
  //       this.showForSOH = true;
  //       this.showDemoSohExport = false;
  //       this.resetTable();
  //       this.isLoading = false;
  //     },1000)
  //   }
  //   else
  //   {
  //     this.showR = false;
  //     this.showForSOH = false;
  //     this.showRt = true;
  //     this.showLc = true;
  //     this.showHash= true;
  //       setTimeout(()=> {
  //         let element = document.getElementById('pldata');
  //         const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, {raw:true});
  //         const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //         var range = XLSX.utils.decode_range(ws['!ref']!);
  //         for (var r = range.s.r; r <= range.e.r; r++) {
  //           for (var c = range.s.c; c <= range.e.c; c++) {
  //             var cellName = XLSX.utils.encode_cell({c: c, r: r});
  //             ws[cellName].z = '@';
  //           }
  //         }

          
  //         //Hijack
  //         const cellRef = XLSX.utils.encode_cell({c: 1, r: 0});
  //         const cell = ws[cellRef];
  //         if (cell) {
  //             cell.v = "Stock Code - 'add #'";
  //         } else {
  //             XLSX.utils.sheet_add_aoa(ws, [['YourValue']], {origin: cellRef});
  //         }
  //         XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  //         XLSX.writeFile(wb,'StockFinder-Stocklist.xlsx', { bookType: 'xlsx', type: 'buffer' });
  //         this.showR = true;
  //         this.showForSOH = true;
  //         this.showDemoSohExport = false;
  //         this.showHash= false;
  //         this.showRt = false;
  //         this.showLc = false;
  //         this.isLoading = false;
  //         this.resetTable();
  //       },1000)
        
  //   }
  // }

  toggle()
  {
    this.showmain = true;
    this.showButtons = true;
    this.showupload = false;
    this.showuploadSoh = false;
    this.printSection = false;
    this.showaddStock = false;
  }

  toggleupload()
  {
    this.showmain = false;
    this.printSection = false;
    this.showuploadSoh = false;
    this.showaddStock = false;
    this.showButtons = false;
    this.showupload =  true;
    this.uploadlist = "pl";
  }

  toggleuploadSoh()
  {
    this.showmain = false;
    this.showupload = false;
    this.printSection = false;
    this.showaddStock = false;
    this.showButtons = false;
    this.showuploadSoh = true;
    this.uploadlist = "sh";
  }

  toggleaddStock()
  {
    this.showmain = false;
    this.showupload = false;
    this.printSection = false;
    this.showuploadSoh = false;
    this.showButtons = false;
    this.showaddStock = true;
  }

  onFileSelect(event) {
    let af = ['.csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      if (!_.includes(af, file.type))
      {
        this.notification.showWarning("Only Excel files allowed")
      }
      this.fileInputLabel = file.name;
      this.file = event.target.files[0];

    }
  }

  onFormSubmit() {
    if (this.fileUploadForm.value.formFile == null)
    {
      this.notification.showWarningTimer('Please Select a File', 1500);
      return;
    }
    else
    {
      if(this.uploadlist != "")
      {
        if(this.uploadlist == "pl")
        {
          this.pricelistsService.uploadexcel(this.file).subscribe(response =>
            {
              if (response == "success")
              {
                // Reset the file input
                this.notification.showSuccess("Price list Updated");
                this.ngOnInit();
                this.uploadFileInput= "";
                this.fileInputLabel = "";
                this.toggle();
                this.isLoadingOne = false;
              }
            },
            error => {
              this.notification.showError(error)
            });
        }
        else
        {
          this.pricelistsService.uploadSohExcel(this.file).subscribe(response =>
            {
              if (response == 200)
              {
                this.ngOnInit();
                this.notification.ShowAlert({type: resultType.Success, 
                  title: "Stock Successfully Updated", 
                  htmlMessage: 'Your Stock Has been Updated , Remember to Check Mappings for any Unmapped stock' ,
                  maskClosable: false,
                  btnText:'OK'});
                this.uploadFileInput= "";
                this.fileInputLabel = "";
                this.toggle();
                this.isLoadingOne = false;
              }
              else
              {
                this.notification.ShowAlert({type: resultType.Error, 
                  title: "Error Failed to Upload", 
                  htmlMessage: '' ,
                  maskClosable: false,
                  btnText:'OK'});
                return;
              }
            },
            error => {
              this.notification.showError(error)
            });
        }
      }
    }

  }

  assignType(type:string)
  {
    this.selectedType = "";
    this.selectedType = this.stockTypes.find(x=> x.stockTypeId == Number(type))?.stockDescription!;
  }

  onFormAddSubmit()
  {
    Object.values(this.StockAddForm.controls).forEach(control => {
      if (control.invalid) 
      {
        control.markAsDirty();
        control.updateValueAndValidity({ onlySelf: true });
      }
    });

    if (this.StockAddForm.valid) 
    {
      let obj = new  OrderLine();
      obj.description = this.StockAddForm.value.description;
      obj.code = this.StockAddForm.value.stock_code;
      obj.qty = this.StockAddForm.value.soh;
      obj.cost = this.StockAddForm.value.cost;
      obj.price = this.StockAddForm.value.price;   
      obj.stocktypeId = this.StockAddForm.value.type;
      
      this.pricelistsService.receiveStockline(obj).subscribe(res =>
        {
          this.toggle();
          this.ngOnInit();
          this.StockAddForm.reset();
          this.notification.ShowAlert({type: resultType.Success, 
            title: "Stock Successfully Received", 
            htmlMessage: 'Your Stock Has been Added , Remember to Check Mappings for any Unmapped stock',
            maskClosable: false,
            btnText: 'OK'});
        },
        error =>
        {
          this.notification.handleError(error);
        })
    }
  }


  showQtyModal(item: PriceListItem)
  {   
    this.isQtyModalVisible = true;
    this.modifystock = item;

  }

  changeQty(newValue)
  {
    this.newsoh = newValue;
  }

  updateQty(item : PriceListItem)
  {
    let newReasonAdjust = new ReasonAdjust();
    newReasonAdjust.reasonId = Number(this.selectedReason);
    newReasonAdjust.qtyFrom =  item.soh;
    newReasonAdjust.qtyTo = this.newsoh;
    newReasonAdjust.userId = this.authService.user.user_id;
    newReasonAdjust.stockIndex =  item.stockIndex;
    if (newReasonAdjust.qtyTo  == newReasonAdjust.qtyFrom) {
      this.notification.showError("Invalid Quantity: New SOH may not be more than the current SOH");
      return;
    }
    else{
    this.pricelistsService.updateSoh(newReasonAdjust).subscribe(response =>
      {
          this.notification.showSuccess("Stock Successfully Updated");
         this.priceList.items.find(x=> x.stockIndex == item.stockIndex)!.soh = this.newsoh;
          this.isQtyModalVisible=false;
      },
      error => {
        this.notification.showError(error)
      });
    }
  }

  fitToColumn(arrayOfArray) {
    // get maximum character of each column
      return Object.keys(arrayOfArray[0]).map((a) => ({ wch: Math.max(a.length, ...arrayOfArray.map(a2 => a2[a] ? a2[a].toString().length : 0)) }));
  }
}
