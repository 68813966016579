import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { customStockItem, PosQuote, PosQuoteLine, StockSearchResult, stockType } from 'src/app/models/pos';
import { SearchObj, sfSearch } from 'src/app/models/search-obj';
import { SearchService } from 'src/app/search/search.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { UsersService } from 'src/app/users/users.service';
import { PosService } from '../../../pos.service';
import { PricelistsService } from '../../../../pricelists/pricelists.service';
import { CustomStock } from '../../../../models/pricelist';

@Component({
  selector: 'app-pos-add-lines',
  templateUrl: './pos-add-lines.component.html',
  styleUrls: ['./pos-add-lines.component.css']
})
export class PosAddLinesComponent implements OnInit {

  @Input() posQuote!: PosQuote
  @Output() itemAddedEvent = new EventEmitter<PosQuoteLine>();

  stockSearchResult: StockSearchResult[] = []
  isLoading = false;
  isLoadingCustomers = true;
  isLoadingStockSearch = false;
  isLoadingCustomer = false;
  isSavingQuote = false;
  isAddCustomerModalVisible = false;
  isSearchStockModalVisible = false;
  isSearchOtherModalVisible = false;
  isEditStockModalVisible = false;
  showCost = true;
  searchString = "";
  qtyT = "1";
  custom: CustomStock[];
  editcustom: CustomStock;
  addType:string = "";
  isAddCustomStockModalVisible = false;
  title: string = "";
  stockTypes: stockType[] = [];
  desc:string = "";
  code:string = "";
  price:number = 0;
  type:string = "";



  constructor(private userService: AuthService, private posService: PosService, private notification: NotificationService, private searchService: SearchService,private pricelistsService: PricelistsService) { }

  ngOnInit(): void {
    this.editcustom = new CustomStock();
    this.custom = [];
    this.posService.getStockTypes().subscribe(res => {
      this.stockTypes = res;
    })
  }

  searchStock(data: PosQuoteLine) {
    this.isLoadingStockSearch = true;
    this.stockSearchResult = [];
    this.isSearchStockModalVisible = true;
    this.posService.searchStock(data.stockCode).subscribe(
      val => {
        this.stockSearchResult = val;
        this.isLoadingStockSearch = false;
        this.isSearchStockModalVisible = true;
      },
      error => {
        this.notification.handleError(error);
      }
    )
   
  }

  filterSearchStock(pos: number): StockSearchResult[] {
    return this.stockSearchResult.filter(x => x.locationId == pos);
  }

  selectSearchItem(item: StockSearchResult) {
    this.isSearchStockModalVisible = false;
    this.isSearchOtherModalVisible = false;

    let res = new PosQuoteLine();
    res.brand = item.brand;
    res.brandLogoUrl = item.brandLogoUrl;
    res.clientId = item.supplierId;
    res.clientName = item.supplier;
    res.cost = item.cost;
    res.description = item.description;
    res.difference = 0;
    res.locationId = item.locationId;
    res.msfid = item.msfid;
    res.price = item.price;
    res.priceIncl = res.brand == "Service" ? item.price : Math.round(item.price * this.posQuote.vatRate);
    res.qty = res.brand == "Service" ? 1 : 4;
    res.quoteLineId = 0;
    res.soh = item.soh;
    res.stockCode = item.stockCode;
    res.stockType = res.brand == "Service" ? "SERVICE" : "TYRE";
    res.recommended = false;
    res.origClientId = item.supplierId;
    res.origClientName = item.supplier;
    res.origCost = item.cost;
    res.customStockId = Number(item.stockId);
    this.itemAddedEvent.emit(res);

  }

  showExtra_Tyre() {
    this.stockSearchResult = [];
    this.isLoadingStockSearch = true;

    // if (this.searchString == "" && this.posQuote.quoteLines && this.posQuote.quoteLines[0] && this.posQuote.quoteLines[0].description.length > 9) {
    //   this.searchString = this.posQuote.quoteLines[0].description.substring(0,9);
    // } //this was causing issues with weird tyre descriptions... so removed.
    if (!this.searchString) {
      if (!this.posQuote.width || !this.posQuote.profile || !this.posQuote.rim) {
        this.isLoadingStockSearch = false;
        this.isSearchStockModalVisible = true;
        return;
      }
      this.searchString = this.posQuote.width.toString().concat(this.posQuote.profile.toString(), this.posQuote.rim.toString())
    }

    let search : sfSearch =
    {
     search_logic : {
                     search_requirements :
                                           {
                                             raw_search: this.searchString
                                           }
                   },
     sellerId : this.userService.user.client_id
   }
    this.searchService.searchStock(search).subscribe(
      
      val => {
        for (let item of val["tyres"]) {
          let res = new StockSearchResult();
          item.gp_rands = Math.round(item.market_price - item.cost);
          res.brand = item.brand;
          res.brandLogoUrl = item.image;
          res.cost = item.cost;
          res.description = item.description;
          res.locationId = item.location_levelId;
          res.msfid = item.msfid;
          res.price = Math.round(item.cost + item.gp_rands);
          res.sla = item.sla;
          res.soh = item.sohInt;
          res.stockCode = item.stock_code;
          res.supplier = item.seller_name;
          res.supplierId = item.sellerId;
          this.stockSearchResult.push(res);
        }
        this.isLoadingStockSearch = false;
        this.isSearchStockModalVisible = true;

      },
      error => {
        this.isLoadingStockSearch = false;
        this.notification.handleError(error);
      }
    )


  }

  showExtra_Other(searchType: string) {
    this.addType = searchType;
    this.stockSearchResult = [];
    this.isLoadingStockSearch = true;
    this.posService.searchOtherStock(searchType).subscribe(
      val => {
        this.stockSearchResult = val
        this.isLoadingStockSearch = false;
        this.isSearchOtherModalVisible = true;
      },
      error => {
        this.notification.handleError(error);
        this.isLoadingStockSearch = false;
        this.isSearchOtherModalVisible = true;
      }

    )
  }
  
  addByMsfids(items: number[]) {
    items.forEach(element => {
      this.posService.getItemByMsfid(element).subscribe(
        val => {
          this.itemAddedEvent.emit(val);
        },
        error => {
          this.notification.handleError(error);
        }
      )
    });
  }

  updatecustom()
  {
      this.pricelistsService.updatecustom(this.editcustom).subscribe(res =>
        {
          this.notification.showSuccess("Updated Successfully");
          this.isEditStockModalVisible = false;
          this.isSearchOtherModalVisible = false;
          this.isSearchStockModalVisible = false;
        },
        error =>
        {
          this.notification.handleError(error);
        });
  }

  loadstockinfo(item: CustomStock)
  {
    this.editcustom = item;
    this.isEditStockModalVisible = true;

  }

  openAdd()
  {
    this.isSearchOtherModalVisible = false;
    this.isAddCustomStockModalVisible = true;
    this.title = "Add new " + this.addType +" item";


  }

  submitCustomStock(){
    if(this.desc == ""){this.notification.showWarning("Please Enter Product Description");}
    if(this.code == ""){this.notification.showWarning("Please Enter Stock Code");}
    if(this.price == 0){this.notification.showWarning("Please Enter Retail Price");}
    if(this.desc != "" && this.code !="" && this.price != 0 && this.addType != "")
    {
      let custom = new customStockItem()
      custom.description = this.desc.toUpperCase();
      custom.code = this.code.toUpperCase();
      custom.retail_price = this.price;
      custom.type = this.addType.toUpperCase();
      custom.stockTypeId = this.stockTypes.find(x=> x.stockDescription == this.addType)?.stockTypeId
      this.isLoading = true;
      this.posService.addCustomStock(custom).subscribe(
        val => {
          this.isAddCustomStockModalVisible = false;
          this.isLoading = false;
          this.notification.showInfo(val.Message);
          //add item to quote:
          // this.posService.getItemByCustomStockCode(val.customStockId).subscribe(
          //   val => {
          //     this.itemAddedEvent.emit(val);
          //   },
          //   error => {
          //     this.isSearchOtherModalVisible = true; //if we fail to insert item to quote, open the modal again
          //     this.notification.handleError(error);
          //   }
          // )
          let res = new PosQuoteLine();
          res.brand = "Service";
          res.brandLogoUrl = "none.jpg";
          res.clientId = this.userService.user.client_id;
          res.clientName = this.userService.user.client_name;
          res.cost = 0;
          res.description = custom.description;
          res.difference = 0;
          res.locationId = 1;
          res.msfid = val.msfId;
          res.price = custom.retail_price;
          res.priceIncl = res.brand == "Service" ? custom.retail_price : Math.round(custom.retail_price * this.posQuote.vatRate); //TODO: Refactor when custom stock saves price ex vat
          res.qty = 1;
          res.quoteLineId = 0;
          res.soh = 20;
          res.stockCode = custom.code;
          res.stockType = "SERVICE";
          res.recommended = false;
          res.origClientId = this.userService.user.client_id;
          res.origClientName = this.userService.user.client_name;
          res.origCost = 0;
          res.customStockId = Number(val.customStockId);
          this.itemAddedEvent.emit(res);
        },
        error => {
          this.isAddCustomStockModalVisible = false;
          this.notification.handleError(error);
        }
      )
    }
  }

  typeAssign(type: string){
    (document.getElementById("custom_type") as HTMLInputElement).value = type;
  }


}
