import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NotificationService } from '../shared/notification.service';
import { LogAudit } from '../models/logs';

// actionId 	Description

// 1	        Clicked on the Quick-Quote button
// 2	        Clicked on the Manual-Quote button
// 3	        Clicked on the Stock Image button
// 4	        Switched into Selling Mode
// 5	        Hovered over the recomended pricing for more than two seconds
// 6	        Used the Retail Less calculator
// 7	        Changed the pricing matrix
// 8	        Changed the brand strategy
// 9	        Printed the Quote
// 10	        Emailed Quote
// 11	        Viewed Leads
// 12	        Viewed Purchases


@Injectable({
  providedIn: 'root'
})

export class LogService implements OnDestroy{

  private actionLogTimer;
  apiUrl = 'v1/logs/';
  actionAuditLog: LogAudit[] = [];

  constructor(private http: HttpClient, private notification: NotificationService) {  
    if(!this.actionLogTimer)  
      clearInterval(this.actionLogTimer);
    this.actionLogTimer = setInterval(() => {    
      // this.SaveActionsSub().subscribe(() => {})
      this.SaveActions();
    }, 60000) 
  }
  
    public addAction(item: LogAudit) {
    this.actionAuditLog.push(item);
    localStorage.setItem("ActionLogs", JSON.stringify(this.actionAuditLog));
  }

  public SaveActions() {
    let results = JSON.parse(localStorage.getItem("ActionLogs")!);
      if (!Array.isArray(results)) {
        results = [];
      }
    //create early exit
    if (results.length === 0) {
        return;
    }
    this.http.post(environment.ApiUrl() + this.apiUrl + "actionLogs", results)
    .subscribe(
      {next: _ => {
        localStorage.removeItem("ActionLogs");
        this.actionAuditLog = [];
        },
      error: error => {
          this.notification.handleErrorSilent(error);
        }
      })
      
  }

  public SaveActionsSub() {
    //Not used anymore?
      // update current array to writing
      let results = JSON.parse(localStorage.getItem("ActionLogs")!);
      if (!Array.isArray(results)) {
        results = [];
      }
      return this.http.post(environment.ApiUrl() + this.apiUrl + "actionLogs", results)
      .pipe(tap(
          () => {
            localStorage.removeItem("ActionLogs");
            this.actionAuditLog = [];
          },
          error => {
            this.notification.handleErrorSilent(error);
          })
      )
  }

  ngOnDestroy(): void {
    clearInterval(this.actionLogTimer);
  }
}

