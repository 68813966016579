<ng-template #prefixUser>
    <span nz-icon nzType="user" nzTheme="outline"></span>
</ng-template>
<ng-template #prefixCard>
    <span nz-icon nzType="idcard" nzTheme="outline"></span>
</ng-template>
<ng-template #prefixCar>
    <span nz-icon nzType="car" nzTheme="outline"></span>
</ng-template>

<div class="container-fluid col-11  bg-1">
    <div class="row justify-content-md-center">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 offset-3">
                    <p></p>
                    <h5><span class="text-style">{{posQuote.sellerName}} </span> - Quote: {{posQuote.quoteRef}}</h5>
                </div>
            </div>
        </div>
        <br>
        <div class="container-fluid">
            <div class="row" style="height: auto;">
                <div class="col-lg-4">
                    <p></p>
                    <nz-input-group [nzAddOnBefore]="prefixUser">
                        <input nz-input value="{{posQuote.custName}}" placeholder="Customer" readonly>
                    </nz-input-group>
                    <p></p>

                    <nz-input-group [nzAddOnBefore]="prefixCard">
                        <input nz-input value="{{posQuote.custCell}}" placeholder="Registartion No." readonly>
                    </nz-input-group>
                    <p></p>
                    <nz-input-group [nzAddOnBefore]="prefixCar">
                        <input nz-input value="{{posQuote.custEmail}}" placeholder="Vehicle Make" readonly>
                    </nz-input-group>

                </div>
                <div *ngIf="this.isCompleted == false" class="col-lg-8 text-center">
                    <app-pq-add-lines [posQuote]="posQuote" (itemAddedEvent)="addItemToQuote($event)">
                    </app-pq-add-lines>
                </div>
                <div *ngIf="this.isCompleted == true" class="col-lg-8 text-center">
                    <div style="text-align: center">
                        <div class="justify-content-md-center">
                            <h5>Completed - {{this.posQuote.state.dt_processed}}</h5>
                        </div>
                        <div id="circle" style="display: inline-block;">
                            <div class="loader">
                                <div class="loader">
                                    <div class="loader">
                                    </div>
                                </div>
                            </div>
                            <div class="percent text-center">
                                100%
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <!--Middle Row Options-->
            <div *ngIf="this.isCompleted == false" class="container-fluid">
                <div class="row">
                    <div class="col-sm-2">
                        <div class="float-left">
                            <button nz-button nzType="primary" (click)="saveQuote()">
                            <i nz-icon nzType="save" nzTheme="outline"></i>

                            </button>
                        </div>
                    </div>
                    <div class="col-sm-8 text-center justify-content-center">
                        <button nz-button nzType="primary" hidden class="btn btn-lg">
                            <span nz-icon nzType="file-pdf" nzTheme="outline"></span>
                            Auth Letter
                        </button>
                    </div>
                    <div class="col-sm-2">
                        <div class="float-right">
                            <button nz-button nzType="primary" class="btn btnround " style="margin-left:5px" (click)="clearQuote()">
                                <i nz-icon nzType="delete" nzTheme="outline"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <!--Table-->
            <!-- <div class="container-fluid"> -->
            <nz-table #quotes [nzData]="posQuote.quoteLines" style="width:100%;" id="example" [nzFrontPagination]="false">
                <thead>
                    <th>Brand</th>
                    <th>Description</th>
                    <th>Code</th>
                    <th>SOH</th>
                    <th>Quantity</th>
                    <th>Price <span class="text-style">(*VAT)</span></th>
                    <th>Total Price <span class="text-style">(*VAT)</span></th>
                    <th></th>
                </thead>
                <tbody class="tbody">
                    <tr *ngFor="let item of quotes.data; let i = index">
                        <td>
                            <img *ngIf="item.stockType== 'TYRE'" style='border-radius:10px' height='25' width='50' src='https://sftptyres.blob.core.windows.net/brands-images/brands/{{ item.brandLogoUrl }}'>
                            <img *ngIf="item.stockType== 'SERVICE'" style='border-radius:10px' height='25' width='50' src='https://sftptyres.blob.core.windows.net/brands-images/brands/service.jpg'>
                        </td>
                        <td><button nz-button nzType="default" class=' btn text-left' (click)="searchStock(item)">{{ item.description }}</button></td>
                        <td>{{ item.stockCode}}</td>
                        <td>{{ item.soh }}</td>
                        <td>
                            <nz-input-number [(ngModel)]="item.qty" [nzMin]="1" [nzMax]="500" [nzStep]="1" style="width: 55px;" (ngModelChange)="updateLineItem(i)">
                            </nz-input-number>
                        </td>
                        <td>
                            <div *ngIf="(item.stockType && item.stockType.toUpperCase() == 'TYRE'); else nonTyrePrice">
                                <nz-input-number [(ngModel)]="item.priceIncl" [nzDisabled]="true" style="width: 100px;" [nzFormatter]="randFormatter">
                                </nz-input-number>
                            </div>
                            <ng-template #nonTyrePrice>
                                <nz-input-number [(ngModel)]="item.priceIncl" [nzDisabled]="true" style="width: 100px;" [nzFormatter]="randFormatter">
                                </nz-input-number>
                            </ng-template>
                        </td>
                        <td>
                            <b>R <span class="text-info-style">{{ item.totalPriceIncl }}  </span></b>
                            <div class="smallText">
                                <sup style="color: #117274;">{{ item.gpPercent }}</sup>
                                <sub>{{ item.clientName }}</sub>
                            </div>
                        </td>
                        <td>
                            <button nz-button nzType="primary" class="btn btnremove" (click)="removeLineItem(i)">
                                <i nz-icon nzType="minus" nzTheme="outline"></i>
                                </button>
                            <span *ngIf="item.stockType && item.stockType.toUpperCase() == 'TYRE'">
                                    <nz-divider nzType="vertical" ></nz-divider>
                                        <button nz-button nzType="primary" class="btn btnaccept" (click)="selectLineItem(i)">
                                            <i nz-icon nzType="check" nzTheme="outline" nz-tooltip nzTooltipTitle="Select Item"></i>
                                        </button>
                                </span>
                        </td>
                    </tr>
                    <tr *ngIf="this.isCompleted == false">
                        <td>
                            <input nz-input placeholder="Stock Code" [(ngModel)]="searchString" (keyup.enter)="searchStockInput()" class="submit trinput" />
                        </td>
                        <td colspan="8">
                        </td>
                    </tr>
                </tbody>
            </nz-table>
            <!-- </div> -->
            <br>
            <!--comment row-->
            <div class="container">
                <div class="row">
                    <div class="col-sm-6 offset-3">
                        <p class="text-center">Comment</p>
                        <!-- <div class="input-group text-center"> -->
                        <nz-input-group nzSearch [nzAddOnAfter]="suffixSaveButton">
                            <input nz-input [(ngModel)]="posQuote.comment" placeholder="Type your notes here..." />
                        </nz-input-group>
                        <!-- <div class="input-group-append "> -->
                        <ng-template #suffixSaveButton>
                            <button nz-button nzType="primary" class="btn btn-sm " (click)="savecomment()" style="border-bottom-left-radius: 0px; border-top-left-radius: 0px;">Save</button>
                        </ng-template>
                        <!-- </div> -->
                        <!-- </div> -->
                    </div>
                </div>
            </div>
            <br>
            <!--end row-->
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-4">
                        <button nz-button nzType="primary" *ngIf="this.isCompleted == false" class="btn btn-md submit float-left " (click)=" isSubmitModalVisible  = true ">
                            <span nz-icon nzType="send" nzTheme="outline"></span>
                            Submit for Authorization
                        </button>
                    </div>
                    <div class="col-lg-4">
                    </div>
                    <div class="col-lg-4 ">
                        <div class="float-right">
                            <h4>Total : <span class="text-info-style">R </span>{{totalPriceInc()}}</h4>
                        </div>
                    </div>
                </div>
            </div>
            <br>
        </div>
    </div>
</div>


<!--Modals-->
<nz-modal [(nzVisible)]="isSearchStockModalVisible" nzTitle="Stock Availability" (nzOnCancel)="isSearchStockModalVisible = false" [nzFooter]="null" [nzWidth]="'900px'">
    <div *nzModalContent>
        <nz-tabset>
            <nz-tab nzTitle="Local">
                <nz-table #searchTable1 [nzData]="filterSearchStock(1)" [nzLoading]="isLoadingStockSearch" [nzShowPagination]="false" [nzBordered]="false" [nzOuterBordered]="false" nzSize="small" [nzNoResult]="stockSearchNoResults" class="table table-striped table-borderless table-hover table-responsive-sm ">
                    <thead>
                        <tr>
                            <th>Brand</th>
                            <th>Description</th>
                            <th>SOH</th>
                            <th>SLA</th>
                            <th>Supplier</th>
                            <th>Price</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody class="tbody">
                        <tr *ngFor="let item of searchTable1.data">
                            <td><img src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{item.brandLogoUrl}}" class="brandImage" /></td>
                            <td>{{item.description}}</td>
                            <td>{{item.soh}}</td>
                            <td>{{item.sla}}</td>
                            <td>{{item.supplier}}</td>
                            <td>{{item.price}}</td>
                            <td>
                                <a (click)="selectSearchItem(item)">
                                    <i nz-icon nzType="check-circle" nzTheme="outline" style="font-size: 1.5rem; color: #136413;" nz-tooltip nzTooltipTitle="Select Item"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </nz-table>
            </nz-tab>
            <nz-tab nzTitle="Group">
                <nz-table #searchTable2 [nzData]="filterSearchStock(2)" [nzLoading]="isLoadingStockSearch" [nzShowPagination]="false" [nzBordered]="false" [nzOuterBordered]="false" nzSize="small" [nzNoResult]="stockSearchNoResults">
                    <thead>
                        <tr>
                            <th>Brand</th>
                            <th>Description</th>
                            <th>SOH</th>
                            <th>SLA</th>
                            <th>Supplier</th>
                            <th>Price</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody class="tbody">
                        <tr *ngFor="let item of searchTable2.data">
                            <td><img src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{item.brandLogoUrl}}" class="brandImage" /></td>
                            <td>{{item.description}}</td>
                            <td>{{item.soh}}</td>
                            <td>{{item.sla}}</td>
                            <td>{{item.supplier}}</td>
                            <td>{{item.price}}</td>
                            <td>
                                <a (click)="selectSearchItem(item)">
                                    <i nz-icon nzType="check-circle" nzTheme="outline" style="font-size: 1.5rem; color: #136413;" nz-tooltip nzTooltipTitle="Select Item"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </nz-table>
            </nz-tab>
            <nz-tab nzTitle="Supplier">
                <nz-table #searchTable3 [nzData]="filterSearchStock(3)" [nzLoading]="isLoadingStockSearch" [nzShowPagination]="false" [nzBordered]="false" [nzOuterBordered]="false" nzSize="small" [nzNoResult]="stockSearchNoResults">
                    <thead>
                        <tr>
                            <th>Brand</th>
                            <th>Description</th>
                            <th>SOH</th>
                            <th>SLA</th>
                            <th>Supplier</th>
                            <th>Price</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody class="tbody">
                        <tr *ngFor="let item of searchTable3.data">
                            <td><img src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{item.brandLogoUrl}}" class="brandImage" /></td>
                            <td>{{item.description}}</td>
                            <td>{{item.soh}}</td>
                            <td>{{item.sla}}</td>
                            <td>{{item.supplier}}</td>
                            <td>{{item.price}}</td>
                            <td>
                                <a (click)="selectSearchItem(item)">
                                    <i nz-icon nzType="check-circle" nzTheme="outline" style="font-size: 1.5rem; color: #136413;" nz-tooltip nzTooltipTitle="Select Item"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </nz-table>
            </nz-tab>
            <nz-tab nzTitle="NRS">
                <nz-table #searchTable4 [nzData]="filterSearchStock(4)" [nzLoading]="isLoadingStockSearch" [nzShowPagination]="false" [nzBordered]="false" [nzOuterBordered]="false" nzSize="small" [nzNoResult]="stockSearchNoResults">
                    <thead>
                        <tr>
                            <th>Brand</th>
                            <th>Description</th>
                            <th>SOH</th>
                            <th>SLA</th>
                            <th>Supplier</th>
                            <th>Price</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody class="tbody">
                        <tr *ngFor="let item of searchTable4.data">
                            <td><img src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{item.brandLogoUrl}}" class="brandImage" /></td>
                            <td>{{item.description}}</td>
                            <td>{{item.soh}}</td>
                            <td>{{item.sla}}</td>
                            <td>{{item.supplier}}</td>
                            <td>{{item.price}}</td>
                            <td>
                                <a (click)="selectSearchItem(item)">
                                    <i nz-icon nzType="check-circle" nzTheme="outline" style="font-size: 1.5rem; color: #136413;" nz-tooltip nzTooltipTitle="Select Item"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </nz-table>
            </nz-tab>
        </nz-tabset>
        <ng-template #stockSearchNoResults>
            <div style="text-align: center;">
                <i nz-icon nzType="warning" nzTheme="outline" class="text-warning-style " style="font-size: 4rem;"></i>
                <br />
                <b>NO STOCK FOUND</b>
            </div>
        </ng-template>
    </div>
</nz-modal>


<nz-modal [(nzVisible)]=" isSubmitModalVisible " nzClosable="true" nzTitle="Please Provide Fleet Details" (nzOnCancel)=" isSubmitModalVisible  = false ;" (nzOnOk)="onModalOk()" [nzFooter]="null">
    <div *nzModalContent>
        <form [formGroup]="authForm" class="text-center">
            <nz-form-item>
                <nz-form-control>
                    <label class="text-style">Milage</label>
                    <input class="inputdiv" (keypress)="numberOnly($event)" maxlength="6" nz-input name="odometer" formControlName="odometer" placeholder="Vehicle Milage">
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control>
                    <label class="text-style">Driver Name</label>
                    <input class="inputdiv" nz-input name="drivername" formControlName="drivername" placeholder="Driver Name">
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control>
                    <label class="text-style">Driver Contact No</label>
                    <input class="inputdiv" (keypress)="numberOnly($event)" maxlength="10" nz-input name="drivercell" formControlName="drivercell" placeholder="Driver Cell">
                </nz-form-control>
            </nz-form-item>
        </form>
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-2 offset-5">
                    <button nz-button nzType="default" [nzLoading]="isSavingQuote" class="btn" (click)="onModalOk()">Submit</button>
                </div>
            </div>
        </div>
    </div>
</nz-modal>