import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { customerobj } from '../models/customer';
import { SearchService } from '../search/search.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  apiUrl = 'v1/customers/';


  constructor(private http: HttpClient, private searchService: SearchService) { }


  public getCustomerData(obj: customerobj)
  {
    return this.http.post( environment.ApiUrl() + this.apiUrl + "getCustomerData",  obj);
  }

  public AddCustomer(customer)
  {
    return this.http.post(environment.ApiUrl() + this.apiUrl + "addCustomerData", customer)
    .pipe(
      tap(() => {this.searchService.refreshSearchData()}) //tap when complete to refresh the local search data with new customer details
    );
  }

  public updateCustomer(customer)
  {
    return this.http.post(environment.ApiUrl() + this.apiUrl + "updateCustomerData", customer)
    .pipe(
      tap(() => {this.searchService.refreshSearchData()}) //tap when complete to refresh the local search data with new customer details
    );
  }

  public updateVehicle(vehicle)
  {
    return this.http.post(environment.ApiUrl() + this.apiUrl + "updateVehicle", vehicle);
  }

  public getCustomerList(cell: string)
  {
    return this.http.get( environment.ApiUrl() + this.apiUrl + `getCustomerDetailsWhatsApp/${cell}`);
  }

}
