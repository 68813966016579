import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Quote, QuoteLine } from 'src/app/classes/quote';
import { NotificationService } from 'src/app/shared/notification.service';
import { PosService } from '../pos.service';
import { Router } from '@angular/router';
import { PosActionLogEntry, PosAuditEntry, PosAuditEntryType, StockSearchResult, customStockItem, stockType } from 'src/app/models/pos';
import { DatePipe } from '@angular/common';
import { SendReportService } from 'src/app/widgets/send-report/send-report.service';
import { AuthService } from 'src/app/auth.service';
import { QuoteCommunication } from 'src/app/models/communication';
import { PosAuditService } from '../posaudit.service';
import { CustomStock } from 'src/app/models/pricelist';
import { PricelistsService } from 'src/app/pricelists/pricelists.service';
import { SearchService } from 'src/app/search/search.service';
import { sfSearch } from 'src/app/models/search-obj';
import { ReportsService } from 'src/app/shared/reports.service';

@Component({
  selector: 'app-pos-actions',
  templateUrl: './pos-actions.component.html',
  styleUrls: ['./pos-actions.component.less']
})
export class PosActionsComponent implements OnInit {

  @Input() quote : Quote = new Quote();
  @Output() quoteChange = new EventEmitter<Quote>(); //this is not used at the moment... but if needs be, this can emit to update the quote upwards

  @Output() statusChange = new EventEmitter<boolean>();
  @Output() addItems = new EventEmitter<QuoteLine[]>();

  public _isLoading: boolean;
  @Input() set isLoading(value: boolean) {
    this._isLoading = value;
    if (value===false) {
      //do after load
      //this.updateVehicleForm(); 
    }
  };

  datepipe: DatePipe = new DatePipe('en-US'); //used to format dates in action log
  actionLog: PosActionLogEntry[] = [];

  isLoadingActionLog = false;
  isLoadingServices = false;
  isLoadingCustomStockGroups = false;
  isActionLogModalVisible = false;
  commentHolder:string = "";
  inputValue: string = "";
  inputList: string [] = ["Description", "stockCode"]
  
  newQuoteAdd: QuoteLine [] = [];
  tyreStockTab = [{name: 'Local', id: 1}, {name: 'Group', id: 2}, {name: 'Supplier', id: 3}, {name: 'NRS', id: 4}]

  services: {packageTypeId: number, packageDescription: string, packageAcronym: string, clientId: number}[] = [];
  customStockGroups: {stockTypeId: number, stockDescription: string, stockTypeImage: string}[] = [];

  constructor(private notification: NotificationService, 
    private posService: PosService, 
    private router: Router, 
    private sendReportService: SendReportService,
    private authService: AuthService,
    private auditService: PosAuditService,
    private pricelistsService: PricelistsService,
    private searchService: SearchService,
    private reportsService: ReportsService) { }

  ngOnInit(): void {
    this.editcustom = new CustomStock();
    this.commentHolder = this.quote.comment;
  
    //load services

    this.isLoadingServices = true;
    this.posService.serviceGroups().subscribe({
      next: val => {
        this.services = val;
        this.isLoadingServices = false;
      },
      error: err => {
        this.notification.handleError(err);
        this.isLoadingServices = false;
      }
    })

    // Load Custom Stock
    this.posService.customStockGroups().subscribe({
      next: val => {
        this.customStockGroups = val;
        this.isLoadingCustomStockGroups = false;
      },
      error: err => {
        this.notification.handleError(err);
        this.isLoadingCustomStockGroups = false;
      }
    })
  }

  updateMainQuote() {   
    this.quote.markDirty();
  }

  updateQuoteComment()
  {
    if(this.quote.comment != this.commentHolder)
    {
      this.auditService.addAction(new PosAuditEntry(this.quote.quoteId, PosAuditEntryType.SalesmanAddComment, 0, "",this.commentHolder,this.quote.comment));
      this.commentHolder = this.quote.comment;
      this.updateMainQuote(); 
    }
    
  }

  navigateToCustomer() {
    
    if (this.quote.custId === 0 || this.quote.custId === 14041) {
      this.notification.showWarning("Select customer first");
      return;
    }
    localStorage.removeItem("customerData");
    var Customer = { "customerId" : this.quote.custId }
    localStorage.setItem('customerData', JSON.stringify(Customer));
    //this is not according to standard due to the fact that customers is not working according to standard.
    this.router.navigate(['/customer']);
  }

  onStatusChangeClick() {
    this.statusChange.emit(false);
  }

  showActionLog() {
    this.isLoadingActionLog = true;
    this.posService.getActionLog(this.quote.quoteId).subscribe({
      next: val => {
        this.actionLog = val;
        this.isActionLogModalVisible = true;
        this.isLoadingActionLog = false;
      },
      error: error => {
        this.notification.handleError(error);
        this.isLoadingActionLog = false;
      }

    })
  }

  printJobCard() {
    this.reportsService.openJobCard(this.quote.quoteId,"JOBCARD",this.quote.isUpdateWIPRevision);
    this.quote.isUpdateWIPRevision = false;
    this.router.navigate(['/wip']);
  }

  onShareClick() {
    //is it necessary to save the quote?
    let rpt = new QuoteCommunication(this.quote.quoteRef, 
      this.quote.quoteId, 
      this.authService.user.user_id, 
      this.quote.custCell, 
      this.quote.custEmail, 
      this.quote.custId);
    //rpt.disableWhatsapp = this.quote.channelId == 140; //if the buyerId is 140, set the quote not not be whatsappable..
    rpt.disableWhatsapp = false;
    this.sendReportService.assignReport(rpt);
  }

  onClearQuoteClick() {
    //action audit
    this.auditService.addAction(new PosAuditEntry(this.quote.quoteId, PosAuditEntryType.ClearQuote, 0));
    this.quote.lines = [];
    this.quote.lines = this.quote.lines.slice(); //This will copy the array and assign it again. To circumvent angular change detection
    this.quote.markDirty();
  }

  onRemoveServicesClick() {
    this.quote.lines = this.quote.lines.filter(e => e.isTyre === true);
    this.quote.markDirty();
  }

  onAddServiceGroupClick(groupId: number) {
    this.isLoadingServices = true;
    this.posService.servicesByServiceGroupSpecialPrice(groupId, this.quote.specialPriceSetId).subscribe({
      next: val => {
        this.isLoadingServices = false;
        let newLines: QuoteLine[] = [];
        
        val.forEach(e => newLines.push(Object.assign(new QuoteLine() , e)));
        
        this.addItems.emit(newLines);
        if(this.quote.lines.some(val => val.isTyre === true)){
          this.quote.updateLockedQuantities(this.quote.lines[0].qty);
        }
        else{
          for (const item of this.quote.lines)
            item.totalPriceIncl = item.priceIncl * item.qty;
        }
      },
      error: err => {
        this.notification.handleError(err);
        this.isLoadingServices = false;
      }
    })
    
  }

  isSearchStockModalVisible = false;
  searchString = "";
  showExtra_Tyre() {
    this.stockSearchResult = [];
    this.isLoadingStockSearch = true;

    // if (this.searchString == "" && this.posQuote.quoteLines && this.posQuote.quoteLines[0] && this.posQuote.quoteLines[0].description.length > 9) {
    //   this.searchString = this.posQuote.quoteLines[0].description.substring(0,9);
    // } //this was causing issues with weird tyre descriptions... so removed.
    if (!this.searchString) {
      if (!this.quote.width || !this.quote.profile || !this.quote.rim) {
        this.isLoadingStockSearch = false;
        this.isSearchStockModalVisible = true;
        return;
      }
      this.searchString = this.quote.genCode.toString()
    }

    let search : sfSearch =
    {
     search_logic : {
                     search_requirements :
                                           {
                                             raw_search: this.searchString,
                                             specialPriceSetId: this.quote.specialPriceSetId
                                           }
                   },
     sellerId : this.authService.user.client_id
   }

    this.searchService.searchStock(search).subscribe(
      val => {
        for (let item of val["tyres"]) {
          let res = new StockSearchResult();
          item.gp_rands = Math.round(item.market_price - item.cost);
          res.brand = item.brand;
          res.brandLogoUrl = item.image;
          res.cost = item.cost;
          res.description = item.description;
          res.locationId = item.location_levelId;
          res.msfid = item.msfid;
          res.price = Math.round(item.cost + item.gp_rands);
          res.sla = item.sla;
          res.soh = item.sohInt;
          res.stockCode = item.stock_code;
          res.supplier = item.seller_name;
          res.supplierId = item.sellerId;
          res.width = item.width;
          res.profile = item.profile;
          res.rim = item.rim;
          res.market = item.market;
          this.stockSearchResult.push(res);
          
          // Removes from add list if the tyre is already on the quote

          // this.stockSearchResult = this.stockSearchResult.filter(({ msfid }) => !this.quote.lines.some((e) => e.msfid === msfid));

          
        }
        this.isLoadingStockSearch = false;
        this.isSearchStockModalVisible = true;

      },
      error => {
        this.isLoadingStockSearch = false;
        this.notification.handleError(error);
      }
    )


  }

  filterSearchStock(pos: number): StockSearchResult[] {
    return this.stockSearchResult.filter(x => x.locationId == pos);
  }

  addType = "";
  stockSearchResult: StockSearchResult[] = []
  filteredResults: StockSearchResult[] = []
  isLoadingStockSearch = false;
  isSearchOtherModalVisible = false;
  showExtra_Other(searchTypeId: number, searchTypeString: string) {
    this.addType = searchTypeString;
    this.stockSearchResult = [];
    this.isLoadingStockSearch = true;
    this.posService.searchOtherStockWithSpecialPrice(searchTypeId, this.quote.specialPriceSetId).subscribe(
      val => {
        this.stockSearchResult = val
        this.isLoadingStockSearch = false;
        this.isSearchOtherModalVisible = true;
        this.filteredResults = this.stockSearchResult;
        this.updateDisplayedFilteredData(); 
        
      },
      error => {
        this.notification.handleError(error);
        this.isLoadingStockSearch = false;
        this.isSearchOtherModalVisible = true;
      }

    )
  }

  checkIfStockSearchResultOnQuote(item: StockSearchResult) {
    return this.quote.lines.some(e => e.customStockId === item.stockId);
  }

  stockSearchNoResults = false;
  currentPage = 1; // Current page
  pageSize = 10; // Number of items per page
  displayedResults: StockSearchResult[] = [];
  onAdvancedFilterChange(){
    if (this.inputValue) {
      this.filteredResults = this.stockSearchResult.filter(item => 
        item.description.toLowerCase().includes(this.inputValue.toLowerCase()) ||
        item.stockCode.toLowerCase().includes(this.inputValue.toLowerCase())
      );
    } else {
      this.filteredResults = this.stockSearchResult; // Reset to original data
    }

    this.currentPage = 1;
    
    this.updateDisplayedFilteredData();
    // this.stockSearchNoResults = this.filteredResults.length === 0;
  }

  updateDisplayedFilteredData() {
    // Calculate which items to display based on pagination
    const start = (this.currentPage - 1) * this.pageSize;
    const end = start + this.pageSize;
    this.displayedResults = this.filteredResults.slice(start, end);
  }

  onPageChange(page: number) {
    this.currentPage = page;
    this.updateDisplayedFilteredData(); // Update displayed items on page change
  }
  
  
  // checkLine(msfid:number,description:string){
  //   var resp = false
  //   for(let x =0; x < this.quote.lines.length;x++){
  //     if(this.quote.lines[x].msfid+this.quote.lines[x].description == msfid+description){
  //       resp = true; 
  //     }
  //   }
  //   return resp
    
  // }

  isAddCustomStockModalVisible = false;
  title = "";
  desc:string = "";
  code:string = "";
  price:number = 0;
  type:string = "";
  openAdd() {
    this.isSearchOtherModalVisible = false;
    this.isAddCustomStockModalVisible = true;
    this.title = "Add new " + this.addType +" item";
  }

  editcustom: CustomStock;
  isEditStockModalVisible = false;
  loadstockinfo(item: CustomStock)
  {
    this.editcustom = item;
    this.isEditStockModalVisible = true;

  }

  updateCustomStockItem()
  {
      this.pricelistsService.updatecustom(this.editcustom).subscribe(res =>
        {
          this.notification.showSuccess("Updated Successfully");
          this.isEditStockModalVisible = false;
          this.isSearchOtherModalVisible = false;
          this.isSearchStockModalVisible = false;
        },
        error =>
        {
          this.notification.handleError(error);
        });
  }

  stockTypes: stockType[] = [];
  addCustomStockItem() {
    if(this.desc == ""){this.notification.showWarning("Please Enter Product Description");}
    if(this.code == ""){this.notification.showWarning("Please Enter Stock Code");}
    if(this.price == 0){this.notification.showWarning("Please Enter Retail Price");}
    if(this.desc != "" && this.code !="" && this.price != 0 && this.addType != "")
    {
      this.posService.getStockTypes().subscribe(res => {
        this.stockTypes = res;
        let custom = new customStockItem()
      custom.description = this.desc.toUpperCase();
      custom.code = this.code.toUpperCase();
      custom.retail_price = this.price;
      custom.type = this.addType.toUpperCase();
      custom.stockTypeId = this.stockTypes.find(x=> x.stockDescription == this.addType)?.stockTypeId
      this.isLoading = true;
      this.posService.addCustomStock(custom).subscribe(
        val => {
          this.isAddCustomStockModalVisible = false;
          this.isLoading = false;
          this.notification.showInfo(val.Message);
          
          let res = new QuoteLine();
          res.brand = "Service";
          res.brandLogoUrl = "none.jpg";
          res.clientId = this.authService.user.client_id;
          res.clientName = this.authService.user.client_name;
          res.cost = 0;
          res.description = custom.description;
          res.difference = 0;
          res.locationId = 1;
          res.msfid = val.msfId;
          res.isTyre = res.brand === "Service" ? false : true;
          res.price = custom.retail_price;
          res.priceIncl = res.isTyre ? Math.round(res.price * this.quote.vatRate) : res.price; //TODO: Refactor when custom stock saves price ex vat
          res.qty = 1;
          res.totalPriceIncl = res.priceIncl * res.qty;
          
          res.quoteLineId = 0;
          res.soh = 20;
          res.stockCode = custom.code;
          res.stockType = res.isTyre ? "TYRE" : "SERVICE";
          res.recommended = false;
          res.origClientId = this.authService.user.client_id;
          res.origClientName = this.authService.user.client_name;
          res.origCost = 0;
          res.customStockId = Number(val.customStockId);

          this.addItems.emit([res]);
        },
        error => {
          this.isAddCustomStockModalVisible = false;
          this.notification.handleError(error);
        }
      )
      })
      
    }
  }

  stockSearchToQuoteLine(item: StockSearchResult): QuoteLine{
    let res = new QuoteLine();
    res.brand = item.brand;
    res.brandLogoUrl = item.brandLogoUrl;
    res.clientId = item.supplierId;
    res.clientName = item.supplier;
    res.cost = item.cost;
    res.description = item.description;
    res.difference = 0;
    res.locationId = item.locationId;
    res.msfid = item.msfid;
    res.price = item.price;
    res.isTyre = res.brand === "Service" ? false : true;
    res.priceIncl = res.isTyre ? Math.round(item.price * this.quote.vatRate) : item.price;    
    res.qty = res.isTyre ? 4 : 1;
    res.totalPriceIncl = res.priceIncl * res.qty;
    res.quoteLineId = 0;
    res.soh = item.soh;
    res.stockCode = item.stockCode;
    res.stockType = res.isTyre ? "TYRE" : "SERVICE";
    
    res.recommended = false;
    res.origClientId = item.supplierId;
    res.origClientName = item.supplier;
    res.origCost = item.cost;
    res.rank = res.isTyre ? 95 : 99;
    res.customStockId = Number(item.stockId);
    res.isBuyOut = item.isBuyOut;
    res.linkedToTyreQty = item.linkedToTyreQty;
    res.width = item.width;
    res.profile = item.profile;
    res.rim = item.rim;

    res.market = item.market ? item.market : "UNKNOWNMARKET";
    return res
  }

  selectSearchItem(item: StockSearchResult) {

    this.isSearchStockModalVisible = false;
    this.isSearchOtherModalVisible = false;

    //let res = item.stockSearchToQuoteLine(item, this.quote);  
    let res: QuoteLine = this.stockSearchToQuoteLine(item);
    if(!this.newQuoteAdd.length)
      this.addItems.emit([res]);
    else{
      this.addItems.emit(this.newQuoteAdd);

      this.newQuoteAdd = [];
    }
  }

  selectMultipleSearchItems(item: StockSearchResult){
    //let res = item.stockSearchToQuoteLine(item, this.quote);
    let res: QuoteLine = this.stockSearchToQuoteLine(item);
    if(item.selected)
      this.newQuoteAdd.push(res);
    else {
      this.newQuoteAdd.splice(this.newQuoteAdd.findIndex(e => e.msfid === res.msfid), 1);
    }
  
  }

  addMultipleSearchItems(){
    this.isSearchStockModalVisible = false;
    this.isSearchOtherModalVisible = false;
    
    // for(let n = 0 ; n < this.newQuoteAdd.length;n++){
    //   if(['Service'].includes(this.newQuoteAdd[n].brand)){
    //     // console.log(this.newQuoteAdd[n])
    //     // this.newQuoteAdd[n].brandLogoUrl = 'service.jpg'

    //     // if(this.newQuoteAdd[n].price > 0){ //Making sure VAT manipulation is not done on Discounts
    //     //   var price:number = +(this.newQuoteAdd[n].price+(this.newQuoteAdd[n].price*0.15)).toFixed(2)
    //     //   this.newQuoteAdd[n].price = price

    //     //   var tp_incl:number = +(this.newQuoteAdd[n].totalPriceIncl+(this.newQuoteAdd[n].totalPriceIncl*0.15)).toFixed(2)
    //     //   this.newQuoteAdd[n].totalPriceIncl = tp_incl;
          
    //     //   var p_incl:number = +(this.newQuoteAdd[n].priceIncl+(this.newQuoteAdd[n].priceIncl*0.15)).toFixed(2)
    //     //   this.newQuoteAdd[n].priceIncl = p_incl
    //     // }
    //   }
    // }
    this.addItems.emit(this.newQuoteAdd);

    this.newQuoteAdd = [];

  }

}
