import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';  
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FilterPipe } from './filter.pipe';




//Internationalisation
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';


//NG-Zorro
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzRateModule } from 'ng-zorro-antd/rate';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzQRCodeModule } from 'ng-zorro-antd/qr-code';
import { NzTreeFlatDataSource, NzTreeFlattener, NzTreeViewModule } from 'ng-zorro-antd/tree-view';
//Components
import { AppComponent } from './app.component';
import { SearchComponent } from './search/search.component';
import { SalesComponent } from './sales/sales.component';
import { PurchasesComponent } from './purchases/purchases.component';
import { LeadsComponent } from './leads/leads.component';
import { LoginComponent } from './login/login.component';
import { AuthInterceptorService } from './auth-intercepter.service';
import { ChangePasswordComponent } from './settings/change-password/change-password.component';
import { InsightsComponent } from './insights/insights.component';
import { SearchesInsightsComponent } from './insights/searches-insights/searches-insights.component';
import { FleetComponent } from './fleet/fleet.component';
import { StockMappingComponent } from './stock-mapping/stock-mapping.component';
import { NgChartsModule } from 'ng2-charts';
import { SalesInsightsComponent } from './insights/sales-insights/sales-insights.component';
import { ConversionsInsightsComponent } from './insights/conversions-insights/conversions-insights.component';
import { UpcomingComponent } from './leads/upcoming/upcoming.component';
import { QuotesComponent } from './leads/quotes/quotes.component';
import { ClientComponent } from './client/client.component';
import { ClienteditComponent } from './client/clientedit/clientedit.component';
import { ReplenishmentsComponent } from './replenishments/replenishments.component';
import { DiagnosticComponent } from './diagnostic/diagnostic.component';
import { PosquoteComponent } from './pos/posquote/posquote.component';
import { ClientaddComponent } from './client/clientadd/clientadd.component';
import { PricelistsComponent } from './pricelists/pricelists.component';
import { StockOrderComponent } from './stock-order/stock-order.component';
import { PqCustomerDetailsComponent } from './pos/posquote/pq-customer-details/pq-customer-details.component';
import { CustomersComponent } from './customers/customers.component';
import { PosfleetComponent } from './pos/posfleet/posfleet.component';
import { PqAddLinesComponent } from './pos/posquote/pq-add-lines/pq-add-lines.component';
import { UsersComponent } from './users/users.component';
import { SearchesDashboardInsightsComponent } from './insights/searches-dashboard-insights/searches-dashboard-insights.component';
import { SearchbarComponent } from './searchbar/searchbar.component';
import { PricingComponent } from './settings/pricing/pricing.component';
import { StockOverrideComponent } from './stock-override/stock-override.component';
import { PricingmatrixComponent } from './pricelists/pricingmatrix/pricingmatrix.component';
import { CustomstockComponent } from './pricelists/customstock/customstock.component';
import { PampricesComponent } from './pricelists/pamprices/pamprices.component';
import { GrvComponent } from './grv/grv.component';
import { NmsfComponent } from './nmsf/nmsf.component';
import { SaleviewComponent } from './sales/saleview/saleview.component';
import { PurchaseviewComponent } from './purchases/purchaseview/purchaseview.component';
import { HelpComponent } from './help/help.component';
import { DirectuploadComponent } from './pricelists/directupload/directupload.component';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { LeadsCurrentComponent } from './leads/leads-current/leads-current.component';
import { LeadsFutureComponent } from './leads/leads-future/leads-future.component';
import { LeadsQuotesComponent } from './leads/leads-quotes/leads-quotes.component';
import { BookingsSlotsComponent } from './settings/bookings-slots/bookings-slots.component';
import { BookingsSlotsDayComponent } from './settings/bookings-slots/bookings-slots-day/bookings-slots-day.component';
import { SettingsMainComponent } from './settings/settings-main/settings-main.component';
import { CustomerComponent } from './settings/customer/customer.component';
import { CustomerEditComponent } from './settings/customer/customer-edit/customer-edit.component';
import { BrandStrategyComponent } from './settings/brand-strategy/brand-strategy.component';
import { StockModelsComponent } from './stock-models/stock-models.component';
import { MessagesComponent } from './messages/messages.component';
import { NoCommaPipe } from './messages/no-comma.pipe';

import { ModelStockAnalysisComponent } from './model-stock-analysis/model-stock-analysis.component';

import { WipComponent } from './wip/wip.component';
import { SendReportComponent } from './widgets/send-report/send-report.component';
import { PosCustomerComponent } from './pos/posquoteV2/posquote-v2/pos-customer/pos-customer.component';
import { PosVehiclesComponent } from './pos/posquoteV2/posquote-v2/pos-vehicles/pos-vehicles.component';
import { PosAddLinesComponent } from './pos/posquoteV2/posquote-v2/pos-add-lines/pos-add-lines.component';
import { PosquoteV2Component } from './pos/posquoteV2/posquote-v2/posquote-v2.component';
import { ComponentLegendComponent } from './debug/component-legend/component-legend.component';
import { ModalContentComponent } from './shared/modal-content/modal-content.component';
import { PosComponent } from './pos/pos.component';
import { QuoteLinesComponent } from './pos/quote-lines/quote-lines.component';
import { PosCustomerDetailsComponent } from './pos/pos-customer-details/pos-customer-details.component';
import { PosVehicleDetailsComponent } from './pos/pos-vehicle-details/pos-vehicle-details.component';
import { PosActionsComponent } from './pos/pos-actions/pos-actions.component';
import { WhatsappComponent } from './whatsapp/whatsapp.component';
import { WaConversationComponent } from './whatsapp/wa-conversation/wa-conversation.component';
import { WaInboxComponent } from './whatsapp/wa-inbox/wa-inbox.component';
import { SettingsMainV2Component } from './settings/settings-main-v2/settings-main-v2.component';
import { AdminWhatsappComponent } from './admin/admin-whatsapp/admin-whatsapp.component';
import { AdminWaInboxComponent } from './admin/whatsapp/admin-wa-inbox/admin-wa-inbox.component';
import { AdminWaConversationComponent } from './admin/whatsapp/admin-wa-conversation/admin-wa-conversation.component';
import { PosWbcComponent } from './pos/pos-wbc/pos-wbc.component';


// Directives
import { AutofocusDirective } from './auto-focus.directive';
import { VideoWidgetComponent } from './help/video-widget/video-widget.component';
import { AdminUsersComponent } from './admin/admin-users/admin-users.component';
import { ProcurementMaintenanceComponent } from './procurement-maintenance/procurement-maintenance.component';
import { ProcurementClientModelStockComponent } from './procurement-client-model-stock/procurement-client-model-stock.component';
import { ModalVideoComponent } from './widgets/modal-video/modal-video.component';
import { StockAddNmsfComponent } from './stock-mapping/stock-add-nmsf/stock-add-nmsf.component';
import { AdminStockMappingsComponent } from './admin-stock-mappings/admin-stock-mappings.component';
import { ModalMappingComponent } from './widgets/modal-mapping/modal-mapping.component';
import { CopyClipboardDirective } from './copy-clipboard.directive';
import { AllUsersComponent } from './all-users/all-users.component';
import { ClientStockMappingsComponent } from './client-stock-mappings/client-stock-mappings.component';
import { ClientMappingsModalComponent } from './client-stock-mappings/client-mappings-modal/client-mappings-modal.component';
import { PriceMatrixComponent } from './pricelists/price-matrix/price-matrix.component';


@NgModule({
  declarations: [
    AppComponent,
    AutofocusDirective,
    SearchComponent,
    SalesComponent,
    PurchasesComponent,
    LeadsComponent,
    LoginComponent,
    ChangePasswordComponent,
    InsightsComponent,
    FleetComponent,
    SearchesInsightsComponent,
    SalesInsightsComponent,
    ConversionsInsightsComponent,
    UpcomingComponent,
    QuotesComponent,
    ClientComponent,
    ClienteditComponent,
    ReplenishmentsComponent,
    DiagnosticComponent,
    PosquoteComponent,
    ClientaddComponent,
    PricelistsComponent,
    StockMappingComponent,
    StockOrderComponent,
    CustomersComponent,
    PosfleetComponent,
    PqAddLinesComponent,
    UsersComponent,
    SearchesDashboardInsightsComponent,
    SearchbarComponent,
    PricingComponent,
    StockOverrideComponent,
    PricingmatrixComponent,
    CustomstockComponent,
    GrvComponent,
    NmsfComponent,
    SaleviewComponent,
    PurchaseviewComponent,
    HelpComponent,
    DirectuploadComponent,
    SuppliersComponent,
    LeadsCurrentComponent,
    LeadsFutureComponent,
    LeadsQuotesComponent,
    BookingsSlotsComponent,
    BookingsSlotsDayComponent,
    SettingsMainComponent,
    PampricesComponent,
    CustomerComponent,
    CustomerEditComponent,
    BrandStrategyComponent,
    StockModelsComponent,
    MessagesComponent,
    NoCommaPipe,
    ModelStockAnalysisComponent,
    WipComponent,
    SendReportComponent,
    PosCustomerComponent,
    PosVehiclesComponent,
    PosAddLinesComponent,
    PosquoteV2Component,
    PqCustomerDetailsComponent,
    ComponentLegendComponent,
    ModalContentComponent,
    PosComponent,
    QuoteLinesComponent,
    PosCustomerDetailsComponent,
    PosVehicleDetailsComponent,
    PosActionsComponent,
    WhatsappComponent,
    WaConversationComponent,
    WaInboxComponent,
    SettingsMainV2Component,
    AdminWhatsappComponent,
    AdminWaInboxComponent,
    AdminWaConversationComponent,
    PosWbcComponent,
    VideoWidgetComponent,
    AdminUsersComponent,
    FilterPipe,
    ProcurementMaintenanceComponent,
    ProcurementClientModelStockComponent,
    ModalVideoComponent,
    StockAddNmsfComponent,
    AdminStockMappingsComponent,
    ModalMappingComponent,
    CopyClipboardDirective,
    AllUsersComponent,
    ClientStockMappingsComponent,
    ClientMappingsModalComponent,
    PriceMatrixComponent,
    

    
    ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NzIconModule,
    NzLayoutModule,
    NzMenuModule,
    NzTableModule,
    NzInputModule,
    NzButtonModule,
    NzSpinModule,
    NzNotificationModule,
    NzFormModule,
    NzAlertModule,
    NzToolTipModule,
    NzModalModule,
    CommonModule,
    NgChartsModule,
    NzTabsModule,
    NzTabsModule,
    NzSelectModule,
    NzAutocompleteModule,
    NzSkeletonModule,
    NzSelectModule,
    NzInputNumberModule,
    NzPopoverModule,
    NzDividerModule,
    NzDatePickerModule,
    NzDropDownModule,
    NzDatePickerModule,
    NzBadgeModule,
    NzCalendarModule,
    NzSwitchModule,    
    ScrollingModule,
    DragDropModule,
    NzPaginationModule,
    NzCheckboxModule,
    NzRadioModule,
    NzResultModule,
    NzCardModule, 
    NzProgressModule,
    NzDrawerModule,
    NzListModule,
    NzAffixModule,
    NzAvatarModule,
    NzImageModule,
    NzRateModule,
    NzAffixModule, 
    NzSpaceModule, 
    NzPopconfirmModule,
    NzUploadModule,
    NzSliderModule,
    NzCollapseModule,
    NzStepsModule,
    NzEmptyModule,
    NzQRCodeModule,
    NzTreeViewModule
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
