<div class="container-fluid">
    <div class="row ">
        <div class="col-lg-2">
        </div>
        <div class="col-lg-8 " style="text-align: center;">
            <h4 class="color"><b>
        <i nz-icon nzType="inbox" nzTheme="outline"></i>
        
        Inbox</b></h4>
        </div>
        <div class="col-lg-2">
        </div>
    </div>
</div>

<div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="22" nzXl="22" nzXXl="22" class="container-fluid">
    <div nz-row nzJustify="center">
        <div nz-col nzXs="0" nzSm="0" nzMd="0" nzLg="8" nzXl="8" nzXXl="8">
        </div>
        <div nz-col nzXs="20" nzSm="20" nzMd="20" nzLg="8" nzXl="8" nzXXl="8">
            <nz-input-group [nzPrefix]="Search" class="text-center" style="margin-bottom: 15px">
                <input type="text" nz-input placeholder="Search Messages" [(ngModel)]="listFilter" (keyup)="filterlist()" />
            </nz-input-group>
            <ng-template #Search>
                <span nz-icon nzType="search"></span>
            </ng-template>
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="8" nzXl="8" nzXXl="8" class="text-center">
            <button nz-button nzType="primary" style="margin: 5px;" (click)="MarkAllAsRead()">
        <span nz-icon nzType="read" nzTheme="outline"></span> Mark All as Read
      </button>
            <button nz-button nzType="primary" style="margin: 5px;" (click)="DeleteAll()">
        <span nz-icon nzType="delete" nzTheme="outline"></span> Delete All Read
      </button>
        </div>
    </div>
</div>
<div class="container-fluid col-lg-11  text-center bg-1 ">
    <br>
    <div class="row">
        <div class="col-lg-12">
            <nz-spin [nzSpinning]="isLoading">
                <nz-table #stockTable [nzData]="filteredData" [nzPageSize]="20">
                    <thead>
                        <tr>
                            <th colSpan="4">
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let message of stockTable.data" (click)="openMessage(message)">
                            <td style="width:20px;">
                                <i *ngIf="!message.messageOpened" nz-icon nzType="mail" nzTheme="outline" class="envelope sf"></i>
                                <span *ngIf="message.messageOpened" nz-icon nzType="sf:mailOpenSVG" nzTheme="outline"></span>
                            </td>
                            <td style="width:100px;">
                                <div>{{message.dateCreated | date:'dd MMM' }}</div>
                                <div>{{ message.dateCreated | date:'shortTime' }}</div>
                            </td>
                            <td style="font-size: 15px">
                                {{ message.message }}
                                <!-- <span *ngIf="message.messageLong">
                  - {{ (message.messageLong.length>120)? (message.messageLong| slice:0:120)+'...':(message.messageLong) }}
                </span> -->
                            </td>

                        </tr>
                    </tbody>
                </nz-table>
            </nz-spin>
        </div>
    </div>


</div>


<nz-modal [(nzVisible)]="isMessageVisible" nzClosable="false" nzTitle="Message" (nzOnCancel)="isMessageVisible= false;" [nzFooter]="null" [nzWidth]="750">
    <ng-container *nzModalContent>
        <div style="margin-bottom: 70px; margin-top: -70px;">
            <button nz-button nzType="primary" style="float: right; background-color: var(--backcolor); border: none !important; height: 50px; border-radius: 25px;" (click)="DeleteMsg()" [nzLoading]="isDeleting">
      <span nz-icon nzType="delete" nzTheme="outline" style="font-size: 1.5rem !important;"></span> 
    </button>
    </div>
    <div>
      <p class="float-right tm ">{{ readingMessage.dateCreated.toString() + 'Z' | date:'medium' }}</p>
    </div>
    <br>
    <div class="message">
      <span *ngIf="readingMessage.message != ''" >
        {{ readingMessage.message}}
        <hr>
      </span>
            <div *ngIf="readingMessage.messageLong != ''" [innerHtml]="readingMessage.messageLong">
            </div>
            <br>
            <br>
            <span *ngIf="readingMessage.action1Text != null">
        <button nz-button nzType="primary" [routerLink]="readingMessage.action1Url">
          {{ readingMessage.action1Text }}
          
          <span nz-icon nzType="sf:externalSVG" nzTheme="outline" ></span>
            </button>
            </span>
        </div>


    </ng-container>
</nz-modal>