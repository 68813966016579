export class Purchase {
        name:string
        cell:string
        orderRef:string

        SFRef:string     // sf sales order ref
        SysRef:string    // client pos ref so SS/IQ
        PoRef:string     // po ref for email clients
        ExtRef:string    // stock order ref External so SB Custom etc
        
        comment:string
        orderid:number
        statusId:number
        dateformatted : string
        qty:number
        price:number
        dt : string
        dt_email:string
        dt_so: string
        errorId:number  
        show:string
        username:string
        useremail:string
        error:string
        errorFix: number
        sellerId: number
        sell_state: string
        isReceived: number
        isReceiveable:boolean
        showERef: boolean = false 
        showDelivery: boolean = false 
        showVreg: boolean = false 
        showMilage: boolean = false 
        showVmake:boolean = false 
        delivery: string = "" 
        milage: string = "" 
        v_make: string = "" 
        v_reg: string = "" 
        pos!: string
}

export class Purchaseline {
        description_Long:string
        code: string
        orderId: number
        qty: number
        price: number
        cost: number
        gp: number
        brandLogo_url: string
}

export class OrderLine {
  description:string
  code: string
  qty: number
  cost: number
  price?:number
  stocktypeId?:number
}

// ---Class for supplier spend---------
export class SupplierSpend{
        supplierId: number
        supplier_name: string
        invoices: number
        units: number
        total_spend: number
}

// --- Class for brand spend ---------
export class BrandSpend{
        brandId : number
        brand_name: string
        brand_logo: string
        invoices: number
        units: number
        total_spend: number
}

