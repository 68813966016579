import { EventEmitter } from "@angular/core"
import { PosQuoteBooking } from "../models/pos"


export class QuoteLine {
    difference: number = 0
    gpPercent: number = 0
    gpPercentLow: number = 0
    gpPercentHigh: number = 0
    gpRands: number = 0
    gpRandsLow: number = 0
    gpRandsHigh: number = 0
    fiveLow: number = 0
    fiveHigh: number = 0
    totalPriceIncl: number = 0
    cost: number = 0
    description: string
    locationId: number = 0
    msfid: number = 0
    price: number = 0
    priceIncl: number = 0
    quoteLineId: number = 0
    qty: number = 0
    linkedToTyreQty: boolean = true
    fixedQty?: number = 1
    soh: number = 0
    stockCode: string
    stockType: string
    stockTypeId: number = 0
    brand: string
    brandLogoUrl: string
    clientName: string
    clientId: number = 0
    recommended: boolean
    origClientName?: string
    origClientId?: number = 0
    origCost?: number = 0
    customStockId: number = 0
    stockUsedId: number = 0
    rank?: number = 0
    isTyre: boolean = false
    width: number;
    profile: number;
    rim: number;
    optionId: number|null = null
    gpUpDownPercent: number = 3;
    retail?: number = 0
    retailValuePercent?:number = 0
    retailValueLess?:number = 0
    isBuyOut: boolean
    isUnfolded: boolean = false
    altStockCode: string
    altStockDescription: string
    market: string;
    usedStockClassification: string;
    
    public updateLineItemGPList(vatRate: number) {

        this.gpPercent = Math.round(100 * (this.price - this.cost) / this.price); 
        this.gpRands = Math.round(this.price - this.cost); 
        this.gpPercentHigh = this.gpPercent + this.gpUpDownPercent;
        this.gpPercentLow = this.gpPercent - this.gpUpDownPercent;

        this.gpRandsHigh = Math.round((this.cost / (100 - this.gpPercentHigh) * 100) - this.cost);
        this.gpRandsLow = Math.round((this.cost / (100 - this.gpPercentLow) * 100) - this.cost);

        this.fiveHigh = +((this.cost + this.gpRandsHigh) * vatRate).toFixed(0);
        this.fiveLow = +((this.cost + this.gpRandsLow) * vatRate).toFixed(0);
    }

    public changeGPPercent(percent: number, vatRate: number) {
        this.gpPercent = percent;

        this.price = Math.round((this.cost / (100 - this.gpPercent) * 100));
        this.priceIncl = Math.round(this.price * vatRate);
        this.totalPriceIncl = this.priceIncl * this.qty;

        this.updateLineItemGPList(vatRate);
    }  
}

export class sbFleetAuthData {
    reg: string
    odo: number = 0
    driver_name: string
    driver_cell: string
}

export class CustomerGroup {
    customerGroupId: number;
    customerGroupName: string;
    canAddMembers: boolean;
    canRemoveMembers: boolean;
}

export class Quote {
    quoteId: number = 0;
    manualInsert: boolean
    quoteRef: string;
    clientId: number;
    channelId: number;
    vatRate: number = 0;
    lines: QuoteLine[];
    custId: number;
    custName: string;
    custCell: string;
    custEmail: string;
    custVATNumber: string;
    custDebtorAccount: string;
    custCustomerGroupIds: number[];
    parentCustId: number;
    parentCustName: string;
    running_total: number;
    stateId: number;
    stateDescription: string;
    quoteStatusId: number;
    dt_act: Date;
    dt_processed: Date;
    Vehicle_Customer_MappingId: number;
    vehicleOdoId: number;
    comment: string;
    rim: number = 0
    width: number = 0
    profile: number = 0 
    genCode: string = ''
    customerVehicles: CustomerVehicleDetails[] = [];
    userId: number
    userCell: string
    userFullname: string
    booking: PosQuoteBooking
    wbcActive: boolean
    selectedOption: number
    isSaving: boolean = false;
    isDirty: boolean = false;
    isUpdateWIPRevision: boolean = false;
    authType: number = 0; //Auth type 0 = NOT FLEET, the rest is fleet types. 1 = SB
    customerGroups: CustomerGroup[] = [];
    customerOrderReference: string;

    specialPriceSetId: number = 0;
    specialPriceSetName: string = "";  
    showNavAway:boolean = true;
    leadChannel:boolean = false;
    leadType:boolean = false;
    selectedChannel: number;
    selectedType: number;
    discountRules: DiscountRule[] = [];
    shouldApplyDiscounts: boolean = true;
    gpProtectionPercent: number | null = null;
    sbFleetAuthData: sbFleetAuthData;
    quoteToBeSaved$?: EventEmitter<boolean> = new EventEmitter<boolean>();
    actionLogToBeSaved$?: EventEmitter<boolean> = new EventEmitter<boolean>();

    payments: Payment[] = []
    invoiceDeliveryMethod: number = 0

    get canApplyDiscountEngine(): boolean {
        return this.specialPriceSetId === 0;
    }

    get hasCustomer(): boolean {        
        return (this.custId !== undefined && !(this.custId === 0 || this.custId === 14041));
    }

    get hasVehicle(): boolean {        
        return (this.Vehicle_Customer_MappingId !== undefined && this.Vehicle_Customer_MappingId !== null  && this.Vehicle_Customer_MappingId !== 0);
    }

    get isWIP(): boolean {
        return [26,27].includes(this.quoteStatusId);
    }

    get isCompleted() : boolean {
        return this.stateId === 3;
    }

    get canBeReactivated() : boolean{
        const currentDate = new Date()
        const dateAction = new Date(this.dt_act);

        let Difference_In_Time = currentDate.getTime() - dateAction.getTime();

        let Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
        
        return Difference_In_Days <= 45;
    }

    get canSave() : boolean {
        if(this.lines.some(line => line.priceIncl.toString() === "" && line.qty.toString() === "")){
            return false;
        }
        if(this.gpProtectionPercent !== null && this.lines.some(line => line.gpPercent < this.gpProtectionPercent!)){
            return false;
        }
        return true;
    }

    public updateLockedQuantities(qty: number) {        
        for (const item of this.lines) {
            if (item.isTyre || item.linkedToTyreQty === true) {
                item.qty = qty;
                item.totalPriceIncl = item.priceIncl * item.qty;
            }
        }
    }

    public sortLines() {
        this.lines = this.lines.sort((a,b) => a.rank! - b.rank!)
    }

    public markDirty(skipReload: boolean = true) {
        this.isDirty = true;
        //check if we must update the WIP revision
        if (this.isWIP) {
            this.isUpdateWIPRevision = true;
        }
        //fire the save algo        
        this.quoteToBeSaved$?.emit(skipReload);
    }

    public markActionLog() {
        this.actionLogToBeSaved$?.emit(true);
    }
    
    
}

export class QuoteLineAdded {
    quoteLineId: number;
    msfid: number;
    customStockId: number;
}

export class CustomerVehicleDetails {
    Vehicle_Customer_MappingId: number;
    v_reg: string;
    vehicleOdoId: number;
    odoReading: number;
    readingDate: string;
    width: number;
    profile: number;
    rim: number;
    tyre_size: string;
    vehicleMakeId: number;
    vehicleModelId: number;
    vehicleVariantId: number;
    vehicleMake: string;
    vehicleModel: string;
    vehicleVariant: string;
    modelYear: number;
    customerId : number;
}

export class DiscountRuleDiscount extends QuoteLine {
    qty: number;
}

export class DiscountRule {
    discountRuleId: number;
    rimSize?: string;
    tyreQty?: string;
    genCodes?: string;
    markets?: string;
    BrandDescriptions?: string;
    MsfidRequirements?: string;
    hasCustomerLimits: boolean;
    discounts?: DiscountRuleDiscount[];
    
    customerGroupIds: number[];
}

export class Payment{
    paymentTypeId: number = 0
    amount: number = 0
  }

