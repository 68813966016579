import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PosAuditEntry } from '../models/pos';
import { NotificationService } from '../shared/notification.service';

@Injectable({providedIn: 'root'})
export class PosAuditService {
    constructor(private http: HttpClient, private notification: NotificationService) { }
  
    posAuditLog: PosAuditEntry[] = [];

    public addAction(item: PosAuditEntry) {
        if (this.posAuditLog.length > 0) {
            let lastItem = this.posAuditLog[this.posAuditLog.length - 1];
            if (lastItem.actionId === item.actionId && lastItem.quoteId === item.quoteId && lastItem.foreignKeyId === item.foreignKeyId && lastItem.comment === item.comment)
            {
                lastItem.valueNew = item.valueNew;
            }
            else {
                this.posAuditLog.push(item);
            }
        }
        else {
            this.posAuditLog.push(item);
        } 
    }

    public saveActions() {
        //write to api
        this.SaveActionsSub().subscribe(() => {})
        
    }

    public SaveActionsSub() {
        //update current array to writing
        const now = new Date();
        this.posAuditLog.forEach(x => {
            x.isWriting = true;
            x.writingTime = now;        
        });
        return this.http.post(environment.ApiUrl() + `v1/pos/saveQuoteActions`,this.posAuditLog.filter(x => x.writingTime === now)) //only send the items that have not been sent
        .pipe(tap(
            () => {
                this.posAuditLog = this.posAuditLog.filter(x => x.isWriting === false);
            },
            error => {
                this.notification.handleErrorSilent(error);
                //mark the array unpicked again so everything will be written on next update
                this.posAuditLog.forEach(x => {
                    x.isWriting = false;
                    x.writingTime = null;
                });
            })
        )
    }

}