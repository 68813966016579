<div nz-row>
    <div nz-col nzXs="0" nzSm="8" nzMd="6" nzLg="5" nzXl="4" nzXXl="4"></div>
    <div nz-col nzXs="24" nzSm="8" nzMd="12" nzLg="14" nzXl="16" nzXXl="16" style="text-align: center;">
        <h2><b>Search StockFinder</b></h2>
    </div>
    <div nz-col nzXs="24" nzSm="8" nzMd="6" nzLg="5" nzXl="4" nzXXl="4" class="text-center">
        <button nz-button nzType="primary" (click)="openCart()" *ngIf="isShopping">
          <span nz-icon nzType="shopping-cart" nzTheme="outline"></span>
            <span>Continue with Cart</span>
          </button>
    </div>
</div>
<br>
<div nz-row nzJustify="center" nzAlign="middle">
    <div nz-col nzXs="22" nzSm="16" nzMd="12" nzLg="9" class="text-center">
        <app-searchbar autofocus></app-searchbar>
    </div>
</div>
