// actionId 	Description

// 1	        Clicked on the Quick-Quote button
// 2	        Clicked on the Manual-Quote button
// 3	        Clicked on the Stock Image button
// 4	        Switched into Selling Mode
// 5	        Hovered over the recomended pricing for more than two seconds
// 6	        Used the Retail Less calculator
// 7	        Changed the pricing matrix
// 8	        Changed the brand strategy
// 9	        Printed the Quote
// 10	        Emailed Quote
// 11	        Viewed Leads
// 12	        Viewed Purchases
// 13           Chnaged the Suplier Rankings

export enum LogAuditType {
    None = 0,
    QuickQuote = 1, 
    ManualQuote = 2, 
    StockImage = 3, 
    SellingMode = 4, 
    PriceHover = 5, 
    RetailLess = 6,
    PricingMatrix = 7,
    BrandStrat = 8,
    PrintQuote = 9,
    EmailQuote = 10, 
    ViewLeads = 11,
    SupplierRankings = 13
    }

export class LogAudit {
    actionId: LogAuditType;
    dateActioned: Date;
    userId : number

    constructor(action: LogAuditType, userId: number = 0) {
        this.actionId = action;
        this.dateActioned = new Date();
        this.userId = userId;
      }

}