<ng-container *ngIf="this.view == 'client_list'">
    <!-- <div nz-row>
        <div nz-col nzSpan="24"><h1>Procurement Maintenance</h1></div>
    </div>
    <hr style="border-bottom: solid 2px rgb(95, 158, 160)">
    <div nz-row>
        <div nz-col nzSpan="24" style="display:flex; justify-content: center;"><h4>Client List</h4></div>
    </div> -->
    <div nz-row >
        <div nz-col nzSpan="6" >Search all clients containing the following code: 
            <nz-spin [nzSpinning]="isLoading">
                <div style="display: flex;" style="margin-top: 5px;">
                    <input nz-input placeholder="Search Stock Code" id="stockSearch" style="width: 70%; border: solid 1px var(--backcolor);" class="submit trinput" autocomplete="off" />
                    <button nz-button (click)="scanforcode()" style="background-color: var(--backcolor); margin-left: 5px; color: white;" nz-dropdown nzTrigger="click"  [nzPlacement]="'bottomLeft'"  ><span nz-icon nzType="search" nzTheme="outline"></span></button>
                </div>
            </nz-spin>
          
        </div> 
     
    </div>
    <hr style="background-color: var(--textcolor);">
    <!--[START] Modal used to edit MSLs  ------------------------------ -->
    <nz-modal [(nzVisible)]="this.viewSearchResults"  (nzOnCancel)="this.viewSearchResults= false; this.bulkEdit = false;"  [nzFooter]="null" nzTheme="fill" [nzWidth]="'80%'">
        <ng-container *nzModalContent>
            <div  nz-col nzSpan="24" >
                <span style="display: flex; justify-content: left;  margin-left: 20px;"><u><b>Code</b></u>: <b style="color: var(--warningcolor); margin-left: 5px; margin-right: 5px;"> '{{this.codeSearched}}' </b> <!--<button nz-button style="position: absolute; right: 5%;"   nz-tooltip nzTooltipTitle="use escape button or click outside this widnow to close" >ESC</button>--></span>
                <span style="display: flex; justify-content: left; margin-left: 20px;">
                    <u><b>Description</b></u>: <b style="color: var(--warningcolor); margin-left: 5px; margin-right: 5px;"> '{{this.description_match}}' </b> 
                    <img *ngIf="this.brand_Match != ''" style="margin: 0 auto;" src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{brand_logoMatch}}" class="brandImage" nz-tooltip="{{brand_Match}}" />
                </span>
                <span style="display: flex; justify-content: left; margin-left: 20px;">
                    <u><b>supplier_soh</b></u>: 
                    <b style="color: var(--warningcolor); margin-left: 5px; margin-right: 5px;" *ngIf="this.supplier_sohMatch != -1"> {{this.supplier_sohMatch}} </b>
                    <b style="color: var(--warningcolor); margin-left: 5px; margin-right: 5px;" *ngIf="this.supplier_sohMatch == -1"> N/A</b>
                </span>
                <hr style="background-color: var(--textcolor);">
                <span *ngIf="this.bulkEdit" style="display: flex; justify-content: left;" >
                    <button nz-button style="background-color: var(--backcolor); border: none; color: white;" nz-dropdown  [nzDropdownMenu]="additional_centres" >
                        Add center(s) [{{clients_with_no_match.length}}]
                    </button>
                    <nz-dropdown-menu #additional_centres="nzDropdownMenu" >
                        <ul nz-menu nzSelectable *ngFor="let item of clients_with_no_match">
                            <li nz-menu-item nzTooltipTitle="click to add" (click)="addClientMSL(item)">{{item.client_name}}</li>
                        </ul>
                    </nz-dropdown-menu>
                    <span *ngIf="this.bulkEdit" > | <nz-input-number [ngModel]="bulk_msl" [nzMin]="0"  [nzStep]="1" style="width: 70px;" (ngModelChange)="this.bulk_msl = $event"> </nz-input-number> | 
                        <button nz-button nzType="primary" (click)="updateAllRepsItems_input(bulk_msl)" style="background-color: var(--backcolor);"><span nz-icon nzType="upload" nz-tooltip="Set msl to {{bulk_msl}} for {{this.codeSearch_Results.length}} centres" nzTheme="outline"></span>
                        </button>
                        <span style="position: absolute; right: 15px;">
                            <button nz-button nzType="default" *ngIf="this.codeSearch_Results.length > 0" nz-dropdown  [nzDropdownMenu]="status_centres">set status [{{this.codeSearch_Results.length}}] center(s) <span nz-icon nzType="down" nzTheme="outline" ></span></button>
                            <nz-dropdown-menu #status_centres="nzDropdownMenu" >
                                <ul nz-menu nzSelectable >
                                    <li nz-menu-item (click)="updateAllStatusItems_input(0)">Active</li>
                                    <li nz-menu-item (click)="updateAllStatusItems_input(1)">Holding</li>
                                    <li nz-menu-item (click)="updateAllStatusItems_input(2)">Disable</li>
                                </ul>
                            </nz-dropdown-menu>
                        </span>
                    </span>
                
                </span>
            
                <br>
                <nz-spin [nzSpinning]="isLoading">
                    <nz-table  #code_results [nzData]="codeSearch_Results" [nzFrontPagination]="false" nzSize="small" nzTableLayout="auto"  [nzScroll]="{'y':this.scrollHeight}"  > <!--[nzScroll]="{'y':this.scrollHeight}"-->
                        <thead>
                            <tr class="sticky-header">
                                <th style="text-align: center;" [nzSortFn]="sortClientName_R">Client</th>
                                <!--<th style="text-align: center;">Code</th>-->
                                <!-- <th style="text-align: center;">Description</th> -->
                                <th style="text-align: center;" [nzSortFn]="sortClientMSL"><a>MSL
                                    <button nz-button nztype="default" style="margin-left: 10px;">
                                        <i *ngIf="!this.bulkEdit" nz-icon nzType="edit" nz-tooltip nzTooltipTitle="Modify MSLs" (click)="start_BulkEdit()"></i>
                                        <i *ngIf="this.bulkEdit" nz-icon nzType="save"  nz-tooltip nzTooltipTitle="SAVE MSLs" (click)="submit_bulk_updates()"></i>
                                    </button></a>
                                    <button *ngIf="this.bulkEdit" nz-button nztype="default" style="margin-left: 10px;" (click)="this.bulkEdit = false;">
                                        <i style="color: red;" nz-icon nzType="close" nzTheme="outline" nz-tooltip nzTooltipTitle="cancel bulk modification" ></i>
                                    </button>
                                </th>
                                <th style="text-align: center;" [nzSortFn]="sortClientAI_MSL">AI_MSL</th>
                                <th style="text-align: center;" [nzSortFn]="sortClient_SOH">SOH</th>
                                <th style="text-align: center;" [nzSortFn]="sortClient_Status">Status</th>
                                <!-- <th style="text-align: center;">S_SOH</th> -->
                            
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let r of code_results.data; let i = index">
                                <td *ngIf="r.client_soh != -1" style="font-size: {{fsBulkEdit}};">{{r.client_name}}</td>
                                <td *ngIf="r.client_soh == -1" style="color: var(--warningcolor); font-size: {{fsBulkEdit}};">{{r.client_name}}</td>
                                <!-- <td>{{r.stock_code}}</td> -->
                                <!-- <td>{{r.description}}</td> -->
                                <td style="font-size: {{fsBulkEdit}};">{{r.msl}} 
                                    <span *ngIf="this.bulkEdit" > | <nz-input-number [ngModel]="r.updated_msl" [nzMin]="0"  [nzStep]="1" style="width: 55px;" (ngModelChange)="updateRepItem(i,r.client_name,$event)"> </nz-input-number></span>
                                </td>
                                <td style="font-size: {{fsBulkEdit}};">{{r.suggested_msl}}</td>
                                <td style="font-size: {{fsBulkEdit}};" *ngIf="r.client_soh != -1">{{r.client_soh}}</td>
                                <td style="font-size: {{fsBulkEdit}};" *ngIf="r.client_soh == -1">-</td>

                                <!-- [START} STATUS CONFIGURATION ----------------------------------------------- -->
                                <td style="font-size: {{fsBulkEdit}};" *ngIf="r.updated_holding_status == 0"><button nz-button nztype="default" nz-dropdown  [nzDropdownMenu]="status_optionsActive">Active <span nz-icon nzType="down" nzTheme="outline" ></span></button></td>
                                <nz-dropdown-menu #status_optionsActive="nzDropdownMenu" >
                                    <ul>
                                        <li nz-menu-item (click)="updateRepItem_status(i,r.client_name,1);"  style="background-color: var(--backcolor); border-bottom: solid 1px black; border-radius: 5px; color: white;">Holding</li>
                                        <li nz-menu-item (click)="updateRepItem_status(i,r.client_name,2);"  style="background-color: var(--backcolor); border-radius: 5px; color: white;">Disabled</li>
                                    </ul>
                                </nz-dropdown-menu>

                                <td style="font-size: {{fsBulkEdit}};" *ngIf="r.updated_holding_status == 1"><button nz-button nztype="default" nz-dropdown  [nzDropdownMenu]="status_optionsHolding">Holding <span nz-icon nzType="down" nzTheme="outline" ></span></button></td>
                                <nz-dropdown-menu #status_optionsHolding="nzDropdownMenu" >
                                    <ul>
                                        <li nz-menu-item (click)="updateRepItem_status(i,r.client_name,0);"  style="background-color: var(--backcolor); border-bottom: solid 1px black; border-radius: 5px; color: white;">Active</li>
                                        <li nz-menu-item (click)="updateRepItem_status(i,r.client_name,2);"  style="background-color: var(--backcolor); border-radius: 5px; color: white;">Disabled</li>
                                    </ul>
                                </nz-dropdown-menu>

                                <td style="font-size: {{fsBulkEdit}};" *ngIf="r.updated_holding_status == 2"><button nz-button nztype="default" nz-dropdown  [nzDropdownMenu]="status_optionsDisabled">Disabled <span nz-icon nzType="down" nzTheme="outline" ></span></button></td>
                                <nz-dropdown-menu #status_optionsDisabled="nzDropdownMenu" >
                                    <ul>
                                        <li nz-menu-item (click)="updateRepItem_status(i,r.client_name,0);"  style="background-color: var(--backcolor); border-bottom: solid 1px black; border-radius: 5px; color: white;">Active</li>
                                        <li nz-menu-item (click)="updateRepItem_status(i,r.client_name,1);"  style="background-color: var(--backcolor); border-radius: 5px; color: white;">Holding</li>
                                    </ul>
                                </nz-dropdown-menu>
                                <!-- [END] STATUS CONFIGURATION ----------------------------------------------- -->
                                <!-- <td>{{r.supplier_soh}}</td> -->
                            </tr>
                        </tbody>
                    </nz-table>
                </nz-spin>
            </div>
        </ng-container>
    </nz-modal>
    <!--[END] Modal used to edit MSLs  ------------------------------ -->
    <nz-spin [nzSpinning]="isLoading" style="margin-top: 2px;" *ngIf="view == 'client_list'">
    <div nz-row *ngIf="this.Client_Data.length >0" style="z-index: -1;" >
        <div nz-col nzSpan="24">
            <nz-table  #client_lines [nzData]="Client_Data"  [nzFrontPagination]="false" [nzShowPagination]="false" nzSize="small" nzTableLayout="auto"> <!--[nzScroll]="{'y':this.scrollHeight}"-->
                <thead>
                    <tr class="sticky-header">
                        <th style="text-align: center;" [nzSortFn]="sortClientName">Client</th>
                        <th style="text-align: center;" [nzSortFn]="sortClientAcc">Account</th>
                        <th style="text-align: center;" [nzSortFn]="sortClientSellState">Sell_state</th>
                        <th style="text-align: center;" [nzSortFn]="sortClientPriceList">Price List</th>
                        <th style="text-align: center;" [nzSortFn]="sortClientSKUs">SKUs</th>
                     
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let c of client_lines.data" (click)="view_client_modelStock(c)" class="clientRow">
                        <td style="font-size: {{fsBulkEdit}};">{{c.account.buyer_name}}</td>
                        <td style="font-size: {{fsBulkEdit}};">{{c.account.debtorAcc}}</td>
                        <td style="font-size: {{fsBulkEdit}};">{{c.account.sell_state}}</td>
                        <td style="font-size: {{fsBulkEdit}};">{{c.account.pricelist_name}}</td>
                        <td style="font-size: {{fsBulkEdit}};">{{c.replenishment_lines.length}}</td>
                   

                    </tr>
                </tbody>
            </nz-table>
        </div>
    </div>
    </nz-spin>
</ng-container>

<ng-container *ngIf="this.view != 'client_list'">
    <app-procurement-client-model-stock></app-procurement-client-model-stock>
    <!-- <div nz-row  style="border: solid green;">
        <div nz-col nzSpan="24">
            <app-procurement-client-model-stock></app-procurement-client-model-stock>
            
        </div>
    </div> -->
</ng-container>