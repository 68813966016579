<div class="container">
    <div class="row ">
        <div class="col-lg-2">
            <button nz-button nzType="primary" class="btn btn-md info l r" (click)="openadd()">
            <i nz-icon nzType="plus" nzTheme="outline"></i>
            Add Client
        </button>
        </div>
        <div class="col-lg-8 " style="text-align: center;">
            <h2><b>Clients</b></h2>
        </div>
        <div class="col-lg-2">
            <app-video-widget [videoId]="11" style="float: inline-end;"></app-video-widget>

        </div>
    </div>
</div>

<br>
<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12" style="padding-left: 5%;padding-right: 4%;">
            <div class="container">
                <br>
                <div class="row justify-content-md-center">
                    <div class="col-6">

                        <nz-input-group nzSuffixIcon="search">
                            <input type="text" nz-input placeholder="Filter Clients" [(ngModel)]="clientFilter" (keyup)="filterClients()" />
                        </nz-input-group>

                    </div>
                </div>
            </div>
            <br>
            <nz-table #cldata [nzData]="this.filteredClients" [nzFrontPagination]="false" [nzLoading]="isLoading">
                <thead>
                    <tr>
                        <th *ngIf="this.authService.user.isSuperUser"></th>
                        <th [nzSortFn]="clientSortFn">Client</th>
                        <th>Price List</th>
                        <th [nzSortFn]="searchesSortFn">Searches <span class="text-style">*(7 days)</span></th>
                        <th [nzSortFn]="salesSortFn">Sales <span class="text-style">*(7 days)</span></th>
                        <th>DebtorAcc </th>
                        <th>Email </th>
                        <th>Cell</th>
                    </tr>
                </thead>
                <tbody class="tbody">
                    <tr *ngFor="let item of cldata.data">
                        <td *ngIf="this.authService.user.isSuperUser">
                            <nz-spin [nzSpinning]="isLoadingLinkedAccounts">
                                <button nz-button nzType="default" (click)="loginToAccount(item.buyerId)" [nz-tooltip]="item.isSuperUser ? 'Can not log in to other super users' : 'Switch Account'" *ngIf="linkedAccountExists(item.buyerId); else linkedAccountNotExists" [disabled]="item.isSuperUser">
                  <i nz-icon nzType="login" nzTheme="outline" style="font-size: 1.5rem !important; color: var(--textcolor)"></i>
                </button>
                                <ng-template #linkedAccountNotExists>
                                    <button nz-button nzType="default" (click)="createAccountLogin(item.buyerId)" [nz-tooltip]="item.isSuperUser ? 'Can not log in to other super users' : 'Create Login Account'" [disabled]="item.isSuperUser">
                    <i nz-icon nzType="user-add" nzTheme="outline" style="font-size: 1.5rem !important; color: brown;"></i>
                  </button>
                                </ng-template>
                            </nz-spin>
                        </td>
                        <td><button nz-button nzType="default" (click)="editclient(item.buyerId)">{{ item.name.toUpperCase() }}</button></td>
                        <td>
                            <span *ngIf="item.pricelid==0">Dont Sell</span>
                            <span *ngIf="item.pricelid==1">{{ pricelist[0].deflt }}</span>
                            <span *ngIf="item.pricelid==2">Bulk </span>
                            <span *ngIf="item.pricelid==3">Demand </span>
                            <span *ngIf="item.pricelid==4">Competitor</span>
                            <span *ngIf="item.pricelid==5">TyreMart </span>
                            <span *ngIf="item.pricelid==6">HiQ</span>
                            <span *ngIf="item.pricelid==7">Supa Quick</span>
                            <span *ngIf="item.pricelid==8">Dunlop Zone </span>
                            <span *ngIf="item.pricelid==9">TWT </span>
                            <span *ngIf="item.pricelid==10">{{ pricelist[0].plcustom1 }}</span>
                            <span *ngIf="item.pricelid==11">{{ pricelist[0].plcustom2 }}</span>
                            <span *ngIf="item.pricelid==18">{{ pricelist[0].plclear }}</span>
                            <span *ngIf="item.pricelid==13">{{ pricelist[0].plonline }}</span>
                            <span *ngIf="item.pricelid==14">{{ pricelist[0].plinternal}}</span>
                            <span *ngIf="item.pricelid==15">{{ pricelist[0].deflt }}</span>
                            <span *ngIf="item.pricelid==16">{{ pricelist[0].plretail }}</span>
                            <span *ngIf="item.pricelid==17">{{ pricelist[0].wholesale }}</span>
                            <span *ngIf="item.pricelid==12">{{ pricelist[0].plcustom3 }}</span>
                            <span *ngIf="item.pricelid==19">{{ pricelist[0].plcustom4 }}</span>
                        </td>
                        <td>{{ item.apicallcnt }}</td>
                        <td>{{ item.sohcallcnt }}</td>
                        <td>{{ item.debtorAcc }}</td>
                        <td>{{ item.email }}</td>
                        <td>{{ item.cell}}</td>
                    </tr>
                </tbody>
            </nz-table>
        </div>
    </div>
</div>

<nz-modal [(nzVisible)]="isCreateAccountModalVisible" nzClosable="true" nzTitle="Create Account" (nzOnCancel)="isCreateAccountModalVisible = false;" [nzFooter]="null" [nzWidth]="750">
    <ng-container *nzModalContent>
        <form nz-form [formGroup]="addLoginForm" style="padding-bottom: 15px;">
            <nz-form-item>
                <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="email" style="color: white !important;">Password</nz-form-label>
                <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Minimum length: 4 characters">
                    <input nz-input formControlName="password" id="password" />
                </nz-form-control>
            </nz-form-item>

        </form>
        <div style="text-align: center;">
            <button nz-button [nzLoading]="isAddingLogin" (click)="onCreateLinkedAccount()">
        <i nz-icon nzType="check-circle" nzTheme="outline" ></i> Confirm
    </button>
        </div>


    </ng-container>
</nz-modal>