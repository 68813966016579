<!--No Vehicles Linked-->
<div *ngIf="this.CustomerVehicles.length == 0 ">
    <form nz-form [formGroup]="addVehicle">
        <div class="row row-height-customer">
            <div class="col-8 offset-2">
                <nz-form-item>
                    <nz-form-control [nzErrorTip]="'Vehicle Registration Required'">
                        <div class="input-group maindiv">
                            <span class="input-group-prepend">
                                <div class="input-group-text bg-transparent">
                                    <i nz-icon nzType="car" nzTheme="outline"></i>
                            </div>
                            </span>
                            <input style="text-transform: uppercase;" nz-input name="v_reg" formControlName="v_reg" placeholder="Registration" class="form-control text-center form-control-custom">
                        </div>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div class="row row-height-customer">
            <div class="col-6">
                <nz-form-item>
                    <nz-form-control>
                        <div class="input-group maindiv">
                            <span class="input-group-prepend">
                                <div class="input-group-text bg-transparent">
                                    <i nz-icon nzType="calendar" nzTheme="outline"></i>
                                </div>
                            </span>
                            <input nz-input name="vehicleYear" formControlName="vehicleYear" [(ngModel)]="vehicleYear" placeholder=" Vehicle Year" class="form-control text-center form-control-custom">
                        </div>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="col-6">
                <nz-form-item>
                    <nz-form-control>
                        <div class="input-group maindiv">
                            <span class="input-group-prepend">
                                <div class="input-group-text bg-transparent">
                                    <span nz-icon nzType="sf:speedoMeterSVG" nzTheme="outline" ></span>
                        </div>
                        </span>
                        <input nz-input name="vehicleMilage" formControlName="vehicleMilage" placeholder="Vehicle Milage" class="form-control text-center form-control-custom">
            </div>
            </nz-form-control>
            </nz-form-item>
        </div>
</div>
</form>
<div class="container-fluid">
    <div class="row row-height-customer">
        <div class="col-6">
            <div class="input-group maindiv">
                <span class="input-group-prepend">
                    </span>
                <nz-select (ngModelChange)="onSearchVmake($event)" [(ngModel)]="vehicleMake" class="form-control text-center" nzShowSearch [nzPlaceHolder]="'Vehicle Make'" [nzShowArrow]="false" nzLayout="inline" nzAllowClear>
                    <nz-option *ngFor="let option of VehicleMakes" [nzValue]="option.vehicleMakeId" [nzLabel]="option.vehicleMake"></nz-option>
                </nz-select>
            </div>
        </div>
        <div class="col-6">
            <div class="input-group maindiv">
                <span class="input-group-prepend">
                    </span>
                <nz-select (ngModelChange)="onSearchVmodels($event)" [(ngModel)]="vehicleModel" class="form-control text-center" nzShowSearch [nzPlaceHolder]="'Vehicle Model'" [nzShowArrow]="false" nzLayout="inline" nzAllowClear>
                    <nz-option *ngFor="let option of VehicleModels" [nzValue]="option.vehicleModelId" [nzLabel]="option.vehicleModel"></nz-option>
                </nz-select>
            </div>
        </div>
    </div>
    <div class="row row-height-customer">
        <div class="col-12">
            <div class="input-group maindiv">
                <span class="input-group-prepend">
                            </span>
                <nz-select (ngModelChange)="onSearchVvariant($event)" [(ngModel)]="vehicleVariant" class="form-control text-center" nzShowSearch [nzPlaceHolder]="'Vehicle Variant'" [nzShowArrow]="false" nzLayout="inline" nzAllowClear>
                    <nz-option *ngFor="let option of VehicleVariants" [nzValue]="option.vehicleVariantId" [nzLabel]="option.vehicleVariant"></nz-option>
                </nz-select>
            </div>
        </div>
    </div>
</div>
</div>

<!--Linked Vehicles Exist-->
<div *ngIf="this.CustomerVehicles.length > 0 ">
    <div *ngFor="let vehicle of CustomerVehicles">
        <form [formGroup]="addVehicle" [hidden]="!vehicle.isVisable">
            <div class="row row-height-customer">
                <div class="col-8 offset-2">
                    <nz-form-item>
                        <nz-form-control [nzErrorTip]="'Vehicle Registration Required'">
                            <div class="input-group maindiv">
                                <span class="input-group-prepend">
                                    <div class="input-group-text bg-transparent">
                                        <i nz-icon nzType="car" nzTheme="outline"></i>
                                    </div>
                                </span>
                                <input (focusout)="updatingCheck()" value="{{vehicle.v_reg | uppercase}}" style="text-transform: uppercase;" nz-input name="v_reg" formControlName="v_reg" placeholder="Registration" class="form-control text-center form-control-custom">
                            </div>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <div class="row row-height-customer">
                <div class="col-6">
                    <nz-form-item>
                        <nz-form-control>
                            <div class="input-group maindiv">
                                <span class="input-group-prepend">
                                        <div class="input-group-text bg-transparent">
                                            <i nz-icon nzType="calendar" nzTheme="outline"></i>
                                        </div>
                                    </span>
                                <input (focusout)="updatingCheck()" value="{{vehicle.modelYear}}" nz-input name="vehicleYear" formControlName="vehicleYear" [(ngModel)]="vehicleYear" placeholder=" Vehicle Year" class="form-control text-center form-control-custom">
                            </div>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div class="col-6">
                    <nz-form-item>
                        <nz-form-control>
                            <div class="input-group maindiv">
                                <span class="input-group-prepend">
                                        <div class="input-group-text bg-transparent">
                                            <span nz-icon nzType="sf:speedoMeterSVG" nzTheme="outline" ></span>
                            </div>
                            </span>
                            <input (focusout)="updatingCheck()" value="{{vehicle.vehicleMilage | uppercase}}" nz-input name="vehicleMilage" formControlName="vehicleMilage" placeholder="Vehicle Milage" class="form-control text-center form-control-custom">
                </div>
                </nz-form-control>
                </nz-form-item>
            </div>
    </div>
    </form>
    <div class="container-fluid">
        <div class="row row-height-customer" [hidden]="!vehicle.isVisable">
            <div class="col-6">
                <div class="input-group maindiv">
                    <span class="input-group-prepend">
                                </span>
                    <nz-select (ngModelChange)="onSearchVmake($event);updatingCheck()" [(ngModel)]="vehicleMake" class="form-control text-center" nzShowSearch [nzPlaceHolder]="'Vehicle Make'" [nzShowArrow]="false" nzLayout="inline" nzAllowClear>
                        <nz-option *ngFor="let option of VehicleMakes" [nzValue]="option.vehicleMakeId" [nzLabel]="option.vehicleMake"></nz-option>
                    </nz-select>
                </div>
            </div>
            <div class="col-6">
                <nz-form-item>
                    <nz-form-control>
                        <div class="input-group maindiv">
                            <span class="input-group-prepend">
                                </span>
                            <nz-select (ngModelChange)="onSearchVmodels($event);updatingCheck()" [(ngModel)]="vehicleModel" class="form-control text-center" nzShowSearch [nzPlaceHolder]="'Vehicle Model'" [nzShowArrow]="false" nzLayout="inline" nzAllowClear>
                                <nz-option *ngFor="let option of VehicleModels" [nzValue]="option.vehicleModelId" [nzLabel]="option.vehicleModel"></nz-option>
                            </nz-select>
                        </div>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div class="row row-height-customer" [hidden]="!vehicle.isVisable">
            <div class="col-12">
                <div class="input-group maindiv">
                    <span class="input-group-prepend">
                                </span>
                    <nz-select (ngModelChange)="onSearchVvariant($event);updatingCheck()" [(ngModel)]="vehicleVariant" class="form-control text-center" nzShowSearch [nzPlaceHolder]="'Vehicle Variant'" [nzShowArrow]="false" nzLayout="inline" nzAllowClear>
                        <nz-option *ngFor="let option of VehicleVariants" [nzValue]="option.vehicleVariantId" [nzLabel]="option.vehicleVariant"></nz-option>
                    </nz-select>
                </div>
            </div>
        </div>
    </div>
</div>
</div>

<!--Navigators For Vehicles-->
<div nz-row [nzJustify]="'center'">
    <div nz-col [nzSpan]="12" *ngIf="CustomerVehicles.length > 0">
        <nz-pagination [nzPageIndex]="selectIndex+1" [nzTotal]="pageTotal" [nzSimple]="true" (nzPageIndexChange)="onPageIndexChange($event)">
            <!-- (click)="onPageIndexChange($event)" -->
        </nz-pagination>
        <!-- <ul class="pagination">
            <li *ngFor="let item of CustomerVehicles;let i = index">
                <button [ngClass]="{'activebtn': i == selectIndex, 'nonactive' : i != selectIndex}" class="btn btn-pagi" (click)="onPageIndexChange(i)">{{i+1}}</button>
            </li>
        </ul> -->
    </div>
    <div *ngIf="CustomerVehicles.length == 0 ">
        <!-- [ngStyle]="{'display': posQuote.custId == 14041 || posQuote.custId == 0  ? 'none' : 'inline' }" -->
        <button class="btn btn-serv" (click)="addVehicleCheck()" [disabled]="posQuote.custId === 14041 || posQuote.custId === 0" [nz-tooltip]="posQuote.custId === 14041 || posQuote.custId === 0 ? 'Search Cell No. First!' : ''">
            <i nz-icon nzType="link" nzTheme="outline"></i>
            Link Vehicle
        </button>
    </div>
</div>