// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { ApiEndpoint } from "src/app/models/settings";

export const environment = {
  production: false,
  api_url_main: "https://qaapi.stockfinder.co.za/",
  api_url_alt: "https://qaapi.stockfinder.co.za/",
  b2c_url: "https://qashop.stockfinder.co.za/",
  ws_url: "wss://qahub.stockfinder.co.za/v1/ws/connect",
  api_alt_weight: 50,
  apiEndpoints: [
    {url: 'https://qaapi.stockfinder.co.za/', weight: 100, isActive: true}
  ] as ApiEndpoint[],
  ApiUrl() {
    get: {
      // return Math.random() * 100 < this.api_alt_weight ? this.api_url_alt : this.api_url_main
      // Calculate the total weight
      const totalWeight = this.apiEndpoints.filter(e => e.isActive === true).reduce((sum, item) => sum + item.weight, 0);
      // Generate a random number between 0 and the total weight
      let randomNum = Math.random() * totalWeight;
      // Find the item that corresponds to the random number
      for (const item of this.apiEndpoints.filter(e => e.isActive === true)) {
        if (randomNum < item.weight) {
          return item.url;
        }
        randomNum -= item.weight;
      }
      // Fallback in case of rounding errors
      return this.apiEndpoints[0].url;
    };
  },

  hasActive() {
    get: {
      return this.apiEndpoints.some(e => e.isActive === true);
    };
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
