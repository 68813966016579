import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommunicationInfo } from '../models/communication';
import { NotificationService } from '../shared/notification.service';
import { environment } from './../../environments/environment';
import { salesAutoMAP } from '../models/stock';
import { AutoMAPSearch } from '../models/sale';

@Injectable({
  providedIn: 'root'
})

export class SalesService {
  apiUrl = 'v1/sales/';

  constructor(private http: HttpClient, private notification: NotificationService) { }

  //Get Client Sales
  public getSales()
  {
    return this.http.get( environment.ApiUrl() + this.apiUrl + "getsales");
  }

  public getSale(orderid: number)
  {
    return this.http.get( environment.ApiUrl() + this.apiUrl + "getsale/" + orderid.toString());
  }

  //Get OrderLines
  public getSalelines(orderId)
  {
    return this.http.get(environment.ApiUrl() + this.apiUrl + "salelines/"+ orderId );
  }

  public getUnseenSalesCount() {
    return this.http.get<{count: number}>(environment.ApiUrl() + this.apiUrl + "unseenCount");
  }

  public resubmit_order(orderid:number)
  {
    return this.http.get(environment.ApiUrl() + this.apiUrl + "resubmitOrder/"+ orderid );
  }

  public communicationDetails(orderId: number) {
    return this.http.get<CommunicationInfo>(environment.ApiUrl() + this.apiUrl + "communicationDetails/"+ orderId)
  }


  public emailInvoice(id: number, emailAddress: string = "") {
    return this.http.post(environment.ApiUrl() + this.apiUrl + `invoice/${id.toString()}/sendEmail${emailAddress ? "?overrideEmail=" + emailAddress : ""}`, null)

  }

  public getMappingForCode(code:string)
  {
    let searchCode = new AutoMAPSearch() 
    searchCode.code = code;
    return this.http.post(environment.ApiUrl() + this.apiUrl + "getAutoMapCode", searchCode);
  }

  public autoMapCode(Mapping:salesAutoMAP)
  {
    return this.http.post(environment.ApiUrl() + this.apiUrl + "addSalesAutoMapping", Mapping)
  }

  public markAllAsSeen() {
    return this.http.post(environment.ApiUrl() + this.apiUrl + "markAllAsSeen", null)
  }

}
