<div class="container-fluid col-lg-11">
    <!-- <div *ngIf="this.topCtrls==true;" class="flex-container" style=" display: flex; width: 100%; padding-bottom: 10px; border: solid rgb(38,38,38); border-radius: 8px; border-width: 3px; background-color: rgb(38,38,38);"> -->
    <nz-card nzSize="small" style="padding-top: 10px;">
        <div nz-row *ngIf="this.topCtrls==true;">

            <div nz-col nzSpan="8">
                <nz-spin [nzSpinning]="isLoading">
                    <input *ngIf="this.currP2_Table != 1" nz-input placeholder="Search Gen Code" [ngModel]="search" (ngModelChange)="gen_codeInputChange($event)" id="stockSearch" style="border-color: var(--backcolor); color: var(--backcolor);" autocomplete="off" />
                    <input *ngIf="this.currP2_Table == 1" nz-input placeholder="Search stock Code" [ngModel]="search" (ngModelChange)="gen_codeInputChange($event)" id="stockSearch" style="border-color: var(--backcolor); color: var(--backcolor);" autocomplete="off" />
                </nz-spin>
                <hr style="border-top: solid 2px; width: 100%; color: var(--textcolor)">
                
                <div style="padding-top: 8px; margin-left: 8px;">
                    <div style="display: flex;">
                        <b style="margin-right: 10px;">Days Of Data:</b><input nz-input placeholder="{{this.daysofdata}}" type="number" min="1" max="121" value="{{this.daysofdata}}" id="daysOfData" style="width: 27%; margin-right: 4px;" autocomplete="off"/>
                        <button nz-button nzType="primary" (click)="dodChange()"><span nz-icon nzType="search"></span></button>
                        <button nz-button nzType="primary" nz-dropdown [nzDropdownMenu]="tier_menu" style="margin-left: 5px;" nz-tooltip nzTooltipTitle="Set Tiers"><span nz-icon [nzType]="'setting'" [nzTheme]="'fill'"></span></button>
                        <div style="margin-left: 5px; justify-content: baseline;" *ngIf="this.sales_tier != 'None'">showing price tier(s): {{this.sales_tier}}</div>
                    </div>
                    <nz-dropdown-menu #tier_menu="nzDropdownMenu">
                        <div style="width: 150%;">
                            <ul nz-menu nzSelectable style="border-radius: 8px; padding-left: 10px;">
                                <li style="display: flex;">
                                    <div>Price Tier : T01</div>
                                    <div style="position: absolute; right: 5px;"><label nz-checkbox [(ngModel)]="tierA_checked"></label></div>
                                </li>
                                <li style="display: flex;">
                                    <div>Price Tier : T02</div>
                                    <div style="position: absolute; right: 5px;"><label nz-checkbox [(ngModel)]="tierB_checked"></label></div>
                                </li>
                                <li style="display: flex;">
                                    <div>Price Tier : T03</div>
                                    <div style="position: absolute; right: 5px;"><label nz-checkbox [(ngModel)]="tierC_checked"></label></div>
                                </li>
                                <li style="display: flex;">
                                    <div>Price Tier : T04</div>
                                    <div style="position: absolute; right: 5px;"><label nz-checkbox [(ngModel)]="tierD_checked"></label></div>
                                </li>
                                <!-- <li style="display: flex;"><div>sales Tier_E  :</div> <div style="position: absolute; right: 5px;"><label nz-checkbox [(ngModel)]="tierE_checked"></label></div></li> -->
                                <li style="display: flex; border-top: solid 1px var(--textcolor); border-bottom: solid 1px var(--textcolor);">
                                    <div style="margin: 0 auto;"><button nz-button nzType="default" (click)="set_tiers()">Set tiers</button></div>
                                </li>
                            </ul>
                        </div>
                    </nz-dropdown-menu>
                </div>
                <!-- <div style="border: solid brown;">
                    <a nz-dropdown [nzDropdownMenu]="menu">
                    More Filters<span nz-icon nzType="down"></span>
                    </a>
                    <nz-dropdown-menu #menu="nzDropdownMenu" >
                        <div id="filterMenu">
                            <ul nz-menu nzSelectable style="background-color: black;">
                            <li nz-menu-item style="color: white;"><b>Only Sizes With Days of Stock:</b><div id="radioDiv"><nz-switch></nz-switch></div></li>
                            <li nz-menu-item><b>Only Sizes With Sales:</b><div id="radioDiv"><nz-switch></nz-switch></div></li>
                            <li nz-menu-item><b>Only Sizes With Model Stock</b><div id="radioDiv"><nz-switch></nz-switch></div></li>
                            </ul>
                        </div>
                    </nz-dropdown-menu>
                </div> -->
            </div>

            <div nz-col nzSpan="3" nzOffset="0.5" class="text-center">
                <button nz-button nzType="default" nzSize="large" nz-dropdown [nzDropdownMenu]="alerts_menu"> View Alerts
                    
                <nz-dropdown-menu  #alerts_menu="nzDropdownMenu">
                <ul nz-menu nzSelectable  >
                    <li nz-menu-item  (click)="getAlert('NR')"><b>National Rank</b></li>
                    <li nz-menu-item  (click)="getAlert('BC')"><b>Best Cost</b></li>
                    <!-- <li nz-menu-item  (click)="getAlert('BR')"><b>Brand Rank</b></li> -->
                    <li nz-menu-item  (click)="getAlert('BS')"><b>Brand Switch</b></li>
                    <li nz-menu-item  (click)="getAlert('AI:A')"><b>Suggestion Not Applied</b></li>
                    <li nz-menu-item  (click)="getAlert('AI:B')"><b>Deviated From Suggestion</b></li>
                    <!-- <li nz-menu-item style="color: green;" (click)="fetchAlerts()"><b>ALL</b></li> -->
                </ul>
                <hr>
                </nz-dropdown-menu>
                </button>
            </div>

            <div nz-col nzSpan="12">
                <div *ngIf="this.initLoading == false && this.Model_Stock" style="display: flex; width: 100%;">
                    <!-- <div nz-row> -->
                    <!-- <div nz-col nzSpan="9" *ngIf="this.initLoading == false && this.Model_Stock"> -->
                    <div *ngIf="this.initLoading == false && this.Model_Stock " style="padding-left: 30px;  padding-top: 2%;">
                        <div>
                            <h5>Total Sales: <u>{{this.Model_Stock.sales}}</u></h5>
                        </div>
                    </div>
                    <!-- <div nz-col nzSpan="6"> -->
                    <div style="padding-left: 30px;  padding-top: 2%;">
                        <h5>SOH: <u>{{this.Model_Stock.soh}}</u></h5>
                    </div>
                    <!-- <div nz-col nzSpan="9" *ngIf="this.initLoading == false && this.Model_Stock"> -->
                    <div *ngIf="this.initLoading == false && this.Model_Stock" style="padding-top:2%; padding-left: 30px;">
                        <div>
                            <h5 *ngIf="this.Model_Stock.daysOfStock != 9999">Days of Stock: <u>{{this.Model_Stock.daysOfStock}}</u></h5>
                            <h5 *ngIf="this.Model_Stock.daysOfStock == 9999">Days of Stock: <u>∞</u></h5>
                        </div>
                    </div>

                </div>
                <div *ngIf="this.initLoading == false && this.Model_Stock" style="display: flex; width: 100%;">
                    <div style="padding-left: 30px; padding-top: 2%;">
                        <h5>Model Stock: <u>{{this.Model_Stock.modelStock}}</u></h5>
                    </div>
                    <div style="padding-left: 30px; padding-top: 2%;">
                        <h5>Suggested Model Stock: <u>{{this.Model_Stock.suggested_modelStock}}</u></h5>
                    </div>
                    <div style="position: absolute; right: -8%; top: 10%;">
                        <!-- <i class="fa fa-calculator" aria-hidden="true"></i> -->
                        <button nz-button nzType="primary" style="margin-left: 5px;" nz-tooltip nzTooltipTitle="customize days of stock calculation" nz-dropdown [nzDropdownMenu]="DOS_Calculation"><i nz-icon nzType="calculator" nzTheme="outline"></i></button>
                        <nz-dropdown-menu #DOS_Calculation="nzDropdownMenu">
                            <nz-card style="display: flex; width: 600px; height: 200px; border-radius: 8px;">
                                <div><u>Days of Stock Formula: </u></div>
                                <div style="justify-content: center; display: flex; margin: 0 auto; margin-top: 20px;">
                                    <button nz-button nzType="primary" style="margin-right: 5px;margin-top: 3px;" (click)="switch_var1()">{{dos_var1}} </button><b> <h3 style="color: var(--warningcolor);">/(</h3> </b>
                                    <button nz-button nzType="default" style="margin-right: 5px;margin-top: 3px;">{{dos_var2}} </button><b> <h3 style="color: var(--warningcolor);">/</h3> </b>
                                    <button nz-button nzType="default" style="margin-right: 5px;margin-top: 3px;">{{this.daysofdata}} </button><b> <h3 style="color: var(--warningcolor);">)</h3> </b>
                                </div>
                                <div><span>-> {{var1}} / ({{var2}} / {{this.daysofdata}}) = {{(var1/(var2/this.daysofdata)) | number : '1.0-0'}}</span></div>
                                <button nz-button nzType="primary" style="position: absolute; right: 50px; bottom: 5px;" (click)="dodChange()">set formula for all sizes</button>
                            </nz-card>
                        </nz-dropdown-menu>
                        <button nz-button nzType="primary" style="margin-left: 10px;" (click)="displayChart(0,0)">
                            <i nz-icon nzType="pie-chart" nzTheme="outline"></i>
                        </button>
                        <br /><br />
                        <button nz-button nzType="primary" (click)="onBrandModalClick()" style="margin-left: 5px;">
                            <i nz-icon nzType="node-index" nzTheme="outline"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </nz-card>
    <div style="display: flex;">
        <div style="width: 98%;">
            <hr class="color-primary" style="border-top: solid 2px ;">
        </div>
        <div style="margin-left: 1%; margin-top: 0.6%;">
            <a *ngIf="this.topCtrls == true;" (click)="topCtrls=false;"><i nz-icon nzType="up" aria-hidden="true"></i></a>
            <a *ngIf="this.topCtrls == false;" (click)="topCtrls=true;"><i nz-icon nzType="down" aria-hidden="true"></i></a>
        </div>
    </div>


</div>

<!-- filters --------- -->
<nz-spin [nzSpinning]="isLoading">
    <div style="margin-left: 5%; width: 20%;">

        <h6 nz-dropdown [nzDropdownMenu]="filter_menu"><u style="border-bottom: solid var(--textcolor);">Apply Filters </u><a style="color: var(--textcolor);"><i nz-icon nzType="filter" aria-hidden="true"></i></a><a style="color: var(--warningcolor); margin-left: 50%;" *ngIf="this.filter_applied == true"
                (click)="clear_filters()" nz-tooltip nzTooltipTitle="clear filters"><i nz-icon nzType="close-square" aria-hidden="true" nz-tool></i></a></h6>
        <nz-dropdown-menu #filter_menu="nzDropdownMenu" id="filter_menu">
            <ul *ngIf="this.double_relational == false;" nz-menu nzSelectable style="padding-left: 10px;" id="filter_line">
                <li>
                    <div style="display: flex;">
                        <div style=" width: 43%"><b>Days of Stock  </b></div>
                        <div style=" width: 10%">
                            <a *ngIf="filter_comparisonA =='gt'"><button nz-button nzType="default" style="width: 30%;" (click)="filter_comparisonA = 'lt'"><b>> </b></button></a>
                            <a *ngIf="filter_comparisonA =='lt'"><button nz-button nzType="default" style="width: 30%;" (click)="filter_comparisonA = 'gt'"><b>< </b></button></a>
                        </div>
                        <div style=" width: 33%; padding-left: 5%;"><input nz-input placeholder="0" value="0" type="number" min="0" id="filterA" style="width: 80%;" autocomplete="off" /></div>
                        <div style="width: 10%; position: absolute; right: 10px; padding-top:1px;"><a style="color: var(--textcolor);"><button nz-button nzType="default" (click)="filter('DOS')"><i  nz-icon nzType="search" aria-hidden="true" ></i></button></a></div>
                    </div>
                </li>
            </ul>
            
            <ul *ngIf="this.double_relational == false;" nz-menu nzSelectable style="padding-left: 10px;" id="filter_line">
                <li>
                    <div style="display: flex;">
                        <div style=" width: 43%"><b>Stock On Hand  </b></div>
                        <div style=" width: 10%">
                            <a *ngIf="filter_comparisonB =='gt'"><button nz-button nzType="default" style="width: 30%;" (click)="filter_comparisonB = 'lt'"><b>></b></button></a>
                            <a *ngIf="filter_comparisonB =='lt'"><button nz-button nzType="default" style="width: 30%;" (click)="filter_comparisonB = 'gt'"><b></b></button></a>
                        </div>
                        <div style=" width: 33%; padding-left: 5%;"><input nz-input placeholder="0" value="0" type="number" min="0" id="filterB" style="width: 80%;" autocomplete="off" /></div>
                        <div style="width: 10%; position: absolute; right: 10px; padding-top:1px;"><a style="color: var(--textcolor);"><button nz-button nzType="default" (click)="filter('SOH')"><i  nz-icon nzType="search" aria-hidden="true" ></i></button></a></div>
                    </div>
                </li>
            </ul>
            
            <ul *ngIf="this.double_relational == false;" nz-menu nzSelectable style="padding-left: 10px; border-bottom: solid 1px var(--textcolor);" id="filter_line">
                <li>
                    <div style="display: flex;">
                        <div style=" width: 43%"><b>Units Sold  </b></div>
                        <div style=" width: 10%">
                            <a *ngIf="filter_comparisonC =='gt'"><button nz-button nzType="default" style="width: 30%;" (click)="filter_comparisonC = 'lt'"><b>></b></button></a>
                            <a *ngIf="filter_comparisonC =='lt'"><button nz-button nzType="default" style="width: 30%;" (click)="filter_comparisonC = 'gt'"><b><</b></button></a>
                        </div>
                        <div style=" width: 33%; padding-left: 5%;"><input nz-input placeholder="0" value="0" type="number" min="0" id="filterC" style="width: 80%;" autocomplete="off" /></div>
                        <div style="width: 10%; position: absolute; right: 10px; padding-top:1px;"><a style="color: var(--textcolor);"><button nz-button nzType="default" (click)="filter('SALES')"><i  nz-icon nzType="search" aria-hidden="true" ></i></button></a></div>
                    </div>
                </li>
            </ul>
            

        </nz-dropdown-menu>
    </div>
</nz-spin>
<!-- Size statistics --------------------------------------------------------------- -->
<nz-spin [nzSpinning]="isLoading">
    <div nz-row>
        <div nz-col nzSpan="22" nzOffset="1">
            <nz-tabset nzCentered nzSize="large" [(nzSelectedIndex)]="currP2_Table">
                <nz-tab nzTitle="Size Statistics">
                    <nz-table *ngIf="this.initLoading == false && this.Model_Stock" #MSSizes [nzData]="Model_Stock.sizes" [nzFrontPagination]="false" [nzShowPagination]="false" nzSize="small" >
                        <thead>
                            <tr id="sticky-header">
                                <th></th>
                                <th style="text-align: center;" [nzSortFn]="sortSize">Size</th>
                                <th style="text-align: center;" [nzSortFn]="sortSales">Units Sold</th>
                                <th style="text-align: center;" [nzSortFn]="sortQuotes">Quoted</th>
                                <th style="text-align: center;" [nzSortFn]="sortConv">Converted</th>
                                <th style="text-align: center;" [nzSortFn]="sortNR" nz-tooltip nzTooltipTitle="This is the ranking of the most popular tyres based on national sales over the past {{this.daysofdata}} days">NR</th>
                                <th style="text-align: center;" [nzSortFn]="sortDOS" nz-tooltip nzTooltipTitle="This is an approximation of how many days it will take to run out of stock in this size">DOS</th>
                                <!-- <th style="text-align: center;" [nzSortFn]="sortWDemand" nz-tooltip nzTooltipTitle="Weekly Demand: According to historical data you will sell this many tyres over the following week">W_Demand</th> -->
                                <!-- <th [nzSortFn]="sortAIMS" nz-tooltip nzTooltipTitle="Suggested Model Stock" *ngIf="this.suggestions == true" style="text-align: center; color: var(--textcolor)">AI_MS</th> -->
                                <th [nzSortFn]="sortMS" style="text-align: center;" nz-tooltip nzTooltipTitle="Model Stock">MSL</th>
                                <th style="text-align: center;" [nzSortFn]="sortSOH" nz-tooltip nzTooltipTitle="Stock On Hand">SOH</th>
                                <!-- <th style="text-align: center;" nz-tooltip nzTooltipTitle="This is the percentage contribution of this size to your overall sales for the past {{this.daysofdata}} days"><a (click)="sortP2_Table(3,'A')">Sales Share (%) <a  id="sortIcon"  style="position: absolute; right:10px;"><i class="fa fa-sort" aria-hidden="true"></i></a></a></th> -->
                                <th style="text-align: center;">View</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of MSSizes.data | filter: '': []:0:pageSize">
                                <!-- <hr  *ngIf="this.marked_row == item.size" style="width: 100%; height: 10px; background-color: yellow;"> -->
                                <td>
                                    <span nz-icon nzType="stock" nzTheme="outline" nz-tooltip="Sales Trend" style="color: var(--textcolor);font-size: 2rem !important;cursor: pointer" (click)="viewSalesTrendGencode(item.size)"></span>
                                    <span nz-icon nzType="pie-chart" nzTheme="outline" style="color: var(--textcolor);font-size: 2rem !important;cursor: pointer" (click)="displayChart(2656517,0)"></span>
                                </td>
                                <td style="text-align: center;">
                                    <div style="display: flex; margin-left: 10%;">
                                        <div *ngIf="this.findSize(item.size) == false" style="float: left;">{{item.size}} </div>
                                        <div *ngIf="this.findSize(item.size) == true && this.findSize_Date(item.size) == 'today'" style="float: left;"><button nzTooltipTitle="Item has been viewed today" nzTooltipPlacement="top" nz-button nz-tooltip nzType="primary">{{item.size}} </button></div>
                                        <div *ngIf="this.findSize(item.size) == true && this.findSize_Date(item.size) == 'not_today'" style="float: left;"><button nzTooltipTitle="Item has been viewed  {{this.getDaysPast(item.size)}} day(s) ago" nzTooltipPlacement="top" nz-button nz-tooltip nzType="primary" style="background-color: var(--warningcolor);">{{item.size}} </button></div>
                                        <!-- <div *ngIf="this.gencodes_view.includes(item.size) == true" style="float: left;"><button nzTooltipTitle="Item has been viewed in the past 7 days" nzTooltipPlacement="top" nz-button nz-tooltip nzType="primary">{{item.size}} </button></div> -->
                                        <div style="float: right; padding-left: 3px; display: flex; position: absolute; right: 8px;" *ngIf="item.alerts.length > 0;">
                                            <div>
                                                <a *ngIf="item.alerts[0] && item.alerts[0].includes(' rank in recommended sizes is not in your model stock') == true && this.alert_on == true;" (click)="viewLines(item.size)" style="color: var(--warningcolor);">
                                                    <!-- nz-popover [nzPopoverTitle]="titleTemplate" [nzPopoverContent]="contentTemplate"-->
                                                    <i nz-icon nzType="info-circle" aria-hidden="true" nz-tooltip nzTooltipTitle="{{item.alerts[0]}}"></i>
                                                </a>
                                            </div>
                                            <!-- <a *ngIf="item.alerts[0].includes(' Rank in recommended sizes is not in your model stock') == false && this.alert_on == true;" (click)="viewLines(item.size)" style="color:blue;" nz-popover [nzPopoverTitle]="titleTemplate" [nzPopoverContent]="contentTemplate">
                                    <i nz-icon nzType="info-circle"></i>
                                    </a> -->

                                            <div style="float: right; display: flex;" *ngFor="let n of item.alerts">
                                                <div>
                                                    <a *ngIf="n.includes('#1 National Rank for this size not in model Stock') == true && this.alert_on == true;" (click)="viewLines(item.size)" style="color: var(--textcolor);">
                                                        <i nz-icon nzType="info-circle" aria-hidden="true" nz-tooltip nzTooltipTitle="Highest Nationally Ranked tire for this size is not in model Stock"></i>
                                                    </a>
                                                </div>

                                                <!-- <div style="padding-left: 2px;"><a *ngIf="n.includes('#1 Brand Rank for this size not in model Stock') == true && this.alert_on == true;" (click)="viewLines(item.size)" style="color:orange;">
                                        <i nz-icon nzType="info-circle"  aria-hidden="true" nz-tooltip nzTooltipTitle="Highest Ranked Brand for this size is not in model Stock"></i>
                                        </a></div> -->

                                                <div style="padding-left: 2px;">
                                                    <a *ngIf="n.includes('Brand Switch') == true && this.alert_on == true;" (click)="viewLines(item.size)" style="color:orange;">
                                                        <i nz-icon nzType="info-circle" aria-hidden="true" nz-tooltip nzTooltipTitle="A Brand Switch May be Possible"></i>
                                                    </a>
                                                </div>

                                                <div style="padding-left: 2px;">
                                                    <a *ngIf="n.includes('Best Costed item for this size not in model Stock') == true && this.alert_on == true;" (click)="viewLines(item.size)" style="color:brown;">
                                                        <i nz-icon nzType="info-circle" aria-hidden="true" nz-tooltip nzTooltipTitle="Cheapest tire is not in model stock"></i>
                                                    </a>
                                                </div>

                                                <div style="right: 2px;">
                                                    <a *ngIf="n.includes('AI Deviation: A') == true && this.alert_on == true;" (click)="viewLines(item.size)" style="color:var(--warningcolor);">
                                                        <i nz-icon nzType="info-circle" aria-hidden="true" nz-tooltip nzTooltipTitle="AI suggests a Model Stock of {{item.suggested_modelStock}}"></i>
                                                    </a>
                                                </div>

                                                <div style="right: 2px;">
                                                    <a *ngIf="n.includes('AI Deviation: B') == true && this.alert_on == true;" (click)="viewLines(item.size)" style="color:var(--warningcolor);">
                                                        <i nz-icon nzType="info-circle" aria-hidden="true" nz-tooltip nzTooltipTitle="AI suggested a Model Stock of {{item.suggested_modelStock}}, You have {{item.modelStock}} set as your model stock"></i>
                                                    </a>
                                                </div>
                                            </div>

                                            <!-- <div style="float: right;" *ngFor="let n of item.alerts">
                                        <a *ngIf="n.includes('#1 Brand Rank for this size not in model Stock') == true && this.alert_on == true;" (click)="viewLines(item.size)" style="color:orange;">
                                        <i nz-icon nzType="info-circle"  aria-hidden="true" nz-tooltip nzTooltipTitle="#1 Brand Rank for this size not in model Stock"></i>
                                        </a>
                                    </div>

                                    <div style="float: right;" *ngFor="let n of item.alerts">
                                        <a *ngIf="n.includes('Cheapest Item not in model stock') == true && this.alert_on == true;" (click)="viewLines(item.size)" style="color:brown;">
                                        <i nz-icon nzType="info-circle"  aria-hidden="true" nz-tooltip nzTooltipTitle="Cheapest Item not in model stock"></i>
                                        </a>
                                    </div> -->

                                            <ng-template #titleTemplate>
                                                <span nz-icon nzType="close"></span> {{item.alerts.length}}: Alerts
                                            </ng-template>
                                            <ng-template #contentTemplate>
                                                <div *ngFor="let line of item.alerts">
                                                    <span><h6 >- {{line}} </h6></span>
                                                </div>
                                            </ng-template>



                                        </div>
                                    </div>
                                </td>
                                <td *ngIf="item.sales != 9999" style="text-align: center;">
                                    <span nz-tooltip nzTooltipTitle="sales | monthly demand">
                                        {{item.sales}} | {{item.monthly_demand}}
                                    </span>
                                </td>
                                <td style="text-align: center;">
                                    <span nz-tooltip nzTooltipTitle="leads generated">{{item.leads_generated}}</span>
                                </td>
                                <!-- Display color coded conversion rates -------------------------------------------- -->
                                <td *ngIf="leadConversion(item.leads_converted,item.leads_generated) >= 90" style="text-align: center; background-color: rgba(0, 128, 0, 0.7);">
                                    <span nz-tooltip nzTooltipTitle="{{item.leads_converted}} leads converted">{{leadConversion(item.leads_converted,item.leads_generated)}}%</span>
                                </td>
                                <td *ngIf="leadConversion(item.leads_converted,item.leads_generated) >= 70 && leadConversion(item.leads_converted,item.leads_generated) < 90" style="text-align: center; background-color: rgba(47, 255, 99, 0.7);">
                                    <span nz-tooltip nzTooltipTitle="{{item.leads_converted}} leads converted">{{leadConversion(item.leads_converted,item.leads_generated)}}%</span>
                                </td>
                                <td *ngIf="leadConversion(item.leads_converted,item.leads_generated) >= 50 && leadConversion(item.leads_converted,item.leads_generated) < 70" style="text-align: center; background-color: rgba(255, 255, 0, 0.7);">
                                    <span nz-tooltip nzTooltipTitle="{{item.leads_converted}} leads converted">{{leadConversion(item.leads_converted,item.leads_generated)}}%</span>
                                </td>
                                <td *ngIf="leadConversion(item.leads_converted,item.leads_generated) >= 20 && leadConversion(item.leads_converted,item.leads_generated) < 50" style="text-align: center; background-color: rgba(255, 166, 0, 0.7);">
                                    <span nz-tooltip nzTooltipTitle="{{item.leads_converted}} leads converted">{{leadConversion(item.leads_converted,item.leads_generated)}}%</span>
                                </td>
                                <td *ngIf="leadConversion(item.leads_converted,item.leads_generated) < 20" style="text-align: center; background-color: rgba(255, 0, 0, 0.7);">
                                    <span nz-tooltip nzTooltipTitle="{{item.leads_converted}} leads converted">{{leadConversion(item.leads_converted,item.leads_generated)}}%</span>
                                </td>
                                <!-- ---------------------------------------------------------------------------------- -->
                                <td *ngIf="item.sales_rank_all != 9999" style="text-align: center;">{{item.sales_rank_all}}</td>
                                <td *ngIf="item.sales_rank_all == 9999" style="text-align: center;"><b>∞</b></td>
                                <td *ngIf="item.sales == 9999" style="text-align: center;">0</td>
                                <td *ngIf="item.dos != 9999 && item.dos != -1 " style="text-align: center;">{{item.dos}}</td>
                                <td *ngIf="item.dos == 9999 || item.dos == -1 && this.marked_row != item.size && this.row_marked == true" style="text-align: center;"><b>∞</b></td>
                                <!-- <td *ngIf="item.dos == 9999 || item.dos == -1 && this.marked_row == item.size && this.row_marked == true" style="text-align: center; border-top: solid #117274;"><b>∞</b></td> -->

                                <!-- <td style="text-align: center;" *ngIf="item.four_week_demand != 9999">{{item.four_week_demand}}</td> -->
                                <!-- <td style="text-align: center;" *ngIf="item.four_week_demand == 9999">0</td> -->

                                <!-- <td *ngIf="this.suggestions == true" style="text-align: center;">{{item.suggested_modelStock}}</td>
                                <td *ngIf="item.modelStock != 9999" style="text-align: center;">{{item.modelStock}}</td> -->

                                <td *ngIf="this.suggestions == true and item.modelStock != 9999" style="text-align: center;"> <span nz-tooltip nzTooltipTitle="MSL | AI_MSL">{{item.modelStock}} | {{item.suggested_modelStock}} </span></td>
                                <td *ngIf="item.modelStock == 9999" style="text-align: center;"><span nz-tooltip nzTooltipTitle="MSL | AI_MSL">0 | {{item.suggested_modelStock}}</span></td>

                                <td *ngIf="item.soh < item.modelStock" style="text-align: center; color: var(--warningcolor)">{{item.soh}}</td>
                                <td style="text-align: center;" *ngIf="item.soh >= item.modelStock">{{item.soh}}</td>
                                <!-- <td style="text-align: center;" *ngIf="item.sales_share != 9999">{{item.sales_share}}</td>
                            <td style="text-align: center;" *ngIf="item.sales_share == 9999">0</td> -->
                                <td style="text-align: center;"><button nz-button nzType="primary" (click)="viewLines(item.size)"><span nz-icon nzType="search"></span></button></td> <!--<span nz-icon nzType="search"></span>-->
                            </tr>
                        </tbody>
                    </nz-table>
                </nz-tab>
                <nz-tab nzTitle="Tyre Statistics">
                    <nz-row> <!--[nzJustify]="'end'"-->
                        <nz-col [nzSpan]="'4'">
                            <button (click)="download_TS()" nz-button nzType="primary" nz-tooltip nzTooltipTitle="Download Tyre Statistics [{{this.ModelTyresData.length}} Items]"><span nz-icon nzType="download" nzTheme="outline"></span></button>
                        </nz-col>
                        <nz-col [nzSpan]="'4'" nzOffset="16">
                            <label>Filter : AI Logic Applied </label>
                            <nz-select [(ngModel)]="selectedLogicId" style="width: 100%;" (ngModelChange)="sortReason($event)">
                                <nz-option *ngFor="let item of aiStockReasons" [nzValue]="item.ms_ai_reasonId" [nzLabel]="item.description"></nz-option>
                            </nz-select><br>
                        </nz-col>
                    </nz-row>
                    <p></p>
                    <nz-table #AllItems_Set [nzData]="ModelTyresData" id="AllItems_Set" [nzFrontPagination]="false" 
                    [nzPageSize]="50" nzShowPagination="true">
                        <thead style="position: sticky; inset-block-start: 0; background-color: black; ">
                            <tr>
                                <th>Sales Trend</th>
                                <th [nzSortFn]="sortDescInv" class="text-center">Description</th>
                                <th [nzSortFn]="sortBrandInv" class="text-center">Brand</th>
                                <th [nzSortFn]="sortCodeInv" class="text-center">Code</th>
                                <th [nzSortFn]="sortSoldInv" class="text-center">Sold</th>
                                <th [nzSortFn]="sortDOSInv" class="text-center">DOS</th>
                                <th [nzSortFn]="sortMSInv" class="text-center">MS</th>
                                <th [nzSortFn]="sortAIMSInv" class="text-center">AI MS</th>
                                <th class="text-center"> AI Logic</th>
                                <th [nzSortFn]="sortSOHInv" class="text-center">SOH</th>
                                <th class="text-center">View</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of AllItems_Set.data | filter: '': []:0:pageSize" style="padding: 1px;">
                                <td style="text-align: center;">
                                    <span nz-icon nzType="stock" nzTheme="outline" nz-tooltip="Sales Trend" style="color: var(--textcolor);font-size: 2rem !important;cursor: pointer" (click)="viewSalesTrendTyre(item.msfid)"></span>
                                </td>
                                <td style="text-align: center;">
                                    {{item.description}}
                                </td>
                                <td style="text-align: center; border-right: solid 0.01px var(--textcolor); border-left: solid 0.01px var(--textcolor);">
                                    <img style="margin: 0 auto;" src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{item.brandLogo}}" class="brandImage" nz-tooltip="{{item.brand}}" /> {{item.brand}}
                                </td>
                                <td style="text-align: center;">
                                    {{item.stock_code}}
                                </td>
                                <td class="text-center">
                                    <span *ngIf="item.sales != 9999" style="text-align: center;">{{item.sales}}</span>
                                    <span *ngIf="item.sales == 9999" style="text-align: center;">0</span>
                                </td>
                                <td class="text-center">
                                    {{ item.days_of_stock | number : '1.0-0'}}
                                </td>
                                <td class="text-center">
                                    <span *ngIf="item.modelStock != 9999" style="text-align: center;">{{item.modelStock}}</span>
                                    <span *ngIf="item.modelStock == 9999" style="text-align: center;">0</span>
                                </td>
                                <td class="text-center">
                                    <span *ngIf="this.suggestions == true" style="text-align: center;">{{item.suggested_modelStock}}</span>
                                </td>
                                <td style="text-align: center;">
                                    {{item.ms_ai_reason}}
                                </td>
                                <td class="text-center">
                                    <span *ngIf="item.soh < item.modelStock" style="text-align: center; color: var(--warningcolor)">{{item.soh}}</span>
                                    <span *ngIf="item.soh >= item.modelStock" style="text-align: center;">{{item.soh}}</span>
                                </td>
                                <td style="text-align: center;">
                                    <button nz-button nzType="primary" (click)="viewLines(item.size)"><i nz-icon nzType="search"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </nz-table>
                </nz-tab>
                <nz-tab nzTitle="Recommended Sizes" (nzClick)="fetchRecommendedSizes()">
                    <nz-table #rSizes [nzData]="Model_Size_Suggestions" [nzFrontPagination]="false">
                        <thead style="position: sticky; inset-block-start: 0; background-color: black; ">
                            <tr>
                                <th style="text-align: center;" [nzSortFn]="sortSizeR">Size</th>
                                <th style="text-align: center;" [nzSortFn]="sortNR_SearchesR" nz-tooltip nzTooltipTitle="Rank based on searches for the past {{this.daysofdata}} days">National Rank Searches</th>
                                <th style="text-align: center;" [nzSortFn]="sortSalesR" nz-tooltip nzTooltipTitle="Sales for the past {{daysofdata}} days"> Your Sales</th>
                                <th style="text-align: center;" [nzSortFn]="sortRankR" nz-tooltip nzTooltipTitle="Ranking based on sales for the past {{daysofdata}} days">Rank</th>
                                <th style="text-align: center;">Add</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of rSizes.data | filter: '': []:0:pageSize">
                                <td style="text-align: center;">{{item.gen_code}}</td>
                                <td style="text-align: center;">{{item.searches}}</td>
                                <td style="text-align: center;">{{item.sales}}</td>
                                <td style="text-align: center;">{{item.rank}}</td>
                                <td style="text-align: center;"><button nz-button nzType="primary" (click)="viewLines(item.gen_code)"><i nz-icon nzType="search"></i></button></td>

                            </tr>
                        </tbody>
                    </nz-table>
                </nz-tab>
            </nz-tabset>
        </div>
    </div>
</nz-spin>



<div>
    
    <nz-modal [(nzVisible)]="this.initLoading" [nzFooter]="null" nzTheme="fill" nzWidth="50%">
        <ng-container *nzModalContent>
            <div>
                <h5>Statistics can take up to 60 seconds to load</h5>
                <hr>
                <!-- <button nz-button nzType="primary"  (click)="cancelAlerts()">cancel</button> -->
                <!-- <nz-switch [ngModel]="this.alert_on" (click)="repAlert()"></nz-switch> -->
                <div style="display: flex;">
                    <h6><i>"{{this.waiting_caption}}"</i></h6>
                    <div style=" padding-left: 10px;"><a (click)="nextJoke()"><i nz-icon nzType="right" aria-hidden="true"></i></a></div>
                </div>
            </div>
        </ng-container>
    </nz-modal>
    <div>
        <nz-spin [nzSpinning]="isLoading">
            <nz-modal [(nzVisible)]="this.inputStock" nzTitle="Add Stock to Auto replenishment model" (nzOnCancel)="this.inputStock = false" [nzFooter]="null" nzTheme="fill">
                <ng-container *nzModalContent>
                    <form nz-form nzTitle="Add new stock model">
                        <nz-form-item>
                            <input nz-input placeholder="{{suggestedAddition.description}}" readonly id="new_description" style="width: 70%;" autocomplete="off" />
                        </nz-form-item>
                        <nz-form-item>
                            <input nz-input placeholder="{{suggestedAddition.code}}" readonly id="new_code" style="width: 70%;" autocomplete="off" />
                        </nz-form-item>
                        <nz-form-item>
                            <input nz-input placeholder="Enter MSL" type="number" min="0" id="new_msl" style="width: 70%;" autocomplete="off" />
                        </nz-form-item>
                        <nz-form-item>
                            <input nz-dropdown [nzDropdownMenu]="menu" placeholder="Add new supplier" id="new_supplier" class="submit trinput" style="width: 50%; text-align: center; color: var(--warningcolor);" autocomplete="off" />
                            <nz-dropdown-menu #menu="nzDropdownMenu">
                                <ul nz-menu nzSelectable *ngFor="let supplier of suppliersTemp">
                                    <li nz-menu-item *ngIf="supplier.repActive == true" (click)="chooseSupplier(supplier,3)">{{supplier.supplier_name}} </li>
                                </ul>
                            </nz-dropdown-menu>
                        </nz-form-item>
                    </form>
                    <button nz-button nzType="primary" (click)="submitModelStock(suggestedAddition)"><i nz-icon nzType="save" nzTheme="fill" 
                style="vertical-align: top; margin-right: 4px; font-size: 1.5rem;"></i>Save</button> <!-- <span nz-icon nzType="save" nzTheme="outline"></span>-->
                </ng-container>
            </nz-modal>



           
        </nz-spin>
    </div>

</div>

<ng-template #titleTag>
    <nz-row [nzJustify]="'start'">
        <nz-col nzSpan="24">
            <nz-row>
                <nz-col nzSpan="6">
                    Add/Edit tyre items for Model Stock
                </nz-col>
                <nz-col nzSpan="1"><a style="color: brown;"><i nz-icon nzType="eye" aria-hidden="true" nz-tooltip nzTooltipTitle="cheapest items"></i></a></nz-col>
                <nz-col nzSpan="1"><a style="color: green;"><i nz-icon nzType="eye" aria-hidden="true" nz-tooltip nzTooltipTitle="Nationally Ranked #1"></i></a></nz-col>
                <nz-col nzSpan="1"><a style="color: orange;"><i nz-icon nzType="eye" aria-hidden="true" nz-tooltip nzTooltipTitle="Brand Rank #1"></i></a></nz-col>
                <nz-col nzSpan="7"></nz-col>
                <nz-col nzSpan="8">
                    <div style="display: flex;"><button *ngIf="this.currentSupplierOnView.supplier_name != 'ALL'" nz-button nzType="default" style="margin-right: 1%;" (click)="displayByCriteria()" nz-tooltip nzTooltipTitle="Clear Filter">X</button><button nz-button nzType="primary" nz-dropdown [nzDropdownMenu]="supplier_menu" *ngIf="currentSupplierOnView"><p>Supplier: {{currentSupplierOnView.supplier_name}}<a style="color: orange; margin-left: 1%;"><i nz-icon nzType="down"  aria-hidden="true"></i></a></p>  </button></div>
                    <!-- <button nz-button nzType="primary" nz-dropdown [nzDropdownMenu]="supplier_menu" *ngIf="!currentSupplierOnView"><p>Group By Supplier<a style="color: orange; margin-left: 1%;"><i nz-icon nzType="down"  aria-hidden="true"></i></a></p>  </button> -->
                    <nz-dropdown-menu #supplier_menu="nzDropdownMenu">
                        <ul nz-menu nzSelectable style="background-color: var(--backcolor); border: solid 1px var(--backcolor); border-radius: 8px;">
                            <ul nz-menu nzSelectable *ngFor="let supplier of suppliersTemp" style="background-color: var(--backcolor);">
                                <li nz-menu-item *ngIf="supplier.repActive == true && ['WAREHOUSE','WHOLESALE','REMOTE','FITMENT'].includes(supplier.type)" (click)="groupBy(supplier)"><b>{{supplier.supplier_name}}</b></li>
                                
                            </ul>
                            <li nz-menu-item (click)="showNonDemandOnly()"><b>Items Out of Stock</b></li>
                            <!-- <li nz-menu-item (click)="displayByCriteria()" *ngIf="this.currentSupplierOnView.supplier_name != 'ALL'" style="background-color: green;"><b>Back to default</b></li> -->
                        </ul>
                        
                        <hr>
                    </nz-dropdown-menu>
                </nz-col>
            </nz-row>
        </nz-col>
        <!-- <div style="margin-left: 2px;"><a style="color: brown;"><i nz-icon nzType="eye" aria-hidden="true" nz-tooltip nzTooltipTitle="cheapest items"></i></a></div>
        <div style="margin-left: 2px;"><a style="color: green;"><i nz-icon nzType="eye" aria-hidden="true" nz-tooltip nzTooltipTitle="Nationally Ranked #1"></i></a></div>
        <div style="margin-left: 2px;"><a style="color: orange;"><i nz-icon nzType="eye" aria-hidden="true" nz-tooltip nzTooltipTitle="Brand Rank #1"></i></a></div></nz-col>  -->
    </nz-row>
</ng-template>
<nz-modal [(nzVisible)]="this.viewModel_Lines" [nzTitle]="titleTag" (nzOnCancel)="this.viewModel_Lines = false; this.editMode = false; this.items_shown = false;" [nzFooter]="null" nzTheme="fill" [nzWidth]="'100%'">
    <ng-container *nzModalContent>
        <nz-table #ModelTyreLines [nzData]="ModelTyres" [nzFrontPagination]="false" [nzScroll]="{ y:'450px'}" nzSize="small">
            <!--[nzScroll]="{ y:'900px'}"-->
            <thead>
                <tr>
                <th  [nzSortFn]="sortBrandInv" [nzWidth]="'80px'"  nz-tooltip nzTooltipTitle="Item Brand" >Brand </th>
                <th [nzWidth]="'60px'" >Tier: {{this.tier_inspected}}</th>
                <th [nzSortFn]="sortDescInv"  nz-tooltip nzTooltipTitle="Description">Description</th>
                <th [nzSortFn]="sortCodeInv"   [nzWidth]="'150px'"   nz-tooltip nzTooltipTitle="Stock Code">Code</th>
                <th [nzSortFn]="sortSoldInv"  [nzWidth]="'140px'" nz-tooltip nzTooltipTitle="Units sold"> Sold</th>
                <th [nzSortFn]="sortQuoteInv"  [nzWidth]="'140px'" nz-tooltip nzTooltipTitle="Units sold"> Quoted</th>
                <th [nzSortFn]="sortDOSInv" [nzWidth]="'60px'" nz-tooltip nzTooltipTitle="Days of stock">DOS</th>
                <th [nzSortFn]="sortMSInv" [nzWidth]="'100px'" nz-tooltip nzTooltipTitle="Model Stock">MS </th>
                <th [nzSortFn]="sortAIMSInv" [nzWidth]="'90px'" *ngIf="this.suggested_msl == true" style="color: #1a9b9e;" nz-tooltip nzTooltipTitle="Suggested Model Stock">AI MS </th>
                <th [nzSortFn]="sortNRInv" [nzWidth]="'50px'" nz-tooltip nzTooltipTitle="National Rank">NR </th>
                <th [nzSortFn]="sortCostInv"  [nzWidth]="'100px'"nz-tooltip nzTooltipTitle="Cost">Cost </th>
                <!-- <th [nzSortFn]="sortSuppInv"     nz-tooltip nzTooltipTitle="Supplier">Supplier </th> -->

                <th [nzSortFn]="sortS_SOHInv" [nzWidth]="'100px'"  nz-tooltip nzTooltipTitle="Suppliers Stock On Hand">S_SOH </th>
                <th [nzSortFn]="sortSOHInv" [nzWidth]="'70px'" nz-tooltip nzTooltipTitle="Stock On Hand">SOH </th>
                
                <th  [nzWidth]="'90px'">Add/Edit</th>
               
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of ModelTyreLines.data">
                <td style="text-align: center; border-right: solid 0.01px; border-left: solid 0.01px;"><img style="margin: 0 auto; width: 80%;" src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{item.brandLogo}}" class="brandImage" /></td>
                <td><span nz-icon nzType="stock" nzTheme="outline" nz-tooltip="Sales Trend" style="color: var(--textcolor);font-size: 2rem !important;cursor: pointer" (click)="viewSalesTrendTyre(item.msfid)"></span></td>
                <td [ngStyle]="{'color':item.stock_code == cheapest_code  ? 'brown' : (national_codes.includes(item.stock_code) ? 'green': (brand_codes.includes(item.stock_code) ? 'orange': baseTxt))  }" style="width: 110% !important;">{{item.description}}</td>
                <td [ngStyle]="{'color':item.stock_code == cheapest_code  ? 'brown' : (national_codes.includes(item.stock_code) ? 'green': (brand_codes.includes(item.stock_code) ? 'orange': baseTxt))  }">{{item.stock_code}}</td>
                <td style="text-align: center;" [ngStyle]="{'color':item.stock_code == cheapest_code  ? 'brown' : (national_codes.includes(item.stock_code) ? 'green': (brand_codes.includes(item.stock_code) ? 'orange':baseTxt))  }">
                    <span nz-tooltip nzTooltipTitle="units sold | monthly demand">
                        {{item.sales}} | {{monthDemand(item.sales)}}
                    </span>
                </td>
                <td style="text-align: center;">
                    <span nz-tooltip nzTooltipTitle="{{item.stock_code}} appeared on {{item.leads_generated}} quotes, making up {{leadConversion(item.leads_generated,this.genCode_quoteQty)}}% of the quotes on size {{this.genCode_onView}}">
                        <!-- {{item.leads_generated}} | {{item.qty_quoted}} -->
                        {{item.leads_generated}} | {{leadConversion(item.leads_generated,this.genCode_quoteQty)}}%
                    </span>
                </td>
               
                <td style="text-align: center;" [ngStyle]="{'color':item.stock_code == cheapest_code  ? 'brown' : (national_codes.includes(item.stock_code) ? 'green': (brand_codes.includes(item.stock_code) ? 'orange':baseTxt))  }">{{item.soh/(item.sales/this.daysofdata*1.0) | number : '1.0-0'}}</td>
                <td style="text-align: center;" *ngIf="this.editMode == true && this.editID != item.msfid">{{item.modelStock}}</td>
                <td style="text-align: center;" *ngIf="this.editMode == false;">{{item.modelStock}}</td>
                <td style="text-align: center;" *ngIf="this.editMode == true && this.editID == item.msfid">
                    <input nz-input placeholder="{{item.modelStock}}" value="{{item.modelStock}}" type="number" min="0" id="msl_suggest" style="width: 80%;" autocomplete="off" />
                </td>
                <td style="text-align: center;" *ngIf="item.ms_ai_reasonId != 0;" nz-tooltip nzTooltipTitle="{{item.ms_ai_reason}}">{{item.suggested_modelStock}}</td>
                <td style="text-align: center;" *ngIf="item.ms_ai_reasonId == 0;">{{item.suggested_modelStock}}</td>

                <td style="text-align: center;" [ngStyle]="{'color':item.stock_code == cheapest_code  ? 'brown' : (national_codes.includes(item.stock_code) ? 'green': (brand_codes.includes(item.stock_code) ? 'orange':baseTxt))  }">{{item.national_rank}}</td>
                <td style="text-align: center;" [ngStyle]="{'color':item.stock_code == cheapest_code  ? 'brown' : (national_codes.includes(item.stock_code) ? 'green': (brand_codes.includes(item.stock_code) ? 'orange':baseTxt))  }" nz-tooltip nzTooltipTitle="{{item.best_supplier}}">{{item.best_cost}}</td>
                <!-- <td style="text-align: center;" [ngStyle]="{'color':item.stock_code == cheapest_code  ? 'brown' : (national_codes.includes(item.stock_code) ? 'green': (brand_codes.includes(item.stock_code) ? 'orange':baseTxt))  }">{{item.best_supplier}} </td> -->
                <td style="text-align: center;" [ngStyle]="{'color':item.stock_code == cheapest_code  ? 'brown' : (national_codes.includes(item.stock_code) ? 'green': (brand_codes.includes(item.stock_code) ? 'orange':baseTxt))  }" *ngIf="item.supplier_soh <= item.supplier_sohMax">{{item.supplier_soh}}</td>
                <td style="text-align: center;" [ngStyle]="{'color':item.stock_code == cheapest_code  ? 'brown' : (national_codes.includes(item.stock_code) ? 'green': (brand_codes.includes(item.stock_code) ? 'orange':baseTxt))  }" *ngIf="item.supplier_soh > item.supplier_sohMax">{{item.supplier_sohMax}}+</td>
                <td style="text-align: center;" [ngStyle]="{'color':item.stock_code == cheapest_code  ? 'brown' : (national_codes.includes(item.stock_code) ? 'green': (brand_codes.includes(item.stock_code) ? 'orange':baseTxt))  }">{{item.soh}}<span *ngIf="item.back_order_qty >0" style="color: cadetblue; cursor:ponter;" nz-tooltip nzTooltipTitle="SOH | Back order Quantity"> | {{item.back_order_qty}}</span></td>

                <td *ngIf="((item.stockReplenishmentId  !=0 && item.best_supplier != null) || item.modelStock >0) && this.editMode == false && item.replenish_active == true;" style="  border-radius: 5%; text-align: center;"><a><button nz-button style="border: solid 3px var(--textcolor);" (click)="editLine_P2(item)"><i nz-icon nzType="edit" nzTheme="outline" nz-tooltip nzTooltipTitle="Edit Item"></i></button></a></td>  
                <td *ngIf="((item.stockReplenishmentId  !=0 && item.best_supplier != null) || item.modelStock >0)  && this.editMode == false && item.replenish_active == false;" style="  border-radius: 5%; text-align: center;"><a><button nz-button style="border: solid 3px var(--warningcolor);" (click)="editLine_P2(item)"><i nz-icon nzType="edit" nzTheme="outline" nz-tooltip nzTooltipTitle="None of your active suppliers are carrying this stock"></i></button></a></td>

                <td *ngIf="item.stockReplenishmentId == 0 && item.modelStock ==0  && this.editMode == false && item.replenish_active == true || (item.stockReplenishmentId != 0 && item.best_supplier == null) ;" style="text-align: center;"><button nz-button nzType="default" (click)="addItem_P2(item)"><i nz-icon nzType="plus" nz-tooltip nzTooltipTitle="Add Item to Replenishment Models"></i></button></td> <!--<span nz-icon nzType="plus" nzTheme="outline"></span>-->

                <td *ngIf="item.replenish_active == false && item.modelStock == 0 && item.stockReplenishmentId == 0 ;" style="text-align: center;"><a style="color: var(--warningcolor);" id="inactive_supplier"><button nz-button style="border: solid 3px var(--warningcolor);" (click)="addItem_P2(item)"><i nz-icon nzType="plus" nz-tooltip nzTooltipTitle="None of your active suppliers are carrying this stock"></i></button></a></td>
                <td *ngIf="item.msfid == this.editID && this.editMode == true;">
                    <div style="display: flex;">
                        <button nz-button (click)="saveChanges_P2(item)" style="border: solid 1px green; border-top-right-radius: 0px; border-bottom-right-radius: 0px;"><i nz-icon nzType="save" nz-tooltip nzTooltipTitle="SAVE MSL"></i></button>
                        <button nz-button (click)="deleteModel_Permenant(item)" style="margin-left: 2px; border: solid 1px red;  border-top-left-radius: 0px; border-bottom-left-radius: 0px;"> <i nz-icon nzType="delete" nz-tooltip nzTooltipTitle="Delete Item"></i> </button>
                    </div>
                </td>
            </tr>
        </tbody>
        </nz-table>

    </ng-container>
</nz-modal>

<nz-modal [(nzVisible)]="isMsfidSalesModalVisible" (nzOnCancel)="isMsfidSalesModalVisible = false" [nzFooter]="null" nzTheme="fill" [nzWidth]="700">
    <ng-container *nzModalContent>
        <nz-spin [nzSpinning]="isLoadingSalesPerMsfid">
            <div *ngIf="this.tyreSalesTrend">
                <h4>6 MONTH SALES TREND</h4>
                <img style="margin: 0 auto;" src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{tyreSalesTrend.logo}}" class="brandImage" style="display: inline-block" />
                <h5 style="display: inline-block; margin-left: 20px">{{tyreSalesTrend.description}}</h5>
                <h5 style="padding-top: 10px;">Classification: {{tyreSalesTrend.classification}}</h5>
                <nz-row class="border-top" style="padding-top: 10px; padding-bottom: 5px;">
                    <div nz-col [nzSpan]="3">

                    </div>
                    <div nz-col [nzSpan]="3" *ngFor="let item of tyreSalesTrend.sales; let i = index" style="text-align: center;">
                        <i *ngIf="i === 0; else elseMonth">{{getMonthName(item.month)}} {{item.year}}</i>
                        <ng-template #elseMonth>{{getMonthName(item.month)}} {{item.year}}</ng-template>
                    </div>
                </nz-row>
                <nz-row style="padding-top: 5px;padding-bottom: 5px;">
                    <div nz-col [nzSpan]="3" style="padding-left: 5px;">
                        Qty:
                    </div>
                    <div nz-col [nzSpan]="3" *ngFor="let item of tyreSalesTrend.sales; let i = index" style="text-align: center;">
                        <i *ngIf="i === 0; else elseQty">{{item.qty}}</i>
                        <ng-template #elseQty>{{item.qty}}</ng-template>
                    </div>
                </nz-row>
                <nz-row style="padding-top: 5px;padding-bottom: 5px;">
                    <div nz-col [nzSpan]="3" style="padding-left: 5px;">
                        Invoices:
                    </div>
                    <div nz-col [nzSpan]="3" *ngFor="let item of tyreSalesTrend.sales; let i = index" style="text-align: center;">
                        <i *ngIf="i === 0; else elseCount">{{item.count}}</i>
                        <ng-template #elseCount>{{item.count}}</ng-template>
                    </div>
                </nz-row>
                <div class="border-top" style="font-size: 1.2rem; padding-top: 8px;">
                    Month Sales Trend ({{getMonthRange(tyreSalesTrend)}}): <span [ngStyle]="{'color': getMonthTrend(tyreSalesTrend).charAt(0) === '-' ? 'red' : 'green'}">{{getMonthTrend(tyreSalesTrend)}}</span>
                </div>
                <div style="font-size: 1.2rem; padding-top: 8px;">
                    3 Month Sales Trend ({{getQuarterRange(tyreSalesTrend)}}): <span [ngStyle]="{'color': getQuarterTrend(tyreSalesTrend).charAt(0) === '-' ? 'red' : 'green'}">{{getQuarterTrend(tyreSalesTrend)}}</span>
                </div>
            </div>
        </nz-spin>
    </ng-container>
</nz-modal>

<nz-modal [(nzVisible)]="isGencodeSalesModalVisible" (nzOnCancel)="isGencodeSalesModalVisible = false" [nzFooter]="null" nzTheme="fill" [nzWidth]="700">
    <ng-container *nzModalContent>
        <nz-spin [nzSpinning]="isLoadingSalesPerGencode">
            <div *ngIf="this.gencodeSalesTrend">
                <h4>6 MONTH SALES TREND</h4>

                <h5 style="display: inline-block; margin-left: 20px">{{gencodeSalesTrend.description}}</h5>

                <nz-row class="border-top" style="padding-top: 10px; padding-bottom: 5px;">
                    <div nz-col [nzSpan]="3">

                    </div>
                    <div nz-col [nzSpan]="3" *ngFor="let item of gencodeSalesTrend.sales; let i = index" style="text-align: center;">
                        <i *ngIf="i === 0; else elseMonth">{{getMonthName(item.month)}} {{item.year}}</i>
                        <ng-template #elseMonth>{{getMonthName(item.month)}} {{item.year}}</ng-template>
                    </div>
                </nz-row>
                <nz-row style="padding-top: 5px;padding-bottom: 5px;">
                    <div nz-col [nzSpan]="3" style="padding-left: 5px;">
                        Qty:
                    </div>
                    <div nz-col [nzSpan]="3" *ngFor="let item of gencodeSalesTrend.sales; let i = index" style="text-align: center;">
                        <i *ngIf="i === 0; else elseQty">{{item.qty}}</i>
                        <ng-template #elseQty>{{item.qty}}</ng-template>
                    </div>
                </nz-row>
                <nz-row style="padding-top: 5px;padding-bottom: 5px;">
                    <div nz-col [nzSpan]="3" style="padding-left: 5px;">
                        Invoices:
                    </div>
                    <div nz-col [nzSpan]="3" *ngFor="let item of gencodeSalesTrend.sales; let i = index" style="text-align: center;">
                        <i *ngIf="i === 0; else elseCount">{{item.count}}</i>
                        <ng-template #elseCount>{{item.count}}</ng-template>
                    </div>
                </nz-row>
                <div class="border-top" style="font-size: 1.2rem; padding-top: 8px;">
                    Month Sales Trend ({{getMonthRange(gencodeSalesTrend)}}): <span [ngStyle]="{'color': getMonthTrend(gencodeSalesTrend).charAt(0) === '-' ? 'red' : 'green'}">{{getMonthTrend(gencodeSalesTrend)}}</span>
                </div>
                <div style="font-size: 1.2rem; padding-top: 8px;">
                    3 Month Sales Trend ({{getQuarterRange(gencodeSalesTrend)}}): <span [ngStyle]="{'color': getQuarterTrend(gencodeSalesTrend).charAt(0) === '-' ? 'red' : 'green'}">{{getQuarterTrend(gencodeSalesTrend)}}</span>
                </div>
            </div>
        </nz-spin>
    </ng-container>
</nz-modal>

<!-- Model Stock Brand AI -->

<nz-modal [(nzVisible)]="isBrandModalVisable" nzClosable="true" nzTitle="AI Settings" (nzOnCancel)="isBrandModalVisable = false;" [nzFooter]="null" [nzWidth]="1000">
    <ng-container *nzModalContent>
        <nz-spin [nzSpinning]="isLoadingBrandsTable">
            <form nz-form [formGroup]="aiForm">
                <nz-row [nzJustify]="'start'" nzGutter="2">
                    <nz-col [nzSpan]="8">
                        <nz-form-item>
                            <nz-form-label [nzSpan]="12">Monthly Units Sold</nz-form-label>
                            <nz-form-control [nzSpan]="12" nzErrorTip="Valid Input greater than 0 required">
                                <input nz-input type="text" formControlName="unitsSold" />
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label [nzSpan]="12">Capital</nz-form-label>
                            <nz-form-control nzHasFeedback [nzSpan]="12" nzErrorTip="Required">
                                <nz-select formControlName="capital">
                                    <nz-option [nzValue]="1" nzLabel="Very low"></nz-option>
                                    <nz-option [nzValue]="2" nzLabel="Low"></nz-option>
                                    <nz-option [nzValue]="3" nzLabel="Medium"></nz-option>
                                    <nz-option [nzValue]="4" nzLabel="High"></nz-option>
                                    <nz-option [nzValue]="5" nzLabel="Very High"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label [nzSpan]="12" nzTooltipTitle="Convert 2 tyres to 4 when national sales of 3 and 4 unts is greater than x percent." [nzTooltipIcon]="TooltipIcon">Convert 2 to 4</nz-form-label>
                            <nz-form-control [nzSpan]="12" nzErrorTip="Valid Input between than 1 - 100 required">
                                <nz-slider [nzMin]="0" [nzMax]="100" formControlName="conversion"></nz-slider>
                            </nz-form-control>
                        </nz-form-item>
                    </nz-col>
                    <nz-col [nzSpan]="8">
                        <nz-form-item>
                            <nz-form-label [nzSpan]="12" nzTooltipTitle="Days of Stock" [nzTooltipIcon]="TooltipIcon">DOS<sub style="color: var(--textcolor);">(days)</sub></nz-form-label>
                            <nz-form-control [nzSpan]="12" nzErrorTip="Valid Input between 1 - 300 required">
                                <input nz-input type="text" formControlName="daysofStock" />
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label [nzSpan]="12" nzTooltipTitle="Replenishment Cycle" [nzTooltipIcon]="TooltipIcon">RPC <sub style="color: var(--textcolor);">(days)</sub></nz-form-label>
                            <nz-form-control [nzSpan]="12" nzErrorTip="Valid Input between 1 - 100 is Required">
                                <input nz-input type="text" formControlName="replenishmentCycle" />
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label [nzSpan]="12" nzTooltipTitle="Use x percent of market sales to enhance your model stock.">Push Market Sales</nz-form-label>
                            <nz-form-control [nzSpan]="12" nzErrorTip="Valid Input between 1 - 100 % is Required">
                                <nz-slider [nzMin]="0" [nzMax]="100" formControlName="pushMarketSales"></nz-slider>
                            </nz-form-control>
                        </nz-form-item>
                    </nz-col>
                    <nz-col [nzSpan]="8">
                        <nz-form-item>
                            <nz-form-label [nzSpan]="12">Push Profit</nz-form-label>
                            <nz-form-control [nzSpan]="12">
                                <nz-switch formControlName="pushProfit" [nzCheckedChildren]="showAdd1" [nzUnCheckedChildren]="hideAdd1"></nz-switch>
                                <ng-template #showAdd1>
                                    <span nz-icon nzType="check"></span>
                                </ng-template>
                                <ng-template #hideAdd1>
                                    <span nz-icon nzType="close"></span>
                                </ng-template>
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label [nzSpan]="12">Push Cheap</nz-form-label>
                            <nz-form-control [nzSpan]="12">
                                <nz-switch formControlName="pushCheap" [nzCheckedChildren]="showAdd2" [nzUnCheckedChildren]="hideAdd2"></nz-switch>
                                <ng-template #showAdd2>
                                    <span nz-icon nzType="check"></span>
                                </ng-template>
                                <ng-template #hideAdd2>
                                    <span nz-icon nzType="close"></span>
                                </ng-template>
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label [nzSpan]="12">Only Supplier SOH</nz-form-label>
                            <nz-form-control [nzSpan]="12">
                                <nz-switch formControlName="supplierSOH" [nzCheckedChildren]="showAdd3" [nzUnCheckedChildren]="hideAdd3"></nz-switch>
                                <ng-template #showAdd3>
                                    <span nz-icon nzType="check"></span>
                                </ng-template>
                                <ng-template #hideAdd3>
                                    <span nz-icon nzType="close"></span>
                                </ng-template>
                            </nz-form-control>
                        </nz-form-item>
                        <button nz-button nzType="primary" style="float: inline-end" nzSize="large" (click)="runAI()">Run AI</button>
                    </nz-col>
                </nz-row>
            </form>
            <hr style="background-color: var(--textcolor);">
            <nz-row [nzJustify]="'start'">
                <nz-col [nzSpan]="12">
                    <h5>Advantage Brands</h5>
                </nz-col>
            </nz-row>
            <br>
            <nz-row [nzJustify]="'start'">
                <nz-col [nzSpan]="24">
                    <nz-table #cldata [nzData]="this.clientBrands" [nzFrontPagination]="false">
                        <thead>
                            <tr>
                                <th style="width: 20%;">Brand</th>
                                <th style="width:100%">Brand Advantage</th>
                                <th colSpan="2"></th>
                            </tr>
                        </thead>
                        <tbody class="tbody">
                            <tr *ngFor="let item of cldata.data;let i = index">
                                <td style="width: 20%;">{{item.name}}</td>
                                <td>
                                    <nz-slider [id]="'item'+i" [nzMin]="-100" [nzMax]="100" [(ngModel)]="item.sliderValue" (nzOnAfterChange)="updateBrand(item)"></nz-slider>
                                </td>
                                <td style="width:100%">
                                    {{item.sliderValue}}
                                </td>
                                <td>
                                    <button nz-button nzType="default" nzDanger nzShape="circle" (click)="deleteBrand(item)">
                                        <i nz-icon nzType="delete" nzTheme="outline"  style="color: cr;"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td style="width: 20%;">
                                    <input type="text" nz-input placeholder="Search Brand" (input)="searchBrands($event)" [nzAutocomplete]="auto" width="300" [(ngModel)]="searchedBrand" (ngModelChange)="selectBrand($event)" />
                                    <nz-autocomplete #auto>
                                        <nz-auto-option *ngFor="let option of autoCompleteBrands" [nzValue]="option.name">{{ option.name}} </nz-auto-option>
                                    </nz-autocomplete>
                                </td>
                                <td colspan="3">

                                </td>
                            </tr>
                    </nz-table>
                </nz-col>
            </nz-row>
        </nz-spin>
    </ng-container>
</nz-modal>

<nz-modal [(nzVisible)]="isloadingAISuggestion" nzClosable="{{isAiModalClosable}}" nzMaskClosable="false" (nzOnCancel)="isloadingAISuggestion = false;this.ngOnInit();" [nzFooter]="null" nzCentered>
    <ng-container *nzModalContent>
        <div class="text-center">
            <ng-container *ngIf="isAiModalClosable then completed else loading"></ng-container>
            <ng-template #loading>
                <div nz-row [nzJustify]="'center'">
                    <div nz-col nzSpan="6">
                        <nz-spin nzSimple [nzSize]="'large'" style="font-size: 100px !important;color: var(--textcolor);"></nz-spin>
                        <br>
                        <h5>Proccessing</h5>
                    </div>
                </div>
            </ng-template>
            <ng-template #completed>
                <div nz-row [nzJustify]="'center'" nzGutter="2">
                    <div nz-col nzSpan="6">
                        <i nz-icon nzType="check-circle" nzTheme="outline" style="font-size: 80px !important;color: var(--textcolor);"></i><br>
                        <br>
                        <h5 class="text-center">Result</h5>
                        <div nz-row [nzJustify]="'center'" nzGutter="2">
                            <div style="float: inline-start;font-size: 15px;">
                                <b>Suggested MSL</b> <br> {{ suggestedMSL }}
                            </div>
                            <div style="float: inline-start;font-size: 15px;">
                                <b>Suggested SKU </b> <br> {{ suggestedSKU }}
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </ng-container>
</nz-modal>

<nz-modal [(nzVisible)]="isPieChartModal" nzClosable="true" (nzOnCancel)="isPieChartModal = false;" [nzFooter]="null">
    <ng-container *nzModalContent>
        <nz-tabset nzCentered nzSize="large" (nzSelectedIndexChange)="reloadChart($event)" [(ngModel)]="selectedTab">
            <nz-tab *ngFor="let item of nzTabs" nzTitle="{{item.title}}">
                <nz-spin [nzSpinning]="isPieChartLoading">
                    <canvas baseChart [datasets]="pieChartData" [labels]="pieChartLabels" [type]="'doughnut'" [options]="pieChartOptions" [legend]="true">
                    </canvas>
                </nz-spin>
            </nz-tab>
        </nz-tabset>
    </ng-container>
</nz-modal>