import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OrderLine } from '../models/purchase';
import { environment } from './../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class PurchasesService {
  apiUrl = 'v1/purchases/';

  constructor(private http: HttpClient) { }

  //Get Client  Purchases
  public getPurchases()
  {
    return this.http.get( environment.ApiUrl() + this.apiUrl + "getpurchases");
  }

  public getPurchase(orderid: number)
  {
    return this.http.get( environment.ApiUrl() + this.apiUrl + "getpurchase/" + orderid.toString());
  }

  public saveRef(id:number,ref:string)
  {
    return this.http.get( environment.ApiUrl() + this.apiUrl + "saveRef/" + id + "/" + ref);
  }

  //Get OrderLines
  public getPurchaselines(orderId)
  {
    return this.http.get(environment.ApiUrl() + this.apiUrl + "purchaselines/" + orderId);
  }

  public getUnseenPurchasesCount() {
    return this.http.get<{count: number}>(environment.ApiUrl() + this.apiUrl + "unseenCount");
  }


  public receiveStock(orderId:number,List:OrderLine[]) {
    return this.http.post(environment.ApiUrl() + this.apiUrl + "receiveStock/"+ orderId,List);
  }

  public SeeSupplierSpend(){
    return this.http.get(environment.ApiUrl() + this.apiUrl + "supplierSpend")
  }

  public SeeBrandSpend(){
    return this.http.get(environment.ApiUrl() + this.apiUrl + "brandSpend")
  }

  public getUserRoles(){
    return this.http.get(environment.ApiUrl() + this.apiUrl+ "user_roles")
  }


}
