import { Component, OnInit } from '@angular/core';
import { GrvService } from './grv.service';
import { NzModalService } from 'ng-zorro-antd/modal';


@Component({
  selector: 'app-grv',
  templateUrl: './grv.component.html',
  styleUrls: ['./grv.component.less']
})
export class GrvComponent implements OnInit {

  private grvService: GrvService;
  isLoading = false;
  load = '';
  message = '';
  helpModal = false

  constructor(_grvService: GrvService, private modalService: NzModalService) { 
    this.grvService = _grvService;
  }

  ngOnInit(): void {
  }

  Pam(group: string): void {
    this.isLoading = true;
    this.load = 'Generating '+group+' PAM...'
    this.grvService.callGeneratePam(group).subscribe(
      result => {
        this.isLoading = false;
        this.message = result.message;
      }
    )    
  }

  PrePam(group: string): void {
    this.isLoading = true;
    this.load = 'Generating '+group+' Pre-PAM...'
    this.grvService.callGeneratePrePam(group).subscribe(
      result => {
        this.isLoading = false;
        this.message = result.message;
      }
    )    
  }

  StdGP(group: string): void {
    this.isLoading = true;
    this.load = 'Updating '+group+' standard GPs...'
    this.grvService.callStdGp(group).subscribe(
      result => {
        this.isLoading = false;
        this.message = result.message;
      }
    )    
  }

  Agilis(group: string): void {
    this.isLoading = true;
    this.load = 'Updating '+group+' Agilis tyres...'
    this.grvService.callAgilisUpdate(group).subscribe(
      result => {
        this.isLoading = false;
        this.message = result.message;
      }
    )    
  }

  storedProc(storedProc: string): void {
    this.isLoading = true;
    this.load = 'Executing stored procedure...'
    this.grvService.callAgilisUpdate(storedProc).subscribe(
      result => {
        this.isLoading = false;
        this.message = result.message;
      }
    )    
  }
 

}
