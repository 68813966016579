import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ClientInfoForAdminStockMappings, UnMappedStock, MappedStock, CustomStockToBeMapped, Stock, TyreStockToBeMapped, CustomStock, NewCustomStockMapping, StockType } from '../models/admin-mappings';
import { NotificationService } from '../shared/notification.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AdminMappingService {


    customStockTypes : StockType[] = []

    apiUrl = 'v2/admin_stockmappings/';

    unmappedPlaceholder: UnMappedStock = {
        stockCode: '',
        description: '',
        clientId: 0,
        clientName: '',
        soh: 0,
    };

    unmappedStockItem: UnMappedStock = this.unmappedPlaceholder;
    
    currentClientName: string = '';
    currentClientId: number = 0;

    clientListTemp: ClientInfoForAdminStockMappings[] = [];
    clientList: ClientInfoForAdminStockMappings[] = [];

    activeCustomStock: CustomStock[] = [];
    
    currentStockItem: UnMappedStock;
    currentVisibility: boolean;
    isLoadingActiveCustomStock = false;
  constructor(private http: HttpClient, private notification: NotificationService) { 
    this.currentStockItem = {
      stockCode: '',
      description: '',
      clientId: 0,
      clientName: '',
      soh: 0,
      } ;
    this.currentVisibility = false;
 
    this.currentClientName = '';
    }

    public getUnmappedStock(client: ClientInfoForAdminStockMappings) {
        return this.http.get<UnMappedStock[]>(environment.ApiUrl() + this.apiUrl + "UnmappedStock/" + client.clientId);
    }

    public getClients() {
        return this.http.get<ClientInfoForAdminStockMappings[]>(environment.ApiUrl() + this.apiUrl + "Clients");    
    }

    public getActiveStock() {
  
        return this.http.get<Stock[]>(environment.ApiUrl() + this.apiUrl + "ActiveStock");
    }

    public getActiveCustomStock(clientId: number) {
        this.isLoadingActiveCustomStock = true;
        this.http.get<CustomStock[]>(environment.ApiUrl() + this.apiUrl + "ActiveCustomStock/" + clientId)
            .subscribe((response: CustomStock[]) => {
                this.isLoadingActiveCustomStock = false;
                this.activeCustomStock = response;
            }, error => {
                this.isLoadingActiveCustomStock = false;
                this.notification.ShowAlert(error);      
            });
          }

    public getMappedStock(clientId: number) {
        return this.http.get<MappedStock[]>(environment.ApiUrl() + this.apiUrl + "MappedStock/" + clientId);
    }

    public mapCustomStock(customStockItem: CustomStockToBeMapped) {
        return this.http.post(environment.ApiUrl() + this.apiUrl + "mapCustomStock", customStockItem);
    }

    public mapTyreStock(unmappedStockItem: TyreStockToBeMapped) {
        return this.http.post(environment.ApiUrl() + this.apiUrl + "mapTyreStock", unmappedStockItem);
    }

    public mapUnmappableStock(unmappableStockItem: TyreStockToBeMapped) {
        return this.http.post(environment.ApiUrl() + this.apiUrl + "mapUnmappableStock", unmappableStockItem);
    }

    public createCustomStockMapping(customStockMapping: NewCustomStockMapping) {
        return this.http.post(environment.ApiUrl() + this.apiUrl + "createCustomStockMapping", customStockMapping);
    }

    public openModal(stockItem: UnMappedStock): void {
        this.unmappedStockItem = stockItem;
        this.currentVisibility = true; 
        this.getActiveStock();
        this.getActiveCustomStock(this.currentClientId);
    }

    public closeModal(): void {
        this.currentVisibility = false; 
    }

    public deleteMapping(item: MappedStock) {
        return this.http.post(environment.ApiUrl() + this.apiUrl + "deleteMapping", item);
    }

    public reUnmapStock(stock: UnMappedStock) {
        return this.http.post(environment.ApiUrl() + this.apiUrl + "reUnmapStock", stock);
    }

    public getCustomStockTypes() {
        return this.http.get<StockType[]>(environment.ApiUrl() + this.apiUrl + "customStockTypes");
    }

}

