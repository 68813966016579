<nz-spin [nzSpinning]="_isLoading">
    <!--Form-->
    <form nz-form [formGroup]="addStockItemForm" (keydown.enter)="$event.preventDefault()" *ngIf="showForm">
        <div nz-row [nzGutter]="24">
            <div nz-col [nzSpan]="8">
                <nz-form-item>
                    <nz-form-control [nzErrorTip]="'Width Required'">
                        <nz-input-group>
                            <nz-select formControlName="width" style="width: 100%;" [nzPlaceHolder]="'Tyre Width'" [nzShowSearch]="true">
                                <nz-option *ngFor="let width of metaHolder.widths" [nzValue]="width" [nzLabel]="width"></nz-option>
                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col [nzSpan]="8">
                <nz-form-item>
                    <nz-form-control [nzErrorTip]="'Profile Required'">
                        <nz-input-group>
                            <nz-select formControlName="profile" style="width: 100%;" [nzPlaceHolder]="'Tyre Profile'" [nzShowSearch]="true">
                                <nz-option *ngFor="let profile of metaHolder.profiles" [nzValue]="profile" [nzLabel]="profile"></nz-option>
                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col [nzSpan]="8">
                <nz-form-item>
                    <nz-form-control [nzErrorTip]="'Rim Required'">
                        <nz-input-group>
                            <nz-select formControlName="rim" style="width: 100%;" [nzPlaceHolder]="'Tyre Rim'" [nzShowSearch]="true">
                                <nz-option *ngFor="let rim of metaHolder.rims" [nzValue]="rim" [nzLabel]="rim"></nz-option>
                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div nz-row [nzGutter]="24">
            <div nz-col [nzSpan]="8">
                <nz-form-item>
                    <nz-form-control [nzErrorTip]="'Market Required'">
                        <nz-input-group>
                            <nz-select formControlName="market" style="width: 100%;" [nzPlaceHolder]="'Market'" [nzShowSearch]="true" (ngModelChange)="marketCheck($event)">
                                <nz-option *ngFor="let market of metaHolder.markets" [nzValue]="market" [nzLabel]="market"></nz-option>
                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col [nzSpan]="8">
                <nz-form-item>
                    <nz-form-control [nzErrorTip]="'Speed Rating Required'">
                        <nz-input-group>
                            <nz-select formControlName="speedRating" style="width: 100%;" [nzPlaceHolder]="'Speed Rating'" [nzShowSearch]="true">
                                <nz-option *ngFor="let speed of metaHolder.speedRatings" [nzValue]="speed" [nzLabel]="speed"></nz-option>
                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col [nzSpan]="8">
                <nz-form-item>
                    <nz-form-control [nzErrorTip]="'Load Rating Required'">
                        <nz-input-group>
                            <nz-select formControlName="loadRating" style="width: 100%;" [nzPlaceHolder]="'load Rating'" [nzShowSearch]="true">
                                <nz-option *ngFor="let load of metaHolder.loadRatings" [nzValue]="load" [nzLabel]="load"></nz-option>
                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div nz-row [nzGutter]="24">
            <div nz-col [nzSpan]="8">
                <nz-form-item>
                    <nz-form-control [nzErrorTip]="'Brand Required'">
                        <nz-input-group>
                            <nz-select formControlName="brand" style="width: 100%;" [nzPlaceHolder]="'Brand'" [nzShowSearch]="true">
                                <nz-option *ngFor="let brand of metaHolder.brands" [nzValue]="brand.brandId" [nzLabel]="brand.name"></nz-option>
                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col [nzSpan]="8">
                <nz-form-item>
                    <nz-form-control [nzErrorTip]="'Pattern Required'">
                        <nz-input-group nzPrefixIcon="tag">
                            <input style="text-transform: titlecase;" nz-input formControlName="pattern" placeholder="Pattern" class="text-center">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col [nzSpan]="8">
                <nz-form-item>
                    <nz-form-control [nzErrorTip]="'Stock Code'">
                        <nz-input-group nzPrefixIcon="barcode">
                            <input style="text-transform: titlecase;" nz-input formControlName="stockCode" placeholder="StockCode" class="text-center">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div nz-row [nzGutter]="24" [nzJustify]="'center'">
            <div nz-col [nzSpan]="8">
                <nz-form-item>
                    <nz-form-control [nzErrorTip]="'Runflat Required'">
                        <nz-input-group>
                            <nz-select formControlName="runFlat" style="width: 100%;" [nzPlaceHolder]="'RunFlat'" [nzShowSearch]="true">
                                <nz-option *ngFor="let option of metaHolder.runFlat" [nzValue]="option" [nzLabel]="option"></nz-option>
                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col [nzSpan]="8">
                <nz-form-item>
                    <nz-form-control [nzErrorTip]="'Extra Load Required'">
                        <nz-input-group>
                            <nz-select formControlName="xl" style="width: 100%;" [nzPlaceHolder]="'Extra Load'" [nzShowSearch]="true">
                                <nz-option *ngFor="let option of metaHolder.extraLoad" [nzValue]="option" [nzLabel]="option"></nz-option>
                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
                <button [disabled]="!addStockItemForm.valid" nz-button nzType="primary" class="btn btn-wbc" (click)="addStockItem()">
                    Add Item
            </button>
            </div>
        </div>
    </form>
    <!--Status 1 -->
    <div *ngIf="showCodeExists && responseModel" class="text-center">
        <h5>We found a Match on Stock Code : <span style="color: var(--textcolor) !important">{{responseModel.data[0].stockCode}}</span></h5>
        <div nz-row [nzGutter]="24" [nzJustify]="'center'">
            <div nz-col [nzSpan]="18">
                <nz-card class="items">
                    <div nz-row [nzGutter]="24" [nzJustify]="'center'">
                        <div nz-col [nzSpan]="24">
                            {{responseModel.data[0].brand}} | {{responseModel.data[0].descriptionLong}} | {{responseModel.data[0].stockCode}}
                        </div>
                    </div>
                </nz-card>
            </div>
        </div>
        <p>Does this Tyre Match what you are Trying to Insert ?</p>
        <div nz-row [nzGutter]="24" [nzJustify]="'center'">
            <div nz-col [nzSpan]="6">
                <button nzType="default" nz-button (click)="notifyAlreadyExists()">
                    Yes
                </button>
            </div>
            <div nz-col [nzSpan]="6">
                <button nzType="default" nz-button (click)="displayAlert()">
                    No
                </button>
            </div>
        </div>
    </div>
    <!--Status 2 -->
    <div *ngIf="similarTyreExist && responseModel" class="text-center">
        <h5>We found similar tyres matching on the data your provided</h5>
        <p>If Any of the options Below match the tyre you are trying to add , click to map it</p>
        <p></p>
        <div nz-row [nzGutter]="24" [nzJustify]="'center'">
            <div nz-col [nzSpan]="18">
                <nz-card [nzBodyStyle]="{'padding': '4px'}" class="items" *ngFor="let item of responseModel.data" (click)="addNewStockMapping(item)">
                    <div nz-row [nzGutter]="24" [nzJustify]="'center'">
                        <div nz-col [nzSpan]="24" class="text-center">
                            {{item.brand}} | {{item.descriptionLong}} | {{item.stockCode}}
                        </div>
                    </div>
                </nz-card>
                <nz-card [nzBodyStyle]="{'padding': '4px'}" class="items" (click)="addStockItemDirect()">
                    <div nz-row [nzGutter]="24" [nzJustify]="'center'">
                        <div nz-col [nzSpan]="24" class="text-center">
                            No Tyres Match
                        </div>
                    </div>
                </nz-card>
            </div>
        </div>
    </div>
</nz-spin>