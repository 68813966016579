<nz-spin [nzSpinning]="isLoading">
    <div nz-col nzSpan="24" class="container-fluid">
        <nz-card nzHoverable>
            <div nz-row>
                <div nz-col nzSpan="8" class="text-left">
                    <span nz-icon nzType="user" nzTheme="outline"></span> {{ this.customer.name }}<br><br>
                    <span nz-icon nzType="phone" nzTheme="outline"></span> {{ this.customer.cell }}<br>
                    <!-- <i nz-icon nzType="mail" nzTheme="outline" class="back"></i> {{ this.customer.email }} -->
                </div>
                <div nz-col nzSpan="8" class="text-center ">
                    <h6>Vehicle Registrations:</h6>
                    <nz-select [(ngModel)]="this.SelectedVreg" (ngModelChange)="filtertable()" style="width: 80%;">
                        <nz-option nzLabel="All" nzValue="All"> </nz-option>
                        <nz-option *ngFor="let item of vehicles" [nzLabel]="item.vreg" [nzValue]="item.vreg"></nz-option>
                    </nz-select>
                </div>
                <div nz-col nzSpan="8" class="text-center" style="text-align: center;">
                    <span class="back">{{ this.localquotes }}</span> Quote<span *ngIf="this.localquotes != 1">s</span> / <span class="back">{{ this.localsales }}</span> Sale<span *ngIf="this.localsales != 1">s</span>
                </div>
            </div>
            <br>
            <div nz-row class="text-center" style="height:inherit">
                <div nz-col nzSpan="8" class="text-left">
                    <button nz-button nzType="primary" nzSize="large" (click)="openCustomer()">
                            <span nz-icon nzType="edit" nzTheme="outline"></span>
                            Edit Customer 
                        </button>
                </div>
                <div nz-col nzSpan="8">
                    <app-searchbar></app-searchbar>
                    <br>
                </div>
                <div nz-col nzSpan="8">
                    <h6> Total Sales</h6>
                    <span class="text-style" style="font-weight:600;"> R {{ total() | number }} </span>
                </div>
            </div>
            <nz-divider></nz-divider>
            <div nz-row nzJustify="center">
                <div nz-col nzSpan="24">
                    <nz-spin [nzSpinning]="isLoading">
                        <nz-table #lines [nzData]="ShowLines" [nzFrontPagination]="true" [nzPageSize]="25">
                            <thead>
                                <tr>
                                    <th [nzFilters]="leadFilterValues" [nzFilterFn]="leadFilterFn">Lead Type</th>
                                    <th>Date</th>
                                    <th>Tyre Size</th>
                                    <th>Tyre Brands</th>
                                    <th>Channel</th>
                                    <th>Total <small class="text-style">(Vat Excl)</small></th>
                                    <th>Status</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody class="tbody">
                                <tr *ngFor="let item of lines.data">
                                    <td>
                                        <span *ngIf="item.salequote == 'quote'"><span nz-icon nzType="file-text" nzTheme="outline"></span> Quote</span>
                                        <span *ngIf="item.salequote == 'sale'"><span class="text-success" nz-icon nzType="credit-card" nzTheme="outline"></span> Sale</span>
                                    </td>
                                    <td>
                                        {{ item.dtcreated }}
                                    </td>
                                    <td>
                                        <div *ngFor="let item of item.LineItems; let i=index">
                                            <span *ngIf="i<1">
                                                <span *ngIf="item.stocktype == 'TYRE'">
                                                    {{ item.Size }}
                                                </span>
                                            <span *ngIf="item.stocktype == 'NONE STOCK'">
                                                    Service
                                                </span>
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <span *ngFor="let line of item.LineItems">
                                            <img *ngIf="line.stocktype == 'TYRE'" class="brandImage-customer" style='margin-left: 1%;border-radius: 10px;' height='25' width='50' src='assets/Images/brands/{{line.Brand}}'>   
                                    </span>
                                        <span>
                                        <img *ngIf="hasService(item.LineItems)" class="brandImage-customer" style='margin-left: 1%;border-radius: 10px;' height='25' width='50' src='assets/Images/brands/service.jpg'> 
                                    </span>
                                    </td>
                                    <td>
                                        <span *ngIf="item.Ref.startsWith('S') || item.Ref.startsWith('N') ">Created  with <img src="../../assets/Images/s-logo-white.png" height='25' width='25'></span>
                                        <span *ngIf="item.Ref.startsWith('E')">Created Externally 
                                            <i nz-icon nzType="laptop" nzTheme="outline" class=" text"></i>
                                        
                                        </span>
                                    </td>
                                    <td>
                                        <span *ngIf="item.sellerId != authService.user.client_id">
                                            <button nz-button nzType="primary">External</button> 
                                        </span>
                                        <span *ngIf="item.sellerId == authService.user.client_id">
                                                <span class="text-info-style">R </span> {{ item.Price | number }}
                                        </span>
                                    </td>
                                    <td>
                                        <span *ngIf="item.sellerId != authService.user.client_id">
                                            <button nz-button nzType="primary">External</button> 
                                        </span>
                                        <span *ngIf="item.sellerId == authService.user.client_id">
                                            <span *ngIf="item.salequote == 'quote'">
                                                <span *ngIf="item.stateId == 1 || item.stateId == 2">
                                                    Work In Progress
                                                    <span nz-icon nzType="hourglass" nzTheme="outline"></span>
                                        </span>
                                        <span *ngIf="item.stateId == 3">
                                                    <span *ngIf="item.stateType == 1">
                                                        {{ item.status }} 
                                                        <i nz-icon nzType="check" nzTheme="outline" class="text"></i>
                                                    </span>
                                        <span *ngIf="item.stateType == 0">
                                                        {{ item.status }}
                                                        <i nz-icon nzType="check" nzTheme="outline"  class="text-danger-style"></i>
                                                    </span>
                                        </span>
                                        </span>
                                        <span *ngIf="item.salequote == 'sale'">
                                                <span *ngIf="item.stategroup == 1">        
                                                    {{ item.status }}
                                                    <i nz-icon [nzType]="'loading'"  class="text-warning-style"></i>
                                                </span>
                                        <span *ngIf="item.stategroup == 2">
                                                    {{ item.status }}
                                                    <i nz-icon nzType="check" nzTheme="outline" class="text"></i>
                                                </span>
                                        <span *ngIf="item.stategroup == 3">
                                                    {{ item.status }}
                                                    <i nz-icon nzType="close" nzTheme="outline" class="text-danger-style"></i>
                                                </span>
                                        <span *ngIf="item.stategroup == 4">
                                                    <i nz-icon nzType="check" nzTheme="outline" class=" text"></i>
                                                    {{ item.status }}
                                                </span>
                                        </span>
                                        </span>
                                    </td>
                                    <td>
                                        <span *ngIf="item.sellerId != authService.user.client_id">                         
                                        </span>
                                        <span *ngIf="item.sellerId == authService.user.client_id">
                                            <span *ngIf="item.salequote == 'quote'">
                                                <button nz-button nzType="primary" (click)="route(item.Id)"><span nz-icon nzType="export" nzTheme="outline"></span></button>
                                        </span>
                                        <span *ngIf="item.salequote == 'sale'">
                                                <button nz-button nzType="primary" (click)="routesale(item.Id)"><span nz-icon nzType="export" nzTheme="outline"></span></button>
                                        </span>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </nz-table>
                    </nz-spin>
                </div>
            </div>
        </nz-card>
    </div>
</nz-spin>