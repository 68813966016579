import { Component, OnInit } from '@angular/core';
import { LeadsService } from '.././leads.service';
import { Router } from '@angular/router';
import { NotificationService } from '../../shared/notification.service';
import { Quotes, Quotelines } from '../../models/quotes';

@Component({
  selector: 'app-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.less']
})
export class QuotesComponent implements OnInit {

  quotes: Quotes[];
  quotelines: Quotelines [];

  constructor(private leadsService : LeadsService,private router: Router, private notification: NotificationService) { }

  custSortFn = (a: Quotes[], b: Quotes[]): number => a['customername'].toLowerCase() < b['customername'].toLowerCase() ? -1 : 1;
  emailSortFn = (a: Quotes[], b: Quotes[]): number => a['customeremail'].toLowerCase() < b['customeremail'].toLowerCase() ? -1 : 1;
  cellSortFn = (a: Quotes[], b: Quotes[]): number => a['customercell'] < b['customercell'] ? -1 : 1;
  refSortFn = (a: Quotes[], b: Quotes[]): number => a['quoteRef'].toLowerCase() < b['quoteRef'].toLowerCase() ? -1 : 1;
  dtSortFn = (a: Quotes[], b: Quotes[]): number => a['dt'] < b['dt'] ? -1 : 1;

  ngOnInit(): void {
    this.reset();
    this.leadsService.getLeads().subscribe(res => 
      {
        this.quotes = res['quotes'] as Quotes[];  
      },
      error => {
        this.notification.handleError(error);       
      }); 
  }

  reset()
  {
    this.quotes=[];
  }
  
  viewquote(quoteId)
  {
      this.router.navigate(["/pos/v2",quoteId])
  }

}
