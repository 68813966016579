<div class="container-fluid bg-1">

    <!--Top Row -->
    <div nz-row class="justify-content-md-center text-div">
        <div nz-col [nzSpan]="8" class="text-center">
            <span class="text-style">Quote :</span> {{posQuote.quoteRef}}
        </div>
        <div nz-col [nzSpan]="8" class="text-center">
            <span class="text-style">Date :</span> {{currentDate}}
        </div>
        <div nz-col [nzSpan]="8" class="text-center">
            <span class="text-style">Salesman :</span> {{posQuote.user.userName}} {{ posQuote.user.userCell }}
        </div>
    </div>

    <br>

    <!--Section - Data Capture -->
    <div nz-row class="border-row">
        <!-- Customer -->

        <div nz-col [nzSpan]="8">
            <nz-skeleton [nzLoading]="isLoading || isLoading" [nzActive]="true">
                <app-pos-customer [posQuote]="posQuote" (loadVehicles)="loadVehicles()"></app-pos-customer>
            </nz-skeleton>
        </div>

        <!-- Vehicle -->
        <div nz-col [nzSpan]="8" class="text-center border-center-col">
            <!-- If Vehicles Found -->
            <nz-skeleton [nzLoading]="isLoading || isLoading" [nzActive]="true">
                <app-pos-vehicles [posQuote]="posQuote" (loadVehicles)="loadVehicles()"></app-pos-vehicles>
            </nz-skeleton>
        </div>

        <!-- Action Button Section -->
        <div nz-col [nzSpan]="8" class="border-right-col" class="justify-content-md-center ">
            <ng-container *ngIf="posQuote.custId != 14041 && posQuote.custId > 0 then showAll; else shareQuote">
            </ng-container>
            <ng-template #showAll>
                <div nz-row [nzJustify]="'start'" class="row-height-customer" [ngClass]="{'btn-space-1' : (this.isWIP === true || this.posQuote.wbcActive) , 'btn-space-2' : (!this.isWIP  || !this.posQuote.wbcActive)}">
                    <!--  -->
                    <div nz-col [nzSpan]="6" [nzOffset]="4">
                        <button class="btn btn-action btn_hover" style="width: 150px;" (click)="clickSendQuote()" [nzTooltipPlacement]="'left'" nz-tooltip nzTooltipTitle="Share Quote">
                                    
                                    <i nz-icon nzType="share-alt" nzTheme="outline" class="color-icon"></i>   
                                    Share Quote      
                        </button>
                    </div>
                    <div nz-col [nzSpan]="6" [nzOffset]="3">
                        <button class="btn btn-action btn_hover" style="width: 150px;" (click)="navigateCustomers()" [nzTooltipPlacement]="'right'" nz-tooltip nzTooltipTitle="View Customer Info">
                                    <i nz-icon nzType="user" nzTheme="outline" class="color-icon"></i>  
                                    Customer                 
                        </button>
                    </div>
                </div>
                <div nz-row [nzJustify]="'start'" class="row-height-customer btn-space">
                    <div nz-col [nzSpan]="6" [nzOffset]="4">
                        <button class="btn btn-action btn_hover" style="width: 150px;" (click)="showVehicleModal()" [nzTooltipPlacement]="'left'" nz-tooltip nzTooltipTitle="Link New Customer Vehicle">
                                    <i nz-icon nzType="car" nzTheme="outline" class="color-icon"></i>
                                    Add Vehicle                                
                        </button>
                    </div>
                    <div nz-col [nzSpan]="6" [nzOffset]="3">
                        <button class="btn btn-action btn_hover" style="width: 150px;" (click)="showFullActionLog()" [nzTooltipPlacement]="'right'" nz-tooltip nzTooltipTitle="View Action log">
                                    <i nz-icon nzType="credit-card" nzTheme="outline" class="color-icon"></i>  
                                    Action Log                              
                        </button>
                    </div>
                </div>
                <div nz-row [nzJustify]="'start'" class="row-height-customer btn-space">
                    <div nz-col [nzSpan]="6" [nzOffset]="4" *ngIf="this.isCompleted == false && this.isWIP === true">
                        <button class="btn btn-action btn_hover" style="width: 150px;" (click)="printJobCard()" [nzTooltipPlacement]="'left'" nz-tooltip nzTooltipTitle="Print Job Card" [nzTooltipPlacement]="'left'">
                                    <i nz-icon nzType="credit-card" nzTheme="outline" class="color-icon"></i>     
                                    Print JC                               
                        </button>
                    </div>
                    <div nz-col [nzSpan]="6" [nzOffset]="3" *ngIf="this.posQuote.wbcActive && this.isWIP === true">
                        <button class="btn btn-orange btn-action color-icon-wbc" style="width: 150px;" (click)="showAddVehiclModal()" [nzTooltipPlacement]="'right'" nz-tooltip nzTooltipTitle="New WBC Quote" [nzTooltipPlacement]="'right'">                                              
                                <img src="./../../../assets/Images/dealers/wbc.png" width="19" height="19" >
                                WBC Quote
                        </button>
                    </div>
                    <div nz-col [nzSpan]="6" [nzOffset]="4" *ngIf="this.posQuote.wbcActive && this.isWIP === false">
                        <button class="btn btn-orange btn-action color-icon-wbc" style="width: 150px;" (click)="showAddVehiclModal()" [nzTooltipPlacement]="'left'" nz-tooltip nzTooltipTitle="New WBC Quote" [nzTooltipPlacement]="'right'">                                              
                                <img src="./../../../assets/Images/dealers/wbc.png" width="19" height="19" >
                                WBC Quote
                        </button>
                    </div>
                </div>
            </ng-template>
            <ng-template #shareQuote>
                <div nz-row [nzJustify]="'center'" class="row-height-customer space ">
                    <div nz-col [nzSpan]="6" class="justify-content-md-center ">
                        <button class="btn btn-action" style="width: 150px;" (click)="clickSendQuote()">
                                    <i nz-icon nzType="share-alt" nzTheme="outline" class="color-icon"></i>
                                    Share Quote
                        </button>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>

    <br>

    <!-- Dynamic Actions -->
    <div nz-row class="pos-middile">
        <div nz-col [nzSpan]="8">
            <div nz-row [nzJustify]="'center'" [nzAlign]="'bottom'">
                <div nz-col [nzSpan]="24">
                    <div class="text-center">
                        <span class="text-left" style="font-size: 1.3rem;">Total : </span>
                        <span class="text-info-style" style="padding-left: 1px;font-size: 1.3rem;"> R </span><span style="padding-left: 1px;font-size: 1.3rem;">{{totalPriceInc()}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div nz-col [nzSpan]="8" class="text-center">
            <div nz-row [nzJustify]="'center'">
                <!-- <span class="text-left" style="font-size: 1rem;">Comment</span> -->
                <div class="input-group text-center">
                    <input (focusout)="savecomment()" [(ngModel)]="posQuote.comment" class="form-control txtarea text-center comment" placeholder="Comments" (keypress)="$event.keyCode == 13 ? savecomment() : null" [maxlength]="200" />
                </div>
            </div>
        </div>
        <div nz-col [nzSpan]="8" class="text-right">
            <div nz-row [nzJustify]="'center'">
                <div nz-col [nzSpan]="6" class="text-center">
                    <button *ngIf="this.isCompleted == false" (click)="saveQuote()" class="btn service1 btn-pos-action" nz-tooltip nzTooltipTitle="Save Quote">
                            <i nz-icon nzType="save" nzTheme="outline" class="btn-pos-action-i"></i>
                    </button>
                </div>
                <div nz-col [nzSpan]="6" class="text-center">
                    <app-pos-add-lines [posQuote]="posQuote" (itemAddedEvent)="addItemToQuote($event)"></app-pos-add-lines>
                </div>
                <div nz-col [nzSpan]="6" class="text-center">
                    <button *ngIf="this.isCompleted == false" (click)="clearQuote()" class="btn service1 btn-pos-action" nz-tooltip nzTooltipTitle="Clear Quote">
                            <i nz-icon nzType="delete" nzTheme="outline" class="btn-pos-action-i"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Pos Table -->
    <div nz-row>
        <div nz-col [nzSpan]="24">
            <nz-table #quoteLinesTable [nzData]="posQuote.quoteLines" [nzLoading]="isLoading" [nzBordered]="false" [nzOuterBordered]="false" nzSize="small" [nzFrontPagination]="false">
                <thead>
                    <tr>
                        <th>Description</th>
                        <th>Brand</th>
                        <th>Code</th>
                        <th>SOH</th>
                        <th (click)="this.showCost = !this.showCost" style="cursor: pointer;"><i nz-icon nzType="eye" nzTheme="outline" style="vertical-align: baseline;"></i></th>
                        <th (click)="isQtyLocked = !isQtyLocked" [style.color]="isQtyLocked ? 'var(--textcolor)' : ''" style="cursor: pointer;">
                            <i nz-icon nzType="lock" nzTheme="fill" *ngIf="isQtyLocked" style="vertical-align: middle;"></i>
                            <i nz-icon nzType="unlock" nzTheme="fill" *ngIf="!isQtyLocked" style="vertical-align: middle;"></i> Qty
                        </th>
                        <th>Price (incl VAT)</th>
                        <th>Total Price</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of quoteLinesTable.data; let i = index">
                        <td>
                            <nz-badge [nzCount]="data.recommended ? recommendedTemplate : 0">
                                <a (click)="searchStock(data)" class="btn">
                                    <i nz-icon nzType="warning" nzTheme="fill" style="color: orange; font-size: 1rem; vertical-align: middle; margin-bottom: 3px;" *ngIf="getQuoteLineWarningTextSeller(data) || getQuoteLineWarningTextCost(data)" nz-tooltip [nzTooltipTitle]="costPriceWarningTemplate"></i>                                    {{ data.description }}
                                </a>
                            </nz-badge>
                            <ng-template #recommendedTemplate>
                                <i nz-icon nzType="check-circle" class="ant-scroll-number-custom-component" style="color: var(--textcolor); margin-top: 4px;" nz-tooltip="Recommended Option"></i>
                            </ng-template>
                            <ng-template #costPriceWarningTemplate>
                                {{getQuoteLineWarningTextSeller(data)}} <br *ngIf="getQuoteLineWarningTextSeller(data)" /> {{getQuoteLineWarningTextCost(data)}}
                            </ng-template>
                        </td>
                        <td>
                            <div *ngIf="!data.brandLogoUrl; else brandLogoImage">{{ data.brand }}</div>
                            <ng-template #brandLogoImage>
                                <img src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{data.brandLogoUrl}}" class="brandImage" alt="{{data.brand}}" nz-tooltip nzTooltipTitle="{{data.brand}}" nzTooltipPlacement="right" />
                            </ng-template>
                        </td>
                        <td>{{ data.stockCode }}</td>
                        <td>{{ data.soh }}</td>
                        <td>
                            <div *ngIf="showCost && data.cost !== 0 ">{{ data.cost }}</div>
                        </td>
                        <td>
                            <nz-input-number [ngModel]="data.qty" [nzMin]="1" [nzMax]="500" [nzStep]="1" style="width: 55px;" (ngModelChange)="updateLineItem(i,'QTY',$event)">
                            </nz-input-number>
                        </td>
                        <td>
                            <ng-template #toolTipTemplate>
                                <table>
                                    <tr>
                                        <td>
                                            R {{data.gpRandsHigh}}
                                        </td>
                                        <td>
                                            <div style="padding-left: 10px;">{{data.gpPercentHigh}}%</div>
                                        </td>
                                        <td>
                                            <button (click)="updateLineItemGP(data, data.gpRandsHigh,'+3')" class="btn">R {{
                                                    data.fiveHigh}}</button>
                                        </td>
                                    </tr>
                                    <tr style="border-top: lightgray 1px solid;">
                                        <td>
                                            R {{data.gpRands}}
                                        </td>
                                        <td>
                                            <nz-input-number [ngModel]="data.gpPercent" [nzMin]="1" [nzStep]="1" style="width: 75px;" (ngModelChange)="updateGpPercent(data, $event)" [nzFormatter]="percentFormatter"></nz-input-number>
                                        </td>
                                        <td>
                                            <button disabled class="btn">R {{ data.priceIncl }}</button>
                                        </td>
                                    </tr>
                                    <tr style="border-top: lightgray 1px solid;">
                                        <td>
                                            R {{data.gpRandsLow}}
                                        </td>
                                        <td>
                                            <div style="padding-left: 10px;">{{data.gpPercentLow}}%</div>
                                        </td>
                                        <td>
                                            <button (click)="updateLineItemGP(data, data.gpRandsLow,'-3')" class="btn">R {{
                                                    data.fiveLow}}</button>
                                        </td>
                                    </tr>
                                </table>

                            </ng-template>
                            <div nz-popover nzPopoverPlacement="leftTop" [nzPopoverContent]="toolTipTemplate" *ngIf="(data.stockType && data.stockType.toUpperCase() == 'TYRE'); else nonTyrePrice">
                                <nz-input-group [nzPrefix]="'R'" class="inputGroupNumber">
                                    <nz-input-number [ngModel]="data.priceIncl" [nzStep]="1" class="text-style" (ngModelChange)="updateLineItem(i,'PRICE',$event)">
                                    </nz-input-number>
                                </nz-input-group>
                            </div>
                            <ng-template #nonTyrePrice>
                                <nz-input-group [nzPrefix]="'R'" class="inputGroupNumber">
                                    <nz-input-number [ngModel]="data.priceIncl" [nzStep]="1" style="width: 100px;" (ngModelChange)="updateLineItem(i,'PRICE',$event)">
                                    </nz-input-number>
                                </nz-input-group>
                            </ng-template>
                        </td>
                        <ng-template #profitTooltip>
                            <span style="color:var(--textcolor)"><b>OPTION TOTAL</b></span><br /> R {{calcOptionPriceIncl(data).toFixed(2)}}<br /> GP: R{{calcOptionGPExcl(data).toFixed(2)}} <small style="color: var(--textcolor)">ex vat</small>

                        </ng-template>
                        <td>
                            <div nz-popover nzPopoverPlacement="rightTop" [nzPopoverContent]="profitTooltip" *ngIf="(data.stockType && data.stockType.toUpperCase() == 'TYRE'); else nonStockTotal">
                                R <b class="text-style">{{ data.totalPriceIncl }} </b>
                                <div class="smallText">
                                    <sup style="color: seagreen">{{ data.gpPercent }}</sup>
                                    <sub>{{ data.clientName }}</sub>
                                </div>
                            </div>
                            <ng-template #nonStockTotal>
                                R <b class="text-style">{{ data.totalPriceIncl }} </b>
                                <div class="smallText">
                                    <sup style="color: seagreen">{{ data.gpPercent }}</sup>
                                    <sub>{{ data.clientName }}</sub>
                                </div>
                            </ng-template>
                        </td>
                        <td>
                            <span *ngIf="this.isCompleted == false">
                                    <button class="btn btnremove" (click)="removeLineItem(i)">
                                        <i nz-icon nzType="minus" nzTheme="outline"  nz-tooltip nzTooltipTitle="Remove Item"></i>
                                    </button>
                                    <span *ngIf="data.stockType && data.stockType.toUpperCase() == 'TYRE'">
                                        <nz-divider nzType="vertical"></nz-divider>
                                        <button class="btn btnaccept" (click)="selectLineItem(i)">
                                            <i nz-icon nzType="check" nzTheme="outline"  nz-tooltip nzTooltipTitle="Select Item"></i>
                                        </button>
                                    </span>
                            </span>
                        </td>
                    </tr>
                    <tr *ngIf="this.isCompleted == false">
                        <td>
                            <input nz-input placeholder="Stock Code" [(ngModel)]="searchString" (keyup.enter)="searchStockInput()" class="submit trinput" style="width: 50%;text-transform: uppercase;" />
                        </td>
                        <td colspan="8">

                        </td>
                    </tr>
                </tbody>
            </nz-table>
        </div>
    </div>


    <!--Complete Section-->
    <div nz-row nzJustify="space-between" *ngIf="this.isCompleted == false">
        <div nz-col nzSpan="7">
            <div *ngIf="this.posQuote.quoteLines && this.posQuote.quoteLines.length > 0 && this.isWIP === false">
                <button nz-button class="btn submit float-left" style="height: 40px; font-size: 1rem;" (click)="tentativelyAccept()" [disabled]="posQuote.custId === 14041 || posQuote.custId === 0" [nz-tooltip]="posQuote.custId === 14041 || posQuote.custId === 0 ? 'Select Customer Details First!' : ''"
                    [nzLoading]="isSavingQuote" *ngIf="!authService.hasClaims(['WIP'])">
                        <i nz-icon nzType="clock-circle" nzTheme="fill"
                            style="font-size: 1.5rem; vertical-align: middle; margin-top: -6px;"></i> Tentatively Accept
                    </button>
                <button nz-button *ngIf="this.authService.user.bookingsEnabled === true" class="btn submit float-left" style="height: 40px; font-size: 1rem; margin-left: 10px;" (click)="onBookingClick()" [disabled]="posQuote.custId === 14041 || posQuote.custId === 0"
                    [nz-tooltip]="posQuote.custId === 14041 || posQuote.custId === 0 ? 'Select Customer Details First!' : posQuote.booking ? 'BOOKED: ' + posQuote.booking.dateBooked + ' ' + posQuote.booking.timeBooked : ''" [nzLoading]="isSavingQuote" [ngClass]="{'text-info-style' : posQuote.booking}">
                        <i nz-icon nzType="calendar" nzTheme="fill"
                            style="font-size: 1.5rem; vertical-align: middle; margin-top: -6px;"
                            ></i> {{posQuote.booking ? 'BOOKED: ' + posQuote.booking.dateBooked + ' ' + posQuote.booking.timeBooked.toString().padStart(2,"0").substring(0,5) : 'Booking'}}
                    
                    </button>
            </div>
            <div *ngIf="this.isWIP">
                <button nz-button class="btn submit float-left" style="height: 40px; font-size: 1rem;" (click)="cancelJobCard()" [disabled]="posQuote.custId === 14041 || posQuote.custId === 0" [nz-tooltip]="posQuote.custId === 14041 || posQuote.custId === 0 ? 'Select Customer Details First!' : ''"
                    [nzLoading]="isSavingQuote" *ngIf="this.isWIP">
                        <i nz-icon nzType="close-circle" nzTheme="fill"
                            style="font-size: 1.5rem; vertical-align: middle; margin-top: -6px;"></i> Cancel Jobcard
                    </button>
            </div>
        </div>
        <div nz-col nzSpan="13" *ngIf="authService.hasClaims(['WIP']) && authService.user.featureLevel >= 2">
            <div *ngIf="this.posQuote.quoteLines && this.posQuote.quoteLines.length > 0 && this.isWIP === false">
                <span style="font-size: 1rem;">Work In Progress:</span>
                <button nz-button class="btn submit text-center" style="height: 40px; font-size: 1rem; margin-left: 10px; border-bottom-right-radius: 0px; border-top-right-radius: 0px;" (click)="assignWIP(true)" [disabled]="posQuote.custId === 14041 || posQuote.custId === 0"
                    [nz-tooltip]="posQuote.custId === 14041 || posQuote.custId === 0 ? 'Select Customer Details First!' : posQuote.booking ? 'BOOKED: ' + posQuote.booking.dateBooked + ' ' + posQuote.booking.timeBooked : ''" [nzLoading]="isSavingQuote" [ngClass]="{'text-info-style' : posQuote.booking}">
                        <i nz-icon nzType="plus-circle" nzTheme="fill"
                            style="font-size: 1.5rem; vertical-align: middle; margin-top: -6px;"
                            ></i> 
                            Reserve Stock
                    </button>
                <button nz-button class="btn submit text-center" style="height: 40px; font-size: 1rem;border-bottom-left-radius: 0px; border-top-left-radius: 0px;" (click)="assignWIP(false)" [disabled]="posQuote.custId === 14041 || posQuote.custId === 0" [nz-tooltip]="posQuote.custId === 14041 || posQuote.custId === 0 ? 'Select Customer Details First!' : posQuote.booking ? 'BOOKED: ' + posQuote.booking.dateBooked + ' ' + posQuote.booking.timeBooked : ''"
                    [nzLoading]="isSavingQuote" [ngClass]="{'text-info-style' : posQuote.booking}">
                        <i nz-icon nzType="minus-circle" nzTheme="fill"
                            style="font-size: 1.5rem; vertical-align: middle; margin-top: -6px;"
                            ></i> 
                            Dont Reserve Stock
                    </button>
            </div>

        </div>
        <div nz-col nzSpan="13" *ngIf="!authService.hasClaims(['WIP']) || authService.user.featureLevel < 2">

        </div>
        <div nz-col nzSpan="4" *ngIf="(authService.hasClaims(['WIP']) && this.isWIP === true) || (!authService.hasClaims(['WIP']) || (authService.user.featureLevel < 2)); else noSaveTemplate">
            <button nz-button *ngIf="this.posQuote.quoteLines && this.posQuote.quoteLines.length > 0" class="btn submit float-right" style="height: 40px; font-size: 1rem;" (click)="completeSale()" [disabled]="posQuote.custId === 14041 || posQuote.custId === 0" [nz-tooltip]="posQuote.custId === 14041 || posQuote.custId === 0 ? 'Select Customer Details First!' : ''"
                [nzLoading]="isSavingQuote">
                    <i nz-icon nzType="check-circle" nzTheme="fill" 
                        style="font-size: 1.5rem; vertical-align: middle; margin-top: -6px;"></i><span *ngIf="!this.isWIP || authService.user.featureLevel < 3">Complete Sale</span><span *ngIf="this.isWIP && authService.user.featureLevel === 3">Invoice Customer</span>
                </button>
        </div>
        <ng-template #noSaveTemplate>
            <div nz-col nzSpan="4">

            </div>
        </ng-template>
    </div>
</div>


<!-- Modals -->
<nz-modal [(nzVisible)]="isSearchStockModalVisible" nzTitle="Stock Availability" (nzOnCancel)="isSearchStockModalVisible = false" [nzFooter]="null" [nzWidth]="'900px'">
    <div *nzModalContent>
        <nz-tabset>
            <nz-tab nzTitle="Local">
                <nz-table #searchTable1 [nzData]="filterSearchStock(1)" [nzLoading]="isLoadingStockSearch" [nzFrontPagination]="false" [nzBordered]="false" [nzOuterBordered]="false" nzSize="small" [nzNoResult]="stockSearchNoResults">
                    <thead>
                        <tr>
                            <th>Brand</th>
                            <th>Description</th>
                            <th>SOH</th>
                            <th>SLA</th>
                            <th>Supplier</th>
                            <th>Price</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of searchTable1.data">
                            <td><img src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{item.brandLogoUrl}}" class="brandImage" /></td>
                            <td>{{item.description}}</td>
                            <td>{{item.soh}}</td>
                            <td>{{item.sla}}</td>
                            <td>{{item.supplier}}</td>
                            <td>{{item.price}}</td>
                            <td>
                                <a (click)="selectSearchItem(item)">
                                    <i nz-icon nzType="check-circle" nzTheme="outline" style="font-size: 1.5rem; color: #136413;" nz-tooltip nzTooltipTitle="Select Item"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </nz-table>
            </nz-tab>
            <nz-tab nzTitle="Group">
                <nz-table #searchTable2 [nzData]="filterSearchStock(2)" [nzLoading]="isLoadingStockSearch" [nzFrontPagination]="false" [nzBordered]="false" [nzOuterBordered]="false" nzSize="small" [nzNoResult]="stockSearchNoResults">
                    <thead>
                        <tr>
                            <th>Brand</th>
                            <th>Description</th>
                            <th>SOH</th>
                            <th>SLA</th>
                            <th>Supplier</th>
                            <th>Price</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of searchTable2.data">
                            <td><img src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{item.brandLogoUrl}}" class="brandImage" /></td>
                            <td>{{item.description}}</td>
                            <td>{{item.soh}}</td>
                            <td>{{item.sla}}</td>
                            <td>{{item.supplier}}</td>
                            <td>{{item.price}}</td>
                            <td>
                                <a (click)="selectSearchItem(item)">
                                    <i nz-icon nzType="check-circle" nzTheme="outline" style="font-size: 1.5rem; color: #136413;" nz-tooltip nzTooltipTitle="Select Item"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </nz-table>
            </nz-tab>
            <nz-tab nzTitle="Supplier">
                <nz-table #searchTable3 [nzData]="filterSearchStock(3)" [nzLoading]="isLoadingStockSearch" [nzFrontPagination]="false" [nzBordered]="false" [nzOuterBordered]="false" nzSize="small" [nzNoResult]="stockSearchNoResults">
                    <thead>
                        <tr>
                            <th>Brand</th>
                            <th>Description</th>
                            <th>SOH</th>
                            <th>SLA</th>
                            <th>Supplier</th>
                            <th>Price</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of searchTable3.data">
                            <td><img src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{item.brandLogoUrl}}" class="brandImage" /></td>
                            <td>{{item.description}}</td>
                            <td>{{item.soh}}</td>
                            <td>{{item.sla}}</td>
                            <td>{{item.supplier}}</td>
                            <td>{{item.price}}</td>
                            <td>
                                <a (click)="selectSearchItem(item)">
                                    <i nz-icon nzType="check-circle" nzTheme="outline" style="font-size: 1.5rem; color: #136413;" nz-tooltip nzTooltipTitle="Select Item"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </nz-table>
            </nz-tab>
            <nz-tab nzTitle="NRS">
                <nz-table #searchTable4 [nzData]="filterSearchStock(4)" [nzLoading]="isLoadingStockSearch" [nzFrontPagination]="false" [nzBordered]="false" [nzOuterBordered]="false" nzSize="small" [nzNoResult]="stockSearchNoResults">
                    <thead>
                        <tr>
                            <th>Brand</th>
                            <th>Description</th>
                            <th>SOH</th>
                            <th>SLA</th>
                            <th>Supplier</th>
                            <th>Price</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of searchTable4.data">
                            <td><img src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{item.brandLogoUrl}}" class="brandImage" /></td>
                            <td>{{item.description}}</td>
                            <td>{{item.soh}}</td>
                            <td>{{item.sla}}</td>
                            <td>{{item.supplier}}</td>
                            <td>{{item.price}}</td>
                            <td>
                                <a (click)="selectSearchItem(item)">
                                    <i nz-icon nzType="check-circle" nzTheme="outline" style="font-size: 1.5rem; color: #136413;" nz-tooltip nzTooltipTitle="Select Item"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </nz-table>
            </nz-tab>
        </nz-tabset>
        <ng-template #stockSearchNoResults>
            <div style="text-align: center;">
                <i nz-icon nzType="warning" nzTheme="outline" class="text-warning-style " style="font-size: 4rem;"></i>
                <br />
                <b>NO STOCK FOUND</b>
            </div>
        </ng-template>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="isFullActionLogModalVisible" nzTitle="Action Log" (nzOnCancel)="isFullActionLogModalVisible = false" [nzFooter]="null" nzWidth="900px">
    <div *nzModalContent>
        <nz-table #fullLogTable [nzData]="actionLog" [nzLoading]="isLoadingLog" [nzBordered]="false" [nzOuterBordered]="false" nzSize="small" [nzFrontPagination]="false">
            <thead>
                <tr>
                    <th>User</th>
                    <th>Date</th>
                    <th>Description</th>
                    <th>Comment</th>
                    <th>From</th>
                    <th>To</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of fullLogTable.data; let i = index">
                    <td><small>{{data.name}}</small></td>
                    <td><small>{{datepipe.transform(data.dateActioned, 'dd MMM YYYY HH:mm')}}</small></td>
                    <td>
                        <small>{{data.description}}</small>
                    </td>
                    <td><small>{{data.comment}}</small></td>
                    <td><small>{{data.valuePrev}}</small></td>
                    <td><small>{{data.valueNew}}</small></td>
                </tr>
            </tbody>
        </nz-table>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="isNavModalVisible" nzTitle="Update Status" (nzOnCancel)="isNavModalVisible = false; navPathAway='';" [nzFooter]="null" nzWidth="800px">
    <div *nzModalContent>
        <!-- NAV AWAY WITHOUT CHANING STATE -->
        <div *ngIf="isNavAwayVisible">
            <div class="row mb-5">
                <div class="col-md-6">
                    <button class="btn" (click)="navigateWithoutSaving()"><i nz-icon nzType="left-circle" nzTheme="fill"
                            style="vertical-align: top; margin-right: 4px; font-size: 1.5rem;"></i>Just Checking,
                        Continue</button>
                </div>
                <div class="col-md-6" *ngIf="quoteDirty">
                    <button class="btn" (click)="navigateWithSaving()"><i nz-icon nzType="save" nzTheme="fill"
                            style="vertical-align: top; margin-right: 4px; font-size: 1.5rem;"></i>Save Quote and
                        Continue</button>
                </div>
            </div>
            <!-- <div class="row">
                <div class="col-12">
                    <span style="background-color: black; border-top-left-radius: 20px; border-top-right-radius: 20px; 
                    padding-top: 15px; padding-left: 15px; padding-right: 15px; padding-bottom: 10px;">DON'T CHANGE STATE</span>
                </div>
            </div>
            <div class= "mb-4" style="border: 2px solid black; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; border-top-right-radius: 20px; padding: 15px; margin-top: 8px;">
            
                <div class="row mb-2" style="padding-left: 20px;">
                    <div class="col-md-6">
                        <button class="btn" (click)="navigateWithoutSaving()"><i nz-icon nzType="right-circle" nzTheme="fill" style="vertical-align: top; margin-right: 4px; font-size: 1.5rem;"></i>Just Checking, Continue</button>
                    </div>
                    <div class="col-md-6" *ngIf="quoteDirty">
                        <button class="btn" (click)="navigateWithSaving()"><i nz-icon nzType="save" nzTheme="fill" style="vertical-align: top; margin-right: 4px; font-size: 1.5rem;"></i>Save Quote and Continue</button>
                    </div>
                </div>

                
            </div> -->
        </div>

        <!-- FOLLOW UP -->
        <div class="row" *ngIf="!this.isWIP">
            <div class="col-12">
                <span style="background-color: black; border-top-left-radius: 20px; border-top-right-radius: 20px; 
                padding-top: 15px; padding-left: 15px; padding-right: 15px; padding-bottom: 10px;">FOLLOW UP LATER</span>
            </div>
        </div>
        <div *ngIf="!this.isWIP" style="border: 2px solid black; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; border-top-right-radius: 20px; padding: 15px; margin-top: 8px;">
            <div class="row mb-2">
                <div class="col-12">
                    <div class="input-group text-center">
                        <input [(ngModel)]="statusComment" class=" form-control txtareaLeft" placeholder="Add Comment" [maxLength]="450" />
                        <div class="input-group-append ">
                            <span class="btn btn-sm" style="color: grey;"><small>{{statusComment.length}}/450</small></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-12 mb-2">
                    Follow Up On:

                    <nz-date-picker [nzDisabledDate]="disabledDate" nzShowTime nzFormat="yyyy-MM-dd HH:mm" nzAllowClear="false" [(ngModel)]="followUpDate" nzPlaceHolder="Select Date" #followUpDatePicker (nzOnOk)="updateQuoteStateWithAction(PosAuditEntryType.FollowUpLater)"
                        class="txtareaDatePicker">
                    </nz-date-picker>
                    <!-- <span class="text-style" style="margin-left: 10px;"><i>({{ getDateDiff() }})</i></span>  -->
                </div>

            </div>

            <div class="row">
                <div class="col-sm-2 mb-2">
                    <button nz-button class="btn" (click)="followUpAddMinutes(15)" style="border-color: var(--textcolor);" [autofocus]="true">
                        In 15 mins
                    </button>
                </div>
                <div class="col-sm-2 mb-2">
                    <button nz-button class="btn" (click)="followUpAddHours(1)" style="border-color: var(--textcolor);">
                        In 1 hour
                    </button>
                </div>
                <div class="col-sm-2 mb-2">
                    <button nz-button class="btn" (click)="followUpAddHours(2)" style="border-color: var(--textcolor);">
                        In 2 hours
                    </button>
                </div>
                <div class="col-sm-2 mb-2">
                    <button nz-button class="btn" (click)="followUpLunch()" style="border-color: var(--textcolor);" [disabled]="checkLunchDisabled()">
                        Lunch Time
                    </button>
                </div>
                <div class="col-sm-2 mb-2">
                    <button nz-button class="btn" (click)="followUpAddDays(1)" style="border-color: var(--textcolor);">
                        Tomorrow
                    </button>
                </div>
                <div class="col-sm-2 mb-2">
                    <button nz-button class="btn" (click)="followUpAddDays(2)" style="border-color: var(--textcolor);">
                        In 2 days
                    </button>
                </div>

            </div>
        </div>
        <!-- CUSTOMER ACTIONS -->
        <div class="row mt-4">
            <div class="col-12">
                <span style="background-color: black; border-top-left-radius: 20px; border-top-right-radius: 20px; 
                padding-top: 15px; padding-left: 15px; padding-right: 15px; padding-bottom: 10px;">CLOSE LEAD</span>
            </div>
        </div>
        <div style="border: 2px solid black; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; border-top-right-radius: 20px; padding: 15px; margin-top: 8px;">
            <div class="row mb-2">
                <div class="col-sm-4 mb-2">
                    <button class="btn" (click)="updateQuoteStateWithAction(PosAuditEntryType.NotReadyToBuy, false)">
                        Not Ready To Buy
                    </button>
                </div>
                <div class="col-sm-4 mb-2">
                    <button class="btn" (click)="updateQuoteStateWithAction(PosAuditEntryType.IncorrectCustomerDetails, false)">
                        Incorrect Contact Details
                    </button>
                </div>
                <div class="col-sm-4 mb-2">
                    <button class="btn" (click)="updateQuoteStateWithAction(PosAuditEntryType.BoughtFromCompetitor, false)">
                        Bought From Competitor
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <button class="btn" style="border-top-right-radius: 0px; border-bottom-right-radius: 0px; margin-top: -6px;">
                        OTHER:
                    </button>

                    <input [(ngModel)]="statusComment" class="txtareaMiddle" placeholder="Add Comment" [maxLength]="450" />

                    <button class="btn" style="border-top-left-radius: 0px; border-bottom-left-radius: 0px; margin-top: -6px;" (click)="updateQuoteStateWithAction(PosAuditEntryType.ClosedOther)">
                        <i nz-icon nzType="save" nzTheme="fill"
                            style="vertical-align: top; margin-right: 4px; font-size: 1.5rem;"></i>Save
                    </button>
                </div>
            </div>
        </div>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="isEmailQuoteModalVisible" nzTitle="Email Quote To" (nzOnCancel)="isEmailQuoteModalVisible = false" [nzFooter]="null" nzWidth="700px">
    <div *nzModalContent>

        <nz-input-group nzPrefixIcon="mail">
            <input nz-input [(ngModel)]="emailAddressTo" placeholder="Email Address">
        </nz-input-group>
        <button class="btn" style="border:none; width: 200px; font-size: 1rem;height: 40px; margin-top: 30px;" (click)="emailQuote()" nz-button [nzLoading]="isEmailingQuote">
            <i nz-icon nzType="mail" nzTheme="fill"
                style="vertical-align: middle; margin-top: -5px; font-size: 1.5rem;"></i>
            Email Quote
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="isBookingModalVisible" nzTitle="Book Fitment Slot" (nzOnCancel)="isBookingModalVisible = false" [nzFooter]="null" nzWidth="700px">

    <div *nzModalContent>
        <div nz-row [nzJustify]="'center'">
            <div nz-col nzXs="24" nzSm="24" nzMd="12">
                <div [ngStyle]="{ width: '300px', border: '1px solid #d9d9d9', borderRadius: '4px' }">
                    <nz-calendar [nzFullscreen]="false" (nzSelectChange)="onBookingDateSelected($event)" class="customCalendar"></nz-calendar>
                </div>
            </div>
            <div nz-col nzXs="24" nzSm="24" nzMd="12">
                <nz-spin [nzSpinning]="isLoadingBookingSlots">
                    <div class="text-info-style" style="margin-bottom: 10px;">Date: {{bookingSelectedDate}}</div>
                    <a (click)="onSlotSelected(i)" style="text-decoration: none;" *ngFor="let i of bookingSlots">
                        <div class="timeSlot">Time: {{i.slotTime.toString().padStart(2,"0").substring(0,5)}}<small class="float-right"><i>{{i.bookings}} bookings</i></small></div>
                    </a>
                    <div *ngIf="bookingSlots.length === 0" style="padding-top: 20px; text-align: center;">
                        <i nz-icon nzType="minus-circle" nzTheme="fill" style="color: darkred; font-size: 3rem; padding-bottom: 20px;"></i>

                        <div class="text-info-style" style="text-align: center;">No Time Slots</div>
                    </div>
                </nz-spin>


            </div>
        </div>

    </div>
</nz-modal>

<nz-modal [(nzVisible)]="isAddCustomStockModalVisible" nzTitle="Add New Custom Stock Item" (nzOnCancel)="isAddCustomStockModalVisible = false" [nzFooter]="null" nzWidth="700px">
    <div *nzModalContent>
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-6 offset-3">
                    <form nz-form nzTitle="Create new custom stock item">
                        <nz-form-item>
                            <label>Description</label>
                            <nz-input-group nzPrefixIcon="file-text">
                                <input [(ngModel)]="desc" name="desc" value="{{desc}}" nz-input placeholder="Description" style="width: 50%;" autocomplete="off" />
                            </nz-input-group>
                        </nz-form-item>
                        <nz-form-item>
                            <label>Stock Code</label>
                            <nz-input-group nzPrefixIcon="barcode">
                                <input [(ngModel)]="code" name="code" value="{{code | uppercase}}" nz-input placeholder="Stock Code" style="width: 50%;text-transform: uppercase;" autocomplete="off" />
                            </nz-input-group>
                        </nz-form-item>
                        <nz-form-item>
                            <label>Price (Incl VAT)</label>
                            <nz-input-group nzPrefixIcon="money-collect">
                                <input [(ngModel)]="price" name="value" value="{{price}}" nz-input placeholder="Price" type="number" min="0" style="width: 50%;" autocomplete="off" />
                            </nz-input-group>
                        </nz-form-item>
                        <nz-form-item>
                            <span><label  style="display: block;">Stock Type</label>  </span>
                        </nz-form-item>
                        <nz-form-item>
                            <input [(ngModel)]="selectedType" name="selectedType" value="{{selectedType}}" nz-dropdown [nzDropdownMenu]="menu" placeholder="Type" style="width: 50%; text-align: center;padding:10px;border-radius:10px" autocomplete="off" />
                            <nz-dropdown-menu #menu="nzDropdownMenu">
                                <ul nz-menu nzSelectable>
                                    <li *ngFor="let item of stockTypes" nz-menu-item value="{{item.stockTypeId}}" (click)="assignType(item.stockDescription)">{{item.stockDescription}}</li>
                                </ul>
                            </nz-dropdown-menu>
                        </nz-form-item>
                    </form>
                    <button class="btn" (click)="submitCustomStock()"><i nz-icon nzType="save" nzTheme="fill" 
                    style="vertical-align: top; margin-right: 4px; font-size: 1.5rem;"></i>Save</button>
                </div>
            </div>
        </div>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="isWbcModal" nzTitle="Send WBC Vehicle Details" (nzOnCancel)="isWbcModal = false" [nzFooter]="null" nzWidth="700px">
    <div *nzModalContent>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <form nz-form [formGroup]="wbcForm" nzTitle="Vehicle Details" class="text-center">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-6">
                                    <label>Name</label><br>
                                    <nz-form-item>
                                        <nz-form-control [nzErrorTip]="'Customer Name is required'">
                                            <div class="input-group maindiv">
                                                <span class="input-group-prepend">
                                                    <div class="input-group-text bg-transparent">
                                                    <i nz-icon nzType="user" nzTheme="outline"></i>
                                                </div>
                                                </span>
                                                <input class="form-control text-center form-control-custom" name="name" formControlName="name" nz-input placeholder="Mike" autocomplete="off" />
                                            </div>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                                <div class="col-6">
                                    <label>Surname</label><br>
                                    <nz-form-item>
                                        <nz-form-control [nzErrorTip]="'Customer Surname is required'">
                                            <div class="input-group maindiv">
                                                <span class="input-group-prepend">
                                                    <div class="input-group-text bg-transparent">
                                                   <i nz-icon nzType="user" nzTheme="outline"></i>
                                                </div>
                                                </span>
                                                <input class="form-control text-center form-control-custom" name="surname" formControlName="surname" nz-input placeholder="Coxsmall" autocomplete="off" />
                                            </div>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <label>Cell</label><br>
                                    <nz-form-item>
                                        <nz-form-control [nzErrorTip]="'Customer Cell is required'">
                                            <div class="input-group maindiv">
                                                <span class="input-group-prepend">
                                                    <div class="input-group-text bg-transparent">
                                                        <i nz-icon nzType="phone" nzTheme="outline"></i>
                                                </div>
                                                </span>
                                                <input class="form-control text-center form-control-custom" name="cell" formControlName="cell" nz-input placeholder="082474..." autocomplete="off" />
                                            </div>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                                <div class="col-6">
                                    <label>Email</label><br>
                                    <nz-form-item>
                                        <nz-form-control [nzErrorTip]="'Customer Email is required'">
                                            <div class="input-group maindiv">
                                                <span class="input-group-prepend">
                                                    <div class="input-group-text bg-transparent">
                                                        <i nz-icon nzType="inbox" nzTheme="outline"></i>
                                                </div>
                                                </span>
                                                <input class="form-control text-center form-control-custom" name="email" formControlName="email" nz-input placeholder="jones@gmail.com" autocomplete="off" />
                                            </div>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6 text-center offset-3">
                                    <label>Milage</label><br>
                                    <nz-form-item>
                                        <nz-form-control [nzErrorTip]="'Vehicle milage is required'">
                                            <div class="input-group maindiv">
                                                <span class="input-group-prepend">
                                                    <div class="input-group-text bg-transparent">
                                                        <i nz-icon nzType="inbox" nzTheme="outline"></i>
                                                </div>
                                                </span>
                                                <input class="form-control text-center form-control-custom" name="vehicleMilage" formControlName="vehicleMilage" nz-input placeholder="100000" autocomplete="off" />
                                            </div>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <label>Condition </label><br>
                                    <nz-form-item>
                                        <nz-form-control [nzErrorTip]="'Vehicle Condition is required'">
                                            <div class="input-group maindiv">
                                                <span class="input-group-prepend">
                                                </span>
                                                <nz-select name="vehicleCondition" formControlName="vehicleCondition" (ngModelChange)="onConditionSelect($event)" [(ngModel)]="vehicleConditionselect" class="form-control text-center" nzShowSearch [nzPlaceHolder]="'Vehicle Condition'" [nzShowArrow]="false"
                                                    nzLayout="inline" nzAllowClear>
                                                    <nz-option *ngFor="let condition of vehicleCondition" [nzValue]="condition" [nzLabel]="condition"></nz-option>
                                                </nz-select>
                                            </div>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>

                                <div class="col-4">
                                    <label>Make</label><br>
                                    <nz-form-item>
                                        <nz-form-control [nzErrorTip]="'Vehicle Make is required'">
                                            <div class="input-group maindiv">
                                                <span class="input-group-prepend">
                                                </span>
                                                <nz-select name="vehicleMake" formControlName="vehicleMake" (ngModelChange)="onSearchVmake($event)" [(ngModel)]="vehicleMake" class="form-control text-center" nzShowSearch [nzPlaceHolder]="'Vehicle Make'" [nzShowArrow]="false" nzLayout="inline" nzAllowClear>
                                                    <nz-option *ngFor="let option of VehicleMakes" [nzValue]="option.vehicleMakeId" [nzLabel]="option.vehicleMake"></nz-option>
                                                </nz-select>
                                            </div>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>

                                <div class="col-4">
                                    <label>Year</label><br>
                                    <nz-form-item>
                                        <nz-form-control [nzErrorTip]="'Vehicle Year is required'">
                                            <div class="input-group maindiv">
                                                <span class="input-group-prepend">
                                                    <div class="input-group-text bg-transparent">
                                                   <i nz-icon nzType="user" nzTheme="outline"></i>
                                                </div>
                                                </span>
                                                <input class="form-control text-center form-control-custom" name="vehicleYear" formControlName="vehicleYear" placeholder="2022" nz-input autocomplete="off" />
                                            </div>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <label>Model</label><br>
                                    <nz-form-item>
                                        <nz-form-control [nzErrorTip]="'Vehicle Model is required'">
                                            <div class="input-group maindiv">
                                                <span class="input-group-prepend">
                                                </span>
                                                <nz-select name="vehicleModel" formControlName="vehicleModel" (ngModelChange)="onSearchVmodels($event)" [(ngModel)]="vehicleModel" class="form-control text-center" nzShowSearch [nzPlaceHolder]="'Vehicle Model'" [nzShowArrow]="false" nzLayout="inline"
                                                    nzAllowClear>
                                                    <nz-option *ngFor="let option of VehicleModels" [nzValue]="option.vehicleModelId" [nzLabel]="option.vehicleModel"></nz-option>
                                                </nz-select>
                                            </div>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                                <div class="col-6">
                                    <label>Variant</label><br>
                                    <nz-form-item>
                                        <nz-form-control [nzErrorTip]="'Vehicle Variant is required'">
                                            <div class="input-group maindiv">
                                                <span class="input-group-prepend">
                                                        </span>
                                                <nz-select name="vehicleVariant" formControlName="vehicleVariant" (ngModelChange)="onSearchVvariant($event)" [(ngModel)]="vehicleVariant" class="form-control text-center" nzShowSearch [nzPlaceHolder]="'Vehicle Variant'" [nzShowArrow]="false" nzLayout="inline"
                                                    nzAllowClear>
                                                    <nz-option *ngFor="let option of VehicleVariants" [nzValue]="option.vehicleVariantId" [nzLabel]="option.vehicleVariant"></nz-option>
                                                </nz-select>
                                            </div>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                        </div>
                        <button class="btn btn-orange" (click)="sendWBCquote()">
                            Send To WBC
                            <i nz-icon nzType="field-time" nzTheme="outline" style="vertical-align: top; margin-right: 4px; font-size: 1.5rem;"></i>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="isAddVehicleModal" nzTitle="Add New Customer Vehicle" (nzOnCancel)="isAddVehicleModal = false" [nzFooter]="null" nzWidth="700px">
    <div *nzModalContent>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <form nz-form [formGroup]="addVehicle" nzTitle="Vehicle Details" class="text-center">
                        <div class="row row-height-customer">
                            <div class="col-8 offset-2">
                                <nz-form-item>
                                    <nz-form-control [nzErrorTip]="'Vehicle Registration Required'">
                                        <div class="input-group maindiv">
                                            <span class="input-group-prepend">
                                                <div class="input-group-text bg-transparent">
                                                    <i nz-icon nzType="car" nzTheme="outline"></i>
                                            </div>
                                            </span>
                                            <input style="text-transform: uppercase;" nz-input name="v_reg" formControlName="v_reg" placeholder="Registration" class="form-control text-center form-control-custom">
                                        </div>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                        <div class="row row-height-customer">
                            <div class="col-6">
                                <nz-form-item>
                                    <nz-form-control>
                                        <div class="input-group maindiv">
                                            <span class="input-group-prepend">
                                                <div class="input-group-text bg-transparent">
                                                    <i nz-icon nzType="calendar" nzTheme="outline"></i>
                                                </div>
                                            </span>
                                            <input nz-input name="vehicleYear" formControlName="vehicleYear" [(ngModel)]="vehicleYear" placeholder=" Vehicle Year" class="form-control text-center form-control-custom">
                                        </div>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                            <div class="col-6">
                                <nz-form-item>
                                    <nz-form-control>
                                        <div class="input-group maindiv">
                                            <span class="input-group-prepend">
                                                <div class="input-group-text bg-transparent">
                                                    <span nz-icon nzType="sf:speedoMeterSVG" nzTheme="outline" ></span>
                                        </div>
                                        </span>
                                        <input nz-input name="vehicleMilage" formControlName="vehicleMilage" placeholder="Vehicle Milage" class="form-control text-center form-control-custom">
                            </div>
                            </nz-form-control>
                            </nz-form-item>
                        </div>
                </div>
                </form>
                <div class="container-fluid">
                    <div class="row row-height-customer">
                        <div class="col-6">
                            <div class="input-group maindiv">
                                <span class="input-group-prepend">
                                    </span>
                                <nz-select (ngModelChange)="onSearchVmake($event)" [(ngModel)]="vehicleMake" class="form-control text-center" nzShowSearch [nzPlaceHolder]="'Vehicle Make'" [nzShowArrow]="false" nzLayout="inline" nzAllowClear>
                                    <nz-option *ngFor="let option of VehicleMakes" [nzValue]="option.vehicleMakeId" [nzLabel]="option.vehicleMake"></nz-option>
                                </nz-select>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="input-group maindiv">
                                <span class="input-group-prepend">
                                    </span>
                                <nz-select (ngModelChange)="onSearchVmodels($event)" [(ngModel)]="vehicleModel" class="form-control text-center" nzShowSearch [nzPlaceHolder]="'Vehicle Model'" [nzShowArrow]="false" nzLayout="inline" nzAllowClear>
                                    <nz-option *ngFor="let option of VehicleModels" [nzValue]="option.vehicleModelId" [nzLabel]="option.vehicleModel"></nz-option>
                                </nz-select>
                            </div>
                        </div>
                    </div>
                    <div class="row row-height-customer">
                        <div class="col-12">
                            <div class="input-group maindiv">
                                <span class="input-group-prepend">
                                            </span>
                                <nz-select (ngModelChange)="onSearchVvariant($event)" [(ngModel)]="vehicleVariant" class="form-control text-center" nzShowSearch [nzPlaceHolder]="'Vehicle Variant'" [nzShowArrow]="false" nzLayout="inline" nzAllowClear>
                                    <nz-option *ngFor="let option of VehicleVariants" [nzValue]="option.vehicleVariantId" [nzLabel]="option.vehicleVariant"></nz-option>
                                </nz-select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-center">
                            <button class="btn" (click)="addVehicleCheck()">
                                    Add vehicle
                                    <i nz-icon nzType="field-time" nzTheme="outline" style="vertical-align: top; margin-right: 4px; font-size: 1.5rem;"></i>
                                </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</nz-modal>