<div class="container-fluid col-lg-11  bg-1">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-3">
                <p></p>
                <p></p>
                <button nz-button nzType="default" (click)="openCustomer()">
                    <i nz-icon nzType="user" nzTheme="outline"></i>
                    {{ this.customer.name | titlecase }}'s History
                </button>
            </div>
            <div class="col-lg-9 text-left ">
                <p></p>
                <p></p>
                <p class="offset-2" style="font-size: 14px;">
                    This page allows you Update Details For
                    <ng-container *ngIf="customer">
                        <b><span class="text-style">{{ this.customer.name }}</span></b>
                    </ng-container>
                </p>
            </div>
        </div>
    </div>
    <br>
    <nz-spin [nzSpinning]="isLoading">
        <nz-tabset nzCentered nzSize="large">
            <nz-tab nzTitle="Details">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-4 text-center offset-4">
                            <form nz-form [formGroup]="UpdateCustomerForm">
                                <nz-form-item>
                                    <nz-form-control [nzErrorTip]="'Customer Name Required'">
                                        <label class="float-left">Name</label>
                                        <div class="input-group maindiv">

                                            <input nz-input name="custName" formControlName="custName" placeholder="Customer Name">
                                        </div>
                                    </nz-form-control>
                                </nz-form-item>
                                <nz-form-item>
                                    <nz-form-control nzErrorTip="Valid or empty email required">
                                        <label class="float-left">Email</label>
                                        <div class="input-group maindiv">

                                            <input nz-input name="custEmail" formControlName="custEmail" placeholder="Email Address">
                                        </div>
                                    </nz-form-control>
                                </nz-form-item>
                                <nz-form-item>
                                    <nz-form-control [nzErrorTip]="'Valid Customer Cell Required'">
                                        <label class="float-left">Cell</label>
                                        <div class="input-group maindiv">

                                            <input nz-input name="custCell" formControlName="custCell" placeholder="Cell Number">
                                        </div>
                                    </nz-form-control>
                                </nz-form-item>
                                <nz-form-item>
                                    <nz-form-control>
                                        <label class="float-left">Vat No</label>
                                        <div class="input-group maindiv">

                                            <input nz-input name="custVATNumber" formControlName="custVATNumber" placeholder="VAT No">
                                        </div>
                                    </nz-form-control>
                                </nz-form-item>
                                <nz-form-item>
                                    <nz-form-control>
                                        <label class="float-left">Debtor Acc</label>
                                        <div class="input-group maindiv">

                                            <input nz-input name="custDebtorAcc" formControlName="custDebtorAcc" placeholder="Debtor Account">
                                        </div>
                                    </nz-form-control>
                                </nz-form-item>
                                <button nz-button nzType="primary" (click)="updateCustomer()">Update</button>
                                <br>
                            </form>
                        </div>
                    </div>
                </div>
            </nz-tab>
            <nz-tab nzTitle="Vehicles" *ngIf="this.showVehicles">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-3">
                            <button nz-button nzType="default" (click)="onVehiclesUpdate()">Save Vehicles</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div *ngFor="let item of this.customer.vehicles">
                                <nz-form-item>
                                    <nz-form-control>
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-6">
                                                    <label class="text-style">Registration</label>
                                                    <p hidden> {{item.Id}}</p>
                                                    <input style="text-transform: uppercase;" (change)="changeVreg($event.target)" value="{{item.vreg}}" nz-input name="name" placeholder="Vreg">
                                                </div>
                                                <div class="col-6">
                                                    <label class="text-style">Tyre Size</label>
                                                    <p hidden> {{item.Id}}</p>
                                                    <input style="text-transform: uppercase;" (change)="changeSize($event.target)" value="{{item.size}}" nz-input name="name" placeholder="Size">
                                                </div>
                                            </div>
                                        </div>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                    </div>
                </div>
            </nz-tab>
        </nz-tabset>
    </nz-spin>
</div>