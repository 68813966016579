import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Lead, LeadQuote, WIPLead } from '../models/leads';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LeadsService {
  apiUrl = 'v1/leads/';

  constructor(private http: HttpClient) { }

  leads: Lead[] = [];
  leadQuotes: LeadQuote[] = [];
  isLoadingLeads = false;
  isLoadingQuotes  = false;

  //This will be used to store if we are busy updating a lead. Once we are done updating, we will emit a new value to the BehaviorSubject
  public updatingLeads: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  //FILTERS
  filterAssignedToMe: boolean;
  filterAssignedToOther: boolean;
  filterUnassigned: boolean;
  filterOnlyTentativelyAccepted: boolean;
  filterOnlyBookings: boolean;

  public filterLeads(assignedTo: number): Lead[] {    
    let retVal: Lead[] = [];
    retVal = this.filterAssignedToMe ? retVal.concat(this.leads.filter(e => e.userId === assignedTo)) : retVal;
    retVal = this.filterAssignedToOther ? retVal.concat(this.leads.filter(e => e.userId && e.userId !== assignedTo)) : retVal;
    retVal = this.filterUnassigned ? retVal.concat(this.leads.filter(e => !e.userId || e.userId === 0)): retVal;
    //if empty, add everything
    if (retVal.length === 0)
      retVal = retVal.concat(this.leads);
    //return this.leads.filter(e => e.userId === assignedTo || !this.filterAssignedToMe)
      //.filter(e => (e.userId && e.userId !== assignedTo)
    return retVal.filter(e => e.bookingDate || !this.filterOnlyBookings).filter(e => e.statusId===18 || !this.filterOnlyTentativelyAccepted);
  }

  public filterQuotes(assignedTo: number): LeadQuote[] {    
    let retVal: LeadQuote[] = [];
    retVal = this.filterAssignedToMe ? retVal.concat(this.leadQuotes.filter(e => e.userId === assignedTo)) : retVal;
    retVal = this.filterAssignedToOther ? retVal.concat(this.leadQuotes.filter(e => e.userId && e.userId !== assignedTo)) : retVal;
    retVal = this.filterUnassigned ? retVal.concat(this.leadQuotes.filter(e => !e.userId || e.userId === 0)): retVal;
    //if empty, add everything
    if (retVal.length === 0)
      retVal = retVal.concat(this.leadQuotes);
    //return this.leads.filter(e => e.userId === assignedTo || !this.filterAssignedToMe)
      //.filter(e => (e.userId && e.userId !== assignedTo)
    return retVal.filter(e => e.bookingDate || !this.filterOnlyBookings).filter(e => e.statusId===18 || !this.filterOnlyTentativelyAccepted);
  }

  //Get Client Leads
  public getLeads()
  {
    return this.http.get( environment.ApiUrl() + this.apiUrl + "getleads");
  }

  public getLeadsList()
  {
    return this.http.get<Lead[]>(environment.ApiUrl() + this.apiUrl + "leads")
  }

  public getWorkInProgress()
  {
    return this.http.get<WIPLead[]>(environment.ApiUrl() + this.apiUrl + "wip")
  }

  public getLeadQuoteList()
  {
    return this.http.get<LeadQuote[]>(environment.ApiUrl() + this.apiUrl + "quotes")
  }

  //Get Client Leads
  public getLeadschart(tf: number = -7, chnl: string = 'All')
  {
    return this.http.get( environment.ApiUrl() + this.apiUrl + "leadschart/" + tf + '/' + chnl);
  }
  

    //Get OrderLines
  public getQuotelines(quoteId)
  {
    return this.http.get(environment.ApiUrl() + this.apiUrl + "quotelines/" + quoteId );
  }

  //get the count of unassgined leads
  public getUnseenLeadsCount() {
    return this.http.get<{count: number}>(environment.ApiUrl() + this.apiUrl + "unseenCount");
  }

}
