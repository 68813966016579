import { Component, OnInit } from '@angular/core';
import { ProcurementService } from './procurement.service';
import { StockModelsService} from '../model-stock-analysis/model-stock-analysis.service';
import { StockModelsService_Replenishment } from '../stock-models/stock-models.service';
import { Client_Model,Replenishment_Line,Client_Connection,clients_without_code } from '../models/procurement';
import { NotificationService } from '../shared/notification.service';
import { Client, ClientData } from '../models/client';
import { NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown';

@Component({
  selector: 'app-procurement-maintenance',
  templateUrl: './procurement-maintenance.component.html',
  styleUrls: ['./procurement-maintenance.component.less']
})
export class ProcurementMaintenanceComponent implements OnInit{


  constructor(private procServ: ProcurementService,private repService:StockModelsService_Replenishment,private mslService:StockModelsService,private alerts:NotificationService){}

  innerHeight = 0;
  scrollHeight = '';
  Client_Data: Client_Model[] =[]
  isLoading = false;
  view = "client_list";
  ngOnInit(): void {
    this.isLoading = true;
    this.innerHeight =  window.innerHeight;
    this.scrollHeight = (this.innerHeight*0.80)+"px";
    this.procServ.get_all_replenishment_clients().subscribe((res:Client_Model[]) =>{
      this.Client_Data = res;
      this.isLoading = false;
    },error =>{
      this.alerts.showWarning(error)
      this.isLoading = false;
    })
  }

  Client_Replenishment_Lines: Replenishment_Line[] =[]
  clients_with_no_match: clients_without_code[] = []
  Client_Viewed: Client_Model
  view_client_modelStock(client:Client_Model){
    this.view = client.account.buyer_name;
    this.Client_Viewed = client;
  }

  codeSearch_Results: Replenishment_Line[] = []
  codeSearched = ''
  msfid_match  = 0
  description_match = ''
  supplier_sohMatch = -1
  brand_logoMatch = ''
  brand_Match = ''
  viewSearchResults = false;
  fsBulkEdit = '12px'; //table fontsize
  scanforcode(){
    // this.bulkEdit = false;
    let code = (document.getElementById("stockSearch") as HTMLInputElement).value;
    this.codeSearched = code;
    this.msfid_match = 0;
    this.description_match = '';
    this.supplier_sohMatch = -1;
    this.brand_logoMatch = '';
    this.brand_Match = '';
    this.codeSearch_Results = []
    this.clients_with_no_match = []
    this.Client_Data.forEach(client => {
      let match = false;
      for(let n = 0; n< client.replenishment_lines.length;n++){
        if(client.replenishment_lines[n].stock_code == code){
          this.codeSearch_Results.push(client.replenishment_lines[n]); 
          match = true; 
          if(this.msfid_match == 0){ this.msfid_match = client.replenishment_lines[n].msfid;}
          if(this.description_match == ''){ this.description_match = client.replenishment_lines[n].description;}
          if(this.supplier_sohMatch == -1){ this.supplier_sohMatch = client.replenishment_lines[n].supplier_soh;}
          if(this.brand_Match == ''){ 
            this.brand_logoMatch = client.replenishment_lines[n].brand_logo;
            this.brand_Match = client.replenishment_lines[n].brand;
          }
        }
         
      }
      if (match == false){
        let newC = new  clients_without_code
        newC.clientId = client.account.buyerId;
        newC.client_name = client.account.buyer_name;
        newC.supplierId = client.account.sellerId;
        newC.supplier_name = client.account.seller_name;
        this.clients_with_no_match.push(newC);
      }
    });

    if(this.codeSearch_Results.length == 0){
      this.alerts.showInfo("No results found for stock code: "+code);
    }

    let sorted_clients = this.clients_with_no_match.sort((a, b) => (a.client_name < b.client_name) ? -1 : 1);
    this.clients_with_no_match = sorted_clients;
    this.viewSearchResults = true;
    // this.updateAllRepsItems_input(4);
    this.bulkEdit = true;

  }
  bulkEdit = false;
  start_BulkEdit(){
    this.bulkEdit = true;
  }
  updateRepItem(index: number, center: string, val: number){
    // this.codeSearch_Results[index].updated_msl = val;
    for(var x =0; x<this.codeSearch_Results.length;x++){
      if(this.codeSearch_Results[x].client_name == center){
        this.codeSearch_Results[x].updated_msl = val;
      }
    }
  }
  updateRepItem_status(index: number,center:string, val: number){
    // this.codeSearch_Results[index].updated_holding_status = val;
    for(var x =0; x<this.codeSearch_Results.length;x++){
      if(this.codeSearch_Results[x].client_name == center){
        this.codeSearch_Results[x].updated_holding_status = val;
      }
    }
  }

  updateAllRepsItems_input(val:number){
    for(var x = 0; x < this.codeSearch_Results.length;x++){
      this.codeSearch_Results[x].updated_msl = val;
    }
  }
  updateAllStatusItems_input(val:number){
    for(var x = 0; x < this.codeSearch_Results.length;x++){
      this.codeSearch_Results[x].updated_holding_status = val;
    }
  }
  bulk_msl = 0;
  submit_bulk_updates(){
    this.isLoading = true;
    this.procServ.submit_bulk_msl_update(this.codeSearch_Results).subscribe((res:any) =>{
      if (res.msg == ''){
        this.codeSearch_Results = res.lines;
        this.codeSearch_Results = this.codeSearch_Results.slice();
        this.procServ.get_all_replenishment_clients().subscribe((res:Client_Model[]) =>{
          this.Client_Data = res;
          this.isLoading = false;
          if (this.description_match == ''){this.scanforcode();}
          this.alerts.showSuccess('Updated '+this.codeSearch_Results.length+' items')
          // this.isLoading = false;
        },error =>{
          this.alerts.showWarning(error)
          this.isLoading = false;
        })
        // this.isLoading = false;
      }else{
        this.alerts.showWarning(res.msg)
        this.isLoading = false;
      }
    },error =>{
      this.alerts.showWarning(error)
      this.isLoading = false;
    })
  }

  addClientMSL(client: clients_without_code){
    let newLine = new Replenishment_Line
    newLine.clientId = client.clientId;
    newLine.client_name = client.client_name;
    newLine.supplierId = client.supplierId;
    newLine.supplier_name = client.supplier_name;
    newLine.stock_code = this.codeSearched;
    newLine.msfid = this.msfid_match;
    newLine.description = this.description_match;
    newLine.client_soh = -1;
    newLine.supplier_soh = this.supplier_sohMatch;
    this.codeSearch_Results.push(newLine);
    this.codeSearch_Results = this.codeSearch_Results.slice();
    
    for(let n =0; n < this.clients_with_no_match.length; n++){
      if(this.clients_with_no_match[n].clientId == client.clientId){
        this.clients_with_no_match.splice(n,1);
      }
    }
  
   
  
  }

  // ----------- Table Sortings  --------------------------------------
  sortClientName = (a: Client_Model, b: Client_Model) => a.account.buyer_name.localeCompare(b.account.buyer_name);
  sortClientAcc = (a: Client_Model, b: Client_Model) => a.account.debtorAcc.localeCompare(b.account.debtorAcc);
  sortClientSellState = (a: Client_Model, b: Client_Model) => a.account.sell_state.localeCompare(b.account.sell_state);
  sortClientPriceList = (a: Client_Model, b: Client_Model) => a.account.pricelist_name.localeCompare(b.account.pricelist_name);
  sortClientSKUs = (a: Client_Model, b: Client_Model) => a.replenishment_lines.length-b.replenishment_lines.length;

  sortClientName_R = (a: Replenishment_Line, b: Replenishment_Line) => a.client_name.localeCompare(b.client_name);
  sortClientMSL = (a: Replenishment_Line, b: Replenishment_Line) => a.msl-b.msl;
  sortClientAI_MSL = (a: Replenishment_Line, b: Replenishment_Line) => a.suggested_msl-b.suggested_msl;
  sortClient_SOH = (a: Replenishment_Line, b: Replenishment_Line) => a.client_soh-b.client_soh;
  sortClient_Status = (a: Replenishment_Line, b: Replenishment_Line) => a.updated_holding_status-b.updated_holding_status;
  
}
