import {vehicle,saleStatus,quoteStatus,fleetObj,tyre, fleetobj, Fleet , customer} from '../models/fleet'
import { Customer, customerobj } from '../models/customer';
import { NotificationService } from '../shared/notification.service';
import { resultType } from '../shared/modal-content/modal-content.component';
import { SearchService } from '../search/search.service';
import { FleetService } from '../fleet/fleet.service';
import { aautoquote} from './../models/search-obj';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-fleet',
  templateUrl: './fleet.component.html',
  styleUrls: ['./fleet.component.less']
})
export class FleetComponent implements OnInit {

  //Search Obj
  vehicle: vehicle;

  customer: Customer;

  fleet: Fleet;
  saleStatus: saleStatus[] = [];
  quoteStatus: quoteStatus[] = [];
  fleetObjects: fleetObj[] = [];
  tyre: tyre;

  //Visual Effects
  isLoading = false;
  isMapped = true;

  //Client
  //Client = JSON.parse(localStorage.getItem('clientData')!);

  //Vreg 
  cusData = JSON.parse(localStorage.getItem('fleetData')!);
  
    //CustomerUpdate
  sizeForm: FormGroup = new FormGroup({
      width: new FormControl('', Validators.required),
      profile: new FormControl('', Validators.required),
      rim: new FormControl('', Validators.required),
    });
  isSizeModalVisible  = false;
  btntext="";

  constructor(public authService: AuthService,private searchService: SearchService,private fleetService: FleetService,private router: Router, private notification: NotificationService) 
  { 
    this.isLoading = true;  
  }


  ngOnInit(): void {
    //load data
    var obj = new customerobj();
    if(this.cusData.sendTo == undefined)
    {
      obj.customerId = this.cusData.customerId;
    }
    else
    {
      obj.customerId = this.cusData.customer;
    }
    if(this.cusData.vreg != null)
    {
      obj.vreg = this.cusData.vreg;
    }
    //load data
    this.getFleetData(obj);
  }

  reset()
  {
    this.vehicle = {
      reg: "",
      make: "",
      model: "",
      odo:0,
      vehicle_customer_mappingId: 0,
      width:0,
      profile:0,
      rim:0,
      tyre_size:"",
      mapped:""
    };
    this.customer = new Customer();
    this.fleet = new Fleet();
    this.saleStatus =  [];
    this.quoteStatus = [];
    this.fleetObjects =[];
    this.tyre = {
      width: "0",
      profile: "0",
      rim: "0",
      vmapId: 0
    }
  }

  getFleetData(obj)
  {
    this.reset();
    this.fleetService.getfleetData(obj).subscribe(res => 
      {
        // Map 
        this.vehicle = res["vehicle"];
        this.customer = res["customer"];
        this.fleet = res["fleet"];
        this.fleetObjects = res["Items"];
        this.tyre.width = JSON.stringify(this.vehicle.width);
        this.tyre.profile =JSON.stringify( this.vehicle.profile);
        this.tyre.rim = JSON.stringify(this.vehicle.rim);
               
        // Filter out duplicates        
        var filtered = new Set(this.fleetObjects);
        this.fleetObjects = [...filtered];
        
        //check mapping
        if(this.vehicle.mapped == "no")
        {
          this.isMapped = false;
          this.btntext="Add Tyre Size";
          this.notification.ShowAlert({type: resultType.Warning, 
            title: "Missing Tyre Size", 
            htmlMessage: 'Please Add Tyre Size to enable Auto Quoting' ,
            maskClosable: false, 
            autoCloseInMilliseconds: 5000})
          }
        else
        {
          this.isMapped = true;
          this.sizeForm.controls.width.setValue(this.vehicle.width);
          this.sizeForm.controls.profile.setValue(this.vehicle.profile);
          this.sizeForm.controls.rim.setValue(this.vehicle.rim);
          this.btntext="Update Tyre Size";
        }
        //log
        this.isLoading = false;
      },
      error => {
        this.notification.handleError(error);       
    });

  }

  autoquote()
  {
    if(this.tyre.width == "0" || this.tyre.profile == "0" || this.tyre.rim == "0")
    {
      this.notification.ShowAlert({type: resultType.Warning, 
        title: "Tyre Size Error", 
        htmlMessage: 'Please check that Tyre Size values are all correct' ,
        maskClosable: false, 
        autoCloseInMilliseconds: 3000})
      return
    }
    else
    {
      this.notification.ShowAlert({type: resultType.Success, 
        title: "'Quote Created", 
        htmlMessage: '' ,
        maskClosable: false, 
        autoCloseInMilliseconds: 3000})
        let quote : aautoquote = {
          sellerId : this.authService.user.client_id,//this.Client.client_id,
          ext_Ref : "",
          comment :"Quoted Via SF AQ system",
          name: this.customer.name,
          email: this.customer.email,
          cell: this.customer.cell,
          vreg: this.customer.vreg,
          assignedToUserId: this.authService.user.user_id,
          lastcall: parseInt(localStorage.getItem('lastcall')!),
          quote_logic : {
                          search_requirements : 
                                                {
                                                  raw_search: this.vehicle.tyre_size,
                                                  stock_msfids: [],
                                                  none_stock_msfids: [],
                                                  buyerId: 674
                                                },
                          add_lead: true,
                          qty: 4,
                          allow_partial_full:true,
                          base_level_limit: "Remote",
                          add_recommended_stock:true,
                          max_recommended_options: 5,
                          recommended_level_limit: "Remote",
                          add_default_services: true,
                          isContractPricing: true                                           
                        }
        }
        this.searchService.Autoquote(quote).subscribe(res => {
          var quoteId = res["quoteId"];
          if(quoteId != 0)
          {     
              this.router.navigate(["/pos/fleet",quoteId])
          }
          else
          {
            this.notification.showError("An Error Occoured while trying to Create Quote");
          }
        });     
    }

  }
  
  route(quoteid)
  {
    this.router.navigate(["/pos/fleet",quoteid])
  }

  routesale(orderid)
  {
    this.router.navigate(["/sales/view",orderid])
  }

  togglesize()
  {
    this.isMapped = false;
  }

  openModal()
  {
    this.tyre;
    this.sizeForm.controls.width.setValue(this.tyre.width);
    this.sizeForm.controls.profile.setValue(this.tyre.profile);
    this.sizeForm.controls.rim.setValue(this.tyre.rim);
    this.isSizeModalVisible = true;
  }

  onSizeUpdate()
  {
    if( this.sizeForm.value.width == "0" || this.sizeForm.value.width == "" || this.sizeForm.value.profile == "0" || this.sizeForm.value.profile == "" || this.sizeForm.value.rim == "0" || this.sizeForm.value.rim == "")
    {
      this.notification.ShowAlert({type: resultType.Warning, 
        title: "Tyre Size Error", 
        htmlMessage: 'Please check that Tyre Size values are all correct' ,
        maskClosable: false, 
        autoCloseInMilliseconds: 3000})
      return
    }
    else
    {
      this.tyre.width = this.sizeForm.value.width;
      this.tyre.profile = this.sizeForm.value.profile;
      this.tyre.rim = this.sizeForm.value.rim;
      this.tyre.vmapId = this.vehicle.vehicle_customer_mappingId
      this.fleetService.updatevehiclesize(this.tyre).subscribe(res => 
        {
          // Map
          this.notification.showSuccess("Size Updated Successfully");
          this.isSizeModalVisible =false;
          this.ngOnInit();           
        },
        error => {
          this.notification.handleError(error);       
      });
    }
  }



}
