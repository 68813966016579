import { Component, OnInit } from '@angular/core';
import { ReplenishmentService } from '../replenishments/replenishments.service';
import { Router } from '@angular/router';
import { Replenishments, Replenishments_lines, Branch,orderResp,currClient,stocklvls,pingResp,P_Replenishment_lines } from './../models/replenish';
import { NotificationService } from '../shared/notification.service';
import { FormBuilder } from '@angular/forms';
// import * as internal from 'stream';
 
@Component({
  selector: 'app-replenishments',
  templateUrl: './replenishments.component.html',
  styleUrls: ['./replenishments.component.less']
})
export class ReplenishmentsComponent implements OnInit {
  replenishStock: Replenishments[];
  BranchList: Branch[];
  branchOrder: Replenishments_lines[];
  branchOrderPR: P_Replenishment_lines[];
  orderResponse: orderResp[];
  buyer: number;
  seller: number;
  currClient : number;
  clientStock: stocklvls[];
  pingData : pingResp[];
  orderTotal: number = 0;

  isLoading = false;

  
  

  
  constructor(private repService: ReplenishmentService,private router:Router,private notification: NotificationService,private formBuilder: FormBuilder) {
    
  }
  
  // constructor(){}
  ngOnInit(): void {
    this.BranchList = [];
    this.repService.getUser().subscribe(res =>
      { 
         this.currClient = res;
         this.repService.getBranches(res).subscribe(res =>
          { 
            this.BranchList = res as Branch[];           
          },
          error => {
            this.notification.handleError(error);       
          })
      })
    
    

  }

  

  addItem(itemObj)
  {
    for (let x = 0; x < this.branchOrderPR.length; x++){
      if(this.branchOrder[x].stock_code == itemObj.stock_code)
      {   if(this.branchOrder[x].ordQty != this.branchOrder[x].wh_lvl )
          {
            this.branchOrder[x].ordQty += 1
            if(this.branchOrder[x].ordQty > 0)
            {
              this.branchOrder[x].status = 'background-color:Green;'
            }
          }
          else{
            this.notification.showInfo('Warehouse only has '+this.branchOrder[x].wh_lvl+' unit(s) available\n for stock  item: '+this.branchOrder[x].description)
          }
      }

    }
  }

  removeItem(itemObj)
  {
    for (let x = 0; x < this.branchOrderPR.length; x++){
      if(this.branchOrder[x].stock_code == itemObj.stock_code)
      {
        if(this.branchOrder[x].ordQty > 0)
        {
          this.branchOrder[x].ordQty -= 1
        }
        if(this.branchOrder[x].ordQty == 0 && this.branchOrder[x].wh_lvl > 0){
          this.branchOrder[x].status = 'background-color:#ff6666;'
        }
        if(this.branchOrder[x].ordQty == 0 && this.branchOrder[x].wh_lvl == 0){
          this.branchOrder[x].status = 'background-color:#b32400;'
        }
          
      }

    }
  }

  
  repCentre(id)
  {
    this.isLoading = true; 
    this.buyer = id
    // let loadH:HTMLElement = document.getElementById("Loader") as HTMLElement;
    let repTable:HTMLElement = document.getElementById("repResults") as HTMLElement;
    let repStatus:HTMLElement = document.getElementById("repInvoice") as HTMLElement;
    repStatus.style.display = "None";
    repTable.style.display = "None";
    // loadH.style.display = "block";
    this.repService.replenishCentres(id).subscribe(res =>
      {
        this.branchOrder = res as Replenishments_lines[];
        
        repTable.style.display = "block";

        this.branchOrderPR = []

        for(let i = 0; i < this.branchOrder.length;i++)
        {
          this.branchOrderPR[i] = this.branchOrder[i]
        }
        this.isLoading = false;
        
      },
      
      error =>{
        this.notification.handleError(error)
        this.isLoading = false;
        // loadH.style.display = "None";
      });

        

      
  }

  placeReplenishments()
  {
    this.isLoading = true;
    let repStatus:HTMLElement = document.getElementById("repInvoice") as HTMLElement;
    this.repService.placeRepOrder(this.branchOrder).subscribe(res =>
      {
        this.orderResponse = res as orderResp[];
        repStatus.style.display = "block";
        this.isLoading = false;
      },
      error =>{
        this.notification.handleError(error)
        this.isLoading = false;
      });
  }



 
}
