export class Fleet {
    clientId: number
    email:  string
    name:  string
    phone:  string
}

export class customer {
   Id: number
   cell: string
   email: string
   name: string
}


export class fleetobj {
    vreg: string
}

export class vehicle{
   reg: string
   make: string
   model: string
   odo: number
   vehicle_customer_mappingId: number
   width:number
   profile:number
   rim:number
   tyre_size:string
   mapped:string
 }


 export class saleStatus{
    Id: number
    StateGroup: number
    StateId: number
    StateType: number
    description: string
    stateRank: number
 }

 export class quoteStatus{
    Id: number
    StateType: number
    description: string
    stateId:number
    stateRank: number
 }

 export class fleetline{
    Brand: string
    Description: string
    Id: number
    Price: string
    Size: string
    Total: string
    qty: number
    stocktype: string
 }

 export class fleetObj{
    Id: number
    LineItems: fleetline[]
    Price: string
    Ref: string
    buyerId:  number
    dtcreated: string
    salequote: string
    sellerId:  number
    status: string
    stateId: number
    stateType: number
    stategroup : number
 }

 export class tyre {
    width: string
    profile: string
    rim : string
    vmapId : number
 }

 
export class sb_auth_letter {
   filename: string
   fileData: string
   fileContentType: string
 }
