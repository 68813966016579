import { Component, OnInit } from '@angular/core';
import { Message } from '../models/message';
import { NotificationService } from '../shared/notification.service';
import { MessagingService } from './messaging.service';
import { AppComponent } from '../app.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.less']
})


export class MessagesComponent implements OnInit {

  constructor(private messagingService: MessagingService,
              private notification: NotificationService,
              private app: AppComponent,
              private route:ActivatedRoute,
              private router: Router) { }


  listFilter: string = "";
  showMessageFromList : number = 0;

  isLoading:boolean = false;
  isMessageVisible:boolean = false;



  allMessages: Message[];
  filteredData:Message[];
  readingMessage: Message;
  paramsSub: Subscription



  ngOnInit(): void {
     this.paramsSub = this.route.params.subscribe(params => { this.showMessageFromList = params["messageId"]});
     this.fetchAllMessages()
  }

  fetchAllMessages()
  {
    this.isLoading = true;
    this.messagingService.getAllMessages(100).subscribe((res:any)=> {
      this.allMessages = this.messagingService.allMessages;
      this.allMessages.forEach(message => {
        if(message.dateRead != null)
        {
          message.messageOpened = true;
        }
        else
        {
          message.messageOpened = false;
        }
      });
      this.filteredData = this.allMessages;
      this.isLoading=false;
      if(this.showMessageFromList > 0)
      {
        this.openMessage(this.allMessages.find(x=> x.messageId == this.showMessageFromList)!);
      }
    },
    error => {
      this.notification.handleError(error);
    })
  }

  refresh()
  {
    this.allMessages = [];
    this.fetchAllMessages();
  }

  openMessage(message: Message)
  {
    this.readingMessage = new Message();
    this.readingMessage = message;
    this.isMessageVisible = true;
    message.messageOpened = true;
    this.markAsRead(message);
  }

  public markAsRead(msg: Message)
  {
    // this.messegingService.markRead(msgId).subscribe(res => {
    //  this.messegingService.getUreadMessages(15);
    // },error => {
    //   this.notification.handleError(error);
    // })
    this.messagingService.markMessageAsRead(msg).subscribe(
      _ => {
        
      }
    )
  }

  filterlist()
  {
    this.filteredData = this.allMessages.filter(e => e.message!== null).filter(e => e.message.toUpperCase().includes(this.listFilter.toUpperCase()));
  }

  public MarkAllAsRead() {
    this.isLoading = true;
    this.messagingService.MarkAllAsRead().subscribe(
      _ => {
        this.refresh();
        //refresh the inbox icon count
        this.messagingService.getUnreadMessages(15).subscribe(
          _ => {}
        )
      },
      error => {
        this.isLoading = false;
        this.notification.handleError(error);
      }
    )
  }

  public DeleteAll() {
    this.isLoading = true;
    this.messagingService.DeleteAll().subscribe(
      _ => {
        this.notification.showSuccess("All read messages have been deleted.")
        this.refresh();
        //refresh the inbox icon count
        this.messagingService.getUnreadMessages(15).subscribe(
          _ => {}
        )
      },
      error => {
        this.isLoading = false;
        this.notification.handleError(error);
      }
      

    )
  }

  isDeleting = false;
  DeleteMsg() {
    this.isDeleting = true;
    this.messagingService.DeleteGroupId(this.readingMessage.messageGroupId).subscribe(
      _ => {
        this.isDeleting = false;
        this.router.navigate(['messages']);
        this.isMessageVisible = false;
        this.refresh();
      },
      err => {
        this.notification.handleError(err);
      }
    )
  }

}
