import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/shared/notification.service';
import { SettingsService } from '../settings.service';
import { Customer } from 'src/app/models/settings';
import { customer } from 'src/app/models/search-obj';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.less']
})
export class CustomerComponent implements OnInit {

  //Global Var
  customers: Customer[]=[];
  filteredCustomers: Customer[]=[];;
  clientFilter: string = "";

  //Visual Effects
  isLoading = false;
  
  constructor(private settingsService : SettingsService,private router: Router, private notification: NotificationService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.settingsService.getCustomerConnections().subscribe((res: any) =>
      {
        this.customers = res;
        this.filteredCustomers = this.customers;
        this.isLoading = false;
      },
      error => {
        this.notification.handleError(error);
      });
  }

  editCustomer(customer:Customer)
  {
    this.router.navigate(['/settings/customer-edit',customer.customerId]);
  }

  viewCustomer(customer: Customer)
  {
    localStorage.removeItem("customerData");
    localStorage.setItem('customerData', JSON.stringify(customer));
    this.router.navigate(['/customer']);
  }

  filterCustomers()
  {
    this.filteredCustomers = this.customers.filter(e => e.name !== null).filter(e => e.name.toUpperCase().includes(this.clientFilter.toUpperCase()));
  }






}
