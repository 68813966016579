import { Component, OnInit } from '@angular/core';
import { WhatsappService } from 'src/app/whatsapp/whatsapp.service';

@Component({
  selector: 'app-admin-whatsapp',
  templateUrl: './admin-whatsapp.component.html',
  styleUrls: ['./admin-whatsapp.component.less']
})
export class AdminWhatsappComponent implements OnInit {

  constructor(public waService: WhatsappService) { }

  ngOnInit(): void {
  }

}
