import { Component,HostListener, OnInit, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';
import { ProcurementMaintenanceComponent } from '../procurement-maintenance/procurement-maintenance.component';
import * as XLSX from 'xlsx';
import { AuthService } from '../auth.service';
import { Replenishment_Line,Client_Model } from '../models/procurement';

@Component({
  selector: 'app-procurement-client-model-stock',
  templateUrl: './procurement-client-model-stock.component.html',
  styleUrls: ['./procurement-client-model-stock.component.less']
})
export class ProcurementClientModelStockComponent implements OnInit {
  constructor(public proc_Data:ProcurementMaintenanceComponent,private elementRef: ElementRef, private renderer: Renderer2){}
  tmpCLient_Data: Client_Model
  scrollHeight = '';
  isLoading  = false;
  ngOnInit(): void {
    this.tmpCLient_Data = new Client_Model
    this.tmpCLient_Data.account = this.proc_Data.Client_Viewed.account;
    this.tmpCLient_Data.replenishment_lines = this.proc_Data.Client_Viewed.replenishment_lines.slice();
    this.scrollHeight = (this.proc_Data.innerHeight*0.50)+"px";
    

  }

 


  download_msl(){
    this.isLoading = true;
    setTimeout(()=> {this.setTabletoContent(this.tmpCLient_Data.replenishment_lines.length)},1000);
    setTimeout(()=> {
      let element = document.getElementById('rep_lines');
      const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, {raw:true});
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      let filename = this.proc_Data.Client_Viewed.account.buyer_name
      filename = filename+' Model Stock.xlsx';
      XLSX.writeFile(wb, filename, {bookType:'xlsx',type:'buffer'});
      this.isLoading = false;
    },1000)
  }

  code_search: string
  codeInputChange(c: string){
    this.tmpCLient_Data.replenishment_lines = []
    for(let x = 0; x < this.proc_Data.Client_Viewed.replenishment_lines.length; x++){
      if(this.proc_Data.Client_Viewed.replenishment_lines[x].stock_code.includes(c.toUpperCase()) || this.proc_Data.Client_Viewed.replenishment_lines[x].description.replace('/','').replace('R','').replace(' ','').includes(c.toUpperCase().replace('/','').replace('R','').replace(' ','')) || this.proc_Data.Client_Viewed.replenishment_lines[x].description.includes(c.toUpperCase()))
      {
        this.tmpCLient_Data.replenishment_lines.push(this.proc_Data.Client_Viewed.replenishment_lines[x])
      }
    }
  }

  sortRepBrands = (a: Replenishment_Line, b: Replenishment_Line) => a.brand_logo.localeCompare(b.brand_logo);
  sortRepCodes = (a: Replenishment_Line, b: Replenishment_Line) => a.stock_code.localeCompare(b.stock_code);
  sortRepdescriptions = (a: Replenishment_Line, b: Replenishment_Line) => a.description.localeCompare(b.description);
  sortRepMSL = (a: Replenishment_Line, b: Replenishment_Line) => a.msl-b.msl;
  sortRepAIMSL = (a: Replenishment_Line, b: Replenishment_Line) => a.suggested_msl-b.suggested_msl;
  sortRepCost = (a: Replenishment_Line, b: Replenishment_Line) => a.supplier_cost-b.supplier_cost;
  sortRepPrice = (a: Replenishment_Line, b: Replenishment_Line) => a.supplier_replenishment_price-b.supplier_replenishment_price;

  pageSize = 50;
  @HostListener("window:scroll", [])
    onScroll(): void {
    if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight - (document.body.scrollHeight * 0.1)) {
            // you're at the bottom of the page
            this.pageSize += 10;
            // this.input.inputValue = this.input.inputValue;
        }
    }
    resetTable(itemSize){
        this.pageSize = 50;
    }
    setTabletoContent(arrSize){
      this.pageSize = arrSize;
    }
}
