<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12" style="padding-left: 5%;padding-right: 4%;">
            <div class="row justify-content-center">
                <div class="col-md-6 col-sm-8 col-12 input-form text-center">
                    <nz-spin [nzSpinning]="isLoading">
                        <app-video-widget [videoId]="10" style="float: inline-end;"></app-video-widget>

                        <h2>Password Change</h2>
                        <nz-alert nzType="error" [nzMessage]="errorMessage" nzShowIcon *ngIf="errorMessage"></nz-alert>
                        <form nz-form [formGroup]="pwdForm" (ngSubmit)="changePassword()">
                            <nz-form-item>
                                <nz-form-control>
                                    <nz-input-group nzPrefixIcon="unlock" [nzSuffix]="suffixTemplate">
                                        <input nz-input name="currentPassword" formControlName="currentPassword" placeholder="Current Password" [type]="passwordVisible ? 'text' : 'password'">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item>
                                <nz-form-control>
                                    <nz-input-group nzPrefixIcon="lock" [nzSuffix]="suffixTemplate">
                                        <input nz-input name="newPassword" formControlName="newPassword" placeholder="New Password" [type]="passwordVisible ? 'text' : 'password'">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item>
                                <nz-form-control [nzErrorTip]="errorMessage">
                                    <nz-input-group nzPrefixIcon="lock" [nzSuffix]="suffixTemplate">
                                        <input nz-input name="confirmPassword" formControlName="confirmPassword" placeholder="Confirm Password" [type]="passwordVisible ? 'text' : 'password'">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                            <div>
                                <button nz-button nzSize="large" nzType="default" nzShape="round" type="submit" [disabled]="pwdForm.invalid">Change Password</button>
                            </div>
                            <br>
                        </form>
                    </nz-spin>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #suffixTemplate>
    <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" (click)="passwordVisible = !passwordVisible"></i>
</ng-template>