<nz-layout class="app-layout" [ngClass]="{ 'ant-layout-custom' : !authService.isLoggedIn()}">
    <nz-sider class="menu-sidebar ant-layout-sider-collapsed ant-layout-sider-collapsed " nzWidth="{{menuUnCollapsedWidth}}px" nzBreakpoint="xxl" nzCollapsible ng-reflect-nz-collapsed="true" [nzCollapsedWidth]="menuCollapsedWidth" [(nzCollapsed)]="isCollapsed"
        (nzCollapsedChange)="onMenuCollapseChanged($event)" *ngIf="authService.isLoggedIn()">
        <div class="sidebar-logo">
            <img src="./../assets/Images/s-logo-white.png" height="25px" width="25px" style="margin-left: 6%;">
            <h1>StockFinder</h1>
        </div>
        <ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
            <li nz-submenu nzClose nzTitle="SF ADMIN" nzIcon="control" *ngIf="authService.checkMenu(['SFA'])">
                <ul>
                    <li nz-menu-item nzMatchRouter *ngIf="authService.checkMenu(['SFA'])">
                        <a routerLinkActive="activebutton" routerLink="/admin/whatsapp">Whatsapp</a>
                    </li>
                    <li nz-menu-item nzMatchRouter *ngIf="authService.checkMenu(['SFA'])">
                        <a routerLinkActive="activebutton" routerLink="/nmsf">NMSF</a>
                    </li>
                    <li nz-menu-item nzMatchRouter *ngIf="authService.checkMenu(['SFA'])">
                        <a routerLinkActive="activebutton" routerLink="/diagnostic">Diagnostic</a>
                    </li>
                    <li nz-menu-item nzMatchRouter *ngIf="authService.checkMenu(['SFA'])">
                        <a routerLinkActive="activebutton" routerLink="/admin/users">Users</a>
                    </li>
                    <li nz-menu-item nzMatchRouter *ngIf="authService.checkMenu(['SFA'])">
                        <a routerLinkActive="activebutton" routerLink="/component-legend">Components</a>
                    </li>
                    <li nz-menu-item nzMatchRouter *ngIf="authService.checkMenu(['SFA'])">
                        <a routerLinkActive="activebutton" routerLink="/stock/admin-mapping">SF Admin Mapping</a>
                    </li>
                </ul>
            </li>
            <li nz-menu-item nzMatchRouter>
                <a routerLink="search" outerLinkActive="activebutton">
                    <i nz-icon nzType="search" class="menuIcon"></i>
                    <span>Search</span>
                </a>
            </li>
            <li nz-menu-item nzMatchRouter *ngIf="authService.checkMenu(['WIP']) && authService.user.featureLevel >= 2">
                <a routerLinkActive="activebutton" routerLink="/wip">
                    <i nz-icon nzType="car" class="menuIcon"></i>
                    <span>Work In Progress</span>
                </a>
            </li>
            <li nz-menu-item nzMatchRouter *ngIf="authService.checkMenu(['LDU']) && (this.currentBreakpoint == this.Breakpoints.Web || this.currentBreakpoint == this.Breakpoints.Tablet)" (click)="actionLog()">
                <!-- (click)="this.logService.addAction(new PosAuditEntry(LogAuditEntryType.ViewLeads, item.msfid, comment))" -->
                <a routerLinkActive="activebutton" routerLink="/leads">
                    <nz-badge nzSize="small" [nzCount]="this.websockets.leadsCount" [nzOverflowCount]="9" [nzOffset]="[-5,5]" style=" float:right;"></nz-badge>
                    <i nz-icon nzType="funnel-plot" class="menuIcon"></i>                  
                    <span >Leads</span>
                    
                </a>
            </li>
            <li nz-menu-item nzMatchRouter *ngIf="authService.checkMenu(['PUR'])">
                <a routerLinkActive="activebutton" routerLink="/purchases">
                    <nz-badge nzSize="small" [nzCount]="this.websockets.purchasesCount" [nzOverflowCount]="9" [nzOffset]="[-5,5]" style=" float:right;" ></nz-badge>
                    <i nz-icon nzType="credit-card" class="menuIcon"></i>
                    
                    <span>Purchases</span>
                    
                </a>
            </li>
            <li nz-menu-item nzMatchRouter *ngIf="authService.checkMenu(['SLE'])">
                <nz-badge nzSize="small" [nzCount]="this.websockets.salesCount" [nzOverflowCount]="9" [nzOffset]="[-5,5]" style=" float:right;"></nz-badge>
                <a routerLinkActive="activebutton" routerLink="/sales">
                    <i nz-icon nzType="dollar-circle" class="menuIcon"></i>
                    
                    <span>Sales</span>
                    
                </a>
            </li>
            <li nz-submenu nzClose nzTitle="Stock" nzIcon="stock" *ngIf="authService.checkMenu(['STM','OVR','WSP'])  && (this.currentBreakpoint == this.Breakpoints.Web || this.currentBreakpoint == this.Breakpoints.Tablet)">
                <ul>
                    <li nz-menu-item nzMatchRouter *ngIf="authService.checkMenu(['PRL'])">
                        <a routerLinkActive="activebutton" routerLink="/pricelists">Price Lists</a>
                    </li>
                    <li nz-menu-item nzMatchRouter *ngIf="authService.checkMenu(['OVR'])">
                        <a routerLinkActive="activebutton" routerLink="/stock/override">Override Pricing</a>
                    </li>
                    <li nz-menu-item nzMatchRouter *ngIf="authService.checkMenu(['PRL'])">
                        <a routerLink="/pricing">Price List Names</a>
                    </li>
                    <li nz-menu-item nzMatchRouter *ngIf="authService.checkMenu(['STM'])">
                        <a routerLinkActive="activebutton" routerLink="/stock/mapping">Mapping</a>
                    </li>
                    
                    <li nz-menu-item nzMatchRouter *ngIf="authService.checkMenu(['PRL','WSP'])">
                        <a routerLinkActive="activebutton" routerLink="/matrix">Pricing Matrix</a>
                    </li>
                    <li nz-menu-item nzMatchRouter *ngIf="authService.checkMenu(['PRL'])">
                        <a routerLinkActive="activebutton" routerLink="/custom-stock">Custom Stock</a>
                    </li>
                    <li nz-menu-item nzMatchRouter *ngIf="authService.checkMenu(['ARPL'])">
                        <a routerLinkActive="activebutton" routerLink="/stock-models">Replenishment</a>
                    </li>
                    <li nz-menu-item nzMatchRouter *ngIf="authService.checkMenu(['MSV'])">
                        <a routerLinkActive="activebutton" routerLink="/model-stock-analysis">Model Stock</a>
                    </li>
                    <li nz-menu-item nzMatchRouter *ngIf="authService.checkMenu(['PRM'])">
                        <a routerLinkActive="activebutton" routerLink="/procurement-maintenance">Procurement Maintenance</a>
                    </li>
                </ul>
            </li>
            <li nz-submenu nzClose nzTitle="Replenishments" nzIcon="plus-circle" *ngIf="authService.checkMenu(['RPL'])">
                <ul>
                    <li nz-menu-item nzMatchRouter>
                        <a routerLinkActive="activebutton" routerLink="/replenishments">Replenish Fitment Centres</a>
                    </li>


                </ul>
            </li>
            <li nz-submenu nzClose nzTitle="Insights" nzIcon="bar-chart" *ngIf="authService.checkMenu(['INS'])  && (this.currentBreakpoint == this.Breakpoints.Web || this.currentBreakpoint == this.Breakpoints.Tablet)">
                <ul>
                    <li nz-menu-item nzMatchRouter>
                        <a routerLinkActive="activebutton" routerLink="/insights">Main</a>
                    </li>
                    <li nz-menu-item nzMatchRouter>
                        <a routerLinkActive="activebutton" routerLink="/insights/searches">Searches</a>
                    </li>
                    <li nz-menu-item nzMatchRouter>
                        <a routerLinkActive="activebutton" routerLink="/insights/sales">Sales</a>
                    </li>
                    <li nz-menu-item nzMatchRouter>
                        <a routerLinkActive="activebutton" routerLink="/insights/conversions">Conversions</a>
                    </li>
                    <li nz-menu-item nzMatchRouter>
                        <a routerLinkActive="activebutton" routerLink="/insights/searches-dashboard-insights">Searches Dashboard</a>
                    </li>
                </ul>
            </li>
            <li nz-submenu nzClose nzTitle="Settings" nzIcon="setting" *ngIf="(this.currentBreakpoint == this.Breakpoints.Web || this.currentBreakpoint == this.Breakpoints.Tablet)">
                <ul>
                    <li nz-menu-item nzMatchRouter *ngIf="authService.checkMenu(['SET'])">
                        <a routerLinkActive="activebutton" routerLink="/settings/main-v2">Main</a>
                    </li>
                    <li nz-menu-item nzMatchRouter *ngIf="authService.checkMenu(['CLI'])">
                        <a routerLinkActive="activebutton" routerLink="/clients">Clients</a>
                    </li>
                    <!-- <li nz-menu-item nzMatchRouter *ngIf="authService.checkMenu(['POS'])">
                        <a routerLinkActive="activebutton" routerLink="/settings/customer">Customers</a>
                    </li> -->
                    <li nz-menu-item nzMatchRouter *ngIf="authService.checkMenu(['USR'])">
                        <a routerLinkActive="activebutton" routerLink="/users">Users</a>
                    </li>
                    <li nz-menu-item nzMatchRouter *ngIf="authService.checkMenu(['URA'])">
                        <a routerLinkActive="activebutton" routerLink="/all-users">All Users</a>
                    </li>
                    <li nz-menu-item nzMatchRouter *ngIf="authService.checkMenu(['BSM']) && authService.user.bookingsEnabled">
                        <a routerLinkActive="activebutton" routerLink="/settings/bookings">Bookings</a>
                    </li>
                    <li nz-menu-item nzMatchRouter *ngIf="authService.checkMenu(['SUP'])">
                        <a routerLinkActive="activebutton" routerLink="/suppliers">Suppliers</a>
                    </li>
                    <li nz-menu-item nzMatchRouter *ngIf="authService.checkMenu(['BSC'])">
                        <a routerLinkActive="activebutton" routerLink="/settings/brand-strategy">Brand Strategy</a>
                    </li>
                    <li nz-menu-item nzMatchRouter>
                        <a routerLinkActive="activebutton" routerLink="/settings/change-password">Change Password</a>
                    </li>
                </ul>
            </li>
            <li nz-menu-item nzMatchRouter>
                <a routerLink="help" outerLinkActive="activebutton">
                    <i nz-icon nzType="question"></i>
                    <span>Help</span>
                </a>
            </li>
            <!-- <ng-template class="d-md-none" *ngIf=" this.messagingService.UnreadMessages.length > 0; then messageShowSmall else toolShowSmall"></ng-template>
            <ng-template #messageShowSmall>
              <li nz-menu-item
                  nzMatchRouter
                  class="d-md-none"
                  (click)="openMessages()"
                  nz-popover
                  nzPopoverPlacement="right"
                  [nzPopoverContent]="SmallRight"
                  >
                  <a outerLinkActive="activebutton">
                      <span nz-icon nzType="inbox" nzTheme="outline"></span>
                      <span>Messages</span>
                  </a>
                  <ng-template #SmallRight>
                    <div *ngFor="let message of messagingService.UnreadMessages" class="messagebox" nz-row nzJustify="center" >
                        <div nz-col nzSpan="20">
                            {{ message.message }}
                            <button nz-button nzType="default" *ngIf="message.messageType == 4"
                                    (click)="markMessageAsRead(message)"
                                    [routerLink]="message.action1Url">
                             {{ message.action1Text}}
                            </button>
                            </div>
                            
                        <div nz-col nzSpan="2">
                            <button nz-button nzType="default" (click)="markMessageAsRead(message)" *ngIf="message.messageType == 4 && !message.action1Text "><span nz-icon nzType="sf:timesIconSVG" nzTheme="outline" ></span></button>
                            <button nz-button nzType="default" routerLink="/messages/{{message.messageId}}" *ngIf="message.messageType == 3" style="margin-left:-2px"><span nz-icon nzType="sf:envelopeIconSVG" nzTheme="outline" ></span></button>
                        </div>
                    </div>
                </ng-template>

                </li>
            </ng-template>
            <ng-template #toolShowSmall>
                <li nz-menu-item nzMatchRouter class="d-md-none" nz-tooltip nzTooltipTitle="Messages" nzTooltipPlacement="right" (click)="openMessages()">
                    <a outerLinkActive="activebutton">
                      <span nz-icon nzType="inbox" nzTheme="outline"></span>
                        <span>Messages</span>
                    </a>

                </li>
            </ng-template> -->
            <li nz-menu-item nzMatchRouter class="d-md-none" (click)="openLinkedAccounts()" *ngIf="authService.user.isSuperUser || authService.user.suId">
                <a outerLinkActive="activebutton">
                    <i nz-icon nzType="swap"></i>
                    <span>Switch Account</span>
                </a>
            </li>
            <li nz-menu-item nzMatchRouter class="d-md-none" (click)="displayModal = true" *ngIf="authService.user">
                <a outerLinkActive="activebutton">
                    <i nz-icon nzType="user-switch"></i>
                    <span>Switch User</span>
                </a>
            </li>
            <li nz-menu-item (click)="logout()" class="logout">
                <a outerLinkActive="activebutton">
                    <i nz-icon nzType="logout" nzTheme="outline"></i>
                    <span>Logout</span>
                </a>
            </li>


        </ul>
    </nz-sider>
    <nz-layout [style.margin-left.px]="authService.isLoggedIn() ? isCollapsed ? menuCollapsedWidth : menuUnCollapsedWidth : 0" [ngClass]="{ 'ant-layout-custom' : !authService.isLoggedIn()}">
        <nz-header *ngIf="authService.isLoggedIn()"  class="sticky-top" style="padding-left: 24px; padding-right: 24px;">

            <div nz-row nzJustify="center" nzAlign="top">
                <div nz-col nzXs="12" nzSm="8" nzMd="8" nzLg="8" nzXL="8">
                    <div class="header-text-color" style="font-size: 1.2rem; line-height: 1.2rem; margin-top: 5px;">
                        <strong>{{ authService ? authService.user ? authService.user.client_name : "" : "" }}</strong><br />
                        <span class="mobile">{{authService.user ? (authService.user.fullname ? authService.user.fullname : "Beta User") : "Beta User"}}</span>
                    </div>

                </div>
                <div nz-col nzXs="0" nzSm="0" nzMd="0" nzLg="8" nzXL="8" style="text-align:center;">
                    <img src="./../assets/Images/theme_logo/{{themeService.themeLogo()}}" class="header-logo" />
                </div>
                <div nz-col nzXs="12" nzSm="16" nzMd="16" nzLg="8" nzXL="8" style="text-align: right;">
                    <nz-select [(ngModel)]="selectedPriceSet" *ngIf="searchService.specialPriceSets.length > 0" [disabled]="isSpecialPriceSetDisabled()"
                        style="margin-right: 20px; width: 200px; text-align: left;" (ngModelChange)="selectedSpecialPriceSetChange($event)">
                            <nz-option [nzValue]="0" nzLabel="Normal Pricing"></nz-option>
                            <nz-option [nzValue]="item.specialPriceSetId" [nzLabel]="item.name" *ngFor="let item of searchService.specialPriceSets"></nz-option>                        
                    </nz-select>
                    <nz-switch [ngModel]="themeService.isDark" [nzCheckedChildren]="checkedTemplate" [nzUnCheckedChildren]="unCheckedTemplate" (ngModelChange)="switchTheme($event)" class="mobile" style="transform: scale(1.4, 1.4); vertical-align: top !important; margin-top: -4px !important;margin-right: 20px !important;"></nz-switch>
                    <ng-template #checkedTemplate><small style="vertical-align: top;">DARK</small></ng-template>
                    <ng-template #unCheckedTemplate><small style="vertical-align: top;">LIGHT</small></ng-template>
                    <ng-template *ngIf="this.messagingService.UnreadMessages.length > 0; then messageShow else toolShow"></ng-template>
                    <nz-badge [nzCount]="waService.totalUnread()" [nzOverflowCount]="9" [nzOffset]="[-10, 0]">
                        <button nz-button nzType="primary" (click)="isWhatsappDrawerVisible = true" *ngIf="authService.user && authService.user.waEnabled" nz-tooltip nzTooltipTitle="Whatsapp Messages" nzTooltipPlacement="bottom" style="margin-left: 10px; margin-right: 10px">
                                <span nz-icon nzType="whats-app" nzTheme="outline"></span>
                        </button>
                    </nz-badge>
                    <button nz-button nzType="primary" (click)="displayModal = true" class="mobile" *ngIf="authService.user" nz-tooltip nzTooltipTitle="Switch User" nzTooltipPlacement="bottom" style="margin-left: 10px; margin-right: 10px">
                        <span nz-icon nzType="user-switch" nzTheme="outline"></span>
                    </button>
                    <button nz-button nzType="primary" (click)="openLinkedAccounts()" *ngIf="authService.user.isSuperUser || authService.user.suId" nz-tooltip nzTooltipTitle="Switch Account" nzTooltipPlacement="bottom" style="margin-left: 10px; margin-right: 10px">
                        <i nz-icon nzType="swap" nzTheme="outline" style="margin-right: 4px;"></i>
                        <span class="d-none d-lg-inline">
                        </span>
                    </button>
                    <ng-template #messageShow>
                        <nz-badge nzSize="small" [nzCount]="this.messagingService.unreadMessagesCount" [nzOverflowCount]="9" [nzOffset]="[-10, 0]">
                            <button nz-button nzType="primary" (click)="openMessages()" nz-popover nzPopoverPlacement="bottom" [nzPopoverContent]="messageListDropdown" style="margin-left: 10px; margin-right: 10px">
                                <span nz-icon nzType="inbox" nzTheme="outline"></span>
                                    <!-- <a class="head-example"></a> -->
                                <!-- <sup class="calBox" *ngIf="this.messagingService.unreadMessagesCount"> {{this.messagingService.unreadMessagesCount < 10 ? this.messagingService.unreadMessagesCount : "9+"}}</sup> -->
                            </button>
                        </nz-badge>
                        <ng-template #messageListDropdown>
                            <ul nz-menu nzMode="horizontal" *ngFor="let message of messagingService.UnreadMessages">
                                <!-- <div nz-col nzSpan="20"> -->
                                <li nz-menu-item *ngIf="message.messageType == 4 && message.action1Url" (click)="markMessageAsRead(message)" [routerLink]="message.action1Url">{{ message.message }}</li>
                                <li nz-menu-item *ngIf="message.messageType == 4 && !message.action1Text" (click)="markMessageAsRead(message)">{{ message.message }}<span nz-icon nzType="sf:timesIconSVG" nzTheme="outline"></span></li>
                                <li nz-menu-item routerLink="/messages/{{message.messageId}}" *ngIf="message.messageType == 3" (click)="markMessageAsRead(message)"><span nz-icon nzType="mail" nzTheme="outline" class="color-primary"></span> {{ message.message }} </li>
                                <!-- </div> -->
                            </ul>
                            <!-- <div *ngFor="let message of messagingService.UnreadMessages" nz-row nzJustify="start">
                                <div nz-col nzSpan="20">
                                    {{ message.message }}
                                </div>
                                <div nz-col nzSpan="4">
                                    <button nz-button nzType="primary" *ngIf="message.messageType == 4 && message.action1Url" (click)="markMessageAsRead(message)" [routerLink]="message.action1Url">
                                {{ message.action1Text}}
                                    </button>
                                </div>
                                <div nz-col nzSpan="4">
                                    <button nz-button nzType="primary" (click)="markMessageAsRead(message)" *ngIf="message.messageType == 4 && !message.action1Text"><span nz-icon nzType="sf:timesIconSVG" nzTheme="outline" ></span></button>
                                    <button nz-button nzType="primary" routerLink="/messages/{{message.messageId}}" *ngIf="message.messageType == 3" style="margin-left:-2px"><span nz-icon nzType="sf:envelopeIconSVG" nzTheme="outline" ></span></button>
                                </div>
                            </div> -->
                            <div *ngIf="this.messagingService.unreadMessagesCount > 10" style="text-align: center;">
                                + {{this.messagingService.unreadMessagesCount - 10}} more
                            </div>
                        </ng-template>
                    </ng-template>
                    <ng-template #toolShow>
                        <button nz-button nzType="primary" (click)="openMessages()" nz-tooltip nzTooltipTitle="Messages" nzTooltipPlacement="bottom">
                        <span nz-icon nzType="inbox" nzTheme="outline"></span>
                    <!-- <i nz-icon nzType="mail" nzTheme="outline"  style="margin-right: 4px;"></i> -->
                        </button>
                    </ng-template>


                </div>
            </div>


        </nz-header>
        <nz-content [style]="!authService.isLoggedIn() ? 'height: 90vh' : ''">
            <div class="inner-content">
                <br>
                <router-outlet></router-outlet>
            </div>
        </nz-content>
        <nz-footer>
            <div nz-row *ngIf="waService.totalUnread() > 0 && authService.user.client_id !== 954">
                <div nz-col [nzSpan]="24" style="align-items: right; text-align: right; padding-right: 30px;">
                    <nz-affix [nzOffsetBottom]="20">
                        <a (click)="isWhatsappDrawerVisible = true">
                            <span nz-icon nzType="whats-app" nzTheme="outline" style="background-color: #25D366; padding: 8px; border-radius: 20px;" class="horizontal-shake"></span>
                        </a>
                    </nz-affix>
                </div>
            </div>
            <span class="invisibleText" style="width: 0px;display: none;">35 VANDERBIJL STREET, MEYERTON, 1961 MEYERTON +27798953613</span><br />
            STOCKFINDER (PTY) LTD (v{{appVersion}}) &copy;2023
            
        </nz-footer>
    </nz-layout>
</nz-layout>

<nz-modal [(nzVisible)]="displayModal" nzClosable="true" nzTitle="Please Provide Password" (nzOnCancel)="displayModal = false;" (nzOnOk)="onModalOk()" [nzFooter]="null">
    <ng-container *nzModalContent>
        <nz-spin [nzSpinning]="isModalLoading">
            <form [formGroup]="pwdForm">
                <nz-form-item>
                    <nz-form-control>
                        <nz-input-group nzPrefixIcon="lock" [nzSuffix]="suffixTemplate">
                            <input nz-input name="password" formControlName="password" placeholder="Password" [type]="passwordVisible ? 'text' : 'password'">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-spin>
        <ng-template #suffixTemplate>
            <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" (click)="passwordVisible = !passwordVisible"></i>
        </ng-template>
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-2 offset-5">
                    <button nz-button nzType="default" (click)="onModalOk()">Login</button>
                </div>
            </div>
        </div>
    </ng-container>
</nz-modal>

<nz-modal [(nzVisible)]="isLinkedAccountsModalVisible" nzClosable="true" nzTitle="Select Account" (nzOnCancel)="isLinkedAccountsModalVisible = false;" [nzFooter]="null" [nzWidth]="750">
    <ng-container *nzModalContent>
        <nz-spin [nzSpinning]="isLinkedAccountsLoading">
            <div *ngIf="goBackAccount" style="padding-bottom: 15px;">
                <button nz-button nzType="default" (click)="onLinkedAccountSelect(goBackAccount)">
                    <i nz-icon nzType="backward" nzTheme="outline" style="vertical-align: top; font-size: 1.5rem;"></i> Back to {{goBackAccount.clientName}}
                </button>
            </div>
            <div nz-row [nzJustify]="'center'">

                <div nz-col [nzSpan]="12" style="padding-top: 10px; padding-bottom: 15px">
                    <input nz-input placeholder="Filter Clients" type="text" [(ngModel)]="accountsFilterTerm" />
                </div>
            </div>
            <nz-table #linkedAcc [nzData]="filteredLinkedAccounts()" [nzFrontPagination]="false" style="overflow-x: auto;">
                <thead>
                    <tr>
                        <th></th>
                        <th>Name</th>
                        <th [nzSortFn]="searchesSortFn">Searches</th>
                        <th [nzSortFn]="salesSortFn">Sales</th>
                    </tr>
                </thead>
                <tbody class="table-body">
                    <tr *ngFor="let item of linkedAcc.data">
                        <td>
                            <button nz-button nzType="default" (click)="onLinkedAccountSelect(item)">
                  <i nz-icon nzType="login" nzTheme="outline" style="vertical-align: top; margin-top: 4px;"></i>
              </button>
                        </td>
                        <td>{{item.clientName}}</td>
                        <td>{{item.searchCount}}</td>
                        <td>{{item.salesCount}}</td>
                    </tr>
                </tbody>
            </nz-table>
        </nz-spin>

    </ng-container>
</nz-modal>

<!-- Root Widgets -->

<app-send-report></app-send-report>

<app-modal-video></app-modal-video>



<nz-drawer [nzClosable]="true" [nzVisible]="isWhatsappDrawerVisible" [nzWidth]="500" nzPlacement="right" [nzTitle]="drawerTitle" (nzOnClose)="closeWhatsAppDrawer()">
    <ng-container *nzDrawerContent>
        <app-whatsapp></app-whatsapp>
    </ng-container>
</nz-drawer>
<ng-template #drawerTitle>
    <div *ngIf="waService.waConversation.length === 0" style="display:inline-block">
        <b><span nz-icon nzType="whats-app" nzTheme="outline" style="margin-right: 10px;"></span>WhatsApp Inbox</b>
    </div>

    <div *ngIf="waService.waConversation.length > 0" style="display:inline-block">
        <button nz-button (click)="waService.ClearConversation()" style="margin-right: 10px;">
            <span nz-icon nzType="arrow-left" nzTheme="outline"></span>
        </button>


        <b>{{waService.waConversation[0].name}}</b>
        <div style="margin-right: 2rem; float: right;">
            <span>Archive: </span>
            <nz-switch [(ngModel)]="archiveToggle"></nz-switch>
        </div>
    </div>
    <div style="float: right">
        <app-video-widget [videoId]="21" style="float: inline-end;" [isButton]="true"></app-video-widget>
        
        
    </div>
</ng-template>
<!--Use later-->

<!-- <div class="row" style="margin-top:-2%;">
        <img *ngIf=" client.client_logo =='sfw_logo.png'" src="./../assets/Images/dealers/sfw_logo.png" height="30" width="80">
      <img *ngIf=" client.client_logo =='tm_logo.png'" src="./../assets/Images/dealers/tm_logo.png" height="60" width="100">
      <img *ngIf=" client.client_logo =='apex_logo.png'" src="./../assets/Images/dealers/apex_logo.png" height="30" width="100">
      <img *ngIf=" client.client_logo =='tt_logo.png'" src="./../assets/Images/dealers/tt_logo.png" height="30" width="70">
      <img *ngIf=" client.client_logo =='reivilo_logo.png'" src="./../assets/Images/dealers/reivilo_logo.png" height="35" width="90">
  </div> -->

<!-------------------------->