<div nz-row [nzGutter]="20" nzJustify="space-around">
    <div nz-col [nzSpan]="11">
        <nz-form-item>
            <button nz-button nzType="primary" style="width: 100%;" (click)="onShareClick()"><span nz-icon nzType="share-alt" nzTheme="outline"></span>Share</button>
        </nz-form-item>

    </div>
    <div nz-col [nzSpan]="11">
        <nz-form-item>
            <button nz-button nzType="primary" style="width: 100%;" (click)="navigateToCustomer()"><span nz-icon nzType="user" nzTheme="outline" ></span>Customer</button>
        </nz-form-item>

    </div>
    <div nz-col [nzSpan]="11">
        <nz-form-item>
            <button nz-button nzType="primary" style="width: 100%;" (click)="showActionLog()" [nzLoading]="isLoadingActionLog"><span nz-icon nzType="credit-card" nzTheme="outline"></span>Action Log</button>
        </nz-form-item>

    </div>
    <div nz-col [nzSpan]="11">
        <nz-form-item>
            <button nz-button nzType="primary" style="width: 100%;" (click)="onStatusChangeClick()" [disabled]="this.quote.isCompleted"><span nz-icon nzType="edit" nzTheme="outline" ></span>Update Status</button>
        </nz-form-item>

    </div>
    <div nz-col [nzSpan]="23">
        <nz-form-item>
            <input [disabled]="this.quote.isCompleted" [maxlength]="200" nz-input placeholder="Comment to customer" [(ngModel)]="quote.comment" (ngModelChange)="updateQuoteComment()" nz-tooltip="Comment to customer">
        </nz-form-item>
    </div>
</div>
<div nz-row nzJustify="center">
    <div nz-col nzSpan="5">
        <nz-form-item>
            <button [disabled]="this.quote.isCompleted" icon-button nz-button nzType="primary" nzShape="circle" nz-popover [nzPopoverPlacement]="'left'" [nzPopoverContent]="addItemsPopOver" [nzPopoverTrigger]="this.quote.isCompleted ? 'click' : 'hover'" class="text-center"><span nz-icon nzType="tool" nzTheme="outline" ></span></button>
        </nz-form-item>
    </div>
    <div nz-col nzSpan="5">
        <nz-form-item>
            <button [disabled]="this.quote.isCompleted" icon-button nz-button nzType="primary" nzShape="circle" nz-popover nzPopoverTitle="Services" [nzPopoverPlacement]="'left'" [nzPopoverContent]="servicesPopOver" [nzPopoverTrigger]="this.quote.isCompleted ? 'click' : 'hover'"><span nz-icon nzType="car" nzTheme="outline" ></span></button>
        </nz-form-item>
    </div>
    <div nz-col nzSpan="5" *ngIf="quote.isWIP">
        <nz-form-item>
            <button icon-button nz-button nzType="primary" nzShape="circle" nz-tooltip="Print Jobcard" (click)="printJobCard()" [nzLoading]="quote.isDirty"><span nz-icon nzType="file-pdf" nzTheme="outline" ></span></button>
        </nz-form-item>
    </div>
    <div nz-col nzSpan="5">
        <nz-form-item>
            <button [disabled]="this.quote.isCompleted" icon-button nz-button nzType="default" nzShape="circle" (click)="onClearQuoteClick()" nzDanger nz-tooltip="Clear Quote"><span nz-icon nzType="delete" nzTheme="outline" ></span></button>
        </nz-form-item>
    </div>
    <div nz-col nzSpan="4">
        <nz-form-item>
            <button [disabled]="this.quote.isCompleted" icon-button nz-button nzType="default" nzShape="circle" (click)="onRemoveServicesClick()" nzDanger nz-tooltip="Remove Services"><span nz-icon nzType="sf:shadowMinus" nzTheme="outline" ></span></button>
        </nz-form-item>
    </div>
</div>

<!-- Templates -->
<!-- Services Popover -->
<ng-template #servicesPopOver>
    <nz-spin [nzSpinning]="isLoadingServices">
        <div class="block-buttons">
            <button nz-button nzType="primary" nzSize="large" *ngFor="let item of services" (click)="onAddServiceGroupClick(item.packageTypeId)">{{item.packageAcronym}}</button>
        </div>
    </nz-spin>
</ng-template>
<!-- Add To Quote -->
<ng-template #addItemsPopOver>
    <div nz-row class="displaybox">
        <div nz-col nzSpan="24" class="text-center">
            <nz-spin [nzSpinning]="isLoadingStockSearch">
                <nz-button-group *ngFor="let item of customStockGroups">
                    <button *ngIf="item.stockTypeId === 1" nz-button nzType="default" class="addItemButton text-center" (click)="showExtra_Tyre()">
                        <i nz-icon nzType="{{item.stockTypeImage}}" nzTheme="outline" class="color-primary"></i>
                        <br class="mobile">
                        <br/>
                        <span>{{item.stockDescription}}</span>
                    </button>
                    <button *ngIf="item.stockTypeId !== 1" nz-button nzType="default" class="addItemButton text-center" (click)="showExtra_Other(item.stockTypeId, item.stockDescription)">
                        <i nz-icon nzType="{{item.stockTypeImage}}" nzTheme="outline" class="color-primary"></i>
                        <br class="mobile">
                        <br/>
                        <span>{{item.stockDescription}}</span>
                    </button>
                </nz-button-group>
            </nz-spin>
        </div>
    </div>
</ng-template>

<!-- Modals -->
<!-- Action Log Modal -->
<nz-modal [(nzVisible)]="isActionLogModalVisible" nzTitle="Action Log" (nzOnCancel)="isActionLogModalVisible = false" [nzFooter]="null" nzWidth="900px">
    <div *nzModalContent>
        <nz-table #fullLogTable [nzData]="actionLog" [nzLoading]="isLoadingActionLog" nzSize="small" [nzFrontPagination]="false">
            <thead>
                <tr>
                    <th>User</th>
                    <th>Date</th>
                    <th>Description</th>
                    <th>Comment</th>
                    <th>From</th>
                    <th>To</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of fullLogTable.data; let i = index">
                    <td><small>{{data.name}}</small></td>
                    <td><small>{{datepipe.transform(data.dateActioned, 'dd MMM YYYY HH:mm')}}</small></td>
                    <td>
                        <small>{{data.description}}</small>
                    </td>
                    <td><small>{{data.comment}}</small></td>
                    <td><small>{{data.valuePrev}}</small></td>
                    <td><small>{{data.valueNew}}</small></td>
                </tr>
            </tbody>
        </nz-table>
    </div>
</nz-modal>

<!-- Show Other Stock Modal -->
<nz-modal [(nzVisible)]="isSearchOtherModalVisible" nzTitle="Stock Availability" (nzOnCancel)="isSearchOtherModalVisible = false" [nzFooter]="null" [nzWidth]="'900px'">
    <div *nzModalContent>
        <div nz-row  nzJustify="space-between">
            <div nz-col>
                <button nz-button nzType="primary" (click)="openAdd()"> Add New {{addType | titlecase}}</button>
            </div>
            <div nz-col>
                <nz-input-group [nzSuffix]="suffixIconSearch">
                    <input type="text" nz-input [(ngModel)]="inputValue" (ngModelChange)="onAdvancedFilterChange()" placeholder="Advanced Filter" style="text-transform:uppercase;" />
                    <!-- <input type="text" nz-input  (ngModelChange)="onAdvancedFilterChange()" placeholder="Advanced Filter ***" style="text-transform:uppercase;" /> -->
                </nz-input-group>
                <ng-template #suffixIconSearch>
                <span nz-icon nzType="search"></span>
                </ng-template>
            </div>
            
        </div>
        <br>
        <div nz-row>
            <div nz-col nzSpan="4" nzOffset="20">
                <button nz-button nzType="primary" (click)="addMultipleSearchItems()" [disabled]="!newQuoteAdd.length" nz-tooltip nzTooltipTitle="Add multiple items">
                    <i nz-icon nzType="plus" nzTheme="outline"></i>
                    
                    &nbsp;Add multiple
                </button>
            </div>
        </div>
        <br>

        <!-- <nz-table #searchTable1 [nzData]="stockSearchResult" [nzLoading]="isLoadingStockSearch" [nzBordered]="false" [nzOuterBordered]="false" nzSize="small" [nzNoResult]="stockSearchNoResults" > -->
        <!-- <nz-table #searchTable1 [nzData]="displayedResults" [nzLoading]="isLoadingStockSearch" [nzBordered]="false" [nzOuterBordered]="false" nzSize="small" [nzNoResult]="stockSearchNoResults" >     -->
        <nz-table   [nzLoading]="isLoadingStockSearch" [nzBordered]="false" [nzOuterBordered]="false" nzSize="small"  [nzNoResult]="blankNoResults"  [nzFooter]="pageSwitch">    
            <thead>
                <tr>
                    <th>Description+</th>
                    <th>SOH</th>
                    <th>(Ex) Price</th>
                    <th>Stock Code</th>
                    <th>Modify</th>
                    <th>Add Item</th>
                    <th></th>
                    <th>Add multiple</th>
                </tr>
            </thead>
            <tbody>
                <!-- <tr *ngFor="let item of searchTable1.data | filter: inputValue: inputList"> -->
                <tr *ngFor="let item of displayedResults">
                    <!-- <td><img src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{item.brandLogoUrl}}" class="brandImage" /></td> -->
                    <td [ngClass]="{'color-warning' : item.mapped !== 1}">{{item.description}}</td>
                    <td [ngClass]="{'color-warning' : item.mapped !== 1}">{{item.soh}}</td>

                    <td [ngClass]="{'color-warning' : item.mapped !== 1}">{{item.price}}</td>
                    <td [ngClass]="{'color-warning' : item.mapped !== 1}">{{item.stockCode}}</td>
                    <td>
                        <button nz-button nzType="primary" nzShape="circle" nzSize="large" (click)="loadstockinfo(item)" nz-tooltip nzTooltipTitle="Modify price or stock code">
                            <span nz-icon nzType="edit" nzTheme="outline"></span>
                        </button>
                    </td>
                    <td>
                        <button nz-button nzType="primary" nzShape="circle" nzSize="large" *ngIf="item.active" class="btn btnaccept" (click)="selectSearchItem(item)"  nz-tooltip nzTooltipTitle="Select Item">
                           <i nz-icon nzType="plus" nzTheme="outline"></i>
                        </button>
                        <button nz-button nzType="primary" nzShape="circle" nzSize="large" *ngIf="!item.active" class="btn btnaccept" (click)="loadstockinfo(item)"  nz-tooltip nzTooltipTitle="Modify price to activate custom stock item">
                            <i nz-icon nzType="plus" nzTheme="outline"></i>
                        </button>
                    </td>
                    <td></td>
                    
                    <td class="text-center" *ngIf="!checkIfStockSearchResultOnQuote(item);else itemOnQuoteTemplate">
                        <label nz-checkbox [(ngModel)]="item.selected" (nzCheckedChange)="selectMultipleSearchItems(item)"></label>
                    </td>
                    <ng-template #itemOnQuoteTemplate>
                        <td class="text-center" nz-tooltip nzTooltipTitle="Item is already on the quote">
                            <!-- <label nz-checkbox [(ngModel)]="item.selected" (nzCheckedChange)="selectMultipleSearchItems(item)"></label> -->
                            <span nz-icon nzType="check" nzTheme="outline"></span>
                        </td>
                    </ng-template>
                    
                </tr>
            </tbody>
        </nz-table>
        <!-- <nz-pagination [nzPageSize]="pageSize" [nzTotal]="filteredResults.length" [(ngModel)]="currentPage" (ngModelChange)="onPageChange($event)"></nz-pagination> -->
        <ng-template #pageSwitch>
            <nz-pagination  [nzPageSize]="pageSize" [nzTotal]="filteredResults.length" [(ngModel)]="currentPage" (nzPageIndexChange)="onPageChange($event)"></nz-pagination>
        </ng-template>

        <ng-template #stockSearchNoResults>
            <div style="text-align: center;">
                <i nz-icon nzType="warning" nzTheme="outline" class="color-warning " style="font-size: 4rem;"></i>
                <br />
                <b>NO STOCK FOUND</b>
            </div>
        </ng-template>
        <ng-template #blankNoResults>
            <div style="text-align: center;">
                
            </div>
        </ng-template>
    </div>

</nz-modal>

<!-- Edit Custom stock Item -->
<nz-modal [(nzVisible)]="isEditStockModalVisible" nzTitle="{{this.editcustom.description}}" (nzOnCancel)="isEditStockModalVisible = false" [nzFooter]="null" [nzWidth]="'900px'">
    <div *nzModalContent>
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <label class="float-left">Stock Code</label>
                    <div class="input-group maindiv">
                        <span class="input-group-prepend"><div class="input-group-text bg-transparent">
                    <i nz-icon nzType="barcode" nzTheme="outline"></i>
                
                </div>
                </span>
                        <input [(ngModel)]="editcustom.code" class="form-control text-center form-control-custom" value="{{ this.editcustom.code}}">
                    </div>
                </div>
                <div class="col-lg-6">
                    <label class="float-left">Price <small class="text-style">*(Vat incl)</small></label>
                    <div class="input-group maindiv">
                        <span class="input-group-prepend"><div class="input-group-text bg-transparent">
                         <span nz-icon nzType="sf:moneyBillSVG" nzTheme="outline" ></span>
                    </div>
                    </span>
                    <input [(ngModel)]="editcustom.price" class="form-control text-center form-control-custom" value="{{ this.editcustom.price}}">
                </div>
            </div>
        </div>
        <p></p>
        <div class="row text-center ">
            <div class="col-lg-4 offset-4">
                <button nz-button nzType="primary" (click)="updateCustomStockItem()" data-dismiss="modal">Save</button>
            </div>
        </div>
    </div>
    </div>
</nz-modal>

<!-- Add custom Stock Item -->
<nz-modal [(nzVisible)]="this.isAddCustomStockModalVisible" nzTitle="{{title | titlecase}}" (nzOnCancel)="this.isAddCustomStockModalVisible = false" [nzFooter]="null" nzWidth="700px">

    <div *nzModalContent>
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-6 offset-3">
                    <form nz-form>
                        <nz-form-item>
                            <label>Description</label>
                            <nz-input-group nzPrefixIcon="file-text">
                                <input [(ngModel)]="desc" name="desc" value="{{desc}}" nz-input placeholder="Description" id="custom_description" style="width: 50%;" autocomplete="off" />
                            </nz-input-group>
                        </nz-form-item>
                        <nz-form-item>
                            <label>Stock Code</label>
                            <nz-input-group nzPrefixIcon="barcode">
                                <input [(ngModel)]="code" name="code" value="{{code}}" nz-input placeholder="Stock Code" id="custom_code" style="width: 50%;" autocomplete="off" />
                            </nz-input-group>
                        </nz-form-item>
                        <nz-form-item>
                            <label>Price (incl VAT)</label>
                            <nz-input-group nzPrefixIcon="money-collect">
                                <input [(ngModel)]="price" name="value" value="{{price}}" nz-input placeholder="Price" type="number" min="0" id="custom_price" style="width: 50%;" autocomplete="off" />
                            </nz-input-group>
                        </nz-form-item>
                    </form>
                    <button nz-button nzType="primary" (click)="addCustomStockItem()"><i nz-icon nzType="save" nzTheme="fill" 
                    style="vertical-align: top; margin-right: 4px; font-size: 1.5rem;"></i>Save</button>
                </div>
            </div>
        </div>
    </div>
</nz-modal>

<!-- Search Stock (Tyre) Modal -->
<nz-modal [(nzVisible)]="isSearchStockModalVisible" nzTitle="Stock Availability" (nzOnCancel)="isSearchStockModalVisible = false; newQuoteAdd = []" [nzFooter]="null" [nzWidth]="'1400px'">
    <div *nzModalContent>
        <div nz-row>
            <div nz-col nzSpan="8">
                <nz-form-item>
                    <input nz-input placeholder="Search for tyre..." (keyup.enter)="showExtra_Tyre()" [(ngModel)]="searchString" name="searchTyre" id="searchTyre">
                </nz-form-item>
            </div>
            <div nz-col nzSpan="4" nzOffset="12">
                <button nz-button nzType="primary" (click)="addMultipleSearchItems()" [disabled]="!newQuoteAdd.length" nz-tooltip nzTooltipTitle="Add multiple items">
                    <i nz-icon nzType="plus" nzTheme="outline"></i>
                    
                    &nbsp;Add multiple
                </button>
            </div>
        </div>
        <ng-template #emptyTemplate></ng-template>
        <nz-tabset nzCentered>
            <nz-tab *ngFor="let tab of tyreStockTab" [nzTitle]="tab.name">
                <nz-table #searchTable1 [nzData]="filterSearchStock(tab.id)" [nzLoading]="isLoadingStockSearch" [nzShowPagination]="false" [nzBordered]="false" [nzOuterBordered]="false" [nzNoResult]="stockSearchNoResults" [nzPageSize]="100">
                    <thead>
                        <tr>
                            <th>Brand</th>
                            <th>Stock Code</th>
                            <th>Description</th>
                            <th>SOH</th>
                            <th>SLA</th>
                            <th>Supplier</th>
                            <th>(Ex) Price</th>
                            <th></th>
                            <th>Add multiple</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of searchTable1.data">
                            <td><img src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{item.brandLogoUrl}}" class="brandImage" /></td>
                            <td>{{item.stockCode}}</td>
                            <td>{{item.description}}</td>
                            <td>{{item.soh}}</td>
                            <td>{{item.sla}}</td>
                            <td>{{item.supplier}}</td>
                            <td>{{item.price}}</td>
                            <td>
                                <button nz-button nzType="primary" nzShape="circle" nzSize="large" (click)="selectSearchItem(item)" nz-tooltip nzTooltipTitle="Select Item. If multiple tyres are selected, this button will add all the currently selected items">
                                    <i nz-icon nzType="plus"></i>
                                </button>
                            </td>
                            <td class="text-center">
                                <label nz-checkbox [(ngModel)]="item.selected" (nzCheckedChange)="selectMultipleSearchItems(item)"></label>
                            </td>
                           
                        </tr>
                    </tbody>
                </nz-table>
            </nz-tab>
        </nz-tabset>
        <ng-template #stockSearchNoResults>
            <div style="text-align: center;">
                <i nz-icon nzType="warning" nzTheme="outline" class="text-warning-style " style="font-size: 4rem;"></i>
                <br />
                <b>NO STOCK FOUND</b>
            </div>
        </ng-template>
    </div>
</nz-modal>